/**
 * POST shutdown property
 */

import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

interface PostShutdownPropertyOptions {
  propertyURN: string;
}

export default async function postShutdownProperty({
  propertyURN,
}: PostShutdownPropertyOptions) {
  const guid = metrics.start('postShutdownProperty');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/properties/${propertyURN}/shutdown`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };
  logger.info(
    `API:postShutdownProperty /social/properties/${propertyURN}/shutdown`,
  );

  try {
    await axios(config);
    metrics.end('postShutdownProperty', guid);
  } catch (error) {
    metrics.fail('postShutdownProperty', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postShutdownProperty',
    });
    throw apiError;
  }
}
