import { ReactNode, useEffect, useRef } from 'react';

const DEFAULT_ANIMATE_TIMEOUT = 400;

interface AnimateOnChangeProps {
  /**
   * A boolean to indicate that an animation should take place when rendered.
   */
  animate: boolean;

  /**
   * The base class to apply to the div (regardless of animation)
   */
  baseClassName: string;

  /**
   * The class to apply during animation.
   */
  animationClassName: string;

  /**
   * The duration of the animation in ms. Defaults to 400.
   */
  animationDuration?: number;

  children: ReactNode;
}

/**
 * A component which applies the animation className temporarily on each render
 * whenever the animate prop is true.
 */
const AnimateOnChange = ({
  children,
  animate,
  baseClassName,
  animationClassName,
  animationDuration = DEFAULT_ANIMATE_TIMEOUT,
}: AnimateOnChangeProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // On EVERY render, we want this effect to run.
  // When the animate prop is true, add the animation class
  // and then remove it after 400ms.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const elRef = elementRef.current;
    if (animate && elRef) {
      elRef.classList.add(animationClassName);

      const timeoutId = setTimeout(
        () => elRef.classList.remove(animationClassName),
        animationDuration,
      );

      return () => clearTimeout(timeoutId);
    }
  });

  return (
    <div ref={elementRef} className={baseClassName}>
      {children}
    </div>
  );
};

export default AnimateOnChange;
