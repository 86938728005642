/**
 * getMediaId - get media id from article URL
 *
 * @param {long} accountAPIId  - the account to which the item belongs
 * @param {object} articleURL  - the article URL
 * @return {object}                 - the media item associated with the article URL
 */

import { z } from 'zod';

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getURNName } from 'common/social';
import { convertToSocialPageURN } from 'common/urn';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ mediaIds: z.array(z.string()).nonempty() })
  .describe('getMediaId.ResponseSchema');

export default async function getMediaId({
  accountAPIId,
  articleURL,
}: {
  accountAPIId: number;
  articleURL: string;
}) {
  const guid = metrics.start('getMediaId');

  const mediaItem = { unshortenedShareURL: articleURL };

  const typeId = getAPITypeId({ accountAPIId });
  const socialNetworkType = getURNName({ apiTypeId: typeId });
  const socialPageURN = convertToSocialPageURN(socialNetworkType, accountAPIId);

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${socialPageURN}/media/NEW`,
    method: 'POST',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify(mediaItem),
  };
  logger.info(`API:getMediaId /social/api/${socialPageURN}/NEW ${articleURL}`);

  try {
    const response = await axios(config);
    metrics.end('getMediaId', guid);
    logger.debug('getMediaId', { accountAPIId, articleURL });

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData.mediaIds[0];
  } catch (error) {
    metrics.fail('getMediaId', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getMediaId',
      args: { accountAPIId, articleURL },
    });
    throw apiError;
  }
}
