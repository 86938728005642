import { Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as tracker from 'common/tracker';
import { useOnboardingSetupContext } from 'context/OnboardingContext';

interface SignoutModalProps {
  onDismiss: () => void;
}

const SignoutModal = ({ onDismiss }: SignoutModalProps) => {
  const { onSaveRef } = useOnboardingSetupContext();
  const [isSigningOut, setIsSigningOut] = useState(false);

  const navigate = useNavigate();
  const handleSaveAndSignOut = async () => {
    setIsSigningOut(true);

    if (onSaveRef.current) {
      await onSaveRef.current();
    }

    tracker.track({
      eventName: 'Save Property Setup',
      callback: () => navigate('/logout'),
    });

    // Some browser extensions block mixpanel. We have no way of knowing if this happens
    // and when it does, the callback isn't called. So we set a fallback after 1 second
    // just in case
    setTimeout(() => {
      navigate('/logout');
    }, 1000);
  };

  return (
    <Modal isOpen onClose={onDismiss} size="small">
      <Modal.Header>
        <Modal.Title>Save and exit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You will be able to continue setting up Echobox in your next login.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onDismiss}
          isDisabled={isSigningOut}
        >
          Cancel
        </Button>
        <Button isDisabled={isSigningOut} onClick={handleSaveAndSignOut}>
          Save and exit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SignoutModal;
