/* eslint no-use-before-define:"off" */

import { GLOBAL_INFO_STATES } from 'common/constants';

export {
  hasChangedLoading,
  hasChangedRefreshing,
  hasChangedSelecting,
  hasChangedState,
  hasChangedToReady,
  hasChangedUpdating,
};

function hasChangedLoading(prevState, nextState) {
  return hasChangedState(prevState, nextState, [GLOBAL_INFO_STATES.LOADING]);
}

function hasChangedRefreshing(prevState, nextState) {
  return hasChangedState(prevState, nextState, [GLOBAL_INFO_STATES.REFRESHING]);
}

function hasChangedSelecting(prevState, nextState) {
  return hasChangedState(prevState, nextState, [
    GLOBAL_INFO_STATES.SELECTING_PROPERTY,
    GLOBAL_INFO_STATES.SELECTING_PAGE,
  ]);
}

function hasChangedState(prevState, nextState, stateList = []) {
  // Return null if the previous / next states are not ones we are interested in
  if (
    stateList.length !== 0 &&
    stateList.indexOf(prevState) === -1 &&
    stateList.indexOf(nextState) === -1
  ) {
    return null;
  }
  // If no states are listed then report ANY state change
  if (stateList.length === 0) {
    return prevState !== nextState;
  }
  // Otherwise check if either of the previous and next states are ones we are interested in and either has changed
  return (
    (stateList.indexOf(prevState) !== -1 ||
      stateList.indexOf(nextState) !== -1) &&
    prevState !== nextState
  );
}

function hasChangedToReady(
  prevState,
  nextState,
  { excludeLoading = false, excludeLoadingAndRefreshing = false } = {},
) {
  const wasReady =
    prevState === GLOBAL_INFO_STATES.READY ||
    prevState === GLOBAL_INFO_STATES.ERROR;
  const isReady =
    nextState === GLOBAL_INFO_STATES.READY ||
    nextState === GLOBAL_INFO_STATES.ERROR;
  if (!wasReady && isReady) {
    if (excludeLoadingAndRefreshing) {
      return (
        prevState !== GLOBAL_INFO_STATES.LOADING &&
        prevState !== GLOBAL_INFO_STATES.REFRESHING
      );
    }
    if (excludeLoading) {
      return prevState !== GLOBAL_INFO_STATES.LOADING;
    }
    return prevState !== GLOBAL_INFO_STATES.REFRESHING;
  }
  if (!wasReady && !isReady) {
    return false;
  }
  return null;
}

function hasChangedUpdating(prevState, nextState) {
  return hasChangedState(prevState, nextState, [
    GLOBAL_INFO_STATES.UPDATING_SETTINGS,
  ]);
}
