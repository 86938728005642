import { cloneArray } from 'common/utility';

/**
 * chunkArray
 *
 * @param anArray - Array to split
 * @param chunkSize - Maximum size of each group
 */

function chunkArray<TItem>(anArray: Array<TItem>, chunkSize: number) {
  const chunkedArray = [];

  if (Array.isArray(anArray)) {
    while (anArray.length) {
      chunkedArray.push(anArray.splice(0, chunkSize));
    }
  }

  return chunkedArray;
}

/**
 * mergeDedupeArrays
 *
 * @param array1 - First array of objects
 * @param array2 - Second array of objects
 * @param key - Key to use to dedupe entries
 */

function mergeDedupeArrays<
  TItem1,
  TItem2,
  TKey extends keyof (TItem1 | TItem2),
>(array1: Array<TItem1>, array2: Array<TItem2>, key: TKey) {
  const result: Array<TItem1 | TItem2> = cloneArray(array1);
  array2.forEach((entry) => {
    if (result.findIndex((item) => item[key] === entry[key]) === -1) {
      result.push(entry);
    }
  });
  return result;
}

/**
 * compareArrays (For simplicity, comparison was done by !== only. Object comparison is not supported)
 *
 * @param array1 - First array of elements
 * @param array2 - Second array of elements
 * @param ordered - elements have to be in order
 */
function compareArrays(
  array1: Array<unknown>,
  array2: Array<unknown>,
  ordered: boolean,
) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }
  const array1copy = cloneArray(array1);
  const array2copy = cloneArray(array2);
  if (!ordered) {
    array1copy.sort();
    array2copy.sort();
  }
  for (let i = 0; i < array1copy.length; i += 1) {
    if (array1copy[i] !== array2copy[i]) {
      return false;
    }
  }
  return true;
}

export { chunkArray, compareArrays, mergeDedupeArrays };
