import { Flex, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

interface InstantVideoBodyProps {
  children: React.ReactNode;
}

const InstantVideoBody = ({ children }: InstantVideoBodyProps) => {
  return (
    <Modal.Body py={5}>
      <Flex
        overflow="hidden"
        // Not a great calculation, but the preview area should be 100vh - 52px (header) - 68px (footer) - 40px (body padding) - 40px (external padding)
        h="calc(100vh - 52px - 68px - 40px - 40px)"
        // But it also has a max height: https://www.figma.com/file/ljCip2f5xiP9c0lhMspM34/UX-856-Automated-video-workflow?node-id=385:85248&mode=dev
        // However, this deviates from the design, because the default browser video controls are internal to the video element.
        maxH="900px"
        pos="relative"
        borderRadius="lg"
        _before={{
          content: '""',
          boxShadow: 'border',
          inset: 0,
          position: 'absolute',
          pointerEvents: 'none',
          borderRadius: 'lg',
          zIndex: 1,
        }}
      >
        {children}
      </Flex>
    </Modal.Body>
  );
};

export default InstantVideoBody;
