import {
  Flex,
  FormControl,
  Textarea,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

interface CaptionProps {
  value: string;
  onChange: (value: string) => void;
}
const Caption = ({ value, onChange }: CaptionProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <Flex as="form" gap={2}>
      <FormControl>
        <FormControl.FormLabel>Caption</FormControl.FormLabel>
        <Textarea
          value={value}
          onChange={handleChange}
          // 👇 These styling props could be extracted into a component if the Textarea component is used in multiple places
          size="sm"
          p={3}
          border="none"
          boxShadow="border"
          resize="none"
          h={20}
          bg="surface.primary"
          color="text.primary"
          lineHeight={5}
          borderRadius="md"
          _focus={{
            boxShadow: 'focusWithBorder',
          }}
        />
      </FormControl>
    </Flex>
  );
};

export default Caption;
