const Link = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 4.5H9.225C8.93625 4.5 8.7 4.73625 8.7 5.025C8.7 5.31375 8.93516 5.55 9.225 5.55H11.5C12.8508 5.55 13.95 6.64922 13.95 8C13.95 9.35078 12.8508 10.45 11.5 10.45H9.225C8.93516 10.45 8.7 10.6852 8.7 10.975C8.7 11.2648 8.93625 11.5 9.225 11.5H11.5C13.4298 11.5 15 9.92981 15 8C15 6.07019 13.4294 4.5 11.5 4.5ZM6.775 10.45H4.5C3.14922 10.45 2.05 9.35078 2.05 8C2.05 6.64922 3.14922 5.55 4.5 5.55H6.775C7.06594 5.55 7.3 5.31594 7.3 5.025C7.3 4.73406 7.06594 4.5 6.775 4.5H4.5C2.57019 4.5 1 6.07062 1 8C1 9.92937 2.57019 11.5 4.5 11.5H6.775C7.06594 11.5 7.3 11.2659 7.3 10.975C7.3 10.6841 7.06594 10.45 6.775 10.45ZM5.2 8C5.2 8.28984 5.43516 8.525 5.725 8.525H10.275C10.5659 8.525 10.8 8.29094 10.8 8C10.8 7.70906 10.5648 7.475 10.275 7.475H5.725C5.43625 7.475 5.2 7.71125 5.2 8Z"
      fill="currentColor"
    />
  </svg>
);

export default Link;
