import { Button, Flex, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { getCurrentSocialPageURN } from 'common/accountAPIs';
import { MEDIA_ITEM_STATES, VIDEO_PREFIX } from 'common/constants';
import { pickAudio, pickBackgroundVideo } from 'common/instantVideo';
import * as MediaItem from 'common/mediaItem';
import { uploadVideo } from 'common/video';
import saveMediaItem from 'process/saveMediaItem';
import {
  FixTypeLater,
  Frame,
  ShareTimeType,
  Tone,
  VideoParameters,
} from 'types';
import Caption from './Caption';
import EditorForm from './EditorForm';
import FramesOverview from './FramesOverview';
import InstantVideoBody from './InstantVideoBody';
import InstantVideoEditor from './InstantVideoEditor';
import InstantVideoHeader from './InstantVideoHeader';
import ScheduleButton from './ScheduleButton';
import SettingsCard from './SettingsCard';
import TextEditor from './TextEditor';
import TimingOptions from './TimingOptions';
import ToneSelector from './ToneSelector';
import UploadEditor from './UploadEditor';
import VideoPreview from './VideoPreview';
import { useInstantVideoTracking } from './useInstantVideoTracking';
import { useMediaItemVideoGeneration } from './useMediaItemVideoGeneration';

type EditSectionData =
  | {
      type: 'text' | 'imageURL';
      frame: Frame;
    }
  | {
      type: 'videoURL';
    }
  | null;

interface InstantVideoModalProps {
  initialMediaItem: FixTypeLater;
  onClose: () => void;
  onSchedule: () => void;
}

const InstantVideoModalContent = ({
  initialMediaItem,
  onClose,
  onSchedule,
}: InstantVideoModalProps) => {
  const [editSection, setEditSection] = React.useState<EditSectionData>(null);
  const {
    mediaItem,
    setMediaItem,
    data,
    setData,
    blob,
    regenerateVideo,
    progress,
  } = useMediaItemVideoGeneration({ initialMediaItem });
  const articleURL = MediaItem.getUnshortenedShareURL({
    // using original media item as the processed one isn't a link post.
    mediaItem: initialMediaItem,
  });
  const { trackDownloadVideo, trackReplaceVideoElement, trackSchedulePost } =
    useInstantVideoTracking();

  const articleTitle = MediaItem.getRssTitle({ mediaItem });
  const shareTime = MediaItem.getShareTime({ mediaItem });
  const isLoading = MediaItem.getIsLoading({ mediaItem });
  const videoURL = MediaItem.getVideoURL({ mediaItem });
  const message = MediaItem.getMessage({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const socialPageURN = getCurrentSocialPageURN();

  const handleEditorBack = () => {
    setEditSection(null);
  };

  const handleCaptionChange = (value: string) => {
    const updatedMediaItem = MediaItem.setMessage({
      mediaItem,
      fieldValue: value,
    });
    setMediaItem(updatedMediaItem);
  };

  const handleDownloadClick = () => {
    trackDownloadVideo({ articleURL });

    const anchorEl = document.createElement('a');
    anchorEl.href = videoURL;
    anchorEl.download = 'echobox_instant_video.mp4';
    anchorEl.click();
  };

  const handleEditorSave = (value: string) => {
    if (editSection !== null) {
      if (!data) return;

      const updatedData = { ...data };

      if (editSection.type === 'videoURL') {
        updatedData[editSection.type] = value;
      } else {
        updatedData[editSection.frame][editSection.type] = value;
      }

      setData(updatedData);

      let element = 'Video';
      if (editSection.type === 'text') {
        element = `Text ${editSection.frame.slice(-1)}`;
      } else if (editSection.type === 'imageURL') {
        element = `Image ${editSection.frame.slice(-1)}`;
      }

      trackReplaceVideoElement({ articleURL, element });

      regenerateVideo(updatedData);
    }
    setEditSection(null);
  };

  const handleSchedulePost = async () => {
    if (!socialPageURN || !blob) return;

    const file = new File([blob], 'ebx-instant-video.mp4');

    // Upload the video to s3
    const newVideoURL = await uploadVideo({ file, socialPageURN });

    let updatedMediaItem = MediaItem.setVideoURL({
      mediaItem,
      fieldValue: newVideoURL,
    });

    // Remove the media ID and URL so that it is separated from the article itself
    updatedMediaItem = MediaItem.setMediaId({
      mediaItem: updatedMediaItem,
      fieldValue: 'NEW',
    });
    updatedMediaItem = MediaItem.setUnshortenedShareURL({
      mediaItem: updatedMediaItem,
      fieldValue: null,
    });
    updatedMediaItem = MediaItem.setShareURL({
      mediaItem: updatedMediaItem,
      fieldValue: null,
    });
    updatedMediaItem = MediaItem.setImageURLs({
      mediaItem: updatedMediaItem,
      fieldValue: [],
    });

    // Save the media item
    await saveMediaItem({
      mediaItem: updatedMediaItem,
      accountAPIId,
      targetState: MEDIA_ITEM_STATES.SCHEDULED,
    });

    trackSchedulePost({ mediaItem: updatedMediaItem, articleURL });

    // Propagate event to parent
    onSchedule();
  };

  const handleTimingOptionChange = (type: ShareTimeType) => {
    const updatedMediaItem = MediaItem.setShareTime({
      mediaItem,
      fieldValue: { ...shareTime, type },
    });
    setMediaItem(updatedMediaItem);
  };

  const handleToneChange = (tone: Tone) => {
    if (!data) return;

    const backgroundVideo = data.videoURL.startsWith(VIDEO_PREFIX)
      ? pickBackgroundVideo({ tone })
      : data.videoURL;
    const audioTrack = pickAudio({ tone });

    const updatedData: VideoParameters = {
      ...data,
      tone,
      videoURL: backgroundVideo,
      audioURL: audioTrack,
    };

    setData(updatedData);

    trackReplaceVideoElement({ articleURL, element: 'Tone' });

    regenerateVideo(updatedData);
  };

  let editorView = null;

  if (editSection !== null) {
    switch (editSection.type) {
      case 'text':
        editorView = (
          <EditorForm onBack={handleEditorBack}>
            <TextEditor
              frame={editSection.frame}
              initialValue={data?.[editSection.frame].text}
              onCancel={handleEditorBack}
              onSave={handleEditorSave}
            />
          </EditorForm>
        );
        break;
      case 'imageURL':
        editorView = (
          <EditorForm onBack={handleEditorBack}>
            <UploadEditor
              frame={editSection.frame}
              type={editSection.type}
              onCancel={handleEditorBack}
              onSave={handleEditorSave}
            />
          </EditorForm>
        );
        break;
      case 'videoURL':
        editorView = (
          <EditorForm onBack={handleEditorBack}>
            <UploadEditor
              type={editSection.type}
              onCancel={handleEditorBack}
              onSave={handleEditorSave}
            />
          </EditorForm>
        );
        break;
      default:
        editorView = null;
        break;
    }
  } else {
    editorView = (
      <>
        <Flex flexDir="column" gap={5}>
          <Flex flexDir="column" gap={3}>
            <ToneSelector value={data?.tone} onChange={handleToneChange} />
            {data ? (
              <SettingsCard.VideoCard
                videoURL={data.videoURL}
                onClick={() => setEditSection({ type: 'videoURL' })}
              />
            ) : (
              <SettingsCard type="video" isDisabled />
            )}
          </Flex>
          <Caption value={message} onChange={handleCaptionChange} />
        </Flex>
        <FramesOverview
          data={data}
          onEditSection={(frame, type) => setEditSection({ type, frame })}
        />
      </>
    );
  }

  return (
    <>
      <InstantVideoHeader>{articleTitle}</InstantVideoHeader>
      <InstantVideoBody>
        <VideoPreview
          isLoading={isLoading}
          videoURL={videoURL}
          progress={progress}
        />
        <InstantVideoEditor>{editorView}</InstantVideoEditor>
      </InstantVideoBody>
      <Modal.Footer>
        <Button mr="auto" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <TimingOptions
          value={shareTime.type}
          onChange={handleTimingOptionChange}
        />
        <ScheduleButton
          label={shareTime.type === 'now' ? 'Share' : 'Schedule'}
          isDisabled={isLoading}
          onScheduledClick={handleSchedulePost}
          onDownloadClick={handleDownloadClick}
        />
      </Modal.Footer>
    </>
  );
};

export default InstantVideoModalContent;
