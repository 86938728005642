/**
 * GET /{root}/{state}/{mediaId} - Retrieves full details of the specified media item.
 *
 * @param {integer} accountAPIId - Account API id
 * @param {string} state - The current state of the media item
 * @param {string} mediaId - Media item id
 * @return {object} - Data fields for the specified media item
 */

import Immutable from 'seamless-immutable';

import axios from 'api/axios';
import getPropertiesResolveURL from 'api/getPropertiesResolveURL';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import * as cache from 'common/cache';
import {
  ACCOUNT_SETTING_TYPES,
  API_PROPERTIES,
  API_TYPE_IDS,
  CONTENT_TYPE_IDS,
  TAG_TYPES,
} from 'common/constants';
import { getErrorStatus, handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import { getSetting } from 'common/settings';
import { hasMentionsLookups } from 'common/social';
import { convertToSocialPageURN, encodeMediaURN } from 'common/urn';
import { isNull, isNullOrUndefined, isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';
import { addImageOverlay } from 'helpers/imageOverlay';

/**
 * getMediaItem
 * @param {{
 *  accountAPIId: number;
 *  apiTypeId: number;
 *  state: string;
 *  mediaId: string;
 *  getMessages?: boolean;
 *  getPageInfo?: boolean;
 *  getSponsor?: boolean;
 *  getTags?: boolean;
 *  resolveURL?: boolean;
 *  refreshHTMLMetaData?: boolean;
 *  refreshTextContent?: boolean;
 * }}
 */

export default async function getMediaItem({
  accountAPIId = mandatory('accountAPIId'),
  apiTypeId = mandatory('apiTypeId'),
  state = mandatory('state'),
  mediaId = mandatory('mediaId'),
  getMessages = false,
  getPageInfo = false,
  getSponsor = false,
  getTags = false,
  resolveURL = false,
  refreshHTMLMetaData = false,
  refreshTextContent = false,
} = {}) {
  const guid = metrics.start('getMediaItem');
  const encodedMediaId = encodeMediaURN(mediaId);

  try {
    let mediaItem;

    /**
     * 1. First set of requests - media item, suggested hashtags and mentions
     */

    /**
     * 1.1 Requests
     */

    let requests = [];
    let requestMap = {};
    let responses = [];
    let response = {};
    let config;

    let gotPageInfo = false;
    let gotSponsor = false;

    let getTagsResult = getTags;

    const typeId = getAPITypeId({
      accountAPIId,
    });
    const socialNetworkType = API_PROPERTIES[typeId]?.urnName;
    const socialPageURN = convertToSocialPageURN(
      socialNetworkType,
      accountAPIId,
    );

    const params = {
      ...{ refreshHTMLMetaData, refreshTextContent },
    };

    /**
     * 1.1.1. Media item details - /social/api/{socialPageURN}/media/{state}/{mediaId}
     */

    config = {
      url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/media/${state}/${encodedMediaId}`,
      method: 'GET',
      timeout: API_TIMEOUTS.L,
      headers: getClientServiceRequestHeaders(),
      params,
    };
    logger.info(
      `API:getMediaItem /social/api/${socialPageURN}/media/${state}/${encodedMediaId}`,
    );
    requests.push(axios(config));
    requestMap.mediaItem = requests.length - 1;

    responses = await Promise.all(requests);

    /**
     * 1.2. Process media item response - create media item
     */

    response = responses[requestMap.mediaItem];
    mediaItem = MediaItem.initialise({
      accountAPIId,
      backend: {
        encodedMediaId,
        ...response.data.mediaItemData,
      },
    });

    /**
     * 1.2.1. Requests
     */

    requests = [];
    requestMap = {};
    responses = [];

    /**
     * 1.2.2. Hashtag suggestions - /social/api/{socialPageURN}/media/{state}/{encodedMediaId}/hashtags
     */

    if (getTags) {
      config = {
        url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/media/${encodedMediaId}/hashtags`,
        method: 'GET',
        timeout: API_TIMEOUTS.S,
        headers: getClientServiceRequestHeaders(),
      };
      requests.push(axios(config));
      requestMap.hashtagSuggestions = requests.length - 1;
    }

    /**
     * 1.2.3. Mention suggestions - /social/api/{socialPageURN}/media/{state}/{encodedMediaId}/mentions
     */

    if (getTags) {
      config = {
        url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/media/${encodedMediaId}/mentions`,
        method: 'GET',
        timeout: API_TIMEOUTS.S,
        headers: getClientServiceRequestHeaders(),
      };
      requests.push(axios(config));
      requestMap.mentionSuggestions = requests.length - 1;
    }

    /**
     * 1.3. Responses
     */

    try {
      responses = await Promise.all(requests);
    } catch (error) {
      const errorCode = getErrorStatus(error);
      if (errorCode === 408) {
        logger.info(
          'api/getMediaItem - timed out getting hashtags or suggestions',
        );
        getTagsResult = false;
      }
    }

    /**
     * 1.3.1. Process hashtag suggestions response
     */

    if (getTagsResult) {
      response = responses[requestMap.hashtagSuggestions];
      if (response && response.data?.hashtags) {
        mediaItem = MediaItem.setHashtags({
          mediaItem,
          fieldValue: response.data.hashtags,
        });
      }
    }

    /**
     * 1.3.1. Process mention suggestions response
     */

    if (getTagsResult) {
      response = responses[requestMap.mentionSuggestions];
      if (response && response.data?.mentions) {
        mediaItem = MediaItem.setMentions({
          mediaItem,
          fieldValue: response.data.mentions,
        });
      }
    }

    /**
     * 2. Second set of requests - sponsor, inline mentions and image overlay
     */

    const unshortenedShareURL = MediaItem.getUnshortenedShareURL({ mediaItem });

    /**
     * 2.1. Requests
     */

    requests = [];
    requestMap = {};
    responses = [];

    /**
     * 2.1.1. Sponsor details - /pageinfo
     */

    if (getSponsor) {
      const sponsor = MediaItem.getSponsor({ mediaItem });
      if (!isNull(sponsor)) {
        gotSponsor = true;
        const cached = cache.getCacheItem('getPageInfo', sponsor);
        if (!isNull(cached)) {
          requests.push(
            new Promise((resolve) => {
              resolve(cached);
            }),
          );
        } else {
          config = {
            url: `${constructBaseSocialAPIURL()}/pageinfo`,
            method: 'GET',
            timeout: API_TIMEOUTS.S,
            headers: getClientServiceRequestHeaders(),
            params: {
              pageId: sponsor,
            },
          };
          requests.push(axios(config));
        }
        requestMap.sponsorDetails = requests.length - 1;
      }
    }

    /**
     * 2.1.2. Facebook mentions - /pageinfo
     */

    if (getPageInfo) {
      const tags = MediaItem.extractHashtagsAndMentions({ mediaItem });
      if (
        hasMentionsLookups({ apiTypeId }) &&
        tags[TAG_TYPES.MENTION][accountAPIId].length !== 0
      ) {
        gotPageInfo = true;
        tags[TAG_TYPES.MENTION][accountAPIId].forEach((tag) => {
          const pageId = tag.raw.substring(2, tag.raw.length - 1);
          const cached = cache.getCacheItem('getPageInfo', pageId);
          if (!isNull(cached)) {
            requests.push(
              new Promise((resolve) => {
                resolve(cached);
              }),
            );
          } else {
            config = {
              url: `${constructBaseSocialAPIURL()}/pageinfo`,
              method: 'GET',
              timeout: API_TIMEOUTS.S,
              headers: getClientServiceRequestHeaders(),
              params: {
                pageId,
              },
            };
            requests.push(axios(config));
          }
          if (isUndefined(requestMap.facebookMentions)) {
            requestMap.facebookMentions = {};
          }
          requestMap.facebookMentions[pageId] = requests.length - 1;
        });
      }
    }

    /*
     * 2.1.3. LinkedIn tags
     */

    if (apiTypeId === API_TYPE_IDS.LINKEDIN) {
      const tags = MediaItem.extractHashtagsAndMentions({ mediaItem });
      const mentions = tags[TAG_TYPES.MENTION][accountAPIId];
      if (mentions.length !== 0) {
        mediaItem = MediaItem.setMentions({
          mediaItem,
          fieldValue: Immutable(mentions),
        });
      }
      const hashtags = tags[TAG_TYPES.HASHTAG][accountAPIId];
      if (hashtags.length !== 0) {
        mediaItem = MediaItem.setHashtags({
          mediaItem,
          fieldValue: Immutable(hashtags),
        });
      }
    }

    /*
     * 2.1.4. Image overlay
     */

    const overlayURL = MediaItem.getImageOverlayURL({ mediaItem });
    if (overlayURL) {
      requests.push(
        addImageOverlay({
          imageURL: MediaItem.getImageURLs({ mediaItem })[0],
          overlayURL,
        }),
      );
      requestMap.imageOverlay = requests.length - 1;
    }

    /**
     * 2.2. Responses
     */

    responses = await Promise.all(requests);

    /**
     * 2.2.1. Share message content - currently needed for the rest of the code to function
     */

    if (getMessages) {
      const shareDataSourcesSettings = getSetting({
        propertyId: getPropertyIdForAccountAPIId({ accountAPIId }),
        settingTypeId: ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES,
        accountAPIId,
      });
      const contentTypeId =
        shareDataSourcesSettings?.dataJSON?.messageSource === 'OPTIMAL_MESSAGE'
          ? CONTENT_TYPE_IDS.OPTIMAL
          : CONTENT_TYPE_IDS.DATASOURCE_DERIVED;
      mediaItem = MediaItem.setMessages({
        mediaItem,
        fieldValue: {
          selectedContentTypeId: contentTypeId,
          orderedShareContent: [
            {
              contentTypeId,
              value: MediaItem.getMessage({ mediaItem }),
              sequence: 0,
            },
          ],
          selectedShareContent: {
            contentTypeId,
            value: MediaItem.getMessage({ mediaItem }),
            sequence: 0,
          },
        },
      });
    }

    /**
     * 2.2.2. Sponsor details
     */

    if (gotSponsor) {
      response = responses[requestMap.sponsorDetails];
      cache.setCacheItem('getPageInfo', response.data.pageId, {
        data: response.data,
      });
      mediaItem = MediaItem.setSponsorName({
        mediaItem,
        fieldValue: response.data.name,
      });
    }

    /*
     * 2.2.3. Facebook mentions
     */

    if (gotPageInfo) {
      Object.keys(requestMap.facebookMentions).forEach((pageId) => {
        const result = responses[requestMap.facebookMentions[pageId]].data;
        cache.setCacheItem('getPageInfo', result.pageId, { data: result });
        const mentions = Immutable.asMutable(
          MediaItem.getMentions({ mediaItem }),
        );
        mentions.push({
          raw: `@[${result.pageId}]`,
          clean: result.name,
          more: result.pageURL,
        });
        mediaItem = MediaItem.setMentions({
          mediaItem,
          fieldValue: Immutable(mentions),
        });
      });
    }

    /*
     * 2.2.4. Image overlays
     */

    if (overlayURL) {
      const resultURL = responses[requestMap.imageOverlay];
      mediaItem = MediaItem.setImageOverlayResult({
        mediaItem,
        fieldValue: resultURL,
      });
    }

    /*
     * 2.3. Resolve URL
     */

    if (resolveURL && !isNullOrUndefined(unshortenedShareURL)) {
      const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
      const resolvedURL = await getPropertiesResolveURL({
        propertyId,
        url: unshortenedShareURL,
      });
      mediaItem = MediaItem.setIsURLResolved({
        mediaItem,
        fieldValue: resolvedURL.isResolved,
      });
      mediaItem = MediaItem.setUnshortenedShareURL({
        mediaItem,
        fieldValue: resolvedURL.resolvedURL,
      });
    }

    /*
     * Finish
     */

    metrics.end('getMediaItem', guid);
    logger.debug('getMediaItem', { accountAPIId, state, encodedMediaId });
    return mediaItem;
  } catch (error) {
    metrics.fail('getMediaItem', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getMediaItem',
      args: {
        accountAPIId,
        apiTypeId,
        state,
        encodedMediaId,
        getMessages,
        getPageInfo,
        getSponsor,
        getTags,
      },
    });
    throw apiError;
  }
}
