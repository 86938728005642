import PubSub from 'pubsub-js';
import Immutable from 'seamless-immutable';

import * as API from 'api/api';
import {
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
} from 'common/accountAPIs';
import {
  CONTENT_TYPES,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SHARE_ORIGINS,
  SHARE_TIME_TYPES,
  SUGGESTION_TYPES,
} from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { addErrorNotification } from 'common/notifications';
import * as social from 'common/social';
import { generateGuid } from 'common/string';
import * as tracker from 'common/tracker';
import { getFileSuffix } from 'common/url';
import { immutableClone, isNull, isNullOrUndefined } from 'common/utility';
import { getMediaTypeName, getNetworkAndPageName } from 'helpers/tracking';
import {
  REQUEST_ADD_COMPOSE_BOX_ITEM,
  REQUEST_DELETE_COMPOSE_BOX_ITEM,
  REQUEST_DUPLICATE_COMPOSE_BOX_ITEM,
  REQUEST_FIND_COMPOSE_BOX_ITEM,
  REQUEST_UPDATE_COMPOSE_BOX_ITEM,
  RESPONSE_ADD_COMPOSE_BOX_ITEM,
  RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM,
  RESPONSE_FIND_COMPOSE_BOX_ITEM,
  RESPONSE_UPDATE_COMPOSE_BOX_ITEM,
} from 'pubsub/topics';
import { FixTypeLater, SocialChannel } from 'types';

const addComposeBoxItem = ({ mediaItem }: { mediaItem: FixTypeLater }) => {
  return new Promise<string>((resolve) => {
    const requestId = generateGuid();
    const subscription = PubSub.subscribe(
      `${RESPONSE_ADD_COMPOSE_BOX_ITEM}.${requestId}`,
      (message, data) => {
        PubSub.unsubscribe(subscription);
        logger.info(
          `PubSub: receive ${RESPONSE_ADD_COMPOSE_BOX_ITEM}.${requestId} in common/compose/steps.addComposeBoxItem`,
        );
        resolve(data);
      },
    );
    PubSub.publish(REQUEST_ADD_COMPOSE_BOX_ITEM, { requestId, mediaItem });
  });
};

const duplicateComposeBoxItem = ({
  mediaItem,
}: {
  mediaItem: FixTypeLater;
}) => {
  return new Promise<string>((resolve) => {
    const requestId = generateGuid();
    const subscription = PubSub.subscribe(
      `${RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM}.${requestId}`,
      (message, data) => {
        PubSub.unsubscribe(subscription);
        logger.info(
          `PubSub: receive ${RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM}.${requestId} in common/compose/steps.duplicateComposeBoxItem`,
        );
        resolve(data);
      },
    );
    PubSub.publish(REQUEST_DUPLICATE_COMPOSE_BOX_ITEM, {
      requestId,
      mediaItem,
    });
  });
};

const addShortLink = async ({
  mediaItem,
  reasonCode,
  overrideURLParameters = false,
}: {
  mediaItem: FixTypeLater;
  reasonCode: string;
  overrideURLParameters?: boolean;
}) => {
  let updatedItem = immutableClone(mediaItem);
  updatedItem = await MediaItem.addLink({
    mediaItem: updatedItem,
    reasonCode,
    overrideURLParameters,
  });

  return updatedItem;
};

const createMediaItem = ({
  accountAPIId,
  postType,
  isLoading,
  socialChannel,
}: {
  accountAPIId: number;
  postType: string;
  isLoading: boolean;
  socialChannel?: SocialChannel;
}) => {
  let mediaItem = MediaItem.initialise({
    accountAPIId,
    backend: {
      maxShareTime: null,
      mediaId: 'NEW',
      minShareTime: null,
      postType,
      socialChannel,
    },
  });

  mediaItem = MediaItem.setIsLoading({
    mediaItem,
    fieldValue: isLoading,
  });

  return mediaItem;
};

const deleteComposeBoxItem = ({ guid }: { guid: string }) => {
  PubSub.publish(REQUEST_DELETE_COMPOSE_BOX_ITEM, { guid });
};

const findComposeBoxItem = ({ guid }: { guid: string }) => {
  return new Promise<FixTypeLater | null>((resolve) => {
    const subscription = PubSub.subscribe(
      `${RESPONSE_FIND_COMPOSE_BOX_ITEM}.${guid}`,
      (message, data) => {
        PubSub.unsubscribe(subscription);
        if (!isNull(data)) {
          resolve(Immutable(data.mediaItem));
        }
        resolve(null); // Media item not found
      },
    );
    PubSub.publish(REQUEST_FIND_COMPOSE_BOX_ITEM, { guid });
  });
};

const getMediaItem = async ({
  accountAPIId,
  state,
  mediaId,
  postType,
  resolveURL = true,
}: {
  accountAPIId: number;
  state: string;
  mediaId: string;
  postType: string;
  resolveURL?: boolean;
}) => {
  const apiTypeId = getAPITypeId({ accountAPIId });
  const isLinkPost = postType === POST_TYPES.LINK;
  const mediaItemArgs = {
    accountAPIId,
    apiTypeId: getAPITypeId({ accountAPIId }),
    state,
    mediaId,
    getMessages: isLinkPost && social.hasShareMessages({ apiTypeId }),
    getPageInfo: social.hasMentionsLookups({ apiTypeId }),
    getSponsor: social.hasSponsoredPosts({ apiTypeId }),
    getTags: false,
    resolveURL,
  };

  const mediaItem = await API.getMediaItem(mediaItemArgs);
  return mediaItem;
};

const prepareForReshare = ({
  baseItem,
  sharedItem,
}: {
  baseItem?: FixTypeLater;
  sharedItem: FixTypeLater;
}) => {
  let reshareItem = immutableClone(sharedItem);
  const postType = MediaItem.getPostType({ mediaItem: reshareItem });
  const isLinkPost = postType === POST_TYPES.LINK;

  // Remove AB results
  reshareItem = MediaItem.removeABResults({ mediaItem: reshareItem });

  // Set media id for reshare item
  if (isLinkPost) {
    // For link posts, use the media id of the base item
    reshareItem = MediaItem.setMediaId({
      mediaItem: reshareItem,
      fieldValue: MediaItem.getMediaId({ mediaItem: baseItem }),
    });
  } else {
    // For non-link posts, set the media id to RESHARE
    reshareItem = MediaItem.setMediaId({
      mediaItem: reshareItem,
      fieldValue: 'RESHARE',
    });
  }

  // Reset share time to OPTIMAL
  reshareItem = MediaItem.setShareTime({
    mediaItem: reshareItem,
    fieldValue: {
      type: SHARE_TIME_TYPES.OPTIMAL,
    },
  });

  // Reset state to NEW
  reshareItem = MediaItem.setState({
    mediaItem: reshareItem,
    fieldValue: MEDIA_ITEM_STATES.NEW,
  });

  // Reset suggestion type to MANUAL_SOCIAL_SHARE
  reshareItem = MediaItem.setSuggestionTypeId({
    mediaItem: reshareItem,
    fieldValue: SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE,
  });

  // Add share messages from base item
  if (isLinkPost) {
    const shareMessages = MediaItem.getMessages({ mediaItem: baseItem });
    if (!isNullOrUndefined(shareMessages)) {
      reshareItem = MediaItem.setMessages({
        mediaItem: reshareItem,
        fieldValue: shareMessages,
      });

      // Use the reshare message as the current message
      const resharedMessage = MediaItem.getMessage({ mediaItem: sharedItem });
      if (
        MediaItem.getMessage({ mediaItem: reshareItem }) !== resharedMessage
      ) {
        reshareItem = MediaItem.setMessage({
          mediaItem: reshareItem,
          fieldValue: resharedMessage,
        });
      }
    }
  }

  return reshareItem;
};

const resetShareTimeOptimal = ({ mediaItem }: { mediaItem: FixTypeLater }) => {
  let updatedItem = immutableClone(mediaItem);
  const apiTypeId = getAPITypeId({
    accountAPIId: MediaItem.getAccountAPIId({ mediaItem: updatedItem }),
  });
  const shareTime = MediaItem.getShareTime({ mediaItem: updatedItem });
  if (
    shareTime.type === SHARE_TIME_TYPES.NOW &&
    !social.canShareNow({ apiTypeId })
  ) {
    updatedItem = MediaItem.setShareTime({
      mediaItem: updatedItem,
      fieldValue: {
        type: SHARE_TIME_TYPES.OPTIMAL,
      },
    });
  }

  return updatedItem;
};

const setShareOrigin = ({
  mediaItem,
  shareOrigin,
}: {
  mediaItem: FixTypeLater;
  shareOrigin: string;
}) => {
  let updatedItem = immutableClone(mediaItem);
  updatedItem = MediaItem.setTrackingDetails({
    mediaItem: updatedItem,
    fieldValue: {
      Origin: shareOrigin,
    },
    allowOverride: true,
  });

  return updatedItem;
};

const setShareTimeOptimal = ({ mediaItem }: { mediaItem: FixTypeLater }) => {
  let updatedItem = immutableClone(mediaItem);
  updatedItem = MediaItem.setShareTime({
    mediaItem: updatedItem,
    fieldValue: {
      type: SHARE_TIME_TYPES.OPTIMAL,
    },
  });

  return updatedItem;
};

const showErrorNotification = ({ error }: { error: unknown }) => {
  let message = getErrorMessage(error);
  if (message === '') {
    message = 'An error occurred';
  }
  addErrorNotification(message);
};

const storeOriginalMessages = ({ mediaItem }: { mediaItem: FixTypeLater }) => {
  let updatedItem = immutableClone(mediaItem);
  const shareMessages = MediaItem.getMessages({
    mediaItem,
  });
  if (!isNullOrUndefined(shareMessages)) {
    updatedItem = MediaItem.setOriginalMessages({
      mediaItem: updatedItem,
      fieldValue: shareMessages,
    });
  }

  return updatedItem;
};

const trackOpenComposeBox = ({
  mediaItem,
  shareOrigin,
}: {
  mediaItem: FixTypeLater;
  shareOrigin: string;
}) => {
  const postType = MediaItem.getPostType({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const accountAPIId = getCurrentAccountAPIId();
  const apiTypeId = getAPITypeId({ accountAPIId });
  const hashtags = MediaItem.getHashtags({ mediaItem }).map((tag) => tag.clean);
  const mentions = MediaItem.getMentions({ mediaItem }).map((tag) => tag.clean);
  const imageURLs = MediaItem.getImageURLs({ mediaItem });
  const shareContentTypeId = MediaItem.getCurrentShareContentTypeId({
    mediaItem,
    accountAPIId,
  });
  const fileType = imageURLs.length > 0 ? getFileSuffix(imageURLs[0]) : '';
  const contentType =
    CONTENT_TYPES[shareContentTypeId as keyof typeof CONTENT_TYPES];
  const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
  let articleURL = trackingDetails?.articleURL ?? 'undefined';
  if (postType === POST_TYPES.LINK) {
    articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  }

  const trackingParams: Record<string, any> = {
    Origin: shareOrigin,
    'Social Channel': social.getTrackingSocialChannel({
      apiTypeId,
      mediaItem,
    }),
    'Social Network': social.getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Media Type': getMediaTypeName({ mediaItem }),
    'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
    'Account API Id': accountAPIId,
    'File Type': fileType,
    'Article URL': articleURL,
    'Suggested Hashtags': hashtags,
    'Suggested Mentions': mentions,
    'Message Type': shareContentTypeId ? contentType.description : 'undefined',
  };
  if (shareOrigin === SHARE_ORIGINS.NEW_SHARE) {
    trackingParams['New Share Type'] = social.getPostTypeLabel({
      apiTypeId,
      postType,
      socialChannel,
    });
  }
  tracker.track({
    eventName: 'Open Compose Box',
    trackingParams,
  });
};

const updateComposeBoxItem = ({
  guid,
  mediaItem,
}: {
  guid: string;
  mediaItem: FixTypeLater;
}) => {
  const updatedItem = MediaItem.setIsLoading({
    mediaItem,
    fieldValue: false,
  });

  return new Promise<boolean>((resolve) => {
    const subscription = PubSub.subscribe(
      `${RESPONSE_UPDATE_COMPOSE_BOX_ITEM}.${guid}`,
      (message, data) => {
        PubSub.unsubscribe(subscription);
        logger.info(
          `PubSub: receive ${RESPONSE_UPDATE_COMPOSE_BOX_ITEM}.${guid} in common/compose/steps.updateComposeBoxItem`,
        );
        resolve(data);
      },
    );
    PubSub.publish(REQUEST_UPDATE_COMPOSE_BOX_ITEM, {
      guid,
      mediaItem: updatedItem,
    });
  });
};

export {
  addComposeBoxItem,
  addShortLink,
  createMediaItem,
  deleteComposeBoxItem,
  duplicateComposeBoxItem,
  findComposeBoxItem,
  getMediaItem,
  prepareForReshare,
  resetShareTimeOptimal,
  setShareOrigin,
  setShareTimeOptimal,
  showErrorNotification,
  storeOriginalMessages,
  trackOpenComposeBox,
  updateComposeBoxItem,
};
