interface MoreOptionProps {
  width?: string | number;
  height?: string | number;
}
const MoreOptions = ({ width = 16, height = 16 }: MoreOptionProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9077 8.00006C10.9077 7.31208 11.4658 6.75391 12.1538 6.75391C12.8418 6.75391 13.4 7.31208 13.4 8.00006C13.4 8.68804 12.8418 9.24621 12.1538 9.24621C11.4658 9.24621 10.9077 8.68804 10.9077 8.00006ZM6.75382 8.00006C6.75382 7.31208 7.312 6.75391 7.99998 6.75391C8.68796 6.75391 9.24613 7.31208 9.24613 8.00006C9.24613 8.68804 8.68796 9.24621 7.99998 9.24621C7.312 9.24621 6.75382 8.68804 6.75382 8.00006ZM5.09228 8.00006C5.09228 8.68804 4.53437 9.24621 3.84613 9.24621C3.15789 9.24621 2.59998 8.68804 2.59998 8.00006C2.59998 7.31208 3.15789 6.75391 3.84613 6.75391C4.53437 6.75391 5.09228 7.31208 5.09228 8.00006Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoreOptions;
