import type { MouseEventHandler } from 'react';
import { memo } from 'react';

import { arePropsEqual } from 'common/utility';

import { REACT_PREVENT_RENDER } from 'common/constants';

/*
 * Article error
 */

interface SettingsErrorProps {
  errorMessage: string;
  eventHandlers: {
    handleErrorClose: MouseEventHandler<HTMLButtonElement>;
  };
}

const SettingsError = (props: SettingsErrorProps) => {
  if (props.errorMessage === '') {
    return REACT_PREVENT_RENDER;
  }

  return (
    <div className="error_notification notification mb-3 w-100 pr-3 text-danger">
      <img className="icon-error" src="/img/icons/ic-error-red.svg" alt="" />
      <span>{props.errorMessage}</span>
      <button
        type="button"
        className="close text-400 ft-20"
        aria-label="Close"
        onClick={props.eventHandlers.handleErrorClose}
        style={{ position: 'absolute', top: '-3px', right: 0 }}
      >
        ×
      </button>
    </div>
  );
};

export default memo(SettingsError, arePropsEqual);
