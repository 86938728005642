import { ChevronButton, Dropdown } from '@ebx-ui/ebx-ui-component-library-sdk';
import { TIME_RANGE_OPTIONS } from 'common/constants/socialListening';

const TimeRangeFilter = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (val: number) => void;
}) => {
  return (
    <Dropdown>
      <Dropdown.Button as={ChevronButton} variant="chill">
        {TIME_RANGE_OPTIONS.find((item) => item.duration === value)?.label ??
          TIME_RANGE_OPTIONS[0].label}
      </Dropdown.Button>
      <Dropdown.List>
        {TIME_RANGE_OPTIONS.map((option) => (
          <Dropdown.Item
            key={option.duration}
            onClick={() => onChange(option.duration)}
            isSelected={value === option.duration}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
};

export default TimeRangeFilter;
