/**
 * postMedia - create / update media item
 *
 * @param {long} accountAPIId  - the account to which the item belongs
 * @param {string} state       - the existing state of the media item
 * @param {object} mediaItem   - the media item details, including message content
 * @return {object}                 - the created / updated media item
 */

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import {
  API_RESPONSE_IMPERSONATION_ERROR_MESSAGES,
  API_TYPE_IDS,
  MEDIA_ITEM_STATES,
} from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getURNName } from 'common/social';
import { convertToSocialPageURN, encodeMediaURN } from 'common/urn';
import { isDefined } from 'common/utility';
import { FixTypeLater } from 'types';

export default async function postMedia({
  accountAPIId,
  state,
  mediaItem,
  errorOnDuplicate = true,
}: {
  accountAPIId: number;
  state: string;
  mediaItem: FixTypeLater;
  errorOnDuplicate: boolean;
}) {
  const guid = metrics.start('postMedia');

  let timeout: number = API_TIMEOUTS.L;
  if (
    mediaItem.state === MEDIA_ITEM_STATES.SHARED &&
    isDefined(mediaItem.videoURL) &&
    mediaItem.videoURL !== '' &&
    getAPITypeId({ accountAPIId }) === API_TYPE_IDS.TWITTER
  ) {
    timeout = API_TIMEOUTS.XXL;
  }

  const typeId = getAPITypeId({ accountAPIId });
  const socialNetworkType = getURNName({ apiTypeId: typeId });
  const socialPageURN = convertToSocialPageURN(socialNetworkType, accountAPIId);

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${socialPageURN}/media/${state}`,
    method: 'POST',
    timeout,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    params: {
      errorOnDuplicate,
    },
    data: JSON.stringify(mediaItem),
  };

  const modifiedMediaItem = { ...mediaItem };
  if (
    isDefined(modifiedMediaItem.mediaId) &&
    modifiedMediaItem.mediaId !== 'NEW' &&
    modifiedMediaItem.mediaId !== 'RESHARE'
  ) {
    const encodedMediaId = encodeMediaURN(modifiedMediaItem.mediaId);
    config.url += `/${encodedMediaId}`;
  }
  logger.info(
    `API:postMedia /social/api/${socialPageURN}/media/${state} ${modifiedMediaItem.mediaId}`,
  );

  try {
    const response = await axios(config);
    metrics.end('postMedia', guid);
    logger.track({
      event: 'api/postMedia success',
      properties: {
        accountAPIId,
        mediaItem: config.data,
        params: config.params,
        url: config.url,
      },
    });
    return { mediaId: response.data.mediaIds[0] };
  } catch (error: any) {
    metrics.fail('postMedia', guid);

    // If the error is a 403, the page has likely been disconnected.
    if (
      error?.response?.status === 403 &&
      (!error?.response?.data?.error?.message ||
        !API_RESPONSE_IMPERSONATION_ERROR_MESSAGES.includes(
          error.response.data.error.message,
        ))
    ) {
      return { failedMsg: 'The social page has been disconnected.' };
    }

    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postMedia',
      args: {
        accountAPIId,
        state,
        mediaItem,
      },
    });
    throw apiError;
  }
}
