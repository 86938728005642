/* eslint react-hooks/exhaustive-deps: "off" */

import $ from 'jquery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getDomain, isValidURL } from 'common/url';
import AddBody from 'components/settings/property/domainswitching/AddBody';
import AddFooter from 'components/settings/property/domainswitching/AddFooter';
import AddHeader from 'components/settings/property/domainswitching/AddHeader';

const Component = (props) => {
  const [domain, setDomain] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { isSaving, eventHandlers } = props;

  useEffect(() => {
    handleFocus();
  }, []);

  const handleAddDomain = () => {
    if (!isValidURL(domain, false)) {
      setErrorMessage('This URL is invalid');
      handleFocus();
      return;
    }

    let isDuplicate = false;
    props.domainsToSwitch.forEach((domainToSwitch) => {
      if (domainToSwitch === getDomain(domain)) {
        isDuplicate = true;
      }
    });
    if (isDuplicate) {
      setErrorMessage('This URL already exists');
      handleFocus();
      return;
    }

    eventHandlers.handleAddDomain(domain);
  };

  const handleChange = (event) => {
    setDomain(event.target.value);
    setErrorMessage('');
  };

  const handleFocus = () => {
    if (typeof $ !== 'undefined') {
      $('[data-cy-input="domain"]').focus();
    }
  };

  return (
    <div className="ebx-modal-outer">
      <div className="select-pages modal-dialog modal-sm">
        <div className="modal-content">
          <AddHeader
            eventHandlers={{ handleAddCancel: eventHandlers.handleAddCancel }}
          />
          <AddBody
            domain={domain}
            errorMessage={errorMessage}
            isSaving={isSaving}
            eventHandlers={{
              handleAddCancel: eventHandlers.handleAddCancel,
              handleAddDomain,
              handleChange,
            }}
          />
          <AddFooter
            isSaving={isSaving}
            eventHandlers={{
              handleAddCancel: eventHandlers.handleAddCancel,
              handleAddDomain,
            }}
          />
        </div>
      </div>
    </div>
  );
};

Component.propTypes = {
  domainsToSwitch: PropTypes.array.isRequired,
  isSaving: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleAddCancel: PropTypes.func.isRequired,
    handleAddDomain: PropTypes.func.isRequired,
  }).isRequired,
};

export default Component;
