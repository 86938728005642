import {
  Circle,
  Image,
  InfoIcon,
  SidebarItem,
  Tag,
  TagProps,
  Text,
  Tooltip,
  WarningIcon,
  WrenchIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { MouseEventHandler, ReactNode, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  getAPITypeId,
  getCurrentPropertyId,
  getPropertyPermission,
} from 'common/accountAPIs';
import {
  API_STATES,
  FRONTEND_METRICS,
  PERMISSION_TYPES,
  SOCIAL_PLATFORM_DEFAULT_ICONS,
} from 'common/constants';
import * as metrics from 'common/metrics';
import { getReconnectSocialPageLink } from 'common/settings';
import {
  getSocialNetworkDefaultPageIcon,
  getSocialNetworkName,
} from 'common/social';
import * as tracker from 'common/tracker';
import { isDefined } from 'common/utility';
import SocialIcon from 'components/misc/SocialIcon';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import type { FixTypeLater } from 'types';

interface SidebarPageProps {
  accountAPI: FixTypeLater;
  isDisabled: boolean;
  isSelected: boolean;
  permissionTypeId?: number | null;
  eventHandlers: {
    handleAccountChange: (accountAPIId: number) => void;
  };
}

const SidebarPage = ({
  accountAPI,
  isDisabled,
  isSelected,
  permissionTypeId = null,
  eventHandlers,
}: SidebarPageProps) => {
  const isUnderSetup = accountAPI.apiStateId === API_STATES.UNDER_SETUP;
  const icon = accountAPI.apiPostImage !== '' ? accountAPI.apiPostImage : '';
  const isActive = accountAPI.apiStateId === API_STATES.ACTIVE;
  const name = accountAPI.apiPostName !== '' ? accountAPI.apiPostName : '';
  const hasErrors =
    isDefined(accountAPI.notifications) && accountAPI.notifications.length > 0;
  const queuedItems = accountAPI.queuedItems;
  const failedItems = accountAPI.failedItems;

  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const propertyId = getCurrentPropertyId({ globalInfo });
  const propertyPermission = getPropertyPermission({
    propertyId,
    globalInfo,
  });
  const { pathname } = useLocation();

  const apiTypeId = getAPITypeId({ accountAPIId: accountAPI.accountAPIId });
  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

  const isDisabledLink =
    isDisabled ||
    hasErrors ||
    isUnderSetup ||
    (!isActive && permissionTypeId !== PERMISSION_TYPES.ADMIN);

  const logo =
    icon &&
    isActive &&
    icon !== getSocialNetworkDefaultPageIcon({ apiTypeId }) &&
    !SOCIAL_PLATFORM_DEFAULT_ICONS.includes(icon) ? (
      <Image
        borderRadius="full"
        h="6"
        w="6"
        src={icon}
        border="1px"
        borderColor="gray.300"
      />
    ) : (
      <Circle bg="gray.200" border="1px" borderColor="gray.300" size={6}>
        <SocialIcon apiTypeId={apiTypeId} color="gray.500" />
      </Circle>
    );

  const underSetup = (
    <Tooltip
      label="This page is under set up"
      shouldWrapChildren
      placement="top-end"
    >
      <SidebarTag color="gray">
        <WrenchIcon size={5} data-cy-id="wrenchIcon" />
      </SidebarTag>
    </Tooltip>
  );

  const reconnectLink = isAdmin ? (
    <Tooltip
      label="Page disconnected, click to reconnect"
      shouldWrapChildren
      placement="top-end"
    >
      <Text
        as="span"
        tabIndex={0}
        size="sm"
        color="primary.600"
        fontWeight="medium"
        _hover={{ color: 'gray.600' }}
      >
        Reconnect
      </Text>
    </Tooltip>
  ) : (
    <Tooltip
      label="Only administrators can reconnect pages. Please ask a team member with admin privileges to reconnect this page."
      shouldWrapChildren
      placement="top"
    >
      <InfoIcon color="gray.600" />
    </Tooltip>
  );

  const failedShares = <WarningIcon color="red.600" size={4} />;

  const queuedShares = (
    <SidebarTag color="gray" data-cy="sidebar-page:queued-shares">
      {queuedItems}
    </SidebarTag>
  );

  let to = `/share/${accountAPI.accountAPIId}`;
  if (isUnderSetup || hasErrors) {
    if (isAdmin) {
      to = getReconnectSocialPageLink({ accountAPI });
    } else {
      to = '#';
    }
  }

  let iconRight: ReactNode | undefined;
  if (isUnderSetup) iconRight = underSetup;
  if (hasErrors) iconRight = reconnectLink;
  if (failedItems) iconRight = failedShares;
  if (queuedItems) iconRight = queuedShares;

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    const accountAPIId = accountAPI.accountAPIId;
    eventHandlers.handleAccountChange(accountAPIId);
    if (
      document?.location?.pathname === '/share' ||
      document?.location?.pathname === '/'
    ) {
      metrics.mark(FRONTEND_METRICS.CHANGE_PAGE);
    }
  };

  const onItemClick = () => {
    if (hasErrors && isAdmin) {
      tracker.track({
        eventName: 'Reconnect Page',
        trackingParams: {
          'Social Network': getSocialNetworkName({
            apiTypeId,
          }),
          'Account API Id': accountAPI.accountAPIId,
          'Network - Social Page': getNetworkAndPageName({
            accountAPIId: accountAPI.accountAPIId,
          }),
          Location: 'Sidebar',
        },
      });
    }
  };

  const isCurrentPage = pathname === to || isSelected;

  const link = (
    <SidebarItem
      to={to}
      isActive={isSelected}
      isDisabled={isDisabledLink}
      data-cy="page"
      data-cy-attribute={`accountAPIId:${accountAPI.accountAPIId}`}
      onClick={onItemClick}
      {...(isSelected && { 'aria-current': 'page' })}
    >
      <SidebarItem.Link
        as={Link}
        data-cy-attribute={`isSelected:${isCurrentPage}`}
        data-cy-action="accountSelect"
        onClick={onLinkClick}
        {...(isAdmin ? { cursor: 'pointer' } : {})}
      >
        {logo}
        <SidebarItem.Text
          data-cy-id="accountName"
          data-cy-attribute={`accountAPIId:${accountAPI.accountAPIId}`}
        >
          {name}
        </SidebarItem.Text>
        {iconRight}
      </SidebarItem.Link>
    </SidebarItem>
  );

  return link;
};

const SidebarTag = (props: TagProps) => {
  return (
    <Tag
      ml={2}
      px={1}
      minH={0}
      h="1.25rem"
      flexShrink={0}
      justifyContent="center"
      {...props}
    />
  );
};

export default SidebarPage;
