import PropTypes from 'prop-types';

import { Flex, Image } from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  API_TYPE_IDS,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
} from 'common/constants';
import { isIGCabinetEnabled } from 'common/settings';
import { generateGuid } from 'common/string';
import {
  getImageThumbnail,
  getNewsFeedVideoThumbnail,
} from 'common/thumbnails';
import { isNullOrUndefined } from 'common/utility';
import Tooltip from 'components/misc/Tooltip';
import { addImagePrefix } from 'helpers/images';

const ItemImages = (props) => {
  const {
    imageURLs,
    itemState,
    apiTypeId,
    postType,
    socialChannel,
    suggestionTypeId,
    isNewVersion,
    isAlreadyQueued,
    isVideoURL,
  } = props;

  const isDeleted = itemState === MEDIA_ITEM_STATES.DELETED;
  const isDraft =
    (suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE ||
      suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_AB_SHARE) &&
    itemState !== MEDIA_ITEM_STATES.FAILED;

  let backgroundImage;
  let imageThumbnail;
  let videoThumbnail;
  if (isNullOrUndefined(imageURLs) || imageURLs.length === 0) {
    if (postType === POST_TYPES.VIDEO || isVideoURL) {
      videoThumbnail = getNewsFeedVideoThumbnail();
      backgroundImage = videoThumbnail;
    } else {
      imageThumbnail = getImageThumbnail({
        apiTypeId,
        postType,
        isNewsFeed: true,
      });
      backgroundImage = imageThumbnail;
    }
  } else {
    backgroundImage = addImagePrefix(imageURLs[0]);
  }
  const imageStyle = { backgroundImage: `url('${backgroundImage}')` };

  const guidDraft = generateGuid();
  const guidNewVersion = generateGuid();
  const isDouble = isDraft && isNewVersion;
  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;
  const isReels =
    (apiTypeId === API_TYPE_IDS.INSTAGRAM ||
      apiTypeId === API_TYPE_IDS.FACEBOOK) &&
    socialChannel === SOCIAL_CHANNELS.REEL;
  const isIGStories =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    !isIGCabinetEnabled();
  return (
    <div
      className={`article_imagery ${
        (isTikTok || isReels || isIGStories) && 'reel-draft'
      }`}
    >
      <div
        className={`article_image d-none d-md-block ${
          isDeleted ? 'deleted_greyed_out' : ''
        }`}
        style={imageStyle}
        data-cy="article-image"
      >
        {isVideoURL && (
          <Flex h="full" w="full" justifyContent="center" alignItems="center">
            <Image
              bottom={0}
              borderRadius="full"
              bg="rgba(235, 238, 245, 0.35)"
              p={1}
              h={6}
              w={6}
              src="/img/icons/video-icon.svg"
              alt=""
            />
          </Flex>
        )}
      </div>
      {isDraft && !isDeleted && !(isTikTok || isReels || isIGStories) && (
        <div
          className={`d-flex article-draft ${
            isDouble ? 'article-draft_double' : ''
          }`}
          data-tip
          data-for={guidDraft}
          data-iscapture="true"
        >
          <Tooltip
            id={guidDraft}
            placement="right"
            label="Post manually edited and saved as draft"
          >
            <>
              <img src="/img/icons/ic-edit.svg" alt="" />
              &nbsp; Draft
            </>
          </Tooltip>
        </div>
      )}
      {isNewVersion && !isDeleted && (
        <div
          className="article-shared d-flex"
          data-tip
          data-for={guidNewVersion}
          data-iscapture="true"
        >
          <Tooltip
            id={guidNewVersion}
            place="right"
            label="Updated version of a previously shared article"
          >
            <>
              <img src="/img/icons/articlestatus_updated_white.svg" alt="" />
              &nbsp; Shared
            </>
          </Tooltip>
        </div>
      )}
      {isAlreadyQueued && !isDeleted && (
        <div
          className="article-shared d-flex"
          data-tip
          data-for={guidNewVersion}
          data-iscapture="true"
        >
          <Tooltip
            id={guidNewVersion}
            place="right"
            label="This item is currently scheduled for this page"
          >
            <>
              <img src="/img/icons/articlestatus_updated_white.svg" alt="" />
              &nbsp; Queued
            </>
          </Tooltip>
        </div>
      )}
      {postType === POST_TYPES.VIDEO && backgroundImage !== videoThumbnail && (
        <div className="add_photo_icon">
          <img src="/img/icons/video-icon.svg" alt="" />
        </div>
      )}
      {isDeleted && <div className="article-draft status_deleted">Deleted</div>}
    </div>
  );
};

ItemImages.propTypes = {
  imageURLs: PropTypes.arrayOf(PropTypes.string),
  itemState: PropTypes.string.isRequired,
  apiTypeId: PropTypes.number.isRequired,
  postType: PropTypes.string.isRequired,
  socialChannel: PropTypes.string.isRequired,
  suggestionTypeId: PropTypes.number,
  isNewVersion: PropTypes.bool.isRequired,
  isAlreadyQueued: PropTypes.bool.isRequired,
  isVideoURL: PropTypes.bool.isRequired,
};

ItemImages.defaultProps = {
  imageURLs: [],
  suggestionTypeId: null,
};

export default ItemImages;
