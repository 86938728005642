import { Dropdown } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ANALYTICS_SETTINGS } from 'common/config';
import { TimePeriodFilter } from 'types';

import FilterButton from './FilterButton';

const TimeFrameFilter = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (val: number | string) => void;
}) => {
  return (
    <Dropdown>
      <FilterButton data-cy-select="timeframe" minW="140px">
        {ANALYTICS_SETTINGS.TIMEFRAME_OPTIONS.find(
          (item) => Number(item.duration) === Number(value),
        )?.label ?? 'Pick a date range'}
      </FilterButton>
      <Dropdown.List w="180px" maxH="450px">
        {ANALYTICS_SETTINGS.TIMEFRAME_OPTIONS.map(
          (option: TimePeriodFilter) => (
            <Dropdown.Item
              key={option.duration}
              onClick={() => onChange(option.duration)}
              isSelected={Number(value) === Number(option.duration)}
              data-cy-option={option.duration}
            >
              {option.label}
            </Dropdown.Item>
          ),
        )}
        <Dropdown.Item
          onClick={() => onChange('CUSTOM')}
          data-cy-option="CUSTOM"
        >
          Pick a date range
        </Dropdown.Item>
      </Dropdown.List>
    </Dropdown>
  );
};

export default TimeFrameFilter;
