/**
 * Created by Luke Parsons on 20/01/2017.
 */

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function postPropertiesDataExport({
  propertyURN,
  fromTime = 0,
  recipientEmail = null,
}: {
  propertyURN: string;
  fromTime?: number;
  recipientEmail?: string | null;
}) {
  const guid = metrics.start('postPropertiesDataExport');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/properties/${propertyURN}/dataexport`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      fromTime,
      recipientEmail,
    }),
  };

  try {
    await axios(config);
    metrics.end('postPropertiesDataExport', guid);
    logger.debug('postPropertiesDataExport', { propertyURN });
  } catch (error) {
    metrics.fail('postPropertiesDataExport', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postPropertiesDataExport',
      args: {
        propertyURN,
        fromTime,
        recipientEmail,
      },
    });
    throw apiError;
  }
}
