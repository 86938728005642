import { Suspense, useEffect, useState } from 'react';

import {
  PHOTOEDITOR_SDK_UPSCALE_FACTOR,
  PHOTOEDITOR_SDK_UPSCALE_MINIMUM,
} from 'common/config';
import { REACT_PREVENT_RENDER } from 'common/constants';
import { steppedScale } from 'common/image';
import PhotoEditorErrorBoundaryFallback from 'components/compose/imageV2/PhotoEditorErrorBoundaryFallback';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import { useImageContext } from 'context/ComposeBoxImageContext';
import { lazyWithRetries } from 'helpers/lazyLoad';
import { IMAGE_FILE_TYPES } from './constants';

const PhotoEditor5 = lazyWithRetries(
  () => import('components/compose/imageV2/PhotoEditor5'),
);

/**
 * Functional component for ImageEdit
 * Renders PhotoEditor5 child component
 * Scales the image and passes it as a prop to PhotoEditor5 component to improve editing quality
 */
function ImageEdit() {
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  const { src: imageContextSrc, fileType, imageProps } = useImageContext();

  // If it's a video file, use the poster image as the editor source
  const src =
    fileType === IMAGE_FILE_TYPES.VIDEO ? imageProps.poster : imageContextSrc;

  useEffect(() => {
    const originalImage = new Image();
    const resizedImage = new Image();
    originalImage.addEventListener('load', () => {
      const { width, height } = originalImage;
      if (width >= height && width < PHOTOEDITOR_SDK_UPSCALE_MINIMUM) {
        resizedImage.src = steppedScale(
          originalImage,
          PHOTOEDITOR_SDK_UPSCALE_MINIMUM,
          PHOTOEDITOR_SDK_UPSCALE_FACTOR,
        );
      } else if (height < PHOTOEDITOR_SDK_UPSCALE_MINIMUM) {
        resizedImage.src = steppedScale(
          originalImage,
          PHOTOEDITOR_SDK_UPSCALE_MINIMUM * (width / height),
          PHOTOEDITOR_SDK_UPSCALE_FACTOR,
        );
      } else {
        resizedImage.src = `${originalImage.src}?noCache=2`;
      }
    });
    resizedImage.addEventListener('load', () => {
      setImage(resizedImage);
    });
    originalImage.crossOrigin = 'anonymous';
    // Using make-shift cache-busting technique to address chromium bug
    // See https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
    // JIRA: https://echobox.atlassian.net/browse/SL-3994
    originalImage.src = `${src}?noCache=1`;
    resizedImage.crossOrigin = 'anonymous';
  }, [src]);

  if (!image) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <ErrorBoundary fallback={<PhotoEditorErrorBoundaryFallback />}>
      <Suspense fallback={REACT_PREVENT_RENDER}>
        <PhotoEditor5 image={image} />
      </Suspense>
    </ErrorBoundary>
  );
}

export default ImageEdit;
