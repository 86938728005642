import { Thead as BaseThead } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ReactNode } from 'react';

interface TheadProps {
  children: ReactNode;
}
const Thead = (props: TheadProps) => {
  return <BaseThead bg="gray.100" boxShadow="borderBottom" {...props} />;
};

export default Thead;
