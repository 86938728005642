import {
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { POST_TYPES } from 'common/constants';
import { getPlaceholderText } from 'common/socialV2';
import { useImageContext } from 'context/ComposeBoxImageContext';
import { APITypeId, PostType, SocialChannel } from 'types';

interface PlaceholderProps {
  apiTypeId: APITypeId;
  postType: PostType;
  socialChannel: SocialChannel;
}
const Placeholder = ({
  apiTypeId,
  postType,
  socialChannel,
}: PlaceholderProps) => {
  const { isVertical, placeholderPxHeight } = useImageContext();

  const placeholderText = getPlaceholderText({
    apiTypeId,
    postType,
    socialChannel,
  });

  return (
    <Flex
      h={placeholderPxHeight}
      w="full"
      justifyContent="center"
      alignItems="center"
      bg="gray.100"
      borderBottomRadius="3px"
      backgroundPosition="center"
      backgroundImage={
        isVertical
          ? `/img/placeholders/upload-placeholder-vertical.svg`
          : `/img/placeholders/upload-placeholder.svg`
      }
    >
      <Flex justifyContent="center" gap={4} flexDir="column" h="full">
        <Heading variant="h5">
          Drag and drop {postType === POST_TYPES.VIDEO ? 'a video' : 'an image'}
        </Heading>
        {placeholderText.length > 0 ? (
          <Text ml={2} textAlign="start" size="xs" color="text.secondary">
            <UnorderedList spacing={1}>
              {placeholderText.map((text) => (
                <ListItem key={text}>{text}</ListItem>
              ))}
            </UnorderedList>
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default Placeholder;
