import {
  Button,
  Heading,
  SocialTwitterIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useNavigate } from 'react-router-dom';

import {
  getCurrentPropertyId,
  getPropertyPermission,
} from 'common/accountAPIs';
import { PERMISSION_TYPES } from 'common/constants';
import { getReconnectSocialPageLink } from 'common/settings';
import { useHealthMenuActions } from 'state/healthMenu';
import { SocialAPI } from 'types';
import HealthMenuItem from './HealthMenuItem';

const HealthMenuTwitterApp = ({
  accountAPI,
}: {
  /**
   * An account API requiring a Twitter App connection.
   */
  accountAPI: SocialAPI;
}) => {
  const navigate = useNavigate();
  const { closeHealthMenu } = useHealthMenuActions();

  const propertyPermission = getPropertyPermission({
    propertyId: getCurrentPropertyId(),
  });
  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

  const handleReconnect = () => {
    const reconnectLink = getReconnectSocialPageLink({ accountAPI });
    closeHealthMenu();
    navigate(reconnectLink);
  };

  return (
    <HealthMenuItem>
      <HealthMenuItem.Header>
        <SocialTwitterIcon size={5} />
        <Heading variant="h5">Twitter App</Heading>
      </HealthMenuItem.Header>
      <HealthMenuItem.Body>
        <Text size="sm">
          Twitter app disconnected. The connection is required for sharing to
          Twitter.
        </Text>
        {isAdmin && (
          <Button variant="danger" onClick={handleReconnect}>
            Reconnect App
          </Button>
        )}
      </HealthMenuItem.Body>
    </HealthMenuItem>
  );
};

export default HealthMenuTwitterApp;
