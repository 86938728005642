import { useState } from 'react';

import isEqual from 'fast-deep-equal';

import { Flex, Text, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as accountAPIs from 'common/accountAPIs';
import { DATA_SOURCE_DEFAULTS } from 'common/constants';
import { IMAGE_SOURCES, MESSAGE_SOURCES } from 'common/enums';
import * as settings from 'common/settings';
import * as social from 'common/social';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';
import DataSourceDropdown from 'components/settings/automation/newshares/datasources/DataSourceDropdown';
import AvailableSources from 'components/settings/common/AvailableSources';
import Preview from 'components/settings/common/Preview';
import {
  resetSettingsState,
  setSettingsChanged,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  AutofeedSettings,
  DataSourceSettings,
  DescriptionSources,
  FixTypeLater,
  ImageSources,
  MediaPreviewMetadata,
  MessageSources,
  TitleSources,
} from 'types';

function DataSources() {
  const toast = useToast();
  const { dispatch } = useSettingsContext();

  const { global } = useGlobalInfo();

  const { accountAPIId } = useAutomationSettingsContext();
  const apiTypeId = accountAPIs.getAPITypeId({ accountAPIId });

  const [metadata, setMetadata] = useState<MediaPreviewMetadata | null>(null);
  const overrideMessageSources = social.getAutofeedMessageSources({
    apiTypeId,
  });

  function getImageSource(imageSource: ImageSources | null | undefined) {
    if (
      social.hasDataSourceDefault({
        apiTypeId,
        field: 'image',
        reason: 'empty',
      }) &&
      utility.isNullOrUndefined(imageSource)
    ) {
      const imageSources = Object.keys(
        social.getSocialNetworkImageSources({ apiTypeId }),
      );
      return imageSources[DATA_SOURCE_DEFAULTS.IMAGE_SOURCE];
    }
    return imageSource;
  }

  function getDataSources() {
    const shareDataSource =
      settings.getAutofeedSetting({
        key: 'shareDataSource',
      }) ?? {};

    let messageSource = shareDataSource.messageSource;
    let titleSource = shareDataSource.titleSource;
    let descriptionSource = shareDataSource.descriptionSource;
    let imageSource = getImageSource(shareDataSource.imageSource);

    // If data sources are not defined, set defaults
    if (JSON.stringify(shareDataSource) === '{}') {
      const messageSources = Object.keys(
        social.getAutofeedMessageSources({ apiTypeId }),
      );
      messageSource = messageSources[
        DATA_SOURCE_DEFAULTS.MESSAGE_SOURCE
      ] as MessageSources;
      titleSource = messageSources[
        DATA_SOURCE_DEFAULTS.TITLE_SOURCE
      ] as TitleSources;
      descriptionSource = messageSources[
        DATA_SOURCE_DEFAULTS.DESCRIPTION_SOURCE
      ] as DescriptionSources;
      imageSource = messageSources[
        DATA_SOURCE_DEFAULTS.IMAGE_SOURCE
      ] as ImageSources;
    }

    return {
      messageSource: messageSource ?? MESSAGE_SOURCES.NULL,
      imageSource: imageSource ?? IMAGE_SOURCES.NULL,
      titleSource: titleSource ?? MESSAGE_SOURCES.NULL,
      descriptionSource: descriptionSource ?? MESSAGE_SOURCES.NULL,
    } as DataSourceSettings;
  }

  const dataSourceSettings = getDataSources();
  const [currentDataSourceSettings, setCurrentDataSourceSettings] =
    useState(dataSourceSettings);

  const handleSaveDataSources = async (newSettings: DataSourceSettings) => {
    // Remove 'NULL' values from data source settings object.
    const newDataSourceSettings = Object.fromEntries(
      Object.entries(newSettings).filter(
        ([, sourceValue]) => sourceValue !== 'NULL',
      ),
    );

    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    updatedSettings.shareDataSource = newDataSourceSettings;

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
      datasourcesMixpanel,
    );
  };

  const datasourcesMixpanel = (
    updatedTracking: FixTypeLater,
    previousTracking: FixTypeLater,
  ) => {
    tracker.track({
      eventName: 'Update Autofeed Data Sources Settings',
      trackingParams: {
        ...updatedTracking,
        'Social Page': accountAPIs.getAPIPostName({ accountAPIId }),
        'Previous Data Sources Message':
          previousTracking['Data Sources Message'],
        'Previous Data Sources Title': previousTracking['Data Sources Title'],
        'Previous Data Sources Description':
          previousTracking['Data Sources Description'],
        'Previous Data Sources Image': previousTracking['Data Sources Image'],
      },
    });
  };

  const handleDataSourceSettingsChange = (newSettings: DataSourceSettings) => {
    setCurrentDataSourceSettings(newSettings);

    if (isEqual(newSettings, dataSourceSettings)) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveDataSources(newSettings));
  };

  return (
    <>
      <AutomationSettingsHeader
        title="Data sources"
        description="Customize text and images for your automated shares"
      />
      <Flex
        alignItems="flex-start"
        gap={{ base: '46px', lg: '104px' }}
        flexWrap="wrap"
      >
        <Flex flexDir="column" gap={3} w="336px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text size="sm">Message</Text>
            <DataSourceDropdown
              sources={
                overrideMessageSources ??
                social.getSocialNetworkMessageSources({ apiTypeId })
              }
              value={currentDataSourceSettings.messageSource}
              currentDataSourceSettings={currentDataSourceSettings}
              handleDataSourceSettingsChange={handleDataSourceSettingsChange}
              sourceType="messageSource"
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center">
            <Text size="sm">Image</Text>
            <DataSourceDropdown
              sources={social.getSocialNetworkImageSources({ apiTypeId })}
              value={currentDataSourceSettings.imageSource}
              currentDataSourceSettings={currentDataSourceSettings}
              handleDataSourceSettingsChange={handleDataSourceSettingsChange}
              sourceType="imageSource"
            />
          </Flex>
          {social.hasTitleField({ apiTypeId }) && (
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="sm">Title</Text>
              <DataSourceDropdown
                sources={social.getSocialNetworkTitleSources({ apiTypeId })}
                value={currentDataSourceSettings.titleSource}
                currentDataSourceSettings={currentDataSourceSettings}
                handleDataSourceSettingsChange={handleDataSourceSettingsChange}
                sourceType="titleSource"
              />
            </Flex>
          )}
          {social.hasDescriptionField({ apiTypeId }) && (
            <Flex justifyContent="space-between" alignItems="center">
              <Text size="sm">Description</Text>
              <DataSourceDropdown
                sources={social.getSocialNetworkDescriptionSources({
                  apiTypeId,
                })}
                value={currentDataSourceSettings.descriptionSource}
                currentDataSourceSettings={currentDataSourceSettings}
                handleDataSourceSettingsChange={handleDataSourceSettingsChange}
                sourceType="descriptionSource"
              />
            </Flex>
          )}
        </Flex>
        <Flex maxW="lg" flexGrow={1} gap={2} flexDir="column" flexShrink={0}>
          <Preview
            dataSourceSettings={currentDataSourceSettings}
            metadata={metadata}
            onMetadataChange={setMetadata}
          />
          <AvailableSources
            metadata={metadata}
            apiTypeId={apiTypeId}
            accordionStyles={{
              buttonProps: {
                background: 'gray.100',
                _expanded: {
                  pb: 2,
                  background: 'gray.100',
                },
              },
            }}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default DataSources;
