/* eslint react-hooks/exhaustive-deps: "off" */

import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';

import postPropertiesSettings from 'api/postPropertiesSettings';
import { getCurrentPropertyId, getProperty } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES, GLOBAL_INFO_STATES } from 'common/constants';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import * as tracker from 'common/tracker';
import { getDomain } from 'common/url';
import { isNull } from 'common/utility';
import Add from 'components/settings/property/domainswitching/Add';
import Domains from 'components/settings/property/domainswitching/Domains';
import Intro from 'components/settings/property/domainswitching/Intro';
import OnOff from 'components/settings/property/domainswitching/OnOff';
import Title from 'components/settings/property/domainswitching/Title';
import SaveChangesButtons from 'components/settings/SaveChangesButtons';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { setPanelButtonText } from 'helpers/settingsPage';

const DomainSwitching = (props) => {
  /**
   * Initial state
   */

  const currentSetting = props.domainSwitchingSettings;
  const originalSetting = useRef(currentSetting);

  const getDomainsToSwitch = (settingValues) =>
    !isNull(settingValues)
      ? settingValues.dataJSON.domainSwitchingRules.map(
          (rule) => rule.sourceDomainRegex,
        )
      : [];
  const isDomainSwitchingActive = (settingValues) =>
    !isNull(settingValues) && settingValues.enabled;

  const [isSwitchingActive, setSwitchingActive] = useState(
    isDomainSwitchingActive(currentSetting),
  );
  const [domainsToSwitch, setDomainsToSwitch] = useState(
    getDomainsToSwitch(currentSetting),
  );
  const [isAdding, setAdding] = useState(false);
  const [isChanged, setChanged] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Change EDIT button caption to CLOSE once the panel has finished opening
  useEffect(() => {
    setPanelButtonText('collapseDomainSwitching');
  }, []);

  const property = getProperty({ propertyId: getCurrentPropertyId() });
  const rootURL = getDomain(property.propertyRootURL);
  const context = useContext(GlobalInfoContext);

  const { eventHandlers } = props;

  /**
   * Helper methods
   */

  const createSettingsConfig = () => {
    const domainSwitchingRules = [];
    domainsToSwitch.forEach((domain) => {
      domainSwitchingRules.push({
        sourceDomainRegex: domain,
        targetDomain: rootURL,
      });
    });
    const settingsConfig = {
      dataJSON: {
        domainSwitchingRules,
      },
      enabled: isSwitchingActive,
      propertyId: getCurrentPropertyId(),
      settingTypeId: ACCOUNT_SETTING_TYPES.DOMAIN_SWITCHING,
    };
    return settingsConfig;
  };

  const displayError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
  };

  const getTrackingParams = () => {
    const trackingParams = {
      'Domain Switching New Status': isSwitchingActive ? 'On' : 'Off',
      Domains: domainsToSwitch,
    };
    return trackingParams;
  };

  /**
   * Event handlers
   */

  const handleAddClick = () => {
    setAdding(true);
  };

  const handleAddCancel = () => {
    setAdding(false);
  };

  const handleAddDomain = (domainToAdd) => {
    setDomainsToSwitch((domains) => domains.concat(getDomain(domainToAdd)));
    setAdding(false);
    setChanged(true);
    eventHandlers.handleHasSettingChanged({
      key: 'domainSwitching',
      hasSettingChanged: true,
    });
  };

  const handleCancelChanges = () => {
    setSwitchingActive(isDomainSwitchingActive(originalSetting.current));
    setDomainsToSwitch(getDomainsToSwitch(originalSetting.current));
    setChanged(false);
  };

  const handleOnOff = () => {
    setSwitchingActive((isActive) => !isActive);
    setChanged(true);
    eventHandlers.handleHasSettingChanged({
      key: 'domainSwitching',
      hasSettingChanged: true,
    });
  };

  const handleRemoveDomain = (domainToRemove) => {
    setDomainsToSwitch((domains) =>
      domains.filter((domain) => domain !== domainToRemove),
    );
    setChanged(true);
    eventHandlers.handleHasSettingChanged({
      key: 'domainSwitching',
      hasSettingChanged: true,
    });
  };

  const handleSaveChanges = async () => {
    if (isSwitchingActive && domainsToSwitch.length === 0) {
      displayError('At least one domain must be added');
    }
    setSaving(true);
    const trackingParams = getTrackingParams();
    tracker.track({
      eventName: 'Update Domain Switching',
      trackingParams,
    });
    const settingsConfig = createSettingsConfig();
    try {
      await postPropertiesSettings(settingsConfig);
      context.global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        callback: () => {
          eventHandlers.handleHasSettingChanged({
            key: 'domainSwitching',
            hasSettingChanged: false,
          });
          setSaving(false);
          setChanged(false);
          addSuccessNotification('Setting saved');
        },
      });
    } catch (error) {
      setSaving(false);
      addErrorNotification('Error saving setting');
    }
  };

  /**
   * Render methods
   */

  return (
    <>
      <Title />
      <div
        id="collapseDomainSwitching"
        className="settings-sub collapse"
        aria-labelledby="headingOne"
        data-parent="#accordionPropertySettings"
        data-cy-id="domainSwitchingSettings"
      >
        <Intro rootURL={rootURL} />
        <OnOff
          isSaving={isSaving}
          isSwitchingActive={isSwitchingActive}
          eventHandlers={{ handleOnOff }}
        />
        {isSwitchingActive && (
          <Domains
            domainsToSwitch={domainsToSwitch}
            isSaving={isSaving}
            rootURL={rootURL}
            eventHandlers={{
              handleAddClick,
              handleRemoveDomain,
            }}
          />
        )}
        <SaveChangesButtons
          disabled={!isChanged || isSaving}
          eventHandlers={{
            handleCancel: handleCancelChanges,
            handleSave: handleSaveChanges,
          }}
        />
        {errorMessage !== '' && (
          <div className="save-state save failed mb-3">{errorMessage}</div>
        )}
      </div>
      {isAdding && (
        <Add
          domainsToSwitch={domainsToSwitch}
          isSaving={isSaving}
          eventHandlers={{ handleAddCancel, handleAddDomain }}
        />
      )}
    </>
  );
};

DomainSwitching.propTypes = {
  domainSwitchingSettings: PropTypes.object,
  eventHandlers: PropTypes.shape({
    handleHasSettingChanged: PropTypes.func,
  }),
};

DomainSwitching.defaultProps = {
  domainSwitchingSettings: {},
  eventHandlers: {
    handleHasSettingChanged: () => {},
  },
};

export default DomainSwitching;
