/* eslint no-use-before-define: "off" */

import { HOST_TYPES, ONE_DAY_IN_SECONDS, UNIX_EPOCH } from 'common/constants';
import * as environment from 'common/environment';

export { deleteCookie, getCookieValue, getTLD, setCookieValue };

const getCookieValue = (key) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
  return undefined;
};

const setCookieValue = (key, value, flags) => {
  const { hostname } = window.location;
  const hostType = environment.getHostType();
  const cookieFlags = {
    path: '/',
    secure: hostType !== HOST_TYPES.DEV,
    domain: hostname,
    // set expiry to 1 day from now
    expires: new Date(Date.now() + ONE_DAY_IN_SECONDS).toUTCString(),
    ...flags,
  };

  let cookieFlagsString = '';

  Object.keys(cookieFlags).forEach((flag) => {
    cookieFlagsString += `; ${flag}`;
    if (cookieFlags[flag] !== true) {
      cookieFlagsString += `=${cookieFlags[flag]}`;
    }
  });

  document.cookie = `${key}=${value}${cookieFlagsString}`;
};

const deleteCookie = (key, flags = {}) => {
  // set expire time to unix epoch to delete cookie
  setCookieValue(key, '', { expires: UNIX_EPOCH, ...flags });
};

const getTLD = (domain) => {
  const parts = domain.split('.').reverse();
  return parts[1] ? `${parts[1]}.${parts[0]}` : parts[0];
};
