import { memo } from 'react';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { getAccountAPI } from 'common/accountAPIs';
import { REACT_PREVENT_RENDER } from 'common/constants';
import {
  getSocialNetworkDefaultPageIcon,
  getSocialNetworkIcon,
  getSocialNetworkName,
  hasSponsoredPosts,
} from 'common/social';
import { arePropsEqual, isNullOrUndefined } from 'common/utility';

interface PreviewHeaderProps {
  accountAPIId: number;
  guid: string;
  apiTypeId: number;
  sponsorId?: string | null;
  sponsorName?: string | null;
  additionalHeaders?: Array<number>;
}

const PreviewHeader = ({
  accountAPIId,
  apiTypeId,
  guid,
  sponsorId = null,
  sponsorName = null,
  additionalHeaders = [],
}: PreviewHeaderProps) => {
  const headers = [accountAPIId, ...additionalHeaders];

  const renderHeaders = () => {
    return headers.map((apiId) => {
      const currentAccount = getAccountAPI({ accountAPIId: apiId });
      if (currentAccount == null) {
        return REACT_PREVENT_RENDER;
      }
      const accountImage =
        currentAccount.apiPostImage ??
        getSocialNetworkDefaultPageIcon({ apiTypeId });
      const accountName = currentAccount.apiPostName ?? '';
      const accountLink = currentAccount.apiHomePage ?? '';
      const networkImage = getSocialNetworkIcon({ apiTypeId });
      const networkName = getSocialNetworkName({ apiTypeId });
      return (
        <Box
          className="page_info"
          key={apiId}
          display="inline-block"
          minW={20}
          sx={{
            ':only-child': {
              width: '80%',
            },
          }}
          w="18%"
        >
          <div className="position-relative">
            <img className="social_logo" src={networkImage} alt={networkName} />
            <img className="page_logo" src={accountImage} alt="" />
          </div>
          <div data-cy="pageTitle">
            <div className="page_title text-truncate">
              {accountLink === '' && accountName}
              {accountLink !== '' && (
                <a
                  href={accountLink}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={accountName}
                  className="link_grey gray-800 page_title text-700 p-1 d-inline-block"
                >
                  {accountName}
                </a>
              )}
              {hasSponsoredPosts({ apiTypeId }) &&
                !isNullOrUndefined(sponsorName) &&
                sponsorName !== '' && (
                  <span>
                    with{' '}
                    <a
                      href={`https://www.facebook.com/${sponsorId}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="link_grey"
                    >
                      {sponsorName}
                    </a>
                    .
                  </span>
                )}
            </div>
          </div>
        </Box>
      );
    });
  };

  return (
    <div className="card-header bg-white" id={`headingPreview-${guid}`}>
      <h2 className="mb-0">
        <div
          className="compose-box-page-header"
          data-toggle="collapse"
          data-target={`#collapsePreview-${guid}`}
          aria-expanded="true"
          aria-controls={`collapsePreview-${guid}`}
        >
          {renderHeaders()}
        </div>
      </h2>
    </div>
  );
};

export default memo(PreviewHeader, arePropsEqual);
