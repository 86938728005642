import {
  Button,
  Check2Icon,
  ScheduleIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import postPropertiesUser from 'api/postPropertiesUser';
import { getUser } from 'common/accountAPIs';
import * as logger from 'common/logger';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { useGlobalInfo } from 'context/GlobalInfoContext';

const RESEND_STATUS = {
  UNSENT: 'UNSENT',
  SENDING: 'SENDING',
  SENT: 'SENT',
} as const;

interface ResendInviteProps {
  userId: string;
}

const ResendInvite = ({ userId }: ResendInviteProps) => {
  const [status, setStatus] = useState<keyof typeof RESEND_STATUS>(
    RESEND_STATUS.UNSENT,
  );
  const { global } = useGlobalInfo();

  const handleResend = async () => {
    logger.info(`UserManagement:handleInviteResend ${userId}`);

    setStatus(RESEND_STATUS.SENDING);

    try {
      const user = getUser({ userId });
      const globalInfo = global.getGlobalInfo();

      await postPropertiesUser({
        propertyId: globalInfo.current.propertyId,
        name: user.name,
        username: user.username,
        emailAddress: user.emailAddress,
        permissionsOnProperty: user.permissions,
      });

      addSuccessNotification('Invitation sent');
      setStatus(RESEND_STATUS.SENT);
    } catch (error) {
      console.log(error);
      addErrorNotification('Failed to send invitation');
      setStatus(RESEND_STATUS.UNSENT);
    }
  };

  if (status === RESEND_STATUS.SENT) {
    return (
      <Button
        as="span"
        variant="tertiary"
        leftIcon={<Check2Icon />}
        color="green.600"
        userSelect="initial"
        _active={{}}
        _focus={{}}
        _hover={{}}
        mr={4}
      >
        Invitation sent
      </Button>
    );
  }

  return (
    <Button
      variant="secondary"
      leftIcon={<ScheduleIcon />}
      onClick={handleResend}
      isDisabled={status === RESEND_STATUS.SENDING}
      data-cy-action="resendInvite"
      mr={4}
    >
      Resend invite
    </Button>
  );
};

export default ResendInvite;
