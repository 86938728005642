import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { API_PROPERTIES, API_TYPE_IDS } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { APIType, InstagramParams } from 'types';

/**
 * GET /properties/{propertyURN}/connect/request - Request that a connection to a specified social
 * network be added for a particular property.
 */

export default async function getPropertiesConnectRequestNew({
  socialAPIType,
  propertyURN,
  uiCallbackURL,
  apiCallbackURL,
  timezone,
  instagramParams,
  extraParameters,
}: {
  socialAPIType: APIType;
  propertyURN: string;
  uiCallbackURL: string;
  apiCallbackURL: string;
  timezone?: string;
  instagramParams?: InstagramParams;
  extraParameters?: Record<string, string>;
}) {
  const guid = metrics.start('getPropertiesConnectRequestNew');
  // encode password by ourselves rather than let axios to do so
  const copiedInstagramParams = { ...instagramParams };
  let passwordQueryStr = '';
  if (instagramParams?.password !== undefined) {
    const password = encodeURIComponent(instagramParams.password);
    delete copiedInstagramParams.password;
    passwordQueryStr = `?password=${password}`;
  }

  const isTikTokAPI =
    socialAPIType === API_PROPERTIES[API_TYPE_IDS.TIKTOK].apiType;

  const constructForcedBaseSocialAPIURL = () => {
    if (isTikTokAPI) {
      return constructBaseSocialAPIURL(undefined, true);
    }

    return constructBaseSocialAPIURL();
  };

  const constructForcedAPICallbackURL = () => {
    try {
      if (isTikTokAPI && apiCallbackURL.includes('-stage')) {
        return apiCallbackURL.replace('-stage', '');
      }
    } catch (ex) {
      console.log(ex);
    }
    return apiCallbackURL;
  };

  const config = {
    url: `${constructForcedBaseSocialAPIURL()}/social/properties/${propertyURN}/connect/request${passwordQueryStr}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
    params: {
      ...copiedInstagramParams,
      socialAPIType,
      uiCallbackURL,
      apiCallbackURL: constructForcedAPICallbackURL(),
      timezone,
      ...extraParameters,
    },
  };

  try {
    const response = await axios(config);
    // Get the location from the header
    const responseLocation = response.headers.location;
    metrics.end('getPropertiesConnectRequestNew', guid);
    logger.debug('getPropertiesConnectRequestNew', {
      propertyURN,
      socialAPIType,
    });
    return { location: responseLocation };
  } catch (error) {
    metrics.fail('getPropertiesConnectRequestNew', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertiesConnectRequestNew',
      args: {
        propertyURN,
        socialAPIType,
        uiCallbackURL,
        apiCallbackURL,
        timezone,
      },
      isSensitiveRequest: true,
    });
    throw apiError;
  }
}
