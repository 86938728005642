import { z } from 'zod';
import { create } from 'zustand';

import {
  getProperty,
  getPropertyPermission,
  isPropertyIncomplete,
} from 'common/accountAPIs';
import {
  DISMISSED_HIGH_PRIORITY_ERROR_MESSAGES_LOCALSTORAGE_KEY,
  HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY,
  PERMISSION_TYPES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getUnixTimestamp } from 'common/datetime';
import { isOnline } from 'common/offlineDetection';
import { HighPriorityErrorTypeSchema } from 'common/schemas';
import { getFeatureToggle } from 'common/settings';
import { hasActiveGoogleAnalytics, hasActiveWebTag } from 'common/traffic';
import { isDefined } from 'common/utility';
import { stringToJSONSchema } from 'common/zod';
import type { HighPriorityErrorType } from 'types';

const HIGH_PRIORITY_AUTO_DISMISS_TYPES = [
  'GOOGLE_ANALYTICS_WITH_NO_TRACKER',
  'NO_TRACKER_WITH_NO_GOOGLE_ANALYTICS',
];

function hasPropertyCompletedSetup(propertyId: string) {
  return !isPropertyIncomplete({ propertyId });
}

const DismissedErrorTypesArrayByPropertyIdSchema = z
  .string()
  .transform((raw) => JSON.parse(raw))
  .pipe(z.record(z.array(HighPriorityErrorTypeSchema)))
  .catch({})
  .describe('DismissedErrorTypesArraySchema');

const FirstSeenHighPriorityErrorSchema = stringToJSONSchema
  .pipe(z.record(z.number()))
  .catch({})
  .describe('FirstSeenHighPriorityErrorSchema');

function deleteTimeOfInitialRender(propertyId: string) {
  const rawValue = localStorage.getItem(
    HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY,
  );
  const unixTime = FirstSeenHighPriorityErrorSchema.parse(rawValue);

  // Delete the unix time for the current property
  delete unixTime[propertyId];
  localStorage.setItem(
    HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY,
    JSON.stringify(unixTime),
  );
}

function storeTimeOfInitialRender(propertyId: string) {
  // Retrieve any existing unix time for the current property.
  const rawValue = localStorage.getItem(
    HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY,
  );
  const unixTime = FirstSeenHighPriorityErrorSchema.parse(rawValue);

  // If not found, set the unix time to now and save it to local storage
  if (!unixTime[propertyId]) {
    localStorage.setItem(
      HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY,
      JSON.stringify({ ...unixTime, [propertyId]: getUnixTimestamp() }),
    );
  }
}

function getDismissedErrorTypesByPropertyId() {
  const rawDismissedErrorTypes =
    localStorage.getItem(
      DISMISSED_HIGH_PRIORITY_ERROR_MESSAGES_LOCALSTORAGE_KEY,
    ) ?? '{}';
  const dismissedErrorTypesByPropertyId =
    DismissedErrorTypesArrayByPropertyIdSchema.parse(rawDismissedErrorTypes);
  return dismissedErrorTypesByPropertyId;
}

function getDismissedErrorTypes(propertyId: string) {
  const dismissedErrorTypesByPropertyId = getDismissedErrorTypesByPropertyId();
  return dismissedErrorTypesByPropertyId[propertyId] ?? [];
}

function hasErrorTypeBeenAutoDismissed(propertyId: string) {
  // Get the locale storage unix time for the current property.
  const rawValue = localStorage.getItem(
    HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY,
  );
  const unixTime = FirstSeenHighPriorityErrorSchema.parse(rawValue);

  // If the unix time is older than 2 weeks, then the error should be dismissed.
  const TwoWeeksAgo = getUnixTimestamp() - 1209600;
  if (unixTime[propertyId] && unixTime[propertyId] < TwoWeeksAgo) {
    return true;
  }
  return false;
}

function hasErrorTypeBeenDismissed(
  errorType: HighPriorityErrorType,
  propertyId: string,
) {
  const dismissedErrorTypes = getDismissedErrorTypes(propertyId);

  return dismissedErrorTypes.includes(errorType);
}

function isWebtagInstalledByTagManager(propertyId: number) {
  return getFeatureToggle({
    featureName: FEATURE_TOGGLES.TRACKER_PROVISIONED_MANUALLY,
    propertyId,
  });
}

function propertyIsSuspended(propertyId: string) {
  const property = getProperty({ propertyId });

  if (isDefined(property?.propertyBilling?.shutOffUnixTime)) {
    const shutOffUnixTime = property.propertyBilling.shutOffUnixTime;

    if (shutOffUnixTime < getUnixTimestamp()) {
      return true;
    }
  }

  return false;
}

function propertyWillBeSuspended(propertyId: string) {
  const property = getProperty({ propertyId });

  if (isDefined(property?.propertyBilling?.shutOffUnixTime)) {
    const shutOffUnixTime = property.propertyBilling.shutOffUnixTime;

    if (shutOffUnixTime >= getUnixTimestamp()) {
      return true;
    }
  }

  return false;
}

function pageHasGAAndShouldDisplayWebtagsWarning(propertyId: string) {
  return (
    hasPropertyCompletedSetup(propertyId) &&
    !hasActiveWebTag(propertyId) &&
    !isWebtagInstalledByTagManager(parseInt(propertyId, 10)) &&
    hasActiveGoogleAnalytics(propertyId) &&
    !hasErrorTypeBeenDismissed('GOOGLE_ANALYTICS_WITH_NO_TRACKER', propertyId)
  );
}

function propertyHasNoGAAndShouldDisplayWebtagsWarning(propertyId: string) {
  const propertyPermission = getPropertyPermission({ propertyId });
  return (
    propertyPermission === PERMISSION_TYPES.ADMIN &&
    hasPropertyCompletedSetup(propertyId) &&
    !hasActiveWebTag(propertyId) &&
    !isWebtagInstalledByTagManager(parseInt(propertyId, 10)) &&
    !hasActiveGoogleAnalytics(propertyId) &&
    !hasErrorTypeBeenDismissed(
      'NO_TRACKER_WITH_NO_GOOGLE_ANALYTICS',
      propertyId,
    )
  );
}

function getHighPriorityErrorType(
  propertyId: string,
): HighPriorityErrorType | null {
  if (!isOnline()) {
    return 'OFFLINE';
  }

  if (propertyIsSuspended(propertyId)) {
    return 'ACCOUNT_SUSPENDED';
  }

  if (propertyWillBeSuspended(propertyId)) {
    return 'ACCOUNT_WILL_BE_SUSPENDED';
  }

  // Page does not have Web Tag and has Google Analytics
  if (pageHasGAAndShouldDisplayWebtagsWarning(propertyId)) {
    return 'GOOGLE_ANALYTICS_WITH_NO_TRACKER';
  }

  // Page does not have Web Tag and without Google Analytics
  if (propertyHasNoGAAndShouldDisplayWebtagsWarning(propertyId)) {
    return 'NO_TRACKER_WITH_NO_GOOGLE_ANALYTICS';
  }

  return null;
}

interface HighPriorityErrorTypeState {
  highPriorityErrorType: HighPriorityErrorType | null;

  // https://tkdodo.eu/blog/working-with-zustand#separate-actions-from-state
  actions: {
    dismissHighPriorityError: (propertyId: string) => void;
    initialiseHighPriorityErrorState: (propertyId: string) => void;
  };
}

const useHighPriorityErrorTypeStore = create<HighPriorityErrorTypeState>(
  (set, get) => ({
    highPriorityErrorType: null,

    actions: {
      dismissHighPriorityError: (propertyId: string) => {
        const dismissedErrorTypes = getDismissedErrorTypes(propertyId);
        const newDismissedErrorTypes = [
          ...dismissedErrorTypes,
          get().highPriorityErrorType,
        ];

        const dismissedErrorTypesByPropertyId =
          getDismissedErrorTypesByPropertyId();
        const newDismissedErrorTypesByPropertyId = {
          ...dismissedErrorTypesByPropertyId,
          [propertyId]: newDismissedErrorTypes,
        };

        localStorage.setItem(
          DISMISSED_HIGH_PRIORITY_ERROR_MESSAGES_LOCALSTORAGE_KEY,
          JSON.stringify(newDismissedErrorTypesByPropertyId),
        );

        set({
          highPriorityErrorType: getHighPriorityErrorType(propertyId),
        });
      },
      initialiseHighPriorityErrorState: (propertyId: string) => {
        let highPriorityErrorType = getHighPriorityErrorType(propertyId);

        if (highPriorityErrorType === null) {
          deleteTimeOfInitialRender(propertyId);
        } else if (
          HIGH_PRIORITY_AUTO_DISMISS_TYPES.includes(highPriorityErrorType)
        ) {
          if (hasErrorTypeBeenAutoDismissed(propertyId)) {
            highPriorityErrorType = null;
          } else {
            storeTimeOfInitialRender(propertyId);
          }
        }

        set({ highPriorityErrorType });
      },
    },
  }),
);

const useHighPriorityErrorType = () =>
  useHighPriorityErrorTypeStore((state) => state.highPriorityErrorType);
const useIsShowingHighPriorityErrorType = () =>
  useHighPriorityErrorTypeStore(
    (state) => state.highPriorityErrorType !== null,
  );
const useIsOnline = () =>
  useHighPriorityErrorTypeStore(
    (state) => state.highPriorityErrorType !== 'OFFLINE',
  );
const useHighPriorityErrorTypeActions = () =>
  useHighPriorityErrorTypeStore((state) => state.actions);

export {
  isWebtagInstalledByTagManager,
  useHighPriorityErrorType,
  useHighPriorityErrorTypeActions,
  useHighPriorityErrorTypeStore,
  useIsOnline,
  useIsShowingHighPriorityErrorType,
};
