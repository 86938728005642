import {
  getAPIPostName,
  getCurrentAPITypeId,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { SeenNotificationsSchema } from 'common/schemas';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { getErrorMessage } from 'components/header/HealthMenu/HealthMenuPage';
import { HealthMenuNotificationStorage, SocialAPI } from 'types';
import { getNetworkAndPageName, getOrigin } from './tracking';

/**
 * Gets the notifications that have been seen for the current property.
 *
 * @param localStorageKey The key to retrieve the value from localStorage.
 */
export function getSeenNotifications(localStorageKey: string) {
  const propertyId = getCurrentPropertyId();

  const rawNotifications = localStorage.getItem(localStorageKey) ?? '{}';

  const seenNotifications = SeenNotificationsSchema.parse(rawNotifications);

  return seenNotifications[propertyId] ?? {};
}

/**
 * Updates local storage with the notifications that have been seen.
 *
 * @param seenNotificationsForProperty The notifications that have been seen for the current property.
 * @param localStorageKey The key to retrieve the value from localStorage.
 */
export function updateSeenNotifications(
  seenNotificationsForProperty: HealthMenuNotificationStorage[string],
  localStorageKey: string,
) {
  const currentPropertyId = getCurrentPropertyId();

  const rawNotifications = localStorage.getItem(localStorageKey) ?? '{}';

  const seenNotifications = SeenNotificationsSchema.parse(rawNotifications);

  seenNotifications[currentPropertyId] = seenNotificationsForProperty;

  localStorage.setItem(localStorageKey, JSON.stringify(seenNotifications));
}

export function sendErrorTrackingEvents({
  disconnectedAccountAPIs,
  hasDisconnectedTwitterApp,
  disconnectedTwitterPages,
  contentFeedErrorMsg,
}: {
  disconnectedAccountAPIs: SocialAPI[];
  hasDisconnectedTwitterApp: boolean;
  disconnectedTwitterPages: SocialAPI[];
  contentFeedErrorMsg: string | null;
}) {
  const accountAPIId = getCurrentAccountAPIId();
  const apiTypeId = getCurrentAPITypeId();

  tracker.track({
    eventName: 'View Health Menu Dropdown',
    trackingParams: {
      'Social Network': getSocialNetworkName({ apiTypeId }),
      'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
      'Account API Id': accountAPIId,
      Origin: getOrigin(window.location.pathname),
      'Number of errors': disconnectedAccountAPIs.length,
    },
  });

  const trackFrontendError = (args: Record<string, unknown>) => {
    // https://docs.google.com/document/d/1ouekOadpprqEoeQBmPxgdv_H5qtUX8mzybqZHflQ1cA/edit#heading=h.2ncy4no6fnkr
    tracker.track({
      eventName: 'Display Frontend Error',
      trackingParams: {
        'Social Page': getAPIPostName({ accountAPIId }),
        'Social Network': getSocialNetworkName({ apiTypeId }),
        'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
        'Account API Id': accountAPIId,
        'Error Location': 'Health Menu',
        ...args,
      },
    });
  };

  disconnectedAccountAPIs.forEach((accountAPI) => {
    trackFrontendError({
      '# Errors Displayed': disconnectedAccountAPIs.length,
      'Error Message': getErrorMessage(accountAPI),
      'Error Type': 'Social page disconnected with disconnect reason',
    });
  });

  if (hasDisconnectedTwitterApp) {
    trackFrontendError({
      '# Errors Displayed': 1, // TODO: Confirm number of errors displayed
      'Error Message': disconnectedTwitterPages[0].disconnectReason, // TODO: Confirm error message
      'Error Type': 'Twitter app disconnected',
    });
  }

  if (contentFeedErrorMsg) {
    trackFrontendError({
      '# Errors Displayed': 1,
      'Error Message': contentFeedErrorMsg,
      'Error Type': 'Content feed error',
    });
  }
}
