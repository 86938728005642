import * as React from 'react';

interface InlineEmoticonProps {
  children: React.ReactNode;
}

/**
 * Although currently unstyled treating emoticons as entities in DraftJS gets around
 * some undo issues (where the selection is malformed after the undo)
 */
const InlineEmoticon = ({ children }: InlineEmoticonProps) => (
  <span>{children}</span>
);

export default InlineEmoticon;
