import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { SHARE_TIME_TYPES } from 'common/constants';
import type { ShareTime } from 'types';

import type { DatePickerTargetString } from '../TimingOptions';

interface TimeslotProps {
  calendarIcon: () => JSX.Element;
  guid: string;
  inputClass: (key: DatePickerTargetString) => string;
  isOpen: {
    min: {
      time: boolean;
      date: boolean;
    };
    max: {
      time: boolean;
      date: boolean;
    };
  };
  shareTime: Extract<ShareTime, { type: typeof SHARE_TIME_TYPES.TIMESLOT }>;
  timeFormat: string;
  onTimeslotDateFromChange: (
    date?: Date | undefined,
    dateStr?: string | undefined,
  ) => void;
  onTimeslotDateFromClick: React.MouseEventHandler<any>;
  onTimeslotDateFromKeyDown: React.KeyboardEventHandler<any>;
  onTimeslotDateFromBlur: () => void;
  onTimeslotDateToChange: (
    date?: Date | undefined,
    dateStr?: string | undefined,
  ) => void;
  onTimeslotDateToClick: React.MouseEventHandler<any>;
  onTimeslotDateToKeyDown: React.KeyboardEventHandler<any>;
  onTimeslotDateToBlur: () => void;
  onTimeslotTimeFromChange: (
    date?: Date | undefined,
    dateStr?: string | undefined,
  ) => void;
  onTimeslotTimeFromClick: React.MouseEventHandler<any>;
  onTimeslotTimeFromKeyDown: React.KeyboardEventHandler<any>;
  onTimeslotTimeFromBlur: () => void;
  onTimeslotTimeToChange: (
    date?: Date | undefined,
    dateStr?: string | undefined,
  ) => void;
  onTimeslotTimeToClick: React.MouseEventHandler<any>;
  onTimeslotTimeToKeyDown: React.KeyboardEventHandler<any>;
  onTimeslotTimeToBlur: () => void;
  onToggle: () => void;
}

const Timeslot = ({
  calendarIcon,
  guid,
  inputClass,
  isOpen,
  onTimeslotDateFromBlur,
  onTimeslotDateFromChange,
  onTimeslotDateFromClick,
  onTimeslotDateFromKeyDown,
  onTimeslotDateToBlur,
  onTimeslotDateToChange,
  onTimeslotDateToClick,
  onTimeslotDateToKeyDown,
  onTimeslotTimeFromBlur,
  onTimeslotTimeFromChange,
  onTimeslotTimeFromClick,
  onTimeslotTimeFromKeyDown,
  onTimeslotTimeToBlur,
  onTimeslotTimeToChange,
  onTimeslotTimeToClick,
  onTimeslotTimeToKeyDown,
  onToggle,
  shareTime,
  timeFormat,
}: TimeslotProps) => {
  return (
    <div className="time_selection_details">
      <div className="time_selection_picker">
        <span className="time_selection_details_title">Start</span>
        <DateTimePicker
          containerClassName={inputClass('min.date')}
          date
          dateIcon={calendarIcon()}
          editFormat="DD-MMM-YY"
          // @ts-expect-error -- Some of the properties throw an error, but they appear to be working and are used here.
          footer
          format="DD-MMM-YY"
          min={new Date()}
          name={`timeslot_date_from__${guid}`}
          data-cy-input="timeslotStartDate"
          onChange={onTimeslotDateFromChange}
          onClick={onTimeslotDateFromClick}
          onKeyDown={onTimeslotDateFromKeyDown}
          onToggle={onToggle}
          onBlur={onTimeslotDateFromBlur}
          open={isOpen.min.date ? 'date' : false}
          time={false}
          value={new Date(shareTime.min * 1000)}
          views={['month']}
        />
      </div>
      <div className="time_selection_picker">
        <DateTimePicker
          containerClassName={inputClass('min.time')}
          date={false}
          dateIcon={calendarIcon()}
          editFormat={timeFormat}
          // @ts-expect-error -- Some of the properties throw an error, but they appear to be working and are used here.
          footer
          format={timeFormat}
          onChange={onTimeslotTimeFromChange}
          onClick={onTimeslotTimeFromClick}
          onKeyDown={onTimeslotTimeFromKeyDown}
          onToggle={onToggle}
          onBlur={onTimeslotTimeFromBlur}
          open={isOpen.min.time ? 'time' : false}
          name={`timeslot_time_from__${guid}`}
          data-cy-input="timeslotStartTime"
          step={30}
          time
          timeFormat={timeFormat}
          value={new Date(shareTime.min * 1000)}
          views={['month']}
        />
      </div>
      <div className="time_selection_picker">
        <span className="time_selection_details_title">End</span>
        <DateTimePicker
          containerClassName={inputClass('max.date')}
          date
          dateIcon={calendarIcon()}
          editFormat="DD-MMM-YY"
          // @ts-expect-error -- Some of the properties throw an error, but they appear to be working and are used here.
          footer
          format="DD-MMM-YY"
          min={new Date()}
          name={`timeslot_date_to__${guid}`}
          data-cy-input="timeslotEndDate"
          onChange={onTimeslotDateToChange}
          onClick={onTimeslotDateToClick}
          onKeyDown={onTimeslotDateToKeyDown}
          onToggle={onToggle}
          onBlur={onTimeslotDateToBlur}
          open={isOpen.max.date ? 'date' : false}
          time={false}
          value={new Date(shareTime.max * 1000)}
          views={['month']}
        />
      </div>
      <div className="time_selection_picker">
        <DateTimePicker
          containerClassName={inputClass('max.time')}
          date={false}
          dateIcon={calendarIcon()}
          editFormat={timeFormat}
          // @ts-expect-error -- Some of the properties throw an error, but they appear to be working and are used here.
          footer
          format={timeFormat}
          name={`timeslot_time_to__${guid}`}
          data-cy-input="timeslotEndTime"
          onChange={onTimeslotTimeToChange}
          onClick={onTimeslotTimeToClick}
          onKeyDown={onTimeslotTimeToKeyDown}
          onToggle={onToggle}
          onBlur={onTimeslotTimeToBlur}
          open={isOpen.max.time ? 'time' : false}
          step={30}
          time
          timeFormat={timeFormat}
          value={new Date(shareTime.max * 1000)}
          views={['month']}
        />
      </div>
    </div>
  );
};

export default Timeslot;
