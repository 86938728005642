/* eslint no-return-assign: "off" */
/* eslint react-hooks/exhaustive-deps:'off' */

import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import {
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
  isCurrentPropertySuspended,
} from 'common/accountAPIs';
import { ANALYTICS_SETTINGS } from 'common/config';
import {
  API_TYPE_IDS,
  COLLECTION_NAMES,
  FRONTEND_METRICS,
  GLOBAL_INFO_CHANGES,
  TEXT_CASES,
} from 'common/constants';
import { getTimeFilterRange } from 'common/datetime';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { isNull } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import ABResults from 'components/home/analytics/ABResults';
import Export from 'components/home/analytics/Export';
import Filters from 'components/home/analytics/Filters';
import Item from 'components/home/analytics/Item';
import DeleteArticle from 'components/home/DeleteArticle';
import AllNotifications from 'components/misc/AllNotifications';
import withFlashMessages from 'context/withFlashMessages';
import withGlobalInfo from 'context/withGlobalInfo';
import { getDefaultColumns } from 'helpers/analyticsPage';
import { getNetworkAndPageName } from 'helpers/tracking';
import Columns from './Columns';

function getColumnName({ apiTypeId, insightKey }) {
  switch (insightKey) {
    case 'date':
      return 'Date';
    case 'impressions':
      return apiTypeId === API_TYPE_IDS.TIKTOK ? 'Views' : 'Impres.';
    case 'clicks':
      return 'Clicks';
    case 'ctr':
      return 'CTR';
    case 'totalengagement':
      return 'Engagem.';
    case 'engagementrate':
      return 'Eng. Rate';
    case 'reach':
      return 'Reach';
    case 'pageviews':
      return 'Pageviews';
    default:
      return insightKey;
  }
}

/**
 * Analytics page
 */

class AnalyticsPage extends BaseComponent {
  /**
   * Initial state
   */
  constructor(props) {
    super(props);
    this.state = {
      isExporting: false,
      isLoadingMore: false,
      mediaItemToDelete: null,
      showABResults: null,
    };
    this._bind(
      'getTimeFrameMessage',
      'handleArticleDelete',
      'handleArticleDeleteCancel',
      'handleExportCancel',
      'handleExportOpen',
      'handleFilterChange',
      'handleObserver',
      'handleHideABResults',
      'handleSetArticleDelete',
      'handleShowABResults',
      'handleSortChange',
      'handleTimeframeChange',
    );
    this.previousY = 0;
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare(COLLECTION_NAMES.ANALYTICS_PAGE, {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.LOADING_OR_SELECTING,
    });
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
      { excludeLoadingAndRefreshing: true },
    );
    if (hasChangedToReady) {
      this.initialiseComponent();
    }
    this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_ANALYTICS);
  }

  /**
   * Event handlers
   */

  handleArticleDelete(mediaItem) {
    this.setState(
      {
        mediaItemToDelete: null,
      },
      () => {
        this.props.eventHandlers.handleArticleDelete({
          collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
          mediaId: MediaItem.getMediaId({
            mediaItem,
          }),
          showConfirmation: true,
        });
      },
    );
  }

  handleArticleDeleteCancel() {
    this.setState({
      mediaItemToDelete: null,
    });
  }

  handleExportCancel() {
    this.setState({
      isExporting: false,
    });
  }

  handleExportOpen() {
    const accountAPIId = getCurrentAccountAPIId();
    tracker.track({
      eventName: 'Export Analytics Data',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Social Network': getSocialNetworkName({
          apiTypeId: getCurrentAPITypeId(),
        }),
      },
    });
    this.setState((prevState) => ({
      isExporting: !prevState.isExporting,
    }));
  }

  handleFilterChange(filterBy) {
    this.setState({
      isExporting: false,
    });
    this.props.eventHandlers.handleFilterChange({
      collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
      filterBy,
    });
  }

  handleHideABResults() {
    this.setState({
      showABResults: null,
    });
  }

  handleObserver(entities) {
    const currentY = entities[0].boundingClientRect.y;
    const isLoading =
      this.props.feedData.isLoading ||
      this.props.global.isLoading() ||
      this.props.global.isSelecting() ||
      this.state.isLoadingMore;
    const isMoreData =
      this.props.feedData.itemsRendered < this.props.feedData.mediaIds.length;
    if (this.previousY > currentY && !isLoading && isMoreData) {
      logger.debug('AnalyticsPage:handleObserver - more data required');
      this.setState({ isLoadingMore: true }, async () => {
        logger.debug('AnalyticsPage:handleObserver - fetching data...');
        try {
          await this.props.sharedMethods.loadMoreItems({
            collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
            itemsToLoad: this.props.itemsToLoad,
          });
          logger.debug('AnalyticsPage:handleObserver - data received');
          this.setState({
            isLoadingMore: false,
          });
        } catch (error) {
          logger.debug('AnalyticsPage:handleObserver - error occurred');
          this.setState({
            isLoadingMore: false,
          });
        }
      });
    }
    this.previousY = currentY;
  }

  handleSetArticleDelete(mediaItem) {
    this.setState({
      mediaItemToDelete: mediaItem,
    });
  }

  handleShowABResults(mediaItem) {
    this.setState({
      showABResults: mediaItem,
    });
  }

  handleSortChange(sortBy) {
    const apiTypeId = getAPITypeId({ accountAPIId: this.props.accountAPIId });

    return () => {
      const { sortBy: previousSortMethod, sortOrder } =
        this.props.feedData.displayOptions;
      const accountAPIId = getCurrentAccountAPIId();

      tracker.track({
        eventName: 'Sort Analytics Data',
        trackingParams: {
          'Social Network': getSocialNetworkName({
            apiTypeId: getCurrentAPITypeId(),
          }),
          'Network - Social Page': getNetworkAndPageName({
            accountAPIId,
          }),
          'Account API Id': accountAPIId,
          'Sort Method (before)': getColumnName({
            apiTypeId,
            insightKey: previousSortMethod,
          }),
          'Sort Method': getColumnName({
            apiTypeId,
            insightKey: sortBy,
          }),
          'Sort Order':
            previousSortMethod === sortBy && sortOrder === 'DESC'
              ? 'Ascending'
              : 'Descending',
        },
      });

      this.setState({
        isExporting: false,
      });
      this.props.eventHandlers.handleSortChange({
        collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
        sortBy,
      });
    };
  }

  handleTimeframeChange(event) {
    const timeframe = event.target.value;
    let fromTime;
    let toTime;
    if (timeframe === 'CUSTOM') {
      fromTime = event.target.getAttribute('fromTime');
      toTime = event.target.getAttribute('toTime');
    } else {
      const timeRange = getTimeFilterRange(timeframe);
      fromTime = timeRange.fromTime;
      toTime = timeRange.toTime;
    }
    this.setState({
      isExporting: false,
    });
    this.props.eventHandlers.handleTimeframeChange({
      collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
      timeFrame: event.target.value,
      fromTime,
      toTime,
    });
  }

  /**
   * Helper methods
   */

  getColumns({ apiTypeId }) {
    const insightColumns =
      this.props.feedData.displayOptions.insightColumns ?? {};
    const networkName = getSocialNetworkName({
      apiTypeId,
      textCase: TEXT_CASES.LOWER,
    });
    return insightColumns[networkName] ?? getDefaultColumns({ apiTypeId });
  }

  getTimeFrameMessage() {
    const timeFrame = this.props.feedData.displayOptions.timeFrame;

    if (this.props.feedData.displayOptions.timeFrame === 'CUSTOM') {
      const fromDate = new Date(
        this.props.feedData.displayOptions.fromTime * 1000,
      );
      const toDate = new Date(this.props.feedData.displayOptions.toTime * 1000);
      return `From ${fromDate} to ${toDate}`;
    }
    return ANALYTICS_SETTINGS.TIMEFRAME_OPTIONS.find(
      (option) => option.duration === Number(timeFrame),
    ).label;
  }

  initialiseComponent() {
    this.props.sharedMethods.getFeedData({
      collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
    });
    const timeFrameMessage = this.getTimeFrameMessage();
    const accountAPIId = getCurrentAccountAPIId();
    const apiTypeId = getAPITypeId({ accountAPIId });

    tracker.track({
      eventName: 'View Analytics Data',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Social Network': getSocialNetworkName({
          apiTypeId: getCurrentAPITypeId(),
        }),
        'Time Range Filter': timeFrameMessage,
        'Selected Columns': this.getColumns({ apiTypeId }),
      },
    });

    const options = {
      root: document.querySelector('#page-content'),
      rootMargin: '5px',
      threshold: 1.0,
    };
    this.observer = new IntersectionObserver(this.handleObserver, options);
    this.observer.observe(this.loadingRef);
  }

  /**
   * Render method
   */

  render() {
    const feedData = this.props.feedData;
    const isLoading =
      feedData.isLoading ||
      this.props.global.isLoading() ||
      this.props.global.isSelecting();
    const eventHandlers = this.props.eventHandlers;
    const sharedMethods = this.props.sharedMethods;
    const suspendedClass = isCurrentPropertySuspended()
      ? 'account_suspended'
      : '';
    const feedClass =
      this.props.showMobileMenu && this.props.isMobileWindowWidth
        ? 'd-none d-md-block'
        : '';
    const loadingCSS = {
      display: this.state.isLoadingMore ? 'block' : 'none',
    };

    return (
      <div
        className={`analytics ${feedClass}`}
        data-cy-id="analyticsPage"
        data-cy-attribute={`isLoading:${isLoading}`}
      >
        <AllNotifications />
        {!isNull(this.state.mediaItemToDelete) && (
          <DeleteArticle
            mediaItem={this.state.mediaItemToDelete}
            accountAPIId={this.props.accountAPIId}
            onArticleDelete={this.handleArticleDelete}
            onArticleDeleteCancel={this.handleArticleDeleteCancel}
          />
        )}
        <Filters
          displayOptions={feedData.displayOptions}
          totalItems={feedData.mediaIds.length}
          eventHandlers={{
            handleExportOpen: this.handleExportOpen,
            handleFilterChange: this.handleFilterChange,
            handleInsightColumnsChange:
              eventHandlers.handleInsightColumnsChange,
            handleTimeframeChange: this.handleTimeframeChange,
          }}
        />
        {this.state.isExporting && (
          <Export
            eventHandlers={{
              handleExportCancel: this.handleExportCancel,
            }}
          />
        )}
        {isLoading && (
          <div className="empty_feed text-center mt-5 pt-5">
            <Spinner size="lg" />
          </div>
        )}
        <br />
        {!isLoading && (
          <div className={`articles ${suspendedClass}`}>
            <table className="relative">
              <thead>
                <Columns
                  displayOptions={feedData.displayOptions}
                  onSortChange={this.handleSortChange}
                />
              </thead>
            </table>
            <table className="content">
              {feedData.mediaItems.map((mediaItem) => (
                <Item
                  displayOptions={feedData.displayOptions}
                  mediaItem={mediaItem}
                  accountAPIId={this.props.accountAPIId}
                  key={MediaItem.getMediaId({ mediaItem })}
                  eventHandlers={{
                    handleArticleDelete: this.handleSetArticleDelete,
                    handleArticleReshare: eventHandlers.handleArticleReshare,
                    handleShowABResults: this.handleShowABResults,
                  }}
                />
              ))}
            </table>
            {sharedMethods.renderNoMoreItems()}
          </div>
        )}
        {!isLoading && !isNull(this.state.showABResults) && (
          <ABResults
            mediaItem={this.state.showABResults}
            eventHandlers={{
              handleHideABResults: this.handleHideABResults,
            }}
          />
        )}
        <div
          ref={(loadingRef) => {
            this.loadingRef = loadingRef;
          }}
        >
          <span
            className="new_article_notification no_new_article_notification"
            style={loadingCSS}
          >
            <Spinner size="lg" />
          </span>
        </div>
      </div>
    );
  }
}

AnalyticsPage.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  feedData: PropTypes.object.isRequired,
  isMobileWindowWidth: PropTypes.bool.isRequired,
  itemsToLoad: PropTypes.number.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
    handleArticleLoad: PropTypes.func.isRequired,
    handleArticleReshare: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    handleInsightColumnsChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
  sharedMethods: PropTypes.shape({
    getFeedData: PropTypes.func.isRequired,
    loadMoreItems: PropTypes.func.isRequired,
    renderNoMoreItems: PropTypes.func.isRequired,
  }).isRequired,
};

export default withFlashMessages(withGlobalInfo(AnalyticsPage));
