import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ComponentPropsWithoutRef, memo } from 'react';

import { getAPIPostName } from 'common/accountAPIs';
import { POST_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import {
  canAddImage,
  canDeleteImage,
  getImageQuantityLimits,
  getImageUploadCaption,
  getImageUploadTooltip,
  getSocialNetworkName,
} from 'common/social';
import { getVideoThumbnail } from 'common/thumbnails';
import * as tracker from 'common/tracker';
import { arePropsEqual, isNullOrUndefined } from 'common/utility';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import { useImageContext } from 'context/ComposeBoxImageContext';
import { getImageSuffix } from 'helpers/images';
import { getNetworkAndPageName } from 'helpers/tracking';
import { FixTypeLater } from 'types';

import { IMAGE_MODES } from '../imageV2/constants';
import { ImageMode } from '../imageV2/types';
import ImageDownload from './ImageDownload';

/**
 * Renders the image controls for image previews
 * Accepts the following props:
 * 1) apiTypeId - id of the API type
 * 2) articleImages - the images managed by the component
 * 3) canDownload - boolean of whether the image can be downloaded
 * 4) currentImage - index of the current image
 * 5) imageURL - source URL of the image
 * 6) mode - mode to display the image
 * 7) isDownloading - boolean of whether the image is currently downloading
 * 8) eventHandlers - event handlers for when the controls are clicked
 * 9) mediaItem - mediaItem object containing image urls
 */

interface SocialImageControlsProps {
  apiTypeId: number;
  articleImages: string[];
  canDownload: boolean;
  currentImage: number;
  imageURL?: string | null;
  mode: ImageMode;
  isDownloading: boolean;
  onCrop: () => void;
  onDeleteImage: () => void;
  onDeleteVideo: () => void;
  onDownload: (downloadImageURL: string) => Promise<void>;
  onEdit: () => void;
  onNextImage: () => void;
  onPrevImage: () => void;
  onRefresh: () => void;
  mediaItem: FixTypeLater;
}

const SocialImageControls = ({
  apiTypeId,
  articleImages,
  canDownload,
  currentImage,
  imageURL = null,
  mode,
  isDownloading,
  onCrop,
  onDeleteImage,
  onDeleteVideo,
  onDownload,
  onEdit,
  onNextImage,
  onPrevImage,
  onRefresh,
  mediaItem,
}: SocialImageControlsProps) => {
  const { open } = useImageContext();
  const postType = MediaItem.getPostType({ mediaItem });
  const videoURL = MediaItem.getVideoURL({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const noOfImages = articleImages.length;

  const imageLimits = getImageQuantityLimits({
    apiTypeId,
    postType,
    socialChannel,
  });
  const imageSuffix = getImageSuffix(articleImages[0]);
  const videoThumbnail = getVideoThumbnail({
    socialChannel,
    isVideoUploaded: videoURL !== '',
  });

  const canAdd = canAddImage({
    apiTypeId,
    postType,
    socialChannel,
    videoURL,
  });
  const canDelete = {
    image: canDeleteImage({
      postType,
      noOfImages,
      minImages: imageLimits.min,
    }),
    video: postType === POST_TYPES.VIDEO && videoURL !== '',
  };
  const canEdit =
    noOfImages > 0 &&
    articleImages[0] !== videoThumbnail &&
    !isNullOrUndefined(imageSuffix) &&
    imageSuffix !== 'gif';
  const canSelect = noOfImages > 1;

  const cropImage = 'ic-crop';

  const hasVideoFile = videoURL !== '';
  const hasVideoThumbnail =
    noOfImages > 1 ||
    (articleImages.length > 0 && articleImages[0] !== videoThumbnail);
  const uploadCaption = getImageUploadCaption({
    postType,
    hasVideoFile,
    hasVideoThumbnail,
  });
  const uploadTooltip = getImageUploadTooltip({
    apiTypeId,
    postType,
    hasVideoFile,
    hasVideoThumbnail,
  });

  const isUneditable =
    mode === IMAGE_MODES.UNEDITABLE ||
    mode === IMAGE_MODES.UNEDITABLE_NO_REFRESH;
  const notRefresh = mode === IMAGE_MODES.UNEDITABLE_NO_REFRESH;

  const leftRightControlStyling: Partial<ComponentPropsWithoutRef<typeof Box>> =
    {
      position: 'absolute',
      top: '4',
      display: 'flex',
    };

  if (mode === IMAGE_MODES.EDIT || mode === IMAGE_MODES.CROP) {
    leftRightControlStyling.opacity = '0.5';
    leftRightControlStyling.pointerEvents = 'none';
  }

  const handleUpload = () => {
    if (postType === POST_TYPES.VIDEO && hasVideoFile && !hasVideoThumbnail) {
      const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
      const trackingParams = {
        'Social Network': getSocialNetworkName({ apiTypeId }),
        'Social Page': getAPIPostName({ accountAPIId }),
        'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
        'Account API Id': accountAPIId,
      };
      tracker.track({
        eventName: 'Change Video Thumbnail',
        trackingParams,
      });
    }
    open();
  };

  return (
    <>
      <Box
        {...leftRightControlStyling}
        left="3"
        className="add_photo_container hover_container"
      >
        {canAdd && !isUneditable && (
          <>
            <Tooltip
              id="image-add"
              label={uploadTooltip}
              isDisabled={!uploadTooltip}
            >
              <Button
                className="add_photo mt-1"
                onClick={handleUpload}
                data-tip
                data-for="image-add"
                data-iscapture="true"
              >
                <img src="/img/icons/ic-photo-add.svg" alt={uploadTooltip} />
                <span className="ml-2">{uploadCaption}</span>
              </Button>
            </Tooltip>
            &nbsp;
          </>
        )}
        {canEdit && !isUneditable && (
          <>
            <Tooltip id="image-edit" label="Edit image">
              <Button
                className="add_photo mt-1 mr-1"
                onClick={onEdit}
                data-tip
                data-for="image-edit"
                data-cy="edit-image-button"
                data-iscapture="true"
              >
                <img src="/img/icons/ic-edit.svg" alt="" />
                <span className="ml-2">Edit</span>
              </Button>
            </Tooltip>
            <Tooltip id="image-crop" label="Crop image">
              <Button
                className="add_photo mt-1"
                onClick={onCrop}
                data-tip
                data-for="image-crop"
                data-cy="crop-image-button"
                data-iscapture="true"
              >
                <img src={`/img/icons/${cropImage}.svg`} alt="Crop" />
              </Button>
            </Tooltip>
            &nbsp;
          </>
        )}
        {canSelect && !isUneditable && (
          <>
            <Button className="toggle_photo_left mt-1" onClick={onPrevImage}>
              <img src="/img/icons/ic-arrow-left-2-dark.svg" alt="" />
            </Button>
            <span className="info-light mt-1 mx-1">
              {`${currentImage + 1} of ${noOfImages}`}
            </span>
            <Button
              className="toggle_photo_right mr-1 mt-1"
              onClick={onNextImage}
            >
              <img src="/img/icons/ic-arrow-right-2-dark.svg" alt="" />
            </Button>
          </>
        )}
        {canDownload && (
          <ImageDownload
            imageURL={imageURL}
            isCropping={mode === IMAGE_MODES.CROP}
            isDownloading={isDownloading}
            isEditing={mode === IMAGE_MODES.EDIT}
            onDownload={onDownload}
          />
        )}
        {canDelete.image && !isUneditable && (
          <Tooltip id="image-delete" label="Delete image">
            <Button
              className="mt-1 ml-1"
              onClick={onDeleteImage}
              data-tip
              data-for="image-delete"
              data-iscapture="true"
            >
              <img src="/img/icons/ic-delete.svg" alt="" />
            </Button>
          </Tooltip>
        )}
      </Box>
      <Box {...leftRightControlStyling} right="3">
        {canDelete.video && !isUneditable && (
          <div className="hover_container ml-auto">
            <Tooltip id="image-delete" label="Delete video">
              <Button
                className="mt-1 ml-1"
                onClick={onDeleteVideo}
                data-tip
                data-for="image-delete"
                data-iscapture="true"
              >
                <img src="/img/icons/ic-delete.svg" alt="" />
              </Button>
            </Tooltip>
          </div>
        )}
        {postType === POST_TYPES.LINK && !notRefresh && (
          <div className="refresh_preview_container hover_container ml-auto mt-1">
            <Tooltip id="image-refresh" label="Refresh preview">
              <Button
                className="add_photo refresh_preview"
                aria-label="Refresh preview"
                onClick={onRefresh}
                data-tip
                data-for="image-refresh"
                data-iscapture="true"
              >
                <img
                  src="/img/icons/ic-refresh-dark.svg"
                  alt=""
                  className="icon-info"
                />
              </Button>
            </Tooltip>
          </div>
        )}
      </Box>
    </>
  );
};

export default memo(SocialImageControls, arePropsEqual);
