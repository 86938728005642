import {
  Th as BaseTh,
  TableColumnHeaderProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const Th = (props: TableColumnHeaderProps) => {
  return (
    <BaseTh
      display="flex"
      alignItems="center"
      textTransform="none"
      fontWeight="normal"
      fontSize="sm"
      lineHeight="5"
      py={3}
      px={4}
      height={12}
      letterSpacing="normal"
      color="gray.900"
      {...props}
    />
  );
};

export default Th;
