import {
  Button,
  FormControl,
  HStack,
  Input,
  Select,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import {
  API_PROPERTIES,
  API_TYPE_IDS,
  FLASH_MESSAGE_TYPES,
} from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import * as logger from 'common/logger';
import { ErrorWithMessageSchema, ErrorWithStatusSchema } from 'common/schemas';
import { convertToSocialPageURN } from 'common/urn';
import { location } from 'common/window';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { impersonateSocialPage } from 'process/impersonate';

const PLEASE_TRY_AGAIN =
  'Please check the ID and the network type are correct and try again. If the error persists, speak to someone in Tech.';

const ImpersonateSocialPageForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { global } = useGlobalInfo();
  const flashMessagesContext = useFlashMessagesContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    logger.info('EnhancedPermissionsMenu:handleImpersonateSocialPageSubmit');

    const target = event.target as typeof event.target & {
      socialPageId: { value: string };
      urnSocialNetworkType: { value: string };
    };

    const accountAPIId = target.socialPageId.value.trim();

    if (accountAPIId !== '') {
      setIsLoading(true);
      setErrorMessage(null);
      const urnSocialNetworkType = target.urnSocialNetworkType.value;

      try {
        // Attempt to log in to a specified social page
        await impersonateSocialPage({
          accountAPIId,
          urnSocialNetworkType,
          setGlobalInfo: global.setGlobalInfo,
        });
        flashMessagesContext.flashMessages.deleteMessages();
        if (window) {
          location.href = `/share/${accountAPIId}`;
        }
      } catch (error) {
        const parsedError = ErrorWithStatusSchema.safeParse(error);
        if (parsedError.success && parsedError.data.status === 403) {
          setErrorMessage(FRONTEND_ERROR_MESSAGES['impersonate-403']);
        } else if (parsedError.success && parsedError.data.status === 422) {
          const errorWithMessage = ErrorWithMessageSchema.safeParse(error);
          if (
            errorWithMessage.success &&
            errorWithMessage.data.error.message.match(
              /Unable to get Property from Social Page URN/g,
            )
          ) {
            setErrorMessage(
              `Could not find a property for the Social Page URN ${convertToSocialPageURN(
                urnSocialNetworkType,
                accountAPIId,
              )}. ${PLEASE_TRY_AGAIN}`,
            );
          } else if (
            errorWithMessage.success &&
            errorWithMessage.data.error.message.match(
              /Could not get users for Property/g,
            )
          ) {
            setErrorMessage(
              `Could not find any users for the Social Page URN ${convertToSocialPageURN(
                urnSocialNetworkType,
                accountAPIId,
              )}. ${PLEASE_TRY_AGAIN}`,
            );
          } else if (
            errorWithMessage.success &&
            errorWithMessage.data.error.message.match(
              /No Property level admin permissions for Property/g,
            )
          ) {
            setErrorMessage(
              `Could not find any property level admin users for the Social Page URN ${convertToSocialPageURN(
                urnSocialNetworkType,
                accountAPIId,
              )}. ${PLEASE_TRY_AGAIN}`,
            );
          } else if (
            errorWithMessage.success &&
            errorWithMessage.data.error.message.match(
              /Could not get user data from cache for/g,
            )
          ) {
            setErrorMessage(
              `Could not get the username for a user with access to the Social Page URN ${convertToSocialPageURN(
                urnSocialNetworkType,
                accountAPIId,
              )}. ${PLEASE_TRY_AGAIN}`,
            );
          } else {
            setErrorMessage(
              `Error impersonating the Social Page URN ${convertToSocialPageURN(
                urnSocialNetworkType,
                accountAPIId,
              )}. ${PLEASE_TRY_AGAIN}`,
            );
          }
        } else {
          flashMessagesContext.flashMessages.addMessage({
            messageCategory:
              'Impersonate social page POST unexpected impersonate error',
            type: FLASH_MESSAGE_TYPES.ERROR,
            text: getErrorMessage(determineError(error)),
            timeout: 2500,
          });
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isInvalid={errorMessage !== null}>
        <FormControl.FormLabel htmlFor="socialPageId">
          Impersonate a Social Page
        </FormControl.FormLabel>
        <HStack>
          <Input
            type="text"
            placeholder="Enter a Social Page Id..."
            id="socialPageId"
          />
          <Select flexShrink={0} id="urnSocialNetworkType">
            <option value={API_PROPERTIES[API_TYPE_IDS.FACEBOOK].urnName}>
              Facebook
            </option>
            <option value={API_PROPERTIES[API_TYPE_IDS.TWITTER].urnName}>
              Twitter
            </option>
            <option value={API_PROPERTIES[API_TYPE_IDS.LINKEDIN].urnName}>
              LinkedIn
            </option>
            <option value={API_PROPERTIES[API_TYPE_IDS.INSTAGRAM].urnName}>
              Instagram
            </option>
            <option value={API_PROPERTIES[API_TYPE_IDS.TIKTOK].urnName}>
              TikTok
            </option>
          </Select>
          <Button
            type="submit"
            variant="secondary"
            isLoading={isLoading}
            loadingText="Submitting"
            flexShrink={0}
          >
            Submit
          </Button>
        </HStack>
        {errorMessage && (
          <FormControl.FormErrorMessage>
            {errorMessage}
          </FormControl.FormErrorMessage>
        )}
      </FormControl>
    </form>
  );
};

export default ImpersonateSocialPageForm;
