import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedProps {
  children: React.ReactNode;
  redirector: () => { pathname: string; search?: string } | null | undefined;
}

const Protected = ({ children, redirector }: ProtectedProps) => {
  const redirectorResult = redirector();
  const location = useLocation();

  if (redirectorResult) {
    return (
      <Navigate
        replace
        to={{
          pathname: redirectorResult.pathname,
          // If there is no explicit parameters, maintain the query string
          search: redirectorResult.search ?? location.search,
        }}
        state={{ prevLocation: location }}
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment -- Without a fragment, usages of <Protected> flag an error.
  return <>{children}</>;
};

export default Protected;
