import {
  Table as BaseTable,
  TableProps as BaseTableProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ReactNode } from 'react';

interface TableProps extends BaseTableProps {
  children: ReactNode;
}
const Table = (props: TableProps) => {
  return (
    <BaseTable
      variant="unstyled"
      position="relative"
      _after={{
        content: '""',
        display: 'block',
        boxShadow: 'border',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'full',
        height: 'full',
        borderRadius: 'lg',
        pointerEvents: 'none',
      }}
      overflow="hidden"
      borderRadius="lg"
      sx={{ borderCollapse: 'separate', ...props.sx }}
      {...props}
    />
  );
};

export default Table;
