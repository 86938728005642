import { Flex, Image } from '@ebx-ui/ebx-ui-component-library-sdk';

import { addImagePrefix } from 'helpers/images';

interface ItemImageProps {
  imageURLs: Array<string>;
  isVideoLink: boolean;
}

const ItemImage = ({ imageURLs, isVideoLink }: ItemImageProps) => {
  const imageURL = addImagePrefix(imageURLs[0]);

  return (
    <div
      className="image order-2 order-md-1"
      style={{
        backgroundImage: `url('${imageURL}'),url(/img/placeholders/article-archive.svg)`,
      }}
    >
      {isVideoLink && (
        <Flex h="full" w="full" alignItems="center" justifyContent="center">
          <Image
            bottom={0}
            borderRadius="full"
            bg="rgba(235, 238, 245, 0.35)"
            p={1}
            src="/img/icons/video-icon.svg"
            alt=""
          />
        </Flex>
      )}
    </div>
  );
};

export default ItemImage;
