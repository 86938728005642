import migrateHealthMenuStorageStructure from './2024-05-change-health-menu-storage-structure';
import changeHealthMenuToastStorageKey from './2024-05-change-storage-key';

function run() {
  changeHealthMenuToastStorageKey();
  migrateHealthMenuStorageStructure();
}

export default {
  run,
};
