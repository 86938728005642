import { Box, Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

interface RemoveUserModalProps {
  onDismiss: () => void;
  handleUserDelete: () => void;
  userName: string;
}

const RemoveUserModal = ({
  onDismiss,
  handleUserDelete,
  userName,
}: RemoveUserModalProps) => {
  const boldText = {
    fontWeight: 'bold',
  };

  return (
    <Modal isOpen onClose={onDismiss} size="small">
      <Modal.Header>
        <Modal.Title>Remove {userName}</Modal.Title>
      </Modal.Header>
      <Modal.Body color="gray.900">
        <Box maxH="20px" fontSize="sm" lineHeight="base">
          You&apos;re about to remove <span style={boldText}>{userName}</span>{' '}
          from your Team
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleUserDelete}
          whiteSpace="normal"
          wordBreak="break-word"
        >
          Remove {userName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveUserModal;
