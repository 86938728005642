import {
  AddIcon,
  Button,
  CloseIcon,
  Flex,
  Heading,
  IconButton,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import {
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { AnalyticsItem } from 'common/constants';
import { getSocialNetworkName } from 'common/social';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import * as tracker from 'common/tracker';
import { hasActiveWebTag } from 'common/traffic';
import LegacyButton from 'components/misc/Button';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import { isWebtagInstalledByTagManager } from 'state/highPriorityErrorType';
import InstallWebTagModal from './InstallWebTagModal';

function getDefaultColumns(
  columns: Record<string, AnalyticsItem>,
): Record<string, AnalyticsItem> {
  return Object.entries(columns)
    .filter(([, col]) => col.default)
    .reduce<Record<string, AnalyticsItem>>((acc, [key, col]) => {
      acc[key] = col;
      return acc;
    }, {});
}

interface ColumnProps {
  name: string;
  mode: 'selected' | 'unselected';
  isDisabled?: boolean;
  onClick: () => void;
}
const Column = ({ name, mode, isDisabled, onClick }: ColumnProps) => (
  <Flex alignItems="center" gap={1} data-cy={`column-${mode}-${name}`}>
    <IconButton
      size="md"
      aria-label={`${mode === 'selected' ? 'Unselect' : 'Select'} ${name}`}
      icon={mode === 'selected' ? <CloseIcon /> : <AddIcon />}
      isDisabled={isDisabled}
      onClick={onClick}
    />
    <Text size="sm">{name}</Text>
  </Flex>
);

interface EditColumnsProps {
  /**
   * The currently configured columns for the analytics table.
   */
  initialColumns: string[];

  /**
   * A callback that is called when the user has changed the selected columns.
   */
  onSelectedColumnChange: (columns: string[]) => void;
}
const EditColumns = ({
  initialColumns,
  onSelectedColumnChange,
}: EditColumnsProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const accountAPIId = getCurrentAccountAPIId({ globalInfo });
  const apiTypeId = getAPITypeId({ accountAPIId, globalInfo });
  const propertyId = getCurrentPropertyId({ globalInfo });

  // Determine all the available columns for the current social network
  const availableColumns = getSocialAnalyticsInsights(apiTypeId) ?? {};

  // Get a list of the default columns
  const defaultColumns = getDefaultColumns(availableColumns);

  // Populate the initially selected details for those columns
  const initialColumnDetails = initialColumns.reduce<
    Record<string, AnalyticsItem>
  >(
    (acc, key) => ({
      ...acc,
      ...(availableColumns[key] && { [key]: availableColumns[key] }),
    }),
    {},
  );

  const [openModal, setOpenModal] = React.useState(false);
  const [selectedColumns, setSelectedColumns] =
    React.useState(initialColumnDetails);

  const selectedKeys = Object.keys(selectedColumns);
  const unselectedKeys = Object.keys(availableColumns).filter(
    (col) => !selectedKeys.includes(col),
  );

  const track = (eventName: string, params: Record<string, unknown>) => {
    tracker.track({
      eventName,
      trackingParams: {
        'Social Network': getSocialNetworkName({ apiTypeId }),
        'Social Page': getAPIPostName({ accountAPIId }),
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        ...params,
      },
    });
  };

  const onOpen = () => {
    track('Open Analytics Columns Dialog', {
      'Selected Columns': initialColumns,
    });
    setOpenModal(true);
  };

  const resetColumns = () => {
    setSelectedColumns(defaultColumns);
  };

  const onCancel = () => {
    setOpenModal(false);
    setSelectedColumns(initialColumnDetails);
  };

  const onSave = () => {
    track('Update Analytics Columns', {
      'Selected Columns Before': initialColumns,
      'Selected Columns After': selectedKeys,
    });
    onSelectedColumnChange(selectedKeys);
    setOpenModal(false);
  };

  return (
    <>
      <LegacyButton onClick={onOpen}>Edit columns</LegacyButton>
      {!isWebtagInstalledByTagManager(propertyId) &&
      !hasActiveWebTag(propertyId) ? (
        <InstallWebTagModal isOpen={openModal} onClose={onCancel} />
      ) : (
        <Modal isOpen={openModal} onClose={onCancel}>
          <Modal.Header>
            <Modal.Title>Edit columns</Modal.Title>
            <Modal.Subtitle>Select up to 4 metrics</Modal.Subtitle>
          </Modal.Header>
          <Modal.Body
            display="flex"
            flexDir="column"
            gap={3}
            flexShrink={0}
            {...(unselectedKeys.length > 0 && { boxShadow: 'borderBottom' })}
          >
            <Heading variant="h4">Selected</Heading>
            {Object.entries(selectedColumns).map(([key, col]) => (
              <Column
                key={key}
                mode="selected"
                name={col.name}
                onClick={() =>
                  setSelectedColumns((prev) => {
                    const newObj = { ...prev };
                    delete newObj[key];
                    return newObj;
                  })
                }
              />
            ))}
          </Modal.Body>
          {unselectedKeys.length > 0 && (
            <Modal.Body display="flex" flexDir="column" gap={3}>
              <Heading variant="h4">Unselected</Heading>
              {Object.entries(availableColumns)
                .filter(([key]) => !selectedKeys.includes(key))
                .sort(([, colA], [, colB]) =>
                  colA.name.localeCompare(colB.name),
                )
                .map(([key, col]) => (
                  <Column
                    key={key}
                    mode="unselected"
                    name={col.name}
                    onClick={() =>
                      setSelectedColumns((prev) => ({ ...prev, [key]: col }))
                    }
                    isDisabled={selectedKeys.length === 4}
                  />
                ))}
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              variant="link"
              mr="auto"
              isDisabled={
                // Check deviation from default columns (including order)
                `${selectedKeys}` === `${Object.keys(defaultColumns)}`
              }
              onClick={resetColumns}
            >
              Reset to default
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              isDisabled={
                selectedKeys.length === 0 ||
                // Check deviation from initial columns (including order)
                `${selectedKeys}` === `${initialColumns}`
              }
              onClick={onSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default EditColumns;
