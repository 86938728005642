import { TEXT_CASES } from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import { trackOpenArticle } from 'helpers/articleTracking';

interface ItemPublishedProps {
  articleURL: string;
  publishedTime: number;
  twelveHourFormat: boolean;
}

const ItemPublished = ({
  articleURL,
  publishedTime,
  twelveHourFormat,
}: ItemPublishedProps) => {
  return (
    <a
      href={articleURL}
      target="_blank"
      rel="noopener noreferrer"
      className="article_link link_grey"
      onClick={() => trackOpenArticle(articleURL, 'Search bar')}
      id="articleLink"
    >
      Published{' '}
      <span data-cy-id="lastPublished">
        {getFormattedDateFromUnix({
          timestamp: publishedTime,
          twelveHourFormat,
          textCase: TEXT_CASES.LOWER,
        })}
      </span>
    </a>
  );
};

export default ItemPublished;
