import {
  Button,
  ChevronDownIcon,
  Dropdown,
  LightingHighlightIcon,
  LightingOffIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { SHARE_TIME_TYPES } from 'common/constants';
import { ShareTimeType } from 'types';

interface TimingOptionsProps {
  value: ShareTimeType;
  onChange: (value: ShareTimeType) => void;
}

const TimingOptions = ({ value, onChange }: TimingOptionsProps) => {
  return (
    <Dropdown matchWidth>
      <Dropdown.Button
        as={Button}
        variant="secondary"
        leftIcon={
          value === SHARE_TIME_TYPES.OPTIMAL ? (
            <LightingHighlightIcon />
          ) : (
            <LightingOffIcon />
          )
        }
        rightIcon={<ChevronDownIcon />}
      >
        {value === SHARE_TIME_TYPES.OPTIMAL ? 'Optimal' : 'Now'}
      </Dropdown.Button>
      <Dropdown.List>
        <Dropdown.Group textTransform="uppercase" title="Share time">
          <Dropdown.Item
            LeftIcon={LightingHighlightIcon}
            onClick={() => onChange(SHARE_TIME_TYPES.OPTIMAL)}
          >
            Optimal
          </Dropdown.Item>
          <Dropdown.Item
            LeftIcon={LightingOffIcon}
            onClick={() => onChange(SHARE_TIME_TYPES.NOW)}
          >
            Now
          </Dropdown.Item>
        </Dropdown.Group>
      </Dropdown.List>
    </Dropdown>
  );
};

export default TimingOptions;
