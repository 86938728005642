/**
 * GET /pageinfo - Scrape Facebook page for meta data
 *
 * @param {string} pageId - Facebook page ID
 * @return {object} - Facebook page info
 */

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import * as cache from 'common/cache';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { SponsorPageInfoSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';
import type { SponsorPageInfo } from 'types';

export default async function getPageInfo({
  pageId,
}: {
  pageId: string;
}): Promise<SponsorPageInfo> {
  if (!pageId) {
    throw new ReferenceError('pageId is undefined');
  }
  const cached = cache.getCacheItem('getPageInfo', pageId);
  if (cached !== null) {
    return lyingParse(SponsorPageInfoSchema, cached);
  }
  const guid = metrics.start('getPageInfo');
  logger.info(`API:getPageInfo /pageinfo ${pageId}`);

  try {
    const response = await axios.get(
      `${constructBaseSocialAPIURL()}/pageinfo`,
      {
        timeout: API_TIMEOUTS.S,
        headers: getClientServiceRequestHeaders(),
        params: {
          pageId,
        },
      },
    );
    metrics.end('getPageInfo', guid);
    logger.debug('getPageInfo', { pageId });
    const validatedData = lyingParse(SponsorPageInfoSchema, response.data);
    cache.setCacheItem('getPageInfo', pageId, validatedData);
    return validatedData;
  } catch (error) {
    metrics.fail('getPageInfo', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPageInfo',
      args: { pageId },
    });
    throw apiError;
  }
}
