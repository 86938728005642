import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  RssFeedIcon,
  SitemapIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import { RSS_STATE_NAMES } from 'common/constants';
import { getPagesByFeed, isRSSFeed } from 'common/feed';
import GlobalInfoContext from 'context/GlobalInfoContext';
import type { AccountFeed, RssFeedData } from 'types';

import ConnectedFeedsTag from './ConnectedFeedsTag';

const getAllRSSFeeds = (rssFeeds: AccountFeed[] = []) => {
  return rssFeeds.filter(
    (feed) =>
      feed.syndFeedState === RSS_STATE_NAMES.ACTIVE ||
      feed.syndFeedState === RSS_STATE_NAMES.ERROR,
  );
};

const sortAccountFeeds = (feeds: AccountFeed[]) => {
  return feeds.sort((a, b) => {
    if (!a.syndFeedTitle && !b.syndFeedTitle) {
      return a.syndFeedURL < b.syndFeedURL ? -1 : 1;
    }
    const [syndFeedTitleA, syndFeedTitleB] = [
      a.syndFeedTitle?.toUpperCase().trim(),
      b.syndFeedTitle?.toUpperCase().trim(),
    ];

    if (!syndFeedTitleA && syndFeedTitleB) {
      return 1;
    }

    if (syndFeedTitleA && !syndFeedTitleB) {
      return -1;
    }

    if (!syndFeedTitleA || !syndFeedTitleB) {
      return 0;
    }

    if (syndFeedTitleA > syndFeedTitleB) {
      return 1;
    }
    if (syndFeedTitleA < syndFeedTitleB) {
      return -1;
    }
    return 0;
  });
};

interface ConnectedFeedsProps {
  onEditFeed: (feedToEdit: RssFeedData) => void;
}

const ConnectedFeeds = ({ onEditFeed }: ConnectedFeedsProps) => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const { accountAPIs, accountFeeds } = getCurrentProperty({
    globalInfo,
  });

  const allFeeds = getAllRSSFeeds(accountFeeds);
  const sortedFeeds = sortAccountFeeds(allFeeds);

  const pagesByFeed = getPagesByFeed({
    feeds: allFeeds,
    accountAPIs,
  });

  const handleEdit = ({
    syndFeedURN,
    syndFeedURL,
    syndFeedTitle,
    syndFeedType,
  }: AccountFeed) => {
    const feedData = {
      syndFeedURN,
      syndFeedURL,
      syndFeedTitle: syndFeedTitle?.replace('|', '-') ?? null,
      syndFeedType,
    };

    onEditFeed(feedData);
  };

  return (
    <Flex flexDir="column" gap={3} w="full">
      {sortedFeeds.map((feed) => (
        <Flex
          key={feed.syndFeedURN}
          data-cy-attribute={`feedURL:${feed.syndFeedURL}`}
          bg="base"
          p={5}
          boxShadow="border"
          borderRadius="md"
          align="start"
          gap={3}
        >
          <Box>
            {isRSSFeed(feed.syndFeedType) ? (
              <RssFeedIcon size={5} />
            ) : (
              <SitemapIcon size={5} />
            )}
          </Box>
          <Flex flexDir="column" gap={3} align="start">
            <Flex flexDir="column" gap={1} align="start" wordBreak="break-all">
              {feed.syndFeedTitle ? (
                <>
                  <Heading variant="h4">
                    {feed.syndFeedTitle.replace('|', '-')}
                  </Heading>
                  <Text
                    as={Link}
                    href={feed.syndFeedURL}
                    size="sm"
                    color="gray.600"
                    _hover={{ textDecoration: 'underline' }}
                  >
                    {feed.syndFeedURL}
                  </Text>
                </>
              ) : (
                <Heading as={Link} href={feed.syndFeedURL} variant="h4">
                  {feed.syndFeedURL}
                </Heading>
              )}
            </Flex>
            <ConnectedFeedsTag
              feedState={feed.syndFeedState}
              totalConnectedPages={pagesByFeed[feed.syndFeedURN].length}
            />
          </Flex>
          <Button
            ml="auto"
            data-cy-action="editFeed"
            variant="secondary"
            onClick={() => handleEdit(feed)}
          >
            Edit
          </Button>
        </Flex>
      ))}
    </Flex>
  );
};

export default ConnectedFeeds;
