import { z } from 'zod';

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ data: z.string() })
  .describe('getDebugMediaItem.ResponseSchema');

interface GetDebugMediaItemOptions {
  /**
   * The media item ID or URL
   */
  mediaItemIdentifier: string;
}

/**
 * GET /v3.3/social/debugmediaitem/{mediaItemIdentifier}
 *
 * Retrieves the debug information for a media item.
 */
export default async function getDebugMediaItem({
  mediaItemIdentifier,
}: GetDebugMediaItemOptions) {
  if (isUndefined(mediaItemIdentifier)) {
    mandatory('mediaItemIdentifier');
  }

  const guid = metrics.start('getDebugMediaItem');
  logger.info(
    `API:getDebugMediaItem /v3.3/social/debugmediaitem/${mediaItemIdentifier}`,
  );

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.3',
    )}/social/debugmediaitem/${encodeURIComponent(mediaItemIdentifier)}`,
    method: 'GET',
    timeout: API_TIMEOUTS.XXXL,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    const response = await axios(config);
    metrics.end('getDebugMediaItem', guid);

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData.data;
  } catch (error) {
    metrics.fail('getDebugMediaItem', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getDebugMediaItem',
      args: {
        mediaItemIdentifier,
      },
    });
    throw apiError;
  }
}
