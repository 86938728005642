import PubSub from 'pubsub-js';
import { OFFLINE_DETECTED, ONLINE_DETECTED } from 'pubsub/topics';

import * as logger from './logger';

const TARGET_URL = '/health';
const TIMEOUT = 5000;
const INTERVAL = 10000;

let isPolling = false;
let fails: number = 0;
// eslint-disable-next-line no-underscore-dangle -- Distinguishing from public method of the same name
let _isOnline = true;

function ping(): Promise<void> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const handleFailure = () => {
      fails += 1;
      reject();
    };
    const handleSuccess = () => {
      fails = 0;
      resolve();
    };
    xhr.onerror = handleFailure;
    xhr.ontimeout = handleFailure;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          handleSuccess();
        } else {
          handleFailure();
        }
      }
    };

    xhr.open('HEAD', TARGET_URL);
    xhr.timeout = TIMEOUT;
    xhr.send();
  });
}

function startPolling() {
  setInterval(async () => {
    try {
      await ping();
      if (!_isOnline) {
        PubSub.publish(ONLINE_DETECTED);
        _isOnline = true;
      }
    } catch {
      // Only mark as offline if it's failed more than 3 times.
      if (_isOnline && fails > 3) {
        PubSub.publish(OFFLINE_DETECTED);
        _isOnline = false;
      }
    }
  }, INTERVAL);
}

/**
 * Will begin polling `/health` to detect a disconnected network.
 * PubSub topics 'OFFLINE_DETECTED' and 'ONLINE_DETECTED' will be triggered in the event of online/offline changes.
 */
export function initializeOfflineDetection() {
  if (!isPolling) {
    try {
      startPolling();
      isPolling = true;
    } catch (error) {
      logger.error({ event: 'Failed to poll for offline detection', error });
    }
  }
}

/**
 * Gets the current online status.
 */
export function isOnline() {
  return _isOnline;
}
