import {
  Button,
  ButtonGroup,
  ChevronDownIcon,
  DeleteTrashIcon,
  Dropdown,
  IgPostIcon,
  ImageIcon,
  ReelIcon,
  UndoIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useInstantVideoTracking } from 'components/instantvideo/useInstantVideoTracking';

interface InstantShareButtonProps {
  articleURL: string;
  isSaving: boolean;
  isDeleted: boolean;
  onShareClick: () => void;
  onImageClick: () => void;
  onReelsClick: (() => void) | undefined;
  onDeleteClick: () => void;
  onRestoreClick: () => void;
}
const InstantShareButton = ({
  articleURL,
  isSaving,
  isDeleted,
  onShareClick,
  onImageClick,
  onReelsClick,
  onDeleteClick,
  onRestoreClick,
}: InstantShareButtonProps) => {
  const { trackGenerateVideo } = useInstantVideoTracking();

  const handleReelsClick = () => {
    trackGenerateVideo({ articleURL });
    onReelsClick!();
  };

  return (
    <ButtonGroup
      isAttached
      ml="auto"
      opacity={0}
      // Show the button when the parent card is hovered
      _groupHover={{ opacity: 1 }}
      sx={{
        // Keep the button visible when children has focus (via keyboard navigation)
        // This differs from _focusWithin which includes focus by mouse click
        ':has(:focus-visible)': {
          opacity: 1,
        },
        // Keep the button visible when the dropdown is active
        ':has([data-active])': {
          opacity: 1,
        },
      }}
    >
      <Button
        variant="secondary"
        py={1.5}
        px={3}
        isDisabled={isSaving}
        onClick={onShareClick}
        sx={{
          // Remove right side border radius on hover
          // TODO: figure out a way to do this in the Button/ButtonGroup component itself
          ':hover::after': {
            borderStartEndRadius: 0,
            borderEndEndRadius: 0,
          },
        }}
      >
        Share
      </Button>
      <Dropdown size="md" placement="bottom-start">
        <Dropdown.Button
          as={Button}
          size="md"
          aria-label="More options"
          variant="secondary"
          isDisabled={isSaving}
          py={1.5}
          px={3}
          ml="-1px" // to remove the double inner box shadow
          sx={{
            ':hover::after': {
              borderStartStartRadius: 0,
              borderEndStartRadius: 0,
            },
          }}
        >
          <ChevronDownIcon />
        </Dropdown.Button>
        <Dropdown.List>
          <Dropdown.Title title="Share link as">Share link as</Dropdown.Title>
          <Dropdown.Item LeftIcon={IgPostIcon} onClick={onShareClick}>
            Post
          </Dropdown.Item>
          <Dropdown.Item LeftIcon={ImageIcon} onClick={onImageClick}>
            Image
          </Dropdown.Item>
          {onReelsClick && (
            <Dropdown.Item LeftIcon={ReelIcon} onClick={handleReelsClick}>
              Reel
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          {isDeleted ? (
            <Dropdown.Item LeftIcon={UndoIcon} onClick={onRestoreClick}>
              Restore
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              LeftIcon={DeleteTrashIcon}
              color="red.600"
              onClick={onDeleteClick}
            >
              Delete
            </Dropdown.Item>
          )}
        </Dropdown.List>
      </Dropdown>
    </ButtonGroup>
  );
};

export default InstantShareButton;
