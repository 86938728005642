import { GlobalInfo, HealthMenuNotificationStorage, SocialAPI } from 'types';
import {
  getAPIsByNetwork,
  getCurrentProperty,
  getEnabledTwitterApp,
  sortPages,
} from './accountAPIs';
import {
  API_STATES,
  TWITTER_PAGE_DISCONNECTED_ERROR_MESSAGES,
} from './constants';
import { getGlobalInfo } from './globalInfo';

/**
 * Get all account APIs that are disconnected
 * @returns An array of disconnected Account APIs
 */
export function getAllDisconnectedAccountAPIs() {
  const apisByNetwork = getAPIsByNetwork();
  const accountAPIsForCurrentProperty = Object.values(apisByNetwork).reduce(
    (acc, accountAPIs) => acc.concat(accountAPIs.sort(sortPages)),
    [],
  );
  const disconnectedAccountAPIs = accountAPIsForCurrentProperty.filter(
    (accountAPI) =>
      accountAPI.requiresReconnect &&
      accountAPI.notifications &&
      accountAPI.notifications.length > 0,
  );

  return disconnectedAccountAPIs;
}

/**
 * Get all account APIs that have been disconnected due to an incorrectly configured Twitter App.
 * @returns An array of disconnected Account APIs
 */
export function getAllTwitterAppDisconnectedAccountAPIs() {
  const apisByNetwork = getAPIsByNetwork();
  const accountAPIsForCurrentProperty = Object.values(apisByNetwork).reduce(
    (acc, accountAPIs) => acc.concat(accountAPIs.sort(sortPages)),
    [],
  );
  const disconnectedAccountAPIs = accountAPIsForCurrentProperty.filter(
    (accountAPI) =>
      accountAPI.requiresReconnect &&
      accountAPI.notifications &&
      accountAPI.notifications.length > 0 &&
      TWITTER_PAGE_DISCONNECTED_ERROR_MESSAGES.some((item) =>
        accountAPI.disconnectReason?.toLowerCase().includes(item.toLowerCase()),
      ),
  );

  return disconnectedAccountAPIs;
}

/**
 * Construct a map of account API IDs to disconnect reasons.
 */
export function getDisconnectReasonsByAccountAPI(
  disconnectedAccountAPIs: SocialAPI[],
) {
  const disconnectReasonsByAccountAPIIds = disconnectedAccountAPIs.reduce<
    Record<string, string>
  >((prev, accountAPI) => {
    return {
      ...prev,
      [accountAPI.accountAPIId]: accountAPI.disconnectReason,
    };
  }, {});

  return disconnectReasonsByAccountAPIIds;
}

/**
 * Filters all disconnected account APIs that have not been seen/acknowledged by the user.
 *
 * @param disconnectedAccountAPIs An array of disconnected Account APIs
 * @param seenNotifications A map of account API IDs to disconnect reasons that have been seen/acknowledged by the user.
 */
export function getUnseenDisconnectionNotifications(
  disconnectedAccountAPIs: SocialAPI[],
  seenNotifications: HealthMenuNotificationStorage[string],
) {
  const unseenNotifications = disconnectedAccountAPIs.filter((accountAPI) => {
    const { accountAPIId, disconnectReason } = accountAPI;
    const disconnections = seenNotifications.DISCONNECTIONS;
    const hasNotificationBeenSeen =
      disconnections &&
      accountAPIId in disconnections &&
      disconnectReason === disconnections[accountAPIId];

    return !hasNotificationBeenSeen;
  });

  return unseenNotifications;
}

export function isTwitterAppDisconnected({
  globalInfo = getGlobalInfo(),
}: { globalInfo?: GlobalInfo.GlobalInfo } = {}) {
  const accountAPIs = getCurrentProperty({ globalInfo }).accountAPIs;
  const twitterApp = getEnabledTwitterApp({ accountAPIs });

  if (twitterApp != null) {
    return twitterApp.apiStateId === API_STATES.BAD_AUTH;
  }

  return false;
}
