import { useEffect, useState } from 'react';

export interface UseOnKeyProps {
  targetKey: string;
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
}

const useOnKey = ({ targetKey, onKeyDown, onKeyUp }: UseOnKeyProps) => {
  // State variable used to track which key is currently pressed
  const [isKeyDown, setIsKeyDown] = useState(false);

  useEffect(() => {
    const onKeyDownLocal = (event: KeyboardEvent) => {
      if (event.key !== targetKey) return;
      // Update key state if this is the key we are looking for
      setIsKeyDown(true);
      if (typeof onKeyDown !== 'function') return;
      // Call the provided callback, if any
      onKeyDown(event);
    };
    const onKeyUpLocal = (event: KeyboardEvent) => {
      if (event.key !== targetKey) return;
      // Update key state if this is the key we are looking for
      setIsKeyDown(false);
      if (typeof onKeyUp !== 'function') return;
      // Call the provided callback, if any
      onKeyUp(event);
    };

    // Mount - listen for key events
    window.addEventListener('keydown', onKeyDownLocal);
    window.addEventListener('keyup', onKeyUpLocal);

    // Unmount - detach event listeners
    return () => {
      window.removeEventListener('keydown', onKeyDownLocal);
      window.removeEventListener('keyup', onKeyUpLocal);
    };
  }, [targetKey, onKeyDown, onKeyUp]);

  return isKeyDown;
};

export default useOnKey;
