import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';

import { TEXT_CASES } from 'common/constants';
import { getSocialNetworkName } from 'common/social';
import { getDefaultColumns } from 'helpers/analyticsPage';

interface LoadingProps {
  apiTypeId: number;
  mediaId: string;
  insightColumns?: Record<string, string[]>;
}

const Loading = ({ apiTypeId, mediaId, insightColumns }: LoadingProps) => {
  const networkName = getSocialNetworkName({
    apiTypeId,
    textCase: TEXT_CASES.LOWER,
  });
  const insightKeys =
    insightColumns?.[networkName] ?? getDefaultColumns({ apiTypeId });
  // Count the metrics plus 1 for the initial media item details cell
  const cols = 1 + insightKeys.length;

  return (
    <tbody
      className="article"
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <tr className="first-row" data-cy-attribute="isLoading:true">
        <td colSpan={cols}>
          <div
            className="empty_feed text-center my-4 py-1"
            data-cy-attribute="isLoading:true"
          >
            <Spinner size="lg" />
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default Loading;
