import {
  AUTOFEED_SHARE_MODES,
  DATA_SOURCE_DEFAULTS,
  POST_TYPES,
  SOCIAL_CHANNELS,
  SPECIAL_CHARACTER_REGEX,
  TAG_TYPES,
} from 'common/constants/common';

const constants = {
  urnName: 'fb',
  apiType: 'FACEBOOK',
  name: 'Facebook',
  lowerName: 'facebook',
  icon: '/img/icons/ic-facebook.svg',
  defaultPageIcon: '/img/blank.png',
  emojiSet: 'facebook',
  /* jscpd:ignore-start */
  autofeedDefaults: {
    autoCapitaliseMessage: false,
    autoFeedActive: false,
    bestArticlesNewContentNum: 48,
    bestContentOnlySinceCurrentOnPeriod: false,
    breakingNewsIntervalSecs: null,
    bestArticlesReshareNum: 3,
    excludeAllContentContaining: [],
    excludeAllContentURLPathContaining: [],
    excludeReshareContentContaining: [],
    excludeReshareContentURLPathContaining: [],
    excludeImagesContaining: [],
    includeImages: true,
    includeMessage: true,
    includeOnlyContentContaining: [],
    includeOnlyContentFromRSSFeeds: [],
    includeOnlyContentURLPathContaining: [],
    maxResharesPerArticle: 5,
    messageTextToRemove: [],
    minMinsBetweenSharesOfSameArticle: 14 * 24 * 60,
    newshareMessagePrefix: '',
    newshareMessageSuffix: '',
    newShareMode: AUTOFEED_SHARE_MODES.OPTIMAL,
    prioritiseCuratedContent: false,
    reshareMessagePrefix: '',
    reshareMessageSuffix: '',
    reshareMode: AUTOFEED_SHARE_MODES.OPTIMAL,
    shareNowAllContentContaining: [],
    shareNowAllContentURLPathContaining: [],
    startPastMinsForBestArticleReshare: 80 * 24 * 60,
    startPastMinsForNewShare: 2 * 24 * 60,
    statusScheduleTriggers: [],
  },
  autofeedMessageSources: {
    ARTICLE_QUOTE: 'Article Quote',
    RSS_TITLE: 'RSS Title',
    RSS_DESCRIPTION: 'RSS Description',
    OG_TITLE: 'OG Title',
    OG_DESCRIPTION: 'OG Description',
    TWITTER_TITLE: 'Twitter Title',
    TWITTER_DESCRIPTION: 'Twitter Description',
    NULL: 'Keep Empty',
  },
  appendSpace: false,
  appendMentionsAtSymbol: false,
  canAddVideoThumbnail: true,
  canDeletePosts: true,
  canHaveMultipleAccounts: true,
  canGenerateAIMessage: true,
  canResharePosts: true,
  canReshareVideos: true,
  canShareNow: true,
  /* jscpd:ignore-end */
  dataSourceDefaults: {
    missing: {
      description: 7, // NULL = Keep Empty
      image: DATA_SOURCE_DEFAULTS.IMAGE_SOURCE,
      message: 7, // NULL = Keep Empty
      title: 7, // NULL = Keep Empty
    },
    empty: {
      description: DATA_SOURCE_DEFAULTS.DESCRIPTION_SOURCE,
      message: DATA_SOURCE_DEFAULTS.MESSAGE_SOURCE,
      image: DATA_SOURCE_DEFAULTS.IMAGE_SOURCE,
      title: DATA_SOURCE_DEFAULTS.TITLE_SOURCE,
    },
  },
  defaultImageSource: DATA_SOURCE_DEFAULTS.IMAGE_SOURCE,
  descriptionSourceExclusions: ['OPTIMAL_MESSAGE'],
  entityName: 'page',
  excludeImagesRule:
    'Articles with image URLs that contain the following keywords will not be shared by the Autofeed:',
  firstCommentSettings: {
    maximumLength: 8000,
  },
  hasABVariations: {
    [SOCIAL_CHANNELS.FEED]: true,
    [SOCIAL_CHANNELS.REEL]: false,
  },
  hasImageOverlays: true,
  hasAudienceRestriction: true,
  hasCharacterCount: false,
  hasDescriptionField: true,
  hasDomainField: true,
  hasHashtagsMentions: true,
  hasLinkPlaceholder: false,
  hasMentionsLookups: true,
  hasMessageBeforeImage: true,
  hasMessageField: true,
  hasShareMessages: true,
  hasSponsoredPosts: true,
  hasSyndFeeds: true,
  hasTitleField: true,
  hasTitleFieldCharacterCount: true,
  hasVerifiedAccounts: true,
  hasVideoTitle: {
    [SOCIAL_CHANNELS.REEL]: false,
    [SOCIAL_CHANNELS.FEED]: true,
  },
  imageAspectRatioDefaults: {
    [POST_TYPES.LINK]: {
      default: null,
    },
    [POST_TYPES.STATUS]: {
      default: null,
    },
    [POST_TYPES.VIDEO]: {
      default: null,
    },
  },
  imageQuantityLimits: {
    defaults: {
      min: 1,
      max: 1,
    },
    postTypeSpecific: {
      [POST_TYPES.STATUS]: {
        min: 0,
        max: 50,
      },
    },
  },
  imageSources: {
    RSS_IMAGE_LARGEST: 'RSS Image',
    OG_IMAGE_LARGEST: 'OG Image',
    TWITTER_IMAGE_LARGEST: 'Twitter Image',
  },
  isSocialNetwork: true,
  isValidAspectRatioMandatory: {
    [POST_TYPES.LINK]: false,
    [POST_TYPES.STATUS]: true,
    [POST_TYPES.VIDEO]: true,
  },
  messageSources: {
    OPTIMAL_MESSAGE: 'ChatGPT',
    RSS_TITLE: 'RSS Title',
    RSS_DESCRIPTION: 'RSS Description',
    OG_TITLE: 'OG Title',
    OG_DESCRIPTION: 'OG Description',
    TWITTER_TITLE: 'Twitter Title',
    TWITTER_DESCRIPTION: 'Twitter Description',
    NULL: 'Keep Empty',
  },
  pageURLTemplate: 'https://www.facebook.com/PAGENAME',
  placeholderText: {
    [POST_TYPES.STATUS]: ['JPG, PNG, GIF or WEBP files', '10MB max'],
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: ['MP4 or MOV files', '2GB max'],
      [SOCIAL_CHANNELS.REEL]: [
        'MP4 or MOV files',
        '3 to 90 seconds',
        '9:16 aspect ratio',
        '1GB max',
      ],
    },
  },
  /* jscpd:ignore-start */
  postTypes: {
    [POST_TYPES.LINK]: 'Link',
    [POST_TYPES.STATUS]: 'Photo/Status',
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: 'Video',
      [SOCIAL_CHANNELS.REEL]: 'Reel',
    },
  },
  lastSharedLabel: {
    [POST_TYPES.LINK]: 'Link',
    [POST_TYPES.STATUS]: 'Photo/Status',
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: 'Video Feed',
      [SOCIAL_CHANNELS.REEL]: 'Reel',
      [SOCIAL_CHANNELS.STORY]: null,
    },
  },
  shareIcon: {
    [POST_TYPES.STATUS]: '/img/icons/ic-photo.svg',
    [POST_TYPES.VIDEO]: '/img/icons/ic-camera.svg',
  },
  showOnSetupPage: true,
  supportedSocialChannels: [SOCIAL_CHANNELS.FEED, SOCIAL_CHANNELS.REEL],
  tagPatterns: {
    [TAG_TYPES.HASHTAG]: new RegExp(`#(${SPECIAL_CHARACTER_REGEX})`, 'g'),
    [TAG_TYPES.MENTION]: new RegExp(`@\\[(${SPECIAL_CHARACTER_REGEX})\\]`, 'g'),
  },
  /* jscpd:ignore-end */
  titleSourceExclusions: ['OPTIMAL_MESSAGE'],
  validationMessages: {
    videoAspectRatio: {
      [SOCIAL_CHANNELS.REEL]:
        'Facebook requires Reels have a 9:16 aspect ratio',
    },
    videoFileType: {
      [SOCIAL_CHANNELS.REEL]:
        'Facebook requires Reels be of the filetype .MP4 or .MOV',
      [SOCIAL_CHANNELS.FEED]:
        'Facebook requires videos be of the filetype .MP4 or .MOV',
    },
    videoDuration: {
      [SOCIAL_CHANNELS.REEL]:
        'Facebook requires Reels be between 3 and 90 seconds',
    },
    videoLargeFileSize: {
      [SOCIAL_CHANNELS.REEL]: 'Reels must have a file size less than 1GB',
      [SOCIAL_CHANNELS.FEED]: 'Videos must have a file size less than 1GB',
    },
    videoMissing: {
      [SOCIAL_CHANNELS.REEL]: 'Please ensure your Reel includes a video',
    },
    imageLargeFileSize: {
      [SOCIAL_CHANNELS.FEED]: 'Images must have a file size less than 4MB',
    },
  },
} as const;

export default constants;
