/* eslint no-use-before-define:"off" */

import { ANALYTICS_SETTINGS } from 'common/config';
import { AB_TEST_STATUSES, POST_TYPES } from 'common/constants';
import {
  createTimestamp,
  getDate,
  getDatePlus,
  toLocalString,
} from 'common/datetimepicker';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import { mandatory } from 'common/validation';

export { defaultDateRange, getABDetails, getDefaultColumns };

function defaultDateRange() {
  const local = toLocalString(new Date());
  // Default to date = today
  const defaultTo = createTimestamp({
    date: getDate(local),
    time: '23:59',
  });
  // Default from date = one week ago
  const defaultFrom =
    getDatePlus(
      defaultTo,
      -ANALYTICS_SETTINGS.MAXIMUM_CUSTOM_DATE_DURATION_DAYS,
    ) + 60; // Plus one minute to make the start time 00:00
  return { defaultFrom, defaultTo };
}

function getDefaultColumns({ apiTypeId }) {
  const columns = getSocialAnalyticsInsights(apiTypeId) ?? {};
  return Object.entries(columns)
    .filter(([, col]) => col.default)
    .map(([key]) => key);
}

/**
 * @param {{
 * abVariations: import('types').ABVariations;
 * abResults: ABResults;
 * abTestStatusId: number;
 * postType: import('types').PostType;
 * }}
 */
function getABDetails({
  abVariations = mandatory('abVariations'),
  abResults = mandatory('abResults'),
  abTestStatusId = mandatory('abTestStatusId'),
  postType = mandatory('postType'),
}) {
  if (abResults.length > 0) {
    return [
      {
        ctrClass: abResults[0].ctr >= abResults[1].ctr ? 'winner' : 'loser',
        ctrLabel:
          postType !== POST_TYPES.VIDEO
            ? `${(abResults[0].ctr * 100).toFixed(1)}% CTR`
            : `${(abResults[0].ctr * 100).toFixed(1)}% viewed`,
        description: abResults[0].description,
        imageURL: abResults[0].imageURL,
        imageOverlayURL: abVariations[0].imageOverlayURL,
        message: abResults[0].message,
        title: abResults[0].title,
      },
      {
        ctrClass: abResults[1].ctr > abResults[0].ctr ? 'winner' : 'loser',
        ctrLabel:
          postType !== POST_TYPES.VIDEO
            ? `${(abResults[1].ctr * 100).toFixed(1)}% CTR`
            : `${(abResults[1].ctr * 100).toFixed(1)}% viewed`,
        description: abResults[1].description,
        imageURL: abResults[1].imageURL,
        imageOverlayURL: abVariations[1].imageOverlayURL,
        message: abResults[1].message,
        title: abResults[1].title,
      },
    ];
  }
  if (abTestStatusId === AB_TEST_STATUSES.ERROR) {
    return [
      {
        ctrClass: 'failed',
        ctrLabel: 'CTR - Results inconclusive',
        description: abVariations[0].description,
        imageURL: abVariations[0].imageURLs[0],
        imageOverlayURL: abVariations[0].imageOverlayURL,
        message: abVariations[0].message,
        title: abVariations[0].title,
      },
      {
        ctrClass: 'failed',
        ctrLabel: 'CTR - Results inconclusive',
        description: abVariations[1].description,
        imageURL: abVariations[1].imageURLs[0],
        imageOverlayURL: abVariations[1].imageOverlayURL,
        message: abVariations[1].message,
        title: abVariations[1].title,
      },
    ];
  }
  return [
    {
      ctrClass: 'progress',
      ctrLabel: 'CTR - In progress',
      description: abVariations[0].description,
      imageURL: abVariations[0].imageURLs[0],
      imageOverlayURL: abVariations[0].imageOverlayURL,
      message: abVariations[0].message,
      title: abVariations[0].title,
    },
    {
      ctrClass: 'progress',
      ctrLabel: 'CTR - In progress',
      description: abVariations[1].description,
      imageURL: abVariations[1].imageURLs[0],
      imageOverlayURL: abVariations[1].imageOverlayURL,
      message: abVariations[1].message,
      title: abVariations[1].title,
    },
  ];
}
