import {
  Box,
  Button,
  Center,
  EmptyState,
  Flex,
  FormControl,
  Image,
  Input,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import getPreviewMetadata from 'api/getPreviewMetaData';
import { getAPITypeId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import * as tracker from 'common/tracker';
import { isValidURL } from 'common/url';
import { isRunningTests } from 'common/utility';
import { getNetworkAndPageName } from 'helpers/tracking';
import type {
  DataSourceSettings,
  DescriptionSources,
  FixTypeLater,
  ImageSources,
  MediaPreviewMetadata,
  MessageSources,
  TitleSources,
} from 'types';

interface PreviewProps {
  dataSourceSettings: DataSourceSettings;
  metadata: MediaPreviewMetadata | null;
  onMetadataChange: (metadata: MediaPreviewMetadata | null) => void;
  onPreview?: () => void;
}

const getMessage = (
  metadata: MediaPreviewMetadata,
  dataSourceSettings: DataSourceSettings,
): string | undefined => {
  const messageMap: Record<MessageSources, string | undefined> = {
    NULL: '',
    OG_DESCRIPTION: metadata.ogMetaInfo?.description,
    OG_TITLE: metadata.ogMetaInfo?.title,
    OPTIMAL_MESSAGE: metadata.ogMetaInfo?.title,
    RSS_DESCRIPTION: metadata.syndfeedMetaInfo?.description,
    RSS_TITLE: metadata.syndfeedMetaInfo?.title,
    TWITTER_DESCRIPTION: metadata.twitterCardMetaInfo?.description,
    TWITTER_TITLE: metadata.twitterCardMetaInfo?.title,
  };

  return messageMap[dataSourceSettings.messageSource];
};

const getImage = (
  metadata: MediaPreviewMetadata,
  dataSourceSettings: DataSourceSettings,
): string | undefined => {
  const imageMap: Record<ImageSources, string | undefined> = {
    NULL: metadata.twitterCardMetaInfo?.imageURL,
    OG_IMAGE_LARGEST: metadata.ogMetaInfo?.imageURL,
    RSS_IMAGE_LARGEST: metadata.syndfeedMetaInfo?.imageURL,
    TWITTER_IMAGE_LARGEST: metadata.twitterCardMetaInfo?.imageURL,
  };

  return imageMap[dataSourceSettings.imageSource ?? 'NULL'];
};

const getTitle = (
  metadata: MediaPreviewMetadata,
  dataSourceSettings: DataSourceSettings,
): string | undefined => {
  const titleMap: Record<TitleSources, string | undefined> = {
    NULL: '',
    OG_TITLE: metadata.ogMetaInfo?.title,
    OG_DESCRIPTION: metadata.ogMetaInfo?.description,
    RSS_DESCRIPTION: metadata.syndfeedMetaInfo?.description,
    RSS_TITLE: metadata.syndfeedMetaInfo?.title,
    TWITTER_TITLE: metadata.twitterCardMetaInfo?.title,
    TWITTER_DESCRIPTION: metadata.twitterCardMetaInfo?.description,
  };

  return titleMap[dataSourceSettings.titleSource];
};

const getDescription = (
  metadata: MediaPreviewMetadata,
  dataSourceSettings: DataSourceSettings,
): string | undefined => {
  const descriptionMap: Record<DescriptionSources, string | undefined> = {
    NULL: '',
    OG_TITLE: metadata.ogMetaInfo?.title,
    OG_DESCRIPTION: metadata.ogMetaInfo?.description,
    RSS_DESCRIPTION: metadata.syndfeedMetaInfo?.description,
    RSS_TITLE: metadata.syndfeedMetaInfo?.title,
    TWITTER_TITLE: metadata.twitterCardMetaInfo?.title,
    TWITTER_DESCRIPTION: metadata.twitterCardMetaInfo?.description,
  };

  return descriptionMap[dataSourceSettings.descriptionSource];
};

const Preview = ({
  dataSourceSettings,
  metadata,
  onMetadataChange,
  onPreview,
}: PreviewProps) => {
  const [url, setURL] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [currentDataSourceSettings, setCurrentDataSourceSettings] =
    useState<DataSourceSettings>();
  const [shortenDescription, setShortenDescription] = useState(false);

  const paramsObject = useParams<{
    accountAPIId: string;
  }>();
  const accountAPIId = Number(paramsObject.accountAPIId);
  const isInstagram = getAPITypeId({ accountAPIId }) === API_TYPE_IDS.INSTAGRAM;

  const titleRef = useRef<FixTypeLater>(null);
  const descriptionRef = useRef<FixTypeLater>(null);
  const maxDescriptionLength = 120;
  const singleLineHeight = 20;
  let description;

  if (metadata) {
    description = getDescription(metadata, dataSourceSettings);
  }

  useEffect(() => {
    setShortenDescription(false);
    setCurrentDataSourceSettings(dataSourceSettings);
    if (!isRunningTests()) checkTitleHeight();
  }, [dataSourceSettings]);

  useEffect(() => {
    if (!isRunningTests()) checkDescriptionHeight();
  }, [currentDataSourceSettings]);

  const checkTitleHeight = () => {
    const clientHeight = titleRef.current?.clientHeight;
    setShowDescription(clientHeight <= singleLineHeight);
  };

  const checkDescriptionHeight = () => {
    const clientHeight = descriptionRef.current?.clientHeight;
    if (clientHeight > singleLineHeight * 2) {
      setShortenDescription(true);
    }
  };

  const trimDescriptionToTwoLines = (fullDescription: string) => {
    const descriptionWordArray = fullDescription
      .slice(0, maxDescriptionLength)
      .split(' ');
    descriptionWordArray[descriptionWordArray.length - 1] = '...';
    return descriptionWordArray.join(' ');
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsError(false);
    setURL(e.target.value);
  };

  const handleSubmit: React.FormEventHandler<HTMLDivElement> = async (
    event,
  ) => {
    event.preventDefault();

    if (!isValidURL(url)) {
      setIsError(true);
      return;
    }

    setIsLoading(true);
    onMetadataChange(null);
    try {
      const previewMetadata = await getPreviewMetadata(url);

      const trackingParams = {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Data Sources Message': dataSourceSettings.messageSource,
        'Data Sources Title': dataSourceSettings.titleSource,
        'Data Sources Description': dataSourceSettings.descriptionSource,
        'Data Sources Image': dataSourceSettings.imageSource,
      };
      tracker.track({
        eventName: 'Preview Manual Data Source Settings',
        trackingParams,
      });

      onMetadataChange(previewMetadata);
      onPreview?.();
      setIsLoading(false);
      if (!isRunningTests()) checkDescriptionHeight();
      if (!isRunningTests()) checkTitleHeight();
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  if (shortenDescription && description) {
    description = trimDescriptionToTwoLines(description);
  }

  return (
    <Box>
      <Flex
        as="form"
        noValidate
        gap={2}
        alignItems="flex-end"
        onSubmit={handleSubmit}
      >
        <FormControl isInvalid={isError}>
          <FormControl.FormLabel>Test one of your links</FormControl.FormLabel>
          <Input
            placeholder="https://www.website.com/page-link"
            type="url"
            value={url}
            onChange={handleInputChange}
            isDisabled={isLoading}
          />
          <FormControl.FormErrorMessage>
            A valid link could not be found
          </FormControl.FormErrorMessage>
        </FormControl>
        <Button
          variant="secondary"
          flexShrink={0}
          isDisabled={isLoading}
          type="submit"
          mb={isError ? 7 : 0}
        >
          Preview
        </Button>
      </Flex>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <Center py={8}>
          <Spinner size="md" />
        </Center>
      ) : metadata !== null ? (
        <Box
          data-cy="sharePreviewZone"
          mt="2"
          position="relative"
          _before={{
            content: '""',
            inset: 0,
            position: 'absolute',
            boxShadow: 'border',
            borderRadius: 'md',
          }}
          borderRadius="md"
          overflow="hidden"
        >
          <Box pt="3" px="4" pb="8" bg="base">
            <Text size="sm">{getMessage(metadata, dataSourceSettings)}</Text>
          </Box>
          <Image
            w="full"
            h="280px"
            objectFit="cover"
            src={getImage(metadata, dataSourceSettings)}
            alt=""
            verticalAlign="middle"
          />
          {!isInstagram &&
            (dataSourceSettings.titleSource ||
              dataSourceSettings.descriptionSource) && (
              <Box background="gray.200" py="3" px="4">
                <Text size="sm" fontWeight="medium" as="h5" ref={titleRef}>
                  {getTitle(metadata, dataSourceSettings)}
                </Text>
                {showDescription && (
                  <Text size="sm" mt="1" ref={descriptionRef}>
                    {description}
                  </Text>
                )}
              </Box>
            )}
        </Box>
      ) : (
        <EmptyState
          mt={2}
          opacity="0.5"
          bg="white"
          py="120px"
          height="fit-content"
        >
          <EmptyState.Header color="#2E3643">
            Preview will be shown here
          </EmptyState.Header>
          <EmptyState.Description color="#526781">
            We&apos;ll use the data sources you&apos;ve selected on the left to
            generate this preview.
          </EmptyState.Description>
        </EmptyState>
      )}
    </Box>
  );
};

export default Preview;
