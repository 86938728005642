import { generateGuid } from 'common/string';

import { FLASH_MESSAGE_TYPES } from 'common/constants';

export { getErrorMessages, handlePasswordHideShow };

function getErrorMessages() {
  const flashMessages = this.props.flashMessages.messages;
  if (this.props.errorMessageNetwork !== '') {
    return [
      {
        id: generateGuid(),
        text: this.props.errorMessageNetwork,
      },
    ];
  }
  if (this.props.errorMessageAPI !== '') {
    return [
      {
        id: generateGuid(),
        text: this.props.errorMessageAPI,
      },
    ];
  }
  if (flashMessages.length > 0) {
    return flashMessages.filter(
      (flashMessage) =>
        flashMessage.type !== FLASH_MESSAGE_TYPES.SUCCESS &&
        flashMessage.type !== FLASH_MESSAGE_TYPES.INFO,
    );
  }
  return [];
}

function handlePasswordHideShow() {
  this.setState((prevState) => {
    const nextShowPassword = !prevState.showPassword;
    const newTextType = nextShowPassword ? 'text' : 'password';
    const stringReturn = nextShowPassword ? 'Hide password' : 'Show password';
    return {
      showPassword: nextShowPassword,
      textType: newTextType,
      passwordShowText: stringReturn,
    };
  });
}
