/**
 * The response object from statuspage.io - if populated - looks like this:
 *
 * incidents: [
 *   {
 *     incident_updates: [
 *       {
 *         display_at: TIMESTAMP,
 *         body: 'MESSAGE',
 *       },
 *     ],
 *   },
 * ],
 */

import axios from 'api/axios';
import { getUnixTimestamp } from 'common/datetime';
import { handleExternalAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isNull } from 'common/utility';

import { API_TIMEOUTS, statusPageURL } from '../settings';

export default async function getStatusPageIncidents() {
  const guid = metrics.start('getStatusPageIncidents');

  const config = {
    url: `${statusPageURL}/incidents/unresolved.json`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
  };

  try {
    const response = await axios(config);
    metrics.end('getStatusPageIncidents', guid);
    logger.debug('getStatusPageIncidents', {});
    const simulate = sessionStorage.getItem('simulateStatusPageNotification');
    if (!isNull(simulate)) {
      response.data.incidents.push({
        incident_updates: [
          {
            display_at: getUnixTimestamp(),
            body: 'STATUS PAGE NOTIFICATION FOR TEST PURPOSES ONLY, PLEASE IGNORE.',
          },
        ],
      });
    }
    return response.data;
  } catch (error) {
    metrics.fail('getStatusPageIncidents', guid);
    throw handleExternalAPIError({
      originalError: error,
      errorLocation: 'api/getStatusPageIncidents',
      args: {},
    });
  }
}

/**
 * In order to simulate a status page notification for testing purposes, replace
 * the 'return response.data' line above with the following:

    return {
      incidents: [
        {
          incident_updates: [
            {
              display_at: 1590572977,
              body: 'Oh no something has gone terribly wrong.',
            },
          ],
        },
      ],
    };

 */
