import {
  ArrowRightIcon,
  Box,
  Button,
  Card,
  Flex,
  LinkBox,
  LinkOverlay,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';

import { getCurrentAccountAPI, getCurrentAPITypeId } from 'common/accountAPIs';
import { isCompetitorLeaderboardEnabled } from 'common/competitorAnalytics';
import {
  ACCOUNT_STATES,
  API_TYPE_IDS,
  FRONTEND_PAGES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import { getCurrentPropertyStateId } from 'common/currentPropertyAndAPI';
import { isOnPage } from 'common/path';
import * as tracker from 'common/tracker';
import PageImage from 'components/misc/PageImage';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import { useLocation, useNavigate } from 'react-router-dom';

const PageDetails = () => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const location = useLocation();
  const navigate = useNavigate();
  const apiTypeId = getCurrentAPITypeId({ globalInfo });
  const showCompetitors =
    isCompetitorLeaderboardEnabled() && apiTypeId === API_TYPE_IDS.FACEBOOK;

  const isOnCompetitorsPage = isOnPage({
    page: FRONTEND_PAGES.COMPETITORS,
    location,
  });

  if (!apiTypeId || isOnCompetitorsPage) {
    return REACT_PREVENT_RENDER;
  }

  const accountAPI = getCurrentAccountAPI({
    globalInfo,
  });
  const { accountAPIId, apiAltName, apiHomePage, apiPostName } = accountAPI;
  const propertyStateId = getCurrentPropertyStateId(globalInfo);

  let accountName = apiPostName ?? '';
  const accountLink = apiHomePage ?? '';

  if (propertyStateId !== ACCOUNT_STATES.ACTIVE) {
    accountName = 'Page under setup';
  }

  const handleTracking = () => {
    tracker.track({
      eventName: 'Open External Social Page',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
      },
    });
  };

  return (
    <Card borderRadius="md">
      <Card.Content
        flexDir="row"
        justifyContent="space-between"
        w="full"
        alignItems="center"
      >
        <LinkBox as={Flex} onClick={handleTracking}>
          <PageImage accountAPI={accountAPI} mr={4}>
            <PageImage.SocialLogo size={5} svgSize={3.5} />
            <PageImage.Image size={10} />
          </PageImage>
          <Box>
            <Card.Title variant="h3">{accountName}</Card.Title>
            <Card.Description _groupHover={{ color: 'primary.700' }}>
              <LinkOverlay
                href={accountLink}
                isExternal
                color="inherit"
                _hover={{ textDecoration: 'none', color: 'primary.700' }}
              >
                @{apiAltName}
              </LinkOverlay>
            </Card.Description>
          </Box>
        </LinkBox>
        {showCompetitors && (
          <Button
            variant="link"
            rightIcon={<ArrowRightIcon />}
            onClick={() => navigate('/competitors')}
          >
            Competitors
          </Button>
        )}
      </Card.Content>
    </Card>
  );
};

export default PageDetails;
