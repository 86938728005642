import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import data from '@emoji-mart/data/sets/14/google.json';
import Picker from '@emoji-mart/react';

import { REACT_PREVENT_RENDER } from 'common/constants';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import Tooltip from 'components/misc/Tooltip';
import {
  handleIsPopupSelectorOpen,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import {
  MessageBoxState,
  insertEmoji,
  toggleEmojiMenu,
  useMessageBoxContext,
} from 'context/MessageBoxContext';
import { getCommonTrackingParams } from 'helpers/tracking';
import type { FixTypeLater } from 'types';

interface EmojiPickerProps {
  guid: string;
  hasImageOverlay: boolean;
  mediaItem: FixTypeLater;
}

const EmojiPicker = ({
  guid,
  hasImageOverlay,
  mediaItem,
}: EmojiPickerProps) => {
  const { currentUserInputType, dispatch, editorState } =
    useMessageBoxContext();
  const { dispatch: composeBoxDispatch } = useComposeBoxContext();
  const isTooltipDisabled =
    currentUserInputType === MessageBoxState.ADDITIONAL_OPTIONS_MENU_OPEN;

  const handleEmojiAddClick = () => {
    logger.info('EmojiPicker:handleEmojiAddClick');
    if (currentUserInputType !== MessageBoxState.ADDING_EMOJI) {
      const trackingParams = getCommonTrackingParams({ mediaItem });
      tracker.track({
        eventName: 'Open Emoji Dialog',
        trackingParams,
      });
    }

    composeBoxDispatch(
      handleIsPopupSelectorOpen({
        isPopupSelectorOpen:
          // If we were adding an emoji, the popup will no longer be open after the click
          // and vice versa
          currentUserInputType !== MessageBoxState.ADDING_EMOJI,
      }),
    );
    toggleEmojiMenu(dispatch);
  };

  const handleEmojiSaveClick = ({
    name,
    native,
  }: {
    name: string;
    native?: string;
  }) => {
    const trackingParams: Record<string, any> = getCommonTrackingParams({
      mediaItem,
    });

    trackingParams['Emoji Content'] = name;
    tracker.track({
      eventName: 'Insert Emoji',
      trackingParams,
    });

    if (native) {
      insertEmoji({
        composeBoxDispatch,
        editorState,
        guid,
        emoticon: native,
        messageBoxDispatch: dispatch,
      });
    }
  };

  if (hasImageOverlay) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <div data-cy-id="emoji" className="enabled">
      <Tooltip
        id="share_action_emoji"
        label="Add emoji"
        isDisabled={isTooltipDisabled}
      >
        <button
          type="button"
          className="clean_button"
          onClick={handleEmojiAddClick}
          data-tip
          data-for="share_action_emoji"
          data-cy-action="emoji"
          data-iscapture="true"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5.994 0C2.682 0 0 2.688 0 6C0 9.312 2.682 12 5.994 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 5.994 0ZM6 10.8C3.348 10.8 1.2 8.652 1.2 6C1.2 3.348 3.348 1.2 6 1.2C8.652 1.2 10.8 3.348 10.8 6C10.8 8.652 8.652 10.8 6 10.8ZM8.1 5.4C8.598 5.4 9 4.998 9 4.5C9 4.002 8.598 3.6 8.1 3.6C7.602 3.6 7.2 4.002 7.2 4.5C7.2 4.998 7.602 5.4 8.1 5.4ZM3.9 5.4C4.398 5.4 4.8 4.998 4.8 4.5C4.8 4.002 4.398 3.6 3.9 3.6C3.402 3.6 3 4.002 3 4.5C3 4.998 3.402 5.4 3.9 5.4ZM6 9.3C7.398 9.3 8.586 8.424 9.066 7.2H2.934C3.414 8.424 4.602 9.3 6 9.3Z" />
          </svg>
        </button>
      </Tooltip>
      {currentUserInputType === MessageBoxState.ADDING_EMOJI && (
        <ErrorBoundary showMessageOnError={false}>
          <Box position="absolute" zIndex={500}>
            <Picker
              autoFocus
              data={data}
              set="google"
              theme="light"
              navPosition="bottom"
              onEmojiSelect={handleEmojiSaveClick}
            />
          </Box>
        </ErrorBoundary>
      )}
    </div>
  );
};

export default EmojiPicker;
