const SocialTwitterLogo2 = () => {
  return (
    <svg
      width="100"
      height="80"
      viewBox="0 0 100 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.1475 19.9158C89.2075 20.7837 89.2075 21.6517 89.2075 22.5276C89.2075 49.2179 68.8888 80 31.7351 80V79.984C20.7598 80 10.0125 76.8562 0.773071 70.9286C2.36897 71.1206 3.97287 71.2166 5.58077 71.2206C14.6762 71.2286 23.5116 68.1767 30.6672 62.5571C22.0237 62.3931 14.4442 56.7575 11.7964 48.53C14.8242 49.114 17.944 48.994 20.9158 48.182C11.4924 46.2781 4.71282 37.9987 4.71282 28.3833C4.71282 28.2953 4.71282 28.2113 4.71282 28.1273C7.52065 29.6912 10.6644 30.5591 13.8802 30.6551C5.0048 24.7235 2.26898 12.9162 7.62864 3.68482C17.884 16.304 33.015 23.9755 49.258 24.7875C47.6301 17.7719 49.854 10.4204 55.1016 5.4887C63.2371 -2.15881 76.0323 -1.76684 83.6798 6.36465C88.2036 5.4727 92.5393 3.81281 96.507 1.46096C94.9991 6.13666 91.8433 10.1084 87.6276 12.6323C91.6313 12.1603 95.5431 11.0884 99.2269 9.45245C96.515 13.5162 93.0992 17.056 89.1475 19.9158Z"
        fill="#1D9BF0"
      />
    </svg>
  );
};

export default SocialTwitterLogo2;
