import { MEDIA_ITEM_STATES } from 'common/constants';
import { getTextDirection } from 'common/language';
import { renderMessageTags } from 'common/tags';
import { MediaItemState, TagMap } from 'types/common';

interface ItemTitleProps {
  articleTitle?: string;
  itemState: MediaItemState;
  tags: TagMap;
  accountAPIId: number;
}

const ItemTitle = ({
  accountAPIId,
  articleTitle = '',
  itemState,
  tags,
}: ItemTitleProps) => {
  const isDeleted = itemState === MEDIA_ITEM_STATES.DELETED;
  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: articleTitle,
  });

  return (
    <div
      className={`article_title ${
        isDeleted ? 'deleted_greyed_out' : ''
      } ${textDirectionClass}`}
      data-cy-id="title"
    >
      {renderMessageTags({
        message: articleTitle,
        tags,
      })}
    </div>
  );
};

export default ItemTitle;
