import PreviewLoading from 'components/compose/PreviewLoading';

interface ComposeLoadingProps {
  accountAPIId: number;
  guid: string;
  mediaId: string;
}

const ComposeLoading = ({
  accountAPIId,
  guid,
  mediaId,
}: ComposeLoadingProps) => {
  return (
    <div className="ebx-modal-outer">
      <div className="ebx-modal-inner">
        <div
          className="full_edit row"
          data-tip
          data-for={mediaId}
          data-cy-id="composeBox"
          data-cy-attribute="isLoading:true"
        >
          <div className="d-flex flex-column-reverse w-100 finish flex-md-row finish align-items-center bg-light-blue">
            <div className="mx-auto my-3 op-50" role="status" />
          </div>
          <div className="d-flex bg-light-blue w-100 finish flex-md-row finish align-items-center">
            <div className="mx-auto my-3 op-50" role="status" />
          </div>
          <div className="accordion w-100 px-3 pt-3 pb-0" id="previewPanels">
            <PreviewLoading accountAPIId={accountAPIId} guid={guid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComposeLoading;
