import { Box, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

interface InstantVideoHeaderProps {
  children: React.ReactNode;
}
const InstantVideoHeader = ({ children }: InstantVideoHeaderProps) => {
  return (
    <Modal.Header>
      <Box
        as="span"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {children}
      </Box>
    </Modal.Header>
  );
};

export default InstantVideoHeader;
