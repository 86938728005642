import { SidebarGroup } from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  getAccountAPIPermission,
  getCurrentAccountAPIId,
  sortPages,
} from 'common/accountAPIs';
import { getSocialNetworkName } from 'common/social';
import SidebarPage from 'components/sidebar/SidebarPage';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import type { FixTypeLater } from 'types';

interface SidebarNetworkProps {
  apiTypeId: number;
  accountAPIs: FixTypeLater[] | undefined;
}

const SidebarNetwork = ({ apiTypeId, accountAPIs }: SidebarNetworkProps) => {
  const context = useGlobalInfo();
  const globalInfo = context.global.getGlobalInfo();
  const isDisabled = context.global.isLoading();
  const name = getSocialNetworkName({ apiTypeId });
  const currentAPIId = getCurrentAccountAPIId({ globalInfo });

  // If there are no pages for this social network, do not render a section
  if (!accountAPIs || accountAPIs.length === 0) {
    return null;
  }

  return (
    <SidebarGroup title={name}>
      {accountAPIs.sort(sortPages).map((accountAPI) => {
        const permissionTypeId = getAccountAPIPermission({
          accountAPIId: accountAPI.accountAPIId,
          globalInfo,
        });

        return (
          <SidebarPage
            key={accountAPI.accountAPIId}
            accountAPI={accountAPI}
            isDisabled={isDisabled}
            isSelected={accountAPI.accountAPIId === currentAPIId}
            permissionTypeId={permissionTypeId}
            eventHandlers={{
              handleAccountChange: context.global.handleAccountChange,
            }}
          />
        );
      })}
    </SidebarGroup>
  );
};

export default SidebarNetwork;
