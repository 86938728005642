import { Circle } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ComponentPropsWithoutRef } from 'react';

const SocialPageCircle = (props: ComponentPropsWithoutRef<typeof Circle>) => (
  <Circle
    size={6}
    border="1px"
    borderColor="gray.300"
    color="gray.500"
    bg="white"
    {...props}
  />
);

export default SocialPageCircle;
