import * as React from 'react';

import { getAPITypeId } from 'common/accountAPIs';
import { AUTOFEED_SHARE_MODES } from 'common/constants';
import { getAutofeedSetting } from 'common/settings';
import { getAutofeedDefaults } from 'common/social';
import { AutofeedShareMode } from 'types';
import { useAutomationSettingsContext } from './AutomationSettingsContext';

/**
 * A custom hook that returns a method for checking whether or not to show a prompt to disable automation.
 * This is based on whether the user has automation on and is disabling both new share and reshare modes.
 */
export function useAutoDisableAutomationPrompt({
  shareSettingType,
}: {
  shareSettingType: 'new' | 'reshare';
}) {
  const { accountAPIId, isAutomationActive } = useAutomationSettingsContext();

  const shouldShowDisableAutomationPrompt = React.useCallback(
    ({ shareMode }: { shareMode: AutofeedShareMode }) => {
      const defaults = getAutofeedDefaults({
        apiTypeId: getAPITypeId({ accountAPIId }),
      });

      // This hook is shared between newshare and reshare settings,
      // so based on the type of share setting, we need to check the share mode of the 'other' type.
      const otherShareType =
        shareSettingType === 'new' ? 'reshareMode' : 'newShareMode';
      const otherShareMode =
        getAutofeedSetting({ key: otherShareType, accountAPIId }) ??
        defaults[otherShareType];

      const shouldShowPrompt =
        isAutomationActive &&
        otherShareMode === AUTOFEED_SHARE_MODES.OFF &&
        shareMode === AUTOFEED_SHARE_MODES.OFF;

      return shouldShowPrompt;
    },
    [accountAPIId, isAutomationActive, shareSettingType],
  );

  return {
    shouldShowDisableAutomationPrompt,
  };
}
