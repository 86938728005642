// RTK uses immer under the hood, so it encourages param reassignment
/* eslint-disable no-param-reassign */

import { createSlice, current, isDraft, PayloadAction } from '@reduxjs/toolkit';
import Immutable, { Immutable as ImmutableType } from 'seamless-immutable';

import {
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import { CROSS_POST_LIMIT, CROSS_POST_PROPERTIES } from 'common/config';
import {
  CONTENT_TYPE_IDS,
  CONTENT_TYPES,
  POST_TYPES,
  TAG_TYPES,
} from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import * as MediaItem from 'common/mediaItem';
import { PREVIEW_FIELDS } from 'common/mediaItem/common';
import {
  findShareContentIndex,
  getSelectedTypeId,
} from 'common/mediaItem/shareContent';
import { addNotification } from 'common/notifications';
import { convertEchoboxVideoToAmazon } from 'common/s3';
import {
  addPlaceholderToMessage,
  getSocialNetworkName,
  hasLinkPlaceholder,
} from 'common/social';
import { containsURL, toCamelCase } from 'common/string';
import * as tracker from 'common/tracker';
import * as MessageBoxTools from 'components/compose/messagebox/MessageBoxTools';
import { getComposeBoxPostPreviews } from 'helpers/composeBox';
import { getCommonTrackingParams } from 'helpers/tracking';
import type {
  AIMessage,
  FixTypeLater,
  IGLinkStickerConfig,
  MessageHistory,
  PostPreview,
  PostType,
  ShareContent,
  ShareTime,
  SocialChannel,
  Tag,
  TagType,
} from 'types';

import { convertImageToLink, convertLinkToImage } from 'common/instantImage';
import type { ComposeBoxTab, State } from './types';

function addAdditionalSocialPageId({
  mediaItem,
  accountAPIIdToAdd,
}: {
  mediaItem: FixTypeLater;
  accountAPIIdToAdd: number;
}) {
  const additionalSocialPages = MediaItem.getAdditionalSocialPages({
    mediaItem,
  });
  additionalSocialPages.push(accountAPIIdToAdd);
  return MediaItem.setAdditionalSocialPages({
    mediaItem,
    fieldValue: additionalSocialPages,
  });
}

function applyFieldUpdatesToMediaItem({
  fieldUpdates,
  mediaItem,
}: {
  fieldUpdates: Array<{ fieldName: string; fieldValue: FixTypeLater }>;
  mediaItem: FixTypeLater;
}) {
  let updatedMediaItem = mediaItem;

  fieldUpdates.forEach((fieldUpdate) => {
    if (PREVIEW_FIELDS.indexOf(fieldUpdate.fieldName) !== -1) {
      updatedMediaItem = MediaItem.setPreviewField({
        mediaItem: updatedMediaItem,
        fieldName: fieldUpdate.fieldName,
        fieldValue: fieldUpdate.fieldValue,
        setIsEdited:
          ['title', 'description', 'imageURLs'].findIndex(
            (field) => field === fieldUpdate.fieldName,
          ) !== -1,
      });
    } else {
      const setterName = `set${toCamelCase(fieldUpdate.fieldName)}`;
      // @ts-expect-error -- JavaScript hack
      updatedMediaItem = MediaItem[setterName]({
        mediaItem: updatedMediaItem,
        fieldValue: fieldUpdate.fieldValue,
      });
    }
  });

  return updatedMediaItem;
}

function composeBoxPostPreviews(state: State) {
  return state.postPreviews.filter((item) => !item.isHidden);
}

function getMediaItemIndex({ guid, state }: { guid: string; state: State }) {
  return state.postPreviews.findIndex((item) => item.guid === guid);
}

function findMediaItem({ guid, state }: { guid: string; state: State }) {
  const index = getMediaItemIndex({ guid, state });
  if (index !== -1) {
    const rawMediaItem = state.postPreviews[index].mediaItem;
    const mediaItem = isDraft(rawMediaItem)
      ? current(rawMediaItem)
      : rawMediaItem;
    return Immutable(mediaItem);
  }
  return null;
}

function getNumPosts(previews: Array<PostPreview>) {
  const numAdditionalSocialPages = previews
    .filter((preview) => !preview.isHidden)
    .flatMap((item) =>
      MediaItem.getAdditionalSocialPages({ mediaItem: item.mediaItem }),
    ).length;
  const numPreviews = previews.filter((preview) => !preview.isHidden).length;
  return numAdditionalSocialPages + numPreviews;
}

function handleCrossPropertyPage({
  accountAPIId,
  state,
  guidToCopy,
  additionalSocialPages = [],
}: {
  accountAPIId: number;
  state: State;
  guidToCopy?: string;
  additionalSocialPages?: Array<FixTypeLater>;
}) {
  // Reinstate hidden page(s) if any exist
  if (
    state.postPreviews.filter(
      (preview) => preview.accountAPIId === accountAPIId && preview.isHidden,
    ).length > 0
  ) {
    state.postPreviews = state.postPreviews.map((preview) =>
      preview.accountAPIId === accountAPIId && preview.isHidden
        ? { ...preview, isHidden: false }
        : preview,
    );
  } else {
    // Otherwise add page(s) as required
    const visiblePostPreviews = composeBoxPostPreviews(state);

    // Bail out if the post preview is already visible
    const isPageAlreadyInPreviews = visiblePostPreviews.some(
      (preview) => preview.accountAPIId === accountAPIId,
    );
    if (isPageAlreadyInPreviews) {
      return;
    }

    let guid: string | undefined;
    if (guidToCopy) {
      guid = guidToCopy;
    } else if (visiblePostPreviews.length > 0) {
      // Default to the first visible media item
      guid = visiblePostPreviews[0].guid;
    } else {
      // Otherwise use the first hidden media item
      guid = state.postPreviews[0].guid;
    }

    if (guid !== undefined) {
      Compose.sharePostTo({
        sourceItem: findMediaItem({ guid, state }),
        accountAPIId,
        additionalSocialPages,
      });
    }
  }
}

const composeBoxSlice = createSlice({
  name: 'composeBox',
  // initialState has been set to '{} as State' so that createSlice can infer the type of the state
  // the actual initial state will be set in useReducer
  initialState: {} as State,
  reducers: {
    addImage: (
      state,
      action: PayloadAction<{ guid: string; imageURL: string }>,
    ) => {
      const { guid, imageURL } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const imageURLs = MediaItem.getImageURLs({ mediaItem });

      const twitterCard = MediaItem.getTwitterCardType({ mediaItem });
      if (twitterCard !== null) {
        imageURLs.shift();
        mediaItem = MediaItem.setTwitterCardType({
          fieldValue: null,
          mediaItem,
        });
        mediaItem = MediaItem.setTitle({
          fieldValue: null,
          mediaItem,
        });
        mediaItem = MediaItem.setDescription({
          fieldValue: null,
          mediaItem,
        });
      }

      imageURLs.push(imageURL);
      mediaItem = MediaItem.setImageURLs({
        fieldValue: imageURLs,
        mediaItem,
        setIsEdited: true,
      });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    addFiles: (
      state,
      action: PayloadAction<{ guid: string; files: File[] }>,
    ) => {
      const { guid, files } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const postType = MediaItem.getPostType({ mediaItem });

      const oldFilesObj = MediaItem.getFiles({ mediaItem }) ?? [];

      const filesObj = files.map((file) => ({
        name: file.name,
        size: file.size,
        type: file.type,
      }));

      mediaItem = MediaItem.setFiles({
        fieldValue:
          postType === POST_TYPES.VIDEO
            ? filesObj
            : [...oldFilesObj, ...filesObj],
        mediaItem,
        setIsEdited: true,
      });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    addMessageToHistory: (
      state,
      action: PayloadAction<{
        content: string;
        guid: string;
      }>,
    ) => {
      const { content, guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = Immutable(findMediaItem({ guid, state }));
      if (mediaItem === null) {
        return;
      }

      const aiMessage = MediaItem.getAIMessage({ mediaItem });
      const prevMessage = MediaItem.getMessage({ mediaItem });
      const messageHistory = MediaItem.getMessageHistory({ mediaItem });
      const messages = MediaItem.getMessages({ mediaItem });

      mediaItem = MediaItem.setMessage({
        fieldValue: content,
        mediaItem,
      });

      let selectedContentTypeId = getSelectedTypeId({ messages });
      let selectedContentIndex: number | null = messages
        ? messages.selectedContentIndex
        : -1;
      let newMessages = messages;

      if (content !== prevMessage && selectedContentTypeId) {
        mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

        // Ensure the edited message is selected when text is edited
        const contentType = CONTENT_TYPES[selectedContentTypeId];
        if (
          contentType &&
          !contentType.isEdited &&
          'editedId' in contentType &&
          messages
        ) {
          const newMessageHistory = MessageBoxTools.addToMessageHistory({
            messages,
            messageHistory,
            aiMessage,
          });
          mediaItem = MediaItem.setMessageHistory({
            fieldValue: newMessageHistory,
            mediaItem,
          });
          if (prevMessage !== '') {
            selectedContentTypeId = contentType.editedId;
          }
          selectedContentIndex = findShareContentIndex({
            messages,
            contentTypeId: selectedContentTypeId,
          });

          // Add if it does not exist
          if (selectedContentIndex != null && selectedContentIndex < 0) {
            newMessages = {
              ...messages,
              orderedShareContent: messages.orderedShareContent.concat([
                {
                  contentTypeId: contentType.editedId,
                  description: contentType.description,
                  value: content,
                },
              ]),
            };
            selectedContentIndex = messages.orderedShareContent.length - 1;
          }
        }
      }

      if (aiMessage.messages.length > 0) {
        // Check if the content is the same as any AI message
        // If so, set the selectedContentTypeId to 'AI message'
        const aiMessageMatches = aiMessage.messages.some(
          (message) => message.value === content,
        );
        if (aiMessageMatches) {
          selectedContentTypeId = CONTENT_TYPE_IDS.OPTIMAL;
        }
      }

      // Messages do not have to be defined (e.g. photo and video posts), if they are
      // ensure to keep them up to date
      if (!newMessages || selectedContentIndex == null) {
        state.postPreviews[index].mediaItem = mediaItem;
        return;
      }

      // Always ensure that the selected content matches with text, this is particularly important
      // when switching between AB test variations without making other changes
      newMessages = {
        ...newMessages,
        selectedContentIndex,
        selectedContentTypeId,
        orderedShareContent: newMessages.orderedShareContent.map(
          (item, mapIndex) =>
            mapIndex === selectedContentIndex
              ? { ...item, value: content }
              : item,
        ),
        selectedShareContent: {
          ...newMessages.selectedShareContent,
          value: content,
        },
      };

      mediaItem = MediaItem.setMessages({
        fieldValue: newMessages,
        mediaItem,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    addPreview: (state, action: PayloadAction<{ preview: PostPreview }>) => {
      state.postPreviews.push(action.payload.preview);
    },
    addSocialPage: (
      state,
      action: PayloadAction<{
        accountAPIId: number;
        totalAdditionalAPIsToShare: number;
      }>,
    ) => {
      const { accountAPIId, totalAdditionalAPIsToShare } = action.payload;
      const postPreviews = composeBoxPostPreviews(state);
      const numPosts = getNumPosts(postPreviews) + totalAdditionalAPIsToShare;

      if (
        numPosts < CROSS_POST_LIMIT ||
        !CROSS_POST_PROPERTIES.includes(getCurrentPropertyId())
      ) {
        // Render the preview
        handleCrossPropertyPage({ accountAPIId, state });
        state.passedCrossPostLimit = false;
      } else {
        state.passedCrossPostLimit = true;

        // If more than CROSS_POST_LIMIT previews are rendered, add all media ids to the first media item
        const hasAdditionalSocialPages =
          postPreviews
            .filter((mediaItem) => !mediaItem.isHidden)
            .map((mediaItem) =>
              MediaItem.getAdditionalSocialPages({ mediaItem }),
            )
            .filter((additionalPages) => additionalPages.length > 0).length > 0;

        if (numPosts === CROSS_POST_LIMIT && !hasAdditionalSocialPages) {
          // Collapse all previews to per social network
          const socialNetworkPreviews: Record<number, FixTypeLater> = {};
          postPreviews.forEach((item) => {
            const mediaItem = findMediaItem({
              guid: item.guid,
              state,
            });
            const index = getMediaItemIndex({
              guid: item.guid,
              state,
            });
            const mediaItemAccountAPIId = MediaItem.getAccountAPIId({
              mediaItem,
            });
            const mediaItemAPITypeId = getAPITypeId({
              accountAPIId: mediaItemAccountAPIId,
            });
            if (
              socialNetworkPreviews[mediaItemAPITypeId] &&
              socialNetworkPreviews[mediaItemAPITypeId].mediaItem
                .accountAPIId !== mediaItemAccountAPIId
            ) {
              let socialNetworkMediaItem =
                socialNetworkPreviews[mediaItemAPITypeId].mediaItem;
              socialNetworkMediaItem = addAdditionalSocialPageId({
                mediaItem: socialNetworkMediaItem,
                accountAPIIdToAdd: mediaItemAccountAPIId,
              });
              // Update the media item of the social network preview
              state.postPreviews[
                socialNetworkPreviews[mediaItemAPITypeId].index
              ].mediaItem = socialNetworkMediaItem;
              socialNetworkPreviews[mediaItemAPITypeId].mediaItem =
                socialNetworkMediaItem;
              // Remove the media item as it's been added to the first API's additional social APIs
              const removalIndex = state.postPreviews.findIndex(
                (currentItem) => currentItem.guid === item.guid,
              );
              if (removalIndex !== -1) {
                state.postPreviews.splice(removalIndex, 1);
              }
            } else {
              socialNetworkPreviews[mediaItemAPITypeId] = {
                index,
                mediaItem,
              };
            }
          });
        }
        // Add any additional pages to the remaining post previews
        if (numPosts >= CROSS_POST_LIMIT) {
          const postPreview = postPreviews.find((item) => {
            const mediaItem = findMediaItem({
              guid: item.guid,
              state,
            });
            if (mediaItem === null) return false;
            const mediaItemAccountAPIId = MediaItem.getAccountAPIId({
              mediaItem,
            });
            const mediaItemAPITypeId = getAPITypeId({
              accountAPIId: mediaItemAccountAPIId,
            });
            const accountAPITypeId = getAPITypeId({ accountAPIId });
            return mediaItemAPITypeId === accountAPITypeId;
          });
          if (postPreview) {
            let mediaItem = findMediaItem({
              guid: postPreview.guid,
              state,
            });
            mediaItem = addAdditionalSocialPageId({
              mediaItem,
              accountAPIIdToAdd: accountAPIId,
            });
            const index = state.postPreviews
              .map((item) => item.guid)
              .indexOf(postPreview.guid);
            state.postPreviews[index].mediaItem = mediaItem;
          } else {
            // Render the preview
            handleCrossPropertyPage({ accountAPIId, state });
          }
        }
      }
    },
    applyFieldUpdatesForAllGuids: (
      state,
      action: PayloadAction<{
        fieldUpdates: Array<{ fieldName: string; fieldValue: FixTypeLater }>;
      }>,
    ) => {
      const { fieldUpdates } = action.payload;

      const allGuids = getComposeBoxPostPreviews(state.postPreviews).map(
        (item) => item.guid,
      );

      allGuids.forEach((guid) => {
        const index = getMediaItemIndex({ guid, state });
        if (index === -1) {
          return;
        }
        const mediaItem = findMediaItem({ guid, state });
        if (mediaItem === null) {
          return;
        }

        const updatedMediaItem = applyFieldUpdatesToMediaItem({
          fieldUpdates,
          mediaItem,
        });

        state.postPreviews[index].mediaItem = updatedMediaItem;
      });
    },
    /**
     * @deprecated It should not be used for new features. Actions should be modelled as events, not as setters
     * See https://redux.js.org/style-guide/#model-actions-as-events-not-setters
     */
    applyFieldUpdatesForGuid: (
      state,
      action: PayloadAction<{
        fieldUpdates: Array<{ fieldName: string; fieldValue: FixTypeLater }>;
        guid: string;
      }>,
    ) => {
      const { fieldUpdates, guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      const mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const updatedMediaItem = applyFieldUpdatesToMediaItem({
        fieldUpdates,
        mediaItem,
      });

      state.postPreviews[index].mediaItem = updatedMediaItem;
    },
    closeDuplicateScheduleModal: (state) => {
      state.showDuplicateScheduleModal = false;
    },
    deleteAllImages: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setImageURLs({ fieldValue: [], mediaItem });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    deleteFiles: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setFiles({
        fieldValue: [],
        mediaItem,
        setIsEdited: true,
      });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    deleteImage: (
      state,
      action: PayloadAction<{ guid: string; imageIndex: number }>,
    ) => {
      const { guid, imageIndex } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const imageURLs = MediaItem.getImageURLs({ mediaItem });
      imageURLs.splice(imageIndex, 1);

      if (imageURLs.length === 0 && mediaItem.backend.twitterCardType) {
        if (mediaItem.backend.imageURLs.length > 0) {
          imageURLs.push(mediaItem.backend.imageURLs[0]);
        }
        mediaItem = MediaItem.setDescription({
          fieldValue: mediaItem.backend.description,
          mediaItem,
        });
        mediaItem = MediaItem.setTitle({
          fieldValue: mediaItem.backend.title,
          mediaItem,
        });
        mediaItem = MediaItem.setTwitterCardType({
          fieldValue: mediaItem.backend.twitterCardType,
          mediaItem,
        });
      }

      const files = MediaItem.getFiles({ mediaItem }) ?? [];
      files.splice(imageIndex, 1);

      mediaItem = MediaItem.setFiles({ fieldValue: files, mediaItem });

      mediaItem = MediaItem.setImageURLs({ fieldValue: imageURLs, mediaItem });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    deletePostPreview: (state, action: PayloadAction<{ guid: string }>) => {
      state.postPreviews = state.postPreviews.filter(
        (item) => item.guid !== action.payload.guid,
      );
    },
    deleteVideo: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }

      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setImageURLs({ fieldValue: [], mediaItem });
      mediaItem = MediaItem.setVideoURL({ fieldValue: '', mediaItem });
      mediaItem = MediaItem.setFiles({ fieldValue: [], mediaItem });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    duplicateComposeBoxItem: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;

      const mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      Compose.duplicatePost({ guid });
      const trackingParams = getCommonTrackingParams({ mediaItem });
      tracker.track({
        eventName: 'Duplicate Post (Same Social Page)',
        trackingParams,
      });
    },
    finishSavingMediaItem: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem === null) {
          return;
        }
        mediaItem = MediaItem.setIsSaving({
          mediaItem,
          fieldValue: false,
        });
        state.postPreviews[index].mediaItem = mediaItem;
        state.postPreviews[index].isShared = true;
        const sharedCount = state.postPreviews.filter(
          (item) => item.isShared,
        ).length;
        let count = 0;
        for (const preview of state.postPreviews) {
          if (!preview.isHidden) {
            count += 1;
          }
          if (preview.mediaItem.frontend.additionalSocialPages) {
            count += preview.mediaItem.frontend.additionalSocialPages.length;
          }
        }
        if (sharedCount === count) {
          state.postPreviews = [];
          state.passedCrossPostLimit = false;
        }
      }
    },
    firstCommentChange: (
      state,
      action: PayloadAction<{ guid: string; isEnabled: boolean }>,
    ) => {
      const { guid, isEnabled } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }

      const messageAfterToggle = isEnabled ? '' : null;
      const mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const updatedMediaItem = MediaItem.setFirstComment({
        mediaItem,
        fieldValue: messageAfterToggle,
      });
      state.postPreviews[index].mediaItem = updatedMediaItem;
    },
    generateAIMessageRequest: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = findMediaItem({ guid, state });
        if (mediaItem !== null) {
          let aiMessage = MediaItem.getAIMessage({ mediaItem });
          aiMessage = aiMessage.set('isLoading', true);
          mediaItem = MediaItem.setAIMessage({
            mediaItem,
            fieldValue: aiMessage,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    generateAIMessageError: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = findMediaItem({ guid, state });
        if (mediaItem !== null) {
          let aiMessage = MediaItem.getAIMessage({ mediaItem });
          aiMessage = aiMessage
            .set('isLoading', false)
            .set('loadingFailed', true);
          mediaItem = MediaItem.setAIMessage({
            mediaItem,
            fieldValue: aiMessage,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    generateAIMessageSuccess: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = findMediaItem({ guid, state });
        if (mediaItem !== null) {
          let aiMessage = MediaItem.getAIMessage({ mediaItem });
          aiMessage = aiMessage.set('isLoading', false);
          mediaItem = MediaItem.setAIMessage({
            mediaItem,
            fieldValue: aiMessage,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    handleIGLinkStickerConfigUpdate: (
      state,
      action: PayloadAction<{
        guid: string;
        linkStickerConfig: IGLinkStickerConfig;
      }>,
    ) => {
      const { guid, linkStickerConfig } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem !== null) {
          mediaItem = MediaItem.setIGLinkStickerConfig({
            fieldValue: linkStickerConfig,
            mediaItem,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    handleIsFirstRender: (
      state,
      action: PayloadAction<{ isFirstRender: boolean }>,
    ) => {
      state.isFirstRender = action.payload.isFirstRender;
    },
    handleIsPopupSelectorOpen: (
      state,
      action: PayloadAction<{ isPopupSelectorOpen: boolean }>,
    ) => {
      state.isPopupSelectorOpen = action.payload.isPopupSelectorOpen;
    },
    handleMediaItemError: (
      state,
      action: PayloadAction<{ error: FixTypeLater; guid: string }>,
    ) => {
      const { error, guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = findMediaItem({ guid, state });
        if (mediaItem !== null) {
          const errorMessage = getErrorMessage(error);
          mediaItem = MediaItem.setErrorDetails({
            mediaItem,
            fieldValue: error,
          });
          mediaItem = MediaItem.setErrorMessage({
            mediaItem,
            fieldValue: errorMessage,
          });
          mediaItem = MediaItem.setIsLoading({
            mediaItem,
            fieldValue: false,
          });
          mediaItem = MediaItem.setIsSaving({
            mediaItem,
            fieldValue: false,
          });

          if (error?.status === 409) {
            state.showDuplicateScheduleModal = true;
          }
          state.postPreviews[index].mediaItem = mediaItem;
          if (state.passedCrossPostLimit) {
            state.postPreviews = state.postPreviews.filter(
              (item) => item.guid !== guid,
            );
          }
        }
      }
    },
    handlePostTypeChange: (
      state,
      action: PayloadAction<{ guid: string; postType: PostType }>,
    ) => {
      const { guid, postType } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem !== null) {
          const currentPostType = MediaItem.getPostType({ mediaItem });

          mediaItem = MediaItem.setPostType({
            fieldValue: postType,
            mediaItem,
          });

          if (
            currentPostType === POST_TYPES.LINK &&
            postType === POST_TYPES.STATUS
          ) {
            mediaItem = convertLinkToImage({ mediaItem });
          } else if (
            currentPostType === POST_TYPES.STATUS &&
            postType === POST_TYPES.LINK &&
            !!MediaItem.getInstantImageArticleUrl({ mediaItem })
          ) {
            mediaItem = convertImageToLink({ mediaItem });
          }

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    handleRefreshPreviewError: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setIsRefreshPreview({
        mediaItem,
        fieldValue: false,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    handleRefreshPreviewPending: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      // On refresh lock for editing and mark as refreshing preview
      mediaItem = MediaItem.setIsRefreshPreview({
        mediaItem,
        fieldValue: true,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    handleRefreshPreviewSuccess: (
      state,
      action: PayloadAction<{
        baseItem: FixTypeLater;
        guid: string;
        updatedURL: string | null;
        isURLResolved: boolean | null;
      }>,
    ) => {
      const { baseItem, guid, updatedURL, isURLResolved } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      // Reset isURLResolved and isURLValid flags to their default states
      mediaItem = MediaItem.setIsURLResolved({
        mediaItem,
        fieldValue: isURLResolved,
      });
      mediaItem = MediaItem.setIsURLValid({
        mediaItem,
        fieldValue: true,
      });

      if (updatedURL !== null) {
        mediaItem = MediaItem.setUnshortenedShareURL({
          mediaItem,
          fieldValue: updatedURL,
        });
      }

      // Copy relevant preview fields from refreshed preview
      const previewFields = MediaItem.getPreviewFields({
        mediaItem: baseItem,
      });
      mediaItem = MediaItem.setDescription({
        mediaItem,
        fieldValue: previewFields.description ?? '',
      });
      mediaItem = MediaItem.setImageURLs({
        mediaItem,
        fieldValue: previewFields.imageURLs ?? [],
      });
      mediaItem = MediaItem.setMessage({
        mediaItem,
        fieldValue: previewFields.message ?? '',
      });
      if (previewFields.messages !== undefined) {
        mediaItem = MediaItem.setMessages({
          mediaItem,
          fieldValue: previewFields.messages,
        });
      }
      mediaItem = MediaItem.setTitle({
        mediaItem,
        fieldValue: previewFields.title ?? '',
      });

      mediaItem = MediaItem.setIsRefreshPreview({
        mediaItem,
        fieldValue: false,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    handleSelectedTabChange: (
      state,
      action: PayloadAction<{ selectedTab: ComposeBoxTab }>,
    ) => {
      state.selectedTab = action.payload.selectedTab;
    },
    handleSponsorSelect: (
      state,
      action: PayloadAction<{
        guid: string;
        sponsor: string | null;
        sponsorName: string | null;
      }>,
    ) => {
      const { guid, sponsor, sponsorName } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setSponsor({ mediaItem, fieldValue: sponsor });
      mediaItem = MediaItem.setSponsorName({
        mediaItem,
        fieldValue: sponsorName,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    handleTimingOptionsChange: (
      state,
      action: PayloadAction<{ guid: string; shareTime: ShareTime }>,
    ) => {
      const { guid, shareTime } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setShareTime({ fieldValue: shareTime, mediaItem });
      mediaItem = MediaItem.setIsTimingValid({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    handleURLChangePending: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setIsSaving({
        mediaItem,
        fieldValue: true,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    handleURLChangeSuccess: (
      state,
      action: PayloadAction<{
        guid: string;
        isURLChanged?: boolean;
        isURLResolved?: boolean;
        isURLValid?: boolean;
        shortenedURL?: string;
        updatedURL: string;
      }>,
    ) => {
      const {
        guid,
        isURLChanged,
        isURLResolved,
        isURLValid,
        shortenedURL,
        updatedURL,
      } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
      const apiTypeId = getAPITypeId({ accountAPIId });

      if (isURLValid === true) {
        mediaItem = MediaItem.setUnshortenedShareURL({
          mediaItem,
          fieldValue: updatedURL,
        });
        mediaItem = MediaItem.setShareURL({
          mediaItem,
          fieldValue:
            shortenedURL && shortenedURL !== '' ? shortenedURL : updatedURL,
        });
      }

      if (isURLChanged !== undefined) {
        mediaItem = MediaItem.setIsURLChanged({
          mediaItem,
          fieldValue: isURLChanged,
        });
      }
      if (isURLResolved !== undefined) {
        mediaItem = MediaItem.setIsURLResolved({
          mediaItem,
          fieldValue: isURLResolved,
        });
      }
      if (isURLValid !== undefined) {
        mediaItem = MediaItem.setIsURLValid({
          mediaItem,
          fieldValue: isURLValid,
        });
      }

      mediaItem = MediaItem.setIsChanged({
        mediaItem,
        fieldValue: isURLChanged,
      });

      mediaItem = MediaItem.setIsSaving({
        mediaItem,
        fieldValue: false,
      });

      // Add link placeholder if it or other URL already exists in the share message
      if (
        hasLinkPlaceholder({ apiTypeId }) &&
        !containsURL(MediaItem.getMessage({ mediaItem }))
      ) {
        mediaItem = MediaItem.setMessage({
          mediaItem,
          fieldValue: addPlaceholderToMessage({
            apiTypeId: getAPITypeId({ accountAPIId }),
            message: MediaItem.getMessage({ mediaItem }),
          }),
        });
      }

      state.postPreviews[index].mediaItem = mediaItem;
    },
    insertTagIntoMediaItem: (
      state,
      action: PayloadAction<{ guid: string; tag: Tag; tagType: TagType }>,
    ) => {
      const { guid, tag, tagType } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      let mediaItem = Immutable(findMediaItem({ guid, state }));
      if (index === -1 || mediaItem === null) {
        return;
      }

      if (tagType === TAG_TYPES.HASHTAG) {
        const previousHashtags = MediaItem.getHashtags({ mediaItem });
        const tagExists = previousHashtags.some((t) => t.raw === tag.raw);
        if (!tagExists) {
          mediaItem = MediaItem.setHashtags({
            mediaItem,
            fieldValue: [...previousHashtags, tag],
          });
        }
      } else if (tagType === TAG_TYPES.MENTION) {
        const previousMentions = MediaItem.getMentions({ mediaItem });
        const tagExists = previousMentions.some((t) => t.raw === tag.raw);
        if (!tagExists) {
          mediaItem = MediaItem.setMentions({
            mediaItem,
            fieldValue: [...previousMentions, tag],
          });
        }
      }

      state.postPreviews[index].mediaItem = mediaItem;
    },
    removeSocialPage: (
      state,
      action: PayloadAction<{ accountAPIId: number; deselectAll: boolean }>,
    ) => {
      const { accountAPIId, deselectAll } = action.payload;
      const postPreviews = composeBoxPostPreviews(state);
      const numPosts = getNumPosts(postPreviews);

      // Hide page(s) as required
      let nextAccountAPIId: number | null = null;
      state.postPreviews.forEach((preview, index) => {
        // Check if the hidden preview is either the preview managing the additional social pages or just an additional social page
        let mediaItem = findMediaItem({
          guid: preview.guid,
          state,
        });
        let additionalSocialPages = MediaItem.getAdditionalSocialPages({
          mediaItem,
        });

        if (deselectAll) {
          state.passedCrossPostLimit = false;
          mediaItem = MediaItem.setAdditionalSocialPages({
            mediaItem,
            fieldValue: [],
          });
          state.postPreviews[index].mediaItem = mediaItem;
        }

        if (Number(preview.accountAPIId) === Number(accountAPIId)) {
          // The preview needs to be hidden
          state.postPreviews[index].isHidden = true;
          if (numPosts >= CROSS_POST_LIMIT) {
            if (additionalSocialPages.length > 0) {
              mediaItem = MediaItem.setAdditionalSocialPages({
                mediaItem,
                fieldValue: [],
              });
              state.postPreviews[index].mediaItem = mediaItem;
              // Remove the social page that is now being copied
              nextAccountAPIId = additionalSocialPages[0];
              if (numPosts - 1 === CROSS_POST_LIMIT) {
                // Expand all views
                additionalSocialPages = [];
              } else {
                additionalSocialPages.splice(0, 1);
              }
              handleCrossPropertyPage({
                accountAPIId: nextAccountAPIId,
                state,
                guidToCopy: preview.guid,
                additionalSocialPages,
              });
            }
          }
        } else if (additionalSocialPages.indexOf(accountAPIId) > -1) {
          // The social API is in the list of additional pages - just remove
          // The views should remain collapsed
          additionalSocialPages.splice(
            additionalSocialPages.indexOf(accountAPIId),
            1,
          );
          mediaItem = MediaItem.setAdditionalSocialPages({
            mediaItem,
            fieldValue: additionalSocialPages,
          });
          state.postPreviews[index].mediaItem = mediaItem;
        }
      });

      // Check if the previews need to be un-collapsed - the un-selected view will still be counted as a post
      if (numPosts - 1 === CROSS_POST_LIMIT && !deselectAll) {
        state.passedCrossPostLimit = false;
        current(state.postPreviews).forEach((preview, index) => {
          let mediaItem = Immutable(preview.mediaItem);
          const additionalSocialPages = MediaItem.getAdditionalSocialPages({
            mediaItem,
          });
          if (!preview.isHidden || additionalSocialPages.length > 0) {
            mediaItem = MediaItem.setAdditionalSocialPages({
              mediaItem,
              fieldValue: [],
            });
            state.postPreviews[index].mediaItem = mediaItem;
            additionalSocialPages.forEach((additionalAccountAPIId) => {
              if (
                nextAccountAPIId == null ||
                additionalAccountAPIId !== nextAccountAPIId
              ) {
                handleCrossPropertyPage({
                  accountAPIId: additionalAccountAPIId,
                  state,
                  guidToCopy: preview.guid,
                });
              }
            });
          }
        });
      }
    },
    replaceImage: (
      state,
      action: PayloadAction<{
        guid: string;
        imageIndex: number;
        imageURL: string;
      }>,
    ) => {
      const { guid, imageIndex, imageURL } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const imageURLs = Immutable.asMutable(
        MediaItem.getImageURLs({ mediaItem }),
      );

      const twitterCard = MediaItem.getTwitterCardType({ mediaItem });
      if (twitterCard !== null) {
        mediaItem = MediaItem.setTwitterCardType({
          fieldValue: null,
          mediaItem,
        });
        mediaItem = MediaItem.setTitle({
          fieldValue: null,
          mediaItem,
        });
        mediaItem = MediaItem.setDescription({
          fieldValue: null,
          mediaItem,
        });
      }

      imageURLs[imageIndex] = imageURL;
      mediaItem = MediaItem.setImageURLs({
        fieldValue: imageURLs,
        mediaItem,
        setIsEdited: true,
      });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    startSavingMediaItems: (
      state,
      action: PayloadAction<{ guids: Array<string> }>,
    ) => {
      action.payload.guids.forEach((guid) => {
        const index = getMediaItemIndex({ guid, state });
        if (index !== -1) {
          let mediaItem = Immutable(findMediaItem({ guid, state }));
          if (mediaItem !== null) {
            mediaItem = MediaItem.setIsSaving({
              mediaItem,
              fieldValue: true,
            });
            state.postPreviews[index].mediaItem = mediaItem;
          }
        }
      });
    },
    toggleABTestCheckbox: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const abInfo = MediaItem.getABInfo({
        mediaItem,
      });

      if (abInfo.isABVariation) {
        mediaItem = MediaItem.deleteABVariation({
          mediaItem,
          variationIndex: 1 - abInfo.currentABVariationIndex,
        }); // Delete the OTHER variation, not the current one
      } else {
        mediaItem = MediaItem.addABVariation({ mediaItem });
      }

      state.postPreviews[index].mediaItem = mediaItem;
    },
    toggleABVariation: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const abInfo = MediaItem.getABInfo({ mediaItem });
      if (!abInfo.isABVariation) {
        // Should never happen - shouldn't be able to toggle AB variation if checkbox is not checked
        return;
      }

      const variationIndex = abInfo.currentABVariationIndex === 0 ? 1 : 0;
      mediaItem = MediaItem.changeABVariation({
        mediaItem,
        variationIndex,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    toggleImageOverlay: (
      state,
      action: PayloadAction<{
        guid: string;
        resultURL: string;
        imageOverlayURL: string;
      }>,
    ) => {
      const { guid, resultURL, imageOverlayURL } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }

      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
      const hasImageOverlay = trackingDetails.hasImageOverlay
        ? trackingDetails.hasImageOverlay
        : false;
      const hasImageOverlayNewValue = !hasImageOverlay;

      mediaItem = MediaItem.setTrackingDetails({
        mediaItem,
        fieldValue: {
          hasImageOverlay: hasImageOverlayNewValue,
        },
        allowOverride: true,
      });

      const abInfo = MediaItem.getABInfo({ mediaItem });
      const postType = MediaItem.getPostType({ mediaItem });
      tracker.track({
        eventName: 'Toggle Image Overlay',
        trackingParams: {
          'Social Network': getSocialNetworkName({
            apiTypeId: getCurrentAPITypeId(),
          }),
          'Social Page': getAPIPostName({
            accountAPIId: getCurrentAccountAPIId(),
          }),
          'Article URL':
            postType === POST_TYPES.LINK
              ? MediaItem.getUnshortenedShareURL({ mediaItem })
              : 'undefined',
          'New Status': trackingDetails.hasImageOverlay ? 'Off' : 'On',
        },
      });

      // we need to remove Image overlay
      if (!hasImageOverlayNewValue) {
        if (abInfo.isABVariation) {
          const variations = MediaItem.getABVariations({ mediaItem });
          const [variationA, variationB] = variations;
          variationA.imageOverlayURL = null;
          MediaItem.setABVariations({
            mediaItem,
            fieldValue: [variationA, variationB],
          });
        }
        mediaItem = MediaItem.setImageOverlayURL({
          mediaItem,
          fieldValue: null,
        });
        mediaItem = MediaItem.setImageOverlayResult({
          mediaItem,
          fieldValue: null,
        });
        state.postPreviews[index].mediaItem = mediaItem;
        return;
      }

      mediaItem = MediaItem.setIsApplyImageOverlay({
        mediaItem,
        fieldValue: true,
      });

      state.postPreviews[index].mediaItem = mediaItem;

      try {
        mediaItem = MediaItem.setImageOverlayURL({
          mediaItem,
          fieldValue: imageOverlayURL,
        });

        mediaItem = MediaItem.setImageOverlayResult({
          mediaItem,
          fieldValue: resultURL,
        });
        if (abInfo.isABVariation) {
          const variations = MediaItem.getABVariations({ mediaItem });
          const [variationA, variationB] = variations;
          const newVariationA = {
            ...variationA,
            imageOverlayURL,
          };
          mediaItem = MediaItem.setABVariations({
            mediaItem,
            fieldValue: [newVariationA, variationB],
          });
        }
      } catch (error) {
        console.log(error);
        addNotification('Applying Image Overlay failed', 'error');
      }

      mediaItem = MediaItem.setIsApplyImageOverlay({
        mediaItem,
        fieldValue: false,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    toggleIsLive: (state, action: PayloadAction<{ guid: string }>) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }

      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const isLive = MediaItem.getIsLive({ mediaItem });
      mediaItem = MediaItem.setIsLive({
        mediaItem,
        fieldValue: !isLive,
      });
      state.postPreviews[index].mediaItem = mediaItem;
    },
    togglePostToLinkInBioCheckbox: (
      state,
      action: PayloadAction<{ guid: string }>,
    ) => {
      const { guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem !== null) {
          const currentValue = MediaItem.getShouldSendURL({
            mediaItem,
          });

          mediaItem = MediaItem.setShouldSendURL({
            fieldValue: !currentValue,
            mediaItem,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    updateFirstComment: (
      state,
      action: PayloadAction<{ firstComment: string; guid: string }>,
    ) => {
      const { firstComment, guid } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem !== null) {
          mediaItem = MediaItem.setFirstComment({
            mediaItem,
            fieldValue: firstComment,
          });

          mediaItem = MediaItem.setIsChanged({ mediaItem, fieldValue: true });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    updateMediaItemByGuid: (
      state,
      action: PayloadAction<{ guid: string; mediaItem: FixTypeLater }>,
    ) => {
      const { guid, mediaItem } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== undefined && index !== -1) {
        state.postPreviews[index].mediaItem = mediaItem;
      }
    },
    updatePostImageNeedsToBeUpdated: (
      state,
      action: PayloadAction<{
        guid: string;
        imageIndex: number;
        imageNeedsToBeUpdated: boolean;
      }>,
    ) => {
      const { guid, imageIndex, imageNeedsToBeUpdated } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      const postImageNeedsToBeUpdated = MediaItem.getPostImageNeedsToBeUpdated({
        mediaItem,
      });
      postImageNeedsToBeUpdated[imageIndex] = imageNeedsToBeUpdated;

      mediaItem = MediaItem.setPostImageNeedsToBeUpdated({
        fieldValue: postImageNeedsToBeUpdated,
        mediaItem,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    updateSelectedShareContent: (
      state,
      action: PayloadAction<{
        guid: string;
        messageHistory: MessageHistory;
        selectedShareContent: ShareContent;
        selectedContentIndex: number;
        aiMessage: ImmutableType<AIMessage>;
      }>,
    ) => {
      const {
        guid,
        messageHistory,
        selectedShareContent,
        selectedContentIndex,
        aiMessage,
      } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      let mediaItem = Immutable(findMediaItem({ guid, state }));
      if (index === -1 || mediaItem === null) {
        return;
      }

      const messages = MediaItem.getMessages({ mediaItem });

      if (!messages) {
        return;
      }

      const newMessages = MessageBoxTools.updateMessages({
        messages: Immutable(messages),
        selectedContentIndex,
        selectedShareContent,
      });

      mediaItem = MediaItem.setMessageHistory({
        mediaItem,
        fieldValue: messageHistory,
      });
      mediaItem = MediaItem.setMessages({
        mediaItem,
        fieldValue: newMessages,
      });
      mediaItem = MediaItem.setAIMessage({
        mediaItem,
        fieldValue: aiMessage,
      });

      state.postPreviews[index].mediaItem = mediaItem;
    },
    updateSocialChannel: (
      state,
      action: PayloadAction<{ guid: string; socialChannel: SocialChannel }>,
    ) => {
      const { guid, socialChannel } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem !== null) {
          mediaItem = MediaItem.setSocialChannel({
            fieldValue: socialChannel,
            mediaItem,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    updateValidationMessage: (
      state,
      action: PayloadAction<{ guid: string; message: string | null }>,
    ) => {
      const { guid, message } = action.payload;
      const index = getMediaItemIndex({ guid, state });
      if (index !== -1) {
        let mediaItem = Immutable(findMediaItem({ guid, state }));
        if (mediaItem !== null) {
          mediaItem = MediaItem.setValidationMessage({
            mediaItem,
            fieldValue: message,
          });

          state.postPreviews[index].mediaItem = mediaItem;
        }
      }
    },
    updateVideoURL: (
      state,
      action: PayloadAction<{ guid: string; videoURL: string }>,
    ) => {
      const { guid, videoURL } = action.payload;

      const index = getMediaItemIndex({ guid, state });
      if (index === -1) {
        return;
      }
      let mediaItem = findMediaItem({ guid, state });
      if (mediaItem === null) {
        return;
      }

      mediaItem = MediaItem.setVideoURL({
        fieldValue: convertEchoboxVideoToAmazon(videoURL),
        mediaItem,
      });
      mediaItem = MediaItem.setIsChanged({ fieldValue: true, mediaItem });

      state.postPreviews[index].mediaItem = mediaItem;
    },
  },
});

export const { reducer } = composeBoxSlice;

export const {
  addFiles,
  addImage,
  addMessageToHistory,
  addPreview,
  addSocialPage,
  applyFieldUpdatesForAllGuids,
  applyFieldUpdatesForGuid,
  closeDuplicateScheduleModal,
  deleteAllImages,
  deleteFiles,
  deleteImage,
  deletePostPreview,
  deleteVideo,
  duplicateComposeBoxItem,
  finishSavingMediaItem,
  firstCommentChange,
  generateAIMessageError,
  generateAIMessageRequest,
  generateAIMessageSuccess,
  handleIGLinkStickerConfigUpdate,
  handleIsFirstRender,
  handleIsPopupSelectorOpen,
  handleMediaItemError,
  handlePostTypeChange,
  handleRefreshPreviewError,
  handleRefreshPreviewPending,
  handleRefreshPreviewSuccess,
  handleSelectedTabChange,
  handleSponsorSelect,
  handleTimingOptionsChange,
  handleURLChangePending,
  handleURLChangeSuccess,
  insertTagIntoMediaItem,
  removeSocialPage,
  replaceImage,
  startSavingMediaItems,
  toggleABTestCheckbox,
  toggleABVariation,
  toggleImageOverlay,
  toggleIsLive,
  togglePostToLinkInBioCheckbox,
  updateFirstComment,
  updateMediaItemByGuid,
  updatePostImageNeedsToBeUpdated,
  updateSelectedShareContent,
  updateSocialChannel,
  updateValidationMessage,
  updateVideoURL,
} = composeBoxSlice.actions;
