import { Td as BaseTd } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ReactNode } from 'react';

interface TdProps {
  children: ReactNode;
}
const Td = (props: TdProps) => {
  return <BaseTd py={3} px={4} display="flex" alignItems="center" {...props} />;
};

export default Td;
