/* eslint no-use-before-define: ["error", { "functions": false }] */

import Immutable from 'seamless-immutable';

import { getAPITypeId } from 'common/accountAPIs';
import { MAX_AB_VARIATIONS } from 'common/config';
import { AB_TESTING_FIELDS, SUGGESTION_TYPES } from 'common/constants';
import { hasABVariations } from 'common/social';
import { isDefined } from 'common/utility';
import { mandatory } from 'common/validation';

import {
  getABVariations,
  getAccountAPIId,
  getDescription,
  getImageOverlayURL,
  getImageURLs,
  getMessage,
  getPreviewField,
  getPreviewFields,
  getSocialChannel,
  getTitle,
  getVideoURL,
  setDescription,
  setImageURLs,
  setMessage,
  setPreviewField,
  setTitle,
  setVideoURL,
} from './gettersSetters';

export {
  addABVariation,
  changeABVariation,
  deleteABVariation,
  isABVariationsSame,
  removeABResults,
};

/**
 * Methods for adding, changing and deleting AB variations
 */

function addABVariation({ mediaItem = mandatory('mediaItem') } = {}) {
  let updatedItem = mediaItem;

  checkForABVariations({ mediaItem: updatedItem });

  const abInfo = getPreviewField({
    mediaItem: updatedItem,
    fieldName: 'abInfo',
  });

  // Throw an error if this item already has the maximum number of AB variations
  if (
    abInfo.isABVariation &&
    updatedItem.frontend.abVariations.length === MAX_AB_VARIATIONS
  ) {
    throw new ReferenceError('Maximum number of AB variations reached');
  }

  // Convert the item to have AB variations if necessary
  if (!abInfo.isABVariation) {
    updatedItem = initialiseABVariations({
      mediaItem: updatedItem,
    });
    return updatedItem.setIn(
      ['backend', 'suggestionTypeId'],
      SUGGESTION_TYPES.MANUAL_SOCIAL_AB_SHARE,
    );
  }

  // Clone the currently-selected variation
  const currentVariation = getPreviewFields({ mediaItem: updatedItem });
  const abVariation = {
    message: currentVariation.message,
    title: currentVariation.title,
    description: currentVariation.description,
    imageURLs: currentVariation.imageURLs,
    videoURL: currentVariation.videoURL,
  };
  // Add new variation
  updatedItem = setPreviewField({
    mediaItem: updatedItem,
    fieldName: 'abInfo',
    fieldValue: {
      isABVariation: true,
      currentABVariationIndex: 0,
    },
  });
  updatedItem = setPreviewField({
    mediaItem: updatedItem,
    fieldName: 'abVariations',
    fieldValue: currentVariation.abVariations.concat(abVariation),
  });

  return updatedItem;
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  variationIndex: number;
 * }}
 */
function changeABVariation({
  mediaItem = mandatory('mediaItem'),
  variationIndex = mandatory('variationIndex'),
} = {}) {
  checkForABVariations({ mediaItem });

  return mediaItem.setIn(
    ['frontend', 'abInfo', 'currentABVariationIndex'],
    variationIndex,
  );
}

function checkForABVariations({ mediaItem = mandatory('mediaItem') } = {}) {
  // Throw an error when attempting to perform an AB variation activity on a non-Facebook account
  const accountAPIId = getAccountAPIId({ mediaItem });
  const socialChannel = getSocialChannel({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  if (!hasABVariations({ apiTypeId, socialChannel })) {
    throw new ReferenceError(
      'AB variations only permitted for Facebook accounts',
    );
  }
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  variationIndex: number;
 * }}
 */
function deleteABVariation({
  mediaItem = mandatory('mediaItem'),
  variationIndex = mandatory('variationIndex'),
} = {}) {
  let updatedItem = mediaItem;

  // Throw an error when attempting to perform an AB variation activity on a non-Facebook account
  const accountAPIId = getAccountAPIId({ mediaItem });
  const socialChannel = getSocialChannel({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  if (!hasABVariations({ apiTypeId, socialChannel })) {
    throw new ReferenceError(
      'AB variations only permitted for Facebook accounts',
    );
  }

  // Remove the specified variation
  const abVariations = Immutable.asMutable(updatedItem.frontend.abVariations);
  abVariations.splice(variationIndex, 1);
  updatedItem = updatedItem.setIn(
    ['frontend', 'abVariations'],
    Immutable(abVariations),
  );
  // Reset the current variation pointer if necessary
  if (
    updatedItem.frontend.abInfo.currentABVariationIndex >
    abVariations.length - 1
  ) {
    updatedItem.setIn(
      ['frontend', 'abInfo', 'currentABVariationIndex'],
      abVariations.length - 1,
    );
  }
  // Convert back to 'normal' item if there is only one remaining variation
  if (abVariations.length === 1) {
    updatedItem = setPreviewField({
      mediaItem: updatedItem,
      fieldName: 'abInfo',
      fieldValue: {
        isABVariation: false,
      },
    });

    updatedItem = updatedItem.setIn(
      ['backend', 'suggestionTypeId'],
      SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE,
    );

    const abVariation = getPreviewField({
      mediaItem: updatedItem,
      fieldName: 'abVariations',
    })[0];
    abVariation.message = abVariation.message ? abVariation.message : '';
    abVariation.title = abVariation.title ? abVariation.title : '';
    abVariation.description = abVariation.description
      ? abVariation.description
      : '';
    abVariation.imageURLs = abVariation.imageURLs ? abVariation.imageURLs : [];
    abVariation.videoURL = abVariation.videoURL ? abVariation.imageURLs : '';
    updatedItem = updatedItem.set(
      'backend',
      updatedItem.backend.without(['abTestStatusId', 'abVariations']),
    );
    const frontend = Immutable.asMutable(updatedItem.frontend);
    delete frontend.abVariations;
    updatedItem = updatedItem.set('frontend', frontend);
    updatedItem = setMessage({
      mediaItem: updatedItem,
      fieldValue: abVariation.message,
    });
    updatedItem = setTitle({
      mediaItem: updatedItem,
      fieldValue: abVariation.title,
    });
    updatedItem = setDescription({
      mediaItem: updatedItem,
      fieldValue: abVariation.description,
    });
    updatedItem = setImageURLs({
      mediaItem: updatedItem,
      fieldValue: abVariation.imageURLs,
    });
    updatedItem = setVideoURL({
      mediaItem: updatedItem,
      fieldValue: abVariation.videoURL,
    });
  }
  return updatedItem;
}

function initialiseABVariations({ mediaItem = mandatory('mediaItem') } = {}) {
  let updatedItem = mediaItem;

  const abVariations = [];
  const message = getMessage({ mediaItem: updatedItem });
  const title = getTitle({ mediaItem: updatedItem });
  const description = getDescription({ mediaItem: updatedItem });
  const imageURLs = getImageURLs({ mediaItem: updatedItem });
  const videoURL = getVideoURL({ mediaItem: updatedItem });
  const imageOverlayURL = getImageOverlayURL({ mediaItem: updatedItem });
  // When converting to AB variation, always need to add TWO variations, initially identical
  abVariations.push({
    message,
    title,
    description,
    imageURLs,
    videoURL,
    imageOverlayURL,
  });
  abVariations.push({
    message,
    title,
    description,
    imageURLs,
    videoURL,
  });
  updatedItem = setPreviewField({
    mediaItem: updatedItem,
    fieldName: 'abInfo',
    fieldValue: {
      isABVariation: true,
      currentABVariationIndex: 0,
    },
  });
  updatedItem = setPreviewField({
    mediaItem: updatedItem,
    fieldName: 'abVariations',
    fieldValue: abVariations,
  });

  return updatedItem;
}

function removeABResults({ mediaItem = mandatory('originalItem') } = {}) {
  const updatedItem = Immutable.asMutable(mediaItem, { deep: true });
  if (isDefined(updatedItem.backend.abResults)) {
    delete updatedItem.backend.abResults;
  }
  if (isDefined(updatedItem.backend.abTestStatusId)) {
    delete updatedItem.backend.abTestStatusId;
  }
  return Immutable(updatedItem);
}

function isABVariationsSame({ mediaItem = mandatory('mediaItem') }) {
  const abVariations = getABVariations({ mediaItem });
  const [variationA, variationB] = abVariations;
  const variationAStr = JSON.stringify(variationA, AB_TESTING_FIELDS);
  const variationBStr = JSON.stringify(variationB, AB_TESTING_FIELDS);
  return variationAStr === variationBStr;
}
