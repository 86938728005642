import {
  Button,
  Center,
  EditPenIcon,
  Flex,
  Heading,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

export interface ReviewSectionProps {
  children: React.ReactNode;
  isLoading?: boolean;
  title: string;
  onEdit?: () => void;
}

const ReviewSection = ({
  title,
  isLoading = false,
  children,
  onEdit,
}: ReviewSectionProps) => (
  <div>
    <Flex alignItems="center" justifyContent="space-between" mb={4}>
      <Heading variant="h4">{title}</Heading>
      {onEdit && (
        <Button variant="link" onClick={onEdit} gap={2}>
          <EditPenIcon color="gray.900" />
          Edit
        </Button>
      )}
    </Flex>
    {isLoading ? (
      <Center>
        <Spinner size="lg" />
      </Center>
    ) : (
      <Flex p={4} boxShadow="border" borderRadius="lg" gap={3} flexDir="column">
        {children}
      </Flex>
    )}
  </div>
);

export default ReviewSection;
