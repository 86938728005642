import { createContext, useContext } from 'react';

const GlobalInfoContext = createContext();

export const useGlobalInfo = () => {
  const context = useContext(GlobalInfoContext);

  if (context === undefined) {
    throw new Error(
      'useGlobalInfo must be used within a GlobalInfoContext Provider',
    );
  }

  return context;
};

export default GlobalInfoContext;
