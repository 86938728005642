const ArrowUp = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6048 7.0502L7.58798 2.87734C7.69645 2.76354 7.84345 2.7 7.99993 2.7C8.15641 2.7 8.30436 2.76373 8.412 2.87635L12.3952 7.0492C12.6115 7.27667 12.6034 7.63672 12.3759 7.8539C12.1507 8.07098 11.7891 8.06283 11.5712 7.83464L8.56896 4.68874V12.7523C8.56896 13.0665 8.31408 13.3 8.02127 13.3C7.72846 13.3 7.4309 13.0676 7.4309 12.7523V4.68874L4.42929 7.83498C4.21164 8.06259 3.85007 8.0697 3.62407 7.85395C3.39661 7.63582 3.38845 7.27544 3.6048 7.0502Z"
        fill="#0E1E39"
      />
    </svg>
  );
};

export default ArrowUp;
