import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  ACCOUNT_SETTING_TYPES_NAMES,
  API_PROPERTIES,
} from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { AccountSettingTypesNamesSchema } from 'common/schemas';
import { convertToSocialPageURN, extractAccountAPIId } from 'common/urn';
import { lyingParse } from 'common/zod';
import { GlobalInfo, SettingTypeId, Settings } from 'types';

const ResponseSchema = z
  .object({
    settings: z.array(
      z.object({
        socialPageURN: z.string(),
        settingType: AccountSettingTypesNamesSchema,
        enabled: z.boolean(),
        dataJSON: z.unknown().optional(),
      }),
    ),
  })
  .describe('getAPIsSettingsByTypeAndSocialPageURNs.ResponseSchema');

export default async function getAPIsSettingsByTypeAndAccountAPIIds({
  accountAPIIds,
  settingTypeIds,
  globalInfo = getGlobalInfo(),
}: {
  accountAPIIds: number[];
  settingTypeIds: SettingTypeId[];
  globalInfo?: GlobalInfo.GlobalInfo;
}) {
  if (accountAPIIds.length === 0) {
    return {};
  }

  const socialPagesURNs = accountAPIIds.map((apiId) => {
    const apiTypeId = getAPITypeId({
      accountAPIId: apiId,
      globalInfo,
    });
    const apiProperties = API_PROPERTIES[apiTypeId];
    const urnName =
      'urnName' in apiProperties ? apiProperties.urnName : undefined;
    return convertToSocialPageURN(urnName, apiId);
  });

  return getAPIsSettingsByTypeAndSocialPageURNs({
    socialPagesURNs,
    settingTypeIds,
  });
}

/**
 * GET /apis/{accountAPIId}/settings - retrieve account settings information
 */
export async function getAPIsSettingsByTypeAndSocialPageURNs({
  socialPagesURNs,
  settingTypeIds,
}: {
  socialPagesURNs: string[];
  settingTypeIds: SettingTypeId[];
}) {
  if (socialPagesURNs.length === 0) {
    return {};
  }

  const guid = metrics.start('getAPIsSettingsByType');

  const settingTypes = settingTypeIds.map(
    (id) => ACCOUNT_SETTING_TYPES_NAMES[id],
  );

  const apiIds = socialPagesURNs.map((urn) => {
    return extractAccountAPIId(urn);
  });

  const config = {
    url: `${constructBaseSocialAPIURL(
      'v3.3',
    )}/social/api/${socialPagesURNs}/settings/types/${settingTypes.toString()}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };
  logger.info(
    `API:getAPIsSettingsByType social/api/${socialPagesURNs}/settings/types/${settingTypes.toString()}`,
  );

  try {
    const responses = await axios(config);

    const validatedData = lyingParse(ResponseSchema, responses.data);

    const response = validatedData.settings.reduce<Record<number, Settings>>(
      (accumulator, setting) => {
        const apiId = extractAccountAPIId(setting.socialPageURN);
        const settingTypeId = ACCOUNT_SETTING_TYPES[setting.settingType];
        return {
          ...accumulator,
          [apiId]: {
            ...accumulator[apiId],
            [settingTypeId]: [
              ...(accumulator[apiId]?.[settingTypeId] ?? []),
              setting,
            ],
          },
        };
      },
      {},
    );

    metrics.end('getAPIsSettingsByType', guid);
    logger.debug('getAPIsSettingsByType', { accountAPIIds: apiIds });

    return response;
  } catch (error) {
    metrics.fail('getAPIsSettingsByType', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getAPIsSettingsByType',
      args: { accountAPIIds: apiIds },
    });
    throw apiError;
  }
}
