import {
  Button,
  Flex,
  Select,
  Text,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { PERMISSION_TYPES } from 'common/constants';
import { ChangeEvent } from 'react';
import { PermissionTypeId, SocialAPI } from 'types';
import { PropertyDetails } from './UserAddNew';

interface UserRoleProps {
  availableAPIs: SocialAPI[];
  propertyRole: PermissionTypeId;
  selectedPages: number[];
  selectedProperties: Record<number, PropertyDetails>;
  isOnlyCurrentPropertySelected: boolean;
  onRoleChange: (role: PermissionTypeId) => void;
  onPagesClick: () => void;
}

const UserRole = ({
  availableAPIs,
  propertyRole,
  selectedPages,
  selectedProperties,
  isOnlyCurrentPropertySelected,
  onRoleChange,
  onPagesClick,
}: UserRoleProps) => {
  const isAdministrator = propertyRole === PERMISSION_TYPES.ADMIN;

  const handleRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onRoleChange(parseInt(event.target.value) as PermissionTypeId);
  };

  const getButtonLabel = () => {
    if (selectedPages.length === 1) {
      return '1 page';
    }
    if (selectedPages.length === availableAPIs.length) {
      return 'All pages';
    }
    return `${selectedPages.length} pages`;
  };

  const allPagesToolTipLabel =
    Object.keys(selectedProperties).length === 1
      ? 'You’ll be able to select specific pages in Settings.'
      : 'Editors access all pages when multiple Properties are selected.';

  return (
    <Flex alignItems="center">
      <Select value={propertyRole} onChange={handleRoleChange}>
        <option value={PERMISSION_TYPES.ADMIN}>Administator</option>
        <option value={PERMISSION_TYPES.EDITOR}>Editor</option>
      </Select>
      {(Object.keys(selectedProperties).length === 1 && isAdministrator) ||
      Object.keys(selectedProperties).length > 1 ||
      !isOnlyCurrentPropertySelected ? (
        <Tooltip
          isDisabled={isAdministrator}
          textAlign="center"
          placement="top-start"
          label={allPagesToolTipLabel}
        >
          <Text size="sm" ml={6}>
            All pages
          </Text>
        </Tooltip>
      ) : (
        <Button
          fontWeight="normal"
          variant="secondary"
          height="9"
          ml={6}
          onClick={() => onPagesClick()}
          isDisabled={false}
        >
          {getButtonLabel()}
        </Button>
      )}
    </Flex>
  );
};

export default UserRole;
