import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { API_STATES, API_TYPE_IDS } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { SocialAPIResponseSchema } from 'common/schemas';
import { getSocialNetworkDefaultPageIcon } from 'common/social';
import { generateGuid } from 'common/string';
import { extractAccountAPIId, extractPropertyId } from 'common/urn';
import { isDefined } from 'common/utility';
import { lyingParse } from 'common/zod';
import { GlobalInfo } from 'types';

const BATCH_SIZE = 100;

const ResponseSchema = z
  .object({
    apis: z.array(SocialAPIResponseSchema),
  })
  .describe('getPropertiesAPIs.ResponseSchema');

/**
 * GET /social/properties/{propertyURNs}/apis - retrieve account api information
 */
export default async function getPropertiesAPIs({
  propertyURNs,
}: {
  propertyURNs: string[];
}) {
  const guid = metrics.start('getPropertiesAPIs');

  const processChunk = async (chunk: string[]) => {
    const config = {
      url: `${constructBaseSocialAPIURL(
        'v3.1',
      )}/social/properties/${chunk.toString()}/apis`,
      method: 'GET',
      timeout: API_TIMEOUTS.M,
      headers: getClientServiceRequestHeaders(),
    };
    logger.info(
      `API:getPropertiesAPIs /social/properties/${chunk.toString()}/apis`,
    );

    try {
      const responses = await axios(config);
      return lyingParse(ResponseSchema, responses.data);
    } catch (error) {
      metrics.fail('getPropertiesAPIs', guid);
      const apiError = await handleAPIError({
        originalError: error,
        errorLocation: 'api/getPropertiesAPIs',
        args: { propertyURNs: chunk },
      });
      throw apiError;
    }
  };

  let data: Awaited<ReturnType<typeof processChunk>>;
  if (propertyURNs.length > BATCH_SIZE) {
    const chunks = [];
    for (let i = 0; i < propertyURNs.length; i += BATCH_SIZE) {
      chunks.push(propertyURNs.slice(i, i + BATCH_SIZE));
    }
    const responses = await Promise.all(chunks.map(processChunk));
    data = responses.reduce(
      (acc, response) => {
        acc.apis.push(...response.apis);
        return acc;
      },
      { apis: [] },
    );
  } else {
    data = await processChunk(propertyURNs);
  }

  // Convert response to an object containing one response object per account
  const response: Record<number | string, GlobalInfo.SocialAPI[]> = {};
  // Create keys for each property
  propertyURNs.forEach((propertyURN) => {
    response[extractPropertyId(propertyURN)] = [];
  });
  // Process responses
  data.apis.forEach((api) => {
    const socialAPIURN = api.socialAPIURN;
    const accountAPIId = extractAccountAPIId(socialAPIURN);
    const apiTypeId = API_TYPE_IDS[api.apiType];
    const apiStateId = API_STATES[api.apiState];
    const propertyId = extractPropertyId(api.propertyURN);
    const apiEntry = api as GlobalInfo.SocialAPI;
    apiEntry.accountAPIId = accountAPIId;
    apiEntry.propertyId = propertyId;
    apiEntry.apiTypeId = apiTypeId;
    apiEntry.apiStateId = apiStateId;

    // Store page details against relevant property
    response[propertyId].push(apiEntry);

    // Additional processing for each page
    for (const entry of Object.values(response[propertyId])) {
      // Add notifications if page is disconnected
      entry.notifications = [];
      if (entry.requiresReconnect) {
        entry.apiPostImage = getSocialNetworkDefaultPageIcon({
          apiTypeId: entry.apiTypeId,
        });
        entry.notifications.push({
          id: generateGuid(),
          type: 'error',
          message: 'Requires reconnect',
          link: '/settings',
        });
      }
      // Use alt name if post name is not specified
      if (!isDefined(entry.apiPostName)) {
        entry.apiPostName = entry.apiAltName;
      }
      // Use default page icon if post image is not specified
      if (!isDefined(entry.apiPostImage)) {
        entry.apiPostImage = getSocialNetworkDefaultPageIcon({
          apiTypeId: entry.apiTypeId,
        });
      }
      // Set default value for sharing paused
      if (!isDefined(entry.isSharingPaused)) {
        entry.isSharingPaused = false;
      }
    }
  });
  metrics.end('getPropertiesAPIs', guid);
  logger.debug('getPropertiesAPIs', { propertyURNs });
  return response;
}
