import { useContext, useState } from 'react';

import authUpdateUserName from 'api/aws/authUpdateUserName';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { addSuccessNotification } from 'common/notifications';
import { ebxMessage } from 'common/string';
import { isNull, isNullOrUndefined } from 'common/utility';
import Button from 'components/misc/Button';
import SettingsError from 'components/settings/SettingsError';
import GlobalInfoContext from 'context/GlobalInfoContext';

const UserName = () => {
  const context = useContext(GlobalInfoContext);

  /**
   * Initial state
   */

  const user = context.global.getGlobalInfo().user;

  const [currentName, setCurrentName] = useState(user.name);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [newName, setNewName] = useState('');

  /**
   * Event handlers
   */

  const handleErrorClose = () => {
    setErrorMessage('');
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    if (key === 'Enter') {
      handleSave();
    }
  };

  const handleSave = async () => {
    if (newName.trim() === '') {
      setIsSaving(false);
      setErrorMessage(
        ebxMessage('Please enter the name you would like to change.'),
      );
      return;
    }

    const name =
      isNullOrUndefined(newName) || newName === '' ? currentName : newName;

    setIsSaving(true);
    try {
      await authUpdateUserName({ name });
      setIsSaving(false);
      setErrorMessage('');
      updateCurrentInformationAndAllGlobalInfo();
      addSuccessNotification('Change saved');
    } catch (error) {
      setIsSaving(false);
      const message = getErrorMessage(determineError(error));
      if (isNull(getErrorStatus(error))) {
        logger.error({
          event: 'handleSave Error',
          properties: {
            location: 'components/settings/user/UserName',
          },
          error,
        });
      }
      setErrorMessage(message.trim());
    }
  };

  /**
   * Helper methods
   */

  const updateCurrentInformationAndAllGlobalInfo = () => {
    const newCurrentName =
      isNullOrUndefined(newName) || newName.trim() === ''
        ? currentName
        : newName;

    const globalInfo = context.global.getGlobalInfo();
    globalInfo.user.name = currentName;
    context.global.setGlobalInfo(globalInfo);

    setCurrentName(newCurrentName);
    setNewName('');
  };

  /**
   * Render method
   */

  return (
    <div className="box mb-3">
      <div className="d-flex box-header">
        <h3 className="align-self-center">Your Name</h3>
      </div>
      <div className="setting-part pb-3 UserDetails">
        <SettingsError
          errorMessage={errorMessage}
          eventHandlers={{ handleErrorClose }}
        />
        <div className="Name mt-3">
          <label htmlFor="Name">
            <div className="label text-500 pb-1">Full Name</div>
            <input
              placeholder={currentName}
              className="input_medium"
              data-cy-input="name"
              name="newName"
              id="newName"
              onChange={(event) => setNewName(event.target.value)}
              onKeyPress={handleKeyPress}
              value={newName}
              type="text"
              disabled={isSaving}
              style={{ minWidth: '240px' }}
            />
          </label>
        </div>
        <hr className="op-30 mt-3 mb-2" />
        <Button
          variant="dark"
          onClick={handleSave}
          disabled={isSaving}
          data-cy-action="saveName"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserName;
