import { Grid, Heading, Select } from '@ebx-ui/ebx-ui-component-library-sdk';
import { TIMEZONES } from 'common/constants';
import { Property } from 'types';

interface TimeZoneGridSettingItemProps {
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  property: Property;
}

const TimeZoneGridSettingItem = ({
  setSelectedTimezone,
  setChangesMade,
  property,
}: TimeZoneGridSettingItemProps) => {
  const timeZoneList = Object.values(TIMEZONES).map((timezone) => (
    <option key={timezone} value={timezone}>
      {timezone}
    </option>
  ));

  const handleTimeZoneChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedTimezone(event.target.value);
    setChangesMade(true);
  };

  return (
    <Grid.GridItem>
      <Heading variant="h6" color="gray.600" mb={1}>
        Time Zone:
      </Heading>
      <Select
        boxShadow="xs"
        placeholder={property.timezone}
        onChange={handleTimeZoneChange}
      >
        {timeZoneList}
      </Select>
    </Grid.GridItem>
  );
};

export default TimeZoneGridSettingItem;
