import { POST_TYPES, TWITTER_CARD } from 'common/constants';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import { useImageContext } from 'context/ComposeBoxImageContext';
import { PostType, TwitterCardType } from 'types';

import { IMAGE_MODES } from '../imageV2';

interface TwitterImageControlsProps {
  /**
   * The twitter card type (`summary` or `summary_large_image`)
   */
  twitterCardType: TwitterCardType;

  /**
   * The type of post being displayed.
   */
  postType: PostType;

  /**
   * The callback for when the refresh control is clicked.
   */
  onRefresh: () => void;
}

/**
 * Renders the image controls for twitter cards.
 */
const TwitterImageControls = ({
  twitterCardType,
  postType,
  onRefresh,
}: TwitterImageControlsProps) => {
  const { open, mode } = useImageContext();
  const addImageCaption = 'Upload photo';

  const savingClass =
    mode === IMAGE_MODES.EDIT || mode === IMAGE_MODES.CROP
      ? 'saving_locked'
      : '';

  return (
    <div
      className={`add_photo_container px-2 ${savingClass} position-absolute d-flex flex-wrap w-100`}
    >
      {TWITTER_CARD.SUMMARY_LARGE_IMAGE === twitterCardType ? (
        <>
          <div className="hover_container mb-2">
            <Tooltip id="image-caption" label={addImageCaption}>
              <Button
                className="add_photo text_unselectable"
                onClick={open}
                data-tip
                data-for="image-caption"
                data-iscapture="true"
              >
                <img src="/img/icons/ic-photo-add.svg" alt={addImageCaption} />
              </Button>
            </Tooltip>
            &nbsp;&nbsp;&nbsp;
            <div className="share_twitter_card_message">
              <img src="/img/icons/ic-info.svg" alt="" className="mr-1" />
              Adding a photo cancels the Twitter Card.
            </div>
          </div>
          {postType === POST_TYPES.LINK && (
            <div className="refresh_preview_container hover_container ml-auto">
              <Tooltip id="image-refresh" label="Refresh preview">
                <Button
                  className="add_photo refresh_preview text_unselectable"
                  onClick={onRefresh}
                  data-tip
                  data-for="image-refresh"
                  data-iscapture="true"
                >
                  <img
                    src="/img/icons/ic-refresh-dark.svg"
                    alt=""
                    className="icon-info"
                  />
                </Button>
              </Tooltip>
            </div>
          )}
        </>
      ) : (
        <Tooltip id="image-caption" label={addImageCaption}>
          <Button
            className="add_photo text_unselectable"
            onClick={open}
            data-tip
            data-for="image-caption"
            data-iscapture="true"
          >
            <img src="/img/icons/ic-photo-add.svg" alt={addImageCaption} />
            <span className="ml-2">Upload</span>
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default TwitterImageControls;
