import { useState } from 'react';

import {
  Card,
  Checkbox,
  ChevronButton,
  Dropdown,
  Flex,
  FormControl,
  NumberInput,
  Popover,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { MAX_CUMULATIVE_CUSTOM_CONTENT } from 'common/config';
import { ARTICLE_AGE_UNITS } from 'common/enums';
import * as settings from 'common/settings';
import * as string from 'common/string';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import { ReshareSettings } from 'components/settings/automation/reshares/Reshares';

interface CustomSettingsProps {
  currentReshareSettings: ReshareSettings;
  handleRehareSettingsChange: (
    settings: ReshareSettings,
    error?: boolean,
  ) => void;
}

function CustomSettings({
  currentReshareSettings,
  handleRehareSettingsChange,
}: CustomSettingsProps) {
  const { accountAPIId } = useAutomationSettingsContext();

  const [reshareVolume, setReshareVolume] = useState(
    currentReshareSettings.bestArticlesReshareNum.toString(),
  );
  const [volumeError, setVolumeError] = useState('');

  const [reshareMaxAge, setReshareMaxAge] = useState(
    currentReshareSettings.startPastDaysForBestArticleReshare.toString(),
  );
  const [maxAgeError, setMaxAgeError] = useState('');

  const [resharesPerArticle, setResharesPerArticle] = useState(
    currentReshareSettings.maxResharesPerArticle.toString(),
  );
  const [resharesPerArticleError, setResharesPerArticleError] = useState('');

  const [reshareInterval, setReshareInterval] = useState(
    currentReshareSettings.reshareIntervalValue.toString(),
  );
  const [reshareIntervalError, setReshareIntervalError] = useState('');

  const maxArticleAgeDays =
    settings.getAutofeedSetting({
      key: 'maximumAllowedArticleAgeDays',
      accountAPIId,
    }) ?? 0;

  const getMaxIntervalValue = (
    ageUnit: ARTICLE_AGE_UNITS,
    ageValue?: number,
  ) => {
    const dayValue =
      ageValue ?? currentReshareSettings.startPastDaysForBestArticleReshare;
    return ageUnit === ARTICLE_AGE_UNITS.DAYS ? dayValue : dayValue * 24;
  };

  const handleVolumeChange = (value: string) => {
    setReshareVolume(value);
    const numberValue = Number.isNaN(Number(value)) ? 0 : Number(value);

    const newSettings: ReshareSettings = {
      ...currentReshareSettings,
      bestArticlesReshareNum: numberValue,
    };

    if (
      numberValue < 1 ||
      numberValue >= MAX_CUMULATIVE_CUSTOM_CONTENT ||
      !value
    ) {
      setVolumeError(
        `Please specify a number between 1 and ${
          MAX_CUMULATIVE_CUSTOM_CONTENT - 1
        }.`,
      );
      handleRehareSettingsChange(newSettings, true);
    } else {
      setVolumeError('');
      handleRehareSettingsChange(newSettings);
    }
  };

  const handleMaxAgeChange = (value: string) => {
    setReshareMaxAge(value);
    const numberValue = Number.isNaN(Number(value)) ? 0 : Number(value);

    const newSettings: ReshareSettings = {
      ...currentReshareSettings,
      startPastDaysForBestArticleReshare: numberValue,
    };

    if (numberValue < 1 || numberValue > maxArticleAgeDays || !value) {
      setMaxAgeError(
        `Please specify a number between 1 and ${maxArticleAgeDays}.`,
      );
      handleRehareSettingsChange(newSettings, true);
    } else {
      setMaxAgeError('');
      handleRehareSettingsChange(newSettings);
    }

    if (
      currentReshareSettings.reshareIntervalValue >=
      getMaxIntervalValue(
        currentReshareSettings.reshareIntervalUnit,
        numberValue,
      )
    ) {
      setReshareIntervalError(
        `To allow reshares, the minimum duration between shares of the same article must be less than ${numberValue} days, the current value of "Articles older than this should not be reshared"`,
      );
    } else {
      setReshareIntervalError('');
    }
  };

  const handleResharesPerArticleChange = (value: string) => {
    setResharesPerArticle(value);
    const numberValue = Number.isNaN(Number(value)) ? 0 : Number(value);

    const newSettings: ReshareSettings = {
      ...currentReshareSettings,
      maxResharesPerArticle: numberValue,
    };

    if (numberValue < 1 || !value) {
      setResharesPerArticleError(`Please enter a value greater than 0.`);
      handleRehareSettingsChange(newSettings, true);
    } else {
      setResharesPerArticleError('');
      handleRehareSettingsChange(newSettings);
    }
  };

  const handleReshareIntervalValueChange = (value: string) => {
    setReshareInterval(value);
    const numberValue = Number.isNaN(Number(value)) ? 0 : Number(value);

    const newSettings: ReshareSettings = {
      ...currentReshareSettings,
      reshareIntervalValue: numberValue,
    };

    if (numberValue < 1) {
      setReshareIntervalError(`Please enter a value greater than 0.`);
      handleRehareSettingsChange(newSettings, true);
    } else if (
      numberValue >=
      getMaxIntervalValue(currentReshareSettings.reshareIntervalUnit)
    ) {
      setReshareIntervalError(
        `To allow reshares, the minimum duration between shares of the same article must be less than ${currentReshareSettings.startPastDaysForBestArticleReshare} days, the current value of "Articles older than this should not be reshared"`,
      );
      handleRehareSettingsChange(newSettings, true);
    } else {
      setReshareIntervalError('');
      handleRehareSettingsChange(newSettings);
    }
  };

  const handleReshareIntervalUnitChange = (value: ARTICLE_AGE_UNITS) => {
    const newSettings: ReshareSettings = {
      ...currentReshareSettings,
      reshareIntervalUnit: value,
    };

    const articleAgeValue = currentReshareSettings.reshareIntervalValue;

    if (articleAgeValue === 0) {
      setReshareIntervalError(`Please enter a value greater than 0.`);
      handleRehareSettingsChange(newSettings, true);
    } else if (articleAgeValue >= getMaxIntervalValue(value)) {
      setReshareIntervalError(
        `To allow reshares, the minimum duration between shares of the same article must be less than ${currentReshareSettings.startPastDaysForBestArticleReshare} days, the current value of "Articles older than this should not be reshared"`,
      );
      handleRehareSettingsChange(newSettings, true);
    } else {
      setReshareIntervalError('');
      handleRehareSettingsChange(newSettings);
    }
  };

  const handleCheckboxChange = () => {
    const newValue = !currentReshareSettings.reshareEvergreenContentOnly;
    const newSettings = {
      ...currentReshareSettings,
      reshareEvergreenContentOnly: newValue,
    };
    handleRehareSettingsChange(newSettings);
  };

  return (
    <>
      <Card
        flexDir="column"
        alignItems="flex-start"
        gap={2}
        bg="gray.100"
        w="100%"
        p={4}
      >
        <FormControl isInvalid={!!volumeError}>
          <Flex gap={2} alignItems="center">
            <NumberInput
              name="bestArticlesReshareNum"
              id="bestArticlesReshareNum"
              data-cy-input="reshareVolume"
              precision={0}
              min={1}
              value={reshareVolume}
              onChange={handleVolumeChange}
              w={12}
            >
              <NumberInput.Field />
            </NumberInput>
            <Text size="sm">reshares a day (target)</Text>
          </Flex>
          {volumeError && (
            <FormControl.FormErrorMessage>
              {volumeError}
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!maxAgeError}>
          <Flex gap={2} alignItems="center">
            <NumberInput
              name="startPastDaysForBestArticleReshare"
              id="startPastDaysForBestArticleReshare"
              data-cy-input="reshareMaxAge"
              precision={0}
              min={1}
              value={reshareMaxAge}
              onChange={handleMaxAgeChange}
              w={14}
            >
              <NumberInput.Field />
            </NumberInput>
            <Text size="sm">
              days. Articles older than this should not be reshared
            </Text>
          </Flex>
          {maxAgeError && (
            <FormControl.FormErrorMessage>
              {maxAgeError}
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!resharesPerArticleError}>
          <Flex gap={2} alignItems="center">
            <NumberInput
              name="maxResharesPerArticle"
              id="maxResharesPerArticle"
              data-cy-input="reshareMultiple"
              precision={0}
              min={1}
              max={9999}
              value={resharesPerArticle}
              onChange={handleResharesPerArticleChange}
              w={14}
            >
              <NumberInput.Field />
            </NumberInput>
            <Text size="sm">reshares per article (at most)</Text>
          </Flex>
          {resharesPerArticleError && (
            <FormControl.FormErrorMessage>
              {resharesPerArticleError}
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!reshareIntervalError}>
          <Flex gap={2} alignItems="center">
            <NumberInput
              name="minMinsBetweenSharesValue"
              id="minMinsBetweenSharesValue"
              data-cy-input="reshareIntervalNumber"
              precision={0}
              min={1}
              value={reshareInterval}
              onChange={handleReshareIntervalValueChange}
              w={14}
            >
              <NumberInput.Field />
            </NumberInput>
            <Dropdown matchWidth>
              <Dropdown.Button
                name="minMinsBetweenSharesUnit"
                data-cy-select="reshareIntervalUnit"
                as={ChevronButton}
                variant="secondary"
              >
                {string.toSentenceCase(
                  currentReshareSettings.reshareIntervalUnit,
                )}
              </Dropdown.Button>
              <Popover>
                <Dropdown.List>
                  <Dropdown.Item
                    onClick={() =>
                      handleReshareIntervalUnitChange(ARTICLE_AGE_UNITS.HOURS)
                    }
                  >
                    {string.toSentenceCase(ARTICLE_AGE_UNITS.HOURS)}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      handleReshareIntervalUnitChange(ARTICLE_AGE_UNITS.DAYS)
                    }
                  >
                    {string.toSentenceCase(ARTICLE_AGE_UNITS.DAYS)}
                  </Dropdown.Item>
                </Dropdown.List>
              </Popover>
            </Dropdown>
            <Text size="sm">minimum between shares of the same article</Text>
          </Flex>
          {reshareIntervalError && (
            <FormControl.FormErrorMessage>
              {reshareIntervalError}
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
      </Card>
      <Checkbox
        isChecked={currentReshareSettings.reshareEvergreenContentOnly}
        onChange={handleCheckboxChange}
      >
        Only reshare articles classified as Evergreen
      </Checkbox>
    </>
  );
}

export default CustomSettings;
