import PubSub from 'pubsub-js';

import getMediaList from 'api/getMediaList';
import { FAILED_SHARE_DURATION } from 'common/config';
import { MEDIA_ITEM_STATES } from 'common/constants';
import { getTimeFilterRange } from 'common/datetime';
import * as logger from 'common/logger';
import * as topics from 'pubsub/topics';

const { REQUEST_API_INFO, RESPONSE_API_INFO } = topics;

export function API_INFO(subtopic, args) {
  const { accountAPIId } = args;
  const response = { accountAPIId };
  const scheduleQueueConfig = {
    accountAPIId,
    state: MEDIA_ITEM_STATES.SCHEDULED,
    origin: `${REQUEST_API_INFO}:refreshQueueCounts`,
  };
  const failedQueueConfig = {
    accountAPIId,
    state: MEDIA_ITEM_STATES.FAILED,
    from: getTimeFilterRange(FAILED_SHARE_DURATION).fromTime,
    to: null,
    filter: 'all',
    origin: `${REQUEST_API_INFO}:refreshQueueCounts`,
  };
  getMediaList(scheduleQueueConfig)
    .then((mediaList) => {
      response.queuedItems = mediaList.mediaIds.length;
      return getMediaList(failedQueueConfig);
    })
    .then((mediaList) => {
      response.failedItems = mediaList.mediaIds.length;
      logger.info(`PubSub: publish ${RESPONSE_API_INFO} in pubsub/API_INFO`);
      PubSub.publish(`${RESPONSE_API_INFO}`, response);
    })
    .catch((error) => {
      // Not much we can do about errors here, so ignore them
      console.log(error);
    });
}
