/* eslint react/no-danger: "off" */

import createDOMPurify from 'dompurify';
import PropTypes from 'prop-types';

import { getTextDirection } from 'common/language';
import { encodeHTML, wrapWords } from 'common/string';
import { renderMessageTags } from 'common/tags';

const ItemTitle = (props) => {
  const { accountAPIId, articleTitle, itemTags, searchText } = props;

  const DOMPurify = createDOMPurify(window);
  const encodedArticleTitle = encodeHTML(DOMPurify.sanitize(articleTitle));

  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: encodedArticleTitle,
  });

  const titleWithHighlights = wrapWords({
    initialText: encodedArticleTitle,
    searchText,
    replaceStart: '<span class="highlight">',
    replaceEnd: '</span>',
  });
  const titleWithTags = renderMessageTags({
    message: titleWithHighlights,
    tags: itemTags,
  });

  return (
    <div
      className={`article-title text-truncate ${textDirectionClass}`}
      data-cy-id="title"
      dangerouslySetInnerHTML={{ __html: titleWithTags }}
    />
  );
};

ItemTitle.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  articleTitle: PropTypes.string.isRequired,
  itemTags: PropTypes.object.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default ItemTitle;
