import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

interface AutoDisableAutomationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}
const AutoDisableAutomationModal = ({
  isOpen,
  onCancel,
  onConfirm,
}: AutoDisableAutomationModalProps) => {
  return (
    <Modal size="small" isOpen={isOpen} onClose={onCancel}>
      <Modal.Header>
        <Modal.Title>Turn Automation off?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm" color="gray.600">
          Automation requires at least New Shares or Reshares to be turned on.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Turn Automation off
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutoDisableAutomationModal;
