import { Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  POST_TYPES,
  TIME_SENSITIVITY_NAMES,
  TIME_SENSITIVITY_TYPES,
} from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as tracker from 'common/tracker';
import PublishedTime from 'components/compose/PublishedTime';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import { getCommonTrackingParams } from 'helpers/tracking';
import type { FixTypeLater, TimeSensitivityType } from 'types';

interface TimeSensitivityProps {
  articleURL: string;
  isMobile: boolean;
  mediaItem: FixTypeLater;
  onTimeSensitivityChange: (timeSensitivityTypeId: TimeSensitivityType) => void;
  postType: string;
  publishedTime?: number | null;
  twelveHourFormat: boolean;
}

const TimeSensitivity = ({
  articleURL,
  isMobile,
  mediaItem,
  onTimeSensitivityChange,
  postType,
  publishedTime = null,
  twelveHourFormat,
}: TimeSensitivityProps) => {
  const handleTimeSensitivityClick = (
    timeSensitivityTypeId: TimeSensitivityType,
  ) => {
    logger.info(
      `TimingOptions:handleTimeSensitivityClick ${timeSensitivityTypeId}`,
    );

    const oldTimeSensitivityTypeId = MediaItem.getTimeSensitivityTypeId({
      mediaItem,
    });
    const trackingParams = getCommonTrackingParams({ mediaItem });
    trackingParams.Origin = 'Compose Box';
    trackingParams['Time Sensitivity Before Update'] =
      postType === POST_TYPES.LINK &&
      oldTimeSensitivityTypeId !== null &&
      TIME_SENSITIVITY_NAMES[oldTimeSensitivityTypeId] !== undefined
        ? TIME_SENSITIVITY_NAMES[oldTimeSensitivityTypeId].categoryLabel
        : 'undefined';
    trackingParams['Time Sensitivity'] =
      postType === POST_TYPES.LINK
        ? TIME_SENSITIVITY_NAMES[timeSensitivityTypeId].categoryLabel
        : 'undefined';
    tracker.track({
      eventName: 'Update Time Sensitivity',
      trackingParams,
    });

    onTimeSensitivityChange(timeSensitivityTypeId);
  };

  const timeSensitivityTypeId = MediaItem.getTimeSensitivityTypeId({
    mediaItem,
  });
  let timeSensitivityTypeCode: string;
  let timeSensitivityTypeName: string;
  if (
    timeSensitivityTypeId === null ||
    TIME_SENSITIVITY_NAMES[timeSensitivityTypeId] === undefined
  ) {
    timeSensitivityTypeCode = 'none';
    timeSensitivityTypeName = 'Set article type';
  } else {
    timeSensitivityTypeCode =
      TIME_SENSITIVITY_NAMES[timeSensitivityTypeId].categoryCode;
    timeSensitivityTypeName =
      TIME_SENSITIVITY_NAMES[timeSensitivityTypeId].categoryLabel;
  }

  const tooltipPosition = isMobile ? 'bottom' : 'left';

  const tooltipLabel = (
    <ul>
      <li>
        <Text fontSize="s">
          <span className="text-700">Breaking: </span>Post expected within 15
          minutes of scheduling (when using Optimal)
        </Text>
      </li>
      <li>
        <Text fontSize="s">
          <span className="text-700">Normal: </span>Post expected within 12
          hours of scheduling (when using Optimal)
        </Text>
      </li>
      <li>
        <Text fontSize="s">
          <span className="text-700">Evergreen: </span>Post expected within 48
          hours of scheduling (when using Optimal). Post can be reshared in the
          future
        </Text>
      </li>
    </ul>
  );

  return (
    <div className="d-flex align-items-center ml-auto pb-3 pt-1 py-md-3 px-3">
      {postType === POST_TYPES.LINK && (
        <PublishedTime
          articleURL={articleURL}
          isMobile={true}
          publishedTime={publishedTime}
          twelveHourFormat={twelveHourFormat}
        />
      )}
      <Tooltip
        id="timeSensitivityOptions"
        placement={tooltipPosition}
        className="react-tooltip-wide"
        label={tooltipLabel}
      >
        <img
          className="info_icon mr-2"
          src="/img/icons/ic-information.svg"
          alt=""
          data-tip
          data-for="timeSensitivityOptions"
          data-iscapture="true"
          style={{ opacity: '0.35' }}
        />
      </Tooltip>

      <div className="dropdown">
        <Button
          className="dropdown-md article-type dropdown-toggle"
          id="timeSensitivityDropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div
            className={`article-type-${timeSensitivityTypeCode}`}
            data-cy-id="timeSensitivity"
          >
            {timeSensitivityTypeName}
          </div>
        </Button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="timeSensitivityDropdown"
          data-cy-select="timeSensitivity"
        >
          <a
            className="dropdown-item dropdown-item-breaking"
            data-cy-id={TIME_SENSITIVITY_TYPES.BREAKING}
            onClick={(event) => {
              event.preventDefault();
              handleTimeSensitivityClick(TIME_SENSITIVITY_TYPES.BREAKING);
            }}
            href="#"
          >
            Breaking
          </a>
          <a
            className="dropdown-item dropdown-item-normal"
            data-cy-id={TIME_SENSITIVITY_TYPES.TODAY}
            onClick={(event) => {
              event.preventDefault();
              handleTimeSensitivityClick(TIME_SENSITIVITY_TYPES.TODAY);
            }}
            href="#"
          >
            Normal
          </a>
          <a
            className="dropdown-item dropdown-item-evergreen"
            data-cy-id={TIME_SENSITIVITY_TYPES.EVERGREEN}
            onClick={(event) => {
              event.preventDefault();
              handleTimeSensitivityClick(TIME_SENSITIVITY_TYPES.EVERGREEN);
            }}
            href="#"
          >
            Evergreen
          </a>
        </div>
      </div>
    </div>
  );
};

export default TimeSensitivity;
