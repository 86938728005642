import { Box, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Navigate, Outlet } from 'react-router-dom';

import getPropertiesSynds from 'api/getPropertiesSynds';
import { getAllPropertyIds, getCurrentPropertyId } from 'common/accountAPIs';
import {
  FRONTEND_METRICS,
  FRONTEND_PAGES,
  GLOBAL_INFO_STATES,
} from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { isOnPage } from 'common/path';
import { withLocation, withNavigate } from 'common/routing';
import { isDefined, isNull } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import FullPageSpinner from 'components/misc/FullPageSpinner';
import HighPriorityErrorsBanner from 'components/misc/HighPriorityErrorsBanner';
import RouteLeavingGuard from 'components/settings/RouteLeavingGuard';
import SocialSettingsNavbar from 'components/settings/SocialSettingsNavbar';
import SocalSettingsSidebar from 'components/settings/SocialSettingsSidebar';
import { SettingsProvider } from 'context/SettingsContext';
import withGlobalInfo from 'context/withGlobalInfo';
import { scrollToTop } from 'helpers/window';
import { useIsShowingHighPriorityErrorType } from 'state/highPriorityErrorType';

const areFeedsMissing = (location) => {
  const globalInfo = getGlobalInfo();
  const currentPropertyId = getCurrentPropertyId();
  const feedsMissing =
    (isOnPage({ page: FRONTEND_PAGES.SETTINGS_PROPERTY, location }) ||
      isOnPage({ page: FRONTEND_PAGES.SETTINGS_PAGES, location })) &&
    !isDefined(globalInfo.properties[currentPropertyId].accountFeeds);
  return feedsMissing;
};

class Settings extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      isLoadingFeeds: false,
      globalInfoRefreshed: false,
    };
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    logger.info('Settings:componentDidMount');
    this.initialiseComponent();
    this.changeToCorrectProperty();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Settings', { nextProps, nextState });
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
    );
    if (hasChangedToReady) {
      logger.info('Settings:componentDidUpdate - re-initialise component');
      this.initialiseComponent();
    }
    this.changeToCorrectProperty();
    const { location } = this.props;
    if (isOnPage({ page: FRONTEND_PAGES.SETTINGS_PROPERTY, location })) {
      this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_SETTINGS_PROPERTY);
    } else if (isOnPage({ page: FRONTEND_PAGES.SETTINGS_PAGES, location })) {
      this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_SETTINGS_PAGES);
    } else if (isOnPage({ page: FRONTEND_PAGES.SETTINGS_USERS, location })) {
      this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_SETTINGS_TEAM);
    }
  }

  /**
   * Helper methods
   */

  changeToCorrectProperty() {
    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.has('showSettingsForPropertyId')) {
      const propertyId = queryParams.get('showSettingsForPropertyId');
      if (
        propertyId === getCurrentPropertyId() ||
        !getAllPropertyIds().includes(propertyId)
      ) {
        queryParams.delete('showSettingsForPropertyId');
        this.props.navigate(
          `${this.props.location.pathname}?${queryParams.toString()}`,
          { replace: true },
        );
      } else {
        this.props.global.handlePropertyChange(propertyId);
      }
    }
  }

  initialiseComponent() {
    // Scroll to top if necessary
    scrollToTop();

    // Load properties and APIs if necessary
    if (this.props.global.isLoading()) {
      return;
    }
    const currentPropertyId = getCurrentPropertyId();
    const globalInfo = getGlobalInfo();
    const location = this.props.location;
    const feedsMissing = areFeedsMissing(location);
    const settingsMissing =
      isOnPage({ page: FRONTEND_PAGES.SETTINGS_PROPERTY, location }) &&
      !isDefined(globalInfo.properties[currentPropertyId].propertySettings);
    if (feedsMissing && !this.state.isLoadingFeeds) {
      logger.info('Settings:initialiseComponent - feeds missing');

      this.setState(
        {
          isLoadingFeeds: true,
        },
        async () => {
          const response = await getPropertiesSynds({
            propertyIds: [currentPropertyId],
          });
          if (!isNull(response)) {
            Object.keys(response).forEach((propertyId) => {
              globalInfo.properties[propertyId].accountFeeds =
                response[propertyId];
            });
          }
          this.props.global.setGlobalInfo(globalInfo);
          logger.info('Settings:initialiseComponent - feeds loaded');
          this.setState({
            isLoadingFeeds: false,
          });
        },
      );
    }
    if (settingsMissing && !this.state.globalInfoRefreshed) {
      logger.info('Settings:initialiseComponent - settings missing');

      this.props.global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.SELECTING_PROPERTY,
        allowUnderSetup: true,
      });
      this.setState({ globalInfoRefreshed: true });
    }
  }

  /**
   * Render method
   */

  render() {
    // Redirect after returning from api connect process
    const location = this.props.location;
    if (location.hash === '#_=_' && location.pathname === '/settings') {
      return <Navigate replace to="/settings/pages" />;
    }

    const feedsMissing = areFeedsMissing(location);
    const queryParams = new URLSearchParams(location.search);

    // Loading
    if (
      this.props.global.isLoading() ||
      this.props.global.isSelecting(
        queryParams.has('showSettingsForPropertyId')
          ? GLOBAL_INFO_STATES.SELECTING_PROPERTY
          : undefined,
      ) ||
      this.state.isLoadingFeeds ||
      feedsMissing
    ) {
      return <FullPageSpinner />;
    }

    return (
      <SettingsProvider>
        <RouteLeavingGuard />
        <HighPriorityErrorsBanner />
        <Flex
          className="setting_page settings-main"
          flexDir="column"
          height={
            this.props.isShowingHighPriorityError ? 'calc(100% - 48px)' : 'full'
          }
          position="relative"
        >
          <SocialSettingsNavbar />
          <Flex
            width="full"
            mt={15}
            backgroundColor="gray.100"
            flexGrow={1}
            maxH="calc(100% - 60px)"
          >
            <SocalSettingsSidebar />
            <Box
              className="setting_content"
              flex={1}
              px={10}
              py={12}
              overflowY="auto"
            >
              <Outlet />
            </Box>
          </Flex>
        </Flex>
      </SettingsProvider>
    );
  }
}

const SettingsWrapper = (props) => {
  const isShowingHighPriorityError = useIsShowingHighPriorityErrorType();

  return (
    <Settings
      {...props}
      isShowingHighPriorityError={isShowingHighPriorityError}
    />
  );
};

export default withGlobalInfo(withNavigate(withLocation(SettingsWrapper)));
