import { memo } from 'react';

import { arePropsEqual } from 'common/utility';
import Tooltip from 'components/misc/Tooltip';

interface ImageDownloadProps {
  imageURL?: string | null;
  isCropping?: boolean;
  isDownloading: boolean;
  isEditing?: boolean;
  onDownload: (imageURL: string) => Promise<void>;
}

const ImageDownload = ({
  imageURL = null,
  isCropping = false,
  isDownloading,
  isEditing = false,
  onDownload,
}: ImageDownloadProps) => {
  let className: string;

  const handleDownload = () => {
    if (imageURL) {
      onDownload(imageURL);
    }
  };

  if (isDownloading) {
    className = 'text_unselectable btn btn-light disabled mt-1';
    return (
      <a className={className}>
        <img
          src="/img/icons/ic-download-dark.svg"
          alt="Download"
          style={{
            width: '13px',
            height: '13px',
          }}
        />
      </a>
    );
  }
  className = 'btn btn-light mt-1';
  if (isCropping || isEditing) {
    className += ' saving_locked';
  }
  return (
    <Tooltip id="image-download" label="Download image">
      <a
        className={className}
        onClick={handleDownload}
        data-tip
        data-for="image-download"
        data-cy-action="download"
        data-iscapture="true"
      >
        <img
          src="/img/icons/ic-download-dark.svg"
          alt="Download"
          style={{
            width: '13px',
            height: '13px',
          }}
        />
      </a>
    </Tooltip>
  );
};

export default memo(ImageDownload, arePropsEqual);
