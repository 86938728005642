import { Box, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import { IndividualHTTPEchoResponse } from 'types';

interface WhitelistingToolResponseModalProps {
  /**
   * The URL that the requests were made to
   */
  targetURL: string;
  /**
   * The responses for each request to display within the modal.
   */
  responses: IndividualHTTPEchoResponse[];
  /**
   * The event handler called when closing the response modal.
   */
  onCloseWhitelistingResponse: () => void;
}

const WhitelistingToolResponseModal = ({
  targetURL,
  responses,
  onCloseWhitelistingResponse,
}: WhitelistingToolResponseModalProps) => {
  function displayResponse({
    responseArray,
  }: {
    responseArray: IndividualHTTPEchoResponse[];
  }) {
    let displayString = `Target URL:                   ${targetURL}\n\n`;
    displayString = displayString.concat(
      `\t========================================\n\n`,
    );

    responseArray.forEach((individualResponse) => {
      if (individualResponse.userAgent === '') {
        displayString = displayString.concat(
          `User Agent:                   [no user agent]\n\n`,
        );
      } else {
        displayString = displayString.concat(
          `User Agent:                   ${individualResponse.userAgent}\n\n`,
        );
      }

      if (individualResponse.responseCode) {
        displayString = displayString.concat(
          `Response Code:                ${individualResponse.responseCode.toString()}\n`,
        );
      }

      if (individualResponse.responseTimeMS) {
        displayString = displayString.concat(
          `Response Time (ms):           ${individualResponse.responseTimeMS.toString()}\n`,
        );
      }

      if (individualResponse.errorReason) {
        displayString = displayString.concat(
          `Error Reason:                 ${individualResponse.errorReason}\n`,
        );
      }

      if (individualResponse.responseHeaders) {
        displayString = displayString.concat(`Response Headers:\n`);

        Object.entries(individualResponse.responseHeaders).forEach(
          ([key, value]) => {
            displayString = displayString.concat(`\t\t${key}: ${value}\n`);
          },
        );
      }

      displayString = displayString.concat(
        '\n\t----------------------------------------\n\n',
      );
    });
    return displayString;
  }

  return (
    <Modal isOpen={true} onClose={onCloseWhitelistingResponse} size="large">
      <Modal.Header>
        <Modal.Title>Whitelisting Tool Response</Modal.Title>
      </Modal.Header>
      {responses !== null && responses.length > 0 && (
        <Modal.Body>
          <Box as="pre" p={6} whiteSpace="pre-wrap" fontFamily="mono">
            {displayResponse({ responseArray: responses })}
          </Box>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default WhitelistingToolResponseModal;
