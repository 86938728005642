/* eslint no-use-before-define:"off" */

import { QUARTILE_NAMES } from 'common/constants';
import { mandatory } from 'common/validation';

export { getQuartileName };

function getQuartileName({
  itemIndex = mandatory('itemIndex'),
  sortOrder = mandatory('sortOrder'),
  totalNumberOfItems = mandatory('totalNumberOfItems'),
} = {}) {
  if (totalNumberOfItems < 4) {
    return 'Undefined - Content';
  }
  if (sortOrder !== 'potential') {
    return 'Undefined - Sorting';
  }
  const percentage = ((itemIndex + 1) / totalNumberOfItems) * 100;
  const quartile = Math.ceil(percentage / 25);
  return QUARTILE_NAMES[quartile];
}
