import PropTypes from 'prop-types';

/**
 * A share link is just a placeholder for a link so cannot be an anchor,
 * but should be coloured as such
 */

const ShareLinkBlock = (props) => (
  <span
    href={props.decoratedText}
    target="_blank"
    className="share_tag_message"
  >
    {props.children}
  </span>
);

ShareLinkBlock.propTypes = {
  children: PropTypes.array.isRequired,
  decoratedText: PropTypes.string.isRequired,
};

export default ShareLinkBlock;
