/* eslint react-hooks/exhaustive-deps: "off" */

import $ from 'jquery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { COLLECTION_NAMES } from 'common/constants';
import * as tracker from 'common/tracker';
import DropdownFooter from 'components/home/archive/DropdownFooter';
import Button from 'components/misc/Button';
import { getArticleArchiveFilterParams } from 'helpers/tracking';

const filterIn = (filterBy) =>
  filterBy
    .split(',')
    .filter(
      (entry) =>
        entry === 'SHARE' || entry === 'NOTSHARE' || entry === 'RESHARE',
    );

const filterOut = (filterBy) =>
  filterBy
    .split(',')
    .filter(
      (entry) =>
        entry !== 'SHARE' && entry !== 'NOTSHARE' && entry !== 'RESHARE',
    );

const State = (props) => {
  const { filterBy } = props.displayOptions;
  const { eventHandlers } = props;

  const [states, setStates] = useState(filterIn(props.displayOptions.filterBy));

  useEffect(() => {
    if (typeof $ !== 'undefined') {
      try {
        const stateDropdown = $('#stateDropdown').parent();
        stateDropdown.unbind();
        stateDropdown.on('hidden.bs.dropdown', () => {
          const filters =
            filterBy === '' ? states : filterOut(filterBy).concat(states);
          if (filters.toString() !== props.displayOptions.filterBy) {
            tracker.track({
              eventName: 'Update Archive Filter',
              trackingParams: getArticleArchiveFilterParams({
                prevDisplayOptions: props.displayOptions,
                nextDisplayOptions: {
                  ...props.displayOptions,
                  filterBy: filters.toString(),
                },
              }),
            });
            eventHandlers.handleFilterChange({
              collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
              filterBy: filters.toString(),
            });
          }
        });
      } catch (error) {
        //
      }
    }
  }, [states, props.displayOptions.filterBy]);

  useEffect(() => {
    setStates(filterIn(props.displayOptions.filterBy));
  }, [props.displayOptions.filterBy]);

  const handleApply = () => {
    const filters =
      filterBy === '' ? states : filterOut(filterBy).concat(states);
    tracker.track({
      eventName: 'Update Archive Filter',
      trackingParams: {
        'Previous Filter Options': filterBy,
        'Filter Options': filters.toString(),
      },
    });
    eventHandlers.handleFilterChange({
      collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
      filterBy: filters.toString(),
    });
    try {
      $('[data-cy-id="state"]').dropdown('toggle');
    } catch (error) {
      //
    }
  };

  const handleClear = () => {
    setStates([]);
  };

  const handleCheck = (event) => {
    const value = event.target.value;
    setStates((prev) => {
      if (prev.indexOf(value) === -1) {
        const next = prev.concat(value);
        return next;
      }
      const next = prev.filter((item) => item !== value);
      return next;
    });
  };

  const isActive = states.length > 0;
  const activeClasses = [];
  if (isActive) {
    activeClasses.push('filter-active');
  }
  if (props.displayOptions.timeField === 'share') {
    activeClasses.push('saving_locked');
  }

  return (
    <div className="dropdown my-1">
      <Button
        className={`filter ${activeClasses.join(' ')} mr-2 analytics-filter`}
        id="stateDropdown"
        data-cy-id="state"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-boundary="viewport"
      >
        Article state
        <svg
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '6px' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.34056 0.256985L3.8978 1.75336C3.8978 1.75336 4.36444 2.28627 4.81775 2.28627C5.27106 2.28627 5.73636 1.75476 5.73636 1.75476L7.29494 0.256985C7.4664 0.0903181 7.69169 -0.00154286 7.92515 1.96097e-05C8.15861 0.00158207 8.38277 0.0964518 8.5522 0.2654C8.63526 0.346152 8.70168 0.443975 8.74736 0.552815C8.79303 0.661656 8.81698 0.779198 8.81773 0.898167C8.81848 1.01714 8.79601 1.135 8.75171 1.24447C8.70742 1.35394 8.64223 1.45268 8.5602 1.53458L5.44438 4.65214C5.27518 4.82015 5.05041 4.91221 4.81775 4.90878C4.58555 4.91184 4.36132 4.81981 4.19245 4.65214L1.0753 1.53458C0.993266 1.45268 0.928082 1.35394 0.883784 1.24447C0.839487 1.135 0.817017 1.01714 0.817767 0.898167C0.818517 0.779198 0.84247 0.661656 0.888144 0.552815C0.933817 0.443975 1.00024 0.346152 1.0833 0.2654C1.25273 0.0964518 1.47689 0.00158207 1.71035 1.96097e-05C1.94381 -0.00154286 2.1691 0.0903181 2.34056 0.256985Z"
          />
        </svg>
      </Button>
      <div
        className="dropdown-menu"
        aria-labelledby="stateDropdown"
        style={{ minWidth: '200px' }}
      >
        <form>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="shared"
          >
            <input
              type="checkbox"
              id="shared"
              className="check"
              checked={states.indexOf('SHARE') !== -1}
              onChange={handleCheck}
              value="SHARE"
              data-cy-select="shared"
            />
            <label className="label" htmlFor="shared">
              Shared
            </label>
          </label>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="notshared"
          >
            <input
              type="checkbox"
              id="notshared"
              className="check"
              checked={states.indexOf('NOTSHARE') !== -1}
              onChange={handleCheck}
              value="NOTSHARE"
              data-cy-select="notshared"
            />
            <label className="label" htmlFor="notshared">
              Not shared
            </label>
          </label>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="reshared"
          >
            <input
              type="checkbox"
              id="reshared"
              className="check"
              checked={states.indexOf('RESHARE') !== -1}
              onChange={handleCheck}
              value="RESHARE"
              data-cy-select="reshared"
            />
            <label className="label" htmlFor="reshared">
              Reshared
            </label>
          </label>
          <div className="dropdown-divider" />
          <DropdownFooter eventHandlers={{ handleApply, handleClear }} />
        </form>
      </div>
    </div>
  );
};

State.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  eventHandlers: PropTypes.shape({
    handleFilterChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default State;
