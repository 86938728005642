import { useEffect } from 'react';

import { getAPIPostName, getAPITypeId } from 'common/accountAPIs';
import * as modal from 'common/modal';
import { getSocialNetworkName } from 'common/social';
import type { FixTypeLater } from 'types';

interface DeleteArticleProps {
  mediaItem: FixTypeLater;
  accountAPIId: number;
  onArticleDelete: (mediaItem: FixTypeLater) => void;
  onArticleDeleteCancel: () => void;
}

const DeleteArticle = (props: DeleteArticleProps) => {
  /**
   * Lifecycle methods
   */

  useEffect(() => {
    modal.setBackgroundColor('rgba(23, 32, 50, 0.3)');

    return () => {
      modal.setBackgroundColor('initial');
    };
  }, []);

  /**
   * Event handlers
   */

  const handleArticleDelete = () => {
    props.onArticleDelete(props.mediaItem);
  };

  const handleDeleteCancel = () => {
    props.onArticleDeleteCancel();
  };

  /**
   * Render method
   */

  const pageName = getAPIPostName({ accountAPIId: props.accountAPIId });
  const apiTypeId = getAPITypeId({ accountAPIId: props.accountAPIId });
  const networkName = getSocialNetworkName({
    apiTypeId,
  });

  return (
    <div
      className="ebx-modal-outer"
      id="deleteArticle"
      data-cy-id="deleteArticle"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div id="addPageLabel">Delete post from {pageName}?</div>
            <button
              type="button"
              className="close ft-26 text-400"
              data-dismiss="modal"
              onClick={handleDeleteCancel}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body lh-20">
            <div>
              <div>
                <div>
                  Deleting this post will permanently remove it from your
                  <span className="text-700">
                    &nbsp;{networkName}&nbsp;
                  </span>{' '}
                  page.
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div
              className="btn btn-light"
              data-dismiss="modal"
              data-cy-action="closePagePopup"
              onClick={handleDeleteCancel}
            >
              Cancel
            </div>
            <div
              className="btn btn-danger"
              data-cy-action="deleteArticle"
              onClick={handleArticleDelete}
            >
              Delete Post From &quot;{pageName}&quot;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteArticle;
