import { Banner, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getProperty } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES, TEXT_CASES } from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import { getSetting } from 'common/settings';
import { isNull } from 'common/utility';

import { useErrorTracking } from './useErrorTracking';

interface AccountWillBeSuspendedBannerProps {
  propertyId: number;
}

const AccountWillBeSuspendedBanner = ({
  propertyId,
}: AccountWillBeSuspendedBannerProps) => {
  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId,
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;

  const property = getProperty({ propertyId: propertyId.toString() });
  const { invoices, shutOffUnixTime } = property.propertyBilling;
  const hasMultipleInvoices = invoices.split(', ').length > 1;
  const shutOffDisplayTime = getFormattedDateFromUnix({
    timestamp: shutOffUnixTime,
    twelveHourFormat,
    textCase: TEXT_CASES.LOWER,
    useLongForm: true,
  });

  const errorMessage = `This account will be suspended ${shutOffDisplayTime} unless payment for overdue ${
    hasMultipleInvoices ? 'invoices' : 'invoice'
  } ${invoices} is received. Please resolve and send a payment confirmation to`;

  useErrorTracking({
    errorMessage,
    errorType: 'Future suspension for non-payment of invoice(s)',
  });

  return (
    <Banner type="error">
      <Banner.Tag>Warning</Banner.Tag>
      <Banner.Body>
        <Text size="sm">{errorMessage}</Text>
      </Banner.Body>
      <Banner.Link href="mailto:finance@echobox.com">
        finance@echobox.com
      </Banner.Link>
    </Banner>
  );
};

export default AccountWillBeSuspendedBanner;
