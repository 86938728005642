import {
  Box,
  Flex,
  IgPostIcon,
  ReelIcon,
  StoryIcon,
  Text,
  useRadio,
  useRadioGroup,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
} from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { isIGCabinetEnabled } from 'common/settings';
import InstagramSocialChannelRadioGroup from 'components/compose/InstagramSocialChannelRadioGroup';
import {
  handlePostTypeChange,
  updateSocialChannel,
  updateValidationMessage,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { useState } from 'react';
import type { FixTypeLater, SocialChannel } from 'types';

function assertValidSocialChannel(
  potentialSocialChannel: string,
): asserts potentialSocialChannel is SocialChannel {
  if (
    !Object.values(SOCIAL_CHANNELS).includes(
      potentialSocialChannel as SocialChannel,
    )
  ) {
    throw new Error('Chosen value is not a valid social channel');
  }
}

function getShareType({
  isDraft,
  isScheduled,
}: {
  isDraft: boolean;
  isScheduled: boolean;
}) {
  if (isScheduled) {
    return 'SCHEDULED';
  }
  if (isDraft) {
    return 'DRAFT';
  }
  return 'NEW';
}

interface InstagramSocialChannelProps {
  guid: string;
  mediaItem: FixTypeLater;
}

const InstagramSocialChannel = ({
  guid,
  mediaItem,
}: InstagramSocialChannelProps) => {
  const { dispatch } = useComposeBoxContext();
  const [isLinkPost] = useState(
    MediaItem.getPostType({ mediaItem }) === POST_TYPES.LINK,
  );

  const isScheduled =
    MediaItem.getState({ mediaItem }) !== MEDIA_ITEM_STATES.NEW;
  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });
  const isDraft = suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE;

  const { getRadioProps, getRootProps, isDisabled } = useRadioGroup({
    name: 'socialChannel',
    value: MediaItem.getSocialChannel({ mediaItem }),
    onChange: (socialChannel) => {
      assertValidSocialChannel(socialChannel);
      dispatch(updateSocialChannel({ guid, socialChannel }));
      if (isLinkPost && !isIGCabinetEnabled()) {
        dispatch(
          handlePostTypeChange({
            guid,
            postType:
              socialChannel === SOCIAL_CHANNELS.STORY
                ? POST_TYPES.STATUS
                : POST_TYPES.LINK,
          }),
        );
      }
      // Clear validation message as different channels have different validation rules
      // Validation should be performed when video/image loads.
      dispatch(updateValidationMessage({ guid, message: null }));
    },
    isDisabled: isScheduled || isDraft,
  });
  const postRadio = useRadio(getRadioProps({ value: SOCIAL_CHANNELS.FEED }));
  const storyRadio = useRadio(getRadioProps({ value: SOCIAL_CHANNELS.STORY }));
  const reelRadio = useRadio(getRadioProps({ value: SOCIAL_CHANNELS.REEL }));
  const shareType = getShareType({ isDraft, isScheduled });

  const postType = MediaItem.getPostType({ mediaItem });
  const isVideo = postType === POST_TYPES.VIDEO;

  const hasMultipleImages = MediaItem.getImageURLs({ mediaItem })?.length > 1;

  return (
    <>
      <Box className="subtitle text_unselectable" mb="3px">
        Share Type
      </Box>
      <Flex {...getRootProps()} gap="9px" mb="31px">
        {isVideo ? (
          <InstagramSocialChannelRadioGroup
            getCheckboxProps={reelRadio.getCheckboxProps}
            getInputProps={reelRadio.getInputProps}
            isDisabled={isDisabled}
            shareType={shareType}
          >
            <ReelIcon
              size={8}
              color={reelRadio.state.isChecked ? 'primary.600' : 'gray.600'}
              _groupHover={!isDisabled ? { color: 'primary.600' } : undefined}
            />
            <Text
              {...postRadio.getLabelProps()}
              size="xs"
              lineHeight="14px"
              fontWeight="medium"
              color={reelRadio.state.isChecked ? '#3976E6' : '#697386'}
              _groupHover={!isDisabled ? { color: '#3976E6' } : undefined}
            >
              Reel
            </Text>
          </InstagramSocialChannelRadioGroup>
        ) : (
          <InstagramSocialChannelRadioGroup
            getCheckboxProps={postRadio.getCheckboxProps}
            getInputProps={postRadio.getInputProps}
            isDisabled={isDisabled}
            shareType={shareType}
          >
            <IgPostIcon
              size={8}
              color={postRadio.state.isChecked ? 'primary.600' : 'gray.600'}
              _groupHover={!isDisabled ? { color: 'primary.600' } : undefined}
            />
            <Text
              {...postRadio.getLabelProps()}
              size="xs"
              lineHeight="14px"
              fontWeight="medium"
              color={postRadio.state.isChecked ? '#3976E6' : '#697386'}
              _groupHover={!isDisabled ? { color: '#3976E6' } : undefined}
            >
              Post
            </Text>
          </InstagramSocialChannelRadioGroup>
        )}
        <InstagramSocialChannelRadioGroup
          getCheckboxProps={storyRadio.getCheckboxProps}
          getInputProps={storyRadio.getInputProps}
          isDisabled={isDisabled || hasMultipleImages}
          shareType={shareType}
        >
          <StoryIcon
            size={8}
            color={storyRadio.state.isChecked ? 'primary.600' : 'gray.600'}
            _groupHover={
              !isDisabled && !hasMultipleImages
                ? { color: 'primary.600' }
                : undefined
            }
          />
          <Text
            {...storyRadio.getLabelProps()}
            size="xs"
            lineHeight="14px"
            fontWeight="medium"
            color={storyRadio.state.isChecked ? '#3976E6' : '#697386'}
            _groupHover={
              !isDisabled && !hasMultipleImages
                ? { color: '#3976E6' }
                : undefined
            }
          >
            Story
          </Text>
        </InstagramSocialChannelRadioGroup>
      </Flex>
    </>
  );
};

export default InstagramSocialChannel;
