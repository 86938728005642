import type { AxiosRequestConfig } from 'axios';
import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  baseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import * as metrics from 'common/metrics';
import { extractImageURL } from 'common/urn';
import { lyingParse } from 'common/zod';

const MetadataSchema = z
  .object({
    description: z.string().optional(),
    title: z.string().optional(),
    imageURNs: z.array(z.string()).optional(),
  })
  .optional()
  .describe('getPreviewMetaData.MetadataSchema');

const MediaTypeSchema = z
  .object({
    mediaURN: z.string(),
    ogMetaData: MetadataSchema,
    twitterMetaData: MetadataSchema,
    uniqueMediaURN: z.string().optional(),
    unixTimeCreated: z.number(),
  })
  .describe('getPreviewMetaData.MediaTypeSchema');

const getMediaTypeResponseSchema = (url: string) =>
  z
    .object({ media: z.object({ [url]: MediaTypeSchema }) })
    .describe('getPreviewMetaData.MediaTypeResponseSchema');

const SyndMediaTypeSchema = z
  .object({
    syndFeedMetaData: z.object({
      description: z.string().optional(),
      title: z.string().optional(),
      imageURNs: z.array(z.string()).optional(),
    }),
    unixTimeCreated: z.number(),
    unixTimePublished: z.number(),
  })
  .describe('getPreviewMetaData.SyndMediaTypeSchema');

const SyndMediaTypeResponseSchema = z
  .object({
    sources: z.record(z.string(), SyndMediaTypeSchema),
  })
  .describe('getPreviewMetaData.SyndMediaTypeResponseSchema');

export default async function getPreviewMetaData(url: string) {
  const guid = metrics.start('getPreviewMetaData');

  const getMediaDataConfig: AxiosRequestConfig = {
    url: `${baseCoreAPIURL}get/v1/media/byurl`,
    method: 'POST',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
    data: { urls: [url] },
    params: {
      scrapeIfMissing: true,
      fields:
        'facebook_article_meta_info,twitter_article_meta_info,original_full_url,cT,i,h',
    },
  };

  const mediaDataResponse = await axios(getMediaDataConfig);
  const validatedMediaDataData = lyingParse(
    getMediaTypeResponseSchema(url),
    mediaDataResponse.data,
  );
  const { mediaURN, ogMetaData, twitterMetaData } =
    validatedMediaDataData.media[url];

  const getSyndfeedMetaDataConfig: AxiosRequestConfig = {
    url: `${baseCoreAPIURL}get/v1/sources/syndfeed/mostrecentpublishtime`,
    method: 'POST',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
    data: { mediaURNs: [mediaURN] },
    params: {
      fields: 'm',
    },
  };

  const syndFeedMetaDataResponse = await axios(getSyndfeedMetaDataConfig);
  const validatedSyndFeedMetaDataData = lyingParse(
    SyndMediaTypeResponseSchema,
    syndFeedMetaDataResponse.data,
  );
  const syndFeedMetaData =
    validatedSyndFeedMetaDataData.sources[mediaURN]?.syndFeedMetaData;
  metrics.end('getPreviewMetaData', guid);

  return {
    ogMetaInfo: {
      title: ogMetaData?.title,
      description: ogMetaData?.description,
      imageURL:
        ogMetaData?.imageURNs && ogMetaData.imageURNs.length > 0
          ? extractImageURL(ogMetaData.imageURNs[0])
          : undefined,
    },
    twitterCardMetaInfo: {
      title: twitterMetaData?.title,
      description: twitterMetaData?.description,
      imageURL:
        twitterMetaData?.imageURNs && twitterMetaData.imageURNs.length > 0
          ? extractImageURL(twitterMetaData.imageURNs[0])
          : undefined,
    },
    syndfeedMetaInfo: {
      title: syndFeedMetaData?.title,
      description: syndFeedMetaData?.description,
      imageURL:
        syndFeedMetaData?.imageURNs && syndFeedMetaData.imageURNs.length > 0
          ? extractImageURL(syndFeedMetaData.imageURNs[0])
          : undefined,
    },
  };
}
