/* eslint no-use-before-define:"off" */

import { useLocation, useNavigate } from 'react-router-dom';

import {
  getCurrentProperty,
  getCurrentPropertyId,
  getPropertyPermission,
  hasSocialAPIInState,
} from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import {
  API_STATES,
  PERMISSION_TYPES,
  ACCOUNT_STATES as PROPERTY_STATES,
  ROUTE_REDIRECTIONS,
  USER_TYPES,
} from 'common/constants';
import { getCurrentPropertyStateId } from 'common/currentPropertyAndAPI';
import { getGlobalInfo } from 'common/globalInfo';
import * as social from 'common/social';
import { isNull, isNullOrUndefined } from 'common/utility';
import { location } from 'common/window';
import { getZendeskReturnURL } from 'common/zendesk';

export {
  allAccountAPIsInState,
  getPropertyStatus,
  hasPropertyState,
  requireAdmin,
  requireChallengeDetails,
  requireNotLoggedIn,
  requireSetup,
  requireSetupAndAdmin,
  requireStaff,
  selectOrSetup,
  setupOrSettings,
  withLocation,
  withNavigate,
};

/**
 * allAccountAPIsInState
 *
 * @param {number} accountAPIState
 * @return {boolean}
 *
 * Returns true if all social APIs have the specified state
 */

function allAccountAPIsInState(accountAPIState) {
  const globalInfo = getGlobalInfo();
  if (isNull(globalInfo)) {
    return false;
  }
  const currentProperty = getCurrentProperty({
    globalInfo,
  });
  if (isNullOrUndefined(currentProperty)) {
    return false;
  }
  const currentPropertyId = getCurrentPropertyId({
    globalInfo,
  });
  if (isNull(currentPropertyId)) {
    return false;
  }

  const propertyAPIs = currentProperty.accountAPIs;
  if (isNullOrUndefined(propertyAPIs)) {
    return false;
  }
  return (
    Object.keys(propertyAPIs)
      // Examine social APIs only, filter out any others
      .filter((api) =>
        social.isSocialNetwork({ apiTypeId: propertyAPIs[api].apiTypeId }),
      )
      // Check if all remaining APIs have the specified state
      .every((api) => propertyAPIs[api].apiStateId === accountAPIState)
  );
}

/**
 * getPropertyStatus
 *
 * @return {{ isLoggedIn: boolean, isNewSignup: boolean, isUnderSetup: boolean, isSuspended: boolean }}
 * a series of boolean flags indicating the current property's status
 */

function getPropertyStatus() {
  const isLoggedIn = authentication.isLoggedIn();
  const isNewSignup = isLoggedIn && hasPropertyState(PROPERTY_STATES.NEWSIGNUP);
  const isUnderSetup =
    isLoggedIn && hasPropertyState(PROPERTY_STATES.UNDERSETUP);
  const isSuspended = isLoggedIn && hasPropertyState(PROPERTY_STATES.SUSPENDED);
  return {
    isLoggedIn,
    isNewSignup,
    isUnderSetup,
    isSuspended,
  };
}

/**
 * hasPropertyState
 *
 * @param {number} propertyState
 * @return {boolean}
 *
 * Checks if the current property is in the specified state
 */

function hasPropertyState(propertyState) {
  const globalInfo = getGlobalInfo();
  if (isNull(globalInfo)) {
    return false;
  }

  return getCurrentPropertyStateId({ globalInfo }) === propertyState;
}

/**
 * requireAdmin
 *
 * Redirections:
 *   home page if user does not have admin permissions
 */

function requireAdmin() {
  // Redirect to home page if user does not have admin permissions
  const globalInfo = getGlobalInfo();
  if (globalInfo !== null) {
    const permissionTypeId = getPropertyPermission({
      propertyId: getCurrentPropertyId({
        globalInfo,
      }),
      globalInfo,
    });
    if (permissionTypeId !== PERMISSION_TYPES.ADMIN) {
      return { pathname: ROUTE_REDIRECTIONS.HOME };
    }
  }

  // No redirect required
  return null;
}

/**
 * requireNotLoggedIn
 *
 * Redirections:
 *   home page if user is logged in
 */

function requireNotLoggedIn() {
  const propertyStatus = getPropertyStatus();

  // Redirect to home page if user is logged in and isn't coming from zendesk
  if (propertyStatus.isLoggedIn && isNull(getZendeskReturnURL(location))) {
    return { pathname: ROUTE_REDIRECTIONS.HOME };
  }

  // No redirect required
  return null;
}

/**
 * requireSetup
 *
 * Redirections:
 *   setup page if setup is not complete for the current property
 *   settings page if the current account needs to be reconnected
 *   logout with bad auth message if account is in bad auth but user does not have permission to reconnect
 */

function requireSetup(pathname) {
  return function requireSetupInner() {
    const propertyStatus = getPropertyStatus();

    // Redirect to setup page if setup is not complete for the current property
    if (
      propertyStatus.isLoggedIn &&
      (propertyStatus.isNewSignup ||
        propertyStatus.isUnderSetup ||
        propertyStatus.isSuspended)
    ) {
      return { pathname: ROUTE_REDIRECTIONS.SETUP };
    }

    // Redirect to settings page if the current account needs to be reconnected
    // or log the user out if they don't have permissions to access the settings page
    if (pathname.indexOf('/settings') === -1) {
      const isBadAuth =
        propertyStatus.isLoggedIn && allAccountAPIsInState(API_STATES.BAD_AUTH);
      const globalInfo = getGlobalInfo();
      const noActiveOrBadOrUnderSetupAuth =
        !hasSocialAPIInState({
          accountAPIState: API_STATES.ACTIVE,
          globalInfo,
        }) &&
        !hasSocialAPIInState({
          accountAPIState: API_STATES.BAD_AUTH,
          globalInfo,
        }) &&
        !hasSocialAPIInState({
          accountAPIState: API_STATES.UNDER_SETUP,
          globalInfo,
        });
      if (propertyStatus.isLoggedIn) {
        const permissionTypeId = getPropertyPermission({
          propertyId: getCurrentPropertyId({
            globalInfo,
          }),
          globalInfo,
        });
        if (
          noActiveOrBadOrUnderSetupAuth ||
          (isBadAuth && permissionTypeId === PERMISSION_TYPES.ADMIN)
        ) {
          // ADMIN user with access to INACTIVE or BAD_AUTH account(s) only
          // Redirect the user to the settings page so they can reconnect the relevant pages
          return { pathname: ROUTE_REDIRECTIONS.SETTINGS_PAGES };
        }
        if (isBadAuth && permissionTypeId === PERMISSION_TYPES.VIEW_ONLY) {
          // EDITOR user with access to BAD_AUTH account(s) only
          // (Note that editor users have VIEW_ONLY permissions at property level and EDITOR permissions at API level)
          // Can't redirect the user to the settings page, as editors are not allowed to change settings
          // Can't leave them on the home page as this is not permitted for bad auth accounts
          // So... log the user out and show a suitable message
          return { pathname: ROUTE_REDIRECTIONS.LOGOUT, search: '?badauth' };
        }
      }
    }

    // No redirect required
    return null;
  };
}

/**
 * requireSetupAndAdmin
 *
 * Redirections:
 *   setup page if setup is not complete for the current property
 *   settings page if the current account needs to be reconnected
 */

function requireSetupAndAdmin(pathname) {
  return function requireSetupAndAdminInner() {
    const propertyStatus = getPropertyStatus();

    // Redirect to setup page if setup is not complete for the current property
    if (
      propertyStatus.isLoggedIn &&
      (propertyStatus.isNewSignup ||
        propertyStatus.isUnderSetup ||
        propertyStatus.isSuspended)
    ) {
      if (pathname === '/settings/pages') {
        return { pathname: `${ROUTE_REDIRECTIONS.SETUP}/socialpages` };
      }
      if (pathname === '/settings/property') {
        return { pathname: `${ROUTE_REDIRECTIONS.SETUP}/traffic` };
      }

      return { pathname: ROUTE_REDIRECTIONS.SETUP };
    }

    // Redirect to settings > pages if the current account needs to be reconnected
    if (pathname.indexOf('/settings') === -1) {
      const isBadAuth =
        propertyStatus.isLoggedIn && allAccountAPIsInState(API_STATES.BAD_AUTH);
      const noActiveOrBadAuth =
        !hasSocialAPIInState({
          accountAPIState: API_STATES.ACTIVE,
        }) &&
        !hasSocialAPIInState({
          accountAPIState: API_STATES.BAD_AUTH,
        });
      if (propertyStatus.isLoggedIn && (isBadAuth || noActiveOrBadAuth)) {
        return { pathname: ROUTE_REDIRECTIONS.SETTINGS_PAGES };
      }
    }

    // Redirect to home page if the user does not have admin permissions
    return requireAdmin();
  };
}

function requireStaff() {
  const { user } = getGlobalInfo();

  if (USER_TYPES.ECHOBOX_STAFF === user.userType) {
    return null;
  }

  return { pathname: '/share' };
}

/**
 * setupOrSettings
 *
 * Redirections:
 *   settings page if the current property has been set up already
 *   (used to redirect unnecessary requests for the setup page)
 */

function setupOrSettings() {
  const propertyStatus = getPropertyStatus();

  // Redirect to settings page if the current property has been set up already
  if (
    propertyStatus.isLoggedIn &&
    !propertyStatus.isNewSignup &&
    !propertyStatus.isUnderSetup &&
    !propertyStatus.isSuspended
  ) {
    return { pathname: ROUTE_REDIRECTIONS.SETTINGS_PAGES };
  }

  // No redirect required
  return null;
}

/**
 * setupOrSelect
 * Redirections:
 *   setup page if the current property hasn't been set up yet
 *   (used to redirect unnecessary requests for the select page)
 *
 */
function selectOrSetup(isSelectTraffic) {
  const propertyStatus = getPropertyStatus();

  // Redirect to setup page if the current property hasn't been set up yet
  if (
    propertyStatus.isLoggedIn &&
    (propertyStatus.isNewSignup || propertyStatus.isUnderSetup) &&
    !propertyStatus.isSuspended
  ) {
    return { pathname: ROUTE_REDIRECTIONS.SETUP };
  }

  if (isSelectTraffic) {
    return null;
  }

  // Redirect to settings (select page is deprecated)
  return setupOrSettings();
}

/**
 * requireChallengeDetails
 *
 * Redirections:
 *   login page if the challenge details are not present
 *   (used to redirect to login when a user reloads MFA page as no authentication details are present)
 */

function requireChallengeDetails() {
  if (!authentication.hasChallengeDetails()) {
    return ROUTE_REDIRECTIONS.LOGIN;
  }

  // No redirect required
  return null;
}

// Should be replaced with hooks as components are migrated to functional components
function withLocation(Component) {
  function ComponentWithLocationProp(props) {
    const reactRouterLocation = useLocation();
    return <Component {...props} location={reactRouterLocation} />;
  }

  return ComponentWithLocationProp;
}

/**
 * Higher order component that provides `react-routers` navigate prop to the wrapped component.
 * This should be replaced with hooks as components are migrated to functional components.
 */
function withNavigate(Component) {
  function ComponentWithNavigateProp(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  }

  return ComponentWithNavigateProp;
}
