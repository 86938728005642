/**
 * POST /seasonal-keywords/upload
 */

import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function postSeasonalKeywordsUpload(csvFile: File) {
  const formData = new FormData();
  formData.append('csvFile', csvFile);
  const guid = metrics.start('postSeasonalKeywordsUpload');
  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL()}/seasonal-keywords/upload`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'multipart/form-data',
    }),
    data: formData,
  };

  try {
    await axios(config);
    metrics.end('postSeasonalKeywordsUpload', guid);
    logger.debug('postSeasonalKeywordsUpload');
  } catch (error) {
    metrics.fail('postSeasonalKeywordsUpload', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postSeasonalKeywordsUpload',
    });
    throw apiError;
  }
}
