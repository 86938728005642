import * as tracker from 'common/tracker';

type Origin =
  | 'Home feed'
  | 'Scheduled'
  | 'Compose Box'
  | 'Compose Box (preview URL field)'
  | 'Archive'
  | 'Analytics'
  | 'Search bar';

export function trackOpenArticle(articleURL: string, origin: Origin) {
  tracker.track({
    eventName: 'Open Article Externally',
    trackingParams: {
      'Article URL': articleURL,
      Origin: origin,
    },
  });
}
