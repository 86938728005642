import * as Sentry from '@sentry/react';
import { z } from 'zod';

import * as logger from 'common/logger';
import { SENTRY_IGNORED_ERRORS } from './config';
import { SENTRY_USER_TYPES } from './constants/common';
import { getGlobalInfo } from './globalInfo';
import { isError, isErrorLike } from './utility';

import getVersion from 'api/getVersion';

export async function setUserDetails({
  id,
  username,
  email,
  usertype,
}: {
  id: string;
  username: string;
  email: string;
  usertype: SENTRY_USER_TYPES.ENHANCED_PERMISSIONS | SENTRY_USER_TYPES.NORMAL;
}) {
  let version = '0.0.0';
  try {
    version = await getVersion();
  } catch (e) {
    //
  }
  Sentry.configureScope((scope) => {
    scope.setUser({
      id,
      username,
      email,
    });
    scope.setTag('version', version);
    scope.setExtra('usertype', usertype);
  });
}

export async function captureSentryError(
  error: Error | string | object,
  guid: string,
) {
  // Capture raw error
  if (typeof Sentry !== 'undefined') {
    let version = '0.0.0';
    try {
      version = await getVersion();
    } catch (e) {
      //
    }
    Sentry.configureScope((scope) => {
      scope.setTag('version', version);
      scope.setTag('errorGuid', guid);
    });
    const globalInfo = getGlobalInfo();
    if (globalInfo !== null) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: globalInfo.user.emailAddress,
          id: globalInfo.user.userId,
          username: globalInfo.user.username,
        });
        scope.setTag('propertyId', globalInfo.current.propertyId);
        scope.setTag('accountAPIId', globalInfo.current.accountAPIId);
      });
    }
    if (isError(error)) {
      Sentry.captureException(error);
    } else if (isErrorLike(error)) {
      // @ts-expect-error - isErrorLike should narrow the type but it's not typed.
      Sentry.captureException(new EchoboxError(error));
    } else {
      try {
        Sentry.captureException(new Error(JSON.stringify(error)));
      } catch (e) {
        //
      }
    }
  }
}

/**
 * Checks if the error should be ignored by Sentry.
 * @returns A boolean indicating whether the error should be ignored.
 */
export function isIgnoredError(event: Sentry.Event, hint: Sentry.EventHint) {
  // Extract details from event
  const exceptions: string[] = []; // Error messages extracted from exceptions
  if (event.exception?.values !== undefined) {
    try {
      exceptions.push(
        ...(event.exception.values
          .map((exception) => exception.value)
          .filter(Boolean) as string[]),
      );
    } catch (e) {
      //
    }
  }

  const breadcrumbs: string[] = []; // Error messages extracted from breadcrumbs
  if (event.breadcrumbs !== undefined) {
    try {
      breadcrumbs.push(
        ...(event.breadcrumbs
          .map((breadcrumb) => breadcrumb.message)
          .filter(Boolean) as string[]),
      );
    } catch (e) {
      //
    }
  }

  // Extract properties safely from original exception
  let message: string | null | undefined = null;
  let stackTrace: string | null | undefined = null;
  const OriginalExceptionSchema = z.object({
    message: z.string().optional(),
    stack: z.string().optional(),
  });
  const originalExceptionResult = OriginalExceptionSchema.safeParse(
    hint.originalException,
  );
  if (originalExceptionResult.success) {
    message = originalExceptionResult.data.message;
    stackTrace = originalExceptionResult.data.stack;
  }

  // Construct an array of various tokens from the error
  // which will be partially matched against the list of IGNORE patterns.
  const errorDetailsToCheck = [
    message,
    stackTrace,
    ...exceptions,
    ...breadcrumbs,
  ].filter(Boolean) as string[];

  const matches = SENTRY_IGNORED_ERRORS.some((ignorePattern) =>
    errorDetailsToCheck.some((data) => data.match(ignorePattern)),
  );

  if (matches) {
    logger.info({
      event: 'Sentry error ignored',
      error: event,
      properties: { hint },
    });
  }

  return matches;
}

class EchoboxError extends Error {
  constructor(error: { message: string; stack?: string }) {
    super(error.message);
    this.name = this.constructor.name;
    this.stack = error.stack;
  }
}
