/* eslint no-param-reassign:"off" */

import {
  Button,
  LightingHighlightIcon,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link } from 'react-router-dom';
import Immutable from 'seamless-immutable';

import {
  getAPITypeId,
  getAccountAPI,
  getCurrentPropertyId,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_STATES,
  API_TYPE_IDS,
  AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES,
  REACT_PREVENT_RENDER,
  SOCIAL_PAGE_SETTING_TYPES_SOCIAL_PAGES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as logger from 'common/logger';
import { withLocation, withNavigate } from 'common/routing';
import { getFeatureToggle, loadSettingsForAPI } from 'common/settings';
import {
  getSocialNetworkIcon,
  getSocialNetworkName,
  hasAudienceRestriction,
  hasImageOverlays,
} from 'common/social';
import * as tracker from 'common/tracker';
import { isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';
import BaseComponent from 'components/BaseComponent';
import Prompt from 'components/misc/Prompt';
import AudienceRestriction from 'components/settings/page/AudienceRestriction';
import DataSources from 'components/settings/page/DataSources';
import ImageOverlay from 'components/settings/page/ImageOverlay';
import LinkInBio from 'components/settings/page/LinkInBio';
import URLParameters from 'components/settings/page/URLParameters';
import { scrollToTop } from 'helpers/window';

class Page extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.accountAPIId = null;
    try {
      this.accountAPIId = this.props.location.pathname.split('/')[3];
    } catch (e) {
      //
    }
    this.state = {
      data: Immutable({
        isLoadingSettings: true,
        hasChanged: {
          audienceRestriction: false,
          rssFeeds: false,
          sharePreview: false,
          urlParameters: false,
        },
        sharePreview: {},
        audienceRestriction: {},
      }),
    };
    this._bind(
      'areUnsavedChangesPresent',
      'handleHasSettingChanged',
      'handleWindowUnload',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    // Detect attempts to leave the page so we can check for unsaved changes
    window.addEventListener('beforeunload', this.handleWindowUnload);

    // Scroll to top if necessary
    scrollToTop();

    tracker.track({ eventName: 'Settings Page' });

    // Load the required settings for the page
    this.loadAPILevelSettings(this.accountAPIId);
  }

  componentWillUnmount() {
    logger.info('Page:componentWillUnmount');
    // Remove event handlers
    window.removeEventListener('beforeunload', this.handleWindowUnload);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Page', { nextProps, nextState });
  }

  /**
   * Event handlers
   */

  handleHasSettingChanged({
    key = mandatory('key'),
    hasSettingChanged = mandatory('hasSettingChanged'),
  }) {
    logger.info(`Page:handleHasSettingChanged - ${key} ${hasSettingChanged}`);

    this.setState((prevState) => ({
      data: prevState.data.setIn(['hasChanged', key], hasSettingChanged),
    }));
  }

  handleWindowUnload(event) {
    logger.info('Page:handleWindowUnload');

    // Prevent the user from leaving the page if there are unsaved changes
    if (this.areUnsavedChangesPresent()) {
      event.preventDefault();
      event.returnValue = ''; // Message controlled by browser so doesn't matter what we put here
    } else {
      this.componentWillUnmount();
    }
  }

  /**
   * Helper methods
   */

  areUnsavedChangesPresent() {
    return Object.keys(this.state.data.hasChanged).some(
      (setting) => this.state.data.hasChanged[setting],
    );
  }

  loadAPILevelSettings(accountAPIId) {
    this.setState(
      (prevState) => ({
        data: prevState.data.set('isLoadingSettings', true),
      }),
      () => {
        loadSettingsForAPI({
          accountAPIId,
          settingTypeIds: SOCIAL_PAGE_SETTING_TYPES_SOCIAL_PAGES,
        }).then((response) => {
          try {
            this.setState((prevState) => ({
              data: prevState.data
                .set('isLoadingSettings', false)
                .set(
                  'sharePreview',
                  response[ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES],
                )
                .set(
                  'audienceRestriction',
                  response[ACCOUNT_SETTING_TYPES.DEFAULT_FACEBOOK_TARGETING],
                ),
            }));
          } catch (error) {
            console.log(error);
          }
        });
      },
    );
  }

  /**
   * Render methods
   */

  renderPageDetails() {
    if (isNullOrUndefined(this.accountAPIId)) {
      return REACT_PREVENT_RENDER;
    }
    const accountAPI = getAccountAPI({ accountAPIId: this.accountAPIId });
    const iconSource = getSocialNetworkIcon({
      apiTypeId: accountAPI.apiTypeId,
    });
    const networkName = getSocialNetworkName({
      apiTypeId: accountAPI.apiTypeId,
    });
    return (
      <>
        <Prompt
          when={this.areUnsavedChangesPresent()}
          message="Do you want to leave this page? Changes you made may not be saved."
        />
        <img className="social_logo" src={iconSource} alt={networkName} />
        <img
          className="page_logo ml-1 mr-3 rounded-circle"
          src={accountAPI.apiPostImage}
          alt={accountAPI.apiPostName}
          style={{ width: '26px', height: '26px' }}
        />
        <a
          href={accountAPI.apiHomePage}
          rel="noopener noreferrer"
          target="_blank"
          className="align-self-center setting-headline"
        >
          {accountAPI.apiPostName}
        </a>
        {accountAPI.apiStateId === API_STATES.UNDER_SETUP && (
          <div className="align-self-center ml-auto">
            <img
              src="/img/icons/ic-wrench.svg"
              alt=""
              width="13px"
              height="13px"
            />
            <span className="d-none d-md-inline-block ml-2">Under Setup</span>
          </div>
        )}
      </>
    );
  }

  render() {
    // Check that the account API id extracted from the page URL belongs to the
    // currently-selected property - if it doesn't (which can happen after
    // changing property) then redirect back to the Pages list for the property
    // (Note that at the moment the returnHomeOnPropertyChange rules in
    // layouts/Echobox mean that this redirect will be superseded by a redirect
    // back to the home page, but without the redirect to /settings/pages this
    // would result in a blank page if the returnHomeOnPropertyChange rule
    // was ever changed...)
    const propertyId = {
      current: Number(getCurrentPropertyId()),
      account: Number(
        getPropertyIdForAccountAPIId({
          accountAPIId: this.accountAPIId,
        }),
      ),
    };
    if (propertyId.current !== propertyId.account) {
      this.props.navigate('/settings/pages');
      return REACT_PREVENT_RENDER;
    }

    if (this.state.data.isLoadingSettings) {
      return (
        <div className="text-center my-3 py-3">
          <Spinner size="lg" />
          <div className="my-3 text-400">Fetching your settings...</div>
        </div>
      );
    }

    const apiTypeId = getAPITypeId({ accountAPIId: this.accountAPIId });

    const isLinkinbioEnabled = getFeatureToggle({
      featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
      propertyId: getCurrentPropertyId(),
    });

    const handleCustomiseAutomation = () => {
      this.props.navigate(`/settings/automation/${this.accountAPIId}`);
    };

    return (
      <>
        <div className="box bg-white">
          <div className="d-flex box-header border-bottom-0">
            <Link
              className="mr-2 btn btn-light btn-arrow-left align-self-center"
              to="/settings/pages"
              href="/settings/pages"
            >
              Back
            </Link>
            <div className="current_page d-flex align-items-center position-relative w-100">
              {this.renderPageDetails()}
            </div>
          </div>
        </div>
        <div className="box bg-white mt-3 mb-4">
          <div className="d-flex box-header">
            <h3 className="align-self-center">General</h3>
          </div>
          <div className="accordion" id="accordion">
            <URLParameters
              accountAPIId={this.accountAPIId}
              eventHandlers={{
                handleHasSettingChanged: this.handleHasSettingChanged,
              }}
            />
            {hasAudienceRestriction({ apiTypeId }) && (
              <AudienceRestriction
                accountAPIId={this.accountAPIId}
                audienceRestriction={this.state.data.audienceRestriction}
                eventHandlers={{
                  handleHasSettingChanged: this.handleHasSettingChanged,
                }}
              />
            )}
            <DataSources
              accountAPIId={this.accountAPIId}
              sharePreview={this.state.data.sharePreview}
              eventHandlers={{
                handleHasSettingChanged: this.handleHasSettingChanged,
              }}
            />
            {hasImageOverlays({ apiTypeId }) && (
              <ImageOverlay accountAPIId={this.accountAPIId} />
            )}
            {isLinkinbioEnabled && apiTypeId === API_TYPE_IDS.INSTAGRAM && (
              <LinkInBio accountAPIId={this.accountAPIId} />
            )}
          </div>
        </div>
        {AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES.includes(apiTypeId) && (
          <Button
            onClick={handleCustomiseAutomation}
            variant="link"
            leftIcon={<LightingHighlightIcon size={5} />}
            iconSpacing={1}
          >
            Customize automation
          </Button>
        )}
      </>
    );
  }
}

export default withLocation(withNavigate(Page));
