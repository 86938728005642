import {
  Button,
  Heading,
  RssFeedIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link } from 'react-router-dom';

import {
  getCurrentPropertyId,
  getPropertyPermission,
} from 'common/accountAPIs';
import { PERMISSION_TYPES } from 'common/constants';
import HealthMenuItem from './HealthMenuItem';

export function getContentFeedsErrorMessage(contentFeeds: string[]) {
  const multipleContentFeeds = contentFeeds.length > 1;
  const message = multipleContentFeeds
    ? `We aren't receiving new content from ${contentFeeds.length} content feeds. If these feeds are no longer valid, please delete them.`
    : `We aren't receiving new content from the ${contentFeeds[0]}. If this feed is no longer valid, please delete it.`;

  return message;
}

interface HealthMenuContentFeedErrorProps {
  message: string;
}

const HealthMenuContentFeedError = ({
  message,
}: HealthMenuContentFeedErrorProps) => {
  const propertyPermission = getPropertyPermission({
    propertyId: getCurrentPropertyId(),
  });
  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

  return (
    <HealthMenuItem>
      <HealthMenuItem.Header>
        <RssFeedIcon size={5} />
        <Heading variant="h5">Content feeds</Heading>
      </HealthMenuItem.Header>
      <HealthMenuItem.Body>
        <Text size="sm" overflowWrap="break-word" w="full">
          {message}
        </Text>
        {isAdmin && (
          <Button as={Link} to="/settings/content-feeds" variant="secondary">
            Edit content feeds
          </Button>
        )}
      </HealthMenuItem.Body>
    </HealthMenuItem>
  );
};

export default HealthMenuContentFeedError;
