import { getCurrentPropertyId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import { convertEchoboxImageToAmazon } from 'common/s3';
import { getSetting } from 'common/settings';
import { isRunningTests } from 'common/utility';
import { getImageDimensions } from 'helpers/images';

export async function canApplyImageOverlay({
  canCustomiseLinkPosts,
  hasSameABVariations,
  imageURLs,
}: {
  canCustomiseLinkPosts: boolean;
  hasSameABVariations: boolean;
  imageURLs: string;
}) {
  if (!canCustomiseLinkPosts) {
    return {
      isEnabled: false,
      error: "Complete Facebook's domain verification to enable Image Overlay.",
    };
  }

  if (!hasSameABVariations) {
    return {
      isEnabled: false,
      error:
        'Image overlay not available as one version of AB test has been edited. Turn off AB test to enable',
    };
  }

  const imageURLsArray = JSON.parse(imageURLs) as string[];
  const noImage = imageURLsArray.length === 0;
  if (noImage) {
    return {
      isEnabled: false,
      error: 'Please provide an image to enable image overlay',
    };
  }

  const setting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
    propertyId: getCurrentPropertyId(),
  });
  const hasImageOverlayURL =
    (setting?.dataJSON?.imageOverlayURL ?? null) !== null;
  if (!hasImageOverlayURL) {
    return {
      isEnabled: false,
      error:
        'No image overlay available for this page. Please visit your Page settings to configure.',
    };
  }

  if (!isRunningTests()) {
    const { width, height } = await getImageDimensions({
      file: convertEchoboxImageToAmazon(imageURLsArray[0]),
    });
    const imageAspectRatio = width / height;
    const invalidAspectRatio =
      imageAspectRatio !== null &&
      (imageAspectRatio < 1.71 || imageAspectRatio > 2.09);

    if (invalidAspectRatio) {
      return {
        isEnabled: false,
        error:
          'To enable Image Overlay, the share image (left) must have an aspect ratio between 1.71 and 2.09.',
      };
    }
  }

  return { isEnabled: true };
}
