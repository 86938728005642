/* eslint react-hooks/exhaustive-deps: "off" */

import $ from 'jquery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { COLLECTION_NAMES } from 'common/constants';
import * as tracker from 'common/tracker';
import DropdownFooter from 'components/home/archive/DropdownFooter';
import Button from 'components/misc/Button';
import { getArticleArchiveFilterParams } from 'helpers/tracking';

const filterIn = (filterBy) =>
  filterBy
    .split(',')
    .filter(
      (entry) =>
        entry === 'BREAKING' ||
        entry === 'TODAY' ||
        entry === 'EVERGREEN' ||
        entry === 'UNCLASSIFIED',
    );

const filterOut = (filterBy) =>
  filterBy
    .split(',')
    .filter(
      (entry) =>
        entry !== 'BREAKING' &&
        entry !== 'TODAY' &&
        entry !== 'EVERGREEN' &&
        entry !== 'UNCLASSIFIED',
    );

const Classification = (props) => {
  const { filterBy } = props.displayOptions;
  const { eventHandlers } = props;

  const [classifications, setClassifications] = useState(
    filterIn(props.displayOptions.filterBy),
  );

  useEffect(() => {
    if (typeof $ !== 'undefined') {
      try {
        const classificationDropdown = $('#classificationDropdown').parent();
        classificationDropdown.unbind();
        classificationDropdown.on('hidden.bs.dropdown', () => {
          const filters =
            filterBy === ''
              ? classifications
              : filterOut(filterBy).concat(classifications);
          if (filters.toString() !== props.displayOptions.filterBy) {
            tracker.track({
              eventName: 'Update Archive Filter',
              trackingParams: getArticleArchiveFilterParams({
                prevDisplayOptions: props.displayOptions,
                nextDisplayOptions: {
                  ...props.displayOptions,
                  filterBy: filters.toString(),
                },
              }),
            });
            eventHandlers.handleFilterChange({
              collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
              filterBy: filters.toString(),
            });
          }
        });
      } catch (error) {
        //
      }
    }
  }, [classifications, props.displayOptions.filterBy]);

  useEffect(() => {
    setClassifications(filterIn(props.displayOptions.filterBy));
  }, [props.displayOptions.filterBy]);

  const handleApply = () => {
    $('[data-cy-id="classification"]').dropdown('toggle');
  };

  const handleClear = () => {
    setClassifications([]);
  };

  const handleCheck = (event) => {
    const value = event.target.value;
    setClassifications((prev) => {
      if (prev.indexOf(value) === -1) {
        const next = prev.concat(value);
        return next;
      }
      const next = prev.filter((item) => item !== value);
      return next;
    });
  };

  const isActive = classifications.length > 0;
  const activeClass = isActive ? 'filter-active' : '';

  return (
    <div className="dropdown my-1">
      <Button
        className={`filter ${activeClass} mr-2 ml-2 analytics-filter`}
        id="classificationDropdown"
        data-cy-id="classification"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-boundary="viewport"
      >
        Classification
        <svg
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '6px' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.34056 0.256985L3.8978 1.75336C3.8978 1.75336 4.36444 2.28627 4.81775 2.28627C5.27106 2.28627 5.73636 1.75476 5.73636 1.75476L7.29494 0.256985C7.4664 0.0903181 7.69169 -0.00154286 7.92515 1.96097e-05C8.15861 0.00158207 8.38277 0.0964518 8.5522 0.2654C8.63526 0.346152 8.70168 0.443975 8.74736 0.552815C8.79303 0.661656 8.81698 0.779198 8.81773 0.898167C8.81848 1.01714 8.79601 1.135 8.75171 1.24447C8.70742 1.35394 8.64223 1.45268 8.5602 1.53458L5.44438 4.65214C5.27518 4.82015 5.05041 4.91221 4.81775 4.90878C4.58555 4.91184 4.36132 4.81981 4.19245 4.65214L1.0753 1.53458C0.993266 1.45268 0.928082 1.35394 0.883784 1.24447C0.839487 1.135 0.817017 1.01714 0.817767 0.898167C0.818517 0.779198 0.84247 0.661656 0.888144 0.552815C0.933817 0.443975 1.00024 0.346152 1.0833 0.2654C1.25273 0.0964518 1.47689 0.00158207 1.71035 1.96097e-05C1.94381 -0.00154286 2.1691 0.0903181 2.34056 0.256985Z"
          />
        </svg>
      </Button>
      <div
        className="dropdown-menu"
        aria-labelledby="classificationDropdown"
        style={{ minWidth: '200px' }}
      >
        <form>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="breaking"
          >
            <input
              type="checkbox"
              id="breaking"
              className="check"
              checked={classifications.indexOf('BREAKING') !== -1}
              onChange={handleCheck}
              value="BREAKING"
            />
            <label className="label" htmlFor="breaking">
              Breaking
            </label>
          </label>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="today"
          >
            <input
              type="checkbox"
              id="today"
              className="check"
              checked={classifications.indexOf('TODAY') !== -1}
              onChange={handleCheck}
              value="TODAY"
            />
            <label className="label" htmlFor="today">
              Normal
            </label>
          </label>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="evergreen"
          >
            <input
              type="checkbox"
              id="evergreen"
              className="check"
              checked={classifications.indexOf('EVERGREEN') !== -1}
              onChange={handleCheck}
              value="EVERGREEN"
            />
            <label className="label" htmlFor="evergreen">
              Evergreen
            </label>
          </label>
          <label
            className="dropdown-item text_unselectable checkbox line-height-24"
            htmlFor="unclassified"
          >
            <input
              type="checkbox"
              id="unclassified"
              className="check"
              checked={classifications.indexOf('UNCLASSIFIED') !== -1}
              onChange={handleCheck}
              value="UNCLASSIFIED"
            />
            <label className="label" htmlFor="unclassified">
              Unclassified
            </label>
          </label>
          <div className="dropdown-divider" />
          <DropdownFooter eventHandlers={{ handleApply, handleClear }} />
        </form>
      </div>
    </div>
  );
};

Classification.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  eventHandlers: PropTypes.shape({
    handleFilterChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default Classification;
