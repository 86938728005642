import { create } from 'zustand';

interface HealthMenuState {
  isHealthMenuOpen: boolean;

  // https://tkdodo.eu/blog/working-with-zustand#separate-actions-from-state
  actions: {
    closeHealthMenu: () => void;
    openHealthMenu: () => void;
  };
}

const useHealthMenuStore = create<HealthMenuState>((set) => ({
  isHealthMenuOpen: false,

  actions: {
    openHealthMenu: () => set({ isHealthMenuOpen: true }),
    closeHealthMenu: () => set({ isHealthMenuOpen: false }),
  },
}));

const useIsHealthMenuOpen = () =>
  useHealthMenuStore((state) => state.isHealthMenuOpen);
const useHealthMenuActions = () => useHealthMenuStore((state) => state.actions);

export { useHealthMenuActions, useIsHealthMenuOpen };
