import { useLocation } from 'react-router-dom';

import PageImage from 'components/misc/PageImage';
import type { FixTypeLater } from 'types';

interface BreadcrumbPageImageProps {
  accountAPI: FixTypeLater;
  to: string;
}

const BreadcrumbPageImage = ({ accountAPI, to }: BreadcrumbPageImageProps) => {
  const { pathname } = useLocation();

  const isCurrentPage = pathname === to;

  return (
    <PageImage accountAPI={accountAPI} mr={1}>
      <PageImage.SocialLogo
        bg={isCurrentPage ? 'primary.50' : 'white'}
        borderColor={isCurrentPage ? 'primary.200' : 'gray.600'}
        color={isCurrentPage ? 'primary.600' : 'gray.600'}
        size={3}
        svgSize={2.5}
      />
      <PageImage.Image size={4} borderColor="primary.200" />
    </PageImage>
  );
};

export default BreadcrumbPageImage;
