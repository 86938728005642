import clsx from 'clsx';
import { useState } from 'react';

import postUploadImage from 'api/postUploadImage';
import {
  getCurrentAccountAPIId,
  getCurrentPropertyId,
  getCurrentSocialPageURN,
  isCurrentAccountAPIPaused,
} from 'common/accountAPIs';
import {
  AB_TEST_STATUSES,
  ACTION_TYPES,
  COLLECTION_NAMES,
  FRONTEND_METRICS,
  MEDIA_ITEM_STATES,
  MOST_RECENT_SHARE_TYPE_KEY,
  POST_TYPES,
  SHARE_TIME_TYPES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import { getFeatureToggle } from 'common/settings';
import * as tracker from 'common/tracker';
import { isNotNull } from 'common/utility';
import DuplicateSchedulePopup from 'components/compose/DuplicateSchedulePopup';
import { trackApprovalAction } from 'components/home/approvals/Approvals';
import Button from 'components/misc/Button';
import {
  closeDuplicateScheduleModal,
  startSavingMediaItems,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import {
  getComposeBoxPostPreviews,
  getNumPosts,
  isLoadingAny,
  isSavingAny,
} from 'helpers/composeBox';
import { cropRequiredMediaItemImages } from 'helpers/imageCropper';
import { base64ToArrayBuffer } from 'helpers/images';
import { getNetworkAndPageName } from 'helpers/tracking';
import { MESSAGE_MEDIA_ITEM_ERROR } from 'pubsub/topics';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';
import { useComposeBoxScheduleMetricsActions } from 'state/composeBoxScheduleMetrics';
import type { ActionType, CollectionName, PostPreview } from 'types';

const STATUSES = {
  INACTIVE: 'INACTIVE',
  REVERTING: 'REVERTING',
  SAVING: 'SAVING',
  SCHEDULING: 'SCHEDULING',
  SKIPPING: 'SKIPPING',
  APPROVING: 'APPROVING',
} as const;

type Statuses = (typeof STATUSES)[keyof typeof STATUSES];

interface ActionsProps {
  onSavingStateSet: (
    msg: string | null,
    data: {
      actionType: ActionType;
      collectionName: CollectionName;
      postPreviews: Array<PostPreview>;
      errorOnDuplicate: boolean;
    },
  ) => void;
}

/*
 * Full edit mode - actions
 */

const Actions = ({ onSavingStateSet }: ActionsProps) => {
  const { dispatch, postPreviews, showDuplicateScheduleModal } =
    useComposeBoxContext();
  const { startScheduleMetrics } = useComposeBoxScheduleMetricsActions();
  const mediaItems = postPreviews.map((entry) => entry.mediaItem);

  const unhiddenPostPreviews = getComposeBoxPostPreviews(postPreviews);
  const closeComposeBox = useComposeBoxOpenStore(
    (state) => state.closeComposeBox,
  );
  const [status, setStatus] = useState<Statuses>(STATUSES.INACTIVE);

  // We need to track this separately from the isSaving prop because the media item
  // saving state is set later in the process.
  const [isActionSaving, setIsActionSaving] = useState(false);

  const findMediaItem = ({ guid }: { guid: string }) =>
    postPreviews.find((item) => item.guid === guid)?.mediaItem ?? null;

  const isInstantShareNowEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.SHARENOW_INSTANT_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  /**
   * Event handlers
   */

  const shouldAbandonSave = () => {
    const abTestInProgress = postPreviews.some(
      (item) =>
        MediaItem.getABTestStatusId({
          mediaItem: item.mediaItem,
        }) === AB_TEST_STATUSES.TESTING,
    );
    if (abTestInProgress) {
      // eslint-disable-next-line no-alert -- legacy. TODO: convert to modal?
      return !window.confirm(
        'The AB test is already in progress. Saving these changes will restart the AB test. Do you want to restart the AB test?',
      );
    }
    return false;
  };

  const getPostPreviewsWithCroppedImages = async () => {
    const allGuids = unhiddenPostPreviews
      .filter((item) => !item.isShared)
      .map((item) => item.guid);

    // Find the guids associated with a media item whose post images require updating
    const invalidAspectRatiosGuids = allGuids.filter((guid) => {
      const mediaItem = findMediaItem({ guid });

      const postImageNeedsToBeUpdated = MediaItem.getPostImageNeedsToBeUpdated({
        mediaItem,
      });

      return (
        mediaItem !== null &&
        Object.values(postImageNeedsToBeUpdated).indexOf(true) > -1
      );
    });

    // Autocrop any images which do not have the correct aspect ratio
    const promises =
      invalidAspectRatiosGuids.length > 0
        ? invalidAspectRatiosGuids.flatMap((guid) =>
            cropRequiredMediaItemImages({
              guid,
              mediaItem: findMediaItem({
                guid,
              }),
            }),
          )
        : [];

    // Update the images of the media item that have been auto cropped
    if (promises.length > 0) {
      const results = await Promise.all(promises);

      // Group the media items together
      const result = results.filter(isNotNull).reduce<
        Record<
          string,
          Array<{
            guid: string;
            index: number;
            updatedImageURL: string;
            postImageNeedsToBeUpdated: boolean;
          }>
        >
      >((acc, currentResult) => {
        acc[currentResult.guid] = acc[currentResult.guid] || [];
        acc[currentResult.guid].push(currentResult);
        return acc;
      }, {});

      const guidsToUpdate = Object.keys(result);

      return unhiddenPostPreviews.map((preview) => {
        const { guid } = preview;
        if (!guidsToUpdate.includes(guid)) {
          return preview;
        }

        let mediaItem = findMediaItem({
          guid,
        });
        const postImageNeedsToBeUpdated =
          MediaItem.getPostImageNeedsToBeUpdated({ mediaItem });
        const imageURLs = MediaItem.getImageURLs({ mediaItem });
        const updates = result[guid];
        updates.forEach((update) => {
          postImageNeedsToBeUpdated[update.index] =
            update.postImageNeedsToBeUpdated;
          imageURLs[update.index] = update.updatedImageURL;
        });

        mediaItem = MediaItem.setPostImageNeedsToBeUpdated({
          mediaItem,
          fieldValue: postImageNeedsToBeUpdated,
        });
        mediaItem = MediaItem.setImageURLs({
          mediaItem,
          fieldValue: imageURLs,
        });

        return { ...preview, mediaItem };
      });
    }

    return unhiddenPostPreviews;
  };

  const getPostPreviewsWithImageUrls = async (
    postPreviewsArray: PostPreview[],
  ) => {
    const postPreviewsWithImageUrls = [];
    for (const postPreview of postPreviewsArray) {
      let mediaItem = findMediaItem({ guid: postPreview.guid });
      try {
        const imageUrl = MediaItem.getImageURLs({ mediaItem })[0];
        if (imageUrl?.startsWith('data:image/jpeg;base64,')) {
          const arrayBuffer = base64ToArrayBuffer(imageUrl);
          // create an image file from arrayBuffer
          const file = new File([arrayBuffer], 'image.jpeg', {
            type: 'image/jpeg',
          });
          // eslint-disable-next-line no-await-in-loop
          const newImageURL = await postUploadImage({
            socialPageURN: getCurrentSocialPageURN(),
            imageFile: file,
          });
          mediaItem = MediaItem.setImageURLs({
            mediaItem,
            fieldValue: [newImageURL],
          });
          postPreviewsWithImageUrls.push({
            ...postPreview,
            mediaItem,
          });
        } else {
          postPreviewsWithImageUrls.push(postPreview);
        }
      } catch (error) {
        logger.error({
          event: 'Failed to upload thumbnail',
          error,
        });
        // reset image url
        mediaItem = MediaItem.setImageURLs({
          mediaItem,
          fieldValue: [],
        });
        postPreviewsWithImageUrls.push({
          ...postPreview,
          mediaItem,
        });
      }
    }
    return postPreviewsWithImageUrls;
  };

  const handleArticleAction = async ({
    actionType,
    errorOnDuplicate = true,
  }: {
    actionType: ActionType;
    errorOnDuplicate?: boolean;
  }) => {
    // Save all items in compose box
    const firstItem = unhiddenPostPreviews[0].mediaItem;
    const firstGuid = unhiddenPostPreviews[0].guid;
    const allGuids = unhiddenPostPreviews
      .filter((item) => !item.isShared)
      .map((item) => item.guid);

    // Validate timing options
    const invalidTimingGuid = allGuids.find((guid) => {
      const mediaItem = findMediaItem({ guid });
      // Do nothing if the media item no longer exists
      return mediaItem !== null && !MediaItem.getIsTimingValid({ mediaItem });
    });

    if (invalidTimingGuid) {
      logger.info(
        `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in pages/ComposeBox.handleArticleAction`,
      );
      PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
        guid: invalidTimingGuid,
        error: 'Time selection invalid',
      });
      return; // Do not continue if timing options are invalid
    }

    // Validate urls
    const postType = MediaItem.getPostType({ mediaItem: firstItem });
    let areURLsValid = true;
    if (postType === POST_TYPES.LINK && actionType === ACTION_TYPES.SCHEDULE) {
      const validGuids = allGuids.filter((guid) => {
        const mediaItem = findMediaItem({ guid });
        if (mediaItem === null) {
          return true;
        }
        const isURLValid = MediaItem.getIsURLValid({ mediaItem });
        if (!isURLValid) {
          const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
          logger.info(
            `Actions:handleArticleAction - invalid URL for api ${accountAPIId}`,
          );
        }
        return isURLValid;
      });

      const unresolvedURLsExist = allGuids.some((guid) => {
        const mediaItem = findMediaItem({ guid });
        return (
          mediaItem !== null &&
          MediaItem.getIsURLValid({ mediaItem }) &&
          MediaItem.getIsURLResolved({ mediaItem }) === false
        );
      });

      if (validGuids.length === 0) {
        logger.info(
          `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in pages/ComposeBox.handleArticleAction`,
        );
        PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
          guid: firstGuid,
          error: 'Please ensure your post URLs are valid',
        });
        areURLsValid = false;
      }
      if (unresolvedURLsExist) {
        const confirmMessage =
          validGuids.length === 1
            ? "Your share URL doesn't seem to resolve to an article. Do you still want to save this post?"
            : "One or more of your share URLs doesn't seem to resolve to an article. Do you still want to save this post?";
        // eslint-disable-next-line no-alert -- legacy. TODO: convert to modal?
        if (!window.confirm(confirmMessage)) {
          areURLsValid = false;
        }
      }
    }
    if (!areURLsValid) {
      return; // Do nothing if errors exist or the user chooses not to continue
    }

    dispatch(startSavingMediaItems({ guids: allGuids }));

    const postPreviewsWithCroppedImages =
      await getPostPreviewsWithCroppedImages();

    // upload thumbnail image to backend if in base64 format as that means it hasn't been uploaded
    const postPreviewsWithThumbnailImages = (await getPostPreviewsWithImageUrls(
      postPreviewsWithCroppedImages,
    )) as PostPreview[];

    onSavingStateSet(null, {
      actionType,
      collectionName: COLLECTION_NAMES.COMPOSE,
      postPreviews: postPreviewsWithThumbnailImages,
      errorOnDuplicate,
    });
  };

  const handleArticleCancel = () => {
    logger.info('Actions:handleArticleCancel');

    const mediaItem = postPreviews[0].mediaItem;
    const currentState = MediaItem.getState({ mediaItem });

    if (currentState === MEDIA_ITEM_STATES.APPROVAL_REQUIRED) {
      trackApprovalAction({
        mediaItem,
        action: 'Cancel',
        origin: 'Compose Box',
      });
    } else {
      const postType = MediaItem.getPostType({ mediaItem });
      const accountAPIId = getCurrentAccountAPIId();
      tracker.track({
        eventName: 'Cancel Post',
        trackingParams: {
          'Article URL':
            postType === POST_TYPES.LINK
              ? MediaItem.getUnshortenedShareURL({ mediaItem })
              : 'undefined',
          'Network - Social Page': getNetworkAndPageName({
            accountAPIId,
          }),
          'Account API Id': accountAPIId,
        },
      });
    }
    closeComposeBox();
  };

  const handleRevert = async () => {
    logger.info('Actions:handleRevert');

    setStatus(STATUSES.REVERTING);
    startComposeBoxSaveTiming();
    setIsActionSaving(true);

    if (shouldAbandonSave()) {
      return;
    }

    handleArticleAction({
      actionType: ACTION_TYPES.UNSCHEDULE,
    });

    setIsActionSaving(false);
  };

  const handleSave = () => {
    logger.info('Actions:handleSave');

    setStatus(STATUSES.SAVING);
    startComposeBoxSaveTiming();
    setIsActionSaving(true);

    if (shouldAbandonSave()) {
      return;
    }

    handleArticleAction({ actionType: ACTION_TYPES.SAVE });

    setIsActionSaving(false);
  };

  const handleArticleSchedule = async ({ errorOnDuplicate = true }) => {
    logger.info('Actions:handleArticleSchedule');

    if (shouldAbandonSave()) {
      return;
    }
    const postType = MediaItem.getPostType({
      mediaItem: postPreviews?.[0].mediaItem,
    });

    if (postType) {
      localStorage.setItem(
        MOST_RECENT_SHARE_TYPE_KEY,
        JSON.stringify({
          postType,
        }),
      );
    }

    handleArticleAction({
      actionType: ACTION_TYPES.SCHEDULE,
      errorOnDuplicate,
    });
  };

  const handleScheduleClick = async ({
    errorOnDuplicate,
  }: {
    errorOnDuplicate: boolean;
  }) => {
    logger.info('Actions:handleScheduleClick');

    const includesShareNowPost = postPreviews.some(
      (postPreview) =>
        MediaItem.getShareTime({ mediaItem: postPreview.mediaItem }).type ===
        SHARE_TIME_TYPES.NOW,
    );

    if (includesShareNowPost) {
      if (!isInstantShareNowEnabled) {
        metrics.mark(FRONTEND_METRICS.SHARE_NOW);
      }
    } else {
      metrics.mark(FRONTEND_METRICS.COMPOSE_BOX_SCHEDULE_POST);
    }

    if (!isCurrentAccountAPIPaused() || confirmSave()) {
      setStatus(STATUSES.SCHEDULING);
      startScheduleMetrics({
        numberOfPostPreviews: getNumPosts(postPreviews),
        includesShareNowPost,
      });
      setIsActionSaving(true);

      await handleArticleSchedule({ errorOnDuplicate });

      setIsActionSaving(false);
    }
  };

  const handleApprovalSkipped = () => {
    logger.info('Actions:handleApprovalSkipped');

    if (!isCurrentAccountAPIPaused() || confirmSave()) {
      setStatus(STATUSES.SKIPPING);
      setIsActionSaving(true);

      if (shouldAbandonSave()) {
        return;
      }

      const { mediaItem } = unhiddenPostPreviews[0];

      handleArticleAction({
        actionType: ACTION_TYPES.DELETE,
      });

      trackApprovalAction({
        mediaItem,
        action: 'Skip',
        origin: 'Compose Box',
      });

      setIsActionSaving(false);
    }
  };

  const handleApprove = async () => {
    logger.info('Actions:handleApprove');

    if (!isCurrentAccountAPIPaused() || confirmSave()) {
      setStatus(STATUSES.APPROVING);
      setIsActionSaving(true);

      if (shouldAbandonSave()) {
        return;
      }

      const { mediaItem } = unhiddenPostPreviews[0];

      handleArticleAction({
        actionType: ACTION_TYPES.SCHEDULE,
      });

      trackApprovalAction({
        mediaItem,
        action: 'Approve',
        origin: 'Compose Box',
      });

      setIsActionSaving(false);
    }
  };

  /**
   * Helper methods
   */

  const confirmSave = () => {
    let confirmMessage =
      'Your Schedule Queue is paused. Your post will be moved to the Schedule Queue but stay on hold until posting is resumed.';
    mediaItems.forEach((mediaItem) => {
      const shareTime = MediaItem.getShareTime({ mediaItem });
      if (shareTime.type === SHARE_TIME_TYPES.NOW) {
        confirmMessage =
          'Your Schedule Queue is paused. Are you sure you want to share this post now?';
      }
    });

    // eslint-disable-next-line no-alert
    return window.confirm(confirmMessage);
  };

  /**
   * Starts tracking the initial time when the user begins to save (or revert) a post.
   */
  const startComposeBoxSaveTiming = () => {
    if (mediaItems.length === 1) {
      const postType = MediaItem.getPostType({ mediaItem: mediaItems[0] });
      if (postType === POST_TYPES.LINK) {
        metrics.mark(FRONTEND_METRICS.COMPOSE_BOX_SAVE_DRAFT);
      }
    }
  };

  /**
   * Render methods
   */

  const renderLabel = (
    normalLabel: string,
    activeLabel: string,
    isActive: boolean,
  ) => {
    if (isActive) {
      return (
        <span>
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          />
          {activeLabel}
        </span>
      );
    }
    return <span>{normalLabel}</span>;
  };

  const firstMediaItem = mediaItems[0];

  const isLoadingAccount = isLoadingAny(postPreviews);
  const isLocked = unhiddenPostPreviews.length === 0;
  const isMediaItemSaving = isSavingAny(postPreviews);
  const hasError = unhiddenPostPreviews.some(
    ({ mediaItem }) =>
      // Either there is a validation message
      !!MediaItem.getValidationMessage({ mediaItem }) ||
      // Or it's a link post and the URL is invalid or unresolved
      (MediaItem.getPostType({ mediaItem }) === POST_TYPES.LINK &&
        !MediaItem.getIsURLValid({ mediaItem })),
  );
  const isDisabled =
    isLoadingAccount ||
    isLocked ||
    isActionSaving ||
    isMediaItemSaving ||
    hasError;
  const isSaving = isMediaItemSaving || isActionSaving;

  let showApprove = false;
  let showDraft = true;
  let showRevert = false;
  let showSave = false;
  let showSchedule = true;
  const itemState = MediaItem.getState({ mediaItem: firstMediaItem });
  if (itemState === MEDIA_ITEM_STATES.SCHEDULED) {
    showApprove = false;
    showDraft = false;
    showRevert = true;
    showSave = true;
    showSchedule = false;
  } else if (itemState === MEDIA_ITEM_STATES.APPROVAL_REQUIRED) {
    showApprove = true;
    showDraft = true;
    showRevert = false;
    showSave = false;
    showSchedule = false;
  }

  const shareCTA =
    MediaItem.getShareTime({ mediaItem: firstMediaItem }).type ===
    SHARE_TIME_TYPES.NOW
      ? 'Share'
      : 'Schedule';
  const activeShareCTA =
    MediaItem.getShareTime({ mediaItem: firstMediaItem }).type ===
    SHARE_TIME_TYPES.NOW
      ? 'Sharing'
      : 'Scheduling';

  const isDuplicateShareWarningDisabled = getFeatureToggle({
    // @ts-expect-error -- TODO: Check if this feature toggle is still used, delete if not.
    featureName: `suppress_error_on_duplicate_for_api_${getCurrentAccountAPIId()}`,
    propertyId: getCurrentPropertyId(),
  });

  // Duplicate schedule popup only works currently when scheduling on a single page and
  // on pages which a client hasn't explicitly asked us to disable the feature for
  const errorOnDuplicate =
    mediaItems.length === 1 && !isDuplicateShareWarningDisabled;

  const { mediaItem: duplicateSchedulePopupMediaItem } =
    postPreviews.find(({ isHidden }) => !isHidden) ?? postPreviews[0];

  return (
    <div className="d-flex align-items-center w-100 py-2 py-md-3 pr-3 pl-3">
      <Button
        className={clsx('align-self-center mr-2', !showApprove && 'ml-auto')}
        data-cy-action="cancel"
        onClick={handleArticleCancel}
        size="lg"
        autoFocus
        disabled={isSaving}
      >
        Cancel
      </Button>
      {showDraft && (
        <Button
          className="align-self-center mr-2"
          size="lg"
          data-cy-action="draft"
          data-cy-attribute={`isEnabled:${!isDisabled}`}
          disabled={isDisabled}
          onClick={handleSave}
        >
          {renderLabel(
            'Save as draft',
            'Saving',
            isSaving && status === STATUSES.SAVING,
          )}
        </Button>
      )}
      {showRevert && (
        <Button
          className="align-self-center mr-2"
          size="lg"
          data-cy-action="revert"
          data-cy-attribute={`isEnabled:${!isDisabled}`}
          disabled={isDisabled}
          onClick={handleRevert}
        >
          {renderLabel(
            'Revert to draft',
            'Reverting',
            isSaving && status === STATUSES.REVERTING,
          )}
        </Button>
      )}
      {showApprove && (
        <Button
          className="align-self-center mr-2 ml-auto"
          size="lg"
          data-cy-action="skip"
          data-cy-attribute={`isEnabled:${!isDisabled}`}
          disabled={isDisabled}
          onClick={handleApprovalSkipped}
        >
          {renderLabel(
            'Skip',
            'Skipping',
            isSaving && status === STATUSES.SKIPPING,
          )}
        </Button>
      )}
      <div className="button_queue">
        {showSave && (
          <Button
            className="button_queue"
            size="lg"
            variant="dark"
            data-cy-action="save"
            data-cy-attribute={`isEnabled:${!isDisabled}`}
            disabled={isDisabled}
            onClick={() => handleScheduleClick({ errorOnDuplicate })}
          >
            {renderLabel(
              'Save',
              'Saving',
              isSaving && status === STATUSES.SCHEDULING,
            )}
          </Button>
        )}
        {showSchedule && (
          <Button
            className="button_queue"
            size="lg"
            variant="dark"
            data-cy-action="schedule"
            data-cy-attribute={`isEnabled:${!isDisabled}`}
            disabled={isDisabled}
            onClick={() => handleScheduleClick({ errorOnDuplicate })}
          >
            {renderLabel(
              shareCTA,
              activeShareCTA,
              isSaving && status === STATUSES.SCHEDULING,
            )}
          </Button>
        )}
        {showApprove && (
          <Button
            className="button_queue"
            size="lg"
            variant="dark"
            data-cy-action="approve"
            data-cy-attribute={`isEnabled:${!isDisabled}`}
            disabled={isDisabled}
            onClick={handleApprove}
          >
            {renderLabel(
              'Approve',
              'Approving',
              isSaving && status === STATUSES.APPROVING,
            )}
          </Button>
        )}
      </div>
      {showDuplicateScheduleModal && (
        <DuplicateSchedulePopup
          mediaItem={duplicateSchedulePopupMediaItem}
          onClose={() => {
            dispatch(closeDuplicateScheduleModal());
          }}
          onSchedule={() => {
            handleArticleSchedule({
              errorOnDuplicate: false,
            });
          }}
        />
      )}
    </div>
  );
};

export default Actions;
