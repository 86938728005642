import {
  Button,
  Flex,
  FormControl,
  Input,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useRef, useState } from 'react';

import postPropertiesSyndValidate from 'api/postPropertiesSyndValidate';
import { getCurrentPropertyId } from 'common/accountAPIs';
import { KEYNAMES, MIXPANEL_ORIGIN, PROPERTY_STATES } from 'common/constants';
import { getCurrentPropertyState } from 'common/currentPropertyAndAPI';
import { getErrorMessage } from 'common/errorHandling';
import {
  SYNDFEED_VALIDATION_ERROR,
  SYNDFEED_VALIDATION_ERROR_APPEND_MESSAGE,
} from 'common/errorMessages';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import type { AccountFeed, RssFeedData } from 'types';

function getErrorType(error: unknown) {
  // @ts-expect-error -- it is safe to use optional chaining on an unknown type
  return error?.error?.errorType ?? null;
}

interface AddRSSFeedProps {
  existingFeeds: AccountFeed[];
  onSaveRssFeed: (data: RssFeedData) => void;
}

const AddRSSFeed = ({ existingFeeds, onSaveRssFeed }: AddRSSFeedProps) => {
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>('');
  const [feedURL, setFeedURL] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const feedURLRef = useRef<HTMLInputElement>(null);

  const handleURLChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const value = event.currentTarget.value.trim();
    setFeedURL(value);
    setErrorMessage('');
  };

  const handleAddFeed = async () => {
    // Check for empty URL
    if (!feedURL) {
      setErrorMessage('Please enter an RSS Feed URL.');
      return;
    }
    // Check for duplication
    const findDuplicates = existingFeeds.filter(
      (feed) => feed.syndFeedURL === feedURL,
    );
    if (findDuplicates.length > 0) {
      setErrorMessage('This RSS Feed has already been added.');
      return;
    }
    // Attempt to add new feed
    logger.info(`RSSFeeds:handleAddFeed - url ${feedURL}`);
    setIsAdding(true);
    try {
      const data = await postPropertiesSyndValidate({
        propertyId: getCurrentPropertyId(),
        feedURL,
      });

      setIsAdding(false);
      setFeedURL('');
      setErrorMessage('');
      onSaveRssFeed(data.feedData);
      const isUnderSetup =
        getCurrentPropertyState() === PROPERTY_STATES.NEWSIGNUP;
      tracker.track({
        eventName: 'Add Syndfeed',
        trackingParams: {
          'Syndfeed URL': feedURL,
          Origin: isUnderSetup
            ? MIXPANEL_ORIGIN.SETUP
            : MIXPANEL_ORIGIN.SETTINGS,
        },
      });
    } catch (error) {
      const newErrorType: SYNDFEED_VALIDATION_ERROR = getErrorType(error);
      const appendMessage =
        SYNDFEED_VALIDATION_ERROR_APPEND_MESSAGE?.[newErrorType] ?? '';
      const newErrorMessage = (
        <span>
          {getErrorMessage(error)}
          {appendMessage}
        </span>
      );
      setIsAdding(false);
      setErrorMessage(newErrorMessage);
      if (feedURLRef.current) {
        feedURLRef.current.focus();
      }
    }
  };

  const handleKeyUp: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === KEYNAMES.ENTER) {
      handleAddFeed();
    }
  };

  return (
    <FormControl isInvalid={!!errorMessage}>
      <FormControl.FormLabel htmlFor="rss-feed">
        RSS feed or sitemap URL
      </FormControl.FormLabel>
      <Flex gap={3}>
        <Input
          id="rss-feed"
          data-cy-input="feedURL"
          onKeyUp={handleKeyUp}
          ref={feedURLRef}
          onChange={handleURLChange}
          value={feedURL.trim()}
          isDisabled={isAdding}
          maxW="lg"
        />
        <Button
          variant="secondary"
          onClick={handleAddFeed}
          data-cy-action="addFeed"
          loadingText="Adding"
          isDisabled={isAdding || feedURL.length === 0}
          isLoading={isAdding}
        >
          Add
        </Button>
      </Flex>
      {errorMessage && (
        <FormControl.FormErrorMessage data-cy-id="errorMessage">
          {errorMessage}
        </FormControl.FormErrorMessage>
      )}
    </FormControl>
  );
};

export default AddRSSFeed;
