import { Auth } from 'aws-amplify';

import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

export default async function authUpdateUserName({
  name = mandatory('name'),
} = {}) {
  const guid = metrics.start('authUpdateUserName');
  logger.info('API:authUpdateUserName');

  try {
    const user = await Auth.currentAuthenticatedUser();
    const response = await Auth.updateUserAttributes(user, { name });
    metrics.end('authUpdateUserName', guid);
    return response;
  } catch (error) {
    metrics.fail('authUpdateUserName', guid);
    const cognitoError = handleCognitoError({
      originalError: error,
      errorLocation: 'api/authUpdateUserName',
    });
    throw cognitoError;
  }
}
