import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import PubSub from 'pubsub-js';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import Immutable from 'seamless-immutable';
import { shallow } from 'zustand/shallow';

import {
  getCurrentPropertyId,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  ACTION_TYPES,
  API_ERROR_MESSAGES,
  COMPOSE_BOX_TABS,
  FRONTEND_METRICS,
  KEYNAMES,
  MEDIA_ITEM_STATES,
  POST_TYPES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import { setModalActive } from 'common/modal';
import { getFeatureToggle, getSetting } from 'common/settings';
import { generateGuid } from 'common/string';
import { isDefined, isNull, isUndefined } from 'common/utility';
import Actions from 'components/compose/Actions';
import ComposeBoxErrorBoundary from 'components/compose/ComposeBoxErrorBoundary';
import ComposeBoxModal from 'components/compose/ComposeBoxModal';
import ComposeLoading from 'components/compose/ComposeLoading';
import MobileMenu from 'components/compose/MobileMenu';
import PostDetails from 'components/compose/PostDetails';
import Previews from 'components/compose/Previews';
import SocialPages from 'components/compose/SocialPages';
import TimeSensitivity from 'components/compose/TimeSensitivity';
import {
  ComposeBoxProvider,
  addPreview,
  applyFieldUpdatesForAllGuids,
  applyFieldUpdatesForGuid as applyFieldUpdatesForGuidDispatch,
  deletePostPreview,
  finishSavingMediaItem,
  handleMediaItemError,
  updateMediaItemByGuid,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import * as topics from 'pubsub/topics';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';
import {
  useComposeBoxScheduleMetricsActions,
  useIncludesShareNowPost,
  useNumberOfPostPreviews,
  useScheduleClickTime,
} from 'state/composeBoxScheduleMetrics';
import { useComposeBoxSharesStore } from 'state/composeBoxShares';
import { ActionType, CollectionName, FixTypeLater, PostPreview } from 'types';
import PreviewLoading from './PreviewLoading';

// TODO: Re-enable lazy loading, once we have a solution for server-side caching
// const Previews = lazyWithRetries(() => import('components/compose/Previews'));

const {
  MESSAGE_MEDIA_ITEM_ERROR,
  MESSAGE_SAVE_COMPOSE_BOX,
  REQUEST_DELETE_COMPOSE_BOX_ITEM,
  REQUEST_FIND_COMPOSE_BOX_ITEM,
  REQUEST_UPDATE_COMPOSE_BOX_ITEM,
  REQUEST_DUPLICATE_COMPOSE_BOX_ITEM,
  RESPONSE_UPDATE_COMPOSE_BOX_ITEM,
  RESPONSE_FIND_COMPOSE_BOX_ITEM,
  RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM,
} = topics;

const handleWindowUnload = (event: BeforeUnloadEvent) => {
  logger.info('ComposeBox:handleWindowUnload');

  // Prevent the user from leaving the page in case there are unsaved changes
  event.preventDefault();
  // eslint-disable-next-line no-param-reassign
  event.returnValue = ''; // Message controlled by browser so doesn't matter what we put here
};

interface ComposeBoxProps {
  isMobileWindowWidth: boolean;
  onSavingStateSet: (
    message: string | null,
    data: {
      actionType: ActionType;
      collectionName: CollectionName;
      postPreviews: PostPreview[];
      errorOnDuplicate: boolean;
    },
  ) => void;
}

const ComposeBox = ({
  isMobileWindowWidth,
  onSavingStateSet,
}: ComposeBoxProps) => {
  const {
    dispatch,
    isPopupSelectorOpen,
    passedCrossPostLimit,
    postPreviews,
    selectedTab,
  } = useComposeBoxContext();
  const timeMountedRef = useRef(Date.now());
  const hasLoggedMetricsRef = useRef(false);
  const isComposeBoxOpen = useComposeBoxOpenStore(
    (state) => state.isComposeBoxOpen,
  );
  const closeComposeBox = useComposeBoxOpenStore(
    (state) => state.closeComposeBox,
  );
  const { failedShares, setFailedShares } = useComposeBoxSharesStore(
    (state) => ({
      failedShares: state.failedShares,
      setFailedShares: state.setFailedShares,
    }),
    shallow,
  );

  const postPreview =
    postPreviews.find(({ isHidden }) => !isHidden) ?? postPreviews[0];

  const [initialPostType] = useState(
    postPreview
      ? MediaItem.getPostType({ mediaItem: postPreview.mediaItem })
      : '',
  );
  const numberOfPostPreviews = useNumberOfPostPreviews();
  const scheduleClickTime = useScheduleClickTime();
  const includesShareNowPost = useIncludesShareNowPost();
  const { resetScheduleMetrics } = useComposeBoxScheduleMetricsActions();
  const { global } = useGlobalInfo();

  const currentUserEmailAddress = global.getGlobalInfo()?.user?.emailAddress;

  const areAllPostPreviewsClosed = postPreviews.length === 0;
  const areAllPostPreviewInErrorState = postPreviews.every((preview) =>
    MediaItem.getErrorMessage({ mediaItem: preview.mediaItem }),
  );

  const isInstantShareNowEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.SHARENOW_INSTANT_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  const getMediaItemIndex = useCallback(
    (data: { guid: string }) => {
      const { guid } = data;
      return postPreviews.findIndex((item) => item.guid === guid);
    },
    [postPreviews],
  );

  const findMediaItem = useCallback(
    (data: { guid: string }) => {
      const { guid } = data;
      const index = getMediaItemIndex({ guid });
      if (isDefined(index) && index !== -1) {
        return Immutable(postPreviews[index].mediaItem);
      }
      return null;
    },
    [getMediaItemIndex, postPreviews],
  );

  const deleteComposeBoxItem = useCallback(
    ({ guid }: { guid: string }) => {
      logger.info(`ComposeBox:deleteComposeBoxItem ${guid}`);
      dispatch(deletePostPreview({ guid }));
    },
    [dispatch],
  );

  const onDeleteComposeBoxItem = useCallback(
    (message: string, data: { guid: string }) => {
      logger.info('ComposeBox:onDeleteComposeBoxItem');

      const { guid } = data;
      deleteComposeBoxItem({ guid });
    },
    [deleteComposeBoxItem],
  );

  const onFindComposeBoxItem = useCallback(
    (message: string, data: { guid: string }) => {
      logger.info('ComposeBox:onFindComposeBoxItem');

      const { guid } = data;

      const index = getMediaItemIndex({ guid });
      logger.info(
        `PubSub: publish ${RESPONSE_FIND_COMPOSE_BOX_ITEM}.${guid} in components/compose/ComposeBox.onFindComposeBoxItem`,
      );
      if (isDefined(index) && index !== -1) {
        PubSub.publish(`${RESPONSE_FIND_COMPOSE_BOX_ITEM}.${guid}`, {
          mediaItem: postPreviews[index].mediaItem,
        });
      } else {
        PubSub.publish(`${RESPONSE_FIND_COMPOSE_BOX_ITEM}.${guid}`, null);
      }
    },
    [getMediaItemIndex, postPreviews],
  );

  const onMediaItemError = useCallback(
    (message: string, data: { guid: string; error: unknown }) => {
      const { guid, error } = data;
      if (isUndefined(guid)) {
        return; // Ignore news feed errors (these will be handled in the NewsFeed component)
      }

      const index = getMediaItemIndex({ guid });
      if (index !== -1) {
        const mediaItem = findMediaItem({ guid });
        if (!isNull(mediaItem)) {
          const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
          if (passedCrossPostLimit) {
            setFailedShares([...failedShares, accountAPIId]);
          }
          dispatch(
            handleMediaItemError({
              error: error ?? API_ERROR_MESSAGES.API_UNKNOWN,
              guid,
            }),
          );
        }
      }
    },
    [
      dispatch,
      failedShares,
      findMediaItem,
      getMediaItemIndex,
      passedCrossPostLimit,
      setFailedShares,
    ],
  );

  const onUpdateComposeBoxItem = useCallback(
    (message: string, data: { guid: string; mediaItem: FixTypeLater }) => {
      logger.info('ComposeBox:onUpdateComposeBoxItem');
      const { guid, mediaItem } = data;
      dispatch(updateMediaItemByGuid({ guid, mediaItem }));
      logger.info(
        `PubSub: publish ${RESPONSE_UPDATE_COMPOSE_BOX_ITEM}.${guid} in components/compose/ComposeBox.onUpdateComposeBoxItem`,
      );
      PubSub.publish(`${RESPONSE_UPDATE_COMPOSE_BOX_ITEM}.${guid}`, true);
    },
    [dispatch],
  );

  const onDuplicateComposeBoxItem = useCallback(
    (message: string, data: { requestId: string; mediaItem: FixTypeLater }) => {
      logger.info('ComposeBox:onDuplicateComposeBoxItem');

      const { requestId, mediaItem } = data;

      const guid = generateGuid();
      const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
      const mediaId = MediaItem.getMediaId({ mediaItem });
      dispatch(
        addPreview({
          preview: {
            guid,
            mediaId,
            mediaItem: Immutable(mediaItem),
            accountAPIId,
            isHidden: false,
            isShared: false,
          },
        }),
      );
      logger.info(
        `PubSub: publish ${RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM}.${requestId} in pages/Master.onAddComposeBoxItem`,
      );
      PubSub.publish(
        `${RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM}.${requestId}`,
        guid,
      );
    },
    [dispatch],
  );

  const deleteAdditionalSocialPageId = useCallback(
    ({ guid, accountAPIId }: { guid: string; accountAPIId: number }) => {
      logger.info(`ComposeBox:deleteComposeBoxItem ${guid}`);

      const index = getMediaItemIndex({ guid });
      if (index > -1) {
        let mediaItem = findMediaItem({ guid });
        const additionalSocialPages = MediaItem.getAdditionalSocialPages({
          mediaItem,
        });
        additionalSocialPages.splice(
          additionalSocialPages.indexOf(accountAPIId),
        );
        mediaItem = MediaItem.setAdditionalSocialPages({
          mediaItem,
          fieldValue: additionalSocialPages,
        });
        dispatch(updateMediaItemByGuid({ guid, mediaItem }));
      }
    },
    [dispatch, findMediaItem, getMediaItemIndex],
  );

  const onSaveComposeBox = useCallback(
    (
      message: string,
      data: {
        guid: string;
        currentAccountAPIId: number;
        additionalSocialPages: number[];
        accountAPIId: number;
        actionType: ActionType;
      },
    ) => {
      const {
        guid,
        currentAccountAPIId,
        additionalSocialPages,
        accountAPIId,
        actionType,
      } = data;

      dispatch(finishSavingMediaItem({ guid }));

      // If the user is saving (or reverting) a post, complete the metric tracking
      const actionTypesToTrack: ActionType[] = [
        ACTION_TYPES.SAVE,
        ACTION_TYPES.UNSCHEDULE,
      ];
      if (actionTypesToTrack.includes(actionType)) {
        metrics.measure(FRONTEND_METRICS.COMPOSE_BOX_SAVE_DRAFT);
      }

      if (additionalSocialPages.indexOf(currentAccountAPIId) > -1) {
        // Remove the additional social page from the media item

        deleteAdditionalSocialPageId({
          guid,
          accountAPIId: currentAccountAPIId,
        });

        additionalSocialPages.splice(
          additionalSocialPages.indexOf(currentAccountAPIId),
        );
      } else if (
        guid &&
        currentAccountAPIId === accountAPIId &&
        isComposeBoxOpen
      ) {
        deleteComposeBoxItem({
          guid,
        });
      }
    },
    [
      dispatch,
      deleteAdditionalSocialPageId,
      deleteComposeBoxItem,
      isComposeBoxOpen,
    ],
  );

  /**
   * Updates the media items in `context.postPreviews` for multiple media item fields.
   *
   * NOTE: A `fieldUpdates` item with name `isChanged` will be added by default, if it isn't already present.
   */
  const applyFieldUpdatesForGuid = useCallback(
    ({
      fieldUpdates,
      guid,
    }: {
      fieldUpdates: {
        fieldName: string;
        fieldValue: FixTypeLater;
      }[];
      guid?: string;
    }) => {
      if (
        !fieldUpdates.some(
          (fieldUpdate) => fieldUpdate.fieldName === 'isChanged',
        )
      ) {
        fieldUpdates.push({
          fieldName: 'isChanged',
          fieldValue: true,
        });
      }

      if (guid == null) {
        dispatch(applyFieldUpdatesForAllGuids({ fieldUpdates }));
      } else {
        dispatch(applyFieldUpdatesForGuidDispatch({ fieldUpdates, guid }));
      }
    },
    [dispatch],
  );

  /**
   * Updates the media item in `context.postPreviews` for one media item field.
   */
  const applySingleFieldUpdateForGuid = useCallback(
    ({
      fieldName,
      fieldValue,
      guid,
    }: {
      fieldName: string;
      fieldValue: unknown;
      guid?: string;
    }) => {
      applyFieldUpdatesForGuid({
        fieldUpdates: [{ fieldName, fieldValue }],
        guid,
      });
    },
    [applyFieldUpdatesForGuid],
  );

  useEffect(() => {
    metrics.mark(FRONTEND_METRICS.OPEN_COMPOSE_BOX);
  }, []);

  // Log time taken for first post preview to load
  useEffect(() => {
    const mediaItems = postPreviews.map((entry) => entry.mediaItem);

    if (
      mediaItems.length === 1 &&
      !MediaItem.getIsLoading({ mediaItem: mediaItems[0] }) &&
      !hasLoggedMetricsRef.current
    ) {
      logger.track({
        event: 'Post Preview Load Metrics',
        properties: {
          loadTimeMS: Date.now() - timeMountedRef.current,
        },
      });
      hasLoggedMetricsRef.current = true;
      metrics.measure(FRONTEND_METRICS.OPEN_COMPOSE_BOX);
    }
  }, [postPreviews]);

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === KEYNAMES.ESCAPE) {
        logger.info(
          `ComposeBox:handleKeyUp - ESCAPE - isPopupSelectorOpen ${isPopupSelectorOpen}`,
        );
        if (!isPopupSelectorOpen) {
          closeComposeBox();
        }
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [closeComposeBox, isPopupSelectorOpen]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowUnload);
    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, []);

  useEffect(() => {
    const tokens = [
      PubSub.subscribe(MESSAGE_MEDIA_ITEM_ERROR, onMediaItemError),
      PubSub.subscribe(MESSAGE_SAVE_COMPOSE_BOX, onSaveComposeBox),
      PubSub.subscribe(REQUEST_DELETE_COMPOSE_BOX_ITEM, onDeleteComposeBoxItem),
      PubSub.subscribe(REQUEST_FIND_COMPOSE_BOX_ITEM, onFindComposeBoxItem),
      PubSub.subscribe(REQUEST_UPDATE_COMPOSE_BOX_ITEM, onUpdateComposeBoxItem),
      PubSub.subscribe(
        REQUEST_DUPLICATE_COMPOSE_BOX_ITEM,
        onDuplicateComposeBoxItem,
      ),
    ];

    return () => {
      tokens.forEach((token) => PubSub.unsubscribe(token));
    };
  }, [
    onDeleteComposeBoxItem,
    onDuplicateComposeBoxItem,
    onFindComposeBoxItem,
    onMediaItemError,
    onSaveComposeBox,
    onUpdateComposeBoxItem,
  ]);

  // Log when a schedule completes
  useEffect(() => {
    if (
      (areAllPostPreviewsClosed || areAllPostPreviewInErrorState) &&
      scheduleClickTime !== null
    ) {
      if (includesShareNowPost) {
        if (!isInstantShareNowEnabled) {
          metrics.measure(FRONTEND_METRICS.SHARE_NOW);
        }
      } else {
        metrics.measure(FRONTEND_METRICS.COMPOSE_BOX_SCHEDULE_POST);
      }
      logger.track({
        event: 'ComposeBox Schedule Metrics',
        properties: {
          numberOfSocialPagesSelected: numberOfPostPreviews,
          scheduleTimeMS: Date.now() - scheduleClickTime,
          areAllSchedulesSuccessful: areAllPostPreviewsClosed,
          includesShareNowPost,
        },
      });
      resetScheduleMetrics();
    }
  }, [
    areAllPostPreviewInErrorState,
    areAllPostPreviewsClosed,
    currentUserEmailAddress,
    numberOfPostPreviews,
    resetScheduleMetrics,
    includesShareNowPost,
    scheduleClickTime,
    isInstantShareNowEnabled,
  ]);

  useEffect(() => {
    if (areAllPostPreviewsClosed) {
      closeComposeBox();
    }
  }, [areAllPostPreviewsClosed, closeComposeBox]);

  useEffect(() => {
    return () => {
      setModalActive(false);
    };
  }, []);

  if (areAllPostPreviewsClosed) return null;

  const mediaItems = postPreviews.map((entry) => entry.mediaItem);

  const { mediaItem } =
    postPreviews.find(({ isHidden }) => !isHidden) ?? postPreviews[0];
  const mediaId = MediaItem.getMediaId({ mediaItem });

  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });

  // Render loading version if there is only one media item and it is loading
  const showLoading =
    mediaItems.length === 1 && MediaItem.getIsLoading({ mediaItem });
  if (showLoading) {
    return (
      <ComposeLoading
        accountAPIId={accountAPIId}
        guid={postPreviews[0].guid}
        mediaId={mediaId}
      />
    );
  }

  const articleTitle = MediaItem.getRssTitle({ mediaItem });
  const mostRecentScore = MediaItem.getMostRecentScore({
    mediaItem,
  });
  const publishedTime = MediaItem.getMostRecentUnixTimePublished({
    mediaItem,
  });
  const currentlySelectedTab = isMobileWindowWidth
    ? selectedTab
    : COMPOSE_BOX_TABS.PREVIEW;
  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getPropertyIdForAccountAPIId({ accountAPIId }),
  });
  const twelveHourFormat = twelveHourFormatSetting.enabled ?? false;
  const unshortenedShareURL = MediaItem.getUnshortenedShareURL({ mediaItem });

  const isApproval =
    MEDIA_ITEM_STATES.APPROVAL_REQUIRED === MediaItem.getState({ mediaItem });

  const isInstantImage = Boolean(
    MediaItem.getInstantImageArticleUrl({ mediaItem }),
  );

  setModalActive(true);

  return (
    <ComposeBoxModal>
      <div className="d-flex flex-column-reverse w-100 finish flex-md-row finish align-items-center bg-light-blue">
        {!isApproval && <SocialPages />}
        <Actions onSavingStateSet={onSavingStateSet} />
      </div>
      {initialPostType === POST_TYPES.LINK && !isInstantImage && (
        <div className="d-md-flex bg-light-blue w-100 finish flex-md-row finish align-items-center">
          <PostDetails
            articleTitle={articleTitle}
            articleURL={unshortenedShareURL}
            mostRecentScore={mostRecentScore}
            publishedTime={publishedTime}
            twelveHourFormat={twelveHourFormat}
          />
          <TimeSensitivity
            mediaItem={mediaItem}
            articleURL={unshortenedShareURL}
            onTimeSensitivityChange={(timeSensitivityTypeId) => {
              applySingleFieldUpdateForGuid({
                fieldName: 'timeSensitivityTypeId',
                fieldValue: timeSensitivityTypeId,
              });
            }}
            postType={initialPostType}
            publishedTime={publishedTime}
            twelveHourFormat={twelveHourFormat}
            isMobile={isMobileWindowWidth}
          />
        </div>
      )}
      <MobileMenu />
      <Suspense
        fallback={
          <Box w="full" pt={4} px={4} pb={0}>
            <PreviewLoading
              accountAPIId={accountAPIId}
              guid={postPreviews[0].guid}
            />
          </Box>
        }
      >
        <Previews
          postPreviews={postPreviews}
          previewCollapsed={passedCrossPostLimit}
          selectedTab={currentlySelectedTab}
          applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
          deleteComposeBoxItem={deleteComposeBoxItem}
        />
      </Suspense>
    </ComposeBoxModal>
  );
};

const ComposeBoxWrapper = (props: ComposeBoxProps) => (
  <ComposeBoxProvider>
    <ComposeBoxErrorBoundary>
      <ComposeBox {...props} />
    </ComposeBoxErrorBoundary>
  </ComposeBoxProvider>
);

export default ComposeBoxWrapper;
