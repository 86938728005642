/* eslint-disable react/no-unused-class-component-methods */
/* eslint no-underscore-dangle: ["error", { allow: ['_bind', '_compare', '_checkLastUpdate', '_trackLastUpdate']}] */
/* eslint react/prop-types: ["error", { ignore: ["global"] }] */

import isEqual from 'fast-deep-equal';
import { Component } from 'react';
import Immutable from 'seamless-immutable';

import { COMPONENT_STABLE_AFTER_DURATION } from 'common/config';
import { GLOBAL_INFO_CHANGES } from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isDefined, isUndefined } from 'common/utility';

/*
 * BaseComponent provides common methods to all other components
 */

export default class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.lastUpdateTimes = {};
  }

  /**
   * asyncSetState - asynchronous set state method
   */

  asyncSetState(state, callback) {
    return new Promise((resolve) => {
      super.setState(state, async () => {
        if (callback) {
          await callback();
        }
        resolve();
      });
    });
  }

  /**
   * _bind - binds the specified list of callback methods to the instance rather than the prototype
   */

  _bind(...methods) {
    methods.forEach((method) => {
      if (!isDefined(this[method])) {
        throw new ReferenceError(
          `Attempted to bind non-existent method ${method}`,
        );
      }
      this[method] = this[method].bind(this);
    });
  }

  /**
   * _compare - compares props and/or state using deep-equals comparison
   */

  _compare(name, args) {
    if (isUndefined(args.nextProps) && isUndefined(args.nextState)) {
      throw new ReferenceError(
        'Attempted to compare without specifying either props or state',
      );
    }

    if (isDefined(args.globalInfo)) {
      const prev = this.props.global.globalInfoState;
      const next = args.nextProps.global.globalInfoState;
      let hasChangedState;
      switch (args.globalInfo) {
        case GLOBAL_INFO_CHANGES.ANY_STATE_CHANGE:
          hasChangedState = this.props.global.hasChangedState(prev, next);
          break;
        case GLOBAL_INFO_CHANGES.LOADING_OR_SELECTING:
          hasChangedState =
            this.props.global.hasChangedLoading(prev, next) ||
            this.props.global.hasChangedSelecting(prev, next);
          break;
        case GLOBAL_INFO_CHANGES.LOADING_SELECTING_OR_UPDATING:
          hasChangedState =
            this.props.global.hasChangedLoading(prev, next) ||
            this.props.global.hasChangedSelecting(prev, next) ||
            this.props.global.hasChangedUpdating(prev, next);
          break;
        default:
      }
      if (hasChangedState) {
        logger.info(
          `${name}:shouldComponentUpdate - updating due to global info state change - prev ${prev} next ${next}`,
        );
        return hasChangedState;
      }
    }

    const compareProps = isDefined(args.nextProps);
    const compareState = isDefined(args.nextState);
    if (compareProps) {
      if (
        !isEqual(
          Immutable.asMutable(this.props ? this.props : {}, { deep: true }),
          Immutable.asMutable(args.nextProps ? args.nextProps : {}, {
            deep: true,
          }),
        )
      ) {
        // console.log(`${name}:shouldComponentUpdate - Re-rendering due to props change`);
        return true;
      }
    }
    if (compareState) {
      if (
        !isEqual(
          Immutable.asMutable(this.state ? this.state : {}, { deep: true }),
          Immutable.asMutable(args.nextState ? args.nextState : {}, {
            deep: true,
          }),
        )
      ) {
        // console.log(`${name}:shouldComponentUpdate - Re-rendering due to state change`);
        return true;
      }
    }
    return false;
  }

  /**
   * _checkLastUpdate - checks to see if the component has "stabilised" i.e. has not been updated
   */

  _checkLastUpdate(name, timestamp) {
    if (this.lastUpdateTimes[name] === timestamp) {
      metrics.measure(name);
    }
  }

  /**
   * _trackLastUpdate - sets the last update time, duh
   */

  _trackLastUpdate(name) {
    const timestamp = getUnixTimestamp();
    this.lastUpdateTimes[name] = timestamp;
    setTimeout(() => {
      this._checkLastUpdate(name, timestamp);
    }, COMPONENT_STABLE_AFTER_DURATION);
  }
}
