import * as React from 'react';
import { useLocation } from 'react-router-dom';

import {
  ToastId,
  UseToastProps,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  getAllDisconnectedAccountAPIs,
  getAllTwitterAppDisconnectedAccountAPIs,
  getCurrentProperty,
  getCurrentPropertyId,
  getDisconnectReasonsByAccountAPI,
  getPropertyPermission,
  getUnseenDisconnectionNotifications,
  isTwitterAppDisconnected,
} from 'common/accountAPIs';
import {
  GLOBAL_INFO_STATES,
  HEALTH_MENU_ERROR_TYPES,
  HEALTH_MENU_TOAST_LOCALSTORAGE_KEY,
  PERMISSION_TYPES,
  RSS_STATE_NAMES,
} from 'common/constants';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  getSeenNotifications,
  updateSeenNotifications,
} from 'helpers/healthMenu';
import { useContentFeedUnreachableToastProps } from 'hooks/useContentFeedUnreachableToastProps';
import { useDefaultHealthMenuToastProps } from 'hooks/useDefaultHealthMenuToastProps';
import useDisconnectToastProps from 'hooks/useDisconnectToastProps';
import useHealthMenuLocalStorageCleanup from 'hooks/useHealthMenuLocalStorageCleanup';
import { useMultipleErrorToastProps } from 'hooks/useMultipleErrorToastProps';
import { useTwitterDisconnectToastProps } from 'hooks/useTwitterDisconnectToastProps';
import { useIsComposeBoxOpen } from 'state/composeBoxOpen';
import { useIsShowingHighPriorityErrorType } from 'state/highPriorityErrorType';
import { AccountFeed, SocialAPI } from 'types';

function useCloseToastOnPathnameChange({ close }: { close: () => void }) {
  const isComposeBoxOpen = useIsComposeBoxOpen();
  const { global } = useGlobalInfo();
  const { pathname } = useLocation();

  // We only want the close to trigger when pathname changes so we record the original pathname
  const initialPathnameRef = React.useRef(pathname);

  // This will trigger if the pathname changes while NewsFeed is still mounted (e.g. /share -> /archive)
  React.useEffect(() => {
    if (initialPathnameRef.current !== pathname) close();
  }, [close, pathname]);

  // This will trigger if NewsFeed unmounts (which happens when the pathname changes to a non-NewsFeed page)
  React.useEffect(() => {
    return close;
  }, [close]);

  // Although not a pathname change, other actions (opening compose box, switching properties) will also close the toast.
  React.useEffect(() => {
    if (
      isComposeBoxOpen ||
      global?.globalInfoState === GLOBAL_INFO_STATES.SELECTING_PROPERTY
    ) {
      close();
    }
  }, [close, global?.globalInfoState, isComposeBoxOpen]);
}

interface HealthMenuToastProps {
  children: JSX.Element;
  isInitialisingHighPriorityErrorState: boolean;
}

const HealthMenuToast = ({
  children,
  isInitialisingHighPriorityErrorState,
}: HealthMenuToastProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const currentPropertyId = getCurrentPropertyId({ globalInfo });
  const currentProperty = getCurrentProperty({ globalInfo });
  const propertyPermission = getPropertyPermission({
    propertyId: currentPropertyId,
    globalInfo,
  });
  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;
  const isShowingHighPriorityError = useIsShowingHighPriorityErrorType();

  // Firstly, we get all seen notifications from localStorage
  const seenNotifications = getSeenNotifications(
    HEALTH_MENU_TOAST_LOCALSTORAGE_KEY,
  );

  const disconnectedTwitterPages = getAllTwitterAppDisconnectedAccountAPIs();
  const hasDisconnectedTwitterApp =
    isTwitterAppDisconnected() && disconnectedTwitterPages.length > 0;
  let disconnectedAccountAPIs: SocialAPI[];

  if (hasDisconnectedTwitterApp) {
    const disconnectedTwitterAppIds = disconnectedTwitterPages.map(
      (page) => page.accountAPIId,
    );

    disconnectedAccountAPIs = getAllDisconnectedAccountAPIs().filter(
      (page) => !disconnectedTwitterAppIds.includes(page.accountAPIId),
    );
  } else {
    disconnectedAccountAPIs = getAllDisconnectedAccountAPIs();
  }

  // Get all disconnected account APIs and filter out the ones that have already been seen
  const unseenDisconnectionNotifications = getUnseenDisconnectionNotifications(
    disconnectedAccountAPIs,
    seenNotifications,
  );
  const hasUnseenDisconnectionNotifications =
    unseenDisconnectionNotifications.length > 0;

  // Then we determine if there are any disconnected Twitter apps that have not been seen
  const hasUnseenTwitterAppNotifications =
    !(HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION in seenNotifications) &&
    hasDisconnectedTwitterApp;

  // Check for RSS feeds not returning data
  const contentFeedErrors: string[] = (
    (currentProperty.accountFeeds ?? []) as AccountFeed[]
  )
    .filter(
      (accountFeed) => accountFeed.syndFeedState === RSS_STATE_NAMES.ERROR,
    )
    .map((accountFeed) => accountFeed.syndFeedURL);
  const hasContentFeedErrors = contentFeedErrors.length > 0;
  const hasUnseenContentFeedErrors =
    !(HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR in seenNotifications) &&
    hasContentFeedErrors;

  let numberOfUnseenNotifications = unseenDisconnectionNotifications.length;

  if (hasUnseenTwitterAppNotifications) {
    numberOfUnseenNotifications += 1;
  }
  if (hasUnseenContentFeedErrors) {
    numberOfUnseenNotifications += 1;
  }

  const hasMultipleErrors =
    [
      unseenDisconnectionNotifications.length > 0,
      hasUnseenTwitterAppNotifications,
      hasUnseenContentFeedErrors,
    ].filter(Boolean).length > 1;

  // Setup the single toast and get all possible toast errors
  const toast = useToast();
  const toastIdRef = React.useRef<ToastId>('');
  const closeToast = React.useCallback(
    () => toast.close(toastIdRef.current),
    [toast],
  );

  const defaultToastProps = useDefaultHealthMenuToastProps({
    closeToast,
  });

  const multipleErrorsToastProps = useMultipleErrorToastProps({
    noOfErrors: numberOfUnseenNotifications,
  });
  const disconnectToastProps = useDisconnectToastProps({
    disconnectedAccountAPIs: unseenDisconnectionNotifications,
    closeToast,
  });
  const twitterAppToastProps = useTwitterDisconnectToastProps({
    disconnectedTwitterPages,
    closeToast,
  });
  const contentFeedUnreachableToastProps = useContentFeedUnreachableToastProps({
    feed: contentFeedErrors[0],
    closeToast,
  });

  // Determine whether to show a toast, and what properties to use.
  let toastProps: UseToastProps | null = null;
  if (hasMultipleErrors) {
    toastProps = multipleErrorsToastProps;
  } else if (hasUnseenTwitterAppNotifications) {
    toastProps = twitterAppToastProps;
  } else if (hasUnseenDisconnectionNotifications) {
    toastProps = disconnectToastProps;
  } else if (hasUnseenContentFeedErrors) {
    toastProps = contentFeedUnreachableToastProps;
  }

  React.useEffect(() => {
    if (
      isAdmin &&
      toastProps &&
      !isShowingHighPriorityError &&
      !isInitialisingHighPriorityErrorState
    ) {
      toastIdRef.current = toast({
        ...defaultToastProps,
        ...toastProps,
      });

      const disconnectReasonsByAccountAPIIds = getDisconnectReasonsByAccountAPI(
        disconnectedAccountAPIs,
      );

      updateSeenNotifications(
        {
          [HEALTH_MENU_ERROR_TYPES.DISCONNECTIONS]:
            disconnectReasonsByAccountAPIIds,
          ...(hasDisconnectedTwitterApp && {
            [HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION]: true,
          }),
          ...(hasContentFeedErrors && {
            [HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR]: true,
          }),
        },
        HEALTH_MENU_TOAST_LOCALSTORAGE_KEY,
      );
    }
  }, [
    defaultToastProps,
    disconnectedAccountAPIs,
    hasContentFeedErrors,
    hasDisconnectedTwitterApp,
    isAdmin,
    isInitialisingHighPriorityErrorState,
    isShowingHighPriorityError,
    toast,
    toastProps,
  ]);

  useHealthMenuLocalStorageCleanup({
    disconnectedAccountAPIs,
    localStorageKey: HEALTH_MENU_TOAST_LOCALSTORAGE_KEY,
    hasDisconnectedTwitterApp,
    hasContentFeedErrors,
  });

  useCloseToastOnPathnameChange({ close: closeToast });

  return children;
};

export default HealthMenuToast;
