const Error = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21344 1.44479C6.56877 0.851736 7.43122 0.851736 7.78477 1.44479L13.8702 11.6235C14.2326 12.2304 13.7934 13 13.0845 13H0.915547C0.206657 13 -0.232601 12.2304 0.129876 11.6235L6.21344 1.44479ZM6.19201 4.03034H7.8062V9.49013H6.19201V4.03034ZM7.8062 10.2701H6.19201V11.8301H7.8062V10.2701Z"
      fill="#CD3D64"
    />
  </svg>
);

export default Error;
