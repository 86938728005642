import { getCurrentPropertyId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import { createIsKeyInObjectFn } from 'types/utilities';

const HIDE_API_TYPE_FOR_PROPERTY_IDS = {
  [API_TYPE_IDS.INSTAGRAM]: [12611],
};

const hasHideAPITypeForPropertyIdsValues = createIsKeyInObjectFn(
  HIDE_API_TYPE_FOR_PROPERTY_IDS,
);

function isShowingAPIType(apiTypeId: number) {
  const currentPropertyId = Number(getCurrentPropertyId());
  return (
    !hasHideAPITypeForPropertyIdsValues(apiTypeId) ||
    !HIDE_API_TYPE_FOR_PROPERTY_IDS[apiTypeId].includes(currentPropertyId)
  );
}

const PREVENT_CREATE_PROPERTY_IDS = [12611];

function canCreateProperty() {
  const currentPropertyId = Number(getCurrentPropertyId());
  return !PREVENT_CREATE_PROPERTY_IDS.includes(currentPropertyId);
}

export { canCreateProperty, isShowingAPIType };
