import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';

interface VideoEditorProps {
  children: React.ReactNode;
}
const InstantVideoEditor = ({ children }: VideoEditorProps) => {
  return (
    <Flex flexGrow={1} flexDir="column" h="full" bg="gray.100" p={5} gap={3}>
      {children}
    </Flex>
  );
};

export default InstantVideoEditor;
