import { REACT_PREVENT_RENDER } from 'common/constants';

interface LoaderProps {
  className: string;
  containerType?: string;
  colSpan?: number;
}

/*
 * Loader
 */

const Loader = ({
  className,
  colSpan = 0,
  containerType = 'div',
}: LoaderProps) => {
  const LoaderInner = (
    <div className="loader-inner ball-scale-ripple-multiple">
      <div />
      <div />
      <div />
    </div>
  );
  // Render as div
  if (containerType === 'div') {
    return <div className={`loader loader_${className}`}>{LoaderInner}</div>;
  }
  // Render as table row
  if (containerType === 'tr') {
    return (
      <tr className={`loader loader_${className}`}>
        <td colSpan={colSpan}>{LoaderInner}</td>
      </tr>
    );
  }
  // Render as table cell
  if (containerType === 'tr') {
    return (
      <td className={`loader loader_${className}`} colSpan={colSpan}>
        {LoaderInner}
      </td>
    );
  }

  // No container type specified
  return REACT_PREVENT_RENDER;
};

export default Loader;
