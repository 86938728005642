import PropTypes from 'prop-types';

import { COLLECTION_NAMES } from 'common/constants';
import * as tracker from 'common/tracker';
import Button from 'components/misc/Button';

const Sort = (props) => {
  const { displayOptions, eventHandlers } = props;

  const getSortDescription = (sortBy, sortOrder) => {
    switch (sortBy) {
      case 'DATE_PUBLISHED':
        return `Date last published ${
          sortOrder === 'DESC' ? '(newest first)' : '(oldest first)'
        }`;
      case 'DATE_SHARED':
        return `Date last shared ${
          sortOrder === 'DESC' ? '(newest first)' : '(oldest first)'
        }`;
      case 'POTENTIAL':
        return `Score ${
          sortOrder === 'DESC' ? '(highest first)' : '(lowest first)'
        }`;
      default:
        return 'Undefined';
    }
  };

  const handleSortChange = (sortBy, sortOrder) => {
    const previousSort = getSortDescription(
      displayOptions.sortBy,
      displayOptions.sortOrder,
    );
    const nextSort = getSortDescription(sortBy, sortOrder);

    tracker.track({
      eventName: 'Update Archive Sort',
      trackingParams: {
        'Previous Sort Method': previousSort,
        'Sort Method': nextSort,
      },
    });
    eventHandlers.handleSortChange({
      collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
      sortBy,
      sortSequence: sortOrder,
    });
  };

  const isActiveFilter = (sortBy, sortOrder) => {
    return displayOptions.sortBy === sortBy &&
      displayOptions.sortOrder === sortOrder
      ? 'filter-active'
      : '';
  };

  return (
    <div className="dropdown my-1 position-static">
      <Button
        className="filter dropdown-md mr-2 analytics-filter"
        id="sortDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-boundary="viewport"
        data-cy-id="sortFilter"
      >
        Sort
      </Button>
      <div
        className="dropdown-menu"
        aria-labelledby="sortDropdown"
        style={{ minWidth: '200px' }}
      >
        <div className="dropdown-header">Last Published</div>
        <a
          className={`dropdown-item ${isActiveFilter(
            'DATE_PUBLISHED',
            'DESC',
          )}`}
          onClick={() => handleSortChange('DATE_PUBLISHED', 'DESC')}
          data-cy-id="datePublishedDesc"
        >
          Newest first
        </a>
        <a
          className={`dropdown-item ${isActiveFilter('DATE_PUBLISHED', 'ASC')}`}
          onClick={() => handleSortChange('DATE_PUBLISHED', 'ASC')}
          data-cy-id="datePublishedAsc"
        >
          Oldest first
        </a>
        <div className="dropdown-divider" />
        <div className="dropdown-header">Last Shared</div>
        <a
          className={`dropdown-item ${isActiveFilter('DATE_SHARED', 'DESC')}`}
          onClick={() => handleSortChange('DATE_SHARED', 'DESC')}
          data-cy-id="dateSharedDesc"
        >
          Newest first
        </a>
        <a
          className={`dropdown-item ${isActiveFilter('DATE_SHARED', 'ASC')}`}
          onClick={() => handleSortChange('DATE_SHARED', 'ASC')}
          data-cy-id="dateSharedAsc"
        >
          Oldest first
        </a>
        <div className="dropdown-divider" />
        <a
          className={`dropdown-item ${isActiveFilter('POTENTIAL', 'DESC')}`}
          onClick={() => handleSortChange('POTENTIAL', 'DESC')}
          data-cy-id="potentialDesc"
        >
          Score
        </a>
      </div>
    </div>
  );
};

Sort.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  eventHandlers: PropTypes.shape({
    handleSortChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default Sort;
