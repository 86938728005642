/* eslint no-use-before-define:"off" */

import { CONTENT_TYPES, CONTENT_TYPE_EDITED_IDS } from 'common/constants';
import {
  isDefined,
  isEmpty,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';

import { getMessages } from './gettersSetters';

export {
  addShareContent,
  addShareContentItem,
  addShareContentItemToOrderedShareContent,
  duplicateShareContent,
  findShareContentIndex,
  getCurrentShareContentIndex,
  getCurrentShareContentItem,
  getCurrentShareContentType,
  getCurrentShareContentTypeId,
  getSelectedTypeId,
};

/**
 * addShareContent - Adds a share content entry to a media item and make it the selected item
 *
 * @param {object} mediaItem - media item
 * @param {number} contentType - content type, see CONTENT_TYPE_IDS enum
 * @param {string} shareContentValue - the share content text itself
 * @return {object} - updated media item
 */

function addShareContent({
  mediaItem = mandatory('mediaItem'),
  contentType = mandatory('contentType'),
  shareContentValue = mandatory('shareContentValue'),
} = {}) {
  if (!validateMediaItemMessages({ mediaItem })) {
    throw new ReferenceError('Missing share content messages');
  }
  const messages = getMessages({ mediaItem });
  if (isUndefined(messages.orderedShareContent)) {
    throw new ReferenceError('Missing orderedShareContent');
  }
  const shareContentItem = {
    contentTypeId: contentType.editedId,
    description: CONTENT_TYPES[contentType.editedId].description,
    value: shareContentValue,
  };
  const newOrderedShareContent = addShareContentItemToOrderedShareContent({
    orderedShareContent: messages.orderedShareContent,
    shareContent: shareContentItem,
  });
  const variationPath = ['frontend'];
  return mediaItem
    .setIn(
      variationPath.concat(['messages', 'orderedShareContent']),
      newOrderedShareContent,
    )
    .setIn(
      variationPath.concat(['messages', 'selectedContentIndex']),
      newOrderedShareContent.length - 1,
    )
    .setIn(
      variationPath.concat(['messages', 'selectedContentTypeId']),
      contentType.editedId,
    )
    .setIn(
      variationPath.concat(['messages', 'selectedShareContent']),
      shareContentItem,
    );
}

/**
 * addShareContentItem - Push the share content item to the share content and ensure it is selected
 */

function addShareContentItem({
  shareContentTo = mandatory('shareContentTo'),
  shareContentItem = mandatory('shareContentItem'),
} = {}) {
  let shareContentItemCopy = shareContentTo;
  if (isEmpty(shareContentItemCopy)) {
    shareContentItemCopy = {
      orderedShareContent: [],
    };
  }
  shareContentItemCopy.orderedShareContent.push({
    contentTypeId: shareContentItem.contentTypeId,
    value: shareContentItem.value,
  });
  shareContentItemCopy.selectedContentIndex =
    shareContentItemCopy.orderedShareContent.length - 1;
  shareContentItemCopy.selectedContentTypeId = shareContentItem.contentTypeId;
  return shareContentItemCopy;
}

/**
 * addShareContentItemToOrderedShareContent - Add share content item to the order share contend only
 */

function addShareContentItemToOrderedShareContent({
  orderedShareContent = mandatory('orderedShareContent'),
  shareContent = mandatory('shareContent'),
} = {}) {
  return orderedShareContent
    .filter((sc) => !CONTENT_TYPE_EDITED_IDS.includes(sc.contentTypeId))
    .concat([shareContent]);
}

/**
 * duplicateShareContent - Duplicate the share content from the media item, to the provided share content
 */

function duplicateShareContent({
  mediaItem = mandatory('mediaItem'),
  shareContentTo = {},
} = {}) {
  if (!validateMediaItemMessages({ mediaItem })) {
    throw new ReferenceError('Missing share content messages');
  }
  let shareContentToCopy = shareContentTo;
  if (isEmpty(shareContentToCopy)) {
    shareContentToCopy = {
      orderedShareContent: [],
    };
  }
  const shareContentFrom = getMessages({ mediaItem });
  shareContentFrom.orderedShareContent.forEach((shareContent) => {
    if (CONTENT_TYPES[shareContent.contentTypeId].isEdited) {
      // Add the edited share content
      const shareContentToAdd = {
        contentTypeId: shareContent.contentTypeId,
        value: shareContent.value,
      };
      shareContentToCopy.orderedShareContent =
        addShareContentItemToOrderedShareContent({
          orderedShareContent: shareContentToCopy.orderedShareContent,
          shareContent: shareContentToAdd,
        });
      shareContentToCopy.selectedContentIndex =
        shareContentTo.orderedShareContent.length - 1;
      shareContentToCopy.selectedContentTypeId = shareContent.contentTypeId;
      shareContentToCopy.selectedShareContent = shareContentToAdd;
    }
  });
  return shareContentToCopy;
}

/**
 * findShareContent - Find share content index
 * @param {{
 *  messages?: import('types').ShareContentResult | null
 *  contentTypeId: number;
 * }}
 */
function findShareContentIndex({
  messages = mandatory('messages'),
  contentTypeId = mandatory('contentTypeId'),
} = {}) {
  if (!isNullOrUndefined(messages) && isDefined(messages.orderedShareContent)) {
    return messages.orderedShareContent.findIndex(
      (x) => x.contentTypeId === contentTypeId,
    );
  }
  throw new Error('Content Type Id not found in messages');
}

function getCurrentShareContentField({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
  fieldName = mandatory('fieldName'),
} = {}) {
  if (validateMediaItemMessages({ mediaItem })) {
    const messages = getMessages({ mediaItem });

    switch (fieldName) {
      case 'item': {
        if (isUndefined(messages.orderedShareContent)) {
          return null;
        }
        const index = getCurrentShareContentIndex({ mediaItem, accountAPIId });
        if (isNull(index)) {
          return null;
        }
        return messages.orderedShareContent[index];
      }

      case 'index': {
        // Return selectedContentIndex if defined
        if (isDefined(messages.selectedContentIndex)) {
          return messages.selectedContentIndex;
        }
        // Otherwise search for item which matches selectedContentTypeId
        if (isUndefined(messages.selectedContentTypeId)) {
          return null;
        }
        for (let idx = 0; idx < messages.orderedShareContent.length; idx += 1) {
          if (
            messages.orderedShareContent[idx].contentTypeId ===
            messages.selectedContentTypeId
          ) {
            return idx;
          }
        }
        // No selected content exists
        return null;
      }

      case 'type': {
        if (isUndefined(messages.selectedContentTypeId)) {
          return null;
        }
        const contentType = CONTENT_TYPES[messages.selectedContentTypeId];
        return {
          contentTypeId: messages.selectedContentTypeId,
          sourceId: contentType.sourceId,
          isEdited: contentType.isEdited,
          editedId: contentType.editedId,
        };
      }

      case 'typeId': {
        if (isUndefined(messages.selectedContentTypeId)) {
          return null;
        }
        return messages.selectedContentTypeId;
      }

      default:
        return null;
    }
  }
  return null;
}

/**
 * getCurrentShareContentItem - returns the currently-selected share message details
 *
 * @param {object} mediaItem - media item
 * @return {object} - currently-selected share content item, or null if one does not exist
 *
 * Example response:
 * {
 *   contentId: "58c116fd29bb712e3bb43698",
 *   contentTypeId: 1,
 *   sequence: 1489049342,
 *   unixTimeUpdated: 1489049341,
 *   value: "Some text"
 * }
 */

function getCurrentShareContentItem({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  return getCurrentShareContentField({
    mediaItem,
    accountAPIId,
    fieldName: 'item',
  });
}

/**
 * getCurrentShareContentIndex - returns the index for the currently-selected share message
 *
 * @param {object} mediaItem - media item
 * @return {integer} - index of currently-selected content item, or null if one does not exist
 */

function getCurrentShareContentIndex({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  return getCurrentShareContentField({
    mediaItem,
    accountAPIId,
    fieldName: 'index',
  });
}

/**
 * getCurrentShareContentTypeId - returns the content id for the currently-selected share message
 *
 * @param {object} mediaItem - media item
 * @return {number} - currently-selected content id, or null if one does not exist
 */

function getCurrentShareContentTypeId({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  return getCurrentShareContentField({
    mediaItem,
    accountAPIId,
    fieldName: 'typeId',
  });
}

/**
 * getCurrentShareContentType - returns content type details for the currently-selected message
 *
 * @param {object} mediaItem - mediaItem
 * @return {object} - content type details
 *
 * Example response:
 * {
 *   contentTypeId: 1,
 *   sourceId: 1,
 *   isEdited: false
 * }
 */

function getCurrentShareContentType({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  return getCurrentShareContentField({
    mediaItem,
    accountAPIId,
    fieldName: 'type',
  });
}

/**
 * getSelectedTypeId - get the selected type id of the selected content index
 * @returns {keyof typeof CONTENT_TYPES | null}
 */
function getSelectedTypeId({ messages } = {}) {
  if (
    !isNullOrUndefined(messages) &&
    isDefined(messages.orderedShareContent) &&
    isDefined(messages.selectedContentIndex)
  ) {
    const contentIndex = messages.selectedContentIndex;
    return messages.orderedShareContent[contentIndex].contentTypeId;
  }
  return null;
}

/**
 * validateMediaItemMessages - confirms whether the media item contains share messages for the specified account API
 * @param {object} mediaItem    [description]
 * @return {boolean}              [description]
 */
function validateMediaItemMessages({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  return (
    isDefined(mediaItem.frontend) && isDefined(mediaItem.frontend.messages)
  );
}
