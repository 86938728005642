// import PubSub from 'pubsub-js';

import * as logger from 'common/logger';
import * as topics from 'pubsub/topics';

const { REQUEST_MEDIA_ITEM_SAVE } = topics;

export function SAVE(args: { mediaId: string }) {
  logger.info(`${REQUEST_MEDIA_ITEM_SAVE}: ${args.mediaId}`);
}
