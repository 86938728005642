/**
 * GET /apis/{accountAPIId} - retrieve account api details
 *
 * @param {integer} accountAPIId - account to get settings for
 * @return {object} - account api details
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { API_STATES, API_TYPE_IDS } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { SocialAPIResponseSchema } from 'common/schemas';
import { getSocialNetworkDefaultPageIcon } from 'common/social';
import { generateGuid } from 'common/string';
import { extractAccountAPIId, extractPropertyId } from 'common/urn';
import { lyingParse } from 'common/zod';
import type { SocialAPI } from 'types';

const BATCH_SIZE = 100;

const ResponseSchema = z
  .object({
    apis: z.array(SocialAPIResponseSchema),
  })
  .describe('getAPIs.ResponseSchema');

export default async function getAPIs({
  socialAPIURNs,
}: {
  socialAPIURNs: Array<string>;
}) {
  const guid = metrics.start('getAPIs');

  const processChunk = async (chunk: string[]) => {
    const config: AxiosRequestConfig = {
      url: `${constructBaseSocialAPIURL(
        'v3.2',
      )}/social/api/${chunk.toString()}`,
      method: 'GET',
      timeout: API_TIMEOUTS.S,
      headers: getClientServiceRequestHeaders(),
    };
    logger.info(`API:getAPIs /social/api/${chunk.toString()}`);

    try {
      const responses = await axios(config);
      const { apis } = lyingParse(ResponseSchema, responses.data);
      return apis;
    } catch (error) {
      metrics.fail('getAPIs', guid);
      throw await handleAPIError({
        originalError: error,
        errorLocation: 'api/getAPIs',
        args: { socialAPIURNs: chunk },
      });
    }
  };

  let apis: Awaited<ReturnType<typeof processChunk>>;
  if (socialAPIURNs.length > BATCH_SIZE) {
    const chunks = [];
    while (socialAPIURNs.length) {
      chunks.push(socialAPIURNs.splice(0, BATCH_SIZE));
    }
    const responses = await Promise.all(chunks.map(processChunk));
    apis = responses.reduce((prev, curr) => [...prev, ...curr], []);
  } else {
    apis = await processChunk(socialAPIURNs);
  }

  // Convert response to an object containing one response object per account
  const response = apis.reduce<Record<number, SocialAPI>>((prev, entry) => {
    const socialAPIURN = entry.socialAPIURN;
    const accountAPIId = extractAccountAPIId(socialAPIURN);

    const apiTypeId = API_TYPE_IDS[entry.apiType];

    const defaultPageIcon = getSocialNetworkDefaultPageIcon({
      apiTypeId,
    });
    const apiPostImage = entry.requiresReconnect
      ? defaultPageIcon
      : entry.apiPostImage ?? defaultPageIcon;

    const notifications = entry.requiresReconnect
      ? [
          {
            id: generateGuid(),
            type: 'error',
            message: 'Requires reconnect',
            link: '/settings',
          },
        ]
      : [];

    // Use alt name if post name is not specified
    const apiPostName = entry.apiPostName ?? entry.apiAltName;

    const isSharingPaused = entry.isSharingPaused ?? false;

    return {
      ...prev,
      [accountAPIId]: {
        ...entry,
        accountAPIId,
        propertyId: extractPropertyId(entry.propertyURN),
        apiTypeId,
        apiStateId: API_STATES[entry.apiState],
        notifications,
        apiPostImage,
        apiPostName,
        isSharingPaused,
      },
    };
  }, {});

  metrics.end('getAPIs', guid);
  logger.debug('getAPIs', { socialAPIURNs });
  return response;
}
