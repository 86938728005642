const Plus = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="7" width="16" height="2" fill="#737B8B" />
    <rect
      x="7"
      y="16"
      width="16"
      height="2"
      transform="rotate(-90 7 16)"
      fill="#737B8B"
    />
  </svg>
);

export default Plus;
