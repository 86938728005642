import {
  Breadcrumb,
  SettingsNavbar,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { getAccountAPI, getCurrentPropertyId } from 'common/accountAPIs';
import { GLOBAL_INFO_STATES } from 'common/constants';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'common/featureFlags';
import BreadcrumbPageImage from 'components/settings/BreadcrumbPageImage';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { useSettingsContext } from 'context/SettingsContext';
import { FixTypeLater } from 'types';

const isTeamSettingsEnabled = () =>
  isFeatureFlagEnabled({
    flag: FEATURE_FLAGS.TEAM_SETTINGS,
  });

interface Crumb {
  /**
   * The link for this breadcrumb item
   */
  link: string;

  /**
   * The text to display for this breadcrumb
   */
  text: string;

  /**
   * The optional accountAPI object. Used for rendering an image and social logo
   */
  accountAPI?: FixTypeLater;
}

const ROUTES = [
  'ROOT',
  'PROPERTY',
  'AUTOMATION',
  'AUTOMATION_NEW_SHARES',
  'AUTOMATION_NEW_SHARES_DATA_SOURCES',
  'AUTOMATION_NEW_SHARES_KEYWORDS',
  'AUTOMATION_NEW_SHARES_PREFIX_SUFFIX',
  'AUTOMATION_RESHARES',
  'AUTOMATION_RESHARES_KEYWORDS',
  'AUTOMATION_RESHARES_PREFIX_SUFFIX',
  'AUTOMATION_SCHEDULE_PERIODS',
  'AUTOMATION_ADVANCED_SETTINGS',
  'PERFORMANCE_GOAL',
  'PAGES',
  'TEAM',
  'MY_DETAILS',
  'CONTENT_FEEDS',
  'TEAM_SETTINGS',
  'EDIT_PAGE',
  'URL_PARAMETERS',
  'AUDIENCE_RESTRICTION',
  'DEFAULT_SHARE_PREVIEW',
  'IMAGE_OVERLAY',
  'PAGE_NAME',
] as const;

const SocialSettingsNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accountAPIId } = useParams<{ accountAPIId?: string }>();
  const { actionButtonSaveText, actionButtonStatus, onSave } =
    useSettingsContext();

  const { global } = useContext(GlobalInfoContext);

  const CRUMBS: Record<(typeof ROUTES)[number], Crumb> = {
    ROOT: {
      link: '/settings',
      text: 'Settings',
    },
    PROPERTY: {
      link: '/settings/property',
      text: 'Property',
    },
    AUTOMATION: {
      link: '/settings/automation',
      text: 'Automation',
    },
    AUTOMATION_NEW_SHARES: {
      link: '/settings/automation/:accountAPIId/newshares',
      text: 'New shares',
    },
    AUTOMATION_NEW_SHARES_DATA_SOURCES: {
      link: '/settings/automation/:accountAPIId/newshares/datasources',
      text: 'Data sources',
    },
    AUTOMATION_NEW_SHARES_KEYWORDS: {
      link: '/settings/automation/:accountAPIId/newshares/keywords',
      text: 'Keywords',
    },
    AUTOMATION_NEW_SHARES_PREFIX_SUFFIX: {
      link: '/settings/automation/:accountAPIId/newshares/prefix-suffix',
      text: 'Prefix & Suffix',
    },
    AUTOMATION_RESHARES: {
      link: '/settings/automation/:accountAPIId/reshares',
      text: 'Reshares',
    },
    AUTOMATION_RESHARES_KEYWORDS: {
      link: '/settings/automation/:accountAPIId/reshares/keywords',
      text: 'Keywords',
    },
    AUTOMATION_RESHARES_PREFIX_SUFFIX: {
      link: '/settings/automation/:accountAPIId/reshares/prefix-suffix',
      text: 'Prefix & Suffix',
    },
    AUTOMATION_SCHEDULE_PERIODS: {
      link: '/settings/automation/:accountAPIId/schedule',
      text: 'Schedule periods',
    },
    AUTOMATION_ADVANCED_SETTINGS: {
      link: '/settings/automation/:accountAPIId/advanced',
      text: 'Advanced settings',
    },
    PERFORMANCE_GOAL: {
      link: '/settings/property/performancegoal',
      text: 'Performance goal',
    },
    PAGES: {
      link: '/settings/pages',
      text: 'Pages',
    },
    PAGE_NAME: {
      link: '',
      text: '',
    },
    TEAM: {
      link: '/settings/users',
      text: 'Team',
    },
    TEAM_SETTINGS: {
      link: '/settings/users',
      text: 'Team settings',
    },
    MY_DETAILS: {
      link: '/settings/user',
      text: 'My details',
    },
    CONTENT_FEEDS: {
      link: '/settings/content-feeds',
      text: 'Content feeds',
    },
    EDIT_PAGE: {
      link: '/settings/page/:accountAPIId',
      text: 'Edit Page',
    },
    URL_PARAMETERS: {
      link: '/settings/page/:accountAPIId/url-parameters',
      text: 'URL Parameters',
    },
    AUDIENCE_RESTRICTION: {
      link: '/settings/page/:accountAPIId/audience-restriction',
      text: 'Audience Restriction',
    },
    DEFAULT_SHARE_PREVIEW: {
      link: '/settings/page/:accountAPIId/default-share-preview',
      text: 'Default Share Preview',
    },
    IMAGE_OVERLAY: {
      link: '/settings/page/:accountAPIId/image-overlay',
      text: 'Image Overlay',
    },
  };

  const routes: Record<string, Crumb[]> = {
    '/settings/property/performancegoal': [
      CRUMBS.ROOT,
      CRUMBS.PROPERTY,
      CRUMBS.PERFORMANCE_GOAL,
    ],
    '/settings/property': [CRUMBS.ROOT, CRUMBS.PROPERTY],
    '/settings/automation': [CRUMBS.ROOT, CRUMBS.AUTOMATION],
    '/settings/automation/:accountAPIId': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
    ],
    '/settings/automation/:accountAPIId/newshares': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_NEW_SHARES,
    ],
    '/settings/automation/:accountAPIId/newshares/datasources': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_NEW_SHARES,
      CRUMBS.AUTOMATION_NEW_SHARES_DATA_SOURCES,
    ],
    '/settings/automation/:accountAPIId/newshares/keywords': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_NEW_SHARES,
      CRUMBS.AUTOMATION_NEW_SHARES_KEYWORDS,
    ],
    '/settings/automation/:accountAPIId/newshares/prefix-suffix': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_NEW_SHARES,
      CRUMBS.AUTOMATION_NEW_SHARES_PREFIX_SUFFIX,
    ],
    '/settings/automation/:accountAPIId/reshares': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_RESHARES,
    ],
    '/settings/automation/:accountAPIId/reshares/keywords': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_RESHARES,
      CRUMBS.AUTOMATION_RESHARES_KEYWORDS,
    ],
    '/settings/automation/:accountAPIId/reshares/prefix-suffix': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_RESHARES,
      CRUMBS.AUTOMATION_RESHARES_PREFIX_SUFFIX,
    ],
    '/settings/automation/:accountAPIId/schedule': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_SCHEDULE_PERIODS,
    ],
    '/settings/automation/:accountAPIId/advanced': [
      CRUMBS.ROOT,
      CRUMBS.AUTOMATION,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUTOMATION_ADVANCED_SETTINGS,
    ],
    '/settings/pages': [CRUMBS.ROOT, CRUMBS.PAGES],
    '/settings/page/:accountAPIId/url-parameters': [
      CRUMBS.ROOT,
      CRUMBS.PAGES,
      CRUMBS.PAGE_NAME,
      CRUMBS.URL_PARAMETERS,
    ],
    '/settings/page/:accountAPIId/audience-restriction': [
      CRUMBS.ROOT,
      CRUMBS.PAGES,
      CRUMBS.PAGE_NAME,
      CRUMBS.AUDIENCE_RESTRICTION,
    ],
    '/settings/page/:accountAPIId/default-share-preview': [
      CRUMBS.ROOT,
      CRUMBS.PAGES,
      CRUMBS.PAGE_NAME,
      CRUMBS.DEFAULT_SHARE_PREVIEW,
    ],
    '/settings/page/:accountAPIId/image-overlay': [
      CRUMBS.ROOT,
      CRUMBS.PAGES,
      CRUMBS.PAGE_NAME,
      CRUMBS.IMAGE_OVERLAY,
    ],
    '/settings/page/:accountAPIId': [
      CRUMBS.ROOT,
      CRUMBS.PAGES,
      CRUMBS.PAGE_NAME,
    ],
    '/settings/users': [CRUMBS.ROOT, CRUMBS.TEAM],
    '/settings/user': [CRUMBS.ROOT, CRUMBS.MY_DETAILS],
    '/settings/content-feeds': [CRUMBS.ROOT, CRUMBS.CONTENT_FEEDS],
  };

  if (isTeamSettingsEnabled())
    routes['/settings/users'] = [CRUMBS.ROOT, CRUMBS.TEAM_SETTINGS];

  let crumbs: Crumb[] = [];

  const match = Object.keys(routes).find((route) =>
    matchPath(
      {
        path: route,
      },
      pathname,
    ),
  );

  if (match) {
    // If the route has a dynamic `:accountAPIId` placeholder,
    // find the account and update the breadcrumb for the page name.
    if (accountAPIId) {
      const accountAPI = getAccountAPI({
        accountAPIId: parseInt(accountAPIId, 10),
      });
      if (accountAPI?.apiPostName) {
        const finalSegment = pathname.substring(pathname.lastIndexOf('/') + 1);
        const linkURL =
          finalSegment !== accountAPIId
            ? pathname.substring(
                0,
                pathname.lastIndexOf(`${accountAPIId}`) + accountAPIId.length,
              )
            : pathname;
        CRUMBS.PAGE_NAME.text = accountAPI?.apiPostName;
        CRUMBS.PAGE_NAME.link = linkURL;
        CRUMBS.PAGE_NAME.accountAPI = accountAPI;
      }
    }
    crumbs = routes[match];
  }

  const onClose = () => {
    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.SELECTING_PROPERTY,
      propertyId: getCurrentPropertyId(),
      allowUnderSetup: false,
      callback: () => {
        navigate('/share');
      },
    });
  };

  return (
    <SettingsNavbar
      actionButtonStatus={actionButtonStatus}
      actionButtonSaveText={actionButtonSaveText}
      onClose={onClose}
      onSave={onSave}
    >
      <Breadcrumb size="lg">
        {crumbs.map(({ link, text, accountAPI }) => {
          let newLink = link;
          if (link.includes(':accountAPIId') && accountAPIId) {
            newLink = link.replace(':accountAPIId', accountAPIId);
          }

          return (
            <Breadcrumb.Item key={`${link}-${text}`}>
              {accountAPI && (
                <BreadcrumbPageImage accountAPI={accountAPI} to={newLink} />
              )}
              <Breadcrumb.Link
                as={Link}
                to={newLink}
                isCurrentPage={pathname === newLink}
              >
                {text}
              </Breadcrumb.Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </SettingsNavbar>
  );
};

export default SocialSettingsNavbar;
