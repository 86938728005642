import {
  Box,
  Tooltip as EbxTooltip,
  IgPostIcon,
  Image,
  ImageIcon,
  NormalIcon,
  ReelIcon,
  StoryIcon,
  VideoIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import { getAPITypeId } from 'common/accountAPIs';
import {
  ACTION_HISTORY_TYPES,
  API_TYPE_IDS,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SHARE_TIME_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
  TIME_SENSITIVITY_TYPES,
} from 'common/constants';
import {
  getFormattedDateFromUnix,
  getFormattedTimeslotsFromUnix,
} from 'common/datetime';
import { isIGCabinetEnabled } from 'common/settings';
import { getShareIcon } from 'common/social';
import { isNull, isNullOrUndefined } from 'common/utility';
import Tooltip from 'components/misc/Tooltip';

/**
 * Timing badges
 */

const TimingBadges = (props) => {
  const {
    accountAPIId,
    actionTypeId,
    mediaStatesByAccountAPIId,
    hasImage,
    totalNumberOfShares,
    postType,
    shareTime,
    suggestionTypeId,
    timeSensitivityTypeId,
    twelveHourFormat,
    socialChannel,
    showPostType,
    showReshareCount,
    showReshare,
    showShareCount,
    showTimingOption,
    showTimeSensitivity,
    mediaId,
  } = props;

  const timingLabel = [];
  const isAnalyticsPageItem = props.origin === 'ANALYTICS_PAGE';
  const isItem = !isAnalyticsPageItem;
  let shouldIncludeTimeSensitivity = isItem;
  const place = isAnalyticsPageItem ? 'right' : 'top';
  const apiTypeId = getAPITypeId({ accountAPIId: props.accountAPIId });
  const shareIcon = getShareIcon({ apiTypeId, postType });
  const isInstagram = apiTypeId === API_TYPE_IDS.INSTAGRAM;

  /**
   * Icons for photo/status and video posts
   */

  if (showPostType) {
    const isInstagramGraphAPI =
      apiTypeId === API_TYPE_IDS.INSTAGRAM && !isIGCabinetEnabled();
    const isFacebook = apiTypeId === API_TYPE_IDS.FACEBOOK;
    const isShowingSocialChannel =
      socialChannel !== SOCIAL_CHANNELS.FEED &&
      (isInstagramGraphAPI || isFacebook);

    const guid = `timing-badge-post-type-${mediaId}`;
    if (isShowingSocialChannel) {
      if (socialChannel === SOCIAL_CHANNELS.REEL) {
        timingLabel.push(
          <EbxTooltip id={guid} label="Shared to Reels" placement="top-end">
            <Box
              as="span"
              bg="#D7DEE4"
              borderRadius="sm"
              p="3px"
              mb={1}
              mr={1}
              key={guid}
              data-tip
              data-id="reel-post"
              data-for={guid}
            >
              <ReelIcon />
            </Box>
          </EbxTooltip>,
        );
      } else if (socialChannel === SOCIAL_CHANNELS.STORY) {
        timingLabel.push(
          <EbxTooltip id={guid} label="Shared to Stories" placement="top-end">
            <Box
              as="span"
              bg="#D7DEE4"
              borderRadius="sm"
              p="3px"
              mb={1}
              mr={1}
              key={guid}
              data-tip
              data-id="reel-post"
              data-for={guid}
            >
              <StoryIcon />
            </Box>
          </EbxTooltip>,
        );
      } else {
        timingLabel.push(
          <EbxTooltip id={guid} label="Shared as a post" placement="top-end">
            <Box
              as="span"
              bg="#D7DEE4"
              borderRadius="sm"
              p="3px"
              mb={1}
              mr={1}
              key={guid}
              data-tip
              data-id="photo-post"
              data-for={guid}
            >
              {isInstagram ? <IgPostIcon /> : <NormalIcon />}
            </Box>
          </EbxTooltip>,
        );
      }
    } else if (postType === POST_TYPES.STATUS && hasImage !== false) {
      timingLabel.push(
        <EbxTooltip id={guid} label="Photo post" placement="top-end">
          <Box
            as="span"
            bg="#D7DEE4"
            borderRadius="sm"
            p="3px"
            mb={1}
            mr={1}
            key={guid}
            data-tip
            data-id="photo-post"
            data-for={guid}
          >
            <ImageIcon />
          </Box>
        </EbxTooltip>,
      );
    } else if (postType === POST_TYPES.VIDEO) {
      timingLabel.push(
        <EbxTooltip id={guid} label="Video post" placement="top-end">
          <Box
            as="span"
            bg="#D7DEE4"
            borderRadius="sm"
            p="3px"
            mb={1}
            mr={1}
            key={guid}
            data-tip
            data-id="photo-post"
            data-for={guid}
          >
            <VideoIcon />
          </Box>
        </EbxTooltip>,
      );
    } else if (postType === POST_TYPES.PHOTO_STORY) {
      timingLabel.push(
        <span key={guid}>
          <Tooltip id={guid} placement={place} label="Photo Story post">
            <Image
              src={shareIcon}
              alt=""
              mr={1}
              h="22px"
              p="3px"
              bg="#D7DEE4"
              mb={1}
              data-tip
              data-for={guid}
              data-iscapture="true"
            />
          </Tooltip>
        </span>,
      );
    }
  }

  /**
   * External shares
   */

  if (
    isAnalyticsPageItem &&
    actionTypeId !== ACTION_HISTORY_TYPES.ECHOBOX_SHARE
  ) {
    const guid = `timing-badge-external-share-${mediaId}`;
    timingLabel.push(
      <span
        className="share_tag not_shared align-items-baseline badge px-0"
        key={guid}
      >
        <img
          className="img_not_shared align-self-center"
          src="/img/icons/ic-notification.svg"
          alt=""
          style={{ minWidth: '12px' }}
        />
        <span>Not shared with Echobox</span>
      </span>,
    );
    return timingLabel;
  }

  /**
   * Share by autofeed
   */

  if (
    (isAnalyticsPageItem &&
      actionTypeId === ACTION_HISTORY_TYPES.ECHOBOX_SHARE) ||
    isItem
  ) {
    if (suggestionTypeId === SUGGESTION_TYPES.AUTO_SOCIAL_SHARE) {
      shouldIncludeTimeSensitivity = true;
      const guid = `timing-badge-autofeed-${mediaId}`;

      timingLabel.push(
        <span
          className="badge badge-autofeed"
          key={guid}
          data-tip
          data-for={guid}
          data-iscapture="true"
        >
          <Tooltip id={guid} key={guid} label="Article queued by the Autofeed">
            <>
              <img
                className="mr-1"
                src="/img/icons/ic-autofeed-w.svg"
                alt=""
                width="10px;"
              />
              Autofeed
            </>
          </Tooltip>
        </span>,
      );
    } else if (suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_AB_SHARE) {
      if (isAnalyticsPageItem) {
        shouldIncludeTimeSensitivity = true;
      }
      const guid = `timing-badge-ab-test-${mediaId}`;
      timingLabel.push(
        <span className="badge badge-ab-test" key={guid}>
          AB Test
        </span>,
      );
    } else if (
      isAnalyticsPageItem &&
      suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE
    ) {
      shouldIncludeTimeSensitivity = true;
    }
  }

  /**
   * Timing option
   */

  if (showTimingOption) {
    if (
      shareTime.type === SHARE_TIME_TYPES.OPTIMAL &&
      suggestionTypeId !== SUGGESTION_TYPES.AUTO_SOCIAL_SHARE
    ) {
      const optimalLabel = 'Optimal';
      const guid = `timing-badge-optimal-${mediaId}`;
      timingLabel.push(
        <span className="badge badge-optimal" key={guid}>
          {optimalLabel}
        </span>,
      );
    } else if (shareTime.type === SHARE_TIME_TYPES.TIMESLOT) {
      const guid = `timing-badge-timeslot-${mediaId}`;
      timingLabel.push(
        <span className="badge badge-timing" key={guid}>
          {getFormattedTimeslotsFromUnix({
            minTime: shareTime.min,
            maxTime: shareTime.max,
            twelveHourFormat,
          })}
        </span>,
      );
    } else if (shareTime.type === SHARE_TIME_TYPES.TIME) {
      const guid = `timing-badge-time-${mediaId}`;
      timingLabel.push(
        <span className="badge badge-timing" key={guid}>
          {getFormattedDateFromUnix({
            timestamp: shareTime.time,
            twelveHourFormat,
          })}
        </span>,
      );
    } else if (isAnalyticsPageItem && shareTime.type === SHARE_TIME_TYPES.NOW) {
      const guid = `timing-badge-now-${mediaId}`;
      timingLabel.push(
        <span className="badge badge-timing" key={guid}>
          Now
        </span>,
      );
    }
  }

  /**
   * Shares/reshares
   */

  if (showReshareCount && totalNumberOfShares > 1) {
    const guid = `timing-badge-reshares-${mediaId}`;
    timingLabel.push(
      <span className="badge badge-reshared" key={guid}>
        Reshare {`${totalNumberOfShares - 1}`}
      </span>,
    );
  }

  if (showShareCount && totalNumberOfShares > 0) {
    const tooltipLabel = `This article has been shared ${totalNumberOfShares} time
          ${totalNumberOfShares !== 1 ? 's' : ''} to this page`;
    const guid = `timing-badge-no-of-shares-${mediaId}`;
    timingLabel.push(
      <span
        className="badge badge-reshared"
        key={guid}
        data-tip
        data-for={guid}
        data-iscapture="true"
      >
        <Tooltip id={guid} label={tooltipLabel}>
          <>
            <img
              className="mr-1"
              src="/img/icons/ic-share.svg"
              alt=""
              width="10px"
            />
            <span style={{ pointerEvents: 'none' }}>
              {`${totalNumberOfShares}`}
            </span>
          </>
        </Tooltip>
      </span>,
    );
  }

  /**
   * Time sensitivity
   */

  if (
    showTimeSensitivity &&
    shouldIncludeTimeSensitivity &&
    !isNull(timeSensitivityTypeId)
  ) {
    const guid = `timing-badge-time-sensitivity-${mediaId}`;
    switch (timeSensitivityTypeId) {
      case TIME_SENSITIVITY_TYPES.BREAKING:
        timingLabel.push(
          <span className="badge badge-breaking" key={guid}>
            Breaking
          </span>,
        );
        break;
      case TIME_SENSITIVITY_TYPES.EVERGREEN:
        timingLabel.push(
          <span className="badge badge-evergreen" key={guid}>
            Evergreen
          </span>,
        );
        break;
      case TIME_SENSITIVITY_TYPES.TODAY:
        timingLabel.push(
          <span className="badge badge-normal" key={guid}>
            Normal
          </span>,
        );
        break;
      default:
        break;
    }
  }

  /**
   * Reshare
   */

  if (
    showReshare &&
    isItem &&
    !isNullOrUndefined(mediaStatesByAccountAPIId) &&
    !isNullOrUndefined(mediaStatesByAccountAPIId[accountAPIId]) &&
    mediaStatesByAccountAPIId[accountAPIId].includes(MEDIA_ITEM_STATES.SHARED)
  ) {
    const guid = `timing-badge-reshared-${mediaId}`;
    timingLabel.push(
      <span className="badge badge-reshared" key={guid}>
        Reshare
      </span>,
    );
  }

  /**
   * Miscellaneous
   */

  // Display old schedules with no timing information as TODAY
  if (showTimingOption && timingLabel.length === 0) {
    const guid = `timing-badge-default-${mediaId}`;
    timingLabel.push(
      <span className="badge badge-normal" key={guid}>
        Normal
      </span>,
    );
  }

  return timingLabel.length ? timingLabel : null;
};

TimingBadges.propTypes = {
  origin: PropTypes.string.isRequired,
  shareTime: PropTypes.object,
  postType: PropTypes.string.isRequired,
  twelveHourFormat: PropTypes.bool.isRequired,
  timeSensitivityTypeId: PropTypes.number,
  suggestionTypeId: PropTypes.number,
  actionTypeId: PropTypes.number,
  totalNumberOfShares: PropTypes.number,
  accountAPIId: PropTypes.any,
  mediaStatesByAccountAPIId: PropTypes.object,
  hasImage: PropTypes.bool,
  socialChannel: PropTypes.string,
  showPostType: PropTypes.bool,
  showReshareCount: PropTypes.bool,
  showReshare: PropTypes.bool,
  showShareCount: PropTypes.bool,
  showTimingOption: PropTypes.bool,
  showTimeSensitivity: PropTypes.bool,
  mediaId: PropTypes.string.isRequired,
};

TimingBadges.defaultProps = {
  timeSensitivityTypeId: null,
  suggestionTypeId: null,
  actionTypeId: null,
  totalNumberOfShares: 0,
  accountAPIId: null,
  mediaStatesByAccountAPIId: {},
  hasImage: undefined,
  socialChannel: SOCIAL_CHANNELS.FEED,
  shareTime: {},
  showPostType: false,
  showReshareCount: true,
  showReshare: false,
  showShareCount: false,
  showTimingOption: false,
  showTimeSensitivity: false,
};

export default TimingBadges;
