import {
  postUploadVideoFinalise,
  postUploadVideoInitialise,
  putUploadVideoPart,
} from 'api/api';
import { chunkVideoForS3 } from 'helpers/videos';
import { generateSHA256Checksum } from './utility';

export async function uploadVideo({
  file,
  socialPageURN,
  onProgress,
}: {
  file: File;
  socialPageURN: string;
  onProgress?: (progress: { percent: number }) => void;
}) {
  const { size, name } = file;
  const checksumSHA256 = await generateSHA256Checksum(file);
  const { signedURLParts, partSizeBytes, uploadId } =
    await postUploadVideoInitialise({
      fileName: name,
      fileSizeBytes: size,
      checksumSHA256,
      socialPageURN,
    });

  const chunks = chunkVideoForS3(file, signedURLParts?.length, partSizeBytes);
  const promises = [];
  const abortControllers = [];
  const partNumbers: number[] = [];
  const percentageArray: number[] = new Array(signedURLParts.length).fill(0);

  for (let i = 0; i < signedURLParts?.length; i += 1) {
    const controller = new AbortController();
    abortControllers.push(controller);
    promises.push(
      putUploadVideoPart({
        url: signedURLParts[i],
        data: chunks[i],
        controller,
        index: i,
        percentageArray,
        onProgress,
      }),
    );
    partNumbers.push(i + 1);
  }

  try {
    const responses = await Promise.all(promises);
    // finalise upload
    const parts = responses.map((response, index) => ({
      eTag: response.etag,
      partNumber: partNumbers[index],
    }));
    const finaliseResponse = await postUploadVideoFinalise({
      fileName: name,
      uploadId,
      parts,
      socialPageURN,
    });

    return finaliseResponse?.name;
  } catch (error: any) {
    // abort all requests
    abortControllers.forEach((controller) => controller.abort());
    throw error;
  }
}
