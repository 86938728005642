import {
  Button,
  ChevronDownIcon,
  Dropdown,
  Flex,
  SignOutIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link } from 'react-router-dom';

import { isMatchMedia } from 'common/window';
import { useGlobalInfo } from 'context/GlobalInfoContext';

const SidebarUserMenu = () => {
  const context = useGlobalInfo();
  const globalInfo = context.global.getGlobalInfo();

  const { user } = globalInfo;

  const isMobile = isMatchMedia();

  if (context.global.isLoading() || !isMobile) {
    return null;
  }

  return (
    <Dropdown gutter={8} matchWidth>
      <Dropdown.Button
        as={Button}
        variant="secondary"
        rightIcon={<ChevronDownIcon color="gray.700" />}
        w="full"
        _expanded={{
          boxShadow: 'focus',
        }}
      >
        <Flex flexDirection="column" alignItems="flex-start">
          <Text size="sm" fontWeight="medium">
            {user.name}
          </Text>
          <Text size="xs">{user.emailAddress}</Text>
        </Flex>
      </Dropdown.Button>
      <Dropdown.List>
        <Link to="/logout">
          <Dropdown.Item LeftIcon={SignOutIcon}>Sign out</Dropdown.Item>
        </Link>
      </Dropdown.List>
    </Dropdown>
  );
};

export default SidebarUserMenu;
