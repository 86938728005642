/**
 * POST /serviceAuth - Exchanges an accessToken/IdToken for a ClientServiceToken.
 *                      Can also provide an overrideUsername, overridePropertyURN,
 *                      or overrideSocialPageURN to impersonate a different user
 */
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { z } from 'zod';

import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getCognitoRequestHeaders,
} from 'api/settings';
import {
  NO_PERMISSIONS_PREFIX,
  NO_PERMISSIONS_STATUS_CODES,
} from 'common/constants';
import * as cookie from 'common/cookie';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import * as utility from 'common/utility';

export default async function postServiceAuth({
  overrideUsername = null,
  overridePropertyURN = null,
  overrideSocialPageURN = null,
  ...args
}: {
  overrideUsername?: string | null;
  overridePropertyURN?: string | null;
  overrideSocialPageURN?: string | null;
} & Record<string, unknown>) {
  const guid = metrics.start('postServiceAuth');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/serviceauth`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getCognitoRequestHeaders(),
  };
  if (overrideUsername) {
    config.data = JSON.stringify({ overrideUsername });
  } else if (overridePropertyURN) {
    config.data = JSON.stringify({ overridePropertyURN });
  } else if (overrideSocialPageURN) {
    config.data = JSON.stringify({ overrideSocialPageURN });
  }
  logger.info(
    `API:postServiceAuth /serviceauth ${overrideUsername ?? ''}${
      overridePropertyURN ?? ''
    }${overrideSocialPageURN ?? ''}`,
  );

  try {
    const response = await axios(config);
    const clientServiceToken = response.headers['x-ebx-clientservicetoken'];
    metrics.end('postServiceAuth', guid);
    cookie.deleteCookie(`${NO_PERMISSIONS_PREFIX}${window.location.host}`, {
      domain: cookie.getTLD(window.location.hostname),
    });
    return clientServiceToken;
  } catch (error) {
    metrics.fail('postServiceAuth', guid);
    if (
      doesNotHavePermissions(error) &&
      [overrideUsername, overridePropertyURN, overrideSocialPageURN].every(
        (override) => override === null,
      )
    ) {
      const newError = utility.cloneObject(error);
      newError.noPermissions = true;
      throw newError;
    }
    const errorArgs = {
      ...args,
      ...(overrideUsername && { overrideUsername }),
      ...(overridePropertyURN && { overridePropertyURN }),
      ...(overrideSocialPageURN && { overrideSocialPageURN }),
    };
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postServiceAuth',
      args: errorArgs,
    });
    throw apiError;
  }
}

const ErrorSchema = z
  .object({ response: z.object({ status: z.number() }) })
  .describe('postServiceAuth.ErrorSchema');

function doesNotHavePermissions(
  error: unknown,
): error is Record<string, unknown> {
  const safelyParsedError = ErrorSchema.safeParse(error);
  if (!safelyParsedError.success) {
    return false;
  }
  const { status } = safelyParsedError.data.response;
  return NO_PERMISSIONS_STATUS_CODES.includes(status);
}
