import PropTypes from 'prop-types';
import { useState } from 'react';

import { getAPIPostName, getAPITypeId } from 'common/accountAPIs';
import { MEDIA_ITEM_STATES, POST_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { isNullOrUndefined } from 'common/utility';
import Button from 'components/misc/Button';
import Dialog from 'components/misc/Dialog';
import { getNetworkAndPageName } from 'helpers/tracking';

const { DialogHeader, DialogBody, DialogFooter } = Dialog;

/**
 * React component for capturing the reason why a user deleted
 * an article from the Schedule Queue
 * 1) isOpen    - boolean for showing/hiding the dialog
 * 2) onDismiss - function to dismiss the dialog
 * 3) mediaItem - the media item being deleted
 */

const ShowArticleDeleteSurvey = ({ isOpen, onDismiss, mediaItem }) => {
  const [response, setResponse] = useState('');

  /**
   * Function to submit the user's response
   *  and track in Mixpanel
   */
  const handleSubmit = async () => {
    trackAndDismiss(true);
  };

  /**
   * Function to track the media item info
   * and lack of user response in Mixpanel
   * before dismissing the modal
   */
  const handleCancel = () => {
    trackAndDismiss(false);
  };

  const trackAndDismiss = async (submit) => {
    try {
      await trackArticleDeletionSurveyAction(submit);
      onDismiss();
      if (submit) {
        addSuccessNotification(
          'Response submitted. Thank you for your feedback!',
        );
      }
    } catch (error) {
      logger.info('ShowArticleDeleteSurvey:MixpanelTrackingFailure');
      const message = getErrorMessage(determineError(error));
      addErrorNotification(message);
    }
  };

  function trackArticleDeletionSurveyAction(submit) {
    const trackingParams = getSurveyTrackingParams(submit);
    tracker.track({
      eventName: 'User Feedback: Delete Autofeed Suggestion',
      trackingParams,
    });
  }

  function getSurveyTrackingParams(submit) {
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
    const apiTypeId = getAPITypeId({ accountAPIId });
    const postType = MediaItem.getPostType({ mediaItem });
    const message = MediaItem.getMessage({ mediaItem });
    const title = MediaItem.getRssTitle({ mediaItem });
    const mediaStatesByAccountAPIId = MediaItem.getMediaStatesByAccountAPIId({
      mediaItem,
    });
    const isReshare =
      !isNullOrUndefined(mediaStatesByAccountAPIId) &&
      !isNullOrUndefined(mediaStatesByAccountAPIId[accountAPIId]) &&
      mediaStatesByAccountAPIId[accountAPIId].includes(
        MEDIA_ITEM_STATES.SHARED,
      );
    const articleURL =
      postType === POST_TYPES.LINK
        ? MediaItem.getUnshortenedShareURL({ mediaItem })
        : 'undefined';
    const socialNetwork = getSocialNetworkName({ apiTypeId });
    const socialPageName = getAPIPostName({ accountAPIId });
    return {
      'Feedback Provided': submit,
      'Free Text Response': response,
      'Share type': isReshare ? 'Autofeed Reshare' : 'Autofeed New Share',
      'Social Network': socialNetwork,
      'Social Page': socialPageName,
      'Account API Id': accountAPIId,
      'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
      'Article URL': articleURL,
      'Share Message Text': message !== null ? message : 'undefined',
      'Share Message Length': message ? message.length : 'undefined',
      'Title Length': title ? title.length : 'undefined',
    };
  }
  return (
    <Dialog
      className="modal-sm"
      isOpen={isOpen}
      onDismiss={handleCancel}
      centerVertically
    >
      <DialogHeader showClose={true}>
        Please tell us why you deleted this Autofeed post
      </DialogHeader>
      <DialogBody>
        I deleted this Autofeed post because...
        <div className="mt-3">
          <textarea
            data-cy-input="survey-response"
            type="text"
            value={response}
            onChange={(event) => setResponse(event.target.value)}
            style={{ minHeight: '200px', width: '100%' }}
          />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          id="submit-survey"
          size="lg"
          variant="dark"
          loading
          loadingEl="Submitting survey"
          disabled={response === ''}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

ShowArticleDeleteSurvey.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  mediaItem: PropTypes.object.isRequired,
};

export default ShowArticleDeleteSurvey;
