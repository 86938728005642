import type { ChangeEventHandler } from 'react';

import { getFilteredPropertiesAndPages } from 'common/accountAPIs';
import { REACT_PREVENT_RENDER } from 'common/constants';
import SocialPagesNetworks from 'components/compose/socialpages/SocialPagesNetworks';

interface SocialPagesPropertyProps {
  checkedAPIs: Array<number>;
  mediaStates?: Record<string, string[]> | null;
  onSelectAll: (propertyId: number, apiTypeId: number) => () => void;
  onSocialPagesChange: ChangeEventHandler<HTMLInputElement>;
  postType: string;
  propertyId: number;
  searchText: string;
  showName: boolean;
}

const SocialPagesProperty = ({
  checkedAPIs,
  mediaStates = null,
  onSelectAll,
  onSocialPagesChange,
  postType,
  propertyId,
  searchText,
  showName,
}: SocialPagesPropertyProps) => {
  const filteredPropertyAndPages = getFilteredPropertiesAndPages({
    checkedAPIs: [],
    mediaStates,
    postType,
    propertyId,
    searchText,
  });
  if (filteredPropertyAndPages.length === 0) {
    return REACT_PREVENT_RENDER;
  }
  const filteredPagesForProperty = filteredPropertyAndPages[0];
  if (
    filteredPagesForProperty.accountAPIs === undefined ||
    filteredPagesForProperty.accountAPIs.length === 0
  ) {
    return REACT_PREVENT_RENDER;
  }

  const socialPagesNetworks = (
    <SocialPagesNetworks
      checkedAPIs={checkedAPIs}
      mediaStates={mediaStates}
      postType={postType}
      propertyId={propertyId}
      searchText={searchText}
      onSelectAll={onSelectAll}
      onSocialPagesChange={onSocialPagesChange}
    />
  );

  if (showName) {
    return (
      <div className="mb-2 dropdown-other-properties">
        <div className="dropdown-header-title">
          {filteredPagesForProperty.propertyName}
        </div>
        <div>{socialPagesNetworks}</div>
      </div>
    );
  }

  return socialPagesNetworks;
};

export default SocialPagesProperty;
