import {
  Flex,
  SettingsCard,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import isEqual from 'fast-deep-equal';
import * as React from 'react';
import { Link } from 'react-router-dom';

import * as accountAPIs from 'common/accountAPIs';
import { AUTOFEED_SHARE_MODES } from 'common/constants';
import { ARTICLE_AGE_UNITS } from 'common/enums';
import * as settings from 'common/settings';
import * as social from 'common/social';
import * as tracker from 'common/tracker';
import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';
import ReshareModes from 'components/settings/automation/reshares/ReshareModes';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  setSettingsError,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';
import { AutofeedSettings, AutofeedShareMode, FixTypeLater } from 'types';
import { useAutoDisableAutomationPrompt } from '../useAutoDisableAutomationPrompt';

export interface ReshareSettings {
  reshareMode: AutofeedShareMode;
  bestArticlesReshareNum: number;
  maxResharesPerArticle: number;
  reshareIntervalUnit: ARTICLE_AGE_UNITS;
  reshareIntervalValue: number;
  reshareEvergreenContentOnly: boolean;
  startPastDaysForBestArticleReshare: number;
}

function Reshares() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();

  const { global } = useGlobalInfo();

  const { accountAPIId, showDisableAutomationPrompt } =
    useAutomationSettingsContext();

  const { shouldShowDisableAutomationPrompt } = useAutoDisableAutomationPrompt({
    shareSettingType: 'reshare',
  });

  const getReshareSettings = () => {
    const defaults = social.getAutofeedDefaults({
      apiTypeId: accountAPIs.getAPITypeId({
        accountAPIId,
      }),
    });

    const reshareMode =
      settings.getAutofeedSetting({
        key: 'reshareMode',
        accountAPIId,
      }) ??
      defaults.reshareMode ??
      AUTOFEED_SHARE_MODES.OPTIMAL;

    const bestArticlesReshareNum =
      settings.getAutofeedSetting({
        key: 'bestArticlesReshareNum',
        accountAPIId,
      }) ??
      defaults.bestArticlesReshareNum ??
      0;

    const maxResharesPerArticle =
      settings.getAutofeedSetting({
        key: 'maxResharesPerArticle',
        accountAPIId,
      }) ??
      defaults.maxResharesPerArticle ??
      0;

    const minMinsBetweenSharesOfSameArticle =
      settings.getAutofeedSetting({
        key: 'minMinsBetweenSharesOfSameArticle',
        accountAPIId,
      }) ??
      defaults.minMinsBetweenSharesOfSameArticle ??
      0;

    let reshareIntervalUnit;
    let reshareIntervalValue;
    if (minMinsBetweenSharesOfSameArticle % (24 * 60) === 0) {
      reshareIntervalUnit = ARTICLE_AGE_UNITS.DAYS;
      reshareIntervalValue = minMinsBetweenSharesOfSameArticle / (24 * 60);
    } else {
      reshareIntervalUnit = ARTICLE_AGE_UNITS.HOURS;
      reshareIntervalValue = minMinsBetweenSharesOfSameArticle / 60;
    }

    const startPastMinsForBestArticleReshare =
      settings.getAutofeedSetting({
        key: 'startPastMinsForBestArticleReshare',
        accountAPIId,
      }) ??
      defaults.startPastMinsForBestArticleReshare ??
      0;
    const startPastDaysForBestArticleReshare =
      startPastMinsForBestArticleReshare / (24 * 60);

    const reshareEvergreenContentOnly = !!settings.getAutofeedSetting({
      key: 'reshareEvergreenContentOnly',
      accountAPIId,
    });

    return {
      reshareMode,
      bestArticlesReshareNum,
      maxResharesPerArticle,
      reshareIntervalUnit,
      reshareIntervalValue,
      reshareEvergreenContentOnly,
      startPastDaysForBestArticleReshare,
    };
  };

  const reshareSettings = getReshareSettings();
  const [currentReshareSettings, setCurrentReshareSettings] =
    React.useState(reshareSettings);

  const handleSaveReshareModeConfirmed = async ({
    newSettings,
    disableAutomation,
  }: {
    newSettings: ReshareSettings;
    disableAutomation?: boolean;
  }) => {
    const reshareIntervalMins =
      newSettings.reshareIntervalUnit === ARTICLE_AGE_UNITS.HOURS
        ? newSettings.reshareIntervalValue * 60
        : newSettings.reshareIntervalValue * 24 * 60;

    const startPastDaysMins =
      newSettings.startPastDaysForBestArticleReshare * 60 * 24;

    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    updatedSettings.reshareMode = newSettings.reshareMode;
    updatedSettings.bestArticlesReshareNum = newSettings.bestArticlesReshareNum;
    updatedSettings.startPastMinsForBestArticleReshare = startPastDaysMins;
    updatedSettings.maxResharesPerArticle = newSettings.maxResharesPerArticle;
    updatedSettings.minMinsBetweenSharesOfSameArticle = reshareIntervalMins;
    updatedSettings.reshareEvergreenContentOnly =
      newSettings.reshareEvergreenContentOnly;

    if (disableAutomation) {
      updatedSettings.autoFeedActive = false;
    }

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
      reshareMixpanel,
    );
  };

  const handleSaveReshareMode = async (newSettings: ReshareSettings) => {
    if (
      shouldShowDisableAutomationPrompt({ shareMode: newSettings.reshareMode })
    ) {
      showDisableAutomationPrompt({
        onConfirm: () =>
          handleSaveReshareModeConfirmed({
            newSettings,
            disableAutomation: true,
          }),
      });
      return;
    }

    handleSaveReshareModeConfirmed({ newSettings });
  };

  const reshareMixpanel = (
    updatedTracking: FixTypeLater,
    previousTracking: FixTypeLater,
  ) => {
    tracker.track({
      eventName: 'Update Autofeed Reshare Settings',
      trackingParams: {
        ...updatedTracking,
        'Previous Reshares Mode': previousTracking['Reshares Mode'],
        'Previous Daily Reshares': previousTracking['Daily Reshares'],
        'Previous Maximum Article Age (reshare)':
          previousTracking['Maximum Article Age (reshare)'],
        'Previous Max Reshares Per Article':
          previousTracking['Max Reshares Per Article'],
        'Previous Minimum Time Between Reshares':
          previousTracking['Minimum Time Between Reshares'],
        'Previous Reshares Evergreen Only':
          previousTracking['Reshares Evergreen Only'],
        'Previous Reshares Prefix': previousTracking['Reshares Prefix'],
        'Previous Reshares Suffix': previousTracking['Reshares Suffix'],
      },
    });
  };

  const handleRehareSettingsChange = (
    newSettings: ReshareSettings,
    error: boolean = false,
  ) => {
    setCurrentReshareSettings(newSettings);

    if (isEqual(newSettings, reshareSettings)) {
      resetSettingsState(dispatch);
    } else if (error) {
      setSettingsError(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveReshareMode(newSettings));
  };

  return (
    <>
      <AutomationSettingsHeader
        title="Reshares"
        description="Customize the automation of reshares"
      />
      <ReshareModes
        currentReshareSettings={currentReshareSettings}
        handleRehareSettingsChange={handleRehareSettingsChange}
      />
      <Flex flexDir="column" gap={2}>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/reshares/keywords`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Keywords"
            description="Use keywords to speficy which content can be shared"
          />
          <SettingsCard.Controls />
        </SettingsCard>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/reshares/prefix-suffix`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Prefix & Suffix"
            description="Add a word or phrase to each automated reshare"
          />
          <SettingsCard.Controls />
        </SettingsCard>
      </Flex>
    </>
  );
}

export default Reshares;
