import {
  Alert,
  Box,
  Button,
  Flex,
  FormControl,
  InfoIcon,
  Input,
  Modal,
  Select,
  Text,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChangeEvent, useContext, useState } from 'react';

import { postPropertiesUser } from 'api/api';
import { getCurrentPropertyId } from 'common/accountAPIs';
import {
  GLOBAL_INFO_STATES,
  MIXPANEL_ORIGIN,
  PERMISSION_TYPES,
} from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import GlobalInfoContext from 'context/GlobalInfoContext';
import type { Permission } from 'types';

import PageSelector from './PageSelector';

type Steps = 'USER_DETAILS' | 'PAGES';

interface AddUserModalProps {
  showRoleSettings?: boolean;
  onClose: () => void;
  onUserAdd: () => void;
}

const AddUserModal = ({
  showRoleSettings = false,
  onClose,
  onUserAdd,
}: AddUserModalProps) => {
  const { global } = useContext(GlobalInfoContext);

  const [step, setStep] = useState<Steps>('USER_DETAILS');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('administrator');

  const [networkError, setNetworkError] = useState<string | null>(null);

  const [isAddingUser, setIsAddingUser] = useState(false);

  const handleInputChange = (
    updater: (newValue: string) => void,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    setNetworkError(null);
    updater(e.target.value);
  };

  const handleUserAdd = async (selectedAPIs?: number[]) => {
    logger.info('AddUserModal:handleUserAdd');

    setIsAddingUser(true);
    setNetworkError(null);

    const globalInfo = global.getGlobalInfo();
    const permissionsOnProperty: Permission[] = [
      {
        propertyId: globalInfo.current.propertyId,
        permissionTypeId:
          role === 'administrator'
            ? PERMISSION_TYPES.ADMIN
            : PERMISSION_TYPES.VIEW_ONLY,
      },
    ];

    if (selectedAPIs !== undefined && selectedAPIs.length > 0) {
      selectedAPIs.forEach((accountAPIId) => {
        permissionsOnProperty.push({
          propertyId: globalInfo.current.propertyId,
          accountAPIId,
          permissionTypeId: PERMISSION_TYPES.EDITOR,
        });
      });
    }

    try {
      await postPropertiesUser({
        propertyId: getCurrentPropertyId({
          globalInfo,
        }),
        name: name.trim(),
        username: email.trim(),
        emailAddress: email.trim(),
        permissionsOnProperty,
      });

      tracker.track({
        eventName: 'Add User',
        trackingParams: {
          'New User Email': email,
          Origin: MIXPANEL_ORIGIN.SETUP,
        },
      });

      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        allowUnderSetup: true,
        callback: () => {
          setIsAddingUser(false);
          onUserAdd();
          onClose();
        },
      });
    } catch (error) {
      setNetworkError(getErrorMessage(error));
      setIsAddingUser(false);
    }
  };

  if (step === 'PAGES')
    return (
      <PageSelector
        userEmail={email}
        onSave={handleUserAdd}
        onClose={onClose}
        onCancel={() => setStep('USER_DETAILS')}
        cancelLabel="Previous"
        saveLabel="Add user"
        // When undefined, all pages will be selected by default.
        initialSelectedAPIs={undefined}
        networkError={networkError}
      />
    );

  return (
    <Modal isOpen onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Invite new user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex direction="column" as="form" gap={6}>
          <FormControl>
            <FormControl.FormLabel>Name</FormControl.FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => handleInputChange(setName, e)}
            />
          </FormControl>
          <FormControl>
            <FormControl.FormLabel>Email address</FormControl.FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => handleInputChange(setEmail, e)}
            />
          </FormControl>
          {showRoleSettings ? (
            <FormControl>
              <FormControl.FormLabel display="flex" alignItems="end">
                <span>Role</span>
                <Tooltip
                  minW="380px"
                  label={
                    <>
                      <Text size="sm">
                        <strong>Administrator:</strong> Has access to all pages
                        and settings
                      </Text>
                      <Text size="sm">
                        <strong>Editor:</strong> Has access to selected pages
                        without settings
                      </Text>
                    </>
                  }
                  placement="top-start"
                >
                  <Box
                    as="span"
                    mb="0.5"
                    ml="0.5"
                    cursor="default"
                    tabIndex={0}
                  >
                    <InfoIcon color="gray.600" />
                  </Box>
                </Tooltip>
              </FormControl.FormLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)}>
                <option value="administrator">Administrator</option>
                <option value="editor">Editor</option>
              </Select>
            </FormControl>
          ) : (
            <Alert>
              <Alert.Description>
                Added users will have administrator permissions, you will be
                able to change that later.
              </Alert.Description>
            </Alert>
          )}
        </Flex>
        {networkError && (
          <Text color="red.600" size="sm" mt={3}>
            {networkError}
          </Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={!name || !email || isAddingUser}
          onClick={() =>
            role === 'administrator' ? handleUserAdd() : setStep('PAGES')
          }
          isLoading={isAddingUser}
        >
          {role === 'administrator' ? 'Add user' : 'Next'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserModal;
