import { TWITTER_SHARE_FAILED_ERROR_MESSAGES } from 'common/constants';
import { mandatory } from 'common/validation';

interface PreviewErrorProps {
  errorMessage: string | null;
  statusCode?: number | null;
}

const PreviewError = ({ errorMessage, statusCode }: PreviewErrorProps) => {
  if (errorMessage === undefined) {
    mandatory('errorMessage');
  }

  if (errorMessage === null || errorMessage === '' || statusCode === 409) {
    return null;
  }
  const isTwitterAPIError = TWITTER_SHARE_FAILED_ERROR_MESSAGES.some((item) =>
    errorMessage.toLowerCase().includes(item.toLowerCase()),
  );

  return (
    <div
      className="modal-body modal-error save-state save failed"
      data-cy-id="errorMessage"
    >
      <img
        className="icon-error mr-1"
        src="/img/icons/ic-warning-red.svg"
        alt=""
      />
      <span>
        {isTwitterAPIError ? (
          <>
            {errorMessage} Please read our{' '}
            <a
              target="_blank"
              href="https://echobox.zendesk.com/hc/en-us/articles/10257937885853-Connecting-Twitter-to-Echobox"
              rel="noreferrer"
            >
              guide.
            </a>
          </>
        ) : (
          errorMessage
        )}
      </span>
    </div>
  );
};

export default PreviewError;
