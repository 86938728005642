const NoResults = () => (
  <div className="search-result-articles">
    <div className="empty_feed text-center my-5 py-5">
      <img src="/img/icons/ic-search-large.svg" alt="" />
      <div className="cleared_message">
        <div className="text-500 pb-2">No search results</div>
        <div className="text-400">Try different search terms</div>
      </div>
    </div>
  </div>
);

export default NoResults;
