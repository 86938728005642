import { fabric } from 'fabric';

import { IGLinkStickerConfig } from 'types';

export const STICKER_FONT_FAMILY = 'Dosis';

export const COMPOSE_IMAGE_DIMENSIONS = {
  WIDTH: 284,
  HEIGHT: 507,
  PADDING_X: 12,
  PADDING_Y: 64,
} as const;

export const INSTAGRAM_LINK_STICKER_NAMES = {
  STICKER_GROUP: 'STICKER_GROUP',
  STICKER_TEXT: 'STICKER_TEXT',
  STICKER_BACKGROUND: 'STICKER_BACKGROUND',
} as const;

// The designs which all the proportions are based on has a height of 45px
// However, we want a default height of 5.7% of the image height. Therefore,
// we create the sticker using height 45px and then scale everything down to
// the default height in order to preserve the proportions in the designs
export const STICKER_DEFAULT_HEIGHT_IN_DESIGNS = 45;
export const STICKER_MAX_HEIGHT_RATIO = 0.057;

/**
 * Creates an fabric object to represent the sticker text and background.
 */
export const createSticker = (text: string) => {
  const stickerBackground = new fabric.Rect({
    height: STICKER_DEFAULT_HEIGHT_IN_DESIGNS,
    rx: 8,
    ry: 8,
    fill: 'rgba(115, 123, 139, 0.4)',
    name: INSTAGRAM_LINK_STICKER_NAMES.STICKER_BACKGROUND,
  });

  const stickerText = new fabric.Text(text.toUpperCase(), {
    fontSize: 35,
    fontFamily: STICKER_FONT_FAMILY,
    top: (stickerBackground.top ?? 0) + 5,
    left: (stickerBackground.left ?? 0) + 40,
    fill: 'white',
    name: INSTAGRAM_LINK_STICKER_NAMES.STICKER_TEXT,
  });

  alignStickerBackgroundWidthWithText(stickerBackground, stickerText);

  return { stickerText, stickerBackground };
};

/**
 * Update the width of the sticker background based on the width of the text
 */
export const alignStickerBackgroundWidthWithText = (
  stickerBackground: fabric.Rect,
  stickerText: fabric.Text,
) => {
  const textScaleX = stickerText.scaleX ?? 1;
  stickerBackground.set({
    width: stickerText.getScaledWidth() / textScaleX + 50,
  });
};

/**
 * Scale the sticker down if it's too wide to fit on the image
 */
export const scaleStickerDownToFitCanvas = (stickerGroup: fabric.Group) => {
  const recommendedMoxWidth =
    COMPOSE_IMAGE_DIMENSIONS.WIDTH - COMPOSE_IMAGE_DIMENSIONS.PADDING_X * 2;

  if (
    stickerGroup.getScaledWidth() &&
    stickerGroup.getScaledWidth() > recommendedMoxWidth
  ) {
    stickerGroup.scaleToWidth(
      COMPOSE_IMAGE_DIMENSIONS.WIDTH - COMPOSE_IMAGE_DIMENSIONS.PADDING_X * 2,
    );
  }
};

/**
 * Calculate the sticker position within a canvas, given the previous config.
 */
export const calculateStickerPosition = (
  config: IGLinkStickerConfig,
  canvas: fabric.Canvas,
  sticker: fabric.Group,
) => {
  return {
    top:
      config.centrePointOfStickerAsPercentageOfHeight * canvas.getHeight() -
      sticker.getScaledHeight() / 2,
    left:
      config.centrePointOfStickerAsPercentageOfWidth * canvas.getWidth() -
      sticker.getScaledWidth() / 2,
  };
};

/**
 * Calculates the link sticker position, given a sticker group.
 */
export const getStickerPosition = (
  stickerGroup: fabric.Group,
): IGLinkStickerConfig => {
  return {
    widthOfStickerAsPercentageOfWidth:
      stickerGroup.getScaledWidth() / COMPOSE_IMAGE_DIMENSIONS.WIDTH,
    heightOfStickerAsPercentageOfHeight:
      stickerGroup.getScaledHeight() / COMPOSE_IMAGE_DIMENSIONS.HEIGHT,
    centrePointOfStickerAsPercentageOfWidth:
      ((stickerGroup.left ?? 0) + stickerGroup.getScaledWidth() / 2) /
      COMPOSE_IMAGE_DIMENSIONS.WIDTH,
    centrePointOfStickerAsPercentageOfHeight:
      ((stickerGroup.top ?? 0) + stickerGroup.getScaledHeight() / 2) /
      COMPOSE_IMAGE_DIMENSIONS.HEIGHT,
  };
};
