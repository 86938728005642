import {
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  HOST_TYPES,
} from 'common/constants';
import * as environment from 'common/environment';
import { CommonSetting } from 'types';

export const AB_TEST_MINIMUM_TIME_LENGTH_IN_SECS = 45 * 60;

// Setting types stored at account API level
export const ACCOUNT_API_LEVEL_SETTINGS: CommonSetting[] = [
  ACCOUNT_SETTING_TYPES.AUTO_FEED, // 5
  ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES, // 11
  ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES, // 12
  ACCOUNT_SETTING_TYPES.DEFAULT_FACEBOOK_TARGETING, // 14
  ACCOUNT_SETTING_TYPES.AUTO_PILOT_TOGGLE, // 17
  ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS, // 18
  ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY, // 26
];

export const ANALYTICS_SETTINGS = {
  MAXIMUM_CUSTOM_DATE_DURATION_DAYS: 123,
  TIMEFRAME_OPTIONS: [
    { label: '6 hours', duration: 6 },
    { label: '12 hours', duration: 12 },
    { label: '24 hours', duration: 24 },
    { label: '48 hours', duration: 48 },
    { label: '1 week', duration: 7 * 24 },
    { label: '2 weeks', duration: 14 * 24 },
    { label: '30 days', duration: 30 * 24 },
    { label: '60 days', duration: 60 * 24 },
    { label: '90 days', duration: 90 * 24 },
    { label: '120 days', duration: 120 * 24 },
  ],
};

export const AUTOFEED_SETTINGS = {
  // Autofeed settings keys which should be added to the webservice response
  // if they are not included in the response
  DEFAULTS: [
    { key: 'newshareMessagePrefix', value: '' },
    { key: 'newshareMessageSuffix', value: '' },
    { key: 'reshareMessagePrefix', value: '' },
    { key: 'reshareMessageSuffix', value: '' },
  ],
} as const;

const hostType = environment.getHostType();
const AWS_AMPLIFY_DEFAULTS = {
  REGION: 'eu-west-1',
  USER_POOL_ID: 'eu-west-1_R60WRWhpr',
  WEB_CLIENT_ID_STAGE: '24p0gdhog2d6vq6sc5abnf928c', // Echobox-UI-Login-Staging
  WEB_CLIENT_ID_LIVE: '7cdhie3ij1lt73q111nljqfud6', // Echobox-UI-Login
  AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
};

// AWS Amplify configuration
export const AWS_AMPLIFY_CONFIG = {
  Auth: {
    region: AWS_AMPLIFY_DEFAULTS.REGION,
    userPoolId: AWS_AMPLIFY_DEFAULTS.USER_POOL_ID,
    userPoolWebClientId:
      hostType === HOST_TYPES.PROD
        ? AWS_AMPLIFY_DEFAULTS.WEB_CLIENT_ID_LIVE
        : AWS_AMPLIFY_DEFAULTS.WEB_CLIENT_ID_STAGE,
    authenticationFlowType: AWS_AMPLIFY_DEFAULTS.AUTHENTICATION_FLOW_TYPE,
    cookieStorage: {
      domain: window.location.hostname,
      path: '/',
      expires: 1,
      secure: hostType !== HOST_TYPES.DEV,
      sameSite: 'strict',
    },
  },
};

// Breaking news settings
export const BREAKING_NEWS = {
  MIN_SHARE_TIME_LAG_SECONDS: 5,
  INTERVAL_SECONDS: 15 * 60, // 15 minutes in seconds
};

// Default value for can customise link posts
export const CAN_CUSTOMISE_LINK_POSTS = true;

// Time period (in milliseconds) after which a component is considered "stable" i.e. it has not been updated
// and can therefore be considered to have finished loading
export const COMPONENT_STABLE_AFTER_DURATION = 1000;

export const CORS_PROXY_URL = 'https://corsproxy.service.echobox.com/';

export const CREATIVE_EDITOR_LICENSE_KEY =
  'tuGVlYb3yCqd_P28lhJR-1UGZKo0SnsWH2lng9EPDFkUDmSMRIrY3i0FDP7Z4tah';

export const CROSS_POST_LIMIT = 5;

// Both string and number type were added, as propertyId could be string or number
export const CROSS_POST_PROPERTIES = ['11467', 11467];

// Default auto share interval (hours)
export const DEFAULT_AUTO_SHARE_INTERVAL = 24;

export const EDITOR_ACCESS_MESSAGE =
  "If you'd like to edit user permissions, please contact your Echobox account manager.";

// Toggle the MSW service worker during development
export const ENABLE_MSW = false;

// Evergreen news settings
export const EVERGREEN_NEWS = {
  MIN_SHARE_TIME_LAG_SECONDS: 5,
  INTERVAL_SECONDS: 48 * 60 * 60, // 48 hours in seconds
};

// Field length limits for Facebook posts
export const FACEBOOK_SETTINGS = {
  DESCRIPTION_MAX_CHARS: 170,
};

// Default time period to show failed shares (hours)
export const FAILED_SHARE_DURATION = 7 * 24;

// Errors not to display as flash messages
export const FLASH_IGNORED_ERRORS = [
  'Media id [a-z][a-z]_[0-9a-zA-Z]+ does not exist for account API id [0-9]+',
  'Unauthorized:',
];

// Google Analytics tracking id
export const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-52626777-10';

// Local storage cache duration
export const STORAGE_CACHE_DURATION = 30 * 60; // 30 minutes

// Fields to be excluded or stringified when being logged
export const LOGGLY_SETTINGS = {
  EXCLUDE_IF_EMPTY: ['statusText'],
  KEYS_TO_EXCLUDE: [
    'x-ebx-accesstoken',
    'x-ebx-authtoken',
    'x-ebx-idtoken',
    'x-ebx-impersonatetoken',
    'x-ebx-clientservicetoken',
    'password',
    'oldpassword',
    'newpassword',
  ],
  KEYS_TO_STRINGIFY: ['dataJSON', 'mediaItem'],
};

// Maximum number of AB variations allowed
export const MAX_AB_VARIATIONS = 2;

export const MAX_CUMULATIVE_CUSTOM_CONTENT = 1100;

export const MAX_IMAGE_HEIGHT = {
  [API_TYPE_IDS.FACEBOOK]: 675,
  [API_TYPE_IDS.TWITTER]: 664,
  [API_TYPE_IDS.INSTAGRAM]: 507,
};

export const MAX_IMAGE_WIDTH = {
  [API_TYPE_IDS.INSTAGRAM]: 530,
};

// Maximum number of media items to fetch in a single call to getMediaItems
export const MAX_MEDIA_ITEMS_IN_BATCH = 20;

// Max mobile window width
export const MAX_MOBILE_WINDOW_WIDTH = 767.98;

// Maximum number of items that can be added when refreshing a news feed queue
export const MAX_REFRESH_ITEMS_TO_ADD = 3;

// Maximum number of media items to fetch when searching
export const MAX_SEARCH_ITEMS_IN_BATCH = 10;

// Media item validation errors
export const MEDIA_ITEM_VALIDATION_ERRORS = {
  END_SHARE_TIME_AFTER_START_SHARE_TIME:
    'Please ensure the end date is after the start date.',
  END_SHARE_TIME_IN_FUTURE: 'Please ensure the end date is in the future.',
  START_SHARE_TIME_IN_FUTURE: 'Please ensure the start date is in the future.',
  AB_START_SHARE_TIME_AFTER_LIMIT:
    'The start time for an AB test has to be at least 45 minutes in the future',
  MAX_LENGTH_SHARE_MESSAGE: `Please ensure the message doesn't exceed the maximum length allowed.`,
  SPONSORED_POST_MESSAGE:
    'Please ensure the sponsored post includes a message.',
  SPONSORED_POST_MESSAGE_AB:
    'Please ensure the sponsored post includes a message for AB variation number [idx].',
  SAVE_CANCEL_IMAGE_EDITS:
    'Please ensure the image is in the range of allowed aspect ratios.',
  DEFINE_IMAGE_THUMBNAIL: 'Please define the video thumbnail.',
  POST_INCLUDES_MESSAGE_OR_IMAGE:
    'Please ensure your status post includes a message and/or image.',
  POST_INCLUDES_MESSAGE_AND_IMAGE:
    'Please ensure your status post includes a message and image.',
  VIDEO_POSTS_INCLUDE_VIDEO: 'Please ensure your video post includes a video.',
  MAX_LENGTH_SHARE_MESSAGE_AB:
    "Please ensure the message of the AB variation number [idx] doesn't exceed the maximum length allowed.",
  MAX_LINK_URL_LENGTH: 'This URL is too long',
  POST_INCLUDES_IMAGE: 'Please ensure your post includes an image',
  MAX_LENGTH_FIRST_COMMENT:
    "Please ensure the first comment doesn't exceed the maximum length allowed.",
  MIN_LENGTH_SHARE_MESSAGE: 'Please provide a share message',
  POST_INCLUDES_TITLE: 'Please ensure the post includes a title',
};

// Media file prefixes
export const MEDIA_PREFIXES = {
  IMAGE: 'ebx_img',
  VIDEO: 'ebx_vid',
};

// URL patterns that allow nested shares
export const NESTED_SHARE_URL_PATTERNS = [
  // Short URL seen in the Last Shared queue and on the Analytics page
  // The first part of this is the page identifier, the second part is the entity identifier
  // e.g. https://www.facebook.com/851509308217095_2725334540834553
  /^http(s?):\/\/www.facebook.com\/[0-9]+_[0-9]+$/,
  // Long URL seen just about everywhere else that short URLs are expanded to
  // The page identifier gets expanded to text form and the entity type (in this case "posts") has been added
  // https://www.facebook.com/londonlifetravel/posts/2725334540834553
  /^http(s?):\/\/www.facebook.com\/(photo(\.php|s)|permalink\.php|media|questions|notes|[^/]+\/(activity|posts))[/?].*/,
];

// Notification timeout in seconds
export const NOTIFICATION_TIMEOUT = 5;

// PhotoEditor SDK license key
export const PHOTOEDITOR_SDK_LICENSE_KEY =
  '{"api_token":"qYmCqYFr-OxUCkKB41rZgw","app_identifiers":["localhost","secure.echobox.com","staging.ebx.sh","review-fog.ebx.sh","review-iris.ebx.sh","review-penguin.ebx.sh","social.echobox.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","brush","filter","sticker","text","textdesign","transform"],"issued_at":1664894573,"minimum_sdk_version":"1.0","owner":"Echobox Ltd","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"Vq+IjMZNvvqRD8NTyigyfBHWTWn8Z3UVC/biKY1iyhepc3NQD3vsBE4fPN8eRgTNZxtcc7hphCNW4bjK/+75q+ixC2dH6YXVwkhPnUJnuMbGS2uDTreSUpmfew2vupZdMrwW5eFjMaxGq3VZ6AMxq7PmZ5+g5uXXOiPQiBndazEN3pGtJLEUU6GNVx2qNcDYiNZ7VR4ni2Qg7eTClH2OTAZcfmCI3hqcvkJZmpFQ4/J4YQLdtpxkWzBRqqOuhky5GdcAQy7wvkULnE/wJSb9h+OFR4kOwVeIJz2FOB7JOcIvzkAD44Dlgxwpx/ktWLWHpDKTxVIXfqSDPUPV77AOzyI6I4hn3oKDvIXUK7feeUEPj9/kgHj5J+4wyzXZF7Sq0Whs67T7xtAZzFN567QXMohHu9E/2GmEwSqekPmvq6rajXEAWO2/jmwkCOI1xSSJgib1o9r7Lpnx3bzsFxx+OgVcJTLuB8t4UBCr2EwdVf0BOVmmenYOWnSDOLicF7Gy6Doq7KI5wJh3Wr6miUurBz2xwJ3uIuSq2QWPsR8QzcstRcJp3lrhwVRFyCcwigMQKS27ZM94DUuh0K/HCJwvVtNHAoyuMUtpBwHGTf4wIprZY847KwMONOe3cB8CJuaFzAwB9jyheQL9UQl5tuS5oGSIK+1bW+CnSjWJMtMuEeY="}';
export const PHOTOEDITOR_SDK_DOWNSCALE_MINIMUM = 1280;
export const PHOTOEDITOR_SDK_UPSCALE_MINIMUM = 2560;
export const PHOTOEDITOR_SDK_UPSCALE_FACTOR = 0.5;

// Regexes used to identify "protected" errors
export const PROTECTED_ERRORS = [
  /An administrator has changed your permissions/,
];

// Refresh intervals in milliseconds
export const REFRESH_INTERVALS = {
  BASE_VALUE: 30 * 1000, // Base value from which several other values are defined
  FLASH_MESSAGES: 1 * 1000, // Check for flash message changes every second
  HEADER_RESIZE: 500, // Page content is moved below header if necessary every 500ms
  LOAD_MORE_ITEMS: 1000, // Check if items need to be added to media items page every second
  LOG_CURRENT_VERSION: 5 * 60 * 1000, // Log the version of the code the user is running every 5 min
  SAFARI_SIDEBAR: 1000, // Check for CSS changes for Safari
  SCROLL_TO_TOP: 500, // When entering page, scroll to top after 500ms
  STATUS_PAGE: 1 * 60 * 1000, // Status notifications are polled every minute
};

export const REFRESH_MULTIPLIERS = {
  ARTICLE_FEED: 4, // Article feed
  COGNITO_SESSION: 1, // AWS Cognito access token validity
  LEGACY_FAILED_SHARES: 1, // (legacy) Failed shares
  FAILED_SHARES: 2, // Failed shares
  GLOBAL_INFO: 4, // Global info
  LAST_SHARED: 1, // Last shared
  SCHEDULE_QUEUE: 1, // Scheduled queue
  APPROVALS: 1, // Approvals
  API_QUEUED_ITEMS_FIRST: 0.5, // Queued items - first count
  SELECTED_API_QUEUED_ITEMS: 1, // Queued items for selected API
  UNSELECTED_API_QUEUED_ITEMS: 10, // Queued items for unselected API
  VERSION_CHANGE: 8, // Version update check
};

/**
 * The number of sequential errors for a particular background update
 * before we display an error message to the user.
 */
export const REFRESH_ERROR_THRESHOLD = 3;

// The grace period (seconds) between the frontend seeing an older package version and adopting that version
// Required to avoid frontend fluctuating between older & newer versions during standard version upgrade
export const REVERT_GRACE_PERIOD_SECONDS = 120;

// Right-to-left configuration
export const RTL_CONFIGURATION = {
  // Account APIs which will have their text direction hard-coded as RTL
  ACCOUNT_APIS: [-1],
  // Minimum number of items of text to be collected before attempting language detection
  MIN_ITEMS_REQUIRED: 12,
  // Minimum length of text required for dynamic language detection
  MIN_TEXT_LENGTH: 50,
};

// Number of items to show in un-expanded schedule queue
export const SCHEDULE_QUEUE_DEFAULT_ITEMS = 7;

/**
 * An array of regexes which, if matched against part of the error/stacktrace, should be ignored by Sentry.
 * These patterns found in errors, are either not actionable, handled elsewhere, or are caused by external libraries.
 */
export const SENTRY_IGNORED_ERRORS = [
  // Method names of errors commonly thrown from draft-js and don't appear to manifest as user-facing issues
  /getCharacterList/,
  /getEntityAt/,
  /getBlockTree/,
  /getIn/,

  // Thrown and handled internally
  /EBX:INTERNAL - current property has changed/,

  // The 2 messages below are ignored in both Chrome and Firefox
  // Related discussion: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  /ResizeObserver loop limit exceeded/,
  /ResizeObserver loop completed with undelivered notifications./,

  // Common error thrown by the browser when a node is removed from the DOM before it is fully loaded
  // Doesn't appear to cause any user-facing issues
  // https://stackoverflow.com/questions/42956884/failed-to-execute-removechild-on-node
  /Failed to execute 'removeChild' on 'Node'/,

  // Ignore API errors
  /__sentry_xhr__/i,
  /HTTP Status 0/,

  // Suppress any errors coming from the photo editor sdk library.
  // The library is wrapped in an error boundary, and based on Sentry replays, they do not impact the UX.
  // https://echobox.sentry.io/issues/3793357523/events/da3353d5c8174055ba78e8f2f1507de9/?project=1211089
  /photoeditorsdk/,
];

// Log transactions and spans using Sentry performance API
export const SENTRY_LOG_SPANS = true;
export const SENTRY_LOG_TRANSACTIONS = true;

// Minimum number of APIs that must exist for the "share to all" option to be available
export const SHARE_ALL_MINIMUM_APIS = 3;

// Share URL placeholder
export const SHARE_URL_PLACEHOLDER = '[[shareURLPlaceholder]]';
export const SHARE_URL_PLACEHOLDER_REGEX = /\[\[shareURLPlaceholder\]\]/g;

// Toggle debug logging
export const SHOULD_LOG_DEBUG = false;

// Delay in ms before tooltips appear
export const TOOLTIP_DELAY = 600;

// Field length limit for Twitter posts
export const TWITTER_SETTINGS = {
  MAX_CHARS: 280,
  URL_LENGTH: 23,
  MAX_NUM_IMAGES: 4,
  MAX_NUM_GIFS: 1,
};

// Property ids which will automatically have all account APIs set to "verified"
export const VERIFIED_PROPERTIES = [
  1, 102, 321, 355, 370, 372, 375, 392, 435, 447, 456, 458, 730, 732, 733, 737,
  818, 822, 845, 950, 14095, 14097, 14105, 14125, 14127, 14129, 14142, 14145,
  14146, 14152, 14192, 14199, 14202, 14217, 14219, 14220, 14223, 14224, 14225,
  14226, 14228, 14229, 14231, 14232,
];

// Wootric NPS settings
export const WOOTRIC_ACCOUNT_TOKEN = 'NPS-edc98123';
