import { Auth } from 'aws-amplify';

import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

export default async function authVerifyUserEmail({
  verificationCode = mandatory('verificationCode'),
} = {}) {
  const guid = metrics.start('authVerifyUserEmail');
  logger.info('API:authVerifyUserEmail');

  try {
    const response = await Auth.verifyCurrentUserAttributeSubmit(
      'email',
      verificationCode,
    );
    metrics.end('authVerifyUserEmail', guid);
    return response;
  } catch (error) {
    metrics.fail('authVerifyUserEmail', guid);
    const cognitoError = handleCognitoError({
      originalError: error,
      errorLocation: 'api/authVerifyUserEmail',
    });
    throw cognitoError;
  }
}
