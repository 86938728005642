import {
  Check2Icon,
  CloseIcon,
  Flex,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { RSS_STATE_NAMES } from 'common/constants';
import { isRSSFeed } from 'common/feed';
import ReviewSection from 'components/setup/ReviewSection';
import { AccountFeed } from 'types';

interface ConnectedMessageProps {
  type: string;
  count: number;
}
const ConnectedMessage = ({ type, count }: ConnectedMessageProps) => {
  return (
    <Flex gap={2} alignItems="center">
      <Check2Icon color="green.600" />
      <Text size="sm" color="green.600" fontWeight="medium">
        {count} {type}
        {count !== 1 ? 's' : ''}
      </Text>
    </Flex>
  );
};

interface ReviewContentProps {
  accountFeeds: ReadonlyArray<AccountFeed>;
  onEdit: () => void;
}

const ReviewContent = ({ accountFeeds, onEdit }: ReviewContentProps) => {
  const activeAccountFeeds = accountFeeds.filter(
    (feed) => feed.syndFeedState === RSS_STATE_NAMES.ACTIVE,
  );

  const rssFeeds = activeAccountFeeds.filter((feed) =>
    isRSSFeed(feed.syndFeedType),
  );
  const syndFeeds = activeAccountFeeds.filter(
    (feed) => !isRSSFeed(feed.syndFeedType),
  );

  return (
    <ReviewSection title="Content" onEdit={onEdit}>
      {activeAccountFeeds.length > 0 ? (
        <>
          {rssFeeds.length > 0 && (
            <ConnectedMessage type="RSS feed" count={rssFeeds.length} />
          )}
          {syndFeeds.length > 0 && (
            <ConnectedMessage type="Sitemap" count={syndFeeds.length} />
          )}
        </>
      ) : (
        <Flex gap={2} alignItems="center">
          <CloseIcon size="5" color="gray.600" />
          <Text size="sm" color="gray.600" fontWeight="medium">
            Not connected
          </Text>
        </Flex>
      )}
    </ReviewSection>
  );
};

export default ReviewContent;
