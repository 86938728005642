import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialLinkedinIcon,
  SocialTiktokIcon,
  SocialTwitterIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getAPIsConnectError } from 'api/api';
import {
  getBitlyAPIs,
  getCurrentProperty,
  getCurrentPropertyId,
  getFacebookAPIs,
  getInstagramAPIs,
  getLinkedInAPIs,
  getTikTokAPIs,
  getTwitterAPIs,
  sortPages,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_ERROR_MESSAGES,
  API_STATES,
  API_TYPE_IDS,
  GLOBAL_INFO_STATES,
} from 'common/constants';
import * as logger from 'common/logger';
import { getSetting, isIGCabinetEnabled } from 'common/settings';
import { getQueryStringValue } from 'common/url';
import SocialPageCircle from 'components/misc/SocialPageCircle';
import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from 'components/settings/PagesTable';
import GlobalInfoContext from 'context/GlobalInfoContext';
import addNewAPI from 'process/addNewAPI';
import type { APITypeId } from 'types';

import Bitly from '../pages/Bitly';
import InstagramConnectionErrorModal from '../pages/InstagramConnectionErrorModal';
import SocialPageRow from '../pages/SocialPageRow';
import AddPageButton from './AddPageButton';

interface ConnectSocialPagesProps {
  isOnboarding?: boolean;
}

const ConnectSocialPages = ({
  isOnboarding = false,
}: ConnectSocialPagesProps) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const { global } = useContext(GlobalInfoContext);
  const [globalInfo, setGlobalInfo] = useState(global.getGlobalInfo());
  const [connectionErrorMessage, setConnectionErrorMessage] = useState<
    string | null
  >(null);
  const [showIGErrorModal, setShowIGErrorModal] = useState(false);

  const { accountAPIs } = getCurrentProperty({ globalInfo });
  const facebookAPIs = getFacebookAPIs({ accountAPIs });
  const twitterAPIs = getTwitterAPIs({ accountAPIs });
  const linkedInAPIs = getLinkedInAPIs({ accountAPIs });
  const instagramAPIs = getInstagramAPIs({ accountAPIs });
  const tikTokAPIs = getTikTokAPIs({ accountAPIs });

  const apiTypeId = sessionStorage.getItem('connectingAPITypeId');

  const isIGError =
    apiTypeId &&
    Number(apiTypeId) === API_TYPE_IDS.INSTAGRAM &&
    !isIGCabinetEnabled();

  useEffect(() => {
    const getError = async () => {
      try {
        const response = await getAPIsConnectError({
          requestToken,
        });
        setConnectionErrorMessage(response.message);
      } catch (error) {
        // If anything goes wrong ensure there is a default message
        setConnectionErrorMessage(API_ERROR_MESSAGES.API_UNKNOWN);
      }
    };

    const requestToken = getQueryStringValue({
      queryParams: search,
      name: 'apiConnectErrorRequestToken',
    });

    if (requestToken) {
      if (isIGError) {
        setShowIGErrorModal(true);
      } else {
        getError();
      }
    }
  }, [isIGError, search]);

  useEffect(() => {
    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.REFRESHING,
      allowUnderSetup: true,
      callback: () => {
        setGlobalInfo({ ...global.getGlobalInfo() });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewAPI = useCallback(
    (newApiTypeId: APITypeId) => async () => {
      const propertyId = getCurrentPropertyId({
        globalInfo,
      });

      logger.info(`ConnectSocialPages.handleNewAPI - type ${newApiTypeId}`);
      sessionStorage.setItem('isAddingAPI', 'true');
      sessionStorage.setItem('connectingAPITypeId', newApiTypeId.toString());
      await addNewAPI({
        propertyId,
        apiTypeId: newApiTypeId,
      });
    },
    [globalInfo],
  );

  const handleRemoveAPI = () => {
    // Always trigger a rerender when something has been removed
    setGlobalInfo({ ...global.getGlobalInfo() });
  };

  const closeModal = () => {
    setConnectionErrorMessage(null);
    // Remove query parameter from URL
    navigate(pathname, { replace: true });
  };

  const isFacebookConnectedToBitly = () => {
    if (facebookAPIs.length > 0) {
      const bitlyAPIs = getBitlyAPIs({ accountAPIs });
      const isBitlyConnected =
        bitlyAPIs.length === 1 && bitlyAPIs[0].apiStateId === API_STATES.ACTIVE;

      const isFacebookUnshorteningEnabled = getSetting({
        settingTypeId: ACCOUNT_SETTING_TYPES.FACEBOOK_UNSHORTENED_SHARING,
        propertyId: getCurrentPropertyId(),
      }).enabled;

      return isBitlyConnected && !isFacebookUnshorteningEnabled;
    }
    return false;
  };

  const showBitly = isFacebookConnectedToBitly();

  return (
    <>
      <Flex alignItems="center" gap={3} mb={4}>
        <AddPageButton onHandleApiType={handleNewAPI} />
        <HStack spacing="-2.5px">
          <SocialPageCircle>
            <SocialFacebookIcon />
          </SocialPageCircle>
          <SocialPageCircle>
            <SocialTwitterIcon />
          </SocialPageCircle>
          <SocialPageCircle>
            <SocialLinkedinIcon />
          </SocialPageCircle>
          <SocialPageCircle>
            <SocialInstagramIcon />
          </SocialPageCircle>
          <SocialPageCircle>
            <SocialTiktokIcon />
          </SocialPageCircle>
        </HStack>
      </Flex>
      <Flex direction="column" gap={3}>
        {facebookAPIs?.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    Facebook
                    {showBitly && (
                      <Box display="inline" ml="auto">
                        <Bitly disableMenu />
                      </Box>
                    )}
                  </Th>
                </Tr>
              </Thead>
              <Tbody background="base">
                {facebookAPIs.sort(sortPages).map((accountAPI) => (
                  <SocialPageRow
                    key={accountAPI.accountAPIId}
                    accountAPI={accountAPI}
                    onDelete={handleRemoveAPI}
                    isOnboarding={isOnboarding}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {twitterAPIs?.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    Twitter
                    <Box display="inline" ml="auto">
                      <Bitly />
                    </Box>
                  </Th>
                </Tr>
              </Thead>
              <Tbody background="base">
                {twitterAPIs.sort(sortPages).map((accountAPI) => (
                  <SocialPageRow
                    key={accountAPI.accountAPIId}
                    accountAPI={accountAPI}
                    onDelete={handleRemoveAPI}
                    isOnboarding={isOnboarding}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {linkedInAPIs?.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>LinkedIn</Th>
                </Tr>
              </Thead>
              <Tbody background="base">
                {linkedInAPIs.sort(sortPages).map((accountAPI) => (
                  <SocialPageRow
                    key={accountAPI.accountAPIId}
                    accountAPI={accountAPI}
                    onDelete={handleRemoveAPI}
                    isOnboarding={isOnboarding}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {instagramAPIs?.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Instagram</Th>
                </Tr>
              </Thead>
              <Tbody background="base">
                {instagramAPIs.sort(sortPages).map((accountAPI) => (
                  <SocialPageRow
                    key={accountAPI.accountAPIId}
                    accountAPI={accountAPI}
                    onDelete={handleRemoveAPI}
                    isOnboarding={isOnboarding}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {tikTokAPIs?.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>TikTok</Th>
                </Tr>
              </Thead>
              <Tbody background="base">
                {tikTokAPIs.sort(sortPages).map((accountAPI) => (
                  <SocialPageRow
                    key={accountAPI.accountAPIId}
                    accountAPI={accountAPI}
                    onDelete={handleRemoveAPI}
                    isOnboarding={isOnboarding}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>
      <InstagramConnectionErrorModal
        isOpen={showIGErrorModal}
        onClose={() => {
          setShowIGErrorModal(false);
          navigate(pathname, { replace: true });
        }}
        onTryAgain={() => {
          setShowIGErrorModal(false);
          navigate(`${pathname}?addNewPage=instagram`, { replace: true });
        }}
      />
      {connectionErrorMessage && (
        <Modal isOpen size="small" onClose={closeModal}>
          <Modal.Header>
            <Modal.Title>Connection error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Text>{connectionErrorMessage}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={closeModal}>OK</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ConnectSocialPages;
