import {
  Box,
  Button,
  Card,
  ChevronLeftIcon,
  Flex,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { IndividualHTTPEchoResponse } from 'types';

interface ContentFeedHTTPResponseProps {
  contentFeedResponse: IndividualHTTPEchoResponse;
  onBackClick: () => void;
}

const ContentFeedHTTPResponse = ({
  contentFeedResponse,
  onBackClick,
}: ContentFeedHTTPResponseProps) => {
  return (
    <Flex flexDirection="column" gap={4}>
      <div>
        <Button
          variant="secondary"
          onClick={onBackClick}
          leftIcon={<ChevronLeftIcon />}
        >
          Back to search
        </Button>
      </div>
      <Card overflowX="auto">
        <Card.Content gap={4} flexGrow={1}>
          <Card.Title>Content Feed HTTP Response</Card.Title>
          <Box as="pre" whiteSpace="pre-wrap" fontFamily="mono">
            {contentFeedResponse.responseBody}
          </Box>
        </Card.Content>
      </Card>
    </Flex>
  );
};

export default ContentFeedHTTPResponse;
