import {
  Button,
  FormControl,
  Input,
  Modal,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import getTrackerStatus from 'api/getTrackerStatus';
import { getCurrentPropertyId } from 'common/accountAPIs';
import { TRACKER_KEY } from 'common/constants';
import * as logger from 'common/logger';
import { useOnboardingSetupContext } from 'context/OnboardingContext';

export interface TestWebsiteTagModalProps {
  onClose: () => void;
  setIsWebsiteTagError: (isError: boolean) => void;
}

const TestWebsiteTagModal = ({
  onClose,
  setIsWebsiteTagError,
}: TestWebsiteTagModalProps) => {
  const [url, setUrl] = useState('');
  const [isError, setIsError] = useState(false);
  const { onStepChange, currentStep } = useOnboardingSetupContext();
  const propertyId = getCurrentPropertyId();

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    const { value } = e.target;
    setUrl(value);
  };

  const handleStartTest = async () => {
    try {
      // add http to url if it doesn't have any protocol
      const newUrl = url.startsWith('http') ? url : `http://${url}`;

      const { isInstalled } = await getTrackerStatus({ url: newUrl });
      if (isInstalled) {
        sessionStorage.setItem(`${TRACKER_KEY}-${propertyId}`, 'true');
        onStepChange(currentStep + 1);
        setIsWebsiteTagError(false);
      } else {
        sessionStorage.setItem(`${TRACKER_KEY}-${propertyId}`, 'false');
        setIsWebsiteTagError(true);
      }
      onClose();
    } catch (error: any) {
      if ('status' in error && error.status === 400) {
        setIsError(true);
      } else {
        logger.error({
          event: 'getTrackerStatus Error',
          error,
        });
      }
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Test Web Tag</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormControl isInvalid={isError}>
          <FormControl.FormLabel>
            Please enter any URL from your website to verify that the tag is
            installed correctly
          </FormControl.FormLabel>
          <Input
            value={url}
            onChange={handleUrlChange}
            data-cy-input="url"
            placeholder="https://your.site.com/page"
          />
          {isError && (
            <FormControl.FormErrorMessage>
              Please enter a valid URL
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button isDisabled={isError || !url} onClick={handleStartTest}>
          Start test
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestWebsiteTagModal;
