import { Header } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { HOST_TYPES } from '@ebx-auth/ebx-clientauth-frontend-sdk';
import postProvisionBenchmarkProperty from 'api/postProvisionBenchmarkProperty';
import {
  getCurrentPropertyId,
  getPropertyPermission,
} from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import {
  BENCHMARK_SUPPORTED_PROPERTIES,
  PERMISSION_TYPES,
  PRODUCT_TYPES,
  USER_TYPES,
} from 'common/constants';
import * as environment from 'common/environment';
import * as tracker from 'common/tracker';
import EnhancedPermissionsMenu from 'components/header/EnhancedPermissionsMenu';
import GlobalInfoContext from 'context/GlobalInfoContext';
import type { FixTypeLater } from 'types';
// TODO: Re-enable lazy loading, once we have a solution for server-side caching
// const EnhancedPermissionsMenu = lazyWithRetries(
//   () => import('components/header/EnhancedPermissionsMenu')
// );

const products = ['SOCIAL', 'EMAIL', 'BENCHMARKS'] as const;
const HUBSPOT_LANDING_PAGE_URL = 'https://info.echobox.com/echobox-benchmarks';
const STAFF_MENU_USER_GROUPS = [
  USER_TYPES.ECHOBOX_STAFF,
  USER_TYPES.SUPPORT_USER,
] as const;

type Product = (typeof products)[number];

const PRODUCT_VALUES: Record<Product, string> = {
  SOCIAL: 'Social',
  EMAIL: 'Email',
  BENCHMARKS: 'Benchmarks',
};

/* jscpd:ignore-start */
const getTrackingParams = ({
  before,
  after,
  globalInfo,
}: {
  before: Product;
  after: Product;
  globalInfo: FixTypeLater;
}) => {
  const currentPropertyId = getCurrentPropertyId({
    globalInfo,
  });

  return {
    Product: PRODUCT_VALUES[after],
    'Product Before': PRODUCT_VALUES[before],
    // PropertyID is replaced with "Property ID" in common/tracker.js
    // TODO: Once SSO between products is complete, update PropertyID to property id of product the user is switching to
    PropertyID: 'null',
    'Property ID before': currentPropertyId,
  };
};

const NEWSLETTERS_URLS = {
  OVERVIEW: 'https://www.echobox.com/email',
  SIGNIN: 'https://email.echobox.com/signin',
};

const ProductTabs = () => {
  const { global } = React.useContext(GlobalInfoContext);
  const [
    isShowingEnhancedPermissionsMenu,
    setIsShowingEnhancedPermissionsMenu,
  ] = React.useState(false);
  const { user } = global.getGlobalInfo();
  /* jscpd:ignore-end */
  const cognitoGroups = authentication.getCognitoGroups();
  const isExistingNewslettersUser =
    cognitoGroups?.includes(USER_TYPES.NEWSLETTERS_USER) ||
    cognitoGroups?.includes(USER_TYPES.ECHOBOX_STAFF);

  const isAdmin =
    getPropertyPermission({
      propertyId: getCurrentPropertyId({
        globalInfo: global.getGlobalInfo(),
      }),
      globalInfo: global.getGlobalInfo(),
    }) === PERMISSION_TYPES.ADMIN;

  const benchmarksURL =
    environment.getHostType() === HOST_TYPES.PROD
      ? 'https://benchmarks.echobox.com'
      : 'https://stage.bm.echobox.dev';

  const currentPropertyId = getCurrentPropertyId();

  const getURL = (value: Product) => {
    let newURL = '';

    switch (value) {
      case PRODUCT_TYPES.SOCIAL: {
        newURL = '/';
        break;
      }
      case PRODUCT_TYPES.NEWSLETTERS: {
        newURL = isExistingNewslettersUser
          ? NEWSLETTERS_URLS.SIGNIN
          : NEWSLETTERS_URLS.OVERVIEW;
        break;
      }
      default:
    }
    return newURL;
  };

  const handleChangeProduct = async (value: Product) => {
    if (value !== PRODUCT_TYPES.SOCIAL) {
      tracker.track({
        eventName: 'Switch Product',
        trackingParams: getTrackingParams({
          before: 'SOCIAL',
          after: value,
          globalInfo: global.getGlobalInfo(),
        }),
      });
    }

    if (value === PRODUCT_TYPES.BENCHMARKS) {
      if (
        !BENCHMARK_SUPPORTED_PROPERTIES.has(Number(currentPropertyId)) ||
        !isAdmin
      ) {
        window.open(HUBSPOT_LANDING_PAGE_URL, '_blank');
        return;
      }

      try {
        const response = await postProvisionBenchmarkProperty();
        if (response.status === 200) {
          if (!cognitoGroups?.includes(USER_TYPES.BENCHMARK_USER)) {
            await authentication.refreshCognitoTokens();
          }
          window.open(benchmarksURL, '_self');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleLogoClick = () => {
    if (STAFF_MENU_USER_GROUPS.includes(user.userType)) {
      setIsShowingEnhancedPermissionsMenu(true);
    }
  };

  const currentVersion = APP_VERSION;

  const routes = {
    SOCIAL: getURL('SOCIAL'),
    EMAIL: getURL('EMAIL'),
  };

  return (
    <div>
      <Header.ProductMenu
        activeProduct="SOCIAL"
        onProductClick={handleChangeProduct}
        onLogoClick={handleLogoClick}
        appVersion={`Echobox version ${currentVersion}`}
        href={routes}
      />
      {isShowingEnhancedPermissionsMenu && (
        <React.Suspense>
          <EnhancedPermissionsMenu
            eventHandlers={{
              handleHideEnhancedPermissionsMenu: () =>
                setIsShowingEnhancedPermissionsMenu(false),
            }}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default ProductTabs;
