import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { canApplyImageOverlay } from 'common/mediaItem';
import { getSetting } from 'common/settings';
import Tooltip from 'components/misc/Tooltip';
import {
  toggleImageOverlay,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { addImageOverlay } from 'helpers/imageOverlay';
import { findImg } from 'helpers/images';
import { FixTypeLater } from 'types';

interface ImageOverlayOptionsProps {
  guid: string;
  hasImageOverlay: boolean;
  mediaItem: FixTypeLater;
}

const ImageOverlayOptions = ({
  guid,
  hasImageOverlay,
  mediaItem,
}: ImageOverlayOptionsProps) => {
  const id = `imageoverlay-toggle-${guid}`;

  const { dispatch } = useComposeBoxContext();

  const [isEnabled, setIsEnabled] = useState(false);
  const [error, setError] = useState('');

  const canCustomiseLinkPosts = MediaItem.getCanCustomiseLinkPosts({
    mediaItem,
  });

  const abInfo = MediaItem.getABInfo({ mediaItem });
  const hasSameABVariations = abInfo.isABVariation
    ? MediaItem.isABVariationsSame({ mediaItem })
    : true;

  const imageURLs = JSON.stringify(MediaItem.getImageURLs({ mediaItem }));

  useEffect(() => {
    async function getImageOverlayStatus() {
      const response = await canApplyImageOverlay({
        canCustomiseLinkPosts,
        hasSameABVariations,
        imageURLs,
      });
      setIsEnabled(response.isEnabled);
      if (response.error) {
        setError(response.error);
      }
    }
    getImageOverlayStatus();
  }, [canCustomiseLinkPosts, hasSameABVariations, imageURLs]);

  const handleImageOverlayToggle = async () => {
    logger.info(`ImageOVerlayOptions:handleImageOverlayToggle - guid ${guid}`);
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
    const setting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
      propertyId: getPropertyIdForAccountAPIId({ accountAPIId }),
      accountAPIId,
    });

    const guidImg = findImg(guid);

    const { imageOverlayURL } = setting.dataJSON;
    const imageURL = MediaItem.getImageURLs({ mediaItem })[0];
    const resultURL = await addImageOverlay({
      imageURL,
      overlayURL: imageOverlayURL,
      targetWidth: guidImg.width(),
      targetHeight: guidImg.height(),
    });

    dispatch(toggleImageOverlay({ guid, resultURL, imageOverlayURL }));
  };

  const isDisplayInfoTooltip = !isEnabled;
  const labelClassName = `flex-grow-1${!isEnabled ? ' op-40' : ''}`;

  return (
    <div className="imageoverlaytest" id="imageOverlayToggle">
      <div className="d-flex align-items-center mb-2">
        <input
          type="checkbox"
          id={id}
          name="imageoverlay"
          checked={hasImageOverlay && isEnabled}
          onChange={handleImageOverlayToggle}
          disabled={!isEnabled}
        />
        <label
          htmlFor={id}
          className={clsx(labelClassName, !isEnabled && 'saving_locked')}
        >
          Image Overlay
        </label>
        {isDisplayInfoTooltip && (
          <Tooltip id="imageoverlayicon" label={error}>
            <img
              className="info_icon"
              src="/img/icons/ic-information.svg"
              alt=""
              data-tip="true"
              data-for="imageoverlayicon"
              data-iscapture="true"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ImageOverlayOptions;
