import { TIME_SENSITIVITY_TYPES } from 'common/constants';
import type { TimeSensitivityType } from 'types';

interface ItemTimeSensitivityProps {
  timeSensitivityTypeId?: TimeSensitivityType | null;
  eventHandlers: {
    handleTimeSensitivityClick: (
      timeSensitivityType: TimeSensitivityType,
    ) => void;
  };
}

const ItemTimeSensitivity = ({
  timeSensitivityTypeId = null,
  eventHandlers,
}: ItemTimeSensitivityProps) => {
  const activeClasses = {
    breaking:
      timeSensitivityTypeId === TIME_SENSITIVITY_TYPES.BREAKING
        ? 'saving_locked'
        : '',
    normal:
      timeSensitivityTypeId === TIME_SENSITIVITY_TYPES.TODAY
        ? 'saving_locked'
        : '',
    evergreen:
      timeSensitivityTypeId === TIME_SENSITIVITY_TYPES.EVERGREEN
        ? 'saving_locked'
        : '',
  };

  return (
    <div
      className="btn-group align-self-start"
      role="group"
      aria-label="Time sensitivity"
      data-cy-id="timeSensitivity"
    >
      <a
        className={`btn border-0 ${activeClasses.breaking}`}
        onClick={() =>
          eventHandlers.handleTimeSensitivityClick(
            TIME_SENSITIVITY_TYPES.BREAKING,
          )
        }
        data-cy-action="breaking"
      >
        Breaking
      </a>
      <a
        className={`btn border-left border-right border-top-0 border-bottom-0 ${activeClasses.normal}`}
        onClick={() =>
          eventHandlers.handleTimeSensitivityClick(TIME_SENSITIVITY_TYPES.TODAY)
        }
        data-cy-action="normal"
      >
        Normal
      </a>
      <a
        className={`btn border-0 ${activeClasses.evergreen}`}
        onClick={() =>
          eventHandlers.handleTimeSensitivityClick(
            TIME_SENSITIVITY_TYPES.EVERGREEN,
          )
        }
        data-cy-action="evergreen"
      >
        Evergreen
      </a>
    </div>
  );
};

export default ItemTimeSensitivity;
