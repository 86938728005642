import { POST_TYPES, SOCIAL_CHANNELS } from 'common/constants';
import { PostType, SocialChannel } from 'types';
import { validateReelsThumbnailImage } from './common';

export function getMediaSizeLimits({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  const min = null;
  let max = null;

  if (postType === POST_TYPES.LINK || postType === POST_TYPES.STATUS) {
    max = 4 * 1024 * 1024; // 4MB
  } else if (
    postType === POST_TYPES.VIDEO &&
    socialChannel === SOCIAL_CHANNELS.REEL
  ) {
    max = 1024 * 1024 * 1024; // 1GB
  } else if (
    postType === POST_TYPES.VIDEO &&
    socialChannel === SOCIAL_CHANNELS.FEED
  ) {
    max = 2 * 1024 * 1024 * 1024; // 2GB
  }
  return { min, max };
}

export function getImageAspectRatios({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  let min = null;
  let max = null;

  if (postType === POST_TYPES.LINK) {
    min = 1.91;
    max = 1.91;
  } else if (
    postType === POST_TYPES.VIDEO &&
    socialChannel === SOCIAL_CHANNELS.REEL
  ) {
    min = 0.5625;
    max = 0.5625;
  }

  return { min, max };
}

export function getVideoDurationLimits({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  if (postType === POST_TYPES.VIDEO && socialChannel === SOCIAL_CHANNELS.REEL) {
    return {
      min: 3,
      max: 90,
    };
  }

  return null;
}

export function thumbnailImageValidator({
  socialChannel,
  fileSize,
  imageDimensions,
}: {
  socialChannel: SocialChannel;
  fileSize?: number;
  imageDimensions?: { width: number; height: number };
}) {
  if (fileSize) {
    const maxFileSize = 10 * 1024 * 1024; // 10MB
    if (fileSize > maxFileSize) {
      return `Thumbnails must have a file size less than 10MB`;
    }
  }

  if (imageDimensions) {
    return validateReelsThumbnailImage({ socialChannel, imageDimensions });
  }

  return null;
}
