import {
  Box,
  ChevronRightIcon,
  Flex,
  IconProps,
  Image,
  ImageIcon,
  ImageProps,
  Text,
  TextIcon,
  VideoNewIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import useThumbnail from 'hooks/useThumbnail';

type SettingsCardType = 'video' | 'image' | 'text';

const ICON_MAP: Record<SettingsCardType, React.FunctionComponent<IconProps>> = {
  video: VideoNewIcon,
  image: ImageIcon,
  text: TextIcon,
};

interface BorderedImageProps extends ImageProps {
  src: string;
}
const BorderedImage = ({ src, ...props }: BorderedImageProps) => {
  return (
    <Box boxShadow="border" h="full" borderRadius="lg" overflow="hidden">
      <Image
        borderRadius="lg"
        p="1px"
        h="full"
        objectFit="cover"
        src={src}
        {...props}
      />
    </Box>
  );
};

const TextCard = ({ text, onClick }: { text: string; onClick: () => void }) => {
  return (
    <SettingsCard type="text" onClick={onClick}>
      <Text
        size="sm"
        textAlign="start"
        w="250px"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {text}
      </Text>
    </SettingsCard>
  );
};

const ImageCard = ({
  imageURL,
  onClick,
}: {
  imageURL: string;
  onClick: () => void;
}) => {
  return (
    <SettingsCard type="image" onClick={onClick}>
      <BorderedImage w="76px" src={imageURL} />
    </SettingsCard>
  );
};

const VideoCard = ({
  videoURL,
  onClick,
}: {
  videoURL: string;
  onClick: () => void;
}) => {
  const thumbnailURL = useThumbnail({ videoURL });

  return (
    <SettingsCard type="video" onClick={onClick}>
      {thumbnailURL && <BorderedImage w="24px" src={thumbnailURL} />}
    </SettingsCard>
  );
};

interface SettingsCardProps {
  type: SettingsCardType;
  onClick?: () => void;
  isDisabled?: boolean;
  children?: React.ReactNode;
}

const SettingsCard = ({
  type,
  onClick,
  isDisabled = false,
  children,
}: SettingsCardProps) => {
  const Icon = ICON_MAP[type];
  return (
    <Flex
      as="button"
      onClick={onClick}
      aria-label={`Edit ${type}`}
      h={12}
      border="none"
      borderRadius="lg"
      p={0}
      pos="relative"
      _before={{
        content: '""',
        borderRadius: 'lg',
        pos: 'absolute',
        inset: 0,
        boxShadow: 'border',
      }}
      _hover={{
        boxShadow: 'button.hover',
      }}
      overflow="hidden"
      flexShrink={0}
      disabled={isDisabled}
      _disabled={{
        _hover: {
          boxShadow: 'none',
        },
      }}
    >
      <Box bg="surface.secondary" p={3} boxShadow="borderRight">
        <Icon
          aria-hidden
          size={6}
          color={isDisabled ? 'gray.500' : 'gray.600'}
        />
      </Box>
      <Flex
        gap={3}
        py={type === 'text' ? 2 : 1}
        px={3}
        bg="surface.primary"
        h="full"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        {children}
        <ChevronRightIcon
          ml="auto"
          aria-hidden
          color={isDisabled ? 'gray.300' : 'graphic.primary'}
          size={5}
        />
      </Flex>
    </Flex>
  );
};

export default Object.assign(SettingsCard, { TextCard, ImageCard, VideoCard });
