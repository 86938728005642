import * as cookie from 'common/cookie';

import { HOST_TYPES, LOGIN_URLS, LOOPBACK_ADDRESS } from './constants';

const getHostname = () => {
  if (window.location.hostname === LOOPBACK_ADDRESS) {
    return 'dev';
  }

  const host = window.location.host.split('.')[0];
  switch (host) {
    case 'social':
      return 'social';
    case 'secure-stage':
    case 'staging':
    case 'stage':
      return 'secure-stage';
    default:
      return 'local';
  }
};

function getHostType(hostname = window.location.hostname) {
  const tld = cookie.getTLD(hostname);

  switch (tld) {
    case 'echobox.dev':
      return HOST_TYPES.STAGING;
    case 'echobox.com':
      return HOST_TYPES.PROD;
    default:
      return HOST_TYPES.DEV;
  }
}

const getLoginUrl = () => {
  const hostType = getHostType();
  switch (hostType) {
    case HOST_TYPES.STAGING:
      return LOGIN_URLS.STAGING;
    case HOST_TYPES.PROD:
      return LOGIN_URLS.PROD;
    default:
      return LOGIN_URLS.DEV;
  }
};

export { getHostType, getHostname, getLoginUrl };
