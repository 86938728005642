import * as React from 'react';

import { getAPITypeId, getCurrentAccountAPIId } from 'common/accountAPIs';
import { getAutofeedSettings } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import {
  getAutofeedSettingsTrackingParams,
  getNetworkAndPageName,
} from 'helpers/tracking';

export const useErrorTracking = ({
  errorMessage,
  errorType,
}: {
  errorMessage: string;
  errorType: string;
}) => {
  React.useEffect(() => {
    const accountAPIId = getCurrentAccountAPIId();

    tracker.track({
      eventName: 'Display Frontend Error',
      trackingParams: {
        ...getAutofeedSettingsTrackingParams({
          autofeedSettings: getAutofeedSettings(),
        }),
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Social Network': getSocialNetworkName({
          apiTypeId: getAPITypeId({ accountAPIId }),
        }),
        '# Errors Displayed': 1,
        'Error Message': errorMessage,
        'Error Type': errorType,
        'Error Location': 'High Priority Container',
      },
    });
  }, [errorMessage, errorType]);
};
