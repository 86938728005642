import { DEFAULT_VALUES, TEXT_CASES } from 'common/constants';
import { createTimestamp, getDate, toLocalString } from 'common/datetimepicker';
import { cloneObject, isNullOrUndefined } from 'common/utility';
import type { TextCase } from 'types';

function createTimeRange(from?: number | null, to?: number | null) {
  // Input - from and to times, either of which could be undefined
  // Output - empty if neither parameter is defined
  //          "from" if just one parameter is supplied
  //          "from,to" if both parameters are supplied
  if (isNullOrUndefined(from)) {
    return '';
  }
  if (isNullOrUndefined(to)) {
    return `${from}`;
  }
  return `${from},${to}`;
}

/**
 * endOfToday - Returns a Unix timestamp representing the end of today
 */
function endOfToday() {
  const dateToday = getDate(toLocalString(getUnixTimestamp()));

  // This should never be hit, but is required for typescript
  if (dateToday === null) {
    return null;
  }

  return createTimestamp({
    date: dateToday,
    time: '23:59',
  });
}

/**
 * getDateFromUnix - Returns a date object from a Unix timestamp
 *
 * @param timestamp
 * @return object containing date details:
 *                    hour - hour 0-23
 *                    hourZero - hour as string including leading zero if required e.g. 04, 13
 *                    minute - minute 0-59
 *                    minuteZero - minute as string including leading zero if required e.g. 03, 55
 *                    day - day 1-31
 *                    dayName - day name e.g. Monday, Friday
 *                    month - month 1-12
 *                    monthName - month name e.g. March, September
 *                    year - year (four digits)
 *                    slang - 'Yesterday', 'Today', 'Tomorrow' or empty
 */
function getDateFromUnix(timestamp: number) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const dayOfWeek = date.getDay();
  let dayName = '';
  switch (dayOfWeek) {
    case 0:
      dayName = 'Sunday';
      break;
    case 1:
      dayName = 'Monday';
      break;
    case 2:
      dayName = 'Tuesday';
      break;
    case 3:
      dayName = 'Wednesday';
      break;
    case 4:
      dayName = 'Thursday';
      break;
    case 5:
      dayName = 'Friday';
      break;
    case 6:
      dayName = 'Saturday';
      break;
    default:
  }

  let month = date.getMonth();
  let monthName = '';
  switch (month) {
    case 0:
      monthName = 'January';
      break;
    case 1:
      monthName = 'February';
      break;
    case 2:
      monthName = 'March';
      break;
    case 3:
      monthName = 'April';
      break;
    case 4:
      monthName = 'May';
      break;
    case 5:
      monthName = 'June';
      break;
    case 6:
      monthName = 'July';
      break;
    case 7:
      monthName = 'August';
      break;
    case 8:
      monthName = 'September';
      break;
    case 9:
      monthName = 'October';
      break;
    case 10:
      monthName = 'November';
      break;
    case 11:
      monthName = 'December';
      break;
    default:
  }
  month += 1;

  const year = date.getFullYear();

  // Work out if we need to adjust the result due to any time zone changes
  // that have occurred between now and the target date
  const startOfDay = new Date().setHours(0, 0, 0, 0);
  const timeZoneOffsets = {
    now: new Date(startOfDay).getTimezoneOffset() * 60 * 1000,
    then: date.getTimezoneOffset() * 60 * 1000,
  };
  const timeZoneDifference = timeZoneOffsets.then - timeZoneOffsets.now;
  const dateOffset = Math.floor(
    (date.getTime() - startOfDay - timeZoneDifference) / (24 * 60 * 60 * 1000),
  );

  let slang = '';
  if (dateOffset === 0) {
    slang = 'Today';
  } else if (dateOffset === 1) {
    slang = 'Tomorrow';
  } else if (dateOffset === -1) {
    slang = 'Yesterday';
  } else {
    slang = '';
  }

  // Add leading zeros to minutes & hours if < 10
  let minuteZero = '';
  let hourZero = '';
  if (minute.toString().length < 2) {
    // Add leading zeros
    minuteZero = `0${minute}`;
  } else {
    minuteZero = minute.toString();
  }

  if (hour.toString().length < 2) {
    // Add leading zeros
    hourZero = `0${hour}`;
  } else {
    hourZero = hour.toString();
  }

  const dateObject = {
    hour,
    hourZero,
    minute,
    minuteZero,
    day,
    dayName,
    month,
    monthName,
    year,
    slang,
    dateOffset,
  };
  return dateObject;
}

/**
 * getFormattedDateFromUnix - Returns formatted date string from a Unix timestamp
 *
 * @param timestamp - date/time to be formatted
 * @param twelveHourFormat - twelveHourFormat - flag indicating whether 12-hour format should be used
 * @param textCase - textCase - format of the resulting text
 * @param useLongForm - useLongForm - flag indicating whether the text should be in long format
 * @return examples:
 *    'Today 14:00'
 *    'Yesterday 3:45pm'
 *    'Mar 21, 09:30'
 */
function getFormattedDateFromUnix({
  timestamp,
  twelveHourFormat = false,
  textCase = TEXT_CASES.CAMEL,
  useLongForm = false,
}: {
  timestamp: number;
  twelveHourFormat?: boolean;
  textCase?: TextCase;
  useLongForm?: boolean;
}) {
  const showTime = getUnixTimestamp() - timestamp < 30 * 24 * 60 * 60;

  let timeFormatted;
  const { timeDetail: timeDetailWithoutAmpm, ampm } = getFormattedTimeFromUnix({
    timestamp,
    twelveHourFormat,
    textCase,
  });
  const timeDetail = {
    ...timeDetailWithoutAmpm,
    ampm,
  };

  let at;
  let on;
  if (textCase === TEXT_CASES.UPPER) {
    timeDetail.slang = timeDetail.slang.toUpperCase();
    at = 'AT';
    on = 'ON';
    timeDetail.ampm = timeDetail.ampm.toUpperCase();
  } else if (textCase === TEXT_CASES.LOWER) {
    timeDetail.slang = timeDetail.slang.toLowerCase();
    at = 'at';
    on = 'on';
  } else {
    at = 'at';
    on = 'On';
  }
  if (!showTime) {
    at = ',';
  }
  const timeOrYear = showTime
    ? `${timeDetail.hourZero}:${timeDetail.minuteZero}${timeDetail.ampm}`
    : timeDetail.year;

  if (useLongForm) {
    if (timeDetail.slang !== '') {
      timeFormatted = `${timeDetail.slang} ${at} ${timeOrYear}`;
    } else {
      timeFormatted = `${on} ${timeDetail.monthName.substring(0, 3)} ${
        timeDetail.day
      } ${at} ${timeOrYear}`;
    }
  } else if (timeDetail.slang !== '') {
    timeFormatted = `${timeDetail.slang}, ${timeOrYear}`;
  } else {
    timeFormatted = `${timeDetail.monthName.substring(0, 3)} ${
      timeDetail.day
    }, ${timeOrYear}`;
  }

  return timeFormatted;
}

/**
 * getFormattedDuration - Returns a value as a whole number of seconds, minutes,
 *                        hours or days (whichever is the most appropriate)
 *
 * @param duration - duration in seconds
 * @return - String representation in the appropriate order of magnitude - examples:
 *                    '45 seconds' - when duration is between 0 and 59 seconds
 *                    '3 minutes' - when duration is between 60 and 3,599 seconds
 *                    '6 hours' - when duration is between 3,600 and 86,399 seconds
 *                    '14 days' - when duration is 86,400 seconds or more
 */
function getFormattedDuration(duration: string) {
  try {
    const seconds = parseInt(duration, 10);
    if (seconds < 60) {
      return `${seconds} seconds`;
    }
    if (seconds < 3600) {
      return `${Math.floor(seconds / 60)} minutes`;
    }
    if (seconds < 86400) {
      return `${Math.floor(seconds / 3600)} hours`;
    }
    return `${Math.floor(seconds / 86400)} days`;
  } catch (error) {
    return null;
  }
}

/**
 * getFormattedShareTimeFromUnix - Returns a textual description of an
 *                                 estimated share time
 *
 * @param timestamp - Unix timestamp
 * @param twelveHourFormat - Use 12-hour time format yes/no
 * @return String representation of specific time - examples:
 *                    'in < 5 min'
 *                    'in ~ 5 min'
 *                    'in ~ 10 min'
 *                    'in ~ 15 min'
 *                    'in ~ 30 min'
 *                    'in ~ 45 min'
 *                    'in ~ 1h'
 *                    'in ~ 2h'
 *                    'in ~ 3h'
 *                    'today at ~ 1:30pm'
 *                    'tomorrow at ~ 18:45'
 */
function getFormattedShareTimeFromUnix({
  timestamp,
  twelveHourFormat = false,
}: {
  timestamp: number;
  twelveHourFormat?: boolean;
}) {
  // Calculate difference (in seconds) between now and the specified time
  const timeDifference = timestamp - getUnixTimestamp();

  // Work out how to render this as a time difference string
  let result;
  const MINUTES = 60;
  if (timeDifference < 5 * MINUTES) {
    // Less than five minutes
    result = 'in <5 min';
  } else if (timeDifference < 7 * MINUTES) {
    // Less than seven minutes
    result = 'in ~5 min';
  } else if (timeDifference < 12 * MINUTES) {
    // Less than seven minutes
    result = 'in ~10 min';
  } else if (timeDifference < 17 * MINUTES) {
    // Less than seven minutes
    result = 'in ~15 min';
  } else if (timeDifference < 35 * MINUTES) {
    // Less than seven minutes
    result = 'in ~30 min';
  } else if (timeDifference < 50 * MINUTES) {
    // Less than seven minutes
    result = 'in ~45 min';
  } else if (timeDifference < 75 * MINUTES) {
    // Less than seven minutes
    result = 'in ~1h';
  } else if (timeDifference < 135 * MINUTES) {
    // Less than seven minutes
    result = 'in ~2h';
  } else if (timeDifference < 195 * MINUTES) {
    // Less than seven minutes
    result = 'in ~3h';
  } else {
    result = getFormattedDateFromUnix({
      timestamp,
      twelveHourFormat,
      textCase: TEXT_CASES.LOWER,
      useLongForm: true,
    });
  }

  return result;
}

/**
 * getFormattedTimeslotsFromUnix - Returns textual description of timeslot
 *
 * @param minTime - Unix timestamp, start of timeslot
 * @param maxTime - Unix timestamp, end of timeslot
 * @param twelveHourFormat - Use 12-hour time format yes/no
 * @return String representation of specified timeslot - examples:
 *     'Today, 12:00 to 15:00'
 *     'Today, 12:00 to Tomorrow, 12:00'
 *     'Tomorrow, 3.30pm to Apr 25, 9:00am'
 *     'Jun 18, 6:00pm to 9:00pm'
 */
function getFormattedTimeslotsFromUnix({
  minTime,
  maxTime,
  twelveHourFormat = false,
}: {
  minTime: number;
  maxTime: number;
  twelveHourFormat?: boolean;
}) {
  const minDetails = getDateFromUnix(minTime);
  const maxDetails = getDateFromUnix(maxTime);
  const minDayString = getFormattedDateFromUnix({
    timestamp: minTime,
    twelveHourFormat,
  });
  const maxDayString = getFormattedDateFromUnix({
    timestamp: maxTime,
    twelveHourFormat,
  });

  const NOW = getUnixTimestamp();
  const THIRTY_DAYS_AGO = 30 * 24 * 60 * 60;

  if (NOW - maxTime > THIRTY_DAYS_AGO && NOW - minTime > THIRTY_DAYS_AGO) {
    if (maxDetails.day === minDetails.day) {
      return minDayString;
    }
    return `${minDayString.split(',')[0].trim()} - ${maxDayString}`;
  }

  if (maxDetails.day === minDetails.day) {
    return `${minDayString} - ${maxDayString.split(',')[1].trim()}`;
  }

  return `${minDayString} - ${maxDayString}`;
}

/**
 * getFormattedTimeFromUnix - Returns formatted time string from a Unix timestamp
 *
 * @param timestamp  - Unix timestamp
 * @param twelveHourFormat - Use 12-hour time format yes/no
 * @param textCase - Specifies case to use when formatting string
 * @return String representation of specified timestamp - examples:
 *     '13:00'
 *     '1:00pm'
 */
function getFormattedTimeFromUnix({
  timestamp,
  twelveHourFormat = false,
  textCase = TEXT_CASES.CAMEL,
}: {
  timestamp: number;
  twelveHourFormat?: boolean;
  textCase?: TextCase;
}) {
  const timeDetail = getDateFromUnix(timestamp);

  let ampm = '';
  if (twelveHourFormat === true) {
    timeDetail.hourZero = timeDetail.hour.toString();
    // Check if am/pm setting switched on
    if (timeDetail.hour >= 12) {
      ampm = 'pm';
      if (timeDetail.hour > 12) {
        timeDetail.hourZero = (timeDetail.hour - 12).toString();
      }
    } else {
      ampm = 'am';
    }
    // Quick fix here for the first hour of the day which comes up as 00 when it should be 12
    if (timeDetail.slang !== '' && ampm === 'am' && timeDetail.hour === 0) {
      timeDetail.hourZero = '12';
    }
  }

  if (timeDetail.slang !== '') {
    let slang = timeDetail.slang;
    if (textCase === TEXT_CASES.UPPER) {
      slang = slang.toUpperCase();
    } else if (textCase === TEXT_CASES.LOWER) {
      slang = slang.toLowerCase();
    }
    timeDetail.slang = slang;
  }

  return {
    timeDetail,
    ampm,
  };
}

/**
 * getSpecificTime - Returns a timestamp for the current date/time rounded up
 *                   to the nearest five-minute interval
 *
 * @return timestamp
 */
function getSpecificTime() {
  // Get current date/time
  let datetimeNow = getDateFromUnix(getUnixTimestamp());
  // Round up to the nearest five minute interval
  datetimeNow = roundToInterval(datetimeNow);
  return getUnixTimestampFromOffset(datetimeNow);
}

/**
 * getTimeslot - Returns a timeslot ranging from the current date/time
 *               rounded up to the nearest five-minute interval
 *               to that start time plus the system default timeslot duration
 *
 * @return timeslot start and end timestamps
 */
function getTimeslot() {
  let timeslotStart = getDateFromUnix(getUnixTimestamp());
  // Round up to the nearest five minute interval
  timeslotStart = roundToInterval(timeslotStart);
  // Default end time = start time plus default timeslot duration
  let hour = timeslotStart.hour + DEFAULT_VALUES.TIMESLOT_DURATION;
  let dateOffset = timeslotStart.dateOffset;
  const minute = timeslotStart.minute;
  if (hour >= 24) {
    hour -= 24;
    dateOffset += 1;
  }

  // Round up to the nearest five minute interval
  const timeslotEnd = roundToInterval({
    hour,
    dateOffset,
    minute,
  });

  return [
    getUnixTimestampFromOffset(timeslotStart),
    getUnixTimestampFromOffset(timeslotEnd),
  ];
}

/**
 * getTimeFilterRange - Returns from/to range to use as date filter by share and analytics pages
 *
 * @param timeRange - number of hours to go back
 * @return - { fromTime, toTime } - timestamps to use for date range
 */
function getTimeFilterRange(timeRange: number) {
  const fromTime = getUnixTimestamp() - timeRange * 60 * 60;
  const toTime = null;
  return { fromTime, toTime };
}

/**
 * getUnixTimestamp - Returns (current) JavaScript date/time as Unix timestamp
 *
 * @param dateObj - date object, if not supplied defaults to now
 * @return Unix timestamp
 */
function getUnixTimestamp(dateObj = new Date()) {
  return Math.round(dateObj.getTime() / 1000);
}

/**
 * getUnixTimestampFromOffset - Returns Unix timestamp date specified as day / hour / minute offsets
 *
 * @param offset offset object
 * @return Unix timestamp
 */
function getUnixTimestampFromOffset(offset: {
  dateOffset: number;
  hour: number;
  minute: number;
}) {
  return (
    new Date().setHours(0, 0, 0, 0) / 1000 +
    offset.dateOffset * 24 * 60 * 60 +
    offset.hour * 60 * 60 +
    offset.minute * 60
  );
}

function roundToInterval<
  TTimeObject extends { hour: number; minute: number; dateOffset: number },
>(
  timeObject: TTimeObject,
): TTimeObject & { minuteZero: string; hourZero: string } {
  const timeRounded = cloneObject(timeObject);
  const timeToInterval = timeRounded.minute % 5;
  // Round the minutes down to the nearest five minute interval
  timeRounded.minute -= timeToInterval;
  // Now add five minutes
  timeRounded.minute += 5;
  // Add another five minutes if we are within two minutes of the next interval
  if (timeToInterval >= 3) {
    timeRounded.minute += 5;
  }
  // Update hours / days if necessary
  if (timeRounded.minute >= 60) {
    timeRounded.minute -= 60;
    timeRounded.hour += 1;
    if (timeRounded.hour >= 24) {
      timeRounded.hour -= 24;
      timeRounded.dateOffset += 1;
    }
  }
  // Update display minutes and hours fields
  const minuteZero =
    timeRounded.minute.toString().length < 2
      ? `0${timeRounded.minute}`
      : timeRounded.minute.toString();
  const hourZero =
    timeRounded.hour.toString().length < 2
      ? `0${timeRounded.hour}`
      : timeRounded.hour.toString();

  return { ...timeRounded, minuteZero, hourZero };
}

export {
  createTimeRange,
  endOfToday,
  getDateFromUnix,
  getFormattedDateFromUnix,
  getFormattedDuration,
  getFormattedShareTimeFromUnix,
  getFormattedTimeslotsFromUnix,
  getSpecificTime,
  getTimeFilterRange,
  getTimeslot,
  getUnixTimestamp,
  getUnixTimestampFromOffset,
  roundToInterval,
};
