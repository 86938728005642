import * as React from 'react';

import { getDisconnectReasonsByAccountAPI } from 'common/accountAPIs';
import { HEALTH_MENU_ERROR_TYPES } from 'common/constants';
import {
  getSeenNotifications,
  updateSeenNotifications,
} from 'helpers/healthMenu';
import type { SocialAPI } from 'types';

/**
 * Clean up keys from localStorage if an account API is now connected or other errors have been resolved.
 */
export default function useHealthMenuLocalStorageCleanup({
  disconnectedAccountAPIs,
  localStorageKey,
  hasDisconnectedTwitterApp,
  hasContentFeedErrors,
}: {
  disconnectedAccountAPIs: Array<SocialAPI>;
  localStorageKey: string;
  hasDisconnectedTwitterApp?: boolean;
  hasContentFeedErrors?: boolean;
}) {
  React.useEffect(() => {
    const disconnectReasonsByAccountAPIIds = getDisconnectReasonsByAccountAPI(
      disconnectedAccountAPIs,
    );
    const seenNotifications = getSeenNotifications(localStorageKey);
    const newSeenNotifications = { ...seenNotifications };
    if (newSeenNotifications.DISCONNECTIONS) {
      Object.keys(newSeenNotifications.DISCONNECTIONS).forEach((key) => {
        // If a disconnection key isn't one of the known disconnected accountAPIId remove it from localStorage.
        if (!(key in disconnectReasonsByAccountAPIIds)) {
          delete newSeenNotifications.DISCONNECTIONS![key];
        }
      });
    }
    if (hasDisconnectedTwitterApp === false) {
      delete newSeenNotifications[
        HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION
      ];
    }
    if (hasContentFeedErrors === false) {
      delete newSeenNotifications[HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR];
    }
    updateSeenNotifications(newSeenNotifications, localStorageKey);
  }, [
    disconnectedAccountAPIs,
    hasContentFeedErrors,
    hasDisconnectedTwitterApp,
    localStorageKey,
  ]);
}
