import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function getSocialPageConnectRequestNew({
  socialAPIURN,
  socialAPIType,
  uiCallbackURL,
  apiCallbackURL,
  extraParameters,
}: {
  socialAPIURN: string;
  socialAPIType: string;
  uiCallbackURL: string;
  apiCallbackURL: string;
  extraParameters?: Record<string, string>;
}) {
  const guid = metrics.start('getSocialPageConnectRequestNew');

  const config = {
    url: `${constructBaseSocialAPIURL()}/social/api/${socialAPIURN}/connect/request`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
    params: {
      socialAPIType,
      uiCallbackURL,
      apiCallbackURL,
      ...extraParameters,
    },
  };

  try {
    const response = await axios(config);
    // Get the location from the header
    const responseLocation = response.headers.location;
    metrics.end('getSocialPageConnectRequestNew', guid);
    logger.debug('getSocialPageConnectRequestNew', {
      socialAPIURN,
      socialAPIType,
    });
    return { location: responseLocation };
  } catch (error) {
    metrics.fail('getSocialPageConnectRequestNew', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getSocialPageConnectRequestNew',
      args: {
        socialAPIURN,
        socialAPIType,
        uiCallbackURL,
        apiCallbackURL,
      },
      isSensitiveRequest: true,
    });
    throw apiError;
  }
}
