import PropTypes from 'prop-types';

import { isNullOrUndefined } from 'common/utility';

const DropDown = (props) => (
  <select
    className={`btn btn-light btn btn-light dropdown-md ${props.dropDownClass}`}
    key={`${props.name}[${props.value}`}
    aria-label={props.label}
    name={props.name}
    value={props.value}
    onChange={props.handleDropDownChange}
    disabled={props.disabled}
    style={props.style}
    data-cy-select={props.dataCySelect}
  >
    {!isNullOrUndefined(props.default) && (
      <option disabled={!props.default.selectable} value={props.default.value}>
        {props.default.label}
      </option>
    )}
    {Object.keys(props.sources).map((source) => (
      <option value={source} key={`option-${source}`}>
        {props.sources[source]}
      </option>
    ))}
  </select>
);

DropDown.propTypes = {
  dropDownClass: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleDropDownChange: PropTypes.func.isRequired,
  dataCySelect: PropTypes.string,
  sources: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  default: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    selectable: PropTypes.bool.isRequired,
  }),
  style: PropTypes.object,
};

DropDown.defaultProps = {
  label: '',
  dropDownClass: '',
  dataCySelect: '',
  default: null,
  style: {},
  disabled: false,
};

export default DropDown;
