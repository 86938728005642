import PropTypes from 'prop-types';

import { MEDIA_ITEM_STATES } from 'common/constants';
import { isDefined } from 'common/utility';

const ItemBadges = (props) => {
  const { accountAPIId, mediaStates, totalShares } = props;

  const isShared =
    isDefined(mediaStates[accountAPIId]) &&
    mediaStates[accountAPIId]?.includes(MEDIA_ITEM_STATES.SHARED);

  return (
    <div
      className="article-badges ml-auto align-self-center pl-2"
      data-cy-id="badges"
    >
      {isShared && (
        <div className="ml-auto badge badge-info mb-0" data-cy-id="sharedBadge">
          Shared{totalShares > 1 && ` (${totalShares})`}
        </div>
      )}
    </div>
  );
};

ItemBadges.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  mediaStates: PropTypes.object.isRequired,
  totalShares: PropTypes.number,
};

ItemBadges.defaultProps = {
  totalShares: 0,
};

export default ItemBadges;
