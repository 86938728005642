/* eslint consistent-return:"off" */

import getShortURL from 'api/getShortURL';
import {
  getCurrentAPITypeId,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import { API_PROPERTIES } from 'common/constants';
import * as logger from 'common/logger';
import { getShorteningDisabledSetting, isSocialNetwork } from 'common/social';
import { isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export default function getShortenedURL({
  pageURL = mandatory('pageURL'),
  accountAPIId = mandatory('accountAPIId'),
  apiTypeId = getCurrentAPITypeId(),
} = {}) {
  return new Promise((resolve, reject) => {
    if (isNullOrUndefined(pageURL) || pageURL === '') {
      resolve('');
    }

    if (!isSocialNetwork({ apiTypeId })) {
      // Not a social account, so no URL to be appended
      resolve('');
    }

    const shorteningDisabled = getShorteningDisabledSetting({
      propertyId: getPropertyIdForAccountAPIId({ accountAPIId }),
      apiTypeId,
    });

    // Link shortening is disabled or not set, so append full URL
    if (isNull(shorteningDisabled) || shorteningDisabled.enabled) {
      resolve(pageURL);
    }

    const socialNetworkType = API_PROPERTIES[apiTypeId]?.urnName;
    // Link shortening is enabled, to get short URL
    getShortURL({
      longURL: pageURL,
      accountAPIId,
      socialNetworkType,
    })
      .then((shortURL) => {
        resolve(shortURL.shortURL);
      })
      .catch((error) => {
        if (error instanceof Error || error instanceof ErrorEvent) {
          logger.error({
            event: 'Caught Error',
            properties: {
              location: 'process/getShortenedURL',
              pageURL,
            },
            error,
          });
        }
        return reject(error);
      });
  });
}
