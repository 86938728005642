import clsx from 'clsx';

import Button from 'components/misc/Button';

interface ItemActionsProps {
  isMobile: boolean;
  onArticleShare: React.MouseEventHandler<HTMLButtonElement>;
}

const ItemActions = ({ isMobile, onArticleShare }: ItemActionsProps) => {
  return (
    <Button
      className={clsx('align-self-start', !isMobile && 'ml-2')}
      onClick={onArticleShare}
      data-cy-action="shareArticle"
    >
      Share
    </Button>
  );
};

export default ItemActions;
