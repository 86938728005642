import type { ChangeEvent } from 'react';

import { SHARE_ALL_MINIMUM_APIS } from 'common/config';
import { REACT_PREVENT_RENDER } from 'common/constants';
import { getSocialNetworkName } from 'common/social';
import SocialPagesItem from 'components/compose/socialpages/SocialPagesItem';
import { SocialAPIWithStates } from 'types';

interface SocialPagesNetworkProps {
  apiTypeId: number;
  filteredPagesForNetwork: ReadonlyArray<SocialAPIWithStates>;
  propertyId: number;
  onSelectAll: (propertyId: number, apiTypeId: number) => () => void;
  onSocialPagesChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SocialPagesNetwork = (props: SocialPagesNetworkProps) => {
  const {
    apiTypeId,
    filteredPagesForNetwork,
    propertyId,
    onSelectAll,
    onSocialPagesChange,
  } = props;

  const networkName = getSocialNetworkName({ apiTypeId });
  const canSelectAll = filteredPagesForNetwork.length >= SHARE_ALL_MINIMUM_APIS;

  if (filteredPagesForNetwork.length === 0) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <form data-id="socialNetwork">
      <div
        className="dropdown ft-11 dropdown-divider-title d-flex"
        data-cy-id={apiTypeId}
      >
        <span className="text-uppercase">{networkName}</span>
        {canSelectAll && (
          <a
            className="ml-auto text-400"
            onClick={onSelectAll(propertyId, apiTypeId)}
            data-cy-action="selectAll"
          >
            Select All
          </a>
        )}
      </div>
      {filteredPagesForNetwork.map((accountAPI) => (
        <SocialPagesItem
          accountAPI={accountAPI}
          onSocialPagesChange={onSocialPagesChange}
          key={`social_pages_list_page_${accountAPI.accountAPIId}`}
        />
      ))}
    </form>
  );
};

export default SocialPagesNetwork;
