/* eslint-disable no-await-in-loop */
import * as Logger from 'common/logger';
import * as React from 'react';

const LAZY_LOAD_ERRORS = [
  'Failed to fetch dynamically imported module: ',
  'error loading dynamically imported module: ',
];

const delay = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

/**
 * A wrapper around React.Lazy which loads the component and retries on error.
 * This will retry 3 times with a 2 second delay and backoff factor of 2 (2, 4, 8 seconds).
 * Inspired by https://medium.com/@alonmiz1234/retry-dynamic-imports-with-react-lazy-c7755a7d557a
 */
export const lazyWithRetries: typeof React.lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error: any) {
      const logImportError = (e: unknown, retryCount: number) => {
        Logger.error({
          event: 'Lazy load error',
          error: e,
          properties: {
            retryCount,
          },
        });
      };

      logImportError(error, 0);

      const message: string = error.message;
      if (LAZY_LOAD_ERRORS.some((pattern) => message.includes(pattern))) {
        // eslint-disable-next-line no-plusplus -- we want a traditional for loop as we are using await
        for (let i = 1; i <= 3; i++) {
          await delay(1000 * 2 ** i);

          // If the error message contains one of the known lazy load errors, we will attempt to extract the URL from it.
          const urlStr = LAZY_LOAD_ERRORS.reduce((acc, pattern) => {
            return acc.replace(pattern, '').trim();
          }, message);

          const url = new URL(urlStr);

          // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
          url.searchParams.set('t', `${Number(new Date())}`);

          try {
            return await import(/* @vite-ignore */ url.href);
          } catch (e) {
            logImportError(e, i);
          }
        }
      }

      throw error;
    }
  };

  return React.lazy(retryImport);
};
