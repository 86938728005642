import {
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { TOOLTIP_DELAY } from 'common/config';
import { REACT_PREVENT_RENDER } from 'common/constants';

interface TooltipProps extends ChakraTooltipProps {
  id: string;
  children: React.ReactNode;
  label: string | React.ReactNode;
  className?: string;
  clickable?: boolean;
}

const Tooltip = ({
  id,
  children,
  label,
  className = '',
  placement = 'bottom',
  clickable = false,
  ...rest
}: TooltipProps) => {
  const isHidingTooltips = sessionStorage.getItem('hideTooltips');
  if (isHidingTooltips) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <ChakraTooltip
      id={id}
      backgroundColor="#ffffff"
      className={className}
      openDelay={TOOLTIP_DELAY}
      closeDelay={clickable ? 200 : undefined}
      placement={placement}
      textColor="#424d60"
      label={label}
      closeOnClick={false}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  );
};

export default Tooltip;
