import { Flex, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getSyndFeeds, postGetHTTPEcho, postSyndFeedValidate } from 'api/api';
import * as logger from 'common/logger';
import { isValidURL } from 'common/url';
import { isValidSyndFeedURN } from 'common/urn';
import { isEmpty } from 'common/utility';
import { useReducer } from 'react';
import { IndividualHTTPEchoResponse, SyndFeedInterface } from 'types';
import ContentFeedDetails from './ContentFeedDetails';
import ContentFeedHTTPResponse from './ContentFeedHTTPResponse';
import ContentFeedSearch from './ContentFeedSearch';

type DetailsState =
  | { state: 'SEARCH' }
  | { state: 'CONTENT_FEED_DETAILS'; feedData: SyndFeedInterface }
  | { state: 'HTTP_RESPONSE'; responseData: IndividualHTTPEchoResponse };

type ActionType =
  | {
      type: 'SEARCH_SUCCESS';
      payload: {
        feedData: SyndFeedInterface;
      };
    }
  | {
      type: 'VALIDATE_SUCCESS';
      payload: {
        feedData: SyndFeedInterface;
      };
    }
  | {
      type: 'SCRAPE_SUCCESS';
      payload: {
        responseData: IndividualHTTPEchoResponse;
      };
    }
  | {
      type: 'GO_BACK';
    };

function reducer(state: DetailsState, action: ActionType): DetailsState {
  switch (action.type) {
    case 'SEARCH_SUCCESS': {
      return {
        state: 'CONTENT_FEED_DETAILS',
        feedData: action.payload.feedData,
      };
    }

    case 'VALIDATE_SUCCESS': {
      return {
        state: 'CONTENT_FEED_DETAILS',
        feedData: action.payload.feedData,
      };
    }

    case 'SCRAPE_SUCCESS': {
      return {
        state: 'HTTP_RESPONSE',
        responseData: action.payload.responseData,
      };
    }

    case 'GO_BACK': {
      return {
        state: 'SEARCH',
      };
    }

    default:
      throw new Error(`Invalid action type: ${(action as any).type}`);
  }
}

interface ContentFeedToolsModalProps {
  onClose: () => void;
}

const ContentFeedToolsModal = ({ onClose }: ContentFeedToolsModalProps) => {
  const [state, dispatch] = useReducer(reducer, { state: 'SEARCH' });

  const handleBackClick = () => {
    dispatch({ type: 'GO_BACK' });
  };

  const handleSearch = async (identifier: string) => {
    if (!isValidURL(identifier) && !isValidSyndFeedURN(identifier)) {
      throw new Error('The provided identifier is not a valid URL or URN');
    }

    const encodedIdentifier = encodeURIComponent(identifier);

    let feeds: SyndFeedInterface[] = [];
    try {
      feeds = await getSyndFeeds({
        identifiers: [encodedIdentifier],
      });
    } catch (ex) {
      logger.error({
        event: 'Content Feed Tools Get Content Feeds',
        error: ex,
      });
    }

    if (isEmpty(feeds) && isValidURL(identifier)) {
      try {
        const feed = await postSyndFeedValidate({
          feedURL: identifier,
        });
        feeds = [feed];
      } catch (ex) {
        logger.error({
          event: 'Content Feed Tools Validate Content Feed',
          error: ex,
        });
      }
    }

    if (!isEmpty(feeds)) {
      dispatch({
        type: 'SEARCH_SUCCESS',
        payload: {
          feedData: feeds[0],
        },
      });

      return;
    }

    if (isValidURL(identifier)) {
      try {
        const httpResponse = await getHttpResponse(identifier);
        dispatch({
          type: 'SCRAPE_SUCCESS',
          payload: {
            responseData: httpResponse,
          },
        });

        return;
      } catch (ex) {
        logger.error({
          event: 'Content Feed Tools Get Content Feed HTTP Response',
          error: ex,
        });
      }
    }

    throw new Error('No data could be retrieved for this identifier');
  };

  const getHttpResponse = async (identifier: string) => {
    const httpEchoResponse: IndividualHTTPEchoResponse[] =
      await postGetHTTPEcho({
        targetURL: identifier,
        userAgents: ['EchoboxBot'],
        includeResponseBody: true,
      });

    if (isEmpty(httpEchoResponse) || httpEchoResponse[0].errorReason) {
      throw new Error(httpEchoResponse[0].errorReason);
    }

    return httpEchoResponse[0];
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      size={state.state === 'SEARCH' ? 'small' : 'medium'}
    >
      <Modal.Header>
        <Modal.Title>Content Feed Tools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={4} direction="column">
          {state.state === 'SEARCH' && (
            <ContentFeedSearch onSearch={handleSearch} />
          )}
          {state.state === 'CONTENT_FEED_DETAILS' && (
            <ContentFeedDetails
              contentFeed={state.feedData}
              onBackClick={handleBackClick}
            />
          )}
          {state.state === 'HTTP_RESPONSE' && (
            <ContentFeedHTTPResponse
              contentFeedResponse={state.responseData}
              onBackClick={handleBackClick}
            />
          )}
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default ContentFeedToolsModal;
