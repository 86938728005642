import Moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import momentLocalizer from 'react-widgets-moment';

import { isCurrentPropertySuspended } from 'common/accountAPIs';
import * as tracker from 'common/tracker';
import CategoryFilter from 'components/home/archive/CategoryFilter';
import Classification from 'components/home/archive/Classification';
import DateRange from 'components/home/archive/DateRange';
import Import from 'components/home/archive/Import';
import Sort from 'components/home/archive/Sort';
import State from 'components/home/archive/State';
import Button from 'components/misc/Button';

const Filters = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen((prev) => !prev);
    tracker.track({
      eventName: 'Click Import Button',
    });
  };

  const { categories, displayOptions, eventHandlers, isLoadingCategory } =
    props;
  const suspendedClass = isCurrentPropertySuspended()
    ? 'account_suspended'
    : '';

  Moment.updateLocale('en', { week: { dow: 1 } });
  momentLocalizer();

  return (
    <div
      className={`d-flex box-header ${suspendedClass}`}
      data-cy-id="archiveFilters"
    >
      <div className="d-flex flex-md-wrap w-100">
        <Sort
          displayOptions={displayOptions}
          eventHandlers={{ handleSortChange: eventHandlers.handleSortChange }}
        />
        <span className="align-self-center op-20">|</span>
        <Classification
          displayOptions={displayOptions}
          eventHandlers={{
            handleFilterChange: eventHandlers.handleFilterChange,
          }}
        />
        <State
          displayOptions={displayOptions}
          eventHandlers={{
            handleFilterChange: eventHandlers.handleFilterChange,
          }}
        />
        <DateRange
          displayOptions={displayOptions}
          timeField="publish"
          eventHandlers={{
            handleTimeframeChange: eventHandlers.handleTimeframeChange,
          }}
        />
        <DateRange
          displayOptions={displayOptions}
          timeField="share"
          eventHandlers={{
            handleTimeframeChange: eventHandlers.handleTimeframeChange,
          }}
        />
        <CategoryFilter
          categories={categories}
          displayOptions={displayOptions}
          isLoadingCategory={isLoadingCategory}
          eventHandlers={{
            handleSelectedCategoriesChanged:
              eventHandlers.handleSelectedCategoriesChanged,
          }}
        />
        <div className="article-counter d-none d-md-flex">
          <Button onClick={handleModalOpen}>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              style={{ position: 'relative', top: '-1px' }}
            >
              <path d="M5.90909 4.09091V0H4.09091V4.09091H0V5.90909H4.09091V10H5.90909V5.90909H10V4.09091H5.90909Z" />
            </svg>{' '}
            Import
          </Button>
        </div>
      </div>
      {isModalOpen && (
        <Import
          eventHandlers={{
            handleModalClose,
          }}
        />
      )}
    </div>
  );
};

Filters.propTypes = {
  categories: PropTypes.array,
  displayOptions: PropTypes.object.isRequired,
  isLoadingCategory: PropTypes.bool,
  eventHandlers: PropTypes.shape({
    handleFilterChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
    handleSelectedCategoriesChanged: PropTypes.func,
  }).isRequired,
};

Filters.defaultProps = {
  categories: [],
  isLoadingCategory: false,
};

export default Filters;
