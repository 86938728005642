import { Box, Flex, Popover } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ReactNode } from 'react';

import SortArrow from 'components/home/analytics/SortArrow';

interface ColumnHeaderProps {
  columnName: string;
  displayOptions: {
    sortBy: string;
    sortOrder: 'ASC' | 'DESC';
  };
  eventHandlers: {
    handleSortChange: (fieldName: string) => () => void;
  };
  fieldName: string;
  tooltipContent?: ReactNode;
  tooltipHeader?: string;
}

const ColumnHeader = ({
  columnName,
  displayOptions,
  eventHandlers,
  fieldName,
  tooltipContent,
  tooltipHeader,
}: ColumnHeaderProps) => {
  const content = (
    <Flex
      as="button"
      pos="relative"
      alignItems="center"
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      border="none"
      bg="none"
      w="full"
      h="full"
      p="2"
      fontSize="13px"
      color="#697386"
      textAlign="start"
      _hover={{ textDecoration: 'underline' }}
      onClick={eventHandlers.handleSortChange(fieldName)}
      data-cy-action="sortByField"
    >
      <Box
        as="span"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {columnName}
      </Box>
      {fieldName === displayOptions.sortBy && (
        <SortArrow fieldName={fieldName} displayOptions={displayOptions} />
      )}
    </Flex>
  );

  return tooltipContent ? (
    <Popover trigger="hover" placement="top-end">
      <Popover.Trigger>{content}</Popover.Trigger>
      <Popover.Content w="fit-content" maxWidth="420px" textAlign="start">
        {tooltipHeader && <Popover.Header>{tooltipHeader}</Popover.Header>}
        <Popover.Body fontWeight="normal">{tooltipContent}</Popover.Body>
      </Popover.Content>
    </Popover>
  ) : (
    content
  );
};

export default ColumnHeader;
