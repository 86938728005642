import {
  Dropdown,
  Flex,
  Heading,
  Tag,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  getCurrentProperty,
  getCurrentPropertyId,
  getPropertyName,
  isPropertyIncomplete,
} from 'common/accountAPIs';
import { MIXPANEL_ORIGIN } from 'common/constants';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { useOnboardingSetupContext } from 'context/OnboardingContext';
import ChevronDown from 'svg/ChevronDown';
import ChevronUp from 'svg/ChevronUp';

const SwitchPropertyDropdown = () => {
  const { setIsWebsiteTagError } = useOnboardingSetupContext();
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const currentProperty = getCurrentProperty({ globalInfo });
  const currentPropertyId = getCurrentPropertyId({ globalInfo });
  const hasMultipleProperties =
    globalInfo?.properties && Object.keys(globalInfo.properties).length > 1;

  const handlePropertyChange = (propertyIdString: string) => () => {
    const propertyId = parseInt(propertyIdString, 10);
    logger.info(`Header:handlePropertyChange ${propertyId}`);
    tracker.track({
      eventName: 'Switch Property',
      trackingParams: {
        'New Property Name': getPropertyName({ propertyId }),
        'New Property Id': propertyId,
        Origin: MIXPANEL_ORIGIN.SETUP,
      },
    });
    setIsWebsiteTagError(false);
    global.handlePropertyChange(propertyId);
  };

  const sortedPropertyIds = Object.keys(globalInfo?.properties).sort(
    (propertyIdA, propertyIdB) => {
      const propertyA = globalInfo?.properties[propertyIdA];
      const propertyB = globalInfo?.properties[propertyIdB];
      return propertyA.propertyName.localeCompare(propertyB.propertyName);
    },
  );

  return (
    <Dropdown gutter={8}>
      {({ isOpen }) => (
        <>
          <Flex gap={2} alignItems="center" color="gray.600">
            <Heading
              variant="h3"
              fontWeight="medium"
              data-cy-id="currentProperty"
              cursor="pointer"
              as={Dropdown.Button}
            >
              Set up {currentProperty?.propertyName}
            </Heading>
            <Dropdown.Button
              data-cy-select="propertySelect"
              borderRadius="base"
              bg={isOpen ? 'gray.200' : 'none'}
              p={1}
            >
              {isOpen ? (
                <ChevronUp width="16" height="16" />
              ) : (
                <ChevronDown width="16" height="16" />
              )}
            </Dropdown.Button>
          </Flex>
          <Dropdown.List maxH="xl">
            {hasMultipleProperties && (
              <>
                {sortedPropertyIds.map((propertyId) => (
                  <Dropdown.Item
                    key={propertyId}
                    data-cy-id={propertyId}
                    onClick={handlePropertyChange(propertyId)}
                    color={
                      String(currentPropertyId) === propertyId
                        ? 'primary.600'
                        : undefined
                    }
                    bg={
                      String(currentPropertyId) === propertyId
                        ? 'gray.100'
                        : 'none'
                    }
                  >
                    <Flex align="center" justify="space-between">
                      {globalInfo.properties[propertyId].propertyName}
                      {isPropertyIncomplete({ propertyId, globalInfo }) && (
                        <Tag color="gray">Incomplete</Tag>
                      )}
                    </Flex>
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider my={1} mx={0} color="gray.300" />
              </>
            )}
            <Dropdown.Item
              as={Link}
              to="/addproperty?origin=setup"
              _hover={{ textDecor: 'none' }}
            >
              Create new Property
            </Dropdown.Item>
          </Dropdown.List>
        </>
      )}
    </Dropdown>
  );
};

export default SwitchPropertyDropdown;
