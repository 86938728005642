import {
  Alert,
  Box,
  Button,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Steps from './PagesModalSteps';

interface AddInstagramIntroProps {
  onClose: () => void;
  onNextStep: () => void;
}

const AddInstagramIntro = ({ onClose, onNextStep }: AddInstagramIntroProps) => (
  <>
    <Modal.Body py={6}>
      <Steps mb={6}>
        <Steps.Step>
          <Text size="md">
            Clear any data from the fields that your browser or password manager
            has added
          </Text>
        </Steps.Step>
        <Steps.Step>
          <Text size="md">Enter your username and password</Text>
        </Steps.Step>
        <Steps.Step>
          <Text size="md">Click &quot;Connect Instagram&quot;</Text>
        </Steps.Step>
      </Steps>
      <Alert variant="warning">
        <Alert.Title>Instagram suspicious detection</Alert.Title>
        <Alert.Description size="md">
          Instagram may detect activity from our headquarters in London and
          think this is suspicious. Please do not flag this as suspicious, as it
          will block Echobox’s access to your account
        </Alert.Description>
      </Alert>
    </Modal.Body>
    <Modal.Footer px={5}>
      <Box mr="auto">
        <Button
          variant="tertiary"
          as="a"
          href="mailto:support@echobox.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact support
        </Button>
      </Box>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button data-cy-action="next" onClick={onNextStep}>
        Next
      </Button>
    </Modal.Footer>
  </>
);

export default AddInstagramIntro;
