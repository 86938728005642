import { memo } from 'react';

import { arePropsEqual } from 'common/utility';
import Tooltip from 'components/misc/Tooltip';
import { toggleIsLive, useComposeBoxContext } from 'context/ComposeBoxContext';

interface LiveStoryProps {
  guid: string;
  isLive: boolean;
}

const LiveStory = ({ guid, isLive }: LiveStoryProps) => {
  const { dispatch } = useComposeBoxContext();

  const handleIsLiveToggle = () => {
    dispatch(toggleIsLive({ guid }));
  };

  const id = `live-story-${guid}`;
  const tooltipMessage = 'Echobox will automatically update the article';
  return (
    <div className="d-flex align-items-center mb-2">
      <input
        type="checkbox"
        id={id}
        name="livestory"
        checked={isLive}
        onChange={handleIsLiveToggle}
      />
      <label htmlFor={id} className="flex-grow-1">
        Live story
      </label>
      <Tooltip id="livestoryicon" label={tooltipMessage}>
        <img
          className="info_icon"
          src="/img/icons/ic-information.svg"
          alt=""
          data-tip="true"
          data-for="livestoryicon"
          data-iscapture="true"
        />
      </Tooltip>
    </div>
  );
};

export default memo(LiveStory, arePropsEqual);
