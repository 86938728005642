import {
  Box,
  Card,
  Flex,
  LightingHighlightIcon,
  ListItem,
  Radio,
  Tag,
  UnorderedList,
  UseRadioProps,
  useRadio,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as string from 'common/string';

interface SettingsRadioCardProps extends UseRadioProps {
  settingDescriptions: string[];
  hideIcon?: boolean;
  children?: React.ReactNode;
}

function SettingsRadioCard({
  settingDescriptions,
  hideIcon = false,
  children,
  ...rest
}: SettingsRadioCardProps) {
  const { state, getInputProps, getRadioProps } = useRadio(rest);

  const input = getInputProps();
  const checkbox = getRadioProps();

  const displayValue = string.toSentenceCase(rest.value ?? '');

  return (
    <Box
      as="label"
      data-cy-id={`reshareMode${displayValue}`}
      data-cy-active={`${state.isChecked}`}
      sx={{
        '.chakra-radio__label': {
          color: state.isChecked ? 'primary.600' : 'default',
          fontSize: 16,
          lineHeight: '20px',
        },
      }}
    >
      <input {...input} />
      <Card
        {...checkbox}
        flexDir="column"
        gap={4}
        _checked={{
          boxShadow: 'inset 0px 0px 0px 2px #3371E6',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        cursor="pointer"
      >
        <Card.Content w="100%">
          <Flex justifyContent="space-between">
            <Radio
              isChecked={state.isChecked}
              value={rest.value}
              onChange={rest.onChange}
              size="md"
            >
              {displayValue}
            </Radio>
            {!hideIcon && (
              <Tag
                color={state.isChecked ? 'blue' : 'gray'}
                icon={<LightingHighlightIcon />}
                _hover={{ cursor: 'pointer' }}
              />
            )}
          </Flex>
          {settingDescriptions.length > 0 && (
            <UnorderedList ml="42px">
              {settingDescriptions.map((description) => (
                <ListItem
                  key={description}
                  color="gray.600"
                  fontSize={14}
                  lineHeight="20px"
                >
                  {description}
                </ListItem>
              ))}
            </UnorderedList>
          )}
        </Card.Content>
        {state.isChecked && children && (
          <Flex flexDir="column" gap={4} w="100%" cursor="default">
            {children}
          </Flex>
        )}
      </Card>
    </Box>
  );
}

export default SettingsRadioCard;
