import postServiceAuth from 'api/postServiceAuth';
import { raiseAPIErrors } from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import { SENTRY_USER_TYPES, USER_TYPES } from 'common/constants';
import { setCurrentPropertyAndAPI } from 'common/currentPropertyAndAPI';
import { determineError, getErrorStatus } from 'common/errorHandling';
import { storeGlobalInfo } from 'common/globalInfo';
import APIimpersonate from 'common/impersonate';
import { resetTextDirection } from 'common/language';
import * as logger from 'common/logger';
import { setUserDetails } from 'common/sentry';
import { convertToPropertyURN, convertToSocialPageURN } from 'common/urn';
import { isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';
import populateGlobalInfo from 'process/populateGlobalInfo';

/**
 * @param {{
 *  propertyId: string;
 * }}
 */
export async function impersonateProperty({
  propertyId = mandatory('propertyId'),
} = {}) {
  let clientServiceToken;
  try {
    const propertyURN = convertToPropertyURN(propertyId);
    clientServiceToken = await postServiceAuth({
      overridePropertyURN: propertyURN,
    });
  } catch (error) {
    const handledError = determineError(error);
    if (isNull(getErrorStatus(error))) {
      logger.error({
        event: 'Impersonate Error',
        properties: {
          location: 'process/impersonateProperty',
          arguments: {
            propertyId,
          },
        },
        error,
      });
    }
    throw handledError;
  }

  return impersonate({ clientServiceToken });
}

/**
 * @param {{
 *  accountAPIId: string;
 *  urnSocialNetworkType: string;
 *  setGlobalInfo: (globalInfo: import('types').FixTypeLater) => void;
 * }}
 */
export async function impersonateSocialPage({
  accountAPIId = mandatory('accountAPIId'),
  urnSocialNetworkType = mandatory('urnSocialNetworkType'),
  setGlobalInfo = mandatory('setGlobalInfo'),
} = {}) {
  let clientServiceToken;
  try {
    const socialPageURN = convertToSocialPageURN(
      urnSocialNetworkType,
      accountAPIId,
    );
    clientServiceToken = await postServiceAuth({
      overrideSocialPageURN: socialPageURN,
    });
  } catch (error) {
    const handledError = determineError(error);
    if (isNull(getErrorStatus(error))) {
      logger.error({
        event: 'Impersonate Error',
        properties: {
          location: 'process/impersonateSocialPage',
          arguments: {
            accountAPIId,
            urnSocialNetworkType,
          },
        },
        error,
      });
    }
    throw handledError;
  }

  return impersonate({ clientServiceToken, setGlobalInfo });
}

/**
 * impersonateUser - log into another Echobox user's account as enhanced permission user
 *
 * @param {{
 *  username: string;
 *  setGlobalInfo: (globalInfo: import('types').FixTypeLater) => void;
 * }}
 * @return - user and property information
 *
 * Example response
 * {
 *   user: {object}
 *   properties: {array of objects}
 *   current: {
 *     propertyId: {integer},
 *     accountAPIId: {integer}
 *   }
 * }
 */
export async function impersonateUser({
  username = mandatory('username'),
} = {}) {
  let clientServiceToken;
  try {
    clientServiceToken = await APIimpersonate({
      overrideUsername: username,
    });
  } catch (error) {
    const handledError = determineError(error);
    if (isNull(getErrorStatus(error))) {
      logger.error({
        event: 'Impersonate Error',
        properties: {
          location: 'process/impersonateUser',
          arguments: {
            username,
          },
        },
        error,
      });
    }
    throw handledError;
  }

  return impersonate({ clientServiceToken });
}

async function impersonate({
  clientServiceToken = mandatory('clientServiceToken'),
} = {}) {
  try {
    const data = {};

    const userDetail = authentication.getUserDetails(null, clientServiceToken);
    authentication.setClientServiceToken(clientServiceToken);

    sessionStorage.removeItem('blockedErrors');
    resetTextDirection(); // Reset text direction flag

    // Store user information from the client service token...
    data.user = userDetail;
    data.user.userId = authentication.getUserIdFromCSToken(clientServiceToken);

    // Set the usertype to Staff to be able to have the enhanced permissions user toggle and go to another account
    data.user.userType = USER_TYPES.ECHOBOX_STAFF;

    // Set user details for Sentry logging
    setUserDetails({
      id: data.user.userId,
      username: data.user.name,
      email: data.user.emailAddress,
      usertype: SENTRY_USER_TYPES.ENHANCED_PERMISSIONS,
    });

    // Load property and page information
    const propertiesResponse = await populateGlobalInfo({
      user: data.user,
    });

    // Store global data in local storage
    data.properties = propertiesResponse.properties;
    data.current = setCurrentPropertyAndAPI({
      propertiesAndAPIs: propertiesResponse.properties,
    });
    // since we reload the page after impersonating, we only need to store global info in localstorage
    // and not have to update react state / context
    storeGlobalInfo({
      properties: data.properties,
      current: data.current,
      user: data.user,
    });

    // Raise property/page-level errors as required
    if (
      !isNullOrUndefined(data.current.propertyId) &&
      !isNullOrUndefined(data.current.accountAPIId)
    ) {
      raiseAPIErrors({
        property: data.properties[data.current.propertyId],
      });
    }

    // All done
    return data;
  } catch (error) {
    const handledError = determineError(error);
    if (isNull(getErrorStatus(error))) {
      logger.error({
        event: 'Impersonate Error',
        properties: {
          location: 'process/impersonate',
        },
        error,
      });
    }
    throw handledError;
  }
}
