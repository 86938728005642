import { Box, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  getAPIPostName,
  getAPITypeId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  ACCOUNT_SETTING_TYPES,
  COLLECTION_NAMES,
  POST_TYPES,
  SHARE_ORIGINS,
  TEXT_CASES,
  UI_MESSAGES,
} from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import * as logger from 'common/logger';
import { round } from 'common/maths';
import * as MediaItem from 'common/mediaItem';
import { convertEchoboxImageToAmazon } from 'common/s3';
import { getSetting } from 'common/settings';
import { getShareURLPlaceholder, replaceShareURL } from 'common/shareURL';
import { getSocialNetworkName } from 'common/social';
import { shortenURLsInMessage } from 'common/string';
import { getImageThumbnail } from 'common/thumbnails';
import * as tracker from 'common/tracker';
import { isVideoURL } from 'common/url';
import { isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import Title from 'components/compose/Title';
import MessageWithTags from 'components/home/MessageWithTags';
import TimingBadges from 'components/home/TimingBadges';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import withGlobalInfo from 'context/withGlobalInfo';
import { addImagePrefix } from 'helpers/images';
import useOnChangeToReady from 'hooks/useOnChangeToReady';
import { useIsComposeBoxOpen } from 'state/composeBoxOpen';

const GRID_FIXED_WIDTH = { maxW: '424px', margin: 'auto' };

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  origin: string;
 *  action: string;
 * }}
 */
export function trackApprovalAction({
  mediaItem = mandatory('mediaItem'),
  origin = mandatory('origin'),
  action = mandatory('action'),
}) {
  const trackingParams = getApprovalTrackingParams({ mediaItem });

  trackingParams.Origin = origin;
  trackingParams.Action = action;

  tracker.track({
    eventName: 'Action Autofeed Approval',
    trackingParams,
  });
}

function getApprovalTrackingParams({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const postType = MediaItem.getPostType({ mediaItem });
  const message = MediaItem.getMessage({ mediaItem });
  const title = MediaItem.getRssTitle({ mediaItem });
  const noOfShares = MediaItem.getTotalNumberOfShares({ mediaItem });

  return {
    'Share type': noOfShares === 0 ? 'Autofeed New Share' : 'Autofeed Reshare',
    'Social Network': getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Account API Id': accountAPIId,
    'Article URL':
      postType === POST_TYPES.LINK
        ? MediaItem.getUnshortenedShareURL({ mediaItem })
        : 'undefined',
    'Share Message Text': message !== null ? message : 'undefined',
    'Share Message Length': message ? message.length : 'undefined',
    'Title Length': title ? title.length : 'undefined',
  };
}

const Approvals = (props) => {
  // Do not want to show card until image is loaded
  const [loadedImageURL, setLoadedImageURL] = useState(null);

  // Check for new approval cards being shown
  const [displayedMediaId, setDisplayedMediaId] = useState(null);

  const isComposeBoxOpen = useIsComposeBoxOpen();

  // Fetch data on initial load
  useEffect(() => {
    logger.info('Approvals:initialiseComponent');

    props.sharedMethods.getFeedData({
      collectionName: COLLECTION_NAMES.APPROVALS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch the data when page is changed
  useOnChangeToReady(() => {
    logger.info('Approvals:useOnChangeToReady');

    props.sharedMethods.getFeedData({
      collectionName: COLLECTION_NAMES.APPROVALS,
    });
  });

  const { feedData, accountAPIId } = props;
  const apiTypeId = getAPITypeId({ accountAPIId });
  const mediaItem =
    feedData.mediaItems.length > 0 ? feedData.mediaItems[0] : null;
  const mediaId =
    mediaItem !== null ? MediaItem.getMediaId({ mediaItem }) : null;

  const postType =
    mediaItem !== null
      ? MediaItem.getPostType({ mediaItem })
      : POST_TYPES.UNKNOWN;

  const imageFallbackURL = getImageThumbnail({
    apiTypeId,
    postType,
    isNewsFeed: true,
  });

  // Reset loaded image each time the mediaId changes
  useEffect(() => {
    setLoadedImageURL(null);
  }, [mediaId]);

  let imageURL = null;
  if (mediaItem !== null && !MediaItem.getIsLoading({ mediaItem })) {
    const imageURLs = MediaItem.getImageURLs({ mediaItem });
    if (imageURLs.length > 0) {
      imageURL = convertEchoboxImageToAmazon(imageURLs[0]);
    } else {
      imageURL = imageFallbackURL;
    }
  }

  const isLoadingList =
    feedData.isLoading ||
    props.global.isLoading() ||
    props.global.isSelecting();

  const isLoadingData =
    mediaItem === null ||
    MediaItem.getIsLoading({ mediaItem }) ||
    MediaItem.getIsSaving({ mediaItem }) ||
    (loadedImageURL !== imageURL && loadedImageURL !== imageFallbackURL);

  // Track when a new approval is shown to the user
  if (!isLoadingData && mediaId !== displayedMediaId) {
    const trackingParams = getApprovalTrackingParams({ mediaItem });
    tracker.track({
      eventName: 'Display Autofeed Approval Card',
      trackingParams,
    });

    setDisplayedMediaId(mediaId);
  }

  const handleApprove = () => {
    props.eventHandlers.handleApprove({ mediaId, mediaItem });
  };

  const handleEdit = () => {
    if (isComposeBoxOpen) {
      return;
    }

    trackApprovalAction({
      mediaItem,
      action: 'Edit',
      origin: 'Approval Card',
    });

    Compose.editPost({
      editItem: mediaItem,
      itemOrigin: SHARE_ORIGINS.APPROVALS,
    });
  };

  const handleSkip = () => {
    props.eventHandlers.handleApprovalSkipped({ mediaId, mediaItem });
  };

  const renderHeader = () => {
    const mostRecentScore = MediaItem.getMostRecentScore({
      mediaItem,
    });
    const roundedScore = round({
      value: mostRecentScore * 10,
      decimals: 1,
    })
      .toFixed(0)
      .toString();

    const timeSensitivityTypeId = MediaItem.getTimeSensitivityTypeId({
      mediaItem,
    });
    const twelveHourFormatSetting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
      propertyId: getCurrentPropertyId(),
    });
    const twelveHourFormat = !isNull(twelveHourFormatSetting)
      ? twelveHourFormatSetting.enabled
      : false;

    const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
    const publishedTime = MediaItem.getMostRecentUnixTimePublished({
      mediaItem,
    });

    return (
      <div className="approvals-header">
        <div className="d-flex align-items-center justify-content-right ml-auto">
          {roundedScore > 0 && <div className="score">{roundedScore}</div>}
          <TimingBadges
            accountAPIId={accountAPIId}
            origin="APPROVALS"
            postType={postType}
            twelveHourFormat={twelveHourFormat}
            timeSensitivityTypeId={timeSensitivityTypeId}
            showTimeSensitivity={true}
            mediaId={mediaId}
          />
          <a
            href={articleURL}
            target="_blank"
            rel="noopener noreferrer"
            className="article_link link_grey ml-auto"
          >
            Published{' '}
            {getFormattedDateFromUnix({
              timestamp: publishedTime,
              twelveHourFormat,
              textCase: TEXT_CASES.LOWER,
            })}
            <span style={{ padding: 4 }} />
            <img
              className="link_icon"
              src="/img/icons/ic-open-in-new.svg"
              alt=""
            />
          </a>
        </div>
      </div>
    );
  };

  const renderMessage = () => {
    const previewFields = MediaItem.getPreviewFields({ mediaItem });
    const message = replaceShareURL({
      text: previewFields.message,
      shareURL: getShareURLPlaceholder({ mediaItem }),
    });
    const tags = MediaItem.getTags({ mediaItem });

    if (message === '') {
      return <div className="no_message_shared">No share message</div>;
    }
    const messageWithShortURLs = shortenURLsInMessage(message);
    return (
      <div className="m-2">
        <MessageWithTags
          message={messageWithShortURLs}
          tags={tags}
          accountAPIId={accountAPIId}
          outerClass="text"
        />
      </div>
    );
  };

  // Render image hidden in the HTML to pre-download it
  const renderPreLoadImage = () => {
    return (
      <img
        className="share_image"
        data-cy-id="shareImage"
        src={addImagePrefix(imageURL)}
        onLoad={() => setLoadedImageURL(imageURL)}
        onError={(e) => {
          e.target.onError = null;
          e.target.onLoad = () => setLoadedImageURL(imageFallbackURL);
          e.target.src = imageFallbackURL;
        }}
        alt=""
      />
    );
  };

  const renderImage = () => {
    const imageStyle = {
      backgroundImage: `url('${addImagePrefix(loadedImageURL, {
        height: 213,
      })}')`,
      width: '100%',
      height: 213,
    };

    return (
      <div className="share_imagery">
        <div
          className="share_image"
          style={imageStyle}
          data-cy-id="shareImage"
        />
      </div>
    );
  };

  const renderButtons = () => {
    const renderButton = (text, style, variant, handler) => {
      return (
        <Button
          className={`${style} align-self-center`}
          variant={variant}
          data-cy-action="approval-edit"
          data-cy-attribute={`isEnabled:${!isLoadingData}`}
          disabled={isLoadingData}
          onClick={handler}
        >
          {text}
        </Button>
      );
    };

    return (
      <Box
        className="d-flex align-items-center justify-content-end"
        {...GRID_FIXED_WIDTH}
      >
        {renderButton('Edit', 'mr-auto', 'light', handleEdit)}
        {renderButton('Skip', 'btn-spaced', 'light', handleSkip)}
        {renderButton('Approve', '', 'dark', handleApprove)}
      </Box>
    );
  };

  const renderTitle = () => {
    const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
    const isVideoLink = isVideoURL(articleURL);
    const title = MediaItem.getRssTitle({ mediaItem }) || 'No title found';
    return (
      <div
        className="approval-title"
        data-tip
        data-for="approvals_title"
        data-iscapture="true"
      >
        <Tooltip id="approvals_title" label={title}>
          <Title
            accountAPIId={accountAPIId}
            canCustomiseLinkPosts={false}
            isLoading={false}
            isRefreshPreview={false}
            isVideoLink={isVideoLink}
            mediaId={mediaId}
            title={title}
            onTitleChange={() => {}}
            onShowDescription={() => false}
          />
        </Tooltip>
      </div>
    );
  };

  return (
    <Box
      display={{
        base: props.tabName === 'approvals' ? 'block' : 'none',
        md: 'block',
      }}
      w="full"
      className="queue"
      data-cy-id="approvals"
      data-cy-attribute={`isLoading:${isLoadingList}`}
      {...{ mt: { base: 0, md: 4 } }}
    >
      <div
        className="queue_header d-flex align-items-center"
        data-cy-id="approvalStatus"
      >
        <Box className="heading text-700" mr={2} mb={1}>
          Autofeed suggestions{' '}
          <span data-cy-id="approvalCount">
            {!isLoadingList && `(${feedData.mediaIds.length})`}
          </span>
        </Box>
      </div>
      {isLoadingList && feedData.mediaIds.length === 0 && (
        <div className="empty_feed text-center py-5">
          <Spinner size="lg" />
        </div>
      )}
      {!isLoadingList && feedData.mediaIds.length === 0 && (
        <div className="queue_main">
          <div className="share_item_empty">
            <div className="cleared_message">
              {UI_MESSAGES.APPROVALS_NO_ITEMS_FOUND}
            </div>
          </div>
        </div>
      )}

      {isLoadingData && feedData.mediaIds.length > 0 && (
        <div className="approvals" data-cy-id={mediaId}>
          <Box
            className="approvals-card d-flex"
            style={{ height: '378px' }}
            {...GRID_FIXED_WIDTH}
          >
            <Spinner m="auto" size="lg" />
          </Box>
          {renderPreLoadImage()}
          {renderButtons()}
        </div>
      )}
      {!isLoadingData && feedData.mediaIds.length > 0 && (
        <div className="approvals" data-cy-id={mediaId}>
          <Box className="approvals-card" {...GRID_FIXED_WIDTH}>
            {renderHeader()}
            {renderMessage()}
            {renderImage()}
            {renderTitle()}
          </Box>
          {renderButtons()}
        </div>
      )}
    </Box>
  );
};

Approvals.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    handleApprovalSkipped: PropTypes.func.isRequired,
    handleApprove: PropTypes.func.isRequired,
  }).isRequired,
  feedData: PropTypes.object.isRequired,
  global: PropTypes.shape({
    isLoading: PropTypes.func,
    isSelecting: PropTypes.func,
  }),
  sharedMethods: PropTypes.shape({
    getFeedData: PropTypes.func.isRequired,
  }).isRequired,
  tabName: PropTypes.string.isRequired,
};

Approvals.defaultProps = {
  global: {},
};

export default withGlobalInfo(Approvals);
