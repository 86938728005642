import type { MessageBoxStateType } from './types';

export const ACTION_TYPE = {
  HANDLE_ADDITIONAL_MENU_CLOSE: 'HANDLE_ADDITIONAL_MENU_CLOSE',
  HANDLE_ADDITIONAL_MENU_OPEN: 'HANDLE_ADDITIONAL_MENU_OPEN',
  HANDLE_EDITOR_STATE_CHANGE: 'HANDLE_EDITOR_STATE_CHANGE',
  HANDLE_FULL_STOP_ADDED_ON_DOUBLE_SPACE:
    'HANDLE_FULL_STOP_ADDED_ON_DOUBLE_SPACE',
  INSERT_EMOJI: 'INSERT_EMOJI',
  INSERT_TAG: 'INSERT_TAG',
  RESET_MESSAGEBOX_STATE: 'RESET_MESSAGEBOX_STATE',
  START_ADDING_HASHTAG: 'START_ADDING_HASHTAG',
  START_ADDING_MENTION: 'START_ADDING_MENTION',
  TOGGLE_ADDING_SPONSER_MENU: 'TOGGLE_ADDING_SPONSER_MENU',
  TOGGLE_ADDING_TAG_MENU: 'TOGGLE_ADDING_TAG_MENU',
  TOGGLE_EMOJI_MENU: 'TOGGLE_EMOJI_MENU',
  UPDATE_HANDLE_HASHTAG: 'UPDATE_HANDLE_HASHTAG',
  UPDATE_HANDLE_MENTION: 'UPDATE_HANDLE_MENTION',
  UPDATE_SELECTED_SHARE_CONTENT: 'UPDATE_SELECTED_SHARE_CONTENT',
} as const;

export const MessageBoxState = {
  ADDING_INLINE_MENTION: 'ADDING_INLINE_MENTION',
  ADDING_INLINE_HASHTAG: 'ADDING_INLINE_HASHTAG',
  ADDING_EMOJI: 'ADDING_EMOJI',
  ADDING_SPONSER: 'ADDING_SPONSER',
  ADDING_TAG: 'ADDING_TAG',
  ADDITIONAL_OPTIONS_MENU_OPEN: 'ADDITIONAL_OPTIONS_MENU_OPEN',
  DEFAULT: 'DEFAULT',
} as const;

export const ADDING_TAG_STATES: MessageBoxStateType[] = [
  MessageBoxState.ADDING_INLINE_HASHTAG,
  MessageBoxState.ADDING_INLINE_MENTION,
];
