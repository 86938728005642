import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertFromRaw,
  EditorState,
  EntityInstance,
  Modifier,
  SelectionState,
} from 'draft-js';

import { ENTITY_TYPES } from 'common/constants';
import { mandatory } from 'common/validation';
import InlineEmoticon from 'components/compose/messagebox/InlineEmoticon';
import InlineTag from 'components/compose/messagebox/InlineTag';
import LinkBlock from 'components/compose/messagebox/LinkBlock';
import * as MessageProcessor from 'components/compose/messagebox/MessageProcessor';
import ShareLinkBlock from 'components/compose/messagebox/ShareLinkBlock';
import { EntityType, FixTypeLater, TagEntity, TagMap } from 'types';

interface EditorStateConfig {
  content: string;
  apiTypeId: number;
  replacementFields: TagMap;
  shareURL: string;
}

function findEntitiesByType(type: number) {
  return (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        // @ts-expect-error -- Return type from `getType()` is incorrectly typed as string. It should be number.
        contentState.getEntity(entityKey).getType() === type
      );
    }, callback);
  };
}

/**
 * Get the selected block from state
 */
export function getCurrentBlock(editorState: EditorState) {
  const selection = editorState.getSelection();
  return editorState
    .getCurrentContent()
    .getBlockForKey(selection.getAnchorKey());
}

/**
 * Gets all the text from all blocks in editor
 */
export function getDecoratedText(editorState: EditorState) {
  return editorState
    .getCurrentContent()
    .getBlocksAsArray()
    .map((block) => block.getText())
    .join('\n');
}

/**
 * Gets the undecorated text from all blocks in editor
 */
export function getUndecoratedText(editorState: EditorState) {
  const currentContent = editorState.getCurrentContent();
  const blockTexts = currentContent.getBlocksAsArray().map((block) => {
    let blockText = block.getText();
    let updatedText: string;
    let acc = 0;
    block.findEntityRanges(
      (character) => {
        const entity = character.getEntity();
        if (entity) {
          const { originalContent } = currentContent
            .getEntity(entity)
            .getData();

          // If there is original content to update then use this, otherwise do not
          // update the text for this entity
          if (originalContent) {
            updatedText = originalContent;
            return true;
          }
        }
        return false;
      },
      (startOrig, endOrig) => {
        const start = startOrig + acc;
        const end = endOrig + acc;

        // Update the text with the non-decorated content
        blockText =
          blockText.substr(0, start) + updatedText + blockText.substr(end);

        // Keep track of the change in length
        acc += updatedText.length - (end - start);
      },
    );
    return blockText;
  });
  return blockTexts.join('\n');
}

/**
 * Create DraftJS text decorator for the given API
 */
export function createDecorator() {
  const decorators = [
    {
      strategy: findEntitiesByType(ENTITY_TYPES.LINK),
      component: LinkBlock,
    },
    {
      strategy: findEntitiesByType(ENTITY_TYPES.SHARE_URL),
      component: ShareLinkBlock,
    },
    {
      strategy: findEntitiesByType(ENTITY_TYPES.INLINE_TAG),
      component: InlineTag,
    },
    {
      strategy: findEntitiesByType(ENTITY_TYPES.EMOTICON),
      component: InlineEmoticon,
    },
  ];
  return new CompositeDecorator(decorators);
}

/**
 * Creates a editor state from text
 */
function createContentState(config: EditorStateConfig) {
  const contentMap = createContentMap(config);
  // Our type construction doesn't match draft-js here. Needs improvement.
  return convertFromRaw(contentMap as FixTypeLater);
}

/**
 * Updates the editor state by replacing text at cursor and adding entity data to
 * the content so that that text can be decorated
 */
export function updateContentState({
  editorState,
  newText,
  newEntities = [],
}: {
  editorState: EditorState;
  newText: string;
  newEntities: {
    start: number;
    end: number;
    entity: TagEntity;
  }[];
}) {
  if (editorState === undefined) mandatory('editorState');
  if (newText === undefined) mandatory('newText');

  let selection = editorState.getSelection();
  let contentState = editorState.getCurrentContent();

  // Ensure the entities are sorted by start
  newEntities.sort((a, b) => a.start - b.start);

  // Move over text adding it to the context, if the text is an entity ensure to
  // link that entity when adding text
  let start = 0;
  let end = 0;
  let entityIndex = 0;
  let entityKey;
  while (start < newText.length) {
    const currentEntity = newEntities[entityIndex];
    if (currentEntity) {
      if (start >= currentEntity.start) {
        // In entity, so move to the next afterwards
        end = currentEntity.end;
        entityIndex += 1;

        // Add this entity to the context
        const entityData = currentEntity.entity;

        // We are passing in a entityType (number) even though draft-js is expecting a string.
        // However casting from number to string, causes a regression in the decorating the inline styles for a hashtag/mention,
        // so we will override the typescript error. It works, so I think it's typed wrong on their side.
        contentState = contentState.createEntity(
          // @ts-expect-error -- See explanation above
          entityData.type,
          entityData.mutability,
          entityData.data,
        );
        entityKey = contentState.getLastCreatedEntityKey();
      } else {
        // In plain text before entity
        end = currentEntity.start;
      }
    } else {
      // No more entities go to end of text
      end = newText.length;
    }

    // Select text block and add (as entity or plain text)
    const text = newText.substring(start, end);
    ({ selection, contentState } = insertTextAndSelectEnd(
      contentState,
      selection,
      text,
      entityKey,
    ));

    // Move the window across
    start = end;
    entityKey = undefined;
  }

  return insertContentState({
    editorState,
    selection,
    contentState,
  });
}

/**
 * Set the text and the decorator in the editor state
 */
export function createEditorState({
  config,
  initialFocus = true,
}: {
  config: {
    content: string;
    apiTypeId: number;
    replacementFields: TagMap;
    shareURL: string;
  };
  initialFocus?: boolean;
}) {
  const contentState = createContentState(config);
  const decorator = createDecorator();
  const editorState = EditorState.createWithContent(contentState, decorator);

  return initialFocus
    ? moveFocusToEnd(editorState)
    : moveSelectionToEnd(editorState);
}

/**
 * Updates an editors state be overriding the content completely but be keeping the
 * undo and redo stacks. Use this to programatically change the whole editor text
 */
export function setEditorContent({
  editorState,
  config,
}: {
  editorState: EditorState;
  config: EditorStateConfig;
}) {
  const selection = editorState.getSelection();
  const contentState = createContentState(config);

  // select the minimum between the previous selection offset and the length of the content
  // as the paste could make the text length shorter than what was there previously
  const newAnchor = Math.min(
    selection.getAnchorOffset(),
    config.content.length,
  );
  const newFocus = Math.min(selection.getFocusOffset(), config.content.length);
  const newEditorState = offsetSelection({
    editorState: EditorState.push(
      editorState,
      contentState,
      'change-block-data',
    ),
    anchorOffset: newAnchor,
    focusOffset: newFocus,
  });
  return newEditorState;
}

/**
 * Offsets the caret from the current position by the given amounts.
 */
export function offsetSelection({
  editorState,
  anchorOffset,
  focusOffset,
}: {
  editorState: EditorState;
  anchorOffset: number;
  focusOffset: number;
}) {
  if (editorState === undefined) mandatory('editorState');
  if (anchorOffset === undefined) mandatory('start');
  if (focusOffset === undefined) mandatory('end');

  const selection = editorState.getSelection();
  return forceSelection({
    editorState,
    start: selection.getAnchorOffset() + anchorOffset,
    end: selection.getFocusOffset() + focusOffset,
  });
}

/**
 * Sets the caret in to the given offset
 */
export function forceSelection({
  editorState,
  start,
  end,
}: {
  editorState: EditorState;
  start: number;
  end: number;
}) {
  if (editorState === undefined) mandatory('editorState');
  if (start === undefined) mandatory('start');
  if (end === undefined) mandatory('end');

  const selection = editorState.getSelection();
  const isBackward = selection.getIsBackward();
  return EditorState.forceSelection(
    editorState,
    selection.merge({
      anchorOffset: isBackward ? end : start,
      focusOffset: isBackward ? start : end,
    }),
  );
}

/**
 * Moves the selection to the end of the text
 */
export function moveSelectionToEnd(editorState: EditorState) {
  return EditorState.moveSelectionToEnd(editorState);
}

/**
 * Moves the selection to the end of the text and forces focus.
 */
export function moveFocusToEnd(editorState: EditorState) {
  return EditorState.moveFocusToEnd(editorState);
}

/**
 * Account for any characters that are multiple code-points (like emoji), these
 * need to be counted as code points and not characters
 */
export function getLengthInCodePoints({
  text,
  index,
}: {
  text: string;
  index: number;
}) {
  return [...text.substring(0, index)].length;
}

/**
 * Create the raw content map for the editor using the decorated content and the parsed
 * tokens, will create:
 *
 * - LINK entity for and URL
 * - INLINE_TAG entity for and hashtag/mention
 * - SHARE_URL entity for any share URL
 */
export function createContentMap({
  content,
  shareURL,
  apiTypeId,
  replacementFields,
}: EditorStateConfig) {
  if (content === undefined) mandatory('content');
  if (shareURL === undefined) mandatory('shareURL');
  if (apiTypeId === undefined) mandatory('apiTypeId');

  const entityMap: Record<string, TagEntity> = {};
  let counter = 0;

  const blocks = content.split(/\r\n?|\n/g).map((blockContent) => {
    const entityRanges: {
      key: string;
      offset: number;
      length: number;
    }[] = [];

    // Process the content to get decorated text and tokens
    const { decoratedText, tokens } = MessageProcessor.process({
      text: blockContent,
      apiTypeId,
      replacementFields,
      shareURL,
    });

    // Add all tokens
    tokens.forEach((token) => {
      const tag = `token_${(counter += 1)}`;
      const start = getLengthInCodePoints({
        text: decoratedText,
        index: token.start,
      });

      entityRanges.push({
        key: tag,
        offset: start,
        length: token.length,
      });

      switch (token.type) {
        case MessageProcessor.TOKEN_TYPES.SHARE_URL:
          entityMap[tag] = createShareURLEntity(token.text);
          break;
        case MessageProcessor.TOKEN_TYPES.TAG:
          entityMap[tag] = createInlineTagEntity(token.text);
          break;
        case MessageProcessor.TOKEN_TYPES.URL:
          entityMap[tag] = createLinkEntity();
          break;
        default:
        // Do nothing, will be treated as plain text
      }
    });

    // Sort entity ranges for debugging
    entityRanges.sort((a, b) => a.offset - b.offset);

    return {
      text: decoratedText,
      type: 'unstyled',
      entityRanges,
    };
  });

  return {
    blocks,
    entityMap,
  };
}

/**
 * Normal links can be edited like plain text
 */
export function createLinkEntity(): TagEntity {
  return {
    type: ENTITY_TYPES.LINK,
    mutability: 'MUTABLE',
    data: {
      addWhitespace: true,
    },
  };
}

/**
 * Share URLs are immutable and can only be deleted/replaced
 */
export function createShareURLEntity(originalContent: string): TagEntity {
  return {
    type: ENTITY_TYPES.SHARE_URL,
    mutability: 'IMMUTABLE',
    data: {
      addWhitespace: true,
      originalContent,
    },
  };
}

/**
 * Tags are immutable and can only be deleted/replaced
 */
export function createInlineTagEntity(originalContent: string): TagEntity {
  return {
    type: ENTITY_TYPES.INLINE_TAG,
    mutability: 'IMMUTABLE',
    data: {
      addWhitespace: true,
      originalContent,
    },
  };
}

export function insertInlineTag({
  editorState,
  decoratedContent,
  originalContent,
}: {
  editorState: EditorState;
  decoratedContent: string;
  originalContent: string;
}) {
  if (editorState === undefined) mandatory('editorState');
  if (decoratedContent === undefined) mandatory('decoratedContent');
  if (originalContent === undefined) mandatory('originalContent');

  return insertEntity({
    editorState,
    entityType: ENTITY_TYPES.INLINE_TAG,
    text: decoratedContent,
    data: { originalContent, addWhitespace: true },
  });
}

export function insertEmoticon({
  editorState,
  emoticon,
}: {
  editorState: EditorState;
  emoticon: string;
}) {
  if (editorState === undefined) mandatory('editorState');
  if (emoticon === undefined) mandatory('emoticon');

  return insertEntity({
    editorState,
    entityType: ENTITY_TYPES.EMOTICON,
    text: emoticon,
    data: { addWhitespace: false },
  });
}

function insertTextAndSelectEnd(
  contentState: ContentState,
  selection: SelectionState,
  text: string,
  entityKey: string | undefined = undefined,
) {
  const newContentState = Modifier.replaceText(
    contentState,
    selection,
    text,
    undefined,
    entityKey,
  );
  return {
    selection: newContentState.getSelectionAfter(),
    contentState: newContentState,
  };
}

function insertEntity({
  editorState,
  entityType,
  text,
  data,
}: {
  editorState: EditorState;
  entityType: EntityType;
  text: string;
  data: {
    originalContent?: string;
    addWhitespace: boolean;
  };
}) {
  let newEditorState = editorState;

  // If cursor is in entity replace the whole entity
  const entity = getSelectedEntity({
    editorState: newEditorState,
  });
  if (entity) {
    newEditorState = forceSelection({
      editorState: newEditorState,
      start: entity.start,
      end: entity.end,
    });
  }

  let contentState = newEditorState.getCurrentContent();
  let selection = newEditorState.getSelection();
  const { before, after } = getTextAtCursor(newEditorState);

  // Add a whitespace before entity if required
  if (
    isAddWhitespace({
      editorState: newEditorState,
      entityType,
      ch: before,
      offset: -1,
    })
  ) {
    ({ contentState, selection } = insertTextAndSelectEnd(
      contentState,
      selection,
      ' ',
    ));
  }

  // Insert the entity

  // We are passing in a entityType (number) even though draft-js is expecting a string.
  // However casting from number to string, causes a regression in the decorating the inline styles for a hashtag/mention,
  // so we will override the typescript error. It works, so I think it's typed wrong on their side.
  // @ts-expect-error
  contentState.createEntity(entityType, 'IMMUTABLE', data);
  const entityKey = contentState.getLastCreatedEntityKey();
  ({ contentState, selection } = insertTextAndSelectEnd(
    contentState,
    selection,
    text,
    entityKey,
  ));

  // And white after entity if required
  if (
    isAddWhitespace({
      editorState: newEditorState,
      entityType,
      ch: after,
      offset: 1,
    })
  ) {
    ({ contentState, selection } = insertTextAndSelectEnd(
      contentState,
      selection,
      ' ',
    ));
  }

  newEditorState = insertContentState({
    editorState: newEditorState,
    selection,
    contentState,
  });

  return newEditorState;
}

/**
 * Surround all new entity text with whitespace unless it is an emoticon that is not next
 * to another entity or is already surrounded by non alpha-numeric characters (so that
 * selection can be visually separated from inserted text)
 */
export function isAddWhitespace({
  editorState,
  entityType,
  ch,
  offset,
  addToNonAlphanumeric = false,
}: {
  editorState: EditorState;
  entityType: EntityType;
  ch: string;
  offset: number;
  addToNonAlphanumeric?: boolean;
}) {
  if (editorState === undefined) mandatory('editorState');
  if (entityType === undefined) mandatory('entityType');
  if (ch === undefined) mandatory('ch');
  if (offset === undefined) mandatory('offset');

  if (ch === ' ' || ch === '(') {
    return false;
  }

  const notEmoticonWithAlphanumeric = !addToNonAlphanumeric
    ? ENTITY_TYPES.EMOTICON !== entityType && /^[a-z0-9]+$/i.test(ch)
    : ENTITY_TYPES.EMOTICON !== entityType;

  const entityAtOffset = getSelectedEntity({
    editorState,
    offset,
  });
  const entityAtOffsetRequiresWhitespace =
    entityAtOffset?.entity.getData().addWhitespace;

  return notEmoticonWithAlphanumeric || entityAtOffsetRequiresWhitespace;
}

export function insertText({
  editorState,
  text,
}: {
  editorState: EditorState;
  text: string;
}) {
  if (editorState === undefined) mandatory('editorState');
  if (text === undefined) mandatory('text');

  const { contentState, selection } = insertTextAndSelectEnd(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    text,
  );

  return insertContentState({
    editorState,
    selection,
    contentState,
  });
}

/**
 * Insert the content state at current cusor position, replacing any selection found
 */
function insertContentState({
  editorState,
  selection,
  contentState,
}: {
  editorState: EditorState;
  selection: SelectionState;
  contentState: ContentState;
}) {
  let newEditorState = EditorState.push(
    editorState,
    contentState,
    'insert-characters',
  );

  newEditorState = EditorState.acceptSelection(newEditorState, selection);

  return newEditorState;
}

export function getTextAtCursor(editorState: EditorState) {
  const selection = editorState.getSelection();
  const currentBlockText = getCurrentBlock(editorState).getText();

  const indexBefore =
    Math.min(selection.getAnchorOffset(), selection.getFocusOffset()) - 1;
  const indexAfter = Math.max(
    selection.getAnchorOffset(),
    selection.getFocusOffset(),
  );
  return {
    before: currentBlockText.charAt(indexBefore),
    after: currentBlockText.charAt(indexAfter),
  };
}

export function getStringBeforeCursor(editorState: EditorState) {
  const selection = editorState.getSelection();
  const currentBlockText = getCurrentBlock(editorState).getText();

  const indexBefore = Math.min(
    selection.getAnchorOffset(),
    selection.getFocusOffset(),
  );
  const stringsBefore = currentBlockText.substring(0, indexBefore).split(' ');

  return stringsBefore[stringsBefore.length - 1];
}

export function getSelectedRange({
  editorState,
  offset = 0,
}: {
  editorState: EditorState;
  offset?: number;
}) {
  const selection = editorState.getSelection();

  const isBackward = selection.getIsBackward();

  let { start, end } = getSelectedRangeInBlock({
    editorState,
    offset,
  });

  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const firstBlockKey = blocks[0].getKey();

  const endBlock = isBackward
    ? selection.getAnchorKey()
    : selection.getFocusKey();
  // If the selection spans multiple bocks or starts after multiple blocks must account for this
  if (
    selection.getAnchorKey() !== selection.getFocusKey() ||
    firstBlockKey !== selection.getAnchorKey()
  ) {
    // Add the length of all the blocks between the selection start and end to the
    // length of selection
    let beforeSelection = true;
    let endSelection = false;
    for (let i = 0; i < blocks.length; i += 1) {
      const block = blocks[i];
      if (
        block.getKey() === selection.getAnchorKey() ||
        block.getKey() === selection.getFocusKey()
      ) {
        if (beforeSelection) {
          // Entering selection
          beforeSelection = false;
        } else {
          // Exiting selection
          break;
        }
      }

      // Add 1 for new line between blocks
      const blockLength = block.getLength() + 1;

      // Update ranges depending on selection state
      if (beforeSelection) {
        start += blockLength;
      }

      if (!endSelection) {
        endSelection = endBlock === block.getKey();
      }
      if (!endSelection) {
        end += blockLength;
      }
    }
  }

  return { start, end };
}

export function getSelectedDecoratedText(editorState: EditorState) {
  const allText = getDecoratedText(editorState);
  const selection = getSelectedRange({ editorState });
  const text = allText.substring(selection.start, selection.end);
  return { text, selection };
}

/**
 * Returns an array of entities in the selection, only those entities that are completely in
 * the selection range.
 */
export function getEntitiesInSelection(editorState: EditorState) {
  const selection = getSelectedRange({ editorState });

  let previousKey: string;
  let entityKey: string;
  const selectedEntities: {
    entity: EntityInstance;
    start: number;
    end: number;
  }[] = [];
  let acc = 0;
  editorState
    .getCurrentContent()
    .getBlocksAsArray()
    .forEach((block) => {
      block.findEntityRanges(
        (character) => {
          entityKey = character.getEntity();

          // Only trigger entity range function once per entity
          return entityKey !== previousKey && entityKey !== null;
        },
        (start, end) => {
          previousKey = entityKey;

          // Adjust the start and end to account for previous blocks
          const startAdj = start + acc;
          const endAdj = end + acc;

          // Only add if entity is completely inside of the selected range (inclusively)
          if (startAdj >= selection.start && endAdj <= selection.end) {
            selectedEntities.push({
              entity: editorState.getCurrentContent().getEntity(entityKey),
              start: startAdj,
              end: endAdj,
            });
          }
        },
      );

      // Keep track of previous block lengths
      acc += block.getLength() + 1;
    });
  return selectedEntities;
}

function getSelectedRangeInBlock({
  editorState,
  offset = 0,
}: {
  editorState: EditorState;
  offset?: number;
}) {
  const selection = editorState.getSelection();

  const isBackward = selection.getIsBackward();
  const start =
    (isBackward ? selection.getFocusOffset() : selection.getAnchorOffset()) +
    offset;
  const end =
    (isBackward ? selection.getAnchorOffset() : selection.getFocusOffset()) +
    offset;

  return { start, end };
}

/**
 * Returns the currently selected entity, being the entity that the cursor or selection range is
 * inside. If a whole entity is selected it will return that too, will not return if multiple
 * entities are selected.
 */
export function getSelectedEntity({
  editorState,
  offset = 0,
}: {
  editorState: EditorState;
  offset?: number;
}) {
  const selection = getSelectedRangeInBlock({
    editorState,
    offset,
  });

  let entityKey: string;
  let selectedEntity:
    | { entity: EntityInstance; start: number; end: number }
    | undefined;
  getCurrentBlock(editorState).findEntityRanges(
    (character) => {
      entityKey = character.getEntity();
      // Only match the first selected entity
      return !selectedEntity && entityKey !== null;
    },
    (start, end) => {
      // Check is selection is inside entity, if a selection range can be the whole entity
      // otherwise is exclusive of start and end
      const noRangeExclusiveOfEnd =
        selection.start === selection.end &&
        start < selection.start &&
        end > selection.end;
      const rangeInclusive =
        selection.start !== selection.end &&
        start <= selection.start &&
        end >= selection.end;
      if (noRangeExclusiveOfEnd || rangeInclusive) {
        selectedEntity = {
          entity: editorState.getCurrentContent().getEntity(entityKey),
          start,
          end,
        };
      }
    },
  );
  return selectedEntity;
}

/**
 * Call an undo operation programmatically
 */
export function undoChange(editorState: EditorState) {
  return EditorState.undo(editorState);
}

export function hasFocus(editorState: EditorState) {
  return editorState.getSelection().getHasFocus();
}

export function getSelectionStartIndex(editorState: EditorState) {
  return editorState.getSelection().getStartOffset();
}

export function getChangeInStartPosition(
  oldEditorState: EditorState,
  newEditorState: EditorState,
) {
  return Math.abs(
    getSelectionStartIndex(oldEditorState) -
      getSelectionStartIndex(newEditorState),
  );
}
