import twitter from 'twitter-text';

import { replaceShareURL } from 'common/shareURL';

/**
 * Counts the characters in the text as they will be counted on Twitter
 */
export default function getTwitterCount(text?: string | null) {
  let message = text;

  // Replace the placeholder with any URL so will get the correct count
  message = replaceShareURL({ text: message, shareURL: 'http://count.com' });

  // Twitter cannot handle null text
  return message == null ? 0 : twitter.getTweetLength(message);
}
