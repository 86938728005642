import PropTypes from 'prop-types';
import { memo, useState } from 'react';

import { Box, Flex, Image } from '@ebx-ui/ebx-ui-component-library-sdk';
import { getAPITypeId, getCurrentPropertyId } from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  ACTION_HISTORY_TYPES,
  API_TYPE_IDS,
  COLLECTION_NAMES,
  POST_TYPES,
  SHARE_ORIGINS,
  SOCIAL_CHANNELS,
  TEXT_CASES,
} from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import { getTextDirection } from 'common/language';
import * as MediaItem from 'common/mediaItem';
import { isEbxImage } from 'common/s3';
import { getSetting, isIGCabinetEnabled } from 'common/settings';
import { replaceShareURL } from 'common/shareURL';
import {
  canDeletePosts,
  canResharePosts,
  canReshareVideos,
} from 'common/social';
import { getImageThumbnail, getVideoThumbnail } from 'common/thumbnails';
import { isVideoURL } from 'common/url';
import { arePropsEqual, isNull } from 'common/utility';
import DeleteArticle from 'components/home/DeleteArticle';
import ItemError from 'components/home/ItemError';
import Loading from 'components/home/lastshared/Loading';
import { addImagePrefix } from 'helpers/images';
import useThumbnail from 'hooks/useThumbnail';
import { useIsComposeBoxOpen } from 'state/composeBoxOpen';

/**
 * Article feed item
 */

const Item = (props) => {
  const isComposeBoxOpen = useIsComposeBoxOpen();

  const [isDeleting, setIsDeleting] = useState(false);
  const videoURL = MediaItem.getVideoURL({ mediaItem: props.mediaItem });
  let imageURLs = MediaItem.getImageURLs({ mediaItem: props.mediaItem });
  const thumbnail = useThumbnail({
    videoURL,
    shouldFetch:
      imageURLs == null || imageURLs.length === 0 || !isEbxImage(imageURLs[0]),
  });

  if (imageURLs && thumbnail) {
    imageURLs = [thumbnail];
  }

  const handleArticleDelete = () => {
    setIsDeleting(false);
    props.eventHandlers.handleArticleDelete({
      collectionName: COLLECTION_NAMES.LAST_SHARED,
      mediaId: MediaItem.getMediaId({
        mediaItem: props.mediaItem,
      }),
      showConfirmation: true,
    });
  };

  const handleArticleDeleteCancel = () => {
    setIsDeleting(false);
  };

  const handleArticleReshare = () => {
    if (isComposeBoxOpen) {
      return;
    }

    props.eventHandlers.handleArticleReshare({
      mediaId: MediaItem.getMediaId({ mediaItem: props.mediaItem }),
      sourceCollection: COLLECTION_NAMES.LAST_SHARED,
      shareOrigin: SHARE_ORIGINS.LAST_SHARED_RESHARE,
      thumbnail,
    });
  };

  const handleSetArticleDelete = () => {
    setIsDeleting(true);
  };

  const mediaItem = props.mediaItem;
  const mediaId = MediaItem.getMediaId({ mediaItem });
  const accountAPIId = props.accountAPIId;
  const isLoading = MediaItem.getIsLoading({ mediaItem });

  if (isLoading) {
    return <Loading mediaId={mediaId} />;
  }

  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const errorMessage = MediaItem.getErrorMessage({ mediaItem });
  const isSaving = MediaItem.getIsSaving({ mediaItem });
  const labels = MediaItem.getMediaItemTags({ mediaItem });
  const message = MediaItem.getMessage({ mediaItem });
  const socialPostURL = MediaItem.getSocialPostURL({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const rssTitle = MediaItem.getRssTitle({ mediaItem });
  const sharedTime = MediaItem.getUnixTimeShared({ mediaItem });
  const shareURL = MediaItem.getShareURL({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId: props.accountAPIId });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: rssTitle,
  });

  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getCurrentPropertyId(),
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;

  let articleClass = '';
  if (errorMessage !== '') {
    articleClass = 'error_validation_box';
  }

  if (isSaving) {
    articleClass += 'saving_locked';
  }

  const canDelete = canDeletePosts({ apiTypeId });
  const canReshare =
    postType === POST_TYPES.VIDEO
      ? canReshareVideos({ apiTypeId })
      : canResharePosts({ apiTypeId });

  const actionTypeId = MediaItem.getActionTypeId({ mediaItem });
  const isFacebookNativeReelShare =
    socialChannel === SOCIAL_CHANNELS.REEL &&
    apiTypeId === API_TYPE_IDS.FACEBOOK &&
    actionTypeId !== ACTION_HISTORY_TYPES.ECHOBOX_SHARE;

  const previewFields = MediaItem.getPreviewFields({ mediaItem });

  const updatedImageUrls = previewFields.imageOverlayResult
    ? [previewFields.imageOverlayResult]
    : previewFields.imageURLs;

  let backgroundImage;
  let imageThumbnail;
  let videoThumbnail;
  if (updatedImageUrls.length === 0) {
    if (postType === POST_TYPES.VIDEO) {
      videoThumbnail = getVideoThumbnail({ apiTypeId, isNewsFeed: true });
      backgroundImage = videoThumbnail;
    } else {
      imageThumbnail = getImageThumbnail({
        apiTypeId,
        postType,
        isNewsFeed: true,
      });
      backgroundImage = imageThumbnail;
    }
  } else {
    backgroundImage = addImagePrefix(imageURLs[0]);
  }

  const isVideoPost = postType === POST_TYPES.VIDEO;

  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;
  const isInstagramGraphStory =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    !isIGCabinetEnabled();
  const isInstagramStory =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    (postType === POST_TYPES.LINK || postType === POST_TYPES.PHOTO_STORY);
  const isInstagram916 =
    isInstagramStory ||
    isInstagramGraphStory ||
    socialChannel === SOCIAL_CHANNELS.REEL;

  let w = '96px';
  let h = '54px';

  if (isTikTok) {
    w = '66px';
    h = '116px';
  }

  if (isInstagram916) {
    w = '50px';
    h = '96px';
  }

  return (
    <div
      className={`share_item ${articleClass}`}
      data-tip
      data-for={mediaId}
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <Flex gap="2">
        <Box
          w={w}
          minW={w}
          h={h}
          backgroundSize="cover"
          backgroundPosition="center"
          style={{
            backgroundImage: `url("${thumbnail || backgroundImage}")`,
          }}
        >
          {isVideoPost && (
            <Flex h="full" w="full" justifyContent="center" alignItems="center">
              <Image
                borderRadius="full"
                bg="rgba(235, 238, 245, 0.35)"
                p={1}
                h={6}
                w={6}
                src="/img/icons/video-icon.svg"
                alt=""
              />
            </Flex>
          )}
        </Box>
        <Box
          className={`article_title ${textDirectionClass}`}
          data-cy-id="title"
          height="max-content"
        >
          {postType === POST_TYPES.LINK
            ? replaceShareURL({ text: rssTitle, shareURL })
            : message}
        </Box>
      </Flex>

      {isDeleting && (
        <DeleteArticle
          mediaItem={props.mediaItem}
          accountAPIId={props.accountAPIId}
          onArticleDelete={handleArticleDelete}
          onArticleDeleteCancel={handleArticleDeleteCancel}
        />
      )}
      <div data-cy-attribute="isLoading:false" className="w-100">
        {isVideoURL(articleURL) && (
          <img
            src="/img/icons/ic-play.svg"
            alt=""
            className="last-shared-video"
          />
        )}
        <div className="d-none" data-cy-id="guid">
          {labels[0]?.tag}
        </div>
        <div className="top">
          {errorMessage !== '' && <ItemError errorMessage={errorMessage} />}
        </div>
        <div className="right" />
        <div className="d-flex align-items-center mt-2">
          <a
            href={socialPostURL}
            className="article_link link_grey"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shared{' '}
            {getFormattedDateFromUnix({
              timestamp: sharedTime,
              twelveHourFormat,
              textCase: TEXT_CASES.LOWER,
            })}
            <img
              className="link_icon"
              src="/img/icons/ic-open-in-new.svg"
              alt=""
            />
          </a>
          {(canDelete || canReshare) && (
            <div className="ml-auto">
              {canDelete && (
                <a
                  className="delete link_grey mr-2 text-500"
                  onClick={handleSetArticleDelete}
                  data-cy-action="deleteLastShared"
                >
                  Delete
                </a>
              )}
              {canReshare && !isFacebookNativeReelShare && (
                <a
                  className="link_grey text-500"
                  onClick={handleArticleReshare}
                  data-cy-action="reshareLastShared"
                >
                  Reshare
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/* jscpd:ignore-start */
Item.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  accountAPIId: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
    handleArticleLoad: PropTypes.func.isRequired,
    handleArticleReshare: PropTypes.func.isRequired,
  }).isRequired,
};
/* jscpd:ignore-end */

export default memo(Item, arePropsEqual);
