import { APITypeId } from 'types';
import { API_TYPE_IDS } from './accountAPIs';

export interface AnalyticsItem {
  name: string;
  description?: string;
  default?: boolean;
  sortKey?: string;
}

/**
 * An object containing the analytics insight ID and their attributes, grouped by social network.
 *
 * Populated from the data in this Google sheet: https://docs.google.com/spreadsheets/d/1V-hkJq07sdLpBAJdA5Na-dhpcMOSKYeaNg9gjrUnp2Y/edit#gid=1383087279
 */
export const ANALYTICS_INSIGHTS: Partial<
  Record<APITypeId, Record<string, AnalyticsItem>>
> = {
  [API_TYPE_IDS.FACEBOOK]: {
    // Default insights
    impressions: {
      name: 'Impressions',
      description:
        "The number of times your Page's post entered a person's screen.",
      default: true,
    },
    clicks: {
      name: 'Clicks',
      description:
        'The number of times people clicked on anywhere in your posts without generating a story.',
      default: true,
    },
    ctr: {
      name: 'CTR',
      description:
        'The proportion of your posts that prompted readers to click',
      default: true,
    },
    totalSocialEngagement: {
      name: 'Engagement',
      description:
        'The number of Reactions, Comments and Shares of your posts, in the selected timeframe',
      default: true,
      sortKey: 'totalengagement',
    },

    // Extra insights
    PostImpressionsUnique: {
      name: 'Impressions (Unique)',
      description:
        "The number of people who had your Page's post enter their screen.",
    },
    PostImpressionsPaid: {
      name: 'Impressions (Paid)',
      description:
        "The number of times your Page's post entered a person's screen through paid distribution such as an ad.",
    },
    PostImpressionsPaidUnique: {
      name: 'Impressions (Paid, Unique)',
      description:
        "The number of people who had your Page's post enter their screen through paid distribution such as an ad.",
    },
    PostImpressionsFan: {
      name: 'Impressions (Fan)',
      description:
        'The number of impressions for your Page post by people who have liked your Page.',
    },
    PostImpressionsFanUnique: {
      name: 'Impressions (Fan, Unique)',
      description:
        'The number of people who have liked your Page who saw your Page post.',
    },
    PostImpressionsOrganic: {
      name: 'Impressions (Organic)',
      description:
        "The number of times your Page's posts entered a person's screen through unpaid distribution.",
    },
    PostImpressionsOrganicUnique: {
      name: 'Impressions (Organic, Unique)',
      description:
        "The number of people who had your Page's post enter their screen through unpaid distribution.",
    },
    PostImpressionsViral: {
      name: 'Impressions (Viral)',
      description:
        "The number of times your Page's post entered a person's screen with social information attached. Social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
    },
    PostImpressionsViralUnique: {
      name: 'Impressions (Viral, Unique)',
      description:
        "The number of people who had your Page's post enter their screen with social information attached. As a form of organic distribution, social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
    },
    PostImpressionsByStoryOther: {
      name: 'Impressions By Story (Other)',
      description:
        'The number of people who saw your Page post in a story from a friend, by story type "other".',
    },
    PostImpressionsByStoryOtherUnique: {
      name: 'Impressions By Story (Other, Unique)',
      description:
        'The number of people who saw your Page post in a story from a friend, by story type "other unique".',
    },
    PostClicksUnique: {
      name: 'Clicks (Unique)',
      description:
        'The number of people who clicked anywhere in your post without generating a story.',
    },
    PostClicksByLinkClicks: {
      name: 'Clicks (Link Clicks)',
      description:
        'The number of times people clicked on anywhere in your posts without generating a story, by consumption type "Link Clicks".',
    },
    PostClicksByPhotoViews: {
      name: 'Clicks (Photo Views)',
      description:
        'The number of times people clicked on anywhere in your posts without generating a story, by consumption type "Photo Views".',
    },
    PostClicksByVideoPlays: {
      name: 'Clicks (Video Plays)',
      description:
        'The number of times people clicked on anywhere in your posts without generating a story, by consumption type "Video Plays".',
    },
    PostClicksByOtherClicks: {
      name: 'Clicks (Other Clicks)',
      description:
        'The number of times people clicked on anywhere in your posts without generating a story, by consumption type "Other Clicks".',
    },
    PostClicksByLinkClicksUnique: {
      name: 'Clicks (Link Clicks, Unique)',
      description:
        'The number of people who clicked anywhere in your post without generating a story, by consumption type "Link Clicks".',
    },
    PostClicksByPhotoViewsUnique: {
      name: 'Clicks (Photo Views, Unique)',
      description:
        'The number of people who clicked anywhere in your post without generating a story, by consumption type "Photo Views".',
    },
    PostClicksByVideoPlaysUnique: {
      name: 'Clicks (Video Plays, Unique)',
      description:
        'The number of people who clicked anywhere in your post without generating a story, by consumption type "Video Plays".',
    },
    PostClicksByOtherClicksUnique: {
      name: 'Clicks (Other Clicks, Unique)',
      description:
        'The number of people who clicked anywhere in your post without generating a story, by consumption type "Other Clicks".',
    },
    PostEngagedUsers: {
      name: 'Engaged Users',
      description: 'The number of people who clicked anywhere in your posts.',
    },
    PostNegativeFeedback: {
      name: 'Negative Feedback',
      description:
        'The number of times people took a negative action in your post (e.g. hid it).',
    },
    PostNegativeFeedbackUnique: {
      name: 'Negative Feedback (Unique)',
      description:
        'The number of people who took a negative action in your post (e.g. hid it).',
    },
    PostNegativeFeedbackByHideClicks: {
      name: 'Negative Feedback (Hide Clicks)',
      description: 'The number of times people hid a post.',
    },
    PostNegativeFeedbackByHideAllClicks: {
      name: 'Negative Feedback (Hide All Clicks)',
      description: 'The number of times people hid all posts from your Page.',
    },
    PostNegativeFeedbackByUnlikePageClicks: {
      name: 'Negative Feedback (Unlike Page Clicks)',
      description: 'The number of times people unliked a page.',
    },

    PostNegativeFeedbackByReportSpamClicks: {
      name: 'Negative Feedback (Report Spam Clicks)',
      description: 'The number of times people reported a post as spam.',
    },
    PostNegativeFeedbackByHideClicksUnique: {
      name: 'Negative Feedback (Hide Clicks, Unique)',
      description: 'The number of people who hid a post.',
    },
    PostNegativeFeedbackByHideAllClicksUnique: {
      name: 'Negative Feedback (Hide All Clicks, Unique)',
      description: 'The number of people who hid all posts from a page.',
    },
    PostNegativeFeedbackByUnlikePageClicksUnique: {
      name: 'Negative Feedback (Unlike Page Clicks, Unique)',
      description: 'The number of people who unliked a page.',
    },
    PostNegativeFeedbackByReportSpamClicksUnique: {
      name: 'Negative Feedback (Report Spam Clicks, Unique)',
      description: 'The number of people who reported an object as spam.',
    },
    PostVideoAvgTimeWatched: {
      name: 'Video Average Time Watched',
      description:
        'The average time, in milliseconds, people viewed your videos. Only available for videos created after August 25th 2016. Returns 0 for reshared videos.',
    },
    PostVideoCompleteViewsOrganic: {
      name: 'Video Complete Views (Organic)',
      description:
        "The number of times your videos played from the beginning to 97%, or more, of its length, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video. Returns 0 for reshared videos.",
    },
    PostVideoCompleteViewsOrganicUnique: {
      name: 'Video Complete Views (Organic, Unique)',
      description:
        "The number of people who viewed your videos from the beginning to 97%, or more, of its length, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video. Returns 0 for reshared videos.",
    },
    PostVideoCompleteViewsPaid: {
      name: 'Video Complete Views (Paid)',
      description:
        "The number of times your promoted videos played from the beginning to 97%, or more, of its length. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video. Returns 0 for reshared videos.",
    },
    PostVideoCompleteViewsPaidUnique: {
      name: 'Video Complete Views (Paid, Unique)',
      description:
        "The number of people who viewed your promoted videos from the beginning to 97%, or more, of its length. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
    },
    PostVideoViewsOrganic: {
      name: 'Video Views (Organic)',
      description:
        "The number of times your videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViewsOrganicUnique: {
      name: 'Video Views (Organic, Unique)',
      description:
        "The number of people who viewed your videos for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViewsPaid: {
      name: 'Video Views (Paid)',
      description:
        "The number of times your promoted videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
    },
    PostVideoViewsPaidUnique: {
      name: 'Video Views (Paid, Unique)',
      description:
        "The number of people who viewed your promoted videos for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
    },
    PostVideoLength: {
      name: 'Video Length',
      description: 'The length, in milliseconds, of a video post.',
    },
    PostVideoViews: {
      name: 'Video Views',
      description:
        "The number of times your videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video. This includes live views.",
    },
    PostVideoViewsUnique: {
      name: 'Video Views (Unique)',
      description:
        "The number of people who viewed your videos for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViewsAutoplayed: {
      name: 'Video Views (Autoplayed)',
      description:
        "The number of times your videos automatically played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViewsClickedToPlay: {
      name: 'Video Views (Clicked to Play)',
      description:
        "The number of times your videos played for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds, after people clicked play. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViews10SUnique: {
      name: 'Video Views (10s, Unique)',
      description:
        "Deprecated above Graph API v18: The number of times your videos played for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViews10SAutoplayed: {
      name: 'Video Views (10s, Autoplayed)',
      description:
        "Deprecated above Graph API v18: The number of times your videos played automatically for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViews10SClickedToPlay: {
      name: 'Video Views (10s, Clicked to Play)',
      description:
        "Deprecated above Graph API v18: The number of times your videos played for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds, after people clicked play. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViews10SOrganic: {
      name: 'Video Views (10s, Organic)',
      description:
        "Deprecated above Graph API v18: The number of times your videos played for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViews10SPaid: {
      name: 'Video Views (10s, Paid)',
      description:
        "Deprecated above Graph API v18: The number of times your promoted videos played for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
    },
    PostVideoViews10SSoundOn: {
      name: 'Video Views (10s, Sound On)',
      description:
        "Deprecated above Graph API v18: The number of times your videos played with sound on for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViewsSoundOn: {
      name: 'Video Views (Sound on)',
      description:
        "The number of times your videos played with sound on for at least 3 seconds, or for nearly their total length if they're shorter than 3 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoViewTime: {
      name: 'Video View Time',
      description:
        'The total time, in milliseconds, your videos played, including videos played for less than 3 seconds and replays. Returns 0 for reshared videos.',
    },
    PostVideoViewTimeOrganic: {
      name: 'Video View Time (Organic)',
      description:
        'The total time, in milliseconds, your videos played by organic reach. Returns 0 for reshared videos.',
    },
    PostVideoViewsByDistributionTypeShared: {
      name: 'Video Views (Distribution Type, Shared)',
      description:
        'The number of times your videos played by distribution type; shared.',
    },
    PostVideoViewsByDistributionTypePageOwned: {
      name: 'Video Views (Distribution Type, Owned)',
      description:
        'The number of times your videos played by distribution type; page_owned.',
    },
    PostVideoViewTimeByDistributionTypeShared: {
      name: 'Video View Time (Distribution Type, Shared)',
      description:
        'The total time, in milliseconds, your videos played by distribution type; shared.',
    },
    PostVideoViewTimeByDistributionTypeOwned: {
      name: 'Video View Time (Distribution Type, Owned)',
      description:
        'The total time, in milliseconds, your videos played by distribution type; page_owned.',
    },
    PostVideoCompleteViews30SAutoplayed: {
      name: 'Video Complete Views (30s, Autoplayed)',
      description:
        "The number of times your Page's automatically played videos played for at least 30 seconds, or for nearly their total length if they're shorter than 30 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoCompleteViews30SClickedToPlay: {
      name: 'Video Complete Views (30s, Clicked To Play)',
      description:
        "The number of times your Page's videos played for at least 30 seconds, or for nearly their total length if they're shorter than 30 seconds, after people clicked play. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoCompleteViews30SOrganic: {
      name: 'Video Complete Views (30s, Organic)',
      description:
        "The number of times your Page's videos played for at least 30 seconds, or for nearly their total length if they're shorter than 30 seconds, by organic reach. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostVideoCompleteViews30SPaid: {
      name: 'Video Complete Views (30s, Paid)',
      description:
        "The number of times your Page's promoted videos played for at least 30 seconds, or for nearly their total length if they're shorter than 30 seconds. For each impression of a video, we'll count video views separately and exclude any time spent replaying the video.",
    },
    PostVideoCompleteViews30SUnique: {
      name: 'Video Complete Views (30s, Unique)',
      description:
        "The number of people who viewed your Page's videos for at least 30 seconds, or for nearly their total length if they're shorter than 30 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostStorytellers: {
      name: 'Storytellers',
      description:
        'The number of people who create a story about your Page post.',
    },
    PostEngagedFan: {
      name: 'Engaged Fan',
      description:
        'People who have liked your Page and engaged with your post.',
    },
    PostReactionsLike: {
      name: 'Reactions Like',
      description: 'Total "like" reactions of a post or Reel.',
    },
    PostReactionsLoveTotal: {
      name: 'Reactions Love',
      description: 'Total "love" reactions of a post.',
    },
    PostReactionsWowTotal: {
      name: 'Reactions Wow',
      description: 'Total "wow" reactions of a post.',
    },
    PostReactionsHahaTotal: {
      name: 'Reactions Haha',
      description: 'Total "haha" reactions of a post.',
    },
    PostReactionsSorryTotal: {
      name: 'Reactions Sorry',
      description: 'Total "sad" reactions of a post.',
    },
    PostReactionsAngerTotal: {
      name: 'Reactions Anger',
      description: 'Total "anger" reactions of a post.',
    },
    PostVideoViews10S: {
      name: 'Video Views (10s)',
      description:
        "Deprecated above Graph API v18: The number of times your videos played for at least 10 seconds, or for nearly their total length if they're shorter than 10 seconds. During a single instance of a video playing, we'll exclude any time spent replaying the video.",
    },
    PostImpressionsNonviralUnique: {
      name: 'Impressions (Nonviral, Unique)',
      description:
        "The number of people who had your Page's post enter their screen. This does not include content created about your Page with social information attached. As a form of organic distribution, social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
    },
    PostVideoAdBreakAdImpressions: {
      name: 'Video Ad Break (Ad Impressions)',
      description:
        'The total number of times an ad was shown during ad breaks in your videos.',
    },
    PostVideoAdBreakEarnings: {
      name: 'Video Ad Break (Earnings)',
      description:
        'An estimate of the amount you earned from ad breaks in your videos, based on the number of impressions and CPM of ads shown. Actual payments may differ if there are content ownership claims or other adjustments.',
    },
    PostVideoAdBreakAdCpm: {
      name: 'Video Ad Break (Ad CPM)',
      description:
        'The average amount paid by advertisers for 1,000 impressions of their ads in your videos. This number also includes the amount paid to Facebook.',
    },
    PostImpressionsNonviral: {
      name: 'Impressions (Nonviral)',
      description:
        "The number of times your Page's post entered a person's screen. This does not include content created about your Page with social information attached. Social information displays when a person's friend interacted with your Page or post. This includes when someone's friend likes or follows your Page, engages with a post, shares a photo of your Page and checks into your Page.",
    },
    PostVideoPlayRate: {
      name: 'Video Play Rate',
      description:
        'The proportion of your posts that prompted readers to play the video',
    },
    PostVideoSocialActions: {
      name: 'Reel Actions',
      description:
        'The number of comments on your reel and the number of times your reel was shared.',
    },
    BlueReelsPlayCount: {
      name: 'Reel Plays',
      description:
        'The number of times your reel starts to play after an impression is counted. Excludes replays.',
    },
  },

  [API_TYPE_IDS.TWITTER]: {
    pageviews: {
      name: 'Pageviews',
      default: true,
      description:
        'The number of views of the shared article arriving from Twitter',
    },
  },

  [API_TYPE_IDS.LINKEDIN]: {
    // Default insights
    impressions: {
      name: 'Impressions',
      description:
        "The number of times your posts entered a LinkedIn user's screen in the selected timeframe",
      default: true,
    },
    clicks: {
      name: 'Clicks',
      description:
        'The number of clicks on links, the logo or your Page name on your posts',
      default: true,
    },
    ctr: {
      name: 'CTR',
      description:
        'The proportion of your posts that prompted readers to click',
      default: true,
    },
    totalSocialEngagement: {
      name: 'Engagement',
      description:
        'The number of Reactions, Comments and Shares of your posts, in the selected timeframe',
      default: true,
      sortKey: 'totalengagement',
    },

    // Extra insights
    CommentCount: {
      name: 'Comment Count',
      description: 'Number of comments on a share.',
    },
    ImpressionCount: {
      name: 'Impression Count',
      description:
        "The number of times your share is at least 50% in view, for at least 300 milliseconds on a signed-in member's device screen or browser window.",
    },
    LikeCount: {
      name: 'Like Count',
      description: 'The number of likes your share receives.',
    },
    ShareCount: {
      name: 'Share Count',
      description: 'The number of times your post was shared.',
    },
    UniqueImpressionCount: {
      name: 'Impression Count (Unique)',
      description:
        'The number of signed-in users for whom your share is at least 50% in view, for at least 300 milliseconds on their device screen or browser window.',
    },
  },

  [API_TYPE_IDS.INSTAGRAM]: {
    // Default insights
    impressions: {
      name: 'Impressions',
      description: 'Number of times the video was shown to users.',
      default: true,
    },
    reach: {
      name: 'Reach',
      description:
        'The number of people who have seen your media in the Discover tab, feed, tagged posts, location, or hashtag search.',
      default: true,
    },
    totalSocialEngagement: {
      name: 'Engagement',
      description: 'Sum of likes, comments, shares and saves on this share',
      default: true,
      sortKey: 'totalengagement',
    },
    engagementRate: {
      name: 'Engagement Rate',
      description:
        'How often a view of this share has resulted in a like, comment, save or share',
      default: true,
    },

    // Extra insights
    Replies: {
      name: 'Replies',
      description: 'Number of replies to your story.',
    },
    Saved: {
      name: 'Saved',
      description: 'The number of times your media was saved.',
    },
    Shares: {
      name: 'Shares',
      description: 'The number of times your reel was shared.',
    },
    TotalInteractions: {
      name: 'Total Interactions',
      description: 'The sum of all interactions for the reel.',
    },
    VideoPlayRate: {
      name: 'Video Play Rate',
    },
    VideoViews: {
      name: 'Video Views',
      description:
        'The number of times your media was viewed for longer than 3 seconds.',
    },
  },

  [API_TYPE_IDS.TIKTOK]: {
    // Default insights
    impressions: {
      name: 'Views',
      description:
        "The number of times your posts entered a TikTok user's screen in the selected timeframe",
      default: true,
    },
    totalSocialEngagement: {
      name: 'Engagement',
      description:
        'The number of Reactions, Comments and Shares of your posts, in the selected timeframe',
      default: true,
      sortKey: 'totalengagement',
    },

    // Extra insights
    VideoViews: {
      name: 'Video Views',
      description:
        "Total number of times the TikTok Account's videos have been viewed within the specified day.",
    },
    VideoDuration: {
      name: 'Video Duration',
      description: 'Video length - in seconds, up to three decimal places.',
    },
    Likes: {
      name: 'Likes',
      description: 'Total number of likes on the video.',
    },
    Comments: {
      name: 'Comments',
      description: 'Total number of comments on the video.',
    },
    Shares: {
      name: 'Shares',
      description: 'Total number of times the video has been shared.',
    },
    Reach: {
      name: 'Reach',
      description: 'Total number of unique users who viewed the video.',
    },
    FullVideoWatchedRate: {
      name: 'Full Video Watched Rate',
      description:
        'Percentage of views that completed watching the full video.',
    },
    TotalTimeWatched: {
      name: 'Total Time Watched',
      description:
        'Total watch time of video aggregated across all views - in seconds.',
    },
    AverageTimeWatched: {
      name: 'Average Time Watched',
      description:
        'Average watch time of video calculated across all views - in seconds.',
    },
    ImpressionSourceForYou: {
      name: 'Impression Source (For You)',
      description:
        'Percentage of views that originated from a user For You feed.',
    },
    ImpressionSourceFollow: {
      name: 'Impression Source (Follow)',
      description:
        'Percentage of views that originated from a user Follow feed.',
    },
    ImpressionSourceHashtag: {
      name: 'Impression Source (Hashtag)',
      description: 'Percentage of views that originated from a hashtag.',
    },
    ImpressionSourceSound: {
      name: 'Impression Source (Sound)',
      description:
        "Percentage of views that originated from the video's sound.",
    },
    ImpressionSourcePersonalProfile: {
      name: 'Impression Source (Personal Profile)',
      description:
        "Percentage of views that originated from the business account's profile page.",
    },
    ImpressionSourceSearch: {
      name: 'Impression Source (Search)',
      description:
        'Percentage of views that originated from a search on the Discover page.',
    },
  },
};
