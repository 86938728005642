import Cookies from 'js-cookie';
import PubSub from 'pubsub-js';
import { Outlet } from 'react-router-dom';

import getStatusPageIncidents from 'api/external/getStatusPageIncidents';
import * as authentication from 'common/authentication';
import { REFRESH_INTERVALS } from 'common/config';
import { FRONTEND_PAGE_MAP } from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import * as logger from 'common/logger';
import { randomish } from 'common/maths';
import * as metrics from 'common/metrics';
import { isDefined, isRunningTests, isUndefined } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import StatusPageContext from 'context/StatusPageContext';
import ReactGAWrapper from 'layouts/ReactGAWrapper';
import { API_INFO } from 'pubsub/API_INFO';
import { MEDIA_ITEM } from 'pubsub/MEDIA_ITEM';

// Webpack imports
// Bootstrap and dependencies
import 'include/bootstrap';
// Local styles
import 'styles/beamer.css';
import 'styles/bootstrap.css';
import 'styles/emoji.css';
import 'styles/hacks.css';
import 'styles/inline-notifications.css';
import 'styles/networks.css';
import 'styles/notifications.css';
import 'styles/reset.css';
import 'styles/styles.css';
import 'styles/tooltips.css';
import 'styles/ui.css';
// Mobile styles (need to appear after the other CSS imports so it takes priority)
import 'styles/mobile.css';
// React Widgets styles
import 'styles/react-widgets.css';
// Emoji picker styles
import 'styles/emoji-mart.css';

/**
 * Global application container
 */

export default class Global extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const hideAdblockCookie = Cookies.get('ebx_hideadblocknotice');
    const hideAdblockNotice = isDefined(hideAdblockCookie)
      ? JSON.parse(hideAdblockCookie)
      : false;
    this.state = {
      hideAdblockNotice,
      statusPageNotification: '',
    };
    this._bind('handleNoticeHide', 'handlePubSubRequest');
    this.subscribers = {
      API_INFO,
      MEDIA_ITEM,
    };
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    logger.info('Global:componentDidMount');

    // Set queue interval to base value plus or minus up to five seconds
    localStorage.setItem(
      'refreshInterval',
      randomish({ value: REFRESH_INTERVALS.BASE_VALUE, variance: 5 * 1000 }),
    );

    // Handlers for pub-sub requests and commands
    logger.info(
      `PubSub: subscribe REQUEST in layouts/Global.componentDidMount`,
    );
    this.tokens = [
      // Generic request handler
      PubSub.subscribe('REQUEST', this.handlePubSubRequest),
    ];

    // Show notice if ad blockers detected
    const adblockNotice = document.getElementById('adb-wrapper');
    function adBlockDetected() {
      if (adblockNotice) {
        adblockNotice.className = adblockNotice.className.replace(/d-none/, '');
        adblockNotice.style.display = 'block';
      }
    }
    function adBlockNotDetected() {
      if (adblockNotice) {
        adblockNotice.style.display = 'none';
      }
    }
    if (typeof window.adblockDetector === 'undefined') {
      adBlockDetected();
    } else {
      window.adblockDetector.init({
        debug: false,
        found: () => {
          adBlockDetected();
        },
        notFound: () => {
          adBlockNotDetected();
        },
      });
    }

    // Get status page notifications periodically
    if (isUndefined(window.intervals)) {
      window.intervals = {};
    }
    if (isUndefined(window.intervals.statusPageNotification)) {
      logger.info(
        'Global:componentDidMount - adding status page notification interval',
      );
      window.intervals.statusPageNotification = setInterval(
        this.getStatusPageNotification.bind(this),
        REFRESH_INTERVALS.STATUS_PAGE,
      );
      this.getStatusPageNotification();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Global', { nextProps, nextState });
  }

  componentWillUnmount() {
    logger.info('Global:componentWillUnmount');

    window.removeEventListener('beforeunload', Global.handleWindowUnload);

    logger.info(
      `PubSub: unsubscribe REQUEST in layouts/Global.componentWillUnmount`,
    );
    this.tokens.forEach((token) => {
      PubSub.unsubscribe(token);
    });

    if (window.intervals?.getNotification) {
      window.clearInterval(window.intervals.statusPageNotification);
      window.intervals.statusPageNotification = null;
    }
  }

  /**
   * Helper methods
   */

  getStatusPageNotification() {
    getStatusPageIncidents()
      .then((response) => {
        // Returns an array - get the incident which was updated most recently
        const incidents = response.incidents;
        let statusPageNotification = '';
        if (incidents.length > 0) {
          const incident = incidents[0];
          const incidentUpdates = incident.incident_updates;
          const update = incidentUpdates[0];
          if (
            isDefined(update.display_at) &&
            getUnixTimestamp(new Date(update.display_at)) <= getUnixTimestamp()
          ) {
            const body = update.body;
            statusPageNotification = `${body} For more information please see <span class="text-700">
            <a href="http://status.echobox.com/" target="blank" rel="noopener noreferrer">
            status.echobox.com</a></span>.`;
          }
        }
        this.setState({
          statusPageNotification,
        });
      })
      .catch((error) => {
        if (!isRunningTests()) {
          logger.error({
            event: 'Promise Rejection Error',
            error,
          });
          console.log(error);
        }
      });
  }

  /**
   * Event handlers
   */

  handleNoticeHide() {
    Cookies.set('ebx_hideadblocknotice', true, { expires: 30 });
    this.setState({
      hideAdblockNotice: true,
    });
  }

  handlePubSubRequest(message, args) {
    const verb = message.split('.')[0];
    if (verb === 'REQUEST') {
      const topic = message.split('.')[1];
      const subtopic = message.split('.').slice(2).join('.');
      if (isDefined(this.subscribers[topic])) {
        logger.info(
          `PubSub: handle ${verb}.${topic}.${subtopic} in layouts/Global.handlePubSubRequest`,
        );
        this.subscribers[topic](subtopic, args);
      }
    }
  }

  render() {
    const statusPageContext = {
      statusPage: {
        notification: this.state.statusPageNotification,
      },
    };

    let pathname = window.location.pathname;
    if (pathname === '/') {
      pathname = authentication.isLoggedIn() ? '/share' : '/login';
    }
    if (isDefined(FRONTEND_PAGE_MAP[pathname]) && authentication.isLoggedIn()) {
      metrics.mark(FRONTEND_PAGE_MAP[pathname]);
    }

    return (
      <StatusPageContext.Provider value={statusPageContext}>
        <ReactGAWrapper>
          <Outlet />
        </ReactGAWrapper>
        {!this.state.hideAdblockNotice && (
          <div className="adb-wrapper d-none" id="adb-wrapper">
            <div
              className="adb-enabled d-flex flex-wrap flex-md-nowrap align-items-center"
              id="adb-enabled"
            >
              <span>
                It looks like you are using an <strong>ad blocker</strong>.
                Please note that this can prevent Echobox from working as
                expected. We recommend that you add{' '}
                <strong>https://social.echobox.com</strong> to your ad
                blocker&#39;s whitelist.{' '}
              </span>
              <a
                className="btn btn-light ml-md-3 mt-2 mt-md-0"
                onClick={this.handleNoticeHide}
              >
                Close
              </a>
            </div>
          </div>
        )}
      </StatusPageContext.Provider>
    );
  }
}
