import { getAPITypeId } from 'common/accountAPIs';
import {
  AB_TEST_STATUSES,
  API_TYPE_IDS,
  POST_TYPES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { hasABVariations, hasImageOverlays } from 'common/social';
import { getFileSuffix } from 'common/url';
import ABOptions from 'components/compose/ABOptions';
import FirstComment from 'components/compose/FirstComment';
import ForceShare from 'components/compose/ForceShare';
import ImageOverlayOptions from 'components/compose/ImageOverlayOptions';
import LiveStory from 'components/compose/LiveStory';
import type { FixTypeLater } from 'types';

interface OtherSettingsProps {
  accountAPIId: number;
  applySingleFieldUpdateForGuid: (args: {
    guid: string;
    fieldName: string;
    fieldValue: number;
  }) => void;
  guid: string;
  isUploading: boolean;
  mediaItem: FixTypeLater;
}

/*
 * Full edit mode - other settings
 */

const OtherSettings = ({
  accountAPIId,
  applySingleFieldUpdateForGuid,
  guid,
  isUploading,
  mediaItem,
}: OtherSettingsProps) => {
  const abTestStatusId = MediaItem.getABTestStatusId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const autopilotToggle = MediaItem.getAutopilotToggle({ mediaItem });
  const isLive = MediaItem.getIsLive({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const imageURLs = MediaItem.getImageURLs({ mediaItem });
  const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const hasImageOverlay =
    trackingDetails?.hasImageOverlay !== undefined &&
    trackingDetails.hasImageOverlay;
  const fileType = imageURLs.length > 0 ? getFileSuffix(imageURLs[0]) : '';
  const isImageOverlayValidFileType = fileType !== 'gif';

  const showLiveStory = postType === POST_TYPES.LINK;
  const showForceShare = autopilotToggle;
  const showABOptions =
    hasABVariations({ apiTypeId, socialChannel }) ||
    abTestStatusId === AB_TEST_STATUSES.COMPLETED ||
    abTestStatusId === AB_TEST_STATUSES.ERROR;
  const showImageOverlay =
    hasImageOverlays({ apiTypeId }) &&
    postType === POST_TYPES.LINK &&
    isImageOverlayValidFileType;

  const showFirstComment =
    postType !== POST_TYPES.VIDEO ||
    apiTypeId === API_TYPE_IDS.FACEBOOK ||
    apiTypeId === API_TYPE_IDS.TWITTER;

  if (
    !showLiveStory &&
    !showForceShare &&
    !showABOptions &&
    !showImageOverlay &&
    !showFirstComment
  ) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <div className="other_settings text_unselectable w-100 mt-3">
      <div className="subtitle">Other Settings</div>
      <div className="other_setting text_unselectable checkbox mt-1">
        {showLiveStory && <LiveStory guid={guid} isLive={isLive} />}
        {showForceShare && (
          <ForceShare
            applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
            guid={guid}
            mediaItem={mediaItem}
          />
        )}
        {showABOptions && (
          <ABOptions
            abTestStatusId={abTestStatusId}
            guid={guid}
            isUploading={isUploading}
            mediaItem={mediaItem}
          />
        )}
        {showImageOverlay && (
          <ImageOverlayOptions
            guid={guid}
            hasImageOverlay={hasImageOverlay}
            mediaItem={mediaItem}
          />
        )}
        {showFirstComment && (
          <FirstComment
            key={postType}
            comment={MediaItem.getFirstComment({ mediaItem })}
            guid={guid}
            mediaItem={mediaItem}
          />
        )}
      </div>
    </div>
  );
};

export default OtherSettings;
