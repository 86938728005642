/* eslint jsx-a11y/no-autofocus:"off" */
import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';

import { getPageInfo, getSearchMentions } from 'api/api';
import {
  API_TYPE_IDS,
  REACT_PREVENT_RENDER,
  TAG_SEARCH_MODES,
  TAG_SEARCH_TYPES,
  TAG_TYPES,
} from 'common/constants';
import { createTagDetails } from 'common/social';
import type {
  APITypeId,
  Mention,
  SponsorPageInfo,
  Tag,
  TagSearchMode,
  TagSearchType,
  TagType,
} from 'types';

import { isRunningTests } from 'common/utility';
import { useMessageBoxContext } from 'context/MessageBoxContext';
import SponsorSearchResult from './SponsorSearchResult';
import TagSearchResult from './TagSearchResult';

function assertsSponsor(
  type: TagSearchType,
): asserts type is typeof TAG_SEARCH_TYPES.SPONSOR {
  if (type !== TAG_SEARCH_TYPES.SPONSOR) {
    throw new Error('Invalid type');
  }
}

function assertsTag(
  type: TagSearchType,
): asserts type is typeof TAG_SEARCH_TYPES.TAG {
  if (type !== TAG_SEARCH_TYPES.TAG) {
    throw new Error('Invalid type');
  }
}

function assertMentions(
  results: (Mention | SponsorPageInfo)[],
): asserts results is Mention[] {
  if (results.some((result) => 'pageURL' in result)) {
    throw new Error('Invalid type');
  }
}

interface SharedProps {
  mode: TagSearchMode;
  input?: string;
}

interface SponsorSearchPanelProps extends SharedProps {
  type: typeof TAG_SEARCH_TYPES.SPONSOR;
  onInsert: (clean: string, raw: string) => void;
  apiTypeId?: APITypeId;
}

interface TagSearchPanelProps extends SharedProps {
  type: typeof TAG_SEARCH_TYPES.TAG;
  onInsert: (args: { tag: Tag; tagType: TagType; fromSearch: boolean }) => void;
  apiTypeId: APITypeId;
}

type SponsorTagSearchPanelProps = SponsorSearchPanelProps | TagSearchPanelProps;

/**
 * A shared selector for retrieving tags and mentions.
 *
 * NOTE: It would be good to split this update into two components, one for tags
 * and one for mentions as there is a lot of branching logic in this component (and associated type assertions).
 */
const SponsorTagSearchPanel = (props: SponsorTagSearchPanelProps) => {
  const { mode, type, input = '', onInsert } = props;
  const { didPaste } = useMessageBoxContext();

  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<(Mention | SponsorPageInfo)[]>([]);

  // This function is an artificial delay such that the spinner does not show for a fraction of a second if data is retrieved from a backend cache.
  const findEntity = useCallback(
    async (value: string) => {
      const delaySetIsSearching = isRunningTests()
        ? () => setIsSearching(true)
        : debounce(500, () => setIsSearching(true));

      delaySetIsSearching();

      try {
        if (type === TAG_SEARCH_TYPES.SPONSOR) {
          const response = await getPageInfo({ pageId: value });
          setResults([response]);
        } else {
          const sanitiseValue = value.replaceAll(/\s+|@|\?|\n|/g, '').trim();
          const response = await getSearchMentions({
            apiTypeId: props.apiTypeId,
            keyword: sanitiseValue,
          });
          setResults(response);

          if (didPaste) {
            for (const res of response) {
              if (
                input.toLowerCase() === res.username?.toLowerCase() &&
                (res?.pageId || res?.username) &&
                res.name
              ) {
                const tag = createTagDetails({
                  raw: res.pageId ? res.pageId : res.username,
                  clean: res.name,
                  username: res.username,
                  apiTypeId: props.apiTypeId,
                  results: response,
                });
                if (tag) {
                  onInsert({
                    tag,
                    tagType: TAG_TYPES.MENTION,
                    fromSearch: true,
                  });
                }
                break;
              }
            }
          }
        }
      } catch {
        // Swallow error here - errors should be logged by the internal methods.
      } finally {
        setIsSearching(false);
      }
    },

    [didPaste, input, onInsert, props.apiTypeId, type],
  );

  useEffect(() => {
    if (input) {
      findEntity(input);
    }
  }, [findEntity, input]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const search = event.target.value;
    setResults([]);
    findEntity(search);
  };

  const handleSponsorSave = ({
    clean,
    raw,
  }: {
    clean: string;
    raw: string;
  }) => {
    assertsSponsor(type);
    setIsSearching(false);
    onInsert(clean, raw);
  };

  const handleTagSave = ({
    clean,
    raw,
    username,
  }: {
    clean: string | null;
    raw: string | undefined;
    username: string | null;
  }) => {
    setIsSearching(false);
    assertsTag(type);
    assertMentions(results);
    const tag = createTagDetails({
      raw: raw !== undefined ? raw : '',
      clean: clean ?? '',
      username: username ?? '',
      apiTypeId: props.apiTypeId,
      results,
    });
    if (tag) {
      onInsert({
        tag,
        tagType: TAG_TYPES.MENTION,
        fromSearch: true,
      });
    }
  };

  const className = mode === TAG_SEARCH_MODES.INLINE ? 'inline' : 'popup';
  const panelTitle =
    type === TAG_SEARCH_TYPES.SPONSOR ? 'Sponsor' : 'Tag a page';

  // 'inline' mode displays the search bar as an integral part of the full edit preview panel
  // and is used when clicking the 'add tag' icon
  if (mode === TAG_SEARCH_MODES.INLINE) {
    const placeholder =
      props.apiTypeId === API_TYPE_IDS.FACEBOOK
        ? 'Enter name or complete Facebook URL'
        : 'Enter name';
    return (
      <>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text ft-13">{panelTitle}</span>
          </div>
          <input
            type="text"
            aria-label={placeholder}
            autoComplete="off"
            autoCorrect="off"
            autoFocus
            className="form-control"
            data-cy-input="mentionBox"
            onChange={handleChange}
            placeholder={placeholder}
          />
        </div>
        <div className="position-relative">
          <div
            data-cy="tag-results"
            className={`tag_search_popup ${className}`}
          >
            {results.length > 0 &&
              results.map((result) =>
                type === TAG_SEARCH_TYPES.SPONSOR ? (
                  <SponsorSearchResult
                    key={result.pageId}
                    result={result as SponsorPageInfo}
                    onSave={handleSponsorSave}
                  />
                ) : (
                  <TagSearchResult
                    key={result.pageId}
                    result={result as Mention}
                    onSave={handleTagSave}
                  />
                ),
              )}
          </div>
        </div>
      </>
    );
  }

  // 'popup' mode displays the search bar floating on top of the preview panel
  // and is used when adding a tag in a message after typing an '@' symbol
  if (results.length > 0) {
    return (
      <div data-cy="tag-results" className="tag_search_popup">
        {results.length > 0 &&
          results.map((result) =>
            type === TAG_SEARCH_TYPES.SPONSOR ? (
              <SponsorSearchResult
                key={result.pageId}
                result={result as SponsorPageInfo}
                onSave={handleSponsorSave}
                isPopup
              />
            ) : (
              <TagSearchResult
                key={result.pageId}
                result={result as Mention}
                onSave={handleTagSave}
                isPopup
              />
            ),
          )}
      </div>
    );
  }

  if (isSearching) {
    return (
      <div className="tag_search_popup py-3 text-center">
        <Spinner />
      </div>
    );
  }

  return REACT_PREVENT_RENDER;
};

export default SponsorTagSearchPanel;
