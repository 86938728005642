import Tooltip from 'components/misc/Tooltip';
import { SponsorPageInfo } from 'types';

interface SponsorSearchResultProps {
  result: SponsorPageInfo;
  isPopup?: boolean;
  onSave: (args: { raw: string; clean: string }) => void;
}

const SponsorSearchResult = ({
  result,
  isPopup,
  onSave,
}: SponsorSearchResultProps) => (
  <div data-cy-id={result.pageId} key={`tag-search-result-${result.pageId}`}>
    <a
      className={isPopup ? 'tag_result_popup' : 'tag_result'}
      onClick={() =>
        onSave({
          raw: result.pageId,
          clean: result.name,
        })
      }
      key={`tag-search-result-link-${result.pageId}`}
    >
      <div
        className="tag_result_box"
        style={{
          backgroundImage: `url('${result.coverPhoto}'`,
        }}
      />
      <div className="d-flex align-items-center">
        {result.isVerified && (
          <Tooltip id="verified" label="This is a verified page">
            <img
              src="/img/icons/ic-check-fb.svg"
              data-cy="verified-check"
              className="verified_account ml-0 mr-1 p-0"
              alt=""
              data-tip
              data-for="verified"
              data-iscapture="true"
              style={{ width: '12px' }}
            />
          </Tooltip>
        )}
        <div data-cy="sponsor-title" className="tag_result_title text-truncate">
          {result.name}
        </div>
      </div>
      <div className="tag_result_likes text-truncate">
        {result.likes.toLocaleString()} likes
      </div>
    </a>
  </div>
);

export default SponsorSearchResult;
