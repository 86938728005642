import { Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import AddInstagramIntro from './AddInstagramIntro';
import AddInstagramLogin from './AddInstagramLogin';

enum Step {
  INTRO,
  LOGIN,
}

interface AddInstagramModalProps {
  onClose: () => void;
}

const AddInstagramModal = ({ onClose }: AddInstagramModalProps) => {
  const [currentStep, setCurrentStep] = useState(Step.INTRO);

  return (
    <Modal isOpen onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Add Instagram page</Modal.Title>
      </Modal.Header>
      {currentStep === Step.INTRO && (
        <AddInstagramIntro
          onClose={onClose}
          onNextStep={() => setCurrentStep(Step.LOGIN)}
        />
      )}
      {currentStep === Step.LOGIN && (
        <AddInstagramLogin
          onBack={() => setCurrentStep(Step.INTRO)}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export default AddInstagramModal;
