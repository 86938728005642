import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

import deleteAccountAPI from 'api/deleteAccountAPI';
import getAPIsConnectRequestUpdate from 'api/getAPIsConnectRequestUpdate';
import getPropertiesConnectRequestNew from 'api/getPropertiesConnectRequestNew';
import { constructBaseSocialAPIURL } from 'api/settings';
import {
  getBitlyAPIs,
  getCurrentProperty,
  getCurrentPropertyId,
  getFacebookAPIs,
  getTwitterAPIs,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_PROPERTIES,
  API_STATES,
  API_TYPE_IDS,
  GLOBAL_INFO_STATES,
} from 'common/constants';
import { isPropertyNewSignup } from 'common/currentPropertyAndAPI';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import { getGlobalInfo } from 'common/globalInfo';
import { redirectBrowser } from 'common/location';
import * as logger from 'common/logger';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { getSetting, savePropertiesSettings } from 'common/settings';
import { convertToPropertyURN, convertToShortenAPIURN } from 'common/urn';
import { isNull } from 'common/utility';
import { location } from 'common/window';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/misc/Button';
import SaveChangesButtons from 'components/settings/SaveChangesButtons';
import withGlobalInfo from 'context/withGlobalInfo';
import { setPanelButtonText } from 'helpers/settingsPage';

class LinkShortening extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable({
        isConnected: LinkShortening.isBitlyConnected(),
        bitlyAPI: LinkShortening.getBitlyAPI(),
        propertyIsNewSignUp: isPropertyNewSignup({
          globalInfo: getGlobalInfo(),
        }),
        isUnshorteningEnabled: {
          facebook: getSetting({
            settingTypeId: ACCOUNT_SETTING_TYPES.FACEBOOK_UNSHORTENED_SHARING,
            propertyId: getCurrentPropertyId(),
          }).enabled,
          twitter: getSetting({
            settingTypeId: ACCOUNT_SETTING_TYPES.TWITTER_UNSHORTENED_SHARING,
            propertyId: getCurrentPropertyId(),
          }).enabled,
        },
        isSaving: false,
        isChanged: false,
      }),
    };
    this._bind(
      'handleBitlyConnect',
      'handleBitlyReconnect',
      'handleCancel',
      'handleRemoveBitly',
      'handleChangedShortened',
      'saveSocialLinkShortening',
      'handleSave',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    setPanelButtonText('collapseBitly');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('LinkShortening', { nextProps, nextState });
  }

  /**
   * Event handlers
   */

  handleBitlyConnect() {
    const globalInfo = getGlobalInfo();
    this.setState(
      getPropertiesConnectRequestNew({
        propertyURN: convertToPropertyURN(
          getCurrentPropertyId({
            globalInfo,
          }),
        ),
        socialAPIType: API_PROPERTIES[API_TYPE_IDS.BITLY]?.apiType,
        uiCallbackURL: `${location.href}`,
        apiCallbackURL: `${constructBaseSocialAPIURL()}`,
      })
        .then((response) => redirectBrowser(response.location))
        .catch((error) => {
          const errorMessage = getErrorMessage(determineError(error));
          if (isNull(getErrorStatus(error))) {
            logger.error({
              event: 'Handle Bitly Connect Error',
              properties: {
                location: 'components/settings/property/LinkShortening',
              },
              error,
            });
          }
          addErrorNotification(`Error connecting Bitly: ${errorMessage}`);
        }),
    );
  }

  handleBitlyReconnect() {
    const bitlyAPIId = LinkShortening.getBitlyAPI().accountAPIId;
    const shortenAPIURN = convertToShortenAPIURN(
      API_PROPERTIES[API_TYPE_IDS.BITLY].urnName,
      bitlyAPIId,
    );

    getAPIsConnectRequestUpdate({
      socialPageURN: shortenAPIURN,
      timezone: this.state.data.bitlyAPI.timezone,
      uiCallbackURL: `${location.href}`,
      apiCallbackURL: `${constructBaseSocialAPIURL()}`,
    })
      .then((response) => redirectBrowser(response.location))
      .catch((error) => {
        const errorMessage = getErrorMessage(determineError(error));
        if (isNull(getErrorStatus(error))) {
          logger.error({
            event: 'Handle Bitly Reconnect Error',
            properties: {
              location: 'components/settings/property/LinkShortening',
            },
            error,
          });
        }
        addErrorNotification(`Error connecting Bitly: ${errorMessage}`);
      });
  }

  handleCancel() {
    logger.info('LinkShortening:handleCancel');
    const fbEnabled = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.FACEBOOK_UNSHORTENED_SHARING,
      propertyId: getCurrentPropertyId(),
    }).enabled;
    const twtEnabled = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.TWITTER_UNSHORTENED_SHARING,
      propertyId: getCurrentPropertyId(),
    }).enabled;
    const isShortenedEnabled = {
      facebook: fbEnabled,
      twitter: twtEnabled,
    };
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set('isUnshorteningEnabled', Immutable(isShortenedEnabled))
          .set('isSaving', false)
          .set('isChanged', false),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'linkShortening',
          hasSettingChanged: false,
        }),
    );
  }

  handleChangedShortened(event) {
    const isUnshorteningEnabled = Immutable.asMutable(
      this.state.data.isUnshorteningEnabled,
    );
    const network = event.target.id;
    const enabled = event.target.value === 'true';
    logger.info(`LinkShortening:handleChangeShortened - ${network} ${enabled}`);
    isUnshorteningEnabled[network] = enabled;
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set('isUnshorteningEnabled', Immutable(isUnshorteningEnabled))
          .set('isChanged', true),
      }),
      () => {
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'linkShortening',
          hasSettingChanged: true,
        });
      },
    );
  }

  handleRemoveBitly() {
    const bitlyAPIId = LinkShortening.getBitlyAPI().accountAPIId;
    const shortenAPIURN = convertToShortenAPIURN(
      API_PROPERTIES[API_TYPE_IDS.BITLY].urnName,
      bitlyAPIId,
    );
    deleteAccountAPI({ socialAPIURN: shortenAPIURN })
      .then(() => {
        this.setState((prevState) => ({
          data: prevState.data.set('isConnected', false).set('bitlyAPI', null),
        }));
        this.props.global.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.REFRESHING,
        });
      })
      .catch((error) => {
        if (isNull(getErrorStatus(error))) {
          logger.error({
            event: 'Handle Remove Bitly Error',
            properties: {
              location: 'components/settings/SettingsBitly',
            },
            error,
          });
        }
      });
  }

  handleSave() {
    logger.info('linkShortening:saveChanges');

    this.setState(
      (prevState) => ({
        data: prevState.data.set('isChanged', false).set('isSaving', true),
      }),
      () => {
        this.saveSocialLinkShortening();
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'linkShortening',
          hasSettingChanged: false,
        });
      },
    );
  }

  /**
   * Helper methods
   */

  static getBitlyAPI() {
    const globalInfo = getGlobalInfo();
    const apis = getCurrentProperty({
      globalInfo,
    }).accountAPIs;
    const bitlyAPIs = getBitlyAPIs({ accountAPIs: apis });
    if (bitlyAPIs.length === 1) {
      return bitlyAPIs[0];
    }

    return null;
    /*
    if (bitlyAPIs.length > 1) {
      throw new Error('Account cannot be linked to multiple Bitly accounts');
    } else {
      throw new Error('Account not linked to any Bitly account');
    }
    */
  }

  static isBitlyConnected() {
    const api = LinkShortening.getBitlyAPI();
    return !isNull(api) && api.apiStateId === API_STATES.ACTIVE;
  }

  saveSocialLinkShortening() {
    const fbSettingSaved = false;
    const twSettingSaved = false;
    const isUnshorteningEnabled = Immutable.asMutable(
      this.state.data.isUnshorteningEnabled,
    );
    const globalInfo = getGlobalInfo();
    const propertyId = getCurrentPropertyId({
      globalInfo,
    });
    const fbSettingConfig = {
      propertyId,
      settingTypeId: ACCOUNT_SETTING_TYPES.FACEBOOK_UNSHORTENED_SHARING,
      enabled: isUnshorteningEnabled.facebook,
    };
    const twitterSettingConfig = {
      propertyId,
      settingTypeId: ACCOUNT_SETTING_TYPES.TWITTER_UNSHORTENED_SHARING,
      enabled: isUnshorteningEnabled.twitter,
    };
    savePropertiesSettings.call(this, {
      propertyId,
      settingConfig: fbSettingConfig,
      callback: () => {
        this.setState((prevState) => ({
          data: prevState.data.set(
            'isSaving',
            fbSettingSaved && twSettingSaved,
          ),
        }));
        addSuccessNotification('Setting saved');
      },
      errorHandler: () => {
        this.settingErrorHandler();
      },
    });
    savePropertiesSettings.call(this, {
      propertyId,
      settingConfig: twitterSettingConfig,
      callback: () => {
        this.setState((prevState) => ({
          data: prevState.data.set(
            'isSaving',
            fbSettingSaved && twSettingSaved,
          ),
        }));
      },
      errorHandler: () => {
        this.settingErrorHandler();
      },
    });
  }

  settingErrorHandler() {
    this.setState((prevState) => ({
      data: prevState.data.set('isChanged', true).set('isSaving', false),
    }));
    addErrorNotification('Error saving setting');
  }

  /**
   * Render method
   */

  renderIsConnected() {
    let button = null;
    if (this.state.data.isConnected) {
      return (
        <span>
          Echobox is connected to Bitly.
          {this.state.data.propertyIsNewSignUp && (
            <span>
              &nbsp;&nbsp;
              <button
                className="remove"
                onClick={this.handleRemoveBitly}
                type="button"
              >
                <span className="ft-11 close gray-500">✖</span>
              </button>
            </span>
          )}
        </span>
      );
    }
    let message = null;
    if (isNull(this.state.data.bitlyAPI)) {
      message =
        'Connect your Bitly account to enable link shortening in Echobox.';
      button = (
        <Button
          className="ml-2 align-self-center mr-2"
          data-cy-action="connectBitly"
          onClick={this.handleBitlyConnect}
        >
          Connect
        </Button>
      );
    } else {
      message = 'Echobox is not connected. Reconnect your Bitly account.';
      button = (
        <Button
          className="btn-reconnect align-self-center mr-2"
          variant="danger"
          data-cy-action="reconnectBitly"
          onClick={this.handleBitlyReconnect}
        >
          Reconnect
        </Button>
      );
    }
    return (
      <div>
        <p>
          {message} {button}
        </p>
      </div>
    );
  }

  render() {
    const globalInfo = getGlobalInfo();
    const accountAPIs = getCurrentProperty({
      globalInfo,
    }).accountAPIs;
    return (
      <>
        <div className="setting-part border-top d-flex flex-row">
          <div className="mr-auto">
            <div className="ft-13 text-500">Link Shortening</div>
          </div>
          {this.state.data.isChanged && (
            <span className="align-self-center mr-2 op-60">
              Unsaved Changes
            </span>
          )}
          <div
            className="btn btn-light align-self-center"
            data-toggle="collapse"
            data-target="#collapseBitly"
            data-cy-action="editLinkShortening"
            aria-expanded="true"
            aria-controls="collapseBitly"
          >
            Edit
          </div>
        </div>
        <div
          id="collapseBitly"
          className="settings-sub collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionPropertySettings"
        >
          <div className="pt-4 border-top d-flex flex-column mb-2">
            {this.renderIsConnected()}
            {this.state.data.isConnected &&
              getFacebookAPIs({ accountAPIs }).length > 0 && (
                <div className="d-flex align-items-center mt-3">
                  <span>
                    Shorten links for <b>Facebook</b> posts
                  </span>
                  <select
                    className="btn btn-light dropdown-md ml-2"
                    name=""
                    onChange={this.handleChangedShortened}
                    value={this.state.data.isUnshorteningEnabled.facebook}
                    id="facebook"
                    data-cy-select="facebookShortening"
                  >
                    <option value="false">On</option>
                    <option value="true">Off</option>
                  </select>
                </div>
              )}
            {this.state.data.isConnected &&
              getTwitterAPIs({ accountAPIs }).length > 0 && (
                <div className="d-flex align-items-center mt-2">
                  <span>
                    Shorten links for <b>Twitter</b> posts
                  </span>
                  <select
                    className="btn btn-light dropdown-md ml-2"
                    name=""
                    onChange={this.handleChangedShortened}
                    value={this.state.data.isUnshorteningEnabled.twitter}
                    id="twitter"
                    data-cy-select="twitterShortening"
                  >
                    <option value="false">On</option>
                    <option value="true">Off</option>
                  </select>
                </div>
              )}
          </div>
          {this.state.data.isConnected && (
            <div className="pt-2">
              <SaveChangesButtons
                disabled={
                  !this.state.data.isChanged || this.state.data.isSaving
                }
                eventHandlers={{
                  handleSave: this.handleSave,
                  handleCancel: this.handleCancel,
                }}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

LinkShortening.propTypes = {
  eventHandlers: PropTypes.shape({
    handleHasSettingChanged: PropTypes.func,
  }),
};

LinkShortening.defaultProps = {
  eventHandlers: {
    handleHasSettingChanged: () => {},
  },
};

export default withGlobalInfo(LinkShortening);
