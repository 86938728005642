import { Button, ButtonGroup } from '@ebx-ui/ebx-ui-component-library-sdk';

import putProperties from 'api/putProperties';
import { getCurrentPropertyId } from 'common/accountAPIs';
import {
  GLOBAL_INFO_STATES,
  PROPERTY_STATES,
  TRACKER_KEY,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import { getFeatureToggle } from 'common/settings';
import * as tracker from 'common/tracker';
import { isEmptyOrNullOrUndefined, isRunningTests } from 'common/utility';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { useOnboardingSetupContext } from 'context/OnboardingContext';

type HeaderButtonProps = {
  showSignOutModal: () => void;
};

const HeaderButtons = ({ showSignOutModal }: HeaderButtonProps) => {
  const {
    currentStep,
    haveChangesBeenMade,
    nextButtonDisabled,
    onSaveRef,
    onStepChange,
    setIsWebsiteTagOpen,
  } = useOnboardingSetupContext();
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const propertyId = getCurrentPropertyId({
    globalInfo,
  });

  // Needs to explicitly check for false - if it is undefined or null we shouldn't show the skip button
  const requiresGA =
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.REQUIRES_GA,
      propertyId,
    }) !== false;
  const requiresTracker =
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.REQUIRES_EBX_TRACKER,
      propertyId,
    }) !== false;

  const skipStep = 2;
  const nextSteps = [1, 2, 3];
  const previousSteps = [2, 3, 4, 5];
  let submitSteps = [4];
  let signOutSteps = [5];
  let lastStep = 5;

  if (requiresTracker) {
    nextSteps.push(4);
    submitSteps = [5];
    signOutSteps = [6];
    lastStep = 6;
  }

  const showNextButton = nextSteps.includes(currentStep);

  const showSkip =
    skipStep === currentStep && !requiresGA && !haveChangesBeenMade;
  const showSave =
    !showSkip && (haveChangesBeenMade || previousSteps.includes(currentStep));

  const handleNext = async () => {
    const isTrackerInstalled =
      sessionStorage.getItem(`${TRACKER_KEY}-${propertyId}`) === 'true';
    try {
      if (currentStep === 4 && !isTrackerInstalled && requiresTracker) {
        setIsWebsiteTagOpen(true);
        return;
      }
      if (onSaveRef.current) {
        await onSaveRef.current();
      }
      const nextStep = currentStep + 1;
      onStepChange(nextStep);
    } catch (error) {
      const newErrorMessage = getErrorMessage(error);
      addErrorNotification(newErrorMessage);
    }
  };

  const handlePrev = () => {
    const prevStep = currentStep - 1;
    onStepChange(prevStep);
  };

  const handleSubmit = async () => {
    if (isEmptyOrNullOrUndefined(globalInfo)) {
      return;
    }

    try {
      await putProperties({
        propertyId,
        propertyState: PROPERTY_STATES.UNDERSETUP,
      });
      tracker.track({
        eventName: 'Submit Property Setup',
      });
      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.REFRESHING,
        allowUnderSetup: true,
        callback: () => {
          onStepChange(lastStep);
        },
      });
    } catch (error) {
      logger.error({
        event: 'Promise Rejection Error',
        error,
      });
      if (!isRunningTests()) {
        console.log(error);
      }
    }
  };

  const handleSignOut = () => {
    window.location.href = '/logout';
  };

  return (
    <ButtonGroup spacing={2} ml="auto">
      {showSkip && (
        <Button onClick={handleNext} variant="tertiary">
          Skip this step
        </Button>
      )}
      {showSave && (
        <Button onClick={showSignOutModal} variant="tertiary">
          Save and exit
        </Button>
      )}
      {previousSteps.includes(currentStep) && (
        <Button onClick={handlePrev} variant="secondary">
          Previous
        </Button>
      )}
      {showNextButton && (
        <Button isDisabled={nextButtonDisabled} onClick={handleNext}>
          Next
        </Button>
      )}
      {submitSteps.includes(currentStep) && (
        <Button onClick={handleSubmit}>Submit</Button>
      )}
      {signOutSteps.includes(currentStep) && (
        <Button onClick={handleSignOut} variant="secondary">
          Sign out
        </Button>
      )}
    </ButtonGroup>
  );
};

export default HeaderButtons;
