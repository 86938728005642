import PropTypes from 'prop-types';

import { TEXT_CASES } from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import { trackOpenArticle } from 'helpers/articleTracking';

const ItemPublished = (props) => {
  const { articleURL, publishedTime, twelveHourFormat, isNewVersion } = props;
  const label = isNewVersion ? 'Republished' : 'Published';

  return (
    <div className="float-left">
      <a
        href={articleURL}
        target="_blank"
        rel="noopener noreferrer"
        className="article_link link_grey"
        onClick={() => trackOpenArticle(articleURL, 'Home feed')}
      >
        {label}{' '}
        {getFormattedDateFromUnix({
          timestamp: publishedTime,
          twelveHourFormat,
          textCase: TEXT_CASES.LOWER,
        })}
        <img className="link_icon" src="/img/icons/ic-open-in-new.svg" alt="" />
      </a>
    </div>
  );
};

ItemPublished.propTypes = {
  articleURL: PropTypes.string.isRequired,
  publishedTime: PropTypes.number.isRequired,
  twelveHourFormat: PropTypes.bool.isRequired,
  isNewVersion: PropTypes.bool.isRequired,
};

export default ItemPublished;
