import { Box, Heading } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getCurrentProperty } from 'common/accountAPIs';
import UserManagement from 'components/settings/team/UserManagement';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import usePropertyUsers from 'hooks/usePropertyUsers';

const Team = () => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const { propertyUsers, onUsersChange } = usePropertyUsers(
    getCurrentProperty({
      globalInfo,
    }).propertyUsers,
  );

  return (
    <Box>
      <Heading pb={6}>Team</Heading>
      <UserManagement
        users={propertyUsers}
        onUsersChange={onUsersChange}
        showRoleSettings={true}
      />
    </Box>
  );
};

export default Team;
