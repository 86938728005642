import {
  ArrowRightIcon,
  Banner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useErrorTracking } from './useErrorTracking';

interface NoTrackerWithNoGABannerProps {
  onClose: () => void;
}

const NoTrackerWithNoGABanner = ({ onClose }: NoTrackerWithNoGABannerProps) => {
  const errorMessage =
    "Enhance Echobox's performance with your website traffic.";

  useErrorTracking({ errorMessage, errorType: 'No tracker connected' });

  return (
    <Banner type="error" onClose={onClose}>
      <Banner.Tag>Important</Banner.Tag>
      <Banner.Body>
        <Text size="sm">{errorMessage}</Text>
      </Banner.Body>
      <Banner.Link
        as={ReactRouterLink}
        // @ts-expect-error - Chakra UI's `as` prop doesn't do well with TS
        to="/settings/property"
        icon={<ArrowRightIcon />}
      >
        Install Web Tag
      </Banner.Link>
    </Banner>
  );
};

export default NoTrackerWithNoGABanner;
