import { Flex, Modal, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useReducer } from 'react';

import getPropertySearchByName from 'api/getPropertySeachByName';
import { extractPropertyId } from 'common/urn';
import { getPropertyById } from 'helpers/getPropertyById';
import type { GetPropertiesResponseData, Property, SocialAPI } from 'types';

import { getActiveAPIsForProperty } from 'common/accountAPIs';
import { ErrorWithMessageSchema } from 'common/schemas';
import { location } from 'common/window';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import { impersonateProperty } from 'process/impersonate';
import PropertyDetails from './PropertyDetails';
import PropertySearch from './PropertySearch';
import PropertySearchOptions from './PropertySearchOptions';
import UpdatePropertySettings from './UpdatePropertySettings';

export type { PropertyDetailsState };

type PropertyDetailsState =
  | { state: 'SEARCH' }
  | {
      state: 'PROPERTY_DETAILS';
      propertyData: Property;
      socialPages: Array<SocialAPI>;
    }
  | {
      state: 'PROPERTY_SEARCH';
      properties: Array<GetPropertiesResponseData>;
      warning?: string;
    }
  | {
      state: 'PROPERTY_SETTINGS';
      propertyData: Property;
      socialPages: Array<SocialAPI>;
    };

type ActionType =
  | {
      type: 'GET_PROPERTY_DETAILS_SUCCESS';
      payload: {
        propertyData: Property;
        socialPages: Array<SocialAPI>;
      };
    }
  | { type: 'GO_BACK' }
  | { type: 'GO_TO_SETTINGS' }
  | {
      type: 'SHUTDOWN_PROPERTY_SUCCESS';
      payload: {
        property: Property;
      };
    }
  | {
      type: 'SEARCH_PROPERTY_NAME_SUCCESS';
      payload: {
        properties: Array<GetPropertiesResponseData>;
        warning?: string;
      };
    }
  | {
      type: 'PROPERTY_SELECTED';
      payload: {
        propertyData: Property;
        socialPages: Array<SocialAPI>;
      };
    }
  | {
      type: 'REACTIVATE_PROPERTY_SUCCESS';
      payload: {
        property: Property;
      };
    };

function reducer(
  state: PropertyDetailsState,
  action: ActionType,
): PropertyDetailsState {
  switch (action.type) {
    case 'GET_PROPERTY_DETAILS_SUCCESS':
      return {
        state: 'PROPERTY_DETAILS',
        propertyData: action.payload.propertyData,
        socialPages: action.payload.socialPages,
      };

    case 'GO_BACK': {
      if (state.state === 'PROPERTY_SEARCH') {
        return {
          state: 'SEARCH',
        };
      }

      if (state.state === 'PROPERTY_DETAILS') {
        return {
          state: 'SEARCH',
        };
      }

      if (state.state === 'PROPERTY_SETTINGS') {
        return {
          state: 'PROPERTY_DETAILS',
          propertyData: state.propertyData,
          socialPages: state.socialPages,
        };
      }

      return state;
    }

    case 'GO_TO_SETTINGS': {
      if (state.state === 'PROPERTY_DETAILS') {
        return {
          state: 'PROPERTY_SETTINGS',
          propertyData: state.propertyData,
          socialPages: state.socialPages,
        };
      }

      return state;
    }

    case 'PROPERTY_SELECTED':
      return {
        ...state,
        state: 'PROPERTY_DETAILS',
        propertyData: action.payload.propertyData,
        socialPages: action.payload.socialPages,
      };

    case 'SEARCH_PROPERTY_NAME_SUCCESS': {
      return {
        ...state,
        state: 'PROPERTY_SEARCH',
        properties: action.payload.properties,
        warning: action.payload.warning,
      };
    }

    case 'SHUTDOWN_PROPERTY_SUCCESS': {
      if (state.state !== 'PROPERTY_DETAILS') {
        return state;
      }

      return {
        ...state,
        propertyData: action.payload.property,
      };
    }

    case 'REACTIVATE_PROPERTY_SUCCESS': {
      if (state.state !== 'PROPERTY_DETAILS') {
        return state;
      }

      return {
        ...state,
        propertyData: action.payload.property,
      };
    }

    default:
      throw new Error(`Invalid action type: ${(action as any).type}`);
  }
}

interface PropertyToolsModalInterface {
  onClose: () => void;
  handleAccountAPIToolsClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    propertyId: string,
  ) => void;
  initialState: PropertyDetailsState;
}

const PropertyToolsModal = ({
  onClose,
  handleAccountAPIToolsClick,
  initialState = { state: 'SEARCH' },
}: PropertyToolsModalInterface) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const flashMessagesContext = useFlashMessagesContext();
  const toast = useToast();

  const handleSearchByName = async (nameToSearch: string) => {
    const { properties, warning } = await getPropertySearchByName({
      propertyName: nameToSearch,
    });

    dispatch({
      type: 'SEARCH_PROPERTY_NAME_SUCCESS',
      payload: {
        properties,
        warning,
      },
    });
  };

  const handleSearchById = async (propertyId: string) => {
    await getPropertyById({
      propertyId,
      onSuccess: ({ property, socialPages }) => {
        dispatch({
          type: 'GET_PROPERTY_DETAILS_SUCCESS',
          payload: {
            propertyData: property,
            socialPages,
          },
        });
      },
    });
  };

  const handleShutdownConfirm = (property: Property) => {
    dispatch({ type: 'SHUTDOWN_PROPERTY_SUCCESS', payload: { property } });
  };

  const handleReactivateSubmit = (property: Property) => {
    dispatch({ type: 'REACTIVATE_PROPERTY_SUCCESS', payload: { property } });
  };

  const handlePropertySearchSelect = async (propertyURN: string) => {
    const propertyId = extractPropertyId(propertyURN);

    await getPropertyById({
      propertyId: propertyId.toString(),
      onSuccess: ({ property, socialPages }) => {
        dispatch({
          type: 'PROPERTY_SELECTED',
          payload: {
            propertyData: property,
            socialPages,
          },
        });
      },
    });
  };

  const handleImpersonateProperty = async (propertyId: string) => {
    try {
      await impersonateProperty({
        propertyId,
      });
      flashMessagesContext.flashMessages.deleteMessages();
      if (window) {
        const apisForProperty = getActiveAPIsForProperty({ propertyId });
        if (apisForProperty.length > 0) {
          const { accountAPIId } = apisForProperty[0];
          location.href = `/share/${accountAPIId}`;
        } else {
          location.href = '/share?impersonate+error=no+active+pages';
        }
      }
    } catch (error) {
      const parsedError = ErrorWithMessageSchema.safeParse(error);
      const errorMessage = parsedError.success
        ? parsedError.data.error.message
        : 'Unknown error';
      toast({
        title: 'Unable to impersonate Property',
        description: errorMessage,
        variant: 'error',
      });
    }
  };

  const handleBackClick = () => {
    dispatch({ type: 'GO_BACK' });
  };

  const handleUpdateSettingsClick = () => {
    dispatch({ type: 'GO_TO_SETTINGS' });
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      size={state.state === 'PROPERTY_DETAILS' ? 'medium' : 'small'}
    >
      <Modal.Header>
        <Modal.Title>Property Tools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={4} direction="column">
          {state.state === 'SEARCH' && (
            <PropertySearch
              onSearchById={handleSearchById}
              onSearchByName={handleSearchByName}
            />
          )}
          {state.state === 'PROPERTY_DETAILS' && (
            <PropertyDetails
              property={state.propertyData}
              socialPages={state.socialPages}
              onBackClick={handleBackClick}
              onAccountAPIToolsClick={(event) => {
                handleAccountAPIToolsClick(
                  event,
                  state.propertyData.propertyId.toString(),
                );
              }}
              onImpersonateClick={handleImpersonateProperty}
              onShutdownConfirm={handleShutdownConfirm}
              onReactivateSubmit={handleReactivateSubmit}
              onUpdateSettings={handleUpdateSettingsClick}
            />
          )}
          {state.state === 'PROPERTY_SEARCH' && (
            <PropertySearchOptions
              onBackClick={handleBackClick}
              onPropertySelect={handlePropertySearchSelect}
              properties={state.properties}
              warning={state.warning}
            />
          )}
          {state.state === 'PROPERTY_SETTINGS' && (
            <UpdatePropertySettings
              onBackClick={handleBackClick}
              propertyId={state.propertyData.propertyId}
              propertyName={state.propertyData.propertyName}
              propertyURN={state.propertyData.propertyURN}
            />
          )}
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default PropertyToolsModal;
