import { useState } from 'react';

import isEqual from 'fast-deep-equal';

import {
  Checkbox,
  Flex,
  FormControl,
  InfoIcon,
  Input,
  Link,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as accountAPIs from 'common/accountAPIs';
import * as settings from 'common/settings';
import * as social from 'common/social';
import * as tracker from 'common/tracker';

import Emoji from 'components/misc/Emoji';
import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';

import Bold from 'components/misc/Bold';
import { AutofeedSettings, FixTypeLater } from 'types';

interface NewSharePrefixSuffixSettings {
  newshareMessagePrefix: string;
  newshareMessageSuffix: string;
  newShareMessageEmojiSuffix: boolean;
}

function PrefixSuffix() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();
  const { global } = useGlobalInfo();
  const { accountAPIId } = useAutomationSettingsContext();

  const getNewSharePrefixSuffixSettings = () => {
    const defaults = social.getAutofeedDefaults({
      apiTypeId: accountAPIs.getAPITypeId({
        accountAPIId,
      }),
    });

    const newshareMessagePrefix =
      settings.getAutofeedSetting({
        key: 'newshareMessagePrefix',
      }) ??
      defaults.newshareMessagePrefix ??
      '';

    const newshareMessageSuffix =
      settings.getAutofeedSetting({
        key: 'newshareMessageSuffix',
      }) ??
      defaults.newshareMessageSuffix ??
      '';

    const newShareMessageEmojiSuffix =
      settings.getAutofeedSetting({
        key: 'newShareMessageEmojiSuffix',
      }) ?? !!defaults.newShareMessageEmojiSuffix;
    return {
      newshareMessagePrefix,
      newshareMessageSuffix,
      newShareMessageEmojiSuffix,
    };
  };

  const newSharePrefixSuffixSettings = getNewSharePrefixSuffixSettings();
  const [
    currentNewSharePrefixSuffixSettings,
    setCurrentNewSharePrefixSuffixSettings,
  ] = useState(newSharePrefixSuffixSettings);

  const handleSaveNewSharePreffixSuffix = (
    newSettings: NewSharePrefixSuffixSettings,
  ) => {
    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    updatedSettings.newshareMessagePrefix = newSettings.newshareMessagePrefix;
    updatedSettings.newshareMessageSuffix = newSettings.newshareMessageSuffix;
    updatedSettings.newShareMessageEmojiSuffix =
      newSettings.newShareMessageEmojiSuffix;

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
      prefixSuffixMixpanel,
    );
  };

  const prefixSuffixMixpanel = (
    updatedTracking: FixTypeLater,
    previousTracking: FixTypeLater,
  ) => {
    tracker.track({
      eventName: 'Update Autofeed New Share Settings',
      trackingParams: {
        ...updatedTracking,
        'Social Page': accountAPIs.getAPIPostName({ accountAPIId }),
        'Previous New Share Prefix': previousTracking['New Share Prefix'],
        'Previous New Share Suffix': previousTracking['New Share Suffix'],
        'Previous Emoji Toggle Status': previousTracking['Emoji Toggle Status'],
      },
    });
  };

  const handleNewSharePrefixSuffixSettingsChange = (
    newSettings: NewSharePrefixSuffixSettings,
  ) => {
    setCurrentNewSharePrefixSuffixSettings(newSettings);

    if (isEqual(newSettings, newSharePrefixSuffixSettings)) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveNewSharePreffixSuffix(newSettings));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    const newSettings = {
      ...currentNewSharePrefixSuffixSettings,
      [name]: value,
    };
    handleNewSharePrefixSuffixSettingsChange(newSettings);
  };

  const handleCheckboxChange = () => {
    const newValue =
      !currentNewSharePrefixSuffixSettings.newShareMessageEmojiSuffix;
    const newSettings = {
      ...currentNewSharePrefixSuffixSettings,
      newShareMessageEmojiSuffix: newValue,
    };
    handleNewSharePrefixSuffixSettingsChange(newSettings);
  };

  const tooltipLabel = (
    <Flex flexDir="column" gap={1}>
      <Text size="sm">
        Leveraging the findings from our{' '}
        <Link href="https://www.echobox.com/resources/white-papers/should-publishers-use-emojis-on-facebook/">
          white paper
        </Link>
        , activating this feature will apply the following 50% of the time:
      </Text>
      <UnorderedList ml={5}>
        <ListItem fontSize={14} lineHeight="20px" fontWeight={400}>
          Add <Emoji label="warning" symbol="⚠️" /> to breaking news posts
        </ListItem>
        <ListItem fontSize={14} lineHeight="20px" fontWeight={400}>
          Add <Emoji label="down-arrow" symbol="⬇️" /> to posts with a high
          score
        </ListItem>
      </UnorderedList>
    </Flex>
  );

  return (
    <>
      <AutomationSettingsHeader
        title="Prefix & Suffix"
        description="Add a word or phrase to each automated new share"
      />
      <Flex flexDir="column" gap={6}>
        <FormControl>
          <FormControl.FormLabel fontWeight="normal">
            New shares will start with the following <Bold>prefix</Bold> (max.
            20 characters)
          </FormControl.FormLabel>
          <Flex gap={2}>
            <Input
              name="newshareMessagePrefix"
              data-cy-input="newSharePrefix"
              w="420px"
              maxLength={20}
              value={currentNewSharePrefixSuffixSettings.newshareMessagePrefix}
              onChange={handleInputChange}
            />
          </Flex>
        </FormControl>
        <FormControl>
          <FormControl.FormLabel fontWeight="normal">
            New shares will end with the following <Bold>suffix</Bold> (max. 20
            characters)
          </FormControl.FormLabel>
          <Flex gap={2}>
            <Input
              name="newshareMessageSuffix"
              data-cy-input="newShareSuffix"
              w="420px"
              maxLength={20}
              value={currentNewSharePrefixSuffixSettings.newshareMessageSuffix}
              onChange={handleInputChange}
            />
          </Flex>
        </FormControl>
        <Flex gap={1} alignItems="center">
          <Checkbox
            isChecked={
              currentNewSharePrefixSuffixSettings.newShareMessageEmojiSuffix
            }
            onChange={handleCheckboxChange}
          >
            Add performance enhancing emojis to the end of my share messages
          </Checkbox>
          <Tooltip
            label={tooltipLabel}
            placement="top-start"
            p={4}
            closeDelay={800}
            w="420px"
            pointerEvents="all"
          >
            <InfoIcon color="gray.600" />
          </Tooltip>
        </Flex>
      </Flex>
    </>
  );
}

export default PrefixSuffix;
