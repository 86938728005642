/* jscpd:ignore-start */

import { Box, Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import clsx from 'clsx';
import * as React from 'react';
import reactStringReplace from 'react-string-replace';

import { getAPITypeId } from 'common/accountAPIs';
import {
  ACTION_HISTORY_TYPES,
  API_TYPE_IDS,
  TAG_TYPES,
} from 'common/constants';
import { getTextDirection } from 'common/language';
import { regexpEscape } from 'common/tags';
import { isNull } from 'common/utility';
import { useEllipsisActive } from 'hooks/useEllipsisActive';
import { ActionHistoryType, TagMap } from 'types';

const styleValidHashtag = (match: string, i: number) => {
  if ((match.match(/[\s]?#/g) || []).length === 1) {
    return (
      <span key={match + i} className="share_tag_message">
        {match}
      </span>
    );
  }
  return match;
};

interface MessageWithTagsProps {
  actionTypeId: ActionHistoryType;
  message: string | null;
  tags: TagMap;
  accountAPIId: number;
  outerClass: string;
  truncateLines?: number;
}

const AnalyticsMessageWithTags = ({
  actionTypeId,
  message,
  tags,
  accountAPIId,
  outerClass,
  truncateLines,
}: MessageWithTagsProps) => {
  const messageRef = React.useRef<HTMLDivElement>(null);
  const isEllipsisActive = useEllipsisActive(messageRef);

  if (isNull(message)) {
    return null;
  }
  // Convert all tags in the message from using the "internal" tag format (e.g. #FB[EchoboxHQ])
  // to using the "raw" tag format (e.g. #EchoboxHQ) so we can then search for instances of
  // each tag's "raw" representation and replace it with the "clean" user-facing label
  let output: string | React.ReactNode[] | undefined = message;
  Object.values(TAG_TYPES).forEach((tagType) => {
    const tagsOfType = tags[tagType];
    tagsOfType.forEach((tag) => {
      const find = new RegExp(
        tagType === TAG_TYPES.HASHTAG
          ? `(?<!\\S)(${regexpEscape(tag.raw)})`
          : `(${regexpEscape(tag.raw)})`,
        'gi',
      );
      output = reactStringReplace(output, find, (match, i) => (
        <span key={match + i} className="share_tag_message">
          {tag.clean}
        </span>
      ));
    });
  });

  // When a post is directly shared to LinkedIn, the message can contain \ used to escape characters (e.g. \#)
  // We want to remove those when we display the message text
  const apiTypeId = getAPITypeId({ accountAPIId });
  const wasSharedDirectlyToLinkedIn =
    apiTypeId === API_TYPE_IDS.LINKEDIN &&
    actionTypeId !== ACTION_HISTORY_TYPES.ECHOBOX_SHARE;
  if (wasSharedDirectlyToLinkedIn) {
    output = reactStringReplace(output, /\\([^a-zA-Z0-9])/g, (match) => match);
  }

  output = reactStringReplace(output, /^(#[\w]+)/g, styleValidHashtag);

  output = reactStringReplace(
    output,
    /([\s][@|#](?!Echobox=)\S+)/g,
    styleValidHashtag,
  );

  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: message,
  });

  return (
    <Tooltip
      maxWidth="xl"
      label={Array.isArray(output) ? output[0] : output}
      isDisabled={!isEllipsisActive}
    >
      <Box
        ref={messageRef}
        className={clsx(outerClass, textDirectionClass)}
        display="-webkit-box"
        sx={{
          WebkitLineClamp: `${truncateLines}`,
          WebkitBoxOrient: 'vertical',
        }}
        overflow="hidden"
        data-cy-id="message"
      >
        {output}
      </Box>
    </Tooltip>
  );
};

export default AnalyticsMessageWithTags;

/* jscpd:ignore-end */
