import {
  Accordion,
  Card,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { isNullOrUndefined } from 'common/utility';
import { SyndFeedUpdateHistoryIndividual } from 'types';

const sortDescByUpdateTime = (
  first: SyndFeedUpdateHistoryIndividual,
  second: SyndFeedUpdateHistoryIndividual,
) => {
  if (
    first.updateTime > second.updateTime ||
    (!isNullOrUndefined(first.updateTime) &&
      isNullOrUndefined(second.updateTime))
  ) {
    return -1;
  }

  if (
    first.updateTime === second.updateTime ||
    (isNullOrUndefined(first.updateTime) &&
      isNullOrUndefined(second.updateTime))
  ) {
    return 0;
  }

  if (
    first.updateTime < second.updateTime ||
    (!isNullOrUndefined(first.updateTime) &&
      isNullOrUndefined(second.updateTime))
  ) {
    return 1;
  }

  throw new Error("We shouldn't be here");
};

interface ContentFeedUpdateHistoryProps {
  updateHistory: Array<SyndFeedUpdateHistoryIndividual>;
}

const ContentFeedUpdateHistory = ({
  updateHistory,
}: ContentFeedUpdateHistoryProps) => {
  return (
    <Card>
      <Card.Content gap={4} flexGrow={1}>
        <Card.Title>Update History</Card.Title>
        <Accordion>
          {updateHistory.sort(sortDescByUpdateTime).map((update) => {
            return (
              <Accordion.Item key={`feed-update-history-${update.updateTime}`}>
                <Accordion.ItemHeader>
                  {new Date(update.updateTime * 1000).toUTCString()}
                </Accordion.ItemHeader>

                <Accordion.ItemPanel display="flex">
                  <VStack alignItems="left">
                    <Text>{`Update Succeeded: ${update.updateSucceeded}`}</Text>
                    <Text>{`Articles Seen: ${update.articlesSeen}`}</Text>
                    {update.feedTitle && (
                      <Text>{`Content Feed Title: ${update.feedTitle}`}</Text>
                    )}
                  </VStack>
                </Accordion.ItemPanel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Card.Content>
    </Card>
  );
};

export default ContentFeedUpdateHistory;
