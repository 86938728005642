import { POST_TYPES, SOCIAL_CHANNELS } from 'common/constants';
import { isIGCabinetEnabled } from 'common/settings';
import { PostType, SocialChannel } from 'types';
import { validateReelsThumbnailImage } from './common';

export function getDefaultImageAspectRatios({
  socialChannel,
  postType,
}: {
  socialChannel?: SocialChannel;
  postType: PostType;
}) {
  let defaultValue = null;

  if (postType === POST_TYPES.LINK && socialChannel === SOCIAL_CHANNELS.FEED) {
    defaultValue = 1;
  }

  if (
    postType === POST_TYPES.PHOTO_STORY ||
    socialChannel === SOCIAL_CHANNELS.STORY
  ) {
    defaultValue = 0.5625;
  }

  if (
    postType === POST_TYPES.STATUS &&
    socialChannel === SOCIAL_CHANNELS.FEED
  ) {
    defaultValue = 1.25;
  }

  return { default: defaultValue };
}

export function getImageAspectRatios({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  let min = null;
  let max = null;

  if (
    postType === POST_TYPES.STATUS ||
    (postType === POST_TYPES.LINK && socialChannel === SOCIAL_CHANNELS.FEED)
  ) {
    min = 0.8;
    max = 1.91;
  }

  const socialChannelsWith9x16AspectRatio: SocialChannel[] = [
    SOCIAL_CHANNELS.STORY,
    SOCIAL_CHANNELS.REEL,
  ];
  if (
    postType === POST_TYPES.PHOTO_STORY ||
    socialChannelsWith9x16AspectRatio.includes(socialChannel)
  ) {
    min = 0.5625;
    max = 0.5625;
  }

  if (postType === POST_TYPES.VIDEO && socialChannel === SOCIAL_CHANNELS.FEED) {
    min = 0.5625;
    max = 1.8;
  }

  return { min, max };
}

export function getMediaSizeLimits({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  const min = null;
  let max = null;

  // Special override for cabinet users.
  // TODO: remove once the cabinet is deprecated.
  if (isIGCabinetEnabled()) {
    if (postType === POST_TYPES.VIDEO) {
      max = 650 * 1024 * 1024; // 650MB
    }
  } else if (postType === POST_TYPES.STATUS) {
    max = 8 * 1024 * 1024; // 8MB
  } else if (postType === POST_TYPES.VIDEO) {
    if (socialChannel === SOCIAL_CHANNELS.FEED) {
      max = 1024 * 1024 * 1024; // 1GB
    } else if (socialChannel === SOCIAL_CHANNELS.STORY) {
      max = 100 * 1024 * 1024; // 100MB
    } else if (socialChannel === SOCIAL_CHANNELS.REEL) {
      max = 1024 * 1024 * 1024; // 1GB
    }
  }

  return { min, max };
}

export function getImageQuantityLimits({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  if (
    socialChannel === SOCIAL_CHANNELS.FEED &&
    postType === POST_TYPES.STATUS
  ) {
    return {
      min: 1,
      max: 10,
    };
  }
  return {
    min: 1,
    max: 1,
  };
}

export function getVideoDurationLimits({
  socialChannel,
  postType,
}: {
  socialChannel: SocialChannel;
  postType: PostType;
}) {
  // Special case for Instagram Cabinet
  // To be removed when Cabinet is fully deprecated
  if (postType === POST_TYPES.VIDEO && isIGCabinetEnabled()) {
    return {
      min: 0,
      max: 60,
    };
  }

  if (postType === POST_TYPES.VIDEO) {
    if (socialChannel === SOCIAL_CHANNELS.STORY) {
      return {
        min: 3,
        max: 60,
      };
    }
    if (
      socialChannel === SOCIAL_CHANNELS.REEL ||
      socialChannel === SOCIAL_CHANNELS.FEED
    ) {
      return {
        min: 3,
        max: 900, // 15 minutes
      };
    }
  }

  return null;
}

export function thumbnailImageValidator({
  socialChannel,
  fileSize,
  imageDimensions,
}: {
  socialChannel: SocialChannel;
  fileSize?: number;
  imageDimensions: { width: number; height: number };
}) {
  if (fileSize && socialChannel === SOCIAL_CHANNELS.FEED) {
    const maxFileSize = 8 * 1024 * 1024; // 8MB
    if (fileSize > maxFileSize) {
      return `Thumbnails must have a file size less than 8MB`;
    }
  }

  return validateReelsThumbnailImage({ socialChannel, imageDimensions });
}
