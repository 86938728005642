import {
  Header as BaseHeader,
  Box,
  Link as ComponentLink,
  WarningIcon,
  useBreakpointValue,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  getCurrentAPITypeId,
  getCurrentPropertyId,
  getPropertyPermission,
  isCurrentPropertySuspended,
  isPropertyIncomplete,
} from 'common/accountAPIs';
import { isImpersonating } from 'common/authentication';
import {
  API_TYPE_IDS,
  PERMISSION_TYPES,
  REACT_PREVENT_RENDER,
  ROUTE_REDIRECTIONS,
} from 'common/constants';
import * as environment from 'common/environment';
import { isRunningTests } from 'common/utility';
import BeamerNotification from 'components/header/BeamerNotification';
import { HeaderContext } from 'components/header/HeaderContext';
import HealthMenu from 'components/header/HealthMenu';
import HelpMenu from 'components/header/HelpMenu';
import MobileMenu from 'components/header/MobileMenu';
import NewSharePanel from 'components/header/NewSharePanel';
import SearchV3 from 'components/header/Search';
import Settings from 'components/header/Settings';
import SubHeader from 'components/header/SubHeader';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { GlobalInfo } from 'types';
import NewSharePanelV2 from './NewSharePanelV2';
import ProductTabs from './ProductTabs';

interface HeaderProps {
  showMobileMenu: boolean;
  onMobileMenuToggle: () => void;
}

const Header = ({ showMobileMenu, onMobileMenuToggle }: HeaderProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isMobile = isRunningTests()
    ? false
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useBreakpointValue({ base: true, md: false });
  const { global } = useContext(GlobalInfoContext);
  const navigate = useNavigate();

  const showNewShare = !isCurrentPropertySuspended();
  const showSearchInput = !isMobile || isOpen;

  const globalInfo: GlobalInfo.GlobalInfo = global.getGlobalInfo();
  const { user, properties } = globalInfo;
  const propertyId = getCurrentPropertyId({ globalInfo });
  const propertyPermission = getPropertyPermission({
    propertyId,
    globalInfo,
  });
  const apiTypeId = getCurrentAPITypeId({ globalInfo });

  const isLoading = global.isLoading();

  const getProperties = () => {
    const propertyValues = Object.values(properties).map(
      ({ propertyId: id, propertyName }) => {
        return {
          id,
          name: propertyName,
          isIncomplete: isPropertyIncomplete({ propertyId: id }),
        };
      },
    );

    const sortedPropertyValues = propertyValues.sort((propertyA, propertyB) => {
      return propertyA.name.localeCompare(propertyB.name);
    });

    return sortedPropertyValues;
  };

  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

  const headerContextValue = useMemo(() => {
    return { showMobileMenu, onMobileMenuToggle };
  }, [onMobileMenuToggle, showMobileMenu]);

  if (global.isLoading()) {
    return REACT_PREVENT_RENDER;
  }

  const loginUrl = environment.getLoginUrl();

  const myProfileLink = {
    href: `${loginUrl}/myprofile`,
    as: ComponentLink,
  };

  return (
    <HeaderContext.Provider value={headerContextValue}>
      <BaseHeader
        position="absolute"
        className="social-header"
        top={0}
        right={0}
        left={0}
        boxShadow="borderBottom"
        {...(isMobile && {
          h: '52px',
          p: 4,
        })}
      >
        {isMobile ? (
          <MobileHeader isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
        ) : (
          <>
            <BaseHeader.Section>
              <ProductTabs />
              {!isLoading && isImpersonating() && (
                <BaseHeader.TagGroup>
                  <BaseHeader.Tag
                    tooltipLabel={`Impersonating client ID: ${propertyId}`}
                    color="amber"
                    icon={<WarningIcon />}
                  >
                    Impersonating
                  </BaseHeader.Tag>
                </BaseHeader.TagGroup>
              )}
            </BaseHeader.Section>
            <BaseHeader.Section
              flexGrow={1}
              display={{ base: 'none', lg: 'flex' }}
            >
              {showSearchInput && (
                <SearchV3 isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
              )}
            </BaseHeader.Section>
            <BaseHeader.Section>
              <BaseHeader.ButtonGroup>
                <Box display={{ base: 'block', lg: 'none' }}>
                  <SearchV3 isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
                </Box>
                {showNewShare &&
                (apiTypeId === API_TYPE_IDS.INSTAGRAM ||
                  apiTypeId === API_TYPE_IDS.FACEBOOK) ? (
                  <NewSharePanelV2 />
                ) : (
                  <NewSharePanel />
                )}
              </BaseHeader.ButtonGroup>
              <BaseHeader.IconButtonGroup>
                {showNewShare && <BeamerNotification />}
                <HealthMenu />
                <HelpMenu />
                {isAdmin && <Settings />}
              </BaseHeader.IconButtonGroup>
              {!isLoading && (
                <BaseHeader.PropertyAndUserMenu
                  displayName={user.name}
                  email={user.emailAddress}
                  onSignOut={() => navigate(ROUTE_REDIRECTIONS.LOGOUT)}
                  currentPropertyId={Number(propertyId)}
                  properties={getProperties()}
                  onPropertySelect={global.handlePropertyChange}
                  createPropertyLink={{
                    to: '/addproperty?origin=share',
                    as: Link,
                  }}
                  myProfileLink={myProfileLink}
                />
              )}
            </BaseHeader.Section>
          </>
        )}
      </BaseHeader>
    </HeaderContext.Provider>
  );
};

interface MobileHeaderProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const MobileHeader = ({ isOpen, onClose, onOpen }: MobileHeaderProps) => {
  return (
    <>
      <BaseHeader.Section>
        <MobileMenu />
      </BaseHeader.Section>
      <BaseHeader.Section>
        <BaseHeader.ButtonGroup>
          <SearchV3 isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
          <NewSharePanel />
        </BaseHeader.ButtonGroup>
      </BaseHeader.Section>
      <Box
        w="full"
        boxShadow="borderBottom"
        h="52px"
        bg="gray.100"
        overflowX="auto"
        position="absolute"
        top="52px"
        left={0}
        zIndex={1}
      >
        <SubHeader />
      </Box>
    </>
  );
};

export default Header;
