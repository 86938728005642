import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChangeEvent, KeyboardEvent, MouseEvent, useState } from 'react';

import { KEYNAMES } from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as tracker from 'common/tracker';
import { getCommonTrackingParams } from 'helpers/tracking';
import { FixTypeLater } from 'types';

/**
 * Full edit mode - labels
 */

interface LabelsProps {
  guid: string;
  mediaItem: FixTypeLater;
  applySingleFieldUpdateForGuid: (args: {
    guid: string;
    fieldName: string;
    fieldValue: unknown;
  }) => void;
}

const Labels = ({
  guid,
  mediaItem,
  applySingleFieldUpdateForGuid,
}: LabelsProps) => {
  /**
   * Initial state
   */

  const [labelText, setLabelText] = useState('');

  /**
   * Event handlers
   */

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYNAMES.ENTER) {
      handleLabelAdd();
    }
  };

  const handleLabelAdd = () => {
    const labelToAdd = labelText.trim().toUpperCase();
    if (labelToAdd !== '') {
      logger.info(`Labels:handleLabelAdd - ${labelToAdd}`);

      const trackingParams = getCommonTrackingParams({ mediaItem });
      trackingParams.Label = labelToAdd;
      tracker.track({
        eventName: 'Add Label',
        trackingParams,
      });

      setLabelText('');
      const labels = MediaItem.getMediaItemTags({ mediaItem });
      if (!labels.some((label) => label.tag === labelToAdd)) {
        labels.push({
          tag: labelToAdd,
        });
        applySingleFieldUpdateForGuid({
          guid,
          fieldName: 'mediaItemTags',
          fieldValue: labels,
        });
      }
    }
  };

  const handleLabelChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLabelText(event.target.value);
  };

  const handleLabelDelete = (event: MouseEvent<HTMLAnchorElement>) => {
    const labelToDelete = event.currentTarget.getAttribute('data-label');
    logger.info(`Labels:handleLabelDelete - ${labelToDelete}`);

    const trackingParams = getCommonTrackingParams({ mediaItem });
    trackingParams['Label Removed'] = labelToDelete;
    tracker.track({
      eventName: 'Remove Label',
      trackingParams,
    });

    const labels = MediaItem.getMediaItemTags({ mediaItem });
    const updated = labels.filter((label) => label.tag !== labelToDelete);
    applySingleFieldUpdateForGuid({
      guid,
      fieldName: 'mediaItemTags',
      fieldValue: updated,
    });
  };

  /**
   * Render methods
   */

  const labels = MediaItem.getMediaItemTags({
    mediaItem,
  });

  return (
    <>
      <div data-cy-input="labels" className="mt-3">
        <Box as="label" htmlFor="labels" display="block" className="subtitle">
          Labels
        </Box>
        <input
          className="labels d-flex mb-1 w-100 mt-1 input_medium"
          id="labels"
          name="label"
          type="text"
          placeholder="Type label and press enter..."
          value={labelText}
          onChange={handleLabelChange}
          onKeyDown={handleKeyDown}
          data-cy-input="label"
          data-cy="labelInput"
        />
        <div className="labels w-100 mt-1 input_medium d-flex flex-wrap" />
      </div>
      <div
        data-cy="labels"
        className="labels w-100 mt-1 input_medium d-flex flex-wrap"
      >
        {labels.map((label) => (
          <span
            className="text_unselectable gray-600 mr-1 mb-1 text-break badge badge-label text-400"
            key={label.tag}
            data-cy-id={label.tag}
            data-cy={label.tag}
          >
            <span>{label.tag}</span>
            <span className="remove ml-1">
              <a
                className="ft-11 close gray-600 float-none p-1"
                data-label={label.tag}
                onClick={handleLabelDelete}
                data-cy-action="removeLabel"
              >
                ✖
              </a>
            </span>
          </span>
        ))}
      </div>
    </>
  );
};

export default Labels;
