import { Box, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { memo, useState } from 'react';
import { z } from 'zod';

import { getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import {
  API_TYPE_IDS,
  COMPOSE_BOX_TABS,
  POST_TYPES,
  SOCIAL_CHANNELS,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as MediaItem from 'common/mediaItem';
import { getFeatureToggle, isIGCabinetEnabled } from 'common/settings';
import {
  getSocialNetworkMessageBoxPlaceholder,
  hasMessageField,
} from 'common/social';
import { lyingParse } from 'common/zod';
import Images from 'components/compose/Images';
import InstagramOtherSettings from 'components/compose/InstagramOtherSettings';
import InstagramSocialChannel from 'components/compose/InstagramSocialChannel';
import Labels from 'components/compose/Labels';
import MessageBox from 'components/compose/messagebox/MessageBox';
import OtherActions from 'components/compose/OtherActions';
import PreviewError from 'components/compose/PreviewError';
import PreviewHeader from 'components/compose/PreviewHeader';
import TimingOptions from 'components/compose/TimingOptions';
import URL from 'components/compose/URL';
import type { FixTypeLater, IGLinkStickerConfig } from 'types';

import InstagramShareType from './InstagramShareType';

const errorStatusCodeSchema = z
  .number()
  .optional()
  .describe('InstagramPreview.errorStatusCodeSchema');

interface InstagramPreviewProps {
  accountAPIId: number;
  allowDelete: boolean;
  applySingleFieldUpdateForGuid: (args: {
    guid?: string;
    fieldName: string;
    fieldValue: FixTypeLater;
    isChanged?: boolean;
  }) => void;
  containsSingleAccountAPIId: boolean;
  deleteComposeBoxItem: (args: { guid: string }) => void;
  guid: string;
  mediaId: string;
  mediaItem: FixTypeLater;
  previewCollapsed: boolean;
  selectedTab: string;
}

const InstagramPreview = ({
  accountAPIId,
  allowDelete,
  applySingleFieldUpdateForGuid,
  containsSingleAccountAPIId,
  deleteComposeBoxItem,
  guid,
  mediaId,
  mediaItem,
  previewCollapsed,
  selectedTab,
}: InstagramPreviewProps) => {
  const [pendingStickerConfig, setPendingStickerConfig] =
    useState<IGLinkStickerConfig | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const validationMessage = MediaItem.getValidationMessage({ mediaItem });
  const errorMessage = MediaItem.getErrorMessage({ mediaItem });
  const errorDetails = MediaItem.getErrorDetails({ mediaItem });
  const unvalidatedStatusCode =
    typeof errorDetails === 'object' &&
    errorDetails != null &&
    'status' in errorDetails
      ? errorDetails.status
      : undefined;
  const statusCode = lyingParse(errorStatusCodeSchema, unvalidatedStatusCode);
  const isRefreshPreview = MediaItem.getIsRefreshPreview({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const unshortenedShareURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });

  const isLinkinbioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId,
  });
  const showMessage = hasMessageField({
    apiTypeId: API_TYPE_IDS.INSTAGRAM,
    postType,
    socialChannel,
  });
  const showURL =
    postType === POST_TYPES.LINK &&
    (socialChannel !== SOCIAL_CHANNELS.FEED ||
      (isLinkinbioEnabled && MediaItem.getShouldSendURL({ mediaItem })));

  const leftClass =
    selectedTab === COMPOSE_BOX_TABS.PREVIEW ? 'd-block' : 'd-none d-md-block';
  const rightClass =
    selectedTab === COMPOSE_BOX_TABS.PREVIEW ? 'd-none d-md-block' : 'd-block';

  const renderImagery = () => {
    const previewFields = MediaItem.getPreviewFields({ mediaItem });
    const articleImages = previewFields.imageURLs;

    if (isRefreshPreview) {
      const message = isRefreshPreview
        ? 'Retrieving article data...'
        : 'Applying image overlay...';
      return (
        <div className="share_imagery">
          <div className="text-center mt-4 mb-5 op-50">
            <Spinner size="md" />
            <span className="text-loading mt-5">{message}</span>
          </div>
        </div>
      );
    }

    return (
      <Images
        key={socialChannel}
        mediaItem={mediaItem}
        accountAPIId={accountAPIId}
        guid={guid}
        apiTypeId={API_TYPE_IDS.INSTAGRAM}
        articleImages={articleImages}
        pendingStickerConfig={pendingStickerConfig}
        onPendingStickerConfig={setPendingStickerConfig}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
      />
    );
  };

  return (
    <div data-cy-attribute={`accountAPIId:${accountAPIId}`}>
      <PreviewError
        errorMessage={validationMessage ?? errorMessage}
        statusCode={statusCode}
      />
      <PreviewHeader
        accountAPIId={accountAPIId}
        guid={guid}
        apiTypeId={API_TYPE_IDS.INSTAGRAM}
        additionalHeaders={mediaItem?.frontend?.additionalSocialPages}
      />
      <div
        id={`collapsePreview-${guid}`}
        className="collapse show"
        aria-labelledby={`headingPreview-${guid}`}
        data-cy-id="mediaId"
        data-cy-attribute={`mediaId:${mediaId}`}
      >
        <div className="d-flex">
          <div className={`left main-preview ${leftClass}`}>
            <div className="previews">
              <div className="preview-wrapper">
                <Box className="preview" overflow="hidden" w="547px">
                  {showMessage && (
                    <div className="border-bottom">
                      <MessageBox
                        mediaItem={mediaItem}
                        guid={guid}
                        accountAPIId={accountAPIId}
                        placeholder={getSocialNetworkMessageBoxPlaceholder({
                          apiTypeId: API_TYPE_IDS.INSTAGRAM,
                        })}
                        applySingleFieldUpdateForGuid={
                          applySingleFieldUpdateForGuid
                        }
                      />
                    </div>
                  )}
                  {renderImagery()}
                </Box>
                {showURL && (
                  <URL
                    mediaItem={mediaItem}
                    guid={guid}
                    url={unshortenedShareURL}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`options right options-right ${rightClass}`}>
            {postType === POST_TYPES.LINK &&
              isLinkinbioEnabled &&
              isIGCabinetEnabled({ propertyId }) && (
                <InstagramSocialChannel guid={guid} mediaItem={mediaItem} />
              )}
            {(postType === POST_TYPES.VIDEO ||
              postType === POST_TYPES.STATUS ||
              postType === POST_TYPES.LINK) &&
              !isIGCabinetEnabled({ propertyId }) && (
                <InstagramShareType guid={guid} mediaItem={mediaItem} />
              )}
            <TimingOptions
              guid={guid}
              mediaItem={mediaItem}
              accountAPIId={accountAPIId}
              applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
            />
            <InstagramOtherSettings guid={guid} mediaItem={mediaItem} />
            <Labels
              guid={guid}
              mediaItem={mediaItem}
              applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
            />
            <OtherActions
              guid={guid}
              allowDelete={allowDelete}
              deleteComposeBoxItem={deleteComposeBoxItem}
              previewCollapsed={previewCollapsed}
              containsSingleAccountAPIId={containsSingleAccountAPIId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(InstagramPreview);
