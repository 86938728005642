import PropTypes from 'prop-types';

const OnOff = (props) => {
  const { isSaving, isSwitchingActive, eventHandlers } = props;

  const savingClass = isSaving ? 'saving_locked' : '';

  return (
    <div className="my-3">
      <div className="d-flex align-items-center mt-1">
        <span className="mr-2">Domain switching</span>
        <select
          className={`btn btn-light dropdown-md ${savingClass}`}
          value={isSwitchingActive}
          onChange={eventHandlers.handleOnOff}
          data-cy-select="domainSwitching"
        >
          <option value="true">On</option>
          <option value="false">Off</option>
        </select>
      </div>
    </div>
  );
};

OnOff.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isSwitchingActive: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleOnOff: PropTypes.func.isRequired,
  }).isRequired,
};

export default OnOff;
