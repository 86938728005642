import clsx from 'clsx';

import * as MediaItem from 'common/mediaItem';
import { getMessageSettings } from 'common/social';
import getTwitterCount from 'common/twitterCount';
import type { FixTypeLater } from 'types';

interface TwitterCountProps {
  apiTypeId: number;
  mediaItem: FixTypeLater;
}

const TwitterCount = ({ apiTypeId, mediaItem }: TwitterCountProps) => {
  const content = MediaItem.getMessage({ mediaItem });
  const messageSettings = getMessageSettings({ apiTypeId });
  let characterCount: number | undefined;
  let className = '';

  if (messageSettings?.maximumLength != null) {
    characterCount = messageSettings.maximumLength - getTwitterCount(content);
    if (characterCount <= -1) {
      className = 'twitter_negative';
    } else if (characterCount <= 20) {
      className = 'twitter_20';
    }
  }

  return (
    <div
      className={clsx(
        'twitter_char_count text_unselectable ml-auto',
        className,
      )}
    >
      {characterCount}
    </div>
  );
};

export default TwitterCount;
