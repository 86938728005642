import clsx from 'clsx';

import {
  getAPIPostName,
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
} from 'common/accountAPIs';
import { AB_TEST_STATUSES, POST_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import Tooltip from 'components/misc/Tooltip';
import {
  toggleABTestCheckbox,
  toggleABVariation,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import type { ABTestStatus, FixTypeLater } from 'types';

interface ABOptionsProps {
  abTestStatusId?: ABTestStatus | null;
  guid: string;
  isUploading: boolean;
  mediaItem: FixTypeLater;
}

const ABOptions = ({
  abTestStatusId = null,
  guid,
  isUploading,
  mediaItem,
}: ABOptionsProps) => {
  const { dispatch } = useComposeBoxContext();

  if (
    abTestStatusId === AB_TEST_STATUSES.COMPLETED ||
    abTestStatusId === AB_TEST_STATUSES.ERROR
  ) {
    return (
      <div className="abtest ml-auto mt-3">
        AB TEST COMPLETED{' '}
        <img
          src="/img/icons/checkmark.svg"
          className="verified_account"
          alt=""
          style={{ position: 'relative', top: '-2px' }}
        />
      </div>
    );
  }

  const abInfo = MediaItem.getABInfo({
    mediaItem,
  });
  const targetingParams = MediaItem.getFbTargetingParams({
    mediaItem,
  });
  const targetingExists =
    targetingParams != null && Object.keys(targetingParams).length > 0;
  const trackingDetails = MediaItem.getTrackingDetails({
    mediaItem,
  });
  const hasImageOverlay = Boolean(trackingDetails?.hasImageOverlay);

  const isDisabled = targetingExists;

  const id = `ab-toggle-${guid}`;

  const isDisplayABVariations = abInfo.isABVariation && !hasImageOverlay;

  const handleABVariationToggle = () => {
    dispatch(toggleABVariation({ guid }));
  };

  const handleABTestToggle = () => {
    const postType = MediaItem.getPostType({ mediaItem });
    const accountAPIId = getCurrentAccountAPIId();
    tracker.track({
      eventName: 'Toggle AB Test',
      trackingParams: {
        'Social Network': getSocialNetworkName({
          apiTypeId: getCurrentAPITypeId(),
        }),
        'Social Page': getAPIPostName({
          accountAPIId,
        }),
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Article URL':
          postType === POST_TYPES.LINK
            ? MediaItem.getUnshortenedShareURL({ mediaItem })
            : 'undefined',
        'New Status': abInfo.isABVariation ? 'Off' : 'On',
      },
    });

    dispatch(toggleABTestCheckbox({ guid }));
  };

  return (
    <div
      className="abtest"
      id="abToggle"
      data-tip
      data-for="targeting_exists"
      data-iscapture="true"
    >
      <div className="text_unselectable abtest checkbox line-height-24 d-flex align-items-center">
        <Tooltip
          id="targeting_exists"
          label="AB Testing is not compatible with Audience Restriction"
          isDisabled={!targetingExists}
        >
          <div>
            <input
              type="checkbox"
              id={id}
              data-cy-action="abTest"
              checked={abInfo.isABVariation}
              disabled={isDisabled}
              className={clsx(
                'check',
                (isDisabled || isUploading) && 'saving_locked',
              )}
              onChange={handleABTestToggle}
            />
            <label
              className={clsx(
                'label flex-grow-1',
                (isDisabled || isUploading) && 'saving_locked',
              )}
              htmlFor={id}
            >
              AB Test
            </label>
          </div>
        </Tooltip>
        {hasImageOverlay && abInfo.isABVariation && (
          <Tooltip
            id="image_overlay_active"
            label="When Image Overlay is selected, it is the only element that can be
              A/B tested."
          >
            <img
              className="info_icon"
              src="/img/icons/ic-information.svg"
              alt=""
              data-tip="true"
              data-for="image_overlay_active"
              data-iscapture="true"
              data-cy="image_overlay_active"
            />
          </Tooltip>
        )}
      </div>
      {isDisplayABVariations && (
        <select
          id="abVariation"
          value={abInfo.currentABVariationIndex}
          className={clsx(
            'filter btn btn-light dropdown-md pl-2 dropdown-abtest w-100 mt-2',
            (targetingExists || isUploading) && 'saving_locked',
          )}
          onChange={handleABVariationToggle}
        >
          <option value={0}>Version A</option>
          <option value={1}>Version B</option>
        </select>
      )}
    </div>
  );
};

export default ABOptions;
