import Moment from 'moment';

import {
  getCurrentAccountAPI,
  getCurrentAccountAPIId,
} from 'common/accountAPIs';
import { SHOULD_LOG_DEBUG } from 'common/config';
import { LOG_LEVELS, USER_TYPES } from 'common/constants';
import { getErrorMessage, getErrorStatus } from 'common/errorHandling';
import { getGlobalInfo } from 'common/globalInfo';
import { stripKeys } from 'common/object';
import { cloneObject, isRunningTests } from 'common/utility';
import { location } from 'common/window';
import { stringifyNonCircular } from './string';

const version = APP_VERSION;

function stringifyError(err: Error) {
  const plainObject: Record<string, unknown> = {};
  Object.getOwnPropertyNames(err).forEach((key) => {
    // @ts-expect-error
    plainObject[key] = err[key];
  });
  return JSON.stringify(plainObject);
}

type Severity = 'info' | 'error' | 'log' | 'track';

interface LoggerOptions {
  /**
   * Event type/name
   */
  event: string;

  /**
   * A flag to indicate that event should be sent to Loggly. Default `true`.
   */
  log?: boolean;

  /**
   * Event properties. Default `{}`.
   */
  properties?: Record<string, unknown>;

  /**
   * The log level.
   */
  severity: Severity;

  /**
   * The raw error object.
   */
  error?: unknown;
}

/**
 * Global logging and tracking functionality for Echobox V3
 */
function logger(rawArgs: LoggerOptions) {
  const args: Partial<LoggerOptions> = {};
  // Set dev mode to true if we are running locally
  // The catch block handles the case where we are running in a test environment
  let dev;
  try {
    dev = location.hostname !== 'social.echobox.com';
  } catch (e) {
    dev = true;
  }

  // Set parameter defaults
  if (rawArgs.log && typeof rawArgs.log === 'boolean') {
    args.log = rawArgs.log;
  } else {
    args.log = true;
  }
  if (rawArgs.event && typeof rawArgs.event === 'string') {
    args.event = rawArgs.event;
  } else {
    throw new ReferenceError('event is undefined');
  }
  if (rawArgs.properties && typeof rawArgs.properties === 'object') {
    args.properties = rawArgs.properties;
  } else {
    args.properties = {};
  }
  if (rawArgs.severity && typeof rawArgs.severity === 'string') {
    args.severity = rawArgs.severity;
  } else {
    args.severity = 'log';
  }

  // Work out if we actually need to log anything
  const logLevel = sessionStorage.getItem('logLevel');
  const logLevelValue =
    logLevel === null ? LOG_LEVELS.ERROR : parseInt(logLevel, 10);

  if (
    LOG_LEVELS[args.severity.toUpperCase() as Uppercase<Severity>] <
    logLevelValue
  ) {
    return;
  }

  // Add error details
  if (rawArgs.error) {
    // Add error details if an error object has been passed
    if (rawArgs.error instanceof Error) {
      args.properties.Error = JSON.parse(stringifyError(rawArgs.error));
      const errorMessage = getErrorMessage(rawArgs.error);
      if (errorMessage !== '') {
        args.properties.ErrorMessage = errorMessage;
      }
      args.properties.ErrorStatus ??= getErrorStatus(rawArgs.error);
    }

    // Add error message and stacktrace if an errorEvent object has been passed
    if (rawArgs.error instanceof ErrorEvent) {
      let message;
      let stack;
      if (rawArgs.error.error) {
        message = rawArgs.error.error.message;
        stack = rawArgs.error.error.stack;
      } else {
        message = rawArgs.error.message;
        // Non-standard property. We shouldn't assume it.
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
        if ('stack' in rawArgs.error) {
          stack = rawArgs.error.stack;
        }
      }
      if (message !== '') {
        args.properties.ErrorMessage = message;
      }
      if (stack !== '') {
        args.properties.Stack = stack;
      }
    }

    // Add error details for non-"real" errors
    if (!(rawArgs.error instanceof Error)) {
      args.properties.Error = rawArgs.error;
    }

    // Make error type a top-level property and remove it from the error details
    if (
      args.properties?.Error &&
      typeof args.properties.Error === 'object' &&
      'type' in args.properties.Error
    ) {
      args.properties.ErrorType = args.properties.Error.type;
      delete args.properties.Error.type;
    }
  }

  // Strip credentials from original error details
  if (args?.properties?.Error) {
    args.properties.Error = stripKeys(args.properties.Error);
  }
  if (args?.properties?.OriginalError) {
    args.properties.OriginalError = JSON.parse(
      stringifyNonCircular(args.properties.OriginalError),
    );
    args.properties.OriginalError = stripKeys(args.properties.OriginalError);
  }

  // Work out if we need to write to logging and/or tracking systems
  const sendToLogger =
    args.log &&
    typeof _LTracker !== 'undefined' &&
    !dev &&
    (args.severity === 'error' || args.severity === 'track');

  // Add account details to reported data
  if (sendToLogger) {
    const globalInfo = getGlobalInfo();
    if (globalInfo) {
      args.properties.UserId = globalInfo.user.userId.toString();
      args.properties.UserEmailAddress = globalInfo.user.emailAddress;
      if (
        globalInfo.current?.propertyId &&
        globalInfo.current.accountAPIId &&
        getCurrentAccountAPI({
          globalInfo,
        }) != null
      ) {
        const currentAPI = getCurrentAccountAPI({
          globalInfo,
        });
        args.properties.AccountAPIId = getCurrentAccountAPIId({
          globalInfo,
        }).toString();
        args.properties.AccountAPIName = currentAPI.apiPostName;
        if (globalInfo.user.userType === USER_TYPES.ECHOBOX_STAFF) {
          dev = true;
        }
      }
    }
  }

  // Add version number to reported data
  args.properties.HV = version;

  // Add user agent to reported data
  if (window) {
    args.properties.UserAgent = window.navigator.userAgent;
  }

  // Stringify any non-essential elements to avoid creating unnecessary indexed fields in Loggly
  args.properties = stringifyReport(args.properties);

  // Send event details to logging system
  if (sendToLogger) {
    try {
      const EventType = args.event;
      _LTracker.push({ EventType, ...args.properties });
    } catch (e) {
      console.log(e);
    }
  }

  // Write event details to console when running locally
  if (
    args.log &&
    (dev ||
      typeof _LTracker === 'undefined' ||
      sessionStorage.getItem('enableLiveLogging')) &&
    (!isRunningTests() || sessionStorage.getItem('enableTestLogging'))
  ) {
    if (
      LOG_LEVELS[args.severity.toUpperCase() as Uppercase<Severity>] ===
      LOG_LEVELS.LOG
    ) {
      console.log(args.event);
    } else if (
      LOG_LEVELS[args.severity.toUpperCase() as Uppercase<Severity>] ===
        LOG_LEVELS.INFO ||
      LOG_LEVELS[args.severity.toUpperCase() as Uppercase<Severity>] ===
        LOG_LEVELS.TRACK
    ) {
      console.log(`*** ${Moment().format('HH:mm:ss.SSS')} ${args.event}`);
    } else {
      console.error(`Event: ${args.event}`);
      console.error(`Properties: ${JSON.stringify(args.properties, null, 2)}`);
    }
  }
}

/**
 * Public logger methods
 */

export async function debug(label: string, args: Record<string, unknown> = {}) {
  if (SHOULD_LOG_DEBUG) {
    const globalInfo = getGlobalInfo();
    if (typeof _LTracker !== 'undefined') {
      _LTracker.push({
        EventType: 'Debug',
        Endpoint: label,
        HV: version,
        UserId: globalInfo ? globalInfo.user.userId : '',
        ...args,
      });
    }
  }
}

export function error(args: Omit<LoggerOptions, 'severity'>) {
  logger({ ...args, severity: 'error' });
}

export function info(args: string | Omit<LoggerOptions, 'severity'>) {
  if (!args) {
    // do nothing
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'info' });
  } else {
    logger({ ...args, severity: 'info' });
  }
}

export function log(args: string | Omit<LoggerOptions, 'severity'>) {
  if (!args) {
    // do nothing
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'log' });
  } else {
    logger({ ...args, severity: 'log' });
  }
}

export function track(args: string | Omit<LoggerOptions, 'severity'>) {
  if (!args) {
    // do nothing
  } else if (typeof args === 'string') {
    logger({ event: args, severity: 'track' });
  } else {
    logger({ ...args, severity: 'track' });
  }
}

/**
 * Helper methods
 */

export function stringifyReport(report: LoggerOptions['properties']) {
  const stringified = cloneObject(report);

  // Stringify any Arguments entries that are objects
  // This includes things like Arguments.accountAPIIds, Arguments.identifiers,
  // Arguments.permissionsOnProperty and Arguments.propertyIds which can account for
  // a significant number of different fields
  if (stringified?.Arguments && typeof stringified.Arguments === 'object') {
    const args = stringified.Arguments as Record<string, unknown>;
    Object.keys(args).forEach((arg) => {
      if (typeof args[arg] === 'object') {
        try {
          args[arg] = JSON.stringify(args[arg]);
        } catch (e) {
          //
        }
        stringified.Arguments = args;
      }
    });
  }

  // Stringify any Error.config and Error.response.config/headers/request entries
  if (stringified?.Error && typeof stringified.Error === 'object') {
    if ('config' in stringified.Error) {
      try {
        stringified.Error.config = JSON.stringify(stringified.Error.config);
      } catch (e) {
        //
      }
    }
    if (
      'response' in stringified.Error &&
      stringified.Error.response != null &&
      typeof stringified.Error.response === 'object'
    ) {
      if ('config' in stringified.Error.response) {
        try {
          stringified.Error.response.config = JSON.stringify(
            stringified.Error.response.config,
          );
        } catch (e) {
          //
        }
      }
      if ('headers' in stringified.Error.response) {
        try {
          stringified.Error.response.headers = JSON.stringify(
            stringified.Error.response.headers,
          );
        } catch (e) {
          //
        }
      }
      if ('request' in stringified.Error.response) {
        try {
          stringified.Error.response.request = JSON.stringify(
            stringified.Error.response.request,
          );
        } catch (e) {
          //
        }
      }
    }
  }

  return stringified;
}
