import {
  Avatar,
  Box,
  Dropdown,
  EnvelopeIcon,
  Flex,
  Header,
  Heading,
  HelpQuestionIcon,
  LearnIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import gabrielAvatarSrc from 'assets/img/gabriel-avatar.jpg';
import josephAvatarSrc from 'assets/img/joseph-avatar.png';
import megAvatarSrc from 'assets/img/meg-avatar.png';
import {
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
} from 'common/accountAPIs';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { showZendesk } from 'common/zendesk';
import HelpMenuExternalLinkItem from 'components/header/HelpMenuExternalLinkItem';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { getNetworkAndPageName, getOrigin } from 'helpers/tracking';

function trackAction(action: string) {
  const accountAPIId = getCurrentAccountAPIId();
  tracker.track({
    eventName: 'Action Help Header Dropdown',
    trackingParams: {
      'Social Network': getSocialNetworkName({
        apiTypeId: getCurrentAPITypeId(),
      }),
      'Network - Social Page': getNetworkAndPageName({
        accountAPIId,
      }),
      'Account API Id': accountAPIId,
      Action: action,
      Origin: getOrigin(window.location.pathname),
    },
  });
}

const HelpMenuAvatar = (
  props: React.ComponentPropsWithoutRef<typeof Avatar>,
) => {
  return (
    <Avatar
      position="absolute"
      border="1px"
      borderColor="gray.300"
      boxShadow="0 0 1px 0 rgba(9, 30, 66, 0.31), 0 3px 5px 0 rgba(9, 30, 66, 0.12)"
      {...props}
    />
  );
};

const HelpMenu = () => {
  const { global } = useGlobalInfo();

  const handleMenuButtonClick = (isOpen: boolean) => {
    if (isOpen) {
      const accountAPIId = getCurrentAccountAPIId();
      tracker.track({
        eventName: 'View Help Header Dropdown',
        trackingParams: {
          'Social Network': getSocialNetworkName({
            apiTypeId: getCurrentAPITypeId(),
          }),
          'Network - Social Page': getNetworkAndPageName({
            accountAPIId,
          }),
          'Account API Id': accountAPIId,
          Origin: getOrigin(window.location.pathname),
        },
      });
    }
  };

  const handleSupportClick = () => {
    trackAction('Contact Support');

    if (window.zE) {
      window.zE.activate({ hideOnClose: true });
      showZendesk(global.getGlobalInfo()?.user ?? undefined);
    }
  };

  return (
    <Dropdown gutter={4}>
      {({ isOpen }) => (
        <>
          <Dropdown.Button
            as={Header.IconButton}
            aria-label="Help"
            icon={<HelpQuestionIcon />}
            // `!isOpen` because the state will be toggled
            onClick={() => handleMenuButtonClick(!isOpen)}
          />
          <Dropdown.List pt={0} overflow="auto">
            <Flex p={5} bg="gray.100" gap={4} mb={2}>
              <Heading color="gray.600">We are here to help</Heading>
              <Box width="72px" position="relative" flexShrink={0}>
                <HelpMenuAvatar
                  height={9}
                  width={9}
                  left={0}
                  bottom={2}
                  src={gabrielAvatarSrc}
                />
                <HelpMenuAvatar
                  height={8}
                  width={8}
                  left={4}
                  top={0}
                  src={josephAvatarSrc}
                />
                <HelpMenuAvatar
                  height={11}
                  width={11}
                  right={0}
                  bottom={0}
                  src={megAvatarSrc}
                />
              </Box>
            </Flex>
            <HelpMenuExternalLinkItem
              href="https://echobox.zendesk.com/hc/en-us/categories/11173186912028-Social"
              LeftIcon={HelpQuestionIcon}
              onClick={() => trackAction('Help Center')}
            >
              Help Center
            </HelpMenuExternalLinkItem>
            <HelpMenuExternalLinkItem
              href="https://www.echobox.com/resources/white-papers/"
              LeftIcon={LearnIcon}
              onClick={() => trackAction('Learning Center')}
            >
              Learning Center
            </HelpMenuExternalLinkItem>
            <Dropdown.Item LeftIcon={EnvelopeIcon} onClick={handleSupportClick}>
              Contact support
            </Dropdown.Item>
          </Dropdown.List>
        </>
      )}
    </Dropdown>
  );
};

export default HelpMenu;
