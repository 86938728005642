import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useEffect, useState } from 'react';

import getPropertiesUsers from 'api/getPropertiesUsers';
import { getCurrentProperty, getCurrentPropertyId } from 'common/accountAPIs';
import * as logger from 'common/logger';
import { sortUsers } from 'common/misc';
import ReviewSection from 'components/setup/ReviewSection';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { User } from 'types';

interface ReviewTeamProps {
  onEdit: () => void;
}

const ReviewTeam = ({ onEdit }: ReviewTeamProps) => {
  const context = useContext(GlobalInfoContext);
  const globalInfo = context.global.getGlobalInfo();
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [propertyUsers, setPropertyUsers] = useState<
    ReadonlyArray<User> | undefined
  >(
    sortUsers(
      getCurrentProperty({
        globalInfo,
      }).propertyUsers,
    ),
  );

  useEffect(() => {
    const currentPropertyId = getCurrentPropertyId();

    if (
      !globalInfo.properties[currentPropertyId].propertyUsers &&
      !isLoadingUsers
    ) {
      const loadUsers = async () => {
        logger.info('ReviewPropertySetup - users missing');

        setIsLoadingUsers(true);

        const response = await getPropertiesUsers({
          propertyIds: [currentPropertyId],
        });
        if (response) {
          Object.keys(response).forEach((propertyId) => {
            globalInfo.properties[propertyId].propertyUsers =
              response[propertyId];
          });
        }
        context.global.setGlobalInfo(globalInfo);
        setPropertyUsers(
          sortUsers(getCurrentProperty(globalInfo).propertyUsers),
        );
        logger.info('UserManagement - users loaded');
        setIsLoadingUsers(false);
      };

      loadUsers();
    }
  }, [context.global, globalInfo, isLoadingUsers]);

  return (
    <ReviewSection title="Team" isLoading={isLoadingUsers} onEdit={onEdit}>
      {propertyUsers?.map(({ userId, name, emailAddress }) => (
        <Flex
          key={userId}
          fontSize="sm"
          lineHeight="5"
          color="gray.900"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="text-500">{name}</div>
          {emailAddress}
        </Flex>
      ))}
    </ReviewSection>
  );
};

export default ReviewTeam;
