import { ChangeEventHandler } from 'react';

import { getFilteredPropertiesAndPages } from 'common/accountAPIs';
import { REACT_PREVENT_RENDER } from 'common/constants';
import { getSocialNetworks } from 'common/social';
import SocialPagesNetwork from 'components/compose/socialpages/SocialPagesNetwork';

interface SocialPagesNetworksProps {
  checkedAPIs: Array<number>;
  mediaStates?: Record<string, string[]> | null;
  postType: string;
  propertyId: number;
  searchText: string;
  onSelectAll: (propertyId: number, apiTypeId: number) => () => void;
  onSocialPagesChange: ChangeEventHandler<HTMLInputElement>;
}

const SocialPagesNetworks = ({
  checkedAPIs,
  mediaStates = null,
  postType,
  propertyId,
  searchText,
  onSelectAll,
  onSocialPagesChange,
}: SocialPagesNetworksProps) => {
  const socialNetworks = getSocialNetworks();

  return (
    <div>
      {socialNetworks.map((apiTypeId) => {
        const filteredPropertyAndPages = getFilteredPropertiesAndPages({
          apiTypeId,
          checkedAPIs,
          mediaStates,
          postType,
          propertyId,
          searchText,
        });
        if (filteredPropertyAndPages.length === 0) {
          return REACT_PREVENT_RENDER;
        }
        const filteredPagesForNetwork = filteredPropertyAndPages[0];
        if (
          filteredPagesForNetwork.accountAPIs === undefined ||
          filteredPagesForNetwork.accountAPIs.length === 0
        ) {
          return REACT_PREVENT_RENDER;
        }

        return (
          <SocialPagesNetwork
            apiTypeId={apiTypeId}
            filteredPagesForNetwork={filteredPagesForNetwork.accountAPIs}
            propertyId={propertyId}
            onSelectAll={onSelectAll}
            onSocialPagesChange={onSocialPagesChange}
            key={`social_pages_list_network_${apiTypeId}`}
          />
        );
      })}
    </div>
  );
};

export default SocialPagesNetworks;
