import {
  Box,
  Button,
  Card,
  FormControl,
  HStack,
  Input,
  Modal,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import * as React from 'react';
import { z } from 'zod';

import postPropertiesSettings from 'api/postPropertiesSettings';
import postServiceAuth from 'api/postServiceAuth';
import * as authentication from 'common/authentication';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import { getFormattedDuration } from 'common/datetime';

const OptimalShareTimeWindowFormSchema = z.object({
  optimalShareWindow: z
    .number({
      coerce: true,
      required_error: 'Please enter a value',
      invalid_type_error: 'Please enter a valid number',
    })
    .gte(3600, 'Please enter a value between 3,600 and 86,400')
    .lte(86400, 'Please enter a value between 3,600 and 86,400'),
});

type State =
  | { status: 'editing' }
  | { status: 'error'; fieldErrors: Array<string> }
  | { status: 'confirmation'; optimalShareWindow: number };

const initialState: State = { status: 'editing' };

const slice = createSlice({
  name: 'optimalShareTimeWindow',
  // initialState has been set to '{} as State' so that createSlice can infer the type of the state
  // the actual initial state will be set in useReducer
  initialState: {} as State,
  reducers: {
    closeConfirmation: () => {
      return {
        status: 'editing',
      } as const;
    },
    invalidInput: (
      state,
      action: PayloadAction<{ fieldErrors: Array<string> | undefined }>,
    ) => {
      if (action.payload.fieldErrors) {
        return {
          status: 'error',
          fieldErrors: action.payload.fieldErrors,
        };
      }

      return state;
    },
    validInput: (
      state,
      action: PayloadAction<{ optimalShareWindow: number }>,
    ) => {
      return {
        status: 'confirmation',
        optimalShareWindow: action.payload.optimalShareWindow,
      };
    },
  },
});

const { closeConfirmation, invalidInput, validInput } = slice.actions;

interface OptimalShareTimeWindowCardProps {
  customShareTimeWindowSettings: {
    intervalSecs?: string;
  };
  propertyId: number;
  propertyName: string;
  propertyURN: string;
}

const OptimalShareTimeWindowCard = ({
  customShareTimeWindowSettings,
  propertyId,
  propertyName,
  propertyURN,
}: OptimalShareTimeWindowCardProps) => {
  const [state, dispatch] = React.useReducer(slice.reducer, initialState);
  const toast = useToast();

  const hasFieldErrors =
    state.status === 'error' &&
    state.fieldErrors != null &&
    state.fieldErrors.length > 0;

  const handleSave: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!(event.target instanceof HTMLFormElement)) {
      return;
    }

    const formData = Object.fromEntries(new FormData(event.target));
    const safeParseResult =
      OptimalShareTimeWindowFormSchema.safeParse(formData);

    if (!safeParseResult.success) {
      dispatch(
        invalidInput({
          fieldErrors:
            safeParseResult.error.flatten().fieldErrors.optimalShareWindow,
        }),
      );
      return;
    }

    dispatch(
      validInput({
        optimalShareWindow: safeParseResult.data.optimalShareWindow,
      }),
    );
  };

  const handleModalClose = () => {
    dispatch(closeConfirmation());
  };

  const handleConfirm = async () => {
    if (state.status === 'confirmation') {
      const userCSToken = authentication.getClientServiceToken();

      try {
        const impersonateCSToken = await postServiceAuth({
          overridePropertyURN: propertyURN,
        });
        authentication.setClientServiceToken(impersonateCSToken);

        await postPropertiesSettings({
          propertyId,
          settingTypeId: ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL,
          enabled: true,
          dataJSON: {
            ...customShareTimeWindowSettings,
            intervalSecs: String(state.optimalShareWindow),
          },
        });

        toast({
          variant: 'success',
          title: 'Settings have been successfully updated',
        });
      } catch (error) {
        console.error(error);
        toast({
          variant: 'chilledError',
          title: 'Something went wrong',
          description:
            'Please try again. If the error persists, contact someone in Tech.',
        });
      } finally {
        authentication.setClientServiceToken(userCSToken);
      }
    }

    dispatch(closeConfirmation());
  };

  return (
    <>
      <Card>
        <Card.Content flexGrow={1} gap={4}>
          <Card.Title as="label" htmlFor="custom-share-time">
            Custom Share Time Window (seconds)
          </Card.Title>
          <form onSubmit={handleSave}>
            <FormControl isInvalid={hasFieldErrors}>
              <HStack>
                <Input
                  type="text"
                  id="custom-share-time"
                  name="optimalShareWindow"
                  defaultValue={customShareTimeWindowSettings.intervalSecs}
                />
                <Button type="submit" loadingText="Saving" flexShrink={0}>
                  Save
                </Button>
              </HStack>
              {hasFieldErrors && (
                <FormControl.FormErrorMessage>
                  {state.fieldErrors[0]}
                </FormControl.FormErrorMessage>
              )}
            </FormControl>
          </form>
        </Card.Content>
      </Card>

      {state.status === 'confirmation' && (
        <Modal isOpen onClose={handleModalClose} size="small">
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Text size="sm" color="gray.600">
              You&apos;re about to update the optimal share time window for
              property{' '}
              <Box as="strong" fontWeight="medium">
                {propertyName}
              </Box>{' '}
              to{' '}
              <Box as="strong" fontWeight="medium">
                {state.optimalShareWindow.toLocaleString()}
              </Box>{' '}
              seconds (i.e.{' '}
              {getFormattedDuration(state.optimalShareWindow.toString())}).
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default OptimalShareTimeWindowCard;
