import postServiceAuth from 'api/postServiceAuth';

export default async function APIimpersonate({
  overrideUsername,
}: {
  overrideUsername: string;
}) {
  /* Impersonate user */
  return postServiceAuth({
    overrideUsername,
  });
}
