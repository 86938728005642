const UsersTeam = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99727 11.75C11.5671 11.75 12.8164 10.4769 12.8164 8.90625C12.8164 7.33562 11.5438 6.0625 9.99727 6.0625C8.42746 6.0625 7.17812 7.33562 7.17812 8.90625C7.15352 10.4758 8.42773 11.75 9.99727 11.75ZM9.99727 7.375C10.8408 7.375 11.5274 8.06187 11.5274 8.90625C11.5274 9.75062 10.8395 10.4375 9.99727 10.4375C9.15508 10.4375 8.46602 9.75117 8.46602 8.90625C8.46602 8.06133 9.15234 7.375 9.99727 7.375ZM15.25 7.375C16.458 7.375 17.4375 6.39555 17.4375 5.1875C17.4375 3.97945 16.4586 3 15.25 3C14.042 3 13.0625 3.97945 13.0625 5.1875C13.0625 6.39555 14.0414 7.375 15.25 7.375ZM11.3645 12.625H8.63555C6.48906 12.625 4.75 14.2574 4.75 16.2699C4.75 16.6746 5.09727 17 5.52656 17H14.4734C14.9027 17 15.25 16.6746 15.25 16.2699C15.25 14.2574 13.5109 12.625 11.3645 12.625ZM6.11992 15.6875C6.40621 14.6823 7.41492 13.9375 8.61203 13.9375H11.3645C12.5613 13.9375 13.57 14.6823 13.8566 15.6875H6.11992ZM16.341 8.25H14.6501C14.3001 8.25 13.9698 8.33304 13.6695 8.47531C13.6859 8.61914 13.7133 8.75859 13.7133 8.90625C13.7133 9.82801 13.3638 10.662 12.8066 11.3125H18.2671C18.534 11.3125 18.75 11.0828 18.75 10.8012C18.75 9.39297 17.6727 8.25 16.341 8.25ZM6.27852 8.90625C6.27852 8.75725 6.30538 8.61558 6.32251 8.47121C6.02422 8.30742 5.69609 8.25 5.34883 8.25H3.65844C2.32844 8.25 1.25 9.39297 1.25 10.8012C1.25 11.0828 1.46566 11.3125 1.7318 11.3125H7.18687C6.62852 10.6617 6.27852 9.82773 6.27852 8.90625ZM4.75 7.375C5.95805 7.375 6.9375 6.39555 6.9375 5.1875C6.9375 3.97945 5.95859 3 4.75 3C3.54195 3 2.5625 3.97945 2.5625 5.1875C2.5625 6.39555 3.54195 7.375 4.75 7.375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UsersTeam;
