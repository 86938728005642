import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

/**
 * Async Function to set preferred MFA method
 * Can also be used to disable MFA by setting it to NOMFA
 * Uses Amplify SDK's setPreferredMFA method
 * @returns {Object} response - success or failure response e.g SUCCESS
 */
export default async function authSetPreferredMFA(
  preferredMFA = mandatory('preferredMFA'),
) {
  const guid = metrics.start('authSetPreferredMFA');
  const user = await Auth.currentAuthenticatedUser();
  const username = authentication.getUsername();
  logger.info(`API:authSetPreferredMFA ${username}`);
  try {
    const response = await Auth.setPreferredMFA(user, preferredMFA);
    metrics.end('authSetPreferredMFA', guid);
    return response;
  } catch (error) {
    metrics.fail('authSetPreferredMFA', guid);
    throw handleCognitoError({
      originalError: error,
      errorLocation: 'api/authSetPreferredMFA',
      args: { username },
    });
  }
}
