import { ChevronButton, Dropdown } from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  DataSourceSettings,
  DescriptionSources,
  ImageSources,
  MessageSources,
  TitleSources,
} from 'types';

type DataSources =
  | MessageSources
  | ImageSources
  | TitleSources
  | DescriptionSources;

interface DataSourceDropdownProps {
  sources: Record<string, string>;
  value: string;
  currentDataSourceSettings: DataSourceSettings;
  handleDataSourceSettingsChange: (newSettings: DataSourceSettings) => void;
  sourceType: keyof DataSourceSettings;
}

function DataSourceDropdown({
  sources,
  value,
  currentDataSourceSettings,
  handleDataSourceSettingsChange,
  sourceType,
}: DataSourceDropdownProps) {
  const handleDropdownChange = (newValue: DataSources) => {
    const newSettings: DataSourceSettings = {
      ...currentDataSourceSettings,
      [sourceType]: newValue,
    };

    handleDataSourceSettingsChange(newSettings);
  };

  return (
    <Dropdown matchWidth>
      <Dropdown.Button
        as={ChevronButton}
        variant="secondary"
        textAlign="left"
        w="244px"
      >
        {sources[value]}
      </Dropdown.Button>
      <Dropdown.List>
        {Object.entries(sources).map(([key, source]) => (
          <Dropdown.Item
            key={source}
            onClick={() => handleDropdownChange(key as DataSources)}
          >
            {source}
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
}

export default DataSourceDropdown;
