interface AddingSponsorProps {
  onClick: () => void;
}

const AddingSponsor = ({ onClick }: AddingSponsorProps) => (
  <div className="in_progress">
    <button
      type="button"
      className="clean_button d-flex align-items-center"
      onClick={onClick}
    >
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path d="M0 3.6L3.6 0 4.9 1.3C5.1 1.6 5.1 2 4.9 2.2L2.2 5C1.9 5.2 1.6 5.2 1.3 5L0 3.6Z" />
        <path d="M16 3.6L12.4 0 11.1 1.3C10.9 1.6 10.9 2 11.1 2.2L13.8 5C14.1 5.2 14.4 5.2 14.7 5L16 3.6Z" />
        <path d="M8.6 3.8L6.2 6.3 6.4 6.6C7 7.2 8 7.2 8.6 6.6L10.1 5 12.4 7.2C12.8 6.7 12.8 6 12.4 5.5L11 4.1 11 4.1 10.7 3.8C10.2 3.2 9.2 3.2 8.6 3.8Z" />
        <path d="M5.8 7.8C6.8 8.8 8.4 8.8 9.3 7.8L10.3 6.8 11.4 7.9C12 8.6 12 9.6 11.4 10.3L10.1 11.5C9.5 12.2 8.5 12.2 7.8 11.5L3.9 7.6C3.3 6.9 3.3 5.9 3.9 5.2L5.1 4C5.7 3.4 6.4 3.3 7.1 3.7L5.4 5.3C4.9 5.9 4.9 6.8 5.4 7.4L5.8 7.8Z" />
      </svg>
    </button>
  </div>
);

export default AddingSponsor;
