/* eslint no-use-before-define:"off" */

import { LOGGLY_SETTINGS } from 'common/config';
import { isNullOrUndefined } from 'common/utility';

export { stringifyKeys, stripKeys };

function stringifyKeys(object) {
  if (isNullOrUndefined(object) || typeof object !== 'object') {
    return object;
  }
  const result = {};
  const strippedObject = stripKeys(object);
  Object.keys(stripKeys(strippedObject)).forEach((key) => {
    const value = strippedObject[key];
    if (typeof value !== 'object') {
      if (key === 'body' || key === 'data') {
        try {
          result[key] = JSON.stringify(stripKeys(JSON.parse(value)));
        } catch (e) {
          result[key] = value;
        }
      } else {
        result[key] = value;
      }
    } else if (LOGGLY_SETTINGS.KEYS_TO_STRINGIFY.indexOf(key) !== -1) {
      result[key] = JSON.stringify(value);
    } else {
      result[key] = stringifyKeys(value);
    }
  });
  return result;
}

function stripKeys(object) {
  if (isNullOrUndefined(object) || typeof object !== 'object') {
    return object;
  }
  const result = {};
  Object.keys(object).forEach((key) => {
    if (typeof object[key] !== 'object') {
      if (
        LOGGLY_SETTINGS.KEYS_TO_EXCLUDE.indexOf(key.toLowerCase()) === -1 &&
        (LOGGLY_SETTINGS.EXCLUDE_IF_EMPTY.indexOf(key) === -1 ||
          object[key] !== '')
      ) {
        result[key] = object[key];
      }
    } else {
      result[key] = stripKeys(object[key]);
    }
  });
  return result;
}
