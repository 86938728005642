/**
 * GET /apis/{apiId}/shorturl
 *
 * @param {longURL} longURL - url to shorten
 * @param {integer} accountAPIId - the accountAPIId linked to the URL to shorten
 * @return {string} - shortened URL
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getURLFromString } from 'common/string';
import { convertToSocialPageURN } from 'common/urn';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({
    shortURL: z.string(),
  })
  .describe('getShortURL.ResponseSchema');

const ErrorResponse = z
  .object({ response: z.object({ status: z.number() }) })
  .describe('getShortURL.ErrorResponse');

export default async function getShortURL({
  longURL,
  accountAPIId,
  socialNetworkType,
}: {
  longURL: string;
  accountAPIId: number;
  socialNetworkType: string;
}) {
  const guid = metrics.start('getShortURL');
  const socialPageURN = convertToSocialPageURN(socialNetworkType, accountAPIId);
  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/shorturl`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
    params: { longURL },
  };
  logger.info(`API:getShortURL /shorturl ${longURL}`);

  try {
    const response = await axios(config);
    const data = lyingParse(ResponseSchema, response.data);
    metrics.end('getShortURL', guid);
    logger.debug('getShortURL', { longURL, accountAPIId });
    return data;
  } catch (error) {
    metrics.fail('getShortURL', guid);
    const errorParseResult = ErrorResponse.safeParse(error);
    if (
      error &&
      errorParseResult.success &&
      errorParseResult.data.response.status === 422 &&
      getURLFromString(longURL)
    ) {
      return {
        shortURL: longURL,
      };
    }
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getShortURL',
      args: { longURL, accountAPIId, socialNetworkType },
    });
    throw apiError;
  }
}
