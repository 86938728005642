import { Box, Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as tracker from 'common/tracker';

interface ConnectTwitterAppSuccessModalProps {
  onClose: () => void;
  onAddTwitterPage: () => void;
}

const ConnectTwitterAppSuccessModal = ({
  onAddTwitterPage,
  onClose,
}: ConnectTwitterAppSuccessModalProps) => {
  const trackAction = (action: string) => {
    tracker.track({
      eventName: 'Action Connect Twitter Success Modal',
      trackingParams: {
        Action: action,
      },
    });
  };

  const handleAddTwitterPage = () => {
    trackAction('Add a Twitter Page');
    onAddTwitterPage();
  };

  const handleClose = () => {
    trackAction('Close');
    onClose();
  };

  const handleDoItLater = () => {
    trackAction("I'll do it later");
    onClose();
  };

  return (
    <Modal isOpen onClose={handleClose} size="small">
      <Modal.Header>
        <Modal.Title>Twitter Project App connected</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm" color="gray.600">
          Your Twitter Project App has been successfully connected.{' '}
          <Box as="strong" fontWeight="medium">
            You can now add Twitter pages to Echobox.
          </Box>
        </Text>
      </Modal.Body>
      <Modal.Footer justifyContent="flex-start">
        <Button onClick={handleAddTwitterPage}>Add a Twitter page</Button>
        <Button variant="secondary" onClick={handleDoItLater}>
          I&apos;ll do it later
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectTwitterAppSuccessModal;
