import {
  Box,
  CashIcon,
  Circle,
  DeleteTrashIcon,
  Dropdown,
  Flex,
  GalleryIcon,
  IconButton,
  Image,
  MoreOptionsIcon,
  OpenInNewIcon,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { memo, useContext } from 'react';

import {
  getAccountAPIPermission,
  getAPITypeId,
  getCurrentAccountAPI,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  ACTION_HISTORY_TYPES,
  API_TYPE_IDS,
  COLLECTION_NAMES,
  PERMISSION_TYPES,
  POST_TYPES,
  REACT_PREVENT_RENDER,
  SHARE_ORIGINS,
  SOCIAL_CHANNELS,
  TEXT_CASES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getFormattedDateFromUnix } from 'common/datetime';
import { getTextDirection } from 'common/language';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import {
  getLastUpdateUnixTime,
  getLastUpdateUser,
  hasLastUpdateDetails,
} from 'common/mediaItem/gettersSetters';
import * as s3 from 'common/s3';
import {
  getFeatureToggle,
  getSetting,
  isIGCabinetEnabled,
} from 'common/settings';
import { replaceShareURL } from 'common/shareURL';
import {
  canDeletePosts,
  canResharePosts,
  canReshareVideos,
} from 'common/social';
import { getNewsFeedVideoThumbnail } from 'common/thumbnails';
import * as tracker from 'common/tracker';
import { isVideoURL } from 'common/url';
import {
  arePropsEqual,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import AnalyticsVideoIcon from 'components/home/analytics/AnalyticsVideoIcon';
import Loading from 'components/home/analytics/Loading';
import Message from 'components/home/analytics/Message';
import LastEditedUser from 'components/home/LastEditedUser';
import TimingBadges from 'components/home/TimingBadges';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { trackOpenArticle } from 'helpers/articleTracking';
import { addImagePrefix } from 'helpers/images';
import { getBoostPostTrackingParams } from 'helpers/tracking';
import useThumbnail from 'hooks/useThumbnail';
import Insights from './Insights';

/**
 * Gets the width and height (in Chakra UI units) for the image.
 */
function getImageDimensions({ mediaItem, accountAPIId }) {
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });

  const isReel = socialChannel === SOCIAL_CHANNELS.REEL;
  const isGraphStory =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    !isIGCabinetEnabled();
  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;

  let width = 16;
  let height = '38px';

  if (isReel || isGraphStory) {
    height = 28;
  }
  if (isTikTok) {
    width = 11;
    height = 20;
  }

  return { width, height };
}

function populateFacebookBoostURL({ mediaItem }) {
  // The socialPageURL takes the following format: https://www.facebook.com/{pageId}_{postId}
  const socialPostURL = MediaItem.getSocialPostURL({ mediaItem });

  if (!socialPostURL) return null;

  // First get the last part of the URL
  const path = socialPostURL.split('/').pop();

  // Then split it by the underscore
  const [pageId, postId] = path.split('_');

  return `https://www.facebook.com/ad_center/create/boostpost/?entry_point=www_profile_plus_timeline&page_id=${pageId}&target_id=${postId}`;
}

const Item = (props) => {
  const globalContext = useContext(GlobalInfoContext).global;
  const videoURL = MediaItem.getVideoURL({ mediaItem: props.mediaItem });
  let imageURLs = MediaItem.getImageURLs({ mediaItem: props.mediaItem });
  const thumbnail = useThumbnail({
    videoURL,
    shouldFetch:
      imageURLs == null ||
      imageURLs.length === 0 ||
      !s3.isEbxImage(imageURLs[0]),
  });
  if (imageURLs && thumbnail) {
    imageURLs = [thumbnail];
  }
  /**
   * Event handlers
   */

  const handleArticleDelete = () => {
    props.eventHandlers.handleArticleDelete(props.mediaItem);
  };

  const handleArticleReshare = () => {
    logger.info('Item:handleArticleReshare');

    props.eventHandlers.handleArticleReshare({
      mediaId: MediaItem.getMediaId({ mediaItem: props.mediaItem }),
      sourceCollection: COLLECTION_NAMES.ANALYTICS_PAGE,
      shareOrigin: SHARE_ORIGINS.ANALYTICS_RESHARE,
      thumbnail,
    });
  };

  const handleShowABResults = () => {
    logger.info('Item:handleHideShowABResults');

    props.eventHandlers.handleShowABResults(props.mediaItem);
  };

  /**
   * Helper methods
   */

  const getImageURL = (mediaItem) => {
    const abResults = MediaItem.getABResults({ mediaItem });
    const abVariation = MediaItem.getCurrentABVariationIndex({
      mediaItem,
    });
    if (abResults.length === 0) {
      return s3.convertEchoboxImageToAmazon(imageURLs[0]);
    }
    return s3.convertEchoboxImageToAmazon(abResults[abVariation].imageURL);
  };

  const getMessageText = (mediaItem) => {
    const abResults = MediaItem.getABResults({ mediaItem });
    const abVariation = MediaItem.getCurrentABVariationIndex({
      mediaItem,
    });
    let messageText;
    if (abResults.length === 0) {
      messageText = MediaItem.getPreviewField({
        mediaItem,
        fieldName: 'message',
      });
    } else {
      messageText = abResults[abVariation].message;
    }

    const shareURL = MediaItem.getShareURL({ mediaItem });
    return replaceShareURL({ text: messageText, shareURL });
  };

  /**
   * Render methods
   */

  const renderImage = ({ apiTypeId, isVideoLink }) => {
    const { mediaItem } = props;
    const imageURL = getImageURL(mediaItem);
    const postType = MediaItem.getPostType({ mediaItem });

    const isCarousel =
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      !isIGCabinetEnabled() &&
      MediaItem.getImageURLs({ mediaItem }).length > 1;

    const videoThumbnail = getNewsFeedVideoThumbnail();

    const { width, height } = getImageDimensions({
      mediaItem,
      accountAPIId,
    });

    return (
      <div className="share_image_info">
        {isVideoLink && <AnalyticsVideoIcon apiTypeId={apiTypeId} />}
        {isCarousel && (
          <Circle
            position="absolute"
            inset={0}
            bg="gray.300-60"
            size={6}
            margin="auto"
          >
            <GalleryIcon color="gray.900" />
          </Circle>
        )}
        <Box
          w={width}
          h={height}
          bgPos="center"
          bgSize="cover"
          boxSizing="border-box"
          borderRadius="3px"
          backgroundRepeat="no-repeat"
          // A workaround because of a Chakra UI bug that sometimes modifies
          // the backgroundImage prop to an incorrect value.
          // See https://github.com/chakra-ui/chakra-ui/issues/7548
          // See https://echobox.atlassian.net/browse/HELP-5417
          style={{
            backgroundImage: `url('${addImagePrefix(imageURL)}')`,
          }}
          position="relative"
        >
          {postType === POST_TYPES.VIDEO && imageURL !== videoThumbnail && (
            <Flex
              pos="absolute"
              bottom={0}
              right={0}
              w="full"
              h="full"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                borderRadius="full"
                bg="rgba(235, 238, 245, 0.35)"
                p={1}
                src="/img/icons/video-icon.svg"
                alt=""
                h={6}
                w={6}
              />
            </Flex>
          )}
        </Box>
      </div>
    );
  };

  const renderShared = (
    sharedTime,
    twelveHourFormat,
    socialPostURL,
    className,
  ) => {
    const renderProperly = true;
    if (!renderProperly) {
      return <div>SHARED</div>;
    }

    return (
      <div className={className}>
        <a
          href={socialPostURL}
          className="share_time link_grey"
          target="_blank"
          rel="noopener noreferrer"
        >
          Shared{' '}
          {getFormattedDateFromUnix({
            timestamp: sharedTime,
            twelveHourFormat,
            textCase: TEXT_CASES.LOWER,
          })}
        </a>
      </div>
    );
  };

  const renderTitle = (articleTitle, articleURL, accountAPIId, mediaId) => {
    const apiTypeId = getAPITypeId({ accountAPIId });

    if (articleTitle === '' || apiTypeId === API_TYPE_IDS.INSTAGRAM) {
      return REACT_PREVENT_RENDER;
    }

    const guid = `article-title-${mediaId}`;
    const textDirectionClass = getTextDirection({
      accountAPIId,
      text: articleTitle,
    });

    return (
      <Tooltip id={guid} label={articleTitle}>
        <a
          href={articleURL}
          className={`link_grey article_title overflow_ellipsis ${textDirectionClass}`}
          target="_blank"
          rel="noopener noreferrer"
          data-tip
          data-for={guid}
          data-cy-id="title"
          data-iscapture="true"
          onClick={() => trackOpenArticle(articleURL, 'Analytics')}
        >
          {articleTitle}
        </a>
      </Tooltip>
    );
  };

  const mediaItem = props.mediaItem;
  const accountAPIId = props.accountAPIId;
  const apiTypeId = getAPITypeId({ accountAPIId });
  const mediaId = MediaItem.getMediaId({ mediaItem });

  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;

  const isLoading = MediaItem.getIsLoading({ mediaItem });

  if (isLoading) {
    return (
      <Loading
        apiTypeId={apiTypeId}
        mediaId={mediaId}
        insightColumns={props.displayOptions.insightColumns}
      />
    );
  }

  const globalInfo = globalContext.getGlobalInfo();

  const permissionTypeId = getAccountAPIPermission({
    accountAPIId: getCurrentAccountAPI({
      globalInfo,
    }),
    globalInfo,
  });

  const abInfo = MediaItem.getABInfo({ mediaItem });
  const abResults = MediaItem.getABResults({ mediaItem });
  const abVariations = MediaItem.getABVariations({ mediaItem });
  const abTestStatusId = MediaItem.getABTestStatusId({ mediaItem });
  const actionTypeId = MediaItem.getActionTypeId({ mediaItem });
  const articleTitle = MediaItem.getRssTitle({ mediaItem });
  const isSaving = MediaItem.getIsSaving({ mediaItem });
  const labels = MediaItem.getMediaItemTags({ mediaItem });
  const messageText = getMessageText(mediaItem);
  const itemTags = MediaItem.getTags({ mediaItem });
  const totalNumberOfShares = MediaItem.getTotalNumberOfShares({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const shareTime = MediaItem.getShareTime({ mediaItem });
  const sharedTime = MediaItem.getUnixTimeShared({ mediaItem });
  const shareURL = MediaItem.getShareURL({ mediaItem });
  const socialPostURL = MediaItem.getSocialPostURL({ mediaItem });
  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });
  const timeSensitivityTypeId = MediaItem.getTimeSensitivityTypeId({
    mediaItem,
  });
  const unshortenedShareURL = MediaItem.getUnshortenedShareURL({
    mediaItem,
  });

  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getCurrentPropertyId(),
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;

  const hasImage =
    (!isNullOrUndefined(imageURLs) && imageURLs.length > 0) ||
    postType === POST_TYPES.VIDEO;
  const insights = JSON.parse(
    JSON.stringify(MediaItem.getInsights({ mediaItem })),
  );
  if (!isNullOrUndefined(insights.ctr)) {
    insights.ctr = `${(insights.ctr * 100).toFixed(1)}%`;
  }

  const isUserManagementEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
  let lastUser;
  let lastEditedtime;
  if (isUserManagementEnabled && hasLastUpdateDetails({ mediaItem })) {
    lastUser = getLastUpdateUser({ mediaItem });
    lastEditedtime = getFormattedDateFromUnix({
      timestamp: getLastUpdateUnixTime({ mediaItem }),
      twelveHourFormat,
      textCase: TEXT_CASES.LOWER,
      useLongForm: true,
    });
  }

  let articleURL;
  if (!isNullOrUndefined(shareURL)) {
    articleURL = shareURL;
  } else {
    articleURL = unshortenedShareURL;
  }
  if (isUndefined(articleURL)) {
    articleURL = '';
  }

  const isVideoLink = isVideoURL(articleURL);

  let articleClass = '';
  if (isSaving) {
    articleClass += 'saving_locked';
  }
  const colSpan = apiTypeId === API_TYPE_IDS.TWITTER ? 3 : 5;

  const hasPermissions = permissionTypeId !== PERMISSION_TYPES.NONE;
  const canBoostPost = apiTypeId === API_TYPE_IDS.FACEBOOK;
  const boostLink = canBoostPost
    ? populateFacebookBoostURL({ mediaItem })
    : null;
  const canDeletePost = canDeletePosts({ apiTypeId });
  const canResharePost =
    postType === POST_TYPES.VIDEO
      ? canReshareVideos({ apiTypeId })
      : canResharePosts({ apiTypeId });

  const timingBadges = (
    <TimingBadges
      accountAPIId={props.accountAPIId}
      origin="ANALYTICS_PAGE"
      shareTime={shareTime}
      postType={postType}
      twelveHourFormat={twelveHourFormat}
      timeSensitivityTypeId={timeSensitivityTypeId}
      suggestionTypeId={suggestionTypeId}
      actionTypeId={actionTypeId}
      totalNumberOfShares={totalNumberOfShares}
      hasImage={hasImage}
      socialChannel={socialChannel}
      showPostType={true}
      showReshare={true}
      showTimingOption={true}
      showTimeSensitivity={true}
      mediaId={mediaId}
    />
  );

  const isFacebookNativeReelShare =
    socialChannel === SOCIAL_CHANNELS.REEL &&
    apiTypeId === API_TYPE_IDS.FACEBOOK &&
    actionTypeId !== ACTION_HISTORY_TYPES.ECHOBOX_SHARE;

  logger.info(`Item:render - ${mediaId}`);

  return (
    <tbody
      className={`article ${articleClass}`}
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <tr
        className="first-row"
        data-tip
        data-for={mediaId}
        data-cy-attribute="isLoading:false"
      >
        <td className="position-relative share_image_td">
          <Flex className="share_content">
            <div className="d-none" data-cy-id="guid">
              {labels[0]?.tag}
            </div>
            {renderImage({ apiTypeId, isVideoLink })}
            <VStack
              alignItems="baseline"
              minH={isTikTok ? 20 : 7}
              justifyContent="space-between"
              flexGrow={1}
              minW={0}
            >
              <Box width="full">
                {!isTikTok && (
                  <div className="middle d-none d-md-flex align-items-baseline">
                    <div className="d-flex mr-1 align-items-center">
                      {timingBadges}
                    </div>
                    {renderTitle(
                      articleTitle,
                      articleURL,
                      accountAPIId,
                      mediaId,
                    )}
                  </div>
                )}
                <div className="d-none d-md-flex">
                  <Message
                    abResults={abResults}
                    abTestStatusId={abTestStatusId}
                    abVariations={abVariations}
                    accountAPIId={accountAPIId}
                    actionTypeId={actionTypeId}
                    isABVariation={abInfo.isABVariation}
                    messageText={messageText}
                    postType={postType}
                    tags={itemTags}
                  />
                </div>
              </Box>
              <div className="bottom w-100 align-items-end d-none d-md-flex mt-1">
                <div className="mr-auto d-flex">
                  {isUserManagementEnabled &&
                    hasLastUpdateDetails({ mediaItem }) && (
                      <LastEditedUser
                        className="d-flex align-items-center"
                        lastUser={lastUser}
                        lastEditedTime={lastEditedtime}
                        mediaId={mediaId}
                      />
                    )}
                  {renderShared(
                    sharedTime,
                    twelveHourFormat,
                    socialPostURL,
                    'float-left',
                  )}
                </div>
                <Flex alignItems="center">
                  {hasPermissions && (canDeletePost || canBoostPost) && (
                    <Dropdown size="md" placement="bottom-end">
                      <Dropdown.Button
                        as={IconButton}
                        icon={<MoreOptionsIcon />}
                        aria-label="Options"
                      />
                      <Dropdown.List>
                        {canBoostPost && boostLink && (
                          <Dropdown.Item
                            as="a"
                            href={boostLink}
                            target="_blank"
                            onClick={() =>
                              tracker.track({
                                eventName: 'Boost Post',
                                trackingParams: getBoostPostTrackingParams({
                                  mediaItem,
                                }),
                              })
                            }
                            LeftIcon={CashIcon}
                            RightIcon={OpenInNewIcon}
                          >
                            Boost share
                          </Dropdown.Item>
                        )}
                        {canDeletePost && (
                          <Dropdown.Item
                            color="red.600"
                            onClick={handleArticleDelete}
                            LeftIcon={DeleteTrashIcon}
                          >
                            Delete share
                          </Dropdown.Item>
                        )}
                      </Dropdown.List>
                    </Dropdown>
                  )}
                  {abInfo.isABVariation && (
                    <Button className="ml-2" onClick={handleShowABResults}>
                      AB Results
                    </Button>
                  )}
                  {hasPermissions &&
                    canResharePost &&
                    !isFacebookNativeReelShare && (
                      <Button
                        className="ml-2"
                        data-cy-action="reshareAnalytics"
                        onClick={handleArticleReshare}
                      >
                        Reshare
                      </Button>
                    )}
                </Flex>
              </div>
            </VStack>
          </Flex>
        </td>
        <Insights
          apiTypeId={apiTypeId}
          cellId={mediaId}
          displayOptions={props.displayOptions}
          insightData={insights}
        />
      </tr>
      <tr
        className="border-bottom"
        data-cy-attribute="isLoading:false"
        data-tip
      >
        <td colSpan={colSpan} className="p-2 w-100 d-table-cell d-md-none">
          <div className="share_message lh-20">
            <div className="middle">
              <span className="d-flex mr-1 align-items-center flex-wrap">
                {timingBadges}
              </span>
              {renderTitle(articleTitle, articleURL, accountAPIId, mediaId)}
            </div>
            <Message
              abResults={abResults}
              abTestStatusId={abTestStatusId}
              abVariations={abVariations}
              accountAPIId={accountAPIId}
              actionTypeId={actionTypeId}
              isABVariation={abInfo.isABVariation}
              messageText={messageText}
              postType={postType}
              tags={itemTags}
            />
          </div>
          <div className="clearfix" />
          <div className="bottom align-items-center d-flex flex-wrap">
            <div className="d-flex mt-2 align-items-center">
              {isUserManagementEnabled &&
                hasLastUpdateDetails({ mediaItem }) && (
                  <LastEditedUser
                    lastUser={lastUser}
                    lastEditedTime={lastEditedtime}
                    className="d-flex align-items-center"
                    mediaId={mediaId}
                  />
                )}
              {renderShared(sharedTime, twelveHourFormat, socialPostURL, '')}
            </div>
            <div className="d-flex ml-auto mt-2 align-items-center">
              {hasPermissions && canDeletePost && (
                <a
                  className="delete align-items-center btn btn-secondary"
                  data-cy-action="deleteNewFeed"
                  onClick={handleArticleDelete}
                >
                  <span>Delete Post</span>
                </a>
              )}
              {abInfo.isABVariation && (
                <Button className="ml-2" onClick={handleShowABResults}>
                  AB Results
                </Button>
              )}
              {hasPermissions &&
                canResharePost &&
                !isFacebookNativeReelShare && (
                  <Button
                    className="reshare-button ml-2"
                    data-cy-action="reshareAnalytics"
                    onClick={handleArticleReshare}
                  >
                    Reshare
                  </Button>
                )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

Item.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  mediaItem: PropTypes.object.isRequired,
  accountAPIId: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
    handleArticleReshare: PropTypes.func.isRequired,
    handleShowABResults: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(Item, arePropsEqual);
