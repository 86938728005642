const UserProfile = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 5.78125C8.57617 5.78125 7.42188 6.93555 7.42188 8.35938C7.42188 9.7832 8.57617 10.9375 10 10.9375C11.4238 10.9375 12.5781 9.7832 12.5781 8.35938C12.5781 6.93555 11.4238 5.78125 10 5.78125ZM10 9.53125C9.35371 9.53125 8.82812 9.00537 8.82812 8.35938C8.82812 7.71191 9.35254 7.1875 10 7.1875C10.6475 7.1875 11.1719 7.71309 11.1719 8.35938C11.1719 9.00684 10.6475 9.53125 10 9.53125ZM10 2.5C5.85742 2.5 2.5 5.85742 2.5 10C2.5 14.1426 5.85742 17.5 10 17.5C14.1426 17.5 17.5 14.1426 17.5 10C17.5 5.85742 14.1426 2.5 10 2.5ZM10 16.0938C8.63096 16.0938 7.37031 15.6344 6.35254 14.8694C6.85938 13.8965 7.84375 13.2812 8.95117 13.2812H11.0515C12.1574 13.2812 13.1406 13.8968 13.6495 14.8694C12.6309 15.6338 11.3682 16.0938 10 16.0938ZM14.6934 13.8818C13.9023 12.6455 12.5547 11.875 11.0488 11.875H8.95117C7.44648 11.875 6.09912 12.644 5.30664 13.8813C4.43242 12.8271 3.90625 11.4736 3.90625 10C3.90625 6.63965 6.63994 3.90625 10 3.90625C13.3601 3.90625 16.0938 6.63994 16.0938 10C16.0938 11.4736 15.5664 12.8271 14.6934 13.8818Z"
      fill="currentColor"
    />
  </svg>
);

export default UserProfile;
