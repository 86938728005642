import { memo } from 'react';

import { arePropsEqual } from 'common/utility';
import SocialPagesHeaderItem from 'components/compose/socialpages/SocialPagesHeaderItem';

/**
 * Internal components
 */

interface SocialPagesHeaderWrapperProps {
  children: React.ReactNode;
}

const SocialPagesHeaderWrapper = ({
  children,
}: SocialPagesHeaderWrapperProps) => (
  <div
    className="btn btn-light text-left dropdown-select-pages d-flex flex-wrap flex-md-nowrap"
    id="socialPagesDropdown"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    data-cy-id="socialPagesHeader"
  >
    {children}
  </div>
);

export interface SocialPagesHeaderProps {
  shareToAPIs?: ReadonlyArray<number>;
}

/**
 * Exported component
 */

const SocialPagesHeader = ({ shareToAPIs = [] }: SocialPagesHeaderProps) => {
  if (shareToAPIs.length === 0) {
    return (
      <SocialPagesHeaderWrapper>
        <span className="mr-1 mb-1 d-flex align-items-center selected-pages">
          Please select a page to share this item to
        </span>
      </SocialPagesHeaderWrapper>
    );
  }

  return (
    <SocialPagesHeaderWrapper>
      <SocialPagesHeaderItem accountAPIId={shareToAPIs[0]} />
      {shareToAPIs.length > 1 && (
        <SocialPagesHeaderItem accountAPIId={shareToAPIs[1]} />
      )}
      {shareToAPIs.length > 2 && (
        <span className="bg-light-blue mr-1 mb-1 d-flex align-items-center selected-pages">
          +{shareToAPIs.length - 2}
        </span>
      )}
    </SocialPagesHeaderWrapper>
  );
};

export default memo(SocialPagesHeader, arePropsEqual);
