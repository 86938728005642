import Tooltip from 'components/misc/Tooltip';
import {
  MessageBoxState,
  useMessageBoxContext,
} from 'context/MessageBoxContext';

interface AudienceRestrictionActiveProps {
  onClick: () => void;
}

const AudienceRestrictionActive = ({
  onClick,
}: AudienceRestrictionActiveProps) => {
  const { currentUserInputType } = useMessageBoxContext();
  const isTooltipDisabled =
    currentUserInputType === MessageBoxState.ADDITIONAL_OPTIONS_MENU_OPEN;

  return (
    <div className="enabled">
      <Tooltip
        id="people-icon"
        label="Audience restriction is on"
        isDisabled={isTooltipDisabled}
      >
        <button
          type="button"
          className="clean_button d-flex align-items-center"
          data-cy-id="audienceRestriction"
          onClick={onClick}
          data-for="people-icon"
          data-tip
          data-iscapture="true"
          aria-label="Audience restriction is on"
        >
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
          >
            <path d="M10.7655 4.85434C12.086 4.85434 13.144 3.80744 13.144 2.51054C13.144 1.21364 12.086 0.166748 10.7655 0.166748C9.44507 0.166748 8.37914 1.21364 8.37914 2.51054C8.37914 3.80744 9.44507 4.85434 10.7655 4.85434ZM4.40179 4.85434C5.72227 4.85434 6.78024 3.80744 6.78024 2.51054C6.78024 1.21364 5.72227 0.166748 4.40179 0.166748C3.08131 0.166748 2.01538 1.21364 2.01538 2.51054C2.01538 3.80744 3.08131 4.85434 4.40179 4.85434ZM4.40179 6.41687C2.54834 6.41687 -1.1665 7.33095 -1.1665 9.1513V11.1045H9.97008V9.1513C9.97008 7.33095 6.25523 6.41687 4.40179 6.41687ZM10.7655 6.41687C10.5349 6.41687 10.2724 6.4325 9.99394 6.45593C10.9167 7.1122 11.561 7.99503 11.561 9.1513V11.1045H16.3338V9.1513C16.3338 7.33095 12.619 6.41687 10.7655 6.41687Z" />
          </svg>
        </button>
      </Tooltip>
    </div>
  );
};

export default AudienceRestrictionActive;
