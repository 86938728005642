import {
  HStack,
  IgPostIcon,
  ReelIcon,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { SOCIAL_CHANNELS } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { FixTypeLater } from 'types';

interface ShareTypeProps {
  mediaItem: FixTypeLater;
}

const shareTypes = {
  [SOCIAL_CHANNELS.FEED]: {
    Icon: IgPostIcon,
    text: 'Post',
  },
  [SOCIAL_CHANNELS.REEL]: {
    Icon: ReelIcon,
    text: 'Reel',
  },
};

const ShareType = ({ mediaItem }: ShareTypeProps) => {
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const shareType = shareTypes[socialChannel as keyof typeof shareTypes];

  // this is in case the backend is returning a social channel that we don't have a share type for
  if (!shareType) {
    return null;
  }

  const { Icon, text } = shareType;

  return (
    <VStack alignItems="flex-start" spacing="3px" mb="31px">
      <Text size="xs" color="#a3acb9">
        Share Type
      </Text>
      <HStack
        spacing={1}
        py="7px"
        px="2"
        bg="#f2f5f7"
        borderRadius="3px"
        boxShadow="inset 0px 0px 0px 1px #e3e8ee"
        w="full"
      >
        <>
          <Icon size="5" color="#697386" />
          <Text size="xs" color="#697386" fontWeight="500">
            {text}
          </Text>
        </>
      </HStack>
    </VStack>
  );
};

export default ShareType;
