import {
  Box,
  Button,
  Checkbox,
  Circle,
  Flex,
  Heading,
  Link,
  Modal,
  Radio,
  RadioGroup,
  RssFeedIcon,
  SitemapIcon,
  Tag,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useState } from 'react';

import deletePropertiesSynd from 'api/deletePropertiesSynd';
import postAPIsSettingsType from 'api/postAPIsSettingsType';
import postPropertiesSynd from 'api/postPropertiesSynd';
import {
  getCurrentProperty,
  getCurrentPropertyId,
  getFacebookAPIs,
  getInstagramAPIs,
  getLinkedInAPIs,
  getPropertyIdForAccountAPIId,
  getTwitterAPIs,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  GLOBAL_INFO_STATES,
  MIXPANEL_ORIGIN,
  ONBOARDING_FEED_SUPPORTED_SOCIAL_PAGES,
  PROPERTY_STATES,
} from 'common/constants';
import { getCurrentPropertyState } from 'common/currentPropertyAndAPI';
import { getErrorMessage } from 'common/errorHandling';
import { isRSSFeed as getIsRSSFeed } from 'common/feed';
import { getGlobalInfo } from 'common/globalInfo';
import { addErrorNotification } from 'common/notifications';
import { getSetting } from 'common/settings';
import * as tracker from 'common/tracker';
import GlobalInfoContext from 'context/GlobalInfoContext';
import type { FixTypeLater, RssFeedData, SocialAPI } from 'types';

import DeleteFeedConfirmationModal from './DeleteFeedConfirmationModal';

enum SELECTED_PAGES_OPTIONS {
  ALL = 'ALL',
  SELECTED = 'SELECTED',
}

interface SocialPagesProps {
  socialType: string;
  accountAPIs: Array<SocialAPI>;
  selectedSocialPages: Set<number>;
  setSelectedSocialPages: (SocialPages: Set<number>) => void;
}

const renderPages = ({
  socialType,
  accountAPIs,
  selectedSocialPages,
  setSelectedSocialPages,
}: SocialPagesProps) => {
  const handleSelect = (accountAPIId: number) => {
    let newSet;
    if (selectedSocialPages.has(accountAPIId)) {
      selectedSocialPages.delete(accountAPIId);
      newSet = new Set(selectedSocialPages);
    } else {
      newSet = new Set(selectedSocialPages.add(accountAPIId));
    }
    setSelectedSocialPages(newSet);
  };

  return (
    <Flex flexDir="column" gap={3}>
      <Heading variant="h5">{socialType}</Heading>
      {accountAPIs.map(
        ({ accountAPIId, apiPostName, apiPostImage, requiresReconnect }) => (
          <Checkbox
            key={accountAPIId}
            name={apiPostName}
            value={accountAPIId}
            id={apiPostName}
            isChecked={selectedSocialPages.has(accountAPIId)}
            onChange={() => handleSelect(accountAPIId)}
            isDisabled={requiresReconnect}
          >
            <Flex alignItems="center" gap={2} as="span">
              <Circle
                as="img"
                bg="white"
                border="1px solid #dbe3f0"
                size={6}
                src={apiPostImage}
                alt=""
              />
              <Text
                size="sm"
                as="span"
                color={requiresReconnect ? 'gray.500' : 'gray.900'}
                textDecorationLine={requiresReconnect ? 'line-through' : 'none'}
              >
                {apiPostName}
              </Text>
              {requiresReconnect && <Tag color="gray">Disconnected</Tag>}
            </Flex>
          </Checkbox>
        ),
      )}
    </Flex>
  );
};

const getSyndSetting = (apiId: number): string[] => {
  const setting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
    propertyId: getPropertyIdForAccountAPIId({
      accountAPIId: apiId,
      globalInfo: getGlobalInfo(),
    }),
    accountAPIId: apiId,
    settingIndex: 0,
  });

  return setting.dataJSON ?? [];
};

interface ConnectRSSFeedToSocialPagesProps {
  onDismiss: () => void;
  rssFeedData: RssFeedData;
  isAdd: boolean;
}

const ConnectRSSFeedToSocialPages = ({
  onDismiss,
  rssFeedData,
  isAdd,
}: ConnectRSSFeedToSocialPagesProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingDeleteConfirmation, setIsShowingDeleteConfirmation] =
    useState(false);

  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();

  const { accountAPIs } = getCurrentProperty({ globalInfo });
  const facebookAPIs: SocialAPI[] = getFacebookAPIs({ accountAPIs });
  const twitterAPIs: SocialAPI[] = getTwitterAPIs({ accountAPIs });
  const linkedInAPIs: SocialAPI[] = getLinkedInAPIs({ accountAPIs });
  const instagramAPIs: SocialAPI[] = getInstagramAPIs({ accountAPIs });

  const { syndFeedType, syndFeedURN, syndFeedURL } = rssFeedData;
  const isRSSFeed = getIsRSSFeed(syndFeedType);
  const allAPIs = [facebookAPIs, twitterAPIs, linkedInAPIs, instagramAPIs];
  const initSelected = allAPIs
    .flatMap((apis) => apis.map((api) => api.accountAPIId))
    .filter((api) => {
      const rssFeeds = getSyndSetting(api);
      const index = rssFeeds.indexOf(syndFeedURN);
      return index !== -1;
    });
  const [selectedSocialPages, setSelectedSocialPages] = useState(
    new Set(initSelected),
  );
  const getInitialIsSelectAllPages = () => {
    const allConnectedSocialPages = Object.keys(accountAPIs).filter(
      (accountAPIId) =>
        ONBOARDING_FEED_SUPPORTED_SOCIAL_PAGES.includes(
          accountAPIs[accountAPIId].apiTypeId,
        ),
    );

    return (
      selectedSocialPages.size === 0 ||
      selectedSocialPages.size === allConnectedSocialPages.length
    );
  };
  const [isSelectAllPages, setIsSelectAllPages] = useState(
    getInitialIsSelectAllPages,
  );

  const isUnderSetup = getCurrentPropertyState() === PROPERTY_STATES.NEWSIGNUP;

  const handleSelectedPagesChange = (newValue: string) => {
    if (newValue === SELECTED_PAGES_OPTIONS.ALL) {
      setIsSelectAllPages(true);
    } else {
      setIsSelectAllPages(false);
    }
  };

  const handleRemoveFeed = async () => {
    try {
      const allPromises = allAPIs
        .flatMap((apis) => apis.map((api) => api.accountAPIId))
        .map((api) => {
          const rssFeeds = getSyndSetting(api);
          const index = rssFeeds.indexOf(syndFeedURN);
          if (index === -1) {
            return Promise.resolve();
          }
          rssFeeds.splice(index, 1);
          return postAPIsSettingsType({
            accountAPIId: api,
            settingTypeId:
              ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
            enabled: true,
            dataJSON: rssFeeds,
          });
        });

      await Promise.all(allPromises);
      await deletePropertiesSynd({
        propertyId: getCurrentPropertyId(),
        syndFeedURN,
      });

      await refreshGlobalInfo('RSS Feed removed');
      tracker.track({
        eventName: 'Delete Syndfeed',
        trackingParams: {
          'Syndfeed URL': syndFeedURL,
          Origin: isUnderSetup
            ? MIXPANEL_ORIGIN.SETUP
            : MIXPANEL_ORIGIN.SETTINGS,
        },
      });
      onDismiss();
    } catch (error) {
      const newErrorMessage = getErrorMessage(error);
      addErrorNotification(newErrorMessage);
      onDismiss();
    }
  };

  const handleSaveFeed = async () => {
    try {
      setIsLoading(true);
      const allAccountAPIIds = allAPIs.flatMap((apis) =>
        apis.map((api) => api.accountAPIId),
      );
      const selectedPages = isSelectAllPages
        ? allAccountAPIIds
        : Array.from(selectedSocialPages);

      if (isAdd) {
        // Add RSS feed to property
        await postPropertiesSynd({
          propertyId: getCurrentPropertyId(),
          feedURL: syndFeedURL,
        });
      }

      // Specify set of pages if required
      const allUpdates = allAccountAPIIds.reduce<Promise<FixTypeLater>[]>(
        (prev, apiId) => {
          const previousRSSFeeds = getSyndSetting(apiId);

          const isFeedAdded =
            selectedPages.includes(apiId) &&
            !previousRSSFeeds.includes(syndFeedURN);
          const isFeedRemoved =
            !selectedPages.includes(apiId) &&
            previousRSSFeeds.includes(syndFeedURN);

          if (isFeedAdded || isFeedRemoved) {
            const rssFeeds = isFeedAdded
              ? [...previousRSSFeeds, syndFeedURN]
              : previousRSSFeeds.filter((urn) => urn !== syndFeedURN);

            const updatePromise = postAPIsSettingsType({
              accountAPIId: apiId,
              settingTypeId:
                ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
              enabled: true,
              dataJSON: rssFeeds,
            });
            return [...prev, updatePromise];
          }

          return prev;
        },
        [],
      );

      if (allUpdates.length > 0) {
        await Promise.all(allUpdates);
        await refreshGlobalInfo('RSS Feed Saved');
      }
      setIsLoading(false);
      if (!isAdd) {
        tracker.track({
          eventName: 'Edit Syndfeed',
          trackingParams: {
            'Syndfeed URL': rssFeedData.syndFeedURL,
            Origin: isUnderSetup
              ? MIXPANEL_ORIGIN.SETUP
              : MIXPANEL_ORIGIN.SETTINGS,
          },
        });
      }
      onDismiss();
    } catch (error) {
      const newErrorMessage = getErrorMessage(error);
      addErrorNotification(newErrorMessage);
      setIsLoading(false);
    }
  };

  const refreshGlobalInfo = (successMessage: string) => {
    return new Promise((resolve, reject) => {
      try {
        global.refreshGlobalInfo({
          allowUnderSetup: true,
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          successMessage,
          callback: () => {
            resolve('Global Info Refreshed');
          },
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <>
      <Modal isOpen onClose={onDismiss}>
        <Modal.Header data-cy-id="edit-modal-header">
          <Modal.Title lineHeight="6">
            {isAdd ? 'Add' : 'Edit'} {isRSSFeed ? 'RSS feed' : 'Sitemap'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Flex
            border="1px"
            borderColor="gray.300"
            borderRadius="lg"
            p={5}
            gap={3}
            marginBottom={6}
          >
            <Box color="gray.600">
              {isRSSFeed ? <RssFeedIcon size={5} /> : <SitemapIcon size={5} />}
            </Box>
            <Flex flexDirection="column" gap={1}>
              {rssFeedData.syndFeedTitle && (
                <Heading variant="h4">{rssFeedData.syndFeedTitle}</Heading>
              )}
              <Text
                as={Link}
                href={rssFeedData.syndFeedURL}
                size="sm"
                wordBreak="break-word"
                color="gray.600"
                _hover={{ textDecoration: 'underline' }}
              >
                {rssFeedData.syndFeedURL}
              </Text>
            </Flex>
          </Flex>
          <Heading variant="h5" mb={3}>
            Connect this content feed to
          </Heading>

          <RadioGroup
            onChange={handleSelectedPagesChange}
            value={
              isSelectAllPages
                ? SELECTED_PAGES_OPTIONS.ALL
                : SELECTED_PAGES_OPTIONS.SELECTED
            }
          >
            <Flex align="flex-start" gap={3} flexDir="column">
              <Radio value={SELECTED_PAGES_OPTIONS.ALL} id="all-pages">
                All social pages
              </Radio>
              <Radio
                value={SELECTED_PAGES_OPTIONS.SELECTED}
                id="selected-pages"
              >
                Selected social pages
              </Radio>
            </Flex>
          </RadioGroup>
          {!isSelectAllPages && (
            <Flex pl={6} pt={4} flexDir="column" gap={8}>
              {facebookAPIs.length > 0 &&
                renderPages({
                  socialType: 'Facebook',
                  accountAPIs: facebookAPIs,
                  selectedSocialPages,
                  setSelectedSocialPages,
                })}
              {twitterAPIs.length > 0 &&
                renderPages({
                  socialType: 'Twitter',
                  accountAPIs: twitterAPIs,
                  selectedSocialPages,
                  setSelectedSocialPages,
                })}
              {linkedInAPIs.length > 0 &&
                renderPages({
                  socialType: 'LinkedIn',
                  accountAPIs: linkedInAPIs,
                  selectedSocialPages,
                  setSelectedSocialPages,
                })}
              {instagramAPIs.length > 0 &&
                renderPages({
                  socialType: 'Instagram',
                  accountAPIs: instagramAPIs,
                  selectedSocialPages,
                  setSelectedSocialPages,
                })}
            </Flex>
          )}
        </Modal.Body>
        <Modal.Footer data-cy-id="setupModalFooter">
          {!isAdd && (
            <Button
              color="red.600"
              data-dismiss="modal"
              className="mr-auto"
              type="reset"
              data-cy-action="removeFeed"
              onClick={() => setIsShowingDeleteConfirmation(true)}
              isDisabled={isLoading}
              variant="tertiary"
            >
              Remove
            </Button>
          )}
          <Button
            variant="secondary"
            data-dismiss="modal"
            data-cy-action="closePagePopup"
            onClick={onDismiss}
            isDisabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveFeed}
            data-cy-action="saveFeedSettings"
            isDisabled={
              isLoading || (!isSelectAllPages && selectedSocialPages.size === 0)
            }
            isLoading={isLoading}
            loadingText="Saving"
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>

      {isShowingDeleteConfirmation && (
        <DeleteFeedConfirmationModal
          feedTitle={rssFeedData.syndFeedTitle ?? undefined}
          feedURL={rssFeedData.syndFeedURL}
          onCancel={() => setIsShowingDeleteConfirmation(false)}
          onDelete={handleRemoveFeed}
        />
      )}
    </>
  );
};

export default ConnectRSSFeedToSocialPages;
