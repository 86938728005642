/* global mixpanel */
/**
 * postLoginDataSetup - set up user and property info after successful authentication
 *
 * @param {object} cognitoResponse - contains details of the authenticated user
 * @param {function} setGlobalInfo - function used for setting global info
 * @return {object} - user and property information
 *
 * Example response
 * {
 *   user: {object}
 *   properties: {array of objects}
 *   current: {
 *     propertyId: {integer},
 *     accountAPIId: {integer}
 *   }
 * }
 */
import postServiceAuth from 'api/postServiceAuth';
import {
  getAccountAPIPermission,
  getCurrentAccountAPIId,
} from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import {
  COGNITO_CHALLENGE_NAMES,
  PERMISSION_TYPES,
  SENTRY_USER_TYPES,
  UI_MESSAGES,
  USER_TYPES,
} from 'common/constants';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import { setUserDetails } from 'common/sentry';
import * as tracker from 'common/tracker';
import { isNull } from 'common/utility';
import { mandatory } from 'common/validation';

import setUpGlobalData from './setUpGlobalData';

export default async function postLoginDataSetup({
  cognitoResponse = mandatory('cognitoResponse'),
  setGlobalInfo = mandatory('setGlobalInfo'),
}) {
  try {
    if (COGNITO_CHALLENGE_NAMES[cognitoResponse.challengeName]) {
      const userDetail = authentication.getUserDetails(cognitoResponse);
      return { user: userDetail };
    }
    if (isNull(cognitoResponse.userType)) {
      throw new ReferenceError(UI_MESSAGES.LOGIN_NULL_USER_TYPE);
    }
    authentication.login(cognitoResponse);

    // Get the user details from the cognito response, so we can get the
    // username and emailAddress for /serviceAuth error handling
    let userDetail = authentication.getUserDetails(cognitoResponse, null);

    /* Exchange access and id tokens for client service token */
    const clientServiceToken = await postServiceAuth({
      username: userDetail.userId,
      emailAddress: userDetail.emailAddress,
    });

    // calls getUserDetails a second time - this time using the
    // client service token values as an override
    userDetail = authentication.getUserDetails(
      cognitoResponse,
      clientServiceToken,
    );

    authentication.deleteOldKeys();
    try {
      authentication.setClientServiceToken(clientServiceToken);
    } catch (e) {
      logger.error({
        event: 'Error setting client service token',
        properties: {
          emailAddress: userDetail.emailAddress,
          localStorage: JSON.stringify(localStorage),
        },
      });
      throw e;
    }
    const data = { user: userDetail };

    // Set user details for Sentry logging
    setUserDetails({
      id: data.user.userId,
      username: data.user.name,
      email: data.user.emailAddress,
      usertype:
        data.user.userType === USER_TYPES.ECHOBOX_STAFF
          ? SENTRY_USER_TYPES.ENHANCED_PERMISSIONS
          : SENTRY_USER_TYPES.NORMAL,
    });

    const globalData = await setUpGlobalData({ userDetail, setGlobalInfo });
    const currentProperty =
      globalData.properties[globalData.current.propertyId];
    // Track login event to Mixpanel adding extra information about the user
    if (typeof mixpanel !== 'undefined') {
      const currentPropertyId = globalData.current.propertyId;
      const currentRole = getAccountAPIPermission({
        accountAPIId: getCurrentAccountAPIId({
          globalInfo: globalData,
        }),
        globalInfo: globalData,
      });

      const currentUser = globalData.user;
      const emailAddressHash = await tracker.hashEmailAddress(
        currentUser.emailAddress,
      );

      const mixpanelId = emailAddressHash;
      mixpanel.people.set({
        $distinct_id: mixpanelId,
        $name: currentUser.name,
        $property: currentProperty?.propertyName ?? 'Not set',
        $email: currentUser.emailAddress,
        'Property ID': currentPropertyId ?? 'Not set',
        Staff: currentUser.userType === USER_TYPES.ECHOBOX_STAFF,
        Role: currentRole === PERMISSION_TYPES.EDITOR ? 'Editor' : 'Admin',
        HV: window.sessionStorage.getItem('currentVersion'),
      });
      mixpanel.identify(mixpanelId);
      tracker.track({ eventName: 'Login' });
    }

    // All done
    return globalData;
  } catch (error) {
    const handledError = determineError(error);
    if (isNull(getErrorStatus)) {
      logger.error({
        event: 'Setup Data Error',
        properties: {
          location: 'process/postLoginDataSetup',
          arguments: {
            cognitoResponse,
          },
        },
        handledError,
      });
    }
    throw handledError;
  }
}
