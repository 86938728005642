import {
  Box,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import { GLOBAL_INFO_STATES } from 'common/constants';
import { getAllRSSFeeds } from 'common/feed';
import AddRSSFeed from 'components/setup/AddRSSFeed';
import AllowContentCheckbox from 'components/setup/AllowContentCheckbox';
import ConnectedFeeds from 'components/setup/ConnectedFeeds';
import ConnectRSSFeedToSocialPages from 'components/setup/ConnectRSSFeedToSocialPages';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import useAllowContentFromAllDomains from 'hooks/useAllowContentFromAllDomains';
import type { RssFeedData } from 'types';

const ContentFeeds = () => {
  const {
    allowContentFromAllDomains: initialAllowContentFromAllDomains,
    isLoading,
  } = useAllowContentFromAllDomains();
  const { global } = useGlobalInfo();

  const [rssFeedData, setRssFeedData] = useState<RssFeedData | null>(null);

  const [isAdd, setIsAdd] = useState(false);
  const allRSSFeeds = getAllRSSFeeds();

  useEffect(() => {
    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.REFRESHING,
      allowUnderSetup: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || global.isRefreshing()) {
    return (
      <Center>
        <Spinner size="lg" />
      </Center>
    );
  }

  return (
    <Flex
      flexDir="column"
      gap={6}
      align="flex-start"
      data-cy-id="contentFeedSettings"
    >
      <Box>
        <Heading variant="h2">Content feeds</Heading>
        <Text size="sm" color="gray.600" mt={2}>
          A content feed is an RSS feed or sitemap that contains articles you’ve
          published to your website.
        </Text>
      </Box>
      <Box>
        <AddRSSFeed
          existingFeeds={allRSSFeeds}
          onSaveRssFeed={(feedData) => {
            setIsAdd(true);
            setRssFeedData(feedData);
          }}
        />
        <Box w="776px" mt="3">
          <ConnectedFeeds
            onEditFeed={(feedData) => {
              setIsAdd(false);
              setRssFeedData(feedData);
            }}
          />
        </Box>
      </Box>
      {allRSSFeeds.length > 0 && (
        <AllowContentCheckbox
          initialAllowContentFromAllDomains={initialAllowContentFromAllDomains}
        />
      )}
      {rssFeedData && (
        <ConnectRSSFeedToSocialPages
          onDismiss={() => setRssFeedData(null)}
          rssFeedData={rssFeedData}
          isAdd={isAdd}
        />
      )}
    </Flex>
  );
};

export default ContentFeeds;
