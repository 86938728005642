import { SETTINGS_ACTION_BUTTON_STATUS } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { useBlocker } from 'react-router-dom';

import {
  resetSettingsState,
  useSettingsContext,
} from 'context/SettingsContext';

import ConfirmUnsavedChangesModal from './ConfirmUnsavedChangesModal';

/**
 * A guard to interrupt route navigation from the settings form.
 *
 * NOTE: It may be worth extracting to a common component if this logic is used elsewhere.
 */
const RouteLeavingGuard = () => {
  const { actionButtonStatus, dispatch } = useSettingsContext();

  const shouldBlock = React.useCallback(
    () => actionButtonStatus === SETTINGS_ACTION_BUTTON_STATUS.STATUS_CHANGED,
    [actionButtonStatus],
  );
  const blocker = useBlocker(shouldBlock);

  return blocker.state === 'blocked' ? (
    <ConfirmUnsavedChangesModal
      onConfirm={() => {
        resetSettingsState(dispatch);
        blocker.proceed?.();
      }}
      onClose={() => {
        blocker.reset?.();
      }}
    />
  ) : null;
};

export default RouteLeavingGuard;
