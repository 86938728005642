import { Link, useLocation } from 'react-router-dom';

import {
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
} from 'common/accountAPIs';
import {
  API_TYPE_IDS,
  FRONTEND_PAGES,
  PERMISSION_TYPES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import { isOnPage } from 'common/path';
import {
  getAutofeedSetting,
  isApprovalsActive,
  isIGCabinetEnabled,
} from 'common/settings';

/*
 * Page select menu
 */

interface PageSelectProps {
  isMobileWindowWidth: boolean;
  isUnderSetup: boolean;
  permissionTypeId: number;
  showMobileMenu: boolean;
}

const PageSelect = (props: PageSelectProps) => {
  const { isMobileWindowWidth, permissionTypeId, showMobileMenu } = props;
  const location = useLocation();

  const isUnderSetup =
    props.isUnderSetup || isOnPage({ page: FRONTEND_PAGES.SETUP, location });
  if (isUnderSetup) {
    return REACT_PREVENT_RENDER;
  }
  if (isMobileWindowWidth && showMobileMenu) {
    return REACT_PREVENT_RENDER;
  }

  const analyticsSelected = isOnPage({
    page: FRONTEND_PAGES.ANALYTICS,
    location,
  });
  const archiveSelected = isOnPage({
    page: FRONTEND_PAGES.ARCHIVE,
    location,
  });
  const autofeedSelected = isOnPage({
    page: FRONTEND_PAGES.AUTOFEED,
    location,
  });
  const queueSelected = isOnPage({
    page: FRONTEND_PAGES.MOBILE_QUEUED,
    location,
  });
  const approvalSelected = isOnPage({
    page: FRONTEND_PAGES.MOBILE_APPROVAL,
    location,
  });

  const shareSelected =
    isOnPage({ page: FRONTEND_PAGES.SHARE_DESKTOP, location }) ||
    (!isMobileWindowWidth &&
      isOnPage({ page: FRONTEND_PAGES.SHARE_MOBILE, location }));

  const autofeedActive = getAutofeedSetting({
    key: 'autoFeedActive',
  });

  let modeLabel = 'Off';
  if (isApprovalsActive()) {
    modeLabel = 'Approvals On';
  } else if (autofeedActive) {
    modeLabel = 'On';
  }

  const modeClass = autofeedActive
    ? 'badge-autofeed-status-on'
    : 'badge-autofeed-status-off';

  const renderAnalytics = (isInstagram: boolean) => {
    if (isInstagram && isIGCabinetEnabled()) {
      return REACT_PREVENT_RENDER;
    }
    return (
      <Link
        to="/analytics"
        data-cy-action="analytics"
        className={`${analyticsSelected ? 'selected' : ''}`}
      >
        Analytics
      </Link>
    );
  };

  const renderArchive = () => (
    <Link
      to="/archive"
      data-cy-action="archive"
      className={`${archiveSelected ? 'selected' : ''}`}
    >
      Archive
    </Link>
  );

  const renderAutofeed = (accountAPIId: { accountAPIId?: string }) => {
    return (
      <>
        <div
          style={{
            borderLeft: '1px solid #697386',
            marginLeft: '10px',
            marginRight: '6px',
            opacity: '20%',
          }}
        >
          &nbsp;
        </div>
        <Link
          to={`/settings/automation/${accountAPIId}`}
          data-cy-action="autofeed"
          className={`pr-md-2 ${autofeedSelected ? 'selected' : ''}`}
        >
          Autofeed
        </Link>
        <div
          className={`badge badge-autofeed-status badge-autofeed-status-home ${modeClass}`}
        >
          {modeLabel}
        </div>
      </>
    );
  };

  const renderHome = () => (
    <Link
      to="/share"
      data-cy-action="home"
      className={`${shareSelected ? 'selected' : ''}`}
    >
      Home
    </Link>
  );

  const renderQueue = () => (
    <Link
      to="/queue"
      className={`d-md-none ${queueSelected ? 'selected' : ''}`}
    >
      Queue
    </Link>
  );

  const renderApprovals = () => (
    <Link
      to="/approvals"
      className={`d-md-none ${approvalSelected ? 'selected' : ''}`}
    >
      Approvals
    </Link>
  );

  const apiTypeId = getCurrentAPITypeId();
  const accountAPIId = getCurrentAccountAPIId();
  const isInstagram = apiTypeId === API_TYPE_IDS.INSTAGRAM;
  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;
  const showAutofeed =
    !(isTikTok || isInstagram) && permissionTypeId === PERMISSION_TYPES.ADMIN;
  const showApprovals = isApprovalsActive();
  const showArchive = !isTikTok;

  const id = isMobileWindowWidth ? 'pageSelectMobile' : 'pageSelectDesktop';

  return (
    <div className="menu d-flex align-items-center" data-cy-id={id}>
      {renderHome()}
      {showApprovals && renderApprovals()}
      {renderQueue()}
      {showArchive && renderArchive()}
      {renderAnalytics(isInstagram)}
      {showAutofeed && renderAutofeed(accountAPIId ?? null)}
    </div>
  );
};

export default PageSelect;
