import { Box, PropertySelect } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  getCurrentPropertyId,
  getPropertyName,
  isPropertyIncomplete,
} from 'common/accountAPIs';
import { REACT_PREVENT_RENDER } from 'common/constants';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import { isNullOrUndefined } from 'common/utility';
import GlobalInfoContext from 'context/GlobalInfoContext';

interface SocialPropertySelectProps {
  redirectOrigin: string;
}

const SocialPropertySelect = ({
  redirectOrigin,
}: SocialPropertySelectProps) => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();

  if (global.isLoading()) {
    return REACT_PREVENT_RENDER; // Global info is currently being loaded
  }

  if (isNullOrUndefined(globalInfo?.properties)) {
    return REACT_PREVENT_RENDER; // Global info is not yet populated
  }

  if (Object.keys(globalInfo.properties).length === 1) {
    return REACT_PREVENT_RENDER; // Only one property exists so there is no need to show the dropdown
  }

  const currentPropertyId = getCurrentPropertyId({
    globalInfo,
  });

  const availableProperties: { propertyId: number; propertyName: string }[] =
    Object.keys(globalInfo.properties).map((property) => {
      return {
        propertyId: Number(property),
        propertyName: globalInfo.properties[property].propertyName,
        isIncomplete: isPropertyIncomplete({
          propertyId: Number(property),
        }),
      };
    });

  const sortedPropertyIds = availableProperties.sort((propertyA, propertyB) => {
    return propertyA.propertyName.localeCompare(propertyB.propertyName);
  });

  const handlePropertyChange = (propertyId: number) => {
    logger.info(`PropertySelect:handlePropertyChange ${propertyId}`);

    tracker.track({
      eventName: 'Switch Property',
      trackingParams: {
        'New Property Name': getPropertyName({ propertyId }),
        'New Property Id': propertyId,
        Origin: 'Home page',
      },
    });
    global.handlePropertyChange(propertyId.toString());
  };

  return (
    <Box data-cy-select="propertySelect">
      <PropertySelect
        currentPropertyId={currentPropertyId}
        onChange={handlePropertyChange}
        properties={sortedPropertyIds}
        createPropertyLink={{
          to: `/addproperty?origin=${redirectOrigin}`,
          as: Link,
        }}
      />
    </Box>
  );
};

export default SocialPropertySelect;
