import * as MediaItem from 'common/mediaItem';
import { FixTypeLater } from 'types';
import { getCurrentPropertyId } from './accountAPIs';
import {
  POST_TYPES,
  PROPERTIES_WITH_LINK_IN_COMMENT,
  PROPERTIES_WITH_LINK_IN_MESSAGE,
} from './constants';

export const mergeMessageWithLink = (message: string, link: string) => {
  if (!message) return link;
  if (!link) return message;

  // Remove any trailing whitespace
  message = message.trim();

  // if message ends with a punctuation mark, then don't add a period
  if (message.endsWith('.') || message.endsWith('!') || message.endsWith('?')) {
    return `${message} ${link}`;
  }
  return `${message}. ${link}`;
};

const removeLinkFromMessage = (message: string, link: string) => {
  if (!message) return message;
  if (!link) return message;

  // Match the link with or without a space before it.
  return message.replace(link, '') || null;
};

export function convertLinkToImage({ mediaItem }: { mediaItem: FixTypeLater }) {
  const currentPropertyId = getCurrentPropertyId();
  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });

  // Change to Image/Status post
  mediaItem = MediaItem.setPostType({
    mediaItem,
    fieldValue: POST_TYPES.STATUS,
  });

  // Put the article URL into the first comment or message
  if (PROPERTIES_WITH_LINK_IN_MESSAGE.includes(Number(currentPropertyId))) {
    const message = MediaItem.getMessage({ mediaItem });
    if (message) {
      mediaItem = MediaItem.setMessage({
        mediaItem,
        fieldValue: mergeMessageWithLink(message, articleURL),
      });
    }
  }
  if (PROPERTIES_WITH_LINK_IN_COMMENT.includes(Number(currentPropertyId))) {
    const firstComment = MediaItem.getFirstComment({ mediaItem });
    mediaItem = MediaItem.setFirstComment({
      mediaItem,
      fieldValue: mergeMessageWithLink(firstComment, articleURL),
    });
  }

  // Remove the unshortened share URL so the backend doesn't treat it as a link post.
  mediaItem = MediaItem.setUnshortenedShareURL({ mediaItem, fieldValue: null });

  mediaItem = MediaItem.setInstantImageArticleUrl({
    mediaItem,
    fieldValue: articleURL,
  });

  // Track the articleURL
  mediaItem = MediaItem.setTrackingDetails({
    mediaItem,
    fieldValue: { articleURL },
  });

  return mediaItem;
}

export function convertImageToLink({ mediaItem }: { mediaItem: FixTypeLater }) {
  const currentPropertyId = getCurrentPropertyId();
  const articleURL = MediaItem.getInstantImageArticleUrl({ mediaItem });

  // Change to Link post
  mediaItem = MediaItem.setPostType({
    mediaItem,
    fieldValue: POST_TYPES.LINK,
  });

  // Reapply the article URL to the media item
  mediaItem = MediaItem.setUnshortenedShareURL({
    mediaItem,
    fieldValue: articleURL,
  });
  mediaItem = MediaItem.setInstantImageArticleUrl({
    mediaItem,
    fieldValue: null,
  });

  // Remove detected link from message/comment
  if (articleURL) {
    if (PROPERTIES_WITH_LINK_IN_MESSAGE.includes(Number(currentPropertyId))) {
      const message = MediaItem.getMessage({ mediaItem });
      if (message) {
        mediaItem = MediaItem.setMessage({
          mediaItem,
          fieldValue: removeLinkFromMessage(message, articleURL),
        });
      }
    }
    if (PROPERTIES_WITH_LINK_IN_COMMENT.includes(Number(currentPropertyId))) {
      const firstComment = MediaItem.getFirstComment({ mediaItem });
      if (firstComment) {
        mediaItem = MediaItem.setFirstComment({
          mediaItem,
          fieldValue: removeLinkFromMessage(firstComment, articleURL),
        });
      }
    }
  }

  return mediaItem;
}
