import * as Sentry from '@sentry/react';

import { SENTRY_LOG_SPANS, SENTRY_LOG_TRANSACTIONS } from 'common/config';
import { FRONTEND_METRICS_STATUS, KEY_TRANSACTIONS } from 'common/constants';
import * as logger from 'common/logger';
import { generateGuid } from 'common/string';
import { isNotNull, isRunningTests } from 'common/utility';
import { GlobalInfo } from 'types';
import { getGlobalInfo } from './globalInfo';

/*
 * Usage: API metrics
 *
 *   import * as metrics from 'common/metrics';
 *
 *   // At the start of the operation you want to track
 *   const guid = metrics.start('label');
 *
 *   // When the tracked operation completes successfully
 *   metrics.end('label', guid);
 *
 *   // If the tracked operation fails
 *   metrics.fail('label', guid);
 *
 *   // To reset all tracked results
 *   metrics.reset();
 *
 * Usage: Frontend performance metrics
 *
 *   import * as metrics from 'common/metrics';
 *   import { FRONTEND_METRICS } from 'common/constants';
 *
 *   // Start tracking
 *   metrics.mark(FRONTEND_METRICS.KEY);
 *
 *   // Stop tracking
 *   metrics.measure(FRONTEND_METRICS.KEY);
 *
 *   When we need to wait for a component to be "stable" before measuring:
 *   this._trackLastUpdate(FRONTEND_METRICS.EVENT_NAME);
 *
 */

/*
 * API performance methods
 */

/**
 * Starts a timer for the specified label
 *
 * @param label The label to use for the timer
 * @returns Returns a guid to identify the timer
 */
export function start(label: string) {
  const guid = generateGuid();
  initialise(label, guid);
  window.metrics[label].record[guid].start = new Date().getTime();
  if (SENTRY_LOG_TRANSACTIONS && SENTRY_LOG_SPANS) {
    if (window.sentry?.transactions) {
      if (!window.sentry.spans) {
        window.sentry.spans = {};
      }
      const noOfTransactions = Object.keys(window.sentry.transactions).length;
      if (noOfTransactions > 0) {
        const firstTransactionName = Object.keys(window.sentry.transactions)[
          noOfTransactions - 1
        ];
        window.sentry.spans[label] = window.sentry.transactions[
          firstTransactionName
        ].startChild({
          op: label,
        });
      }
    }
  }
  return guid;
}

/**
 * Ends a timer for the specified label (success)
 *
 * @param label The label to use for the timer
 * @param guid The unique identifier for the timer
 */
export function end(label: string, guid: string) {
  finish(label, guid, true);
}

/**
 * Ends a timer for the specified label (failure)
 *
 * @param label The label to use for the timer
 * @param guid The unique identifier for the timer
 */
export function fail(label: string, guid: string) {
  finish(label, guid, false);
}

/**
 * Converts all metrics to JSON
 */
export function stringify() {
  return JSON.stringify(window.metrics);
}

/**
 * Creates relevant data structures for metrics
 *
 * @param label The label to use for the timer
 * @param guid The unique identifier for the timer
 */
function initialise(label: string, guid?: string) {
  if (!window.metrics[label]) {
    window.metrics[label] = {
      calls: 0,
      success: 0,
      failure: 0,
      timed: 0,
      elapsed: 0,
      min: Infinity,
      max: 0,
      average: 0,
      record: {},
    };
  }
  if (guid && !window.metrics[label].record[guid]) {
    window.metrics[label].record[guid] = {
      start: null,
    };
  }
}

/**
 * Ends a timer with the specified outcome (success or failure)
 *
 * @param label The label to use for the timer
 * @param guid The unique identifier for the timer
 * @param success A flag to indicate whether the timer ended successfully
 */
function finish(label: string, guid: string, success: boolean) {
  initialise(label, guid);
  const entry = window.metrics[label];
  const record = entry.record[guid];
  entry.calls += 1;
  if (success) {
    entry.success += 1;
  } else {
    entry.failure += 1;
  }
  if (record?.start) {
    entry.timed += 1;
    const elapsed = new Date().getTime() - record.start;
    entry.elapsed += elapsed;
    entry.average = Math.round((entry.elapsed * 100) / entry.timed) / 100;
    entry.min = Math.min(elapsed, entry.min);
    entry.max = Math.max(elapsed, entry.max);
  }
  delete entry.record[guid];
  if (
    SENTRY_LOG_TRANSACTIONS &&
    SENTRY_LOG_SPANS &&
    window.sentry?.spans?.[label]
  ) {
    window.sentry.spans[label].finish();
    delete window.sentry.spans[label];
  }
}

/*
 * Frontend performance methods
 */

/**
 * Start performance tracking
 *
 * @param name The name of the transaction
 * @param id The id to distinguish between concurrent transactions
 *
 * @example
 *
 * metrics.mark(FRONTEND_METRICS.KEY);
 * metrics.mark(FRONTEND_METRICS.KEY, 'b210b43b-7ff8-40c6-aefe-47aa82d6b82d');
 *
 */
export function mark(name: string, id: string | number | null = null) {
  if (!isRunningTests()) {
    // If an id is provided we store the transaction using it
    const key = id !== null ? name + id : name;
    logger.info(`Metrics:mark ${name}`);

    if (SENTRY_LOG_TRANSACTIONS && KEY_TRANSACTIONS.indexOf(name) !== -1) {
      if (window.sentry == null) {
        window.sentry = {};
      }
      if (window.sentry.transactions == null) {
        window.sentry.transactions = {};
      }
      window.sentry.transactions[key] = Sentry.startTransaction({ name });
    }
  }
}

/**
 * Finish performance tracking
 *
 * @param name The name of the transaction
 * @param id The id to  distinguish between concurrent transactions
 * @param status The status of the transaction - if not supplied then defaults to 'ok'
 * @param globalInfo The global info object
 *
 * @example
 *
 * metrics.measure(FRONTEND_METRICS.KEY);
 * metrics.measure(FRONTEND_METRICS.KEY, 'b210b43b-7ff8-40c6-aefe-47aa82d6b82d');
 */
export function measure(
  name: string,
  {
    id = null,
    status = FRONTEND_METRICS_STATUS.OK,
    user = getGlobalInfo()?.user,
    additionalTags = {},
  }: {
    id?: string | number | null;
    status?: string;
    user?: GlobalInfo.User;
    additionalTags?: Record<string, string>;
  } = {},
) {
  const key = id !== null ? name + id : name;
  if (!isRunningTests()) {
    logger.info(`Metrics:measure ${name}`);
    const transactions = window.sentry?.transactions;
    if (SENTRY_LOG_TRANSACTIONS && transactions && transactions[key]) {
      const { emailAddress } = user;

      // Setting the user details as a tag for the transaction
      // as transactions don't support setting user details.
      // You can also set the user details as 'context' but
      // global scrubbing rules will be applied.
      transactions[key].setTag('email', emailAddress);

      if (isNotNull(status)) {
        transactions[key].setStatus(status);
      }

      Object.entries(additionalTags).forEach(([k, value]) => {
        transactions[key].setTag(k, value);
      });

      transactions[key].finish();
      delete transactions[key];
    }
  }
}
