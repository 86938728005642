import {
  Button,
  Card,
  Grid,
  HStack,
  Spacer,
  Toggle,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import postPropertiesSettings from 'api/postPropertiesSettings';
import postServiceAuth from 'api/postServiceAuth';
import * as authentication from 'common/authentication';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import { useState } from 'react';
import { CommonSetting } from 'types/common';

interface LiveStoryCardProps {
  currentToggleState?: boolean;
  propertyId: number;
  propertyURN: string;
}

const LiveStoryCard = ({
  currentToggleState,
  propertyId,
  propertyURN,
}: LiveStoryCardProps) => {
  const [toggleState, setToggleState] = useState(currentToggleState || false);
  const toast = useToast();

  const handleToggleClick = () => {
    setToggleState(!toggleState);
  };

  const handleSaveClick = async () => {
    const userCSToken = authentication.getClientServiceToken();

    try {
      const impersonateCSToken = await postServiceAuth({
        overridePropertyURN: propertyURN,
      });
      authentication.setClientServiceToken(impersonateCSToken);

      await postPropertiesSettings({
        propertyId,
        settingTypeId: ACCOUNT_SETTING_TYPES.LIVE_STORY_DEFAULT,
        enabled: toggleState,
      });

      toast({
        variant: 'success',
        title: 'Settings have been successfully updated',
      });
    } catch (error) {
      console.error(error);
      toast({
        variant: 'chilledError',
        title: 'Something went wrong',
        description:
          'Please try again. If the error persists, contact someone in Tech.',
      });
    } finally {
      authentication.setClientServiceToken(userCSToken);
    }
  };

  const liveStorySetting: Partial<Record<CommonSetting, string>> = {
    [ACCOUNT_SETTING_TYPES.LIVE_STORY_DEFAULT]: 'Live Story enabled by default',
  };

  return (
    <Card alignItems="end">
      <Card.Content flexGrow={1} gap={4}>
        <Card.Title as="label">Live Story</Card.Title>
        <HStack>
          <Grid
            as="ul"
            px={0}
            gap={2}
            templateColumns={{ base: '1fr', md: 'repeat(1, 1fr)' }}
          >
            {Object.entries(liveStorySetting).map(([key, value]) => {
              const commonSetting = key as unknown as CommonSetting;
              return (
                <Grid.GridItem key={commonSetting} as="li" w="full">
                  <Toggle isChecked={toggleState} onChange={handleToggleClick}>
                    {value}
                  </Toggle>
                </Grid.GridItem>
              );
            })}
          </Grid>
          <HStack>
            <Spacer />
            <Button
              w="fit-content"
              onClick={handleSaveClick}
              alignItems="right"
            >
              Save
            </Button>
          </HStack>
        </HStack>
      </Card.Content>
    </Card>
  );
};

export default LiveStoryCard;
