import { SOCIAL_CHANNELS } from 'common/constants';
import { SocialChannel } from 'types';

export function thumbnailImageValidator({
  socialChannel,
  fileSize,
}: {
  socialChannel: SocialChannel;
  fileSize?: number;
}) {
  if (fileSize && socialChannel === SOCIAL_CHANNELS.FEED) {
    const maxFileSize = 2 * 1024 * 1024; // 2MB
    if (fileSize > maxFileSize) {
      return `Thumbnails must have a file size less than 2MB`;
    }
  }
  return null;
}
