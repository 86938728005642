import PropTypes from 'prop-types';

import { getTextDirection } from 'common/language';
import { renderMessageTags } from 'common/tags';

const ItemTitle = (props) => {
  const { accountAPIId, articleTitle, itemTags } = props;

  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: articleTitle,
  });

  return (
    <div className={`article-title ${textDirectionClass}`} data-cy-id="title">
      {renderMessageTags({
        message: articleTitle,
        tags: itemTags,
      })}
    </div>
  );
};

ItemTitle.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  articleTitle: PropTypes.string.isRequired,
  itemTags: PropTypes.object.isRequired,
};

export default ItemTitle;
