import { trackOpenArticle } from 'helpers/articleTracking';

interface ItemMenuProps {
  articleURL: string;
}

const ItemMenu = ({ articleURL }: ItemMenuProps) => {
  return (
    <div className="dropdown ml-auto align-items-center d-flex">
      <div
        className="border-0 align-self-center dot-menu dot-menu-primary border-radius-2 mx-1"
        id="dropdownMenuPage"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <svg
          viewBox="0 0 22 22"
          focusable="false"
          fill="#A3ACB9"
          style={{
            pointerEvents: 'none',
            width: '18px',
            height: '18px',
          }}
        >
          <path
            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
            className="style-scope yt-icon"
          />
        </svg>
      </div>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuPage"
        // eslint-disable-next-line react/no-unknown-property
        x-placement="top-start"
        style={{
          position: 'absolute',
          willChange: 'transform',
          top: '0px',
          left: '0px',
          transform: 'translate3d(0px, -41px, 0px)',
        }}
      >
        <a
          href={articleURL}
          target="_blank"
          rel="noopener noreferrer"
          className="dropdown-item"
          type="button"
          onClick={() => trackOpenArticle(articleURL, 'Archive')}
        >
          Open article URL
        </a>
      </div>
    </div>
  );
};

export default ItemMenu;
