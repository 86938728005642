import $ from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { Flex, HStack } from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  getAccountAPIPermission,
  getCurrentAPITypeId,
  getCurrentAccountAPIId,
  isCurrentPropertySuspended,
} from 'common/accountAPIs';
import { ANALYTICS_SETTINGS } from 'common/config';
import {
  API_TYPE_IDS,
  KEYCODES,
  PERMISSION_TYPES,
  REACT_PREVENT_RENDER,
  TEXT_CASES,
} from 'common/constants';
import {
  createTimestamp,
  getDate,
  isNotDateControl,
  toLocalString,
} from 'common/datetimepicker';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { getSocialNetworkName } from 'common/social';
import { canEditMetrics } from 'common/socialV2';
import * as tracker from 'common/tracker';
import { cloneObject, createEventHandler, isNull } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import { defaultDateRange, getDefaultColumns } from 'helpers/analyticsPage';
import { getNetworkAndPageName } from 'helpers/tracking';
import EditColumns from './EditColumns';
import PostTypeFilter from './PostTypeFilter';
import TimeFrameFilter from './TimeFrameFilter';

function trackFilterChange({
  beforeShareValue,
  afterShareValue,
  beforeTimeValue,
  afterTimeValue,
}) {
  const globalInfo = getGlobalInfo();
  const accountAPIId = getCurrentAccountAPIId({
    globalInfo,
  });

  let echoboxShareFilterBefore = null;
  let echoboxShareFilter = null;
  let shareTypeFilterBefore = null;
  let shareTypeFilter = null;
  let timeSensitivityFilterBefore = null;
  let timeSensitivityFilter = null;

  switch (beforeShareValue?.toUpperCase()) {
    case 'ALL':
    case 'SHARE_ECHOBOX':
    case 'SHARE_DIRECT':
      echoboxShareFilterBefore = beforeShareValue;
      break;
    case 'BREAKING':
    case 'TODAY':
    case 'EVERGREEN':
      timeSensitivityFilterBefore = beforeShareValue;
      break;
    case 'STATUS':
    case 'VIDEO':
    case 'AB':
    case 'SPONSORED':
    case 'REEL':
    case 'MANUAL':
    case 'AUTOFEED':
    case 'STORY':
    case 'LINK':
    case 'FEED':
      shareTypeFilterBefore = beforeShareValue;
      break;
    default:
      break;
  }

  switch (afterShareValue?.toUpperCase()) {
    case 'ALL':
    case 'SHARE_ECHOBOX':
    case 'SHARE_DIRECT':
      echoboxShareFilter = afterShareValue;
      break;
    case 'BREAKING':
    case 'TODAY':
    case 'EVERGREEN':
      timeSensitivityFilter = afterShareValue;
      break;
    case 'STATUS':
    case 'VIDEO':
    case 'AB':
    case 'SPONSORED':
    case 'REEL':
    case 'MANUAL':
    case 'AUTOFEED':
    case 'STORY':
    case 'LINK':
    case 'FEED':
      shareTypeFilter = afterShareValue;
      break;
    default:
      break;
  }

  tracker.track({
    eventName: 'Filter Analytics Data',
    trackingParams: {
      'Social Network': getSocialNetworkName({
        apiTypeId: getCurrentAPITypeId(),
      }),
      'Network - Social Page': getNetworkAndPageName({
        accountAPIId,
      }),
      'Account API Id': accountAPIId,
      'Echobox Share Filter (before)': echoboxShareFilterBefore,
      'Echobox Share Filter': echoboxShareFilter,
      'Time Range Filter (before)': beforeTimeValue,
      'Time Range Filter': afterTimeValue,
      'Share Type Filter (before)': shareTypeFilterBefore,
      'Share Type Filter': shareTypeFilter,
      'Time Sensitivity Filter (before)': timeSensitivityFilterBefore,
      'Time Sensitivity Filter': timeSensitivityFilter,
    },
  });
}

/**
 * Analytics page filters
 */

export default class Filters extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const { defaultFrom, defaultTo } = defaultDateRange();
    const displayOptions = this.props.displayOptions;
    this.state = {
      isCustomDateRange: displayOptions.timeFrame === 'CUSTOM',
      fromDate: {
        value:
          displayOptions.timeFrame === 'CUSTOM' &&
          !isNull(displayOptions.fromTime) &&
          !Number.isNaN(displayOptions.fromTime)
            ? displayOptions.fromTime
            : defaultFrom,
        isOpen: false,
        isValid: true,
      },
      toDate: {
        value:
          displayOptions.timeFrame === 'CUSTOM' &&
          !isNull(displayOptions.toTime) &&
          !Number.isNaN(displayOptions.toTime)
            ? displayOptions.toTime
            : defaultTo,
        isOpen: false,
        isValid: true,
      },
    };
    this.wasChanged = false;
    this._bind(
      'handleDateChange',
      'handleDateClick',
      'handleDateKeyDown',
      'handleFilterChange',
      'handleSearchClick',
      'handleTimeframeChange',
      'handleBlur',
    );

    Moment.updateLocale('en', { week: { dow: 1 } });
    momentLocalizer();

    this.handleDateFromChange = createEventHandler(
      this.handleDateChange,
      'fromDate',
    );
    this.handleDateFromClick = createEventHandler(
      this.handleDateClick,
      'fromDate',
    );
    this.handleDateFromKeyDown = createEventHandler(
      this.handleDateKeyDown,
      'fromDate',
    );
    this.handleDateToChange = createEventHandler(
      this.handleDateChange,
      'toDate',
    );
    this.handleDateToClick = createEventHandler(this.handleDateClick, 'toDate');
    this.handleDateToKeyDown = createEventHandler(
      this.handleDateKeyDown,
      'toDate',
    );
  }

  /**
   * Lifecycle methods
   */

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Filter', {
      nextProps,
      nextState,
    });
  }

  /**
   * Event handlers
   */

  handleDateChange(target, value) {
    logger.info(`Filters:handleDateChange ${target} ${value}`);

    this.wasChanged = this.state[target].isOpen;
    this.setState((prevState) => {
      const nextState = cloneObject(prevState);

      if (isNull(value)) {
        nextState[target].isValid = false;
        nextState[target].value = null;
      } else {
        const local = toLocalString(value);
        switch (target) {
          case 'fromDate':
            nextState.fromDate.isValid = true;
            nextState.fromDate.value = createTimestamp({
              date: getDate(local),
              time: '00:00',
            });
            nextState.toDate.isValid = true;
            nextState.toDate.value = createTimestamp({
              date: getDate(local),
              time: '23:59',
            });
            break;
          case 'toDate':
            nextState.toDate.isValid = true;
            nextState.toDate.value = createTimestamp({
              date: getDate(local),
              time: '23:59',
            });
            break;
          default:
        }
      }
      nextState[target].isOpen = false;

      return nextState;
    });
  }

  handleDateClick(target, event) {
    logger.info(`Filters:handleDateClick ${target}`);

    // Ignore clicks on month selector controls
    const classNames = event.target.className.split(' ');
    if (isNotDateControl(classNames)) {
      return;
    }

    if (!this.wasChanged) {
      this.setState((prevState) => {
        const nextState = cloneObject(prevState);
        // Close the "other" date selector
        if (target === 'fromDate') {
          nextState.toDate.isOpen = false;
        } else if (target === 'toDate') {
          nextState.fromDate.isOpen = false;
        }
        // Toggle the clicked date selector
        nextState[target].isOpen = !nextState[target].isOpen;
        return nextState;
      });
    }
    this.wasChanged = false;
  }

  handleDateKeyDown(target, event) {
    logger.info(`Filters:handleDateKeyDown ${target}`);

    if (
      !(
        (event.keyCode >= KEYCODES.ENTER && event.keyCode <= KEYCODES.INSERT) ||
        (event.keyCode >= KEYCODES.LEFT_WINDOW_KEY &&
          event.keyCode <= KEYCODES.SELECT_KEY) ||
        (event.keyCode >= KEYCODES.F1 && event.keyCode <= KEYCODES.SCROLL_LOCK)
      )
    ) {
      this.setState((prevState) => {
        const nextState = cloneObject(prevState);
        nextState[target].isOpen = false;
        return nextState;
      });
    } else if (event.keyCode === KEYCODES.ENTER) {
      if (typeof $ !== 'undefined' && $(':focus')[0].tagName === 'INPUT') {
        $(':focus').blur();
      }
    }
  }

  handleFilterChange(filterBy) {
    trackFilterChange({
      beforeShareValue: this.props.displayOptions.filterBy,
      afterShareValue: filterBy,
      beforeTimeValue: this.props.displayOptions.timeFrame,
      afterTimeValue: this.props.displayOptions.timeFrame,
    });

    this.props.eventHandlers.handleFilterChange(filterBy);
  }

  handleSearchClick() {
    logger.info('Filters:handleSearchClick');
    const event = {
      target: {
        value: 'CUSTOM',
        getAttribute: (key) => {
          if (key === 'fromTime') {
            return this.state.fromDate.value;
          }
          if (key === 'toTime') {
            return this.state.toDate.value;
          }
          return null;
        },
      },
    };
    this.props.eventHandlers.handleTimeframeChange(event);
  }

  handleTimeframeChange(value) {
    const event = {
      target: {
        value,
      },
    };
    const timeframe = event.target.value;
    logger.info(`Filters:handleTimeframeChange - ${timeframe}`);

    trackFilterChange({
      beforeShareValue: this.props.displayOptions.filterBy,
      afterShareValue: this.props.displayOptions.filterBy,
      beforeTimeValue: this.props.displayOptions.timeFrame,
      afterTimeValue: timeframe,
    });

    if (timeframe === 'CUSTOM') {
      event.target.getAttribute = () => null;
    }
    this.setState(
      (prevState) => {
        const nextState = cloneObject(prevState);
        if (timeframe === 'CUSTOM') {
          nextState.isCustomDateRange = true;
          const { defaultFrom, defaultTo } = defaultDateRange();
          nextState.fromDate.value = defaultFrom;
          nextState.toDate.value = defaultTo;
        } else {
          nextState.isCustomDateRange = false;
        }
        return nextState;
      },
      () => {
        this.props.eventHandlers.handleTimeframeChange(event);
      },
    );
  }

  static handleToggle() {
    logger.info('Timing:handleToggle');
    // This method only exists to prevent warnings about using the "open" prop
    // without also having an "onToggle" handler prop, even though everything
    // works just fine without it...
  }

  handleBlur() {
    this.setState((prevState) => ({
      ...prevState,
      fromDate: { ...prevState.fromDate, isOpen: false },
      toDate: { ...prevState.toDate, isOpen: false },
    }));
  }

  /**
   * Helper methods
   */

  inputClass(key) {
    return this.state[key].isValid ? '' : 'date-time-error';
  }

  /**
   * Render methods
   */

  renderFilters() {
    const isTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;

    return (
      <select
        className="filter btn btn-light dropdown-md mr-2 analytics-filter my-1"
        value={this.props.displayOptions.filterBy}
        data-cy-select="filter"
        onChange={(e) => this.handleFilterChange(e.target.value)}
      >
        <option value="ALL">All</option>
        {isTikTok ? (
          <>
            <option value="SHARE_ECHOBOX">Echobox shares</option>
            <option value="SHARE_DIRECT">Non-Echobox shares</option>
          </>
        ) : (
          <>
            <option disabled>---</option>
            <option value="BREAKING">Breaking</option>
            <option value="TODAY">Normal</option>
            <option value="EVERGREEN">Evergreen</option>
            <option disabled>---</option>
            <option value="STATUS">Photo/Status</option>
            <option value="VIDEO">Video</option>
            <option value="AB">AB Test</option>
            <option value="SPONSORED">Sponsored</option>
          </>
        )}
      </select>
    );
  }

  renderTimeframes() {
    const timeframeOptions = [];
    ANALYTICS_SETTINGS.TIMEFRAME_OPTIONS.forEach((option) => {
      timeframeOptions.push(
        <option value={option.duration} key={option.duration}>
          {option.label}
        </option>,
      );
    });
    return (
      <select
        className="filter btn btn-light dropdown-md mr-2 analytics-timeframe my-1"
        value={this.props.displayOptions.timeFrame}
        data-cy-select="timeframe"
        onChange={(e) => this.handleTimeframeChange(e.target.value)}
      >
        <option disabled>Timeframe</option>
        {timeframeOptions}
        <option value="CUSTOM">Pick a date range</option>
      </select>
    );
  }

  renderCustomDateRanges() {
    const calendarIcon = () => <img src="/img/icons/ic-calendar.svg" alt="" />;

    return (
      <>
        <div className="mr-2 my-1">
          <DateTimePicker
            containerClassName={this.inputClass('fromDate')}
            date={true}
            dateIcon={calendarIcon()}
            editFormat="DD-MMM-YY"
            footer={false}
            format="DD-MMM-YY"
            max={new Date()}
            name="fromDate"
            data-cy-input="fromDate"
            onBlur={this.handleBlur}
            onChange={this.handleDateFromChange}
            onClick={this.handleDateFromClick}
            onKeyDown={this.handleDateFromKeyDown}
            onToggle={Filters.handleToggle}
            open={this.state.fromDate.isOpen ? 'date' : false}
            time={false}
            value={new Date(this.state.fromDate.value * 1000)}
            views={['month']}
            style={{ maxWidth: '160px' }}
          />
        </div>
        <div className="mr-2 my-1">
          <DateTimePicker
            containerClassName={this.inputClass('toDate')}
            date={true}
            dateIcon={calendarIcon()}
            editFormat="DD-MMM-YY"
            footer={false}
            format="DD-MMM-YY"
            min={new Date(this.state.fromDate.value) * 1000}
            max={
              new Date(
                (this.state.fromDate.value +
                  ANALYTICS_SETTINGS.MAXIMUM_CUSTOM_DATE_DURATION_DAYS *
                    24 *
                    60 *
                    60 -
                  1) *
                  1000,
              )
            }
            name="toDate"
            data-cy-input="toDate"
            onBlur={this.handleBlur}
            onChange={this.handleDateToChange}
            onClick={this.handleDateToClick}
            onKeyDown={this.handleDateToKeyDown}
            onToggle={Filters.handleToggle}
            open={this.state.toDate.isOpen ? 'date' : false}
            time={false}
            value={new Date(this.state.toDate.value * 1000)}
            views={['month']}
            style={{ maxWidth: '160px' }}
          />
        </div>
        <Button
          data-cy-action="search"
          className="my-1"
          variant="dark"
          onClick={this.handleSearchClick}
        >
          Search
        </Button>
      </>
    );
  }

  renderExport() {
    const globalInfo = getGlobalInfo();
    const currentRole = getAccountAPIPermission({
      accountAPIId: getCurrentAccountAPIId({
        globalInfo,
      }),
      globalInfo,
    });
    if (currentRole !== PERMISSION_TYPES.ADMIN) {
      return REACT_PREVENT_RENDER;
    }

    return (
      <Flex ml="auto">
        <Tooltip
          id="export-csv"
          className="d-none d-md-flex"
          label="Export all historical data in a CSV file"
        >
          <Button
            onClick={this.props.eventHandlers.handleExportOpen}
            data-cy-action="exportCSV"
            data-tip
            data-for="export-csv"
            data-iscapture="true"
          >
            <span className="d-flex">
              <span>Export CSV</span>
              <img
                src="/img/icons/ic-download-cloud.svg"
                alt="Export CSV"
                className="align-middle pl-1 cloud-icon"
              />
            </span>
          </Button>
        </Tooltip>
      </Flex>
    );
  }

  renderItems() {
    const { totalItems } = this.props;

    return (
      <div className="d-flex items mr-2">
        {totalItems} item{totalItems !== 1 ? 's' : ''}
      </div>
    );
  }

  render() {
    const suspendedClass = isCurrentPropertySuspended()
      ? 'account_suspended'
      : '';

    const apiTypeId = getCurrentAPITypeId();
    const networkName = getSocialNetworkName({
      apiTypeId,
      textCase: TEXT_CASES.LOWER,
    });

    return (
      <div className={`controls d-flex flex-wrap ${suspendedClass}`}>
        <div className="d-flex flex-wrap">
          <HStack spacing="1.5" mr={2}>
            <PostTypeFilter
              value={this.props.displayOptions.filterBy}
              onChange={this.handleFilterChange}
            />
            <TimeFrameFilter
              value={this.props.displayOptions.timeFrame}
              onChange={this.handleTimeframeChange}
            />
          </HStack>
          {this.state.isCustomDateRange && this.renderCustomDateRanges()}
          {this.renderItems()}
        </div>
        <Flex gap={3} ml="auto" my={1}>
          {canEditMetrics(apiTypeId) && (
            <EditColumns
              initialColumns={
                this.props.displayOptions.insightColumns?.[networkName] ??
                getDefaultColumns({ apiTypeId })
              }
              onSelectedColumnChange={(columns) =>
                this.props.eventHandlers.handleInsightColumnsChange({
                  columns,
                  networkName,
                })
              }
            />
          )}
          {this.renderExport()}
        </Flex>
      </div>
    );
  }
}

Filters.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  totalItems: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    handleExportOpen: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    handleInsightColumnsChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * Documentation for the date-time picker can be found here:
 *
 * https://jquense.github.io/react-widgets/api/DateTimePicker/
 */
