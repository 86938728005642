import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { memo, useContext, useState } from 'react';

import postPropertiesUser from 'api/postPropertiesUser';
import {
  getAPIsByStateAndType,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import {
  API_STATES,
  KEYNAMES,
  MIXPANEL_ORIGIN,
  PERMISSION_TYPES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import { getFeatureToggle } from 'common/settings';
import * as tracker from 'common/tracker';
import { arePropsEqual } from 'common/utility';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import UserPages from 'components/settings/user/UserPages';
import GlobalInfoContext from 'context/GlobalInfoContext';
import useOnKey from 'hooks/useOnKey';
import InfoIcon from 'svg/InfoCurrentColor';

/*
 * User add
 */

const UserAdd = (props) => {
  const context = useContext(GlobalInfoContext);
  const { canEditRole, eventHandlers } = props;

  /**
   * Initial state
   */

  const resetErrorMessages = () => {
    return {
      name: '',
      email: '',
      role: '',
      general: '',
    };
  };

  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [propertyPermission, setPropertyPermission] = useState(
    PERMISSION_TYPES.ADMIN,
  );
  const [pagePermissions, setPagePermissions] = useState([]);
  const [isEditingPages, setIsEditingPages] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [errorMessages, setErrorMessages] = useState(resetErrorMessages());

  const isEditorEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  const noPagesSelected = pagePermissions.length === 0;

  /**
   * Helper methods
   */

  const resetState = (callback) => {
    setName('');
    setEmailAddress('');
    setPropertyPermission(PERMISSION_TYPES.ADMIN);
    setPagePermissions([]);
    setErrorMessages(resetErrorMessages());
    setIsAddingUser(false);
    setIsEditingPages(false);
    if (typeof callback === 'function') {
      callback();
    }
  };

  const setInputClass = (isSavingUser, hasError) => {
    let inputClass = '';
    if (isSavingUser) {
      inputClass += ' saving_locked';
    }
    if (hasError) {
      inputClass += ' input-with-error';
    }
    return inputClass;
  };

  /**
   * Event handlers
   */

  const handleAddUserCancel = () => {
    logger.info('UserAdd:handleAddUserCancel');
    resetState(() => eventHandlers.handleAddUserCancel());
  };

  const handleEmailChange = (event) => {
    event.persist();
    setEmailAddress(event.target.value.trim());
    setErrorMessages({ ...errorMessages, email: '' });
  };

  const handleNameChange = (event) => {
    event.persist();
    setName(event.target.value);
    setErrorMessages({ ...errorMessages, name: '' });
  };

  const handlePagesCancel = () => {
    logger.info('UserAdd:handlePagesCancel');
    setIsEditingPages(false);
  };

  const handlePagesEdit = () => {
    logger.info('UserAdd:handlePagesEdit');
    setIsEditingPages(true);
  };

  const handlePagesSave = (newPagePermissions) => {
    logger.info('UserAdd:handlePagesSave');
    setIsEditingPages(false);
    setPagePermissions(newPagePermissions);
  };

  const handlePropertyPermissionChange = (event) => {
    const newPropertyPermission = event.target.value;
    logger.info(
      `UserAdd:handlePropertyPermissionChange: ${newPropertyPermission}`,
    );
    event.persist();
    let newPagePermissions = [];
    if (propertyPermission.toString() === PERMISSION_TYPES.EDITOR.toString()) {
      const globalInfo = context.global.getGlobalInfo();
      const apiList = getAPIsByStateAndType({
        propertyId: globalInfo.current.propertyId,
        apiStateId: [
          API_STATES.ACTIVE,
          API_STATES.BAD_AUTH,
          API_STATES.UNDER_SETUP,
        ],
        globalInfo,
      });
      newPagePermissions = apiList.map((accountAPI) =>
        accountAPI.accountAPIId.toString(),
      );
    }
    setPropertyPermission(newPropertyPermission);
    setPagePermissions(newPagePermissions);
  };

  const handleUserAdd = () => {
    logger.info('UserAdd:handleUserAdd');

    let errorsExist = false;
    const newErrorMessages = resetErrorMessages();
    if (name.trim() === '') {
      errorsExist = true;
      newErrorMessages.name = 'Please enter a name';
    }
    if (emailAddress.trim() === '') {
      errorsExist = true;
      newErrorMessages.email = 'Please enter an email address';
    }
    if (
      propertyPermission.toString() === PERMISSION_TYPES.EDITOR.toString() &&
      pagePermissions.length === 0
    ) {
      errorsExist = true;
      newErrorMessages.role = 'At least 1 page needs to be selected';
    }
    setErrorMessages(newErrorMessages);
    if (errorsExist) {
      return;
    }

    setIsAddingUser(true);
    setErrorMessages(resetErrorMessages());

    const globalInfo = context.global.getGlobalInfo();
    const permissionsOnProperty = [
      {
        propertyId: globalInfo.current.propertyId,
        permissionTypeId:
          propertyPermission.toString() === PERMISSION_TYPES.EDITOR.toString()
            ? PERMISSION_TYPES.VIEW_ONLY
            : PERMISSION_TYPES.ADMIN,
      },
    ];
    if (pagePermissions.length > 0) {
      pagePermissions.forEach((accountAPIId) => {
        permissionsOnProperty.push({
          propertyId: globalInfo.current.propertyId,
          accountAPIId,
          permissionTypeId: PERMISSION_TYPES.EDITOR,
        });
      });
    }
    postPropertiesUser({
      propertyId: getCurrentPropertyId({
        globalInfo,
      }),
      name,
      username: emailAddress,
      emailAddress,
      permissionsOnProperty,
    })
      .then(() => {
        tracker.track({
          eventName: 'Add User',
          trackingParams: {
            'New User Email': emailAddress,
            Origin: canEditRole
              ? MIXPANEL_ORIGIN.SETTINGS
              : MIXPANEL_ORIGIN.SETUP,
          },
        });
        eventHandlers.handleAddUserDone(() => {
          resetState();
        });
      })
      .catch((error) => {
        setIsAddingUser(false);
        setErrorMessages({ ...errorMessages, general: getErrorMessage(error) });
      });
  };

  /**
   * Lifecycle methods
   */

  useOnKey({ targetKey: KEYNAMES.ESCAPE, onKeyUp: handleAddUserCancel });

  /**
   * Render methods
   */

  const renderEmail = (isSavingUser) => {
    const errorMessage = errorMessages.email;
    const inputClass = setInputClass(isSavingUser, errorMessage !== '');

    return (
      <div>
        <div className="text-500 mb-1 d-flex mt-3">Email</div>
        <input
          type="text"
          data-cy-input="email"
          name="email"
          className={`input_medium w-100 ${inputClass}`}
          value={emailAddress}
          onChange={handleEmailChange}
          disabled={isSavingUser}
          placeholder="email@address.com"
        />
        {errorMessage !== '' && (
          <div className="save-state save failed mt-1">{errorMessage}</div>
        )}
      </div>
    );
  };

  const renderFooter = (isSavingUser) => {
    const buttonClass = isSavingUser ? 'saving_locked' : '';
    const isInputNotCompleted =
      name.trim() === '' || emailAddress.trim() === '';

    return (
      <div className="modal-footer">
        <div className="ml-auto">
          <Button
            className="ml-auto"
            data-cy-action="cancelPages"
            onClick={!isSavingUser ? handleAddUserCancel : null}
            disabled={isSavingUser}
          >
            Cancel
          </Button>
        </div>
        <div className="save">
          <Button
            className={buttonClass}
            variant="dark"
            onClick={!isSavingUser ? handleUserAdd : null}
            disabled={
              isSavingUser ||
              isInputNotCompleted ||
              (noPagesSelected &&
                propertyPermission.toString() !==
                  PERMISSION_TYPES.ADMIN.toString())
            }
            data-cy-action="saveNewUser"
          >
            Add User
          </Button>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="modal-header">
        <div className="top">
          <div className="left mr-2">
            <span>Invite new user</span>
          </div>
        </div>
        <a
          aria-label="Close"
          onClick={handleAddUserCancel}
          className="close ft-26 text-400"
        >
          &times;
        </a>
      </div>
    );
  };

  const renderName = (isSavingUser) => {
    const errorMessage = errorMessages.name;
    const inputClass = setInputClass(isSavingUser, errorMessage !== '');

    return (
      <div>
        <div className="text-500 mb-1 d-flex">Name</div>
        <input
          type="text"
          name="name"
          data-cy-input="name"
          className={`input_medium w-100 ${inputClass}`}
          value={name}
          onChange={handleNameChange}
          disabled={isSavingUser}
          placeholder="Name"
        />
        {errorMessage !== '' && (
          <div className="save-state save failed mt-1">{errorMessage}</div>
        )}
      </div>
    );
  };

  const renderPagePermissions = (isSavingUser) => {
    const isLocked = isSavingUser || !isEditorEnabled;
    const className = isLocked ? 'saving_locked' : '';
    const globalInfo = context.global.getGlobalInfo();

    if (propertyPermission.toString() === PERMISSION_TYPES.ADMIN.toString()) {
      return <span className={`pt-1 ${className}`}>All Pages</span>;
    }

    const apiList = getAPIsByStateAndType({
      propertyId: globalInfo.current.propertyId,
      apiStateId: [
        API_STATES.ACTIVE,
        API_STATES.BAD_AUTH,
        API_STATES.UNDER_SETUP,
      ],
      globalInfo,
    });
    const buttonLabel =
      apiList.length === pagePermissions.length
        ? 'All Pages'
        : `${pagePermissions.length} Page${
            pagePermissions.length !== 1 ? 's' : ''
          }`;

    return (
      <Button
        className={className}
        onClick={!isLocked ? handlePagesEdit : null}
        disabled={isLocked}
      >
        {buttonLabel}
      </Button>
    );
  };

  const renderPropertyPermission = (isSavingUser) => {
    const isLocked = isSavingUser || !isEditorEnabled;
    const selectClass = isLocked ? 'saving_locked' : '';

    const tooltipLabel = (
      <div>
        <div>Administrator: Has access to all pages and settings</div>
        <div>Editor: Has access to selected pages without settings</div>
      </div>
    );

    return (
      <div>
        <select
          name="permissionTypeId"
          className={`btn btn-light dropdown-md ${selectClass} mr-2`}
          value={propertyPermission}
          onChange={handlePropertyPermissionChange}
          disabled={isLocked}
        >
          <option value={PERMISSION_TYPES.ADMIN}>Administrator</option>
          <option value={PERMISSION_TYPES.EDITOR}>Editor</option>
        </select>

        <Tooltip id="user-permissions" label={tooltipLabel}>
          <img
            className="info_icon mr-3 op-60"
            src="/img/icons/ic-information.svg"
            alt=""
            data-tip
            data-for="user-permissions"
            data-iscapture="true"
          />
        </Tooltip>
      </div>
    );
  };

  const renderRole = (isSavingUser) => {
    const errorMessage = errorMessages.role;

    if (canEditRole) {
      return (
        <div>
          <div className="text-500 mb-1 d-flex mt-3">Role</div>
          <div className="row align-items-center">
            <div className="col-md-6 col-8">
              {renderPropertyPermission(isSavingUser)}
            </div>
            <div className="col-md-6 col-4 pl-0">
              {renderPagePermissions(isSavingUser)}
            </div>
          </div>
          {errorMessage !== '' && (
            <div className="save-state save failed mt-1">{errorMessage}</div>
          )}
        </div>
      );
    }

    return (
      <Flex
        mt={6}
        p={5}
        color="#596a87"
        bg="white"
        border="1px solid #dbe3f0"
        borderRadius={2}
        gap={3}
      >
        <InfoIcon />
        Added users will have administrator permissions, you will be able to
        change that later.
      </Flex>
    );
  };

  const renderUserDetails = (isSavingUser) => {
    const errorMessage = errorMessages.general;

    return (
      <div>
        <div className="modal-body lh-20">
          <div>
            Enter the email address, name and choose the role for the user.
          </div>
        </div>
        <div className="modal-divider" />
        <div className="modal-body lh-20">
          {renderName(isSavingUser)}
          {renderEmail(isSavingUser)}
          {renderRole(isSavingUser)}
        </div>
        {errorMessage !== '' && (
          <div className="modal-body modal-error save-state save failed">
            <img
              className="icon-error mr-1"
              src="/img/icons/ic-warning-red.svg"
              alt=""
            />
            {errorMessage}
          </div>
        )}
      </div>
    );
  };

  const isSavingUser = props.isSavingUser || isAddingUser;
  const buttonClass = isSavingUser ? 'saving_locked' : '';
  const globalInfo = context.global.getGlobalInfo();

  return (
    <>
      <Button
        data-cy-action="addUser"
        className={`new-user-button ${buttonClass}`}
        onClick={!isSavingUser ? eventHandlers.handleAddUserClick : null}
        disabled={isSavingUser}
      >
        Add User
      </Button>
      {props.isAddingUser && (
        <div className="ebx-modal-outer">
          <div className="select-pages modal-dialog modal-sm">
            <div className="modal-content">
              {renderHeader()}
              {renderUserDetails(isSavingUser)}
              {renderFooter(isSavingUser)}
            </div>
          </div>
        </div>
      )}
      {isEditingPages && (
        <UserPages
          availableAPIs={getAPIsByStateAndType({
            propertyId: globalInfo.current.propertyId,
            apiStateId: [
              API_STATES.ACTIVE,
              API_STATES.BAD_AUTH,
              API_STATES.UNDER_SETUP,
            ],
            globalInfo,
          })}
          selectedAPIs={pagePermissions}
          eventHandlers={{ handlePagesCancel, handlePagesSave }}
        />
      )}
    </>
  );
};

UserAdd.propTypes = {
  canEditRole: PropTypes.bool,
  isAddingUser: PropTypes.bool.isRequired,
  isSavingUser: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleAddUserCancel: PropTypes.func.isRequired,
    handleAddUserClick: PropTypes.func.isRequired,
    handleAddUserDone: PropTypes.func.isRequired,
  }).isRequired,
};

UserAdd.defaultProps = {
  canEditRole: true,
};

export default memo(UserAdd, arePropsEqual);
