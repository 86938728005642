import $ from 'jquery';

import postZendeskAuth from 'api/postZendeskAuth';
import { GlobalInfo } from 'types';

export function hideZendesk() {
  setZendeskVisible(false);
}

export function showZendesk(user: GlobalInfo.User | undefined) {
  setZendeskVisible(true, user);
}

export function setZendeskVisible(show: boolean, user?: GlobalInfo.User) {
  if (window.zE) {
    if (typeof user !== 'undefined') {
      window.zE('webWidget', 'prefill', {
        name: {
          value: user.name ?? '',
        },
        email: {
          value: user.emailAddress ?? '',
        },
      });
    }
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          suppress: !show,
        },
      },
    });
  }
}

export function openZendeskContactForm() {
  if (typeof $ !== 'undefined') {
    $('iframe#launcher')
      .contents()
      .find('button[aria-label="Help"]')
      .trigger('click');
  }
}

/**
 * Checks if current location contains a `return_to` parameter with a zendesk URL
 * and returns the value if it exists.
 * @param location The Location object (https://developer.mozilla.org/en-US/docs/Web/API/Location)
 * @returns The return URL if it exists, otherwise null.
 */
export function getZendeskReturnURL(location: Location) {
  const params = new URLSearchParams(location.search);
  const returnUrl = params.get('return_to');

  if (returnUrl?.startsWith('https://echobox.zendesk.com/')) {
    return returnUrl;
  }

  return null;
}

/**
 * Gets the URL to redirect given a zendesk return URL.
 *
 * @param returnUrl The return URL.
 * @returns The URL to redirect to.
 */
export async function getZendeskRedirectURL(returnUrl: string) {
  const jwtToken = await postZendeskAuth();
  return `https://echobox.zendesk.com/access/jwt?jwt=${jwtToken}&return_to=${returnUrl}`;
}
