import 'cropperjs/dist/cropper.css';

import { useState } from 'react';
import Cropper from 'react-cropper';

import {
  getAPITypeId,
  getCurrentAccountAPI,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { API_TYPE_IDS, SOCIAL_CHANNELS } from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { getFeatureToggle } from 'common/settings';
import Button from 'components/misc/Button';
import { useImageContext } from 'context/ComposeBoxImageContext';
import { getCroppedImage } from 'helpers/images';
import { FixTypeLater } from 'types';

import { ACTIONS, IMAGE_FILE_TYPES, IMAGE_MODES } from '.';

interface ImageProps {
  mediaItem?: FixTypeLater;
}

/**
 * Functional component for rending image in crop mode
 */
function ImageCrop({ mediaItem }: ImageProps) {
  const {
    aspectRatioRange,
    previewRef,
    src: imageContextSrc,
    onModeChange,
    onChange,
    fileType,
    imageProps,
  } = useImageContext();

  const src =
    fileType === IMAGE_FILE_TYPES.VIDEO ? imageProps.poster : imageContextSrc;

  const { min, max } = aspectRatioRange ?? { min: null, max: null };

  const [cropper, setCropper] = useState<Cropper>();
  // Get the height with `clientHeight` as this works for both image and video
  const [height] = useState(previewRef.current?.clientHeight ?? 0);

  const isLinkinbioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
  const accountAPIId = mediaItem
    ? MediaItem.getAccountAPIId({ mediaItem })
    : getCurrentAccountAPI();
  const apiTypeId = getAPITypeId({ accountAPIId });

  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const isInstagramLinkInBioFeed =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    isLinkinbioEnabled &&
    socialChannel === SOCIAL_CHANNELS.FEED;

  const squareAspectRatio = 1;
  let aspectRatio;
  if (isInstagramLinkInBioFeed) {
    aspectRatio = squareAspectRatio;
  } else {
    aspectRatio = min !== null && max !== null && min === max ? min : NaN;
  }

  /**
   * Function to handle zoom in/out on image
   * @param ratio - % by which to zoom in zoom out. -0.1 is zoom out (10%) and +0.1 is zoom in (10%)
   */
  const handleZoom = (ratio: number) => {
    if (!cropper) return;
    // Similar to how the wheel zoom ratio is calcualted in cropper.js
    const canvasData = cropper.getCanvasData();
    if (canvasData) {
      let finalRatio;
      if (ratio < 0) {
        finalRatio = 1 / (1 - ratio);
      } else {
        finalRatio = 1 + ratio;
      }
      cropper.zoomTo((canvasData.width * finalRatio) / canvasData.naturalWidth);
    }
  };

  /**
   * Handler to be triggered when save button is clicked
   * Calls onChange function provided by user with cropped image and crop action arguments
   * Changes image mode back to preview after executing onChange
   */
  const handleSave = async () => {
    if (!cropper) return;
    try {
      const file = await getCroppedImage({
        croppedCanvas: cropper.getCroppedCanvas(),
        fileName: 'resize.jpeg',
      });
      if (!file) {
        throw new Error('Could not convert a cropped canvas to a Blob.');
      }
      await onChange([file], ACTIONS.CROP);
      onModeChange(IMAGE_MODES.PREVIEW);
    } catch (error) {
      logger.error({ event: 'Image crop handleSave', error });
    }
  };

  const handleCancel = async () => {
    try {
      await onChange([], ACTIONS.CANCEL);
      onModeChange(IMAGE_MODES.PREVIEW);
    } catch (error) {
      logger.error({ event: 'Image crop handleCrop', error });
    }
  };

  return (
    <div
      className="share_imagery"
      data-cy="image-cropper"
      data-cy-ready={cropper !== undefined}
    >
      <Cropper
        className="share_image"
        style={{ height, width: '100%' }}
        src={src}
        initialAspectRatio={aspectRatio ?? NaN}
        // For now we want to enforce a hard aspect ratio for IG stories
        // If this requirement is needed for other networks or social channels,
        // move the logic out to the constants file.
        aspectRatio={
          socialChannel === SOCIAL_CHANNELS.STORY ? aspectRatio : undefined
        }
        autoCropArea={1.0}
        crossOrigin="anonymous"
        onInitialized={(cropperInstance) => setCropper(cropperInstance)}
      />
      <div className="edit_photo_container">
        <div className="edit_photo_tools d-flex w-100">
          <div className="tool">
            <Button
              className="add_photo text_unselectable"
              onClick={() => handleZoom(-0.1)}
              aria-label="Zoom out"
              data-cy="zoom-out"
            >
              <img src="/img/icons/ic-minus.svg" alt="" />
            </Button>
          </div>
          <div className="tool">
            <Button
              className="add_photo text_unselectable"
              onClick={() => handleZoom(0.1)}
              aria-label="Zoom in"
              data-cy="zoom-in"
            >
              <img src="/img/icons/ic-plus.svg" alt="" />
            </Button>
          </div>
          <div className="tool ml-auto">
            <Button
              className="add_photo text_unselectable"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="tool">
            <Button
              className="add_photo text_unselectable"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageCrop;
