import * as React from 'react';

import { getAPITypeId } from 'common/accountAPIs';
import * as MediaItem from 'common/mediaItem';
import { getShareURLPlaceholder } from 'common/shareURL';
import * as DraftJSTools from 'components/compose/messagebox/DraftJSTools';
import type { FixTypeLater } from 'types';

import { MessageBoxState as MessageBoxStateMap } from './constants';
import { reducer } from './reducer';
import type { MessageBoxContextInterface, State } from './types';

const getMessageOrFirstComment = ({
  isFirstComment,
  mediaItem,
}: {
  isFirstComment: boolean;
  mediaItem: FixTypeLater;
}) => {
  if (isFirstComment) {
    return MediaItem.getFirstComment({ mediaItem }) || '';
  }

  return MediaItem.getMessage({ mediaItem }) || '';
};

const MessageBoxContext =
  React.createContext<MessageBoxContextInterface | null>(null);
MessageBoxContext.displayName = 'MessageBoxContext';

const useMessageBoxContext = () => {
  const messageBoxContext = React.useContext(MessageBoxContext);

  if (messageBoxContext === undefined) {
    throw new Error(
      'useMessageBoxContext must be used within a MessageBoxProvider',
    );
  }
  if (messageBoxContext === null) {
    throw new Error('MessageBoxContext must be initialized by the Provider');
  }

  return messageBoxContext;
};

function getInitialState({
  mediaItem,
  accountAPIId,
  isFirstComment,
}: {
  mediaItem: FixTypeLater;
  accountAPIId: number;
  isFirstComment: boolean;
}): State {
  let decodedContent: string;
  try {
    decodedContent = decodeURIComponent(
      getMessageOrFirstComment({ isFirstComment, mediaItem }),
    );
  } catch (e) {
    // Use original if any error when decoding
    decodedContent = getMessageOrFirstComment({ isFirstComment, mediaItem });
  }
  const content = decodedContent;
  const shareURL = getShareURLPlaceholder({ mediaItem }) ?? '';
  const apiTypeId = getAPITypeId({ accountAPIId });
  const tags = MediaItem.getTags({ mediaItem });

  const editorState = DraftJSTools.createEditorState({
    config: {
      content,
      apiTypeId,
      replacementFields: tags,
      shareURL,
    },
    initialFocus: isFirstComment,
  });

  return {
    currentUserInputType: MessageBoxStateMap.DEFAULT,
    editorState,
    handleMention: '',
    handleHashtag: '',
  };
}

interface MessageBoxProviderProps {
  accountAPIId: number;
  children: React.ReactNode;
  isFirstComment: boolean;
  mediaItem: FixTypeLater;
}

const MessageBoxProvider = ({
  accountAPIId,
  children,
  isFirstComment,
  mediaItem,
}: MessageBoxProviderProps) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { accountAPIId, isFirstComment, mediaItem },
    getInitialState,
  );

  const messageBoxContextValue = React.useMemo(() => {
    return {
      ...state,
      dispatch,
    };
  }, [state]);

  return (
    <MessageBoxContext.Provider value={messageBoxContextValue}>
      {children}
    </MessageBoxContext.Provider>
  );
};

export { MessageBoxProvider, useMessageBoxContext };
