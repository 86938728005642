const Property = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3438 11.4062C12.3438 11.1484 12.5547 10.9375 12.8125 10.9375H13.75C14.0078 10.9375 14.2188 11.1484 14.2188 11.4062V12.3438C14.2188 12.6016 14.0078 12.8125 13.75 12.8125H12.8125C12.5547 12.8125 12.3438 12.6016 12.3438 12.3438V11.4062ZM12.3438 5.78125C12.3438 5.52344 12.5547 5.3125 12.8125 5.3125H13.75C14.0078 5.3125 14.2188 5.52344 14.2188 5.78125V6.71875C14.2188 6.97656 14.0078 7.1875 13.75 7.1875H12.8125C12.5547 7.1875 12.3438 6.97656 12.3438 6.71875V5.78125ZM12.8125 10C12.5547 10 12.3438 9.78906 12.3438 9.53125V8.59375C12.3438 8.33594 12.5547 8.125 12.8125 8.125H13.75C14.0078 8.125 14.2188 8.33594 14.2188 8.59375V9.53125C14.2188 9.78906 14.0078 10 13.75 10H12.8125ZM15.625 2.5C16.6592 2.5 17.5 3.33936 17.5 4.375V15.625C17.5 16.6592 16.6592 17.5 15.625 17.5H10.9375C9.90332 17.5 9.0625 16.6592 9.0625 15.625V4.375C9.0625 3.33936 9.90332 2.5 10.9375 2.5H15.625ZM15.625 3.90625H10.9375C10.6797 3.90625 10.4688 4.11602 10.4688 4.375V15.625C10.4688 15.8828 10.6797 16.0938 10.9375 16.0938H15.625C15.8828 16.0938 16.0938 15.8828 16.0938 15.625V4.375C16.0938 4.11602 15.8828 3.90625 15.625 3.90625ZM8.125 7.65625H4.60938C4.22119 7.65625 3.90625 7.96973 3.90625 8.35938V15.3906C3.90625 15.7803 4.22119 16.0938 4.60938 16.0938H8.16309C8.25391 16.6299 8.49414 17.1133 8.83984 17.5H4.60938C3.44453 17.5 2.5 16.5566 2.5 15.3906V8.35938C2.5 7.19336 3.44453 6.25 4.60938 6.25H8.125V7.65625ZM6.71875 11.875C6.97656 11.875 7.1875 12.0859 7.1875 12.3438V13.2812C7.1875 13.5391 6.97656 13.75 6.71875 13.75H5.78125C5.52344 13.75 5.3125 13.5391 5.3125 13.2812V12.3438C5.3125 12.0859 5.52344 11.875 5.78125 11.875H6.71875ZM6.71875 9.0625C6.97656 9.0625 7.1875 9.27344 7.1875 9.53125V10.4688C7.1875 10.7266 6.97656 10.9375 6.71875 10.9375H5.78125C5.52344 10.9375 5.3125 10.7266 5.3125 10.4688V9.53125C5.3125 9.27344 5.52344 9.0625 5.78125 9.0625H6.71875Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Property;
