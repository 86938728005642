import {
  AddIcon,
  Button,
  Dropdown,
  DropdownItemProps,
  HStack,
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialLinkedinIcon,
  SocialTiktokIcon,
  SocialTwitterIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useNavigate } from 'react-router-dom';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import * as logger from 'common/logger';
import SocialPageCircle from 'components/misc/SocialPageCircle';
import addNewAPI from 'process/addNewAPI';
import type { APITypeId } from 'types';

interface SocialMenuItemProps extends DropdownItemProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

const SocialMenuItem = ({
  children,
  onClick,
  ...rest
}: SocialMenuItemProps) => (
  <Dropdown.Item onClick={onClick} {...rest}>
    {children}
  </Dropdown.Item>
);

const AddPage = () => {
  const navigate = useNavigate();

  const handleNewAPI = (apiTypeId: APITypeId) => () => {
    const propertyId = getCurrentPropertyId();

    logger.info(`ConnectSocialPages.handleNewAPI - type ${apiTypeId}`);
    sessionStorage.setItem('isAddingAPI', 'true');
    sessionStorage.setItem('connectingAPITypeId', apiTypeId.toString());
    addNewAPI({
      propertyId,
      apiTypeId,
    });
  };

  return (
    <Dropdown gutter={8}>
      <Dropdown.Button
        data-group
        data-cy-id="addPageButton"
        as={Button}
        lineHeight="shorter"
        filter="none"
        py={3.5}
        px={4}
        w="full"
        bg="gray.100"
        color="gray.600"
        textAlign="left"
        boxShadow="border"
        _hover={{
          bg: 'gray.200',
        }}
        _active={{
          boxShadow: 'outline',
        }}
        _disabled={{
          opacity: 0.36,
        }}
        leftIcon={<AddIcon />}
        rightIcon={
          <HStack spacing="-2.5px">
            <SocialPageCircle
              bg="gray.100"
              size={5}
              _groupHover={{ bg: 'gray.200' }}
            >
              <SocialFacebookIcon size={3.5} />
            </SocialPageCircle>
            <SocialPageCircle
              bg="gray.100"
              size={5}
              _groupHover={{ bg: 'gray.200' }}
            >
              <SocialTwitterIcon size={3.5} />
            </SocialPageCircle>
            <SocialPageCircle
              bg="gray.100"
              size={5}
              _groupHover={{ bg: 'gray.200' }}
            >
              <SocialInstagramIcon size={3.5} />
            </SocialPageCircle>
            <SocialPageCircle
              bg="gray.100"
              size={5}
              _groupHover={{ bg: 'gray.200' }}
            >
              <SocialLinkedinIcon size={3.5} />
            </SocialPageCircle>
            <SocialPageCircle
              bg="gray.100"
              size={5}
              _groupHover={{ bg: 'gray.200' }}
            >
              <SocialTiktokIcon size={3.5} />
            </SocialPageCircle>
          </HStack>
        }
      >
        Add page
      </Dropdown.Button>
      <Dropdown.List>
        <SocialMenuItem
          onClick={() => navigate('/settings/pages?addNewPage=facebook')}
          LeftIcon={SocialFacebookIcon}
        >
          Facebook
        </SocialMenuItem>
        <SocialMenuItem
          onClick={() => navigate('/settings/pages?addNewPage=twitter')}
          LeftIcon={SocialTwitterIcon}
        >
          Twitter
        </SocialMenuItem>
        <SocialMenuItem
          onClick={handleNewAPI(API_TYPE_IDS.LINKEDIN)}
          LeftIcon={SocialLinkedinIcon}
        >
          LinkedIn
        </SocialMenuItem>
        <SocialMenuItem
          onClick={() => navigate('/settings/pages?addNewPage=instagram')}
          LeftIcon={SocialInstagramIcon}
        >
          Instagram
        </SocialMenuItem>
        <SocialMenuItem
          onClick={handleNewAPI(API_TYPE_IDS.TIKTOK)}
          LeftIcon={SocialTiktokIcon}
        >
          TikTok
        </SocialMenuItem>
      </Dropdown.List>
    </Dropdown>
  );
};

export default AddPage;
