/* eslint no-param-reassign:"off" */

import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { Children, cloneElement } from 'react';

import { getAPITypeId, getCurrentAccountAPIId } from 'common/accountAPIs';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { getAutofeedSettings } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import { generateGuid } from 'common/string';
import * as tracker from 'common/tracker';
import { isDefined, isNullOrUndefined } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import FlashMessagesContext from 'context/FlashMessageContext';
import {
  getBlockedErrors,
  ignoreMessage,
  isBlockedError,
  isProtectedError,
} from 'helpers/flashMessages';
import {
  getAutofeedSettingsTrackingParams,
  getNetworkAndPageName,
} from 'helpers/tracking';
import * as topics from 'pubsub/topics';

const {
  COMMAND_FLASH_MESSAGES_ADD_MESSAGE,
  COMMAND_FLASH_MESSAGES_DELETE_MESSAGE,
  COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE,
  COMMAND_FLASH_MESSAGES_DELETE_MESSAGES,
} = topics;

/**
 * Super-global application container
 */

export default class FlashMessagesProvider extends BaseComponent {
  /**
   * Static methods
   */

  static initialMessages() {
    const messages = sessionStorage.getItem('flashMessages');
    if (!isNullOrUndefined(messages)) {
      return JSON.parse(messages);
    }
    return [];
  }

  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      flashMessages: FlashMessagesProvider.initialMessages(),
    };
    this._bind(
      'addMessage',
      'deleteMessage',
      'deleteMessages',
      'deleteMessageType',
      'removeMessagesOnReload',
    );
  }

  componentDidMount() {
    logger.info('FlashMessagesProvider:componentDidMount');

    window.addEventListener('beforeunload', this.handleWindowUnload);

    // Handlers for pub-sub requests and commands
    logger.info(
      `PubSub: subscribe ${COMMAND_FLASH_MESSAGES_ADD_MESSAGE} in context/FlashMessagesProvider.componentDidMount`,
    );
    logger.info(
      `PubSub: subscribe ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGE} in context/FlashMessagesProvider.componentDidMount`,
    );
    logger.info(
      `PubSub: subscribe ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGES} in context/FlashMessagesProvider.componentDidMount`,
    );
    logger.info(
      `PubSub: subscribe ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE} in context/FlashMessagesProvider.componentDidMount`,
    );
    this.tokens = [
      // Flash message command handlers
      PubSub.subscribe(COMMAND_FLASH_MESSAGES_ADD_MESSAGE, (msg, data) => {
        this.addMessage(data);
      }),
      PubSub.subscribe(COMMAND_FLASH_MESSAGES_DELETE_MESSAGE, (msg, data) =>
        this.deleteMessage({ id: data.id, markAsBlocked: data.markAsBlocked }),
      ),
      PubSub.subscribe(COMMAND_FLASH_MESSAGES_DELETE_MESSAGES, () =>
        this.deleteMessages(),
      ),
      PubSub.subscribe(
        COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE,
        (msg, data) => this.deleteMessageType(data.type),
      ),
    ];
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   return this._compare('FlashMessagesProvider', { nextProps, nextState });
  // }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleWindowUnload);

    logger.info(
      `PubSub: unsubscribe ${COMMAND_FLASH_MESSAGES_ADD_MESSAGE} in context/FlashMessagesProvider.componentWillUnmount`,
    );
    logger.info(
      `PubSub: unsubscribe ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGE} in context/FlashMessagesProvider.componentWillUnmount`,
    );
    logger.info(
      `PubSub: unsubscribe ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGES} in context/FlashMessagesProvider.componentWillUnmount`,
    );
    logger.info(
      `PubSub: unsubscribe ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE} in context/FlashMessagesProvider.componentWillUnmount`,
    );
    this.tokens.forEach((token) => {
      PubSub.unsubscribe(token);
    });
  }

  /**
   * Flash messages event handlers and helper methods
   * @param {import('types').FlashMessage} message
   * @returns The ID of the created message
   */
  addMessage(message) {
    const newMessageId = generateGuid();
    this.setState((prevState) => {
      const nextState = { ...prevState };

      // Retrieve existing messages
      const messages = nextState.flashMessages;

      // Ignore messages or message patterns we want to suppress
      if (!ignoreMessage(message.text)) {
        // If the message is already in the flash messages, don't add
        const matchingMessages = messages.filter(
          (flashMessage) =>
            flashMessage.text === message.text &&
            flashMessage.type === message.type,
        );
        if (matchingMessages.length === 0) {
          logger.info(
            `FlashMessagesProvider:addMessage ${
              message.type
            } ${message.text.substr(0, 32)}${
              message.text.length > 20 ? '...' : ''
            }`,
          );
          message.id = newMessageId;
          messages.push(message);
          sessionStorage.setItem('flashMessages', JSON.stringify(messages));
          nextState.flashMessages = messages;

          const accountAPIId = getCurrentAccountAPIId();
          const globalInfo = getGlobalInfo();
          if (globalInfo) {
            tracker.track({
              eventName: 'Display Frontend Error',
              trackingParams: {
                ...getAutofeedSettingsTrackingParams({
                  autofeedSettings: getAutofeedSettings(),
                }),
                'Network - Social Page': getNetworkAndPageName({
                  accountAPIId,
                }),
                'Account API Id': accountAPIId,
                'Social Network': getSocialNetworkName({
                  apiTypeId: getAPITypeId({ accountAPIId }),
                }),
                '# Errors Displayed': messages.length,
                'Error Message': message.text,
                'Error Type': message.messageCategory,
                'Error Location': 'Flash Message',
              },
            });
          }
        }
      }

      return nextState;
    });
    return newMessageId;
  }

  deleteMessage({ id, markAsBlocked = true } = {}) {
    if (isNullOrUndefined(id)) {
      return;
    }

    logger.info(`FlashMessagesProvider:deleteMessage ${id}`);

    this.setState((prevState) => {
      const nextState = { ...prevState };

      const messages = prevState.flashMessages;
      let message;
      const index = messages.findIndex((msg) => msg.id === id);
      if (index !== -1) {
        message = messages[index];
        messages.splice(index, 1);
      }
      nextState.flashMessages = messages;
      sessionStorage.setItem('flashMessages', JSON.stringify(messages));

      if (markAsBlocked && isDefined(message)) {
        const blockedErrors = getBlockedErrors();
        blockedErrors.push(message.text);
        sessionStorage.setItem('blockedErrors', JSON.stringify(blockedErrors));
      }

      return nextState;
    });
  }

  deleteMessages() {
    logger.info('FlashMessagesProvider:deleteMessages');

    this.setState({
      flashMessages: [],
    });
    sessionStorage.setItem('flashMessages', JSON.stringify([]));
  }

  deleteMessageType(messageType) {
    logger.info(`FlashMessagesProvider:deleteMessageType ${messageType}`);

    const messages = this.state.flashMessages;
    const matchingMessages = messages.filter(
      (flashMessage) => flashMessage.type === messageType,
    );
    if (matchingMessages.length > 0) {
      for (let i = 0; i < matchingMessages.length; i += 1) {
        // Delete message but do NOT add to list of blocked messages
        this.deleteMessage({
          id: matchingMessages[i].id,
          markAsBlocked: false,
        });
      }
    }
  }

  removeMessagesOnReload() {
    logger.info('FlashMessagesProvider:removeMessagesOnReload');

    // Remove any dismissed or unimportant error messages
    const messages = this.state.flashMessages;
    messages.forEach((message) => {
      const isBlocked = isBlockedError(message);
      const isProtected = isProtectedError(message);
      if (isBlocked && !isProtected) {
        this.deleteMessage({
          id: message.id,
          markAsBlocked: false,
        });
      }
    });
  }

  /**
   * Event handlers
   */

  handleWindowUnload() {
    logger.info('FlashMessagesProvider:handleWindowUnload');

    // Remove any dismissed or unimportant error messages
    const { flashMessages } = window;
    if (isDefined(flashMessages)) {
      this.removeMessagesOnReload();
    }
  }

  /**
   * Render method
   */

  render() {
    const flashMessagesContext = {
      flashMessages: {
        addMessage: this.addMessage,
        deleteMessage: this.deleteMessage,
        deleteMessageType: this.deleteMessageType,
        deleteMessages: this.deleteMessages,
        messages: this.state.flashMessages,
        removeMessagesOnReload: this.removeMessagesOnReload,
      },
    };

    const componentChildren = Children.map(this.props.children, (child) =>
      cloneElement(child, {
        flashMessages: { ...flashMessagesContext },
      }),
    );

    return (
      <FlashMessagesContext.Provider value={flashMessagesContext}>
        {componentChildren}
      </FlashMessagesContext.Provider>
    );
  }
}

FlashMessagesProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
