import { Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useNavigate } from 'react-router-dom';

import { getReconnectSocialPageLink } from 'common/settings';
import { SocialAPI } from 'types';

export function useTwitterDisconnectToastProps({
  disconnectedTwitterPages,
  closeToast,
}: {
  /**
   * The account APIs that were disconnected due to a Twitter app disconnection
   */
  disconnectedTwitterPages: SocialAPI[];
  closeToast: () => void;
}) {
  const navigate = useNavigate();

  const handleReconnect = () => {
    navigate(
      getReconnectSocialPageLink({ accountAPI: disconnectedTwitterPages[0] }),
    );
    closeToast();
  };

  return {
    title: 'Twitter App disconnected',
    description: 'The connection is required for sharing to Twitter',
    buttons: (
      <Button variant="secondary" onClick={handleReconnect}>
        Reconnect App
      </Button>
    ),
  };
}
