import { Box, Circle, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex
      bg="gray.100"
      py={2.5}
      pl={3}
      pr={4}
      boxShadow="borderBottom"
      alignItems="center"
      justifyContent="space-between"
      gap={6}
    >
      <Flex alignItems="center" gap={1} minW={0}>
        {children}
      </Flex>
      <Circle size={2} bg="red.600" />
    </Flex>
  );
};

const Body = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex p={4} flexDirection="column" gap={3} alignItems="flex-start">
      {children}
    </Flex>
  );
};

const HealthMenuItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      position="relative"
      borderRadius="md"
      overflow="hidden"
      _before={{
        content: '""',
        boxShadow: 'border',
        position: 'absolute',
        top: 0,
        left: 0,
        height: 'full',
        width: 'full',
        pointerEvents: 'none',
        borderRadius: 'md',
        zIndex: 1,
      }}
    >
      {children}
    </Box>
  );
};

HealthMenuItem.Header = Header;
HealthMenuItem.Body = Body;

export default HealthMenuItem;
