/* eslint consistent-return:"off" */

import { Box, Grid } from '@ebx-ui/ebx-ui-component-library-sdk';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import * as topics from 'pubsub/topics';
import { Children, cloneElement, createRef } from 'react';
import Immutable from 'seamless-immutable';
import { shallow } from 'zustand/shallow';

import {
  getCurrentAccountAPIId,
  getCurrentProperty,
  getCurrentPropertyId,
  getPropertyPermission,
} from 'common/accountAPIs';
import {
  ACCOUNT_STATES,
  FRONTEND_PAGES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { isOnPage } from 'common/path';
import { withLocation } from 'common/routing';
import { generateGuid } from 'common/string';
import { isNull, isNullOrUndefined } from 'common/utility';
import { isMatchMedia, location } from 'common/window';
import BaseComponent from 'components/BaseComponent';
import Header from 'components/header/Header';
import PageDetails from 'components/header/PageDetails';
import FailedShareDialog from 'components/home/FailedShareDialog';
import HighPriorityErrorsBanner from 'components/misc/HighPriorityErrorsBanner';
import Sidebar from 'components/sidebar/Sidebar';
import withGlobalInfo from 'context/withGlobalInfo';
import { scrollToTop } from 'helpers/window';
import useChameleon from 'hooks/useChameleon';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';
import { useComposeBoxScheduleMetricsActions } from 'state/composeBoxScheduleMetrics';
import { useComposeBoxSharesStore } from 'state/composeBoxShares';
import { useIsShowingHighPriorityErrorType } from 'state/highPriorityErrorType';

const { REQUEST_ADD_COMPOSE_BOX_ITEM, RESPONSE_ADD_COMPOSE_BOX_ITEM } = topics;

class Master extends BaseComponent {
  constructor(props) {
    super(props);
    const isMobileWindowWidth = isMatchMedia();

    this.state = {
      isMobileWindowWidth,
    };
    this.pageRef = createRef();
    this._bind('handleWindowResize', 'onAddComposeBoxItem');
    this.tokens = [];
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    // Scroll to top if necessary
    scrollToTop();

    window.addEventListener('resize', this.handleWindowResize);

    logger.info(
      `PubSub: subscribe ${REQUEST_ADD_COMPOSE_BOX_ITEM} in pages/Master.componentDidMount`,
    );
    this.tokens = [
      PubSub.subscribe(REQUEST_ADD_COMPOSE_BOX_ITEM, this.onAddComposeBoxItem),
    ];
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Master', { nextProps, nextState });
  }

  componentWillUnmount() {
    this.tokens.forEach((token) => {
      PubSub.unsubscribe(token);
    });

    window.removeEventListener('resize', this.handleWindowResize);
  }

  /**
   * Event handlers
   */

  handleWindowResize() {
    const isMobileWindowWidth = this.state.isMobileWindowWidth;
    if (
      (!isMatchMedia() && isMobileWindowWidth) ||
      (isMatchMedia() && !isMobileWindowWidth)
    ) {
      this.setState((prevState) => ({
        isMobileWindowWidth: !prevState.isMobileWindowWidth,
      }));
    }
  }

  /**
   * Pub-sub event handlers
   */

  onAddComposeBoxItem(message, data) {
    logger.info('Master:onAddComposeBoxItem');

    const { requestId } = data;
    let { mediaItem } = data;
    const guid = generateGuid();
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
    const mediaId = MediaItem.getMediaId({ mediaItem });
    const existingHashtags = MediaItem.getHashtags({ mediaItem });
    const existingMentions = MediaItem.getMentions({ mediaItem });
    mediaItem = MediaItem.setHashtags({
      mediaItem,
      fieldValue: existingHashtags.concat([]),
    });
    mediaItem = MediaItem.setTrackingDetails({
      mediaItem,
      fieldValue: {
        hashtagsSuggested: [],
        mentionsSuggested: [],
      },
    });
    mediaItem = MediaItem.setMentions({
      mediaItem,
      fieldValue: existingMentions.concat([]),
    });
    const previews = [
      {
        accountAPIId,
        guid,
        isHidden: false,
        isShared: false,
        mediaId,
        mediaItem: Immutable(mediaItem),
      },
    ];
    this.props.resetScheduleMetrics();
    this.props.openComposeBox(previews);
    logger.info(
      `PubSub: publish ${RESPONSE_ADD_COMPOSE_BOX_ITEM}.${requestId} in pages/Master.onAddComposeBoxItem`,
    );
    PubSub.publish(`${RESPONSE_ADD_COMPOSE_BOX_ITEM}.${requestId}`, guid);
  }

  /**
   * Render methods
   */

  render() {
    const loc = this.props.location;
    const isNewslettersAdPage = isOnPage({
      page: FRONTEND_PAGES.NEWSLETTERS_AD,
      location: loc,
    });

    document.body.classList.add('facelift');

    const globalInfo = getGlobalInfo();
    if (isNull(globalInfo)) {
      // Something has gone badly wrong, better log out to be on the safe side
      location.href = '/logout';
      return REACT_PREVENT_RENDER;
    }
    const currentProperty = getCurrentProperty({
      globalInfo,
    });
    // Something else has gone badly wrong, log out to be on the safe side
    if (isNullOrUndefined(currentProperty)) {
      location.href = '/logout';
      return REACT_PREVENT_RENDER;
    }
    const propertyState = currentProperty.propertyStateId;
    const permissionTypeId = getPropertyPermission({
      propertyId: getCurrentPropertyId(),
    });

    const {
      children,
      eventHandlers,
      failedShares,
      setFailedShares,
      setTotalShares,
      totalShares,
    } = this.props;

    const wrappedChildren = Children.map(children, (child) =>
      cloneElement(child, {
        guid: '',
        isMobileWindowWidth: this.state.isMobileWindowWidth,
        isUnderSetup:
          propertyState === ACCOUNT_STATES.NEWSIGNUP ||
          propertyState === ACCOUNT_STATES.UNDERSETUP,

        permissionTypeId,
        showMobileMenu: this.props.showMobileMenu,
        pageRef: this.pageRef.current,
        eventHandlers: {
          handleMobileMenuClose: eventHandlers.handleMobileMenuClose,
          handleMobileMenuToggle: eventHandlers.handleMobileMenuToggle,
        },
        isInitialisingHighPriorityErrorState:
          this.props.isInitialisingHighPriorityErrorState,
      }),
    );

    return (
      <>
        <HighPriorityErrorsBanner />
        <Box
          ref={this.pageRef}
          pl={!this.state.isMobileWindowWidth && 80}
          position="relative"
          className={clsx(
            'page facelift',
            this.props.isShowingHighPriorityError && 'with-banner',
          )}
        >
          <Header
            showMobileMenu={this.props.showMobileMenu}
            onMobileMenuToggle={this.props.eventHandlers.handleMobileMenuToggle}
          />
          {(!isNewslettersAdPage || this.props.showMobileMenu) && (
            <Sidebar
              accountAPIId={getCurrentAccountAPIId({
                globalInfo,
              })}
              propertyId={getCurrentPropertyId({
                globalInfo,
              })}
              showMobileMenu={this.props.showMobileMenu}
              eventHandlers={{
                handleMobileMenuClose:
                  this.props.eventHandlers.handleMobileMenuClose,
                handleMobileMenuToggle:
                  this.props.eventHandlers.handleMobileMenuToggle,
              }}
            />
          )}
          <Box
            maxHeight="full"
            height="full"
            overflowY="auto"
            id="page-content"
          >
            <Box
              flexShrink={0}
              minW={{
                md: '880px',
              }}
              w="full"
              marginX="auto"
            >
              {!isNewslettersAdPage && (
                <Grid>
                  <Grid.GridItem colSpan={{ base: 2, md: 12 }}>
                    <Box
                      pt={5}
                      display={this.props.showMobileMenu ? 'none' : 'block'}
                      className="hide-on-mobile facelift"
                    >
                      <PageDetails />
                    </Box>
                  </Grid.GridItem>
                </Grid>
              )}
              {wrappedChildren}
            </Box>
          </Box>
          {failedShares.length > 0 && (
            <FailedShareDialog
              failedShares={failedShares}
              totalShares={totalShares}
              onDismiss={() => {
                setFailedShares([]);
                setTotalShares(1);
              }}
            />
          )}
        </Box>
      </>
    );
  }
}

Master.propTypes = {
  showMobileMenu: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleMobileMenuClose: PropTypes.func.isRequired,
    handleMobileMenuToggle: PropTypes.func.isRequired,
  }).isRequired,
};

const MasterWrapper = (props) => {
  const openComposeBox = useComposeBoxOpenStore(
    (state) => state.openComposeBox,
  );
  const { totalShares, setTotalShares, failedShares, setFailedShares } =
    useComposeBoxSharesStore(
      (state) => ({
        totalShares: state.totalShares,
        setTotalShares: state.setTotalShares,
        failedShares: state.failedShares,
        setFailedShares: state.setFailedShares,
      }),
      shallow,
    );
  const { resetScheduleMetrics } = useComposeBoxScheduleMetricsActions();
  const isShowingHighPriorityError = useIsShowingHighPriorityErrorType();

  useChameleon();

  return (
    <Master
      {...props}
      openComposeBox={openComposeBox}
      totalShares={totalShares}
      setTotalShares={setTotalShares}
      failedShares={failedShares}
      setFailedShares={setFailedShares}
      resetScheduleMetrics={resetScheduleMetrics}
      isShowingHighPriorityError={isShowingHighPriorityError}
    />
  );
};

export default withGlobalInfo(withLocation(MasterWrapper));
