import {
  Button,
  Card,
  Checkbox,
  ChevronLeftIcon,
  Flex,
  FormControl,
  Grid,
  Heading,
  HStack,
  Input,
  Modal,
  Spacer,
  Text,
  useToast,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import getAPIs from 'api/getAPIs';
import postPropertySetup from 'api/postPropertySetup';
import postReactivateProperty from 'api/postReactivateProperty';
import postServiceAuth from 'api/postServiceAuth';
import postShutdownProperty from 'api/postShutdownProperty';
import putProperties from 'api/putProperties';
import * as authentication from 'common/authentication';
import { API_STATES } from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import { getErrorMessage } from 'common/errorHandling';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import { ErrorWithStatusSchema } from 'common/schemas';
import {
  getSocialNetWorkIconByAPITypeId,
  getURNName,
  isSocialNetwork,
} from 'common/social';
import { isNullOrUndefinedOrEmpty } from 'common/string';
import { convertToSocialPageURN, extractPropertyId } from 'common/urn';
import { isNullOrUndefined } from 'common/utility';
import SocialPageCircle from 'components/misc/SocialPageCircle';
import { Property, SocialAPI } from 'types';
import CRMIdentifierSettingGridItem from './CRMIdentifierSettingGridItem';
import LanguageGridSettingItem from './LanguageSettingGridItem';
import PropertyNameSettingGridItem from './PropertyNameSettingGridItem';
import TimeZoneGridSettingItem from './TimezoneSettingGridItem';

interface PropertyDetailsInterface {
  property: Property;
  socialPages: Array<SocialAPI>;
  onBackClick: () => void;
  onAccountAPIToolsClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onImpersonateClick: (propertyId: string) => void;
  onShutdownConfirm: (property: Property) => void;
  onReactivateSubmit: (property: Property) => void;
  onUpdateSettings: () => void;
}

const propertyDisplayDetails: Array<{
  heading: string;
  getValueFromProperty: (property: Property) => string;
}> = [
  {
    heading: 'Property URN',
    getValueFromProperty: (property) => property.propertyURN,
  },
  {
    heading: 'Property State',
    getValueFromProperty: (property) => {
      if (property.approxEndTime) {
        return 'SHUTDOWN';
      }
      return property.propertyState;
    },
  },
  {
    heading: 'Root URL',
    getValueFromProperty: (property) => property.propertyRootURL,
  },
  {
    heading: 'Property Type',
    getValueFromProperty: (property) => property.propertyType,
  },
  {
    heading: 'Start Date',
    getValueFromProperty: (property) =>
      new Date(property.approxStartTime * 1000).toUTCString(),
  },
  {
    heading: 'End Date',
    getValueFromProperty: (property) =>
      property.approxEndTime === 0
        ? 'No End Date Available'
        : new Date(property.approxEndTime * 1000).toUTCString(),
  },
  {
    heading: 'Time Created',
    getValueFromProperty: (property) =>
      new Date(property.timeCreated * 1000).toUTCString(),
  },
];

const PropertyDetails = ({
  property,
  socialPages,
  onBackClick,
  onAccountAPIToolsClick,
  onImpersonateClick,
  onShutdownConfirm,
  onReactivateSubmit,
  onUpdateSettings,
}: PropertyDetailsInterface) => {
  const [isConfirmShutdownModalOpen, setIsConfirmShutdownModalOpen] =
    useState(false);
  const [isReactivateLoading, setIsReactivateLoading] =
    useState<boolean>(false);
  const [reactivateErrorMessage, setReactivateErrorMessage] = useState<
    string | null
  >(null);
  const toast = useToast();
  const [pagesForSetup, setPagesForSetup] = useState<SocialAPI[]>([]);
  const [isPropertySetupModalOpen, setShowPropertySetupModal] = useState(false);
  const [checkedPages, setCheckedPages] = useState<SocialAPI[]>([]);
  const [isStaffUser, setIsStaffUser] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCRMIdentifier, setSelectedCRMIdentifier] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [isPropertyNameChange, setIsPropertyNameChanged] = useState(false);
  const [validCRMIdentifier, setValidCRMIdentifier] = useState(true);
  const [changesMade, setChangesMade] = useState(false);

  const isSubmitDisabled = Object.keys(checkedPages).length === 0;
  const isPropertySuspended =
    Boolean(property.approxEndTime) &&
    property.approxEndTime <= getUnixTimestamp();

  useEffect(() => {
    const checkForUserType = async () => {
      const isStaff = await authentication.isStaffUser();
      setIsStaffUser(isStaff);
    };

    checkForUserType();
  }, []);

  const handleShutdownConfirm: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    const { propertyURN } = property;
    const propertyId = extractPropertyId(property.propertyURN).toString();

    if (isNullOrUndefined(propertyURN)) {
      return;
    }

    try {
      await postShutdownProperty({ propertyURN });

      const updatedPropertyData: Property = {
        ...property,
        approxEndTime: getUnixTimestamp(),
      };

      onShutdownConfirm(updatedPropertyData);
      setIsConfirmShutdownModalOpen(false);

      toast({
        title: `Property ${property.propertyName} (${propertyId}) shutdown successfully`,
        variant: 'success',
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description:
          'An error occurred while shutting down this property. Please try again and contact support if the problem persists',
        variant: 'error',
      });
    }
  };

  const handleReactivateSubmit: React.FormEventHandler<
    HTMLFormElement
  > = async (event) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      propertyToolsReactivationId: { value: string };
      propertyToolsReactivationEmail: { value: string };
    };

    const userEmail = target.propertyToolsReactivationEmail.value;

    if (isNullOrUndefinedOrEmpty(userEmail)) {
      setReactivateErrorMessage(
        FRONTEND_ERROR_MESSAGES['property-tools-reactivate-empty-email'],
      );
    } else {
      reactivateProperty(userEmail);
    }
  };

  const handleSetupPages: React.MouseEventHandler<
    HTMLButtonElement
  > = async () => {
    try {
      const socialAPIURNs = socialPages
        .filter((page) => isSocialNetwork({ apiTypeId: page.apiTypeId }))
        .map((page) => {
          const socialPageURN = convertToSocialPageURN(
            getURNName({ apiTypeId: page.apiTypeId }),
            page.accountAPIId,
          );
          return socialPageURN;
        });

      if (socialAPIURNs.length === 0) {
        toast({
          title: 'No Pages available for setup',
          description: `No Pages found in a valid state for setup.`,
          variant: 'chilledError',
        });
        return;
      }

      const allSocialPagesMap = await getAPIs({ socialAPIURNs });
      const allSocialPages = Object.values(allSocialPagesMap);

      const pagesSetupInProgress = allSocialPages
        .filter((page) => page.isSetupInProgress)
        .map((page) => {
          return `${page.apiPostName}:${getURNName({
            apiTypeId: page.apiTypeId,
          })?.toUpperCase()}`;
        });

      if (pagesSetupInProgress.length > 0) {
        toast({
          title: 'Pages already submitted for setup',
          description: `Setup already in progress for the following Pages in this Property: (${pagesSetupInProgress})`,
          variant: 'chilledError',
        });
        return;
      }

      const pagesUnderSetup = allSocialPages.filter(
        (page) => page.apiStateId === API_STATES.UNDER_SETUP,
      );

      if (pagesUnderSetup.length === 0) {
        if (property.propertyState === 'UNDERSETUP') {
          toast({
            title: 'Something went wrong',
            description:
              'Pages must be added in order to trigger Property setup.',
            variant: 'chilledError',
          });
          return;
        }
        if (property.propertyState === 'ACTIVE') {
          toast({
            title: "No Pages found 'Under Setup'",
            description:
              'This Property is active, and there are no Pages available to trigger the setup process for.',
            variant: 'chilledError',
          });
          return;
        }

        toast({
          title: 'Something went wrong',
          description: 'Property is in an invalid state to trigger setup.',
          variant: 'chilledError',
        });
        return;
      }

      if (
        (property.propertyState === 'ACTIVE' ||
          property.propertyState === 'UNDERSETUP') &&
        pagesUnderSetup.length > 0 &&
        pagesSetupInProgress.length === 0
      ) {
        setPagesForSetup(pagesUnderSetup);
        setShowPropertySetupModal(true);
      }
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description:
          'An error occurred while fetching Pages for this Property. Please try again and contact support if the problem persists.',
        variant: 'error',
      });
    }
  };

  const reactivateProperty = async (userEmail: string) => {
    setIsReactivateLoading(true);
    const propertyId = extractPropertyId(property.propertyURN).toString();

    try {
      await postReactivateProperty({
        propertyURN: property.propertyURN,
        initialPropertyAdminEmail: userEmail,
      });

      const updatedPropertyData: Property = {
        ...property,
        approxEndTime: 0,
      };
      onReactivateSubmit(updatedPropertyData);

      toast({
        title: `Property ${property.propertyName} (${propertyId}) reactivated successfully`,
        variant: 'success',
      });
    } catch (error) {
      const parsedError = ErrorWithStatusSchema.safeParse(error);

      if (parsedError.success && parsedError.data.status !== 500) {
        setReactivateErrorMessage(getErrorMessage(error));
      } else {
        toast({
          title: 'Something went wrong',
          description:
            'An error occurred while reactivating this property. Please try again and contact support if the problem persists',
          variant: 'error',
        });
      }
    } finally {
      setIsReactivateLoading(false);
    }
  };

  const handleCheckboxItems = (page: SocialAPI) => {
    const isPageAlreadySelected = !isNullOrUndefined(
      checkedPages[page.accountAPIId],
    );

    const newSelectedPages = { ...checkedPages };
    if (isPageAlreadySelected) {
      delete newSelectedPages[page.accountAPIId];
    } else {
      newSelectedPages[page.accountAPIId] = page;
    }

    setCheckedPages(newSelectedPages);
  };

  const handleSaveClick = async (): Promise<void> => {
    const userCSToken = authentication.getClientServiceToken();
    const propertyId = extractPropertyId(property.propertyURN).toString();

    const newTimezone =
      selectedTimezone !== '' ? selectedTimezone : property.timezone;
    const newLanguage =
      selectedLanguage !== '' ? selectedLanguage : property.language;

    const newCRMIdentifier =
      selectedCRMIdentifier !== ''
        ? selectedCRMIdentifier
        : property.crmIdentifier;

    try {
      const impersonateCSToken = await postServiceAuth({
        overridePropertyURN: property.propertyURN,
      });
      authentication.setClientServiceToken(impersonateCSToken);

      await putProperties({
        propertyId,
        propertyTimezone: newTimezone,
        language: newLanguage,
        propertyCRMIdentifier: newCRMIdentifier,
        ...(isPropertyNameChange ? { propertyName: selectedName } : {}),
      });

      toast({
        variant: 'success',
        title: 'Settings have been successfully updated',
      });
    } catch (error) {
      console.error(error);
      toast({
        variant: 'chilledError',
        title: 'Something went wrong',
        description:
          'Please try again. If the error persists, contact someone in Tech.',
      });
    } finally {
      authentication.setClientServiceToken(userCSToken);
      setChangesMade(false);
      setIsPropertyNameChanged(false);
    }
  };

  const handleSubmitPages = async () => {
    const propertyId = extractPropertyId(property.propertyURN).toString();

    try {
      const selectedPages = Object.values(checkedPages);
      let propertyURN: string = '';
      const selectedPageURNs: string[] = [];

      selectedPages.forEach((page) => {
        const selectedPageURN = convertToSocialPageURN(
          getURNName({ apiTypeId: page.apiTypeId }),
          page.accountAPIId,
        );

        selectedPageURNs.push(selectedPageURN);

        if (page.propertyURN !== undefined) {
          propertyURN = page.propertyURN;
        }
      });

      await postPropertySetup({
        propertyURN,
        socialAPIURNs: selectedPageURNs,
      });

      toast({
        title: `Successfully submitted ${property.propertyName} (${propertyId}) for setup.`,
        variant: 'success',
      });
      setShowPropertySetupModal(false);
    } catch {
      toast({
        title: 'Something went wrong',
        description:
          'An error occurred while submitting this Property for setup. Please try again and contact support if the problem persists.',
        variant: 'error',
      });
    }
  };

  return (
    <Flex flexDirection="column" gap={4}>
      <div>
        <Flex>
          <Button variant="secondary" gap={1} onClick={onBackClick}>
            <ChevronLeftIcon /> Back to search
          </Button>
          <Spacer />
          <Button variant="secondary" gap={1} onClick={onAccountAPIToolsClick}>
            Account API Tools
          </Button>
          <Spacer />
          <Button
            variant="secondary"
            gap={1}
            onClick={() => onImpersonateClick(property.propertyId.toString())}
          >
            Impersonate Property
          </Button>
        </Flex>
      </div>
      <Card>
        <Card.Content gap={4} flexGrow={1}>
          <Card.Title>Property Details</Card.Title>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} px={0}>
            <PropertyNameSettingGridItem
              setChangesMade={setChangesMade}
              setSelectedPropertyName={setSelectedName}
              property={property}
              isPropertyNameChanged={setIsPropertyNameChanged}
            />
            {propertyDisplayDetails.map(({ getValueFromProperty, heading }) => (
              <Grid.GridItem key={heading}>
                <Heading variant="h6" color="gray.600" mb={1}>
                  {heading}:
                </Heading>
                <Text size="sm">{getValueFromProperty(property)}</Text>
              </Grid.GridItem>
            ))}
            <Grid.GridItem>
              <Heading variant="h6" color="gray.600" mb={1}>
                Active pages:
              </Heading>
              <Text size="sm">
                {
                  socialPages.filter(
                    (page) => page.apiStateId === API_STATES.ACTIVE,
                  ).length
                }
              </Text>
            </Grid.GridItem>
            <Grid.GridItem>
              <Heading variant="h6" color="gray.600" mb={1}>
                Disconnected pages:
              </Heading>
              <Text size="sm">
                {
                  socialPages.filter(
                    (page) => page.apiStateId === API_STATES.BAD_AUTH,
                  ).length
                }
              </Text>
            </Grid.GridItem>
            <TimeZoneGridSettingItem
              setChangesMade={setChangesMade}
              setSelectedTimezone={setSelectedTimezone}
              property={property}
            />
            <LanguageGridSettingItem
              setChangesMade={setChangesMade}
              setSelectedLanguage={setSelectedLanguage}
              property={property}
            />
            <CRMIdentifierSettingGridItem
              setChangesMade={setChangesMade}
              setSelectedCRMIdentifier={setSelectedCRMIdentifier}
              setValidCRMIdentifier={setValidCRMIdentifier}
              validCRMIdentifier={validCRMIdentifier}
              property={property}
            />
          </Grid>
          {changesMade && validCRMIdentifier && (
            <Button
              loadingText="Saving"
              variant="primary"
              onClick={handleSaveClick}
              w="fit-content"
              ml="auto"
            >
              Save changes
            </Button>
          )}
        </Card.Content>
      </Card>
      {isStaffUser && (
        <>
          <Card alignItems="end">
            <Card.Content flexGrow={1}>
              <Card.Title>Update Property Settings</Card.Title>
              <Card.Description>
                Update the settings for the property.
              </Card.Description>
            </Card.Content>
            <Button variant="secondary" ml="auto" onClick={onUpdateSettings}>
              Settings
            </Button>
          </Card>
          <Card alignItems="end">
            <Card.Content flexGrow={1}>
              <Card.Title>Setup Property or Pages</Card.Title>
              <Card.Description>
                Provides a list of pages (if any) that can be pushed for setup.
              </Card.Description>
            </Card.Content>
            <Button variant="secondary" onClick={handleSetupPages} ml="auto">
              Setup
            </Button>
          </Card>
          <Card alignItems="end">
            {isPropertySuspended ? (
              <Card.Content flexGrow={1}>
                <Card.Title>Shutdown or Reactivate Property</Card.Title>
                <Card.Description mb={2}>
                  This Property has been deactivated. You must provide the email
                  address for a current Echobox user. They will be added to the
                  reactivated property.
                </Card.Description>
                <form onSubmit={handleReactivateSubmit}>
                  <FormControl isInvalid={reactivateErrorMessage !== null}>
                    <HStack>
                      <Input
                        type="text"
                        placeholder="Enter an email address..."
                        id="propertyToolsReactivationEmail"
                        flexShrink={0}
                        flexGrow={1}
                        w="auto"
                      />
                      <Button
                        type="submit"
                        variant="secondary"
                        loadingText="Submitting"
                        flexShrink={0}
                        isLoading={isReactivateLoading}
                      >
                        Reactivate
                      </Button>
                    </HStack>
                    {reactivateErrorMessage != null && (
                      <FormControl.FormErrorMessage>
                        {reactivateErrorMessage}
                      </FormControl.FormErrorMessage>
                    )}
                  </FormControl>
                </form>
              </Card.Content>
            ) : (
              <>
                <Card.Content flexGrow={1}>
                  <Card.Title>Shutdown or Reactivate Property</Card.Title>
                  <Card.Description>
                    This Property is currently active, deactivate it here.
                  </Card.Description>
                </Card.Content>
                <Button
                  variant="danger"
                  onClick={() => {
                    setIsConfirmShutdownModalOpen(true);
                  }}
                  ml="auto"
                >
                  Shutdown
                </Button>
              </>
            )}
          </Card>
        </>
      )}

      <Modal
        isOpen={isConfirmShutdownModalOpen}
        onClose={() => {
          setIsConfirmShutdownModalOpen(false);
        }}
        size="small"
      >
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to shutdown {property.propertyName}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text size="sm">
            This will remove all users, disable autofeeds and empty schedule
            queues across all pages, and set the property end time.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-dismiss="modal"
            variant="secondary"
            onClick={() => {
              setIsConfirmShutdownModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleShutdownConfirm}>
            Shutdown {property.propertyName}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        isOpen={isPropertySetupModalOpen}
        onClose={() => {
          setShowPropertySetupModal(false);
          setCheckedPages([]);
        }}
        size="small"
      >
        <Modal.Header>
          <Modal.Title>Property Setup: {property.propertyName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text size="sm">
            This will trigger the Property Setup process for this Property.
          </Text>
          <VStack pt="8px" align="flex-start">
            {pagesForSetup.map((page) => {
              const SocialIcon = getSocialNetWorkIconByAPITypeId(
                page.apiTypeId,
              );
              return (
                <Checkbox
                  my={2}
                  key={page.accountAPIId}
                  onChange={() => handleCheckboxItems(page)}
                >
                  <HStack>
                    <SocialPageCircle>
                      <SocialIcon />
                    </SocialPageCircle>
                    <Text size="sm">{page.apiPostName}</Text>
                  </HStack>
                </Checkbox>
              );
            })}
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-dismiss="modal"
            variant="secondary"
            onClick={() => {
              setShowPropertySetupModal(false);
              setCheckedPages([]);
            }}
          >
            Cancel
          </Button>
          <Button isDisabled={isSubmitDisabled} onClick={handleSubmitPages}>
            Setup {property.propertyName}
          </Button>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
};

export default PropertyDetails;
