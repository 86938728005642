const PhotoStory = () => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '14px' }}
    className="mr-2"
  >
    <path d="M15.4 17.7778V2.22222C15.4 1 14.63 0 13.6889 0H1.71111C0.77 0 0 1 0 2.22222V17.7778C0 19 0.77 20 1.71111 20H13.6889C14.63 20 15.4 19 15.4 17.7778ZM4.70556 11.6667L6.84444 15.0111L9.83889 10L13.6889 16.6667H1.71111L4.70556 11.6667Z" />
  </svg>
);

export default PhotoStory;
