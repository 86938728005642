interface StatisticsProps {
  itemsSelected: number;
  totalNumberOfItems: number;
}

const Statistics = ({ itemsSelected, totalNumberOfItems }: StatisticsProps) => {
  const showSelected = false; // Temporary until multi-select is implemented

  return (
    <div className="article-counter d-none d-md-flex">
      {showSelected && (
        <>
          <div>{itemsSelected} selected</div>
          <span className="align-self-center op-50 mx-2">|</span>
        </>
      )}
      <div>
        {totalNumberOfItems} item{totalNumberOfItems !== 1 ? 's' : ''}
      </div>
    </div>
  );
};

export default Statistics;
