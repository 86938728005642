// common settings constants may also be found in src/common/constants/common.ts
// ideally they will be moved into this file over time

import { FeatureToggle } from 'types';

// Should match with values in the AccountSettingFeatureToggle enum in https://github.com/ebx/main
export const FEATURE_TOGGLES = {
  DISABLE_IG_ECHOBOX_TIME: 'disable_ig_echobox_time',
  LINKINBIO_ENABLED: 'linkinbio_enabled',
  MANUAL_IMAGE_FROM_ARTICLE_ENABLED: 'manual_image_from_article_enabled',
  USER_MANAGEMENT_EDITOR_ENABLED: 'user_management_editor_enabled',
  OPEN_AI_MESSAGE_ENABLED: 'open_ai_message_enabled',
  REQUIRES_GA: 'requires_ga',
  IG_CABINET_ENABLED: 'instagram_cabinet_enabled',
  SHARENOW_INSTANT_ENABLED: 'sharenow_instant_enabled',
  REQUIRES_EBX_TRACKER: 'requires_ebx_tracker',
  TRACKER_PROVISIONED_MANUALLY: 'tracker_installed_by_tag_manager',
} as const;

// Should match with values in the AccountSettingFeatureToggle enum in https://github.com/ebx/main
export const FEATURE_TOGGLE_DEFAULTS: Record<FeatureToggle, boolean> = {
  [FEATURE_TOGGLES.DISABLE_IG_ECHOBOX_TIME]: false,
  [FEATURE_TOGGLES.LINKINBIO_ENABLED]: true,
  [FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED]: true,
  [FEATURE_TOGGLES.OPEN_AI_MESSAGE_ENABLED]: false,
  [FEATURE_TOGGLES.REQUIRES_GA]: false,
  [FEATURE_TOGGLES.IG_CABINET_ENABLED]: false,
  [FEATURE_TOGGLES.SHARENOW_INSTANT_ENABLED]: false,
  [FEATURE_TOGGLES.REQUIRES_EBX_TRACKER]: true,
  [FEATURE_TOGGLES.TRACKER_PROVISIONED_MANUALLY]: false,
  [FEATURE_TOGGLES.MANUAL_IMAGE_FROM_ARTICLE_ENABLED]: false,
};
