/**
 * POST reactivate property
 */

import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

interface PostReactivatePropertyOptions {
  propertyURN: string;
  initialPropertyAdminEmail: string;
}

export default async function postReactivateProperty({
  propertyURN,
  initialPropertyAdminEmail,
}: PostReactivatePropertyOptions) {
  const guid = metrics.start('postReactivateProperty');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/properties/${propertyURN}/reactivate`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
    data: {
      initialPropertyAdminEmail,
    },
  };
  logger.info(
    `API:postReactivateProperty /social/properties/${propertyURN}/reactivate`,
  );

  try {
    await axios(config);
    metrics.end('postReactivateProperty', guid);
  } catch (error) {
    metrics.fail('postReactivateProperty', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postReactivateProperty',
    });
    throw apiError;
  }
}
