import getAPIsConnectRequestUpdate from 'api/getAPIsConnectRequestUpdate';
import { constructBaseSocialAPIURL } from 'api/settings';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import { isDefined, isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import { location } from 'common/window';

/**
 * reconnectAPI
 * @param {{
 *  socialPageURN: string;
 *  args?: object;
 * }} args
 */
export default function reconnectAPI({
  socialPageURN = mandatory('socialPageURN'),
  args = {},
} = {}) {
  return new Promise((resolve, reject) => {
    let instagramParams = null;
    if (isDefined(args.password) && isDefined(args.username)) {
      instagramParams = {
        connectType: 'LITE',
        username: args.username,
        password: args.password,
      };
    }
    if (isDefined(args.mfa) && args.mfa) {
      instagramParams.mfa1 = args.mfa1;
      instagramParams.mfa2 = args.mfa2;
      instagramParams.mfa3 = args.mfa3;
    }
    getAPIsConnectRequestUpdate({
      socialPageURN,
      uiCallbackURL: `${location.protocol}//${location.host}/settings/pages`,
      apiCallbackURL: constructBaseSocialAPIURL('v3'),
      instagramParams,
    })
      .then((response) => {
        location.href = response.location;
      })
      .catch((error) => {
        const handledError = determineError(error);
        if (isNull(getErrorStatus(error))) {
          logger.error({
            event: 'Reconnect API Error',
            properties: {
              location: 'process/reconnectAPI',
              arguments: {
                socialPageURN,
              },
            },
            error,
          });
        }
        reject(handledError);
      });
  });
}
