import {
  Button,
  CloseIcon,
  Dropdown,
  Flex,
  IconButton,
  Modal,
  Search,
  Tag,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import getSearchMentions from 'api/getSearchMentions';
import { getCurrentAccountAPIId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import { formatLikesFollowers } from 'common/string';
import * as tracker from 'common/tracker';
import { getNetworkAndPageName } from 'helpers/tracking';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import PageImage from './PageImage';

interface Pages {
  likes: number | null;
  name: string | null;
  profileImage: string | null;
  updatedTime: number;
  username: string | null;
  verified: boolean;
  link?: string | undefined;
  pageId?: string;
}

const AddPageModal = ({
  isOpen,
  onClose,
  onAddPages,
}: {
  isOpen: boolean;
  onClose: () => void;
  onAddPages: (pageIds: string[]) => void;
}) => {
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [matchingPages, setMatchingPages] = useState<Pages[]>([]);
  const [selectedPages, setSelectedPages] = useState<Pages[]>([]);
  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    const getPages = async (newSearchText: string) => {
      try {
        // remove spaces from the search text
        const searchKeyword = newSearchText.replace(/\s/g, '');
        setIsSearching(true);
        const results = await getSearchMentions({
          apiTypeId: API_TYPE_IDS.FACEBOOK,
          keyword: searchKeyword,
        });
        if (results.length === 0) {
          tracker.track({
            eventName: 'No Competitors Found',
            trackingParams: {
              'Network - Social Page': getNetworkAndPageName({
                accountAPIId: getCurrentAccountAPIId(),
              }),
              'Search term': newSearchText,
            },
          });
        }
        const filterResults = results.filter(
          (page) =>
            !selectedPages.some(
              (selectedPage) => selectedPage.pageId === page.pageId,
            ),
        );
        setMatchingPages(filterResults);
      } catch (error) {
        console.error(error);
      } finally {
        setIsSearching(false);
      }
    };
    if (debouncedSearchText?.length > 1) {
      getPages(debouncedSearchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const handleSearch = async (newSearchText: string) => {
    setSearchText(newSearchText);
    if (newSearchText.length < 2) {
      setMatchingPages([]);
      return;
    }
  };

  const handlePageClick = (page: Pages) => {
    setMatchingPages([]);
    setSearchText('');
    setSelectedPages([...selectedPages, page]);
  };

  const handleDelete = (pageId: string | undefined) => {
    if (!pageId) return;
    setSelectedPages(selectedPages.filter((page) => page.pageId !== pageId));
  };

  const handleAddPages = () => {
    const pageIds = (selectedPages?.map((page) => page.pageId) ??
      []) as string[];
    onAddPages(pageIds);

    const pageNames = selectedPages.map((page) => page.name);
    const trackingParams = {
      'Network - Social Page': getNetworkAndPageName({
        accountAPIId: getCurrentAccountAPIId(),
      }),
      'Count competitors added': pageIds.length,
      'Competitors added': pageNames.join(', '),
    };
    tracker.track({
      eventName: 'Add Competitors',
      trackingParams,
    });
    onClose();
  };

  return (
    <Modal size="small" isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Facebook pages</Modal.Title>
      </Modal.Header>
      <Modal.Body overflow="visible">
        <Flex gap={4} flexDir="column" position="relative">
          <Dropdown matchWidth isOpen={matchingPages.length > 0}>
            <Search
              isSearching={isSearching}
              searchText={searchText}
              onChange={handleSearch}
            />
            <Dropdown.List
              rootProps={{
                top: '40px !important',
                w: 'full',
                zIndex: 'popover',
              }}
            >
              {matchingPages.map((page) => (
                <Dropdown.Item
                  onClick={() => handlePageClick(page)}
                  key={page.pageId}
                >
                  <Flex alignItems="center" gap={2}>
                    <PageImage
                      w="10"
                      h="10"
                      src={page.profileImage as string | undefined}
                    />
                    <Flex flex="1" flexDir="column">
                      <Text size="sm">{page.name}</Text>
                      <Text size="sm" color="text.secondary">
                        @{page.username}
                      </Text>
                    </Flex>
                    <Flex justifyContent="flex-end">
                      <Tag color="gray" size="sm">
                        {formatLikesFollowers(page.likes)}
                      </Tag>
                    </Flex>
                  </Flex>
                </Dropdown.Item>
              ))}
            </Dropdown.List>
          </Dropdown>

          <Flex gap={3} flexDir="column">
            {selectedPages.map((page) => (
              <Flex key={page.pageId} gap={2} alignItems="center">
                <IconButton
                  icon={<CloseIcon />}
                  aria-label="Close"
                  onClick={() => handleDelete(page.pageId)}
                />
                <PageImage src={page.profileImage as string | undefined} />
                <Text size="sm">{page.name}</Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Modal.Body>
      <Modal.Footer gap={3} justifyContent="flex-end">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          isDisabled={selectedPages.length === 0}
          onClick={handleAddPages}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPageModal;
