import {
  Box,
  Button,
  Dropdown,
  Flex,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useState } from 'react';

import deletePropertiesUser from 'api/deletePropertiesUser';
import postPropertiesUser from 'api/postPropertiesUser';
import {
  getAPIsByStateAndType,
  getCurrentProperty,
  getCurrentPropertyId,
  getUser,
  getUserPagePermissions,
  getUserPropertyPermission,
} from 'common/accountAPIs';
import {
  API_STATES,
  FRONTEND_PAGES,
  GLOBAL_INFO_STATES,
  PERMISSION_TYPES,
  USER_STATES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as logger from 'common/logger';
import { sortUsers } from 'common/misc';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { isOnPage } from 'common/path';
import { getFeatureToggle } from 'common/settings';
import { isNull } from 'common/utility';
import { location } from 'common/window';
import RemoveUserModal from 'components/setup/RemoveUserModal';
import GlobalInfoContext from 'context/GlobalInfoContext';
import ArrowBack from 'svg/ArrowBack';
import ArrowUp from 'svg/ArrowUp';
import ThreeDot from 'svg/ThreeDot';
import { FixTypeLater } from 'types';

import ResendInvite from './ResendInvite';
import SelectUserPages from './SelectUserPages';

const permissionTypeMap = {
  [PERMISSION_TYPES.ADMIN]: 'Administrator',
  [PERMISSION_TYPES.EDITOR]: 'Editor',
  [PERMISSION_TYPES.VIEW_ONLY]: 'View only',
  [PERMISSION_TYPES.NONE]: 'None',
};

interface TeamTableProps {
  propertyUsers: FixTypeLater[];
  setPropertyUsers: React.Dispatch<FixTypeLater>;
  showRoleSettings: boolean;
}

const TeamTable = ({
  propertyUsers,
  setPropertyUsers,
  showRoleSettings,
}: TeamTableProps) => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();

  const [removingUserId, setRemovingUserId] = useState<string | null>(null);
  const [removingUserEmail, setRemovingUserEmail] = useState<string | null>(
    null,
  );
  const [modalUserName, setModalUserName] = useState('');
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [editingPagesUserId, setEditingPagesUserId] = useState<string | null>(
    null,
  );
  const [alphabeticalOrder, setAlphabeticalOrder] = useState(true);

  const isUserManagementEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
  const isSetupPage = isOnPage({ page: FRONTEND_PAGES.SETUP, location });
  const isLocked = isSavingUser || !isUserManagementEnabled;

  const refreshGlobalInfo = ({ message }: { message: string }) => {
    let newGlobalInfo = global.getGlobalInfo();
    if (!isNull(newGlobalInfo)) {
      setIsSavingUser(true);

      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        message: 'Role updated',
        callback: () => {
          newGlobalInfo = global.getGlobalInfo();
          setPropertyUsers(
            sortUsers(
              getCurrentProperty(newGlobalInfo).propertyUsers,
              alphabeticalOrder,
            ),
          );
          addSuccessNotification(message);
          setIsSavingUser(false);
        },
        allowUnderSetup: true,
      });
    }
  };

  const handlePropertyPermissionChange = (userId: FixTypeLater) => {
    return async () => {
      logger.info(`TeamTable:handlePropertyPermissionChange ${userId}`);
      setIsSavingUser(true);
      const propertyId = getCurrentPropertyId({
        globalInfo,
      });
      const newPermissionTypeId = getUserPropertyPermission({
        userId,
        propertyId,
        globalInfo,
      });

      const permissionsOnProperty = [];
      if (newPermissionTypeId === PERMISSION_TYPES.ADMIN) {
        // Changing from ADMIN to EDITOR
        // Create VIEW_ONLY permission for property and EDITOR for all apis
        logger.info(
          'TeamTable:handlePropertyPermissionChange - changing from ADMIN to EDITOR...',
        );
        permissionsOnProperty.push({
          propertyId,
          permissionTypeId: PERMISSION_TYPES.VIEW_ONLY,
        });
        const accountAPIs = getAPIsByStateAndType({
          propertyId,
          apiStateId: [
            API_STATES.ACTIVE,
            API_STATES.BAD_AUTH,
            API_STATES.UNDER_SETUP,
          ],
          globalInfo,
        });
        accountAPIs.forEach((accountAPI) => {
          permissionsOnProperty.push({
            propertyId,
            accountAPIId: accountAPI.accountAPIId,
            permissionTypeId: PERMISSION_TYPES.EDITOR,
          });
        });
      } else {
        // Changing from EDITOR to ADMIN
        // Create ADMIN permission for property
        logger.info(
          'TeamTable:handlePropertyPermissionChange - changing from EDITOR to ADMIN...',
        );
        permissionsOnProperty.push({
          propertyId,
          permissionTypeId: PERMISSION_TYPES.ADMIN,
        });
      }

      // Save change
      const user: FixTypeLater = getUser({ userId });
      try {
        await postPropertiesUser({
          propertyId: globalInfo.current.propertyId,
          name: user.name,
          username: user.username,
          emailAddress: user.emailAddress,
          permissionsOnProperty,
        });
        refreshGlobalInfo({
          message: 'Role updated',
        });
      } catch (error) {
        console.log(error);
        setIsSavingUser(false);
        addErrorNotification(error);
      }
    };
  };

  const handleSelectUserPagesSave = () => {
    logger.info('TeamTable:handleSelectUserPagesSave');
    setEditingPagesUserId(null);

    // Refresh global info
    refreshGlobalInfo({
      message: 'Pages updated',
    });
  };

  const handleUserDelete = async () => {
    logger.info(`TeamTable:handleUserDelete ${removingUserEmail}`);
    try {
      if (removingUserEmail) {
        await deletePropertiesUser({
          propertyId: globalInfo.current.propertyId,
          emailAddress: removingUserEmail,
        });
        refreshGlobalInfo({
          message: 'User removed',
        });
        setRemovingUserId(null);
      }
    } catch (error) {
      addErrorNotification('Failed');
    }
  };

  const renderPropertyPermission = (
    user: FixTypeLater,
    isCurrentUser: boolean,
  ) => {
    if (isCurrentUser) {
      return <Box opacity={0.5}>Administrator</Box>;
    }

    let newPermissionTypeId =
      getUserPropertyPermission({
        userId: user.userId,
        propertyId: getCurrentPropertyId({
          globalInfo,
        }),
        globalInfo,
      }) ?? PERMISSION_TYPES.NONE;

    if (newPermissionTypeId === PERMISSION_TYPES.VIEW_ONLY) {
      newPermissionTypeId = PERMISSION_TYPES.EDITOR;
    }

    const renderAdmin = newPermissionTypeId !== PERMISSION_TYPES.ADMIN;
    const showRoleSelect = showRoleSettings && !isSetupPage;

    return (
      <Box
        width="fit-content"
        data-tip
        data-for={`property-permission=${user.userId}`}
      >
        {showRoleSelect ? (
          <Select
            placeholder={permissionTypeMap[newPermissionTypeId]}
            onChange={handlePropertyPermissionChange(user.userId)}
            isDisabled={isLocked}
          >
            {renderAdmin ? (
              <option value={PERMISSION_TYPES.ADMIN}>
                {permissionTypeMap[PERMISSION_TYPES.ADMIN]}
              </option>
            ) : (
              <option value={PERMISSION_TYPES.EDITOR}>
                {permissionTypeMap[PERMISSION_TYPES.EDITOR]}
              </option>
            )}
          </Select>
        ) : (
          permissionTypeMap[newPermissionTypeId]
        )}
      </Box>
    );
  };

  const renderThreeButtonMenu = (
    userName: string,
    userId: string,
    userEmail: string,
  ) => {
    const handleMenuItemClick = () => {
      setModalUserName(userName);
      setRemovingUserId(userId);
      setRemovingUserEmail(userEmail);
    };

    return (
      <Dropdown gutter={8} placement="bottom-end">
        <Dropdown.Button
          aria-label="More options"
          w={7}
          h={7}
          borderRadius="md"
          color="gray.600"
          _hover={{ bg: 'gray.900-04' }}
          _active={{ bg: 'gray.900-08' }}
        >
          <ThreeDot />
        </Dropdown.Button>
        <Dropdown.List>
          <Dropdown.Item onClick={handleMenuItemClick} color="red.600">
            Remove user
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>
    );
  };

  const headerRow = (headings: string[]) => {
    return (
      <Tr>
        {headings.map((heading) => {
          const isNameHeading = heading === 'Name';

          const handleHeadingClick = () => {
            sortUsers(propertyUsers, !alphabeticalOrder);

            setAlphabeticalOrder(!alphabeticalOrder);
          };

          return (
            <Th
              key={heading}
              textTransform="none"
              px={5}
              py={4}
              color="gray.900"
              fontSize="sm"
              lineHeight="5"
              fontWeight="medium"
              border="none"
              boxShadow="borderBottom"
            >
              <Flex
                gap={2}
                cursor={isNameHeading ? 'pointer' : 'text'}
                onClick={isNameHeading ? handleHeadingClick : undefined}
                h={4}
              >
                {heading}
                {isNameHeading && (
                  <Box
                    bottom="1px"
                    pos="relative"
                    onClick={handleHeadingClick}
                    cursor="pointer"
                  >
                    {alphabeticalOrder ? <ArrowBack /> : <ArrowUp />}
                  </Box>
                )}
              </Flex>
            </Th>
          );
        })}
      </Tr>
    );
  };

  const headers = ['Name', 'Email', 'Role'];
  if (showRoleSettings) {
    headers.push('Pages');
  }

  const availableAPIs = getAPIsByStateAndType({
    propertyId: globalInfo.current.propertyId,
    apiStateId: [
      API_STATES.ACTIVE,
      API_STATES.BAD_AUTH,
      API_STATES.UNDER_SETUP,
    ],
    globalInfo,
  });

  const propertyId = getCurrentPropertyId({
    globalInfo,
  });

  return (
    <>
      <TableContainer>
        <Table
          variant="simple"
          maxWidth="1040px"
          borderRadius="md"
          border="1px"
          borderColor="gray.300"
          overflow="hidden"
          sx={{ borderCollapse: 'separate' }}
        >
          <Thead bg="gray.100">{headerRow(headers)}</Thead>
          <Tbody>
            {propertyUsers.map((user: FixTypeLater) => {
              const isCurrentUser = user.userId === globalInfo.user.userId;
              const userPagePermissions = getUserPagePermissions({
                userId: user.userId,
                propertyId: globalInfo.current.propertyId,
                globalInfo,
              });

              const newPermissionTypeId = getUserPropertyPermission({
                userId: user.userId,
                propertyId,
                globalInfo,
              });

              let connectedPages =
                userPagePermissions.length === availableAPIs.length ||
                newPermissionTypeId === PERMISSION_TYPES.ADMIN
                  ? 'All pages'
                  : `${userPagePermissions.length} pages`;

              if (
                userPagePermissions.length === 1 &&
                availableAPIs.length !== 1
              ) {
                connectedPages = '1 page';
              }

              const rowActionButtons = (
                <Box ml="auto">
                  {user.userState === USER_STATES.INVITED && (
                    <ResendInvite userId={user.userId} />
                  )}
                  {!isCurrentUser &&
                    renderThreeButtonMenu(
                      user.name,
                      user.userId,
                      user.emailAddress,
                    )}
                </Box>
              );
              return (
                <Tr
                  key={user.userId}
                  boxShadow="borderTop"
                  bgColor="white"
                  _first={{ boxShadow: 'none' }}
                >
                  <Td
                    px={5}
                    py={4}
                    color="gray.900"
                    fontSize="sm"
                    border="none"
                  >
                    <Flex alignItems="center" justifyContent="space-between">
                      {user.name}
                    </Flex>
                  </Td>
                  <Td
                    px={5}
                    py={4}
                    color="gray.900"
                    fontSize="sm"
                    border="none"
                  >
                    {user.emailAddress}
                  </Td>
                  <Td
                    px={5}
                    py={4}
                    color="gray.900"
                    fontSize="sm"
                    border="none"
                  >
                    <Flex alignItems="center" maxH={5}>
                      <Box mr={4}>
                        {renderPropertyPermission(user, isCurrentUser)}
                      </Box>
                      {!showRoleSettings && rowActionButtons}
                    </Flex>
                  </Td>
                  {showRoleSettings && (
                    <Td
                      px={5}
                      py={4}
                      color="gray.900"
                      fontSize="sm"
                      border="none"
                    >
                      <Flex alignItems="center" maxH={5}>
                        {newPermissionTypeId === PERMISSION_TYPES.ADMIN ? (
                          <Box mr={4}>{connectedPages}</Box>
                        ) : (
                          <Button
                            variant="secondary"
                            mr={4}
                            onClick={() => setEditingPagesUserId(user.userId)}
                            isDisabled={isLocked}
                          >
                            {connectedPages}
                          </Button>
                        )}
                        {rowActionButtons}
                      </Flex>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      {editingPagesUserId && (
        <SelectUserPages
          userId={editingPagesUserId}
          onCancel={() => setEditingPagesUserId(null)}
          onSave={handleSelectUserPagesSave}
        />
      )}
      {removingUserId && (
        <RemoveUserModal
          onDismiss={() => setRemovingUserId(null)}
          userName={modalUserName}
          handleUserDelete={handleUserDelete}
        />
      )}
    </>
  );
};

export default TeamTable;
