import { getCurrentAPITypeId } from 'common/accountAPIs';
import { TEXT_CASES } from 'common/constants';
import { getSocialNetworkName } from 'common/social';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { getDefaultColumns } from 'helpers/analyticsPage';
import { APITypeId } from 'types';
import ColumnHeader from './ColumnHeader';

interface ColumnsProps {
  displayOptions: {
    insightColumns?: {
      [networkName: string]: string[];
    };
    sortBy: string;
    sortOrder: 'ASC' | 'DESC';
  };
  onSortChange: (fieldName: string) => () => void;
}
const Columns = ({ displayOptions, onSortChange }: ColumnsProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const apiTypeId: APITypeId = getCurrentAPITypeId({ globalInfo });
  const networkName = getSocialNetworkName({
    apiTypeId,
    textCase: TEXT_CASES.LOWER,
  });

  const insightKeys =
    displayOptions.insightColumns?.[networkName] ??
    getDefaultColumns({ apiTypeId });

  return (
    <tr>
      <td>
        <ColumnHeader
          columnName="Date"
          fieldName="date"
          displayOptions={displayOptions}
          eventHandlers={{ handleSortChange: onSortChange }}
        />
      </td>
      {insightKeys.map((insightKey) => {
        const analyticsInsights = getSocialAnalyticsInsights(apiTypeId);
        const columnDetails = analyticsInsights?.[insightKey];
        if (!columnDetails) return null;
        return (
          <td key={insightKey}>
            <ColumnHeader
              columnName={columnDetails.name}
              displayOptions={displayOptions}
              eventHandlers={{ handleSortChange: onSortChange }}
              fieldName={insightKey}
              tooltipContent={columnDetails.description}
              tooltipHeader={columnDetails.name}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default Columns;
