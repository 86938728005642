import PropTypes from 'prop-types';
import { useContext } from 'react';

import { getCurrentAccountAPI } from 'common/accountAPIs';
import { KEYNAMES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getSocialNetworkDefaultPageIcon } from 'common/social';
import ABResult from 'components/home/analytics/ABResult';
import Button from 'components/misc/Button';
import GlobalInfoContext from 'context/GlobalInfoContext';
import * as helpers from 'helpers/analyticsPage';
import useOnKey from 'hooks/useOnKey';

/**
 * AB results modal
 */

const ABResults = (props) => {
  const context = useContext(GlobalInfoContext);

  /**
   * Lifecycle methods
   */

  useOnKey({
    targetKey: KEYNAMES.ESCAPE,
    onKeyUp: () => props.eventHandlers.handleHideABResults(),
  });

  /**
   * Render method
   */

  const globalInfo = context.global.getGlobalInfo();
  const mediaItem = props.mediaItem;

  const abResults = MediaItem.getABResults({ mediaItem });
  const abVariations = MediaItem.getABVariations({ mediaItem });
  const abTestStatusId = MediaItem.getABTestStatusId({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const itemTags = MediaItem.getTags({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });

  const currentAccount = getCurrentAccountAPI({
    globalInfo,
  });
  const accountImage =
    currentAccount.apiPostImage ??
    getSocialNetworkDefaultPageIcon({
      apiTypeId: currentAccount.apiTypeId,
    });
  const accountName = currentAccount.apiPostName ?? '';

  const abDetails = helpers.getABDetails({
    abVariations,
    abResults,
    abTestStatusId,
    postType,
  });

  return (
    <div
      className="ebx-modal-outer modal"
      id="abResults"
      tabIndex="-1"
      role="dialog"
      aria-modal="true"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-ab" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div
              className="ft-15 pr-2 align-self-center text-700 gray-700"
              id="exampleModalLabel"
            >
              AB Test Results
            </div>
            <button
              type="button"
              className="close ft-26 text-400"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.eventHandlers.handleHideABResults}
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="abtest_full_view row">
              <ABResult
                abDetail={abDetails[0]}
                abLabel="A"
                accountAPIId={accountAPIId}
                accountImage={accountImage}
                accountName={accountName}
                itemTags={itemTags}
              />
              <ABResult
                abDetail={abDetails[1]}
                abLabel="B"
                accountAPIId={accountAPIId}
                accountImage={accountImage}
                accountName={accountName}
                itemTags={itemTags}
              />
            </div>
          </div>
          <div className="modal-footer">
            <Button
              variant="dark"
              data-dismiss="modal"
              onClick={props.eventHandlers.handleHideABResults}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ABResults.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  eventHandlers: PropTypes.shape({
    handleHideABResults: PropTypes.func.isRequired,
  }).isRequired,
};

export default ABResults;
