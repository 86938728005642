import clsx from 'clsx';

import Tooltip from 'components/misc/Tooltip';

interface LastEditedUserProps {
  className?: string;
  lastUser?: string | null;
  lastEditedTime: string;
  mediaId: string;
}

const LastEditedUser = ({
  className = '',
  lastEditedTime,
  lastUser = null,
  mediaId,
}: LastEditedUserProps) => {
  if (lastUser == null) return null;

  const guid = `last-edited-user-${mediaId}`;

  const tooltipLabel = `last edited by ${lastUser} ${lastEditedTime}`;

  return (
    <div
      data-cy="last-editor-user"
      className={clsx('lastEditedUser float-right ml-auto', className)}
    >
      <Tooltip id={guid} label={tooltipLabel}>
        <span
          className="gray-600"
          data-tip
          data-for={guid}
          data-iscapture="true"
        >
          {lastUser}
        </span>
      </Tooltip>
    </div>
  );
};

export default LastEditedUser;
