import PropTypes from 'prop-types';

import Button from 'components/misc/Button';

const SaveChangesButtons = (props) => (
  <div className="border-top save-part">
    <Button
      className={`mr-2 align-self-center ${props.disabled ? 'disabled' : ''}`}
      variant="dark"
      data-cy-action="saveChanges"
      onClick={props.eventHandlers.handleSave}
    >
      Save Changes
    </Button>
    <Button
      className={`mr-2 align-self-center ${
        props.disabled && !props.canCancel ? 'disabled' : ''
      }`}
      data-cy-action="cancelChanges"
      onClick={props.eventHandlers.handleCancel}
    >
      Cancel
    </Button>
  </div>
);

SaveChangesButtons.propTypes = {
  disabled: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool,
  eventHandlers: PropTypes.shape({
    handleSave: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
  }).isRequired,
};

SaveChangesButtons.defaultProps = {
  canCancel: false,
};

export default SaveChangesButtons;
