import { useEffect, useState } from 'react';

import { COUNTRIES, FACEBOOK_AGE, KEYCODES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import * as modal from 'common/modal';
import { getCountryCode } from 'common/string';
import * as tracker from 'common/tracker';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import KeywordList from 'components/settings/KeywordList';
import { getCommonTrackingParams } from 'helpers/tracking';
import type { FbTargetingParams, FixTypeLater } from 'types';

function isValidCountryCode(
  potentialCode: string,
): potentialCode is keyof typeof COUNTRIES {
  return Object.keys(COUNTRIES).includes(potentialCode);
}

interface AudienceRestrictionProps {
  applySingleFieldUpdateForGuid: (args: {
    guid: string;
    fieldName: 'fbTargetingParams';
    fieldValue: FbTargetingParams;
  }) => void;
  guid: string;
  mediaItem: FixTypeLater;
  onTargetingParamsChange: () => void;
}

const AudienceRestriction = ({
  applySingleFieldUpdateForGuid,
  guid,
  mediaItem,
  onTargetingParamsChange,
}: AudienceRestrictionProps) => {
  const targetingParams = MediaItem.getFbTargetingParams({
    mediaItem,
  });

  const [ageMin, setAgeMin] = useState(targetingParams?.ageMin ?? '');
  const [countries, setCountries] = useState(targetingParams?.countries ?? []);

  const handleTargetingSave = (updatedTargetingParams: FbTargetingParams) => {
    const trackingParams = getCommonTrackingParams({ mediaItem });
    trackingParams['Age Restriction'] =
      updatedTargetingParams.ageMin === undefined ||
      updatedTargetingParams.ageMin === ''
        ? 'Everyone'
        : `${updatedTargetingParams.ageMin}plus`;
    trackingParams['Geographic Restriction'] =
      updatedTargetingParams.countries ?? [];
    tracker.track({
      eventName: 'Add Audience Restriction',
      trackingParams,
    });

    applySingleFieldUpdateForGuid({
      guid,
      fieldName: 'fbTargetingParams',
      fieldValue: updatedTargetingParams,
    });
    onTargetingParamsChange();
  };

  const handleCountryAdd: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    const country = event.target.value;
    // Only add country if not already in the list and max 25 countries in the list
    if (
      isValidCountryCode(country) &&
      countries.indexOf(country) === -1 &&
      countries.length < 25
    ) {
      setCountries((prev) => prev.concat([country]));
    }
  };

  const handleCountryRemove = (keyword: string) => {
    return () => {
      const countryToRemove = getCountryCode(keyword);
      setCountries((prev) =>
        prev.filter((country) => country !== countryToRemove),
      );
    };
  };

  const handleTargetingClear = () => {
    setAgeMin('');
    setCountries([]);
    handleTargetingSave({});
  };

  const handleSaveClick = () => {
    const updatedTargetingParams: FbTargetingParams = {};
    if (ageMin !== '') {
      updatedTargetingParams.ageMin = ageMin;
    }
    if (countries.length > 0) {
      updatedTargetingParams.countries = countries;
    }

    handleTargetingSave(updatedTargetingParams);
  };

  useEffect(() => {
    // TODO: Remove keyup event listeners once we replace modal with Modal from component library
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.keyCode === KEYCODES.ESCAPE) {
        onTargetingParamsChange();
      }
    };

    modal.setBackgroundColor('initial');
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      modal.setBackgroundColor('rgba(23, 32, 50, 0.3)');
    };
  }, [onTargetingParamsChange]);

  return (
    <div
      className="ebx-modal-outer"
      id="targetingParams"
      tabIndex={-1}
      role="dialog"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-sm" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div>Audience restriction</div>
            <button
              type="button"
              className="close ft-26 text-400"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onTargetingParamsChange}
            >
              ×
            </button>
          </div>
          <div className="modal-body lh-20">
            <div>
              <div>
                <div className="ft-12 text-500 mb-1 text-secondary d-flex">
                  Age
                  <Tooltip
                    id="age_targeting"
                    label="Select your audience based on minimum age."
                  >
                    <img
                      className="ml-2 cursor-pointer op-30"
                      src="/img/icons/ic-information.svg"
                      alt=""
                      data-tip
                      data-for="age_targeting"
                      data-iscapture="true"
                    />
                  </Tooltip>
                </div>
                <select
                  id="start"
                  value={ageMin}
                  onChange={(event) => setAgeMin(event.target.value)}
                  className="btn btn-light dropdown-md"
                  data-cy-select="age"
                >
                  <option value="">Everyone</option>
                  {Object.entries(FACEBOOK_AGE).map(([age, ageValue]) => (
                    <option key={age} value={ageValue}>
                      {`${ageValue}+`}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <div className="ft-12 text-500 mb-1 mt-3 text-secondary d-flex">
                  Location
                  <Tooltip
                    id="location_targeting"
                    label="Enter one or more countries to only show your post to the
                    people in those locations."
                  >
                    <img
                      className="ml-2 cursor-pointer op-30"
                      src="/img/icons/ic-information.svg"
                      alt=""
                      data-tip="true"
                      data-toggle="popover"
                      data-for="location_targeting"
                      data-content="Enter one or more countries to only show your post to the people in those locations."
                      data-iscapture="true"
                    />
                  </Tooltip>
                </div>
                <select
                  id="countrySelect"
                  data-cy-select="location"
                  value=""
                  onChange={handleCountryAdd}
                  className="btn btn-light dropdown-md mb-1"
                >
                  <option key="no-selection" value="">
                    Select location
                  </option>
                  {Object.entries(COUNTRIES).map(([code, countryName]) => (
                    <option key={code} value={code}>
                      {countryName}
                    </option>
                  ))}
                </select>
                <KeywordList
                  keywordList={Array.from(
                    countries.map((countryCode) => COUNTRIES[countryCode]),
                  )}
                  onKeywordRemove={handleCountryRemove}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button data-dismiss="modal" onClick={handleTargetingClear}>
              Clear
            </Button>
            <Button variant="dark" onClick={handleSaveClick}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudienceRestriction;
