const Check = () => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7986 0.141545C12.0295 0.340019 12.067 0.681388 11.8846 0.924472L4.49921 10.7666C4.3929 10.9083 4.22716 10.9941 4.04911 10.9997C3.87106 11.0053 3.70016 10.9301 3.58499 10.7954L0.13849 6.76423C-0.0365238 6.55953 -0.0467036 6.26245 0.113894 6.04645C0.274492 5.83045 0.563776 5.75214 0.812845 5.85724L3.89793 7.15905L11.0045 0.168023C11.2214 -0.0453837 11.5676 -0.0569284 11.7986 0.141545Z"
      fill="currentColor"
    />
  </svg>
);

export default Check;
