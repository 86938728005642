import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useState } from 'react';

import { getPropertiesConnectRequestNew } from 'api/api';
import { constructBaseSocialAPIURL } from 'api/settings';
import { getCurrentPropertyId } from 'common/accountAPIs';
import { API_PROPERTIES, API_TYPE_IDS } from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import { redirectBrowser } from 'common/location';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import * as tracker from 'common/tracker';
import { convertToPropertyURN } from 'common/urn';
import { location } from 'common/window';
import GlobalInfoContext from 'context/GlobalInfoContext';
import BrandBitly from 'svg/BrandBitly';

interface ConnectBitlyModalProps {
  onClose: () => void;
}
const ConnectBitlyModal = ({ onClose }: ConnectBitlyModalProps) => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();

  const [isConnecting, setIsConnecting] = useState(false);

  const handleError = ({
    error,
    action,
    event,
  }: {
    error: Error;
    action: string;
    event: string;
  }) => {
    const errorMessage = getErrorMessage(determineError(error));
    if (getErrorStatus(error) === null) {
      logger.error({
        event,
        properties: {
          location: 'components/setup/ConnectBitly',
        },
        error,
      });
    }
    addErrorNotification(`Error ${action} Bitly: ${errorMessage}`);
  };

  const handleBitlyConnect = async () => {
    setIsConnecting(true);
    try {
      tracker.track({
        eventName: 'Connect Bitly',
      });

      const response = await getPropertiesConnectRequestNew({
        propertyURN: convertToPropertyURN(
          getCurrentPropertyId({
            globalInfo,
          }),
        ),
        socialAPIType: API_PROPERTIES[API_TYPE_IDS.BITLY]?.apiType,
        uiCallbackURL: location.href,
        apiCallbackURL: constructBaseSocialAPIURL(),
      });

      redirectBrowser(response.location);
    } catch (error) {
      if (error instanceof Error) {
        handleError({
          error,
          action: 'connecting',
          event: 'Handle Bitly Connect Error',
        });
      }
    }
    setIsConnecting(false);
  };

  return (
    <Modal size="small" isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Connect your Bitly account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm">
          Connecting a Bitly account enables link shortening and provides
          additional analytics.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button isDisabled={isConnecting} variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={isConnecting}
          onClick={handleBitlyConnect}
          leftIcon={<BrandBitly />}
        >
          Connect Bitly
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectBitlyModal;
