/**
 * POST /properties/{propertyId}/settings - Add a setting to the specified account
 *
 * @param {Integer} propertyId - Account id
 * @param {SettingTypeId} settingTypeId - Setting type id for the setting
 * @param {Boolean} enabled - Flag indicating whether the setting is active
 * @param {Integer} autoExpireTime - Optional timestamp indicating when the setting will expire
 * @param {String} dataJSON - JSON representation of the setting details
 * @return {Object} - accountSettingId that was created
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { AccountSettingTypesSchema } from 'common/schemas';
import { convertToPropertyURN } from 'common/urn';
import { isNull } from 'common/utility';
import { lyingParse } from 'common/zod';
import type {
  CustomShareTimeWindowSettings,
  DomainSwitchingData,
  FeatureToggleSettings,
  SettingTypeId,
} from 'types';

const ResponseSchema = z
  .object({
    settingTypeId: AccountSettingTypesSchema,
  })
  .describe('postPropertiesSettings.ResponseSchema');

export default async function postPropertiesSettings({
  propertyId,
  settingTypeId,
  enabled,
  autoExpireTime = null,
  dataJSON = null,
}: {
  propertyId: number;
  settingTypeId: SettingTypeId;
  enabled: boolean;
  autoExpireTime?: number | null;
  dataJSON?:
    | FeatureToggleSettings
    | CustomShareTimeWindowSettings
    | DomainSwitchingData
    | null;
}) {
  const guid = metrics.start('postPropertiesSettings');

  const propertyURN = convertToPropertyURN(propertyId);
  const clientAuthHeader = getClientServiceRequestHeaders({
    'Content-Type': 'application/json',
  });

  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL()}/properties/${propertyURN}/settings`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: clientAuthHeader,
    data: JSON.stringify({
      settingTypeId,
      enabled,
      autoExpireTime,
      dataJSON: isNull(dataJSON) ? dataJSON : JSON.stringify(dataJSON),
    }),
  };
  logger.info(
    `API:postPropertiesSettings /properties/${propertyURN}/settings ${settingTypeId}`,
  );

  try {
    const response = await axios(config);
    metrics.end('postPropertiesSettings', guid);
    logger.debug('postPropertiesSettings', { propertyId, settingTypeId });
    const data = lyingParse(ResponseSchema, response.data);
    return data;
  } catch (error) {
    metrics.fail('postPropertiesSettings', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postPropertiesSettings',
      args: {
        propertyId,
        settingTypeId,
        enabled,
        autoExpireTime,
        dataJSON,
      },
    });
    throw apiError;
  }
}
