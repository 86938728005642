import PropTypes from 'prop-types';

import { MEDIA_ITEM_STATES } from 'common/constants';
import Button from 'components/misc/Button';

const ItemShareButton = (props) => {
  const { isNewVersion, isSaving, itemState, eventHandlers } = props;
  let buttonLabel = 'Share';
  if (isNewVersion) {
    buttonLabel = 'Reshare';
  }
  if (isSaving) {
    buttonLabel = 'Saving';
  }
  if (itemState === MEDIA_ITEM_STATES.FAILED) {
    buttonLabel = 'Try again';
  }
  const isDeleted = itemState === MEDIA_ITEM_STATES.DELETED;

  return (
    <Button
      data-cy-action="shareNewFeed"
      className={`share_button ${isDeleted ? 'deleted_greyed_out' : ''}`}
      onClick={eventHandlers.handleArticleEdit}
    >
      {buttonLabel}
    </Button>
  );
};

ItemShareButton.propTypes = {
  isNewVersion: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  itemState: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleEdit: PropTypes.func.isRequired,
  }).isRequired,
};

export default ItemShareButton;
