/* eslint no-alert:"off" */
/* eslint no-use-before-define: "off" */

import { Box, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useState } from 'react';

import deletePropertiesUser from 'api/deletePropertiesUser';
import getPropertiesUsers from 'api/getPropertiesUsers';
import postPropertiesUser from 'api/postPropertiesUser';
import {
  getAPIsByStateAndType,
  getCurrentProperty,
  getCurrentPropertyId,
  getPropertyName,
  getPropertyPermission,
  getUser,
  getUserPagePermissions,
  getUserPropertyPermission,
} from 'common/accountAPIs';
import { EDITOR_ACCESS_MESSAGE } from 'common/config';
import {
  API_STATES,
  FRONTEND_PAGES,
  GLOBAL_INFO_STATES,
  PERMISSION_TYPES,
  REACT_PREVENT_RENDER,
  USER_STATES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'common/featureFlags';
import * as logger from 'common/logger';
import { sortUsers } from 'common/misc';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { isOnPage } from 'common/path';
import { getFeatureToggle } from 'common/settings';
import { isDefined, isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import { location } from 'common/window';
import Button from 'components/misc/Button';
import Tooltip from 'components/misc/Tooltip';
import SettingsError from 'components/settings/SettingsError';
import UserAdd from 'components/settings/user/UserAdd';
import UserPages from 'components/settings/user/UserPages';
import GlobalInfoContext from 'context/GlobalInfoContext';
import UserAddNew from './UserAddNew';

const permissionTypeMap = {
  [PERMISSION_TYPES.ADMIN]: 'Administrator',
  [PERMISSION_TYPES.EDITOR]: 'Editor',
  [PERMISSION_TYPES.VIEW_ONLY]: 'View only',
  [PERMISSION_TYPES.NONE]: 'None',
};

/*
 * User management
 */

const UserManagement = () => {
  const context = useContext(GlobalInfoContext);
  const globalInfo = context.global.getGlobalInfo();

  const isUserAddModalEnabled = () =>
    isFeatureFlagEnabled({ flag: FEATURE_FLAGS.ADD_USER_MODAL });

  /**
   * Initial state
   */

  const [errorMessage, setErrorMessage] = useState('');
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isEditingPages, setIsEditingPages] = useState(false);
  const [editingPagesFor, setEditingPagesFor] = useState({});
  const [invitesResent, setInvitesResent] = useState([]);
  const [propertyUsers, setPropertyUsers] = useState(
    sortUsers(
      getCurrentProperty({
        globalInfo,
      }).propertyUsers,
    ),
  );
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const isSetupPage = isOnPage({ page: FRONTEND_PAGES.SETUP, location });

  const permissionTypeId = getPropertyPermission({
    propertyId: getCurrentPropertyId({ globalInfo }),
    globalInfo,
  });
  const isUserManagementEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  const loadUsers = async () => {
    logger.info('UserManagement - users missing');

    setIsLoadingUsers(true);

    const response = await getPropertiesUsers({
      propertyIds: [currentPropertyId],
    });
    if (!isNull(response)) {
      Object.keys(response).forEach((propertyId) => {
        globalInfo.properties[propertyId].propertyUsers = response[propertyId];
      });
    }
    context.global.setGlobalInfo(globalInfo);
    setPropertyUsers(sortUsers(getCurrentProperty(globalInfo).propertyUsers));
    logger.info('UserManagement - users loaded');
    setIsLoadingUsers(false);
  };

  const currentPropertyId = getCurrentPropertyId();
  const currentPropertyName = getPropertyName({
    propertyId: currentPropertyId,
  });
  const usersMissing =
    (isOnPage({ page: FRONTEND_PAGES.SETTINGS_USERS, location }) ||
      isSetupPage) &&
    !isDefined(globalInfo.properties[currentPropertyId].propertyUsers);
  if (usersMissing && !isLoadingUsers) {
    loadUsers();
  }

  /**
   * Event handlers
   */

  const handleAddUserClick = () => {
    logger.info('UserManagement:handleAddUserClick');
    setIsAddingUser(true);
  };

  // Can be removed with feature flag.
  const handleAddUserCancel = () => {
    logger.info('UserManagement:handleAddUserCancel');
    setIsAddingUser(false);
  };

  // Can be removed with feature flag.
  const handleAddUserDone = (callback) => {
    logger.info('UserManagement:handleAddUserDone');
    // Refresh global info
    refreshGlobalInfo({
      variable: 'isAddingUser',
      message: 'User added',
      callback,
    });
  };

  const handleErrorClose = () => {
    logger.info('UserManagement:handleErrorClose');
    setErrorMessage('');
  };

  const handleInviteResend = (userId) => {
    return () => {
      logger.info(`UserManagement:handleInviteResend ${userId}`);
      if (
        window.confirm(
          'The user has not activated their account. Resend invitation?',
        )
      ) {
        const user = getUser({ userId });
        postPropertiesUser({
          propertyId: globalInfo.current.propertyId,
          name: user.name,
          username: user.username,
          emailAddress: user.emailAddress,
          permissionsOnProperty: user.permissions,
        })
          .then(() => {
            setInvitesResent((prev) => [...prev, userId.toString()]);
            addSuccessNotification('Invitation sent');
          })
          .catch((error) => {
            console.log(error);
            addErrorNotification('Failed to send invitation');
          });
      }
    };
  };

  const handlePagesCancel = () => {
    logger.info('UserManagement:handlePagesCancel');
    setIsEditingPages(false);
  };

  const handlePagesEdit = (userId) => {
    return () => {
      logger.info(`UserManagement:handlePagesEdit ${userId}`);
      setIsEditingPages(true);
      setEditingPagesFor({ userId, accountAPIIds: [] });
    };
  };

  const handlePagesSave = () => {
    logger.info('UserManagement:handlePagesSave');
    setIsSavingUser(true);
  };

  const handlePagesSaved = () => {
    logger.info('UserManagement:handlePagesSaved');
    // Refresh global info
    refreshGlobalInfo({
      variable: 'isSavingUser',
      message: 'Pages updated',
      callback: () => {
        setIsEditingPages(false);
      },
    });
  };

  const handlePropertyPermissionChange = (userId) => {
    return () => {
      logger.info(`UserManagement:handlePropertyPermissionChange ${userId}`);
      setIsSavingUser(true);
      const propertyId = getCurrentPropertyId({
        globalInfo,
      });
      const newPermissionTypeId = getUserPropertyPermission({
        userId,
        propertyId,
        globalInfo,
      });

      const permissionsOnProperty = [];
      if (newPermissionTypeId === PERMISSION_TYPES.ADMIN) {
        // Changing from ADMIN to EDITOR
        // Create VIEW_ONLY permission for property and EDITOR for all apis
        logger.info(
          'UserManagement:handlePropertyPermissionChange - changing from ADMIN to EDITOR...',
        );
        permissionsOnProperty.push({
          propertyId,
          permissionTypeId: PERMISSION_TYPES.VIEW_ONLY,
        });
        const accountAPIs = getAPIsByStateAndType({
          propertyId,
          apiStateId: [
            API_STATES.ACTIVE,
            API_STATES.BAD_AUTH,
            API_STATES.UNDER_SETUP,
          ],
          globalInfo,
        });
        accountAPIs.forEach((accountAPI) => {
          permissionsOnProperty.push({
            propertyId,
            accountAPIId: accountAPI.accountAPIId,
            permissionTypeId: PERMISSION_TYPES.EDITOR,
          });
        });
      } else {
        // Changing from EDITOR to ADMIN
        // Create ADMIN permission for property
        logger.info(
          'UserManagement:handlePropertyPermissionChange - changing from EDITOR to ADMIN...',
        );
        permissionsOnProperty.push({
          propertyId,
          permissionTypeId: PERMISSION_TYPES.ADMIN,
        });
      }

      // Save change
      const user = getUser({ userId });
      postPropertiesUser({
        propertyId: globalInfo.current.propertyId,
        name: user.name,
        username: user.username,
        emailAddress: user.emailAddress,
        permissionsOnProperty,
      })
        .then(() => {
          // Refresh global info
          refreshGlobalInfo({
            variable: 'isSavingUser',
            message: 'Role updated',
          });
        })
        .catch((error) => {
          console.log(error);
          setIsSavingUser(false);
          addErrorNotification(error);
        });
    };
  };

  const handleUserDelete = (emailAddress) => {
    return () => {
      logger.info(`UserManagement:handleUserDelete ${emailAddress}`);
      if (window.confirm('Are you sure you want to delete this user?')) {
        setIsSavingUser(true);
        deletePropertiesUser({
          propertyId: globalInfo.current.propertyId,
          emailAddress,
        })
          .then(() => {
            // Refresh global info
            refreshGlobalInfo({
              variable: 'isSavingUser',
              message: 'User removed',
            });
          })
          .catch(() => {
            addErrorNotification('Failed');
            setIsSavingUser(false);
          });
      }
    };
  };

  /**
   * Helper methods
   */

  const refreshGlobalInfo = ({
    variable = mandatory('variable'),
    message = mandatory('message'),
    callback = null,
  } = {}) => {
    let newGlobalInfo = context.global.getGlobalInfo();
    if (!isNull(newGlobalInfo)) {
      context.global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        callback: () => {
          newGlobalInfo = context.global.getGlobalInfo();
          setPropertyUsers(
            sortUsers(getCurrentProperty(newGlobalInfo).propertyUsers),
          );
          switch (variable) {
            case 'isAddingUser':
              setIsAddingUser(false);
              break;
            case 'isSavingUser':
              setIsSavingUser(false);
              break;
            default:
            //
          }
          addSuccessNotification(message);
          if (typeof callback === 'function') {
            callback();
          }
        },
        allowUnderSetup: true,
      });
    }
  };

  /**
   * Render methods
   */

  const renderName = (user) => {
    const isInvited = user.userState === USER_STATES.INVITED;
    const isResent = invitesResent.indexOf(user.userId.toString()) > -1;
    const className = isSavingUser ? 'saving_locked' : '';

    return (
      <td
        className="d-flex border-0 align-items-center"
        data-cy-id={user.userId}
      >
        <div className="mr-2 overflow-hidden">
          <div className="user-name text-truncate">{user.name}</div>
          <div className="user-email text-truncate">{user.emailAddress}</div>
        </div>
        {isInvited && !isResent && (
          <Button
            className={`ml-auto ${className}`}
            onClick={!isSavingUser ? handleInviteResend(user.userId) : null}
            disabled={isSavingUser}
            data-cy-action="resendInvite"
          >
            <i className="fa fa-exclamation-triangle" aria-hidden="true" />
            <span>Resend invite</span>
          </Button>
        )}
        {isInvited && isResent && (
          <span className="p-1 ml-auto" disabled>
            Invitation sent
            <img
              src="/img/icons/ic-checkmark-dark.svg"
              alt=""
              width="12px"
              className="ml-1"
            />
          </span>
        )}
      </td>
    );
  };

  const renderPagePermissions = (user, isCurrentUser) => {
    const newPermissionTypeId = getUserPropertyPermission({
      userId: user.userId,
      propertyId: getCurrentPropertyId({
        globalInfo,
      }),
      globalInfo,
    });
    const isLocked = isSavingUser || !isUserManagementEnabled;
    const className = isLocked ? 'saving_locked' : '';

    // If the user is an administrator they have access to all pages
    if (isCurrentUser || newPermissionTypeId === PERMISSION_TYPES.ADMIN) {
      return <td className={className}>All Pages</td>;
    }

    const apiList = getAPIsByStateAndType({
      propertyId: globalInfo.current.propertyId,
      apiStateId: [
        API_STATES.ACTIVE,
        API_STATES.BAD_AUTH,
        API_STATES.UNDER_SETUP,
      ],
      globalInfo,
    });
    const userPagePermissions = getUserPagePermissions({
      userId: user.userId,
      propertyId: globalInfo.current.propertyId,
      globalInfo,
    });

    const buttonLabel =
      apiList.length === userPagePermissions.length
        ? 'All Pages'
        : `${userPagePermissions.length} Page${
            userPagePermissions.length !== 1 ? 's' : ''
          }`;

    return (
      <td
        data-tip
        data-for={`property-permission-${user.userId}`}
        data-iscapture="true"
      >
        <Tooltip
          id={`property-permission-${user.userId}`}
          label={EDITOR_ACCESS_MESSAGE}
          isDisabled={isUserManagementEnabled}
        >
          <Button
            className={className}
            onClick={!isLocked ? handlePagesEdit(user.userId) : null}
            disabled={isLocked}
            data-cy-action="pages"
          >
            {buttonLabel}
          </Button>
        </Tooltip>
      </td>
    );
  };

  const renderPropertyPermission = (user, isCurrentUser) => {
    const isLocked = isSavingUser || !isUserManagementEnabled;
    const className = isLocked ? 'saving_locked' : '';

    if (isCurrentUser) {
      return <td className={className}>Administrator</td>;
    }

    let newPermissionTypeId = getUserPropertyPermission({
      userId: user.userId,
      propertyId: getCurrentPropertyId({
        globalInfo,
      }),
      globalInfo,
    });

    if (newPermissionTypeId === PERMISSION_TYPES.VIEW_ONLY) {
      newPermissionTypeId = PERMISSION_TYPES.EDITOR;
    }

    const tooltipLabel = (
      <Box whiteSpace="initial">{EDITOR_ACCESS_MESSAGE}</Box>
    );

    return (
      <td>
        <Tooltip
          id={`property-permission=${user.userId}`}
          label={tooltipLabel}
          isDisabled={isUserManagementEnabled}
        >
          <Box
            width="fit-content"
            data-tip
            data-for={`property-permission=${user.userId}`}
            data-iscapture="true"
          >
            {!isSetupPage ? (
              <select
                name="permssionTypeId"
                className={`btn btn-light dropdown-md ${className}`}
                value={newPermissionTypeId}
                data-value={newPermissionTypeId}
                onChange={handlePropertyPermissionChange(user.userId)}
                disabled={isLocked}
                data-cy-select="role"
              >
                <option value={PERMISSION_TYPES.ADMIN}>
                  {permissionTypeMap[PERMISSION_TYPES.ADMIN]}
                </option>
                <option value={PERMISSION_TYPES.EDITOR}>
                  {permissionTypeMap[PERMISSION_TYPES.EDITOR]}
                </option>
              </select>
            ) : (
              permissionTypeMap[newPermissionTypeId]
            )}
          </Box>
        </Tooltip>
      </td>
    );
  };

  const renderRemove = (user, isCurrentUser) => {
    if (isCurrentUser) {
      return <td>&nbsp;</td>;
    }

    const isLocked = isSavingUser;
    const buttonClass = isLocked ? 'saving_locked' : '';

    return (
      <td>
        <Button
          className={buttonClass}
          onClick={!isLocked ? handleUserDelete(user.emailAddress) : null}
          disabled={isLocked}
          data-cy-action="removeUser"
        >
          Remove
        </Button>
      </td>
    );
  };

  const renderRow = (user) => {
    const isCurrentUser = user.userId === globalInfo.user.userId;

    return (
      <tr key={`row-user-${user.userId}`}>
        {renderName(user)}
        {renderPropertyPermission(user, isCurrentUser)}
        {!isSetupPage && renderPagePermissions(user, isCurrentUser)}
        {renderRemove(user, isCurrentUser)}
      </tr>
    );
  };

  const renderTable = (users) => {
    const className = !isUserManagementEnabled ? 'saving_locked' : '';
    return (
      <table className="table-responsive table-fixed">
        <tbody>
          <tr className="table-header">
            <th className="w-60">User</th>
            <th className="w-15">
              <span className={className}>Role</span>
            </th>
            {!isSetupPage && (
              <th className="w-10">
                <span className={className}>Pages</span>
              </th>
            )}
            <th className="w-10">&nbsp;</th>
          </tr>
          {users.map((user) => renderRow(user))}
        </tbody>
      </table>
    );
  };

  if (permissionTypeId !== PERMISSION_TYPES.ADMIN) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <>
      <div className="d-flex box-header">
        <h3 className="align-self-center">Team</h3>
        <div className="ml-auto">
          {isUserAddModalEnabled() ? (
            <>
              <Button onClick={handleAddUserClick}>Add User</Button>
              <UserAddNew
                isAddingUser={isAddingUser}
                globalInfo={globalInfo}
                currentPropertyId={currentPropertyId}
                currentPropertyName={currentPropertyName}
                onUserAdd={() => setIsAddingUser(!isAddingUser)}
              />
            </>
          ) : (
            <UserAdd
              canEditRole={!isSetupPage}
              isAddingUser={isAddingUser}
              isSavingUser={isSavingUser}
              eventHandlers={{
                handleAddUserCancel,
                handleAddUserClick,
                handleAddUserDone,
              }}
            />
          )}
        </div>
      </div>
      <div className="part settingsUsers table-responsive">
        {!isUserManagementEnabled && !isSetupPage && (
          <div className="message-upgrade text-500 my-3 px-2 py-1">
            <img
              src="/img/icons/ic-upgrade-bolt.svg"
              className="upgrade"
              style={{
                top: '-1px',
                position: 'relative',
                marginRight: '6px',
              }}
              alt=""
            />
            {EDITOR_ACCESS_MESSAGE}
          </div>
        )}
        <SettingsError
          errorMessage={errorMessage}
          eventHandlers={{ handleErrorClose }}
        />
        {isLoadingUsers ? (
          <div className="text-center my-3 py-3">
            <Spinner size="lg" />
            <div className="my-3 text-400">Fetching your users...</div>
          </div>
        ) : (
          renderTable(propertyUsers)
        )}
      </div>
      {isEditingPages && (
        <UserPages
          key={editingPagesFor.userId}
          userId={editingPagesFor.userId}
          availableAPIs={getAPIsByStateAndType({
            propertyId: globalInfo.current.propertyId,
            apiStateId: [
              API_STATES.ACTIVE,
              API_STATES.BAD_AUTH,
              API_STATES.UNDER_SETUP,
            ],
            globalInfo,
          })}
          selectedAPIs={getUserPagePermissions({
            userId: editingPagesFor.userId,
            propertyId: globalInfo.current.propertyId,
            globalInfo,
          }).map((permission) => permission.accountAPIId)}
          eventHandlers={{
            handlePagesCancel,
            handlePagesSave,
            handlePagesSaved,
          }}
        />
      )}
    </>
  );
};

export default UserManagement;
