import * as React from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { WOOTRIC_ACCOUNT_TOKEN } from 'common/config';
import { convertToPropertyURN } from 'common/urn';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { GlobalInfo } from 'types';

const WootricWidget = () => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo() as GlobalInfo.GlobalInfo;
  const user = globalInfo?.user;

  React.useEffect(() => {
    if (!window.wootric && user) {
      const { emailAddress, timeCreated } = user;

      const PROPERTY_URN = convertToPropertyURN(getCurrentPropertyId());

      window.wootricSettings = {
        email: emailAddress,
        created_at: timeCreated,
        account_token: WOOTRIC_ACCOUNT_TOKEN,
        properties: {
          property_urn: PROPERTY_URN,
        },
      };

      if (document.getElementById('wootricBeaconScript') === null) {
        const beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.id = 'wootricBeaconScript';
        beacon.async = true;
        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = () => {
          if (window.wootric && typeof window.wootric === 'function') {
            window.wootric('run');
          }
        };
        document.body.appendChild(beacon);
      }
    }
  }, [user]);

  return null;
};

export default WootricWidget;
