import $ from 'jquery';
import PropTypes from 'prop-types';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getSetting } from 'common/settings';
import { isNull, isNullOrUndefined } from 'common/utility';
import ItemBadges from 'components/header/ItemBadges';
import ItemPublished from 'components/header/ItemPublished';
import ItemTitle from 'components/header/ItemTitle';

const Item = (props) => {
  const { mediaItem, rank, searchText, eventHandlers } = props;

  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const articleTitle = MediaItem.getRssTitle({ mediaItem });
  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const itemTags = MediaItem.getTags({ mediaItem });
  const mediaId = MediaItem.getMediaId({ mediaItem });
  const mediaStatesByAccountAPIId = MediaItem.getMediaStatesByAccountAPIId({
    mediaItem,
  });
  const publishedTime = MediaItem.getMostRecentUnixTimePublished({
    mediaItem,
  });
  const totalShares = MediaItem.getTotalNumberOfShares({ mediaItem });
  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getCurrentPropertyId(),
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;

  const handleArticleShare = (event) => {
    const classClicked = $(event.target).attr('class');
    const linkClicked =
      !isNullOrUndefined(classClicked) &&
      classClicked.indexOf('article_link') !== -1;
    const spanClicked = $(event.target).closest('#articleLink').length === 1;
    if (!linkClicked && !spanClicked) {
      eventHandlers.handleArticleShare(mediaItem, rank);
    }
  };

  return (
    <div
      className="dropdown-item"
      onClick={handleArticleShare}
      data-cy-id="searchResult"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <div className="ic-article">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12.4444 0H1.55556C0.7 0 0 0.7 0 1.55556V12.4444C0 13.3 0.7 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444V1.55556C14 0.7 13.3 0 12.4444 0ZM8.55556 10.8889H3.11111V9.33333H8.55556V10.8889ZM10.8889 7.77778H3.11111V6.22222H10.8889V7.77778ZM10.8889 4.66667H3.11111V3.11111H10.8889V4.66667Z" />
        </svg>
      </div>
      <div className="article-content text-truncate">
        <ItemTitle
          accountAPIId={accountAPIId}
          articleTitle={articleTitle}
          itemTags={itemTags}
          searchText={searchText}
        />
        <ItemPublished
          articleURL={articleURL}
          publishedTime={publishedTime}
          twelveHourFormat={twelveHourFormat}
        />
      </div>
      <ItemBadges
        accountAPIId={accountAPIId}
        mediaStates={mediaStatesByAccountAPIId}
        totalShares={totalShares}
      />
    </div>
  );
};

Item.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired,
  searchText: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleShare: PropTypes.func.isRequired,
  }).isRequired,
};

export default Item;
