import {
  API_PROPERTIES,
  API_TYPE_IDS,
  MEDIA_ITEM_TYPES,
} from 'common/constants';

export {
  convertToPropertyURN,
  convertToShortenAPIURN,
  convertToSocialPageURN,
  encodeMediaURN,
  extractAccountAPIId,
  extractImageURL,
  extractPropertyId,
  extractViewIdFromGATrafficURN,
  getAPITypeFromSocialPageURN,
  getMediaItemType,
  isMediaURNType,
  isSocialAPIURNType,
  isSocialPageURNAPIType,
  isValidSyndFeedURN,
};

/**
 * Map a property id to property urn
 * @param {string | number} propertyId
 * @returns property URN
 */
function convertToPropertyURN(propertyId) {
  return `urn:property:${propertyId}`;
}

/**
 * Extract property Id from URN
 * @param {String} urn = property URN like urn:property:102
 * @returns integer property id (102 in above example)
 */
function extractPropertyId(urn) {
  const matches = urn.match(/urn:property:(\d+)/);
  if (matches) {
    return parseInt(matches[1], 10);
  }
  throw new Error(`No valid property URN ${urn}`);
}

/**
 * Extracts the view id from a ga traffic urn
 * @param {string} gaTrafficAPIURN  the GA traffic urn
 * @returns the view id
 */
function extractViewIdFromGATrafficURN(gaTrafficAPIURN) {
  const matches = gaTrafficAPIURN.match(/urn:traffic:api:ga:\S+:view:(\d+)/);
  if (matches) {
    return parseInt(matches[1], 10);
  }
  throw new Error(`No valid traffic URN ${gaTrafficAPIURN}`);
}

/**
 * Map social network type and accountAPIId to a socialPageURN
 * @param {String | undefined} urnSocialNetworkType
 * @param {int} accountAPIId
 * @returns social page urn
 */
function convertToSocialPageURN(urnSocialNetworkType, accountAPIId) {
  return `urn:social:page:${urnSocialNetworkType}:${accountAPIId}`;
}

/**
 * Map Bitly accountAPIId to a shortenAPIURN
 * @param {String | undefined} urnSocialNetworkType the shortname of the shorten API type
 * @param {int} accountAPIId
 * @returns string social page urn
 */
function convertToShortenAPIURN(urnSocialNetworkType, accountAPIId) {
  return `urn:shorten:api:${urnSocialNetworkType}:${accountAPIId}`;
}

/**
 * Extract the accountAPIId from the social page urn or shorten api urn
 * @param {String} urn
 * @returns
 */
function extractAccountAPIId(urn) {
  const matches = urn.match(
    /urn:(social:page|shorten:api|social:app):([a-zA-Z]{2}):(\d+)/,
  );
  if (matches) {
    return parseInt(matches[3], 10);
  }
  throw new Error(`No valid social api URN ${urn}`);
}

/**
 * Extracts the image URL from a URN.
 * @param {string} urn the URN to extract the image URL from
 * @returns {string} The image URL
 */
function extractImageURL(urn) {
  const matches = urn.match(/urn:media:image:url:(.*)/);
  if (matches) {
    return matches[1];
  }
  throw new Error(`No valid image URN ${urn}`);
}

/**
 * Returns whether the socialpage urn is requested apiType
 * @param {*} urn
 * @param {*} apiTypeId
 * @returns
 */
function isSocialPageURNAPIType(urn, apiTypeId) {
  const matches = urn.match(/urn:social:page:([a-zA-Z]{2}):(\d+)/);
  if (matches) {
    const socialPageType = matches[1];
    return socialPageType === API_PROPERTIES[apiTypeId]?.urnName;
  }
  return false;
}

/**
 * Returns whether the string is a social page or a shorten api URN
 * @param {*} urn
 * @returns
 */
function isSocialAPIURNType(urn) {
  const matches = urn.match(
    /urn:(social:page|shorten:api):([a-zA-Z]{2}):(\d+)/,
  );
  return matches;
}

/**
 * Returns whether the string is a media URN
 * @param {*} mediaId
 * @returns
 */
function isMediaURNType(mediaId) {
  return mediaId.match(/urn:media:(image|video):url:(.*)/);
}

/**
 * Encodes the string if it is a media URN
 * @param {*} mediaId
 * @returns
 */
function encodeMediaURN(mediaId) {
  return isMediaURNType(mediaId) ? encodeURIComponent(mediaId) : mediaId;
}

/**
 * Get the media type from the media URN
 * @param {*} mediaId
 * @returns
 */
function getMediaItemType(mediaId) {
  const matches = isMediaURNType(mediaId);
  if (matches) {
    return MEDIA_ITEM_TYPES[matches[1].toUpperCase()];
  }
  return MEDIA_ITEM_TYPES.ARTICLE;
}

function extractURNNameFromSocialPageURN(urn) {
  const matches = urn.match(/urn:social:page:([^:]+):\d+/);

  if (matches) {
    return matches[1];
  }

  return null;
}

function getAPITypeFromSocialPageURN(urn) {
  if (typeof urn !== 'string') {
    return null;
  }

  const urnName = extractURNNameFromSocialPageURN(urn);
  let apiType = null;

  Object.values(API_TYPE_IDS).forEach((type) => {
    if (
      API_PROPERTIES[type].isSocialNetwork &&
      API_PROPERTIES[type].urnName === urnName
    ) {
      apiType = API_PROPERTIES[type].apiType;
    }
  });

  return apiType;
}

function isValidSyndFeedURN(urn) {
  const syndFeedURNPrefix = 'urn:syndfeed:url:';

  if (!urn.includes(syndFeedURNPrefix)) {
    return false;
  }

  return true;
}
