import type { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as metrics from 'common/metrics';

export default async function postZendeskAuth() {
  const headers = getClientServiceRequestHeaders();

  const guid = metrics.start('postZendeskAuth');
  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL('v3')}/zendeskauth`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers,
  };

  try {
    const response = await axios(config);
    metrics.end('postZendeskAuth', guid);
    return response.data.jwt;
  } catch (error) {
    metrics.fail('postZendeskAuth', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postZendeskAuth',
    });
    throw apiError;
  }
}
