import {
  Box,
  Button,
  DeleteTrashIcon,
  Flex,
  Heading,
  IconButton,
  Progress,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import * as API from 'api/api';
import {
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentSocialPageURN,
} from 'common/accountAPIs';
import { determineError, getErrorMessage } from 'common/errorHandling';
import * as Logger from 'common/logger';
import {
  convertEchoboxImageToAmazon,
  convertEchoboxVideoToAmazon,
} from 'common/s3';
import { uploadVideo } from 'common/video';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import useThumbnail from 'hooks/useThumbnail';
import { Frame } from 'types';
import { validateImage, validateVideo } from './validation';

const LABEL_MAP: Record<Frame, string> = {
  frame1: 'Frame 1',
  frame2: 'Frame 2',
  frame3: 'Frame 3',
};

type UploadEditorState =
  | {
      state: 'INITIAL';
    }
  | {
      state: 'UPLOADING';
      percent: number;
      fileName?: string;
    }
  | {
      state: 'UPLOADED';
      fileURL: string;
      fileName?: string;
    }
  | {
      state: 'FAILED';
      errorMsg: string;
    };

type ActionType =
  | { type: 'UPLOAD_START'; payload: { fileName: string } }
  | { type: 'UPLOAD_PROGRESS'; payload: { percent: number } }
  | { type: 'UPLOAD_FAILED'; payload: { errorMsg: string } }
  | { type: 'UPLOAD_FINISH'; payload: { fileURL: string } }
  | { type: 'RESET' };

const reducer = (
  s: UploadEditorState,
  action: ActionType,
): UploadEditorState => {
  switch (action.type) {
    case 'UPLOAD_START':
      return {
        state: 'UPLOADING',
        fileName: action.payload.fileName,
        percent: 0,
      };
    case 'UPLOAD_PROGRESS':
      return { ...s, state: 'UPLOADING', percent: action.payload.percent };
    case 'UPLOAD_FAILED':
      return { state: 'FAILED', errorMsg: action.payload.errorMsg };
    case 'UPLOAD_FINISH':
      return { ...s, state: 'UPLOADED', fileURL: action.payload.fileURL };
    case 'RESET':
    default:
      return initialState;
  }
};

const initialState: UploadEditorState = { state: 'INITIAL' };

interface ImageEditorProps {
  frame?: Frame;
  type: 'imageURL' | 'videoURL';
  onCancel: () => void;
  onSave: (value: string) => void;
}
const UploadEditor = ({ frame, type, onCancel, onSave }: ImageEditorProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const accountAPIId = getCurrentAccountAPIId({ globalInfo });
  const apiTypeId = getAPITypeId({ accountAPIId, globalInfo });

  const [state, dispatch] = React.useReducer(reducer, initialState);

  const processImage = async (file: File) => {
    let errorMsg = validateImage(file, apiTypeId);
    if (errorMsg) {
      dispatch({ type: 'UPLOAD_FAILED', payload: { errorMsg } });
      return;
    }

    dispatch({
      type: 'UPLOAD_START',
      payload: { fileName: file.name },
    });

    try {
      const ebxURL = await API.postUploadImage({
        socialPageURN: getCurrentSocialPageURN(),
        onProgress: ({ percent: p }) => {
          dispatch({ type: 'UPLOAD_PROGRESS', payload: { percent: p } });
        },
        imageFile: file,
      });

      const imageURL = convertEchoboxImageToAmazon(ebxURL);

      dispatch({ type: 'UPLOAD_FINISH', payload: { fileURL: imageURL } });
    } catch (error) {
      Logger.error({
        event: 'Failed to upload image for Instant Video frame',
        error,
      });

      errorMsg = getErrorMessage(determineError(error));

      dispatch({
        type: 'UPLOAD_FAILED',
        payload: { errorMsg: errorMsg ?? 'Unknown error occurred' },
      });
    }
  };

  const processVideo = async (file: File) => {
    let errorMsg = validateVideo(file);
    if (errorMsg) {
      dispatch({ type: 'UPLOAD_FAILED', payload: { errorMsg } });
      return;
    }

    dispatch({
      type: 'UPLOAD_START',
      payload: { fileName: file.name },
    });

    try {
      const ebxVideo = await uploadVideo({
        file,
        socialPageURN: getCurrentSocialPageURN()!,
        onProgress: ({ percent }) => {
          dispatch({ type: 'UPLOAD_PROGRESS', payload: { percent } });
        },
      });

      const videoURL = convertEchoboxVideoToAmazon(ebxVideo)!;

      dispatch({ type: 'UPLOAD_FINISH', payload: { fileURL: videoURL } });
    } catch (error) {
      Logger.error({
        event: 'Failed to upload video for Instant Video frame',
        error,
      });

      errorMsg = getErrorMessage(determineError(error));

      dispatch({
        type: 'UPLOAD_FAILED',
        payload: { errorMsg: errorMsg ?? 'Unknown error occurred' },
      });
    }
  };

  const onDrop = async ([file]: File[]) => {
    if (type === 'imageURL') {
      processImage(file);
    } else {
      processVideo(file);
    }
  };

  let uploadUI = null;
  switch (state.state) {
    case 'UPLOADING':
      uploadUI = (
        <UploadingImage fileName={state.fileName} percent={state.percent} />
      );
      break;
    case 'UPLOADED':
      uploadUI = (
        <UploadedImage
          fileName={state.fileName}
          type={type}
          fileURL={state.fileURL}
          onDelete={() => dispatch({ type: 'RESET' })}
        />
      );
      break;
    case 'FAILED':
      uploadUI = (
        <UploadImage
          type={type}
          hasFailed
          errorMsg={state.errorMsg}
          onDrop={onDrop}
        />
      );
      break;
    default:
      uploadUI = <UploadImage type={type} onDrop={onDrop} />;
  }

  return (
    <Flex flexDir="column" gap={3}>
      <Flex flexDir="column" gap={2}>
        <Text size="sm" lineHeight="140%" fontWeight="medium">
          {frame
            ? `${LABEL_MAP[frame]} ${type === 'imageURL' ? 'image' : 'background video'}`
            : 'Background video'}
        </Text>
        {uploadUI}
      </Flex>
      <Flex gap={3}>
        {state.state === 'UPLOADED' && (
          <Button type="button" onClick={() => onSave(state.fileURL)}>
            Save
          </Button>
        )}
        <Button type="button" onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};

export default UploadEditor;

interface UploadImageProps {
  type: 'imageURL' | 'videoURL';
  hasFailed?: boolean;
  errorMsg?: string;
  onDrop: (files: File[]) => void;
}
function UploadImage({ type, hasFailed, errorMsg, onDrop }: UploadImageProps) {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
    accept:
      type === 'imageURL'
        ? { 'image/jpeg': [], 'image/png': [] }
        : { 'video/mp4': [], 'video/quicktime': [] },
  });

  return (
    <>
      <Flex
        flexDir="column"
        borderRadius="md"
        border="1px"
        borderStyle="dashed"
        borderColor="#DBE3F0"
        py={8}
        px={10}
        gap={4}
        {...getRootProps()}
        {...(isDragActive && { bg: 'base' })}
        {...(hasFailed && {
          boxShadow: 'error',
          borderColor: 'red.600',
          borderStyle: 'solid',
        })}
      >
        <Heading as="p" variant="h5" color="text.secondary">
          {type === 'imageURL'
            ? 'Drag and drop an image'
            : 'Drag and drop a video'}
        </Heading>
        <input {...getInputProps()} />
        <Button w="fit-content" variant="secondary" onClick={open}>
          Select file
        </Button>
      </Flex>
      {hasFailed && errorMsg && (
        <Text size="sm" fontWeight="medium" color="red.600">
          {errorMsg}
        </Text>
      )}
      <Text size="xs" color="text.secondary">
        {type === 'imageURL'
          ? 'File types: PNG, JPG - 8MB max'
          : 'File types: MP4, MOV - 30MB max'}
      </Text>
    </>
  );
}

interface UploadingImageProps {
  fileName?: string;
  percent: number;
}
function UploadingImage({ fileName, percent }: UploadingImageProps) {
  return (
    <Flex p={4} bg="base" boxShadow="border" flexDir="column" gap={4}>
      <Flex gap={4} w="full">
        <Heading variant="h5" flexGrow={1}>
          {fileName}
        </Heading>
        {percent != null && (
          <Heading as="span" variant="h5" color="text.secondary">
            {percent}%
          </Heading>
        )}
      </Flex>
      <Progress value={percent} borderRadius="3px" h={1} />
    </Flex>
  );
}

interface UploadedImageProps {
  fileName?: string;
  type: 'imageURL' | 'videoURL';
  fileURL: string;
  onDelete: () => void;
}
function UploadedImage({
  fileName,
  type,
  onDelete,
  fileURL,
}: UploadedImageProps) {
  const thumbnailURL = useThumbnail({
    videoURL: fileURL,
    shouldFetch: type === 'videoURL',
  });

  const src = type === 'imageURL' ? fileURL : thumbnailURL;
  return (
    <Flex
      borderRadius="md"
      p={4}
      bg="base"
      boxShadow="border"
      gap={4}
      alignItems="center"
    >
      {src && (
        <Box
          as="img"
          src={src}
          objectFit="contain"
          w={11}
          h={11}
          borderRadius={4}
          boxShadow="border"
          p="1px"
        />
      )}
      <Heading flexGrow={1} variant="h5">
        {fileName}
      </Heading>
      <IconButton
        aria-label="Delete image"
        size="lg"
        color="gray.600"
        icon={<DeleteTrashIcon />}
        onClick={onDelete}
      />
    </Flex>
  );
}
