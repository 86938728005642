import { TAG_TYPES } from 'common/constants';
import { isDefined, isNull, isUndefined } from 'common/utility';
import type { ShareContentResult, Tag, TagMap } from 'types';

function identifyReplacementField({
  decoratedText,
  replacementFields,
}: {
  decoratedText?: string;
  replacementFields: TagMap;
}) {
  let tagType;
  let field: Tag;
  for (let tid = 0; tid < Object.values(TAG_TYPES).length; tid += 1) {
    tagType = Object.values(TAG_TYPES)[tid];
    for (let idx = 0; idx < replacementFields[tagType].length; idx += 1) {
      field = replacementFields[tagType][idx];
      if (decoratedText === field.raw) {
        return field;
      }
    }
  }
  return null;
}

function regexpEscape(text: string) {
  return text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

function renderMessageTags({
  message,
  tags,
}: {
  message: string | null;
  tags: TagMap;
}) {
  if (isNull(message)) {
    return '';
  }
  // Convert all tags in the message from using the "internal" tag format (e.g. #FB[EchoboxHQ])
  // to using the "raw" tag format (e.g. #EchoboxHQ) so we can then search for instances of
  // each tag's "raw" representation and replace it with the "clean" user-facing label
  let output = message;
  Object.values(TAG_TYPES).forEach((tagType) => {
    const tagsOfType = tags[tagType];
    tagsOfType.forEach((tag) => {
      const find = new RegExp(`${regexpEscape(tag.raw)}`, 'gi');
      output = output.replace(find, tag.clean);
    });
  });
  return output;
}

function cleanTags(message: string, tags: TagMap): string;
function cleanTags(
  message: ShareContentResult,
  tags: TagMap,
): ShareContentResult;
function cleanTags(message: null, tags: TagMap): string;
function cleanTags(message: undefined, tags: TagMap): undefined;
function cleanTags(
  message: string | ShareContentResult | null | undefined,
  tags: TagMap,
) {
  if (isUndefined(message)) {
    return message;
  }
  if (isNull(message)) {
    return '';
  }
  if (typeof message === 'string') {
    return renderMessageTags({ message, tags });
  }
  const cleaned = message;
  if (isDefined(cleaned.orderedShareContent)) {
    cleaned.orderedShareContent.forEach((text, index) => {
      cleaned.orderedShareContent[index].value = renderMessageTags({
        message: cleaned.orderedShareContent[index].value,
        tags,
      });
    });
  }
  return cleaned;
}

export { cleanTags, identifyReplacementField, regexpEscape, renderMessageTags };
