import {
  Box,
  BoxProps,
  Circle,
  Image,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { SOCIAL_PLATFORM_DEFAULT_ICONS } from 'common/constants';
import { getSocialNetworkDefaultPageIcon } from 'common/social';
import BaseSocialLogo from 'components/header/SocialLogo';
import SocialIcon from 'components/misc/SocialIcon';
import type { FixTypeLater } from 'types';
import { PageImageProvider, usePageImageContext } from './PageImageContext';

interface PageImageProps extends BoxProps {
  accountAPI: FixTypeLater;
}

const PageImage = ({ accountAPI, ...rest }: PageImageProps) => {
  if (!accountAPI) {
    return null;
  }

  return (
    <PageImageProvider accountAPI={accountAPI}>
      <Box position="relative" pr={1} pb={1} {...rest} />
    </PageImageProvider>
  );
};

const SocialLogo = (
  props: Omit<React.ComponentProps<typeof BaseSocialLogo>, 'apiTypeId'>,
) => {
  const { accountAPI } = usePageImageContext();

  if (!accountAPI) {
    return null;
  }

  const { apiTypeId } = accountAPI;

  if (!apiTypeId) {
    return null;
  }

  return <BaseSocialLogo apiTypeId={apiTypeId} {...props} />;
};

type AccountAPIImageProps = React.ComponentProps<typeof Image> &
  React.ComponentProps<typeof Circle> & {
    socialIconSize?: React.ComponentProps<typeof SocialIcon>['size'];
  };

const AccountAPIImage = ({
  borderColor = 'gray.300',
  size,
  socialIconSize = 6,
  ...rest
}: AccountAPIImageProps) => {
  const { accountAPI } = usePageImageContext();

  if (!accountAPI) {
    return null;
  }

  const { apiPostImage, apiTypeId } = accountAPI;

  if (
    apiPostImage &&
    apiPostImage !== getSocialNetworkDefaultPageIcon({ apiTypeId }) &&
    !SOCIAL_PLATFORM_DEFAULT_ICONS.includes(apiPostImage)
  ) {
    return (
      <Image
        src={apiPostImage}
        alt=""
        border="1px"
        borderColor={borderColor}
        borderRadius="full"
        boxSize={size}
        verticalAlign="middle"
        {...rest}
      />
    );
  }

  return (
    <Circle
      border="1px"
      borderColor={borderColor}
      bg="gray.200"
      size={size}
      {...rest}
    >
      <SocialIcon
        apiTypeId={apiTypeId}
        color="gray.500"
        size={socialIconSize}
      />
    </Circle>
  );
};

PageImage.Image = AccountAPIImage;
PageImage.SocialLogo = SocialLogo;

export default PageImage;
