import { z } from 'zod';

import {
  getAccountAPI,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
  getProperty,
} from 'common/accountAPIs';
import { ACCOUNT_API_LEVEL_SETTINGS, AUTOFEED_SETTINGS } from 'common/config';
import { ACCOUNT_SETTING_TYPES, API_TYPE_IDS } from 'common/constants';
import {
  AccountAPIId,
  AutofeedSettings,
  CommonSetting,
  FeatureToggle,
  GlobalInfo,
  Settings,
  SocialAPI,
} from 'types';
import { FEATURE_TOGGLES, FEATURE_TOGGLE_DEFAULTS } from './constants/settings';

/**
 * Return the specified autofeed setting for the current property and account API
 */
export function getAutofeedSetting<T extends keyof AutofeedSettings>({
  key,
  settingIndex = 0,
  accountAPIId,
}: {
  /**
   * The name of the autofeed setting to retrieve
   */
  key: T;
  /**
   * Allows for possible future implementation of multiple entries for the same setting.
   * currently defaults to 0.
   */
  settingIndex?: number;
  /**
   * The account API ID to retrieve the setting for. If omitted, the current account API is used.
   */
  accountAPIId?: AccountAPIId;
}): AutofeedSettings[T] | null {
  try {
    const currentAccount = getAccountAPI({
      accountAPIId: accountAPIId ?? getCurrentAccountAPIId(),
    });
    if (currentAccount?.accountSettings) {
      const accountSettings = currentAccount.accountSettings;
      const autofeedSettings =
        accountSettings[ACCOUNT_SETTING_TYPES.AUTO_FEED][settingIndex];
      if (autofeedSettings.enabled) {
        const dataJSON = autofeedSettings.dataJSON as AutofeedSettings;

        if (dataJSON[key]) {
          return dataJSON[key];
        }
      }
    }
    return null; // Settings are disabled
  } catch (e) {
    return null; // Setting does not exist
  }
}

/**
 * Return details for autofeed settings for the current property and account api
 */
export function getAutofeedSettings({
  /**
   * Allows for possible future implementation of multiple entries for the same setting.
   * currently defaults to 0.
   */
  settingIndex = 0,
  /**
   * The account API ID to retrieve the setting for. If omitted, the current account API is used.
   */
  accountAPIId,
}: {
  settingIndex?: number;
  accountAPIId?: AccountAPIId;
} = {}): AutofeedSettings | null {
  const accountSettings = getAccountAPI({
    accountAPIId: accountAPIId ?? getCurrentAccountAPIId(),
  })?.accountSettings;
  const autofeedSettings =
    accountSettings?.[ACCOUNT_SETTING_TYPES.AUTO_FEED]?.[settingIndex];
  if (autofeedSettings?.enabled) {
    const dataJSON = { ...autofeedSettings.dataJSON } as AutofeedSettings;

    // Add any keys which need to have default values set
    AUTOFEED_SETTINGS.DEFAULTS.forEach((entry) => {
      if (dataJSON[entry.key]) {
        dataJSON[entry.key] = entry.value;
      }
    });

    return dataJSON;
  }
  return null; // Settings are disabled
}

/**
 * Returns sensible defaults for each different setting type
 */
function getDefaultSetting<T extends CommonSetting>({
  settingTypeId,
}: {
  settingTypeId: T;
}): Settings[T][number] {
  const isPropertySetting = !ACCOUNT_API_LEVEL_SETTINGS.includes(settingTypeId);
  const defaultSetting = { enabled: !isPropertySetting, dataJSON: {} };

  switch (settingTypeId) {
    case ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES:
      return { enabled: !isPropertySetting, dataJSON: [] };
    case ACCOUNT_SETTING_TYPES.DEFAULT_FACEBOOK_TARGETING:
      // Default structure containing empty list of countries
      defaultSetting.dataJSON = { targetingParams: { countries: [] } };
      break;
    default:
      break;
  }

  return defaultSetting;
}

/**
 * Returns a boolean to indicate whether the specified feature toggle is enabled for the provided property.
 * This differs from a 'feature flag' in that it is a setting that is enabled/disabled at the property level, not system-wide.
 */
export function getFeatureToggle({
  featureName,
  propertyId,
  settingIndex = 0,
}: {
  /**
   * The name of the feature toggle
   */
  featureName: FeatureToggle;
  /**
   * The property ID to test against this feature toggle
   */
  propertyId: string | number;
  /**
   * Allows for possible future implementation of multiple entries for the same setting.
   */
  settingIndex?: number;
}): boolean {
  const featureToggles = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.FEATURE_TOGGLES,
    propertyId,
    settingIndex,
  });

  // Ideally the `getSetting` function would return a more specific type (perhaps at the API response level),
  // but for now we'll just validate the data here.
  const schema = z.object({
    dataJSON: z.record(
      z.string(), // Keeping as string in case of server/client misalignment.
      z.boolean(),
    ),
  });

  try {
    const { dataJSON } = schema.parse(featureToggles);

    return dataJSON[featureName] ?? !!FEATURE_TOGGLE_DEFAULTS[featureName];
  } catch (e) {
    return false;
  }
}

/**
 * Get the reconnect link to the settings page for the specified account API
 */
export function getReconnectSocialPageLink({
  accountAPI,
}: {
  accountAPI: SocialAPI;
}) {
  const hasErrors =
    accountAPI.notifications && accountAPI.notifications.length > 0;

  if (
    hasErrors &&
    (accountAPI.apiTypeId === API_TYPE_IDS.INSTAGRAM ||
      accountAPI.apiTypeId === API_TYPE_IDS.TWITTER)
  ) {
    return `/settings/pages?accountAPIId=${accountAPI.accountAPIId}&reconnectPage=true`;
  }

  return '/settings/pages';
}

/**
 * Return the specified setting for the current property and account API
 */
export function getSetting<T extends CommonSetting>({
  settingTypeId,
  propertyId,
  accountAPIId = getCurrentAccountAPIId(),
  settingIndex = 0,
}: {
  settingTypeId: T;
  propertyId: string | number;
  accountAPIId?: number;
  settingIndex?: number;
}): Settings[T][number] {
  const defaultSetting = getDefaultSetting({
    settingTypeId,
  });

  if (!ACCOUNT_API_LEVEL_SETTINGS.includes(settingTypeId)) {
    // Property level setting
    const property = getProperty({ propertyId }) as GlobalInfo.Property;
    if (property?.propertySettings?.[settingTypeId]?.[settingIndex] == null) {
      return defaultSetting;
    }
    return property.propertySettings[settingTypeId][settingIndex];
  }

  // Account API level setting
  const accountAPI = getAccountAPI({ accountAPIId });
  if (accountAPI?.accountSettings?.[settingTypeId]?.[settingIndex] == null) {
    return defaultSetting;
  }
  return accountAPI.accountSettings[settingTypeId][settingIndex];
}

export function isApprovalsActive(
  accountAPIId: AccountAPIId = getCurrentAccountAPIId(),
) {
  const autoFeedActive = getAutofeedSetting({
    accountAPIId,
    key: 'autoFeedActive',
  });

  if (!autoFeedActive) {
    return false;
  }

  return isApprovalsSettingsActive(accountAPIId);
}

export function isApprovalsSettingsActive(
  accountAPIId: AccountAPIId = getCurrentAccountAPIId(),
) {
  const newShareSettings = getAutofeedSetting({
    accountAPIId,
    key: 'newShareApprovals',
  });

  const reShareSettings = getAutofeedSetting({
    accountAPIId,
    key: 'reshareApprovals',
  });

  return newShareSettings?.active || reShareSettings?.active;
}

export function isIGCabinetEnabled({
  propertyId = getCurrentPropertyId(),
}: {
  propertyId?: string | number;
} = {}) {
  return getFeatureToggle({
    featureName: FEATURE_TOGGLES.IG_CABINET_ENABLED,
    propertyId,
  });
}
