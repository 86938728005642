/* eslint no-param-reassign: "off" */
/* eslint no-use-before-define: ["error", { "functions": false }] */
import Immutable from 'seamless-immutable';

import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import {
  AB_TEST_MINIMUM_TIME_LENGTH_IN_SECS,
  BREAKING_NEWS,
  DEFAULT_AUTO_SHARE_INTERVAL,
  EVERGREEN_NEWS,
  FACEBOOK_SETTINGS,
} from 'common/config';
import {
  ACCOUNT_SETTING_TYPES,
  ADD_LINK_REASONS,
  BACKEND_LINK_POST_PROPERTIES,
  FRONTEND_LINK_POST_PROPERTIES,
  SHARE_TIME_TYPES,
  SUGGESTION_TYPES,
  TIME_SENSITIVITY_TYPES,
} from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import * as s3 from 'common/s3';
import { getSetting } from 'common/settings';
import { hasABVariations, hasMessageField } from 'common/social';
import { truncateMessage } from 'common/string';
import { getImageThumbnail, getVideoThumbnail } from 'common/thumbnails';
import {
  cloneObject,
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';

import { BACKEND_PROPERTIES, NETWORK_PROPERTIES } from './common';
import {
  addLink,
  getABInfo,
  getPreviewField,
  getPreviewFields,
  getSocialChannel,
} from './gettersSetters';

export {
  getBackendProperties,
  minMaxToShareTime,
  removeMediaItemLinkProperties,
  shareTimeToMinMax,
};

/**
 * Method for returning only properties to be sent to endpoint when saving media item
 */

function getBackendProperties({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
  isChangingState = false,
  mediaItemIndex = 0,
} = {}) {
  return new Promise((resolve, reject) => {
    const backendItem = {};
    const apiTypeId = getAPITypeId({ accountAPIId });
    const socialChannel = getSocialChannel({ mediaItem });

    // 1. Get all properties flagged as being backend properties
    //    but exclude any flagged as being network-specific
    addLink({
      mediaItem,
      reasonCode: isChangingState
        ? ADD_LINK_REASONS.CHANGE_STATE
        : ADD_LINK_REASONS.CHECK_BEFORE_SAVE,
      mediaItemIndex,
    })
      .then((updatedMediaItem) => {
        BACKEND_PROPERTIES.forEach((property) => {
          const value = updatedMediaItem.backend[property];
          if (
            isDefined(value) && // Property has a value
            isUndefined(NETWORK_PROPERTIES[property]) // Property is not network-specific
          ) {
            backendItem[property] = value;
          }
        });

        // 2. Now do any special processing required before the item can be returned

        // Convert AB variations to the required format
        if (
          hasABVariations({ apiTypeId, socialChannel }) &&
          updatedMediaItem.frontend.abInfo.isABVariation
        ) {
          delete backendItem.message;
          delete backendItem.messages;
          delete backendItem.title;
          delete backendItem.description;
          delete backendItem.imageURLs;
          delete backendItem.videoURL;
          backendItem.abTestVariations =
            updatedMediaItem.frontend.abVariations.map((feVariation) => {
              const abVariation = cloneObject(feVariation);

              // Truncate descriptions if necessary
              if (
                abVariation.description &&
                abVariation.description.length >
                  FACEBOOK_SETTINGS.DESCRIPTION_MAX_CHARS
              ) {
                abVariation.description = `${truncateMessage({
                  message: abVariation.description,
                  length: FACEBOOK_SETTINGS.DESCRIPTION_MAX_CHARS - 3,
                  apiTypeId,
                })}...`;
              }

              // Convert image and video URLs to Echobox format
              abVariation.imageURLs = abVariation.imageURLs.map((imageURL) =>
                s3.convertAmazonImageToEchobox(imageURL),
              );
              abVariation.videoURL = s3.convertAmazonVideoToEchobox(
                abVariation.videoURL,
              );
              // Remove placeholder images
              if (!isNullOrUndefined(abVariation.imageURLs)) {
                const imageURLs = [];
                abVariation.imageURLs.forEach((imageURL) => {
                  let isThumbnail = false;
                  const imagePlaceholder = getImageThumbnail({
                    apiTypeId,
                    postType: updatedMediaItem.backend.postType,
                  });
                  const videoPlaceholder = getVideoThumbnail({
                    isVideoUploaded: abVariation.videoURL !== '',
                    socialChannel,
                  });
                  if (
                    imageURL === imagePlaceholder ||
                    imageURL === videoPlaceholder
                  ) {
                    isThumbnail = true;
                  }
                  if (!isThumbnail) {
                    imageURLs.push(imageURL);
                  }
                });
                abVariation.imageURLs = imageURLs;
              }

              return abVariation;
            });
          // Convert suggestion type back to manual share
          backendItem.suggestionTypeId =
            SUGGESTION_TYPES.MANUAL_SOCIAL_AB_SHARE;
          // Ensure the share URL amd unshortened share URL is updated
          const shareURL = getPreviewField({
            mediaItem: updatedMediaItem,
            fieldName: 'shareURL',
          });
          backendItem.shareURL = shareURL ?? null;
          const unshortenedShareURL = getPreviewField({
            mediaItem: updatedMediaItem,
            fieldName: 'unshortenedShareURL',
          });
          backendItem.unshortenedShareURL = unshortenedShareURL ?? null;
        }

        const previewFields = getPreviewFields({
          mediaItem: updatedMediaItem,
          convertToEbx: true,
        });
        backendItem.imageOverlayURL = previewFields.imageOverlayURL ?? null;
        backendItem.socialChannel = previewFields.socialChannel ?? null;

        // Get preview fields
        if (
          !hasABVariations({ apiTypeId, socialChannel }) ||
          !updatedMediaItem.frontend.abInfo.isABVariation
        ) {
          delete backendItem.abTestVariations;
          if (isDefined(backendItem.abTestStatusId)) {
            delete backendItem.abTestStatusId;
          }
          if (!isNullOrUndefined(previewFields.mediaId)) {
            backendItem.mediaId = previewFields.mediaId;
          }
          backendItem.description =
            isUndefined(previewFields.description) ||
            previewFields.description === ''
              ? null
              : previewFields.description;
          backendItem.imageURLs =
            previewFields.imageURLs.length === 0
              ? null
              : previewFields.imageURLs;
          backendItem.message = isUndefined(previewFields.message)
            ? null
            : previewFields.message;
          backendItem.messages = previewFields.messages ?? null;
          backendItem.shareURL =
            isUndefined(previewFields.shareURL) || previewFields.shareURL === ''
              ? null
              : previewFields.shareURL;
          backendItem.title =
            isUndefined(previewFields.title) || previewFields.title === ''
              ? null
              : previewFields.title;
          backendItem.twitterCardType = previewFields.twitterCardType ?? null;
          backendItem.unshortenedShareURL =
            isUndefined(previewFields.unshortenedShareURL) ||
            previewFields.unshortenedShareURL === ''
              ? null
              : previewFields.unshortenedShareURL;

          backendItem.imageOverlayURL = previewFields.imageOverlayURL ?? null;
          const fbTargetingParams = previewFields.fbTargetingParams;
          if (!isNullOrUndefined(fbTargetingParams)) {
            backendItem.fbTargetingParams = fbTargetingParams;
          }

          // Truncate descriptions if necessary
          if (hasABVariations({ apiTypeId, socialChannel })) {
            if (
              !isNullOrUndefined(backendItem.description) &&
              backendItem.description.length >
                FACEBOOK_SETTINGS.DESCRIPTION_MAX_CHARS
            ) {
              backendItem.description = `${truncateMessage({
                message: backendItem.description,
                length: FACEBOOK_SETTINGS.DESCRIPTION_MAX_CHARS - 3,
                apiTypeId,
              })}...`;
            }
          }
          // If item is marked as AB share, convert to normal share
          if (
            backendItem.suggestionTypeId ===
            SUGGESTION_TYPES.MANUAL_SOCIAL_AB_SHARE
          ) {
            backendItem.suggestionTypeId = SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE;
          }
        }

        backendItem.mediaItemTags = previewFields.mediaItemTags;
        backendItem.trackingDetails = previewFields.trackingDetails;

        // Convert shareTime to min and max timestamps
        const [minShareTime, maxShareTime] = shareTimeToMinMax({
          mediaItem: updatedMediaItem,
          accountAPIId,
        });
        backendItem.minShareTime = minShareTime;
        backendItem.maxShareTime = maxShareTime;

        // Adjust AB variation timing periods if required
        if (
          hasABVariations({ apiTypeId, socialChannel }) &&
          updatedMediaItem.frontend.abInfo.isABVariation
        ) {
          const minStartTime =
            getUnixTimestamp() +
            60 * 15 * (updatedMediaItem.frontend.abVariations.length + 1);
          if (
            updatedMediaItem.frontend.shareTime.type ===
            SHARE_TIME_TYPES.TIMESLOT
          ) {
            if (updatedMediaItem.frontend.shareTime.min < minStartTime) {
              backendItem.minShareTime = minStartTime;
              const autoShareInterval = getSetting({
                settingTypeId: ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL,
                propertyId: getPropertyIdForAccountAPIId({ accountAPIId }),
              });
              if (isNull(autoShareInterval)) {
                backendItem.maxSharetime =
                  minStartTime + DEFAULT_AUTO_SHARE_INTERVAL * 3600;
              } else {
                backendItem.maxSharetime =
                  minStartTime + autoShareInterval.dataJSON.intervalSecs;
              }
            }
          }

          if (
            updatedMediaItem.frontend.shareTime.type === SHARE_TIME_TYPES.TIME
          ) {
            if (updatedMediaItem.frontend.shareTime.time < minStartTime) {
              backendItem.minShareTime = minStartTime;
              backendItem.maxShareTime = minStartTime;
            }
          }
        }

        // Convert video URLs to "ebx" format
        if (
          isDefined(updatedMediaItem.backend.videoURL) &&
          (!hasABVariations({ apiTypeId, socialChannel }) ||
            !updatedMediaItem.frontend.abInfo.isABVariation)
        ) {
          backendItem.videoURL = s3.convertAmazonVideoToEchobox(
            updatedMediaItem.backend.videoURL,
          );
          backendItem.videoURL =
            backendItem.videoURL === '' ? null : backendItem.videoURL;
        }

        // Remove placeholder images if necessary
        if (!isNullOrUndefined(backendItem.imageURLs)) {
          const imageURLs = [];
          backendItem.imageURLs.forEach((imageURL) => {
            let isThumbnail = false;
            const imagePlaceholder = getImageThumbnail({
              apiTypeId,
              postType: updatedMediaItem.backend.postType,
            });
            const videoPlaceholder = getVideoThumbnail({
              isVideoUploaded: backendItem.videoURL !== '',
              socialChannel,
            });
            if (
              imageURL === imagePlaceholder ||
              imageURL === videoPlaceholder
            ) {
              isThumbnail = true;
            }
            if (!isThumbnail) {
              imageURLs.push(imageURL);
            }
          });
          backendItem.imageURLs = imageURLs;
        }

        // Additional processing for Instagram posts
        if (
          !hasMessageField({
            apiTypeId,
            postType: updatedMediaItem.backend.postType,
            socialChannel: updatedMediaItem.frontend.socialChannel,
          })
        ) {
          delete backendItem.message;
        }

        // Return the finished item which can now be sent to the relevant webservice endpoint
        resolve(backendItem);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Methods for share time conversions
 */

function getTimeSensitivityTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (
    mediaItem.backend.timeSensitivityTypeId ===
      TIME_SENSITIVITY_TYPES.BREAKING ||
    mediaItem.backend.timeSensitivityTypeId === TIME_SENSITIVITY_TYPES.EVERGREEN
  ) {
    const now = getUnixTimestamp();
    const isABVariation = getABInfo({ mediaItem }).isABVariation;
    const minTime = isABVariation
      ? now + AB_TEST_MINIMUM_TIME_LENGTH_IN_SECS
      : null;
    if (
      mediaItem.backend.timeSensitivityTypeId ===
      TIME_SENSITIVITY_TYPES.BREAKING
    ) {
      const maxTime = isABVariation
        ? minTime + BREAKING_NEWS.INTERVAL_SECONDS
        : now + BREAKING_NEWS.INTERVAL_SECONDS;
      return [minTime, maxTime];
    }

    const maxTime = isABVariation
      ? minTime + EVERGREEN_NEWS.INTERVAL_SECONDS
      : now + EVERGREEN_NEWS.INTERVAL_SECONDS;
    return [minTime, maxTime];
  }

  return null;
}

function minMaxToShareTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isNull(mediaItem.backend.minShareTime)) {
    return {
      type: SHARE_TIME_TYPES.OPTIMAL,
    };
  }

  if (
    mediaItem.backend.minShareTime === 0 &&
    mediaItem.backend.maxShareTime === 0
  ) {
    return {
      type: SHARE_TIME_TYPES.NOW,
    };
  }

  if (mediaItem.backend.minShareTime !== mediaItem.backend.maxShareTime) {
    return {
      type: SHARE_TIME_TYPES.TIMESLOT,
      min: mediaItem.backend.minShareTime,
      max: mediaItem.backend.maxShareTime,
    };
  }

  return {
    type: SHARE_TIME_TYPES.TIME,
    time: mediaItem.backend.minShareTime,
  };
}

function shareTimeToMinMax({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  if (
    isNull(mediaItem.backend.minShareTime) &&
    isNull(mediaItem.backend.maxShareTime) &&
    mediaItem.frontend.shareTime.type === SHARE_TIME_TYPES.OPTIMAL
  ) {
    // Article has optimal share time - check for time sensitivity type
    const timeSensitivityTime = getTimeSensitivityTime({
      mediaItem,
      accountAPIId,
    });
    return isNull(timeSensitivityTime)
      ? [mediaItem.backend.minShareTime, mediaItem.backend.maxShareTime]
      : timeSensitivityTime;
  }

  if (mediaItem.frontend.shareTime.type === SHARE_TIME_TYPES.OPTIMAL) {
    let timeSensitivityTime = null;
    if (!isNull(mediaItem.backend.suggestionTypeId)) {
      timeSensitivityTime = getTimeSensitivityTime({ mediaItem, accountAPIId });
    }

    if (!isNull(timeSensitivityTime)) {
      return timeSensitivityTime;
    }

    const autoShareInterval = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL,
      propertyId: getPropertyIdForAccountAPIId({ accountAPIId }),
    });
    if (isNull(autoShareInterval) || !autoShareInterval.enabled) {
      return [null, getUnixTimestamp() + DEFAULT_AUTO_SHARE_INTERVAL * 3600];
    }

    return [
      null,
      getUnixTimestamp() +
        parseInt(autoShareInterval.dataJSON.intervalSecs, 10),
    ];
  }

  if (mediaItem.frontend.shareTime.type === SHARE_TIME_TYPES.NOW) {
    // AB tests need 30 minutes before they can be shared
    if (mediaItem.frontend.abInfo.isABVariation) {
      return [getUnixTimestamp() + 1800, getUnixTimestamp() + 1800];
    }

    return [0, 0];
  }

  if (mediaItem.frontend.shareTime.type === SHARE_TIME_TYPES.TIMESLOT) {
    return [mediaItem.frontend.shareTime.min, mediaItem.frontend.shareTime.max];
  }

  // this.frontend.shareTime.type === 'time'
  return [mediaItem.frontend.shareTime.time, mediaItem.frontend.shareTime.time];
}

/**
 * Method for removing link specific properties from a media item
 */
function removeMediaItemLinkProperties({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  const backend = { ...mediaItem.backend };
  BACKEND_LINK_POST_PROPERTIES.forEach((key) => {
    if (backend[key]) {
      delete backend[key];
    }
  });

  const frontend = { ...mediaItem.frontend };
  FRONTEND_LINK_POST_PROPERTIES.forEach((key) => {
    if (frontend[key]) {
      delete frontend[key];
    }
  });
  mediaItem = Immutable({ ...mediaItem, backend, frontend });
  return mediaItem;
}
