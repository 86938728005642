import * as accountAPIs from 'common/accountAPIs';

import { GlobalInfo, StatusScheduleTrigger } from 'types';

export { loadStatusScheduleTriggers, toJSONFromSchedulePeriods };

function loadStatusScheduleTriggers(
  triggers: StatusScheduleTrigger[],
  accountAPI: GlobalInfo.SocialAPI | null,
) {
  if (!accountAPI) return [];

  const apiOffset = accountAPI.utcOffsetSecs;
  const userOffset = new Date().getTimezoneOffset() * 60; // in seconds
  const statusScheduleTriggers = [];
  //
  if (triggers && triggers.length && triggers.length > 0) {
    triggers.forEach((entry) => {
      let dayStart = 1;
      let hourStart = 0;
      while (dayStart <= 7 && hourStart <= 23) {
        let secondsFromStartOfMonday =
          (dayStart - 1) * 86400 + hourStart * 3600 + apiOffset + userOffset;

        // make sure that seconds can't be greater than week and not below 0
        if (secondsFromStartOfMonday >= 604800) {
          secondsFromStartOfMonday -= 604800;
        }
        if (secondsFromStartOfMonday < 0) {
          secondsFromStartOfMonday += 604800;
        }
        // Check the status of the trigger, account for schedules made in timezones with
        // non-integer hour offsets by checking for triggers in the same hour
        if (
          secondsFromStartOfMonday >= entry.secondsFromStartOfMonday &&
          secondsFromStartOfMonday < entry.secondsFromStartOfMonday + 3600
        ) {
          // Move seconds from start of Monday to the new offset
          const time = (dayStart - 1) * 24 * 3600 + hourStart * 3600;
          statusScheduleTriggers.push({
            secondsFromStartOfMonday: time,
            statusAfterTrigger: entry.statusAfterTrigger,
          });
        }
        hourStart += 1;
        if (hourStart > 23) {
          dayStart += 1;
          hourStart = 0;
        }
      }
    });
  } else {
    // Blank all entries
    let dayStart = 1;
    let hourStart = 0;
    while (dayStart <= 7 && hourStart <= 24) {
      const secs = (dayStart - 1) * 86400 + hourStart * 3600;
      statusScheduleTriggers.push({
        secondsFromStartOfMonday: secs,
        statusAfterTrigger: false,
      });
      hourStart += 1;
      if (hourStart > 23) {
        dayStart += 1;
        hourStart = 0;
      }
    }
  }
  statusScheduleTriggers.sort(
    (a, b) => a.secondsFromStartOfMonday - b.secondsFromStartOfMonday,
  );
  return statusScheduleTriggers;
}

function toJSONFromSchedulePeriods(
  schedulePeriods: StatusScheduleTrigger[],
  globalInfo: GlobalInfo.GlobalInfo,
) {
  const triggers = [...schedulePeriods];
  let json: StatusScheduleTrigger[] = [];
  const userOffset = new Date().getTimezoneOffset() * 60; // in seconds
  const apiOffset = accountAPIs.getCurrentAccountAPI({
    globalInfo,
  }).utcOffsetSecs;
  let activeTriggers = 0;
  triggers.forEach((value) => {
    const secondsFromStartOfMonday = value.secondsFromStartOfMonday;
    let secs = secondsFromStartOfMonday + userOffset + apiOffset;
    if (secs < 0) {
      secs += 604800;
    }
    if (secs >= 604800) {
      secs -= 604800;
    }
    json.push({
      secondsFromStartOfMonday: secs,
      statusAfterTrigger: value.statusAfterTrigger,
    });
    if (value.statusAfterTrigger) {
      activeTriggers += 1;
    }
  });
  if (activeTriggers === 0) {
    json = [];
  }
  return json;
}
