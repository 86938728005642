import { FeatureFlag } from 'types';

/**
 * A registry of available feature flags across the application.
 *
 * Note: When adding a code, please add a corresponding test in `__tests__/unit/common/featureFlags.ts`
 */
export const FEATURE_FLAGS = {
  TEAM_SETTINGS: 'TEAM_SETTINGS',
  FAILED_SHARES: 'FAILED_SHARES',
  ADD_USER_MODAL: 'ADD_USER_MODAL',
  COMPETITOR_LEADERBOARD: 'COMPETITOR_LEADERBOARD',
  POST_TYPE_SWITCHER: 'POST_TYPE_SWITCHER',
  COMPETITOR_POST_ANALYTICS: 'COMPETITOR_POST_ANALYTICS',
} as const;

/**
 * A record used to configure which feature flags to enable/disable application-wide.
 */
export const FEATURE_FLAG_CONFIG: Record<
  FeatureFlag,
  { defaultValue: boolean; name: string }
> = {
  [FEATURE_FLAGS.TEAM_SETTINGS]: { defaultValue: false, name: 'Team Settings' },
  [FEATURE_FLAGS.FAILED_SHARES]: {
    defaultValue: false,
    name: 'Move failed shares to health menu',
  },
  [FEATURE_FLAGS.ADD_USER_MODAL]: {
    defaultValue: false,
    name: 'Add Users to multiple Properties',
  },
  [FEATURE_FLAGS.COMPETITOR_LEADERBOARD]: {
    defaultValue: true,
    name: 'Competitor Leaderboard',
  },
  [FEATURE_FLAGS.POST_TYPE_SWITCHER]: {
    defaultValue: true,
    name: 'Post Type Switcher',
  },
  [FEATURE_FLAGS.COMPETITOR_POST_ANALYTICS]: {
    defaultValue: true,
    name: 'Competitor Post Analytics',
  },
};

const FEATURE_FLAG_OVERRIDE_KEY = 'FLAG_OVERRIDES';

/**
 * Checks whether a provided feature flag is enabled.
 */
export function isFeatureFlagEnabled({ flag }: { flag: FeatureFlag }) {
  // Merge the hardcoded feature flag configuration with any user-defined overrides
  const flags = {
    ...getFeatureFlagDefaultValues(),
    ...getFeatureFlagOverrides(),
  };

  return flags[flag] ?? false;
}

/**
 * Gets all the default values from the hard-coded feature flags configuration.
 */
export function getFeatureFlagDefaultValues(): Record<FeatureFlag, boolean> {
  const defaultValues = Object.entries(FEATURE_FLAG_CONFIG).reduce<
    Partial<Record<FeatureFlag, boolean>>
  >(
    (acc, [flagName, { defaultValue }]) => ({
      ...acc,
      [flagName]: defaultValue,
    }),
    {},
  );

  // Cast it, as we know that all the feature flags will be included.
  return defaultValues as Record<FeatureFlag, boolean>;
}

/**
 * Gets any feature flag overrides from session storage. If undefined, returns an empty object.
 */
export function getFeatureFlagOverrides(): Partial<
  Record<FeatureFlag, boolean>
> {
  try {
    const overrides = sessionStorage.getItem(FEATURE_FLAG_OVERRIDE_KEY);
    if (overrides) {
      return JSON.parse(overrides);
    }
  } catch (ex) {
    console.error('Failed to retrieve feature flag overrides', ex);
  }
  return {};
}

/**
 * Overrides a feature flag value within session storage.
 *
 * @param flag The feature flag to update
 * @param value The boolean value of the feature flag
 */
export function setFeatureFlagOverrides(flag: FeatureFlag, value: boolean) {
  try {
    const overridesValue =
      sessionStorage.getItem(FEATURE_FLAG_OVERRIDE_KEY) ?? '{}';
    const overrides = JSON.parse(overridesValue);
    overrides[flag] = value;
    sessionStorage.setItem(
      FEATURE_FLAG_OVERRIDE_KEY,
      JSON.stringify(overrides),
    );
  } catch (ex) {
    console.error('Failed to update feature flag overrides', ex);
  }
}

/**
 * Clears any feature flag overrides from session storage.
 */
export function clearFeatureFlagOverrides() {
  try {
    sessionStorage.removeItem(FEATURE_FLAG_OVERRIDE_KEY);
  } catch (ex) {
    console.error('Failed to clear feature flag overrides', ex);
  }
}
