import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { getCurrentAccountAPIId } from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  API_TYPE_IDS,
  FILE_TYPE_ACCEPTS,
  POST_TYPES,
  SOCIAL_CHANNELS,
} from 'common/constants';
import { getPlaceholderText } from 'common/socialV2';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';

const TIKTOK_UPLOAD_RULES = getPlaceholderText({
  apiTypeId: API_TYPE_IDS.TIKTOK,
  postType: POST_TYPES.VIDEO,
  socialChannel: SOCIAL_CHANNELS.REEL,
});

const TikTokVideoUpload = () => {
  const setPreloadedFiles = useComposeBoxOpenStore(
    (state) => state.setPreloadedFiles,
  );
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      await Compose.addNonLinkPost({
        accountAPIId: getCurrentAccountAPIId(),
        postType: POST_TYPES.VIDEO,
      });
      setPreloadedFiles(acceptedFiles);
    },
    [setPreloadedFiles],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept: FILE_TYPE_ACCEPTS.VIDEO,
  });
  return (
    <Box
      {...getRootProps()}
      border="1px"
      borderRadius="3px"
      borderStyle="dashed"
      borderColor="#D7DEE4"
      padding="10"
      {...(isDragActive && { bg: 'base' })}
    >
      <input {...getInputProps()} />
      <Heading variant="h5" fontWeight={700} color="#292D46" mb="4">
        Drag and drop a video to share
      </Heading>
      <Box mb="4">
        <button
          type="button"
          className="btn btn-light btn-video"
          onClick={open}
        >
          Select video
        </button>
      </Box>
      <UnorderedList ml="5" mb="0">
        {TIKTOK_UPLOAD_RULES.map((rule) => (
          <ListItem key={rule} lineHeight="5" color="#56667F">
            {rule}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default TikTokVideoUpload;
