import { Image } from '@ebx-ui/ebx-ui-component-library-sdk';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import {
  getAPITypeId,
  getCurrentAccountAPI,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { API_TYPE_IDS, POST_TYPES, SOCIAL_CHANNELS } from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as MediaItem from 'common/mediaItem';
import { getFeatureToggle, isIGCabinetEnabled } from 'common/settings';
import { useImageContext } from 'context/ComposeBoxImageContext';
import { FixTypeLater } from 'types';

import { IMAGE_FILE_TYPES } from './constants';
import Placeholder from './Placeholder';

interface ImagePreviewProps {
  mediaItem: FixTypeLater;
}

/**
 * Correctly displays image/video
 * Shows play image overlay if showPlayButton is true
 * Shows error message if it exists
 */
function ImagePreview({ mediaItem }: ImagePreviewProps) {
  const {
    src,
    outerWrapperClass,
    innerWrapperClass,
    fileType,
    showPlayButton,
    errorMessage,
    imageUneditableMessage,
    previewRef,
    imageProps,
    isDragActive,
    hasBeenModified,
    children,
  } = useImageContext();

  const [imageUrl, setImageUrl] = useState(src);

  const isLinkinbioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  const accountAPIId = mediaItem
    ? MediaItem.getAccountAPIId({ mediaItem })
    : getCurrentAccountAPI();
  const apiTypeId = getAPITypeId({ accountAPIId });

  const isInstagramLinkInBio =
    apiTypeId === API_TYPE_IDS.INSTAGRAM && isLinkinbioEnabled;

  const postType = MediaItem.getPostType({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const imageURLs = MediaItem.getImageURLs({ mediaItem });

  /**
   * useEffect hook to update imageUrl whenever src prop changes
   */
  useEffect(() => {
    setImageUrl(src);
  }, [src]);

  const getHeight = () => {
    if (!isIGCabinetEnabled() && apiTypeId === API_TYPE_IDS.INSTAGRAM) {
      if (
        isInstagramLinkInBio &&
        socialChannel === SOCIAL_CHANNELS.FEED &&
        MediaItem.getPostType({ mediaItem }) === POST_TYPES.LINK &&
        imageURLs.length > 0 &&
        !hasBeenModified
      ) {
        return '545px !important';
      }
      if (
        socialChannel === SOCIAL_CHANNELS.REEL ||
        socialChannel === SOCIAL_CHANNELS.STORY
      ) {
        return '442px';
      }
      return '370px';
    }
    return isInstagramLinkInBio && !hasBeenModified
      ? '545px !important'
      : 'full';
  };

  const getWidth = () => {
    if (
      (apiTypeId === API_TYPE_IDS.INSTAGRAM && !isIGCabinetEnabled()) ||
      (isInstagramLinkInBio && !hasBeenModified)
    ) {
      return '545px';
    }
    return 'full';
  };

  return (
    <div
      className={clsx(
        'share_imagery',
        isDragActive && 'dropzone_active',
        outerWrapperClass,
      )}
    >
      {fileType === IMAGE_FILE_TYPES.IMAGE && (
        <>
          {imageUrl ? (
            <Image
              h={getHeight()}
              w={getWidth()}
              className="share_image"
              data-cy-id="shareImage"
              display="block"
              objectFit="cover"
              bgPosition="50% 50%"
              bgSize="cover"
              overflow="hidden"
              boxSizing="border-box"
              m="auto"
              src={imageUrl}
              onError={() => {
                setImageUrl(undefined);
              }}
              ref={previewRef}
              {...imageProps}
            />
          ) : (
            <Placeholder
              apiTypeId={apiTypeId}
              postType={postType}
              socialChannel={socialChannel}
            />
          )}
          {innerWrapperClass && <div className={innerWrapperClass} />}
        </>
      )}
      {fileType === IMAGE_FILE_TYPES.VIDEO && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          width="100%"
          controls
          controlsList="noplaybackrate"
          preload="metadata"
          id="video"
          key={src}
          ref={previewRef}
          {...imageProps}
        >
          <source src={src} type="video/mp4" />
        </video>
      )}
      {showPlayButton && (
        <img
          src="/img/icons/ic-play-w.svg"
          alt=""
          className="compose-box-video"
        />
      )}
      {children}
      {imageUneditableMessage && (
        <div className="hover_container">
          <div className="photo_disabled_container_explainer">
            <div className="photo_disabled_explainer p-2 lh-16">
              <div className="text-left">
                <img
                  src="/img/icons/ic-information.svg"
                  alt=""
                  className="mr-1 icon-info"
                />
                {imageUneditableMessage}
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMessage && (
        <div className="photo_disabled_container_explainer">
          <div className="invalid_aspect_ratio p-2 lh-16">
            <div className="d-flex">
              <img
                src="/img/icons/ic-error-dark.svg"
                alt=""
                className="mr-2 icon-info"
              />
              {errorMessage}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ImagePreview;
