import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import type { FixTypeLater } from 'types';

interface RemovePageModalProps {
  accountAPI: FixTypeLater;
  onClose: () => void;
  onRemove: () => void;
}
const RemovePageModal = ({
  accountAPI,
  onClose,
  onRemove,
}: RemovePageModalProps) => {
  const { apiPostName } = accountAPI;

  const [removing, setRemoving] = useState(false);

  const handleRemove = () => {
    setRemoving(true);
    onRemove();
  };

  return (
    <Modal isOpen onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Remove {apiPostName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm">
          You’re about to disconnect{' '}
          <Text as="span" size="sm" fontWeight="medium">
            {apiPostName}
          </Text>{' '}
          from your Echobox. You will be able to re-add this page later.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button isDisabled={removing} variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button isDisabled={removing} variant="danger" onClick={handleRemove}>
          Remove {apiPostName}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemovePageModal;
