import { Flex, Heading, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';

import { getCurrentProperty, getCurrentPropertyId } from 'common/accountAPIs';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getFeatureToggle } from 'common/settings';
import ReviewContent from 'components/setup/ReviewContent';
import ReviewSocialPages from 'components/setup/ReviewSocialPages';
import ReviewTeam from 'components/setup/ReviewTeam';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { useOnboardingSetupContext } from 'context/OnboardingContext';

import ReviewWebsiteTag from './ReviewWebsiteTag';

const ReviewPropertySetup = () => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const { accountAPIs, accountFeeds } = getCurrentProperty({
    globalInfo,
  });

  const isTrackerEnabledForCurrentProperty =
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.REQUIRES_EBX_TRACKER,
      propertyId: getCurrentPropertyId(),
    }) ?? true;

  const { onStepChange } = useOnboardingSetupContext();

  const handleEdit = (stepNumber: number) => () => {
    onStepChange(stepNumber);
  };

  return (
    <Flex flexDirection="column" gap={9} maxWidth="776px">
      <Flex flexDirection="column" gap={2}>
        <Heading>Review your set up</Heading>
        <Text color="gray.600" size="sm">
          You’re almost ready to get started with Echobox. Please double-check
          that this information is correct.
        </Text>
      </Flex>
      {isTrackerEnabledForCurrentProperty && <ReviewWebsiteTag />}
      <ReviewTeam onEdit={handleEdit(1)} />
      <ReviewSocialPages accountAPIs={accountAPIs} onEdit={handleEdit(2)} />
      <ReviewContent accountFeeds={accountFeeds} onEdit={handleEdit(3)} />
    </Flex>
  );
};

export default ReviewPropertySetup;
