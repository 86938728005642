/**
 * A hook to trigger a toast when there are multiple health issues.
 */
export function useMultipleErrorToastProps({
  noOfErrors,
}: {
  /**
   * The number of health issues
   */
  noOfErrors: number;
}) {
  return {
    title: `You have ${noOfErrors} health issues`,
    description: 'Click to review',
  };
}
