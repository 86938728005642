import type { ChangeEvent } from 'react';
import { memo, useState } from 'react';

import { arePropsEqual } from 'common/utility';
import { SocialAPIWithStates } from 'types';

interface SocialPagesItemProps {
  accountAPI: SocialAPIWithStates;
  onSocialPagesChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SocialPagesItem = ({
  accountAPI,
  onSocialPagesChange,
}: SocialPagesItemProps) => {
  const [isDisabled, setDisabled] = useState(false);

  const classExtra = isDisabled ? 'saving_locked' : '';

  const handleSocialPagesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDisabled(true);
    onSocialPagesChange(event);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  return (
    <div
      className="d-flex align-items-center"
      data-id="socialPage"
      data-cy-id={accountAPI.accountAPIId}
    >
      <input
        type="checkbox"
        className={`form-check-input ${classExtra}`}
        id={`shareto_${accountAPI.accountAPIId}`}
        data-cy-action="toggleSocialPage"
        checked={accountAPI.isChecked}
        disabled={isDisabled}
        onChange={handleSocialPagesChange}
      />
      <label
        className={`form-check-label ft-13 w-100 d-flex align-items-center dropdown-item ${classExtra}`}
        htmlFor={`shareto_${accountAPI.accountAPIId}`}
        data-cy-id={accountAPI.accountAPIId}
      >
        <div className="position-relative mr-2">
          <img
            className=""
            src={accountAPI.apiNetworkIcon}
            alt={accountAPI.apiNetworkName}
            width="13px;"
            style={{
              position: 'absolute',
              bottom: '-4px',
              right: '-4px',
            }}
          />
          <img
            className="rounded-circle"
            width="18px;"
            src={accountAPI.apiPostImage}
            alt=""
          />
        </div>
        <span className={`text-truncate w-70 ${classExtra}`}>
          {accountAPI.apiPostName}
        </span>
        {accountAPI.isQueued && (
          <div className="ml-auto badge badge-info mb-0">Scheduled</div>
        )}
        {accountAPI.isShared && (
          <div className="ml-auto badge badge-info mb-0">Shared</div>
        )}
      </label>
    </div>
  );
};

export default memo(SocialPagesItem, arePropsEqual);
