import { useState } from 'react';

import {
  Flex,
  FormControl,
  Input,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Bold from 'components/misc/Bold';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';

import isEqual from 'fast-deep-equal';

import * as accountAPIs from 'common/accountAPIs';
import * as settings from 'common/settings';
import * as social from 'common/social';
import * as tracker from 'common/tracker';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';

import { AutofeedSettings, FixTypeLater } from 'types';

interface ResharePrefixSuffixSettings {
  reshareMessagePrefix: string;
  reshareMessageSuffix: string;
}

function PrefixSuffix() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();
  const { global } = useGlobalInfo();
  const { accountAPIId } = useAutomationSettingsContext();

  const getResharePrefixSuffixSettings = () => {
    const defaults = social.getAutofeedDefaults({
      apiTypeId: accountAPIs.getAPITypeId({
        accountAPIId,
      }),
    });

    const reshareMessagePrefix =
      settings.getAutofeedSetting({
        key: 'reshareMessagePrefix',
      }) ??
      defaults.newshareMessagePrefix ??
      '';

    const reshareMessageSuffix =
      settings.getAutofeedSetting({
        key: 'reshareMessageSuffix',
      }) ??
      defaults.newshareMessageSuffix ??
      '';

    return {
      reshareMessagePrefix,
      reshareMessageSuffix,
    };
  };

  const handleSaveResharePrefixSuffix = (
    newSettings: ResharePrefixSuffixSettings,
  ) => {
    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    updatedSettings.reshareMessagePrefix = newSettings.reshareMessagePrefix;
    updatedSettings.reshareMessageSuffix = newSettings.reshareMessageSuffix;

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
      prefixSuffixMixpanel,
    );
  };

  const prefixSuffixMixpanel = (
    updatedTracking: FixTypeLater,
    previousTracking: FixTypeLater,
  ) => {
    tracker.track({
      eventName: 'Update Autofeed Reshare Settings',
      trackingParams: {
        ...updatedTracking,
        'Social Page': accountAPIs.getAPIPostName({ accountAPIId }),
        'Previous Reshare Prefix': previousTracking['Reshares Prefix'],
        'Previous Reshare Suffix': previousTracking['Reshares Suffix'],
      },
    });
  };

  const resharePrefixSuffixSettings = getResharePrefixSuffixSettings();
  const [
    currentResharePrefixSuffixSettings,
    setCurrentResharePrefixSuffixSettings,
  ] = useState(resharePrefixSuffixSettings);

  const handleResharePrefixSuffixSettingsChange = (
    newSettings: ResharePrefixSuffixSettings,
  ) => {
    setCurrentResharePrefixSuffixSettings(newSettings);

    if (isEqual(newSettings, resharePrefixSuffixSettings)) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveResharePrefixSuffix(newSettings));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const name = event.target.name;

    const newSettings = {
      ...currentResharePrefixSuffixSettings,
      [name]: value,
    };
    handleResharePrefixSuffixSettingsChange(newSettings);
  };

  return (
    <>
      <AutomationSettingsHeader
        title="Prefix & Suffix"
        description="Add a word or phrase to each automated reshare"
      />
      <Flex flexDir="column" gap={6}>
        <FormControl>
          <FormControl.FormLabel fontWeight="normal">
            Reshares will start with the following <Bold>prefix</Bold> (max. 20
            characters)
          </FormControl.FormLabel>
          <Flex gap={2}>
            <Input
              name="reshareMessagePrefix"
              data-cy-input="resharePrefix"
              w="420px"
              maxLength={20}
              value={currentResharePrefixSuffixSettings.reshareMessagePrefix}
              onChange={handleInputChange}
            />
          </Flex>
        </FormControl>
        <FormControl>
          <FormControl.FormLabel fontWeight="normal">
            Reshares will end with the following <Bold>suffix</Bold> (max. 20
            characters)
          </FormControl.FormLabel>
          <Flex gap={2}>
            <Input
              name="reshareMessageSuffix"
              data-cy-input="reshareSuffix"
              w="420px"
              maxLength={20}
              value={currentResharePrefixSuffixSettings.reshareMessageSuffix}
              onChange={handleInputChange}
            />
          </Flex>
        </FormControl>
      </Flex>
    </>
  );
}

export default PrefixSuffix;
