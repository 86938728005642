import {
  Button,
  Flex,
  NormalIcon,
  SimpleGrid,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { getCurrentAccountAPIId } from 'common/accountAPIs';
import { POST_TYPES } from 'common/constants/socialListening';
import * as tracker from 'common/tracker';
import PageImage from 'components/competitorleaderboard/PageImage';
import { getNetworkAndPageName } from 'helpers/tracking';
import { useState } from 'react';
import Filter from './Filter';
import ShareCard from './ShareCard';

const ALL_PAGES = {
  value: 'all',
  label: 'All pages',
  icon: <NormalIcon size={5} />,
};

const SORTING_OPTIONS = [
  {
    value: 'normalisedEngagement',
    label: 'Overperformance',
  },
  {
    value: 'engagements',
    label: 'Engagements',
  },
  {
    value: 'creationTime',
    label: 'Time',
  },
];

const getPostData = (data: any) => {
  const posts: any[] = [];
  if (!data) return posts;
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].posts.length; j++) {
      const post = data[i].posts[j];
      post.pageName = data[i].name;
      post.pageImage = data[i].picture;
      post.pageId = data[i].id;
      post.engagements = post.shares + post.comments + post.reactions;
      posts.push(data[i].posts[j]);
    }
  }

  return posts;
};

const getPages = (data: any) => {
  if (!data) return [];
  const pages = data.map((page: any) => ({
    value: page.id,
    label: page.name,
    icon: <PageImage src={page.picture} />,
  }));
  return [ALL_PAGES, ...pages];
};

const Shares = ({ data }: { data: any }) => {
  const posts = getPostData(data);
  const pages = getPages(data);
  const [selectedPage, setSelectedPage] = useState(
    pages?.[0]?.value ?? ALL_PAGES.value,
  );
  const [selectedPostType, setSelectedPostType] = useState(POST_TYPES[0].value);
  const [selectedSorting, setSelectedSorting] = useState(
    SORTING_OPTIONS[0].value,
  );
  const [itemLimit, setItemLimit] = useState(18);

  let filteredPosts =
    selectedPage === ALL_PAGES.value
      ? posts
      : posts.filter((post) => post.pageId === selectedPage);

  filteredPosts =
    selectedPostType === POST_TYPES[0].value
      ? filteredPosts
      : filteredPosts.filter((post) => post.postType === selectedPostType);

  filteredPosts.sort((a, b) => {
    return b[selectedSorting] - a[selectedSorting];
  });

  const limitedPosts = filteredPosts.slice(0, itemLimit);

  const accountAPIId = getCurrentAccountAPIId();

  const handlePageChange = (value: string) => {
    setSelectedPage(value);
  };

  const handlePostTypeChange = (value: string) => {
    const oldPostType = POST_TYPES.find(
      (option) => option.value === selectedPostType,
    )?.label;
    const newPostType = POST_TYPES.find(
      (option) => option.value === value,
    )?.label;
    tracker.track({
      eventName: 'Filter Competitors Shares',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API ID': accountAPIId,
        'Share Type Filter (before)': oldPostType,
        'Share Type Filter (after)': newPostType,
      },
    });
    setSelectedPostType(value);
  };

  const handleSortingChange = (value: string) => {
    const oldSorting = SORTING_OPTIONS.find(
      (option) => option.value === selectedSorting,
    )?.label;
    const newSorting = SORTING_OPTIONS.find(
      (option) => option.value === value,
    )?.label;
    tracker.track({
      eventName: 'Sort Competitors Shares',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API ID': accountAPIId,
        'Sort Method (before)': oldSorting,
        'Sort Method (after)': newSorting,
      },
    });
    setSelectedSorting(value);
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex gap="3">
          <Filter
            title="Page"
            data={pages}
            value={selectedPage}
            onChange={handlePageChange}
            isDisabled={!pages || pages.length === 1}
          />
          <Filter
            title="Type"
            data={POST_TYPES}
            value={selectedPostType}
            onChange={handlePostTypeChange}
            size="sm"
          />
        </Flex>
        <Filter
          title="Sort by"
          data={SORTING_OPTIONS}
          value={selectedSorting}
          prefix="Sort by"
          onChange={handleSortingChange}
          size="md"
        />
      </Flex>
      <SimpleGrid columns={3} spacing="4">
        {limitedPosts.map((post: any) => (
          <ShareCard key={post.id} {...post} />
        ))}
      </SimpleGrid>
      {itemLimit <= filteredPosts.length && (
        <Flex justifyContent="center">
          <Button
            variant="secondary"
            onClick={() => setItemLimit((limit) => limit + 18)}
          >
            Load more
          </Button>
        </Flex>
      )}
    </>
  );
};

const SharesWrapper = ({
  isLoading,
  data,
}: {
  isLoading: boolean;
  data: any;
}) => {
  return isLoading ? <Spinner color="primary" /> : <Shares data={data} />;
};

export default SharesWrapper;
