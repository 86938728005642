import PropTypes from 'prop-types';
import { memo } from 'react';

import { arePropsEqual, isNull } from 'common/utility';
import TimeframeOptions from 'components/home/TimeframeOptions';
import Tooltip from 'components/misc/Tooltip';

/**
 * Article feed filters
 */

const Filters = (props) => {
  const displayOptions = props.displayOptions;
  const lastSharedCount = props.lastSharedCount;
  const lastSharedDate = props.lastSharedDate;
  const isLoading = props.isLoading;
  const eventHandlers = props.eventHandlers;

  let lastSharedLabel = '';
  if (!isNull(lastSharedDate)) {
    if (lastSharedDate < 60 * 60) {
      lastSharedLabel = `${Math.round(lastSharedDate / 60)}min ago`;
    } else if (lastSharedDate < 24 * 60 * 60) {
      lastSharedLabel = `${Math.round(lastSharedDate / (60 * 60))}hr ago`;
    } else {
      lastSharedLabel = '24h+ ago';
    }
  }

  const guid = 'last-shared-datetime';

  return (
    <div className="queue_header d-flex align-items-center">
      <div className="heading text-700 mr-2">
        Last Shared
        {!isLoading && (
          <span className="lastSharedCount" data-cy-id="lastSharedCount">
            {' '}
            ({lastSharedCount})
          </span>
        )}
      </div>
      <Tooltip id={guid} label="When the last share was made">
        <div
          className="timer ml-2"
          data-tip
          data-for={guid}
          data-iscapture="true"
          data-cy-id="lastSharedLabel"
        >
          {lastSharedLabel}
        </div>
      </Tooltip>
      <select
        className="filter btn btn-light dropdown-md ml-auto"
        value={displayOptions.timeFrame}
        onChange={eventHandlers.handleTimeframeChange}
        data-cy-select="sharedTimeWindow"
      >
        <TimeframeOptions />
      </select>
    </div>
  );
};

Filters.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  lastSharedCount: PropTypes.number.isRequired,
  lastSharedDate: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
};

Filters.defaultProps = {
  lastSharedDate: null,
};

export default memo(Filters, arePropsEqual);
