import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { fabric } from 'fabric';
import { useCallback, useEffect } from 'react';

import {
  calculateStickerPosition,
  createSticker,
  STICKER_FONT_FAMILY,
  STICKER_MAX_HEIGHT_RATIO,
} from 'common/instagramSticker';
import * as MediaItem from 'common/mediaItem';
import { getRootDomain } from 'common/url';
import { isMatchMedia } from 'common/window';
import useFontLoader from 'hooks/useFontLoader';
import { FixTypeLater } from 'types';

const IMAGE_DIMENSIONS = {
  desktop: {
    width: 54,
    height: 96,
  },
  mobile: {
    width: 34,
    height: 60,
  },
} as const;

interface StickerPreviewProps {
  mediaItem: FixTypeLater;
}

const StickerPreview = ({ mediaItem }: StickerPreviewProps) => {
  const { fontLoaded } = useFontLoader({ fontFamily: STICKER_FONT_FAMILY });

  const mediaId = MediaItem.getMediaId({ mediaItem });
  const canvasId = `sticker-canvas-${mediaId}`;

  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const url = getRootDomain(articleURL);

  const config = MediaItem.getIGLinkStickerConfig({ mediaItem });

  const isMobile = isMatchMedia();
  const { width, height } = IMAGE_DIMENSIONS[isMobile ? 'mobile' : 'desktop'];

  const initSticker = useCallback(() => {
    const fabricCanvas = new fabric.Canvas(canvasId, {
      width,
      height,
      selection: false,
    });

    const { stickerText, stickerBackground } = createSticker(url);

    const stickerGroup = new fabric.Group([stickerBackground, stickerText]);

    stickerGroup.set({
      selectable: false,
      hoverCursor: 'default',
    });

    // Scale the sticker down if it's too wide to fit on the image
    if (stickerGroup.width && stickerGroup.width > width) {
      stickerGroup.scaleToWidth(width);
    }

    if (
      stickerGroup.height &&
      stickerGroup.height > height * STICKER_MAX_HEIGHT_RATIO
    ) {
      stickerGroup.scaleToHeight(height * STICKER_MAX_HEIGHT_RATIO);
    }

    // Add the link icon to the sticker
    fabric.loadSVGFromURL(
      '/img/icons/instagram-link-sticker.svg',
      (objects, options) => {
        const svgData = fabric.util.groupSVGElements(objects, options);
        svgData.top = (stickerBackground.top ?? 0) + 8;
        svgData.left = (stickerBackground.left ?? 0) + 10;
        svgData.selectable = false;
        stickerGroup.add(svgData);
        fabricCanvas.renderAll();
      },
    );

    if (config) {
      stickerGroup.set(
        calculateStickerPosition(config, fabricCanvas, stickerGroup),
      );
      fabricCanvas.add(stickerGroup);
    } else {
      fabricCanvas.add(stickerGroup);
      fabricCanvas.centerObject(stickerGroup);
    }
  }, [canvasId, width, height, url, config]);

  useEffect(() => {
    if (fontLoaded) {
      initSticker();
    }
  }, [initSticker, fontLoaded]);

  return (
    <Box position="relative" inset={0} width={width} marginX="auto">
      <canvas id={canvasId} />
    </Box>
  );
};

export default StickerPreview;
