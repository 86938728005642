import { MAX_MOBILE_WINDOW_WIDTH } from 'common/config';
import { isRunningTests } from 'common/utility';

function isMatchMedia() {
  return window.matchMedia(`(max-width: ${MAX_MOBILE_WINDOW_WIDTH}px)`).matches;
}

/**
 * @deprecated Use `window.location` directly. The test environment (vitest + happy-dom) already mocks the `window.location`
 */
const location = {
  // Properties
  source: !isRunningTests()
    ? window.location
    : {
        host: null,
        hostname: null,
        href: '',
        origin: null,
        pathname: null,
        port: null,
        protocol: null,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- require the unused parameters for type inference
        reload: (force: boolean) => {},
        search: null,
      },

  // host
  get host() {
    return this.source.host;
  },
  set host(value) {
    this.source.host = value;
  },

  // hostname
  get hostname() {
    return this.source.hostname;
  },
  set hostname(value) {
    this.source.hostname = value;
  },

  // href
  get href() {
    return this.source.href;
  },
  set href(value) {
    this.source.href = value;
  },

  // origin
  get origin() {
    return this.source.origin;
  },

  // pathname
  get pathname() {
    return this.source.pathname;
  },
  set pathname(value) {
    this.source.pathname = value;
  },

  // port
  get port() {
    return this.source.port;
  },
  set port(value) {
    this.source.port = value;
  },

  // protocol
  get protocol() {
    return this.source.protocol;
  },
  set protocol(value) {
    this.source.protocol = value;
  },

  // search
  get search() {
    return this.source.search;
  },
  set search(value) {
    this.source.search = value;
  },

  // Methods

  // reload
  reload(force: boolean = false) {
    this.source.reload(force);
  },
};

export { isMatchMedia, location };
