import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import * as logger from 'common/logger';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { getSetting, savePropertiesSettings } from 'common/settings';
import { isNull } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import SaveChangesButtons from 'components/settings/SaveChangesButtons';
import withGlobalInfo from 'context/withGlobalInfo';
import { setPanelButtonText } from 'helpers/settingsPage';

class TimeFormat extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const timeSetting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
      propertyId: getCurrentPropertyId(),
    });
    this.state = {
      data: Immutable({
        timeFormat: TimeFormat.getTimeFormat(timeSetting),
        errorMessage: null,
        isChanged: false,
        isSaving: false,
      }),
    };
    this._bind('handleCancel', 'handleChange', 'handleSave');
    this.originalSettings = timeSetting;
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    setPanelButtonText('collapseTime');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('TimeFormat', { nextProps, nextState });
  }

  /**
   * Event handlers
   */

  handleCancel() {
    logger.info('TimeFormat:handleCancel');
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set('timeFormat', TimeFormat.getTimeFormat(this.originalSettings))
          .set('isChanged', false)
          .set('isSaving', false)
          .set('errorMessage', ''),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'timeFormat',
          hasSettingChanged: false,
        }),
    );
  }

  handleChange(event) {
    const timeFormat = event.target.value;
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set('timeFormat', timeFormat)
          .set('isChanged', true),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'timeFormat',
          hasSettingChanged: true,
        }),
    );
  }

  handleSave() {
    logger.info('SettingsTimeFormat:saveChanges');
    this.setState(
      (prevState) => ({
        data: prevState.data.set('isSaving', true).set('isChanged', false),
      }),
      () => {
        const propertyId = getCurrentPropertyId();
        const settingConfig = {
          propertyId,
          settingTypeId: 2,
          enabled: this.state.data.timeFormat === '12',
        };
        savePropertiesSettings.call(this, {
          propertyId,
          settingConfig,
          callback: () => {
            this.setState((prevState) => ({
              data: prevState.data.set('isSaving', false),
            }));
            this.originalSettings = getSetting({
              settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
              propertyId,
            });
            this.props.eventHandlers.handleHasSettingChanged({
              key: 'timeFormat',
              hasSettingChanged: false,
            });
            addSuccessNotification('Setting saved');
          },
          errorHandler: () => {
            this.setState((prevState) => ({
              data: prevState.data
                .set('isChanged', true)
                .set('isSaving', false),
            }));
            addErrorNotification('Error saving setting');
          },
        });
      },
    );
  }

  /**
   * Helper methods
   */

  static getTimeFormat(timeSetting) {
    return !isNull(timeSetting) && timeSetting.enabled ? '12' : '24';
  }

  /**
   * Render method
   */

  render() {
    const savingClass = this.state.data.isSaving ? 'saving_locked' : '';

    return (
      <>
        <div className="setting-part border-top d-flex flex-row">
          <div className=" mr-auto">
            <div className="ft-13 text-500">Time Format</div>
          </div>
          {this.state.data.isChanged && (
            <span className="align-self-center mr-2 op-60">
              Unsaved Changes
            </span>
          )}
          <div
            className="btn btn-light align-self-center"
            data-cy-action="editTimeFormat"
            data-toggle="collapse"
            data-target="#collapseTime"
            aria-expanded="true"
            aria-controls="collapseTime"
          >
            Edit
          </div>
        </div>
        <div
          id="collapseTime"
          className="settings-sub collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionPropertySettings"
        >
          <div className="py-4 border-top d-flex flex-column">
            <div className="mb-3">
              <span>Chose a time format</span>
              <div className="d-flex align-items-center mt-1">
                <select
                  className={`btn btn-light dropdown-md ${savingClass}`}
                  value={this.state.data.timeFormat}
                  onChange={this.handleChange}
                  data-cy-select="timeFormat"
                >
                  <option value="24">24-hour format (e.g. 15:30)</option>
                  <option value="12">12-hour format (e.g. 3.30pm)</option>
                </select>
              </div>
            </div>
            <SaveChangesButtons
              disabled={!this.state.data.isChanged || this.state.data.isSaving}
              eventHandlers={{
                handleCancel: this.handleCancel,
                handleSave: this.handleSave,
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

TimeFormat.propTypes = {
  eventHandlers: PropTypes.shape({
    handleHasSettingChanged: PropTypes.func,
  }),
};

TimeFormat.defaultProps = {
  eventHandlers: {
    handleHasSettingChanged: () => {},
  },
};

export default withGlobalInfo(TimeFormat);
