import PropTypes from 'prop-types';

/**
 * React component for rendering a panel for user details
 * Adds default styling
 * Accepts the following props (required):
 * 1) title - title of the panel
 * 2) children - can be a react component(s) or string. This is rendered below the title
 * All other props are passed to the parent div
 */
const Panel = ({ title, children, ...rest }) => {
  return (
    <div className="box mb-3" {...rest}>
      <div className="d-flex box-header">
        <h3 className="align-self-center">{title}</h3>
      </div>
      <div className="setting-part pb-3">{children}</div>
    </div>
  );
};

Panel.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Panel;
