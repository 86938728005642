/* eslint jsx-a11y/no-noninteractive-element-interactions: "off" */
/* global Beamer */

import {
  EditPen1Icon,
  Header,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import $ from 'jquery';
import { debounce } from 'throttle-debounce';

import {
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  API_TYPE_IDS,
  DEBOUNCE_INPUT_DELAY,
  FRONTEND_METRICS,
  GLOBAL_INFO_CHANGES,
  KEYCODES,
  POST_TYPES,
  SOCIAL_CHANNELS,
} from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import {
  getMaximumUrlByteLength,
  getMaximumUrlLength,
  getNewShareOptions,
  getPostTypeLabel,
  hasPostType,
} from 'common/social';
import { getLengthInBytes } from 'common/string';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isRunningTests,
} from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import withGlobalInfo from 'context/withGlobalInfo';
import Photo from 'svg/Photo';
import PhotoStory from 'svg/PhotoStory';
import Video from 'svg/Video';

class NewSharePanel extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      articleURL: '',
      errorMessage: '',
      isExpanded: false,
      isLoading: false,
    };
    this._bind(
      'handleAddPhotoPost',
      'handleAddPhotoStoryPost',
      'handleAddVideoPost',
      'handleKeyDown',
      'handleKeyUp',
      'handleMouseDown',
      'handleToggleVisibility',
      'handleURLChange',
    );
    this.processURLChangeDebounced = null;
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('mousedown', this.handleMouseDown);
    if (
      typeof $ !== 'undefined' &&
      !isRunningTests() &&
      isDefined($('#newShareButton').parent)
    ) {
      $('#newShareButton')
        .parent()
        .on('show.bs.dropdown', () => {
          window.setTimeout(() => {
            $('#articleURL').focus();
            if (typeof Beamer !== 'undefined') {
              Beamer.hide();
            }
          }, 0);
        });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('NewSharePanel', {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.ANY_STATE_CHANGE,
    });
  }

  componentDidUpdate() {
    if (typeof $ !== 'undefined' && !isRunningTests()) {
      if (
        !isNullOrUndefined($('#articleURL')) &&
        $('#articleURL').is(':visible')
      ) {
        $('#articleURL').focus();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('mousedown', this.handleMouseDown);
  }

  /**
   * Event handlers
   */

  handleAddPhotoPost() {
    const accountAPIId = getCurrentAccountAPIId();
    this.setState(
      {
        isExpanded: false,
      },
      () => {
        Compose.addNonLinkPost({
          accountAPIId,
          postType: POST_TYPES.STATUS,
        });
      },
    );
  }

  handleAddPhotoStoryPost() {
    const accountAPIId = getCurrentAccountAPIId();
    this.setState(
      {
        isExpanded: false,
      },
      () => {
        Compose.addNonLinkPost({
          accountAPIId,
          postType: POST_TYPES.PHOTO_STORY,
        });
      },
    );
  }

  handleAddVideoPost() {
    const accountAPIId = getCurrentAccountAPIId();
    this.setState(
      {
        isExpanded: false,
      },
      () => {
        Compose.addNonLinkPost({
          accountAPIId,
          postType: POST_TYPES.VIDEO,
        });
      },
    );
  }

  handleKeyDown(event) {
    if (event.keyCode === KEYCODES.ENTER) {
      event.preventDefault();
      this.processURLChange();
    }
  }

  handleKeyUp(event) {
    if (event.keyCode === KEYCODES.ESCAPE) {
      this.setState({
        articleURL: '',
        errorMessage: '',
        isExpanded: false,
        isLoading: false,
      });
    }
  }

  handleMouseDown(event) {
    if (typeof $ !== 'undefined') {
      const target = $(event.target);
      const isButtonClicked = target.closest('#newShareButton').length === 1;
      const isPanelClicked = target.closest('.dropdown-new-share').length === 1;
      if (!isButtonClicked && !isPanelClicked) {
        logger.info('NewSharePanel:handleMouseDown - resetting state');
        this.setState({
          articleURL: '',
          errorMessage: '',
          isExpanded: false,
          isLoading: false,
        });
      }
    }
  }

  handleToggleVisibility() {
    logger.info('NewSharePanel:toggleVisibility');

    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  }

  handleURLChange(event) {
    this.setState(
      {
        articleURL: event.target.value,
        errorMessage: '',
      },
      () => {
        if (isNull(this.processURLChangeDebounced)) {
          this.processURLChangeDebounced = debounce(
            DEBOUNCE_INPUT_DELAY,
            this.processURLChange,
          );
        }
        this.processURLChangeDebounced();
      },
    );
  }

  processURLChange() {
    logger.info('NewSharePanel:processURLChange');

    const apiTypeId = getCurrentAPITypeId();
    const maximumUrlLength = getMaximumUrlLength({ apiTypeId });
    const maximumUrlByteLength = getMaximumUrlByteLength({ apiTypeId });

    if (
      !isNull(maximumUrlLength) &&
      this.state.articleURL.length > maximumUrlLength
    ) {
      this.setState({
        errorMessage: 'This URL is more than 245 characters',
      });
      return;
    }

    if (
      !isNull(maximumUrlByteLength) &&
      getLengthInBytes(this.state.articleURL) > maximumUrlByteLength
    ) {
      this.setState({
        errorMessage: 'This URL is too long.',
      });
      return;
    }

    if (this.state.articleURL !== '' && !this.state.isLoading) {
      metrics.mark(FRONTEND_METRICS.OPEN_COMPOSE_BOX_NEW_SHARE);
      this.setState(
        {
          errorMessage: '',
          isLoading: true,
        },
        async () => {
          const accountAPIId = getCurrentAccountAPIId();
          try {
            await Compose.addLinkPost({
              accountAPIId,
              articleURL: this.state.articleURL,
            });
            this.setState({
              articleURL: '',
              errorMessage: '',
              isExpanded: false,
              isLoading: false,
            });
          } catch (error) {
            if (error instanceof Error || error instanceof ErrorEvent) {
              logger.error({
                event: 'Caught Error',
                properties: {
                  location: 'components/NewSharePanel',
                },
                error,
              });
            }
            this.setState({
              errorMessage: getErrorMessage(error) ?? 'An error occurred',
              isLoading: false,
            });
          }
        },
      );
    }
  }

  /**
   * Render method
   */

  render() {
    const { errorMessage, isExpanded, isLoading } = this.state;
    const errorClass = errorMessage !== '' ? 'input-with-error' : '';
    const apiTypeId = getCurrentAPITypeId();

    const newShareOptions = getNewShareOptions({ apiTypeId });
    const articleUrlIcon = newShareOptions?.articleUrlIcon ?? null;
    const hasArticleUrlIcon = !isNull(articleUrlIcon);
    const photoVideoTitle = newShareOptions?.photoVideoTitle ?? null;
    const hasPhotoVideoTitle = !isNull(photoVideoTitle);

    const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;

    logger.info(`NewSharePanel:render - isExpanded: ${isExpanded}`);

    return (
      <div className="dropdown">
        <Header.Button
          id="newShareButton"
          onClick={this.handleToggleVisibility}
          data-cy-action="newShare"
          aria-expanded="false"
          leftIcon={<EditPen1Icon />}
        >
          New share
        </Header.Button>
        {isExpanded && (
          <div className="dropdown-new-share dropdown-menu show dropdown-menu-right">
            {!isTikTok && (
              <div className="dropdown-header">
                {getPostTypeLabel({
                  apiTypeId,
                  postType: POST_TYPES.LINK,
                })}
              </div>
            )}
            {hasPostType({ apiTypeId, postType: POST_TYPES.LINK }) && (
              <>
                <form
                  className="dropdown-item py-2 d-flex align-items-center"
                  onKeyDown={this.handleKeyDown}
                >
                  <div
                    className="form-group mb-0"
                    style={{ display: '-webkit-inline-box' }}
                  >
                    <div className="d-flex align-items-center form-control-with-spinner">
                      {!isNull(articleUrlIcon) && (
                        <img
                          src={`img/icons/${articleUrlIcon}`}
                          className="position-absolute ml-2"
                          alt=""
                        />
                      )}
                      <input
                        id="articleURL"
                        data-cy-input="link"
                        type="url"
                        className={`article-url form-control input_medium ${errorClass}`}
                        style={hasArticleUrlIcon ? { paddingLeft: '27px' } : {}}
                        placeholder="Enter article URL..."
                        value={this.state.articleURL}
                        onChange={this.handleURLChange}
                        alt=""
                      />
                      {isLoading && (
                        <Spinner position="absolute" right="10px" top="7px" />
                      )}
                    </div>
                  </div>
                </form>
                {errorMessage !== '' && (
                  <p className="input-with-error">{errorMessage}</p>
                )}
              </>
            )}
            {hasPostType({ apiTypeId, postType: POST_TYPES.PHOTO_STORY }) && (
              <button
                className="dropdown-item py-2 d-flex align-items-center"
                data-cy-action="imageStatus"
                style={{ height: '38px' }}
                onClick={this.handleAddPhotoStoryPost}
                type="button"
              >
                <PhotoStory />
                {getPostTypeLabel({
                  apiTypeId,
                  postType: POST_TYPES.PHOTO_STORY,
                })}
              </button>
            )}

            {!isTikTok && <div className="dropdown-divider" />}
            {hasPhotoVideoTitle && (
              <div className="dropdown-header">{photoVideoTitle}</div>
            )}
            {hasPostType({ apiTypeId, postType: POST_TYPES.STATUS }) && (
              <button
                className="dropdown-item py-2 d-flex align-items-center"
                data-cy-action="imageStatus"
                style={{ height: '38px' }}
                onClick={this.handleAddPhotoPost}
                type="button"
              >
                <Photo />
                {getPostTypeLabel({
                  apiTypeId,
                  postType: POST_TYPES.STATUS,
                  socialChannel: SOCIAL_CHANNELS.FEED,
                })}
              </button>
            )}
            {hasPostType({ apiTypeId, postType: POST_TYPES.VIDEO }) && (
              <button
                className="dropdown-item py-2 d-flex align-items-center"
                data-cy-action="video"
                style={{ height: '38px' }}
                onClick={this.handleAddVideoPost}
                type="button"
              >
                <Video />
                {getPostTypeLabel({ apiTypeId, postType: POST_TYPES.VIDEO })}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withGlobalInfo(NewSharePanel);
