import {
  Dropdown,
  DropdownItemProps,
  Flex,
  OpenInNewIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface ExternalLinkMenuItemProps extends DropdownItemProps {
  href: string;
}

const ExternalLinkMenuItem = ({
  children,
  href,
  ...props
}: ExternalLinkMenuItemProps) => {
  return (
    <Dropdown.Item
      as="a"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      data-group
      {...props}
    >
      <Flex as="span" justifyContent="space-between" alignItems="center">
        {children}
        <OpenInNewIcon
          color="gray.500"
          opacity={0}
          transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
          _groupHover={{ opacity: 1 }}
          _groupFocusVisible={{ opacity: 1 }}
        />
      </Flex>
    </Dropdown.Item>
  );
};

export default ExternalLinkMenuItem;
