/**
 * POST /apis/connect/save - The final step in the API connect process.
 * @param {string[]} identifiers - property id to get synd feeds for
 * @return {object} - an object wrapping the new/updated account api id
 */

import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import { API_TIMEOUTS, constructBaseSocialAPIURL } from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function postAPIConnectSave({
  identifiers,
  requestToken,
}: {
  identifiers: string[];
  requestToken: string;
}) {
  const guid = metrics.start('postAPIConnectSave');
  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL('v3')}/social/api/connect/save`,
    method: 'POST',
    timeout: API_TIMEOUTS.XXL,
    headers: {
      'Content-Type': 'application/json',
      'X-EBX-ConnectState': requestToken,
    },
    data: JSON.stringify({
      identifiers,
    }),
  };

  try {
    const response = await axios(config);
    metrics.end('postAPIConnectSave', guid);
    logger.debug('postAPIConnectSave', { requestToken });
    return response.data;
  } catch (error) {
    metrics.fail('postAPIConnectSave', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postAPIConnectSave',
      args: {
        identifiers,
        requestToken,
      },
    });
    throw apiError;
  }
}
