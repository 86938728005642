const ArrowBack = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.333 3.333v7.447L4.08 7.527a.672.672 0 0 0-.947 0c-.26.26-.26.68 0 .94l4.394 4.393c.26.26.68.26.94 0l4.393-4.393a.664.664 0 1 0-.94-.94L8.667 10.78V3.333c0-.366-.3-.666-.667-.666-.367 0-.667.3-.667.666z"
      fill="#0E1E39"
    />
  </svg>
);

export default ArrowBack;
