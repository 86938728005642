import {
  Alert,
  Box,
  Divider,
  Flex,
  Heading,
  Link,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import {
  ACCOUNT_SETTING_TYPES,
  PERFORMANCE_GOAL,
  USER_TYPES,
} from 'common/constants';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import { getSetting, savePropertiesSettings } from 'common/settings';
import * as tracker from 'common/tracker';
import EchoboxEmailAlert from 'components/settings/property/EchoboxEmailAlert';
import { WebsiteTagRequiredAlert } from 'components/settings/property/WebsiteTagRequiredAlert';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  setSettingsSaving,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';
import { GlobalInfo } from 'types';

const PerformanceGoalScreen = () => {
  const { global } = useGlobalInfo();
  const globalInfo: GlobalInfo.GlobalInfo = global.getGlobalInfo();

  const settingTypeId = ACCOUNT_SETTING_TYPES.PERFORMANCE_GOAL;
  const propertyId = getCurrentPropertyId({ globalInfo });

  const settings = getSetting({ settingTypeId, propertyId });

  const [value, setValue] = useState<PERFORMANCE_GOAL>(
    settings?.dataJSON?.target ?? PERFORMANCE_GOAL.TRAFFIC,
  );
  const toast = useToast();

  const [isEmailAlertShown, setIsEmailAlertShown] = useState<boolean>(false);
  const { isTrackerInstalled, dispatch } = useSettingsContext();
  const cognitoGroups = authentication.getCognitoGroups();
  const isExistingNewslettersUser =
    cognitoGroups?.includes(USER_TYPES.NEWSLETTERS_USER) ||
    cognitoGroups?.includes(USER_TYPES.ECHOBOX_STAFF);

  const toastSuccessNotification = () => {
    toast({
      title: 'Setting updated',
      description: 'Changes can take up to 8 hours to take effect',
      variant: 'success',
    });
  };

  const handleSavePerformanceGoal = async (
    performanceGoal: PERFORMANCE_GOAL,
  ) => {
    try {
      setSettingsSaving(dispatch);
      await savePropertiesSettings({
        propertyId,
        settingConfig: {
          propertyId,
          settingTypeId: ACCOUNT_SETTING_TYPES.PERFORMANCE_GOAL,
          enabled: true,
          dataJSON: {
            target: performanceGoal,
          },
        },
        callback: () => {
          toastSuccessNotification();
          resetSettingsState(dispatch);
          const trackingParams = {
            'Previous Performance Goal': settings?.dataJSON?.target,
          };
          tracker.track({
            eventName: 'Update Property Performance Goal',
            trackingParams,
          });
        },
        errorHandler: () => {
          addErrorNotification('Error saving setting');
        },
        globalInfo: global,
      });
    } catch (error) {
      logger.error({
        event: 'Failed to save performance goal settings',
        error,
        properties: { performanceGoal },
      });
    }
  };

  const handleChange = (performanceGoal: PERFORMANCE_GOAL) => {
    if (
      performanceGoal === PERFORMANCE_GOAL.EMAIL_SUBSCRIPTIONS &&
      !isExistingNewslettersUser
    ) {
      setIsEmailAlertShown(true);
    } else {
      setIsEmailAlertShown(false);
    }

    setValue(performanceGoal);
    if (
      performanceGoal ===
      (settings?.dataJSON?.target ?? PERFORMANCE_GOAL.TRAFFIC)
    ) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
      updateOnSave(dispatch, () => handleSavePerformanceGoal(performanceGoal));
    }
  };

  useEffect(() => {
    tracker.track({
      eventName: 'Property Performance Goal Page',
    });
  }, []);

  if (isTrackerInstalled === null) {
    return (
      <Box textAlign="center">
        <Spinner size="md" />
      </Box>
    );
  }

  return (
    <Flex direction="column" gap={6}>
      <Box>
        <Heading pb={2}>Performance goal</Heading>
        <Text size="sm" color="gray.600">
          Echobox will identify the optimal content, times, volumes and post
          formats to maximize your selected goal
        </Text>
      </Box>
      {!isTrackerInstalled && <WebsiteTagRequiredAlert />}

      <RadioGroup
        as={Flex}
        direction="column"
        onChange={handleChange}
        value={isTrackerInstalled ? value : ''}
        isDisabled={!isTrackerInstalled}
        gap={5}
      >
        <Box>
          <Radio value={PERFORMANCE_GOAL.TRAFFIC} alignItems="flex-start">
            Traffic
            <Text size="sm" color="text.secondary" mt={1}>
              Maximize your pageviews from social media
            </Text>
          </Radio>
        </Box>
        <Box>
          <Radio value={PERFORMANCE_GOAL.ENGAGEMENT} alignItems="flex-start">
            Engagement
            <Text size="sm" color="text.secondary" mt={1}>
              Boost reactions, comments and reshares
            </Text>
            {value === PERFORMANCE_GOAL.ENGAGEMENT && (
              <Alert variant="info" marginTop={5} maxWidth="728px">
                <Alert.Description>
                  Echobox will automatically convert certain Facebook link posts
                  to image posts with links in first comments. Echobox will make
                  further adjustments to boost engagement.
                </Alert.Description>
              </Alert>
            )}
          </Radio>
        </Box>
        <Box>
          <Radio
            value={PERFORMANCE_GOAL.EMAIL_SUBSCRIPTIONS}
            alignItems="flex-start"
          >
            Email subscriptions
            <VStack alignItems="left" spacing="5">
              <Text size="sm" color="text.secondary" mt={1}>
                Increase subscriptions to your newsletters with{' '}
                <Link href="https://email.echobox.com/" isExternal>
                  Echobox Email
                </Link>
                . <Link href="mailto:support@echobox.com">Contact us</Link> for
                more information
              </Text>
              {isEmailAlertShown && <EchoboxEmailAlert />}
            </VStack>
          </Radio>
        </Box>
        <Box>
          <Radio
            value={PERFORMANCE_GOAL.PAID_SUBSCRIPTIONS}
            alignItems="flex-start"
          >
            Paid subscriptions
            <Text size="sm" color="text.secondary" mt={1}>
              Acquire more paid subscribers.{' '}
              <Link href="mailto:support@echobox.com">Contact us</Link> for more
              information
            </Text>
          </Radio>
        </Box>
      </RadioGroup>

      <Divider />
      <Box>
        <Heading variant="h6" color="gray.600" textTransform="uppercase">
          Learn
        </Heading>
        <VStack alignItems="left">
          <Link
            isExternal
            href="https://echobox.zendesk.com/hc/en-us/articles/10927304662300-First-Comment"
            my={2}
            w="fit-content"
          >
            Use first comments to increase engagement
          </Link>
        </VStack>
        <Link
          isExternal
          href="https://www.echobox.com/resources/press/new-research-reveals-publishers-missing-out-on-67-facebook-traffic-from-reshares/"
        >
          Use reshares to drive traffic
        </Link>
      </Box>
    </Flex>
  );
};

export default PerformanceGoalScreen;
