import { SOCIAL_CHANNELS } from 'common/constants';
import { getValidationMessages } from 'common/social';
import { formatFileSize } from 'common/string';
import { APITypeId } from 'types';

/**
 * Validates an image upload for an Instant video frame.
 */
export function validateImage(
  file: File | undefined,
  apiTypeId: APITypeId,
): string | null {
  if (file == null) {
    return 'The file type does not seem to be valid. Please check the file type.';
  }

  const max = 8 * 1024 * 1024; // 8MB
  if (file != null && max != null && max > 0 && file.size > max) {
    const tooLargeValidationMessage = getValidationMessages({
      apiTypeId,
      validationType: 'imageLargeFileSize',
      socialChannel: SOCIAL_CHANNELS.REEL,
    });
    if (tooLargeValidationMessage) {
      return tooLargeValidationMessage;
    }
    return `The selected file is too large unfortunately. Please limit the filesize to ${formatFileSize(
      max,
    )}.`;
  }

  // All tests passed
  return null;
}

export function validateVideo(file: File | undefined): string | null {
  if (file == null) {
    return 'The file type does not seem to be valid. Please check the file type.';
  }

  const max = 30 * 1024 * 1024; // 30MB
  if (file != null && max != null && max > 0 && file.size > max) {
    return `The selected file is too large unfortunately. Please limit the filesize to ${formatFileSize(
      max,
    )}.`;
  }

  // All tests passed
  return null;
}
