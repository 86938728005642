import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { PERMISSION_TYPES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import {
  convertToPropertyURN,
  extractAccountAPIId,
  extractPropertyId,
} from 'common/urn';
import { isDefined } from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * GET /properties/{propertyId}/users - retrieve account users information
 *
 * @param {{propertyIds: number[]}} propertyId - property id to get users for
 * @return {{ [propertyId: string]: object }} - account users information
 *
 * Example data:
 * [
 *   {
 *     "propertyId": 102,
 *     "userId": 419,
 *     "userStateId": 2,
 *     "name": "Bruce Wayne",
 *     "username": "batman@echobox.com",
 *     "email": "batman@echobox.com",
 *     "permissionTypesForAccount": [
 *       5
 *     ]
 *   }
 * ]
 */
export default async function getPropertiesUsers({
  propertyIds = mandatory('propertyIds'),
} = {}) {
  const guid = metrics.start('getPropertiesUsers');
  const requests = [];
  const requestMap = {};

  propertyIds.forEach((propertyId) => {
    const propertyURN = convertToPropertyURN(propertyId);
    const config = {
      url: `${constructBaseSocialAPIURL(
        'v3.2',
      )}/properties/${propertyURN}/users`,
      method: 'GET',
      timeout: API_TIMEOUTS.S,
      headers: getClientServiceRequestHeaders(),
    };
    requests.push(axios(config));
    requestMap[propertyId] = requests.length - 1;
  });

  try {
    const responses = await Promise.all(requests);
    const response = {};
    propertyIds.forEach((propertyId) => {
      if (isDefined(responses[requestMap[propertyId]].data)) {
        response[propertyId] = responses[
          requestMap[propertyId]
        ].data.entries.map((user) => {
          const permissions = transformPermissions(user.permissions);
          return {
            ...user,
            userId: user.cognitoUserId,
            permissions,
          };
        });
      }
    });
    metrics.end('getPropertiesUsers', guid);
    logger.debug('getPropertiesUsers', { propertyIds });
    return response;
  } catch (error) {
    metrics.fail('getPropertiesUsers', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertiesUsers',
      args: { propertyIds },
    });
    throw apiError;
  }
}

const transformPermissions = (permissions) => {
  return permissions.map((permission) => {
    const { propertyURN, socialPageURN, permissionType } = permission;
    return {
      propertyId: extractPropertyId(propertyURN),
      accountAPIId: socialPageURN
        ? extractAccountAPIId(socialPageURN)
        : undefined,
      permissionTypeId: PERMISSION_TYPES[permissionType],
    };
  });
};
