export { canCollectPageStatistics, getAccountAPIs };

// Exclude The SUN (10031) from collectiong page statistics. TEC-11921
// Exclude Ippen properties. TEC-18578
const PROPERTY_IDS_TO_EXCLUDE_FROM_STATISTICS = new Set([
  '10031',
  '11044',
  '11045',
  '11047',
  '11048',
  '11049',
  '11050',
  '11051',
  '11054',
  '11056',
  '11057',
  '11068',
  '11729',
]);

/**
 * canCollectPageStatistics
 *
 * Returns true if the we can get statistcs for the page false otherwise
 */

function canCollectPageStatistics(propertyId) {
  return !PROPERTY_IDS_TO_EXCLUDE_FROM_STATISTICS.has(String(propertyId));
}

/**
 * getAccountAPIs
 *
 * Returns an array of account APIs from the apis-by-network structure
 */

function getAccountAPIs(apisByNetwork) {
  const accountAPIs = [];
  Object.keys(apisByNetwork).forEach((apiTypeId) => {
    apisByNetwork[apiTypeId].forEach((accountAPI) => {
      accountAPIs.push(accountAPI);
    });
  });
  return accountAPIs;
}
