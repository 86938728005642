import { createContext, useContext } from 'react';

interface HeaderContextInterface {
  showMobileMenu: boolean;
  onMobileMenuToggle: () => void;
}

const HeaderContext = createContext<HeaderContextInterface | undefined>(
  undefined,
);

const useHeader = () => {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useNavbar must be used within Headerv2 component');
  }
  return context;
};

export { HeaderContext, useHeader };
