import $ from 'jquery';
import type { Immutable } from 'seamless-immutable';

import {
  getAccountAPIMessageSource,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import {
  CONTENT_TYPE_IDS,
  SPECIAL_CHARACTER_REGEX_PARTS,
  TAG_TYPES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as MediaItem from 'common/mediaItem';
import { findShareContentIndex } from 'common/mediaItem/shareContent';
import { getFeatureToggle } from 'common/settings';
import { canGenerateAIMessage, getTagDetails } from 'common/social';
import { mandatory } from 'common/validation';
import type {
  AIMessage,
  FixTypeLater,
  MessageHistory,
  PostType,
  ShareContent,
  ShareContentResult,
  Tag,
  TagEntity,
} from 'types';

/**
 * Helper functions for the MessageBox
 */

/**
 * Update CSS style to use text cursor
 */

export function setTextCursor() {
  if (typeof $ !== 'undefined') {
    $('span[data-text="true"]').css('cursor', 'text');
  }
}

/**
 * Returns a new messages object with the data updated, if no data has changed it returns
 * the same object.
 */

export function updateMessages({
  messages,
  selectedContentIndex,
  selectedShareContent,
}: {
  messages: Immutable<ShareContentResult>;
  selectedContentIndex: number;
  selectedShareContent: ShareContent;
}) {
  if (messages === undefined) {
    mandatory('messages');
  }
  if (selectedContentIndex === undefined) {
    mandatory('selectedContentIndex');
  }
  if (selectedShareContent === undefined) {
    mandatory('selectedShareContent');
  }

  let newMessages = messages;
  let index = selectedContentIndex;

  // Add the new share content to the end of the list if it does not exist already
  if (index === -1) {
    newMessages = newMessages.set(
      'orderedShareContent',
      messages.orderedShareContent.concat([selectedShareContent]),
    );
    index = newMessages.orderedShareContent.length - 1;
  }

  if (messages.selectedContentIndex !== index) {
    newMessages = newMessages.set('selectedContentIndex', index);
  }
  if (
    selectedShareContent &&
    messages.selectedShareContent !== selectedShareContent
  ) {
    newMessages = newMessages
      .set('selectedShareContent', selectedShareContent)
      .set('selectedContentTypeId', selectedShareContent.contentTypeId);
  }

  return newMessages;
}

export function addToMessageHistory({
  messages,
  messageHistory,
  aiMessage,
}: {
  messages: ShareContentResult;
  messageHistory: MessageHistory;
  aiMessage?: Immutable<AIMessage>;
}) {
  return messageHistory.concat([
    {
      shareContent: messages.selectedShareContent,
      index: messages.selectedContentIndex,
      aiMessageSelectedIndex: aiMessage?.selectedIndex ?? -1,
    },
  ]);
}

export function goBackMessageHistory({
  messageHistory,
  aiMessage,
}: {
  messageHistory: MessageHistory;
  aiMessage: Immutable<AIMessage>;
}) {
  if (messageHistory === undefined) {
    mandatory('messageHistory');
  }
  if (aiMessage === undefined) {
    mandatory('aiMessage');
  }

  if (messageHistory.length === 0) {
    return null;
  }

  const prev = messageHistory[messageHistory.length - 1];
  return {
    prev,
    newMessageHistory: messageHistory.slice(0, messageHistory.length - 1),
    newAIMessage: aiMessage
      .set('selectedIndex', prev.aiMessageSelectedIndex)
      .set('loadingFailed', false),
  };
}

/**
 *  Create a hashtag object based on the input text and accountAPIId
 */

export function getHashtagAndSuffix({
  hashtag,
  apiTypeId,
}: {
  hashtag: string;
  apiTypeId: number;
}): [Tag, string] | [null, null] {
  if (hashtag === undefined) {
    mandatory('hashtag');
  }
  if (apiTypeId === undefined) {
    mandatory('apiTypeId');
  }

  const parts = hashtag.match(SPECIAL_CHARACTER_REGEX_PARTS);
  if (parts === null) {
    return [null, null];
  }
  const tag = getTagDetails({
    tag: `#${parts[1]}`,
    apiTypeId,
    tagTypeId: TAG_TYPES.HASHTAG,
  });
  const suffix = parts[2];
  return tag.raw ? [tag, suffix] : [null, null];
}

/**
 * Remove mentions
 *
 * entities is an array of objects representing any hashtags or mentions
 */

export function removeMentions(
  entities: {
    entity: TagEntity;
    start: number;
    end: number;
  }[],
) {
  const updated: {
    entity: TagEntity;
    start: number;
    end: number;
  }[] = [];

  entities.forEach((entity) => {
    // If the entitity is not a mention...
    if (
      entity?.entity?.data?.originalContent &&
      entity?.entity?.data?.originalContent.substr(0, 1) !== '@'
    ) {
      // ... add it to the updated list of entities
      updated.push(entity);
    }
  });
  return updated;
}

export function canPageGenerateAIMessage({
  apiTypeId,
  postType,
}: {
  apiTypeId: number;
  postType: PostType;
}) {
  if (apiTypeId === undefined) {
    mandatory('apiTypeId');
  }
  return (
    canGenerateAIMessage({ apiTypeId, postType }) && isOpenAIMessageEnabled()
  );
}

export function isOpenAIMessageEnabled() {
  return getFeatureToggle({
    featureName: FEATURE_TOGGLES.OPEN_AI_MESSAGE_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
}

export async function getNextAIMessage({
  messages,
  aiMessage,
  generateAIMessageContent,
}: {
  messages: ShareContentResult;
  aiMessage: Immutable<AIMessage>;
  generateAIMessageContent: () => Promise<ShareContent>;
}) {
  if (messages === undefined) {
    mandatory('messages');
  }
  if (aiMessage === undefined) {
    mandatory('aiMessage');
  }
  if (generateAIMessageContent === undefined) {
    mandatory('generateAIMessageContent');
  }

  let newAIMessage = aiMessage;
  let newIndexedShareContent;

  // We always use OPTIMAL even for generated types as only one AI message gets saved
  const optimalIndex = findShareContentIndex({
    messages,
    contentTypeId: CONTENT_TYPE_IDS.OPTIMAL,
  });

  const nextAIMessageIndex = aiMessage.selectedIndex + 1;

  if (aiMessage.messages.length > nextAIMessageIndex) {
    // If AI message is cached use that one
    newIndexedShareContent = {
      shareContent: aiMessage.messages[nextAIMessageIndex],
      index: optimalIndex,
    };
  } else {
    // Generate a new AI message and cache it
    const generatedContent: ShareContent = await generateAIMessageContent();
    generatedContent.contentTypeId = CONTENT_TYPE_IDS.OPTIMAL;

    newIndexedShareContent = {
      shareContent: generatedContent,
      index: optimalIndex,
    };

    newAIMessage = aiMessage.set(
      'messages',
      aiMessage.messages.concat([newIndexedShareContent.shareContent]),
    );
  }

  return {
    newIndexedShareContent,
    newAIMessage: newAIMessage
      .set('selectedIndex', nextAIMessageIndex)
      .set('isLoading', false)
      .set('loadingFailed', false),
  };
}

export function shouldGetAIMessageOnRender({
  accountAPIId,
  mediaItem,
  isFirstRender,
}: {
  accountAPIId: number;
  mediaItem: FixTypeLater;
  isFirstRender: boolean;
}) {
  return (
    getAccountAPIMessageSource({ accountAPIId }) === 'OPTIMAL_MESSAGE' &&
    MediaItem.getSuggestionTypeId({ mediaItem }) === null &&
    isFirstRender
  );
}
