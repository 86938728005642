import $ from 'jquery';
import PubSub from 'pubsub-js';
import {
  Dispatch,
  ReactEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  postUploadImage,
  postUploadVideoFinalise,
  postUploadVideoInitialise,
  putUploadVideoPart,
} from 'api/api';
import getDownloadFile from 'api/external/getDownloadFile';
import getMediaItem from 'api/getMediaItem';
import getVideoThumbnail from 'api/getVideoThumbnail';

import {
  getAPIPostName,
  getAPITypeId,
  getCurrentPropertyId,
  getCurrentSocialPageURN,
} from 'common/accountAPIs';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from 'common/config';
import {
  API_TYPE_IDS,
  IMAGE_TYPES,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { getImageType } from 'common/misc';
import { addErrorNotification, addNotification } from 'common/notifications';
import { convertEchoboxImageToAmazon, isEbxMedia } from 'common/s3';
import { getFeatureToggle, isIGCabinetEnabled } from 'common/settings';
import {
  getImageQuantityLimits,
  getSocialNetworkName,
  getURNName,
  getValidationMessages,
  hasMentionsLookups,
  hasShareMessages,
  hasSponsoredPosts,
  isAspectRatioValid,
  isValidAspectRatioMandatory,
} from 'common/social';
import { canAddThumbnail, getAllowedFileTypes } from 'common/socialV2';
import { formatFileSize } from 'common/string';
import * as tracker from 'common/tracker';
import {
  getRootURL,
  getShareParameters,
  isValidURL,
  splitURL,
} from 'common/url';
import { convertToSocialPageURN } from 'common/urn';
import { generateSHA256Checksum } from 'common/utility';
import validators from 'common/validators';
import SocialImage from 'components/compose/images/SocialImage';
import {
  addFiles,
  addImage,
  deleteAllImages,
  deleteFiles,
  deleteImage,
  deleteVideo,
  handleIsPopupSelectorOpen,
  handleRefreshPreviewError,
  handleRefreshPreviewPending,
  handleRefreshPreviewSuccess,
  replaceImage,
  updatePostImageNeedsToBeUpdated as updatePostImageNeedsToBeUpdatedDispatch,
  updateValidationMessage,
  updateVideoURL,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import {
  calculateFinalImageHeight,
  calculateFinalImageWidth,
  findElement,
  getFileSizeLimits,
  isImageUploadNotAllowed,
  logError,
} from 'helpers/images';
import { getNetworkAndPageName } from 'helpers/tracking';
import { addURLParameters } from 'helpers/url';
import {
  chunkVideoForS3,
  validateVideo,
  validateVideoFile,
} from 'helpers/videos';
import * as topics from 'pubsub/topics';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';
import {
  APITypeId,
  FileMetaDataType,
  FixTypeLater,
  IGLinkStickerConfig,
} from 'types';

const { MESSAGE_MEDIA_ITEM_ERROR } = topics;

const IMAGE_MODES = {
  DEFAULT: 'DEFAULT',
  CROPPING: 'CROPPING',
  DOWNLOADING: 'DOWNLOADING',
  EDITING: 'EDITING',
};

const VIDEO_UPLOAD_CANCELLED_KEY = 'VideoUploadCancelled';

const trackDisplayVideoUploadError = ({
  apiTypeId,
  accountAPIId,
  acceptedFile,
  errorMessage,
}: {
  apiTypeId: APITypeId;
  accountAPIId: number;
  acceptedFile: File | null;
  errorMessage: string;
}) => {
  const trackingParams = {
    'Error Type': errorMessage,
    'Social Network': getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
    'Account API Id': accountAPIId,
    'File Format': acceptedFile ? acceptedFile?.name?.split('.')?.pop() : '',
  };
  tracker.track({
    eventName: 'Display Video Upload Error Message',
    trackingParams,
  });
};

const trackAbortVideoUpload = ({
  apiTypeId,
  accountAPIId,
}: {
  apiTypeId: APITypeId;
  accountAPIId: number;
}) => {
  const trackingParams = {
    'Social Network': getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
    'Account API Id': accountAPIId,
  };
  tracker.track({
    eventName: 'Abort Video Upload',
    trackingParams,
  });
};

interface ImagesProps {
  mediaItem: FixTypeLater;
  accountAPIId: number;
  guid: string;
  apiTypeId: APITypeId;
  articleImages: string[];
  pendingStickerConfig: IGLinkStickerConfig | null;
  isUploading: boolean;
  setIsUploading: (isUploading: boolean) => void;
  onPendingStickerConfig: Dispatch<SetStateAction<IGLinkStickerConfig | null>>;
}

/*
 * Article imagery
 */
const Images = ({
  articleImages,
  apiTypeId,
  accountAPIId,
  guid,
  mediaItem,
  pendingStickerConfig,
  isUploading,
  setIsUploading,
  onPendingStickerConfig,
}: ImagesProps) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [mode, setMode] = useState(IMAGE_MODES.DEFAULT);
  const [hasCorrectAspectRatio, setHasCorrectAspectRatio] = useState(true);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const imageUrl = MediaItem.getImageURLs({ mediaItem })?.[0];
  const isAutoGeneratedThumbnail = imageUrl?.startsWith(
    'data:image/jpeg;base64,',
  );
  const postType = MediaItem.getPostType({ mediaItem });
  const isThumbnailUploaded = Boolean(
    postType === POST_TYPES.VIDEO && imageUrl && !isAutoGeneratedThumbnail,
  );
  const [isVideoLoaded, setIsVideoLoaded] = useState(isThumbnailUploaded);
  const [firstImageHeight, setFirstImageHeight] = useState<
    number | undefined
  >();
  const [firstImageWidth, setFirstImageWidth] = useState<number | undefined>();
  const controllers = useRef<AbortController[]>([]);

  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });
  const isDraft = suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE;
  const state = MediaItem.getState({ mediaItem });
  const isNew = !isDraft && state === MEDIA_ITEM_STATES.NEW;
  const [hasBeenModified, setHasBeenModified] = useState(!isNew);

  const { dispatch } = useComposeBoxContext();

  const isUnmounted = useRef(false);

  useEffect(() => {
    return () => {
      isUnmounted.current = true;
    };
  }, []);

  const setPreloadedFiles = useComposeBoxOpenStore(
    (composeBoxOpenState) => composeBoxOpenState.setPreloadedFiles,
  );

  const naturalDimensions = useRef({ width: 0, height: 0 });

  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const previousSocialChannelRef = useRef(socialChannel);
  const validationMessage = MediaItem.getValidationMessage({ mediaItem });

  const isLinkinbioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  const updatePostImageNeedsToBeUpdated = ({
    currentPostImageNeedsToBeUpdated,
  }: {
    currentPostImageNeedsToBeUpdated: boolean;
  }) => {
    dispatch(
      updatePostImageNeedsToBeUpdatedDispatch({
        guid,
        imageIndex: currentImage,
        imageNeedsToBeUpdated: currentPostImageNeedsToBeUpdated,
      }),
    );
  };

  const setArticleError = (errorMessage: string) => {
    if (errorMessage.trim() !== '') {
      setMode(IMAGE_MODES.DEFAULT);
      setIsUploading(false);
    }

    logger.info(
      `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in components/compose/Images.setArticleError`,
    );
    PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
      guid,
      error: errorMessage,
    });
  };

  const setValidationMessage = (message: string | null) => {
    dispatch(updateValidationMessage({ guid, message }));
  };

  // Ensure the current image sits within the bounds of article images.
  if (articleImages.length > 0 && currentImage > articleImages.length - 1) {
    setCurrentImage(articleImages.length - 1);
  }

  const handleCancel = () => {
    setMode(IMAGE_MODES.DEFAULT);
    setIsUploading(false);
  };

  const handleCrop = () => {
    setMode(IMAGE_MODES.CROPPING);
    handleCropEdit();
  };

  const handleEdit = () => {
    dispatch(handleIsPopupSelectorOpen({ isPopupSelectorOpen: true }));
    setMode(IMAGE_MODES.EDITING);
    handleCropEdit();
  };

  const handleImageReplace = ({
    imageIndex,
    imageURL,
  }: {
    imageIndex: number;
    imageURL: string;
  }) => {
    dispatch(replaceImage({ guid, imageIndex, imageURL }));
  };

  const handleCropEdit = async () => {
    const imageURL = convertEchoboxImageToAmazon(articleImages[currentImage]);

    // If it's already uploaded to S3 - don't upload again
    if (isEbxMedia(imageURL)) {
      return;
    }

    setIsUploading(true);
    setProgressPercentage(0);

    try {
      const s3ImageURL = await postUploadImage({
        socialPageURN: getCurrentSocialPageURN(),
        imageURL,
      });
      updatePostImageNeedsToBeUpdated({
        currentPostImageNeedsToBeUpdated: true,
      });
      handleImageReplace({
        imageIndex: currentImage,
        imageURL: convertEchoboxImageToAmazon(s3ImageURL),
      });
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);

      const errorMessage = logError({
        error,
        fileType: 'image',
      });
      setArticleError(errorMessage);
    }
  };

  const handleSave = (fileBlob: File) => {
    setHasBeenModified(true);
    updatePostImageNeedsToBeUpdated({
      currentPostImageNeedsToBeUpdated: false,
    });
    handleFileDrop([fileBlob]);
  };

  const handleDeleteImage = () => {
    dispatch(deleteImage({ guid, imageIndex: currentImage }));
    if (currentImage > articleImages.length - 2) {
      setCurrentImage((prev) => prev - 1);

      if (articleImages.length === 1) {
        setHasCorrectAspectRatio(false);
      }
    }
  };

  const handleDownload = async (downloadImageURL: string) => {
    setMode(IMAGE_MODES.DOWNLOADING);
    try {
      const isURL = isValidURL(downloadImageURL);
      const isBase64 = downloadImageURL.startsWith('data:image');
      let data: Blob | string = '';
      let suffix;
      if (isURL) {
        const response = await getDownloadFile({
          url: downloadImageURL,
          responseType: 'blob',
        });
        data = response.data;
        const urlParts = splitURL(downloadImageURL);
        suffix = urlParts.rootURL.match(/\.[0-9a-z]+$/i);
        if (suffix === null) {
          // Determine the file type using the blob as the suffix does not contain the file type
          if (typeof data !== 'string' && data.type) {
            suffix = `.${data.type.split('/')[1]}`;
          } else {
            suffix = '';
          }
        } else {
          suffix = suffix[0];
        }
      } else if (isBase64) {
        data = downloadImageURL;
        const dataAndImageType = downloadImageURL.split(';')[0];
        suffix = dataAndImageType.split('/')[1];
      } else {
        throw new ReferenceError('Unsupported format!');
      }

      const filename = `ebx_image${suffix}`;

      const link = document.createElement('a');
      let url;
      if (typeof data === 'string') {
        link.href = data;
      } else {
        url = window.URL.createObjectURL(data);
        link.href = url;
      }
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (isURL && url) {
        window.URL.revokeObjectURL(url);
      }
      setMode(IMAGE_MODES.DEFAULT);
    } catch (error) {
      setMode(IMAGE_MODES.DEFAULT);
      addErrorNotification('Download failed');
    }
  };

  const handleFileDrop = async (acceptedFiles: File[]) => {
    setValidationMessage(null);
    setIsUploading(true);
    setProgressPercentage(0);
    let imageType = IMAGE_TYPES.OTHER;
    acceptedFiles.forEach((file) => {
      if (getImageType(file) === IMAGE_TYPES.GIF) {
        imageType = IMAGE_TYPES.GIF;
      }
    });

    const limits = getImageQuantityLimits({
      apiTypeId,
      postType,
      imageType,
      socialChannel,
    });

    const videoURL = MediaItem.getVideoURL({ mediaItem });
    const isVideoUploaded = videoURL !== '';

    const errorMessage = validateFiles(acceptedFiles, limits, isVideoUploaded);

    if (errorMessage != null) {
      const files = MediaItem.getFiles({ mediaItem });
      if (files && files.length > 0) {
        dispatch(deleteFiles({ guid }));
      }
      if (postType === POST_TYPES.VIDEO) {
        trackDisplayVideoUploadError({
          apiTypeId,
          accountAPIId,
          acceptedFile: acceptedFiles?.[0],
          errorMessage,
        });
      }

      setArticleError(errorMessage);
      return;
    }

    if (
      acceptedFiles.length > 0 &&
      !errorMessage &&
      mode === IMAGE_MODES.DEFAULT
    ) {
      dispatch(addFiles({ guid, files: acceptedFiles }));
    }

    // Set the article error to empty
    setArticleError('');

    // Upload file(s)
    const filteredFiles = acceptedFiles
      .filter((file) => file != null)
      .map((file) => {
        const fileType = file.type.split('/')[0];
        // Upload image
        if (fileType === 'image') {
          return uploadImage(file, limits);
        }
        // Upload video
        if (fileType === 'video') {
          return uploadVideo(file);
        }

        return null;
      });

    await Promise.all(filteredFiles);
    // reset preloaded files once the files have been uploaded
    setPreloadedFiles(null);
  };

  const handleRefreshPreview = async () => {
    if (mediaItem == null) {
      return;
    }

    dispatch(handleRefreshPreviewPending({ guid }));

    // Refresh the media item data
    try {
      const mediaItemAccountAPIId = MediaItem.getAccountAPIId({ mediaItem });
      const isURLResolved = MediaItem.getIsURLResolved({ mediaItem });
      const mediaItemApiTypeId = getAPITypeId({ accountAPIId });
      const mediaId = MediaItem.getMediaId({ mediaItem });
      const currentPostType = MediaItem.getPostType({ mediaItem });
      const isLinkPost = currentPostType === POST_TYPES.LINK;
      const refreshMediaId =
        mediaId === 'RESHARE' ? MediaItem.getMediaId({ mediaItem }) : mediaId;
      const baseItem = await getMediaItem({
        accountAPIId: mediaItemAccountAPIId,
        apiTypeId: mediaItemApiTypeId,
        state,
        mediaId: refreshMediaId,
        getMessages:
          isLinkPost && hasShareMessages({ apiTypeId: mediaItemApiTypeId }),
        getPageInfo:
          isLinkPost && hasMentionsLookups({ apiTypeId: mediaItemApiTypeId }),
        getSponsor:
          isLinkPost && hasSponsoredPosts({ apiTypeId: mediaItemApiTypeId }),
        getTags: false,
        ...{ refreshHTMLMetaData: true },
      });

      // Update the article URL if necessary
      let updatedURL: string | null = null;
      if (isLinkPost) {
        const unshortenedShareURL = MediaItem.getUnshortenedShareURL({
          mediaItem: baseItem,
        });
        const rootURL = getRootURL(unshortenedShareURL);
        const shareParameters = getShareParameters(unshortenedShareURL);
        if (rootURL !== '' && shareParameters === '') {
          const isNewURL =
            MediaItem.getSuggestionTypeId({ mediaItem: baseItem }) === null;
          updatedURL = await addURLParameters(
            rootURL,
            mediaItemAccountAPIId,
            isNewURL,
          );
        }
      }

      dispatch(
        handleRefreshPreviewSuccess({
          baseItem,
          guid,
          updatedURL,
          isURLResolved,
        }),
      );
      addNotification('Article data successfully updated', 'success');
    } catch (error) {
      logger.error({ event: 'Images handleRefreshPreview', error });

      // Remove lock and notify user
      dispatch(handleRefreshPreviewError({ guid }));
      addNotification('Article data not successfully updated', 'error');
    }
  };

  const resizeImage = async () => {
    const noOfImages = articleImages.length;
    const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;
    const isInstagramReelPost =
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      socialChannel === SOCIAL_CHANNELS.REEL;
    const isInstagramGraphStoryPost =
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      socialChannel === SOCIAL_CHANNELS.STORY &&
      !isIGCabinetEnabled();
    // Resize the image container
    if (typeof $ !== 'undefined') {
      // Remove any existing height styling
      findElement(guid).css('height', '');
      if (isTikTok || isInstagramReelPost || isInstagramGraphStoryPost) {
        findElement(guid).height(442);
      } else if (postType === POST_TYPES.VIDEO) {
        findElement(guid).height(370);
      }

      if (noOfImages === 0) {
        if (
          socialChannel === SOCIAL_CHANNELS.REEL ||
          (socialChannel === SOCIAL_CHANNELS.STORY && !isIGCabinetEnabled())
        ) {
          findElement(guid).height(442);
        } else {
          findElement(guid).height(370); // Reset to default
        }
      } else if (
        noOfImages >= 1 &&
        apiTypeId === API_TYPE_IDS.INSTAGRAM &&
        (postType === POST_TYPES.LINK || postType === POST_TYPES.PHOTO_STORY) &&
        (socialChannel === SOCIAL_CHANNELS.STORY ||
          socialChannel === SOCIAL_CHANNELS.FEED)
      ) {
        if (
          !isValidAspectRatioMandatory({ apiTypeId, postType }) ||
          isAspectRatioValid({
            apiTypeId,
            postType,
            imageDimensions: naturalDimensions.current,
            socialChannel,
          })
        ) {
          findElement(guid).height(MAX_IMAGE_HEIGHT[API_TYPE_IDS.INSTAGRAM]);
          findElement(guid).width(
            calculateFinalImageWidth({
              guid,
              apiTypeId,
              naturalDimensions: naturalDimensions.current,
            }),
          );
        }
      } else if (
        noOfImages >= 1 &&
        postType !== POST_TYPES.LINK &&
        apiTypeId === API_TYPE_IDS.INSTAGRAM
      ) {
        let targetHeight;
        if (noOfImages > 1) {
          targetHeight = firstImageHeight;
        }

        if (!targetHeight) {
          targetHeight = calculateFinalImageHeight({
            guid,
            apiTypeId,
            naturalDimensions: naturalDimensions.current,
          });
        }

        if (currentImage === 0) {
          setFirstImageHeight(targetHeight ?? MAX_IMAGE_HEIGHT[apiTypeId]);
        }
        if (
          socialChannel === SOCIAL_CHANNELS.REEL ||
          (socialChannel === SOCIAL_CHANNELS.STORY && !isIGCabinetEnabled())
        ) {
          findElement(guid).height(442);
        } else {
          findElement(guid).height(
            Number(targetHeight ?? MAX_IMAGE_HEIGHT[apiTypeId]),
          );
        }
        let targetWidth;
        if (noOfImages > 1) {
          targetWidth = firstImageWidth;
        }

        if (!targetWidth) {
          targetWidth = calculateFinalImageWidth({
            guid,
            apiTypeId,
            naturalDimensions: naturalDimensions.current,
          });
        }

        if (currentImage === 0) {
          setFirstImageWidth(targetWidth ?? MAX_IMAGE_WIDTH[apiTypeId]);
        }
        if (socialChannel === SOCIAL_CHANNELS.STORY && !isIGCabinetEnabled()) {
          findElement(guid).width(248);
        } else
          findElement(guid).width(
            Number(targetWidth ?? MAX_IMAGE_WIDTH[apiTypeId]),
          );
      } else if (
        noOfImages >= 1 &&
        (postType !== POST_TYPES.LINK || apiTypeId !== API_TYPE_IDS.FACEBOOK)
      ) {
        findElement(guid).height(
          calculateFinalImageHeight({
            guid,
            apiTypeId,
            naturalDimensions: naturalDimensions.current,
          }),
        );
      }
    }

    // Work out whether an aspect ratio needs to be enforced
    checkAspectRatio();
  };

  const onImageLoad: ReactEventHandler<HTMLImageElement & HTMLVideoElement> = (
    event,
  ) => {
    naturalDimensions.current = {
      width: event.currentTarget.naturalWidth,
      height: event.currentTarget.naturalHeight,
    };

    const files = MediaItem.getFiles({ mediaItem });
    let imageType = IMAGE_TYPES.OTHER;
    if (files && files.length > 0) {
      files.forEach((file) => {
        if (getImageType(file) === IMAGE_TYPES.GIF) {
          imageType = IMAGE_TYPES.GIF;
        }
      });

      const limits = getImageQuantityLimits({
        apiTypeId,
        postType,
        imageType,
        socialChannel,
      });

      const videoURL = MediaItem.getVideoURL({ mediaItem });
      const isVideoUploaded = videoURL !== '';

      // validate file size and type
      const error = validateFiles(files, limits, isVideoUploaded, apiTypeId);

      if (!validationMessage && error) {
        dispatch(deleteAllImages({ guid }));
        dispatch(deleteFiles({ guid }));
        setValidationMessage(error);
        return;
      }
    }

    resizeImage();
  };

  // We want to perform validation when the video has fully loaded.
  // This should have already been done when the video was uploaded, however,
  // it is also performed here is so that videos cross-posted between different
  // networks (with differing limits), can display the appropriate errors.
  const onVideoLoad: ReactEventHandler<HTMLVideoElement> = async (event) => {
    const files = MediaItem.getFiles({ mediaItem });

    const limits = getImageQuantityLimits({
      apiTypeId,
      postType,
      imageType: IMAGE_TYPES.VIDEO,
      socialChannel,
    });

    // validate file size and type
    let error = validateFiles(files, limits, isVideoLoaded, apiTypeId);
    if (!validationMessage && error) {
      if (files && files.length > 0) {
        dispatch(deleteFiles({ guid }));
      }
      setValidationMessage(error);
      return;
    }

    // validate aspect ratio, duration and dimensions
    error = validateVideo({
      apiTypeId,
      videoDimensions: {
        width: event.currentTarget.videoWidth,
        height: event.currentTarget.videoHeight,
      },
      duration: event.currentTarget.duration,
      socialChannel,
    });

    if (!validationMessage && error) {
      setValidationMessage(error);
    } else {
      setIsVideoLoaded(true);
    }
  };

  const validateThumbnail = async ({
    src,
    fileSize,
  }: {
    src: string;
    fileSize: number;
  }) => {
    const validator = validators[apiTypeId]?.thumbnailImageValidator;
    if (!validator) {
      return null;
    }
    return new Promise<string | null>((resolve) => {
      const posterImage = new Image();
      posterImage.src = src;
      posterImage.onload = () => {
        resolve(
          validator({
            imageDimensions: {
              width: posterImage.width,
              height: posterImage.height,
            },
            ...(fileSize && { fileSize }),
            socialChannel,
          }),
        );
      };
    });
  };

  const checkAspectRatio = () => {
    if (articleImages.length === 0) {
      return;
    }

    const imageURLs = MediaItem.getImageURLs({ mediaItem });

    const isVideoThumbnailGenerated =
      postType === POST_TYPES.VIDEO &&
      imageURLs &&
      imageURLs[0]?.startsWith('data:image/jpeg;base64,');

    const newHasCorrectAspectRatio =
      !isValidAspectRatioMandatory({ apiTypeId, postType }) ||
      isAspectRatioValid({
        apiTypeId,
        postType,
        imageDimensions: naturalDimensions.current,
        socialChannel,
      });
    setHasCorrectAspectRatio(newHasCorrectAspectRatio);

    // If this is a IG FEED post, that hasn't been cropped manually yet, we want to mark is as 'needs update'
    // This will ensure that it is manually cropped to a square when saved/scheduled.
    // See https://echobox.atlassian.net/browse/SL-4850
    if (
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      socialChannel === SOCIAL_CHANNELS.FEED &&
      postType === POST_TYPES.LINK &&
      isLinkinbioEnabled &&
      !hasBeenModified
    ) {
      updatePostImageNeedsToBeUpdated({
        currentPostImageNeedsToBeUpdated: true,
      });
    } else if (!isVideoThumbnailGenerated) {
      updatePostImageNeedsToBeUpdated({
        currentPostImageNeedsToBeUpdated: !newHasCorrectAspectRatio,
      });
    }
  };

  const uploadImage = async (
    file: File,
    limits: { min: number; max: number },
  ) => {
    try {
      setHasBeenModified(true);
      let noOfImages = articleImages.length;
      // If this is a video post, we need to validate the thumbnail image
      if (postType === POST_TYPES.VIDEO) {
        const error = await validateThumbnail({
          src: URL.createObjectURL(file),
          fileSize: file.size,
        });

        if (error) {
          setArticleError(error);
          return;
        }
        // make sure image urls array is empty before we set it to the newly uploaded image
        dispatch(deleteAllImages({ guid }));
        noOfImages = 0;
      }

      const newImageURL = await postUploadImage({
        socialPageURN: getCurrentSocialPageURN(),
        imageFile: file,
        onProgress: ({ percent }) => setProgressPercentage(percent),
      });

      const imageURL = convertEchoboxImageToAmazon(newImageURL);

      if (
        isImageUploadNotAllowed({
          apiTypeId,
          articleImages,
          mediaItem,
          limits,
          file,
        })
      ) {
        if (postType !== POST_TYPES.VIDEO) {
          setArticleError(
            'Twitter only allows 1 gif or up to 4 images to be uploaded at once. Delete one or more existing images before uploading more.',
          );
          return;
        }
        dispatch(deleteAllImages({ guid }));
        noOfImages = 0;
      }
      // Replace image if appropriate
      if (noOfImages === limits.max) {
        handleImageReplace({
          imageIndex: noOfImages - 1,
          imageURL,
        });
      } else if ([IMAGE_MODES.CROPPING, IMAGE_MODES.EDITING].includes(mode)) {
        handleImageReplace({
          imageIndex: currentImage,
          imageURL,
        });
      } else {
        // Add newly-uploaded image
        const twitterCard = MediaItem.getTwitterCardType({
          mediaItem,
        });
        const imageURLs = MediaItem.getImageURLs({
          mediaItem,
        });
        if (twitterCard === null || imageURLs.length === 0) {
          noOfImages += 1;
        }
        dispatch(addImage({ guid, imageURL }));
      }
      if (![IMAGE_MODES.CROPPING, IMAGE_MODES.EDITING].includes(mode)) {
        setCurrentImage(noOfImages - 1); // Set current image to last image
      }
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      const errorMessage = logError({
        error,
        fileType: 'image',
      });
      setArticleError(errorMessage);
    }
  };

  const cancelRequests = (abortControllers: AbortController[]) => {
    abortControllers.forEach((controller) => {
      controller.abort();
    });
  };

  const uploadVideo = async (file: File) => {
    const socialPageURN = convertToSocialPageURN(
      getURNName({ apiTypeId }),
      accountAPIId,
    );
    try {
      await validateVideoFile({
        apiTypeId,
        file,
        socialChannel,
      });

      let videoURL;

      const { name, size } = file;
      const sizeInMB = size / 1024 / 1024;
      tracker.track({
        eventName: 'Start Video Upload',
        trackingParams: {
          'File Size (MB)': sizeInMB?.toFixed(2),
          'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
        },
      });

      const checksumSHA256 = await generateSHA256Checksum(file);
      const { signedURLParts, partSizeBytes, uploadId } =
        await postUploadVideoInitialise({
          fileName: name,
          fileSizeBytes: size,
          checksumSHA256,
          socialPageURN,
        });

      const chunks = chunkVideoForS3(
        file,
        signedURLParts?.length,
        partSizeBytes,
      );
      const promises = [];
      const abortControllers = [];
      const partNumbers: number[] = [];
      const percentageArray: number[] = new Array(signedURLParts.length).fill(
        0,
      );

      // check if the upload was cancelled
      // this is needed here in case a user closes the compose box before any of the requests have been made.
      // This is because the controllers array would be empty at that point so useEffect cleanup would run before the requests are made (essentially doing nothing)
      if (sessionStorage.getItem(VIDEO_UPLOAD_CANCELLED_KEY) === 'true') {
        sessionStorage.removeItem(VIDEO_UPLOAD_CANCELLED_KEY);
        trackAbortVideoUpload({
          apiTypeId,
          accountAPIId,
        });
        return;
      }

      for (let i = 0; i < signedURLParts?.length; i += 1) {
        const controller = new AbortController();
        abortControllers.push(controller);
        promises.push(
          putUploadVideoPart({
            url: signedURLParts[i],
            data: chunks[i],
            controller,
            onProgress: ({ percent }) => setProgressPercentage(percent),
            index: i,
            percentageArray,
          }),
        );
        partNumbers.push(i + 1);
      }
      try {
        controllers.current = abortControllers;
        const responses = await Promise.all(promises);
        // finalise upload
        const parts = responses.map((response, index) => ({
          eTag: response.etag,
          partNumber: partNumbers[index],
        }));
        const finaliseResponse = await postUploadVideoFinalise({
          fileName: name,
          uploadId,
          parts,
          socialPageURN,
        });

        videoURL = finaliseResponse?.name;
        controllers.current = [];
        tracker.track({
          eventName: 'Finish Video Upload',
          trackingParams: {
            'File Size (MB)': sizeInMB?.toFixed(2),
            'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
          },
        });
      } catch (error: any) {
        if (error?.code === 'ERR_CANCELED') {
          return;
        }
        console.log('error', error);
        cancelRequests(abortControllers);
        const trackingParams = {
          'Error Type': error,
          'Social Network': getSocialNetworkName({ apiTypeId }),
          'Social Page': getAPIPostName({ accountAPIId }),
          'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
          'Account API Id': accountAPIId,
          'File Format': name.split('.').pop(),
        };
        tracker.track({
          eventName: 'Video Upload Error Message',
          trackingParams,
        });
        throw error;
      }

      const thumbnail = await getVideoThumbnail({
        videoURL,
      });
      if (thumbnail) {
        if (canAddThumbnail(apiTypeId)) {
          // make sure no images are in the array before we add the thumbnail
          dispatch(deleteAllImages({ guid }));
          dispatch(addImage({ guid, imageURL: thumbnail }));
        } else {
          dispatch(replaceImage({ guid, imageIndex: 0, imageURL: '' }));
        }
      }

      dispatch(updateVideoURL({ guid, videoURL }));
      setIsUploading(false);
    } catch (error) {
      setIsUploading(false);
      const errorMessage = logError({
        error,
        fileType: 'video',
      });
      setValidationMessage(errorMessage);
      trackDisplayVideoUploadError({
        apiTypeId,
        accountAPIId,
        acceptedFile: file,
        errorMessage,
      });
    }
  };

  const validateFiles = (
    acceptedFiles: File[] | FileMetaDataType[] | undefined,
    limits: { min: number; max: number },
    isVideoUploaded: boolean,
    currentApiTypeId?: APITypeId,
  ) => {
    // this is to make sure we don't validate files if they haven't even been uploaded yet
    if (!acceptedFiles) {
      return null;
    }
    const isVideo =
      postType === POST_TYPES.VIDEO &&
      (!isVideoUploaded || acceptedFiles[0]?.type.includes('video'));

    let isFileTypeValid = true;
    let fileTypeError = '';
    // Display error if no files were accepted
    if (acceptedFiles.length === 0) {
      isFileTypeValid = false;
      if (isVideo) {
        const error = getValidationMessages({
          apiTypeId,
          validationType: 'videoFileType',
          socialChannel,
        });

        if (error != null) {
          fileTypeError = error;
        }
      }
      fileTypeError =
        'The file type does not seem to be valid. Please check the file type.';
    }

    // display error if file is uploaded but not valid (i.e. when cross posting)
    const allowedFileTypes = getAllowedFileTypes({
      apiTypeId: currentApiTypeId || apiTypeId,
      postType,
      socialChannel,
      isVideoUploaded,
    });
    if (acceptedFiles.length > 0) {
      isFileTypeValid = acceptedFiles.every((file) => {
        if (file.type in allowedFileTypes) {
          return true;
        }
        fileTypeError =
          'The file type does not seem to be valid. Please check the file type.';
        return false;
      });
    }

    if (!isFileTypeValid) {
      return fileTypeError;
    }

    // Display error if too many files were submitted
    if (acceptedFiles.length > limits.max) {
      return `Too many files are trying to be submitted. Please limit the number of files to ${limits.max}.`;
    }
    if (
      postType !== POST_TYPES.VIDEO &&
      acceptedFiles.some((file) =>
        isImageUploadNotAllowed({
          apiTypeId: currentApiTypeId || apiTypeId,
          articleImages,
          mediaItem,
          limits,
          file,
        }),
      )
    ) {
      return 'Twitter only allows 1 gif or up to 4 images to be uploaded at once.';
    }

    // Reject any files that are too large
    const { underMin, overMax } = acceptedFiles.reduce<{
      underMin: number[];
      overMax: number[];
    }>(
      (acc, file) => {
        const { max, min } = getFileSizeLimits({
          file,
          socialChannel,
          apiTypeId,
          postType,
        });
        if (file != null && max != null && max > 0 && file.size > max) {
          acc.overMax.push(max);
        }
        if (file != null && min != null && min > 0 && file.size < min) {
          acc.underMin.push(min);
        }
        return acc;
      },
      { underMin: [], overMax: [] },
    );

    if (underMin.length > 0) {
      const tooSmallValidationMessage = getValidationMessages({
        apiTypeId,
        validationType: isVideo ? 'videoSmallFileSize' : 'imageSmallFileSize',
        socialChannel,
      });
      if (tooSmallValidationMessage) {
        return tooSmallValidationMessage;
      }
    }

    if (overMax.length > 0) {
      const tooLargeValidationMessage = getValidationMessages({
        apiTypeId,
        validationType: isVideo ? 'videoLargeFileSize' : 'imageLargeFileSize',
        socialChannel,
      });
      if (tooLargeValidationMessage) {
        return tooLargeValidationMessage;
      }

      if (overMax.length === 1) {
        return `The selected file is too large unfortunately. Please limit the filesize to ${formatFileSize(
          overMax[0],
        )}.`;
      }
      return `One of the selected files is too large. Please limit the size of each file to ${formatFileSize(
        overMax[0],
      )}.`;
    }

    // All tests passed
    return null;
  };

  const handleUploadCancel = useCallback(() => {
    if (controllers.current.length > 0) {
      sessionStorage.setItem(VIDEO_UPLOAD_CANCELLED_KEY, 'true');
      cancelRequests(controllers.current);
      trackAbortVideoUpload({ apiTypeId, accountAPIId });
      sessionStorage.setItem(VIDEO_UPLOAD_CANCELLED_KEY, 'false');
      dispatch(deleteFiles({ guid }));
    }
  }, [accountAPIId, apiTypeId, dispatch, guid]);

  // cancel pending video upload requests on unmount
  useEffect(() => {
    // reset sessionStorage variable on mount
    sessionStorage.setItem(VIDEO_UPLOAD_CANCELLED_KEY, 'false');
    return () => {
      if (isUploading && isUnmounted.current) {
        sessionStorage.setItem(VIDEO_UPLOAD_CANCELLED_KEY, 'true');
        handleUploadCancel();
      }
    };
  }, [accountAPIId, apiTypeId, handleUploadCancel, isUploading]);

  const title = MediaItem.getTitle({ mediaItem });
  const twitterCardType = MediaItem.getTwitterCardType({ mediaItem });

  let hasTwitterCardType = twitterCardType !== null;
  const twitterCardImage =
    hasTwitterCardType && articleImages.length > 0 ? articleImages[0] : null;

  hasTwitterCardType = hasTwitterCardType && title !== undefined;

  if (socialChannel !== previousSocialChannelRef.current) {
    // If the social channel changes, what is considered a "valid aspect ratio" may also have changed.
    resizeImage();
    previousSocialChannelRef.current = socialChannel;
  }

  return (
    <SocialImage
      mediaItem={mediaItem}
      articleImages={hasTwitterCardType ? [] : articleImages}
      guid={guid}
      accountAPIId={accountAPIId}
      twitterCardImage={twitterCardImage}
      hasCorrectAspectRatio={hasCorrectAspectRatio}
      hasBeenModified={hasBeenModified}
      currentImage={currentImage}
      isDownloading={mode === IMAGE_MODES.DOWNLOADING}
      isEditing={mode === IMAGE_MODES.EDITING}
      isUploading={isUploading}
      uploadProgressPercent={progressPercentage}
      pendingStickerConfig={pendingStickerConfig}
      onCancel={handleCancel}
      onCrop={handleCrop}
      onDeleteImage={handleDeleteImage}
      onDeleteVideo={() => {
        setIsVideoLoaded(false);
        dispatch(deleteVideo({ guid }));
      }}
      onDownload={handleDownload}
      onEdit={handleEdit}
      onEditClose={() => setMode(IMAGE_MODES.DEFAULT)}
      onFileDrop={handleFileDrop}
      onImageLoad={onImageLoad}
      onVideoLoad={onVideoLoad}
      onNextImage={() =>
        setCurrentImage(
          currentImage >= articleImages.length - 1 ? 0 : currentImage + 1,
        )
      }
      onPendingStickerConfig={onPendingStickerConfig}
      onPrevImage={() =>
        setCurrentImage(
          currentImage <= 0 ? articleImages.length - 1 : currentImage - 1,
        )
      }
      onRefresh={handleRefreshPreview}
      onSave={handleSave}
      onUploadCancel={handleUploadCancel}
    />
  );
};

export default Images;
