import FACEBOOK_CONSTANT_PROVIDER from 'common/constantProviders/facebookConstantProvider';
import INSTAGRAM_CONSTANT_PROVIDER from 'common/constantProviders/instagramConstantProvider';
import LINKEDIN_CONSTANT_PROVIDER from 'common/constantProviders/linkedInConstantProvider';
import TIKTOK_CONSTANT_PROVIDER from 'common/constantProviders/tiktokConstantProvider';
import TWITTER_CONSTANT_PROVIDER from 'common/constantProviders/twitterConstantProvider';

import type { APIType, APITypeId, ApiPropertySettings } from 'types';

export const API_STATES = {
  ACTIVE: 1,
  DELETED: 2,
  BAD_AUTH: 3,
  QUOTA_HIT: 4,
  UNDER_SETUP: 5,
} as const;

export const API_TYPE_IDS = {
  GA_CORE: 1,
  FACEBOOK: 2,
  TWITTER: 3,
  GA_REALTIME: 4,
  BITLY: 5,
  GOOGLEPLUS: 6,
  HASHTAGIFY: 7,
  DIFFBOT: 8,
  TEXTRAZOR: 9,
  OPHAN: 10,
  LINKEDIN: 14,
  INSTAGRAM: 13,
  TIKTOK: 16,
  TWITTER_APP: 17,
} as const;

export const API_PROPERTIES: Record<APITypeId, ApiPropertySettings> = {
  [API_TYPE_IDS.FACEBOOK]: FACEBOOK_CONSTANT_PROVIDER,
  [API_TYPE_IDS.INSTAGRAM]: INSTAGRAM_CONSTANT_PROVIDER,
  [API_TYPE_IDS.LINKEDIN]: LINKEDIN_CONSTANT_PROVIDER,
  [API_TYPE_IDS.TIKTOK]: TIKTOK_CONSTANT_PROVIDER,
  [API_TYPE_IDS.TWITTER]: TWITTER_CONSTANT_PROVIDER,
  [API_TYPE_IDS.GA_CORE]: {
    apiType: 'GA_CORE',
    name: 'Google Analytics',
  },
  [API_TYPE_IDS.GA_REALTIME]: {
    apiType: 'GA_REALTIME',
    name: 'Google Analytics',
  },
  [API_TYPE_IDS.BITLY]: {
    urnName: 'bl',
    apiType: 'BITLY',
    name: 'Bitly',
    isShortenAPI: true,
  },
  [API_TYPE_IDS.GOOGLEPLUS]: {
    apiType: 'GOOGLEPLUS',
    name: 'Google Plus',
  },
  [API_TYPE_IDS.HASHTAGIFY]: {
    apiType: 'HASHTAGIFY',
    name: 'Hashtagify',
  },
  [API_TYPE_IDS.DIFFBOT]: {
    apiType: 'DIFFBOT',
    name: 'Diffbot',
  },
  [API_TYPE_IDS.TEXTRAZOR]: {
    apiType: 'TEXTRAZOR',
    name: 'TextRazor',
  },
  [API_TYPE_IDS.OPHAN]: {
    apiType: 'OPHAN',
    name: 'Ophan',
  },
  [API_TYPE_IDS.TWITTER_APP]: {
    apiType: 'TWITTER_APP',
    name: 'Twitter App',
  },
} as const;

export const API_TYPES: Array<APIType> = Object.values(API_PROPERTIES).map(
  (properties) => properties.apiType,
);

export const ONBOARDING_FEED_SUPPORTED_SOCIAL_PAGES = [
  API_TYPE_IDS.FACEBOOK,
  API_TYPE_IDS.TWITTER,
  API_TYPE_IDS.INSTAGRAM,
  API_TYPE_IDS.LINKEDIN,
];

export const AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES = [
  API_TYPE_IDS.FACEBOOK,
  API_TYPE_IDS.TWITTER,
  API_TYPE_IDS.LINKEDIN,
];
