import postUploadImage from 'api/postUploadImage';
import { getAPITypeId, getCurrentSocialPageURN } from 'common/accountAPIs';
import { API_PROPERTIES } from 'common/constants';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as s3 from 'common/s3';
import { addCacheBuster } from 'common/url';
import validators from 'common/validators';
import { getCroppedImage } from 'helpers/images';
import type { APITypeId, FixTypeLater, PostType, SocialChannel } from 'types';

function cropRequiredMediaItemImages({
  guid,
  mediaItem,
}: {
  guid: string;
  mediaItem: FixTypeLater;
}) {
  const apiTypeId = getAPITypeId({
    accountAPIId: MediaItem.getAccountAPIId({ mediaItem }),
  });
  const postType = MediaItem.getPostType({ mediaItem });
  const postImageNeedsToBeUpdated = MediaItem.getPostImageNeedsToBeUpdated({
    mediaItem,
  });
  const imageURLs = MediaItem.getImageURLs({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });

  const promises: Promise<{
    guid: string;
    index: number;
    updatedImageURL: string;
    postImageNeedsToBeUpdated: boolean;
  } | null>[] = [];

  Object.keys(postImageNeedsToBeUpdated).forEach((i) => {
    const index = Number(i);
    if (postImageNeedsToBeUpdated[index]) {
      const imageSrc = imageURLs[index];
      promises.push(
        Promise.resolve()
          .then(() => {
            // Upload the original image to S3 for CORS issues and tainted canvases
            if (s3.isEbxImage(imageSrc)) {
              return imageSrc;
            }
            return postUploadImage({
              socialPageURN: getCurrentSocialPageURN(),
              imageURL: imageSrc,
            });
          })
          .then((ebxImageSrc) => {
            // Crop the image onto a canvas
            return cropImageToCanvas({
              apiTypeId,
              postType,
              imageSrc: s3.convertEchoboxImageToAmazon(ebxImageSrc),
              socialChannel,
            });
          })
          .then((canvas) => {
            // Save the cropped image to a file
            return getCroppedImage({
              croppedCanvas: canvas,
              fileName: 'resize.jpeg',
            });
          })
          .then((file) => {
            // Upload the final cropped image to S3
            return postUploadImage({
              socialPageURN: getCurrentSocialPageURN(),
              imageFile: file,
            });
          })
          .then((ebxImageSrc) => {
            return {
              guid,
              index,
              updatedImageURL: ebxImageSrc,
              postImageNeedsToBeUpdated: false,
            };
          })
          .catch((error) => {
            const handledError = determineError(error);
            if (getErrorStatus(error) === null) {
              logger.error({
                event: 'Login Error',
                properties: {
                  location: 'helpers/imageCropper',
                  arguments: {
                    guid,
                    mediaItem,
                  },
                },
                error: handledError,
              });
            }
            return null;
          }),
      );
    }
  });

  return promises;
}

function cropImageToCanvas({
  apiTypeId,
  postType,
  imageSrc,
  socialChannel,
}: {
  apiTypeId: APITypeId;
  postType: PostType;
  imageSrc: string;
  socialChannel?: SocialChannel;
}) {
  return new Promise<HTMLCanvasElement>((resolve) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      const { height, width } = getCropHeightWidth({
        naturalHeight: image.height,
        naturalWidth: image.width,
        postType,
        apiTypeId,
        socialChannel,
      });

      // Check scale
      const xscale = width / image.width;
      const yscale = height / image.height;
      const scale = Math.max(xscale, yscale);
      // Create empty canvas
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.scale(scale, scale);
      // Crop it centre
      canvas
        .getContext('2d')
        ?.drawImage(
          image,
          (width - image.width) / 2,
          (height - image.height) / 2,
        );

      resolve(canvas);
    };
    const ebxImage = s3.isEbxImage(imageSrc)
      ? addCacheBuster({ originalURL: imageSrc })
      : imageSrc;
    image.src = ebxImage;
  });
}

function getCropHeightWidth({
  naturalHeight,
  naturalWidth,
  postType,
  apiTypeId,
  socialChannel,
}: {
  naturalHeight: number;
  naturalWidth: number;
  postType: PostType;
  apiTypeId: APITypeId;
  socialChannel?: SocialChannel;
}) {
  let height = naturalHeight;
  let width = naturalWidth;

  let defaultAspectRatioValue = null;

  const getDefaultImageAspectRatios =
    validators[apiTypeId]?.getDefaultImageAspectRatios;
  if (getDefaultImageAspectRatios) {
    defaultAspectRatioValue = getDefaultImageAspectRatios({
      postType,
      socialChannel,
    }).default;
  } else {
    const apiProperties = API_PROPERTIES[apiTypeId];

    const defaultAspectRatios =
      'imageAspectRatioDefaults' in apiProperties
        ? apiProperties.imageAspectRatioDefaults
        : undefined;

    const defaultAspectRatio =
      defaultAspectRatios?.[postType as keyof typeof defaultAspectRatios];

    if (defaultAspectRatio) {
      defaultAspectRatioValue = defaultAspectRatio.default;
    }
  }

  if (defaultAspectRatioValue != null) {
    const currentAspectRatio = naturalWidth / naturalHeight;

    // If the current aspect ratio is greater than the default aspect ratio,
    // then the width needs to be reduced, otherwise the height needs to be
    // reduced. This should prevent empty space being added to the image.
    if (currentAspectRatio > defaultAspectRatioValue) {
      width = naturalHeight * defaultAspectRatioValue;
    } else if (currentAspectRatio < defaultAspectRatioValue) {
      height = naturalWidth / defaultAspectRatioValue;
    }
  }
  return { height, width };
}

export { cropRequiredMediaItemImages };
