import {
  Alert,
  Box,
  ChartUpIcon,
  Divider,
  Heading,
  HStack,
  Link,
  LockIcon,
  RocketIcon,
  StackProps,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import TrackerInstallationGuide from 'components/misc/TrackerInstallationGuide';

const DISPLAY_LIST = [
  {
    icon: ChartUpIcon,
    heading: 'Built for performance',
    description:
      'Your website traffic will be analyzed in real-time in order to optimize your social media curation.',
  },
  {
    icon: LockIcon,
    heading: 'Designed with privacy in mind',
    description: 'Fully GDPR compliant and no personal information tracked.',
  },
  {
    icon: RocketIcon,
    heading: 'Engineered for stability and speed',
    description:
      "This tiny JavaScript snippet loads asynchronously and doesn't impact your website's load times.",
  },
];

export interface InstallWebsiteTagProps extends StackProps {
  isError?: boolean;
  rootUrl?: string;
}

const InstallWebsiteTag = ({
  isError = false,
  rootUrl = '',
  ...rest
}: InstallWebsiteTagProps) => {
  let url;
  try {
    url = new URL(rootUrl);
  } catch (err) {
    url = { host: '', href: '' };
  }
  const { host, href } = url;
  return (
    <VStack spacing={6} align="baseline" maxW="776px" {...rest}>
      {isError && (
        <Alert variant="error">
          <Alert.Title>
            Echobox isn&apos;t receiving data{' '}
            {host && (
              <>
                from{' '}
                <Link color="red.600" href={href} isExternal fontSize="inherit">
                  {host}
                </Link>
              </>
            )}
          </Alert.Title>
          <Alert.Description>
            Please make sure the web tag is installed correctly. If you&apos;re
            using a tag manager, please{' '}
            <Link href="mailto: support@echobox.com" fontWeight="normal">
              contact us
            </Link>
            .
          </Alert.Description>
        </Alert>
      )}
      <Box>
        <Heading variant="h2" mb={2}>
          Install Web Tag
        </Heading>
        <Text size="sm" color="gray.600">
          For Echobox to maximize your content’s performance, a small code
          snippet must be installed on your website.{' '}
          <Link
            href="https://www.echobox.com/resources/blog/introducing-the-echobox-web-tag/"
            target="_blank"
            fontWeight="normal"
          >
            More information here
          </Link>
          .
        </Text>
      </Box>
      <VStack spacing={6} align="flex-start">
        {DISPLAY_LIST.map((item) => (
          <HStack spacing={3} align="flex-start" key={item.heading}>
            <item.icon size="5" color="gray.600" />
            <Box>
              <Heading variant="h4" mb={1}>
                {item.heading}
              </Heading>
              <Text size="sm" color="gray.600">
                {item.description}
              </Text>
            </Box>
          </HStack>
        ))}
      </VStack>
      <Divider color="gray.300" />
      <TrackerInstallationGuide />
    </VStack>
  );
};

export default InstallWebsiteTag;
