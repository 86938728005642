/* eslint no-useless-constructor: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import { useContext, useEffect } from 'react';

import { isImpersonating } from 'common/authentication';
import { USER_TYPES } from 'common/constants';
import * as tracker from 'common/tracker';
import FlashMessages from 'components/misc/FlashMessages';
import ChangePassword from 'components/settings/user/ChangePassword';
import MFA from 'components/settings/user/MFA';
import UserEmail from 'components/settings/user/UserEmail';
import UserName from 'components/settings/user/UserName';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { scrollToTop } from 'helpers/window';

/*
 * Share page
 */

const User = () => {
  const context = useContext(GlobalInfoContext);
  const globalInfo = context.global.getGlobalInfo();
  const isStaffUser = globalInfo.user.userType === USER_TYPES.ECHOBOX_STAFF;

  /**
   * Lifecycle methods
   */

  useEffect(() => {
    scrollToTop();

    tracker.track({ eventName: 'Settings Page' });
  }, []);

  /**
   * Render method
   */

  return (
    <>
      <FlashMessages />
      <div className="setting_content">
        <UserName />
        {!isStaffUser && <UserEmail />}
        {isStaffUser && !isImpersonating() ? (
          <MFA hasSetupMFA={globalInfo.user.hasSetupMFA} />
        ) : (
          <ChangePassword />
        )}
      </div>
    </>
  );
};

export default User;
