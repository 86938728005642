import PropTypes from 'prop-types';

const Loading = (props) => {
  const { mediaId } = props;

  return (
    <div
      className="share_item"
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <div
        className="animated-background load_last_shared d-none d-md-block"
        data-cy-attribute="isLoading:true"
      >
        <div className="background-masker load_last_shared_1">&nbsp;</div>
        <div className="background-masker load_last_shared_2">&nbsp;</div>
        <div className="background-masker load_last_shared_3">&nbsp;</div>
        <div className="background-masker load_last_shared_4">&nbsp;</div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  mediaId: PropTypes.string.isRequired,
};

export default Loading;
