import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  LINK_TIME_BEHAVIOUR_TYPES,
} from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import { getFeatureToggle, getSetting } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import {
  extractURLElements,
  isNestedShare,
  objectToQueryString,
  queryStringToObject,
} from 'common/url';
import {
  cloneObject,
  isDefined,
  isNull,
  isNullOrUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';

export { addLinkTime, addURLParameters };

/**
 * addLinkTime
 */
function addLinkTime({
  propertyId = mandatory('propertyId'),
  queryParams = mandatory('queryParams'),
  fragmentParams = mandatory('fragmentParams'),
  mediaItemIndex = 0,
  disableIgEbxTime = false,
  apiTypeId = null,
} = {}) {
  // Create copies of the incoming parameters to avoid mutating the originals
  const queryParamsUpdated = cloneObject(queryParams);
  const fragmentParamsUpdated = cloneObject(fragmentParams);

  if (
    disableIgEbxTime &&
    isDefined(apiTypeId) &&
    apiTypeId === API_TYPE_IDS.INSTAGRAM
  ) {
    return { queryParamsUpdated, fragmentParamsUpdated };
  }

  // Convert old link_time parameter to Echobox
  if (isDefined(queryParamsUpdated.link_time)) {
    queryParamsUpdated.Echobox = queryParamsUpdated.link_time;
    delete queryParamsUpdated.link_time;
  }
  if (isDefined(fragmentParamsUpdated.link_time)) {
    fragmentParamsUpdated.Echobox = fragmentParamsUpdated.link_time;
    delete fragmentParamsUpdated.link_time;
  }

  const hasNotLinkTimeAsParameter = isNullOrUndefined(
    queryParamsUpdated.Echobox,
  );
  const hasLinkTimeAsParameter = isDefined(queryParamsUpdated.Echobox);
  const hasNotLinkTimeAsFragment = isNullOrUndefined(
    fragmentParamsUpdated.Echobox,
  );

  // By default, add the link time as a fragment parameter, unless there are
  // already fragment parameters, in which case add it as a query parameter
  let linkTimeLocation =
    hasLinkTimeAsParameter ||
    (Object.keys(fragmentParams).length > 0 && hasNotLinkTimeAsFragment)
      ? LINK_TIME_BEHAVIOUR_TYPES.QUERY_PARAM
      : LINK_TIME_BEHAVIOUR_TYPES.FRAGMENT_PARAM;

  // Override this default if a LINK_TIME_BEHAVIOUR settings is present
  const linkTimeBehaviour = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.LINK_TIME_BEHAVIOUR,
    propertyId,
  });

  const hasNotLinkTime = hasNotLinkTimeAsFragment && hasNotLinkTimeAsParameter;
  if (
    linkTimeBehaviour.enabled &&
    isDefined(linkTimeBehaviour.dataJSON) &&
    hasNotLinkTime
  ) {
    linkTimeLocation = linkTimeBehaviour.dataJSON.value;
  }

  // Create the link time parameter
  let linkTime = getUnixTimestamp();
  if (mediaItemIndex) {
    linkTime += `-${mediaItemIndex}`;
  }

  // Append the link time parameter to the relevant part of the query string
  if (
    linkTimeLocation.toString() ===
    LINK_TIME_BEHAVIOUR_TYPES.QUERY_PARAM.toString()
  ) {
    queryParamsUpdated.Echobox = linkTime;
  } else {
    fragmentParamsUpdated.Echobox = linkTime;
  }

  return { queryParamsUpdated, fragmentParamsUpdated };
}

function addURLParameters(
  pageURL,
  accountAPIId,
  isNewURL = false,
  asPromise = true,
  isCopyingURL = false,
  mediaItemIndex = 0,
  overrideURLParameters = false,
) {
  if (asPromise) {
    return new Promise((resolve) => {
      if (pageURL === '' || isNull(pageURL) || isNestedShare(pageURL)) {
        resolve(pageURL);
      }

      resolve(
        addURLParametersInternal(
          pageURL,
          accountAPIId,
          isNewURL,
          isCopyingURL,
          mediaItemIndex,
          overrideURLParameters,
        ),
      );
    });
  }

  if (isNestedShare(pageURL)) {
    return pageURL;
  }
  return addURLParametersInternal(
    pageURL,
    accountAPIId,
    isNewURL,
    isCopyingURL,
    mediaItemIndex,
    overrideURLParameters,
  );
}

/**
 * addURLParametersInternal
 */
function addURLParametersInternal(
  pageURL,
  accountAPIId,
  isNewURL = false,
  isCopyingURL = false,
  mediaItemIndex = 0,
  overrideURLParameters = false,
) {
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });

  // Get default URL parameters settings
  const urlParams = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS,
    propertyId,
    accountAPIId,
  }).dataJSON;

  // ------------------------------------------
  //
  // Get existing query and fragment parameters
  //
  // ------------------------------------------

  const [url, query, fragment] = extractURLElements(pageURL);

  let updatedURL = url;
  let queryParams = queryStringToObject(query);
  let fragmentParams = queryStringToObject(fragment);

  // ------------------------------------------
  //
  // Build up new query and fragment fields
  //
  // ------------------------------------------

  // Update query parameters from settings object
  if (isDefined(urlParams.queryParams)) {
    queryParams = processURLParams(
      queryParams,
      urlParams.queryParams,
      accountAPIId,
      isNewURL,
      isCopyingURL,
      overrideURLParameters,
    );
  }

  // Update fragment parameters from settings object
  if (isDefined(urlParams.fragmentParams)) {
    fragmentParams = processURLParams(
      fragmentParams,
      urlParams.fragmentParams,
      accountAPIId,
      isNewURL,
      isCopyingURL,
      overrideURLParameters,
    );
  }

  const disableIgEbxTimeFeature = getFeatureToggle({
    featureName: 'disable_ig_echobox_time',
    propertyId,
  });
  const disableIgEbxTime =
    isDefined(disableIgEbxTimeFeature) && disableIgEbxTimeFeature;
  const apiTypeId = getAPITypeId({ accountAPIId });

  // Add link time
  const { queryParamsUpdated, fragmentParamsUpdated } = addLinkTime({
    propertyId,
    queryParams,
    fragmentParams,
    mediaItemIndex,
    disableIgEbxTime,
    apiTypeId,
  });

  // Update the full URL using the final fragment and query parameters
  updatedURL += objectToQueryString(queryParamsUpdated, '?');
  updatedURL += objectToQueryString(fragmentParamsUpdated, '#');

  return updatedURL;
}

/**
 * processURLParams
 */
function processURLParams(
  paramObject,
  settingsArray,
  accountAPIId,
  isNewURL,
  isCopyingURL,
  overrideURLParameters = false,
) {
  let networkName = '';
  const updatedParams = paramObject;

  // Get network name if required
  if (!isNullOrUndefined(accountAPIId)) {
    networkName = getSocialNetworkName({
      apiTypeId: getAPITypeId({ accountAPIId }),
    });
  }

  // Sort settings alphabetically
  settingsArray.sort((a, b) => a.key > b.key);
  // Loop through all settings
  settingsArray.forEach((entry) => {
    switch (entry.editMode) {
      case 'NONE':
        // Overwrite existing parameter with value from settings object
        if (entry.value === '[APITYPE]' && networkName !== '') {
          updatedParams[entry.key] = networkName;
        } else if (entry.value !== '[APITYPE]') {
          updatedParams[entry.key] = entry.value;
        }

        break;
      case 'VALUEONLY':
        // Use value from settings object if one does not already exist
        if (
          !isDefined(paramObject[entry.key]) ||
          paramObject[entry.key] === ''
        ) {
          if (entry.value === '[APITYPE]' && networkName !== '') {
            updatedParams[entry.key] = networkName;
          } else if (entry.value !== '[APITYPE]') {
            updatedParams[entry.key] = entry.value;
          }
        }

        break;
      case 'FULL':
        // Use the parameter value from the settings object if
        // (a) we are copying an unedited URL from one account API to another
        // (b) we are processing an unedited URL and the parameter does not already exist
        // (c) overrideURLParameters is true
        if (
          (isCopyingURL && isNewURL) ||
          (isNewURL && !isDefined(paramObject[entry.key])) ||
          overrideURLParameters
        ) {
          if (entry.value === '[APITYPE]' && networkName !== '') {
            updatedParams[entry.key] = networkName;
          } else if (entry.value !== '[APITYPE]') {
            updatedParams[entry.key] = entry.value;
          }
        }

        break;
      default:
    }
  });

  return updatedParams;
}
