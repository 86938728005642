import {
  BellNotificationIcon,
  Header,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Tooltip from 'components/misc/Tooltip';

const BeamerNotification = () => {
  return (
    <Tooltip id="beamer" label="What&#39;s new in Echobox">
      <Header.IconButton
        aria-label="What's new"
        id="beamerButton"
        data-tip
        data-for="beamer"
        data-iscapture="true"
        icon={<BellNotificationIcon />}
      />
    </Tooltip>
  );
};

export default BeamerNotification;
