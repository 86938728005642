import {
  ChevronButton,
  Dropdown,
  DropdownProps,
  Flex,
} from '@ebx-ui/ebx-ui-component-library-sdk';

type FilterType = {
  value: string;
  label: string;
  icon?: React.ReactNode;
};

interface FilterProps extends Omit<DropdownProps, 'children'> {
  data: FilterType[];
  value: string;
  onChange: (val: string) => void;
  title: string;
  isDisabled?: boolean;
  prefix?: string;
}

const Filter = ({
  data,
  value,
  onChange,
  prefix,
  title,
  isDisabled,
  ...rest
}: FilterProps) => {
  const selectedItem = data.find((item: FilterType) => item.value === value);
  return (
    <Dropdown {...rest}>
      <Dropdown.Button
        isDisabled={isDisabled}
        as={ChevronButton}
        variant="chill"
        w="fit-content"
      >
        {prefix} {selectedItem?.label ?? data[0]?.label}
      </Dropdown.Button>
      <Dropdown.List>
        <Dropdown.Title>{title}</Dropdown.Title>
        {data.map((option) => (
          <Dropdown.Item
            key={option.value}
            onClick={() => onChange(option.value)}
            isSelected={value === option.value}
          >
            <Flex gap="2" alignItems="center">
              {option.icon ? option.icon : null}
              {option.label}
            </Flex>
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
};

export default Filter;
