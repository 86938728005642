/* eslint react-hooks/exhaustive-deps: "off" */

import {
  Box,
  Header,
  useBreakpointValue,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import $ from 'jquery';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import * as topics from 'pubsub/topics';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import getMediaItems from 'api/getMediaItems';
import getMediaSearch from 'api/getMediaSearch';
import {
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentSocialPageURN,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import { MAX_SEARCH_ITEMS_IN_BATCH } from 'common/config';
import {
  COLLECTION_FIELDS,
  COLLECTION_NAMES,
  DEBOUNCE_INPUT_DELAY,
  KEYNAMES,
  MEDIA_ITEM_STATES,
  SEARCH_STATES,
  SHARE_ORIGINS,
} from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { addErrorNotification } from 'common/notifications';
import * as tracker from 'common/tracker';
import HeaderButton from 'components/header/HeaderButton';
import Results from 'components/header/Results';
import { getOrigin, getShareOriginFromPathname } from 'helpers/tracking';
import useDebounce from 'hooks/useDebounce';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useOnKey from 'hooks/useOnKey';
import SearchIcon from 'svg/Search';

const { COMMAND_SET_SAVING_STATE } = topics;

const SEARCH_ORIGINS = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
};

const Search = ({ isOpen, onClose, onOpen }) => {
  /**
   * Initial state
   */

  const [searchResults, setSearchResults] = useState({
    mediaIds: [],
    mediaItems: [],
  });
  const [searchState, setSearchState] = useState(SEARCH_STATES.NONE);
  const [searchText, setSearchText] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const tokens = useRef();
  const inputRef = useRef();
  const location = useLocation();

  const debouncedText = useDebounce(searchText, DEBOUNCE_INPUT_DELAY);
  const showSearchInput = !isMobile || isOpen;

  /**
   * Lifecycle methods
   */

  // Attach/detach mouse click handlers
  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  // Listen for compose box save/schedule button
  useEffect(() => {
    logger.info(
      `PubSub: subscribe ${COMMAND_SET_SAVING_STATE} in components/header/Search.useEffect`,
    );
    tokens.current = [PubSub.subscribe(COMMAND_SET_SAVING_STATE, handleClose)];
    return () => {
      logger.info(
        `PubSub: unsubscribe ${COMMAND_SET_SAVING_STATE} in components/header/Search.useEffect`,
      );
      tokens.current.forEach((token) => {
        PubSub.unsubscribe(token);
      });
    };
  }, []);

  // Initiate search if user presses ENTER
  useOnKey({
    targetKey: KEYNAMES.ENTER,
    onKeyUp: () => handleSearch(SEARCH_ORIGINS.MANUAL),
  });

  // Close search bar if user presses ESCAPE
  useOnKey({
    targetKey: KEYNAMES.ESCAPE,
    onKeyUp: () => handleClose(),
  });

  // Debounced search
  useEffect(() => {
    const debounceSearch = async () => {
      await handleSearch(SEARCH_ORIGINS.AUTO);
      if (isOpen) inputRef.current.focus();
    };
    debounceSearch();
  }, [debouncedText]);

  useEffect(() => {
    if (isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  // Scroll
  const handleScroll = async () => {
    if (searchResults.mediaItems.length < searchResults.mediaIds.length) {
      try {
        const mediaIds = searchResults.mediaIds.slice(
          searchResults.mediaItems.length,
          searchResults.mediaItems.length + MAX_SEARCH_ITEMS_IN_BATCH,
        );
        await loadMediaItems(mediaIds);
        setSearchState(SEARCH_STATES.SUCCEEDED);
        setIsLoadingMore(false);
      } catch (error) {
        setSearchState(SEARCH_STATES.FAILED);
        setIsLoadingMore(false);
        showError(error);
      }
    }
  };

  const [isLoadingMore, setIsLoadingMore] = useInfiniteScroll({
    id: 'search-result-articles',
    isLoading: searchState === SEARCH_STATES.SEARCHING,
    moreItemsExist:
      searchResults.mediaItems.length < searchResults.mediaIds.length,
    callback: handleScroll,
  });

  /**
   * Event handlers
   */

  const handleArticleShare = (mediaItem, rank) => {
    const itemOrigin = getShareOriginFromPathname(location.pathname);
    Compose.editPost({
      editItem: mediaItem,
      itemOrigin: SHARE_ORIGINS.SEARCH,
    });
    tracker.track({
      eventName: 'Select Search Result',
      trackingParams: {
        Origin: itemOrigin,
        'Search Terms': searchText,
        'Number of Results': searchResults.mediaIds.length,
        'Rank of Selected Result': rank,
      },
    });
    handleClose();
  };

  const handleClose = () => {
    setSearchResults({ mediaIds: [], mediaItems: [] });
    setSearchState(SEARCH_STATES.NONE);
    setSearchText('');
    onClose();
  };

  const handleMouseDown = (event) => {
    if (typeof $ !== 'undefined') {
      const target = $(event.target);
      const isBarClicked = target.closest('.nav-search.dropdown').length === 1;
      const isResultsClicked =
        target.closest('.dropdown-menu.search-results').length === 1;
      const isComposeOpen = $('[data-cy-id="composeBox"]').length === 1;
      // Close search bar if user clicks outside it
      if (!isBarClicked && !isResultsClicked && !isComposeOpen) {
        handleClose();
      }
    }
  };

  const handleSearch = async (searchOrigin) => {
    if (
      searchText.trim().length < 2 ||
      searchState === SEARCH_STATES.SEARCHING ||
      (searchOrigin === SEARCH_ORIGINS.DEBOUNCE && searchText === lastSearch)
    ) {
      return;
    }
    logger.info(`Search:handleSearch ${searchText}`);
    setLastSearch(searchText);
    // Track event
    tracker.track({
      eventName: 'Search Text',
      trackingParams: {
        Origin: getOrigin(window.location.pathname),
        'Search Terms': searchText,
      },
    });
    // Perform search
    setSearchState(SEARCH_STATES.SEARCHING);
    const socialPageURN = getCurrentSocialPageURN();
    try {
      const results = await getMediaSearch({
        socialPageURN,
        searchText: searchText.trim(),
      });
      setSearchResults({ mediaIds: results.mediaIds, mediaItems: [] });
      if (results.mediaIds.length > 0) {
        await loadMediaItems(
          results.mediaIds.slice(0, MAX_SEARCH_ITEMS_IN_BATCH),
        );
      }
      setSearchState(SEARCH_STATES.SUCCEEDED);
    } catch (error) {
      setSearchState(SEARCH_STATES.FAILED);
      showError(error);
    }
  };

  const handleTextChange = (updatedText) => {
    setSearchText(updatedText);
    if (updatedText === '' || searchState !== SEARCH_STATES.NONE) {
      setSearchState(SEARCH_STATES.NONE);
    }
  };

  /**
   * Helper methods
   */

  const loadMediaItems = async (mediaIds) => {
    const accountAPIId = getCurrentAccountAPIId();
    const apiTypeId = getAPITypeId({ accountAPIId });
    try {
      const items = await getMediaItems({
        accountAPIId,
        apiTypeId,
        state: MEDIA_ITEM_STATES.NEW,
        mediaIds,
        fields: COLLECTION_FIELDS[COLLECTION_NAMES.ARTICLE_SEARCH],
      });
      const mediaItems = [];
      Object.keys(items).forEach((mediaId) => {
        mediaItems.push(MediaItem.initialise(items[mediaId]));
      });
      setSearchResults((prev) => ({
        ...prev,
        mediaItems: prev.mediaItems.concat(mediaItems),
      }));
    } catch (error) {
      setSearchState(SEARCH_STATES.FAILED);
      showError(error);
    }
  };

  const showSearch = () => {
    onOpen();
  };

  const showError = (error) => {
    addErrorNotification(error);
  };

  /**
   * Render method
   */

  return (
    <>
      <HeaderButton
        aria-label="open search"
        onClick={showSearch}
        display={showSearchInput ? 'none' : 'inline-block'}
        lineHeight={0}
      >
        <SearchIcon />
      </HeaderButton>
      <Box
        className={`nav-search dropdown form-control-with-spinner ${
          !showSearchInput ? 'd-none' : ''
        }`}
        maxW="420px"
        flexGrow={1}
        data-boundary="viewport"
      >
        <Header.Search
          searchText={searchText}
          isSearching={searchState === SEARCH_STATES.SEARCHING || isLoadingMore}
          onChange={handleTextChange}
          onCancel={handleClose}
          isDisabled={searchState === SEARCH_STATES.SEARCHING}
          onFocus={onOpen}
          onBlur={onClose}
          ref={inputRef}
          placeholder="Search"
          _placeholder={{ fontSize: 'sm' }}
        />
        {searchState !== SEARCH_STATES.NONE &&
          searchState !== SEARCH_STATES.SEARCHING && (
            <Results
              isLoadingMore={isLoadingMore}
              searchResults={searchResults}
              searchState={searchState}
              searchText={searchText}
              eventHandlers={{ handleArticleShare }}
            />
          )}
      </Box>
    </>
  );
};

Search.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
};

export default Search;
