// Constants used by others need to be defined first

export const AUTOFEED_SHARE_MODES = {
  OPTIMAL: 'OPTIMAL',
  CUSTOM: 'CUSTOM',
  OFF: 'OFF',
} as const;

export const DATA_SOURCE_DEFAULTS = {
  MESSAGE_SOURCE: 4, // OG_DESCRIPTION
  TITLE_SOURCE: 3, // OG_TITLE
  DESCRIPTION_SOURCE: 7, // NULL
  IMAGE_SOURCE: 1, // OG_IMAGE
} as const;

export const ENTITY_TYPES = Object.freeze({
  LINK: 0,
  INLINE_TAG: 1,
  EMOTICON: 2,
  SHARE_URL: 3,
});

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const FRONTEND_PAGES = {
  ANALYTICS: 'ANALYTICS',
  ARCHIVE: 'ARCHIVE',
  AUTOFEED: 'AUTOFEED',
  CONNECT_ERROR: 'CONNECT_ERROR',
  COMPETITORS: 'COMPETITORS',
  LOGIN: 'LOGIN',
  SELECT: 'SELECT',
  SETTINGS: 'SETTINGS',
  SETUP: 'SETUP',
  SHARE: 'SHARE',
  SHARE_DESKTOP: 'SHARE_DESKTOP',
  SHARE_MOBILE: 'SHARE_MOBILE',
  MOBILE_QUEUED: 'MOBILE_QUEUED',
  MOBILE_APPROVAL: 'MOBILE_APPROVAL',
  MOBILE_SHARED: 'MOBILE_SHARED',
  SETTINGS_PROPERTY: 'SETTINGS_PROPERTY',
  SETTINGS_PAGES: 'SETTINGS_PAGES',
  SETTINGS_PAGE: 'SETTINGS_PAGE',
  SETTINGS_USERS: 'SETTINGS_USERS',
  SETTINGS_USER: 'SETTINGS_USER',
  NEWSLETTERS_AD: 'NEWSLETTERSAD',
};

export const IMAGE_TYPES = {
  GIF: 'GIF',
  OTHER: 'OTHER',
  VIDEO: 'VIDEO',
};

export const POST_TYPES = Object.freeze({
  UNKNOWN: 'UNKNOWN',
  LINK: 'LINK',
  STATUS: 'STATUS',
  VIDEO: 'VIDEO',
  PHOTO_STORY: 'PHOTO_STORY',
});

export const SOCIAL_CHANNELS = Object.freeze({
  STORY: 'STORY',
  FEED: 'FEED',
  REEL: 'REEL',
});

export const TRACKING_SOCIAL_CHANNELS = {
  STORY: 'Story',
  FEED: 'Feed',
  REEL: 'Reel',
};

export const SPECIAL_CHARACTER_REGEX =
  '[\\w\\u00C0-\\u00D6\\u00D8-\\u00F6\\u00F8-\\u1EF3_]+';
export const SPECIAL_CHARACTER_REGEX_PARTS =
  /([\w\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u1EF3_]+)(.*)/;

export const TAG_TYPES = Object.freeze({
  HASHTAG: 1,
  MENTION: 2,
});

export const TEXT_CONTENT_AVAILABLE_STATES = Object.freeze({
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  NOT_ATTEMPTED: 'NOT_ATTEMPTED',
  FAILED_RETRY_ALLOWED: 'FAILED_RETRY_ALLOWED',
  FAILED_NO_RETRY_ALLOWED: 'FAILED_NO_RETRY_ALLOWED',
  AVAILABLE: 'AVAILABLE',
});

// All other constants can be listed in alphabetical order

export const AB_TEST_STATUSES = Object.freeze({
  UNTESTED: 1,
  TESTING: 2,
  COMPLETED: 3,
  ERROR: 4,
});

export const ACCOUNT_SETTING_TYPES = {
  TWELVE_HOUR_TIME_FORMAT: 2,
  CUSTOM_AUTO_SHARE_INTERVAL: 3,
  ERROR_EMAILS: 4,
  AUTO_FEED: 5,
  FACEBOOK_UNSHORTENED_SHARING: 7,
  OG_IMAGE_FALLBACK: 8,
  FEATURE_TOGGLES: 10,
  ACCOUNT_API_RSS_FEED_PREFERENCES: 11,
  SHARE_DATA_SOURCES: 12,
  STALE_SHARE_DATA_CHECK: 13,
  DEFAULT_FACEBOOK_TARGETING: 14,
  TWITTER_IMAGE_FALLBACK: 15,
  TWITTER_UNSHORTENED_SHARING: 16,
  AUTO_PILOT_TOGGLE: 17,
  DEFAULT_URL_PARAMS: 18,
  LINK_TIME_BEHAVIOUR: 19,
  LIVE_STORY_DEFAULT: 20,
  ALLOW_CONTENT_FROM_ALL_DOMAINS: 21,
  LINKEDIN_UNSHORTENED_SHARING: 22,
  DEFAULT_LINKEDIN_TARGETING: 23,
  INSTAGRAM_UNSHORTENED_SHARING: 24,
  DOMAIN_SWITCHING: 25,
  IMAGE_OVERLAY: 26,
  PERFORMANCE_GOAL: 27,
} as const;

export const ACCOUNT_SETTING_TYPES_NAMES = {
  2: 'TWELVE_HOUR_TIME_FORMAT',
  3: 'CUSTOM_AUTO_SHARE_INTERVAL',
  4: 'ERROR_EMAILS',
  5: 'AUTO_FEED',
  7: 'FACEBOOK_UNSHORTENED_SHARING',
  8: 'OG_IMAGE_FALLBACK',
  10: 'FEATURE_TOGGLES',
  11: 'ACCOUNT_API_RSS_FEED_PREFERENCES',
  12: 'SHARE_DATA_SOURCES',
  13: 'STALE_SHARE_DATA_CHECK',
  14: 'DEFAULT_FACEBOOK_TARGETING',
  15: 'TWITTER_IMAGE_FALLBACK',
  16: 'TWITTER_UNSHORTENED_SHARING',
  17: 'AUTO_PILOT_TOGGLE',
  18: 'DEFAULT_URL_PARAMS',
  19: 'LINK_TIME_BEHAVIOUR',
  20: 'LIVE_STORY_DEFAULT',
  21: 'ALLOW_CONTENT_FROM_ALL_DOMAINS',
  22: 'LINKEDIN_UNSHORTENED_SHARING',
  23: 'DEFAULT_LINKEDIN_TARGETING',
  24: 'INSTAGRAM_UNSHORTENED_SHARING',
  25: 'DOMAIN_SWITCHING',
  26: 'IMAGE_OVERLAY',
  27: 'PERFORMANCE_GOAL',
} as const;

export const ACCOUNT_STATES = {
  NEWSIGNUP: 1,
  UNDERSETUP: 2,
  ACTIVE: 3,
  SUSPENDED: 4,
} as const;

export const PROPERTY_STATES = {
  NEWSIGNUP: 'NEWSIGNUP',
  UNDERSETUP: 'UNDERSETUP',
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
} as const;

export const ACTION_HISTORY_TYPES = {
  DIRECT_SHARE: 1,
  ECHOBOX_SHARE: 2,
} as const;

export const ACTION_TYPES = Object.freeze({
  DELETE: 'DELETE',
  SAVE: 'SAVE',
  SCHEDULE: 'SCHEDULE',
  UNSCHEDULE: 'UNSCHEDULE',
});

export const ADD_LINK_REASONS = {
  COPY_PREVIEW_FIELDS: 'COPY_PREVIEW_FIELDS',
  URL_CHANGE: 'URL_CHANGE',
  CHECK_BEFORE_SAVE: 'CHECK_BEFORE_SAVE',
  ENTER_COMPOSE: 'ENTER_COMPOSE',
  CHANGE_STATE: 'CHANGE_STATE',
};

export const API_ERROR_MESSAGES = {
  API_TIMEOUT:
    'Unfortunately, this action timed out. Please refresh the page and try again.',
  API_HTTP:
    'Unfortunately, this action could not be completed. Please retry or reload the page.',
  API_XHR:
    'Unfortunately, this action timed out. Please retry or reload the page.',
  API_UNKNOWN:
    'Something went wrong. Please try again and contact support if the problem persists.',
};

export const API_RESPONSE_IMPERSONATION_ERROR_MESSAGES = [
  'Impersonating users without staff user permissions can only call some Social API endpoints.',
  'Impersonating users without staff user permissions can only call Core API GET requests.',
];

export const API_ERROR_TYPES = {
  API_TIMEOUT: 'API_TIMEOUT',
  API_HTTP: 'API_HTTP',
  API_HTTP_COGNITO: 'API_HTTP_COGNITO',
  API_HTTP_EXTERNAL: 'API_HTTP_EXTERNAL',
  API_XHR: 'API_XHR',
};

export const COGNITO_ERROR_MESSAGES: Record<string, string> = {
  EnableSoftwareTokenMFAException: 'Invalid code. Please try again.',
  UserNotFoundException:
    'User not found, please check the email address is entered correctly.',
  CodeMismatchException:
    'The password reset code is not valid. Please request a new code and try again.',
  InternalServerErrorException: 'Something went wrong. Please try again.',
  NotAuthorizedException: 'Incorrect username or password.',
  INVALID_SESSION: 'No current user',
};

export const TRAFFIC_API_TYPES = {
  EBX_TRACKER: 'EBX_TRACKER',
  GA_CORE: 'GA_CORE',
} as const;

export const TRAFFIC_API_STATES = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
  BAD_AUTH: 'BAD_AUTH',
  QUOTA_HIT: 'QUOTA_HIT',
  UNDER_SETUP: 'UNDER_SETUP',
} as const;

export const COGNITO_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};

export const DEFAULT_USER_AGENTS = [
  '',
  'EchoboxBot',
  'FacebookExternalHit',
  'TwitterBot',
];

export const COLLECTION_NAMES = Object.freeze({
  ARTICLE_ARCHIVE: 'articleArchive',
  ARTICLE_FEED: 'articleFeed',
  ARTICLE_SEARCH: 'articleSearch',
  SCHEDULE_QUEUE: 'scheduleQueue',
  APPROVALS: 'approvals',
  LAST_SHARED: 'lastShared',
  COMPOSE: 'composeBox',
  ANALYTICS_PAGE: 'analyticsPage',
  DELETED_ITEMS: 'deletedItems',
  FAILED_SHARES: 'failedShares',
});

export const COLLECTION_FIELDS = {
  [COLLECTION_NAMES.ARTICLE_ARCHIVE]: [
    'imageURLs',
    'mediaId',
    'mediaURN',
    'mostRecentScore',
    'mostRecentUnixTimePublished',
    'mostRecentUnixTimeShared',
    'rssTitle',
    'state',
    'textContentAvailableState',
    'timeSensitivityTypeId',
    'totalNumberOfShares',
    'trackingDetails',
    'unshortenedShareURL',
  ],
  [COLLECTION_NAMES.ARTICLE_FEED]: [
    'imageOverlayURL',
    'imageURLs',
    'mediaId',
    'mediaURN',
    'mediaItemTags',
    'mediaStatesByAccountAPIId',
    'message',
    'mostRecentScore',
    'mostRecentUnixTimePublished',
    'mostRecentUnixTimeShared',
    'rssTitle',
    'socialChannel',
    'state',
    'suggestionTypeId',
    'textContentAvailableState',
    'timeSensitivityTypeId',
    'trackingDetails',
    'unshortenedShareURL',
    'updateDetails',
    'videoURL',
  ],
  [COLLECTION_NAMES.ARTICLE_SEARCH]: [
    'mediaId',
    'mediaURN',
    'mediaStatesByAccountAPIId',
    'mostRecentUnixTimePublished',
    'rssTitle',
    'textContentAvailableState',
    'totalNumberOfShares',
    'unshortenedShareURL',
  ],
  [COLLECTION_NAMES.SCHEDULE_QUEUE]: [
    'abTestStatusId',
    'igLinkStickerConfig',
    'imageOverlayURL',
    'imageURLs',
    'maxShareTime',
    'mediaId',
    'mediaURN',
    'mediaItemTags',
    'mediaStatesByAccountAPIId',
    'message',
    'minShareTime',
    'optimalShareTime',
    'socialChannel',
    'rssTitle',
    'shareURL',
    'state',
    'suggestionTypeId',
    'textContentAvailableState',
    'timeSensitivityTypeId',
    'trackingDetails',
    'unshortenedShareURL',
    'updateDetails',
    'videoURL',
  ],
  [COLLECTION_NAMES.APPROVALS]: [
    // Want all fields for approvals as this can be directly updated from the
  ],
  [COLLECTION_NAMES.LAST_SHARED]: [
    'actionTypeId',
    'imageURLs',
    'mediaId',
    'mediaItemTags',
    'mediaURN',
    'message',
    'mostRecentUnixTimeShared',
    'socialChannel',
    'rssTitle',
    'shareURL',
    'socialPostURL',
    'state',
    'textContentAvailableState',
    'timeSensitivityTypeId',
    'trackingDetails',
    'unixTimeShared',
    'unshortenedShareURL',
    'videoURL',
  ],
  [COLLECTION_NAMES.ANALYTICS_PAGE]: [
    'abResults',
    'abTestStatusId',
    'abVariations',
    'actionTypeId',
    'imageURLs',
    'insights',
    'maxShareTime',
    'mediaId',
    'mediaItemTags',
    'mediaStatesByAccountAPIId',
    'mediaURN',
    'message',
    'minShareTime',
    'mostRecentScore',
    'mostRecentUnixTimePublished',
    'mostRecentUnixTimeShared',
    'optimalShareTime',
    'rssTitle',
    'shareURL',
    'socialChannel',
    'socialPostURL',
    'state',
    'suggestionTypeId',
    'textContentAvailableState',
    'timeSensitivityTypeId',
    'totalNumberOfShares',
    'trackingDetails',
    'unixTimeShared',
    'unshortenedShareURL',
    'updateDetails',
    'videoURL',
  ],
  [COLLECTION_NAMES.DELETED_ITEMS]: [
    'imageOverlayURL',
    'imageURLs',
    'mediaId',
    'mediaStatesByAccountAPIId',
    'mediaURN',
    'mostRecentScore',
    'mostRecentUnixTimePublished',
    'mostRecentUnixTimeShared',
    'socialChannel',
    'rssTitle',
    'state',
    'suggestionTypeId',
    'textContentAvailableState',
    'trackingDetails',
    'unshortenedShareURL',
    'updateDetails',
    'videoURL',
  ],
  [COLLECTION_NAMES.FAILED_SHARES]: [
    'failReason',
    'imageOverlayURL',
    'imageURLs',
    'mediaId',
    'mediaURN',
    'mediaStatesByAccountAPIId',
    'message',
    'mostRecentScore',
    'mostRecentUnixTimePublished',
    'mostRecentUnixTimeShared',
    'socialChannel',
    'rssTitle',
    'state',
    'suggestionTypeId',
    'textContentAvailableState',
    'trackingDetails',
    'unshortenedShareURL',
    'updateDetails',
    'videoURL',
  ],
};

export const COMPOSE_BOX_TABS = Object.freeze({
  PREVIEW: 'preview',
  TIMING: 'timing',
});

export const CONTENT_SOURCES = {
  OPTIMAL: 1,
  DATASOURCE_DERIVED: 2,
  OPTIMAL_2: 3,
  OPTIMAL_TRANSIENT: 4,
};

export const CONTENT_TYPE_EDITED_IDS = [2, 4, 6];
/**
 * @type Array<number | null>
 */
export const CONTENT_TYPE_AI_IDS = [1, 2, 5, 6];

export const CONTENT_TYPE_IDS = Object.freeze({
  OPTIMAL: 1,
  OPTIMAL_EDITED: 2,
  DATASOURCE_DERIVED: 3,
  DATASOURCE_DERIVED_EDITED: 4,
  OPTIMAL_2: 5,
  OPTIMAL_2_EDITED: 6,
  OPTIMAL_TRANSIENT: 7,
});

export const SHARE_CONTENT_TYPES = {
  OPTIMAL: 'OPTIMAL',
  OPTIMAL_EDITED: 'OPTIMAL_EDITED',
  DERIVED_FROM_DATASOURCE: 'DERIVED_FROM_DATASOURCE',
  DERIVED_FROM_DATASOURCE_EDITED: 'DERIVED_FROM_DATASOURCE_EDITED',
  OPTIMAL_TRANSIENT: 'OPTIMAL_TRANSIENT',
};

export const CONTENT_TYPES = Object.freeze({
  1: Object.freeze({
    sourceId: CONTENT_SOURCES.OPTIMAL,
    description: 'AI message',
    sequence: 1,
    isEdited: false,
    editedId: 2,
  }),
  2: Object.freeze({
    sourceId: CONTENT_SOURCES.OPTIMAL,
    description: 'AI message (ed)',
    sequence: 2,
    isEdited: true,
  }),
  3: Object.freeze({
    sourceId: CONTENT_SOURCES.DATASOURCE_DERIVED,
    description: 'Custom',
    sequence: 5,
    isEdited: false,
    editedId: 4,
  }),
  4: Object.freeze({
    sourceId: CONTENT_SOURCES.DATASOURCE_DERIVED,
    description: 'Custom (ed)',
    sequence: 6,
    isEdited: true,
  }),
  5: Object.freeze({
    sourceId: CONTENT_SOURCES.OPTIMAL_2,
    description: 'Optimal 2',
    sequence: 3,
    isEdited: false,
    editedId: 6,
  }),
  6: Object.freeze({
    sourceId: CONTENT_SOURCES.OPTIMAL_2,
    description: 'Optimal 2 (ed)',
    sequence: 4,
    isEdited: true,
  }),
  7: Object.freeze({
    sourceId: CONTENT_SOURCES.OPTIMAL_TRANSIENT,
    description: 'AI message (generated)',
    sequence: 7,
    isEdited: false,
  }),
});

export const COUNTRIES = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean T.',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, Democratic Republic',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestine',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia, U.K.',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VA: 'Vatican',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  VG: 'Virgin Islands, U.K.',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const CSV_EXPORT_FROM_TIME = {
  ONE_WEEK: '1 week',
  ONE_MONTH: '1 month',
  THREE_MONTHS: '3 months',
};

export const CSV_EXPORT_FROM_TIME_SECS_OFFSET = {
  ONE_WEEK: 7 * 24 * 60 * 60, // 7 days
  ONE_MONTH: 35 * 24 * 60 * 60, // 35 days
  THREE_MONTHS: 98 * 24 * 60 * 60, // 98 days
};

export const DEBOUNCE_INPUT_DELAY = 750;

export const DEFAULT_VALUES = {
  TIMESLOT_DURATION: 3,
};

export const FACEBOOK_AGE = {
  THIRTEEN: 13,
  FIFTEEN: 15,
  EIGHTEEN: 18,
  TWENTY_ONE: 21,
  TWENTY_FIVE: 25,
};

export const FEED_STATES = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  DELETED: 'DELETED',
};

export const FILE_TYPE_ACCEPTS = {
  IMAGE: {
    'image/gif': [],
    'image/jpeg': [],
    'image/png': [],
    'image/webp': [],
  },
  VIDEO: {
    'video/mp4': [],
    'video/quicktime': [],
  },
};

export const FLASH_MESSAGE_TYPES = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  API_ERROR: 'API_ERROR',
  WARNING: 'WARNING',
} as const;

export const FRONTEND_METRICS = {
  ADD_SOCIAL_PAGE: 'Add Social Page for Crosspost',
  ARCHIVE_PAGE_LOAD: 'Archive Page Load',
  CHANGE_PAGE: 'Change Social Page',
  CHANGE_PROPERTY: 'Change Property',
  HOME_PAGE_LOAD: 'Home Page Load',
  POPULATE_GLOBAL_INFO: 'Populate Global Info',
  LOGIN: 'Login',
  OPEN_COMPOSE_BOX: 'Open Compose Box',
  OPEN_COMPOSE_BOX_EDIT_ITEM: 'Open Compose Box Edit Item',
  OPEN_COMPOSE_BOX_NEW_SHARE: 'Open Compose Box New Share',
  PAGE_NAVIGATION_ANALYTICS: 'Page Navigation /analytics',
  PAGE_NAVIGATION_ARCHIVE: 'Page Navigation /archive',
  PAGE_NAVIGATION_AUTOFEED: 'Page Navigation /autofeed',
  PAGE_NAVIGATION_HOME: 'Page Navigation /share',
  PAGE_NAVIGATION_LOGIN: 'Page Navigation /login',
  PAGE_NAVIGATION_LOGOUT: 'Page Navigation /logout',
  PAGE_NAVIGATION_MISC: 'Page Navigation /misc',
  PAGE_NAVIGATION_SETTINGS_PAGES: 'Page Navigation /settings/pages',
  PAGE_NAVIGATION_SETTINGS_PROPERTY: 'Page Navigation /settings/property',
  PAGE_NAVIGATION_SETTINGS_TEAM: 'Page Navigation /settings/users',
  COMPOSE_BOX_SAVE_DRAFT: 'Compose Box to Saved Draft',
  COMPOSE_BOX_SCHEDULE_POST: 'Compose Box to Schedule Queue',
  SHARE_NOW: 'Compose Box to Share Now',
};

export const FRONTEND_METRICS_STATUS = {
  OK: 'ok',
  UNKNOWN_ERROR: 'unknown_error',
};

export const FRONTEND_PAGE_MAP = Object.freeze({
  '': FRONTEND_METRICS.PAGE_NAVIGATION_HOME,
  '/': FRONTEND_METRICS.PAGE_NAVIGATION_HOME,
  '/addproperty': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/analytics': FRONTEND_METRICS.PAGE_NAVIGATION_ANALYTICS,
  '/analyze': FRONTEND_METRICS.PAGE_NAVIGATION_ANALYTICS, // For backwards compatibility
  '/apiConnectError': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/archive': FRONTEND_METRICS.PAGE_NAVIGATION_ARCHIVE,
  '/autofeed': FRONTEND_METRICS.PAGE_NAVIGATION_AUTOFEED,
  '/autopilot': FRONTEND_METRICS.PAGE_NAVIGATION_AUTOFEED,
  '/forgot': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/health': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/invite': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/lastshared': FRONTEND_METRICS.PAGE_NAVIGATION_HOME,
  '/login': FRONTEND_METRICS.PAGE_NAVIGATION_LOGIN,
  '/logout': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/queue': FRONTEND_METRICS.PAGE_NAVIGATION_HOME,
  '/approvals': FRONTEND_METRICS.PAGE_NAVIGATION_HOME,
  '/reset': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/select': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/settings/pages': FRONTEND_METRICS.PAGE_NAVIGATION_SETTINGS_PAGES,
  '/settings/property': FRONTEND_METRICS.PAGE_NAVIGATION_SETTINGS_PROPERTY,
  '/settings/users': FRONTEND_METRICS.PAGE_NAVIGATION_SETTINGS_TEAM,
  '/setup': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
  '/share': FRONTEND_METRICS.PAGE_NAVIGATION_HOME,
  '/signup': FRONTEND_METRICS.PAGE_NAVIGATION_MISC,
});

export const FRONTEND_PATHS = {
  [FRONTEND_PAGES.ANALYTICS]: ['/analytics', '/analyze'], // For backwards compatibility
  [FRONTEND_PAGES.ARCHIVE]: ['/archive'],
  [FRONTEND_PAGES.AUTOFEED]: [
    '/autofeed',
    '/autopilot',
    '/autofeed/newshares',
    '/autofeed/reshares',
    '/autofeed/datasources',
    '/autofeed/advanced',
  ],
  [FRONTEND_PAGES.COMPETITORS]: ['/competitors'],
  [FRONTEND_PAGES.LOGIN]: ['/login'],
  [FRONTEND_PAGES.MOBILE_QUEUED]: ['/queue'],
  [FRONTEND_PAGES.MOBILE_APPROVAL]: ['/approvals'],
  [FRONTEND_PAGES.MOBILE_SHARED]: ['/lastshared'],
  [FRONTEND_PAGES.SELECT]: ['/select', '/select/traffic'],
  [FRONTEND_PAGES.SETTINGS_PROPERTY]: ['/settings/property'],
  [FRONTEND_PAGES.SETTINGS_PAGES]: ['/settings/pages'],
  [FRONTEND_PAGES.SETTINGS_PAGE]: ['/settings/page/[0-9]+'],
  [FRONTEND_PAGES.SETTINGS_USERS]: ['/settings/users'],
  [FRONTEND_PAGES.SETTINGS_USER]: ['/settings/user'],
  [FRONTEND_PAGES.SETTINGS]: [
    '/settings',
    '/settings/property',
    '/settings/automation',
    '/settings/automation/[0-9]+',
    '/settings/automation/[0-9]+/newshares',
    '/settings/automation/[0-9]+/newshares/datasources',
    '/settings/automation/[0-9]+/newshares/keywords',
    '/settings/automation/[0-9]+/newshares/prefix-suffix',
    '/settings/automation/[0-9]+/reshares',
    '/settings/automation/[0-9]+/reshares/keywords',
    '/settings/automation/[0-9]+/reshares/prefix-suffix',
    '/settings/automation/[0-9]+/schedule',
    '/settings/automation/[0-9]+/advanced',
    '/settings/property/performancegoal',
    '/settings/pages',
    '/settings/page/[0-9]+',
    '/settings/page/[0-9]+/url-parameters',
    '/settings/page/[0-9]+/audience-restriction',
    '/settings/page/[0-9]+/default-share-preview',
    '/settings/page/[0-9]+/image-overlay',
    '/settings/users',
    '/settings/user',
    '/settings/content-feeds',
  ],
  [FRONTEND_PAGES.SETUP]: [
    '/setup',
    '/setup/users',
    '/setup/traffic',
    '/setup/socialpages',
    '/setup/content',
    '/setup/review',
    '/setup/holding',
    '/setup/select',
  ],
  [FRONTEND_PAGES.SHARE]: [
    '',
    '/',
    '/share',
    '/share/[0-9]+',
    '/queue',
    '/approvals',
    '/lastshared',
  ],
  [FRONTEND_PAGES.SHARE_DESKTOP]: ['', '/', '/share', '/share/[0-9]+'],
  [FRONTEND_PAGES.SHARE_MOBILE]: ['/approvals', '/queue', '/lastshared'],
  [FRONTEND_PAGES.NEWSLETTERS_AD]: ['/newslettersad'],
  [FRONTEND_PAGES.CONNECT_ERROR]: ['/apiConnectError'],
};

export const GLOBAL_INFO_CHANGES = {
  ANY_STATE_CHANGE: 'ANY_STATE_CHANGE',
  LOADING_OR_SELECTING: 'LOADING_OR_SELECTING',
  LOADING_SELECTING_OR_UPDATING: 'LOADING_SELECTING_OR_UPDATING',
};

export const GLOBAL_INFO_STATES = {
  LOADING: 'LOADING',
  READY: 'READY',
  ERROR: 'ERROR',
  REFRESHING: 'REFRESHING',
  SELECTING_PROPERTY: 'SELECTING_PROPERTY',
  SELECTING_PAGE: 'SELECTING_PAGE',
  UPDATING_SETTINGS: 'UPDATING_SETTINGS',
};

export const KEYCODES = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  ESCAPE: 27,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  INSERT: 45,
  DELETE: 46,
  LEFT_WINDOW_KEY: 91,
  SELECT_KEY: 93,
  F1: 112,
  SCROLL_LOCK: 145,
};

export const KEYNAMES = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
};

export const KEY_TRANSACTIONS = [
  FRONTEND_METRICS.ADD_SOCIAL_PAGE,
  FRONTEND_METRICS.CHANGE_PROPERTY,
  FRONTEND_METRICS.CHANGE_PAGE,
  FRONTEND_METRICS.HOME_PAGE_LOAD,
  FRONTEND_METRICS.POPULATE_GLOBAL_INFO,
  FRONTEND_METRICS.LOGIN,
  FRONTEND_METRICS.OPEN_COMPOSE_BOX,
  FRONTEND_METRICS.OPEN_COMPOSE_BOX_NEW_SHARE,
  FRONTEND_METRICS.OPEN_COMPOSE_BOX_EDIT_ITEM,
  FRONTEND_METRICS.COMPOSE_BOX_SAVE_DRAFT,
  FRONTEND_METRICS.COMPOSE_BOX_SCHEDULE_POST,
  FRONTEND_METRICS.SHARE_NOW,
  FRONTEND_METRICS.ARCHIVE_PAGE_LOAD,
];

export const LINK_TIME_BEHAVIOUR_TYPES = {
  QUERY_PARAM: 1,
  FRAGMENT_PARAM: 2,
};

export const LOG_LEVELS = Object.freeze({
  DEBUG: 0,
  LOG: 1,
  INFO: 2,
  ERROR: 3,
  TRACK: 4,
  NONE: 999,
});

export const MAX_GENERATED_AI_MESSAGES = 3;

export const MEDIA_ITEM_STATES = {
  NEW: 'NEW',
  SCHEDULED: 'SCHEDULED',
  APPROVAL_REQUIRED: 'APPROVAL_REQUIRED',
  SHARED: 'SHARED',
  DELETED: 'DELETED',
  FAILED: 'FAILED',
  ARCHIVED: 'ARCHIVED',
} as const;

export const MEDIA_ITEM_TYPES = {
  ARTICLE: 1,
  IMAGE: 2,
  VIDEO: 3,
};

export const NON_API_ERROR_MESSAGES = {
  NON_API_GENERAL:
    'Unfortunately, this action could not be completed. Please retry or reload the page.',
  CONNECT_CANCELLED: 'Social page connection attempt cancelled.',
};

export const NON_API_ERROR_TYPES = {
  NON_API_GENERAL: 'NON_API_GENERAL',
  CONNECT_CANCELLED: 'CONNECT_CANCELLED',
};

export const PARAMETER_MODES = {
  NONE: 'NONE',
  VALUEONLY: 'VALUEONLY',
  FULL: 'FULL',
} as const;

export const PARAMETER_TYPES = {
  QUERY: 'Query',
  FRAGMENT: 'Fragment',
};

export const PERMISSION_TYPES = {
  NONE: 0,
  VIEW_ONLY: 1,
  EDITOR: 2,
  ADMIN: 5,
} as const;

export const PERMISSION_TYPES_IDS = {
  0: 'NONE',
  1: 'VIEW_ONLY',
  2: 'EDITOR',
  5: 'ADMIN',
};

export const QUARTILE_NAMES = {
  1: 'Top Quartile',
  2: 'Medium-Top Quartile',
  3: 'Medium-Low Quartile',
  4: 'Low Quartile',
};

export const REACT_PREVENT_RENDER = null;

export const RSS_STATE_NAMES = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  ERROR: 'ERROR',
  DELETED: 'DELETED',
};

export const ROUTE_REDIRECTIONS = {
  HOME: '/share',
  LOGIN: '/login',
  LOGOUT: '/logout',
  NEW_PASSWORD: '/newpassword',
  SETTINGS_PROPERTY: '/settings/property',
  SETTINGS_PAGES: '/settings/pages',
  SETTINGS_USER: '/settings/user',
  SETUP: '/setup',
  MFA: '/mfa',
  SIGNUP: '/signup',
};

export const SAVE_STATES = {
  NONE: 'NONE',
  SAVING: 'SAVING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export const SEARCH_STATES = {
  NONE: 'NONE',
  SEARCHING: 'SEARCHING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
};

export const SHARE_ORIGINS = {
  HOME_FEED: 'Home Feed',
  NEW_SHARE: 'New Share',
  SCHEDULE_QUEUE: 'Schedule Queue',
  ANALYTICS_RESHARE: 'Analytics Reshare',
  LAST_SHARED_RESHARE: 'Last Shared Reshare',
  ADDITIONAL_SOCIAL_NETWORK: 'Additional Social Network',
  ADDITIONAL_NETWORK_OTHER_PROPERTY:
    'Additional Social Network (other property)',
  DUPLICATE: 'Duplicate',
  ARCHIVE: 'Archive',
  ANALYTICS_PAGE: 'Analytics Page',
  SEARCH: 'Search',
  APPROVALS: 'Approvals',
};

export const SHARE_TIME_NAMES = {
  OPTIMAL: 'time_optimal',
  TIMESLOT: 'time_timeslot',
  TIME: 'time_specifictime',
  NOW: 'time_now',
};

export const SHARE_TIME_TYPES = Object.freeze({
  OPTIMAL: 'optimal',
  TIMESLOT: 'timeslot',
  TIME: 'time',
  NOW: 'now',
});

export const SOCIAL_PAGE_SETTING_TYPES_ALL = [
  ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
  ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL,
  ACCOUNT_SETTING_TYPES.AUTO_FEED,
  ACCOUNT_SETTING_TYPES.FACEBOOK_UNSHORTENED_SHARING,
  ACCOUNT_SETTING_TYPES.FEATURE_TOGGLES,
  ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
  ACCOUNT_SETTING_TYPES.TWITTER_UNSHORTENED_SHARING,
  ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS,
  ACCOUNT_SETTING_TYPES.LINK_TIME_BEHAVIOUR,
  ACCOUNT_SETTING_TYPES.LIVE_STORY_DEFAULT,
  ACCOUNT_SETTING_TYPES.LINKEDIN_UNSHORTENED_SHARING,
  ACCOUNT_SETTING_TYPES.DEFAULT_LINKEDIN_TARGETING,
  ACCOUNT_SETTING_TYPES.INSTAGRAM_UNSHORTENED_SHARING,
  ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
  ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES,
  ACCOUNT_SETTING_TYPES.PERFORMANCE_GOAL,
];

export const SOCIAL_PAGE_SETTING_TYPES_PROPERTIES = [
  ACCOUNT_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS,
  ACCOUNT_SETTING_TYPES.DOMAIN_SWITCHING,
];

export const SOCIAL_PAGE_SETTING_TYPES_SOCIAL_PAGES = [
  ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES,
  ACCOUNT_SETTING_TYPES.DEFAULT_FACEBOOK_TARGETING,
  ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
];

export const SUGGESTION_TYPES = {
  MANUAL_SOCIAL_SHARE: 1,
  AUTO_SOCIAL_SHARE: 2,
  MANUAL_SOCIAL_AB_SHARE: 3,
};

export const TAG_SEARCH_MODES = Object.freeze({
  INLINE: 'INLINE',
  POPUP: 'POPUP',
});

export const TAG_SEARCH_TYPES = Object.freeze({
  SPONSOR: 'SPONSOR',
  TAG: 'TAG',
});

export const TEXT_CASES = {
  UPPER: 'UPPER',
  LOWER: 'LOWER',
  CAMEL: 'CAMEL',
} as const;

export const TEXT_DIRECTIONS = {
  LTR: 'ltr',
  RTL: 'rtl',
} as const;

export const TIME_SENSITIVITY_TYPES = Object.freeze({
  BREAKING: 0,
  EVERGREEN: 2,
  TODAY: 5,
});

export const TIME_SENSITIVITY_NAMES = {
  0: {
    typeId: TIME_SENSITIVITY_TYPES.BREAKING,
    categoryCode: 'breaking',
    categoryLabel: 'Breaking',
  },
  5: {
    typeId: TIME_SENSITIVITY_TYPES.TODAY,
    categoryCode: 'today',
    categoryLabel: 'Normal',
  },
  2: {
    typeId: TIME_SENSITIVITY_TYPES.EVERGREEN,
    categoryCode: 'evergreen',
    categoryLabel: 'Evergreen',
  },
};

export const TWITTER_CARD = Object.freeze({
  SUMMARY: 'summary',
  SUMMARY_LARGE_IMAGE: 'summary_large_image',
});

export const UI_MESSAGES = {
  ARTICLE_ARCHIVE_NO_ITEMS_FOUND: 'No matching articles found',
  ARTICLE_FEED_NO_ITEMS_FOUND: 'Your News Feed is cleared',
  DELETED_ITEMS_NO_ITEMS_FOUND: 'There are no deleted items',
  INFINITE_SCROLL_NO_ITEMS_FOUND: 'No more items to load',
  LAST_SHARED_NO_ITEMS_FOUND: 'Nothing has been shared',
  LOGIN_NULL_USER_TYPE: 'Incorrect username or password.',
  SCHEDULE_QUEUE_NO_ITEMS_FOUND: 'Your Schedule Queue is empty',
  APPROVALS_NO_ITEMS_FOUND: 'There are no pending Autofeed suggestions',
};

export const USER_STATES = {
  DISABLED: 'DISABLED',
  INVITED: 'INVITED',
  ENABLED: 'ENABLED',
} as const;

export const USER_TYPES = {
  DEFAULT: 'SOCIAL_FRONTEND_USER',
  NEWSLETTERS_USER: 'NEWSLETTER_FRONTEND_USER',
  ECHOBOX_STAFF: 'STAFF_USER',
  SUPPORT_USER: 'SUPPORT_USER',
  BENCHMARK_USER: 'BENCHMARK_FRONTEND_USER',
} as const;

export const USER_TYPES_PRIORITY = {
  SOCIAL_FRONTEND_USER: 10,
  NEWSLETTER_FRONTEND_USER: 10,
  BENCHMARK_FRONTEND_USER: 10,
  STAFF_USER: 1000,
  SUPPORT_USER: 100,
} as const;

export const PRODUCT_TYPES = {
  SOCIAL: 'SOCIAL',
  NEWSLETTERS: 'EMAIL',
  BENCHMARKS: 'BENCHMARKS',
};

export const PRODUCT_USER_TYPE_MAP: Record<string, string> = {
  [USER_TYPES.DEFAULT]: PRODUCT_TYPES.SOCIAL,
  [USER_TYPES.NEWSLETTERS_USER]: PRODUCT_TYPES.NEWSLETTERS,
  [USER_TYPES.BENCHMARK_USER]: PRODUCT_TYPES.BENCHMARKS,
};

export const PREFERRED_MFA_TYPES = {
  TOTP: 'TOTP',
  NOMFA: 'NOMFA',
};

export const TARGET_WIDTH = 542.688;

export const TARGET_HEIGTH = 290.0;
export const IMAGE_URL_PREFIX = 'https://images.ebxcdn.com/';

export const ENABLE_IMAGE_PREFIX = false;

export const IMAGE_RESIZE_OPTIONS = {
  height: 68,
  format: 'auto',
  metadata: 'none',
};

export const MIXPANEL_ORIGIN = {
  SETTINGS: 'Settings',
  SETUP: 'Property Setup Wizard',
  SHARE: 'Share',
};

export const NEWSLETTERS_AD_BG =
  'https://drive.google.com/uc?id=1M0WuusX6RVgdvgJsapu25bNy1AEtpn9w';

export const AB_TESTING_FIELDS = [
  'message',
  'title',
  'description',
  'imageURLs',
  'videoURLs',
];

export const ONE_DAY_IN_SECONDS = 86400e3;
export const UNIX_EPOCH = 'Thu, 01 Jan 1970 00:00:00 GMT';
export const NO_EXPIRY_DATE = 'Fri, 31 Dec 9999 23:59:59 GMT';

export const LOGIN_URLS = {
  PROD: 'https://login.echobox.com',
  STAGING: 'https://login.echobox.dev',
  DEV: 'http://localhost:3001',
} as const;

export const HOST_TYPES = {
  DEV: 'dev',
  PROD: 'prod',
  STAGING: 'staging',
} as const;

export const EBX_LAST_VISITED_PROPERTY_URN_KEY = 'EBX-s-lpr';
export const EBX_LAST_VISITED_SOCIAL_PAGES_KEY = 'EBX-s-lpa';

export const EBX_LAST_USED_PRODUCT_KEY = 'EbxLastUsedProduct';
export const NO_PERMISSIONS_PREFIX = 'NoPermissions_';
export const NO_PERMISSIONS_STATUS_CODES = [422, 403, 404, 401];
export const LOGIN_STARTED = 'Login_Started';
export const EMAIL = 'email';
export const LOGIN_APP_MESSAGES = {
  SIGN_OUT: 'SIGN_OUT',
  GET_TOKENS: 'GET_TOKENS',
};

export const IFRAME_MESSAGE_TIMEOUT = 30000;
export const LOOPBACK_ADDRESS = '127.0.0.1';
// Default images provided by social platforms for pages with no image
export const SOCIAL_PLATFORM_DEFAULT_ICONS = [
  // Twitter
  'https://abs.twimg.com/sticky/default_profile_images/default_profile_bigger.png',
];

export const TRACKER_KEY = 'TRACKER_INSTALLED';

export const TRACKER_SCRIPT =
  '<script async id="ebx" src="//applets.ebxcdn.com/ebx.js"></script>';

export const TRACKER_EMAIL_BODY = encodeURIComponent(
  `Hello, could you please add this JavaScript snippet before the closing <head> tag on each page of your website and after any rel=”canonical” elements are loaded:\r\n\r\n ${TRACKER_SCRIPT}`,
);

export const TWITTER_SHARE_FAILED_ERROR_MESSAGES = [
  'Your Twitter Project App is incorrectly configured and we have been unable to connect your Twitter Pages. Please set your App permissions to "Read and write" and then reconnect your Twitter Pages',
  'Your Twitter Project App is incorrectly configured and we have been unable to connect your Twitter Pages. Please ensure your Twitter developer App is attached to a Project and then reconnect your Twitter Pages.',
];

export const TWITTER_PAGE_DISCONNECTED_ERROR_MESSAGES = [
  'Your Twitter Project App is incorrectly configured and we have been unable to connect your Twitter Pages. Please set your App permissions to "Read and write" and then reconnect your Twitter Pages.',
  'Your Twitter Project App is incorrectly configured and we have been unable to connect your Twitter Pages. Please ensure your Twitter developer App is attached to a Project and then reconnect your Twitter Pages.',
  'Twitter could not validate your credentials. Please reconnect your Twitter Project App using a valid API Key and Secret.',
];

export const TWITTER_PROJECT_APP_INSTRUCTIONS_URL =
  'https://echobox.zendesk.com/hc/en-us/articles/10257937885853-Connecting-Twitter-to-Echobox';

export const INSTAGRAM_INSTRUCTIONS_URL =
  'https://echobox.zendesk.com/hc/en-us/articles/10875956490781';

export const HEALTH_MENU_LOCALSTORAGE_KEY = 'SEEN_HEALTH_MENU_NOTIFICATIONS';
export const HEALTH_MENU_TOAST_LOCALSTORAGE_KEY =
  'SEEN_HEALTH_MENU_TOAST_NOTIFICATIONS';

/**
 * A map of different health menu error types.
 * These are used to mark when a user has seen a particular error.
 */
export const HEALTH_MENU_ERROR_TYPES = {
  DISCONNECTIONS: 'DISCONNECTIONS',
  TWITTER_APP_DISCONNECTION: 'TWITTER_APP_DISCONNECTION',
  CONTENT_FEED_ERROR: 'CONTENT_FEED_ERROR',
} as const;

export const BACKEND_LINK_POST_PROPERTIES = [
  'mostRecentUnixTimePublished',
  'rssTitle',
  'timeSensitivityTypeId',
  'mostRecentScore',
  'mostRecentScoreCalculationUnixTime',
  'title',
  'message',
  'unshortenedShareURL',
  'totalNumberOfShares',
  'mediaStatesByAccountAPIId',
];

export const FRONTEND_LINK_POST_PROPERTIES = [
  'title',
  'message',
  'shareURL',
  'isURLChanged',
  'isURLValid',
  'isURLResolved',
  'unshortenedShareURL',
];
export const DISMISSED_HIGH_PRIORITY_ERROR_MESSAGES_LOCALSTORAGE_KEY =
  'DISMISSED_HIGH_PRIORITY_ERROR_MESSAGES';

export const HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME_LOCALSTORAGE_KEY =
  'HIGH_PRIORITY_ERROR_MESSAGES_FIRST_SEEN_UNIX_TIME';

export enum SENTRY_USER_TYPES {
  NORMAL = 'normal',
  ENHANCED_PERMISSIONS = 'enhanced permissions',
}

export enum PERFORMANCE_GOAL {
  TRAFFIC = 'traffic',
  ENGAGEMENT = 'engagement',
  EMAIL_SUBSCRIPTIONS = 'email_subscriptions',
  PAID_SUBSCRIPTIONS = 'paid_subscriptions',
}

export const BENCHMARK_SUPPORTED_PROPERTIES = new Set([
  13142, 242, 13470, 11503, 13262, 13542, 13512, 13512, 10846, 12526, 11632,
  10584, 3964, 11182, 10838, 10587, 12570, 10394, 12451, 11107, 13261, 13454,
  10210, 11913, 10876, 11716, 13338, 11765, 12410, 13341, 13722, 13720, 11669,
  11331, 12923, 12898, 11309, 11480, 3366, 823, 13277, 10444, 13015, 13016,
  13017, 11655, 12543, 11308, 12384, 10782, 13293, 11475, 13246, 13066, 12915,
  13119, 12623, 11075, 10911, 11564, 6363, 703, 505, 12603, 13120, 13126, 426,
  13560, 13363, 10759, 10895, 11936, 11915, 10625, 13044, 11145, 11706, 11737,
  12945, 10613, 13342, 11437, 11594, 13396, 12944, 11539, 11428, 11672, 11700,
  11696, 11098, 10788, 11801, 13095, 11165, 10218, 12948, 10144, 11302, 11924,
  13047, 10274, 12574, 13455, 11244, 10325, 11453, 13399, 11476, 13482, 10457,
  12919, 11922, 11200, 13270, 102, 732, 14011, 14012, 14014, 12473, 9929, 10004,
  13230, 11079, 11142, 11263, 10725,
]);

export enum TIMEZONES {
  AFRICA_ABIDJAN = 'Africa/Abidjan',
  AFRICA_ACCRA = 'Africa/Accra',
  AFRICA_ADDIS_ABABA = 'Africa/Addis_Ababa',
  AFRICA_ALGIERS = 'Africa/Algiers',
  AFRICA_ASMARA = 'Africa/Asmara',
  AFRICA_BAMAKO = 'Africa/Bamako',
  AFRICA_BANGUI = 'Africa/Bangui',
  AFRICA_BANJUL = 'Africa/Banjul',
  AFRICA_BISSAU = 'Africa/Bissau',
  AFRICA_BLANTYRE = 'Africa/Blantyre',
  AFRICA_BRAZZAVILLE = 'Africa/Brazzaville',
  AFRICA_BUJUMBURA = 'Africa/Bujumbura',
  AFRICA_CAIRO = 'Africa/Cairo',
  AFRICA_CASABLANCA = 'Africa/Casablanca',
  AFRICA_CEUTA = 'Africa/Ceuta',
  AFRICA_CONAKRY = 'Africa/Conakry',
  AFRICA_DAKAR = 'Africa/Dakar',
  AFRICA_DAR_ES_SALAAM = 'Africa/Dar_es_Salaam',
  AFRICA_DJIBOUTI = 'Africa/Djibouti',
  AFRICA_DOUALA = 'Africa/Douala',
  AFRICA_EL_AAIUN = 'Africa/El_Aaiun',
  AFRICA_FREETOWN = 'Africa/Freetown',
  AFRICA_GABORONE = 'Africa/Gaborone',
  AFRICA_HARARE = 'Africa/Harare',
  AFRICA_JOHANNESBURG = 'Africa/Johannesburg',
  AFRICA_JUBA = 'Africa/Juba',
  AFRICA_KAMPALA = 'Africa/Kampala',
  AFRICA_KHARTOUM = 'Africa/Khartoum',
  AFRICA_KIGALI = 'Africa/Kigali',
  AFRICA_KINSHASA = 'Africa/Kinshasa',
  AFRICA_LAGOS = 'Africa/Lagos',
  AFRICA_LIBREVILLE = 'Africa/Libreville',
  AFRICA_LOME = 'Africa/Lome',
  AFRICA_LUANDA = 'Africa/Luanda',
  AFRICA_LUBUMBASHI = 'Africa/Lubumbashi',
  AFRICA_LUSAKA = 'Africa/Lusaka',
  AFRICA_MALABO = 'Africa/Malabo',
  AFRICA_MAPUTO = 'Africa/Maputo',
  AFRICA_MASERU = 'Africa/Maseru',
  AFRICA_MBABANE = 'Africa/Mbabane',
  AFRICA_MOGADISHU = 'Africa/Mogadishu',
  AFRICA_MONROVIA = 'Africa/Monrovia',
  AFRICA_NAIROBI = 'Africa/Nairobi',
  AFRICA_NDJAMENA = 'Africa/Ndjamena',
  AFRICA_NIAMEY = 'Africa/Niamey',
  AFRICA_NOUAKCHOTT = 'Africa/Nouakchott',
  AFRICA_OUAGADOUGOU = 'Africa/Ouagadougou',
  AFRICA_PORTO_NOVO = 'Africa/Porto-Novo',
  AFRICA_SAO_TOME = 'Africa/Sao_Tome',
  AFRICA_TRIPOLI = 'Africa/Tripoli',
  AFRICA_TUNIS = 'Africa/Tunis',
  AFRICA_WINDHOEK = 'Africa/Windhoek',
  AMERICA_ADAK = 'America/Adak',
  AMERICA_ANCHORAGE = 'America/Anchorage',
  AMERICA_ANGUILLA = 'America/Anguilla',
  AMERICA_ANTIGUA = 'America/Antigua',
  AMERICA_ARAGUAINA = 'America/Araguaina',
  AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
  AMERICA_ARGENTINA_CATAMARCA = 'America/Argentina/Catamarca',
  AMERICA_ARGENTINA_CORDOBA = 'America/Argentina/Cordoba',
  AMERICA_ARGENTINA_JUJUY = 'America/Argentina/Jujuy',
  AMERICA_ARGENTINA_LA_RIOJA = 'America/Argentina/La_Rioja',
  AMERICA_ARGENTINA_MENDOZA = 'America/Argentina/Mendoza',
  AMERICA_ARGENTINA_RIO_GALLEGOS = 'America/Argentina/Rio_Gallegos',
  AMERICA_ARGENTINA_SALTA = 'America/Argentina/Salta',
  AMERICA_ARGENTINA_SAN_JUAN = 'America/Argentina/San_Juan',
  AMERICA_ARGENTINA_SAN_LUIS = 'America/Argentina/San_Luis',
  AMERICA_ARGENTINA_TUCUMAN = 'America/Argentina/Tucuman',
  AMERICA_ARGENTINA_USHUAIA = 'America/Argentina/Ushuaia',
  AMERICA_ARUBA = 'America/Aruba',
  AMERICA_ASUNCION = 'America/Asuncion',
  AMERICA_ATIKOKAN = 'America/Atikokan',
  AMERICA_BAHIA = 'America/Bahia',
  AMERICA_BAHIA_BANDERAS = 'America/Bahia_Banderas',
  AMERICA_BARBADOS = 'America/Barbados',
  AMERICA_BELEM = 'America/Belem',
  AMERICA_BELIZE = 'America/Belize',
  AMERICA_BLANC_SABLON = 'America/Blanc-Sablon',
  AMERICA_BOA_VISTA = 'America/Boa_Vista',
  AMERICA_BOGOTA = 'America/Bogota',
  AMERICA_BOISE = 'America/Boise',
  AMERICA_CAMBRIDGE_BAY = 'America/Cambridge_Bay',
  AMERICA_CAMPO_GRANDE = 'America/Campo_Grande',
  AMERICA_CANCUN = 'America/Cancun',
  AMERICA_CARACAS = 'America/Caracas',
  AMERICA_CAYENNE = 'America/Cayenne',
  AMERICA_CAYMAN = 'America/Cayman',
  AMERICA_CHICAGO = 'America/Chicago',
  AMERICA_CHIHUAHUA = 'America/Chihuahua',
  AMERICA_COSTA_RICA = 'America/Costa_Rica',
  AMERICA_CRESTON = 'America/Creston',
  AMERICA_CUIABA = 'America/Cuiaba',
  AMERICA_CURACAO = 'America/Curacao',
  AMERICA_DANMARKSHAVN = 'America/Danmarkshavn',
  AMERICA_DAWSON = 'America/Dawson',
  AMERICA_DAWSON_CREEK = 'America/Dawson_Creek',
  AMERICA_DENVER = 'America/Denver',
  AMERICA_DETROIT = 'America/Detroit',
  AMERICA_DOMINICA = 'America/Dominica',
  AMERICA_EDMONTON = 'America/Edmonton',
  AMERICA_EIRUNEPE = 'America/Eirunepe',
  AMERICA_EL_SALVADOR = 'America/El_Salvador',
  AMERICA_FORTALEZA = 'America/Fortaleza',
  AMERICA_GLACE_BAY = 'America/Glace_Bay',
  AMERICA_GODTHAB = 'America/Godthab',
  AMERICA_GOOSE_BAY = 'America/Goose_Bay',
  AMERICA_GRAND_TURK = 'America/Grand_Turk',
  AMERICA_GRENADA = 'America/Grenada',
  AMERICA_GUADELOUPE = 'America/Guadeloupe',
  AMERICA_GUATEMALA = 'America/Guatemala',
  AMERICA_GUAYAQUIL = 'America/Guayaquil',
  AMERICA_GUYANA = 'America/Guyana',
  AMERICA_HALIFAX = 'America/Halifax',
  AMERICA_HAVANA = 'America/Havana',
  AMERICA_HERMOSILLO = 'America/Hermosillo',
  AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
  AMERICA_INDIANA_KNOX = 'America/Indiana/Knox',
  AMERICA_INDIANA_MARENGO = 'America/Indiana/Marengo',
  AMERICA_INDIANA_PETERSBURG = 'America/Indiana/Petersburg',
  AMERICA_INDIANA_TELL_CITY = 'America/Indiana/Tell_City',
  AMERICA_INDIANA_VEVAY = 'America/Indiana/Vevay',
  AMERICA_INDIANA_VINCENNES = 'America/Indiana/Vincennes',
  AMERICA_INDIANA_WINAMAC = 'America/Indiana/Winamac',
  AMERICA_INUVIK = 'America/Inuvik',
  AMERICA_IQALUIT = 'America/Iqaluit',
  AMERICA_JAMAICA = 'America/Jamaica',
  AMERICA_JUNEAU = 'America/Juneau',
  AMERICA_KENTUCKY_LOUISVILLE = 'America/Kentucky/Louisville',
  AMERICA_KENTUCKY_MONTICELLO = 'America/Kentucky/Monticello',
  AMERICA_KRALENDIJK = 'America/Kralendijk',
  AMERICA_LA_PAZ = 'America/La_Paz',
  AMERICA_LIMA = 'America/Lima',
  AMERICA_LOS_ANGELES = 'America/Los_Angeles',
  AMERICA_LOWER_PRINCES = 'America/Lower_Princes',
  AMERICA_MACEIO = 'America/Maceio',
  AMERICA_MANAGUA = 'America/Managua',
  AMERICA_MANAUS = 'America/Manaus',
  AMERICA_MARIGOT = 'America/Marigot',
  AMERICA_MARTINIQUE = 'America/Martinique',
  AMERICA_MATAMOROS = 'America/Matamoros',
  AMERICA_MAZATLAN = 'America/Mazatlan',
  AMERICA_MENOMINEE = 'America/Menominee',
  AMERICA_MERIDA = 'America/Merida',
  AMERICA_METLAKATLA = 'America/Metlakatla',
  AMERICA_MEXICO_CITY = 'America/Mexico_City',
  AMERICA_MIQUELON = 'America/Miquelon',
  AMERICA_MONCTON = 'America/Moncton',
  AMERICA_MONTERREY = 'America/Monterrey',
  AMERICA_MONTEVIDEO = 'America/Montevideo',
  AMERICA_MONTSERRAT = 'America/Montserrat',
  AMERICA_NASSAU = 'America/Nassau',
  AMERICA_NEW_YORK = 'America/New_York',
  AMERICA_NIPIGON = 'America/Nipigon',
  AMERICA_NOME = 'America/Nome',
  AMERICA_NORONHA = 'America/Noronha',
  AMERICA_NORTH_DAKOTA_BEULAH = 'America/North_Dakota/Beulah',
  AMERICA_NORTH_DAKOTA_CENTER = 'America/North_Dakota/Center',
  AMERICA_NORTH_DAKOTA_NEW_SALEM = 'America/North_Dakota/New_Salem',
  AMERICA_OJINAGA = 'America/Ojinaga',
  AMERICA_PANAMA = 'America/Panama',
  AMERICA_PANGNIRTUNG = 'America/Pangnirtung',
  AMERICA_PARAMARIBO = 'America/Paramaribo',
  AMERICA_PHOENIX = 'America/Phoenix',
  AMERICA_PORT_AU_PRINCE = 'America/Port-au-Prince',
  AMERICA_PORTO_VELHO = 'America/Porto_Velho',
  AMERICA_PORT_OF_SPAIN = 'America/Port_of_Spain',
  AMERICA_PUERTO_RICO = 'America/Puerto_Rico',
  AMERICA_RAINY_RIVER = 'America/Rainy_River',
  AMERICA_RANKIN_INLET = 'America/Rankin_Inlet',
  AMERICA_RECIFE = 'America/Recife',
  AMERICA_REGINA = 'America/Regina',
  AMERICA_RESOLUTE = 'America/Resolute',
  AMERICA_RIO_BRANCO = 'America/Rio_Branco',
  AMERICA_SANTAREM = 'America/Santarem',
  AMERICA_SANTA_ISABEL = 'America/Santa_Isabel',
  AMERICA_SANTIAGO = 'America/Santiago',
  AMERICA_SANTO_DOMINGO = 'America/Santo_Domingo',
  AMERICA_SAO_PAULO = 'America/Sao_Paulo',
  AMERICA_SCORESBYSUND = 'America/Scoresbysund',
  AMERICA_SITKA = 'America/Sitka',
  AMERICA_ST_BARTHELEMY = 'America/St_Barthelemy',
  AMERICA_ST_JOHNS = 'America/St_Johns',
  AMERICA_ST_KITTS = 'America/St_Kitts',
  AMERICA_ST_LUCIA = 'America/St_Lucia',
  AMERICA_ST_THOMAS = 'America/St_Thomas',
  AMERICA_ST_VINCENT = 'America/St_Vincent',
  AMERICA_SWIFT_CURRENT = 'America/Swift_Current',
  AMERICA_TEGUCIGALPA = 'America/Tegucigalpa',
  AMERICA_THULE = 'America/Thule',
  AMERICA_THUNDER_BAY = 'America/Thunder_Bay',
  AMERICA_TIJUANA = 'America/Tijuana',
  AMERICA_TORONTO = 'America/Toronto',
  AMERICA_TORTOLA = 'America/Tortola',
  AMERICA_VANCOUVER = 'America/Vancouver',
  AMERICA_WHITEHORSE = 'America/Whitehorse',
  AMERICA_WINNIPEG = 'America/Winnipeg',
  AMERICA_YAKUTAT = 'America/Yakutat',
  AMERICA_YELLOWKNIFE = 'America/Yellowknife',
  ANTARCTICA_CASEY = 'Antarctica/Casey',
  ANTARCTICA_DAVIS = 'Antarctica/Davis',
  ANTARCTICA_DUMONTDURVILLE = 'Antarctica/DumontDUrville',
  ANTARCTICA_MACQUARIE = 'Antarctica/Macquarie',
  ANTARCTICA_MAWSON = 'Antarctica/Mawson',
  ANTARCTICA_MCMURDO = 'Antarctica/McMurdo',
  ANTARCTICA_PALMER = 'Antarctica/Palmer',
  ANTARCTICA_ROTHERA = 'Antarctica/Rothera',
  ANTARCTICA_SYOWA = 'Antarctica/Syowa',
  ANTARCTICA_VOSTOK = 'Antarctica/Vostok',
  ASIA_ADEN = 'Asia/Aden',
  ASIA_ALMATY = 'Asia/Almaty',
  ASIA_AMMAN = 'Asia/Amman',
  ASIA_ANADYR = 'Asia/Anadyr',
  ASIA_AQTAU = 'Asia/Aqtau',
  ASIA_AQTOBE = 'Asia/Aqtobe',
  ASIA_ASHGABAT = 'Asia/Ashgabat',
  ASIA_BAGHDAD = 'Asia/Baghdad',
  ASIA_BAHRAIN = 'Asia/Bahrain',
  ASIA_BAKU = 'Asia/Baku',
  ASIA_BANGKOK = 'Asia/Bangkok',
  ASIA_BEIRUT = 'Asia/Beirut',
  ASIA_BISHKEK = 'Asia/Bishkek',
  ASIA_BRUNEI = 'Asia/Brunei',
  ASIA_CHOIBALSAN = 'Asia/Choibalsan',
  ASIA_CHONGQING = 'Asia/Chongqing',
  ASIA_COLOMBO = 'Asia/Colombo',
  ASIA_DAMASCUS = 'Asia/Damascus',
  ASIA_DHAKA = 'Asia/Dhaka',
  ASIA_DILI = 'Asia/Dili',
  ASIA_DUBAI = 'Asia/Dubai',
  ASIA_DUSHANBE = 'Asia/Dushanbe',
  ASIA_GAZA = 'Asia/Gaza',
  ASIA_HARBIN = 'Asia/Harbin',
  ASIA_HEBRON = 'Asia/Hebron',
  ASIA_HONG_KONG = 'Asia/Hong_Kong',
  ASIA_HOVD = 'Asia/Hovd',
  ASIA_HO_CHI_MINH = 'Asia/Ho_Chi_Minh',
  ASIA_IRKUTSK = 'Asia/Irkutsk',
  ASIA_JAKARTA = 'Asia/Jakarta',
  ASIA_JAYAPURA = 'Asia/Jayapura',
  ASIA_JERUSALEM = 'Asia/Jerusalem',
  ASIA_KABUL = 'Asia/Kabul',
  ASIA_KAMCHATKA = 'Asia/Kamchatka',
  ASIA_KARACHI = 'Asia/Karachi',
  ASIA_KASHGAR = 'Asia/Kashgar',
  ASIA_KATHMANDU = 'Asia/Kathmandu',
  ASIA_KOLKATA = 'Asia/Kolkata',
  ASIA_KRASNOYARSK = 'Asia/Krasnoyarsk',
  ASIA_KUALA_LUMPUR = 'Asia/Kuala_Lumpur',
  ASIA_KUCHING = 'Asia/Kuching',
  ASIA_KUWAIT = 'Asia/Kuwait',
  ASIA_MACAU = 'Asia/Macau',
  ASIA_MAGADAN = 'Asia/Magadan',
  ASIA_MAKASSAR = 'Asia/Makassar',
  ASIA_MANILA = 'Asia/Manila',
  ASIA_MUSCAT = 'Asia/Muscat',
  ASIA_NICOSIA = 'Asia/Nicosia',
  ASIA_NOVOKUZNETSK = 'Asia/Novokuznetsk',
  ASIA_NOVOSIBIRSK = 'Asia/Novosibirsk',
  ASIA_OMSK = 'Asia/Omsk',
  ASIA_ORAL = 'Asia/Oral',
  ASIA_PHNOM_PENH = 'Asia/Phnom_Penh',
  ASIA_PONTIANAK = 'Asia/Pontianak',
  ASIA_PYONGYANG = 'Asia/Pyongyang',
  ASIA_QATAR = 'Asia/Qatar',
  ASIA_QYZYLORDA = 'Asia/Qyzylorda',
  ASIA_RANGOON = 'Asia/Rangoon',
  ASIA_RIYADH = 'Asia/Riyadh',
  ASIA_SAKHALIN = 'Asia/Sakhalin',
  ASIA_SAMARKAND = 'Asia/Samarkand',
  ASIA_SEOUL = 'Asia/Seoul',
  ASIA_SHANGHAI = 'Asia/Shanghai',
  ASIA_SINGAPORE = 'Asia/Singapore',
  ASIA_TAIPEI = 'Asia/Taipei',
  ASIA_TASHKENT = 'Asia/Tashkent',
  ASIA_TBILISI = 'Asia/Tbilisi',
  ASIA_TEHRAN = 'Asia/Tehran',
  ASIA_THIMPHU = 'Asia/Thimphu',
  ASIA_TOKYO = 'Asia/Tokyo',
  ASIA_ULAANBAATAR = 'Asia/Ulaanbaatar',
  ASIA_URUMQI = 'Asia/Urumqi',
  ASIA_VIENTIANE = 'Asia/Vientiane',
  ASIA_VLADIVOSTOK = 'Asia/Vladivostok',
  ASIA_YAKUTSK = 'Asia/Yakutsk',
  ASIA_YEKATERINBURG = 'Asia/Yekaterinburg',
  ASIA_YEREVAN = 'Asia/Yerevan',
  ATLANTIC_AZORES = 'Atlantic/Azores',
  ATLANTIC_BERMUDA = 'Atlantic/Bermuda',
  ATLANTIC_CANARY = 'Atlantic/Canary',
  ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde',
  ATLANTIC_FAROE = 'Atlantic/Faroe',
  ATLANTIC_MADEIRA = 'Atlantic/Madeira',
  ATLANTIC_REYKJAVIK = 'Atlantic/Reykjavik',
  ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
  ATLANTIC_STANLEY = 'Atlantic/Stanley',
  ATLANTIC_ST_HELENA = 'Atlantic/St_Helena',
  AUSTRALIA_ADELAIDE = 'Australia/Adelaide',
  AUSTRALIA_BRISBANE = 'Australia/Brisbane',
  AUSTRALIA_BROKEN_HILL = 'Australia/Broken_Hill',
  AUSTRALIA_CURRIE = 'Australia/Currie',
  AUSTRALIA_DARWIN = 'Australia/Darwin',
  AUSTRALIA_EUCLA = 'Australia/Eucla',
  AUSTRALIA_HOBART = 'Australia/Hobart',
  AUSTRALIA_LINDEMAN = 'Australia/Lindeman',
  AUSTRALIA_LORD_HOWE = 'Australia/Lord_Howe',
  AUSTRALIA_MELBOURNE = 'Australia/Melbourne',
  AUSTRALIA_PERTH = 'Australia/Perth',
  AUSTRALIA_SYDNEY = 'Australia/Sydney',
  EUROPE_AMSTERDAM = 'Europe/Amsterdam',
  EUROPE_ANDORRA = 'Europe/Andorra',
  EUROPE_ATHENS = 'Europe/Athens',
  EUROPE_BELGRADE = 'Europe/Belgrade',
  EUROPE_BERLIN = 'Europe/Berlin',
  EUROPE_BRATISLAVA = 'Europe/Bratislava',
  EUROPE_BRUSSELS = 'Europe/Brussels',
  EUROPE_BUCHAREST = 'Europe/Bucharest',
  EUROPE_BUDAPEST = 'Europe/Budapest',
  EUROPE_CHISINAU = 'Europe/Chisinau',
  EUROPE_COPENHAGEN = 'Europe/Copenhagen',
  EUROPE_DUBLIN = 'Europe/Dublin',
  EUROPE_GIBRALTAR = 'Europe/Gibraltar',
  EUROPE_GUERNSEY = 'Europe/Guernsey',
  EUROPE_HELSINKI = 'Europe/Helsinki',
  EUROPE_ISLE_OF_MAN = 'Europe/Isle_of_Man',
  EUROPE_ISTANBUL = 'Europe/Istanbul',
  EUROPE_JERSEY = 'Europe/Jersey',
  EUROPE_KALININGRAD = 'Europe/Kaliningrad',
  EUROPE_KIEV = 'Europe/Kiev',
  EUROPE_LISBON = 'Europe/Lisbon',
  EUROPE_LJUBLJANA = 'Europe/Ljubljana',
  EUROPE_LONDON = 'Europe/London',
  EUROPE_LUXEMBOURG = 'Europe/Luxembourg',
  EUROPE_MADRID = 'Europe/Madrid',
  EUROPE_MALTA = 'Europe/Malta',
  EUROPE_MARIEHAMN = 'Europe/Mariehamn',
  EUROPE_MINSK = 'Europe/Minsk',
  EUROPE_MONACO = 'Europe/Monaco',
  EUROPE_MOSCOW = 'Europe/Moscow',
  EUROPE_OSLO = 'Europe/Oslo',
  EUROPE_PARIS = 'Europe/Paris',
  EUROPE_PODGORICA = 'Europe/Podgorica',
  EUROPE_PRAGUE = 'Europe/Prague',
  EUROPE_RIGA = 'Europe/Riga',
  EUROPE_ROME = 'Europe/Rome',
  EUROPE_SAMARA = 'Europe/Samara',
  EUROPE_SAN_MARINO = 'Europe/San_Marino',
  EUROPE_SARAJEVO = 'Europe/Sarajevo',
  EUROPE_SIMFEROPOL = 'Europe/Simferopol',
  EUROPE_SKOPJE = 'Europe/Skopje',
  EUROPE_SOFIA = 'Europe/Sofia',
  EUROPE_STOCKHOLM = 'Europe/Stockholm',
  EUROPE_TALLINN = 'Europe/Tallinn',
  EUROPE_TIRANE = 'Europe/Tirane',
  EUROPE_UZHGOROD = 'Europe/Uzhgorod',
  EUROPE_VADUZ = 'Europe/Vaduz',
  EUROPE_VATICAN = 'Europe/Vatican',
  EUROPE_VIENNA = 'Europe/Vienna',
  EUROPE_VILNIUS = 'Europe/Vilnius',
  EUROPE_VOLGOGRAD = 'Europe/Volgograd',
  EUROPE_WARSAW = 'Europe/Warsaw',
  EUROPE_ZAGREB = 'Europe/Zagreb',
  EUROPE_ZAPOROZHYE = 'Europe/Zaporozhye',
  EUROPE_ZURICH = 'Europe/Zurich',
  INDIAN_ANTANANARIVO = 'Indian/Antananarivo',
  INDIAN_CHAGOS = 'Indian/Chagos',
  INDIAN_CHRISTMAS = 'Indian/Christmas',
  INDIAN_COCOS = 'Indian/Cocos',
  INDIAN_COMORO = 'Indian/Comoro',
  INDIAN_KERGUELEN = 'Indian/Kerguelen',
  INDIAN_MAHE = 'Indian/Mahe',
  INDIAN_MALDIVES = 'Indian/Maldives',
  INDIAN_MAURITIUS = 'Indian/Mauritius',
  INDIAN_MAYOTTE = 'Indian/Mayotte',
  INDIAN_REUNION = 'Indian/Reunion',
  PACIFIC_APIA = 'Pacific/Apia',
  PACIFIC_AUCKLAND = 'Pacific/Auckland',
  PACIFIC_CHATHAM = 'Pacific/Chatham',
  PACIFIC_CHUUK = 'Pacific/Chuuk',
  PACIFIC_EASTER = 'Pacific/Easter',
  PACIFIC_EFATE = 'Pacific/Efate',
  PACIFIC_ENDERBURY = 'Pacific/Enderbury',
  PACIFIC_FAKAOFO = 'Pacific/Fakaofo',
  PACIFIC_FIJI = 'Pacific/Fiji',
  PACIFIC_FUNAFUTI = 'Pacific/Funafuti',
  PACIFIC_GALAPAGOS = 'Pacific/Galapagos',
  PACIFIC_GAMBIER = 'Pacific/Gambier',
  PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal',
  PACIFIC_GUAM = 'Pacific/Guam',
  PACIFIC_HONOLULU = 'Pacific/Honolulu',
  PACIFIC_JOHNSTON = 'Pacific/Johnston',
  PACIFIC_KIRITIMATI = 'Pacific/Kiritimati',
  PACIFIC_KOSRAE = 'Pacific/Kosrae',
  PACIFIC_KWAJALEIN = 'Pacific/Kwajalein',
  PACIFIC_MAJURO = 'Pacific/Majuro',
  PACIFIC_MARQUESAS = 'Pacific/Marquesas',
  PACIFIC_MIDWAY = 'Pacific/Midway',
  PACIFIC_NAURU = 'Pacific/Nauru',
  PACIFIC_NIUE = 'Pacific/Niue',
  PACIFIC_NORFOLK = 'Pacific/Norfolk',
  PACIFIC_NOUMEA = 'Pacific/Noumea',
  PACIFIC_PAGO_PAGO = 'Pacific/Pago_Pago',
  PACIFIC_PALAU = 'Pacific/Palau',
  PACIFIC_PITCAIRN = 'Pacific/Pitcairn',
  PACIFIC_POHNPEI = 'Pacific/Pohnpei',
  PACIFIC_PORT_MORESBY = 'Pacific/Port_Moresby',
  PACIFIC_RAROTONGA = 'Pacific/Rarotonga',
  PACIFIC_SAIPAN = 'Pacific/Saipan',
  PACIFIC_TAHITI = 'Pacific/Tahiti',
  PACIFIC_TARAWA = 'Pacific/Tarawa',
  PACIFIC_TONGATAPU = 'Pacific/Tongatapu',
  PACIFIC_WAKE = 'Pacific/Wake',
  PACIFIC_WALLIS = 'Pacific/Wallis',
}

export enum LANGUAGES {
  // aa = 'Afar',
  // ab = 'Abkhazian',
  af = 'Afrikaans',
  ak = 'Akan',
  am = 'Amharic',
  sq = 'Albanian',
  an = 'Aragonese',
  ar = 'Arabic',
  hy = 'Armenian',
  as = 'Assamese',
  ay = 'Aymara',
  az = 'Azerbaijani',
  // ba = 'Bashkir',
  be = 'Belarusian',
  bg = 'Bulgarian',
  bh = 'Bihari',
  // bi = 'Bislama',
  bn = 'Bengali',
  br = 'Breton',
  bs = 'Bosnian',
  my = 'Burmese',
  ca = 'Catalan',
  // ce = 'Chechen',
  // ny = 'Chichewa',
  zh = 'Chinese',
  // cu = 'Chuvash',
  // ch = 'Chamorro',
  co = 'Corsican',
  // cr = 'Cree',
  cs = 'Czech',
  // cu = 'Slavonic',
  da = 'Danish',
  dv = 'Dhivehi',
  nl = 'Dutch',
  // dz = 'Dzongkha',
  ee = 'Ewe',
  en = 'English',
  eo = 'Esperanto',
  et = 'Estonian',
  eu = 'Basque',
  fa = 'Persian',
  fi = 'Finnish',
  // fj = 'Fijian',
  fo = 'Faroese',
  fr = 'French',
  fy = 'Frisian',
  ga = 'Irish',
  gd = 'Scots_gaelic',
  gl = 'Galician',
  lg = 'Ganda',
  ka = 'Georgian',
  de = 'German',
  el = 'Greek',
  gn = 'Guarani',
  gu = 'Gujarati',
  // gv = 'Manx',
  ha = 'Hausa',
  he = 'Hebrew',
  hi = 'Hindi',
  // ho = 'Hirimotu',
  hr = 'Croatian',
  ht = 'Haitian',
  hu = 'Hungarian',
  // hz = 'Herero',
  ia = 'Interlingua',
  id = 'Indonesian',
  // ie = 'Interlingue',
  ig = 'Igbo',
  // ii = 'Tibetan',
  // ik = 'Inupiak',
  in = 'Lingala',
  // io = 'Ido',
  is = 'Icelandic',
  it = 'Italian',
  // iu = 'Inuktitut',
  ja = 'Japanese',
  jv = 'Javanese',
  // kg = 'Kongo',
  // ki = 'Kikuyu',
  // kj = 'Kwanyama',
  kk = 'Kazakh',
  // kl = 'Greenlandic',
  km = 'Khmer',
  kn = 'Kannada',
  rw = 'Kinyarwanda',
  ko = 'Korean',
  // kr = 'Kanuri',
  // ks = 'Kashmiri',
  ku = 'Kurdish',
  // kv = 'Komi',
  // kw = 'Cornish',
  ky = 'Kyrgyz',
  lo = 'Lao',
  la = 'Latin',
  lb = 'Luxembourgish',
  // li = 'Limburgish',
  lt = 'Lithuanian',
  // lu = 'Lubakatanga',
  lv = 'Latvian',
  mk = 'Macedonian',
  mg = 'Malagasy',
  // mh = 'Marshallese',
  ml = 'Malayalam',
  mi = 'Maori',
  mr = 'Marathi',
  ms = 'Malay',
  mt = 'Maltese',
  mn = 'Mongolian',
  mo = 'Moldavian',
  // na = 'Nauru',
  nb = 'Norwegianbokmal',
  // nd = 'Northernndebele',
  ne = 'Nepali',
  // ng = 'Ndonga',
  nn = 'Norwegiann',
  no = 'Norwegian',
  // nr = 'Southernndebele',
  // nv = 'Navajo',
  oc = 'Occitan',
  // oj = 'Ojibwe',
  om = 'Oromo',
  or = 'Oriya',
  // os = 'Ossetian',
  pa = 'Punjabi',
  // pi = 'Pali',
  pl = 'Polish',
  ps = 'Pashto',
  pt = 'Portuguese',
  qu = 'Quechua',
  rm = 'Romansh',
  // rn = 'Rundi',
  ro = 'Romanian',
  ru = 'Russian',
  sa = 'Sanskrit',
  // sc = 'Sardinian',
  sd = 'Sindhi',
  // se = 'Northernsami',
  // sg = 'Sango',
  si = 'Sinhalese',
  sk = 'Slovak',
  sl = 'Slovene',
  sm = 'Samoan',
  sn = 'Shona',
  so = 'Somali',
  sr = 'Serbian',
  // ss = 'Siswant',
  st = 'Sesotho',
  es = 'Spanish',
  su = 'Sundanese',
  sv = 'Swedish',
  sw = 'Swahili',
  ta = 'Tamil',
  te = 'Telugu',
  tg = 'Tajik',
  th = 'Thai',
  ti = 'Tigrinya',
  tk = 'Turkmen',
  tl = 'Tagalog',
  tn = 'Tswana',
  to = 'Tonga',
  tr = 'Turkish',
  ts = 'Tsonga',
  tt = 'Tatar',
  // tw = 'Twi',
  // ty = 'Tahitian',
  ug = 'Uighur',
  uk = 'Ukrainian',
  unknown = 'Unknown',
  ur = 'Urdu',
  uz = 'Uzbek',
  // ve = 'Venda',
  vi = 'Vietnamese',
  // vo = 'Volapuk',
  wa = 'Walloon',
  cy = 'Welsh',
  wo = 'Wolof',
  xh = 'Xhosa',
  yi = 'Yiddish',
  yo = 'Yoruba',
  // za = 'Zhuang',
  zu = 'Zulu',
}

export const STORAGE_TYPES = {
  LOCAL: 'local',
  SESSION: 'session',
};

export const MOST_RECENT_SHARE_TYPE_KEY = 'EBX_MOST_RECENT_SHARE';
