import {
  Flex,
  SettingsCard,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import isEqual from 'fast-deep-equal';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import * as accountAPIs from 'common/accountAPIs';
import { AUTOFEED_SHARE_MODES } from 'common/constants';
import { ARTICLE_AGE_UNITS } from 'common/enums';
import * as settings from 'common/settings';
import * as social from 'common/social';
import * as tracker from 'common/tracker';
import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';
import NewShareModes from 'components/settings/automation/newshares/NewShareModes';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  setSettingsError,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';
import { AutofeedSettings, AutofeedShareMode, FixTypeLater } from 'types';
import { useAutoDisableAutomationPrompt } from '../useAutoDisableAutomationPrompt';

export interface NewSharesSettings {
  newShareMode: AutofeedShareMode;
  bestArticlesNewContentNum: number;
  articleAgeUnit: ARTICLE_AGE_UNITS;
  articleAgeValue: number;
}

function NewShares() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();

  const { global } = useGlobalInfo();

  const { accountAPIId, showDisableAutomationPrompt } =
    useAutomationSettingsContext();

  const { shouldShowDisableAutomationPrompt } = useAutoDisableAutomationPrompt({
    shareSettingType: 'new',
  });

  const getNewShareSettings = () => {
    const defaults = social.getAutofeedDefaults({
      apiTypeId: accountAPIs.getAPITypeId({
        accountAPIId,
      }),
    });

    const newShareMode =
      settings.getAutofeedSetting({
        key: 'newShareMode',
        accountAPIId,
      }) ??
      defaults.newShareMode ??
      AUTOFEED_SHARE_MODES.OPTIMAL;

    const bestArticlesNewContentNum =
      settings.getAutofeedSetting({
        key: 'bestArticlesNewContentNum',
        accountAPIId,
      }) ??
      defaults.bestArticlesNewContentNum ??
      0;

    const startPastMinsForNewShare =
      settings.getAutofeedSetting({
        key: 'startPastMinsForNewShare',
        accountAPIId,
      }) ??
      defaults.startPastMinsForNewShare ??
      0;

    let articleAgeUnit;
    let articleAgeValue;
    if (startPastMinsForNewShare % (24 * 60) === 0) {
      articleAgeUnit = ARTICLE_AGE_UNITS.DAYS;
      articleAgeValue = startPastMinsForNewShare / (24 * 60);
    } else {
      articleAgeUnit = ARTICLE_AGE_UNITS.HOURS;
      articleAgeValue = startPastMinsForNewShare / 60;
    }

    return {
      newShareMode,
      bestArticlesNewContentNum,
      articleAgeUnit,
      articleAgeValue,
    };
  };

  const newShareSettings = getNewShareSettings();
  const [currentNewShareSettings, setCurrentNewShareSettings] =
    useState(newShareSettings);

  const handleSaveNewShareModeConfirmed = async ({
    newSettings,
    disableAutomation,
  }: {
    newSettings: NewSharesSettings;
    disableAutomation?: boolean;
  }) => {
    const articleAgeMins =
      newSettings.articleAgeUnit === ARTICLE_AGE_UNITS.HOURS
        ? newSettings.articleAgeValue * 60
        : newSettings.articleAgeValue * 24 * 60;

    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    updatedSettings.newShareMode = newSettings.newShareMode;
    updatedSettings.bestArticlesNewContentNum =
      newSettings.bestArticlesNewContentNum;
    updatedSettings.startPastMinsForNewShare = articleAgeMins;

    if (disableAutomation) {
      updatedSettings.autoFeedActive = false;
    }

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
      newShareMixpanel,
    );
  };

  const handleSaveNewShareMode = async (newSettings: NewSharesSettings) => {
    if (
      shouldShowDisableAutomationPrompt({ shareMode: newSettings.newShareMode })
    ) {
      showDisableAutomationPrompt({
        onConfirm: () =>
          handleSaveNewShareModeConfirmed({
            newSettings,
            disableAutomation: true,
          }),
      });
      return;
    }

    handleSaveNewShareModeConfirmed({ newSettings });
  };

  const newShareMixpanel = (
    updatedTracking: FixTypeLater,
    previousTracking: FixTypeLater,
  ) => {
    tracker.track({
      eventName: 'Update Autofeed New Share Settings',
      trackingParams: {
        ...updatedTracking,
        'Social Page': accountAPIs.getAPIPostName({ accountAPIId }),
        'Previous New Share Mode': previousTracking['New Share Mode'],
        'Previous Daily New Shares': previousTracking['Daily New Shares'],
        'Previous Maximum Article Age (new share)':
          previousTracking['Maximum Article Age (new share)'],
      },
    });
  };

  const handleNewShareSettingsChange = (
    newSettings: NewSharesSettings,
    error: boolean = false,
  ) => {
    setCurrentNewShareSettings(newSettings);

    if (isEqual(newSettings, newShareSettings)) {
      resetSettingsState(dispatch);
    } else if (error) {
      setSettingsError(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveNewShareMode(newSettings));
  };

  return (
    <>
      <AutomationSettingsHeader
        title="New shares"
        description="Customize the automation of first-time shares"
      />
      <NewShareModes
        currentNewShareSettings={currentNewShareSettings}
        handleNewShareSettingsChange={handleNewShareSettingsChange}
      />
      <Flex flexDir="column" gap={2}>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/newshares/datasources`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Data Sources"
            description="Customize text and images for your automated shares"
          />
          <SettingsCard.Controls />
        </SettingsCard>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/newshares/keywords`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Keywords"
            description="Use keywords to speficy which content can be shared"
          />
          <SettingsCard.Controls />
        </SettingsCard>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/newshares/prefix-suffix`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Prefix & Suffix"
            description="Add a word or phrase to each automated new share"
          />
          <SettingsCard.Controls />
        </SettingsCard>
      </Flex>
    </>
  );
}

export default NewShares;
