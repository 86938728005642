import {
  Button,
  Image,
  Link,
  Modal,
  Text,
  useToken,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { INSTAGRAM_INSTRUCTIONS_URL } from 'common/constants';

import Steps from './PagesModalSteps';

interface AddIGGraphApiModalProps {
  onClose: () => void;
  onSubmit: () => void;
  onBack: () => void;
}

const AddIGGraphApiModal = ({
  onClose,
  onSubmit,
  onBack,
}: AddIGGraphApiModalProps) => {
  const [red600] = useToken('colors', ['red.600']);

  const redText = {
    color: red600,
  };

  const handleBack = () => {
    onBack();
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Instagram page</Modal.Title>
      </Modal.Header>
      <Modal.Body overflowY="auto" maxHeight="70vh" py={6}>
        <Steps>
          <Steps.Step>
            <Text as="span">
              You’ll be redirected to Facebook, where{' '}
              <span style={redText}>all pages must be selected</span>, including
              the ones that are not connected to Instagram accounts
            </Text>
          </Steps.Step>
          <Image
            src="/img/img-connect-fb-page-select-pages (Cropped).png"
            w="full"
            mb={4}
            borderRadius="md"
          />
          <Steps.Step>
            <Text as="span">
              Once redirected back, you will be able to select the specific
              Instagram accounts you want to use in Echobox
            </Text>
          </Steps.Step>
        </Steps>
      </Modal.Body>
      <Modal.Footer>
        <Link mr="auto" isExternal href={INSTAGRAM_INSTRUCTIONS_URL}>
          Read our guide
        </Link>
        <Button variant="secondary" onClick={handleBack}>
          Back
        </Button>
        <Button onClick={onSubmit}>I understand</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddIGGraphApiModal;
