import { Banner, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import { useErrorTracking } from './useErrorTracking';

interface AccountSuspendedBannerProps {
  invoiceList: string;
}

const AccountSuspendedBanner = ({
  invoiceList,
}: AccountSuspendedBannerProps) => {
  const errorMessage = `Echobox will not share posts. Please resolve ${invoiceList} and send a payment confirmation to`;

  useErrorTracking({ errorMessage, errorType: 'Account suspended' });

  return (
    <Banner type="error">
      <Banner.Tag>Account suspended</Banner.Tag>
      <Banner.Body>
        <Text size="sm">{errorMessage}</Text>
      </Banner.Body>
      <Banner.Link href="mailto:finance@echobox.com">
        finance@echobox.com
      </Banner.Link>
    </Banner>
  );
};

export default AccountSuspendedBanner;
