import {
  Box,
  Checkbox,
  Flex,
  Popover,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useState } from 'react';

import postPropertiesSettings from 'api/postPropertiesSettings';
import { getCurrentPropertyId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES, GLOBAL_INFO_STATES } from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { isNull } from 'common/utility';
import GlobalInfoContext from 'context/GlobalInfoContext';
import InfoCurrentColor from 'svg/InfoCurrentColor';

interface AllowContentCheckboxProps {
  initialAllowContentFromAllDomains: boolean;
}

const AllowContentCheckbox = ({
  initialAllowContentFromAllDomains,
}: AllowContentCheckboxProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [allowContentFromAllDomains, setAllowContentFromAllDomains] = useState(
    initialAllowContentFromAllDomains,
  );
  const { global } = useContext(GlobalInfoContext);

  const handleChange = async () => {
    logger.info('AllowContentCheckbox:handleChange');

    setIsSaving(true);
    const propertyId = getCurrentPropertyId();
    const settingConfig = {
      propertyId,
      settingTypeId: ACCOUNT_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS,
      enabled: !allowContentFromAllDomains,
    };

    try {
      await postPropertiesSettings(settingConfig);

      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        allowUnderSetup: true,
        callback: () => {
          setIsSaving(false);
          addSuccessNotification('Setting saved');
          setAllowContentFromAllDomains((prev) => !prev);
        },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(determineError(error));
      if (isNull(getErrorStatus(error))) {
        const { settingTypeId } = settingConfig;

        logger.error({
          event: 'Save Properties Settings Error',
          properties: {
            location: 'common/savePropertiesSettings',
            arguments: {
              propertyId,
              settingTypeId,
              settingConfig,
            },
          },
          error,
        });
      }
      setIsSaving(false);
      addErrorNotification(errorMessage);
    }
  };

  return (
    <Flex gap={2}>
      <Checkbox
        iconColor="blue.700"
        data-cy-action="editAllowContentFromAllDomains"
        isChecked={allowContentFromAllDomains}
        onChange={handleChange}
        isDisabled={isSaving}
      >
        Content from any domain should appear in Echobox
      </Checkbox>
      <Popover trigger="hover" placement="bottom-start">
        <Popover.Trigger>
          <Box color="gray.500" _hover={{ cursor: 'pointer' }}>
            <InfoCurrentColor size="16px" />
          </Box>
        </Popover.Trigger>
        <Popover.Content>
          <Popover.Header>Cross domain</Popover.Header>
          <Popover.Body>
            Enabling this means articles from any domain can appear. For
            example, if you have articles hosted on www.example.com and
            www.other.com, both articles will be shown in Echobox and be
            selected for suggested or automated shares.
          </Popover.Body>
        </Popover.Content>
      </Popover>
    </Flex>
  );
};

export default AllowContentCheckbox;
