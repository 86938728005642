import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import { API_TIMEOUTS, constructBaseSocialAPIURL } from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export default async function getAPIsConnectSelect({
  requestToken,
}: {
  requestToken: string;
}) {
  if (isUndefined(requestToken)) {
    mandatory('requestToken');
  }

  const guid = metrics.start('getAPIsConnectSelect');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL('v3')}/social/api/connect/select`,
    method: 'GET',
    timeout: API_TIMEOUTS.XXL,
    headers: {
      'X-EBX-ConnectState': requestToken,
    },
  };

  try {
    const response = await axios(config);
    metrics.end('getAPIsConnectSelect', guid);
    logger.debug('getAPIsConnectSelect', { requestToken });
    return response.data;
  } catch (error) {
    metrics.fail('getAPIsConnectSelect', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getAPIsConnectSelect',
      args: { requestToken },
    });
    throw apiError;
  }
}
