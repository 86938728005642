import {
  Button,
  Modal,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';

import getAPIs from 'api/getAPIs';
import getAPIsByTypesAndStates from 'api/getAPIsByTypesAndStates';
import getProperties from 'api/getProperties';
import postPropertySetup from 'api/postPropertySetup';
import { API_PROPERTIES, API_TYPE_IDS } from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import { getURNName } from 'common/social';
import { convertToSocialPageURN, extractPropertyId } from 'common/urn';
import { isNullOrUndefined } from 'common/utility';
import { PagesByPropertyURNType, Property, SocialAPI } from 'types';

import PagesByPropertyAccordion from './PagesByPropertyAccordion';

const apiTypes = [
  API_PROPERTIES[API_TYPE_IDS.FACEBOOK].apiType ?? '',
  API_PROPERTIES[API_TYPE_IDS.TWITTER].apiType ?? '',
  API_PROPERTIES[API_TYPE_IDS.INSTAGRAM].apiType ?? '',
  API_PROPERTIES[API_TYPE_IDS.LINKEDIN].apiType ?? '',
  API_PROPERTIES[API_TYPE_IDS.TIKTOK].apiType ?? '',
];
const apiStates = ['UNDER_SETUP'];

type PagesByPropertyType = {
  [id: string]: SocialAPI[];
};

interface PagesSetupModalInterface {
  onClose: () => void;
}

const getPagesSortedByProperty = (pages: SocialAPI[]) => {
  const pagesByProperty: PagesByPropertyType = {};

  pages.forEach((page) => {
    if (page.propertyURN) {
      if (isNullOrUndefined(pagesByProperty[page.propertyURN])) {
        pagesByProperty[page.propertyURN] = [];
      }
      pagesByProperty[page.propertyURN].push(page);
    }
  });

  return pagesByProperty;
};

const PagesSetupModal = ({ onClose }: PagesSetupModalInterface) => {
  const [socialPagesByProperty, setSocialPagesByProperty] =
    useState<PagesByPropertyType>({});
  const [properties, setProperties] = useState<Record<string, Property>>({});
  const [selectedPages, setSelectedPages] = useState<PagesByPropertyURNType>(
    {},
  );
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useToast();

  const isSubmitDisabled = Object.keys(selectedPages).length === 0;

  useEffect(() => {
    const getPagesAndProperties = async () => {
      try {
        const socialAPIURNs = await getAPIsByTypesAndStates({
          apiTypes,
          apiStates,
        });
        const underSetupSocialPagesMap = await getAPIs({ socialAPIURNs });
        const underSetupSocialPages = Object.values(underSetupSocialPagesMap);
        const pagesByProperty = getPagesSortedByProperty(underSetupSocialPages);

        const propertyIds = Object.keys(pagesByProperty).map((propertyURN) =>
          extractPropertyId(propertyURN).toString(),
        );
        const retrievedProperties = await getProperties({ propertyIds });

        setSocialPagesByProperty(pagesByProperty);
        setProperties(retrievedProperties);
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      }
    };

    getPagesAndProperties();
  }, []);

  const handleCheckboxToggle = (page: SocialAPI) => {
    setErrorMessage('');

    const socialPageURN = convertToSocialPageURN(
      getURNName({ apiTypeId: page.apiTypeId }),
      page.accountAPIId,
    );

    const isPageAlreadySelected = !isNullOrUndefined(
      selectedPages[socialPageURN],
    );

    const newSelectedPages = { ...selectedPages };
    if (isPageAlreadySelected) {
      delete newSelectedPages[socialPageURN];
    } else {
      newSelectedPages[socialPageURN] = page;
    }

    setSelectedPages(newSelectedPages);
  };

  const handleSubmitPages = async () => {
    setErrorMessage('');

    try {
      const socialPages = Object.values(selectedPages);

      if (socialPages.length === 0) {
        throw new Error('At least one page must be selected');
      }

      if (socialPages.every((page) => page.propertyURN === undefined)) {
        throw new Error('At least one page must have a property URN');
      }

      let propertyURN: string = '';
      const socialPageURNs: string[] = [];

      socialPages.forEach((page) => {
        const socialPageURN = convertToSocialPageURN(
          getURNName({ apiTypeId: page.apiTypeId }),
          page.accountAPIId,
        );

        socialPageURNs.push(socialPageURN);

        if (page.propertyURN !== undefined) {
          propertyURN = page.propertyURN;
        }
      });

      await postPropertySetup({
        propertyURN,
        socialAPIURNs: socialPageURNs,
      });

      toast({
        variant: 'success',
        title: 'Successfully submitted pages for setup',
      });
      onClose();
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="large">
      <Modal.Header>
        <Modal.Title>Pages Setup</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PagesByPropertyAccordion
          properties={properties}
          pagesByProperty={socialPagesByProperty}
          selectedPages={selectedPages}
          onCheckboxToggle={handleCheckboxToggle}
        />
      </Modal.Body>
      <Modal.Footer justifyContent="space-between">
        <Text color="red.700" fontWeight="bold">
          {errorMessage}
        </Text>
        <Button isDisabled={isSubmitDisabled} onClick={handleSubmitPages}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PagesSetupModal;
