import * as React from 'react';

const variants = {
  light: 'btn-light',
  dark: 'btn-dark text-light',
  secondary: 'btn-secondary',
  danger: 'btn-danger',
  link: 'btn-link',
};

const noop = () => {};

export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean;
  loadingEl?: React.ReactNode;
  size?: 'sm' | 'lg' | 'xl' | '';
  variant?: keyof typeof variants;
}

/**
 * React component for rendering button
 * Accepts the following props (all optional unless specified otherwise):
 * 1) className           - classes to be added to button
 * 2) type                - supports button, submit and reset values. Defaults to button
 * 3) size                - supports sm, lg and xl values. Defaults to empty string
 * 4) variant             - supports light, dark, secondary and danger variants. Defaults to light
 * 5) children (required) - could be text or another react component
 * 6) onClick             - function to be called on button click
 * 7) loading             - boolean value. If true, a spinner is shown with the button while the onclick function is running
 * 8) loadingEl           - Element to be shown while onclick is running. Could be a string or a react component
 * 9) disabled            - boolean value for disabling or enabling the button
 * Any other props will be added directly to the button tag (eg id)
 * Also accepts ref which is forwarded to the button element
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = '',
      type = 'button',
      size = '',
      variant = 'light',
      children,
      onClick = noop,
      loading = false,
      loadingEl = '',
      disabled = false,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const isMounted = React.useRef(true);

    /**
     * Function to show loading spinner when button is clicked
     * This only runs when loading prop is passed as true
     * isMounted ref is used to make sure react doesn't update state when component unmounts during onClick execution
     * @param {*} e - the actual click event
     */
    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      setIsLoading(true);
      await onClick(e);
      if (isMounted.current) {
        setIsLoading(false);
      }
    };

    /**
     * Use effect hook to set up cleanup function for when component unmounts
     * The cleanup function sets the value of isMounted ref to false which can then be used to avoid updating state after component unmounts
     * An example for this would be an async call which returns response after the user has navigated to another page. Without this,
     * the component would still try to update the isLoading state which is not needed and will throw a warning
     */
    React.useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    const loadingProps = loading
      ? { disabled: isLoading || disabled, onClick: handleClick }
      : { disabled, onClick };

    const buttonProps = {
      className: `btn btn-${size} ${variants[variant]} ${className}`,
      type,
      ...loadingProps,
      ...rest,
    };

    return (
      // Need to be disabled as type cannot be set dynamically. However it is being passed
      // as a default prop so a button will always have a type
      // eslint-disable-next-line react/button-has-type
      <button ref={ref} {...buttonProps}>
        {loading && isLoading && (
          <span
            className="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
          />
        )}
        {loading && isLoading && loadingEl ? loadingEl : children}
      </button>
    );
  },
);

export default Button;
