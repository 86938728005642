import {
  ArrowLeftIcon,
  Flex,
  Link,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface EditorFormProps {
  onBack: () => void;
  children: React.ReactNode;
}
const EditorForm = ({ onBack, children }: EditorFormProps) => {
  return (
    <Flex flexDir="column" gap={8}>
      <Link
        as="button"
        border="none"
        bg="none"
        w="fit-content"
        p={0}
        display="flex"
        alignItems="center"
        gap={1}
        onClick={onBack}
      >
        <ArrowLeftIcon aria-hidden />
        Back
      </Link>
      {children}
    </Flex>
  );
};

export default EditorForm;
