import {
  Box,
  Button,
  ButtonGroup,
  DuplicateCopyIcon,
  EnvelopeIcon,
  Heading,
  Text,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { TRACKER_EMAIL_BODY, TRACKER_SCRIPT } from 'common/constants';
import * as tracker from 'common/tracker';

const TrackerInstallationGuide = ({
  showHeading = true,
}: {
  showHeading?: boolean;
}) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(TRACKER_SCRIPT);
    toast({
      variant: 'success',
      title: 'Copied to clipboard',
    });
    tracker.track({
      eventName: 'Copy Echobox Web Tag',
    });
  };

  const handleEmail = () => {
    window.open(
      `mailto:?subject=Install Echobox Web Tag&body=${TRACKER_EMAIL_BODY}`,
    );
  };

  return (
    <Box>
      {showHeading && (
        <Heading variant="h3" mb={2}>
          Installation guide
        </Heading>
      )}
      <Text size="sm" color="gray.600" mb="3">
        Add this JavaScript snippet before the closing &lt;head&gt; tag on each
        page of your website and after any rel=”canonical” elements are loaded.
      </Text>
      <Box
        py={2}
        px={3}
        bg="gray.100"
        boxShadow="border"
        borderRadius="md"
        maxWidth="440px"
        mb={3}
      >
        <Text size="sm">{TRACKER_SCRIPT}</Text>
      </Box>
      <ButtonGroup spacing={2}>
        <Button
          leftIcon={<DuplicateCopyIcon color="gray.900" />}
          variant="secondary"
          onClick={handleCopy}
        >
          Copy
        </Button>
        <Button
          leftIcon={<EnvelopeIcon color="gray.600" />}
          variant="secondary"
          onClick={handleEmail}
        >
          Email installation guide
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default TrackerInstallationGuide;
