const Search = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8166 13.0391L10.6759 9.89844C11.3556 9.06641 11.7306 8.01875 11.7306 6.875C11.7306 4.18203 9.54789 2 6.85562 2C4.16336 2 2.00171 4.18273 2.00171 6.875C2.00171 9.56727 4.18422 11.75 6.85562 11.75C7.99914 11.75 9.0482 11.3537 9.87906 10.6944L13.0197 13.835C13.1509 13.9461 13.2963 14 13.4392 14C13.5822 14 13.727 13.9451 13.837 13.8352C14.0556 13.6156 14.0556 13.2594 13.8166 13.0391ZM3.12671 6.875C3.12671 4.80734 4.80906 3.125 6.87672 3.125C8.94438 3.125 10.6267 4.80734 10.6267 6.875C10.6267 8.94266 8.94438 10.625 6.87672 10.625C4.80906 10.625 3.12671 8.94219 3.12671 6.875Z"
      fill="currentColor"
    />
  </svg>
);

export default Search;
