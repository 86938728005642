import { memo } from 'react';

import { REACT_PREVENT_RENDER, TAG_TYPES } from 'common/constants';
import { arePropsEqual } from 'common/utility';
import TagSuggestion from 'components/compose/TagSuggestion';
import { Tag, TagMap, TagType } from 'types';

interface TagSuggestionsProps {
  itemTags: TagMap;
  onTagInsert: (args: { tag: Tag; tagType: TagType }) => void;
}

const TagSuggestions = ({ itemTags, onTagInsert }: TagSuggestionsProps) => {
  if (
    itemTags[TAG_TYPES.HASHTAG].length === 0 &&
    itemTags[TAG_TYPES.MENTION].length === 0
  ) {
    return REACT_PREVENT_RENDER;
  }
  return (
    <div className="share_tags preview_element">
      {itemTags[TAG_TYPES.HASHTAG].length > 0 && (
        <div className="share_tags_type">
          <span className="share_tag_title">Hashtags:</span>
          {itemTags[TAG_TYPES.HASHTAG].map((tag) => (
            <TagSuggestion
              tag={tag}
              tagType={TAG_TYPES.HASHTAG}
              key={`${TAG_TYPES.HASHTAG}-${tag.raw}`}
              onTagInsert={onTagInsert}
            />
          ))}
        </div>
      )}
      {itemTags[TAG_TYPES.MENTION].length > 0 && (
        <div className="share_tags_type">
          <span className="share_tag_title">Mentions:</span>
          {itemTags[TAG_TYPES.MENTION].map((tag) => (
            <TagSuggestion
              tag={tag}
              tagType={TAG_TYPES.MENTION}
              key={`${TAG_TYPES.MENTION}-${tag.raw}`}
              onTagInsert={onTagInsert}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default memo(TagSuggestions, arePropsEqual);
