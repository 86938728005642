import { Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

import { compareArrays } from 'common/array';
import { COLLECTION_NAMES } from 'common/constants';
import * as tracker from 'common/tracker';
import DropdownFooter from 'components/home/archive/DropdownFooter';
import Tooltip from 'components/misc/Tooltip';

/**
 * React component for category dropdown filter
 * Accepts the following props
 * 1) categories (required)         - an array storing the category entries in string
 * 2) isLoadingCategory (required)  - a boolean to indicate whether the category entries are being retrieved
 */
const CategoryFilter = (props) => {
  const { categories, displayOptions, isLoadingCategory, eventHandlers } =
    props;
  const { selectedCategories } = displayOptions;

  const [userSelected, setUserSelected] = useState(
    Array.isArray(selectedCategories) ? selectedCategories : [],
  );

  useEffect(() => {
    if (typeof $ !== 'undefined') {
      try {
        const categoryDropdown = $('#categoryDropdown').parent();
        categoryDropdown.unbind();
        categoryDropdown.on('hidden.bs.dropdown', () => {
          const selected = userSelected;
          if (!compareArrays(selected, selectedCategories)) {
            tracker.track({
              eventName: 'Update Categories Filter',
              trackingParams: {
                Origin: 'Archive',
              },
            });
            eventHandlers.handleSelectedCategoriesChanged({
              collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
              userSelected: selected,
            });
          }
        });
      } catch (error) {
        // Avoid breaking tests
      }
    }
  });

  useEffect(() => {
    setUserSelected(selectedCategories);
  }, [selectedCategories, setUserSelected]);
  const handleApply = () => {
    $('[data-cy-id="categoryFilter"]').dropdown('toggle');
  };

  const handleClear = () => {
    setUserSelected([]);
  };

  const handleCheck = (event) => {
    const value = event.target.value;
    setUserSelected((prev) => {
      if (prev.includes(value)) {
        const next = prev.filter((item) => item !== value);
        return next;
      }
      const next = prev.concat(value);
      return next;
    });
  };

  const isActive = Array.isArray(userSelected) && userSelected.length > 0;
  const activeClass = isActive ? 'filter-active' : '';
  const hasCategoryEntries = Array.isArray(categories) && categories.length > 0;
  const tooltipMessage = isLoadingCategory
    ? 'Loading categories ...'
    : 'There are no categories for the shown articles';

  return (
    <div
      className="dropdown my-1"
      data-tip
      data-for="category-dropdown"
      data-iscapture="true"
      style={{ maxHeight: '200px', maxWidth: '300px' }}
    >
      <Tooltip
        id="category-dropdown"
        className="react-tooltip-small"
        label={tooltipMessage}
        isDisabled={hasCategoryEntries}
      >
        <Button
          className={`filter ${activeClass} mr-2 analytics-filter`}
          id="categoryDropdown"
          data-cy-id="categoryFilter"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-boundary="viewport"
          disabled={!hasCategoryEntries}
          variant="secondary"
          borderRadius="3px"
          fontSize="13px"
          px={2.5}
          py={1}
          height="29.43px"
          lineHeight="165%"
          boxShadow="0px 2px 5px rgba(38, 72, 121, 0.25), 0px 1px 0px rgba(38, 72, 121, 0.15), 0px 0px 0px 1px rgba(38, 72, 121, 0.15), 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0)"
        >
          Category
          <svg
            width="9"
            height="5"
            viewBox="0 0 9 5"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: '6px' }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.34056 0.256985L3.8978 1.75336C3.8978 1.75336 4.36444 2.28627 4.81775 2.28627C5.27106 2.28627 5.73636 1.75476 5.73636 1.75476L7.29494 0.256985C7.4664 0.0903181 7.69169 -0.00154286 7.92515 1.96097e-05C8.15861 0.00158207 8.38277 0.0964518 8.5522 0.2654C8.63526 0.346152 8.70168 0.443975 8.74736 0.552815C8.79303 0.661656 8.81698 0.779198 8.81773 0.898167C8.81848 1.01714 8.79601 1.135 8.75171 1.24447C8.70742 1.35394 8.64223 1.45268 8.5602 1.53458L5.44438 4.65214C5.27518 4.82015 5.05041 4.91221 4.81775 4.90878C4.58555 4.91184 4.36132 4.81981 4.19245 4.65214L1.0753 1.53458C0.993266 1.45268 0.928082 1.35394 0.883784 1.24447C0.839487 1.135 0.817017 1.01714 0.817767 0.898167C0.818517 0.779198 0.84247 0.661656 0.888144 0.552815C0.933817 0.443975 1.00024 0.346152 1.0833 0.2654C1.25273 0.0964518 1.47689 0.00158207 1.71035 1.96097e-05C1.94381 -0.00154286 2.1691 0.0903181 2.34056 0.256985Z"
            />
          </svg>
        </Button>
      </Tooltip>
      <div
        className="dropdown-menu"
        aria-labelledby="categoryDropdown"
        style={{
          minWidth: '200px',
        }}
      >
        <form>
          <div
            style={{
              minWidth: '200px',
              maxHeight: '190px',
              maxWidth: '350px',
              overflow: 'auto',
            }}
          >
            {hasCategoryEntries &&
              categories.map((category) => (
                <Fragment key={category}>
                  <label
                    className="dropdown-item text_unselectable checkbox line-height-24"
                    htmlFor={category}
                  >
                    <input
                      type="checkbox"
                      id={category}
                      className="check"
                      value={category}
                      checked={userSelected.includes(category)}
                      onChange={handleCheck}
                    />
                    <label className="label" htmlFor={category}>
                      {category}
                    </label>
                  </label>
                </Fragment>
              ))}
          </div>
          <div className="dropdown-divider" />
          <DropdownFooter eventHandlers={{ handleApply, handleClear }} />
        </form>
      </div>
    </div>
  );
};

CategoryFilter.propTypes = {
  categories: PropTypes.array.isRequired,
  displayOptions: PropTypes.object.isRequired,
  isLoadingCategory: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleSelectedCategoriesChanged: PropTypes.func.isRequired,
  }).isRequired,
};

export default CategoryFilter;
