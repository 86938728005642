import {
  Card,
  Toggle,
  useDisclosure,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as api from 'api/api';

import { ACCOUNT_SETTING_TYPES, GLOBAL_INFO_STATES } from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import * as settings from 'common/settings';
import * as tracker from 'common/tracker';
import * as utility from 'common/utility';
import * as tracking from 'helpers/tracking';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import ConfirmTurnOffApprovals from 'components/settings/automation/ConfirmTurnOffApprovals';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import { AutofeedSettings } from 'types';

interface ApprovalsToggleProps {
  approvalsActive: boolean;
  setApprovalState: (
    currentSettings: Partial<AutofeedSettings>,
    approvalState: boolean,
  ) => Partial<AutofeedSettings>;
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  setErrorMessage: (message: string) => void;
  automationActive: boolean;
}

function ApprovalsToggle({
  approvalsActive,
  setApprovalState,
  isSaving,
  setIsSaving,
  setErrorMessage,
  automationActive,
}: ApprovalsToggleProps) {
  const { global } = useGlobalInfo();

  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { accountAPIId } = useAutomationSettingsContext();

  const handleApprovalChange = () => {
    setIsSaving(true);

    const newApprovalValue = !approvalsActive;

    // Save the updated autofeed settings
    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = setApprovalState(
      existingSettings,
      newApprovalValue,
    );

    const settingConfig = {
      accountAPIId,
      settingTypeId: ACCOUNT_SETTING_TYPES.AUTO_FEED,
      enabled: true,
      dataJSON: updatedSettings,
    };

    api
      .postAPIsSettingsType(settingConfig)
      .then(() => {
        global.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          callback: () => {
            setIsSaving(false);
            toast({
              title: 'Setting updated',
              description: 'Changes can take up to 8 hours to take effect',
              variant: 'success',
            });

            const updatedTracking = tracking.getAutofeedSettingsTrackingParams({
              autofeedSettings: settings.getAutofeedSettings({ accountAPIId }),
            });
            tracker.track({
              eventName: 'Toggle Autofeed Approvals',
              trackingParams: {
                ...updatedTracking,
                'Approvals Toggle New Status': newApprovalValue ? 'On' : 'Off',
                'Previous Approvals Toggle Status': approvalsActive
                  ? 'On'
                  : 'Off',
                'Account API Id': settingConfig.accountAPIId,
              },
            });
          },
        });
      })
      .catch((error) => {
        if (utility.isNull(getErrorStatus(error))) {
          logger.error({
            event: 'Save Autofeed Approval Settings Error',
            properties: {
              location: 'common/settings/autofeed',
              arguments: {
                settingConfig,
              },
            },
            error,
          });
        }

        setIsSaving(false);
        setErrorMessage(getErrorMessage(determineError(error)));
        addErrorNotification('Error saving setting');
      });
  };

  const showModal = approvalsActive && automationActive;

  return (
    <>
      <Card justifyContent="space-between">
        <Card.Content>
          <Card.Title>Approvals</Card.Title>
          <Card.Description>
            Automated shares require your approval before being shared
          </Card.Description>
        </Card.Content>
        <Toggle
          isChecked={approvalsActive}
          isDisabled={isSaving}
          labelPosition="left"
          onChange={showModal ? onOpen : handleApprovalChange}
          data-cy-id="approvalStatus"
          data-cy-action="approvalToggle"
          data-cy-attribute={`isActive:${approvalsActive}`}
        >
          {approvalsActive ? 'On' : 'Off'}
        </Toggle>
      </Card>
      {isOpen && (
        <ConfirmTurnOffApprovals
          isOpen={isOpen}
          onClose={onClose}
          handleApprovalChange={handleApprovalChange}
        />
      )}
    </>
  );
}

export default ApprovalsToggle;
