import { IconButton } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getCurrentAccountAPI } from 'common/accountAPIs';
import PageImage from 'components/misc/PageImage';
import { useGlobalInfo } from 'context/GlobalInfoContext';

import { useHeader } from './HeaderContext';

const MobileMenu = () => {
  const { showMobileMenu, onMobileMenuToggle } = useHeader();
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const accountAPI = getCurrentAccountAPI({
    globalInfo,
  });

  return (
    <IconButton
      onClick={onMobileMenuToggle}
      aria-label={showMobileMenu ? 'close menu' : 'open menu'}
      icon={
        <PageImage accountAPI={accountAPI}>
          <PageImage.SocialLogo size={4} svgSize={3} />
          <PageImage.Image size={7} />
        </PageImage>
      }
      size="sm"
      display={{ base: 'inline-block', md: 'none' }}
      _active={{ bg: 'transparent' }}
      _hover={{ bg: 'transparent' }}
    />
  );
};

export default MobileMenu;
