const Title = () => (
  <div className="setting-part d-flex settings-part">
    <div className="mr-auto">
      <div className="ft-13 text-500">Domain Switching</div>
    </div>
    <div
      className="btn btn-light align-self-center"
      data-toggle="collapse"
      data-target="#collapseDomainSwitching"
      aria-expanded="true"
      aria-controls="collapseDomainSwitching"
      data-cy-action="editDomainSwitching"
    >
      Edit
    </div>
  </div>
);

export default Title;
