import PubSub from 'pubsub-js';

import { NOTIFICATION_TIMEOUT } from 'common/config';
import { getErrorMessage } from 'common/errorHandling';
import { NotificationLevel } from 'components/misc/NotificationSystem';
import { NOTIFICATION_ADD } from 'pubsub/topics';

export function addNotification(
  message: string,
  level: NotificationLevel,
  timeout = NOTIFICATION_TIMEOUT,
) {
  PubSub.publish(NOTIFICATION_ADD, {
    message,
    level,
    duration: timeout * 1000,
  });
}

export function addErrorNotification(
  error: unknown,
  timeout = NOTIFICATION_TIMEOUT,
) {
  addNotification(getErrorMessage(error), 'error', timeout);
}

export function addSuccessNotification(
  message: string,
  timeout = NOTIFICATION_TIMEOUT,
) {
  addNotification(message, 'success', timeout);
}
