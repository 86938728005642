import { createContext, useContext } from 'react';

import { isUndefined } from 'common/utility';
import type { StepNumber } from 'pages/OnBoardingSetup';
import { Page } from 'types';

export interface OnboardingContextInterface {
  currentStep: StepNumber;
  maximumStep: number;
  onStepChange: (newStep: number) => void;
  nextButtonDisabled: boolean;
  setNextButtonDisabled: (disabled: boolean) => void;
  haveChangesBeenMade: boolean;
  setHaveChangesBeenMade: (changesMade: boolean) => void;
  gaViews: ReadonlyArray<Page>;
  setGaViews: (pages: ReadonlyArray<Page>) => void;
  isTestWebsiteTagOpen: boolean;
  setIsWebsiteTagOpen: (isOpen: boolean) => void;
  setIsWebsiteTagError: (isError: boolean) => void;
  onSaveRef: React.MutableRefObject<(() => Promise<void>) | null>;
}

export const OnboardingContext =
  createContext<OnboardingContextInterface | null>(null);

export const useOnboardingSetupContext = () => {
  const context = useContext(OnboardingContext);

  if (isUndefined(context)) {
    throw new Error(
      'useOnboardingSetupContext must be used within an OnboardingSetup Provider',
    );
  }

  if (context === null) {
    throw new Error(
      'useOnboardingSetupContext must be initialized by an OnboardingSetup Provider',
    );
  }

  return context;
};
