import {
  HStack,
  IgPostIcon,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { POST_TYPES, SOCIAL_CHANNELS } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { FixTypeLater } from 'types';
import InstagramSocialChannel from './InstagramSocialChannel';

interface InstagramShareTypeProps {
  mediaItem: FixTypeLater;
  guid: string;
}
const InstagramShareType = ({ mediaItem, guid }: InstagramShareTypeProps) => {
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });

  if (
    !(postType === POST_TYPES.VIDEO && socialChannel === SOCIAL_CHANNELS.FEED)
  ) {
    return <InstagramSocialChannel guid={guid} mediaItem={mediaItem} />;
  }

  return (
    <VStack alignItems="flex-start" spacing="3px" mb="31px">
      <Text size="xs" color="#a3acb9">
        Share type
      </Text>
      <HStack
        spacing={1}
        py="7px"
        px="2"
        bg="#f2f5f7"
        borderRadius="3px"
        boxShadow="inset 0px 0px 0px 1px #e3e8ee"
        w="full"
      >
        <>
          <IgPostIcon size="5" color="#697386" />
          <Text size="xs" color="#697386" fontWeight="500">
            Post
          </Text>
        </>
      </HStack>
    </VStack>
  );
};

export default InstagramShareType;
