import { Center, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getAPITypeId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import PreviewHeader from 'components/compose/PreviewHeader';

interface PreviewLoadingProps {
  accountAPIId: number;
  guid: string;
}

const PreviewLoading = (props: PreviewLoadingProps) => {
  const { accountAPIId, guid } = props;

  const apiTypeId = getAPITypeId({ accountAPIId });
  const messageClass = `share_message d-flex border-bottom preview_element account_${accountAPIId}
                    ${
                      apiTypeId === API_TYPE_IDS.TWITTER
                        ? 'share_message_twitter'
                        : ''
                    }`;

  return (
    <div
      className="card"
      data-cy-preview
      data-cy-id={guid}
      data-cy-attribute="isLoading:true"
      key={`preview-${guid}`}
    >
      <div data-cy-attribute={`accountAPIId:${accountAPIId}`}>
        <PreviewHeader
          accountAPIId={accountAPIId}
          guid={guid}
          apiTypeId={apiTypeId}
          sponsorName=""
        />
        <div className={messageClass}>
          <div className="mx-auto my-3 op-50" role="status" />
        </div>
        <div className="share_tags preview_element my-3">
          <Center>
            <Spinner size="lg" />
          </Center>
          <span className="text-loading" />
          <div className="text-center text-400 my-4">
            Fetching article details from your website...
          </div>
        </div>
        <div className="share_imagery d-flex">
          <div className="mx-auto my-3 op-50" role="status" />
        </div>
      </div>
    </div>
  );
};

export default PreviewLoading;
