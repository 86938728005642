import {
  Alert,
  Button,
  Flex,
  FormControl,
  Grid,
  Heading,
  Modal,
  Toggle,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import {
  clearFeatureFlagOverrides,
  FEATURE_FLAG_CONFIG,
  getFeatureFlagDefaultValues,
  getFeatureFlagOverrides,
  setFeatureFlagOverrides,
} from 'common/featureFlags';
import { FeatureFlag } from 'types';

interface FeatureFlagModalProps {
  onClose: () => void;
}

const FeatureFlagModal = ({ onClose }: FeatureFlagModalProps) => {
  const defaultValues = getFeatureFlagDefaultValues();
  const overrides = getFeatureFlagOverrides();
  const [pendingOverrides, setPendingOverrides] =
    useState<Partial<Record<FeatureFlag, boolean>>>(overrides);

  const handleReset = () => {
    clearFeatureFlagOverrides();
    setPendingOverrides({});
  };

  const handleApply = () => {
    // Clear existing flags first, before setting each pending toggle
    clearFeatureFlagOverrides();
    (Object.entries(pendingOverrides) as [FeatureFlag, boolean][]).forEach(
      ([flag, value]) => {
        setFeatureFlagOverrides(flag, value);
      },
    );

    // As feature flags do not live in React state, we need to force a full refresh to apply changes.
    window.location.reload();
  };

  return (
    <Modal isOpen onClose={onClose} size="medium">
      <Modal.Header>
        <Modal.Title>Override feature flags</Modal.Title>
        <Modal.Subtitle>
          Configure which feature flags you wish to enable/disable.
        </Modal.Subtitle>
      </Modal.Header>
      <Modal.Body>
        <Flex direction="column" gap={4}>
          <Grid
            as="ul"
            px={0}
            py={2}
            gap={2}
            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
          >
            {(
              Object.entries(FEATURE_FLAG_CONFIG) as [
                FeatureFlag,
                { name: string },
              ][]
            ).map(([flag, { name }]) => (
              <Grid.GridItem as="li" key={flag} w="full">
                <Toggle
                  isChecked={pendingOverrides[flag] ?? defaultValues[flag]}
                  onChange={(e) =>
                    setPendingOverrides({
                      ...pendingOverrides,
                      [flag]: e.target.checked,
                    })
                  }
                >
                  <FormControl.FormLabel whiteSpace="nowrap" mb="0" mx="4">
                    <Heading variant="h5">{name}</Heading>
                  </FormControl.FormLabel>
                </Toggle>
              </Grid.GridItem>
            ))}
          </Grid>
        </Flex>
        <Alert variant="info">
          <Alert.Title fontSize="sm">
            After applying these changes, the page will refresh.
          </Alert.Title>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="tertiary" onClick={handleReset}>
          Reset to defaults
        </Button>
        <Button variant="primary" onClick={handleApply}>
          Apply changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeatureFlagModal;
