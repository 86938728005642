import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { generateGuid } from 'common/string';
import { isDefined } from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * GET /properties/{propertyURN}/traffic - retrieve GA traffic apis for a property
 *
 * @param {{
 *  propertyURN: string
 * }}
 * @return {Promise<Record<string, Record<string, import('types').TrafficAPI>>>}
 * 
 * Example response
 * 
 * 
  {
    "trafficAPIs": [
      {
        "trafficAPIURN": "urn:traffic:ga_api:UA-169410785-1:ga_view:34065",
        "propertyURN": "urn:property:102",
        "trafficAPIState": "ACTIVE",
        "trafficAPIType": "GA_CORE",
        "requiresReconnect": false,
        "disconnectReason": null
      }
    ]
  }
 * 
 */

export default async function getTrafficAPIs({
  propertyURN = mandatory('propertyURN'),
} = {}) {
  const guid = metrics.start('getTrafficAPIs');
  const clientAuthHeader = getClientServiceRequestHeaders();
  const config = {
    url: `${constructBaseCoreAPIURL('v1')}/properties/${propertyURN}/traffic`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: clientAuthHeader,
  };
  logger.info(`API:getTrafficAPIs /v1/properties/${propertyURN}/traffic`);

  try {
    const responses = await axios(config);
    // Convert response to an object containing one response object per account
    const response = {};
    responses.data.trafficAPIs.forEach((entry) => {
      const trafficAPIURN = entry.trafficAPIURN;
      if (!isDefined(response[propertyURN])) {
        response[propertyURN] = {};
      }
      response[propertyURN][trafficAPIURN] = entry;
      response[propertyURN][trafficAPIURN].notifications = [];
      if (entry.requiresReconnect) {
        response[propertyURN][trafficAPIURN].notifications.push({
          id: generateGuid(),
          type: 'error',
          message: 'Requires reconnect',
          link: '/settings',
        });
      }
    });
    metrics.end('getTrafficAPIs', guid);
    logger.debug('getTrafficAPIs', { propertyURN });
    return response;
  } catch (error) {
    metrics.fail('getTrafficAPIs', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getTrafficAPIs',
      args: { propertyURN },
    });
    throw apiError;
  }
}
