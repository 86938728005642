import React from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import { GLOBAL_INFO_STATES } from 'common/constants';
import { isRunningCypressTests, isRunningTests } from 'common/utility';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { GlobalInfo } from 'types';

const BeamerWidget = () => {
  const { global } = useGlobalInfo();
  const isReady = global.globalInfoState === GLOBAL_INFO_STATES.READY;
  const globalInfo = global.getGlobalInfo();

  React.useEffect(() => {
    if (
      isReady &&
      !window.beamer_config &&
      !isRunningTests() &&
      !isRunningCypressTests()
    ) {
      const { name, emailAddress, userId } = globalInfo.user as GlobalInfo.User;
      const { language, timezone } = getCurrentProperty({
        globalInfo,
      }) as GlobalInfo.Property;

      window.beamer_config = {
        product_id: 'dzDyqSsc10392',
        selector: '#beamerButton',
        display: 'in-app',
        display_position: 'bottom-right',
        filter_by_url: true,
        bounce: false,
        user_firstname: name,
        user_email: emailAddress,
        user_id: userId,
        user_language: language,
        user_timezone: timezone,
      };
      if (document.getElementById('#beamerPopperScript') === null) {
        const body = document.getElementsByTagName('body')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'beamerPopperScript';
        script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
        script.defer = true;
        body.appendChild(script);
      }
    }
  }, [isReady, globalInfo]);

  return null;
};

export default BeamerWidget;
