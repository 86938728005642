import { useContext, useState } from 'react';

import authUpdateUserEmail from 'api/aws/authUpdateUserEmail';
import authVerifyUserEmail from 'api/aws/authVerifyUserEmail';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { addSuccessNotification } from 'common/notifications';
import { ebxMessage } from 'common/string';
import { isNull, isNullOrUndefined } from 'common/utility';
import Button from 'components/misc/Button';
import SettingsError from 'components/settings/SettingsError';
import GlobalInfoContext from 'context/GlobalInfoContext';

const UserEmail = () => {
  const context = useContext(GlobalInfoContext);

  /**
   * Initial state
   */

  const user = context.global.getGlobalInfo().user;

  const currentEmail = user.emailAddress;
  const [errorMessage, setErrorMessage] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  /**
   * Event handlers
   */

  const handleErrorClose = () => {
    setErrorMessage('');
  };

  const handleKeyPress = (event) => {
    const key = event.key;
    if (key === 'Enter') {
      if (!showVerification) {
        handleSave();
      } else {
        handleVerify();
      }
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    setShowVerification(false);
  };

  const handleSave = async () => {
    if (newEmail.trim() === '') {
      setNewEmail('');
      setIsSaving(false);
      setErrorMessage(
        ebxMessage('Please enter the email address you would like to change.'),
      );
      return;
    }

    const email =
      isNullOrUndefined(newEmail) || newEmail === '' ? currentEmail : newEmail;

    setIsSaving(true);
    try {
      await authUpdateUserEmail({ email });
      setIsSaving(false);
      setErrorMessage('');
      setShowVerification(true);
    } catch (error) {
      setIsSaving(false);
      const message = getErrorMessage(determineError(error));
      if (isNull(getErrorStatus(error))) {
        logger.error({
          event: 'handleSave Error',
          properties: {
            location: 'components/settings/user/UserEmail',
          },
          error,
        });
      }
      setErrorMessage(message.trim());
    }
  };

  const handleVerify = async () => {
    if (verificationCode.trim() === '') {
      setErrorMessage(
        ebxMessage('Please enter the verification code from your email.'),
      );
      return;
    }
    setIsSaving(true);
    try {
      await authVerifyUserEmail({ verificationCode });
      setIsSaving(false);
      setErrorMessage('');
      addSuccessNotification('Change saved');
    } catch (error) {
      setIsSaving(false);
      const message = getErrorMessage(determineError(error));
      if (isNull(getErrorStatus(error))) {
        logger.error({
          event: 'handleSave Error',
          properties: {
            location: 'components/settings/user/UserEmail',
          },
          error,
        });
      }
      setErrorMessage(message.trim());
    }
  };

  /**
   * Render method
   */

  return (
    <div className="box mb-3">
      <div className="d-flex box-header">
        <h3 className="align-self-center">Your Email</h3>
      </div>
      <div className="setting-part pb-3 UserDetails">
        <SettingsError
          errorMessage={errorMessage}
          eventHandlers={{ handleErrorClose }}
        />
        <div className="EmailAddress mt-3">
          {!showVerification && (
            <label htmlFor="newEmail">
              <div className="label text-500 pb-1">Email Address</div>
              <input
                placeholder={currentEmail}
                className="input_medium"
                name="newEmail"
                id="newEmail"
                data-cy-input="email"
                onChange={(event) => setNewEmail(event.target.value)}
                onKeyPress={handleKeyPress}
                value={newEmail}
                type="email"
                disabled={isSaving}
                style={{ minWidth: '240px' }}
              />
            </label>
          )}
          {showVerification && (
            <>
              <div className="mb-3">
                Please check messages for{' '}
                <span className="text-500">{newEmail}</span> for a verification
                code and enter it below to confirm your change of email address.
              </div>
              <label htmlFor="verificationCode">
                <div className="label text-500 pb-1">Verification Code</div>
                <input
                  className="input_medium"
                  name="verificationCode"
                  id="verificationCode"
                  data-cy-input="verificationCode"
                  onChange={(event) => setVerificationCode(event.target.value)}
                  onKeyPress={handleKeyPress}
                  value={verificationCode}
                  type="text"
                  disabled={isSaving}
                  style={{ minWidth: '240px' }}
                />
              </label>
              <div className="mt-1 mb-3">
                <a href="#" onClick={handleReset}>
                  Request new verification code
                </a>
              </div>
            </>
          )}
        </div>
        <hr className="op-30 mt-3 mb-2" />
        <Button
          variant="dark"
          onClick={showVerification ? handleVerify : handleSave}
          disabled={isSaving}
          data-cy-action="saveNameEmail"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserEmail;
