import PropTypes from 'prop-types';

import { KEYNAMES } from 'common/constants';
import useOnKey from 'hooks/useOnKey';

const AddBody = (props) => {
  useOnKey({
    targetKey: KEYNAMES.ESCAPE,
    onKeyUp: () => {
      eventHandlers.handleAddCancel();
    },
  });
  useOnKey({
    targetKey: KEYNAMES.ENTER,
    onKeyUp: () => {
      eventHandlers.handleAddDomain();
    },
  });

  const { domain, errorMessage, isSaving, eventHandlers } = props;

  let inputClass = '';
  if (isSaving) {
    inputClass += ' saving_locked';
  }
  if (errorMessage !== '') {
    inputClass += ' input-with-error';
  }

  return (
    <div className="modal-body lh-20">
      <div className="text-500 mb-2 d-flex">Domain URL</div>
      <input
        type="text"
        data-cy-input="domain"
        name="domain"
        className={`input_medium w-100 ${inputClass}`}
        value={domain}
        onChange={eventHandlers.handleChange}
        disabled={isSaving}
        placeholder="www.example.com"
      />
      {errorMessage !== '' && (
        <div className="save-state save failed mt-1">{errorMessage}</div>
      )}
    </div>
  );
};

AddBody.propTypes = {
  domain: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleAddCancel: PropTypes.func.isRequired,
    handleAddDomain: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default AddBody;
