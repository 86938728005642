import {
  Box,
  Button,
  Collapse,
  DeleteTrashIcon,
  Flex,
  FormControl,
  Heading,
  HStack,
  IconButton,
  InfoIcon,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useDropzone } from 'react-dropzone';

interface OpsToolsBillingTSVFormProps {
  tsvFile: File | null;
  onSetTSVFile: (file: File | null) => void;
  errorMessage: string | null;
  isDisabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
}

const OpsToolsBillingTSV = ({
  tsvFile,
  onSetTSVFile,
  errorMessage,
  isDisabled,
  isLoading,
  onSubmit,
}: OpsToolsBillingTSVFormProps) => {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: {
      'text/tab-separated-values': ['.tsv'],
    },
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noDrag: false,
    onDrop: (acceptedFiles) => {
      onSetTSVFile(acceptedFiles[0]);
    },
    preventDropOnDocument: true,
  });

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();

    if (tsvFile != null) {
      onSubmit();
    }
  };

  return (
    <Box mt={2}>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormControl.FormLabel htmlFor="propertyId">
            Upload Billing TSV
          </FormControl.FormLabel>
          <VStack>
            <Box
              bg="gray.100"
              py={10}
              px={15}
              border="1px"
              borderStyle="dashed"
              borderColor="gray.300"
              borderRadius="base"
              w="full"
              opacity={isDragActive ? 0.5 : undefined}
              {...getRootProps()}
            >
              <Heading variant="h5" mb={3}>
                Drag and drop file
              </Heading>
              <input {...getInputProps()} />
              <Button variant="secondary" onClick={open}>
                Select .TSV File
              </Button>
            </Box>
            <Collapse in={tsvFile !== null} animateOpacity>
              <HStack m={0} p={0}>
                <Box
                  border="1px"
                  borderColor="gray.300"
                  borderRadius="base"
                  p={2}
                  mt={0}
                >
                  <Flex justify="space-between" align="center">
                    <Heading variant="h5">{tsvFile?.name}</Heading>
                    <IconButton
                      aria-label="Remove"
                      icon={<DeleteTrashIcon color="gray.600" />}
                      onClick={() => onSetTSVFile(null)}
                    />
                  </Flex>
                  {errorMessage && (
                    <Box mt={4}>
                      <Heading variant="h5" color="red.600">
                        <InfoIcon mr={1} />
                        {errorMessage}
                      </Heading>
                    </Box>
                  )}
                </Box>
                <Button
                  type="submit"
                  variant="secondary"
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  loadingText="Submitting"
                  flexShrink={0}
                >
                  Submit
                </Button>
              </HStack>
            </Collapse>
          </VStack>
        </FormControl>
      </form>
    </Box>
  );
};

export default OpsToolsBillingTSV;
