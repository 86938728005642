/* eslint no-use-before-define: "off" */

import queryString from 'query-string';

import { FRONTEND_PATHS } from 'common/constants';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * List of exported methods
 */

export { doesQueryParamExist, getPage, getQueryParam, isOnPage };

/**
 * doesQueryParamExist
 *
 * Returns true if the specified query parameter exists
 */

function doesQueryParamExist({ param = mandatory('param'), location } = {}) {
  if (isNullOrUndefined(location) || isNullOrUndefined(location.search)) {
    return false;
  }
  const query = queryString.parse(location.search);
  return isDefined(query[param]);
}

/**
 * getPage
 *
 * Returns a FRONTEND_PAGES entry derived from the current path
 */

function getPage({ location }) {
  if (isNullOrUndefined(location) || isNullOrUndefined(location.pathname)) {
    return null;
  }
  let page = null;
  Object.keys(FRONTEND_PATHS).forEach((key) => {
    if (isNull(page)) {
      FRONTEND_PATHS[key].forEach((entry) => {
        const regex = new RegExp(`^${entry}$`, 'gi');
        if (location.pathname.match(regex)) {
          page = key;
        }
      });
    }
  });
  return page;
}

/**
 * getQueryParam
 *
 * Returns the specified query parameter if it exists
 * Note that if the parameter is specified with no value then we return
 * the value as {true} to differentiate between undefined parameters
 * e.g. /path?flag would return {true} for the 'flag' param
 */

function getQueryParam({ param = mandatory('param'), location } = {}) {
  if (isNullOrUndefined(location) || isNullOrUndefined(location.search)) {
    return null;
  }
  const query = queryString.parse(location.search);
  if (isUndefined(query[param])) {
    return null;
  }
  return isNull(query[param]) ? true : query[param];
}

/**
 * isOnPage
 * @param {{
 *   page: string;
 *   location: object;
 * }} args
 * @returns {boolean} true if the location pathname matches any of the valid
 * alternatives that are mapped to the specified page
 */
function isOnPage({ page = mandatory('page'), location } = {}) {
  if (
    isNullOrUndefined(location) ||
    isNullOrUndefined(location.pathname) ||
    isNullOrUndefined(FRONTEND_PATHS[page])
  ) {
    return false;
  }
  return FRONTEND_PATHS[page].some((path) => {
    const regex = new RegExp(`^${path}$`, 'gi');
    return location.pathname.match(regex);
  });
}
