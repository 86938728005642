import {
  Button,
  Flex,
  FormControl,
  InfoIcon,
  Input,
  Modal,
  Text,
  Tooltip,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import postPropertiesUser from 'api/postPropertiesUser';
import { getAPIsByStateAndType, getPropertyName } from 'common/accountAPIs';
import {
  API_STATES,
  GLOBAL_INFO_STATES,
  MIXPANEL_ORIGIN,
  PERMISSION_TYPES,
} from 'common/constants';
import { getErrorMessage, getErrorStatus } from 'common/errorHandling';
import * as tracker from 'common/tracker';
import GlobalInfoContext from 'context/GlobalInfoContext';
import * as validator from 'email-validator';
import { ChangeEvent, useContext, useState } from 'react';
import { GlobalInfo, Permission, PermissionTypeId, SocialAPI } from 'types';
import UserPages from './UserPages';
import UserProperty from './UserProperty';
import UserRole from './UserRole';

export interface PropertyDetails {
  propertyName: string;
  isPageless: boolean;
}

interface UserAddProps {
  isAddingUser: boolean;
  globalInfo: GlobalInfo.GlobalInfo;
  currentPropertyId: number;
  currentPropertyName: string;
  onUserAdd: () => void;
}

const UserAddNew = ({
  isAddingUser,
  globalInfo,
  currentPropertyId,
  currentPropertyName,
  onUserAdd,
}: UserAddProps) => {
  const isPropertyPageless = (propertyId: number): boolean => {
    const apisForThisProperty: SocialAPI[] = getAPIsByStateAndType({
      propertyId,
      apiStateId: [
        API_STATES.ACTIVE,
        API_STATES.BAD_AUTH,
        API_STATES.UNDER_SETUP,
      ],
      globalInfo,
    });

    return apisForThisProperty.length === 0;
  };

  const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [networkError, setNetworkError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedAPIs, setSelectedAPIs] = useState<number[]>([]);
  const [isEditingPages, setIsEditingPages] = useState(false);
  const [propertyRole, setPropertyRole] = useState<PermissionTypeId>(
    PERMISSION_TYPES.ADMIN,
  );
  const [selectedProperties, setSelectedProperties] = useState<
    Record<number, PropertyDetails>
  >({
    [currentPropertyId]: {
      propertyName: currentPropertyName,
      isPageless: isPropertyPageless(currentPropertyId),
    },
  });

  const { global } = useContext(GlobalInfoContext);
  const toast = useToast();

  const apiList: SocialAPI[] = getAPIsByStateAndType({
    propertyId: parseInt(globalInfo.current.propertyId!),
    apiStateId: [
      API_STATES.ACTIVE,
      API_STATES.BAD_AUTH,
      API_STATES.UNDER_SETUP,
    ],
    globalInfo,
  });

  const adminPropertiesIds: number[] = globalInfo.user.permissions
    .filter(
      (permission) => permission.permissionTypeId === PERMISSION_TYPES.ADMIN,
    )
    .map((permissions) => permissions.propertyId);

  const adminProperties: Record<string, PropertyDetails> = {};
  adminPropertiesIds.forEach((propertyId) => {
    const propertyName = getPropertyName({ propertyId });
    if (propertyName !== null) {
      adminProperties[propertyId] = {
        propertyName,
        isPageless: isPropertyPageless(propertyId),
      };
    }
  });

  const isOnlyCurrentPropertySelected =
    Object.keys(selectedProperties).length === 1 &&
    currentPropertyId in selectedProperties;

  const isPropertySelectAllowed =
    selectedAPIs.length === apiList.length ||
    selectedAPIs.length === 0 ||
    propertyRole === PERMISSION_TYPES.ADMIN ||
    (selectedAPIs.length > 0 && !isOnlyCurrentPropertySelected);

  const isAddUserButtonDisabled =
    !isValidEmail ||
    name === '' ||
    emailAddress === '' ||
    name.toLowerCase() === emailAddress.toLowerCase() ||
    Object.keys(selectedProperties).length === 0 ||
    (propertyRole !== PERMISSION_TYPES.ADMIN &&
      selectedAPIs.length === 0 &&
      isOnlyCurrentPropertySelected);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsValidEmail(validator.validate(event.target.value));
    setEmailAddress(event.target.value.trim());
  };

  const handleRoleChange = (role: PermissionTypeId) => {
    setPropertyRole(role);

    if (role !== PERMISSION_TYPES.ADMIN) {
      const updatedSelectedProperties = Object.fromEntries(
        Object.entries(selectedProperties).filter(
          ([, value]) => value.isPageless === false,
        ),
      );
      setSelectedProperties(updatedSelectedProperties);
    }
  };

  const handlePagesCancel = () => {
    setIsEditingPages(false);
  };

  const handlePagesSave = (selectedPages: number[]) => {
    setSelectedAPIs(selectedPages);
    setIsEditingPages(false);
  };

  const declareSuccess = () => {
    onUserAdd();

    toast({
      variant: 'success',
      title: 'User added',
      description: getToastDescription(),
    });

    tracker.track({
      eventName: 'Add User',
      trackingParams: {
        'New User Email': emailAddress,
        Origin: MIXPANEL_ORIGIN.SETTINGS,
      },
    });

    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
      variable: 'isAddingUser',
      message: 'User added',
    });

    setNetworkError(null);
    setIsLoading(false);
  };

  const handleUserAddMultipleProperties = async (
    propertyPermissionId: PermissionTypeId,
  ) => {
    let permissionsOnProperty: Array<Permission>;

    for (const propertyId of Object.keys(selectedProperties)) {
      permissionsOnProperty = [
        {
          propertyId,
          permissionTypeId: propertyPermissionId,
        },
      ];

      if (propertyPermissionId === PERMISSION_TYPES.VIEW_ONLY) {
        const apisForThisProperty: SocialAPI[] = getAPIsByStateAndType({
          propertyId: parseInt(propertyId),
          apiStateId: [
            API_STATES.ACTIVE,
            API_STATES.BAD_AUTH,
            API_STATES.UNDER_SETUP,
          ],
          globalInfo,
        });

        apisForThisProperty.forEach((accountAPI) => {
          permissionsOnProperty.push({
            propertyId,
            accountAPIId: accountAPI.accountAPIId,
            permissionTypeId: PERMISSION_TYPES.EDITOR,
          });
        });
      }

      await postPropertiesUser({
        propertyId,
        name,
        username: emailAddress,
        emailAddress,
        permissionsOnProperty,
      });
    }
  };

  const handleUserAdd = async () => {
    setIsLoading(true);

    const propertyPermissionId =
      propertyRole.toString() === PERMISSION_TYPES.EDITOR.toString()
        ? PERMISSION_TYPES.VIEW_ONLY
        : PERMISSION_TYPES.ADMIN;

    try {
      if (Object.keys(selectedProperties).length > 1) {
        await handleUserAddMultipleProperties(propertyPermissionId);
      } else {
        const propertyId = Object.keys(selectedProperties)[0];

        if (!isOnlyCurrentPropertySelected) {
          const otherPropertyPages: SocialAPI[] = getAPIsByStateAndType({
            propertyId: parseInt(propertyId),
            apiStateId: [
              API_STATES.ACTIVE,
              API_STATES.BAD_AUTH,
              API_STATES.UNDER_SETUP,
            ],
            globalInfo,
          });

          setSelectedAPIs(
            otherPropertyPages.map((accountAPI) => accountAPI.accountAPIId),
          );
        }

        const permissionsOnProperty: Permission[] = [
          {
            propertyId,
            permissionTypeId: propertyPermissionId,
          },
        ];

        if (propertyPermissionId === PERMISSION_TYPES.VIEW_ONLY) {
          selectedAPIs.forEach((accountAPIId) => {
            permissionsOnProperty.push({
              propertyId,
              accountAPIId,
              permissionTypeId: PERMISSION_TYPES.EDITOR,
            });
          });
        }

        await postPropertiesUser({
          propertyId,
          name,
          username: emailAddress,
          emailAddress,
          permissionsOnProperty,
        });
      }

      declareSuccess();
    } catch (error) {
      setIsLoading(false);

      if (getErrorStatus(error) === 401) {
        setNetworkError(
          `Authentication Error: ${getErrorMessage(error)}, logging out`,
        );
      } else {
        setNetworkError(getErrorMessage(error));
      }
    }
  };

  const getRoleTooltip = (): string => {
    if (propertyRole === PERMISSION_TYPES.ADMIN) {
      return 'Administrators have access to all pages and settings';
    }
    return 'Editors have access to selected pages without settings.';
  };

  const getPropertyToolTip = (): string => {
    if (!isPropertySelectAllowed) {
      return 'Change the editor role to "All pages" to add a user to multiple properties.';
    }
    return 'Select additional Properties to add this user to.';
  };

  const getToastDescription = (): string => {
    const firstProperty = Object.values(selectedProperties)[0];
    const propertyName = firstProperty
      ? firstProperty.propertyName
      : '1 Property';

    const role =
      propertyRole === PERMISSION_TYPES.ADMIN ? 'Administator' : 'Editor';

    if (Object.keys(selectedProperties).length === 1) {
      return `${name} was successfully added to ${propertyName} as an ${role}.`;
    }
    return `${name} was successfully added to ${Object.keys(selectedProperties).length} Properties as an ${role}.`;
  };

  return (
    <Modal isOpen={isAddingUser} onClose={() => onUserAdd()} size="small">
      <Modal.Header>
        <Modal.Title>Invite new user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={6} direction="column">
          <FormControl isInvalid={false}>
            <FormControl.FormLabel>Name</FormControl.FormLabel>
            <Input
              onChange={handleNameChange}
              isInvalid={name !== '' && name === emailAddress}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormControl.FormLabel>Email address</FormControl.FormLabel>
            <Input onChange={handleEmailChange} isInvalid={!isValidEmail} />
          </FormControl>
          <Flex mb={4} alignItems="center">
            <FormControl>
              <FormControl.FormLabel display="flex" alignItems="center" gap={1}>
                Role
                <Tooltip
                  placement="top-start"
                  label={getRoleTooltip()}
                  whiteSpace="pre-wrap"
                >
                  <InfoIcon color="graphic.secondary" />
                </Tooltip>
              </FormControl.FormLabel>
              <UserRole
                availableAPIs={apiList}
                propertyRole={propertyRole}
                selectedPages={selectedAPIs}
                selectedProperties={selectedProperties}
                isOnlyCurrentPropertySelected={isOnlyCurrentPropertySelected}
                onRoleChange={(role: PermissionTypeId) =>
                  handleRoleChange(role)
                }
                onPagesClick={() => setIsEditingPages(!isEditingPages)}
              />
            </FormControl>
            <FormControl>
              <FormControl.FormLabel>&nbsp;</FormControl.FormLabel>
              {isEditingPages && (
                <UserPages
                  availableAPIs={apiList}
                  selectedAPIs={selectedAPIs}
                  eventHandlers={{
                    handlePagesSave,
                    handlePagesCancel,
                  }}
                />
              )}
            </FormControl>
          </Flex>
          {Object.keys(adminProperties).length > 1 && (
            <Flex mb={4} alignItems="center">
              <FormControl>
                <FormControl.FormLabel
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  Property
                  <Tooltip
                    whiteSpace="nowrap"
                    maxWidth="1000px"
                    placement="top-start"
                    label={getPropertyToolTip()}
                  >
                    <InfoIcon color="graphic.secondary" />
                  </Tooltip>
                </FormControl.FormLabel>
                <UserProperty
                  propertyRole={propertyRole}
                  isPropertySelectAllowed={isPropertySelectAllowed}
                  adminProperties={adminProperties}
                  selectedProperties={selectedProperties}
                  onPropertyChange={(properties) =>
                    setSelectedProperties(properties)
                  }
                />
              </FormControl>
            </Flex>
          )}
        </Flex>
        {networkError && (
          <Text color="red.600" size="sm" mt={3}>
            {networkError}
          </Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onUserAdd}>
          Cancel
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading || isAddUserButtonDisabled}
          onClick={handleUserAdd}
        >
          Add user
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserAddNew;
