import $ from 'jquery';
import { ChangeEvent, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
  getFilteredPropertiesAndPages,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import { CROSS_POST_LIMIT, CROSS_POST_PROPERTIES } from 'common/config';
import { FRONTEND_METRICS } from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import { addSuccessNotification } from 'common/notifications';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import SocialPagesDropdown from 'components/compose/socialpages/SocialPagesDropdown';
import SocialPagesHeader from 'components/compose/socialpages/SocialPagesHeader';
import {
  addSocialPage,
  removeSocialPage,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import {
  composeBoxShareToAPIs,
  getComposeBoxPostPreviews,
  getNumPosts,
} from 'helpers/composeBox';
import { getCommonTrackingParams } from 'helpers/tracking';
import { useComposeBoxSharesStore } from 'state/composeBoxShares';
import { PostPreview } from 'types';

async function createPage({
  accountAPIId,
  postPreviews,
}: {
  accountAPIId: number;
  postPreviews: PostPreview[];
}) {
  // Otherwise add page(s) as required
  const visiblePostPreviews = getComposeBoxPostPreviews(postPreviews);

  // Bail out if the post preview is already visible
  const isPageAlreadyInPreviews = visiblePostPreviews.some(
    (preview) => preview.accountAPIId === accountAPIId,
  );
  if (isPageAlreadyInPreviews) {
    return;
  }

  let guid: string | undefined;
  if (visiblePostPreviews.length > 0) {
    // Default to the first visible media item
    guid = visiblePostPreviews[0].guid;
  } else {
    // Otherwise use the first hidden media item
    guid = postPreviews[0].guid;
  }

  if (guid !== undefined) {
    const index = postPreviews.findIndex((item) => item.guid === guid);
    if (index !== -1) {
      const sourceItem = postPreviews[index].mediaItem;
      Compose.sharePostTo({
        sourceItem,
        accountAPIId,
      });
    }
  }
}

const SocialPages = () => {
  const { dispatch, postPreviews, passedCrossPostLimit } =
    useComposeBoxContext();
  const { setTotalShares } = useComposeBoxSharesStore(
    (state) => ({ setTotalShares: state.setTotalShares }),
    shallow,
  );
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if ($('#socialPagesDropdown').parent) {
      $('#socialPagesDropdown')
        .parent()
        .on('show.bs.dropdown', () => {
          // Set focus on search box after opening dropdown
          window.setTimeout(() => {
            $('#pageSearch').focus();
          }, 0);
        });
      $('#socialPagesDropdown')
        .parent()
        // Casting event as `any` because we are using an seemingly undocumented property `clickEvent`
        .on('hide.bs.dropdown', (event: any) => {
          // Prevent mouse click causing close if click occurs within dropdown
          if (event.clickEvent?.target) {
            const isInsideSocialPages =
              $(event.clickEvent.target).parents('[data-cy-id="socialPages"]')
                .length === 1;
            const isInsideHeader = $(event.clickEvent.target).hasClass(
              'dropdown w-100 show',
            );
            const isInsideDropdown = $(event.clickEvent.target).hasClass(
              'dropdown-select-pages',
            );
            if (isInsideSocialPages && !(isInsideHeader && !isInsideDropdown)) {
              event.preventDefault();
            }
          }
        });
    }
  }, []);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const trackAddingSocialPage = ({
    accountAPIId,
  }: {
    accountAPIId: number;
  }) => {
    const mediaItem = postPreviews[0].mediaItem;
    const networkName = getSocialNetworkName({
      apiTypeId: getAPITypeId({ accountAPIId }),
    });
    const pageName = getAPIPostName({ accountAPIId });
    const trackingParams = getCommonTrackingParams({ mediaItem });

    trackingParams['Added Social Network'] = networkName;
    trackingParams['Added Social Page'] = pageName;
    tracker.track({
      eventName: 'Add Social Page',
      trackingParams,
    });
  };

  const enableSocialPage = ({
    accountAPIId,
    totalAdditionalAPIsToShare = 0,
  }: {
    accountAPIId: number;
    totalAdditionalAPIsToShare?: number | undefined;
  }) => {
    metrics.mark(FRONTEND_METRICS.ADD_SOCIAL_PAGE, accountAPIId);

    const visiblePostPreviews = getComposeBoxPostPreviews(postPreviews);
    const numPosts =
      getNumPosts(visiblePostPreviews) + totalAdditionalAPIsToShare;
    setTotalShares(numPosts + 1);
    const isCrossingPostLimit =
      numPosts >= CROSS_POST_LIMIT &&
      CROSS_POST_PROPERTIES.includes(getCurrentPropertyId());
    if (isCrossingPostLimit && !passedCrossPostLimit) {
      addSuccessNotification(
        'Your shares have been collapsed to one per Social Network. Please remove pages if you wish to edit individually.',
      );
    }

    if (
      !isCrossingPostLimit &&
      !postPreviews.filter(
        (preview) => preview.accountAPIId === accountAPIId && preview.isHidden,
      ).length
    ) {
      // When we know we are creating a new page (and not unhiding one), we don't want to do this
      // outside of the reducer, as it has side-effects (and reducers should be pure).
      createPage({
        accountAPIId,
        postPreviews,
      });
    } else {
      dispatch(addSocialPage({ accountAPIId, totalAdditionalAPIsToShare }));
    }

    trackAddingSocialPage({ accountAPIId });
  };

  const trackRemovingSocialPage = ({
    accountAPIId,
  }: {
    accountAPIId: number;
  }) => {
    const mediaItem = postPreviews[0].mediaItem;
    const networkName = getSocialNetworkName({
      apiTypeId: getAPITypeId({ accountAPIId }),
    });
    const pageName = getAPIPostName({ accountAPIId });
    const trackingParams = getCommonTrackingParams({ mediaItem });

    trackingParams['Removed Social Network'] = networkName;
    trackingParams['Removed Social Page'] = pageName;
    tracker.track({
      eventName: 'Remove Social Page',
      trackingParams,
    });
  };

  const disableSocialPage = ({
    accountAPIId,
    deselectAll = false,
  }: {
    accountAPIId: number;
    deselectAll?: boolean | undefined;
  }) => {
    const numPosts = getNumPosts(getComposeBoxPostPreviews(postPreviews));
    setTotalShares(deselectAll ? 0 : numPosts - 1);

    dispatch(removeSocialPage({ accountAPIId, deselectAll }));

    trackRemovingSocialPage({ accountAPIId });
  };

  const handleSelectAll = (propertyId: number, apiTypeId: number) => {
    const firstMediaItem = postPreviews[0].mediaItem;
    const mediaStates = MediaItem.getMediaStatesByAccountAPIId({
      mediaItem: firstMediaItem,
    });
    const postType = MediaItem.getPostType({ mediaItem: firstMediaItem });
    const shareToAPIs = composeBoxShareToAPIs(postPreviews);

    return () => {
      logger.info(
        `SocialPages:handleSelectAll - property id ${propertyId} api type ${apiTypeId}`,
      );

      const filteredPropertiesAndPages = getFilteredPropertiesAndPages({
        apiTypeId,
        checkedAPIs: shareToAPIs,
        mediaStates,
        postType,
        propertyId,
        searchText,
      })[0].accountAPIs;
      const areAllSelected = filteredPropertiesAndPages.every(
        (accountAPI) => accountAPI.isChecked,
      );
      let accountAPIIds;
      if (areAllSelected) {
        // If all accounts are currently selected, deselect all other than the current account API
        const currentAccountAPIId = getCurrentAccountAPIId();
        accountAPIIds = filteredPropertiesAndPages
          .filter(
            (accountAPI) => accountAPI.accountAPIId !== currentAccountAPIId,
          )
          .map((accountAPI) => accountAPI.accountAPIId);
      } else {
        // Otherwise select all currently-unselected accounts
        accountAPIIds = filteredPropertiesAndPages
          .filter((accountAPI) => !accountAPI.isChecked)
          .map((accountAPI) => accountAPI.accountAPIId);
      }

      accountAPIIds.forEach((accountAPIId) => {
        if (areAllSelected)
          disableSocialPage({ accountAPIId, deselectAll: true });
        else
          enableSocialPage({
            accountAPIId,
            totalAdditionalAPIsToShare: accountAPIIds.length - 1,
          });
      });
    };
  };

  const handleSocialPagesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const accountAPIId = Number(event.target.id.split('_')[1]);
    const isChecked = event.target.checked;
    logger.info(`SocialPages:handleSocialPagesChange - api ${accountAPIId}`);

    if (isChecked) {
      enableSocialPage({
        accountAPIId,
      });
    } else {
      disableSocialPage({ accountAPIId });
    }
  };

  const shareToAPIs = composeBoxShareToAPIs(postPreviews);

  return (
    <div className="article_title px-3 py-2 w-100" data-cy-id="socialPages">
      <div className="d-flex">
        <div className="dropdown w-100">
          <SocialPagesHeader shareToAPIs={shareToAPIs} />
          <SocialPagesDropdown
            postPreviews={postPreviews}
            onSearchChange={handleSearchChange}
            onSelectAll={handleSelectAll}
            onSocialPagesChange={handleSocialPagesChange}
            searchText={searchText}
          />
        </div>
      </div>
    </div>
  );
};

export default SocialPages;
