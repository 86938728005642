/**
 * setUpGlobalData - set up user and property info in global info
 *
 * @param {object} cognitoResponse - contains details of the authenticated user
 * @param {function} setGlobalInfo - function used for setting global info
 * @return {object} - user and property information
 *
 * Example response
 * {
 *   user: {object}
 *   properties: {array of objects}
 *   current: {
 *     propertyId: {integer},
 *     accountAPIId: {integer}
 *   }
 * }
 */
import { raiseAPIErrors } from 'common/accountAPIs';
import { setCurrentPropertyAndAPI } from 'common/currentPropertyAndAPI';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import { isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import populateGlobalInfo from 'process/populateGlobalInfo';

export default async function setUpGlobalData({
  userDetail = mandatory('userDetail'),
  setGlobalInfo = mandatory('setGlobalInfo'),
}) {
  try {
    const data = { user: userDetail };
    // Load property and page information
    const propertiesResponse = await populateGlobalInfo({
      user: data.user,
      // Enabled to allow the user to see errors with any feeds.
      getFeedDetails: true,
    });
    data.properties = propertiesResponse.properties;
    data.current = setCurrentPropertyAndAPI({
      propertiesAndAPIs: propertiesResponse.properties,
    });

    // Store global data in local storage
    setGlobalInfo({
      properties: data.properties,
      current: data.current,
      user: data.user,
    });

    // Raise property and page-level errors as required
    raiseAPIErrors({
      property: data.properties[data.current.propertyId],
    });

    // All done
    return data;
  } catch (error) {
    const handledError = determineError(error);
    if (isNull(getErrorStatus)) {
      logger.error({
        event: 'Setup Data Error',
        properties: {
          location: 'process/setUpGlobalData.js',
          arguments: {
            userDetail,
          },
        },
        handledError,
      });
    }
    throw handledError;
  }
}
