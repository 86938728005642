import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

function PhotoEditorErrorBoundaryFallback() {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => setIsOpen(false);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="small">
      <Modal.Header>
        <Modal.Title>An unexpected error occurred</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm">
          Please save your changes and refresh the page before continuing with
          your work.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button ml="auto" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PhotoEditorErrorBoundaryFallback;
