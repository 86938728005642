import { Box, Button, ListItem } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { useOnboardingSetupContext } from 'context/OnboardingContext';

enum STEP_MODE {
  COMPLETED,
  CURRENT,
  NEXT,
}

const primary600 = '#3371e6';
const white = '#ffffff';
const gray300 = '#dbe3f0';
const gray600 = '#596a87';

const getStepNumberColor = (mode: STEP_MODE) => {
  switch (mode) {
    case STEP_MODE.COMPLETED:
      return white;
    case STEP_MODE.CURRENT:
      return primary600;
    case STEP_MODE.NEXT:
      return gray600;
    default:
      // should never happen
      return gray600;
  }
};

const getStepNameColor = (mode: STEP_MODE) => {
  switch (mode) {
    case STEP_MODE.COMPLETED:
      return gray600;
    case STEP_MODE.CURRENT:
      return primary600;
    case STEP_MODE.NEXT:
      return gray600;
    default:
      // should never happen
      return gray600;
  }
};

const getStepMode = (stepNumber: number, currentStep: number): STEP_MODE => {
  if (stepNumber < currentStep) {
    return STEP_MODE.COMPLETED;
  }

  if (stepNumber === currentStep) {
    return STEP_MODE.CURRENT;
  }

  return STEP_MODE.NEXT;
};

interface StepProps {
  children: React.ReactNode;
  stepNumber: number;
  id: string;
}

const Step = ({ children, stepNumber, id }: StepProps) => {
  const onboardingContext = useOnboardingSetupContext();

  const { currentStep, maximumStep, onStepChange } = onboardingContext;

  const mode = getStepMode(stepNumber, Math.min(currentStep, maximumStep));

  const isDisabled = stepNumber > maximumStep || maximumStep === 6;

  return (
    <ListItem
      display="grid"
      placeItems="center"
      gridTemplateColumns="auto 1fr"
      gap={1}
      justifyItems="start"
      mb={6}
      _after={{
        content: 'counter(step)',
        counterIncrement: 'step',
        gridColumnStart: 1,
        gridRowStart: 1,
        w: 7,
        h: 7,
        background: mode === STEP_MODE.COMPLETED ? primary600 : white,
        border: `2px solid ${mode === STEP_MODE.NEXT ? gray300 : primary600}`,
        borderRadius: '24px',
        display: 'grid',
        placeItems: 'center',
        placeSelf: 'center',
        color: getStepNumberColor(mode),
        fontWeight: 500,
        fontSize: '14px',
      }}
      _before={{
        content: '""',
        h: 4,
        w: '2px',
        transform: 'translateY(-26px)',
        ml: 'calc(50% - 1px)',
        gridColumnStart: 1,
        gridRowStart: 1,
        borderRadius: '4px',
        bg: mode === STEP_MODE.NEXT ? gray300 : primary600,
        _firstChild: {
          display: 'none',
        },
      }}
    >
      <Button
        isDisabled={isDisabled}
        onClick={() => onStepChange(stepNumber)}
        variant="link"
        data-cy-action={id}
        // Possibly a bug in the component library, so overridding `padding` prop.
        // See https://github.com/chakra-ui/chakra-ui/issues/3293
        padding={undefined}
        py={1}
        px={3}
        _focus={{ boxShadow: 'none', outline: 'none' }}
        _focusVisible={{ boxShadow: 'outline' }}
        _disabled={{ opacity: 1 }}
      >
        <Box
          as="span"
          fontSize="md"
          color={getStepNameColor(mode)}
          _hover={{
            color: isDisabled ? undefined : primary600,
          }}
        >
          {children}
        </Box>
      </Button>
    </ListItem>
  );
};

export default Step;
