import { POST_TYPES } from 'common/constants';
import {
  getDimensionLimits,
  getValidationMessages,
  getVideoDurationLimits,
  isAspectRatioValid,
  isValidAspectRatioMandatory,
} from 'common/social';
import type { APITypeId, SocialChannel } from 'types';

/**
 * Validate the video file against social network limitations.
 */
function validateVideoFile({
  apiTypeId,
  file,
  socialChannel,
}: {
  apiTypeId: APITypeId;
  file: File;
  socialChannel: SocialChannel;
}): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.onloadedmetadata = (event: any) => {
        URL.revokeObjectURL(event.target.src);

        const error = validateVideo({
          apiTypeId,
          duration: event.target.duration,
          videoDimensions: {
            height: event.target.videoHeight,
            width: event.target.videoWidth,
          },
          socialChannel,
        });

        if (error) {
          reject(error);
          return;
        }

        resolve();
      };
      video.onerror = () => {
        reject('Video failed to load. Please select a video file.');
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      console.log({ e });
      reject(e);
    }
  });
}

const validateVideo = ({
  apiTypeId,
  videoDimensions,
  duration,
  socialChannel,
}: {
  /**
   * The api type id the video will be posted to.
   */
  apiTypeId: APITypeId;
  /**
   * Video dimensions in pixels.
   */
  videoDimensions: {
    width: number;
    height: number;
  };
  /**
   * Duration in seconds.
   */
  duration: number;
  /**
   * The social channel the video will be posted to.
   */
  socialChannel: SocialChannel;
}) => {
  return (
    validateVideoAspectRatio({ apiTypeId, videoDimensions, socialChannel }) ||
    validateVideoDuration({ apiTypeId, duration, socialChannel }) ||
    validateVideoDimensions({ apiTypeId, videoDimensions, socialChannel })
  );
};

/**
 * Checks that a video (with the dimensions provided) matches the supported
 * aspect ratio for the api type.
 * @returns An error message, if invalid. Otherwise null.
 */
const validateVideoAspectRatio = ({
  apiTypeId,
  videoDimensions,
  socialChannel,
}: {
  apiTypeId: APITypeId;
  videoDimensions: {
    height: number;
    width: number;
  };
  socialChannel: SocialChannel;
}) => {
  const hasInvalidAspectRatio =
    isValidAspectRatioMandatory({
      apiTypeId,
      postType: POST_TYPES.VIDEO,
    }) &&
    !isAspectRatioValid({
      apiTypeId,
      postType: POST_TYPES.VIDEO,
      imageDimensions: videoDimensions,
      socialChannel,
    });

  if (hasInvalidAspectRatio) {
    return (
      getValidationMessages({
        apiTypeId,
        validationType: 'videoAspectRatio',
        socialChannel,
      }) ?? 'Invalid aspect ratio'
    );
  }

  return null;
};

/**
 * Checks that a video (with the duration provided) matches the supported
 * duration limitations for the api type.
 * @returns An error message, if invalid. Otherwise null.
 */
const validateVideoDuration = ({
  apiTypeId,
  duration,
  socialChannel,
}: {
  apiTypeId: number;
  duration: number;
  socialChannel: SocialChannel;
}) => {
  const videoDurationLimit = getVideoDurationLimits({
    apiTypeId,
    postType: POST_TYPES.VIDEO,
    socialChannel,
  });

  if (videoDurationLimit) {
    const validationMessage = getValidationMessages({
      apiTypeId,
      validationType: 'videoDuration',
      socialChannel,
    });

    const durationInSec = Math.round(duration);

    if (validationMessage) {
      if (
        durationInSec > videoDurationLimit.max ||
        durationInSec < videoDurationLimit.min
      ) {
        return validationMessage;
      }
      return null;
    }

    // If no validation message is provided, construct a sensible default.
    if (videoDurationLimit.min && durationInSec < videoDurationLimit.min) {
      return `The video duration is too short. Please check the duration is longer than ${videoDurationLimit.min} seconds.`;
    }
    if (videoDurationLimit.max && durationInSec > videoDurationLimit.max) {
      if (videoDurationLimit.max > 180) {
        return `Videos should be shorter than ${
          videoDurationLimit.max / 60
        } minutes.`;
      }
      return `Videos should be ${videoDurationLimit.max} seconds or less.`;
    }
  }
  return null;
};

/**
 * Checks that a video (with the dimensions provided) matches the supported
 * min/max width/height limitations for the api type.
 * @returns An error message, if invalid. Otherwise null.
 */
const validateVideoDimensions = ({
  videoDimensions,
  apiTypeId,
  socialChannel,
}: {
  videoDimensions: {
    width: number;
    height: number;
  };
  apiTypeId: number;
  socialChannel: SocialChannel;
}) => {
  const videoDimensionLimits = getDimensionLimits({
    apiTypeId,
    postType: POST_TYPES.VIDEO,
    socialChannel,
  });
  const { width, height } = videoDimensions;

  if (videoDimensionLimits.height) {
    if (
      videoDimensionLimits.height.min &&
      height < videoDimensionLimits.height.min
    ) {
      return `Videos should have a minimum pixel height of ${videoDimensionLimits.height.min}.`;
    }
    if (
      videoDimensionLimits.height.max &&
      height > videoDimensionLimits.height.max
    ) {
      return `Videos should have a maximum pixel height of ${videoDimensionLimits.height.max}`;
    }
  }
  if (videoDimensionLimits.width) {
    if (
      videoDimensionLimits.width.min &&
      width < videoDimensionLimits.width.min
    ) {
      return `Videos should have a minimum pixel width of ${videoDimensionLimits.width.min}.`;
    }
    if (
      videoDimensionLimits.width.max &&
      width > videoDimensionLimits.width.max
    ) {
      return `Videos should have a maximum pixel width of ${videoDimensionLimits.width.max}`;
    }
  }
  return null;
};

const chunkVideoForS3 = (
  file: File,
  noOfParts: number,
  targetPartSizeInBytes: number,
) => {
  const fileSize = file.size;

  const totalParts = Math.ceil(fileSize / targetPartSizeInBytes);

  const chunks = [];

  if (noOfParts !== totalParts)
    throw new Error(
      'Number of parts does not match the total parts. Something is wrong.',
    );

  for (let partNumber = 1; partNumber <= totalParts; partNumber += 1) {
    const start = (partNumber - 1) * targetPartSizeInBytes;
    const end = Math.min(start + targetPartSizeInBytes, fileSize);

    chunks.push(file.slice(start, end));
  }

  return chunks;
};

export { chunkVideoForS3, validateVideo, validateVideoFile };
