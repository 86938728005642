import { Box, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import {
  getCurrentAccountAPIId,
  isCurrentPropertySuspended,
} from 'common/accountAPIs';
import {
  COLLECTION_NAMES,
  FRONTEND_METRICS,
  GLOBAL_INFO_CHANGES,
  UI_MESSAGES,
} from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import * as tracker from 'common/tracker';
import BaseComponent from 'components/BaseComponent';
import Filters from 'components/home/archive/Filters';
import Item from 'components/home/archive/Item';
import Statistics from 'components/home/archive/Statistics';
import AllNotifications from 'components/misc/AllNotifications';
import withFlashMessages from 'context/withFlashMessages';
import withGlobalInfo from 'context/withGlobalInfo';
import { getNetworkAndPageName } from 'helpers/tracking';

class ArticleArchive extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      itemsSelected: [],
    };
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare(COLLECTION_NAMES.ARTICLE_ARCHIVE, {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.LOADING_OR_SELECTING,
    });
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
      { excludeLoadingAndRefreshing: true },
    );
    if (hasChangedToReady) {
      this.initialiseComponent();
    }

    if (prevProps.feedData.isLoading && !this.props.feedData.isLoading) {
      // Stop metrics tracking for loading time
      metrics.measure(FRONTEND_METRICS.ARCHIVE_PAGE_LOAD, {
        additionalTags: {
          timeframe: this.props.feedData.displayOptions.timeFrame,
        },
      });
    }

    this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_ARCHIVE);
  }

  /**
   * Event handlers
   */
  filterChange(eventHandler) {
    // Wrap the provided event handler in a function that will start metrics tracking first
    return (...args) => {
      metrics.mark(FRONTEND_METRICS.ARCHIVE_PAGE_LOAD);
      eventHandler(...args);
    };
  }

  /**
   * Helper methods
   */

  initialiseComponent() {
    this.props.sharedMethods.getFeedData({
      collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
    });
    const accountAPIId = getCurrentAccountAPIId();

    // Start metrics tracking for loading time
    metrics.mark(FRONTEND_METRICS.ARCHIVE_PAGE_LOAD);

    tracker.track({
      eventName: 'Archive Page',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
      },
    });
  }

  /**
   * Render method
   */

  render() {
    const {
      feedData,
      isMobileWindowWidth,
      showMobileMenu,
      eventHandlers,
      sharedMethods,
    } = this.props;
    const renderNoMoreItemsText =
      sharedMethods.renderNoMoreItems()?.props.children;
    const { itemsSelected } = this.state;
    const isLoading =
      feedData.isLoading ||
      this.props.global.isLoading() ||
      this.props.global.isSelecting();
    const suspendedClass = isCurrentPropertySuspended()
      ? 'account_suspended'
      : '';

    const feedClass =
      showMobileMenu && isMobileWindowWidth ? 'd-none d-md-block' : '';

    return (
      <>
        <AllNotifications />
        <div
          className={`archive box ${feedClass}`}
          style={{
            overflow: 'initial',
            marginBottom: '16px',
            display: 'flex',
            flexDirection: 'column',
          }}
          data-cy-id="articleArchive"
          data-cy-attribute={`isLoading:${isLoading}`}
        >
          <Filters
            categories={feedData.categories}
            displayOptions={feedData.displayOptions}
            isLoadingCategory={feedData.isLoadingCategory}
            eventHandlers={{
              handleFilterChange: this.filterChange(
                eventHandlers.handleFilterChange,
              ),
              handleSortChange: this.filterChange(
                eventHandlers.handleSortChange,
              ),
              handleTimeframeChange: this.filterChange(
                eventHandlers.handleTimeframeChange,
              ),
              handleSelectedCategoriesChanged: this.filterChange(
                eventHandlers.handleSelectedCategoriesChanged,
              ),
            }}
          />
          <div>
            <div className="article-archive-header d-none d-md-flex">
              <div className="image d-inline-flex" style={{ width: '70px' }} />
              <span>Article</span>
              <div className="d-flex ml-auto">
                {!isLoading && (
                  <Statistics
                    itemsSelected={itemsSelected.length}
                    totalNumberOfItems={feedData.mediaIds.length}
                  />
                )}
              </div>
            </div>
            {isLoading && (
              <div className="empty_feed text-center my-5 py-5">
                <Spinner size="lg" />
              </div>
            )}
            {!isLoading && feedData.mediaItems.length === 0 && (
              <div className="empty_feed text-center my-5 py-5">
                <img src="/img/icons/ic-search-large.svg" alt="" />
                <div className="cleared_message text-500">
                  {UI_MESSAGES.ARTICLE_ARCHIVE_NO_ITEMS_FOUND}
                </div>
              </div>
            )}
            {!isLoading && feedData.mediaItems.length > 0 && (
              <div className={suspendedClass}>
                {feedData.mediaItems.map((mediaItem) => (
                  <Item
                    mediaItem={mediaItem}
                    sharedMethods={{
                      updateAndSaveCollectionItem:
                        sharedMethods.updateAndSaveCollectionItem,
                    }}
                    key={MediaItem.getMediaId({ mediaItem })}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {!isLoading && (
          <Box
            ml="50%"
            my={6}
            size="sm"
            cursor="default"
            top={5}
            lineHeight="18px"
            color="#828282"
          >
            {renderNoMoreItemsText}
          </Box>
        )}
      </>
    );
  }
}

/* jscpd:ignore-start */
ArticleArchive.propTypes = {
  feedData: PropTypes.object.isRequired,
  isMobileWindowWidth: PropTypes.bool.isRequired,
  showMobileMenu: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleFilterChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
  sharedMethods: PropTypes.shape({
    getFeedData: PropTypes.func.isRequired,
    renderNoMoreItems: PropTypes.func.isRequired,
    updateAndSaveCollectionItem: PropTypes.func.isRequired,
  }).isRequired,
};
/* jscpd:ignore-end */

export default withFlashMessages(withGlobalInfo(ArticleArchive));
