import {
  Box,
  Card,
  Heading,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { SyndFeedMetaDataInterface } from 'types';

interface ContentFeedMetaDataProps {
  metaData: SyndFeedMetaDataInterface;
}

const ContentFeedMetaData = ({ metaData }: ContentFeedMetaDataProps) => {
  return (
    <Card>
      <Card.Content gap={4} flexGrow={1}>
        <Card.Title>Meta Data</Card.Title>

        <VStack alignItems="left">
          {metaData.title && (
            <Box>
              <Heading variant="h6" color="gray.600" mb={1}>
                Title:
              </Heading>
              <Text>{metaData.title}</Text>
            </Box>
          )}
        </VStack>
      </Card.Content>
    </Card>
  );
};

export default ContentFeedMetaData;
