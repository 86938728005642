import { MEDIA_PREFIXES } from 'common/config';

function convertAmazonImageToEchobox(imageURL: string | null) {
  if (imageURL == null) {
    return null;
  }
  if (imageURL.indexOf('amazonaws.com') > -1) {
    return imageURL.replace(
      /https:\/\/echobox-media.s3.amazonaws.com\/social_media_images\/\d+/gi,
      MEDIA_PREFIXES.IMAGE,
    );
  }
  return imageURL;
}

function convertAmazonVideoToEchobox(videoURL: string | null) {
  if (videoURL == null) {
    return null;
  }
  if (videoURL.indexOf('amazonaws.com') > -1) {
    return videoURL.replace(
      /https:\/\/echobox-media.s3.amazonaws.com\/social_media_videos\/\d+/gi,
      MEDIA_PREFIXES.VIDEO,
    );
  }
  return videoURL;
}

/**
 * Converts an Echobox image URL to an Amazon S3 URL.
 * @param imageURL
 */
function convertEchoboxImageToAmazon(imageURL: null): null;
function convertEchoboxImageToAmazon(imageURL: string): string;
function convertEchoboxImageToAmazon(imageURL: string | null) {
  if (imageURL == null) {
    return null;
  }
  if (imageURL.indexOf(MEDIA_PREFIXES.IMAGE) > -1) {
    const propertyId = imageURL.split('/')[1].split('-')[0];
    return imageURL.replace(
      `${MEDIA_PREFIXES.IMAGE}/`,
      `https://echobox-media.s3.amazonaws.com/social_media_images/${propertyId}/`,
    );
  }
  return imageURL;
}

function convertEchoboxVideoToAmazon(videoURL: string | null) {
  if (videoURL == null) {
    return null;
  }
  if (videoURL.indexOf(MEDIA_PREFIXES.VIDEO) > -1) {
    const propertyId = videoURL.split('/')[1].split('-')[0];
    return videoURL.replace(
      `${MEDIA_PREFIXES.VIDEO}/`,
      `https://echobox-media.s3.amazonaws.com/social_media_videos/${propertyId}/`,
    );
  }
  return videoURL;
}

function isEbxImage(imageURL: string | null) {
  if (imageURL == null) {
    return false;
  }
  if (imageURL.indexOf(MEDIA_PREFIXES.IMAGE) > -1) {
    return true;
  }
  return imageURL.indexOf('social_media_images') > -1;
}

function isEbxMedia(mediaURL: string | null) {
  if (mediaURL == null) {
    return false;
  }
  return mediaURL.indexOf('echobox-media') > -1;
}

export {
  convertAmazonImageToEchobox,
  convertAmazonVideoToEchobox,
  convertEchoboxImageToAmazon,
  convertEchoboxVideoToAmazon,
  isEbxImage,
  isEbxMedia,
};
