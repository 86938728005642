import * as React from 'react';

// Error messages

export enum SYNDFEED_VALIDATION_ERROR {
  DUPLICATED_FEED = 'DUPLICATED_FEED',
  NO_PROTOCOL = 'NO_PROTOCOL',
  INVALID_URL = 'INVALID_URL',
  UNRESOLVED_URL = 'UNRESOLVED_URL',
  CANNOT_PARSE_FEED = 'CANNOT_PARSE_FEED',
  NO_VALID_ARTICLES = 'NO_VALID_ARTICLES',
}

export const SYNDFEED_VALIDATION_ERROR_APPEND_MESSAGE: Record<
  SYNDFEED_VALIDATION_ERROR,
  React.ReactNode
> = {
  DUPLICATED_FEED: '',
  NO_PROTOCOL: '',
  INVALID_URL: '',
  UNRESOLVED_URL: ' Please check or contact support for help.',
  CANNOT_PARSE_FEED: (
    <>
      {' '}
      Please make sure it follows the guidelines{' '}
      <a
        rel="noopener noreferrer"
        href="https://www.echobox.com/customertech"
        target="_blank"
        className="form_error_msg_link"
      >
        here
      </a>{' '}
      (see Media Content Feeds) or contact support for help.
    </>
  ),
  NO_VALID_ARTICLES:
    ' Please check at least one article in your feed is online and accessible or contact support for help.',
};

export const FRONTEND_ERROR_MESSAGES = {
  'impersonate-403':
    'User provided does not have permissions to access the Echobox platform.',
  'impersonate-404': 'User with this email address not found',
  'property-tools-id-search':
    'Could not get details for property with this id. Please contact support for help.',
  'property-tools-reactivate-empty-email':
    'Please enter a valid email address.',
  'content-feed-tools-search':
    'Could not get details for content feed with this identifier. Please contact support for help.',
  'article-quote-language-not-supported':
    'Article Quote is not supported for your language',
} as const;
