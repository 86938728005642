/**
 * GET /searchmentions - Get smart mentions based on the provided string
 */

import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { API_PROPERTIES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { MentionSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';
import type { APITypeId } from 'types';

const ResponseSchema = z
  .object({ mentions: z.array(MentionSchema) })
  .describe('getSearchMentions.ResponseSchema');

export default async function getSearchMentions({
  apiTypeId,
  keyword,
}: {
  apiTypeId: APITypeId;
  keyword: string;
}) {
  const guid = metrics.start('getSearchMentions');

  const socialNetworkType = API_PROPERTIES[apiTypeId]?.apiType;

  try {
    const response = await axios.get(
      `${constructBaseSocialAPIURL()}/searchmentions`,
      {
        timeout: API_TIMEOUTS.S,
        headers: getClientServiceRequestHeaders(),
        params: {
          socialNetworkType,
          keyword,
        },
      },
    );
    metrics.end('getSearchMentions', guid);
    logger.debug('getSearchMentions', { apiTypeId, keyword });

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData.mentions;
  } catch (error) {
    metrics.fail('getSearchMentions', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getSearchMentions',
      args: { apiTypeId, keyword },
    });
    throw apiError;
  }
}
