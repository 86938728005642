import {
  ArrowDownIcon,
  Dropdown,
  Flex,
  HStack,
  Heading,
  IconButton,
  MoreOptionsIcon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { getCurrentAccountAPI } from 'common/accountAPIs';
import { PageData } from 'common/constants/socialListening';
import { formatLikesFollowers } from 'common/string';
import PageImage from './PageImage';

const TABLE_HEADINGS = [
  '',
  'Page',
  'Shares',
  'Engagements',
  '%',
  'Followers',
  '',
];

const tdProps = {
  px: 5,
  py: 4,
  color: 'gray.900',
  fontSize: 'sm',
  border: 'none',
  verticalAlign: 'middle',
};

const hoverActiveStyles = {
  bg: 'gray.900-08',
};

const PagesTable = ({
  pages,
  onRemove,
}: {
  pages: PageData[];
  onRemove: (id: string) => void;
}) => {
  const accountAPI = getCurrentAccountAPI();
  const currentPageUsername = accountAPI?.apiAltName?.split('-')[0];

  return (
    <TableContainer>
      <Table
        variant="simple"
        borderRadius="md"
        border="1px"
        borderColor="gray.300"
        overflow="hidden"
        sx={{ borderCollapse: 'separate' }}
      >
        <Thead bg="gray.100">
          <Tr>
            {TABLE_HEADINGS.map((heading, index) => (
              <Th
                key={heading || index}
                textTransform="none"
                px={5}
                py={4}
                color="gray.900"
                fontSize="sm"
                lineHeight="5"
                fontWeight="medium"
                border="none"
                boxShadow="borderBottom"
              >
                <Flex gap={1} alignItems="center">
                  {heading}
                  {heading === 'Engagements' && (
                    <ArrowDownIcon color="gray.600" />
                  )}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody background="base">
          {pages.map((page, index) => (
            <Tr
              key={page.fbPage}
              boxShadow="borderTop"
              bgColor={`${
                page.fbPage === currentPageUsername ? 'primary.50' : 'white'
              }`}
              _first={{ boxShadow: 'none' }}
            >
              <Td width="52px" {...tdProps}>
                <Heading variant="h4">{index + 1}</Heading>
              </Td>
              <Td width="46%" {...tdProps}>
                <HStack spacing="2">
                  <PageImage src={page.picture} />
                  <Text size="sm">{page.name}</Text>
                  <Text size="sm" color="gray.600">
                    @{page.fbPage}
                  </Text>
                </HStack>
              </Td>
              <Td {...tdProps}>{page.posts?.toLocaleString()}</Td>
              <Td
                {...tdProps}
                fontWeight="bold"
              >{`${page.engagement?.toLocaleString()}`}</Td>
              <Td {...tdProps}>{`${page.shareOfVoiceInPercent}%`}</Td>
              <Td {...tdProps}>{formatLikesFollowers(page.followerCount)}</Td>
              <Td textAlign="right">
                <Dropdown gutter={8} strategy="fixed">
                  <Dropdown.Button
                    as={IconButton}
                    icon={<MoreOptionsIcon />}
                    zIndex="10"
                    aria-label="More options"
                    size="sm"
                    color="gray.600"
                    isDisabled={page.fbPage === currentPageUsername}
                    p={0.5}
                    borderRadius="base"
                    _active={hoverActiveStyles}
                    _hover={hoverActiveStyles}
                  ></Dropdown.Button>
                  <Dropdown.List>
                    <Dropdown.Item
                      color="red.600"
                      onClick={() => onRemove(page?.id)}
                    >
                      Remove page
                    </Dropdown.Item>
                  </Dropdown.List>
                </Dropdown>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PagesTable;
