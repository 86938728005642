import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

/**
 * Async Function to generate a code for setting up MFA
 * Uses Amplify SDK's setupTOTP method to get the code from cognito
 * @returns {string} MFA code - to be used by user in any authenticator app
 */
export default async function authGenerateMFACode() {
  const guid = metrics.start('authGenerateMFACode');
  const user = await Auth.currentAuthenticatedUser();
  const username = authentication.getUsername();
  logger.info(`API:authGenerateMFACode ${username}`);
  try {
    const response = await Auth.setupTOTP(user);
    metrics.end('authGenerateMFACode', guid);
    return response;
  } catch (error) {
    metrics.fail('authGenerateMFACode', guid);
    throw handleCognitoError({
      originalError: error,
      errorLocation: 'api/authGenerateMFACode',
      args: { username },
    });
  }
}
