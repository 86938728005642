import { APITypeId, PostType, SocialChannel } from 'types';
import { API_TYPE_IDS } from './constants';
import * as FacebookValidators from './validators/facebookValidators';
import * as InstagramValidators from './validators/instagramValidators';
import * as LinkedInValidators from './validators/linkedInValidators';

/**
 * The validator functions currently supported.
 */
interface ValidatorFunctions {
  getDefaultImageAspectRatios: (args: {
    socialChannel?: SocialChannel;
    postType: PostType;
  }) => { default: number | null };

  getImageAspectRatios: (args: {
    socialChannel: SocialChannel;
    postType: PostType;
  }) => { min: number | null; max: number | null };

  getMediaSizeLimits: (args: {
    socialChannel: SocialChannel;
    postType: PostType;
  }) => { min: number | null; max: number | null };

  getImageQuantityLimits: (args: {
    socialChannel: SocialChannel;
    postType: PostType;
  }) => { min: number; max: number };

  getVideoDurationLimits: (args: {
    socialChannel: SocialChannel;
    postType: PostType;
  }) => {
    min: number;
    max: number;
  } | null;

  thumbnailImageValidator: (args: {
    socialChannel: SocialChannel;
    imageDimensions: { width: number; height: number };
    fileSize?: number;
  }) => string | null;
}

/**
 * Custom functions for retrieving constant values for a particular API type.
 */
const validators: Partial<Record<APITypeId, Partial<ValidatorFunctions>>> = {
  [API_TYPE_IDS.INSTAGRAM]: InstagramValidators,
  [API_TYPE_IDS.FACEBOOK]: FacebookValidators,
  [API_TYPE_IDS.LINKEDIN]: LinkedInValidators,
};

export default validators;
