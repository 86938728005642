import { Checkbox, HStack, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getSocialNetWorkIconByAPITypeId } from 'common/social';
import SocialPageCircle from 'components/misc/SocialPageCircle';
import type { SocialAPI } from 'types';

interface PagesSetupAccordionPageProps {
  page: SocialAPI;
  onToggle: (page: SocialAPI) => void;
  isDisabled: boolean;
}

const PagesSetupAccordionPage = ({
  page,
  onToggle,
  isDisabled,
}: PagesSetupAccordionPageProps) => {
  const SocialIcon = getSocialNetWorkIconByAPITypeId(page.apiTypeId);

  const handleChange = () => {
    onToggle(page);
  };

  return (
    <Checkbox
      isDisabled={isDisabled}
      name={page.apiAltName}
      value={page.accountAPIId}
      my={2}
      onChange={handleChange}
    >
      <HStack>
        <SocialPageCircle>
          <SocialIcon />
        </SocialPageCircle>
        <Text size="sm" as="span" color="gray.900" fontWeight="bold">
          {`${page.accountAPIId} `}
        </Text>
        <Text
          size="sm"
          as="span"
          color="gray.900"
          fontWeight="normal"
        >{`${page.apiAltName}`}</Text>
      </HStack>
    </Checkbox>
  );
};

export default PagesSetupAccordionPage;
