import {
  Box,
  Button,
  Checkbox,
  ChevronButton,
  Dropdown,
  Flex,
  HStack,
  Portal,
  Spacer,
  Tag,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { PERMISSION_TYPES } from 'common/constants';
import { useState } from 'react';
import { PermissionTypeId } from 'types';
import { PropertyDetails } from './UserAddNew';

interface UserPropertyProps {
  propertyRole: PermissionTypeId;
  isPropertySelectAllowed: boolean;
  adminProperties: Record<number, PropertyDetails>;
  selectedProperties: Record<number, PropertyDetails>;
  onPropertyChange: (
    selectedProperties: Record<number, PropertyDetails>,
  ) => void;
}

const UserProperty = ({
  propertyRole,
  isPropertySelectAllowed,
  adminProperties,
  selectedProperties,
  onPropertyChange,
}: UserPropertyProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [previouslySelectedProperties, setPreviouslySelectedProperties] =
    useState(selectedProperties);

  const selectableProperties = Object.fromEntries(
    Object.entries(adminProperties).filter(
      ([, value]) => value.isPageless === false,
    ),
  );

  const sortProperties = (a: string, b: string) => {
    return a.localeCompare(b);
  };

  const handleToggle = (propertyId: number) => {
    if (!isOpen) return;

    const newSelectedProperties = { ...selectedProperties };

    if (propertyId in newSelectedProperties) {
      delete newSelectedProperties[propertyId];
    } else {
      newSelectedProperties[propertyId] = adminProperties[propertyId];
    }
    onPropertyChange(newSelectedProperties);
  };

  const handleSelectAll = () => {
    let newSelectedProperties: Record<number, PropertyDetails> = {};

    if (propertyRole !== PERMISSION_TYPES.ADMIN) {
      newSelectedProperties =
        Object.keys(selectedProperties).length ===
        Object.keys(selectableProperties).length
          ? {}
          : { ...selectableProperties };
    } else {
      newSelectedProperties =
        Object.keys(selectedProperties).length ===
        Object.keys(adminProperties).length
          ? {}
          : { ...adminProperties };
    }

    onPropertyChange(newSelectedProperties);
  };

  const handlePropertiesSubmit = () => {
    setPreviouslySelectedProperties(selectedProperties);
    onClose();
  };

  const handleClose = () => {
    if (propertyRole === PERMISSION_TYPES.ADMIN) {
      onPropertyChange(previouslySelectedProperties);
    } else {
      onPropertyChange(
        Object.fromEntries(
          Object.entries(previouslySelectedProperties).filter(
            ([, value]) => value.isPageless === false,
          ),
        ),
      );
    }

    onClose();
  };

  const getButtonLabel = () => {
    const selectedPropertiesCount = Object.keys(selectedProperties).length;

    if (selectedPropertiesCount === 0) {
      return <Tag color="amber">Select at least one Property</Tag>;
    }

    return (
      <Flex gap={1}>
        {selectedPropertiesCount > 1 ? (
          <Flex gap={1}>
            <Tag color="blue">
              {Object.values(selectedProperties)[0].propertyName}{' '}
            </Tag>
            <Tag color="blue">+{selectedPropertiesCount - 1} </Tag>
          </Flex>
        ) : (
          Object.entries(selectedProperties).map(([propertyId, property]) => (
            <Tag color="blue" key={propertyId}>
              {property.propertyName}
            </Tag>
          ))
        )}
      </Flex>
    );
  };

  return (
    <Dropdown
      placement="top-start"
      closeOnSelect={false}
      isOpen={isOpen}
      onOpen={onOpen}
      flip={false}
      onClose={handleClose}
      matchWidth={true}
    >
      <Dropdown.Button
        as={ChevronButton}
        variant="secondary"
        isDisabled={!isPropertySelectAllowed}
        chevronColor="gray.600"
        textAlign="left"
        width="100%"
        fontWeight="normal"
      >
        {getButtonLabel()}
      </Dropdown.Button>
      <Portal>
        <Dropdown.List zIndex="modal" maxH="unset" maxWidth="280px">
          <Box maxHeight="264px" overflowY="auto" overscrollBehavior="contain">
            {Object.entries(adminProperties)
              .sort(([, propertyA], [, propertyB]) =>
                sortProperties(propertyA.propertyName, propertyB.propertyName),
              )
              .map(([propertyId, property]) => {
                const isDisabled =
                  property.isPageless &&
                  propertyRole !== PERMISSION_TYPES.ADMIN;
                return (
                  <Dropdown.Item
                    key={propertyId}
                    fontWeight="medium"
                    fontSize="s"
                    py={2.5}
                    onClick={(event) => {
                      event.preventDefault();
                      handleToggle(parseInt(propertyId));
                    }}
                    isDisabled={isDisabled}
                  >
                    <HStack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        isChecked={propertyId in selectedProperties}
                        isDisabled={isDisabled}
                      />
                      <Box>{property.propertyName}</Box>
                    </HStack>
                  </Dropdown.Item>
                );
              })}
          </Box>
          <Dropdown.Divider m={0} />
          <Flex px={4} pt={4} pb={2} justifyContent="flex-end" align="center">
            <Button variant="link" onClick={handleSelectAll}>
              Select All
            </Button>
            <Spacer />
            <Button
              variant="primary"
              onClick={handlePropertiesSubmit}
              isDisabled={Object.keys(selectedProperties).length === 0}
            >
              Apply
            </Button>
          </Flex>
        </Dropdown.List>
      </Portal>
    </Dropdown>
  );
};

export default UserProperty;
