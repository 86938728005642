import { ReactNode, useState } from 'react';

import {
  Button,
  Flex,
  FormControl,
  Input,
  Tag,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { KEYNAMES } from 'common/constants';
import * as guid from 'common/guid';

import { AdvancedSettings } from 'components/settings/automation/Advanced';
import { NewSharesKeywordSettings } from 'components/settings/automation/newshares/Keywords';
import { ReshareKeywordSettings } from 'components/settings/automation/reshares/Keywords';

interface KeywordsInputProps<
  T extends
    | NewSharesKeywordSettings
    | ReshareKeywordSettings
    | AdvancedSettings,
  K extends keyof T,
> {
  label: ReactNode;
  currentKeywordSettings: T;
  handleKeywordSettingsChange: (newSettings: T) => void;
  keywordType: K;
}

function KeywordsInput<
  T extends
    | NewSharesKeywordSettings
    | ReshareKeywordSettings
    | AdvancedSettings,
  K extends keyof T,
>({
  label,
  currentKeywordSettings,
  handleKeywordSettingsChange,
  keywordType,
}: KeywordsInputProps<T, K>) {
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [error, setError] = useState('');

  const keywords = currentKeywordSettings[keywordType] as string[];

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const colorValue = event.target.value;
    setCurrentKeyword(colorValue);
    setError('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYNAMES.ENTER) {
      handleAddKeyword();
    }
    if (event.key === KEYNAMES.ESCAPE) {
      setCurrentKeyword('');
    }
  };

  const handleAddKeyword = () => {
    if (currentKeyword) {
      // Don't add if keyword already exists
      if (keywords.indexOf(currentKeyword) !== -1) {
        setError('This keyword already exists.');
      } else {
        const newKeywordsArray = [...keywords, currentKeyword];
        const newSettings = {
          ...currentKeywordSettings,
          [keywordType]: newKeywordsArray,
        };
        handleKeywordSettingsChange(newSettings);
        setCurrentKeyword('');
        setError('');
      }
    } else {
      setError('Please enter a keyword.');
    }
  };

  const handleRemoveKeyword = (tag: string) => {
    const newKeywordsArray = keywords.filter((keyword) => tag !== keyword);
    const newSettings = {
      ...currentKeywordSettings,
      [keywordType]: newKeywordsArray,
    };
    handleKeywordSettingsChange(newSettings);
  };

  return (
    <Flex flexDir="column" gap={3} data-cy-id={keywordType}>
      <FormControl isInvalid={!!error}>
        <FormControl.FormLabel fontWeight="normal">
          {label}
        </FormControl.FormLabel>
        <Flex gap={2}>
          <Input
            name="keyword"
            data-cy-input="keyword"
            w="200px"
            onKeyDown={handleKeyDown}
            value={currentKeyword}
            onChange={handleChangeInput}
          />
          <Button
            data-cy-action="saveKeyword"
            variant="secondary"
            onClick={handleAddKeyword}
          >
            Add
          </Button>
        </Flex>
        {error && (
          <FormControl.FormErrorMessage>{error}</FormControl.FormErrorMessage>
        )}
      </FormControl>
      {keywords.length > 0 && (
        <Flex gap={1}>
          {keywords.map((keyword) => (
            <Tag
              data-cy-id="keyword"
              data-cy-action="removeKeyword"
              color="gray"
              onClose={() => handleRemoveKeyword(keyword)}
              key={keyword + guid.generateGuid()}
            >
              {keyword}
            </Tag>
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default KeywordsInput;
