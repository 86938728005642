/* eslint no-use-before-define:"off" */

export { isValidInteger, isValidIntegerRange, mandatory };

function isValidInteger(value) {
  if (Number.isNaN(Number(value))) {
    return false;
  }
  if (Number.isNaN(parseInt(value, 10))) {
    return false;
  }
  return true;
}

function isValidIntegerRange(value, min, max) {
  if (Number.isNaN(Number(value))) {
    return false;
  }
  if (Number.isNaN(parseInt(value, 10))) {
    return false;
  }
  if (value < min || value > max) {
    return false;
  }
  return true;
}

/**
 * mandatory
 */

function mandatory(parameterName = '') {
  throw new ReferenceError(`Missing parameter ${parameterName}`);
}
