const RemoveLink = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.775 10.4599H4.5C3.14922 10.4599 2.05 9.36069 2.05 8.00991C2.05 7.22832 2.42472 6.53882 2.99675 6.08994L2.15478 5.43085C1.45063 6.07179 1 6.98616 1 8.00991C1 9.93972 2.57019 11.5099 4.5 11.5099H6.775C7.06594 11.5099 7.3 11.2758 7.3 10.9849C7.3 10.694 7.06594 10.4599 6.775 10.4599ZM12.9022 11.1993C14.1447 10.6546 15 9.43397 15 8.00991C15 6.0801 13.4298 4.50991 11.5 4.50991H9.225C8.93625 4.50991 8.7 4.74616 8.7 5.03491C8.7 5.32366 8.93516 5.55991 9.225 5.55991H11.5C12.8508 5.55991 13.95 6.65913 13.95 8.00991C13.95 9.21588 13.0719 10.2149 11.9226 10.4162L9.52031 8.53491H10.275C10.5659 8.53491 10.8 8.30085 10.8 8.00991C10.8 7.71897 10.5648 7.48491 10.275 7.48491H8.18156L5.725 5.55991H6.775C7.06594 5.55991 7.3 5.32585 7.3 5.03491C7.3 4.74397 7.06594 4.50991 6.775 4.50991H4.5C4.465 4.50991 4.43 4.51472 4.39281 4.51582L1.84897 2.52167C1.75272 2.44649 1.63853 2.40991 1.52566 2.40991C1.36991 2.40991 1.21536 2.47895 1.11215 2.61088C0.933041 2.83925 0.9727 3.16935 1.20101 3.34807L14.1313 13.4784C14.361 13.6578 14.6904 13.6168 14.8683 13.3892C15.0474 13.1608 15.0077 12.8307 14.7794 12.652L12.9022 11.1993Z"
      fill="currentColor"
    />
  </svg>
);

export default RemoveLink;
