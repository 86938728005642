import { useState } from 'react';

import {
  Card,
  ChevronButton,
  Dropdown,
  Flex,
  FormControl,
  NumberInput,
  Popover,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { MAX_CUMULATIVE_CUSTOM_CONTENT } from 'common/config';
import { ARTICLE_AGE_UNITS } from 'common/enums';
import * as settings from 'common/settings';
import * as string from 'common/string';

import { NewSharesSettings } from 'components/settings/automation/newshares/NewShares';
import { useAutomationSettingsContext } from '../AutomationSettingsContext';

interface CustomSettingsProps {
  currentNewShareSettings: NewSharesSettings;
  handleNewShareSettingsChange: (
    settings: NewSharesSettings,
    error?: boolean,
  ) => void;
}

function CustomSettings({
  currentNewShareSettings,
  handleNewShareSettingsChange,
}: CustomSettingsProps) {
  const { accountAPIId } = useAutomationSettingsContext();

  const [newShareVolume, setNewShareVolume] = useState(
    currentNewShareSettings.bestArticlesNewContentNum.toString(),
  );
  const [articleAge, setArticleAge] = useState(
    currentNewShareSettings.articleAgeValue.toString(),
  );
  const [volumeError, setVolumeError] = useState('');
  const [articleAgeError, setArticleAgeError] = useState('');

  const maxArticleAgeDays =
    settings.getAutofeedSetting({
      key: 'maximumAllowedArticleAgeDays',
      accountAPIId,
    }) ?? 0;
  const maxArticleAgeHours = maxArticleAgeDays * 24;

  const getMaxValue = (ageUnit: ARTICLE_AGE_UNITS) =>
    ageUnit === ARTICLE_AGE_UNITS.DAYS ? maxArticleAgeDays : maxArticleAgeHours;

  const handleVolumeChange = (value: string) => {
    setNewShareVolume(value);
    const numberValue = Number.isNaN(Number(value)) ? 0 : Number(value);

    const newSettings = {
      ...currentNewShareSettings,
      bestArticlesNewContentNum: numberValue,
    };

    if (
      numberValue < 1 ||
      numberValue >= MAX_CUMULATIVE_CUSTOM_CONTENT ||
      !value
    ) {
      setVolumeError(
        `Please specify a number between 1 and ${
          MAX_CUMULATIVE_CUSTOM_CONTENT - 1
        }.`,
      );
      handleNewShareSettingsChange(newSettings, true);
    } else {
      setVolumeError('');
      handleNewShareSettingsChange(newSettings);
    }
  };

  const handleArticleAgeChange = (value: string) => {
    setArticleAge(value);
    const numberValue = Number.isNaN(Number(value)) ? 0 : Number(value);

    const newSettings = {
      ...currentNewShareSettings,
      articleAgeValue: numberValue,
    };

    if (
      numberValue < 1 ||
      numberValue >= getMaxValue(currentNewShareSettings.articleAgeUnit)
    ) {
      setArticleAgeError(
        `Please enter a value between 1 hour and ${maxArticleAgeDays} days`,
      );
      handleNewShareSettingsChange(newSettings, true);
    } else {
      setArticleAgeError('');
      handleNewShareSettingsChange(newSettings);
    }
  };

  const handleArticleUnitChange = (value: ARTICLE_AGE_UNITS) => {
    const newSettings = {
      ...currentNewShareSettings,
      articleAgeUnit: value,
    };

    const articleAgeValue = currentNewShareSettings.articleAgeValue;

    if (articleAgeValue >= getMaxValue(value) || articleAgeValue === 0) {
      setArticleAgeError(
        `Please enter a value between 1 hour and ${maxArticleAgeDays} days`,
      );
      handleNewShareSettingsChange(newSettings, true);
    } else {
      setArticleAgeError('');
      handleNewShareSettingsChange(newSettings);
    }
  };

  return (
    <Card
      flexDir="column"
      alignItems="flex-start"
      gap={2}
      bg="gray.100"
      w="100%"
      p={4}
    >
      <FormControl isInvalid={!!volumeError}>
        <Flex gap={2} alignItems="center">
          <NumberInput
            name="bestArticlesReshareNum"
            id="bestArticlesReshareNum"
            data-cy-input="reshareVolume"
            precision={0}
            min={1}
            value={newShareVolume}
            onChange={handleVolumeChange}
            w={12}
          >
            <NumberInput.Field />
          </NumberInput>
          <Text size="sm">new shares a day (target)</Text>
        </Flex>
        {volumeError && (
          <FormControl.FormErrorMessage>
            {volumeError}
          </FormControl.FormErrorMessage>
        )}
      </FormControl>
      <FormControl isInvalid={!!articleAgeError}>
        <Flex gap={2} alignItems="center">
          <NumberInput
            name="minMinsBetweenSharesValue"
            id="minMinsBetweenSharesValue"
            data-cy-input="reshareIntervalNumber"
            precision={0}
            min={1}
            value={articleAge}
            onChange={handleArticleAgeChange}
            w={12}
          >
            <NumberInput.Field />
          </NumberInput>
          <Dropdown matchWidth>
            <Dropdown.Button
              name="minMinsBetweenSharesUnit"
              data-cy-select="reshareIntervalUnit"
              as={ChevronButton}
              variant="secondary"
            >
              {string.toSentenceCase(currentNewShareSettings.articleAgeUnit)}
            </Dropdown.Button>
            <Popover>
              <Dropdown.List>
                <Dropdown.Item
                  onClick={() =>
                    handleArticleUnitChange(ARTICLE_AGE_UNITS.HOURS)
                  }
                >
                  {string.toSentenceCase(ARTICLE_AGE_UNITS.HOURS)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleArticleUnitChange(ARTICLE_AGE_UNITS.DAYS)
                  }
                >
                  {string.toSentenceCase(ARTICLE_AGE_UNITS.DAYS)}
                </Dropdown.Item>
              </Dropdown.List>
            </Popover>
          </Dropdown>
          <Text size="sm">articles older than this should not be shared</Text>
        </Flex>
        {articleAgeError && (
          <FormControl.FormErrorMessage>
            {articleAgeError}
          </FormControl.FormErrorMessage>
        )}
      </FormControl>
    </Card>
  );
}

export default CustomSettings;
