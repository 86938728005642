/* eslint react/no-danger:"off" */

import createDOMPurify from 'dompurify';

/**
 * Item Error
 */

interface ItemErrorProps {
  errorMessage: string;
}

const ItemError = ({ errorMessage }: ItemErrorProps) => {
  const DOMPurify = createDOMPurify(window);

  return (
    <div className="error_notification text-500">
      <img className="icon-error" src="/img/icons/ic-error-red.svg" alt="" />{' '}
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(errorMessage),
        }}
      />
    </div>
  );
};

export default ItemError;
