import type { Configuration } from '@cesdk/engine';
import CreativeEngine from '@cesdk/engine';

import * as API from 'api/api';
import * as Logger from 'common/logger';
import { FixTypeLater, Tone, VideoParameters } from 'types';
import { getAPITypeId, getAccountAPIMessageSource } from './accountAPIs';
import { CORS_PROXY_URL, CREATIVE_EDITOR_LICENSE_KEY } from './config';
import {
  AUDIO_TRACKS_BY_TONE,
  AUDIO_TRACK_PREFIX,
  DEFAULT_TONE,
  INSTANT_VIDEO_PROPERTY_CONFIG,
  POST_TYPES,
  SOCIAL_CHANNELS,
  VIDEOS_BY_TONE,
  VIDEO_PREFIX,
} from './constants';
import { loadCreativeEngine } from './creative-editor';
import * as MediaItem from './mediaItem';
import { convertEchoboxImageToAmazon, isEbxImage } from './s3';
import { getURNName } from './socialV2';
import { getDomain } from './url';
import { convertToSocialPageURN } from './urn';

/**
 * Lazy loads the instant video template
 */
async function loadTemplate() {
  const module = await import('assets/instant-video-template.json');
  return module.template;
}

export async function initializeCreativeEngine({ userId }: { userId: string }) {
  const config: Partial<Configuration> = {
    license: CREATIVE_EDITOR_LICENSE_KEY,
    userId,
  };

  // Lazy loading the creative engine to avoid bloating the initial bundle
  const creativeEngine = await loadCreativeEngine();
  const engine = await creativeEngine.init(config);

  // Load the default video template
  const template = await loadTemplate();
  await engine.scene.loadFromString(template);

  return engine;
}

/**
 * Using CE_SDK, construct an instant video from the given metadata
 * @returns a blob of the constructed video
 */
export async function constructVideo({
  parameters,
  engine,
  setProgress,
}: {
  parameters: VideoParameters;
  engine: CreativeEngine;
  setProgress?: (progress: number) => void;
}) {
  const page = engine.scene.getCurrentPage();

  if (page === null) throw new Error('No page found');

  try {
    // Swap out the text elements and update font
    const hookBlock = engine.block.findByName('Hook')[0];
    engine.block.replaceText(hookBlock, parameters.frame1.text);
    engine.block.setString(hookBlock, 'text/fontFileUri', parameters.font);

    const summaryBlock = engine.block.findByName('Summary')[0];
    engine.block.replaceText(summaryBlock, parameters.frame2.text);
    engine.block.setString(summaryBlock, 'text/fontFileUri', parameters.font);

    const domainBlock = engine.block.findByName('Domain')[0];
    engine.block.replaceText(domainBlock, parameters.frame3.text);
    engine.block.setString(domainBlock, 'text/fontFileUri', parameters.font);

    // Swap out the article images
    const photoBlock1 = engine.block.findByName('Photo1')[0];
    const photoBlock2 = engine.block.findByName('Photo2')[0];
    const photoBlock3 = engine.block.findByName('Photo3')[0];
    const photoBlocks = [photoBlock1, photoBlock2, photoBlock3];
    const images = [
      parameters.frame1.imageURL,
      parameters.frame2.imageURL,
      parameters.frame3.imageURL,
    ];
    images.forEach((imageURL, idx) => {
      const pictureFill = engine.block.getFill(photoBlocks[idx]);
      if (imageURL) {
        engine.block.setString(
          pictureFill,
          'fill/image/imageFileURI',
          `${CORS_PROXY_URL}${imageURL}`,
        );
        engine.block.setContentFillMode(photoBlocks[idx], 'Cover');
      } else {
        engine.block.destroy(pictureFill);
      }
    });

    // Swap out the logo
    const [logoBlock] = engine.block.findByName('Logo');
    const logoFill = engine.block.getFill(logoBlock);
    if (parameters.logoURL) {
      engine.block.setString(
        logoFill,
        'fill/image/imageFileURI',
        parameters.logoURL,
      );
    } else {
      engine.block.destroy(logoFill);
    }

    // Swap out the video
    const [videoBlock] = engine.block.findByName('Video');
    const videoFill = engine.block.getFill(videoBlock);
    engine.block.setString(
      videoFill,
      'fill/video/fileURI',
      parameters.videoURL,
    );

    // Swap out the audio
    const [audioBlock] = engine.block.findByType('audio');
    if (audioBlock === undefined) {
      throw new Error('Expected 1 audio block');
    }
    engine.block.setString(audioBlock, 'audio/fileURI', parameters.audioURL);
  } catch (error) {
    Logger.error({
      event: 'Failed to swap out video elements',
      error,
      properties: { parameters },
    });
    throw error;
  }

  // Export the video
  try {
    const mimeType = 'video/mp4';
    const blob = await engine.block.exportVideo(
      page,
      // @ts-expect-error -- CE seems to expect an enum here but it's not exported
      mimeType,
      (_, exportedFrames, totalFrames) => {
        if (setProgress && totalFrames > 0) {
          const percent = (exportedFrames / totalFrames) * 100;
          setProgress(Math.round(percent));
        }
      },
      {},
    );
    return blob;
  } catch (error) {
    Logger.error({
      event: 'Failed to export video',
      error,
      properties: { parameters },
    });
    throw error;
  }
}

/**
 * Retrieve the parameters for Instant video, using the property and provided media item
 */
export async function retrieveVideoParameters({
  mediaItem,
  propertyId,
}: {
  mediaItem: FixTypeLater;
  propertyId: number;
}): Promise<VideoParameters> {
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const socialNetworkType = getURNName({ apiTypeId });
  const socialPageURN = convertToSocialPageURN(socialNetworkType, accountAPIId);
  const mediaURN = MediaItem.getMediaURN({ mediaItem });
  const articleImages = MediaItem.getImageURLs({ mediaItem });
  const shareURL = MediaItem.getUnshortenedShareURL({ mediaItem });

  const tone = DEFAULT_TONE;

  const [hook, summary, articleImageURL] = await Promise.all([
    getAIHookMessage({ socialPageURN, mediaURN }),
    getAISummaryMessage({ socialPageURN, mediaURN }),
    getArticleImageURL({ articleImages, socialPageURN }),
  ]);

  const videoURL = pickBackgroundVideo({ tone });

  return {
    frame1: {
      text: hook,
      imageURL: articleImageURL,
    },
    frame2: {
      text: summary,
      imageURL: articleImageURL,
    },
    frame3: {
      text: `Read more on ${getDomain(shareURL)}`,
      imageURL: articleImageURL,
    },
    audioURL: pickAudio({ tone }),
    logoURL: getLogoURL({ propertyId }),
    font: getFont({ propertyId }),
    videoURL,
    tone,
  };
}

async function getAIHookMessage({
  socialPageURN,
  mediaURN,
}: {
  socialPageURN: string;
  mediaURN: string;
}) {
  return API.getAIMessage({
    socialPageURN,
    mediaURN,
    messageType: 'VIDEO_OPENER',
  });
}

async function getAISummaryMessage({
  socialPageURN,
  mediaURN,
}: {
  socialPageURN: string;
  mediaURN: string;
}) {
  return API.getAIMessage({
    socialPageURN,
    mediaURN,
    messageType: 'VIDEO_SUMMARY',
  });
}

async function getArticleImageURL({
  articleImages,
  socialPageURN,
}: {
  articleImages: string[];
  socialPageURN: string;
}) {
  if (articleImages.length === 0) {
    return null;
  }

  const imageURL = articleImages[0];

  // If the image is already an Echobox image, convert it to an Amazon S3 URL
  if (isEbxImage(imageURL)) {
    return convertEchoboxImageToAmazon(articleImages[0]);
  }

  // Otherwise upload it to s3
  const s3ImageURL = await API.postUploadImage({
    socialPageURN,
    imageURL,
  });

  // Convert the ebx image to an s3 URL
  return convertEchoboxImageToAmazon(s3ImageURL);
}

export function pickAudio({ tone }: { tone: Tone }) {
  const audioURLs = AUDIO_TRACKS_BY_TONE[tone];

  const audioURL = audioURLs[Math.floor(Math.random() * audioURLs.length)];

  return `${AUDIO_TRACK_PREFIX}/${tone}/${audioURL}`;
}

export function pickBackgroundVideo({ tone }: { tone: Tone }) {
  const videos = VIDEOS_BY_TONE[tone];

  // Randomly sort the videos
  videos.sort(() => Math.random() - 0.5);

  // Return the first video
  return `${VIDEO_PREFIX}/${tone}/${videos[0]}`;
}

function getFont({ propertyId }: { propertyId: number }) {
  return INSTANT_VIDEO_PROPERTY_CONFIG[propertyId]?.font ?? null;
}

function getLogoURL({ propertyId }: { propertyId: number }): string | null {
  return INSTANT_VIDEO_PROPERTY_CONFIG[propertyId]?.logoURL ?? null;
}

export function isInstantVideoEnabled(propertyId: number) {
  return !!INSTANT_VIDEO_PROPERTY_CONFIG[propertyId]?.enabled;
}

export async function initializeMediaItem({
  mediaItem,
  shareOrigin,
}: {
  mediaItem: FixTypeLater;
  shareOrigin: string;
}) {
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const mediaId = MediaItem.getMediaId({ mediaItem });
  const state = MediaItem.getState({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });

  // Fetch the full media item
  let updatedItem = await API.getMediaItem({
    accountAPIId,
    apiTypeId,
    state,
    mediaId,
    getMessages: true,
  });

  if (getAccountAPIMessageSource({ accountAPIId }) === 'OPTIMAL_MESSAGE') {
    const aiMessage = await API.getAIMessage({
      socialPageURN: convertToSocialPageURN(
        getURNName({ apiTypeId }),
        accountAPIId,
      ),
      mediaURN: MediaItem.getMediaURN({ mediaItem }),
    });
    updatedItem = MediaItem.setMessage({
      mediaItem: updatedItem,
      fieldValue: aiMessage,
    });
  }

  // Switch post type and social channel
  updatedItem = MediaItem.setPostType({
    mediaItem: updatedItem,
    fieldValue: POST_TYPES.VIDEO,
  });
  updatedItem = MediaItem.setSocialChannel({
    mediaItem: updatedItem,
    fieldValue: SOCIAL_CHANNELS.REEL,
  });

  // Set share origin
  updatedItem = MediaItem.setTrackingDetails({
    mediaItem: updatedItem,
    fieldValue: {
      Origin: shareOrigin,
    },
    allowOverride: true,
  });

  return updatedItem;
}
