import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { convertToPropertyURN } from 'common/urn';

/**
 * DELETE /properties/{propertyId}/users/{emailAddress}
 */
export default async function deletePropertiesUser({
  propertyId,
  emailAddress,
}: {
  propertyId: number;
  emailAddress: string;
}) {
  const guid = metrics.start('deletePropertiesUser');

  const propertyURN = convertToPropertyURN(propertyId);
  const config = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/properties/${propertyURN}/users`,
    method: 'DELETE',
    params: { userIdentifier: emailAddress },
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    await axios(config);
    metrics.end('deletePropertiesUser', guid);
    logger.debug('deletePropertiesUser', { propertyId, emailAddress });
  } catch (error) {
    metrics.fail('deletePropertiesUser', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/deletePropertiesUser',
      args: {
        propertyId,
        emailAddress,
      },
    });
    throw apiError;
  }
}
