import { Tag, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import { ONBOARDING_FEED_SUPPORTED_SOCIAL_PAGES } from 'common/constants';
import GlobalInfoContext from 'context/GlobalInfoContext';
import Link from 'svg/Link';
import RemoveLink from 'svg/RemoveLink';
import type { AccountFeed } from 'types';

const getTagText = ({
  feedState,
  totalConnectedPages,
  totalAccountAPIs,
}: {
  feedState: AccountFeed['syndFeedState'];
  totalConnectedPages: number;
  totalAccountAPIs: number;
}) => {
  if (feedState !== 'ACTIVE') {
    return 'No pages connected';
  }

  if (totalConnectedPages === 0 || totalConnectedPages === totalAccountAPIs) {
    return 'Connected to all pages';
  }

  return `${totalConnectedPages} page${totalConnectedPages === 1 ? '' : 's'}`;
};

interface ConnectedFeedsTagProps {
  feedState: AccountFeed['syndFeedState'];
  totalConnectedPages: number;
}

const ConnectedFeedsTag = ({
  feedState,
  totalConnectedPages,
}: ConnectedFeedsTagProps) => {
  const { global } = useContext(GlobalInfoContext);
  const { accountAPIs } = getCurrentProperty({
    globalInfo: global.getGlobalInfo(),
  });

  if (feedState === 'ERROR') {
    return (
      <Text color="red.600" size="sm" fontWeight="medium">
        There is a problem connecting to this feed
      </Text>
    );
  }

  const socialPagesAPIs = Object.keys(accountAPIs).reduce(
    (prev, accountAPI) => {
      if (
        ONBOARDING_FEED_SUPPORTED_SOCIAL_PAGES.includes(
          accountAPIs[accountAPI].apiTypeId,
        )
      ) {
        return { ...prev, [accountAPI]: accountAPIs[accountAPI] };
      }
      return prev;
    },
    {},
  );

  const isFeedActive = feedState === 'ACTIVE';

  return (
    <Tag
      color={isFeedActive ? 'blue' : 'amber'}
      icon={isFeedActive ? <Link /> : <RemoveLink />}
      iconPlacement="left"
    >
      {getTagText({
        feedState,
        totalConnectedPages,
        totalAccountAPIs: Object.keys(socialPagesAPIs).length,
      })}
    </Tag>
  );
};

export default ConnectedFeedsTag;
