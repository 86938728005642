import {
  IconProps,
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialLinkedinIcon,
  SocialTiktokIcon,
  SocialTwitterIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { API_TYPE_IDS } from 'common/constants';
import type { APITypeId } from 'types';
import { createIsKeyInObjectFn } from 'types/utilities';

const SOCIAL_ICON_BY_API_TYPE = {
  [API_TYPE_IDS.FACEBOOK]: SocialFacebookIcon,
  [API_TYPE_IDS.INSTAGRAM]: SocialInstagramIcon,
  [API_TYPE_IDS.LINKEDIN]: SocialLinkedinIcon,
  [API_TYPE_IDS.TIKTOK]: SocialTiktokIcon,
  [API_TYPE_IDS.TWITTER]: SocialTwitterIcon,
} as const;

const apiTypeHasSocialIcon = createIsKeyInObjectFn(SOCIAL_ICON_BY_API_TYPE);

interface SocialIconProps extends IconProps {
  apiTypeId: APITypeId;
}

const SocialIcon = ({ apiTypeId, ...iconProps }: SocialIconProps) => {
  if (apiTypeHasSocialIcon(apiTypeId)) {
    const Component = SOCIAL_ICON_BY_API_TYPE[apiTypeId];
    return <Component {...iconProps} />;
  }

  return null;
};

export default SocialIcon;
