import type { TransformConfiguration } from 'photoeditorsdk';
import {
  DetailedHTMLProps,
  HTMLProps,
  ImgHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';

import { ImageContextProvider } from 'context/ComposeBoxImageContext';
import { getImageDimensions } from 'helpers/images';
import { FixTypeLater } from 'types';

import { ACTIONS, IMAGE_FILE_TYPES, IMAGE_MODES } from './constants';
import ImageCrop from './ImageCrop';
import ImageEdit from './ImageEdit';
import ImageLoad from './ImageLoad';
import ImagePreview from './ImagePreview';
import type {
  ImageAction,
  ImageContextValue,
  ImageFileType,
  ImageMode,
} from './types';

type SharedImageAndVideoProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> &
  HTMLProps<HTMLVideoElement>;

interface ImageProps extends Omit<SharedImageAndVideoProps, 'onChange'> {
  src: string | undefined;
  mode: ImageMode;
  onModeChange: (mode: ImageMode) => void;
  onChange: (acceptedFiles: File[], action: ImageAction) => Promise<void>;
  onEditClose: () => void;
  onCancel?: () => void;
  fileType?: ImageFileType;
  outerWrapperClass?: string;
  innerWrapperClass?: string;
  errorMessage?: ReactNode;
  allowDragAndDrop?: boolean;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  acceptedFileTypes?: Record<string, string[]>;
  isDropDisabled?: boolean;
  isVertical: boolean;
  maxFiles?: number;
  maxFileSizeBytes?: number;
  mediaItem: FixTypeLater;
  allowMultipleFiles?: boolean;
  transformOptions?: TransformConfiguration;
  aspectRatioRange?: {
    min: number | null;
    max: number | null;
  } | null;
  imageUneditableMessage?: ReactNode;
  uploadProgressPercent?: number | null;
  showPlayButton?: boolean;
  children?: ReactNode;
  preloadedFiles?: File[] | null;
  placeholderPxHeight: number;
  hasBeenModified: boolean;
}

/**
 * Functional component for Image.
 *  - Renders different child components based on `mode`.
 *  - Initialises dropzone
 *  - Stores original images width and height in state
 *  - Adds all user props, state variables, ref and dropzone variable to user context
 */
function Image({
  src,
  mode,
  onModeChange,
  onChange,
  onEditClose,
  onCancel,
  fileType = IMAGE_FILE_TYPES.IMAGE,
  outerWrapperClass = '',
  innerWrapperClass = '',
  errorMessage = null,
  allowDragAndDrop = true,
  acceptedFileTypes = {},
  isDropDisabled = false,
  isVertical = false,
  maxFiles = 0,
  maxFileSizeBytes = Infinity,
  mediaItem,
  allowMultipleFiles = true,
  transformOptions = {
    categories: [
      {
        identifier: 'imgly_transforms_common',
        items: [
          { identifier: 'imgly_transform_common_custom' },
          { identifier: 'imgly_transform_common_square' },
          { identifier: 'imgly_transform_common_4' },
          { identifier: 'imgly_transform_common_16' },
        ],
      },
    ],
  },
  aspectRatioRange = null,
  imageUneditableMessage = null,
  uploadProgressPercent = null,
  showPlayButton = false,
  children = null,
  preloadedFiles = null,
  placeholderPxHeight,
  hasBeenModified,
  ...rest
}: ImageProps) {
  const [originalDimensions, setOriginalDimensions] = useState<{
    height: number | null;
    width: number | null;
  }>({
    width: null,
    height: null,
  });

  const previewRef = useRef<HTMLImageElement & HTMLVideoElement>(null);

  useEffect(() => {
    async function getImageDimensionsAsync() {
      if (src) {
        const { width, height } = await getImageDimensions({ file: src });
        setOriginalDimensions({ width, height });
      }
    }
    getImageDimensionsAsync();
  }, [src]);

  /**
   * Event handler called when files are uploaded into dropzone (file chooser or drag and drop)
   * Calls user provided onChange function with the following arguments:
   * 1) acceptedFiles - uploaded files
   * 2) UPLOAD - a string indicating an upload action
   */
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      onModeChange(IMAGE_MODES.UPLOADING);
      await onChange(acceptedFiles, ACTIONS.UPLOAD);
      onModeChange(IMAGE_MODES.PREVIEW);
    },
    [onChange, onModeChange],
  );

  const { PREVIEW, EDIT, CROP, UNEDITABLE, UPLOADING, UNEDITABLE_NO_REFRESH } =
    IMAGE_MODES;

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    disabled: isDropDisabled,
    noDrag: !allowDragAndDrop,
    maxFiles,
    maxSize: maxFileSizeBytes,
    multiple: allowMultipleFiles,
    noClick: true,
    onDrop,
    preventDropOnDocument: true,
  });

  const imageContextValue: ImageContextValue = useMemo(() => {
    return {
      src,
      mode,
      onChange,
      onModeChange,
      onEditClose,
      onCancel,
      fileType,
      innerWrapperClass,
      outerWrapperClass,
      placeholderPxHeight,
      errorMessage,
      allowDragAndDrop,
      acceptedFileTypes,
      isDropDisabled,
      isVertical,
      maxFiles,
      maxFileSizeBytes,
      allowMultipleFiles,
      transformOptions,
      aspectRatioRange,
      imageUneditableMessage,
      uploadProgressPercent,
      showPlayButton,
      children,
      imageProps: rest,
      previewRef,
      open,
      isDragActive,
      originalDimensions,
      hasBeenModified,
    };
  }, [
    acceptedFileTypes,
    allowDragAndDrop,
    allowMultipleFiles,
    aspectRatioRange,
    children,
    errorMessage,
    fileType,
    imageUneditableMessage,
    innerWrapperClass,
    isDragActive,
    isDropDisabled,
    isVertical,
    maxFileSizeBytes,
    maxFiles,
    mode,
    onChange,
    onModeChange,
    onEditClose,
    open,
    originalDimensions,
    outerWrapperClass,
    placeholderPxHeight,
    rest,
    showPlayButton,
    src,
    transformOptions,
    uploadProgressPercent,
    hasBeenModified,
    onCancel,
  ]);

  useEffect(() => {
    if (preloadedFiles) {
      onDrop(preloadedFiles);
    }
    // rule disabled as adding onDrop triggers an infinite uploading loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadedFiles]);

  return (
    <ImageContextProvider value={imageContextValue}>
      <div {...getRootProps()}>
        <input data-cy="image-upload" {...getInputProps()} />
        {(mode === PREVIEW ||
          mode === EDIT ||
          mode === UNEDITABLE ||
          mode === UNEDITABLE_NO_REFRESH) && (
          <ImagePreview mediaItem={mediaItem} />
        )}
        {mode === EDIT && <ImageEdit />}
        {mode === CROP && <ImageCrop mediaItem={mediaItem} />}
        {mode === UPLOADING && <ImageLoad />}
      </div>
    </ImageContextProvider>
  );
}

export default Image;
