import {
  Button,
  Dropdown,
  DropdownButtonProps,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useEffect, useState } from 'react';

import { deleteAccountAPI } from 'api/api';
import { getBitlyAPIs, getCurrentProperty } from 'common/accountAPIs';
import { API_PROPERTIES, API_STATES, API_TYPE_IDS } from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import { convertToShortenAPIURN } from 'common/urn';
import GlobalInfoContext from 'context/GlobalInfoContext';
import MoreOptions from 'svg/MoreOptions';
import { FixTypeLater } from 'types';

import ConnectBitlyModal from './ConnectBitlyModal';

const getBitlyAPI = (globalInfo: FixTypeLater) => {
  const apis = getCurrentProperty({
    globalInfo,
  }).accountAPIs;

  const bitlyAPIs = getBitlyAPIs({ accountAPIs: apis });
  if (bitlyAPIs.length === 1) {
    return bitlyAPIs[0];
  }

  return null;
};

const getIsBitlyConnected = (globalInfo: FixTypeLater) => {
  const api = getBitlyAPI(globalInfo);
  return api?.apiStateId === API_STATES.ACTIVE;
};

interface BitlyProps {
  disableMenu?: boolean;
}

const Bitly = ({ disableMenu = false }: BitlyProps) => {
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const [isConnected, setIsConnected] = useState(() =>
    getIsBitlyConnected(globalInfo),
  );
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setIsConnected(getIsBitlyConnected(globalInfo));
  }, [globalInfo]);

  const handleError = ({
    error,
    action,
    event,
  }: {
    error: Error;
    action: string;
    event: string;
  }) => {
    const errorMessage = getErrorMessage(determineError(error));
    if (getErrorStatus(error) === null) {
      logger.error({
        event,
        properties: {
          location: 'components/settings/pages/Bitly',
        },
        error,
      });
    }
    addErrorNotification(`Error ${action} Bitly: ${errorMessage}`);
  };

  const handleRemoveBitly = async () => {
    const bitlyAPIId = getBitlyAPI(global.getGlobalInfo()).accountAPIId;
    const shortenAPIURN = convertToShortenAPIURN(
      API_PROPERTIES[API_TYPE_IDS.BITLY].urnName,
      bitlyAPIId,
    );
    try {
      await deleteAccountAPI({
        socialAPIURN: shortenAPIURN,
      });

      setIsConnected(false);
      const newGlobalInfo = global.getGlobalInfo();
      delete newGlobalInfo.properties[globalInfo.current.propertyId]
        .accountAPIs[bitlyAPIId];
      global.setGlobalInfo(newGlobalInfo);
    } catch (error) {
      if (error instanceof Error) {
        handleError({
          error,
          action: 'removing',
          event: 'Handle Bitly Remove Error',
        });
      }
    }
  };

  const hoverActiveStyles: DropdownButtonProps = {
    bg: 'gray.900-08',
  };

  return (
    <>
      {!isConnected ? (
        <Button
          variant="link"
          onClick={() => setModalOpen(true)}
          color="primary.600"
        >
          Connect Bitly
        </Button>
      ) : (
        <>
          <Text
            as="span"
            size="sm"
            letterSpacing="normal"
            fontWeight="medium"
            color="green.600"
          >
            Bitly Connected
          </Text>
          <Dropdown gutter={8}>
            <Dropdown.Button
              aria-label="More options"
              ml={4}
              width={6}
              height={6}
              color="gray.600"
              p={0.5}
              borderRadius="base"
              disabled={disableMenu}
              cursor={disableMenu ? 'not-allowed' : 'pointer'}
              _active={hoverActiveStyles}
              _hover={hoverActiveStyles}
            >
              <MoreOptions />
            </Dropdown.Button>
            <Dropdown.List>
              <Dropdown.Item color="red.600" onClick={handleRemoveBitly}>
                Disconnect Bitly
              </Dropdown.Item>
            </Dropdown.List>
          </Dropdown>
        </>
      )}
      {modalOpen && <ConnectBitlyModal onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default Bitly;
