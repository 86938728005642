import { Alert, Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { ReactNode } from 'react';

import ComposeBoxModal from 'components/compose/ComposeBoxModal';
import Button from 'components/misc/Button';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import { useComposeBoxContext } from 'context/ComposeBoxContext';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';

const Fallback = () => {
  const closeComposeBox = useComposeBoxOpenStore(
    (state) => state.closeComposeBox,
  );

  return (
    <ComposeBoxModal>
      <div className="d-flex flex-column-reverse w-100 finish flex-md-row finish align-items-center bg-light-blue">
        <div className="d-flex align-items-center w-100 py-2 py-md-3 pr-3 pl-3">
          <Button
            className="align-self-center mr-2 ml-auto"
            data-cy-action="cancel"
            onClick={closeComposeBox}
            size="lg"
            autoFocus
          >
            Cancel
          </Button>
        </div>
      </div>
      <Box px={5} py={4} w="full">
        <Alert variant="error">
          <Alert.Description>
            An unexpected error occurred. Please refresh the page and try again.
          </Alert.Description>
        </Alert>
      </Box>
    </ComposeBoxModal>
  );
};

interface ComposeBoxErrorBoundaryProps {
  children: ReactNode;
}

const ComposeBoxErrorBoundary = ({
  children,
}: ComposeBoxErrorBoundaryProps) => {
  const { postPreviews } = useComposeBoxContext();

  return (
    <ErrorBoundary
      fallback={<Fallback />}
      logglyProperties={{
        location: 'components/compose/ComposeBoxErrorBoundary',
        postPreviews: JSON.stringify(postPreviews),
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ComposeBoxErrorBoundary;
