import { z } from 'zod';

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { API_ERROR_MESSAGES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { addErrorNotification } from 'common/notifications';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ message: z.string() })
  .describe('getAIMessage.ResponseSchema');

interface getAIMessageOptions {
  /**
   * The social page URN
   */
  socialPageURN: string;
  /**
   * The media item URN
   */
  mediaURN: string;
  /**
   * The type of message to get
   */
  messageType?: 'SHARE_MESSAGE' | 'VIDEO_OPENER' | 'VIDEO_SUMMARY';
}

/**
 * GET /v3/social/api/{socialPageURN}/media/{mediaURN}/inspireme/new
 *
 * Retrieves AI message for media item
 */
export default async function getAIMessage({
  socialPageURN,
  mediaURN,
  messageType,
}: getAIMessageOptions) {
  if (!socialPageURN || !mediaURN) {
    throw new ReferenceError(
      `Missing one of the parameters: socialPageURN: ${socialPageURN} mediaURN: ${mediaURN}`,
    );
  }

  const guid = metrics.start('getAIMessage');
  logger.info(
    `API:getAIMessage /v3/social/api/${socialPageURN}/media/${mediaURN}/inspireme/new`,
  );

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/${encodeURIComponent(
      socialPageURN,
    )}/media/${encodeURIComponent(mediaURN)}/inspireme/new`,
    method: 'GET',
    timeout: API_TIMEOUTS.XS,
    headers: getClientServiceRequestHeaders(),
    params: { messageType },
  };

  try {
    const response = await axios(config);
    metrics.end('getAIMessage', guid);

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData.message;
  } catch (error: any) {
    metrics.fail('getAIMessage', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getAIMessage',
      args: {
        socialPageURN,
        mediaURN,
      },
    });
    if (
      apiError != null &&
      typeof apiError === 'object' &&
      'message' in apiError &&
      apiError.message === API_ERROR_MESSAGES.API_TIMEOUT
    ) {
      addErrorNotification("OpenAI didn't return a suggested message");
    }
    throw apiError;
  }
}
