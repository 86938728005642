import axios from 'api/axios';
import { API_TIMEOUTS, constructBaseCoreAPIURL } from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

/**
 * GET /properties/{propertyURN}/traffic/connect/error
 * - get any errors captured during the connect process
 * @param {{ propertyURN: string, requestToken: string }}
 * @return {Promise<{ message: string }>} - connection error details
 */
export default async function getTrafficAPIsConnectError({
  propertyURN = mandatory('propertyURN'),
  requestToken = mandatory('requestToken'),
} = {}) {
  const guid = metrics.start('getTrafficAPIsConnectError');
  const config = {
    url: `${constructBaseCoreAPIURL(
      'v1',
    )}/properties/${propertyURN}/traffic/connect/error`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: {
      'X-EBX-ConnectState': requestToken,
    },
  };
  try {
    const response = await axios(config);
    metrics.end('getTrafficAPIsConnectError', guid);
    logger.debug('getTrafficAPIsConnectError', { propertyURN, requestToken });
    return response.data;
  } catch (error) {
    metrics.fail('getTrafficAPIsConnectError', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getTrafficAPIsConnectError',
      args: { propertyURN, requestToken },
    });
    throw apiError;
  }
}
