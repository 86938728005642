import postPropertiesUser from 'api/postPropertiesUser';
import {
  getCurrentPropertyId,
  getUser,
  getUserPagePermissions,
} from 'common/accountAPIs';
import { compareArrays } from 'common/array';
import { PERMISSION_TYPES } from 'common/constants';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import type { Permission } from 'types';

import PageSelector from './PageSelector';

interface SelectUserPagesProps {
  userId: string;
  onCancel: () => void;
  onSave: () => void;
}

const SelectUserPages = ({
  userId,
  onCancel,
  onSave,
}: SelectUserPagesProps) => {
  const currentUser = getUser({ userId });

  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const initialSelectedAPIs = getUserPagePermissions({
    userId,
    propertyId: globalInfo.current.propertyId,
    globalInfo,
  }).map((permission) => Number(permission.accountAPIId));

  const handlePagesSave = async (selectedAPIs: number[]) => {
    const isChanged = !compareArrays(initialSelectedAPIs, selectedAPIs, false);
    // If no changes made, just close
    if (!isChanged) {
      logger.info('SelectUserPages:handlePagesSave - no changes made');
      onCancel();
      return;
    }

    // When editing user, save permission changes
    logger.info('SelectUserPages:handlePagesSave - existing user');
    const propertyId = getCurrentPropertyId({
      globalInfo,
    });
    const permissionsOnProperty: Array<Permission> = [];
    // Add VIEW_ONLY permission at property level
    permissionsOnProperty.push({
      propertyId,
      permissionTypeId: PERMISSION_TYPES.VIEW_ONLY,
    });
    // Add EDITOR permission to selected pages
    selectedAPIs.forEach((accountAPIId) =>
      permissionsOnProperty.push({
        propertyId,
        accountAPIId,
        permissionTypeId: PERMISSION_TYPES.EDITOR,
      }),
    );

    // Save change
    const newUser = getUser({ userId });

    try {
      await postPropertiesUser({
        propertyId,
        name: newUser.name,
        username: newUser.username,
        emailAddress: newUser.emailAddress,
        permissionsOnProperty,
      });
      onSave();
    } catch (error) {
      console.log(error);
      addErrorNotification(error);
    }
  };

  return (
    <PageSelector
      initialSelectedAPIs={initialSelectedAPIs}
      onClose={onCancel}
      onCancel={onCancel}
      onSave={handlePagesSave}
      userEmail={currentUser?.emailAddress}
    />
  );
};

export default SelectUserPages;
