import { z } from 'zod';

import * as logger from 'common/logger';

/**
 * Parses an unknown value through a zod schema.
 * The parse is always considered successful - no error is thrown and the value is given the correct type.
 * However, this may be a lie. The parse may fail. If it does, an error is logged to loggly.
 * This allows us to test the values with zod and see where our type assumptions are incorrect, but without
 * causing possible run-time errors that would disrupt live users
 * @param schema The zod schema to parse the value
 * @param value The unknown value that will be parsed
 * @returns The value with the type that a successful parse would return
 */
export function lyingParse<TValue>(schema: z.Schema<TValue>, value: unknown) {
  const result = schema.safeParse(value);

  if (result.success) {
    return result.data;
  }

  logger.error({
    event: 'Zod Parse Error',
    properties: {
      location: 'common/zod',
      schema: schema.description,
      actualValue: value,
    },
    error: result.error,
  });

  return value as TValue;
}

/**
 * Utility schema to safely parse a string to a JSON object.
 * https://github.com/colinhacks/zod/discussions/2215
 *
 * @example
 * const MySchema = stringToJSONSchema.pipe(z.record(z.number())).catch({})
 */
export const stringToJSONSchema = z.string().transform((str, ctx): any => {
  try {
    return JSON.parse(str);
  } catch (e) {
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });
    return z.NEVER;
  }
});
