/* eslint no-use-before-define:"off" */

import * as logger from 'common/logger';
import { cloneObject } from 'common/utility';
import { mandatory } from 'common/validation';

export { addItem, deleteItem, moveItem, updateItem };

function addItem({
  feed = mandatory('feed'),
  index = mandatory('index'),
  mediaId = mandatory('mediaId'),
  mediaItem = mandatory('mediaItem'),
} = {}) {
  // Create independent copy of feed data
  const updatedFeed = cloneObject(feed);

  // Check that the specified index is valid - cannot be less than zero (the
  // start of the list) or greater than the length (not the length minus one,
  // because this would prevent us appending an item to the end of the list)
  if (index < 0 || index > updatedFeed.mediaIds.length) {
    logger.info(`queue:addItem - invalid index ${index}`);
    return null;
  }

  // Add the media id to the requested position
  updatedFeed.mediaIds.splice(index, 0, mediaId);
  // Add the media item if the requested position is within the rendered range
  if (index <= updatedFeed.itemsRendered) {
    updatedFeed.mediaItems.splice(index, 0, mediaItem);
    updatedFeed.itemsRendered += 1;
  }

  return updatedFeed;
}

function deleteItem({
  feed = mandatory('feed'),
  index = mandatory('index'),
} = {}) {
  // Create independent copy of feed data
  const updatedFeed = cloneObject(feed);

  // Check that the specified index is valid
  if (index < 0 || index > updatedFeed.mediaIds.length - 1) {
    logger.info(`queue:deleteItem - invalid index ${index}`);
    return null;
  }

  // Remove the item from the media ids list
  updatedFeed.mediaIds.splice(index, 1);
  // Remove the item from the media items list if it is in the rendered range
  if (index <= updatedFeed.itemsRendered) {
    updatedFeed.mediaItems.splice(index, 1);
    updatedFeed.itemsRendered -= 1;
  }

  return updatedFeed;
}

function moveItem({
  feed = mandatory('feed'),
  fromIndex = mandatory('fromIndex'),
  toIndex = mandatory('toIndex'),
  reloadMovedItem = false,
  createMediaItem = mandatory('createMediaItem'),
} = {}) {
  // Create independent copy of feed data
  const updatedFeed = cloneObject(feed);

  // Check that the specified indices are valid
  if (fromIndex < 0 || fromIndex > updatedFeed.mediaIds.length - 1) {
    logger.info(`queue:deleteItem - invalid from index ${fromIndex}`);
    return null;
  }
  if (toIndex < 0 || toIndex > updatedFeed.mediaIds.length - 1) {
    logger.info(`queue:deleteItem - invalid to index ${toIndex}`);
    return null;
  }

  // Move item within the media ids list
  const tempMediaId = updatedFeed.mediaIds[fromIndex];
  updatedFeed.mediaIds.splice(fromIndex, 1);
  updatedFeed.mediaIds.splice(toIndex, 0, tempMediaId);

  const isFromRendered = fromIndex < updatedFeed.itemsRendered;
  const isToRendered = toIndex < updatedFeed.itemsRendered;

  // Move item within rendered part of media items list
  if (isFromRendered && isToRendered) {
    const tempMediaItem = reloadMovedItem
      ? createMediaItem({ mediaId: tempMediaId })
      : updatedFeed.mediaItems[fromIndex];
    updatedFeed.mediaItems.splice(fromIndex, 1);
    updatedFeed.mediaItems.splice(toIndex, 0, tempMediaItem);
  }
  // Remove item from rendered part of media items list
  if (isFromRendered && !isToRendered) {
    updatedFeed.mediaItems.splice(fromIndex, 1);
    updatedFeed.itemsRendered -= 1;
  }
  // Move item into rendered part of media items list
  if (!isFromRendered && isToRendered) {
    updatedFeed.mediaItems.splice(
      toIndex,
      0,
      createMediaItem({ mediaId: tempMediaId }),
    );
    updatedFeed.itemsRendered += 1;
  }

  return updatedFeed;
}

function updateItem({
  feed = mandatory('feed'),
  index = mandatory('index'),
  mediaId = mandatory('mediaId'),
  mediaItem = mandatory('mediaItem'),
} = {}) {
  // Create independent copy of feed data
  const updatedFeed = cloneObject(feed);

  // Check that the specified index is valid
  if (index < 0 || index > updatedFeed.mediaIds.length - 1) {
    logger.info(`queue:updateItem - invalid index ${index}`);
    return null;
  }

  // Update item in media ids list
  updatedFeed.mediaIds[index] = mediaId;

  // Update item in media items list if it is in the rendered range
  if (index < feed.itemsRendered) {
    updatedFeed.mediaItems[index] = mediaItem;
  }

  return updatedFeed;
}
