import {
  Alert,
  Box,
  Button,
  Link,
  Modal,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link as RouterLink } from 'react-router-dom';

interface InstallWebTagModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InstallWebTagModal = ({ isOpen, onClose }: InstallWebTagModalProps) => {
  return (
    <Modal size="small" isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Edit columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning">
          <Alert.Title>Echobox Web Tag is required</Alert.Title>
          <Alert.Description>
            To access these advanced analytics features, a small snippet must be
            installed on your website
          </Alert.Description>
          <Box mt="4">
            <Link
              isExternal
              href="https://echobox.zendesk.com/hc/en-us/articles/9213561013533-Installing-the-Echobox-Web-Tag"
            >
              Learn more
            </Link>
          </Box>
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button as={RouterLink} to="/settings/property">
          Install Web Tag
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstallWebTagModal;
