import {
  POST_TYPES,
  SOCIAL_CHANNELS,
  SPECIAL_CHARACTER_REGEX,
  TAG_TYPES,
} from 'common/constants/common';

const constants = {
  urnName: 'tt',
  apiType: 'TIKTOK',
  name: 'TikTok',
  isSocialNetwork: true,
  entityName: 'page',
  icon: '/img/icons/ic-tiktok.svg',
  lowerName: 'tiktok',
  defaultPageIcon: '/img/tiktok-fallback-page.png',
  showOnSetupPage: true,
  isValidAspectRatioMandatory: true,
  appendSpace: true,
  appendMentionsAtSymbol: true,
  canDeletePosts: false,
  canHaveMultipleAccounts: true,
  canResharePosts: false,
  canShareNow: true,
  colorHashtagsAndMentions: true,
  pageURLTemplate: 'https://www.tiktok.com/@PAGENAME',
  postTypes: {
    [POST_TYPES.VIDEO]: 'Video',
  },
  dimensionLimits: {
    [POST_TYPES.VIDEO]: {
      height: {
        min: 360,
      },
      width: {
        min: 360,
      },
    },
  },
  lastSharedLabel: {
    [POST_TYPES.VIDEO]: 'Video',
  },
  shareIcon: {
    [POST_TYPES.VIDEO]: '/img/icons/ic-camera.svg',
  },
  hasCharacterCount: true,
  hasHashtagsMentions: true,
  hasMessageBeforeImage: true,
  hasMessageField: true,
  hasSyndFeeds: false,
  imageAspectRatios: {
    [POST_TYPES.VIDEO]: {
      min: 0.5625,
      max: 0.5625,
    },
  },
  imageQuantityLimits: {
    defaults: {
      min: 0,
      max: 1,
    },
  },
  imageSizeLimits: {
    default: {
      max: 1024 * 1024 * 1024, // 1GB
    },
  },
  messageBoxPlaceholder: 'Write a caption...',
  placeholderText: {
    [POST_TYPES.VIDEO]: [
      'MP4 or MOV files',
      '3 seconds to 10 minutes',
      '9:16 aspect ratio',
      '1GB max',
    ],
  },
  supportedSocialChannels: [SOCIAL_CHANNELS.REEL],
  validationMessages: {
    videoAspectRatio:
      'The aspect ratio does not seem to be valid. Please check that the aspect ratio is 9:16.',
    videoFileType:
      'The file type does not seem to be valid. Please check that the file type is either .MP4 or .MOV.',
  },
  videoDurationLimits: {
    [POST_TYPES.VIDEO]: {
      min: 3,
      max: 600,
    },
  },
  messageSettings: {
    maximumLength: 2200,
    urlLength: 23,
  },
  tagPatterns: {
    [TAG_TYPES.HASHTAG]: new RegExp(`#(${SPECIAL_CHARACTER_REGEX})`, 'g'),
    [TAG_TYPES.MENTION]: new RegExp(`@(${SPECIAL_CHARACTER_REGEX})`, 'g'),
  },
} as const;

export default constants;
