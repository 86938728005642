import { useState } from 'react';

import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';

import { AUTOFEED_SHARE_MODES } from 'common/constants';

import ApprovalsToggle from 'components/settings/automation/ApprovalsToggle';
import AutomationToggle from 'components/settings/automation/AutomationToggle';
import { AutomationSettings } from 'components/settings/automation/Overview';

import { ApprovalSettings, AutofeedSettings } from 'types';

function setApprovalState(
  currentSettings: Partial<AutofeedSettings>,
  approvalState: boolean,
) {
  let updatedSettings: Partial<AutofeedSettings> = { ...currentSettings };

  const approvalSettingsKeys = ['newShareApprovals', 'reshareApprovals'];
  approvalSettingsKeys.forEach((key) => {
    const approvalSettingsKey = key as keyof AutofeedSettings;

    const approvalSettings = (currentSettings[approvalSettingsKey] || {
      active: false,
      evergreenOnly: false,
    }) as ApprovalSettings;

    approvalSettings.active = approvalState;
    updatedSettings = {
      ...updatedSettings,
      [approvalSettingsKey]: approvalSettings,
    };
  });

  return updatedSettings;
}

interface AutomationToggleProps {
  automationSettings: AutomationSettings;
}

function AutomationAndApprovalToggles({
  automationSettings,
}: AutomationToggleProps) {
  const [isApprovalsSaving, setIsApprovalsSaving] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const { automationActive, approvalsActive, newShareMode, reshareMode } =
    automationSettings;

  const showWarning =
    automationActive &&
    !approvalsActive &&
    newShareMode === AUTOFEED_SHARE_MODES.OFF &&
    reshareMode === AUTOFEED_SHARE_MODES.OFF;

  return (
    <Flex flexDir="column" gap={2}>
      <AutomationToggle
        automationActive={automationActive}
        showWarning={showWarning}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        approvalsActive={approvalsActive}
        setApprovalState={setApprovalState}
        setIsApprovalsSaving={setIsApprovalsSaving}
      />
      <ApprovalsToggle
        approvalsActive={approvalsActive}
        setApprovalState={setApprovalState}
        isSaving={isApprovalsSaving}
        setIsSaving={setIsApprovalsSaving}
        setErrorMessage={setErrorMessage}
        automationActive={automationActive}
      />
    </Flex>
  );
}

export default AutomationAndApprovalToggles;
