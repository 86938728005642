import * as MediaItem from 'common/mediaItem';
import PreviewLoading from 'components/compose/PreviewLoading';
import { PostPreview } from 'types';

interface PreviewWrapperProps {
  children: JSX.Element;
  postPreview: PostPreview;
}

const PreviewWrapper = ({ children, postPreview }: PreviewWrapperProps) => {
  if (postPreview.isHidden || postPreview.isShared) {
    return null;
  }

  const { guid, mediaItem } = postPreview;
  const isLoading = MediaItem.getIsLoading({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });

  if (isLoading) {
    return (
      <PreviewLoading
        accountAPIId={accountAPIId}
        guid={guid}
        key={`preview-${guid}`}
      />
    );
  }

  return children;
};

export default PreviewWrapper;
