import {
  Banner,
  RefreshIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useErrorTracking } from './useErrorTracking';

const OfflineBanner = () => {
  const errorMessage = 'Please check your internet connection.';

  useErrorTracking({ errorMessage, errorType: 'Offline' });

  return (
    <Banner type="error">
      <Banner.Tag>Offline</Banner.Tag>
      <Banner.Body>
        <Text size="sm">{errorMessage}</Text>
      </Banner.Body>
      <Banner.Link href={window.location.href} icon={<RefreshIcon />}>
        Refresh
      </Banner.Link>
    </Banner>
  );
};

export default OfflineBanner;
