import {
  getAPIAltName,
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
  getProperty,
  getPropertyIdForAccountAPIId,
  getPropertyName,
} from 'common/accountAPIs';
import {
  API_TYPE_IDS,
  AUTOFEED_SHARE_MODES,
  CONTENT_TYPES,
  POST_TYPES,
  SHARE_ORIGINS,
  SHARE_TIME_TYPES,
  TIME_SENSITIVITY_NAMES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getDateFromUnix } from 'common/datetime';
import * as MediaItem from 'common/mediaItem';
import { isEbxImage } from 'common/s3';
import { getFeatureToggle } from 'common/settings';
import { replaceShareURL } from 'common/shareURL';
import {
  getPostTypeLabel,
  getRawMentionsFromMessage,
  getSocialNetworkName,
  getTrackingSocialChannel,
} from 'common/social';
import { toSentenceCase } from 'common/string';
import { getFileSuffix, getRootDomain } from 'common/url';
import { AutofeedSettings, FixTypeLater } from 'types';

interface DisplayOptions {
  filterBy: string;
  timeFrame: string;
  fromTime: number;
  toTime: number;
  timeField?: string;
}

function getTimeFilter(displayOptions: DisplayOptions) {
  const timeFilter = [];
  timeFilter.push(
    displayOptions.timeField === 'publish' ? 'Published' : 'Shared',
  );
  switch (displayOptions.timeFrame.toString()) {
    case '6':
      timeFilter.push('in last 6 hours');
      break;
    case '12':
      timeFilter.push('in last 12 hours');
      break;
    case '24':
      timeFilter.push('in last 24 hours');
      break;
    case '168':
      timeFilter.push('in last 7 days');
      break;
    case '720':
      timeFilter.push('in last 30 days');
      break;
    case '8760':
      timeFilter.push('in last 365 days');
      break;
    case 'CUSTOM':
      timeFilter.push('Between');
      break;
    default:
      timeFilter.push('in unknown timeframe');
  }

  return timeFilter.join(' ');
}

function getFormattedDate(timestamp: number) {
  if (timestamp == null) {
    return 'N/A';
  }

  const elements = getDateFromUnix(timestamp);
  const formattedDate = [];
  formattedDate.push(elements.day.toString());
  formattedDate.push(elements.monthName.substr(0, 3));
  formattedDate.push(elements.year.toString().substr(0, 2));

  return formattedDate.join(' ');
}

function getTimeBounds(displayOptions: DisplayOptions) {
  if (displayOptions.timeFrame !== 'CUSTOM') {
    return '';
  }

  const fromDate = getFormattedDate(displayOptions.fromTime);
  const toDate = getFormattedDate(displayOptions.toTime);

  return `${fromDate} : ${toDate}`;
}

export function getArticleArchiveFilterParams({
  prevDisplayOptions,
  nextDisplayOptions,
}: {
  prevDisplayOptions: DisplayOptions;
  nextDisplayOptions: DisplayOptions;
}) {
  const nextFilterOptions = nextDisplayOptions.filterBy;
  const prevFilterOptions = prevDisplayOptions.filterBy;
  const nextDateFilter = getTimeFilter(nextDisplayOptions);
  const prevDateFilter = getTimeFilter(prevDisplayOptions);
  const nextTimeBounds = getTimeBounds(nextDisplayOptions);
  const prevTimeBounds = getTimeBounds(prevDisplayOptions);

  return {
    'Filter Options': nextFilterOptions,
    'Previous Filter Options': prevFilterOptions,
    'Date Filter': nextDateFilter,
    'Previous Date Filter': prevDateFilter,
    'Filter Time Bounds': nextTimeBounds,
    'Previous Filter Time Bounds': prevTimeBounds,
  };
}

export function getAutofeedSettingsTrackingParams({
  autofeedSettings,
  origin = 'Page Automation',
}: {
  autofeedSettings: AutofeedSettings | null;
  origin?: string;
}) {
  if (!autofeedSettings) {
    return {};
  }
  const accountAPIId = getCurrentAccountAPIId();
  const networkAndPageName = getNetworkAndPageName({
    accountAPIId,
  });
  const autofeedStatus = autofeedSettings.autoFeedActive ? 'On' : 'Off';
  const newShareMode = toSentenceCase(autofeedSettings.newShareMode ?? '');
  const dailyNewShares =
    autofeedSettings.newShareMode === AUTOFEED_SHARE_MODES.CUSTOM
      ? autofeedSettings.bestArticlesNewContentNum
      : 'undefined';
  const maxArticleAgeNewShare =
    autofeedSettings.newShareMode === AUTOFEED_SHARE_MODES.CUSTOM
      ? (autofeedSettings.startPastMinsForNewShare ?? 0) / 60
      : 'undefined';
  const newSharePrefix = autofeedSettings.newshareMessagePrefix;
  const newShareSuffix = autofeedSettings.newshareMessageSuffix;
  const reshareMode = toSentenceCase(autofeedSettings.reshareMode ?? '');
  const dailyReshares =
    autofeedSettings.reshareMode === AUTOFEED_SHARE_MODES.CUSTOM
      ? autofeedSettings.bestArticlesReshareNum
      : 'undefined';
  const maxArticleAgeReshare =
    autofeedSettings.reshareMode === AUTOFEED_SHARE_MODES.CUSTOM
      ? autofeedSettings.startPastMinsForBestArticleReshare / 60
      : 'undefined';
  const maxReshares =
    autofeedSettings.reshareMode === AUTOFEED_SHARE_MODES.CUSTOM
      ? autofeedSettings.maxResharesPerArticle
      : 'undefined';
  const minTimeBetweenReshares =
    autofeedSettings.reshareMode === AUTOFEED_SHARE_MODES.CUSTOM
      ? (autofeedSettings.minMinsBetweenSharesOfSameArticle ?? 0) / 60
      : 'undefined';
  const reshareEvergreenOnly = autofeedSettings.reshareEvergreenContentOnly;
  const resharePrefix = autofeedSettings.reshareMessagePrefix;
  const reshareSuffix = autofeedSettings.reshareMessageSuffix;
  const schedulePeriodsUsed =
    autofeedSettings.statusScheduleTriggers.length > 0;
  const activeSchedulePeriods = autofeedSettings.statusScheduleTriggers.filter(
    (schedule) => schedule.statusAfterTrigger,
  ).length;
  const dataSourceMessage =
    autofeedSettings?.shareDataSource?.messageSource ?? 'NULL';
  const dataSourceTitle =
    autofeedSettings?.shareDataSource?.titleSource ?? 'NULL';
  const dataSourceDescription =
    autofeedSettings?.shareDataSource?.descriptionSource ?? 'NULL';
  const dataSourceImage =
    autofeedSettings?.shareDataSource?.imageSource ?? 'NULL';
  const emojiToggleStatus = autofeedSettings.newShareMessageEmojiSuffix
    ? 'ON'
    : 'OFF';

  return {
    Origin: origin,
    'Network - Social Page': networkAndPageName,
    'Account API Id': accountAPIId,
    'Autofeed Status': autofeedStatus,
    'New Share Mode': newShareMode,
    'Daily New Shares': dailyNewShares,
    'Maximum Article Age (new share)': maxArticleAgeNewShare,
    'New Share Prefix': newSharePrefix,
    'New Share Suffix': newShareSuffix,
    'Reshares Mode': reshareMode,
    'Daily Reshares': dailyReshares,
    'Maximum Article Age (reshare)': maxArticleAgeReshare,
    'Max Reshares Per Article': maxReshares,
    'Minimum Time Between Reshares': minTimeBetweenReshares,
    'Reshares Evergreen Only': reshareEvergreenOnly,
    'Reshares Prefix': resharePrefix,
    'Reshares Suffix': reshareSuffix,
    'Schedule Periods Used': schedulePeriodsUsed,
    'Num Active Schedule Periods': activeSchedulePeriods,
    'Data Sources Message': dataSourceMessage,
    'Data Sources Title': dataSourceTitle,
    'Data Sources Description': dataSourceDescription,
    'Data Sources Image': dataSourceImage,
    'Emoji Toggle Status': emojiToggleStatus,
  };
}

export function getCommonTrackingParams({
  mediaItem,
}: {
  mediaItem: FixTypeLater;
}): Record<string, unknown> {
  const abInfo = MediaItem.getABInfo({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const postType = MediaItem.getPostType({ mediaItem });
  return {
    'Social Network': getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
    'Account API Id': accountAPIId,
    'Article URL':
      postType === POST_TYPES.LINK
        ? MediaItem.getUnshortenedShareURL({ mediaItem })
        : 'undefined',
    'AB Test ID': abInfo.isABVariation
      ? 'AB'.substr(abInfo.currentABVariationIndex, 1)
      : 'None',
  };
}

export function getNetworkAndPageName({
  accountAPIId,
}: {
  accountAPIId: number;
}) {
  const apiTypeId = getAPITypeId({ accountAPIId });
  const socialNetworkName = getSocialNetworkName({ apiTypeId });
  const apiAltName = getAPIAltName({ accountAPIId });
  return [socialNetworkName, apiAltName].join('-');
}

export function getOrigin(pathname: string) {
  switch (pathname) {
    case '/share':
      return 'Home Feed';
    case '/queued':
      return 'Schedule Queue';
    case '/analytics':
      return 'Analytics Page';
    case '/archive':
      return 'Article Archive';
    case '/autofeed':
      return 'Autofeed Settings';
    default:
      return 'Unknown';
  }
}

export function getSchedulePostTrackingParams({
  mediaItem,
  mediaItemIndex,
}: {
  mediaItem: FixTypeLater;
  mediaItemIndex?: number;
}) {
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const postType = MediaItem.getPostType({ mediaItem });
  const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
  let articleURL = trackingDetails?.articleURL ?? 'undefined';
  if (postType === POST_TYPES.LINK) {
    articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  }
  const audienceRestriction = MediaItem.getFbTargetingParams({ mediaItem });
  const ageRestriction = !audienceRestriction?.ageMin
    ? 'Everyone'
    : `${audienceRestriction.ageMin}plus`;
  const geographicRestrictionPresent = !!audienceRestriction?.countries;
  const geographicRestriction = !audienceRestriction?.countries
    ? []
    : audienceRestriction.countries;
  const imageURLs = MediaItem.getImageURLs({ mediaItem });
  const fileType = getFileSuffix(imageURLs[0]);
  const labels = MediaItem.getMediaItemTags({ mediaItem });
  const labelsPresent = labels.length > 0;
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
  const propertyName = propertyId ? getPropertyName({ propertyId }) : null;
  const isLinkPost = postType === POST_TYPES.LINK;
  let messageText: string | null | undefined = 'undefined';
  let messageType = 'undefined';
  if (isLinkPost) {
    const shareMessages = MediaItem.getMessages({ mediaItem });
    if (shareMessages != null && shareMessages.selectedContentTypeId != null) {
      messageText = replaceShareURL({
        text: shareMessages.selectedShareContent.value,
      });
      messageType =
        CONTENT_TYPES[shareMessages.selectedContentTypeId].description;
    }
  } else if (apiTypeId === API_TYPE_IDS.TIKTOK) {
    messageText = MediaItem.getMessage({ mediaItem });
  }
  const noOfImages = imageURLs.length;
  const noOfImagesAdded = imageURLs.filter((image) => isEbxImage(image)).length;
  let rootURL = '';
  if (propertyId != null) {
    const property = getProperty({ propertyId });
    if (property != null) {
      rootURL = property.propertyRootURL;
    }
  }
  let shareType;
  switch (postType) {
    case POST_TYPES.LINK:
      shareType =
        getRootDomain(articleURL) === getRootDomain(rootURL)
          ? 'Internal Link'
          : 'External Link';
      break;
    case POST_TYPES.STATUS:
      shareType =
        noOfImages === 0 ? 'Text' : getPostTypeLabel({ apiTypeId, postType });
      break;
    case POST_TYPES.VIDEO:
      shareType = getPostTypeLabel({ apiTypeId, postType });
      break;
    case POST_TYPES.PHOTO_STORY:
      shareType = getPostTypeLabel({ apiTypeId, postType });
      break;
    default:
      shareType = 'Unknown';
  }
  shareType += ' Post';
  const socialChannel = getTrackingSocialChannel({
    apiTypeId,
    mediaItem,
  });
  const timeSensitivity = MediaItem.getTimeSensitivityTypeId({ mediaItem });
  let timingOption;
  switch (MediaItem.getShareTime({ mediaItem }).type) {
    case SHARE_TIME_TYPES.OPTIMAL:
      timingOption = 'Optimal';
      break;
    case SHARE_TIME_TYPES.TIMESLOT:
      timingOption = 'Timeslot';
      break;
    case SHARE_TIME_TYPES.TIME:
      timingOption = 'Specific Time';
      break;
    case SHARE_TIME_TYPES.NOW:
      timingOption = 'Now';
      break;
    default:
      timingOption = 'Unknown';
  }
  const firstComment = MediaItem.getFirstComment({ mediaItem });
  let containsHashtag;
  let containsMention;

  if (!firstComment) {
    containsHashtag = null;
    containsMention = null;
  } else {
    containsHashtag = firstComment.match(/#/g)
      ? firstComment.match(/#/g).length > 0
      : false;
    containsMention = firstComment.match(/@/g)
      ? firstComment.match(/@/g).length > 0
      : false;
  }

  const hashtagsInPost = (messageText || '').match(/(#[\p{L}\d_]+)/gu);
  const rawMentionsInPost = getRawMentionsFromMessage({
    message: messageText || '',
    apiTypeId,
  });

  const mentionsInPost = MediaItem.getMentions({ mediaItem }).filter((tag) =>
    rawMentionsInPost.includes(tag.raw),
  );

  const isLinkinbioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  let isLinkInBioConnected = null;

  if (apiTypeId === API_TYPE_IDS.INSTAGRAM) {
    isLinkInBioConnected = !!isLinkinbioEnabled;
  }

  const trackingParams: Record<string, unknown> = {
    'Account API Id': accountAPIId,
    'First Comment': Boolean(firstComment),
    'Hashtag In First Comment': containsHashtag,
    'Mention In First Comment': containsMention,
    'Share Type': shareType,
    'Media Type': getMediaTypeName({ mediaItem }),
    'Social Channel': socialChannel,
    'File Type': fileType,
    'Social Network': getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
    'AB Test': MediaItem.getABInfo({ mediaItem }).isABVariation,
    'Live Story': isLinkPost ? MediaItem.getIsLive({ mediaItem }) : 'undefined',
    Timing: timingOption,
    'Time Sensitivity':
      timeSensitivity !== null && TIME_SENSITIVITY_NAMES[timeSensitivity]
        ? TIME_SENSITIVITY_NAMES[timeSensitivity].categoryLabel
        : 'undefined',
    'Article URL': articleURL,
    'Message Type': messageType,
    'Message Text': messageText,
    Score: isLinkPost
      ? MediaItem.getMostRecentScore({ mediaItem })
      : 'undefined',
    Ranking: trackingDetails?.NewsFeedArticleRankAtSchedule ?? 'undefined',
    Origin: trackingDetails?.Origin ?? 'undefined',
    'Number of Images': noOfImagesAdded,
    'Labels Present': labelsPresent,
    Labels: JSON.stringify(labels),
    'Age Restriction': ageRestriction,
    'Geographic Restriction Present': geographicRestrictionPresent,
    'Geographic Restriction': geographicRestriction,
    Overlay: trackingDetails?.hasImageOverlay ?? 'undefined',
    'Hashtags Present': hashtagsInPost ? hashtagsInPost.length > 0 : false,
    Hashtags: hashtagsInPost,
    'Mentions Present': rawMentionsInPost.length > 0,
    Mentions:
      mentionsInPost.length > 0 ? mentionsInPost.map((tag) => tag.clean) : null,
    ...(mediaItemIndex != null && { 'Media Item Index': mediaItemIndex }),
    ...(isLinkinbioEnabled &&
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      postType === POST_TYPES.LINK && {
        'Link in Bio': MediaItem.getShouldSendURL({ mediaItem }) ?? false,
      }),
    'LinkInBio Connected': isLinkInBioConnected,
  };

  const currentPropertyId = getCurrentPropertyId();
  if (
    Number(propertyId) !== Number(currentPropertyId) &&
    trackingParams.Origin === SHARE_ORIGINS.ADDITIONAL_SOCIAL_NETWORK
  ) {
    trackingParams.Origin = SHARE_ORIGINS.ADDITIONAL_NETWORK_OTHER_PROPERTY;
    trackingParams.PropertyID = propertyId;
    trackingParams.PropertyName = propertyName;
  }

  return trackingParams;
}

// jscpd:ignore-start

/**
 * Get the tracking parameters for when a post is boosted.
 *
 * TODO: It may be better for us to create a generic function that takes a mediaItem
 * and a list of tracking properties we want to populate, and returns the tracking parameters.
 */
export function getBoostPostTrackingParams({
  mediaItem,
}: {
  mediaItem: FixTypeLater;
}) {
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const audienceRestriction = MediaItem.getFbTargetingParams({ mediaItem });
  const ageRestriction = !audienceRestriction?.ageMin
    ? 'Everyone'
    : `${audienceRestriction.ageMin}plus`;
  const geographicRestrictionPresent = !!audienceRestriction?.countries;
  const geographicRestriction = !audienceRestriction?.countries
    ? []
    : audienceRestriction.countries;
  const imageURLs = MediaItem.getImageURLs({ mediaItem });
  const labels = MediaItem.getMediaItemTags({ mediaItem });
  const labelsPresent = labels.length > 0;
  const postType = MediaItem.getPostType({ mediaItem });
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
  const propertyName = propertyId ? getPropertyName({ propertyId }) : null;
  const isLinkPost = postType === POST_TYPES.LINK;
  let messageText: string | null | undefined = 'undefined';
  let messageType = 'undefined';
  if (isLinkPost) {
    const shareMessages = MediaItem.getMessages({ mediaItem });
    if (shareMessages != null && shareMessages.selectedContentTypeId != null) {
      messageText = replaceShareURL({
        text: shareMessages.selectedShareContent.value,
      });
      messageType =
        CONTENT_TYPES[shareMessages.selectedContentTypeId].description;
    }
  } else if (apiTypeId === API_TYPE_IDS.TIKTOK) {
    messageText = MediaItem.getMessage({ mediaItem });
  }
  const noOfImagesAdded = imageURLs.filter((image) => isEbxImage(image)).length;
  const timeSensitivity = MediaItem.getTimeSensitivityTypeId({ mediaItem });
  let timingOption;
  switch (MediaItem.getShareTime({ mediaItem }).type) {
    case SHARE_TIME_TYPES.OPTIMAL:
      timingOption = 'Optimal';
      break;
    case SHARE_TIME_TYPES.TIMESLOT:
      timingOption = 'Timeslot';
      break;
    case SHARE_TIME_TYPES.TIME:
      timingOption = 'Specific Time';
      break;
    case SHARE_TIME_TYPES.NOW:
      timingOption = 'Now';
      break;
    default:
      timingOption = 'Unknown';
  }
  const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });

  const trackingParams: Record<string, unknown> = {
    'Social Network': getSocialNetworkName({ apiTypeId }),
    'Social Page': getAPIPostName({ accountAPIId }),
    'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
    'Account API Id': accountAPIId,
    Location: 'Analytics',
    'AB Test': MediaItem.getABInfo({ mediaItem }).isABVariation,
    'Live Story': isLinkPost ? MediaItem.getIsLive({ mediaItem }) : 'undefined',
    Timing: timingOption,
    'Time Sensitivity':
      timeSensitivity !== null && TIME_SENSITIVITY_NAMES[timeSensitivity]
        ? TIME_SENSITIVITY_NAMES[timeSensitivity].categoryLabel
        : 'undefined',
    'Article URL': isLinkPost ? articleURL : 'undefined',
    'Message Type': messageType,
    'Message Text': messageText,
    Score: isLinkPost
      ? MediaItem.getMostRecentScore({ mediaItem })
      : 'undefined',
    Ranking: trackingDetails?.NewsFeedArticleRankAtSchedule ?? 'undefined',
    Origin: trackingDetails?.Origin ?? 'undefined',
    'Number of Images': noOfImagesAdded,
    'Labels Present': labelsPresent,
    Labels: JSON.stringify(labels),
    'Age Restriction': ageRestriction,
    'Geographic Restriction Present': geographicRestrictionPresent,
    'Geographic Restriction': geographicRestriction,
  };

  const currentPropertyId = getCurrentPropertyId();
  if (
    Number(propertyId) !== Number(currentPropertyId) &&
    trackingParams.Origin === SHARE_ORIGINS.ADDITIONAL_SOCIAL_NETWORK
  ) {
    trackingParams.Origin = SHARE_ORIGINS.ADDITIONAL_NETWORK_OTHER_PROPERTY;
    trackingParams.PropertyID = propertyId;
    trackingParams.PropertyName = propertyName;
  }

  return trackingParams;
}

// jscpd:ignore-end

export function getShareOriginFromPathname(pathname: string) {
  switch (pathname) {
    case '':
    case '/':
    case '/share':
    case '/queue':
    case '/approvals':
      return SHARE_ORIGINS.HOME_FEED;
    case '/analytics':
      return SHARE_ORIGINS.ANALYTICS_PAGE;
    case '/archive':
      return SHARE_ORIGINS.ARCHIVE;
    default:
      return 'Unknown';
  }
}

export function getMediaTypeName({
  mediaItem = MediaItem,
}: {
  mediaItem: FixTypeLater;
}) {
  const postType = MediaItem.getPostType({ mediaItem });
  switch (postType) {
    case POST_TYPES.PHOTO_STORY:
    case POST_TYPES.STATUS:
      return 'Image';
    case POST_TYPES.VIDEO:
      return 'Video';
    default:
      return 'Article';
  }
}
