import {
  BoxProps,
  HealthIcon,
  UseToastProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useHealthMenuActions } from 'state/healthMenu';

const TOAST_DURATION_MS = 10_000; // 10 seconds

export function useDefaultHealthMenuToastProps({
  closeToast,
}: {
  closeToast: () => void;
}): Partial<UseToastProps> {
  const { openHealthMenu } = useHealthMenuActions();

  const handleToastClick = () => {
    openHealthMenu();
    closeToast();
  };

  const containerProps: BoxProps = {
    cursor: 'pointer',
    marginBottom: 7,
    onClick: handleToastClick,
  };

  return {
    containerProps,
    duration: TOAST_DURATION_MS,
    isClosable: true,
    icon: <HealthIcon size={5} color="red.600" />,
    variant: 'chilledError',
  };
}
