import { Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

interface ConfirmUnsavedChangesModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmUnsavedChangesModal = ({
  onClose,
  onConfirm,
}: ConfirmUnsavedChangesModalProps) => {
  return (
    <Modal isOpen onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Leave without saving?</Modal.Title>
      </Modal.Header>
      <Modal.Body>You will lose the changes you made.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onConfirm}>
          Leave without saving
        </Button>
        <Button variant="primary" onClick={onClose}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmUnsavedChangesModal;
