import * as MediaItem from 'common/mediaItem';
import { useComposeBoxContext } from 'context/ComposeBoxContext';

interface ComposeBoxModalProps {
  children: React.ReactNode;
}

const ComposeBoxModal = ({ children }: ComposeBoxModalProps) => {
  const context = useComposeBoxContext();
  const { mediaItem } =
    context.postPreviews.find(({ isHidden }) => !isHidden) ??
    context.postPreviews[0];
  const mediaId = MediaItem.getMediaId({ mediaItem });

  return (
    <div className="ebx-modal-outer">
      <div className="ebx-modal-inner">
        <div
          className="full_edit row"
          data-tip
          data-for={mediaId}
          data-cy-id="composeBox"
          data-cy-attribute="isLoading:false"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ComposeBoxModal;
