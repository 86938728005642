import { isNull } from 'common/utility';

export function checkPasswordStrength(password) {
  const passwordRegex = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{10,}$';
  const match = password.match(passwordRegex);
  return !isNull(match);
}

export function checkPasswordsMatch(password, verifiedPassword) {
  return password === verifiedPassword;
}
