import { useEffect } from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { OFFLINE_DETECTED, ONLINE_DETECTED } from 'pubsub/topics';
import {
  useHighPriorityErrorTypeActions,
  useIsOnline,
} from 'state/highPriorityErrorType';

/**
 * Detects when the user has gone offline.
 */
export function useOnlineStatus() {
  const isOnline = useIsOnline();
  const { initialiseHighPriorityErrorState } =
    useHighPriorityErrorTypeActions();
  const currentPropertyId = getCurrentPropertyId();

  useEffect(() => {
    const tokens = [
      PubSub.subscribe(OFFLINE_DETECTED, () => {
        initialiseHighPriorityErrorState(currentPropertyId);
      }),
      PubSub.subscribe(ONLINE_DETECTED, () =>
        initialiseHighPriorityErrorState(currentPropertyId),
      ),
    ];

    return () => {
      tokens.forEach((token) => PubSub.unsubscribe(token));
    };
  }, [currentPropertyId, initialiseHighPriorityErrorState]);

  return { isOnline };
}
