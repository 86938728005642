/* eslint react-hooks/exhaustive-deps: "off" */

import $ from 'jquery';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { COLLECTION_NAMES } from 'common/constants';
import { getTimeFilterRange, getUnixTimestamp } from 'common/datetime';
import { createTimestamp, getDate, toLocalString } from 'common/datetimepicker';
import * as tracker from 'common/tracker';
import CustomRange from 'components/home/archive/CustomRange';
import Button from 'components/misc/Button';
import { getArticleArchiveFilterParams } from 'helpers/tracking';

const filterOut = (filterBy) =>
  filterBy
    .split(',')
    .filter(
      (entry) =>
        entry !== 'SHARE' && entry !== 'NOTSHARE' && entry !== 'RESHARE',
    );

const DateRange = (props) => {
  const { displayOptions, timeField, eventHandlers } = props;

  const [isSelectingRange, setIsSelectingRange] = useState(false);

  useEffect(() => {
    if (typeof $ !== 'undefined') {
      try {
        const dateRangeDropdown = $(
          `[data-cy-select="dateRange"][data-cy-attribute="timeField:${timeField}"]`,
        ).parent();
        dateRangeDropdown.unbind();
        dateRangeDropdown.on('hide.bs.dropdown', () => {
          setIsSelectingRange(false);
        });
      } catch (error) {
        //
      }
    }
  }, []);

  const handleApply = (fromTime, toTime) => {
    $(
      `[data-cy-select="dateRange"][data-cy-attribute="timeField:${timeField}"]`,
    ).dropdown('toggle');
    setIsSelectingRange(false);
    tracker.track({
      eventName: 'Update Archive Filter',
      trackingParams: getArticleArchiveFilterParams({
        prevDisplayOptions: props.displayOptions,
        nextDisplayOptions: {
          ...props.displayOptions,
          fromTime,
          toTime,
        },
      }),
    });
    const filterBy =
      timeField === 'share'
        ? filterOut(displayOptions.filterBy).toString()
        : displayOptions.filterBy;
    eventHandlers.handleTimeframeChange({
      collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
      timeFrame: displayOptions.timeFrame,
      fromTime,
      toTime,
      timeField: displayOptions.timeField,
      filterBy,
    });
  };

  const handleClose = () => {
    setIsSelectingRange(false);
  };

  const handleTimeframeChange = (thisTimeField, timeFrame, event) => {
    if (event) {
      event.stopPropagation();
    }
    if (
      timeField === displayOptions.timeField &&
      timeFrame === displayOptions.timeFrame
    ) {
      if (timeFrame === 'CUSTOM') {
        setIsSelectingRange(true);
      }
      return;
    }
    const range =
      timeFrame === 'CUSTOM'
        ? getTimeFilterRange(displayOptions.timeFrame)
        : getTimeFilterRange(timeFrame);
    let fromTime = range.fromTime;
    let toTime = range.toTime;
    let refreshFeedData = true;
    if (timeFrame === 'CUSTOM') {
      fromTime = createTimestamp({
        date: getDate(toLocalString(fromTime)),
        time: '00:00',
      });
      toTime = createTimestamp({
        date: getDate(toLocalString(getUnixTimestamp())),
        time: '23:59',
      });
      setIsSelectingRange(true);
      refreshFeedData = false;
    }
    tracker.track({
      eventName: 'Update Archive Filter',
      trackingParams: getArticleArchiveFilterParams({
        prevDisplayOptions: props.displayOptions,
        nextDisplayOptions: {
          ...props.displayOptions,
          timeFrame,
          fromTime,
          toTime,
          timeField: thisTimeField,
        },
      }),
    });
    const filterBy =
      thisTimeField === 'share'
        ? filterOut(displayOptions.filterBy).toString()
        : displayOptions.filterBy;
    eventHandlers.handleTimeframeChange({
      collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
      timeFrame,
      fromTime,
      toTime,
      timeField: thisTimeField,
      refreshFeedData,
      filterBy,
    });
  };

  const options = [
    { timeframe: 12, label: 'Last 12 hours' },
    { timeframe: 24, label: 'Last 24 hours' },
    { timeframe: 168, label: 'Last 7 days' },
    { timeframe: 720, label: 'Last 30 days' },
    { timeframe: 8760, label: 'Last 365 days' },
  ];

  const isActiveTimeframe = (timeFrame) => {
    return displayOptions.timeField === timeField &&
      displayOptions.timeFrame.toString() === timeFrame.toString()
      ? 'filter-active'
      : '';
  };

  const isActive = displayOptions.timeField === timeField;
  const activeClass = isActive ? 'filter-active' : '';
  const dropdownLabel =
    timeField === 'share' ? 'Date last shared' : 'Date last published';
  const customLabel =
    displayOptions.timeField === timeField &&
    displayOptions.timeFrame === 'CUSTOM'
      ? 'Edit date range'
      : 'Select date range';

  return (
    <div className="dropdown my-1">
      <Button
        className={`filter ${activeClass} mr-2 analytics-filter`}
        id={`${timeField}DateRangeDropdown`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        data-boundary="viewport"
        data-cy-select="dateRange"
        data-cy-attribute={`timeField:${timeField}`}
      >
        {dropdownLabel}
        <svg
          width="9"
          height="5"
          viewBox="0 0 9 5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginLeft: '6px' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.34056 0.256985L3.8978 1.75336C3.8978 1.75336 4.36444 2.28627 4.81775 2.28627C5.27106 2.28627 5.73636 1.75476 5.73636 1.75476L7.29494 0.256985C7.4664 0.0903181 7.69169 -0.00154286 7.92515 1.96097e-05C8.15861 0.00158207 8.38277 0.0964518 8.5522 0.2654C8.63526 0.346152 8.70168 0.443975 8.74736 0.552815C8.79303 0.661656 8.81698 0.779198 8.81773 0.898167C8.81848 1.01714 8.79601 1.135 8.75171 1.24447C8.70742 1.35394 8.64223 1.45268 8.5602 1.53458L5.44438 4.65214C5.27518 4.82015 5.05041 4.91221 4.81775 4.90878C4.58555 4.91184 4.36132 4.81981 4.19245 4.65214L1.0753 1.53458C0.993266 1.45268 0.928082 1.35394 0.883784 1.24447C0.839487 1.135 0.817017 1.01714 0.817767 0.898167C0.818517 0.779198 0.84247 0.661656 0.888144 0.552815C0.933817 0.443975 1.00024 0.346152 1.0833 0.2654C1.25273 0.0964518 1.47689 0.00158207 1.71035 1.96097e-05C1.94381 -0.00154286 2.1691 0.0903181 2.34056 0.256985Z"
          />
        </svg>
      </Button>
      <div
        className="dropdown-menu"
        aria-labelledby={`${timeField}DateRangeDropdown`}
        style={{ minWidth: '300px' }}
      >
        {isSelectingRange && (
          <CustomRange
            fromTime={displayOptions.fromTime ?? Math.floor(Date.now() / 1000)}
            toTime={displayOptions.toTime ?? Math.floor(Date.now() / 1000)}
            eventHandlers={{ handleApply, handleClose }}
          />
        )}
        {!isSelectingRange &&
          options.map((option) => (
            <a
              className={`dropdown-item ${isActiveTimeframe(option.timeframe)}`}
              onClick={() => handleTimeframeChange(timeField, option.timeframe)}
              key={option.timeframe}
              data-cy-id={option.timeframe}
            >
              {option.label}
            </a>
          ))}
        {!isSelectingRange && (
          <form>
            <a
              className={`dropdown-item ${isActiveTimeframe('CUSTOM')}`}
              onClick={(event) =>
                handleTimeframeChange(timeField, 'CUSTOM', event)
              }
              data-cy-id="CUSTOM"
            >
              {customLabel}
              <svg
                width="5"
                height="8"
                viewBox="0 0 5 8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  marginLeft: '5px',
                  position: 'relative',
                  top: '-1px',
                }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.256985 6.47719L1.75336 4.91995C1.75336 4.91995 2.28627 4.45331 2.28627 4C2.28627 3.54669 1.75476 3.08139 1.75476 3.08139L0.256985 1.52281C0.0903182 1.35135 -0.00154278 1.12606 1.96944e-05 0.892598C0.00158216 0.659138 0.0964519 0.434978 0.2654 0.265548C0.346152 0.182488 0.443975 0.116068 0.552815 0.0703883C0.661656 0.0247183 0.779198 0.000768194 0.898167 1.81305e-05C1.01714 -0.000731456 1.135 0.0217381 1.24447 0.0660381C1.35394 0.110328 1.45268 0.175518 1.53458 0.257548L4.65214 3.37337C4.82015 3.54257 4.91221 3.76734 4.90878 4C4.91184 4.2322 4.81981 4.45643 4.65214 4.6253L1.53458 7.74245C1.45268 7.82448 1.35394 7.88967 1.24447 7.93396C1.135 7.97826 1.01714 8.00073 0.898167 7.99998C0.779198 7.99923 0.661656 7.97528 0.552815 7.92961C0.443975 7.88393 0.346152 7.81751 0.2654 7.73445C0.0964518 7.56502 0.00158208 7.34086 1.96203e-05 7.1074C-0.00154285 6.87394 0.0903182 6.64865 0.256985 6.47719Z"
                />
              </svg>
            </a>
          </form>
        )}
      </div>
    </div>
  );
};

DateRange.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  timeField: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default DateRange;
