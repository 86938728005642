import { Box, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { getCurrentProperty, getCurrentPropertyId } from 'common/accountAPIs';
import {
  PROPERTY_STATES,
  REACT_PREVENT_RENDER,
  TRACKER_KEY,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { addSuccessNotification } from 'common/notifications';
import { getFeatureToggle } from 'common/settings';
import { getSocialNetworks } from 'common/social';
import HighPriorityErrorsBanner from 'components/misc/HighPriorityErrorsBanner';
import Header from 'components/setup/Header';
import InstallWebsiteTag from 'components/setup/InstallWebsiteTag';
import Sidebar from 'components/setup/Sidebar';
import SocialPageSelect from 'components/setup/SocialPageSelect';
import TestWebsiteTagModal from 'components/setup/TestWebsiteTagModal';
import GlobalInfoContext from 'context/GlobalInfoContext';
import {
  OnboardingContext,
  OnboardingContextInterface,
} from 'context/OnboardingContext';
import Protected from 'layouts/Protected';
import ConnectSocialPages from 'pages/setup/ConnectSocialPages';
import ConnectYourContent from 'pages/setup/ConnectYourContent';
import HoldingScreen from 'pages/setup/HoldingScreen';
import ReviewPropertySetup from 'pages/setup/ReviewPropertySetup';
import UserManagement from 'pages/setup/UserManagement';
import type { Page } from 'types';

const isTrackerEnabledForCurrentProperty = () =>
  getFeatureToggle({
    featureName: FEATURE_TOGGLES.REQUIRES_EBX_TRACKER,
    propertyId: getCurrentPropertyId(),
  }) ?? true;

/**
 * @property {string} path            - The pathname of the page
 * @property {JSX.Element} component  - The component to render for the page
 * @property {boolean} isProtected    - Whether the page can only be accessed once previous pages
 *                                      have been completed
 */
interface OnboardingPage {
  path: string;
  component: JSX.Element;
  isProtected: boolean;
}

const getOnboardingPages = (): { [stepNumber: number]: OnboardingPage } => {
  const trackerEnabled = isTrackerEnabledForCurrentProperty();

  return {
    1: {
      path: 'users',
      component: <UserManagement />,
      isProtected: false,
    },
    2: {
      path: 'socialpages',
      component: <ConnectSocialPages />,
      isProtected: true,
    },
    3: {
      path: 'content',
      component: <ConnectYourContent />,
      isProtected: true,
    },
    ...((trackerEnabled && {
      4: {
        path: 'websitetag',
        component: <InstallWebsiteTag />,
        isProtected: true,
      },
    }) as { [stepNumber: number]: OnboardingPage }),
    [trackerEnabled ? 5 : 4]: {
      path: 'review',
      component: <ReviewPropertySetup />,
      isProtected: true,
    },
    [trackerEnabled ? 6 : 5]: {
      path: 'holding',
      component: <HoldingScreen />,
      isProtected: true,
    },
  };
};

export type StepNumber = number;

interface Property {
  accountAPIs: object;
  accountFeeds?: ReadonlyArray<object>;
  propertyId: number;
  propertyState: keyof typeof PROPERTY_STATES;
  propertyUsers?: ReadonlyArray<object>;
  trafficAPIs?: object;
}

const getCurrentPathFromProperty = (property: Property) => {
  const { accountAPIs, accountFeeds, propertyId, propertyState } = property;

  const onboardingPages = getOnboardingPages();

  // If property is under setup, go to the holding step.
  if (propertyState === PROPERTY_STATES.UNDERSETUP) {
    return isTrackerEnabledForCurrentProperty()
      ? onboardingPages[6].path
      : onboardingPages[5].path;
  }

  // If the property has at least one feed...
  if (accountFeeds && accountFeeds.length > 0) {
    // Take the user to the review or the web tracker step.
    return sessionStorage.getItem(`${TRACKER_KEY}-${propertyId}`) === 'true'
      ? onboardingPages[5].path
      : onboardingPages[4].path;
  }

  // If the property has at least one account, go to the content feeds step.
  const socialNetworks = getSocialNetworks({ isOnSetupPage: true });
  if (
    Object.values(accountAPIs).filter((x) =>
      socialNetworks.includes(x.apiTypeId),
    ).length > 0
  ) {
    return onboardingPages[3].path;
  }

  // Go to the social pages step.
  return onboardingPages[2].path;
};

/**
 * assertIsValidStep - Used to assure TypeScript that step is a valid step
 *                    i.e. that onboardingPages[step] will be defined
 *                    see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-7.html#assertion-functions
 * @param step The step number to verify
 */
function assertIsValidStep(step: number): asserts step is StepNumber {
  let stepUpperBound = 6;
  if (!isTrackerEnabledForCurrentProperty) {
    stepUpperBound = 5;
  }
  if (typeof step !== 'number' || step < 1 || step > stepUpperBound) {
    throw new Error('Current step is not valid');
  }
}

function getStepNumberFromPathname(pathname: string) {
  const onboardingPages = getOnboardingPages();
  return Object.keys(onboardingPages)
    .map((key) => {
      const step = Number(key);
      assertIsValidStep(step);
      return step;
    })
    .find((step) => onboardingPages[step].path === pathname);
}

const getVisitedPropertiesList = () => {
  return JSON.parse(
    localStorage.getItem('onboardingPropertiesVisited') ?? '[]',
  );
};

const setPropertyIdInLocalStorage = (currentPropertyId: number) => {
  const propertyIdList = getVisitedPropertiesList();
  if (!propertyIdList.includes(currentPropertyId)) {
    propertyIdList.push(currentPropertyId);
    localStorage.setItem(
      'onboardingPropertiesVisited',
      JSON.stringify(propertyIdList),
    );
  }
};

const OnboardingSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const currentProperty = getCurrentProperty({ globalInfo });
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [haveChangesBeenMade, setHaveChangesBeenMade] = useState(false);
  const [isTestWebsiteTagOpen, setIsWebsiteTagOpen] = useState(false);
  const [isWebsiteTagError, setIsWebsiteTagError] = useState(false);
  const [gaViews, setGaViews] = useState<ReadonlyArray<Page>>([]);
  const [propertyId, setPropertyId] = useState(currentProperty?.propertyId);
  const currentStep =
    getStepNumberFromPathname(location.pathname.replace('/setup/', '')) ?? 1;
  const onSaveRef = useRef<(() => Promise<void>) | null>(null);

  const params = new URLSearchParams(location.search);
  const requestToken = params.get('requestToken');
  const apiTypeId = sessionStorage.getItem('connectingAPITypeId');
  const error = params.get('error');
  const onboardingPages = useMemo(getOnboardingPages, []);

  useEffect(() => {
    if (currentProperty) {
      if (location.state?.prevLocation.pathname.startsWith('/select')) {
        navigate(
          `${onboardingPages[2].path}${location.state.prevLocation.search}`,
          { replace: true },
        );
      }
      if (currentProperty.propertyId !== propertyId) {
        setPropertyId(currentProperty.propertyId);
        setGaViews([]);
      }
    }
  }, [currentProperty, navigate, location, onboardingPages, propertyId]);

  useEffect(() => {
    // If we've arrived from the `addProperty` page, show a toast notification.
    if (location.state?.prevLocation.pathname === '/addproperty') {
      addSuccessNotification('Property added');
    }
  }, [location.state?.prevLocation.pathname]);

  const maximumPath = currentProperty
    ? getCurrentPathFromProperty(currentProperty)
    : '';
  const maximumStep = getStepNumberFromPathname(maximumPath) ?? 0;

  let defaultRedirect = maximumPath;

  // If we are redirected back to /setup with `?error=CONNECT_CANCELLED` in the URL,
  // go to the connect social pages step.
  if (error === 'CONNECT_CANCELLED') {
    defaultRedirect = onboardingPages[2].path;
  }

  const contextValue = useMemo<OnboardingContextInterface>(() => {
    const onStepChange = (newStep: number) => {
      assertIsValidStep(newStep);
      navigate(onboardingPages[newStep].path);
    };

    return {
      currentStep,
      maximumStep,
      onStepChange,
      nextButtonDisabled,
      setNextButtonDisabled,
      haveChangesBeenMade,
      setHaveChangesBeenMade,
      gaViews,
      setGaViews,
      isTestWebsiteTagOpen,
      setIsWebsiteTagOpen,
      setIsWebsiteTagError,
      onSaveRef,
    };
  }, [
    currentStep,
    maximumStep,
    nextButtonDisabled,
    haveChangesBeenMade,
    gaViews,
    isTestWebsiteTagOpen,
    navigate,
    onboardingPages,
  ]);

  function redirector(stepString: string) {
    const stepNumber = parseInt(stepString, 10);
    assertIsValidStep(stepNumber);
    // If property is under setup, only allow access to holding page
    if (maximumStep === 6 && stepNumber !== maximumStep) {
      return { pathname: `/setup/${maximumPath}` };
    }

    // If this is the first time on this property, go to the first step.
    const propertyIdList = getVisitedPropertiesList();
    const isPropertyInList = propertyIdList?.includes(propertyId);
    if (propertyId != null && !isPropertyInList) {
      setPropertyIdInLocalStorage(propertyId);
      return { pathname: `/setup/${onboardingPages[1].path}` };
    }

    if (
      onboardingPages[stepNumber].isProtected &&
      maximumStep != null &&
      maximumStep < stepNumber
    ) {
      return { pathname: `/setup/${maximumPath}` };
    }

    return null;
  }

  if (maximumStep == null || currentProperty == null) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <>
      <OnboardingContext.Provider value={contextValue}>
        <Flex flexDirection="column" h="full">
          <HighPriorityErrorsBanner />
          <Box position="relative" flexGrow={1}>
            <Header />
            <Sidebar />
            <Box
              ml={80}
              py={`${60 + 48}px`} // 60px for the header plus 48px padding
              px={10}
              backgroundColor="#fff"
              data-cy-id="setupPage"
              minHeight="full"
            >
              <Routes>
                {Object.entries(onboardingPages).map(
                  ([stepNumber, { component, path }]) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <Protected redirector={() => redirector(stepNumber)}>
                          {path === 'websitetag'
                            ? React.cloneElement(component, {
                                isError: isWebsiteTagError,
                              })
                            : component}
                        </Protected>
                      }
                    />
                  ),
                )}
                <Route
                  path="*"
                  element={<Navigate to={defaultRedirect} replace />}
                />
              </Routes>
            </Box>
          </Box>
        </Flex>
        {isTestWebsiteTagOpen && (
          <TestWebsiteTagModal
            setIsWebsiteTagError={setIsWebsiteTagError}
            onClose={() => setIsWebsiteTagOpen(false)}
          />
        )}
      </OnboardingContext.Provider>
      {requestToken && (
        <SocialPageSelect apiTypeId={apiTypeId} requestToken={requestToken} />
      )}
    </>
  );
};

export default OnboardingSetup;
