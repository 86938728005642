/**
 * GET /{root}/{state}
 *
 * @param {integer} accountAPIId - account api id
 * @param {string} state         - state ('NEW', 'SCHEDULED', 'SHARED', 'DELETED', 'ARCHIVED')
 * @param {timestamp} from       - optional from timestamp
 * @param {timestamp} to         - optional to timestamp
 * @param {string} filter        - optional comma-separated list of filters, available options are:
 *                                  all, ab, live, sponsored, status, video, share, reshare,
 *                                  today, breaking, evergreen, draft
 *                                 multiple options are only allowed for the state ARCHIVED
 * @param {string} sort          - optional sort sequence, available options are:
 *                                  potential, date (any state)
 *                                  socialpvs, reach, clicks, ctr, totalengagement (SHARED only)
 *                                  approxsharetime (SCHEDULED only)
 * @return [string]              - return array of media ids
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import {
  getAPITypeId,
  getCurrentPropertyId,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import { MEDIA_ITEM_STATES, SOCIAL_CHANNELS } from 'common/constants';
import { createTimeRange } from 'common/datetime';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getURNName } from 'common/social';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import { convertToSocialPageURN } from 'common/urn';
import { isDefined, isNotNull, isNull } from 'common/utility';
import { lyingParse } from 'common/zod';
import type { MediaItemState } from 'types';

const SHARED_STATE_SORTS = [
  'totalengagement',
  'ctr',
  'clicks',
  'reach',
  'socialpvs',
];

const ResponseSchema = z
  .object({ mediaIds: z.array(z.string()) })
  .describe('getMediaList.ResponseSchema');

export default async function getMediaList({
  accountAPIId,
  state,
  to = undefined,
  from = undefined,
  publishFromToTimes = undefined,
  shareFromToTimes = undefined,
  deleteFromToTimes = undefined,
  failFromToTimes = undefined,
  filter = null,
  sort = null,
  sortOrder = undefined,
  categories = undefined,
  origin = '',
}: {
  accountAPIId: number;
  state: MediaItemState;
  to?: number | null;
  from?: number | null;
  publishFromToTimes?: string;
  shareFromToTimes?: string;
  deleteFromToTimes?: string;
  failFromToTimes?: string;
  filter?: string | null;
  sort?: string | null;
  sortOrder?: string;
  categories?: Array<string>;
  origin?: string;
}) {
  const typeId = getAPITypeId({ accountAPIId });

  // 'from' and 'to' should not be provided if the 'state' is scheduled
  if (
    state === MEDIA_ITEM_STATES.SCHEDULED &&
    (isDefined(to) || isDefined(from))
  ) {
    throw new ReferenceError(
      '"from" or "to" should not be provided if the state is "scheduled"',
    );
  }

  // Make sure only shared states can have extra sorts
  if (
    state !== MEDIA_ITEM_STATES.SHARED &&
    sort !== null &&
    SHARED_STATE_SORTS.indexOf(sort) > -1
  ) {
    throw new ReferenceError(
      '"sort" should not be provided if the state is not "shared"',
    );
  }

  // Move filters
  let channelFilters = '';
  let finalFilters = filter;
  if (isDefined(filter) && isNotNull(filter) && filter.trim() !== '') {
    const filters = filter.split(',');
    channelFilters = filters
      .filter(
        (currentFilter) =>
          Object.keys(SOCIAL_CHANNELS).indexOf(currentFilter) > -1,
      )
      .join();
    if (channelFilters !== '') {
      finalFilters = filters
        .filter(
          (currentFilter) =>
            Object.keys(SOCIAL_CHANNELS).indexOf(currentFilter) === -1,
        )
        .join();
    }
  }

  const guid = metrics.start('getMediaList');
  // Determine which parameters to pass to the endpoint
  let params: Record<string, string | null>;
  if (!isDefined(sortOrder) || isNull(sortOrder) || sortOrder === '') {
    // sortOrder might not be provided in the first instance as each has a default sortOrder
    params = {
      filters: finalFilters,
      sort,
    };
  } else {
    params = {
      filters: finalFilters,
      sort,
      sortOrder,
    };
  }

  // if sort is an extraInsight then ensure that it's correctly formatted with the social prefix.
  if (sort != null) {
    const analyticsInsights = getSocialAnalyticsInsights(typeId);
    const insightDetails = analyticsInsights?.[sort];
    if (insightDetails) {
      if (insightDetails.sortKey) {
        params.sort = insightDetails.sortKey;
      } else if (!insightDetails.default) {
        const shortName = getURNName({ apiTypeId: typeId });
        params.sort = `${shortName}${sort}`;
      }
    }
  }

  if (channelFilters !== '') {
    params.channelFilters = channelFilters;
  }

  // Pass date ranges depending on the media item state
  // Convert "old-style" from and to times to retain backwards compatibility
  if (isDefined(from) && !isNull(from)) {
    switch (state) {
      case MEDIA_ITEM_STATES.NEW:
        params.publishFromToTimes = createTimeRange(from, to);
        break;
      case MEDIA_ITEM_STATES.FAILED:
        params.failFromToTimes = createTimeRange(from, to);
        break;
      case MEDIA_ITEM_STATES.SHARED:
        params.shareFromToTimes = createTimeRange(from, to);
        break;
      case MEDIA_ITEM_STATES.DELETED:
        params.deleteFromToTimes = createTimeRange(from, to);
        break;
      default: {
        // Do nothing
      }
    }
    delete params.from;
    delete params.to;
  }
  if (isDefined(publishFromToTimes)) {
    params.publishFromToTimes = publishFromToTimes;
  }
  if (isDefined(failFromToTimes)) {
    params.failFromToTimes = failFromToTimes;
  }
  if (isDefined(shareFromToTimes)) {
    params.shareFromToTimes = shareFromToTimes;
  }
  if (isDefined(deleteFromToTimes)) {
    params.deleteFromToTimes = deleteFromToTimes;
  }

  if (Array.isArray(categories) && categories.length > 0) {
    params.categories = categories.join(',');
  }

  const socialNetworkType = getURNName({ apiTypeId: typeId });
  const socialPageURN = convertToSocialPageURN(socialNetworkType, accountAPIId);
  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v4',
    )}/social/api/${socialPageURN}/media/${state}`,
    method: 'GET',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
    params,
  };

  logger.info(
    `API:getMediaList /social/api/${socialPageURN}/media/${state} ${
      params.sort ?? ''
    } ${params.sortOrder ?? ''} ${params.filters ?? ''}`,
  );

  try {
    const response = await axios(config);
    metrics.end('getMediaList', guid);
    logger.debug('getMediaList', { accountAPIId, state, origin });
    const data = lyingParse(ResponseSchema, response.data);
    return data;
  } catch (error) {
    if (
      getPropertyIdForAccountAPIId({ accountAPIId }) === getCurrentPropertyId()
    ) {
      metrics.fail('getMediaList', guid);
      const apiError = await handleAPIError({
        originalError: error,
        errorLocation: 'api/getMediaList',
        args: {
          socialPageURN,
          state,
          to,
          from,
          filter,
          sort,
          sortOrder,
        },
      });
      throw apiError;
    }
    // Current property has been changed since the request was issued
    // Throw an error (to stop any subsequent processing) that will then be ignored in pubsub/API_INFO
    throw new Error('EBX:INTERNAL - current property has changed');
  }
}
