import {
  Box,
  Button,
  LightingHighlightIcon,
  Tooltip,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as API from 'api/api';
import { getAPITypeId, getCurrentPropertyId } from 'common/accountAPIs';
import {
  API_TYPE_IDS,
  CONTENT_TYPE_AI_IDS,
  POST_TYPES,
  TEXT_CONTENT_AVAILABLE_STATES,
} from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { getURNName, hasShareMessages } from 'common/social';
import * as tracker from 'common/tracker';
import { convertToSocialPageURN } from 'common/urn';
import {
  generateAIMessageError,
  generateAIMessageRequest,
  generateAIMessageSuccess,
  handleIsFirstRender,
  updateMediaItemByGuid,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import {
  updateSelectedShareContent,
  useMessageBoxContext,
} from 'context/MessageBoxContext';
import { getCommonTrackingParams } from 'helpers/tracking';
import { useEffect } from 'react';
import type { FixTypeLater } from 'types';

import { FEATURE_TOGGLES } from 'common/constants/settings';
import { mergeMessageWithLink } from 'common/instantImage';
import { getFeatureToggle } from 'common/settings';
import * as MessageBoxTools from './MessageBoxTools';
import { isOpenAIMessageEnabled } from './MessageBoxTools';

interface AIMessageProps {
  accountAPIId: number;
  guid: string;
  mediaItem: FixTypeLater;
  selectedContentTypeId: number | null;
}

const INSPIRE_ME_BUTTON_DISABLED_STATES = [
  TEXT_CONTENT_AVAILABLE_STATES.FAILED_NO_RETRY_ALLOWED,
  TEXT_CONTENT_AVAILABLE_STATES.FAILED_RETRY_ALLOWED,
  TEXT_CONTENT_AVAILABLE_STATES.NOT_APPLICABLE,
];

const AIMessageButton = ({
  accountAPIId,
  guid,
  mediaItem,
  selectedContentTypeId,
}: AIMessageProps) => {
  const { dispatch } = useMessageBoxContext();
  const { dispatch: composeBoxDispatch, isFirstRender } =
    useComposeBoxContext();
  const toast = useToast();

  const aiMessage = MediaItem.getAIMessage({ mediaItem });
  const messages = MediaItem.getMessages({ mediaItem });
  const messageHistory = MediaItem.getMessageHistory({ mediaItem });
  const isURLResolved = MediaItem.getIsURLResolved({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  const isInstantImageEnabled =
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.MANUAL_IMAGE_FROM_ARTICLE_ENABLED,
      propertyId: getCurrentPropertyId(),
    }) && apiTypeId === API_TYPE_IDS.FACEBOOK;
  const shouldGenerateAIMessage =
    (selectedContentTypeId !== null &&
      CONTENT_TYPE_AI_IDS.indexOf(selectedContentTypeId) === -1) ||
    MessageBoxTools.canPageGenerateAIMessage({ apiTypeId, postType });

  let messageTooltip;

  const textContentState = MediaItem.getTextContentAvailableState({
    mediaItem,
  });

  const enableAI =
    shouldGenerateAIMessage &&
    !aiMessage.isLoading &&
    isURLResolved &&
    !INSPIRE_ME_BUTTON_DISABLED_STATES.includes(textContentState);

  if (INSPIRE_ME_BUTTON_DISABLED_STATES.includes(textContentState)) {
    messageTooltip = 'Unable to read your article content';
  } else if (
    textContentState === TEXT_CONTENT_AVAILABLE_STATES.AVAILABLE &&
    postType === POST_TYPES.LINK
  ) {
    messageTooltip =
      'Let Echobox inspire you with unique messages based on your writing style and content';
  }

  const getAIMessage = async (initialMessage = false) => {
    composeBoxDispatch(generateAIMessageRequest({ guid }));

    const textContentAvailableState = MediaItem.getTextContentAvailableState({
      mediaItem,
    });

    const state = MediaItem.getState({ mediaItem });
    const mediaId = MediaItem.getMediaId({ mediaItem });

    let refreshedMediaItem = mediaItem;
    if (
      postType === POST_TYPES.LINK &&
      textContentAvailableState === TEXT_CONTENT_AVAILABLE_STATES.NOT_ATTEMPTED
    ) {
      refreshedMediaItem = await API.getMediaItem({
        accountAPIId,
        apiTypeId,
        state,
        mediaId,
        getMessages: hasShareMessages({ apiTypeId }),
        refreshTextContent: true,
        resolveURL: true,
      });
      composeBoxDispatch(
        updateMediaItemByGuid({ guid, mediaItem: refreshedMediaItem }),
      );
    }

    let newMessageHistory = messageHistory;
    try {
      const { newIndexedShareContent, newAIMessage } =
        await MessageBoxTools.getNextAIMessage({
          messages,
          aiMessage,
          generateAIMessageContent: async () => {
            if (
              MediaItem.getTextContentAvailableState({
                mediaItem: refreshedMediaItem,
              }) === TEXT_CONTENT_AVAILABLE_STATES.AVAILABLE
            ) {
              const socialNetworkType = getURNName({ apiTypeId });
              const socialPageURN = convertToSocialPageURN(
                socialNetworkType,
                accountAPIId,
              );
              try {
                const response = await API.getAIMessage({
                  socialPageURN,
                  mediaURN: MediaItem.getMediaURN({
                    mediaItem: refreshedMediaItem,
                  }),
                });
                newMessageHistory = initialMessage
                  ? messageHistory
                  : MessageBoxTools.addToMessageHistory({
                      messages,
                      messageHistory,
                      aiMessage,
                    });

                const articleURL = MediaItem.getInstantImageArticleUrl({
                  mediaItem,
                });

                const message =
                  isInstantImageEnabled && articleURL
                    ? mergeMessageWithLink(response, articleURL)
                    : response;

                return {
                  contentId: null,
                  contentTypeId: 1,
                  sequence: 0,
                  unixTimeUpdated: Date.now(),
                  value: message,
                };
              } catch (error: any) {
                if ('status' in error && error.status === 422) {
                  toast({
                    variant: 'error',
                    title: error?.error?.message || error?.message,
                  });
                }

                logger.error({
                  event: 'Error getting AI message',
                  properties: {
                    ErrorLocation: 'AIMessageButton:handleClick',
                    socialPageURN,
                    mediaURN: MediaItem.getMediaURN({
                      mediaItem: refreshedMediaItem,
                    }),
                  },
                  error,
                });
              }
            }
            return {
              contentId: null,
              contentTypeId: 1,
              sequence: 0,
              unixTimeUpdated: Date.now(),
              value: '',
            };
          },
        });

      const selectedContentIndex = newIndexedShareContent.index;
      const selectedShareContent = newIndexedShareContent.shareContent;

      const trackingParams: Record<string, any> = getCommonTrackingParams({
        mediaItem,
      });
      trackingParams['AI Message Content'] = selectedShareContent.value;
      trackingParams.Index = newAIMessage.selectedIndex;
      tracker.track({ eventName: 'View AI Message', trackingParams });
      updateSelectedShareContent({
        accountAPIId,
        aiMessage: newAIMessage,
        composeBoxDispatch,
        guid,
        mediaItem: refreshedMediaItem,
        messageBoxDispatch: dispatch,
        messageHistory: newMessageHistory,
        selectedContentIndex,
        selectedShareContent,
      });
      composeBoxDispatch(generateAIMessageSuccess({ guid }));
    } catch (error: any) {
      composeBoxDispatch(generateAIMessageError({ guid }));
      logger.error({ event: 'Failed to generate AI message', error });
    }
  };

  const handleClick = async () => {
    logger.info('AIMessageButton:handleClick');
    await getAIMessage();
  };

  useEffect(() => {
    const getInitialAIMessage = async () => {
      try {
        if (
          MessageBoxTools.shouldGetAIMessageOnRender({
            accountAPIId,
            mediaItem,
            isFirstRender,
          })
        ) {
          await getAIMessage(true);
        }
      } finally {
        composeBoxDispatch(handleIsFirstRender({ isFirstRender: false }));
      }
    };
    getInitialAIMessage();
    // disabling react hooks as specifying getAIMessage as a dependency causes an infinite loop.
    // This is because getAIMessage updates some state which causes a re-render which causes getAIMessage to be called again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [composeBoxDispatch, isFirstRender]);

  if (!isOpenAIMessageEnabled()) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'block !important',
      }}
    >
      <Tooltip label={messageTooltip} isDisabled={!messageTooltip}>
        <Button
          variant="secondary"
          leftIcon={<LightingHighlightIcon />}
          isDisabled={!enableAI}
          onClick={enableAI ? handleClick : undefined}
        >
          Inspire me
        </Button>
      </Tooltip>
    </Box>
  );
};

const ExportedAIMessageButton = AIMessageButton;

export default ExportedAIMessageButton;
