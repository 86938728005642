import getProperties from 'api/getProperties';
import getPropertiesAPIs from 'api/getPropertiesAPIs';
import { convertToPropertyURN } from 'common/urn';

import type { Property, SocialAPI } from 'types';

export { getPropertyById };

const getPropertyById = async ({
  propertyId,
  onSuccess,
}: {
  propertyId: string;
  onSuccess: (args: {
    property: Property;
    socialPages: Array<SocialAPI>;
  }) => void;
}) => {
  const propertyURN = convertToPropertyURN(propertyId);

  const [property, socialPagesResp] = await Promise.all([
    getProperties({
      propertyIds: [propertyId],
    }),
    getPropertiesAPIs({
      propertyURNs: [propertyURN],
    }),
  ]);

  onSuccess({
    property: property[propertyId],
    socialPages: socialPagesResp[propertyId],
  });
};
