import clsx from 'clsx';

import { REACT_PREVENT_RENDER } from 'common/constants';
import { generateGuid } from 'common/string';

interface KeywordListProps {
  keywordList: Array<string>;
  disabled?: boolean;
  onKeywordRemove: (
    keyword: string,
  ) => React.MouseEventHandler<HTMLButtonElement>;
}

const KeywordList = ({
  disabled = false,
  keywordList,
  onKeywordRemove,
}: KeywordListProps) => {
  if (keywordList.length === 0) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <div className="keywords">
      {keywordList.map((keyword) => {
        return (
          <span
            className={clsx(
              'user_keyword text_unselectable',
              disabled && 'saving_locked',
            )}
            key={generateGuid()}
          >
            <span>{keyword}</span>
            <span
              className={clsx('remove ml-2', disabled && 'saving_locked')}
              data-cy-id={keyword}
              data-cy={keyword}
            >
              <button
                className="ft-11 close gray-600 float-none"
                onClick={!disabled ? onKeywordRemove(keyword) : undefined}
                data-cy-action="removeKeyword"
                type="button"
              >
                ✖
              </button>
            </span>
          </span>
        );
      })}
    </div>
  );
};

export default KeywordList;
