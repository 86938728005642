import { Alert, Link } from '@ebx-ui/ebx-ui-component-library-sdk';

const EchoboxEmailAlert = () => {
  const contactUsLink = (
    <Link
      href="https://calendly.com/d/y7y-yzq-r7w/echobox-welcome-call"
      isExternal
      variant="primary"
    >
      Contact us
    </Link>
  );
  return (
    <Alert variant="info" buttons={contactUsLink} w="728px">
      <Alert.Title>Pair with Echobox Email</Alert.Title>
      <Alert.Description>
        Echobox Social and Echobox Email can work together to maximize your
        subscription funnel from social
      </Alert.Description>
    </Alert>
  );
};

export default EchoboxEmailAlert;
