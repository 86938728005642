/* eslint react-hooks/exhaustive-deps: "off" */

import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import { SEARCH_STATES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import Item from 'components/header/Item';
import NoResults from 'components/header/NoResults';

const Results = (props) => {
  const {
    isLoadingMore,
    searchResults,
    searchState,
    searchText,
    eventHandlers,
  } = props;

  if (searchState === SEARCH_STATES.FAILED) {
    // TODO - decide what to show if the search fails for whatever reason
    return null;
  }

  return (
    <div
      className="dropdown-menu search-results show"
      aria-labelledby="inputSearchNavbar"
      // eslint-disable-next-line react/no-unknown-property
      x-placement="bottom-start"
      style={{
        position: 'absolute',
        transform: 'translate3d(0px, 38px, 0px)',
        top: '0px',
        left: '0px',
        willChange: 'transform',
      }}
      data-boundary="viewport"
      data-cy-id="searchResults"
    >
      {searchResults.mediaItems.length > 0 && (
        <div className="search-result-articles" id="search-result-articles">
          {searchResults.mediaItems.map((mediaItem, index) => (
            <Item
              key={MediaItem.getMediaId({ mediaItem })}
              mediaItem={mediaItem}
              rank={index + 1}
              searchText={searchText}
              eventHandlers={{
                handleArticleShare: eventHandlers.handleArticleShare,
              }}
            />
          ))}
        </div>
      )}
      {searchResults.mediaItems.length === 0 && <NoResults />}
      <div className="search-results-bottom">
        <div>
          <span>{searchResults.mediaIds.length}</span> Result
          {searchResults.mediaIds.length === 1 ? '' : 's'}
        </div>
        <div className="d-flex align-items-center">
          {isLoadingMore && <Spinner />}
        </div>
      </div>
    </div>
  );
};

Results.propTypes = {
  isLoadingMore: PropTypes.bool.isRequired,
  searchResults: PropTypes.shape({
    mediaIds: PropTypes.array.isRequired,
    mediaItems: PropTypes.array.isRequired,
  }).isRequired,
  searchState: PropTypes.oneOf(Object.values(SEARCH_STATES)).isRequired,
  searchText: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleShare: PropTypes.func.isRequired,
  }).isRequired,
};

export default Results;
