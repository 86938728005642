/**
 * POST /syndfeeds/validate - validate a synd feed URL
 *
 * @param {string[]} feedURL - synd feed URL
 * @return {object} - synd feeds information
 */

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { SyndFeedDataSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';
import { z } from 'zod';

const VALIDATE_SYNDFEED = 'postSyndFeedValidate';

const ResponseSchema = z
  .object({ feedData: SyndFeedDataSchema })
  .describe('PostSyndFeedValidateResponseDataSchema');

export default async function postSyndFeedValidate({
  feedURL,
}: {
  feedURL: string;
}) {
  const guid = metrics.start(VALIDATE_SYNDFEED);

  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL('v1.1')}/syndfeeds/validate`,
    method: 'POST',
    timeout: API_TIMEOUTS.XXL,
    headers: getClientServiceRequestHeaders(),
    data: JSON.stringify({
      feedURL,
    }),
  };

  try {
    const response = await axios(config);
    const { feedData } = lyingParse(ResponseSchema, response.data);

    metrics.end(VALIDATE_SYNDFEED, guid);
    logger.debug(VALIDATE_SYNDFEED, {
      feedURL,
    });

    return feedData;
  } catch (error) {
    metrics.fail(VALIDATE_SYNDFEED, guid);

    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: `api/${VALIDATE_SYNDFEED}`,
      args: { feedURL },
    });
    throw apiError;
  }
}
