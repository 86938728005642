import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { memo } from 'react';

import { round } from 'common/maths';
import { arePropsEqual } from 'common/utility';
import PublishedTime from 'components/compose/PublishedTime';

interface PostDetailsProps {
  articleTitle: string;
  articleURL: string;
  mostRecentScore: number;
  publishedTime: number;
  twelveHourFormat: boolean;
}

const PostDetails = ({
  articleTitle,
  articleURL,
  mostRecentScore,
  publishedTime,
  twelveHourFormat,
}: PostDetailsProps) => {
  const roundedScore = round({ value: mostRecentScore * 10, decimals: 1 })
    .toFixed(0)
    .toString();

  return (
    <div className="article_title pl-3 pr-2 pt-2 pb-0 py-md-2 d-flex">
      <div className="d-md-flex">
        <div className="score align-self-center justify-content-center mr-2 float-left mt-1 mt-md-0">
          {roundedScore === '0' ? <Spinner size="xs" /> : roundedScore}
        </div>
        <div className="">
          <div className="article_title_text align-self-center">
            {articleTitle}
          </div>
          <PublishedTime
            articleURL={articleURL}
            isMobile={false}
            publishedTime={publishedTime}
            twelveHourFormat={twelveHourFormat}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(PostDetails, arePropsEqual);
