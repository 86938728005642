import { Square, SquareProps } from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  getSocialNetWorkIconByAPITypeId,
  getSocialNetworkName,
} from 'common/social';

interface SocialLogoProps extends SquareProps {
  apiTypeId: number;
  svgSize: number;
}

const SocialLogo = ({ apiTypeId, svgSize, ...rest }: SocialLogoProps) => {
  const networkName = getSocialNetworkName({ apiTypeId });
  const SocialIcon = getSocialNetWorkIconByAPITypeId(apiTypeId);

  return (
    <Square
      aria-label={networkName}
      border="1px"
      borderColor="gray.300"
      borderRadius="base"
      position="absolute"
      right={0}
      bottom={0}
      bg="gray.100"
      color="gray.600"
      {...rest}
    >
      <SocialIcon flexShrink={0} size={svgSize} />
    </Square>
  );
};

export default SocialLogo;
