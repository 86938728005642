import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import { PERMISSION_TYPES_IDS } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getURNName } from 'common/social';
import { convertToPropertyURN, convertToSocialPageURN } from 'common/urn';
import { lyingParse } from 'common/zod';
import type { Permission } from 'types';

const ResponseSchema = z
  .object({ emailAddress: z.string() })
  .describe('postPropertiesUser.ResponseSchema');

const changePermissionsToNewFormat = (
  permissionsOnProperty: Array<Permission>,
) => {
  return permissionsOnProperty.map((permision) => {
    const { propertyId, accountAPIId, permissionTypeId } = permision;
    const permisionPropertyURN = propertyId
      ? convertToPropertyURN(propertyId)
      : null;
    let permisionSocialPageURN;
    if (accountAPIId) {
      const typeId = getAPITypeId({
        accountAPIId,
      });
      const socialNetworkType = getURNName({ apiTypeId: typeId });
      permisionSocialPageURN = convertToSocialPageURN(
        socialNetworkType,
        accountAPIId,
      );
    }
    const permissionType = PERMISSION_TYPES_IDS[permissionTypeId];
    return {
      propertyURN: permisionPropertyURN,
      socialPageURN: permisionSocialPageURN,
      permissionType,
    };
  });
};

export default async function postPropertiesUser({
  propertyId,
  name,
  username,
  emailAddress,
  permissionsOnProperty,
}: {
  propertyId: string | number;
  name: string;
  username: string;
  emailAddress: string;
  permissionsOnProperty: Array<Permission>;
}) {
  const guid = metrics.start('postPropertiesUser');

  const propertyURN = convertToPropertyURN(propertyId);
  const permissionsOnPropertyNewFormat = changePermissionsToNewFormat(
    permissionsOnProperty,
  );

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL('v3.1')}/properties/${propertyURN}/users`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      name,
      username,
      emailAddress,
      permissionsOnProperty: permissionsOnPropertyNewFormat,
    }),
  };
  logger.info(
    `API:postPropertiesUser /properties/${propertyId}/users ${username}`,
  );

  try {
    const response = await axios(config);
    metrics.end('postPropertiesUser', guid);
    logger.debug('postPropertiesUser', { propertyId, emailAddress });

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData;
  } catch (error) {
    metrics.fail('postPropertiesUser', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postPropertiesUsers',
      args: {
        propertyId,
        name,
        username,
        emailAddress,
        permissionsOnProperty,
      },
    });
    throw apiError;
  }
}
