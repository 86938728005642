import {
  getCurrentPropertyId,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import { POST_TYPES, SOCIAL_CHANNELS } from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import * as MediaItem from 'common/mediaItem';
import { getFeatureToggle, isIGCabinetEnabled } from 'common/settings';
import FirstComment from 'components/compose/FirstComment';
import LiveStory from 'components/compose/LiveStory';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import type { FixTypeLater, PostType } from 'types';

import LinkInBioCheckbox from './LinkInBioCheckbox';

const firstCommentExcludePostTypes: PostType[] = [
  POST_TYPES.LINK,
  POST_TYPES.PHOTO_STORY,
  POST_TYPES.VIDEO,
];

interface InstagramOtherSettingsProps {
  guid: string;
  mediaItem: FixTypeLater;
}

const InstagramOtherSettings = ({
  guid,
  mediaItem,
}: InstagramOtherSettingsProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const isLive = MediaItem.getIsLive({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId, globalInfo });
  const isLinkinbioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId: getCurrentPropertyId(),
  });

  const showLiveStory =
    isIGCabinetEnabled({ propertyId }) && postType === POST_TYPES.LINK;
  const showFirstComment =
    (!firstCommentExcludePostTypes.includes(postType) &&
      socialChannel !== SOCIAL_CHANNELS.STORY) ||
    (socialChannel === SOCIAL_CHANNELS.FEED && postType === POST_TYPES.LINK) ||
    (postType === POST_TYPES.VIDEO && socialChannel !== SOCIAL_CHANNELS.STORY);
  const showLinkInBio =
    (postType === POST_TYPES.LINK ||
      (postType === POST_TYPES.STATUS && !isLinkinbioEnabled)) &&
    socialChannel === SOCIAL_CHANNELS.FEED;

  if (!showLiveStory && !showFirstComment && !showLinkInBio) {
    return null;
  }

  return (
    <div className="other_settings text_unselectable w-100 mt-3">
      <div className="subtitle">Other Settings</div>
      <div className="other_setting text_unselectable checkbox mt-1">
        {showLiveStory && <LiveStory guid={guid} isLive={isLive} />}
        {showFirstComment && (
          <FirstComment
            comment={MediaItem.getFirstComment({ mediaItem })}
            guid={guid}
            mediaItem={mediaItem}
          />
        )}
        {showLinkInBio && (
          <LinkInBioCheckbox
            guid={guid}
            mediaItem={mediaItem}
            isDisabled={!isLinkinbioEnabled}
          />
        )}
      </div>
    </div>
  );
};

export default InstagramOtherSettings;
