import FontFaceObserver from 'fontfaceobserver';
import { useEffect, useState } from 'react';

interface FontLoaderProps {
  fontFamily: string;
}

/**
 * A hook that loads the font family specified and
 * returns a boolean indicating whether the font is loaded.
 */
const useFontLoader = ({ fontFamily }: FontLoaderProps) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const loadFont = async () => {
      const font = new FontFaceObserver(fontFamily);
      await font.load();
      setFontLoaded(true);
    };
    loadFont();
  }, [fontFamily]);

  return { fontLoaded };
};

export default useFontLoader;
