import getAPIs from 'api/getAPIs';
import getAPIsSettingsByType from 'api/getAPIsSettingsByType';
import getProperties from 'api/getProperties';
import getPropertiesAPIs from 'api/getPropertiesAPIs';
import getPropertiesSynds from 'api/getPropertiesSynds';
import getTrafficAPIs from 'api/getTrafficAPIs';
import {
  getAPIPropertyMap,
  getAPIsWithPermissions,
  getUserPropertiesAndAPIs,
} from 'common/accountAPIs';
import { VERIFIED_PROPERTIES } from 'common/config';
import {
  API_TYPE_IDS,
  FRONTEND_METRICS,
  SOCIAL_PAGE_SETTING_TYPES_ALL,
} from 'common/constants';
import {
  resetCurrentPropertyAndAPI,
  setCurrentPropertyAndAPI,
} from 'common/currentPropertyAndAPI';
import { getGlobalInfo } from 'common/globalInfo';
import { generateGuid } from 'common/guid';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { storeSettings } from 'common/settings';
import { hasVerifiedAccounts, isSocialNetwork } from 'common/social';
import { getSocialPageURL } from 'common/socialV2';
import { convertToPropertyURN, extractPropertyId } from 'common/urn';
import { isDefined, isEmptyOrNullOrUndefined, isNull } from 'common/utility';
import { GlobalInfo, SocialAPI, TrafficAPI } from 'types';

/**
 * Populates global info with all properties and APIs the user has access to, and their related settings.
 *
 */
export default async function populateGlobalInfo({
  user = getGlobalInfo().user,
  allowUnderSetup = false,
  getFeedDetails = true,
}:
  | {
      user?: GlobalInfo.User & {
        propertyId?: number;
        accountAPIId?: number;
      };
      allowUnderSetup?: boolean;
      getFeedDetails?: boolean;
    }
  | undefined = {}) {
  const guid = generateGuid();
  metrics.mark(FRONTEND_METRICS.POPULATE_GLOBAL_INFO, guid);

  try {
    const [allProperties, propertyIds, socialAPIURNs] =
      getUserPropertiesAndAPIs({
        user,
      });

    // Prepare the first batch of requests
    const getPropertiesRequest = getProperties({ propertyIds: allProperties });
    const getPropertiesAPIsRequest =
      allProperties.length === 0
        ? null
        : getPropertiesAPIs({
            propertyURNs: allProperties.map((propertyId) =>
              convertToPropertyURN(propertyId),
            ),
          });
    const apisRequest =
      socialAPIURNs.length === 0 ? null : getAPIs({ socialAPIURNs });

    // Wait for all requests to resolve
    const [propertiesResponse, propertiesAPIsResponse, apisResponse] =
      await Promise.all([
        getPropertiesRequest,
        getPropertiesAPIsRequest,
        apisRequest,
      ]);

    let properties: Record<
      string,
      Partial<GlobalInfo.Property>
    > = propertiesResponse;

    if (!isNull(propertiesAPIsResponse)) {
      properties = processPropertiesAPIsResponse({
        propertiesAPIsResponse,
        properties,
        propertyIds,
        allProperties,
      });
    }

    if (!isNull(apisResponse)) {
      properties = processApisResponse({
        apisResponse,
        properties,
      });
    }

    // Set current property and account API
    let current: GlobalInfo.GlobalInfo['current'];
    if (!isDefined(user.propertyId)) {
      current = setCurrentPropertyAndAPI({
        propertiesAndAPIs: properties,
      });
      logger.info(
        `populateGlobalInfo - set current property ${current.propertyId} api ${current.accountAPIId}`,
      );
    } else {
      current = resetCurrentPropertyAndAPI({
        currentSettings: {
          propertyId: user.propertyId,
          accountAPIId: user.accountAPIId,
        },
        propertiesAndAPIs: properties,
        allowUnderSetup,
      });
      logger.info(
        `populateGlobalInfo - reset current property ${current.propertyId} api ${current.accountAPIId}`,
      );
    }

    const globalInfo = {
      current,
      properties,
      user,
    } as GlobalInfo.GlobalInfo;

    // Retrieve settings for all active accounts belonging to the current property
    const apisWithPermissions = getAPIsWithPermissions({
      allowUnderSetup,
      globalInfo,
    });

    // Send second batch of requests.
    const getAPIsSettingsByTypeRequest = getAPIsSettingsByType({
      accountAPIIds: apisWithPermissions,
      settingTypeIds: SOCIAL_PAGE_SETTING_TYPES_ALL,
      globalInfo,
    });
    const getPropertiesSyndsRequest =
      getFeedDetails && propertyIds.indexOf(Number(current.propertyId)) > -1
        ? getPropertiesSynds({ propertyIds: [current.propertyId!] })
        : null;
    const getTrafficAPIRequest =
      current.propertyId &&
      propertyIds.length > 0 &&
      propertyIds.includes(Number(current.propertyId))
        ? getTrafficAPIs({
            propertyURN: convertToPropertyURN(current.propertyId),
          })
        : null;

    const [settingsResponse, feedsResponse, trafficAPIResponse] =
      await Promise.all([
        getAPIsSettingsByTypeRequest,
        getPropertiesSyndsRequest,
        getTrafficAPIRequest,
      ]);

    // Store account settings information
    if (!isEmptyOrNullOrUndefined(settingsResponse)) {
      const apiPropertyMap = getAPIPropertyMap({
        propertiesAndAPIs: properties,
        allowUnderSetup,
      });
      Object.keys(settingsResponse).forEach((api) => {
        const propertyId = apiPropertyMap[api];
        properties = storeSettings({
          propertyId,
          accountAPIId: api,
          existingProperties: properties,
          settings: settingsResponse[Number(api)],
        });
      });
    }

    // Store synd feeds information
    if (!isNull(feedsResponse)) {
      Object.keys(feedsResponse).forEach((propertyId) => {
        properties[propertyId].accountFeeds = feedsResponse[propertyId];
      });
    }

    // Store traffic API information
    if (!isEmptyOrNullOrUndefined(trafficAPIResponse)) {
      properties = storeTrafficAPIsOnProperties({
        trafficAPIResponse,
        properties,
      });
    }

    return {
      current,
      properties,
      user,
    } as GlobalInfo.GlobalInfo;
  } catch (error) {
    if (error instanceof Error || error instanceof ErrorEvent) {
      logger.error({
        event: 'Get Properties',
        properties: {
          location: 'process/populateGlobalInfo',
        },
        error,
      });
    }
    throw error;
  } finally {
    metrics.measure(FRONTEND_METRICS.POPULATE_GLOBAL_INFO, { id: guid, user });
  }
}

function storeTrafficAPIsOnProperties({
  trafficAPIResponse,
  properties,
}: {
  trafficAPIResponse: Record<string, Record<string, TrafficAPI>>;
  properties: Record<string, Partial<GlobalInfo.Property>>;
}) {
  const newProperties = { ...properties };

  // Store traffic API information on the properties
  Object.entries(trafficAPIResponse).forEach(
    ([propertyURN, trafficAPIValues]) => {
      const propertyId = extractPropertyId(propertyURN);
      const property = newProperties[propertyId];
      if (property) {
        property.trafficAPIs = trafficAPIValues;
      }
    },
  );

  return newProperties;
}

function processPropertiesAPIsResponse({
  propertiesAPIsResponse,
  properties,
  propertyIds,
  allProperties,
}: {
  propertiesAPIsResponse: Record<string, SocialAPI[]>;
  properties: Record<string, Partial<GlobalInfo.Property>>;
  propertyIds: number[];
  allProperties: number[];
}) {
  const newProperties = { ...properties };

  // Store properties APIs information on the properties
  propertyIds
    .filter((propertyId) => propertyId in propertiesAPIsResponse)
    .forEach((propertyId) => {
      if (newProperties[propertyId]) {
        newProperties[propertyId].accountAPIs = propertiesAPIsResponse[
          propertyId
        ].reduce<Record<number, SocialAPI>>(
          (prev, curr) => ({
            ...prev,
            [curr.accountAPIId]: setHomePageAndVerifiedStatus({
              propertyId,
              accountAPI: curr,
            }),
          }),
          {},
        );
      }
    });
  allProperties
    .filter((propertyId) => !propertyIds.includes(propertyId))
    .filter((propertyId) => propertyId in propertiesAPIsResponse)
    .forEach((propertyId) => {
      newProperties[propertyId].accountAPIs = propertiesAPIsResponse[propertyId]
        .filter((accountAPI) => accountAPI.apiTypeId === API_TYPE_IDS.BITLY)
        .reduce<
          Record<string, SocialAPI>
        >((prev, curr) => ({ ...prev, [curr.accountAPIId]: curr }), newProperties[propertyId].accountAPIs ?? {});
    });

  return newProperties;
}

function processApisResponse({
  apisResponse,
  properties,
}: {
  apisResponse: Record<string, SocialAPI>;
  properties: Record<string, Partial<GlobalInfo.Property>>;
}) {
  const newProperties = { ...properties };
  Object.entries(apisResponse).forEach(([apiId, accountAPI]) => {
    const propertyId = accountAPI.propertyId;
    const accountAPIs = newProperties[propertyId].accountAPIs ?? {};
    accountAPIs[apiId] = setHomePageAndVerifiedStatus({
      propertyId,
      accountAPI,
    });
    newProperties[propertyId].accountAPIs = accountAPIs;
  });
  return newProperties;
}

function setHomePageAndVerifiedStatus({
  propertyId,
  accountAPI,
}: {
  propertyId: number;
  accountAPI: SocialAPI;
}) {
  const newAccountAPI: GlobalInfo.SocialAPI = { ...accountAPI };

  if ('averageTraffic' in newAccountAPI) {
    // Delete unwanted key
    delete newAccountAPI.averageTraffic;
  }
  // Set home page URL
  if (
    isSocialNetwork({ apiTypeId: newAccountAPI.apiTypeId }) &&
    newAccountAPI.apiAltName
  ) {
    newAccountAPI.apiHomePage = getSocialPageURL({
      apiTypeId: newAccountAPI.apiTypeId,
      apiAltName: newAccountAPI.apiAltName,
    });
  }
  if (hasVerifiedAccounts({ apiTypeId: newAccountAPI.apiTypeId })) {
    newAccountAPI.isVerified =
      newAccountAPI.isVerified ||
      VERIFIED_PROPERTIES.indexOf(Number(propertyId)) > -1;
  }

  return newAccountAPI;
}
