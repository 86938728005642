import axios, { AxiosRequestConfig } from 'axios';
import { z } from 'zod';

import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { lyingParse } from 'common/zod';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from './settings';

const ResponseSchema = z
  .object({
    name: z.string(),
  })
  .describe('postUploadVideoFinalise.ResponseSchema');

export default async function postUploadVideoFinalise({
  fileName,
  uploadId,
  parts,
  socialPageURN,
}: {
  fileName: string;
  uploadId: string;
  parts: {
    partNumber: number;
    eTag: string;
  }[];
  socialPageURN: string;
}) {
  const guid = metrics.start('postUploadVideoFinalise');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/media/upload/finalise`,
    method: 'POST',
    timeout: API_TIMEOUTS.XL,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: {
      fileName,
      uploadId,
      parts,
    },
    'axios-retry': {
      retries: 2,
      retryCondition: (error) => {
        // retry if it is a 5XX error
        return Boolean(error?.response?.status && error.response.status >= 500);
      },
      retryDelay: (retryCount) => {
        if (retryCount === 1) {
          return 1000;
        }
        if (retryCount === 2) {
          return 3000;
        }
        return 0;
      },
    },
  };
  logger.info(
    `API:postUploadVideoFinalise /social/api/${socialPageURN}/media/upload/finalise`,
  );

  try {
    const response = await axios(config);

    metrics.end('postUploadVideoFinalise', guid);
    logger.debug('postUploadVideoFinalise', {
      fileName,
      uploadId,
      parts,
      socialPageURN,
    });
    const validatedData = lyingParse(ResponseSchema, response?.data);

    return validatedData;
  } catch (error) {
    metrics.fail('postUploadVideoFinalise', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postUploadVideoFinalise',
      args: {
        fileName,
        uploadId,
        parts,
        socialPageURN,
      },
    });
    throw apiError;
  }
}
