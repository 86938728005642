import chameleon from '@chamaeleonidae/chmln';

import { GlobalInfo } from 'types';
import { isRunningCypressTests, isRunningTests } from './utility';

const shouldCallChameleon = () => {
  return !isRunningCypressTests() && !isRunningTests();
};

/**
 * Initialize Chameleon. This should be called once in the application.
 */
export const initChameleon = () => {
  if (shouldCallChameleon()) {
    chameleon.init(
      'Sr13n8XxnX8bZiMUqh3vGiiuw564PhySJDlQv2mItHmIDe-1QtlFZ-EzYOxzJc1vd8AgNk',
      { fastUrl: 'https://fast.chameleon.io/' },
    );
  }
};

/**
 * Update Chameleon with the current user's information.
 */
export const updateChameleonIdentity = ({
  userId,
  emailAddress,
  name,
  userType,
}: GlobalInfo.User) => {
  if (shouldCallChameleon()) {
    chameleon.identify(userId, {
      email: emailAddress,
      name,
      role: userType,
    });
  }
};
