import {
  Button,
  FormControl,
  HStack,
  Input,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import { lyingParse } from 'common/zod';
import * as React from 'react';
import { z } from 'zod';

const SearchContentFeedIdentifierSchema = z
  .object({ identifier: z.string() })
  .describe('SearchContentFeedIdentifierSchema');

interface ContentFeedSearchProps {
  onSearch: (identifierToSearch: string) => Promise<void>;
}

const ContentFeedSearch = ({ onSearch }: ContentFeedSearchProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();

    if (!(event.target instanceof HTMLFormElement)) {
      return;
    }

    const formData = Object.fromEntries(new FormData(event.target));
    const { identifier } = lyingParse(
      SearchContentFeedIdentifierSchema,
      formData,
    );

    if (identifier.trim().length) {
      setIsLoading(true);

      try {
        await onSearch(identifier.trim());
      } catch (error) {
        setErrorMessage(FRONTEND_ERROR_MESSAGES['content-feed-tools-search']);
      }

      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isInvalid={errorMessage !== null}>
        <FormControl.FormLabel>
          Search for Content Feed by URL or URN
        </FormControl.FormLabel>
        <HStack>
          <Input
            type="text"
            placeholder="Enter a feed URL or URN..."
            name="identifier"
            autoFocus
          />
          <Button
            type="submit"
            variant="secondary"
            loadingText="Submitting"
            flexShrink={0}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Submit
          </Button>
        </HStack>
        {errorMessage && (
          <FormControl.FormErrorMessage>
            {errorMessage}
          </FormControl.FormErrorMessage>
        )}
      </FormControl>
    </form>
  );
};

export default ContentFeedSearch;
