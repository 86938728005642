import { getAPITypeId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import InstagramPreview from 'components/compose/InstagramPreview';
import Preview from 'components/compose/Preview';
import PreviewMetrics from 'components/compose/PreviewMetrics';
import PreviewWrapper from 'components/compose/PreviewWrapper';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import { ComposeBoxTab } from 'context/ComposeBoxContext';
import { FixTypeLater, PostPreview } from 'types';

export interface SharedPreviewProps {
  selectedTab: ComposeBoxTab;
  previewCollapsed: boolean;
  applySingleFieldUpdateForGuid: (args: {
    guid?: string;
    fieldName: string;
    fieldValue: FixTypeLater;
  }) => void;
  deleteComposeBoxItem: (args: { guid: string }) => void;
}

interface PreviewsProps extends SharedPreviewProps {
  postPreviews: PostPreview[];
}

const Previews = ({
  postPreviews,
  selectedTab,
  previewCollapsed,
  applySingleFieldUpdateForGuid,
  deleteComposeBoxItem,
}: PreviewsProps) => {
  const numVisiblePreviews = postPreviews.filter(
    (post) => !post.isHidden,
  ).length;
  const containsSingleAccountAPIId = postPreviews
    .filter((post) => !post.isHidden)
    .every((preview) => preview.accountAPIId === postPreviews[0].accountAPIId);
  if (postPreviews.length === 0) {
    return (
      <div className="py-5 text-center w-100 ft-14">
        Please select a page to share on...
      </div>
    );
  }

  return (
    <div className="accordion w-100 px-3 pt-3 pb-0" id="previewPanels">
      {postPreviews.map((item, index) => {
        const accountAPIId = MediaItem.getAccountAPIId({
          mediaItem: item.mediaItem,
        });
        const apiTypeId = getAPITypeId({ accountAPIId });

        return (
          <PreviewMetrics
            key={item.guid}
            mediaItem={item.mediaItem}
            previewIndex={index}
          >
            <PreviewWrapper postPreview={item}>
              <div
                className="card"
                data-cy-preview
                data-cy-id={item.guid}
                data-cy-attribute="isLoading:false"
                data-cy-index={index}
                key={`preview-${item.guid}`}
              >
                <ErrorBoundary>
                  {apiTypeId === API_TYPE_IDS.INSTAGRAM ? (
                    <InstagramPreview
                      accountAPIId={item.accountAPIId}
                      allowDelete={numVisiblePreviews > 1}
                      applySingleFieldUpdateForGuid={
                        applySingleFieldUpdateForGuid
                      }
                      containsSingleAccountAPIId={containsSingleAccountAPIId}
                      deleteComposeBoxItem={deleteComposeBoxItem}
                      guid={item.guid}
                      mediaId={item.mediaId}
                      mediaItem={item.mediaItem}
                      previewCollapsed={previewCollapsed}
                      selectedTab={selectedTab}
                    />
                  ) : (
                    <Preview
                      mediaId={item.mediaId}
                      mediaItem={item.mediaItem}
                      accountAPIId={item.accountAPIId}
                      guid={item.guid}
                      allowDelete={numVisiblePreviews > 1}
                      previewCollapsed={previewCollapsed}
                      containsSingleAccountAPIId={containsSingleAccountAPIId}
                      selectedTab={selectedTab}
                      applySingleFieldUpdateForGuid={
                        applySingleFieldUpdateForGuid
                      }
                      deleteComposeBoxItem={deleteComposeBoxItem}
                    />
                  )}
                </ErrorBoundary>
              </div>
            </PreviewWrapper>
          </PreviewMetrics>
        );
      })}
    </div>
  );
};

export default Previews;
