export const IMAGE_MODES = {
  PREVIEW: 'preview',
  EDIT: 'edit',
  CROP: 'crop',
  UNEDITABLE: 'uneditable',
  UPLOADING: 'uploading',
  UNEDITABLE_NO_REFRESH: 'uneditable_no_refresh',
} as const;

export const IMAGE_FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
} as const;

export const ACTIONS = {
  EDIT: 'edit',
  CROP: 'crop',
  UPLOAD: 'upload',
  CANCEL: 'cancel',
} as const;

export const DESKTOP_MIN_WIDTH = '768px';
