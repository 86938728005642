/**
 * POST /signup - Creates a new Echobox property and account
 *
 * @param {String} propertyName - Property name
 * @param {String} emailAddress - First user's email address
 * @param {String} password - First user's password
 * @return {JSON} - Creates a new Echobox account
 *
 * Example response
 * {
 *   accountId: 102,
 *   authToken: [auth_token]
 * }
 */

import axios, { AxiosRequestConfig } from 'api/axios';
import { API_TIMEOUTS, constructBaseSocialAPIURL } from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function postSignup({
  propertyName,
  emailAddress,
  password,
}: {
  propertyName: string;
  emailAddress: string;
  password: string;
}) {
  const guid = metrics.start('postSignup');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/signup`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({
      propertyName,
      emailAddress,
      password,
    }),
  };

  try {
    await axios(config);
    metrics.end('postSignup', guid);
    logger.debug('postSignup', { propertyName });
  } catch (error) {
    metrics.fail('postSignup', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postSignup',
      args: {
        propertyName,
        emailAddress,
      },
    });
    throw apiError;
  }
}
