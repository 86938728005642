import { Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useNavigate } from 'react-router-dom';

export function useContentFeedUnreachableToastProps({
  feed,
  closeToast,
}: {
  /**
   * The feed that is unreachable
   */
  feed: string;

  closeToast: () => void;
}) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    // Having to navigate via JS because react-router Link doesn't work in the toast (it's a portal)
    navigate('/settings/content-feeds');
    closeToast();
  };

  return {
    title: 'We aren’t receiving content from one of your feeds',
    description: `${feed} can’t be reached`,
    buttons: (
      <Button variant="secondary" onClick={handleNavigate}>
        Edit content feeds
      </Button>
    ),
  };
}
