import * as API from 'api/api';
import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  ADD_LINK_REASONS,
  API_TYPE_IDS,
  FRONTEND_METRICS,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SHARE_ORIGINS,
  SOCIAL_CHANNELS,
} from 'common/constants';
import { convertLinkToImage } from 'common/instantImage';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { initialiseAIMessage } from 'common/mediaItem/initialise';
import * as metrics from 'common/metrics';
import { addErrorNotification } from 'common/notifications';
import { canAddThumbnail } from 'common/socialV2';
import { validateURL } from 'common/url';
import { isNull, isUndefined } from 'common/utility';
import { FixTypeLater, SocialChannel } from 'types';

const addLinkPost = async ({
  accountAPIId,
  articleURL,
  socialChannel,
}: {
  accountAPIId: number;
  articleURL: string;
  socialChannel?: SocialChannel;
}) => {
  const validatedURL = await validateURL(articleURL);
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
  const resolvedURL = await API.getPropertiesResolveURL({
    propertyId,
    url: validatedURL,
  });
  const mediaId = await API.getMediaId({
    accountAPIId,
    articleURL: resolvedURL.resolvedURL ?? '',
  });
  let mediaItem = await Compose.getMediaItem({
    accountAPIId,
    state: MEDIA_ITEM_STATES.NEW,
    mediaId,
    postType: POST_TYPES.LINK,
    resolveURL: false,
  });
  if (socialChannel) {
    mediaItem = MediaItem.setSocialChannel({
      mediaItem,
      fieldValue: socialChannel,
    });
  }
  mediaItem = MediaItem.setIsURLResolved({
    mediaItem,
    fieldValue: !!resolvedURL.isResolved,
  });
  mediaItem = Compose.setShareTimeOptimal({ mediaItem });
  mediaItem = Compose.setShareOrigin({
    mediaItem,
    shareOrigin: SHARE_ORIGINS.NEW_SHARE,
  });
  mediaItem = await Compose.addShortLink({
    mediaItem,
    reasonCode: ADD_LINK_REASONS.ENTER_COMPOSE,
  });
  Compose.trackOpenComposeBox({
    mediaItem,
    shareOrigin: SHARE_ORIGINS.NEW_SHARE,
  });
  await Compose.addComposeBoxItem({ mediaItem });
};

const addNonLinkPost = async ({
  accountAPIId,
  postType,
  socialChannel,
}: {
  accountAPIId: number;
  postType: string;
  socialChannel?: SocialChannel;
}) => {
  let mediaItem = Compose.createMediaItem({
    accountAPIId,
    postType,
    isLoading: false,
    socialChannel,
  });
  mediaItem = Compose.setShareTimeOptimal({ mediaItem });
  mediaItem = Compose.setShareOrigin({
    mediaItem,
    shareOrigin: SHARE_ORIGINS.NEW_SHARE,
  });
  Compose.trackOpenComposeBox({
    mediaItem,
    shareOrigin: SHARE_ORIGINS.NEW_SHARE,
  });
  const guid = await Compose.addComposeBoxItem({ mediaItem });
  return guid;
};

const duplicateLinkPost = async ({
  sourceItem,
}: {
  sourceItem: FixTypeLater;
}) => {
  let guid;
  try {
    const accountAPIId = MediaItem.getAccountAPIId({
      mediaItem: sourceItem,
    });
    let mediaItem = Compose.createMediaItem({
      accountAPIId,
      postType: POST_TYPES.LINK,
      isLoading: true,
    });

    guid = await Compose.duplicateComposeBoxItem({ mediaItem });

    const url = MediaItem.getUnshortenedShareURL({ mediaItem: sourceItem });
    const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
    const resolvedURL = await API.getPropertiesResolveURL({ propertyId, url });
    const mediaId = await API.getMediaId({
      accountAPIId,
      articleURL: resolvedURL.resolvedURL ?? '',
    });
    const baseItem = await Compose.getMediaItem({
      accountAPIId,
      state: MEDIA_ITEM_STATES.NEW,
      mediaId,
      postType: POST_TYPES.LINK,
    });
    mediaItem = MediaItem.duplicateLinkPost({
      baseItem,
      editedItem: sourceItem,
    });
    mediaItem = Compose.setShareOrigin({
      mediaItem,
      shareOrigin: SHARE_ORIGINS.DUPLICATE,
    });
    const trackingDetails = MediaItem.getTrackingDetails({
      mediaItem,
    });
    const imageOverlayURL = MediaItem.getImageOverlayURL({ mediaItem });
    if (imageOverlayURL) {
      trackingDetails.hasImageOverlay = true;
      mediaItem = MediaItem.setTrackingDetails({
        mediaItem,
        fieldValue: trackingDetails,
      });
    }

    mediaItem = await Compose.addShortLink({
      mediaItem,
      reasonCode: ADD_LINK_REASONS.ENTER_COMPOSE,
    });
    await Compose.updateComposeBoxItem({ guid, mediaItem });
    return guid;
  } catch (error) {
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const duplicateNonLinkPost = async ({
  sourceItem,
}: {
  sourceItem: FixTypeLater;
}) => {
  let guid;
  try {
    const accountAPIId = MediaItem.getAccountAPIId({
      mediaItem: sourceItem,
    });
    const postType = MediaItem.getPostType({
      mediaItem: sourceItem,
    });
    let mediaItem = Compose.createMediaItem({
      accountAPIId,
      postType,
      isLoading: true,
    });

    guid = await Compose.duplicateComposeBoxItem({ mediaItem });

    mediaItem = MediaItem.duplicateNonLinkPost({
      sourceItem,
      accountAPIId,
    });
    mediaItem = Compose.setShareOrigin({
      mediaItem,
      shareOrigin: SHARE_ORIGINS.DUPLICATE,
    });
    await Compose.updateComposeBoxItem({ guid, mediaItem });
    return guid;
  } catch (error) {
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const duplicatePost = async ({ guid }: { guid: string }) => {
  const sourceItem = await Compose.findComposeBoxItem({ guid });
  if (isNull(sourceItem)) {
    return null;
  }
  const postType = MediaItem.getPostType({
    mediaItem: sourceItem,
  });
  let duplicateGuid;
  if (postType === POST_TYPES.LINK) {
    duplicateGuid = await Compose.duplicateLinkPost({ sourceItem });
  } else {
    duplicateGuid = await Compose.duplicateNonLinkPost({ sourceItem });
  }
  return duplicateGuid;
};

const editPost = async ({
  editItem,
  itemOrigin,
  instantImage,
}: {
  editItem: FixTypeLater;
  itemOrigin?: string;
  instantImage?: boolean;
}) => {
  metrics.mark(FRONTEND_METRICS.OPEN_COMPOSE_BOX_EDIT_ITEM);
  let guid;
  try {
    // Take the existing item, set the isLoading flag, and add it to the compose box
    const loadItem = MediaItem.setIsLoading({
      mediaItem: editItem,
      fieldValue: true,
    });
    guid = await Compose.addComposeBoxItem({ mediaItem: loadItem });
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem: editItem });
    const mediaId = MediaItem.getMediaId({ mediaItem: editItem });
    const postType = MediaItem.getPostType({ mediaItem: editItem });
    const state = MediaItem.getState({ mediaItem: editItem });
    // Now fetch the full media item
    logger.info(`Fetching media item ${mediaId}...`);

    let mediaItem = await Compose.getMediaItem({
      accountAPIId,
      state,
      mediaId,
      postType,
    });
    logger.info(`Fetched media item ${mediaId}`);
    // Perform any necessary additional processing of the media item
    if (postType === POST_TYPES.LINK) {
      mediaItem = Compose.storeOriginalMessages({ mediaItem });
      mediaItem = Compose.resetShareTimeOptimal({ mediaItem });
    }

    // Initialize the AI message configuration
    mediaItem = MediaItem.setAIMessage({
      mediaItem,
      fieldValue: initialiseAIMessage({
        mediaItem,
        accountAPIId,
        isFirstRender: true,
      }),
    });

    if (instantImage) {
      mediaItem = convertLinkToImage({ mediaItem });
    }

    let shareOrigin;
    if (itemOrigin !== undefined) {
      shareOrigin = itemOrigin;
    } else {
      shareOrigin =
        state === MEDIA_ITEM_STATES.NEW
          ? SHARE_ORIGINS.HOME_FEED
          : SHARE_ORIGINS.SCHEDULE_QUEUE;
    }
    mediaItem = Compose.setShareOrigin({
      mediaItem,
      shareOrigin,
    });
    mediaItem = await Compose.addShortLink({
      mediaItem,
      reasonCode: ADD_LINK_REASONS.ENTER_COMPOSE,
    });

    // if mediaItem contains an image url and is a video, then we need to save that url as it is the thumbnail
    const imageUrls = MediaItem.getImageURLs({ mediaItem: editItem });
    if (postType === POST_TYPES.VIDEO && imageUrls && imageUrls.length > 0) {
      mediaItem = MediaItem.setImageURLs({
        mediaItem,
        fieldValue: imageUrls,
      });
    }
    Compose.trackOpenComposeBox({
      mediaItem,
      shareOrigin,
    });
    // And update the compose box with the final media item details
    await Compose.updateComposeBoxItem({ guid, mediaItem });
    return guid;
  } catch (error) {
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const reshareLinkPost = async ({
  sourceItem,
  accountAPIId,
  shareOrigin,
}: {
  sourceItem: FixTypeLater;
  accountAPIId: number;
  shareOrigin: string;
}) => {
  let guid;
  try {
    let mediaItem = Compose.createMediaItem({
      accountAPIId,
      postType: POST_TYPES.LINK,
      isLoading: true,
    });
    guid = await Compose.addComposeBoxItem({ mediaItem });
    const articleURL = MediaItem.getUnshortenedShareURL({
      mediaItem: sourceItem,
    });
    const mediaId = await API.getMediaId({ accountAPIId, articleURL });
    const baseItem = await Compose.getMediaItem({
      accountAPIId,
      state: MEDIA_ITEM_STATES.NEW,
      mediaId,
      postType: POST_TYPES.LINK,
    });
    mediaItem = Compose.prepareForReshare({
      baseItem,
      sharedItem: sourceItem,
    });
    mediaItem = Compose.setShareOrigin({ mediaItem, shareOrigin });
    mediaItem = await Compose.addShortLink({
      mediaItem,
      reasonCode: ADD_LINK_REASONS.ENTER_COMPOSE,
      overrideURLParameters: true,
    });
    Compose.trackOpenComposeBox({
      mediaItem,
      shareOrigin,
    });
    await Compose.updateComposeBoxItem({
      guid,
      mediaItem,
    });

    return guid;
  } catch (error) {
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const reshareNonLinkPost = async ({
  sourceItem,
  accountAPIId,
  postType,
  shareOrigin,
}: {
  sourceItem: FixTypeLater;
  accountAPIId: number;
  postType: string;
  shareOrigin: string;
}) => {
  let guid;
  try {
    let mediaItem = Compose.createMediaItem({
      accountAPIId,
      postType,
      isLoading: true,
    });
    guid = await Compose.addComposeBoxItem({ mediaItem });
    mediaItem = Compose.prepareForReshare({
      sharedItem: sourceItem,
    });
    mediaItem = Compose.setShareOrigin({ mediaItem, shareOrigin });
    Compose.trackOpenComposeBox({
      mediaItem,
      shareOrigin,
    });
    await Compose.updateComposeBoxItem({
      guid,
      mediaItem,
    });

    return guid;
  } catch (error) {
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const resharePost = async ({
  mediaId,
  accountAPIId,
  postType,
  shareOrigin,
  thumbnail,
}: {
  mediaId: string;
  accountAPIId: number;
  postType: string;
  shareOrigin: string;
  thumbnail?: string;
}) => {
  let guid;
  try {
    let sourceItem = await Compose.getMediaItem({
      accountAPIId,
      state: MEDIA_ITEM_STATES.SHARED,
      mediaId,
      postType,
    });

    // if a thumbnail is passed in, set imageUrls to that if it is empty and post type is video
    const imageUrls = MediaItem.getImageURLs({ mediaItem: sourceItem });

    if (
      postType === POST_TYPES.VIDEO &&
      imageUrls &&
      imageUrls.length === 0 &&
      thumbnail
    ) {
      sourceItem = MediaItem.setImageURLs({
        mediaItem: sourceItem,
        fieldValue: [thumbnail],
      });
    }

    if (postType === POST_TYPES.LINK) {
      guid = await Compose.reshareLinkPost({
        sourceItem,
        accountAPIId,
        shareOrigin,
      });
    } else {
      guid = await Compose.reshareNonLinkPost({
        sourceItem,
        accountAPIId,
        postType,
        shareOrigin,
      });
    }
    return guid;
  } catch (error) {
    if (guid != null) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const shareLinkPostTo = async ({
  sourceItem,
  accountAPIId,
  additionalSocialPages,
  publishToComposeBox = true,
}: {
  sourceItem: FixTypeLater;
  accountAPIId: number;
  additionalSocialPages?: FixTypeLater[];
  publishToComposeBox?: boolean;
}) => {
  const articleURL = MediaItem.getUnshortenedShareURL({
    mediaItem: sourceItem,
  });
  if (isUndefined(articleURL)) {
    logger.error({
      event: 'shareLinkPostTo: Unexpected failure - articleURL is undefined',
      properties: {
        accountAPIId,
        sourceItem,
      },
    });
    if (publishToComposeBox) {
      addErrorNotification(
        'Failed to select additional social page. Please try again.',
      );
    }
    return null; // Exit in the unlikely event the post being shared is incomplete
  }

  let guid;
  try {
    let mediaItem = Compose.createMediaItem({
      accountAPIId,
      postType: POST_TYPES.LINK,
      isLoading: true,
    });
    if (publishToComposeBox) {
      guid = await Compose.duplicateComposeBoxItem({ mediaItem });
    }
    const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
    const resolvedURL = await API.getPropertiesResolveURL({
      propertyId,
      url: articleURL,
    });
    const mediaId = await API.getMediaId({
      accountAPIId,
      articleURL: resolvedURL.resolvedURL ?? articleURL,
    });
    const baseItem = await Compose.getMediaItem({
      accountAPIId,
      state: MEDIA_ITEM_STATES.NEW,
      mediaId,
      postType: POST_TYPES.LINK,
      resolveURL: false,
    });
    mediaItem = MediaItem.duplicateLinkPost({
      baseItem,
      editedItem: sourceItem,
    });
    mediaItem = Compose.setShareOrigin({
      mediaItem,
      shareOrigin: SHARE_ORIGINS.ADDITIONAL_SOCIAL_NETWORK,
    });
    if (additionalSocialPages) {
      mediaItem = MediaItem.setAdditionalSocialPages({
        mediaItem,
        fieldValue: additionalSocialPages,
      });
    }
    mediaItem = await Compose.addShortLink({
      mediaItem,
      reasonCode: ADD_LINK_REASONS.ENTER_COMPOSE,
    });
    if (publishToComposeBox && guid !== undefined) {
      await Compose.updateComposeBoxItem({
        guid,
        mediaItem,
      });
    }

    return { guid, mediaItem };
  } catch (error) {
    logger.error({
      event: 'shareLinkPostTo: Unexpected failure',
      error,
      properties: {
        accountAPIId,
        sourceItem,
      },
    });
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const shareNonLinkPostTo = async ({
  sourceItem,
  accountAPIId,
  postType,
  additionalSocialPages,
  publishToComposeBox = true,
}: {
  sourceItem: FixTypeLater;
  accountAPIId: number;
  postType: string;
  additionalSocialPages?: FixTypeLater[];
  publishToComposeBox?: boolean;
}) => {
  let guid;
  try {
    let mediaItem = Compose.createMediaItem({
      accountAPIId,
      postType,
      isLoading: true,
    });
    if (publishToComposeBox) {
      guid = await Compose.duplicateComposeBoxItem({ mediaItem });
    }
    mediaItem = MediaItem.duplicateNonLinkPost({
      sourceItem,
      accountAPIId,
    });

    const apiTypeId = getAPITypeId({
      accountAPIId,
    });

    const imageURLs = MediaItem.getImageURLs({ mediaItem });

    if (
      canAddThumbnail(apiTypeId) &&
      postType === POST_TYPES.VIDEO &&
      !imageURLs?.[0]
    ) {
      const videoURL = MediaItem.getVideoURL({ mediaItem });
      if (videoURL) {
        const thumbnail = await API.getVideoThumbnail({
          videoURL,
        });
        if (thumbnail) {
          mediaItem = MediaItem.setImageURLs({
            mediaItem,
            fieldValue: [thumbnail],
          });
        }
      }
    }
    if (apiTypeId === API_TYPE_IDS.FACEBOOK && postType === POST_TYPES.VIDEO) {
      const socialChannel = MediaItem.getSocialChannel({
        mediaItem: sourceItem,
      });
      if (
        socialChannel === SOCIAL_CHANNELS.REEL ||
        socialChannel === SOCIAL_CHANNELS.STORY
      ) {
        mediaItem = MediaItem.setSocialChannel({
          mediaItem,
          fieldValue: SOCIAL_CHANNELS.REEL,
        });
      }
    }

    mediaItem = Compose.setShareOrigin({
      mediaItem,
      shareOrigin: SHARE_ORIGINS.ADDITIONAL_SOCIAL_NETWORK,
    });
    if (additionalSocialPages) {
      mediaItem = MediaItem.setAdditionalSocialPages({
        mediaItem,
        fieldValue: additionalSocialPages,
      });
    }
    const files = MediaItem.getFiles({ mediaItem: sourceItem });
    if (files) {
      mediaItem = MediaItem.setFiles({
        mediaItem,
        fieldValue: files,
      });
    }
    if (publishToComposeBox && guid !== undefined) {
      await Compose.updateComposeBoxItem({ guid, mediaItem });
    }
    return { guid, mediaItem };
  } catch (error) {
    if (guid !== undefined) {
      Compose.deleteComposeBoxItem({ guid });
    }
    Compose.showErrorNotification({ error });
    return null;
  }
};

const sharePostTo = async ({
  sourceItem,
  accountAPIId,
  additionalSocialPages,
  publishToComposeBox = true,
}: {
  /**
   * The source item from which we are duplicating.
   */
  sourceItem: FixTypeLater;
  /**
   * The account API ID we are sharing to.
   */
  accountAPIId: number;
  additionalSocialPages?: FixTypeLater[];
  publishToComposeBox?: boolean;
}) => {
  const postType = MediaItem.getPostType({
    mediaItem: sourceItem,
  });
  let result;
  if (publishToComposeBox) {
    if (postType === POST_TYPES.LINK) {
      Compose.shareLinkPostTo({
        sourceItem,
        accountAPIId,
        additionalSocialPages,
        publishToComposeBox,
      });
    } else {
      Compose.shareNonLinkPostTo({
        sourceItem,
        accountAPIId,
        postType,
        additionalSocialPages,
        publishToComposeBox,
      });
    }
  } else if (postType === POST_TYPES.LINK) {
    result = await Compose.shareLinkPostTo({
      sourceItem,
      accountAPIId,
      additionalSocialPages,
      publishToComposeBox,
    });
  } else {
    result = await Compose.shareNonLinkPostTo({
      sourceItem,
      accountAPIId,
      postType,
      additionalSocialPages,
      publishToComposeBox,
    });
  }
  return result;
};

export {
  addLinkPost,
  addNonLinkPost,
  duplicateLinkPost,
  duplicateNonLinkPost,
  duplicatePost,
  editPost,
  reshareLinkPost,
  reshareNonLinkPost,
  resharePost,
  shareLinkPostTo,
  shareNonLinkPostTo,
  sharePostTo,
};
