import { TAG_TYPES } from 'common/constants';
import type { Tag, TagType } from 'types';

interface TagSuggestionProps {
  tag: Tag;
  tagType: TagType;
  onTagInsert: (args: { tag: Tag; tagType: TagType }) => void;
}

const TagSuggestion = ({ onTagInsert, tag, tagType }: TagSuggestionProps) => (
  <a
    className="share_tag text_unselectable"
    data-cy-id={tag.clean}
    onClick={() =>
      onTagInsert({
        tag,
        tagType,
      })
    }
  >
    {tagType === TAG_TYPES.MENTION ? '@' : ''}
    {tag.clean}
  </a>
);

export default TagSuggestion;
