import { Flex, FormControl, Input } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as MediaItem from 'common/mediaItem';
import Tooltip from 'components/misc/Tooltip';
import {
  togglePostToLinkInBioCheckbox,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import type { FixTypeLater } from 'types';

interface LinkInBioCheckboxProps {
  guid: string;
  mediaItem: FixTypeLater;
  isDisabled?: boolean;
}

const LinkInBioCheckbox = ({
  guid,
  mediaItem,
  isDisabled,
}: LinkInBioCheckboxProps) => {
  const { dispatch } = useComposeBoxContext();
  const id = `linkinbio-${guid}`;

  const handleToggle = () => {
    dispatch(togglePostToLinkInBioCheckbox({ guid }));
  };

  const tooltipMessage = isDisabled
    ? 'To add Link in bio to your Echobox, contact support@echobox.com'
    : 'Will also be shared to your Link in Bio page';

  return (
    <Flex alignItems="center" className={isDisabled ? 'disabled' : ''}>
      <Input
        type="checkbox"
        id={id}
        name="linkinbio"
        checked={!isDisabled && MediaItem.getShouldSendURL({ mediaItem })}
        isDisabled={isDisabled}
        onChange={handleToggle}
      />
      <FormControl.FormLabel
        lineHeight="13px"
        htmlFor={id}
        flexGrow={1}
        fontSize="13px"
        cursor="default"
        color={isDisabled ? 'gray.300' : undefined}
      >
        Post to link in bio
      </FormControl.FormLabel>
      <Tooltip id="linkinbioicon" placement="left" label={tooltipMessage}>
        <img
          className="info_icon"
          src="/img/icons/ic-information.svg"
          alt=""
          data-tip="true"
          data-for="linkinbioicon"
        />
      </Tooltip>
    </Flex>
  );
};

export default LinkInBioCheckbox;
