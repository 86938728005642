import { Tab, Tabs } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getCurrentAPITypeId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import { isApprovalsActive, isIGCabinetEnabled } from 'common/settings';

const SubHeader = () => {
  const tabs = [
    { name: 'Details', url: '/details' },
    { name: 'Home', url: '/share' },
    { name: 'Queue', url: '/queue' },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const apiTypeId = getCurrentAPITypeId();
  const isInstagram = apiTypeId === API_TYPE_IDS.INSTAGRAM;
  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;

  if (isApprovalsActive()) {
    tabs.push({ name: 'Approvals', url: '/approvals' });
  } else if (location.pathname === '/approvals') {
    navigate('/share', { replace: true });
  }

  if (!isTikTok) {
    tabs.push({ name: 'Archive', url: '/archive' });
  }

  if (!isInstagram || !isIGCabinetEnabled()) {
    tabs.push({ name: 'Analytics', url: '/analytics' });
  }

  return (
    <Tabs selectedValue={location.pathname} px={4}>
      {tabs.map(({ name, url }) => (
        <Tab as={Link} href={url} value={url} key={url}>
          {name}
        </Tab>
      ))}
    </Tabs>
  );
};

export default SubHeader;
