import { Grid, Heading, Input } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';
import { Property } from 'types';

interface CRMIdentifierSettingGridItemProps {
  setSelectedCRMIdentifier: React.Dispatch<React.SetStateAction<string>>;
  setValidCRMIdentifier: React.Dispatch<React.SetStateAction<boolean>>;
  validCRMIdentifier: boolean;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  property: Property;
}

const CRMIdentifierSettingGridItem = ({
  setSelectedCRMIdentifier,
  setValidCRMIdentifier,
  validCRMIdentifier,
  setChangesMade,
  property,
}: CRMIdentifierSettingGridItemProps) => {
  const [value, setValue] = useState(property.crmIdentifier);

  const handleCRMIdentifierChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(event.target.value);
    if (event.target.value === property.crmIdentifier) {
      setValidCRMIdentifier(true);
    } else if (!event.target.value.match('^C\\d+$')) {
      setValidCRMIdentifier(false);
      setChangesMade(false);
    } else {
      setSelectedCRMIdentifier(event.target.value);
      setChangesMade(true);
      setValidCRMIdentifier(true);
    }
  };

  return (
    <Grid.GridItem>
      <Heading variant="h6" color="gray.600" mb={1}>
        CRM ID:
      </Heading>
      <Input
        type="text"
        value={value}
        isInvalid={!validCRMIdentifier}
        onChange={handleCRMIdentifierChange}
        placeholder={property.crmIdentifier ?? 'Enter CRM Identifier...'}
      />
    </Grid.GridItem>
  );
};

export default CRMIdentifierSettingGridItem;
