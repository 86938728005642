/**
 * postMedia - create / update media item
 *
 * @param {string} targetURL   - the URL to use for the HTTP echo requests
 * @param {array} userAgents   - the user agents to add to each request
 * @param {boolean} includeResponseBody - the response body from the request
 * @return {object}            - a list of the responses from each request
 */
import type { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  baseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { HTTPEchoResponseSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';

export default async function postGetHTTPEcho({
  targetURL,
  userAgents,
  includeResponseBody,
}: {
  targetURL: string;
  userAgents: Array<string>;
  includeResponseBody: boolean;
}) {
  const headers = getClientServiceRequestHeaders();

  const guid = metrics.start('postGetHTTPEcho');
  const config: AxiosRequestConfig = {
    url: `${baseCoreAPIURL}get/v1/httpecho`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers,
    data: JSON.stringify({ targetURL, userAgents, includeResponseBody }),
  };

  try {
    const response = await axios(config);
    metrics.end('postGetHTTPEcho', guid);
    logger.debug('postGetHTTPEcho', {
      targetURL,
      userAgents,
      includeResponseBody,
    });

    const validatedData = lyingParse(HTTPEchoResponseSchema, response.data);
    return validatedData.responses;
  } catch (error) {
    metrics.fail('postGetHTTPEcho', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postGetHTTPEcho',
      args: {
        targetURL,
        userAgents,
        includeResponseBody,
      },
    });
    throw apiError;
  }
}
