import { getURNName } from 'common/social';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import { isNullOrUndefined } from 'common/utility';
import Tooltip from 'components/misc/Tooltip';
import { APITypeId } from 'types';

const BREAKDOWN_INSIGHTS = ['engagementRate', 'totalSocialEngagement', 'reach'];

interface Breakdown {
  type: string;
  value: string;
}
const sortTooltipData = (first: Breakdown, second: Breakdown) => {
  const SORT_ORDER: Record<string, number> = {
    Likes: 0,
    Comments: 1,
    Reshares: 2,
    Saves: 3,
  };

  const firstOrdinal = SORT_ORDER[first.type];
  const secondOrdinal = SORT_ORDER[second.type];

  if (isNullOrUndefined(firstOrdinal) && isNullOrUndefined(secondOrdinal)) {
    return 0;
  }

  if (!isNullOrUndefined(firstOrdinal) && isNullOrUndefined(secondOrdinal)) {
    return -1;
  }

  if (isNullOrUndefined(firstOrdinal) && !isNullOrUndefined(secondOrdinal)) {
    return 1;
  }

  if (firstOrdinal === secondOrdinal) {
    return 0;
  }

  if (firstOrdinal < secondOrdinal) {
    return -1;
  }

  if (firstOrdinal > secondOrdinal) {
    return 1;
  }

  return 0;
};

interface InsightProps {
  cellKey: string;
  apiTypeId: APITypeId;
  insightKey: string;
  insightData:
    | (Record<string, number> & {
        extraInsights?: Record<string, number>;
        breakdown: Breakdown[];
      })
    | undefined;
}
const Insight = ({
  cellKey,
  apiTypeId,
  insightData,
  insightKey,
}: InsightProps) => {
  const availableInsights = getSocialAnalyticsInsights(apiTypeId);

  if (
    !availableInsights ||
    !Object.keys(availableInsights).includes(insightKey) ||
    insightData == null
  ) {
    return <td data-cy-id={insightKey}>-</td>;
  }

  let value: string | number | undefined = insightData[insightKey];

  if (value == null) {
    // If the value is null, check if it can be retrieved from the `extraInsights` property.
    // keys within extraInsights are prefixed with the shortName of the network (e.g. 'fb' for Facebook)
    const shortName = getURNName({ apiTypeId });
    value = insightData.extraInsights?.[`${shortName}${insightKey}`];
  }

  if (value && typeof value === 'number') {
    value = Math.round(value);
  }

  value ??= '-';

  const showBreakdown = BREAKDOWN_INSIGHTS.includes(insightKey);

  if (!showBreakdown) {
    return <td data-cy-id={insightKey}>{value.toLocaleString()}</td>;
  }

  const tooltipData = [...(insightData.breakdown ?? [])];

  tooltipData.sort(sortTooltipData);

  const tooltipLabel = tooltipData.map((tooltipItem) => {
    const tooltipKey = `${cellKey}_${tooltipItem.type}`;
    return (
      <span className="insight" key={tooltipKey}>
        {tooltipItem.type}: {tooltipItem.value}
        <br />
      </span>
    );
  });

  return (
    <td data-tip data-for={cellKey} data-cy-id={insightKey}>
      <Tooltip id={cellKey + 1} label={tooltipLabel}>
        <div data-tip data-for={cellKey + 1} data-iscapture="true">
          {insightData[insightKey]?.toLocaleString()}
        </div>
      </Tooltip>
    </td>
  );
};

export default Insight;
