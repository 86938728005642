import {
  getAPITypeId,
  getBitlyAPIs,
  getCurrentProperty,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import { SHARE_URL_PLACEHOLDER_REGEX } from 'common/config';
import * as MediaItem from 'common/mediaItem';
import { getShorteningDisabledSetting } from 'common/social';
import { shortenURLsInMessage } from 'common/string';
import { isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * The share URL placeholder is the text to replace if there is no shareURL or if
 * the media item is being edited
 *
 * @returns {string}
 */
export function getShareURLPlaceholder({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
  if (isNullOrUndefined(propertyId)) {
    return 'http://ebx.sh/...';
  }
  const shorteningDisabled = getShorteningDisabledSetting({
    propertyId,
    apiTypeId: getAPITypeId({ accountAPIId }),
  });

  if (shorteningDisabled && !shorteningDisabled.enabled) {
    // If shortening is enabled use the given default placeholder
    const apis = getCurrentProperty().accountAPIs;
    const bitlyAPIs = getBitlyAPIs({ accountAPIs: apis });

    // Use placeholder from Bitly if they have one
    let defaultShareURL;
    if (bitlyAPIs && bitlyAPIs.length > 0) {
      defaultShareURL = bitlyAPIs[0].urlPlaceholderDefault;
    }

    if (defaultShareURL) {
      // Ensure a shortened URL is always elipsed
      if (!defaultShareURL.endsWith('/...')) {
        const append = defaultShareURL.endsWith('/') ? '...' : '/...';
        defaultShareURL += append;
      }
      return defaultShareURL;
    }

    // If shortening is enabled always return a shortened URL, so use Echobox default
    try {
      if (Number(propertyId) === 10852) {
        return 'http://on.ft.com/...';
      }
      return 'http://ebx.sh/...';
    } catch (e) {
      return 'http://ebx.sh/...';
    }
  }

  // If shortening is disabled shorted the media item URL
  const mediaItemURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  if (mediaItemURL) {
    return shortenURLsInMessage(mediaItemURL);
  }

  // Default to removing the URL
  return '';
}

/**
 * Replaces the share URL placeholder text with the provided replacement in the text
 * @param {{
 *  text?: string | null;
 *  shareURL?: string;
 * }}
 */
export function replaceShareURL({ text = undefined, shareURL = '' } = {}) {
  if (text) {
    return text.replace(SHARE_URL_PLACEHOLDER_REGEX, shareURL);
  }
  return text;
}
