import { Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useLocation } from 'react-router-dom';

import ConnectSocialPages from 'components/settings/page/ConnectSocialPages';
import SocialPageSelect from 'components/setup/SocialPageSelect';

const Pages = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const requestToken = params.get('requestToken');
  const apiTypeId = sessionStorage.getItem('connectingAPITypeId');

  return (
    <>
      <Heading mb={6}>Pages</Heading>
      <ConnectSocialPages />
      {requestToken && (
        <SocialPageSelect apiTypeId={apiTypeId} requestToken={requestToken} />
      )}
    </>
  );
};

export default Pages;
