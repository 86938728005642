export const LOCALSTORAGE_PAGES_KEY = 'COMPETITOR_PAGES';
export const TIME_RANGE_OPTIONS = [
  {
    duration: 2,
    label: 'Last 2 hours',
  },
  {
    duration: 6,
    label: 'Last 6 hours',
  },
  {
    duration: 12,
    label: 'Last 12 hours',
  },
  {
    duration: 24,
    label: 'Last 24 hours',
  },
];

export const FACEBOOK_POST_TYPES = {
  LINK: 'shared_story',
  IMAGE: 'added_photos',
  VIDEO: 'added_video',
};

export type FACEBOOK_POST_TYPE =
  (typeof FACEBOOK_POST_TYPES)[keyof typeof FACEBOOK_POST_TYPES];

export const POST_TYPES = [
  {
    value: 'all',
    label: 'All types',
  },
  {
    value: FACEBOOK_POST_TYPES.LINK,
    label: 'Links',
  },
  {
    value: FACEBOOK_POST_TYPES.IMAGE,
    label: 'Images',
  },
  {
    value: FACEBOOK_POST_TYPES.VIDEO,
    label: 'Videos',
  },
];

export interface PageData {
  id: string;
  fbPage: string;
  name: string;
  picture: string;
  followerCount: number;
  posts: any[];
  engagement: number;
  shareOfVoiceInPercent: number;
}
