import {
  Button,
  ButtonProps,
  Dropdown,
  Icon,
  IconProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const ArrowIcon = (props: IconProps) => (
  <Icon width="9" height="6" viewBox="0 0 9 6" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.71316 0.314107L3.46506 2.14309C3.46506 2.14309 3.99003 2.79445 4.5 2.79445C5.00997 2.79445 5.53344 2.1448 5.53344 2.1448L7.28684 0.314107C7.47973 0.110394 7.73318 -0.0018858 7.99583 2.39684e-05C8.25847 0.00193373 8.51065 0.117891 8.70126 0.324392C8.7947 0.423093 8.86942 0.54266 8.92081 0.675693C8.97219 0.808727 8.99914 0.952395 8.99998 1.09781C9.00082 1.24323 8.97554 1.38728 8.92571 1.52109C8.87588 1.65489 8.80254 1.77558 8.71026 1.87568L5.20496 5.6862C5.01461 5.89156 4.76174 6.00408 4.5 5.99989C4.23878 6.00363 3.98652 5.89114 3.79654 5.6862L0.289747 1.87568C0.197457 1.77558 0.124125 1.65489 0.0742897 1.52109C0.0244547 1.38728 -0.000823145 1.24323 2.04391e-05 1.09781C0.000864024 0.952395 0.0278111 0.808727 0.0791941 0.675693C0.130577 0.54266 0.205302 0.423093 0.298746 0.324392C0.489356 0.117891 0.741537 0.00193373 1.00418 2.39684e-05C1.26682 -0.0018858 1.52027 0.110394 1.71316 0.314107Z"
      fill="#A3ACB9"
    />
  </Icon>
);

const FilterButton = (props: ButtonProps) => {
  return (
    <Dropdown.Button
      as={Button}
      variant="secondary"
      w="118px"
      h="29px"
      my={1}
      border="1px solid #CAD0DB"
      color="#424D60"
      textAlign="left"
      borderRadius="3px"
      boxShadow="0px 0px 3px rgba(38, 72, 121, 0.4), 0px 3px 4px rgba(38, 72, 121, 0.15)"
      fontSize="13px"
      px="10px"
      rightIcon={<ArrowIcon w="9px" h="6px" />}
      sx={{
        span: {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      }}
      {...props}
    />
  );
};

export default FilterButton;
