import {
  Box,
  Flex,
  Image,
  VisuallyHidden,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import Immutable from 'seamless-immutable';

import {
  getAccountAPI,
  getCurrentAPITypeId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  AB_TEST_STATUSES,
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  COLLECTION_NAMES,
  POST_TYPES,
  SHARE_ORIGINS,
  SHARE_TIME_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
  TEXT_CASES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getFormattedDateFromUnix } from 'common/datetime';
import { getTextDirection } from 'common/language';
import * as MediaItem from 'common/mediaItem';
import { hasLastUpdateDetails } from 'common/mediaItem/gettersSetters';
import { isEbxImage } from 'common/s3';
import {
  getFeatureToggle,
  getSetting,
  isIGCabinetEnabled,
} from 'common/settings';
import { getShareURLPlaceholder, replaceShareURL } from 'common/shareURL';
import { hasMessageField } from 'common/social';
import { shortenURLsInMessage } from 'common/string';
import {
  getImageThumbnail,
  getNewsFeedVideoThumbnail,
} from 'common/thumbnails';
import { isVideoURL } from 'common/url';
import { arePropsEqual, isNull, isNullOrUndefined } from 'common/utility';
import ItemError from 'components/home/ItemError';
import LastEditedUser from 'components/home/LastEditedUser';
import MessageWithTags from 'components/home/MessageWithTags';
import TimingBadges from 'components/home/TimingBadges';
import Loading from 'components/home/schedulequeue/Loading';
import ShowArticleDeleteSurvey from 'components/home/schedulequeue/ShowArticleDeleteSurvey';
import { trackOpenArticle } from 'helpers/articleTracking';
import { addImagePrefix } from 'helpers/images';
import useThumbnail from 'hooks/useThumbnail';
import { useIsComposeBoxOpen } from 'state/composeBoxOpen';
import StickerPreview from './StickerPreview';

/**
 * Schedule queue item
 */

const Item = (props) => {
  const isComposeBoxOpen = useIsComposeBoxOpen();
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const videoURL = MediaItem.getVideoURL({ mediaItem: props.mediaItem });
  const imageURLs = MediaItem.getImageURLs({ mediaItem: props.mediaItem });
  const thumbnail = useThumbnail({
    videoURL,
    shouldFetch:
      imageURLs == null || imageURLs.length === 0 || !isEbxImage(imageURLs[0]),
  });

  if (imageURLs && imageURLs.length === 0 && thumbnail) {
    imageURLs.push(thumbnail);
  }

  /**
   * Event handlers
   */

  const openSurveyModal = () => {
    if (Math.random() <= 0.05) {
      setShowSurveyModal(true);
    } else {
      handleArticleDelete();
    }
  };

  const closeSurveyModal = () => {
    setShowSurveyModal(false);
    handleArticleDelete();
  };

  const handleArticleDelete = () => {
    const { mediaItem } = props;
    const mediaId = MediaItem.getMediaId({ mediaItem });

    props.eventHandlers.handleArticleDelete({
      collectionName: COLLECTION_NAMES.SCHEDULE_QUEUE,
      mediaId,
    });
  };

  const handleArticleEdit = () => {
    if (isComposeBoxOpen) {
      return;
    }

    // if a video, then update the video thumbnail
    let mediaItem = Immutable(props.mediaItem);
    const mediaItemImageURLs = MediaItem.getImageURLs({ mediaItem });

    if (thumbnail && mediaItemImageURLs.length === 0) {
      mediaItem = MediaItem.setImageURLs({
        mediaItem,
        fieldValue: [thumbnail],
      });
    }

    Compose.editPost({
      editItem: mediaItem,
      itemOrigin: SHARE_ORIGINS.SCHEDULE_QUEUE,
    });
  };

  /**
   * Render methods
   */

  const renderImage = (
    apiTypeId,
    postType,
    socialChannel,
    isVideoLink,
    imageOverlayResult,
  ) => {
    const accountAPI = getAccountAPI({ accountAPIId });
    const showInstagramLinkStickerPreview =
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      postType === POST_TYPES.LINK &&
      socialChannel === SOCIAL_CHANNELS.STORY &&
      !accountAPI.useSwipeUpLinks;

    let backgroundImage;
    let imageThumbnail;
    let videoThumbnail;
    if (imageURLs.length === 0) {
      if (postType === POST_TYPES.VIDEO) {
        videoThumbnail = getNewsFeedVideoThumbnail();
        backgroundImage = videoThumbnail;
      } else {
        imageThumbnail = getImageThumbnail({
          apiTypeId,
          postType,
          isNewsFeed: true,
        });
        backgroundImage = imageThumbnail;
      }
    } else if (imageOverlayResult) {
      backgroundImage = imageOverlayResult;
    } else {
      backgroundImage = addImagePrefix(imageURLs[0]);
    }
    const style = {
      backgroundImage: `url('${thumbnail || backgroundImage}')`,
    };
    const isInstagramStory =
      apiTypeId === API_TYPE_IDS.INSTAGRAM &&
      socialChannel === SOCIAL_CHANNELS.STORY &&
      (postType === POST_TYPES.LINK || postType === POST_TYPES.PHOTO_STORY);
    const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;

    let imageClass = '';

    if (isTikTok) {
      imageClass = 'tiktok-post';
    } else if (
      isInstagramStory ||
      isInstagramGraphStory ||
      socialChannel === SOCIAL_CHANNELS.REEL
    ) {
      imageClass = 'ratio-9-16';
    }

    return (
      <div className={`image ${imageClass}`} style={style}>
        {showInstagramLinkStickerPreview && (
          <StickerPreview mediaItem={mediaItem} />
        )}
        {isVideoLink && (
          <img
            src="/img/icons/ic-play-w.svg"
            alt=""
            className="schedule-queue-video"
          />
        )}
        &nbsp;
        {postType === POST_TYPES.VIDEO &&
          backgroundImage !== videoThumbnail && (
            <Flex
              pos="absolute"
              bottom={0}
              h="full"
              w="full"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                borderRadius="full"
                bg="rgba(235, 238, 245, 0.35)"
                maxH={6}
                maxW={6}
                p={1}
                src="/img/icons/video-icon.svg"
                alt=""
              />
            </Flex>
          )}
      </div>
    );
  };

  const renderMessage = (
    message,
    apiTypeId,
    tags,
    accountAPIId,
    postType,
    socialChannel,
  ) => {
    if (!hasMessageField({ postType, apiTypeId, socialChannel })) {
      return null;
    }
    if (message === '') {
      return <div className="message no_message_shared">No Message</div>;
    }
    const messageWithShortURLs = shortenURLsInMessage(message);
    return (
      <MessageWithTags
        message={messageWithShortURLs}
        tags={tags}
        accountAPIId={accountAPIId}
        outerClass="message"
      />
    );
  };

  const renderURL = (articleURL, rssTitle, accountAPIId) => {
    const textDirectionClass = getTextDirection({
      accountAPIId,
      text: rssTitle,
    });
    return (
      <a
        href={articleURL}
        onClick={() => trackOpenArticle(articleURL, 'Scheduled')}
        target="_blank"
        rel="noopener noreferrer"
        className={`article_link text-truncate ${textDirectionClass}`}
      >
        <span data-cy-id="title">{rssTitle}</span>
        <img
          className="link_icon op-60"
          src="/img/icons/ic-open-in-new.svg"
          alt=""
        />
      </a>
    );
  };

  /* jscpd:ignore-start */
  const mediaItem = props.mediaItem;
  const mediaId = MediaItem.getMediaId({ mediaItem });
  const accountAPIId = props.accountAPIId;
  const isLoading = MediaItem.getIsLoading({ mediaItem });

  if (isLoading) {
    return <Loading mediaId={mediaId} />;
  }
  /* jscpd:ignore-end */

  const apiTypeId = getCurrentAPITypeId();

  const abTestStatusId = MediaItem.getABTestStatusId({ mediaItem });
  let abTestStatusMessage;
  if (!isNullOrUndefined(abTestStatusId)) {
    switch (abTestStatusId) {
      case AB_TEST_STATUSES.TESTING:
        abTestStatusMessage = '(AB test in progress...)';
        break;
      case AB_TEST_STATUSES.COMPLETED:
      case AB_TEST_STATUSES.ERROR:
        abTestStatusMessage = '(AB test completed)';
        break;
      default:
        abTestStatusMessage = '';
    }
  }
  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const errorMessage = MediaItem.getErrorMessage({ mediaItem });
  const isSaving = MediaItem.getIsSaving({ mediaItem });
  const itemTags = MediaItem.getTags({ mediaItem });
  const labels = MediaItem.getMediaItemTags({ mediaItem });
  const mediaStatesByAccountAPIId = MediaItem.getMediaStatesByAccountAPIId({
    mediaItem,
  });
  const postType = MediaItem.getPostType({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const previewFields = MediaItem.getPreviewFields({ mediaItem });
  const rssTitle = MediaItem.getRssTitle({ mediaItem });
  const shareTime = MediaItem.getShareTime({ mediaItem });
  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });
  const timeSensitivityTypeId = MediaItem.getTimeSensitivityTypeId({
    mediaItem,
  });

  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getCurrentPropertyId(),
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;
  const hasImage =
    (!isNullOrUndefined(imageURLs) && imageURLs.length > 0) ||
    postType === POST_TYPES.VIDEO;

  const isUserManagementEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
  let lastUser;
  let lastEditedtime;
  if (isUserManagementEnabled && hasLastUpdateDetails({ mediaItem })) {
    lastUser = MediaItem.getLastUpdateUser({ mediaItem });
    lastEditedtime = getFormattedDateFromUnix({
      timestamp: MediaItem.getLastUpdateUnixTime({ mediaItem }),
      twelveHourFormat,
      textCase: TEXT_CASES.LOWER,
      useLongForm: true,
    });
  }

  let articleClass = '';
  if (isSaving) {
    articleClass = 'saving_locked';
  } else if (errorMessage !== '') {
    articleClass = 'error_validation_box';
  }

  const imageClass = hasImage ? '' : 'no_image';

  const imageOverlaResult = MediaItem.getImageOverlayResult({ mediaItem });
  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;
  const isInstagramGraphStory =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    !isIGCabinetEnabled();

  const isInstantShareNow = shareTime?.type === SHARE_TIME_TYPES.NOW;
  const timingBadgesProps = {
    origin: 'SCHEDULE_QUEUE',
    shareTime,
    postType,
    twelveHourFormat,
    timeSensitivityTypeId,
    suggestionTypeId,
    accountAPIId,
    mediaStatesByAccountAPIId,
    hasImage,
    showPostType: !isTikTok,
    showReshare: true,
    showTimingOption: !isInstantShareNow,
    showTimeSensitivity: true,
    mediaId,
    socialChannel,
  };

  const renderButtons = !isInstantShareNow && (
    <>
      <a
        className="action"
        data-cy-action="scheduleQueueEdit"
        onClick={handleArticleEdit}
      >
        edit
      </a>
      <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
      <a
        className="action"
        data-cy-action="scheduleQueueDelete"
        onClick={
          suggestionTypeId === SUGGESTION_TYPES.AUTO_SOCIAL_SHARE
            ? openSurveyModal
            : handleArticleDelete
        }
      >
        delete
      </a>
    </>
  );

  let backgroundImage;
  let imageThumbnail;
  let videoThumbnail;
  if (imageURLs && imageURLs.length === 0) {
    if (postType === POST_TYPES.VIDEO) {
      videoThumbnail = getNewsFeedVideoThumbnail();
      backgroundImage = videoThumbnail;
    } else {
      imageThumbnail = getImageThumbnail({
        apiTypeId,
        postType,
        isNewsFeed: true,
      });
      backgroundImage = imageThumbnail;
    }
  }

  if (isTikTok) {
    return (
      <Box
        py="2"
        px="2.5"
        borderTop="1px"
        borderColor="#D7DEE4"
        data-tip
        data-for={mediaId}
        data-cy-id="mediaItem"
        data-cy-attribute={`mediaId:${mediaId}`}
      >
        {errorMessage !== '' && <ItemError errorMessage={errorMessage} />}
        <Flex direction="column" flexGrow="1" gap="1">
          <VisuallyHidden data-cy-id="guid">{labels[0]?.tag}</VisuallyHidden>
          <Box
            w="50px"
            h="96px"
            backgroundImage={thumbnail || backgroundImage}
            backgroundSize="cover"
            backgroundPosition="center"
          >
            <Flex h="full" w="full" justifyContent="center" alignItems="center">
              <Image
                borderRadius="full"
                bg="rgba(235, 238, 245, 0.35)"
                p={1}
                h={6}
                w={6}
                src="/img/icons/video-icon.svg"
                alt=""
              />
            </Flex>
          </Box>
          <Box>
            <TimingBadges {...timingBadgesProps} />
          </Box>
          <Box flexGrow="1" wordBreak="break-word">
            {renderMessage(
              replaceShareURL({
                text: previewFields.message,
                shareURL: getShareURLPlaceholder({ mediaItem }),
              }),
              apiTypeId,
              itemTags,
              accountAPIId,
              postType,
              socialChannel,
            )}
          </Box>
          <Flex mt={2} justifyContent="space-between">
            <Box>{renderButtons}</Box>
            {isUserManagementEnabled && hasLastUpdateDetails({ mediaItem }) && (
              <LastEditedUser
                lastUser={lastUser}
                lastEditedTime={lastEditedtime}
                mediaId={mediaId}
              />
            )}
          </Flex>
        </Flex>
        <ShowArticleDeleteSurvey
          isOpen={showSurveyModal}
          onDismiss={closeSurveyModal}
          mediaItem={mediaItem}
        />
      </Box>
    );
  }

  // TODO: Remove this once we have a more consistent design to handle reels and stories
  const isReelsOrStories =
    socialChannel === SOCIAL_CHANNELS.STORY ||
    socialChannel === SOCIAL_CHANNELS.REEL;

  return (
    <div
      className={clsx(
        'schedule',
        articleClass,
        isReelsOrStories && 'reels-stories',
      )}
      data-tip
      data-for={mediaId}
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <div data-cy-attribute="isLoading:false">
        <div className="d-none" data-cy-id="guid">
          {labels[0]?.tag}
        </div>
        <div className={`top ${imageClass}`}>
          {errorMessage !== '' && <ItemError errorMessage={errorMessage} />}
          {hasImage &&
            renderImage(
              apiTypeId,
              postType,
              socialChannel,
              isVideoURL(articleURL),
              imageOverlaResult,
            )}
          <div className={`info ${imageClass}`}>
            <div className="align">
              <TimingBadges {...timingBadgesProps} />
            </div>
            {renderMessage(
              replaceShareURL({
                text: previewFields.message,
                shareURL: getShareURLPlaceholder({ mediaItem }),
              }),
              apiTypeId,
              itemTags,
              accountAPIId,
              postType,
              socialChannel,
            )}
          </div>
        </div>
        <div className="middle">
          {postType === POST_TYPES.LINK &&
            renderURL(articleURL, rssTitle, accountAPIId)}
          {postType === POST_TYPES.PHOTO_STORY && (
            <span className="no_link text-truncate" data-cy-id="title">
              &nbsp;
            </span>
          )}
        </div>
        <div className="bottom d-flex align-items-center">
          {renderButtons}
          {!isNullOrUndefined(abTestStatusId) && (
            <span className="bottom">{abTestStatusMessage}</span>
          )}
          {isUserManagementEnabled && hasLastUpdateDetails({ mediaItem }) && (
            <LastEditedUser
              lastUser={lastUser}
              lastEditedTime={lastEditedtime}
              mediaId={mediaId}
            />
          )}
        </div>
      </div>
      <ShowArticleDeleteSurvey
        isOpen={showSurveyModal}
        onDismiss={closeSurveyModal}
        mediaItem={mediaItem}
      />
    </div>
  );
};

Item.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  accountAPIId: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
    handleArticleLoad: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(Item, arePropsEqual);
