import {
  Box,
  Center,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import { useContext } from 'react';

import GlobalInfoContext from 'context/GlobalInfoContext';

/**
 * React component for rendering Step 1 of MFA
 * Renders a bar code and a text code to be used by user in any authenticator app
 * Accepts the following props:
 * 1) code  - code generated by amplify setupTotp function
 * 2) error - error message to be shown if there is an issue while generating the code
 */
const Step1 = ({ code, error }) => {
  const { global } = useContext(GlobalInfoContext);
  const { emailAddress } = global.getGlobalInfo().user;
  const qrCode = `otpauth://totp/${emailAddress}?secret=${code}&issuer=Echobox`;
  const isLoading = !code && !error;
  return (
    <div>
      <p>
        Scan this QR code with an authenticator app like Google Authenticator.
      </p>
      <p>It can be downloaded from App Store or Play Store.</p>
      <Center h={64} m={5}>
        {code && <QRCodeSVG id="mfa-qr-code" value={qrCode} size={256} />}
        {error && <p>{error}</p>}
        {isLoading && <Spinner size="lg" />}
      </Center>
      <Box minH={16}>
        {code && (
          <>
            <p>Or enter the following code manually:</p>
            <Text
              id="mfa-code"
              fontWeight="medium"
              size="sm"
              wordBreak="break-word"
            >
              {code}
            </Text>
          </>
        )}
      </Box>
    </div>
  );
};

Step1.defaultProps = {
  error: '',
};

Step1.propTypes = {
  code: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default Step1;
