import {
  ArrowRightIcon,
  Banner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link as ReactRouterLink } from 'react-router-dom';

import { useErrorTracking } from './useErrorTracking';

interface GAWithNoTrackerBannerProps {
  onClose: () => void;
}

const GAWithNoTrackerBanner = ({ onClose }: GAWithNoTrackerBannerProps) => {
  const errorMessage = 'Google Analytics 3 has closed down.';

  useErrorTracking({
    errorMessage,
    errorType: 'Google Analytics Connected With No Tracker',
  });

  return (
    <Banner type="error" onClose={onClose}>
      <Banner.Tag>Important</Banner.Tag>
      <Banner.Body>
        <Text size="sm">{errorMessage}</Text>
      </Banner.Body>
      <Banner.Link
        as={ReactRouterLink}
        // @ts-expect-error - Chakra UI's `as` prop doesn't do well with TS
        to="/settings/property"
        icon={<ArrowRightIcon />}
      >
        Maintain your performance
      </Banner.Link>
    </Banner>
  );
};

export default GAWithNoTrackerBanner;
