import { EditorState } from 'draft-js';
import * as React from 'react';
import { Immutable } from 'seamless-immutable';

import { getAPITypeId } from 'common/accountAPIs';
import { ENTITY_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getShareURLPlaceholder } from 'common/shareURL';
import { hasMentionsLookups } from 'common/social';
import striptags from 'common/striptags';
import * as DraftJSTools from 'components/compose/messagebox/DraftJSTools';
import * as MessageBoxTools from 'components/compose/messagebox/MessageBoxTools';
import * as MessageProcessor from 'components/compose/messagebox/MessageProcessor';
import {
  addMessageToHistory,
  ComposeBoxContextInterface,
  insertTagIntoMediaItem,
  updateFirstComment,
  updateSelectedShareContent as updateSelectedShareContentInMediaItem,
} from 'context/ComposeBoxContext';
import type {
  AIMessage,
  FixTypeLater,
  MessageHistory,
  ShareContent,
  Tag,
  TagType,
} from 'types';

import { ACTION_TYPE, ADDING_TAG_STATES } from './constants';
import type { ACTION, MessageBoxStateType } from './types';

function handleAdditionalMenuClose(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.HANDLE_ADDITIONAL_MENU_CLOSE });
}

function handleAdditionalMenuOpen(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.HANDLE_ADDITIONAL_MENU_OPEN });
}

/**
 * Event handler for Editor component
 */
function handleEditorStateChange({
  composeBoxDispatch,
  editorState,
  guid,
  isFirstComment,
  messageBoxDispatch,
}: {
  composeBoxDispatch: ComposeBoxContextInterface['dispatch'];
  editorState: EditorState;
  guid: string;
  isFirstComment: boolean;
  messageBoxDispatch: React.Dispatch<ACTION>;
}) {
  const content = DraftJSTools.getUndecoratedText(editorState);
  if (isFirstComment) {
    composeBoxDispatch(updateFirstComment({ firstComment: content, guid }));
  } else {
    composeBoxDispatch(addMessageToHistory({ content, guid }));
  }
  messageBoxDispatch({
    type: ACTION_TYPE.HANDLE_EDITOR_STATE_CHANGE,
    payload: { editorState },
  });
}

/**
 * Handles the case where Mac OS adds a full stop on pressing double spaces
 */
function handleFullStopAddedOnDoubleSpace(
  dispatch: React.Dispatch<ACTION>,
  input: string,
) {
  dispatch({
    type: ACTION_TYPE.HANDLE_FULL_STOP_ADDED_ON_DOUBLE_SPACE,
    payload: input,
  });
}

function handlePaste({
  accountAPIId,
  composeBoxDispatch,
  clipboardData,
  editorState,
  guid,
  isFirstComment,
  mediaItem,
  messageBoxDispatch,
  text,
  undo,
}: {
  accountAPIId: number;
  composeBoxDispatch: ComposeBoxContextInterface['dispatch'];
  clipboardData: ClipboardEvent['clipboardData'];
  editorState: EditorState;
  guid: string;
  isFirstComment: boolean;
  mediaItem: FixTypeLater;
  messageBoxDispatch: React.Dispatch<ACTION>;
  text: string;
  undo: boolean;
}) {
  const apiTypeId = getAPITypeId({ accountAPIId });

  let newEntities = [];
  if (clipboardData) {
    const rawEntities = clipboardData.getData('text/entities');
    if (rawEntities != null && rawEntities !== '') {
      newEntities = JSON.parse(rawEntities);
    }
    // Convert any Facebook-format mentions
    if (!hasMentionsLookups({ apiTypeId })) {
      newEntities = MessageBoxTools.removeMentions(newEntities);
    }
  }

  const newText = striptags(text);

  // update the content form pasted text. this will do everything we need except handling
  // new line
  let updatedEditorState = DraftJSTools.updateContentState({
    editorState,
    newText,
    newEntities,
  });

  // now we need to make sure we also handle the new lines, we do so by forcing a reset of
  // the editor content
  const shareURL = getShareURLPlaceholder({ mediaItem }) ?? '';
  updatedEditorState = DraftJSTools.setEditorContent({
    editorState: updatedEditorState,
    config: {
      content: DraftJSTools.getUndecoratedText(updatedEditorState) || '',
      apiTypeId,
      replacementFields: MediaItem.getTags({ mediaItem }),
      shareURL,
    },
  });
  if (undo) {
    updatedEditorState = DraftJSTools.undoChange(updatedEditorState);
  }

  const content = DraftJSTools.getUndecoratedText(updatedEditorState);
  if (isFirstComment) {
    composeBoxDispatch(updateFirstComment({ firstComment: content, guid }));
  } else {
    composeBoxDispatch(addMessageToHistory({ content, guid }));
  }
  messageBoxDispatch({
    type: ACTION_TYPE.HANDLE_EDITOR_STATE_CHANGE,
    payload: { editorState: updatedEditorState },
  });
}

function insertEmoji({
  composeBoxDispatch,
  editorState,
  emoticon,
  guid,
  messageBoxDispatch,
}: {
  composeBoxDispatch: ComposeBoxContextInterface['dispatch'];
  editorState: EditorState;
  emoticon: string;
  guid: string;
  messageBoxDispatch: React.Dispatch<ACTION>;
}) {
  const updatedEditorState = DraftJSTools.insertEmoticon({
    editorState,
    emoticon,
  });

  const content = DraftJSTools.getUndecoratedText(updatedEditorState);
  composeBoxDispatch(addMessageToHistory({ content, guid }));
  messageBoxDispatch({
    type: ACTION_TYPE.INSERT_EMOJI,
    payload: { editorState: updatedEditorState },
  });
}

function insertTag({
  accountAPIId,
  appendNewline,
  appendSpace,
  appendText,
  composeBoxDispatch,
  currentUserInputType,
  editorState,
  guid,
  handle,
  isFirstComment,
  messageBoxDispatch,
  tag,
  tagType,
}: {
  accountAPIId: number;
  appendNewline: boolean;
  appendSpace: boolean;
  appendText: string | null;
  composeBoxDispatch: ComposeBoxContextInterface['dispatch'];
  currentUserInputType: MessageBoxStateType;
  editorState: EditorState;
  guid: string;
  handle: string;
  isFirstComment: boolean;
  messageBoxDispatch: React.Dispatch<ACTION>;
  tagType: TagType;
  tag: Tag;
}) {
  let newEditorState = editorState;

  if (ADDING_TAG_STATES.includes(currentUserInputType)) {
    // If this is inline tag want to replace handle text
    const offset = handle.length + 1;
    newEditorState = DraftJSTools.offsetSelection({
      editorState: newEditorState,
      anchorOffset: -offset,
      focusOffset: 0,
    });
  } else {
    // Add a leading space if we are adding a suggested hashtag or mention
    // and the share message isn't empty
    const { before } = DraftJSTools.getTextAtCursor(newEditorState);
    if (before !== '') {
      const isAddWhitespace = DraftJSTools.isAddWhitespace({
        editorState: newEditorState,
        entityType: ENTITY_TYPES.INLINE_TAG,
        ch: before,
        offset: -1,
        addToNonAlphanumeric: true,
      });
      if (isAddWhitespace) {
        newEditorState = DraftJSTools.insertText({
          editorState: newEditorState,
          text: ' ',
        });
      }
    }
  }

  // Add the tag
  const apiTypeId = getAPITypeId({ accountAPIId });
  const decoratedText = MessageProcessor.getDecoratedText({
    tag,
    tagType,
    apiTypeId,
  });
  newEditorState = DraftJSTools.insertInlineTag({
    editorState: newEditorState,
    decoratedContent: decoratedText,
    originalContent: tag.raw,
  });

  // Add specified character after tag
  if (appendText !== null) {
    newEditorState = DraftJSTools.insertText({
      editorState: newEditorState,
      text: appendText,
    });
  }
  if (appendNewline) {
    newEditorState = DraftJSTools.insertText({
      editorState: newEditorState,
      text: '\n',
    });
  }
  if (appendSpace) {
    newEditorState = DraftJSTools.insertText({
      editorState: newEditorState,
      text: ' ',
    });
  }

  const content = DraftJSTools.getUndecoratedText(newEditorState);
  if (isFirstComment) {
    composeBoxDispatch(updateFirstComment({ firstComment: content, guid }));
  } else {
    composeBoxDispatch(addMessageToHistory({ content, guid }));
  }
  composeBoxDispatch(insertTagIntoMediaItem({ guid, tag, tagType }));
  messageBoxDispatch({
    type: ACTION_TYPE.INSERT_TAG,
    payload: { editorState: newEditorState },
  });
}

function resetMessageboxState(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.RESET_MESSAGEBOX_STATE });
}

function startAddingHashtag(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.START_ADDING_HASHTAG });
}

function startAddingMention(
  dispatch: React.Dispatch<ACTION>,
  { didPaste = false }: { didPaste?: boolean } = {},
) {
  dispatch({ type: ACTION_TYPE.START_ADDING_MENTION, payload: { didPaste } });
}

function toggleAddingSponsorMenu(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.TOGGLE_ADDING_SPONSER_MENU });
}

function toggleAddingTagMenu(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.TOGGLE_ADDING_TAG_MENU });
}

function toggleEmojiMenu(dispatch: React.Dispatch<ACTION>) {
  dispatch({ type: ACTION_TYPE.TOGGLE_EMOJI_MENU });
}

function updateHandleHashtag(
  dispatch: React.Dispatch<ACTION>,
  handleHashtag: string,
) {
  dispatch({
    type: ACTION_TYPE.UPDATE_HANDLE_HASHTAG,
    payload: { handleHashtag },
  });
}

function updateHandleMention(
  dispatch: React.Dispatch<ACTION>,
  handleMention: string,
) {
  dispatch({
    type: ACTION_TYPE.UPDATE_HANDLE_MENTION,
    payload: { handleMention },
  });
}

function updateSelectedShareContent({
  accountAPIId,
  aiMessage,
  composeBoxDispatch,
  guid,
  mediaItem,
  messageBoxDispatch,
  messageHistory,
  selectedContentIndex,
  selectedShareContent,
}: {
  accountAPIId: number;
  aiMessage: Immutable<AIMessage>;
  composeBoxDispatch: ComposeBoxContextInterface['dispatch'];
  guid: string;
  mediaItem: FixTypeLater;
  messageBoxDispatch: React.Dispatch<ACTION>;
  messageHistory: MessageHistory;
  selectedContentIndex: number;
  selectedShareContent: ShareContent;
}) {
  messageBoxDispatch({
    type: ACTION_TYPE.UPDATE_SELECTED_SHARE_CONTENT,
    payload: {
      accountAPIId,
      mediaItem,
      selectedShareContent,
    },
  });
  composeBoxDispatch(
    updateSelectedShareContentInMediaItem({
      guid,
      messageHistory,
      selectedContentIndex,
      selectedShareContent,
      aiMessage,
    }),
  );
}

export {
  handleAdditionalMenuClose,
  handleAdditionalMenuOpen,
  handleEditorStateChange,
  handleFullStopAddedOnDoubleSpace,
  handlePaste,
  insertEmoji,
  insertTag,
  resetMessageboxState,
  startAddingHashtag,
  startAddingMention,
  toggleAddingSponsorMenu,
  toggleAddingTagMenu,
  toggleEmojiMenu,
  updateHandleHashtag,
  updateHandleMention,
  updateSelectedShareContent,
};
