/* eslint react-hooks/exhaustive-deps: "off" */

import { useContext, useEffect, useMemo } from 'react';

import GlobalInfoContext from 'context/GlobalInfoContext';
import usePrevious from 'hooks/usePrevious';

const useOnChangeToReady = (callback: () => void) => {
  const context = useContext(GlobalInfoContext);

  const prevState = usePrevious(context.global.globalInfoState);

  const hasChangedToReady = useMemo(
    () =>
      context.global.hasChangedToReady(
        prevState,
        context.global.globalInfoState,
      ),
    [prevState, context.global.globalInfoState],
  );

  useEffect(() => {
    if (hasChangedToReady) {
      callback();
    }
  }, [hasChangedToReady]);
};

export default useOnChangeToReady;
