import {
  Alert,
  Box,
  Flex,
  HStack,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import {
  getAPITypeId,
  getCurrentProperty,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import { ARTICLE_QUOTE_SUPPORTED_LANGUAGES } from 'common/language/data';
import { getFeatureToggle } from 'common/settings';
import {
  getSocialNetworkDescriptionSources,
  getSocialNetworkImageSources,
  getSocialNetworkMessageSources,
  getSocialNetworkTitleSources,
  hasDescriptionField,
  hasMessageField,
  hasTitleField,
} from 'common/social';
import DropDown from 'components/misc/DropDown';
import type { DataSourceSettings } from 'types';

const getArticleQuoteError = (messageSource: string) => {
  const isOpenAIEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.OPEN_AI_MESSAGE_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
  const propertyLanguage = getCurrentProperty().language;

  if (
    !Object.values(ARTICLE_QUOTE_SUPPORTED_LANGUAGES).includes(
      propertyLanguage,
    ) &&
    ((!isOpenAIEnabled && messageSource === 'OPTIMAL_MESSAGE') ||
      messageSource === 'ARTICLE_QUOTE')
  ) {
    return FRONTEND_ERROR_MESSAGES['article-quote-language-not-supported'];
  }

  return '';
};

interface OptionsProps {
  accountAPIId: number;
  isSaving: boolean;
  dataSourceSettings: DataSourceSettings;
  onDropdownChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  overrideMessageSources: Record<string, string> | undefined;
}

const Options = ({
  accountAPIId,
  isSaving,
  dataSourceSettings,
  onDropdownChange,
  overrideMessageSources,
}: OptionsProps) => {
  const apiTypeId = getAPITypeId({ accountAPIId });
  const savingClass = isSaving ? 'saving_locked' : '';

  const errorMessage = getArticleQuoteError(dataSourceSettings.messageSource);

  return (
    <Flex direction="column" gap="2">
      {hasMessageField({ apiTypeId }) && (
        <Box>
          {errorMessage && (
            <Alert variant="warning" mb="2">
              <Alert.Description>{errorMessage}</Alert.Description>
            </Alert>
          )}
          <HStack>
            <Text size="sm" pr="4" width="20">
              Message
            </Text>
            <DropDown
              key="share-preview-message"
              dropDownClass={`btn btn-light dropdown-md ${savingClass}`}
              name="messageSource"
              dataCySelect="messageSource"
              value={dataSourceSettings.messageSource}
              handleDropDownChange={onDropdownChange}
              sources={
                overrideMessageSources ??
                getSocialNetworkMessageSources({ apiTypeId })
              }
              disabled={isSaving}
              style={{ width: '170px' }}
            />
          </HStack>
        </Box>
      )}
      <Box>
        <HStack>
          <Text size="sm" pr="4" width="20">
            Image
          </Text>
          <DropDown
            key="share-preview-message"
            dropDownClass={`btn btn-light dropdown-md ${savingClass}`}
            name="imageSource"
            dataCySelect="imageSource"
            value={dataSourceSettings.imageSource ?? 'NULL'}
            handleDropDownChange={onDropdownChange}
            sources={getSocialNetworkImageSources({ apiTypeId })}
            disabled={isSaving}
            style={{ width: '170px' }}
          />
        </HStack>
      </Box>
      {hasTitleField({ apiTypeId }) && (
        <Box>
          <HStack>
            <Text size="sm" pr="4" width="20">
              Title
            </Text>
            <DropDown
              key="share-preview-message"
              dropDownClass={`btn btn-light dropdown-md ${savingClass}`}
              name="titleSource"
              dataCySelect="titleSource"
              value={dataSourceSettings.titleSource}
              handleDropDownChange={onDropdownChange}
              sources={getSocialNetworkTitleSources({ apiTypeId })}
              disabled={isSaving}
              style={{ width: '170px' }}
            />
          </HStack>
        </Box>
      )}
      {hasDescriptionField({ apiTypeId }) && (
        <Box>
          <HStack>
            <Text size="sm" pr="4" width="20">
              Description
            </Text>
            <DropDown
              key="share-preview-message"
              dropDownClass={`btn btn-light dropdown-md ${savingClass}`}
              name="descriptionSource"
              dataCySelect="descriptionSource"
              value={dataSourceSettings.descriptionSource}
              handleDropDownChange={onDropdownChange}
              sources={getSocialNetworkDescriptionSources({ apiTypeId })}
              disabled={isSaving}
              style={{ width: '170px' }}
            />
          </HStack>
        </Box>
      )}
    </Flex>
  );
};

export default Options;
