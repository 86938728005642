import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ChangeEvent, useState } from 'react';

import { getAPIsByStateAndType } from 'common/accountAPIs';
import { API_STATES } from 'common/constants';
import { formatLikesFollowers } from 'common/string';
import { cloneArray } from 'common/utility';
import { useGlobalInfo } from 'context/GlobalInfoContext';

interface SocialAPI {
  accountAPIId: number;
  apiPostImage: string;
  apiPostName: string;
  numberFollowers: number;
  apiType: string;
}

interface PageSelectorProps {
  /**
   * The user email to show in the modal header.
   */
  userEmail: string | undefined;

  /**
   * An array of initially selected account APIs.
   * If undefined, all available APIs will be selected.
   */
  initialSelectedAPIs: number[] | undefined;

  /**
   * The label to render on the save button.
   */
  saveLabel?: string;

  /**
   * The label to render on the cancel button.
   */
  cancelLabel?: string;

  /**
   * The callback for when the modal is closed.
   */
  onClose: () => void;

  /**
   * The callback for when cancel is clicked.
   */
  onCancel: () => void;

  /**
   * The callback for when the save button is clicked. The selected account APIs
   * are passed as an argument.
   */
  onSave: (selectedAPIs: number[]) => Promise<void>;

  /**
   * The network error to show within the modal.
   */
  networkError?: string | null;
}

const PageSelector = ({
  userEmail,
  onClose,
  onCancel,
  initialSelectedAPIs,
  onSave,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  networkError,
}: PageSelectorProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const availableAPIs: SocialAPI[] = getAPIsByStateAndType({
    propertyId: globalInfo.current.propertyId,
    apiStateId: [
      API_STATES.ACTIVE,
      API_STATES.BAD_AUTH,
      API_STATES.UNDER_SETUP,
    ],
    globalInfo,
  });

  const [selectedAPIs, setSelectedAPIs] = useState<number[]>(
    initialSelectedAPIs ?? availableAPIs.map((x) => x.accountAPIId),
  );
  const [isSaving, setIsSaving] = useState(false);

  const twitterAPIs = availableAPIs.filter((api) => api.apiType === 'TWITTER');
  const facebookAPIs = availableAPIs.filter(
    (api) => api.apiType === 'FACEBOOK',
  );
  const linkedinAPIs = availableAPIs.filter(
    (api) => api.apiType === 'LINKEDIN',
  );
  const instagramAPIs = availableAPIs.filter(
    (api) => api.apiType === 'INSTAGRAM',
  );

  const numberOfConnectedPagesMessage =
    selectedAPIs.length > 0 && availableAPIs.length > 0
      ? `${selectedAPIs.length} out of ${availableAPIs.length} selected`
      : '0 selected';

  const handlePageToggle = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const apiId = Number(event.target.value);
    setSelectedAPIs((oldSelectedAPIs) => {
      const newSelectedAPIs = cloneArray(oldSelectedAPIs);
      const position = selectedAPIs.indexOf(apiId);
      if (position !== -1) {
        // Remove
        newSelectedAPIs.splice(position, 1);
      } else {
        // Add
        newSelectedAPIs.push(apiId);
      }
      return newSelectedAPIs;
    });
  };

  const renderPages = (socialAPIs: SocialAPI[], headingTitle: string) => {
    return (
      <Flex flexDir="column">
        <Heading variant="h5" mb={3}>
          {headingTitle}
        </Heading>
        <Flex flexDir="column" gap={4}>
          {socialAPIs.map((api) => {
            const showNumberOfFollowers =
              formatLikesFollowers(api.numberFollowers) !== null;
            return (
              <Checkbox
                key={api.accountAPIId}
                isChecked={
                  selectedAPIs.indexOf(Number(api.accountAPIId)) !== -1
                }
                value={api.accountAPIId}
                onChange={handlePageToggle}
              >
                <Flex alignItems="center">
                  <Image
                    mr={2}
                    h={5}
                    bgColor="white"
                    borderRadius="50%"
                    border="1px"
                    src={api.apiPostImage}
                  />

                  {api.apiPostName}
                  {showNumberOfFollowers && (
                    <Text size="sm" ml={2} color="gray.600">
                      {formatLikesFollowers(api.numberFollowers)} followers
                    </Text>
                  )}
                </Flex>
              </Checkbox>
            );
          })}
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Select pages</Modal.Title>
        {userEmail && <Modal.Subtitle>{userEmail}</Modal.Subtitle>}
      </Modal.Header>
      <Modal.Body>
        <Flex flexDir="column" gap={8}>
          {facebookAPIs.length > 0 && renderPages(facebookAPIs, 'Facebook')}
          {twitterAPIs.length > 0 && renderPages(twitterAPIs, 'Twitter')}
          {linkedinAPIs.length > 0 && renderPages(linkedinAPIs, 'LinkedIn')}
          {instagramAPIs.length > 0 && renderPages(instagramAPIs, 'Instagram')}
        </Flex>
        {networkError && (
          <Text color="red.600" size="sm" mt={3}>
            {networkError}
          </Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Box mr="auto">
          <Text size="sm" color="gray.600" alignSelf="flex-end" px={3}>
            {numberOfConnectedPagesMessage}
          </Text>
        </Box>
        <Button variant="secondary" onClick={onCancel}>
          {cancelLabel}
        </Button>
        <Button
          loadingText="Saving"
          isDisabled={selectedAPIs.length === 0 || isSaving}
          isLoading={isSaving}
          onClick={async () => {
            setIsSaving(true);
            await onSave(selectedAPIs);
            setIsSaving(false);
          }}
        >
          {saveLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PageSelector;
