import { useState } from 'react';

import { getAPITypeId } from 'common/accountAPIs';
import { POST_TYPES, REACT_PREVENT_RENDER } from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import {
  hasDescriptionField,
  hasDomainField,
  hasTitleField,
  hasTitleFieldCharacterCount,
} from 'common/social';
import Description from 'components/compose/Description';
import Domain from 'components/compose/Domain';
import Title from 'components/compose/Title';
import type { FixTypeLater } from 'types';

interface DomainTitleDescriptionProps {
  guid: string;
  mediaItem: FixTypeLater;
  accountAPIId: number;
  canCustomiseLinkPosts: boolean;
  description: string;
  isRefreshPreview: boolean;
  isVideoLink: boolean;
  hasImageOverlay?: boolean;
  title: string;
  onDescriptionChange: (newDescription: string) => void;
  onTitleChange: (newTitle: string) => void;
}

/**
 * Domain title and description
 */

const DomainTitleDescription = ({
  accountAPIId,
  canCustomiseLinkPosts,
  description,
  guid,
  isRefreshPreview,
  isVideoLink,
  mediaItem,
  onDescriptionChange,
  onTitleChange,
  title,
  hasImageOverlay = false,
}: DomainTitleDescriptionProps) => {
  const [showDescription, setShowDescription] = useState(true);

  const apiTypeId = getAPITypeId({ accountAPIId });
  const postType = MediaItem.getPostType({ mediaItem });
  const unshortenedShareURL = MediaItem.getUnshortenedShareURL({ mediaItem });

  if (
    postType !== POST_TYPES.LINK ||
    (!hasDomainField({ apiTypeId }) &&
      !hasTitleField({ apiTypeId, postType }) &&
      !hasDescriptionField({ apiTypeId }))
  ) {
    return REACT_PREVENT_RENDER;
  }

  const handleShowDescription = (value: boolean) => {
    logger.info(`DomainTitleDescription:handleShowDescription ${value}`);

    setShowDescription(value);
  };

  return (
    <div className="share-preview" style={{ backgroundColor: '#f2f3f5' }}>
      {hasDomainField({ apiTypeId }) && (
        <Domain
          hasTitleFieldCharacterCount={hasTitleFieldCharacterCount({
            apiTypeId,
          })}
          title={title}
          url={unshortenedShareURL}
        />
      )}
      {hasTitleField({ apiTypeId, postType }) && (
        <Title
          accountAPIId={accountAPIId}
          canCustomiseLinkPosts={canCustomiseLinkPosts}
          isRefreshPreview={isRefreshPreview}
          isVideoLink={isVideoLink}
          hasImageOverlay={hasImageOverlay}
          title={title}
          mediaItem={mediaItem}
          onShowDescription={handleShowDescription}
          onTitleChange={onTitleChange}
        />
      )}
      {hasDescriptionField({ apiTypeId }) && (
        <Description
          guid={guid}
          accountAPIId={accountAPIId}
          canCustomiseLinkPosts={canCustomiseLinkPosts}
          description={description}
          isRefreshPreview={isRefreshPreview}
          isVideoLink={isVideoLink}
          hasImageOverlay={hasImageOverlay}
          showDescription={showDescription}
          onDescriptionChange={onDescriptionChange}
        />
      )}
    </div>
  );
};

export default DomainTitleDescription;
