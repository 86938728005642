import {
  Alert,
  Flex,
  useRadioGroup,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { AUTOFEED_SHARE_MODES } from 'common/constants';
import SettingsRadioCard from 'components/misc/SettingsRadioCard';
import CustomSettings from 'components/settings/automation/reshares/CustomSettings';
import { ReshareSettings } from 'components/settings/automation/reshares/Reshares';
import { AutofeedShareMode } from 'types';

interface ReshareModesProps {
  currentReshareSettings: ReshareSettings;
  handleRehareSettingsChange: (
    settings: ReshareSettings,
    error?: boolean,
  ) => void;
}

function ReshareModes({
  currentReshareSettings,
  handleRehareSettingsChange,
}: ReshareModesProps) {
  const handleNewShareMode = (shareMode: AutofeedShareMode) => {
    const newSettings = {
      ...currentReshareSettings,
      reshareMode: shareMode,
    };
    handleRehareSettingsChange(newSettings);
  };

  const shareModes = {
    [AUTOFEED_SHARE_MODES.OPTIMAL]: [
      'Recommended for supplementing new shares and maximizing traffic',
      'Automatically reshare content with the highest traffic potential',
      'Automatically reshare the right number of articles to maximize traffic',
      'Automatically reshare content at the optimal time to maximize traffic',
    ],
    [AUTOFEED_SHARE_MODES.CUSTOM]: [
      'Recommended for extra control and pages that manually reshare',
      'Set a daily target amount of reshares',
      'Configure which articles should be reshared',
      'Timing optimization applied to all reshares',
    ],
    [AUTOFEED_SHARE_MODES.OFF]: [],
  };

  /* jscpd:ignore-start */
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'new-shares-settings',
    defaultValue: currentReshareSettings.reshareMode,
    onChange: handleNewShareMode,
  });

  const group = getRootProps();

  return (
    <Flex flexDir="column" gap={2} mb="48px" {...group}>
      <SettingsRadioCard
        key={AUTOFEED_SHARE_MODES.OPTIMAL}
        settingDescriptions={shareModes[AUTOFEED_SHARE_MODES.OPTIMAL]}
        value={AUTOFEED_SHARE_MODES.OPTIMAL}
        {...getRadioProps({ value: AUTOFEED_SHARE_MODES.OPTIMAL })}
      >
        <Alert variant="info">
          <Alert.Description>
            Setting both New Shares and Reshares to Optimal may prioritise
            resharing articles to maximize performance
          </Alert.Description>
        </Alert>
      </SettingsRadioCard>
      <SettingsRadioCard
        key={AUTOFEED_SHARE_MODES.CUSTOM}
        settingDescriptions={shareModes[AUTOFEED_SHARE_MODES.CUSTOM]}
        value={AUTOFEED_SHARE_MODES.CUSTOM}
        {...getRadioProps({ value: AUTOFEED_SHARE_MODES.CUSTOM })}
      >
        <CustomSettings
          currentReshareSettings={currentReshareSettings}
          handleRehareSettingsChange={handleRehareSettingsChange}
        />
      </SettingsRadioCard>
      <SettingsRadioCard
        key={AUTOFEED_SHARE_MODES.OFF}
        settingDescriptions={shareModes[AUTOFEED_SHARE_MODES.OFF]}
        value={AUTOFEED_SHARE_MODES.OFF}
        {...getRadioProps({ value: AUTOFEED_SHARE_MODES.OFF })}
        hideIcon
      />
    </Flex>
  );
}
/* jscpd:ignore-end */

export default ReshareModes;
