import { createContext, useContext } from 'react';

import type { FlashMessage, FlashMessageType } from 'types';

export interface FlashMessagesContextInterface {
  flashMessages: {
    addMessage: (message: Omit<FlashMessage, 'id'>) => string;
    deleteMessage: (args: { id: string; markAsBlocked?: boolean }) => void;
    deleteMessageType: (messageType: FlashMessageType) => void;
    deleteMessages: () => void;
    messages: Array<FlashMessage>;
    removeMessagesOnReload: () => void;
  };
}

const FlashMessagesContext =
  createContext<FlashMessagesContextInterface | null>(null);

export const useFlashMessagesContext = () => {
  const context = useContext(FlashMessagesContext);

  if (context === undefined) {
    throw new Error(
      'useFlashMessagesContext must be used within a FlashMessages Provider',
    );
  }

  if (context === null) {
    throw new Error(
      'useFlashMessagesContext must be initialized by a FlashMessages Provider',
    );
  }

  return context;
};

export default FlashMessagesContext;
