import { Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import { sortUsers } from 'common/misc';
import { addSuccessNotification } from 'common/notifications';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import type { FixTypeLater } from 'types';

import AddUserModal from './AddUserModal';
import TeamTable from './TeamTable';

interface UserManagementProps {
  users: FixTypeLater[];
  onUsersChange: (updatedUsersList: FixTypeLater[]) => void;
  showRoleSettings?: boolean;
}

const UserManagement = ({
  users,
  onUsersChange,
  showRoleSettings = false,
}: UserManagementProps) => {
  const { global } = useGlobalInfo();

  const [showUserModal, setShowUserModal] = useState(false);
  const sortedPropertyUsers = sortUsers(users, true);

  return (
    <>
      <Button mb="4" onClick={() => setShowUserModal(true)} variant="secondary">
        Add user
      </Button>
      <TeamTable
        propertyUsers={sortedPropertyUsers}
        setPropertyUsers={onUsersChange}
        showRoleSettings={showRoleSettings}
      />
      {showUserModal && (
        <AddUserModal
          showRoleSettings={showRoleSettings}
          onClose={() => setShowUserModal(false)}
          onUserAdd={() => {
            const newGlobalInfo = global.getGlobalInfo();
            onUsersChange(
              sortUsers(getCurrentProperty(newGlobalInfo).propertyUsers),
            );
            addSuccessNotification('User added');
          }}
        />
      )}
    </>
  );
};

export default UserManagement;
