/* eslint func-names:"off" */
/* eslint no-restricted-globals:"off" */

/* global mixpanel */

import { compare } from 'compare-versions';
import $ from 'jquery';
import { Children, cloneElement } from 'react';

import * as API from 'api/api';
import { getUserDetails } from 'api/common';
import getVersion from 'api/getVersion';
import {
  getAccountAPIPermission,
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
  getCurrentProperty,
  getCurrentPropertyId,
  isCurrentPropertySuspended,
  raiseAPIErrors,
} from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import {
  REFRESH_INTERVALS,
  REFRESH_MULTIPLIERS,
  REVERT_GRACE_PERIOD_SECONDS,
} from 'common/config';
import {
  EBX_LAST_VISITED_PROPERTY_URN_KEY,
  EBX_LAST_VISITED_SOCIAL_PAGES_KEY,
  FLASH_MESSAGE_TYPES,
  FRONTEND_METRICS,
  FRONTEND_PAGES,
  GLOBAL_INFO_STATES,
  PERMISSION_TYPES,
  ACCOUNT_STATES as PROPERTY_STATES,
  TRACKER_KEY,
  USER_TYPES,
} from 'common/constants';
import { getCurrentPropertyState } from 'common/currentPropertyAndAPI';
import { getUnixTimestamp } from 'common/datetime';
import { determineError, getErrorMessage } from 'common/errorHandling';
import { getGlobalInfo, storeGlobalInfo } from 'common/globalInfo';
import { resetTextDirection } from 'common/language';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { refreshInterval } from 'common/misc';
import { addSuccessNotification } from 'common/notifications';
import * as object from 'common/object';
import { getPage, isOnPage } from 'common/path';
import { withLocation, withNavigate } from 'common/routing';
import { canAccessPage } from 'common/social';
import { generateGuid } from 'common/string';
import { hashEmailAddress } from 'common/tracker';
import { convertToPropertyURN } from 'common/urn';
import {
  cloneObject,
  isDefined,
  isEmpty,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import { location } from 'common/window';
import BaseComponent from 'components/BaseComponent';
import BeamerWidget from 'components/misc/BeamerWidget';
import NotificationSystem from 'components/misc/NotificationSystem';
import WootricWidget from 'components/misc/WootricWidget';
import ZendeskWidget from 'components/misc/ZendeskWidget';
import GlobalInfoContext from 'context/GlobalInfoContext';
import withFlashMessages from 'context/withFlashMessages';
import {
  hasChangedLoading,
  hasChangedRefreshing,
  hasChangedSelecting,
  hasChangedState,
  hasChangedToReady,
  hasChangedUpdating,
} from 'helpers/echobox';
import populateGlobalInfo from 'process/populateGlobalInfo';
import { useHighPriorityErrorTypeActions } from 'state/highPriorityErrorType';

/*
 * Echobox V3 dashboard container
 */

class Echobox extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      globalInfo: {},
      globalInfoState: GLOBAL_INFO_STATES.LOADING,
      showMobileMenu: false,
      isInitialisingHighPriorityErrorState: true,
    };
    this._bind(
      'getGlobalInfo',
      'handleAccountChange',
      'handleMobileMenuClose',
      'handleMobileMenuToggle',
      'handlePropertyChange',
      'isLoading',
      'isRefreshing',
      'isSelecting',
      'isUpdating',
      'refreshGlobalInfo',
      'reloadIfVersionChanged',
      'setGlobalInfo',
      'shouldReload',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    logger.info('Echobox:componentDidMount');

    if (isUndefined(window.intervals)) {
      window.intervals = {};
    }

    // Auto refresh periodically if the version does not match
    window.intervals.checkVersion = window.setInterval(
      this.reloadIfVersionChanged.bind(this),
      refreshInterval(REFRESH_MULTIPLIERS.VERSION_CHANGE),
    );

    logger.info('Echobox:componentDidMount - initialising component');
    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Echobox', {
      nextProps,
      nextState,
    });
  }

  componentWillUnmount() {
    logger.info('Echobox:componentWillUnmount');

    // Remove periodic checks
    window.clearInterval(window.intervals.checkVersion);
    window.clearInterval(window.intervals.refreshGlobalInfo);
    window.clearInterval(window.intervals.headerResize);
    window.clearInterval(window.intervals.safariHacks);
    window.intervals.checkVersion = null;
    window.intervals.refreshGlobalInfo = null;
    window.intervals.headerResize = null;
    window.intervals.safariHacks = null;
  }

  initaliseStorage() {
    if (localStorage.getItem(EBX_LAST_VISITED_SOCIAL_PAGES_KEY) == null) {
      const globalInfo = getGlobalInfo();
      const allProperties = globalInfo.properties;
      const propertyToAPIMap = {};

      for (const propertyId of Object.keys(allProperties)) {
        propertyToAPIMap[propertyId] = 0;
        propertyToAPIMap[getCurrentPropertyId()] = getCurrentAccountAPIId();
      }

      const propertyToAPIMapString = JSON.stringify(propertyToAPIMap);
      localStorage.setItem(
        EBX_LAST_VISITED_SOCIAL_PAGES_KEY,
        propertyToAPIMapString,
      );
      localStorage.setItem(
        EBX_LAST_VISITED_PROPERTY_URN_KEY,
        convertToPropertyURN(getCurrentPropertyId()),
      );
    }
  }

  addPropertyToStorage(propertyId) {
    localStorage.setItem(
      EBX_LAST_VISITED_PROPERTY_URN_KEY,
      convertToPropertyURN(propertyId),
    );
  }

  addPageToStorage(propertyId) {
    if (!authentication.isImpersonating()) {
      const lastPagePropertyKey = localStorage.getItem(
        EBX_LAST_VISITED_SOCIAL_PAGES_KEY,
      );
      const lastPagePropertyKeyJSON = JSON.parse(lastPagePropertyKey);
      lastPagePropertyKeyJSON[propertyId] = getCurrentAccountAPIId();
      localStorage.setItem(
        EBX_LAST_VISITED_SOCIAL_PAGES_KEY,
        JSON.stringify(lastPagePropertyKeyJSON),
      );
    }
  }

  /**
   * Event handlers
   */

  handleAccountChange(accountAPIId) {
    logger.info(`Echobox:handleAccountChange ${accountAPIId}`);

    // Reset text direction
    if (isDefined(sessionStorage)) {
      resetTextDirection();
    }

    // Check that current property and account are actually defined
    if (isNullOrUndefined(this.state.globalInfo.current)) {
      logger.error({
        event: 'Account Change Error',
        properties: {
          AccountAPIId: accountAPIId,
          State: this.state.globalInfoState,
          User: JSON.stringify(this.state.globalInfo.user),
        },
      });
      return;
    }

    // Update global info state
    this.setState(
      { globalInfoState: GLOBAL_INFO_STATES.SELECTING_PAGE },
      () => {
        this.setState(
          (prevState) => {
            const nextState = cloneObject(prevState);
            // Close mobile menu
            nextState.showMobileMenu = false;
            // Update currently-selected page
            nextState.globalInfo.current.accountAPIId = accountAPIId;
            // Update global info state
            nextState.globalInfoState = GLOBAL_INFO_STATES.READY;
            // Update global info
            storeGlobalInfo(nextState.globalInfo);
            return nextState;
          },
          () => {
            this.addPageToStorage(getCurrentPropertyId());
            // Reload page if required
            if (
              location.pathname === '/select' ||
              location.pathname === '/apiConnectError' ||
              this.returnHomeOnAccountChange() ||
              !this.isAllowedOnPage()
            ) {
              this.props.navigate('/share');
            }

            // Reset errors for the current page
            raiseAPIErrors({
              property: getCurrentProperty({
                globalInfo: this.state.globalInfo,
              }),
            });
          },
        );
      },
    );
  }

  handleMobileMenuClose() {
    logger.info('Echobox:handleMobileMenuClose');

    this.setState({
      showMobileMenu: false,
    });
  }

  handleMobileMenuToggle() {
    logger.info('Echobox:handleMobileMenuToggle');

    this.setState((prevState) => ({
      showMobileMenu: !prevState.showMobileMenu,
    }));
  }

  handlePropertyChange(propertyId) {
    logger.info(`Echobox:handlePropertyChange ${propertyId}`);

    metrics.mark(FRONTEND_METRICS.CHANGE_PROPERTY);

    // Reset text direction
    if (isDefined(sessionStorage)) {
      resetTextDirection();
    }

    // Close mobile menu
    this.setState({
      showMobileMenu: false,
    });

    this.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.SELECTING_PROPERTY,
      newPropertyId: propertyId,
      allowUnderSetup: true,
      callback: () => {
        // Reload page
        if (window) {
          const globalInfo = this.getGlobalInfo();
          if (isNull(globalInfo.current.accountAPIId)) {
            this.props.navigate('/setup');
          } else if (
            this.returnHomeOnPropertyChange() ||
            !this.isAllowedOnPage() ||
            isOnPage({ page: FRONTEND_PAGES.SETUP, location })
          ) {
            this.props.navigate('/share');
          }
        }
      },
    });
  }

  /**
   * Helper methods
   */

  getAccountAPIId() {
    const path = this.props.location.pathname.split('/');
    let currentAccountAPIId;
    if (path.length === 3 && path[1] === 'share' && !isNaN(Number(path[2]))) {
      const accountAPIId = Number(path[2]);
      const globalInfo = this.state.globalInfo;
      if (isDefined(globalInfo.current)) {
        globalInfo.current.accountAPIId = accountAPIId;
        this.setGlobalInfo(globalInfo);
        window.history.pushState({}, '', '/'); // Update URL without navigating
        currentAccountAPIId = accountAPIId;
      }
    }
    if (isUndefined(currentAccountAPIId)) {
      currentAccountAPIId = getCurrentAccountAPIId();
    }
    return currentAccountAPIId;
  }

  getGlobalInfo() {
    return this.state.globalInfo;
  }

  async initialiseComponent() {
    // Remove any dismissed or unimportant error messages
    this.props.flashMessages.removeMessagesOnReload();

    // Check if global info already exists in local storage
    const globalInfo = getGlobalInfo();
    if (!isNull(globalInfo)) {
      this.setState(
        {
          globalInfo,
          globalInfoState: GLOBAL_INFO_STATES.READY,
        },
        () => {
          const propertyId = getCurrentPropertyId({
            globalInfo,
          });
          raiseAPIErrors({
            property: getCurrentProperty({
              globalInfo,
            }),
          });
          this.props.initialiseHighPriorityErrorState(propertyId);
          this.setState({
            isInitialisingHighPriorityErrorState: false,
          });
        },
      );
    } else {
      // Load global info
      this.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.LOADING,
        allowUnderSetup: true,
      });
    }

    // Set email hash to be used by mixpanel tracking
    if (globalInfo?.user?.emailAddress) {
      try {
        const emailHash = await hashEmailAddress(globalInfo.user.emailAddress);
        globalInfo.user.emailHash = emailHash;
        this.setGlobalInfo(globalInfo);
      } catch (error) {
        logger.error({
          event: 'Email hash Error',
          error,
        });
      }
    }

    if (isUndefined(window.intervals)) {
      window.intervals = {};
    }

    // CSS hacks for Safari
    if (window.intervals?.safariHacks) {
      window.clearInterval(window.intervals.safariHacks);
      delete window.intervals.safariHacks;
    }
    window.intervals.safariHacks = window.setInterval(() => {
      if (typeof $ !== 'undefined') {
        const userAgent = navigator.userAgent;
        const isSafari =
          userAgent.indexOf('Safari') !== -1 &&
          userAgent.indexOf('Chrome') === -1 &&
          userAgent.indexOf('Android') === -1;
        if (isSafari) {
          $('.sidebar .sidebar_pages .sidebar_pages_list .social_page').css(
            'line-height',
            '2.3',
          );
          $(
            '.sidebar .sidebar_pages .sidebar_pages_list .social_page .social_page_title',
          ).css('top', '2px');
        }
      }
    }, REFRESH_INTERVALS.SAFARI_SIDEBAR);

    window.addEventListener(
      'beforeunload',
      this.componentWillUnmount.bind(this),
    );

    this.initaliseStorage();
  }

  isAllowedOnPage() {
    return canAccessPage({
      apiTypeId: getCurrentAPITypeId(),
      page: getPage({ location: this.props.location }),
    });
  }

  isLoading(state = this.state.globalInfoState) {
    return state === GLOBAL_INFO_STATES.LOADING;
  }

  isReady(state = this.state.globalInfoState) {
    return state === GLOBAL_INFO_STATES.READY;
  }

  isRefreshing(state = this.state.globalInfoState) {
    return state === GLOBAL_INFO_STATES.REFRESHING;
  }

  isSelecting(state = this.state.globalInfoState) {
    return (
      state === GLOBAL_INFO_STATES.SELECTING_PROPERTY ||
      state === GLOBAL_INFO_STATES.SELECTING_PAGE
    );
  }

  isUpdating(state = this.state.globalInfoState) {
    return state === GLOBAL_INFO_STATES.UPDATING_SETTINGS;
  }

  logVersionCheck({ packageVersion = mandatory('packageVersion') } = {}) {
    if (
      !isNullOrUndefined(this.state.globalInfo) &&
      isDefined(this.state.globalInfo.user)
    ) {
      logger.track({
        event: 'Version Check',
        properties: {
          Package: packageVersion,
          Name: this.state.globalInfo.user.name,
          EmailAddress: this.state.globalInfo.user.emailAddress,
        },
      });
    }
  }

  refreshGlobalInfo({
    reasonCode = mandatory('reasonCode'),
    newPropertyId,
    newAccountAPIId,
    allowUnderSetup = false,
    successMessage,
    reloadUser = false,
    callback,
  } = {}) {
    if (reasonCode === GLOBAL_INFO_STATES.SELECTING_PROPERTY && newPropertyId) {
      this.addPropertyToStorage(newPropertyId);
    }

    const refreshToken = generateGuid();
    this.cancellationToken = refreshToken;
    const currentPropertyId = getCurrentPropertyId();

    let propertyId;
    let accountAPIId;
    if (isDefined(newPropertyId)) {
      propertyId = newPropertyId;
      accountAPIId = null;
    }
    if (isDefined(newAccountAPIId)) {
      accountAPIId = newAccountAPIId;
    }
    if (isUndefined(propertyId)) {
      propertyId = currentPropertyId;
    }
    if (isUndefined(accountAPIId)) {
      accountAPIId = this.getAccountAPIId();
    }

    logger.info(
      `Echobox:refreshGlobalInfo - reasonCode ${reasonCode} current property ${propertyId} api ${accountAPIId}`,
    );

    let globalInfo = JSON.parse(JSON.stringify(this.state.globalInfo));
    if (isNull(globalInfo) || isEmpty(globalInfo)) {
      globalInfo = getGlobalInfo();
    }

    if (!isNull(globalInfo)) {
      this.setState(
        {
          globalInfoState: reasonCode,
        },
        () => {
          const isSuspended = isCurrentPropertySuspended({
            globalInfo,
          });

          getUserDetails(globalInfo, reloadUser)
            .then((rsp) => {
              return populateGlobalInfo({
                user: {
                  ...rsp,
                  propertyId,
                  accountAPIId,
                },
                allowUnderSetup,
              });
            })
            .then(async (response) => {
              // If a new refresh token has been issued, cancel this update.
              if (this.cancellationToken !== refreshToken) {
                return;
              }
              if (reasonCode === GLOBAL_INFO_STATES.SELECTING_PROPERTY) {
                metrics.measure(FRONTEND_METRICS.CHANGE_PROPERTY);
              }
              globalInfo = {
                properties: response.properties,
                current: response.current,
                user: response.user,
              };
              const newPropertyState = getCurrentPropertyState({
                globalInfo,
              });
              if (
                isDefined(window.globalInfo) &&
                isDefined(window.globalInfo.current) &&
                !isNull(window.globalInfo.current.accountAPIId) &&
                window.globalInfo.current.propertyId ===
                  globalInfo.current.propertyId &&
                window.globalInfo.current.accountAPIId !==
                  globalInfo.current.accountAPIId &&
                newPropertyState !== PROPERTY_STATES.NEWSIGNUP &&
                newPropertyState !== PROPERTY_STATES.UNDERSETUP
              ) {
                logger.info(
                  `Echobox:refreshGlobalInfo - accountAPIId reset from ${window.globalInfo.current.accountAPIId} to ${globalInfo.current.accountAPIId}`,
                );
                globalInfo.current.accountAPIId =
                  window.globalInfo.current.accountAPIId;
              }
              storeGlobalInfo(globalInfo);
              if (reasonCode === GLOBAL_INFO_STATES.SELECTING_PROPERTY) {
                this.setState({
                  isInitialisingHighPriorityErrorState: true,
                });
                this.props.initialiseHighPriorityErrorState(propertyId);
                this.setState({
                  isInitialisingHighPriorityErrorState: false,
                });
                const currentProperty = globalInfo.properties[propertyId];
                // get tracker status
                try {
                  const { isInstalled } = await API.getTrackerStatus({
                    url: currentProperty.propertyRootURL,
                  });
                  localStorage.setItem(TRACKER_KEY, isInstalled);
                } catch (error) {
                  logger.error({
                    event: 'TRACKER_API_ERROR',
                    error: object.stringifyKeys(error),
                  });
                }
              }

              this.setState(
                {
                  globalInfo,
                  globalInfoState: GLOBAL_INFO_STATES.READY,
                },
                () => {
                  raiseAPIErrors({
                    property: getCurrentProperty({
                      globalInfo,
                    }),
                  });
                  // Reload page if suspension state has changed for the current property
                  if (
                    currentPropertyId === propertyId &&
                    isSuspended !==
                      isCurrentPropertySuspended({
                        globalInfo,
                      })
                  ) {
                    logger.info(
                      'Echobox:refreshGlobalInfo - reloading due to suspension state change',
                    );
                    location.reload(true);
                  }
                  // Display success notification if required
                  if (isDefined(successMessage)) {
                    addSuccessNotification(successMessage);
                  }
                  // Execute callback if required
                  if (isDefined(callback) && typeof callback === 'function') {
                    callback();
                  }
                },
              );
            })
            .catch((error) => {
              this.setState({
                globalInfoState: GLOBAL_INFO_STATES.ERROR,
              });
              this.props.flashMessages.addMessage({
                messageCategory: 'Failed to refresh global info',
                type: FLASH_MESSAGE_TYPES.ERROR,
                text: getErrorMessage(determineError(error)),
              });
            });
        },
      );
    }
  }

  async reloadIfVersionChanged() {
    if (location.pathname === '/setup') {
      return; // Don't risk reloading while clients are in the middle of setup
    }
    const currentVersion = APP_VERSION;
    try {
      const packageVersion = await getVersion();
      if (
        this.shouldReload({
          currentVersion,
          packageVersion,
        })
      ) {
        // Update current version and force refresh
        Echobox.reloadVersion({ packageVersion });
      }
    } catch (error) {
      logger.error({
        event: 'Version Check Error',
        error,
      });
    }
  }

  shouldReload({
    // Should reload if package version is newer OR if package
    // version is older but revert grace period has elapsed
    currentVersion = mandatory('currentVersion'),
    packageVersion = mandatory('packageVersion'),
  } = {}) {
    const latestPackageVersion = window.sessionStorage.getItem(
      'latestPackageVersion',
    );
    // latestPackageVersion and latestPackageVersionSet required to determine
    // whether reload necessary for version downgrade (revert)
    if (!latestPackageVersion || packageVersion !== latestPackageVersion) {
      window.sessionStorage.setItem('latestPackageVersion', packageVersion);
      window.sessionStorage.setItem(
        'latestPackageVersionSet',
        getUnixTimestamp(),
      );
    }
    this.logVersionCheck({ packageVersion });
    const isPackageNewer = compare(packageVersion, currentVersion, '>');
    const isPackageOlder = compare(packageVersion, currentVersion, '<');
    const latestPackageVersionSet = parseInt(
      window.sessionStorage.getItem('latestPackageVersionSet'),
      10,
    );
    const isRevertGracePeriodElapsed =
      getUnixTimestamp() >
      latestPackageVersionSet + REVERT_GRACE_PERIOD_SECONDS;
    if (isPackageNewer || (isPackageOlder && isRevertGracePeriodElapsed)) {
      return true;
    }
    return false;
  }

  static reloadVersion({ packageVersion = mandatory('packageVersion') } = {}) {
    // Update version
    const currentVersion = window.sessionStorage.getItem('currentVersion');
    logger.info(`Version changed from ${currentVersion} to ${packageVersion}`);
    window.sessionStorage.setItem('currentVersion', packageVersion);
    // Clear all intervals
    if (isDefined(window.intervals)) {
      Object.keys(window.intervals).forEach((interval) => {
        if (
          typeof window.intervals[interval] === 'object' &&
          !isNull(window.intervals[interval])
        ) {
          Object.keys(window.intervals[interval]).forEach((subkey) => {
            window.clearInterval(window.intervals[interval][subkey]);
            delete window.intervals[interval][subkey];
          });
          delete window.intervals[interval];
        } else {
          window.clearInterval(window.intervals[interval]);
          delete window.intervals[interval];
        }
      });
    }
    // Reload page
    location.reload(true);
  }

  returnHomeOnAccountChange() {
    return isOnPage({
      page: FRONTEND_PAGES.SETTINGS,
      location: this.props.location,
    });
  }

  returnHomeOnPropertyChange() {
    return (
      isOnPage({
        page: FRONTEND_PAGES.SETTINGS,
        location: this.props.location,
      }) ||
      isOnPage({
        page: FRONTEND_PAGES.CONNECT_ERROR,
        location: this.props.location,
      })
    );
  }

  setGlobalInfo(globalInfo) {
    // Store global info locally
    this.setState({
      globalInfo,
    });
    // Also store for global accessibility
    storeGlobalInfo(globalInfo);
  }

  /**
   * Render method
   */

  render() {
    const globalContext = {
      global: {
        getGlobalInfo: this.getGlobalInfo,
        globalInfoState: this.state.globalInfoState,
        handleAccountChange: this.handleAccountChange,
        handlePropertyChange: this.handlePropertyChange,
        hasChangedLoading,
        hasChangedRefreshing,
        hasChangedSelecting,
        hasChangedState,
        hasChangedToReady,
        hasChangedUpdating,
        isLoading: this.isLoading,
        isRefreshing: this.isRefreshing,
        isSelecting: this.isSelecting,
        isUpdating: this.isUpdating,
        refreshGlobalInfo: this.refreshGlobalInfo,
        setGlobalInfo: this.setGlobalInfo,
      },
    };

    // Ensure a Mixpanel profile is created for any logged-in user
    if (typeof mixpanel !== 'undefined') {
      if (this.isReady()) {
        const globalInfo = this.state.globalInfo;
        const currentUser = globalInfo.user;
        const currentProperty = getCurrentProperty({
          globalInfo,
        });
        const currentPropertyId = getCurrentPropertyId({
          globalInfo,
        });
        const currentRole = getAccountAPIPermission({
          accountAPIId: getCurrentAccountAPIId({
            globalInfo,
          }),
          globalInfo,
        });

        mixpanel.people.set({
          $distinct_id: currentUser.emailHash,
          $name: currentUser.name,
          $property: currentProperty?.propertyName ?? 'Not set',
          $email: currentUser.emailAddress,
          'Property ID': currentPropertyId ?? 'Not set',
          Staff: currentUser.userType === USER_TYPES.ECHOBOX_STAFF,
          Role: currentRole === PERMISSION_TYPES.EDITOR ? 'Editor' : 'Admin',
          HV: window.sessionStorage.getItem('currentVersion'),
        });
        mixpanel.identify(currentUser.emailHash);
      }
    }

    // Logged in
    const componentChildren = Children.map(this.props.children, (child) =>
      cloneElement(child, {
        showMobileMenu: this.state.showMobileMenu,
        eventHandlers: {
          ...child.props.eventHandlers,
          handleMobileMenuClose: this.handleMobileMenuClose,
          handleMobileMenuToggle: this.handleMobileMenuToggle,
        },
        isInitialisingHighPriorityErrorState:
          this.state.isInitialisingHighPriorityErrorState,
      }),
    );

    return (
      <GlobalInfoContext.Provider value={globalContext}>
        <NotificationSystem />
        {componentChildren}
        <ZendeskWidget />
        <WootricWidget />
        <BeamerWidget />
      </GlobalInfoContext.Provider>
    );
  }
}

const EchoboxWrapper = (props) => {
  const { initialiseHighPriorityErrorState } =
    useHighPriorityErrorTypeActions();
  return (
    <Echobox
      {...props}
      initialiseHighPriorityErrorState={initialiseHighPriorityErrorState}
    />
  );
};

export default withFlashMessages(withLocation(withNavigate(EchoboxWrapper)));
