import clsx from 'clsx';
import { memo } from 'react';

import { REACT_PREVENT_RENDER, TEXT_CASES } from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import { arePropsEqual } from 'common/utility';
import { trackOpenArticle } from 'helpers/articleTracking';

interface PublishedTimeProps {
  articleURL: string;
  isMobile: boolean;
  publishedTime: number | null;
  twelveHourFormat: boolean;
}

const PublishedTime = ({
  articleURL,
  isMobile,
  publishedTime,
  twelveHourFormat,
}: PublishedTimeProps) => {
  if (publishedTime == null) {
    return REACT_PREVENT_RENDER;
  }

  const linkClass = isMobile
    ? 'd-block d-md-none mr-auto'
    : 'd-none d-md-block';
  const formattedPublishedTime = getFormattedDateFromUnix({
    timestamp: publishedTime,
    twelveHourFormat,
    textCase: TEXT_CASES.LOWER,
  });

  return (
    <a
      href={articleURL}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx('article_link link_grey', linkClass)}
      onClick={() => trackOpenArticle(articleURL, 'Compose Box')}
    >
      Published {formattedPublishedTime}
      <img
        className="link_icon"
        src="/img/icons/ic-open-in-new.svg"
        alt=""
        width="13px;"
      />
    </a>
  );
};

export default memo(PublishedTime, arePropsEqual);
