import { Flex, Tab, Tabs } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { Frame, FrameData } from 'types';
import SettingsCard from './SettingsCard';

interface FrameTabProps {
  value: string;
  children: React.ReactNode;
}
const FrameTab = ({ children, value }: FrameTabProps) => {
  return (
    <Tab
      value={value}
      flexGrow={1}
      display="flex"
      justifyContent="center"
      py={4}
    >
      {children}
    </Tab>
  );
};

interface FramesOverviewProps {
  data: Record<Frame, FrameData> | null;
  onEditSection: (frame: Frame, type: 'text' | 'imageURL' | 'videoURL') => void;
}

const FramesOverview = ({ data, onEditSection }: FramesOverviewProps) => {
  const [currentFrame, setCurrentFrame] = React.useState<Frame>('frame1');

  const frameData = data?.[currentFrame];

  return (
    <Flex flexDir="column" gap={3} w="full">
      <Tabs
        boxShadow="borderBottom"
        fitted
        selectedValue={currentFrame}
        display="flex"
        onChange={(value) => setCurrentFrame(value as Frame)}
      >
        <FrameTab value="frame1">Frame 1</FrameTab>
        <FrameTab value="frame2">Frame 2</FrameTab>
        <FrameTab value="frame3">Frame 3</FrameTab>
      </Tabs>
      {frameData ? (
        <>
          <SettingsCard.TextCard
            text={frameData.text}
            onClick={() => onEditSection(currentFrame, 'text')}
          />
          {frameData.imageURL && (
            <SettingsCard.ImageCard
              imageURL={frameData.imageURL}
              onClick={() => onEditSection(currentFrame, 'imageURL')}
            />
          )}
        </>
      ) : (
        <>
          <SettingsCard type="text" isDisabled />
          <SettingsCard type="image" isDisabled />
        </>
      )}
    </Flex>
  );
};

export default FramesOverview;
