import { useEffect, useState } from 'react';

/**
 * Custom hook to check if the text from an element is overflowing it's container or not
 * @param ref The ref of the element to check
 * @returns True if the text from an element is overflowing it's container, false otherwise
 */
export const useEllipsisActive = (ref: React.RefObject<HTMLElement>) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsEllipsisActive(
        ref.current.offsetWidth < ref.current.scrollWidth ||
          ref.current.offsetHeight < ref.current.scrollHeight,
      );
    }
  }, [ref]);

  return isEllipsisActive;
};
