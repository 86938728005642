/**
 * POST /properties - Adds a property to an existing Echobox account
 *
 * @param {String} propertyName - Property name
 * @return {JSON} - Creates a new Echobox account
 *
 * Example response
 * {
 *   accountId: 102,
 *   authToken: [auth_token]
 * }
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { extractPropertyId } from 'common/urn';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ propertyURN: z.string() })
  .describe('postProperties.ResponseSchema');

export default async function postProperties({
  propertyName,
}: {
  propertyName: string;
}) {
  const guid = metrics.start('postProperties');
  const clientAuthHeader = getClientServiceRequestHeaders({
    'Content-Type': 'application/json',
  });
  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/properties`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: clientAuthHeader,
    data: JSON.stringify({
      propertyName,
    }),
  };
  logger.info(`API:postProperties /properties ${propertyName}`);

  try {
    const response = await axios(config);
    const property = lyingParse(ResponseSchema, response.data);
    const propertyId = extractPropertyId(property.propertyURN);
    metrics.end('postProperties', guid);
    logger.debug('postProperties', { propertyName });
    return { ...property, propertyId };
  } catch (error) {
    metrics.fail('postProperties', guid);
    const logError = error;
    if (
      typeof logError === 'object' &&
      logError != null &&
      'config' in logError &&
      logError.config != null
    ) {
      (logError.config as Record<string, unknown>).data = {
        propertyName,
      };
    }
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postProperties',
      args: { propertyName },
    });
    throw apiError;
  }
}
