import { useEffect, useState } from 'react';

import { getPropertiesUsers } from 'api/api';
import { getCurrentProperty, getCurrentPropertyId } from 'common/accountAPIs';
import * as logger from 'common/logger';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { FixTypeLater } from 'types';

const usePropertyUsers = (initialPropertyUsers: FixTypeLater) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const [propertyUsers, setPropertyUsers] = useState(initialPropertyUsers);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUsers = async () => {
      logger.info('UserManagement - users missing');
      setIsLoading(true);

      const currentPropertyId = getCurrentPropertyId();
      const response = await getPropertiesUsers({
        propertyIds: [currentPropertyId],
      });
      if (response) {
        Object.keys(response).forEach((propertyId) => {
          globalInfo.properties[propertyId].propertyUsers =
            response[propertyId];
        });
      }
      global.setGlobalInfo(globalInfo);
      setPropertyUsers(getCurrentProperty(globalInfo).propertyUsers);
      logger.info('UserManagement - users loaded');
      setIsLoading(false);
    };

    if (propertyUsers === undefined || propertyUsers.length === 0) {
      loadUsers();
    }
  }, [global, globalInfo, propertyUsers]);

  return {
    isLoading,
    propertyUsers,
    onUsersChange: setPropertyUsers,
  };
};

export default usePropertyUsers;
