import { Box, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import $ from 'jquery';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getCurrentProperty } from 'common/accountAPIs';
import * as tracker from 'common/tracker';
import InstallWebsiteTag from 'components/setup/InstallWebsiteTag';
import { useSettingsContext } from 'context/SettingsContext';
import { setPanelButtonText } from 'helpers/settingsPage';
import ConnectedWebsiteTag from './ConnectedWebsiteTag';

const WebsiteTag = () => {
  const { isTrackerInstalled } = useSettingsContext();
  const { propertyRootURL } = getCurrentProperty();
  const location = useLocation();

  useEffect(() => {
    setPanelButtonText('collapseWebsiteTag');
    $('#collapseWebsiteTag').on('shown.bs.collapse', () => {
      const origin = location.state?.from ?? 'UNKNOWN';
      tracker.track({
        eventName: 'Echobox Web Tag Settings Page',
        trackingParams: {
          Origin: origin,
        },
      });
    });
  }, [location.state?.from]);

  return (
    <>
      <div className="setting-part border-top d-flex flex-row">
        <div className=" mr-auto">
          <div className="ft-13 text-500">Web Tag</div>
        </div>
        <div
          className="btn btn-light align-self-center"
          data-cy-action="editWebsiteTag"
          data-toggle="collapse"
          data-target="#collapseWebsiteTag"
          aria-expanded="true"
          aria-controls="collapseWebsiteTag"
        >
          Edit
        </div>
      </div>
      <div
        id="collapseWebsiteTag"
        className="settings-sub collapse"
        aria-labelledby="headingOne"
        data-parent="#accordionPropertySettings"
      >
        <div className="py-4 border-top d-flex flex-column">
          {isTrackerInstalled === null && (
            <Box textAlign="center">
              <Spinner size="md" />
            </Box>
          )}
          {isTrackerInstalled === false && (
            <InstallWebsiteTag isError maxW="full" rootUrl={propertyRootURL} />
          )}
          {isTrackerInstalled === true && <ConnectedWebsiteTag />}
        </div>
      </div>
    </>
  );
};

export default WebsiteTag;
