import * as React from 'react';

import { updateChameleonIdentity } from 'common/chameleon';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { GlobalInfo } from 'types';

/**
 * A custom hook for updating Chameleon with the current user's information.
 */
export default function useChameleon() {
  const { global } = useGlobalInfo();
  const globalInfo: GlobalInfo.GlobalInfo = global.getGlobalInfo();
  const { user } = globalInfo;

  const isLoading = global.isLoading();

  React.useEffect(() => {
    if (!isLoading) {
      updateChameleonIdentity(user);
    }
  }, [isLoading, user]);
}
