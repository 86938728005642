import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import { API_TIMEOUTS, constructBaseAppletAPIURL } from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * GET /applets/isInstalled/default
 * - check if tracker is installed at the provided URL
 * @param {String} url - property URN
 * @return {Object}
 */

export default async function getTrackerStatus({ url }: { url: string }) {
  if (isUndefined(url)) {
    mandatory('url');
  }

  const guid = metrics.start('getTrackerStatus');

  const config: AxiosRequestConfig = {
    url: `${constructBaseAppletAPIURL()}/applets/isinstalled/default`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    params: {
      url,
    },
  };

  try {
    const response = await axios(config);
    metrics.end('getTrackerStatus', guid);
    logger.debug('getTrackerStatus', {
      url,
    });
    return response.data;
  } catch (error) {
    metrics.fail('getTrackerStatus', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getTrackerStatus',
      args: { url },
    });
    throw apiError;
  }
}
