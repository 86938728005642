/* eslint no-bitwise: "off" */
/* eslint no-mixed-operators: "off" */
/* eslint no-use-before-define: "off" */
/* eslint no-useless-escape: "off" */
/* eslint prefer-template: "off" */

import twitter from 'twitter-text';

import { SHARE_URL_PLACEHOLDER } from 'common/config';
import { API_TYPE_IDS, COUNTRIES, TAG_TYPES } from 'common/constants';
import { getMessageSettings, getSocialNetworkTagPattern } from 'common/social';
import getTwitterCount from 'common/twitterCount';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';

import { generateGuid } from './guid';

/**
 * containsURL
 */

function containsURL(text: string | null | undefined) {
  if (isNullOrUndefined(text)) {
    return false;
  }

  // Find any URLs in the string
  const urls = twitter.extractUrlsWithIndices(text);
  // Do any URLs exist?
  const hasURL = isDefined(urls) && urls.length > 0;

  // Find any placeholders in the string
  const hasPlaceholder = text.indexOf(SHARE_URL_PLACEHOLDER) !== -1;

  return hasPlaceholder || hasURL;
}

/**
 * @deprecated Unnecessary method. Just return the string.
 */
function ebxMessage(text: string) {
  return text;
}

function encodeHTML(text: string) {
  function encodeChar(char: string) {
    const encodings: { [index: string]: string } = {
      '&': '&#x26;',
      '<': '&#x3C;',
      '>': '&#x3E;',
      '"': '&#x22;',
      "'": '&#x27;',
      '`': '&#x60;',
    };
    return encodings[char] ?? char;
  }

  return text
    .split('')
    .map((char) => encodeChar(char))
    .join('');
}

function encodeTextMessage(text: string) {
  let newText = text;
  newText = newText.replace(/<\/?[^>]+(>|$)/g, '');
  newText = newText.replace(/&nbsp;/gi, ' ');
  newText = unencodeMessage(newText);
  return newText;
}

/**
 * errorGuid
 */

function errorGuid() {
  let errorHash = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i += 1) {
    errorHash += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return errorHash;
}

/**
 * escapeRegExp
 */

function escapeRegExp(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/**
 * extractUsername
 */

function extractUsername(url: string) {
  if (
    typeof url !== 'string' ||
    url.indexOf('https://www.facebook.com/') === -1
  ) {
    return null;
  }
  const rest = url.replace('https://www.facebook.com/', '').split('/');
  if (rest.length > 1 && rest[1] !== '') {
    return null;
  }
  return rest[0];
}

/**
 * formatFileSize
 */
function formatFileSize(bytes: number) {
  let size = bytes;
  const multiplier = 1024;
  if (Math.abs(size) < multiplier) {
    return `${size}B`;
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unit = -1;
  do {
    size /= multiplier;
    unit += 1;
  } while (Math.abs(size) >= multiplier && unit < units.length - 1);
  return `${size.toFixed(0)}${units[unit]}`;
}

/**
 * formatLikesFollowers
 */
function formatLikesFollowers(number: number | null | undefined) {
  if (isNullOrUndefined(number) || Number.isNaN(number)) {
    return null;
  }

  if (number < 0) {
    return null;
  }

  if (number <= 999) {
    return number.toString();
  }
  if (number <= 9949) {
    return `${Math.round(number / 100) / 10}K`;
  }
  if (number <= 999499) {
    return `${Math.round(number / 1000)}K`;
  }
  if (number <= 9499999) {
    return `${Math.round(number / 100000) / 10}M`;
  }
  return `${Math.round(number / 1000000)}M`;
}

/**
 * getCharacterCount
 */

function getCharacterCount(text: string, { apiTypeId }: { apiTypeId: number }) {
  if (apiTypeId === API_TYPE_IDS.TWITTER) {
    return getTwitterCount(text);
  }
  // string.length returns the wrong answer because this just counts UTF-16 units
  // [...string] splits the string into codepoints which is what we really need to count
  // See https://stackoverflow.com/a/54369605/4260770 for details
  return [...text].length;
}

/**
 * getCountryCode
 */

function getCountryCode(countryName: string) {
  return Object.keys(COUNTRIES).find((country) => {
    if (isCountryCode(country)) {
      return COUNTRIES[country] === countryName;
    }

    return false;
  });
}

function isCountryCode(country: string): country is keyof typeof COUNTRIES {
  return Object.keys(COUNTRIES).includes(country);
}

/**
 * getLengthInBytes
 */

function getLengthInBytes(text: string) {
  return new Blob([text]).size;
}

/**
 * getURLLength
 */

function getURLLength(url: string, { apiTypeId }: { apiTypeId: number }) {
  const messageSettings = getMessageSettings({ apiTypeId });
  const urlLength = messageSettings?.urlLength;

  if (isNullOrUndefined(urlLength)) {
    return url.length;
  }
  return urlLength;
}

/**
 * getURLFromString
 */

function getURLFromString(text: string) {
  // Find any URLs in the string
  const urls = twitter.extractUrls(text);
  // No URLs exist
  if (isUndefined(urls) || urls.length === 0) {
    return undefined;
  }
  // Return first URL found
  return urls[0];
}

/**
 * isFacebookURL
 */

function isFacebookURL(url: string) {
  return extractUsername(url) !== null;
}

/**
 * isNullOrUndefinedOrEmpty
 */

function isNullOrUndefinedOrEmpty(text: string | null | undefined) {
  return text == null || text.trim().length === 0;
}

/**
 * shortenURLsInMessage
 */

function shortenURLsInMessage(message: string) {
  // Identify URLs using the Twitter text library
  const urls = twitter.extractUrlsWithIndices(message);

  // Extend each of these URL matches to identify URLs which start with
  // some LTR characters and then continue with some RTL characters,
  // which is a scenario the Twitter text library doesn't support
  // https://www.npmjs.com/package/twitter-text#urls
  // Note that this will fail if in the future Echobox supports languages
  // which don't have spaces between words...
  const extended = urls.map((url) => {
    // Look for a URL followed by a non-space character...
    const regex = new RegExp(`${escapeRegExp(url.url)}\\S*`, 'g');
    // ...but only in the part of the message which contains the original URL
    const section = message.substr(url.indices[0]);
    // Perform the search...
    const matches = section.match(regex);
    // ...and return the "extended" match, if there is one, or the original URL
    return !isNull(matches) ? matches[0] : url.url;
  });

  // Truncate any URLs found to a maximum of 30 characters
  const modifiedURLs = extended.map((url) => {
    if (url.length > 30) {
      return `${url.substring(0, 27)}...`;
    }
    return url;
  });

  // Now replace all URLs identified with their truncated versions
  return modifiedURLs.reduce(
    (acc, modifiedURL, idx) => acc.replace(extended[idx], modifiedURL),
    message,
  );
}

/**
 * stringifyNonCircular
 */

function stringifyNonCircular(obj: unknown) {
  if (typeof obj === 'undefined') {
    return '';
  }

  const cache: any[] = [];
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        try {
          return JSON.parse(JSON.stringify(value));
        } catch (error) {
          return undefined;
        }
      }
      cache.push(value);
    }
    return value;
  });
}

/**
 * toCamelCase
 */

function toCamelCase(text: string) {
  return text
    .split(' ')
    .map((word) => word.substr(0, 1).toUpperCase() + word.substr(1))
    .join(' ');
}

/**
 * toSentenceCase
 */

function toSentenceCase(text: string) {
  return text
    .split(' ')
    .map(
      (word) => word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase(),
    )
    .join(' ');
}

/**
 * truncateMessage
 */

function truncateMessage({
  message,
  length,
  apiTypeId,
}: {
  message: string;
  length: number;
  apiTypeId: number;
}) {
  let result = message;

  // Work out if there is a tag crossing the boundary between the bit of string we are keeping
  const tagTypes = Object.values(TAG_TYPES);
  for (let idx = 0; idx < tagTypes.length; idx += 1) {
    const tagType = tagTypes[idx];
    const tagPattern = getSocialNetworkTagPattern({ apiTypeId, tagType });
    const matches = tagPattern ? tagPattern.exec(result) : null;

    if (!isNull(matches)) {
      const match = { start: matches.index, length: matches[0].length };
      if (match.start + match.length > length) {
        // A tag crosses the boundary, so truncate before the tag and exit
        result = result.substr(0, match.start);
        return result;
      }
    }
  }

  // Truncate to the required length and encode back to our internal tag representation
  result = result.substr(0, length);
  return result;
}

/**
 * unencodeMessage
 */

function unencodeMessage(text: string) {
  return text
    .replace(/&amp;/gi, '&')
    .replace(/&lt;/gi, '<')
    .replace(/&gt;/gi, '>');
}

/**
 * wrapWords
 */

function wrapWords({
  initialText,
  searchText,
  replaceStart,
  replaceEnd,
}: {
  initialText: string;
  searchText: string;
  replaceStart: number;
  replaceEnd: number;
}) {
  const searchWords = searchText.split(' ');
  let wrappedText = initialText;
  searchWords.forEach((searchWord) => {
    let wrappedWord = wrappedText;
    const entireContent = new RegExp(`^(${searchWord})$`, 'gi');
    const atStart = new RegExp(`^(${searchWord})([^\\w]+[\\w]*)`, 'gi');
    const atEnd = new RegExp(`(.*[^\\w]+)(${searchWord})$`, 'gi');
    const inMiddle = new RegExp(
      `(.*)([^\\w]+)(${searchWord})([^\\w]+)(.*)`,
      'gi',
    );
    wrappedWord = wrappedWord.replace(
      entireContent,
      `${replaceStart}$1${replaceEnd}`,
    );
    if (wrappedText === wrappedWord) {
      wrappedWord = wrappedWord.replace(
        atStart,
        `${replaceStart}$1${replaceEnd}$2`,
      );
    }
    if (wrappedText === wrappedWord) {
      wrappedWord = wrappedWord.replace(
        atEnd,
        `$1${replaceStart}$2${replaceEnd}`,
      );
    }
    if (wrappedText === wrappedWord) {
      wrappedWord = wrappedWord.replace(
        inMiddle,
        `$1$2${replaceStart}$3${replaceEnd}$4$5`,
      );
    }
    wrappedText = wrappedWord;
  });
  return wrappedText;
}

export {
  containsURL,
  ebxMessage,
  encodeHTML,
  encodeTextMessage,
  errorGuid,
  extractUsername,
  formatFileSize,
  formatLikesFollowers,
  generateGuid,
  getCharacterCount,
  getCountryCode,
  getLengthInBytes,
  getURLFromString,
  getURLLength,
  isFacebookURL,
  isNullOrUndefinedOrEmpty,
  shortenURLsInMessage,
  stringifyNonCircular,
  toCamelCase,
  toSentenceCase,
  truncateMessage,
  unencodeMessage,
  wrapWords,
};
