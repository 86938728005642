import { Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as MediaItem from 'common/mediaItem';
import { FixTypeLater } from 'types';
import InstantVideoErrorBoundary from './InstantVideoErrorBoundary';
import InstantVideoModalContent from './InstantVideoModalContent';
import { useInstantVideoTracking } from './useInstantVideoTracking';

interface InstantVideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSchedule: () => void;
  initialMediaItem: FixTypeLater;
}

const InstantVideoModal = ({
  isOpen,
  onClose,
  onSchedule,
  initialMediaItem,
}: InstantVideoModalProps) => {
  const { trackCancelPost } = useInstantVideoTracking();

  const handleClose = () => {
    const articleURL = MediaItem.getUnshortenedShareURL({
      mediaItem: initialMediaItem,
    });

    trackCancelPost({ articleURL });

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="extraLarge"
      scrollBehavior="outside"
    >
      <InstantVideoErrorBoundary>
        <InstantVideoModalContent
          initialMediaItem={initialMediaItem}
          onClose={handleClose}
          onSchedule={onSchedule}
        />
      </InstantVideoErrorBoundary>
    </Modal>
  );
};

export default InstantVideoModal;
