import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import * as cache from 'common/cache';
import { STORAGE_TYPES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getURLFromString } from 'common/string';
import { convertToPropertyURN } from 'common/urn';
import { lyingParse } from 'common/zod';

const CachedSchema = z
  .object({
    resolvedURL: z.string().nullable(),
    isResolved: z.boolean().optional(),
  })
  .describe('getPropertiesResolveURL.CachedSchema');

const ResolveURLSchema = z
  .object({ resolvedURL: z.string().nullable() })
  .describe('getPropertiesResolveURL.ResolveURLSchema');

export default async function getPropertiesResolveURL({
  propertyId,
  url,
}: {
  propertyId: string | number;
  url: string | null;
}) {
  if (url === null) {
    return { resolvedURL: url };
  }
  const key = `${propertyId}:${url}`;
  const cached = cache.getCacheItem('getPropertiesResolveURL', key);
  if (cached !== null) {
    return lyingParse(CachedSchema, cached);
  }
  const guid = metrics.start('getPropertiesResolveURL');
  const propertyURN = convertToPropertyURN(propertyId);
  logger.info(
    `API:getPropertiesResolveURL /properties/${propertyId}/resolveURL ${url}`,
  );

  try {
    const response = await axios.get(
      `${constructBaseSocialAPIURL(
        'v3.2',
      )}/social/properties/${propertyURN}/resolveURL`,
      {
        params: { url },
        timeout: API_TIMEOUTS.M,
        headers: getClientServiceRequestHeaders(),
      },
    );
    metrics.end('getPropertiesResolveURL', guid);
    logger.debug('getPropertiesResolveURL', { propertyId, url });
    const validatedData = lyingParse(ResolveURLSchema, response.data);
    const result = {
      resolvedURL: validatedData.resolvedURL,
      isResolved: true,
    };
    cache.setCacheItem(
      'getPropertiesResolveURL',
      key,
      result,
      STORAGE_TYPES.SESSION,
    );
    return result;
  } catch (error: any) {
    metrics.fail('getPropertiesResolveURL', guid);
    const status = error?.response?.status ?? null;
    if (status === 422 && getURLFromString(url)) {
      const resolvedURL: string = error?.response?.data?.resolvedURL ?? url;
      return {
        resolvedURL,
        isResolved: false,
      };
    }
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertiesResolveURL',
      args: { propertyId, url },
    });
    throw apiError;
  }
}
