import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import postAPIConnectSave from 'api/postAPIConnectSave';
import { getCurrentAccountAPIId } from 'common/accountAPIs';
import { GLOBAL_INFO_STATES, MIXPANEL_ORIGIN } from 'common/constants';
import { SAVE_STATES } from 'common/enums';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { isNull } from 'common/utility';
import { location } from 'common/window';
import APISelector from 'components/setup/APISelector';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { getNetworkAndPageName } from 'helpers/tracking';

interface SetupSelectProps {
  apiTypeId: number | string | null;
  requestToken: string;
}

const SetupSelect = ({ apiTypeId, requestToken }: SetupSelectProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [saveState, setSaveState] = useState(SAVE_STATES.NONE);
  const { pathname } = useLocation();

  const { global } = useGlobalInfo();

  const handleSelected = async (
    selected: ReadonlyArray<{ id: string; name: string }>,
    onClose: () => void,
  ) => {
    setSaveState(SAVE_STATES.SAVING);
    const selectedIds = selected.map((item) => item.id);

    try {
      const response = await postAPIConnectSave({
        identifiers: selectedIds,
        requestToken,
      });

      logger.log(`SocialPageSelect ${response}`);
      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.REFRESHING,
        allowUnderSetup: true,
        callback: () => {
          onClose();
          location.reload();
        },
      });
      if (apiTypeId !== null) {
        selected.forEach((item) => {
          const accountAPIId = getCurrentAccountAPIId();
          tracker.track({
            eventName: 'Add Page',
            trackingParams: {
              'Social Network': getSocialNetworkName({ apiTypeId }),
              'Social Page': item.name,
              'Network - Social Page': getNetworkAndPageName({
                accountAPIId,
              }),
              'Account API Id': accountAPIId,
              Origin: pathname.startsWith('/setup')
                ? MIXPANEL_ORIGIN.SETUP
                : MIXPANEL_ORIGIN.SETTINGS,
            },
          });
        });
      }
    } catch (error) {
      setSaveState(SAVE_STATES.FAILED);
      const handledError = determineError(error);
      if (isNull(getErrorStatus(handledError))) {
        logger.error({
          event: 'Handle Selected Error',
          properties: {
            location: 'components/setup/SocialPageSelect',
          },
          error,
        });
      }
      setErrorMessage(getErrorMessage(handledError));
    }
  };

  return (
    <APISelector
      apiTypeId={apiTypeId}
      errorMessage={errorMessage}
      onSubmit={handleSelected}
      requestToken={requestToken}
      saveState={saveState}
    />
  );
};

export default SetupSelect;
