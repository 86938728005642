import { HEALTH_MENU_TOAST_LOCALSTORAGE_KEY } from 'common/constants';

const LEGACY_KEY = 'SEEN_DISCONNECTIONS_TOAST_NOTIFICATIONS';

function changeHealthMenuToastStorageKey() {
  const oldKey = localStorage.getItem(LEGACY_KEY);

  if (oldKey) {
    localStorage.setItem(HEALTH_MENU_TOAST_LOCALSTORAGE_KEY, oldKey);
    localStorage.removeItem(LEGACY_KEY);
  }
}

export default changeHealthMenuToastStorageKey;
