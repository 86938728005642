/* eslint no-use-before-define:"off" */

import Immutable from 'seamless-immutable';

import { getCurrentProperty, getCurrentPropertyId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES, RSS_STATE_NAMES } from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import * as MediaItem from 'common/mediaItem';
import { getSetting } from 'common/settings';
import { cloneArray } from 'common/utility';
import { mandatory } from 'common/validation';
import sortRSSFeedsByTitle from 'helpers/connectYourContent';

export {
  getAllRSSFeeds,
  getFeedTitleOrDefault,
  getIndex,
  getPagesByFeed,
  isRSSFeed,
  refresh,
};

function getIndex({
  feed = mandatory('feed'),
  mediaId = mandatory('mediaId'),
} = {}) {
  return feed.mediaItems.findIndex(
    (mediaItem) => MediaItem.getMediaId({ mediaItem }) === mediaId,
  );
}

const getPagesByFeed = ({ feeds, accountAPIs }) => {
  const pagesByFeed = feeds.reduce(
    (prev, { syndFeedURN }) => ({ ...prev, [syndFeedURN]: [] }),
    {},
  );

  Object.keys(accountAPIs).forEach((APIId) => {
    const connectedFeeds =
      getSetting({
        settingTypeId: ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
        propertyId: accountAPIs[APIId].propertyId ?? getCurrentPropertyId(),
        accountAPIId: accountAPIs[APIId].accountAPIId,
      })?.dataJSON ?? [];

    connectedFeeds.forEach((connectedFeedURN) => {
      if (connectedFeedURN in pagesByFeed) {
        pagesByFeed[connectedFeedURN].push(accountAPIs[APIId].accountAPIId);
      }
    });
  });

  return pagesByFeed;
};

function refresh({
  feed = mandatory('feed'),
  mediaIds = mandatory('mediaList'),
  createMediaItem = mandatory('createMediaItem'),
  maxItemsToAdd = mandatory('maxItemsToAdd'),
  isCompleteRefresh = mandatory('isCompleteRefresh'),
} = {}) {
  const refreshedFeed = Immutable.asMutable(feed, { deep: true });

  // Track any new media items added
  const mediaIdsAdded = [];

  // Count the number of items in the updated media list
  const newItems = mediaIds.length;

  // Media ids - update with the latest list
  refreshedFeed.mediaIds = cloneArray(mediaIds);

  // Media items - calculate the number of items that need to be shown
  // Start by assuming we can show them all...
  let itemsToShow = newItems;
  // ... but reduce this to the allowed number of additional items
  if (itemsToShow > feed.itemsRendered + maxItemsToAdd) {
    itemsToShow = feed.itemsRendered + maxItemsToAdd;
  }

  // Media items - only update the rendered items
  refreshedFeed.mediaItems = [];
  for (let idx = 0; idx < itemsToShow; idx += 1) {
    const mediaId = mediaIds[idx];
    const index = getIndex({ feed, mediaId });
    if (index !== -1) {
      // If the item already exists, use the existing version
      let mediaItem = feed.mediaItems[index];
      if (isCompleteRefresh) {
        mediaItem = MediaItem.setIsReloading({
          mediaItem,
          fieldValue: true,
        });
      }
      refreshedFeed.mediaItems.push(mediaItem);
    } else {
      // Otherwise add a new "loading" item
      refreshedFeed.mediaItems.push(createMediaItem({ mediaId }));
      mediaIdsAdded.push(mediaId);
    }
  }

  // Media items - update the rendered count
  refreshedFeed.itemsRendered = itemsToShow;

  return [Immutable(refreshedFeed), mediaIdsAdded];
}

const RSS_FEED_TYPES = [
  'RSS_NORMAL',
  'RSS_VALID_PUBLISH_TIME',
  'RSS_OVERRIDE_PUBLISH_TIME',
  'RSS_OVERRIDE_PUBLISH_TIME_WITHIN_24_HOURS',
];

/**
 * Checks whether an account feed is an RSS feed.
 * @param {import('types').SyndfeedType} feedType The account feed type to check.
 * @returns {boolean} True, if account feed is an RSS feed. False, otherwise.
 */
function isRSSFeed(feedType) {
  return RSS_FEED_TYPES.includes(feedType);
}

const getAllRSSFeeds = () => {
  const globalInfo = getGlobalInfo();
  /**
   * @type {Array<import('types').AccountFeed> | undefined}
   */
  let rssFeeds = getCurrentProperty({
    globalInfo,
  }).accountFeeds;

  if (!rssFeeds) {
    rssFeeds = [];
  }

  sortRSSFeedsByTitle(rssFeeds);
  return rssFeeds.filter(
    (feed) =>
      feed.syndFeedState === RSS_STATE_NAMES.ACTIVE ||
      feed.syndFeedState === RSS_STATE_NAMES.ERROR,
  );
};

/**
 * @param {import('types').AccountFeed} feed
 */
function getFeedTitleOrDefault(feed) {
  return (
    feed.syndFeedTitle?.trim() ??
    (isRSSFeed(feed.syndFeedType) ? 'Untitled feed' : 'Sitemap')
  );
}
