import { Box, Heading, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import {
  getCurrentAPITypeId,
  getCurrentPropertyId,
  isCurrentPropertySuspended,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  COLLECTION_NAMES,
  FEED_STATES,
  GLOBAL_INFO_CHANGES,
  REACT_PREVENT_RENDER,
  UI_MESSAGES,
} from 'common/constants';
import { getTimeFilterRange } from 'common/datetime';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as settings from 'common/settings';
import * as tracker from 'common/tracker';
import { isDefined, isNullOrUndefined } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import Filters from 'components/home/articlefeed/Filters';
import Item from 'components/home/articlefeed/Item';
import NoFeeds from 'components/home/articlefeed/NoFeeds';
import Button from 'components/misc/Button';
import withGlobalInfo from 'context/withGlobalInfo';

import TikTokVideoUpload from './TikTokVideoUpload';

/**
 * Article feed
 */

class ArticleFeed extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      showFailedShares: ArticleFeed.getShowFailedShares(),
    };
    this._bind(
      'handleShowFailedSharesToggle',
      'handleSortChange',
      'handleStateChange',
      'handleTimeframeChange',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare(this.getCollectionName(), {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.LOADING_OR_SELECTING,
    });
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
      { excludeLoading: true },
    );
    if (hasChangedToReady) {
      this.initialiseComponent();
    }
  }

  /**
   * Event handlers
   */

  handleShowFailedSharesToggle() {
    const showFailedShares = !this.state.showFailedShares;
    logger.info(`ArticleFeed:handleShowFailedSharesToggle ${showFailedShares}`);
    this.setShowFailedShares(showFailedShares);
  }

  handleSortChange(event) {
    tracker.track({
      eventName: 'Update Home Feed Sort',
      trackingParams: {
        Sort: ArticleFeed.getSortName(event.target.value),
        'Previous Sort': ArticleFeed.getSortName(
          this.props.feedData.articleFeed.displayOptions.sortBy,
        ),
      },
    });
    const collectionName = this.getCollectionName();
    this.props.eventHandlers.handleSortChange({
      collectionName,
      sortBy: event.target.value,
    });
  }

  handleStateChange(event) {
    tracker.track({
      eventName: 'Update Home Feed Filter',
      trackingParams: {
        'Type Filter': ArticleFeed.getStateName(event.target.value),
        'Previous Type Filter': ArticleFeed.getStateName(
          this.props.feedData.articleFeed.displayOptions.filterBy,
        ),
        'Time Filter': ArticleFeed.getTimeframeName(
          this.props.feedData.articleFeed.displayOptions.timeFrame,
        ),
        'Previous Time Filter': ArticleFeed.getTimeframeName(
          this.props.feedData.articleFeed.displayOptions.timeFrame,
        ),
      },
    });
    this.props.eventHandlers.handleStateChange({
      event,
    });
  }

  handleTimeframeChange(event) {
    tracker.track({
      eventName: 'Update Home Feed Filter',
      trackingParams: {
        'Type Filter': ArticleFeed.getStateName(
          this.props.feedData.articleFeed.displayOptions.filterBy,
        ),
        'Previous Type Filter': ArticleFeed.getStateName(
          this.props.feedData.articleFeed.displayOptions.filterBy,
        ),
        'Time Filter': ArticleFeed.getTimeframeName(event.target.value),
        'Previous Time Filter': ArticleFeed.getTimeframeName(
          this.props.feedData.articleFeed.displayOptions.timeFrame,
        ),
      },
    });
    const collectionName = this.getCollectionName();
    const { fromTime, toTime } = getTimeFilterRange(event.target.value);
    this.props.eventHandlers.handleTimeframeChange({
      collectionName,
      timeFrame: event.target.value,
      fromTime,
      toTime,
    });
  }

  /**
   * Helper methods
   */

  getCollectionName() {
    switch (this.props.feedState) {
      case FEED_STATES.DELETED:
        return COLLECTION_NAMES.DELETED_ITEMS;
      case FEED_STATES.NEW:
      case FEED_STATES.DRAFT:
      default:
        return COLLECTION_NAMES.ARTICLE_FEED;
    }
  }

  static getFilterCollectionNames() {
    return [COLLECTION_NAMES.ARTICLE_FEED, COLLECTION_NAMES.DELETED_ITEMS];
  }

  static getShowFailedShares() {
    const showFailedShares = Cookies.get('ebx_showfailedshares');
    return isDefined(showFailedShares) ? JSON.parse(showFailedShares) : true;
  }

  static getSortName(sortBy) {
    switch (sortBy) {
      case 'potential':
        return 'Score';
      case 'date':
        return 'Date';
      default:
        return sortBy;
    }
  }

  static getStateName(filterBy) {
    switch (filterBy) {
      case 'all':
      case 'NEW':
        return 'All New';
      case 'draft':
      case 'DRAFT':
        return 'Drafts Only';
      case 'deleted':
      case 'DELETED':
        return 'Deleted Items';
      default:
        return filterBy;
    }
  }

  static getTimeframeName(timeframe) {
    switch (timeframe.toString()) {
      case '6':
        return '6 hours';
      case '12':
        return '12 hours';
      case '24':
        return '24 hours';
      case '48':
        return '48 hours';
      case '168':
        return '1 week';
      default:
        return timeframe;
    }
  }

  initialiseComponent() {
    const isTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;

    switch (this.props.feedState) {
      case FEED_STATES.NEW:
        this.props.sharedMethods.getFeedData({
          collectionName: COLLECTION_NAMES.ARTICLE_FEED,
        });
        this.props.sharedMethods.getFeedData({
          collectionName: COLLECTION_NAMES.FAILED_SHARES,
        });
        break;
      case FEED_STATES.DRAFT:
        this.props.sharedMethods.getFeedData({
          collectionName: COLLECTION_NAMES.ARTICLE_FEED,
        });
        // Entry point for TikTok is the drafts state so want to load failed shares
        if (isTikTok) {
          this.props.sharedMethods.getFeedData({
            collectionName: COLLECTION_NAMES.FAILED_SHARES,
          });
        }
        break;
      case FEED_STATES.DELETED:
        this.props.sharedMethods.getFeedData({
          collectionName: COLLECTION_NAMES.DELETED_ITEMS,
        });
        break;
      default:
    }
  }

  setShowFailedShares(showFailedShares) {
    Cookies.set('ebx_showfailedshares', showFailedShares, { expires: 365 });
    this.setState({
      showFailedShares,
    });
  }

  /**
   * Render methods
   */

  renderShowFailedShares() {
    const showFailedShares = this.state.showFailedShares;
    const failedShares = this.props.feedData.failedShares.mediaIds.length;
    if (failedShares === 0) {
      return REACT_PREVENT_RENDER;
    }

    const buttonLabel = showFailedShares
      ? 'Hide failed shares'
      : 'Show failed shares';
    return (
      <>
        <hr className="inline-block mt-3 mb-2" />
        <div className="d-flex mb-2 align-items-center">
          <span className="text-700 ft-14">Failed shares ({failedShares})</span>
          <Button
            className="float-right ml-auto"
            onClick={this.handleShowFailedSharesToggle}
          >
            {buttonLabel}
          </Button>
        </div>
      </>
    );
  }

  render() {
    const isTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;
    const feedData = this.props.feedData;
    const feedState = this.props.feedState;
    let isLoading;
    let displayOptions;
    let feedItems;
    let clearedMessage;
    let showClearedTick;
    let collectionName;
    if (feedState === FEED_STATES.DELETED) {
      isLoading =
        feedData.deletedItems.isLoading ||
        this.props.global.isLoading() ||
        this.props.global.isSelecting();
      displayOptions = feedData.deletedItems.displayOptions;
      feedItems = feedData.deletedItems.mediaItems;
      if (
        feedData.deletedItems.hasError &&
        !feedData.deletedItems.isInitialised
      ) {
        showClearedTick = false;
      } else {
        showClearedTick = true;
      }
      clearedMessage = UI_MESSAGES.DELETED_ITEMS_NO_ITEMS_FOUND;
      collectionName = COLLECTION_NAMES.DELETED_ITEMS;
    } else {
      isLoading =
        feedData.articleFeed.isLoading ||
        this.props.global.isLoading() ||
        this.props.global.isSelecting();
      displayOptions = feedData.articleFeed.displayOptions;
      feedItems = feedData.articleFeed.mediaItems;
      if (
        feedData.articleFeed.hasError &&
        !feedData.articleFeed.isInitialised
      ) {
        showClearedTick = false;
      } else {
        showClearedTick = true;
      }
      clearedMessage = UI_MESSAGES.ARTICLE_FEED_NO_ITEMS_FOUND;
      collectionName = COLLECTION_NAMES.ARTICLE_FEED;
    }
    const eventHandlers = this.props.eventHandlers;
    const sharedMethods = this.props.sharedMethods;
    const suspendedClass = isCurrentPropertySuspended()
      ? 'account_suspended'
      : '';
    const feedClass = this.props.tabName === 'share' ? '' : 'd-none d-md-block';

    const rssFeeds = settings.getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
      propertyId: getCurrentPropertyId(),
    }).dataJSON;
    const hasNoConnectedFeeds =
      !isLoading && (isNullOrUndefined(rssFeeds) || rssFeeds.length === 0);

    return (
      <div
        className={`feed ${feedClass}`}
        data-cy-id="articleFeed"
        data-cy-attribute={`isLoading:${isLoading}`}
      >
        {!isTikTok && (
          <Filters
            feedState={feedState}
            displayOptions={displayOptions}
            eventHandlers={{
              handleSortChange: this.handleSortChange,
              handleStateChange: this.handleStateChange,
              handleTimeframeChange: this.handleTimeframeChange,
            }}
          />
        )}
        {isLoading && (
          <div className="empty_feed text-center mt-5 pt-5">
            <Spinner size="lg" />
          </div>
        )}
        {!isLoading && this.renderShowFailedShares()}
        {!isLoading &&
          this.state.showFailedShares &&
          feedData.failedShares.mediaItems.length > 0 && (
            <div className={`articles ${suspendedClass}`}>
              {feedData.failedShares.mediaItems.map((mediaItem) => (
                <Item
                  mediaItem={mediaItem}
                  accountAPIId={this.props.accountAPIId}
                  collectionName={COLLECTION_NAMES.FAILED_SHARES}
                  key={MediaItem.getMediaId({ mediaItem })}
                  eventHandlers={{
                    handleArticleDelete: eventHandlers.handleArticleDelete,
                    handleShowInstantVideoModal:
                      eventHandlers.handleShowInstantVideoModal,
                    handleArticleLoad: eventHandlers.handleArticleLoad,
                    handleArticleRestore: eventHandlers.handleArticleRestore,
                    applySingleFieldUpdateForGuid:
                      eventHandlers.applySingleFieldUpdateForGuid,
                  }}
                />
              ))}
            </div>
          )}
        {!isLoading && feedData.failedShares.mediaItems.length > 0 && (
          <hr className="inline-block mb-3 mt-0" />
        )}
        {hasNoConnectedFeeds && !isTikTok && <NoFeeds />}
        {!isLoading && feedItems.length > 0 && (
          <Box
            {...(isTikTok && { mb: '31px' })}
            className={`articles d-flex flex-column  ${suspendedClass}`}
          >
            {isTikTok && (
              <Heading variant="h5" fontWeight={700} color="#292D46" mb="17px">
                Drafts ({feedItems.length})
              </Heading>
            )}
            {feedItems.map((mediaItem) => (
              <Item
                mediaItem={mediaItem}
                accountAPIId={this.props.accountAPIId}
                collectionName={collectionName}
                key={MediaItem.getMediaId({ mediaItem })}
                eventHandlers={{
                  handleArticleDelete: eventHandlers.handleArticleDelete,
                  handleShowInstantVideoModal:
                    eventHandlers.handleShowInstantVideoModal,
                  handleArticleLoad: eventHandlers.handleArticleLoad,
                  handleArticleRestore: eventHandlers.handleArticleRestore,
                  applySingleFieldUpdateForGuid:
                    eventHandlers.applySingleFieldUpdateForGuid,
                }}
              />
            ))}
            {!isTikTok && sharedMethods.renderNoMoreItems()}
          </Box>
        )}
        {!isLoading && !isTikTok && feedItems.length === 0 && (
          <div className={`empty_feed ${suspendedClass}`}>
            <div className="cleared_message">
              {clearedMessage}
              {showClearedTick && (
                <img
                  src="/img/icons/ic-check-2.svg"
                  alt=""
                  className="ml-2 mb-1"
                  width="14px"
                />
              )}
            </div>
          </div>
        )}
        {isTikTok && !isLoading && <TikTokVideoUpload />}
      </div>
    );
  }
}

ArticleFeed.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  feedData: PropTypes.shape({
    articleFeed: PropTypes.object.isRequired,
    failedShares: PropTypes.object.isRequired,
    deletedItems: PropTypes.object.isRequired,
  }).isRequired,
  feedState: PropTypes.oneOf(Object.values(FEED_STATES)).isRequired,
  sharedMethods: PropTypes.shape({
    getFeedData: PropTypes.func.isRequired,
    renderNoMoreItems: PropTypes.func.isRequired,
  }).isRequired,
  tabName: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
    handleArticleLoad: PropTypes.func.isRequired,
    handleArticleRestore: PropTypes.func.isRequired,
    handleShowInstantVideoModal: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default withGlobalInfo(ArticleFeed);
