import { Auth } from 'aws-amplify';

import { ROUTE_REDIRECTIONS } from 'common/constants';
import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

export default async function authChangePassword({
  oldPassword = mandatory('oldPassword'),
  newPassword = mandatory('newPassword'),
} = {}) {
  const guid = metrics.start('authChangePassword');
  logger.info('API:authChangePassword');

  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    metrics.end('authChangePassword', guid);
    await Auth.signOut({ global: true });
    window.location.href = ROUTE_REDIRECTIONS.LOGIN;
  } catch (error) {
    metrics.fail('authChangePassword', guid);
    throw handleCognitoError({
      originalError: error,
      errorLocation: 'api/authChangePassword',
    });
  }
}
