import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { SyndFeedDataSchema } from 'common/schemas';
import { convertToPropertyURN } from 'common/urn';
import { lyingParse } from 'common/zod';
import { AccountFeed } from 'types';
import { z } from 'zod';

const ResponseSchema = z
  .record(z.string(), z.array(SyndFeedDataSchema))
  .describe('getPropertiesAPIs.ResponseSchema');

/*
 * GET /properties/{propertyId}/synd - retrieve property synd feeds information
 */

export default async function getPropertiesSynds({
  propertyIds,
}: {
  propertyIds: string[];
}) {
  const guid = metrics.start('getPropertiesSynds');
  const requests: Promise<AxiosRequestConfig>[] = [];
  const requestMap: { [key: string]: number } = {};
  const clientAuthHeader = getClientServiceRequestHeaders();

  propertyIds.forEach((propertyId) => {
    const propertyURN = convertToPropertyURN(propertyId);
    const config = {
      url: `${constructBaseCoreAPIURL(
        'v1.1',
      )}/properties/${propertyURN}/syndfeeds`,
      method: 'GET',
      timeout: API_TIMEOUTS.S,
      headers: clientAuthHeader,
    };
    logger.info(`API:getPropertiesSynds /properties/${propertyURN}/syndfeeds`);
    requests.push(axios(config));
    requestMap[propertyId] = requests.length - 1;
  });

  try {
    const responses = await Promise.all(requests);
    const response: Record<string, AccountFeed[]> = {};

    propertyIds.forEach((propertyId, index) => {
      if (responses[index].data) {
        response[propertyId] = responses[index].data.syndFeeds;
      }
    });

    metrics.end('getPropertiesSynds', guid);
    logger.debug('getPropertiesSynds', { propertyIds });
    const validatedData = lyingParse(ResponseSchema, response);
    return validatedData;
  } catch (error) {
    metrics.fail('getPropertiesSynds', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertiesSynds',
      args: { propertyIds },
    });
    throw apiError;
  }
}
