import createDOMPurify from 'dompurify';
import { ClipboardEventHandler, KeyboardEventHandler, useState } from 'react';
import { ContentEditableEvent } from 'react-contenteditable';

import getPropertiesResolveURL from 'api/getPropertiesResolveURL';
import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import { getClipboardData } from 'common/clipboard';
import { KEYCODES } from 'common/constants';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as tracker from 'common/tracker';
import {
  escapeURL,
  removeTimestamp,
  stripAndEncodeSpacesInURL,
  unescapeURL,
  validateURL,
} from 'common/url';
import ContentEditable from 'components/misc/ContentEditable';
import {
  handleURLChangePending,
  handleURLChangeSuccess,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { trackOpenArticle } from 'helpers/articleTracking';
import { handleCopy } from 'helpers/composeBox';
import { getCommonTrackingParams } from 'helpers/tracking';
import { addURLParameters } from 'helpers/url';
import getShortenedURL from 'process/getShortenedURL';
import { MESSAGE_MEDIA_ITEM_ERROR } from 'pubsub/topics';
import { FixTypeLater } from 'types';

const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
  // Prevent line breaks from being added
  if (event.keyCode === KEYCODES.ENTER) {
    event.preventDefault();
  }
};

const handlePaste: ClipboardEventHandler<HTMLDivElement> = (event) => {
  event.preventDefault();

  // Get the pasted text from the clipboard
  let text = getClipboardData(event);

  if (text != null) {
    // Replace all possible problem characters with a space
    text = text.replace(matchProblematicCharacters, ' ');
    // Add pasted text
    document.execCommand('insertText', false, text);
  }
};

const matchProblematicCharacters = /(?:\r\n|\r|\n|\v|\f|\t)/g;

interface URLProps {
  mediaItem: FixTypeLater;
  guid: string;
  url: string;
}

export default function URL({ guid, mediaItem, url: propURL }: URLProps) {
  const DOMPurify = createDOMPurify(window);
  const sanitizedUrl = DOMPurify.sanitize(propURL);
  const [url, setUrl] = useState(escapeURL(sanitizedUrl));

  const [isFocused, setIsFocused] = useState(false);
  const [isURLChanged, setIsURLChanged] = useState(false);
  const [isURLValid, setIsURLValid] = useState(validateURL(url, false));
  const [errorMessage, setErrorMessage] = useState('');
  const { dispatch } = useComposeBoxContext();

  const placeholder = 'Enter article URL here...';

  const saveURLChange = async ({
    updatedURL,
    isURLChanged: updatedIsURLChanged,
    isURLResolved: updatedIsURLResolved,
    isURLValid: updatedIsURLValid,
  }: {
    updatedURL: string;
    isURLChanged?: boolean;
    isURLResolved?: boolean;
    isURLValid?: boolean;
  }) => {
    try {
      const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
      const apiTypeId = getAPITypeId({ accountAPIId });

      const updatedURLWithParams = await addURLParameters(
        updatedURL,
        accountAPIId,
        MediaItem.getSuggestionTypeId({ mediaItem }) === null,
      );
      const shortenedURL = await getShortenedURL({
        pageURL: updatedURLWithParams,
        accountAPIId,
        apiTypeId,
      });

      dispatch(
        handleURLChangeSuccess({
          guid,
          isURLChanged: updatedIsURLChanged,
          isURLResolved: updatedIsURLResolved,
          isURLValid: updatedIsURLValid,
          shortenedURL,
          updatedURL: updatedURLWithParams,
        }),
      );
    } catch (error) {
      console.log(error);
      determineError(error);
      if (getErrorStatus(error) === null) {
        logger.error({
          event: 'saveURLChange Error',
          properties: {
            location: 'components/compose/URL',
          },
          error,
        });
      }
      logger.info(
        `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in components/compose/URL.saveURLChange`,
      );
      PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
        guid,
        error,
      });
    }
  };

  const handleBlur = async () => {
    setIsFocused(false);
    dispatch(handleURLChangePending({ guid }));

    const newURL = stripAndEncodeSpacesInURL(unescapeURL(url));
    setUrl(newURL);

    if (isURLChanged) {
      const trackingParams = getCommonTrackingParams({ mediaItem });
      trackingParams['Share URL Before Edit'] = unescapeURL(propURL);
      trackingParams['Share URL'] = unescapeURL(newURL);
      tracker.track({
        eventName: 'Edit Share URL',
        trackingParams,
      });
    }

    let newIsURLValid = false;
    let isURLResolved = false;
    let fallbackURL = unescapeURL(newURL); // If resolve fails we will update state.url with its original value
    let resolvedURL = fallbackURL;
    let newErrorMessage = '';
    try {
      const validatedURL = await validateURL(unescapeURL(newURL));

      newIsURLValid = true;
      fallbackURL = validatedURL;
      const accountAPIId = MediaItem.getAccountAPIId({
        mediaItem,
      });
      const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
      const resolvedResponse = await getPropertiesResolveURL({
        propertyId,
        url: validatedURL,
      });
      isURLResolved = !!resolvedResponse.isResolved;
      resolvedURL = resolvedResponse.resolvedURL ?? '';
    } catch (error: any) {
      if (typeof error === 'string') {
        newErrorMessage = error;
      } else if (error instanceof Error || error instanceof ErrorEvent) {
        newErrorMessage = error.message;
      } else if (error.error?.message) {
        newErrorMessage = error.error.message;
      }
    }

    setErrorMessage(newErrorMessage);
    setIsURLValid(newIsURLValid);
    setUrl(resolvedURL);
    saveURLChange({
      updatedURL: unescapeURL(resolvedURL),
      isURLChanged,
      isURLResolved,
      isURLValid: newIsURLValid,
    });
  };

  const handleURLChange = (event: ContentEditableEvent) => {
    const newURL = event.target.value;
    if (newURL === placeholder) {
      return;
    }

    // Maintain URL
    const newIsURLChanged =
      removeTimestamp(unescapeURL(propURL)) !==
      removeTimestamp(unescapeURL(newURL));

    const newIsURLValid = validateURL(newURL, false) as boolean;

    setUrl(newURL);
    setIsURLChanged(newIsURLChanged);
    setIsURLValid(newIsURLValid);
  };

  let className = 'with-placeholder';
  if (!isFocused) {
    className = `${className} text-truncate`;
  }

  const isURLResolved = MediaItem.getIsURLResolved({ mediaItem });
  const validClass = isURLResolved === false || !isURLValid ? 'invalid' : '';

  const articleURL = unescapeURL(url);

  return (
    <>
      <div className={`url_article d-flex ${validClass}`} data-cy-input="url">
        <ContentEditable
          data-cy="content-editable"
          placeholder={placeholder}
          className={className}
          html={url}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onCopy={handleCopy}
          onPaste={handlePaste}
          onFocus={() => setIsFocused(true)}
          onChange={handleURLChange}
          style={{ padding: '6px 6px 6px 8px', width: '100%' }}
        />
        <a
          className="border-left"
          href={articleURL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ padding: '6px 6px 6px 8px' }}
          onClick={() =>
            trackOpenArticle(articleURL, 'Compose Box (preview URL field)')
          }
        >
          <img
            src="/img/icons/ic-open-in-new.svg"
            alt=""
            className="op-50 p-1"
          />
        </a>
      </div>
      {(!isURLValid || !isURLResolved) && (
        <div data-cy="url-validation" className="error_validation">
          {errorMessage ||
            'Echobox was unable to process the URL. Please check and try again.'}
        </div>
      )}
    </>
  );
}
