import {
  Button,
  CheckCircleIcon,
  HStack,
  Link,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';

import InstallationGuideModal from './InstallationGuideModal';

const ConnectedWebsiteTag = () => {
  const [isInstallationGuideOpen, setIsInstallationGuideOpen] = useState(false);
  const { propertyRootURL } = getCurrentProperty();

  return (
    <>
      <VStack spacing={6} alignItems="baseline">
        <HStack spacing={1}>
          <CheckCircleIcon color="green.600" size={5} />
          <Text size="sm">
            Receiving data from{' '}
            <Link href={propertyRootURL} target="_blank" fontWeight="normal">
              {propertyRootURL}
            </Link>
          </Text>
        </HStack>
        <Button
          variant="secondary"
          onClick={() => setIsInstallationGuideOpen(true)}
        >
          View installation guide
        </Button>
      </VStack>
      {isInstallationGuideOpen && (
        <InstallationGuideModal
          onClose={() => setIsInstallationGuideOpen(false)}
        />
      )}
    </>
  );
};

export default ConnectedWebsiteTag;
