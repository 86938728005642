import * as React from 'react';

import type { FixTypeLater } from 'types';

interface PageImageContextInterface {
  accountAPI: FixTypeLater;
}

const PageImageContext = React.createContext<PageImageContextInterface | null>(
  null,
);
PageImageContext.displayName = 'PageImageContext';

const usePageImageContext = () => {
  const pageImageContext = React.useContext(PageImageContext);

  if (pageImageContext === undefined) {
    throw new Error(
      'usePageImageContext must be used within a PageImageProvider',
    );
  }
  if (pageImageContext === null) {
    throw new Error('PageImageContext must be initialized by the Provider');
  }

  return pageImageContext;
};

interface PageImageProviderProps {
  accountAPI: FixTypeLater;
  children: React.ReactNode;
}

const PageImageProvider = ({
  accountAPI,
  children,
}: PageImageProviderProps) => {
  const contextValue = React.useMemo(
    () => ({
      accountAPI,
    }),
    [accountAPI],
  );

  return (
    <PageImageContext.Provider value={contextValue}>
      {children}
    </PageImageContext.Provider>
  );
};

export { PageImageProvider, usePageImageContext };
