import { Box, Button, Link, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useReducer, useState } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { debounce } from 'throttle-debounce';

import postAPIsSettingsType from 'api/postAPIsSettingsType';
import {
  getAccountAPI,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import { compareArrays } from 'common/array';
import { ACCOUNT_SETTING_TYPES, GLOBAL_INFO_STATES } from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import { getAllRSSFeeds, getFeedTitleOrDefault } from 'common/feed';
import { addErrorNotification } from 'common/notifications';
import { getSetting } from 'common/settings';
import PageImage from 'components/misc/PageImage';
import EditContentFeedsCheckbox from 'components/settings/pages/EditContentFeedsCheckbox';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import type { AccountFeed } from 'types';

function sortFeeds(feeds: Array<AccountFeed>) {
  return [...feeds].sort((feedA, feedB) => {
    const titleA = getFeedTitleOrDefault(feedA).toLowerCase();
    const titleB = getFeedTitleOrDefault(feedB).toLowerCase();

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }

    if (feedA.syndFeedURL < feedB.syndFeedURL) {
      return -1;
    }
    if (feedA.syndFeedURL > feedB.syndFeedURL) {
      return 1;
    }

    return 0;
  });
}

type ActionType =
  | { type: 'TOGGLE_FEED'; payload: string }
  | { type: 'SELECT_ALL'; payload: Array<string> };

function reducer(state: Array<string>, action: ActionType) {
  switch (action.type) {
    case 'TOGGLE_FEED':
      if (state.includes(action.payload)) {
        return state.filter((urn) => urn !== action.payload);
      }

      return [...state, action.payload];

    case 'SELECT_ALL':
      return action.payload;

    default:
      throw new Error(`Invalid action type: ${(action as any).type}`);
  }
}

const resetIsModalBodyScrolling = debounce(
  500,
  (setIsModalBodyScrolling: React.Dispatch<React.SetStateAction<boolean>>) =>
    setIsModalBodyScrolling(false),
);

const useIsModalBodyScrolling = () => {
  const [isModalBodyScrolling, setIsModalBodyScrolling] = useState(false);

  const handleModalBodyScroll = () => {
    setIsModalBodyScrolling(true);

    // Function is debounced, so it will only be called once the user stops scrolling
    resetIsModalBodyScrolling(setIsModalBodyScrolling);
  };

  return { isModalBodyScrolling, handleModalBodyScroll };
};

interface EditContentFeedsModalProps {
  accountAPIId: number;
  onClose: () => void;
}

const EditContentFeedsModal = ({
  accountAPIId,
  onClose,
}: EditContentFeedsModalProps) => {
  const propertyId = getPropertyIdForAccountAPIId({ accountAPIId });
  const connectedFeedURNsForPage =
    getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
      propertyId,
      accountAPIId,
    })?.dataJSON ?? [];

  const [selectedFeeds, dispatch] = useReducer(
    reducer,
    connectedFeedURNsForPage,
  );
  const [isLoading, setIsLoading] = useState(false);
  const { isModalBodyScrolling, handleModalBodyScroll } =
    useIsModalBodyScrolling();
  const { global } = useGlobalInfo();

  const haveChangesBeenMade = !compareArrays(
    selectedFeeds,
    connectedFeedURNsForPage,
    false,
  );

  const accountAPI = getAccountAPI({ accountAPIId });

  if (accountAPI === null) {
    return null;
  }

  const { apiPostName } = accountAPI;
  const allRSSFeedsForCurrentProperty = getAllRSSFeeds();
  const allFeedsSorted = sortFeeds(allRSSFeedsForCurrentProperty);

  const handleSelectAll = () => {
    dispatch({
      type: 'SELECT_ALL',
      payload: allRSSFeedsForCurrentProperty.map((feed) => feed.syndFeedURN),
    });
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      await postAPIsSettingsType({
        accountAPIId,
        settingTypeId: ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES,
        enabled: true,
        dataJSON: selectedFeeds,
      });
      global.refreshGlobalInfo({
        allowUnderSetup: true,
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        successMessage: 'RSS Feeds updated',
        callback: () => {
          setIsLoading(false);
          onClose();
        },
      });
    } catch (error) {
      const newErrorMessage = getErrorMessage(error);
      addErrorNotification(newErrorMessage);
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header flexDirection="row" gap={3} alignItems="center">
        <PageImage accountAPI={accountAPI}>
          <PageImage.SocialLogo size={5} svgSize={3.5} />
          <PageImage.Image size={10} />
        </PageImage>
        <Box>
          <Modal.Title>Content feeds</Modal.Title>
          <Modal.Subtitle>{apiPostName}</Modal.Subtitle>
        </Box>
      </Modal.Header>
      <Modal.Body onScroll={handleModalBodyScroll}>
        <Button
          onClick={handleSelectAll}
          variant="link"
          mb={3}
          _focus={{ boxShadow: 'none', outline: 'none' }}
          _focusVisible={{
            boxShadow: 'focus',
          }}
        >
          Select all
        </Button>
        {allFeedsSorted.map((feed) => (
          <EditContentFeedsCheckbox
            key={feed.syndFeedURN}
            feed={feed}
            isChecked={selectedFeeds.includes(feed.syndFeedURN)}
            isTooltipDisabled={isModalBodyScrolling}
            onChange={() =>
              dispatch({ type: 'TOGGLE_FEED', payload: feed.syndFeedURN })
            }
          />
        ))}
      </Modal.Body>
      <Modal.Footer color="gray.900">
        <Link
          as={ReactRouterLink}
          className="mr-auto"
          color="gray.900"
          to={`/settings/content-feeds?showSettingsForPropertyId=${propertyId}`}
        >
          Content feeds settings
        </Link>
        <Button
          variant="secondary"
          data-dismiss="modal"
          onClick={onClose}
          isDisabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          loadingText="Saving"
          isLoading={isLoading}
          isDisabled={
            selectedFeeds.length === 0 || isLoading || !haveChangesBeenMade
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditContentFeedsModal;
