import * as React from 'react';

interface InlineTagProps {
  children: React.ReactNode;
}

const InlineTag = ({ children }: InlineTagProps) => (
  <span className="share_tag_message" data-cy-id="inline-tag">
    {children}
  </span>
);

export default InlineTag;
