import { formatLikesFollowers } from 'common/string';
import Tooltip from 'components/misc/Tooltip';
import { Mention } from 'types';

interface TagSearchResultProps {
  result: Mention;
  isPopup?: boolean;
  onSave: (args: {
    raw: string | undefined;
    clean: string | null;
    username: string | null;
  }) => void;
}

const TagSearchResult = ({ result, isPopup, onSave }: TagSearchResultProps) => (
  <div
    data-cy-id={result.username}
    key={`tag-search-result-${result.username}`}
  >
    <a
      className={isPopup ? 'tag_result_popup' : 'tag_result'}
      onClick={() =>
        onSave({
          raw: result.pageId,
          clean: result.name,
          username: result.username,
        })
      }
      key={`tag-search-result-link-${result.pageId}`}
    >
      <div
        className="tag_result_box"
        style={{
          backgroundImage: `url('${result.profileImage}'`,
        }}
      />

      <div className="d-flex flex-column">
        <div className="d-flex align-items-center lh-14 tag_result_element">
          {result.verified && (
            <Tooltip id="verified" label="This is a verified page">
              <img
                src="/img/icons/ic-check-fb.svg"
                className="verified-account"
                style={{ width: '12px' }}
                alt=""
                data-tip
                data-cy="verified-check"
                data-for="verified"
                data-iscapture="true"
              />
            </Tooltip>
          )}

          <div
            className="text-truncate text-700 ml-1 tag_result_element"
            style={{ maxWidth: '65%' }}
            data-cy="tag-name"
          >
            {result.name}
          </div>
          <div className="text-truncate ft-12 op-60 ml-auto tag_result_element">
            {formatLikesFollowers(result.likes)}
          </div>
        </div>
        <div className="ft-12 op-60 tag_result_element">@{result.username}</div>
      </div>
    </a>
  </div>
);

export default TagSearchResult;
