import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import * as authentication from 'common/authentication';
import {
  COMPONENT_STABLE_AFTER_DURATION,
  GOOGLE_ANALYTICS_TRACKING_ID,
} from 'common/config';
import { FRONTEND_PAGE_MAP } from 'common/constants';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

// Record performance metrics
const performanceCallback: PerformanceObserverCallback = (list) => {
  list.getEntries().forEach((entry) => {
    switch (entry.entryType) {
      case 'measure':
        // React automatically logs internal and component lifecycle events
        // so we need to filter these out and just listen for custom measures
        // with the EBX prefix that we have recorded manually
        if (entry.name.startsWith('DEL:') || entry.name.startsWith('EBX:')) {
          const prefix = entry.name.split(':')[0];
          const name = entry.name.split(':')[1];
          const duration =
            Math.floor(entry.duration) -
            (prefix === 'DEL' ? COMPONENT_STABLE_AFTER_DURATION : 0);
          logger.info(`GA:Performance:App Performance - ${name} ${duration}`);
          ReactGA.timing({
            category: 'App Performance',
            variable: name,
            value: duration,
          });
        }
        break;
      case 'navigation': {
        const navigationEntry = entry as PerformanceNavigationTiming;
        logger.info(
          `GA:Performance:Server Latency ${
            navigationEntry.responseStart - navigationEntry.requestStart
          }`,
        );
        ReactGA.timing({
          category: 'Load Performance',
          variable: 'Server Latency',
          value: navigationEntry.responseStart - navigationEntry.requestStart,
        });
        logger.info(
          `GA:Performance:Download Time ${
            navigationEntry.responseEnd - navigationEntry.responseStart
          }`,
        );
        ReactGA.timing({
          category: 'Load Performance',
          variable: 'Download Time',
          value: navigationEntry.responseEnd - navigationEntry.responseStart,
        });
        logger.info(
          `GA:Performance:Total Load Time ${
            navigationEntry.responseStart - navigationEntry.requestStart
          }`,
        );
        ReactGA.timing({
          category: 'Load Performance',
          variable: 'Total Load Time',
          value: navigationEntry.responseStart - navigationEntry.requestStart,
        });
        break;
      }
      default:
    }
  });
};

// Track performance metrics
const performanceObserver = new PerformanceObserver(performanceCallback);
performanceObserver.observe({ entryTypes: ['measure'] }); // Add 'navigation' to also capture navigation events

// Google Analytics - initialise analytics tracking
ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID, {
  debug: false,
  gaOptions: {
    cookieDomain: 'none',
    siteSpeedSampleRate: 100,
  },
});

const isInFrontendPage = (
  pathname: string,
): pathname is keyof typeof FRONTEND_PAGE_MAP => {
  return pathname in FRONTEND_PAGE_MAP;
};

interface ReactGAWrapperProps {
  children: React.ReactNode;
}

const ReactGAWrapper = ({ children }: ReactGAWrapperProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
    if (isInFrontendPage(pathname) && authentication.isLoggedIn()) {
      metrics.mark(FRONTEND_PAGE_MAP[pathname]);
    }
  }, [pathname]);

  return children;
};

export default ReactGAWrapper;
