import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { SHARE_TIME_TYPES } from 'common/constants';
import type { ShareTime } from 'types';

import type { DatePickerTargetString } from '../TimingOptions';

interface SpecificTimeProps {
  calendarIcon: () => JSX.Element;
  guid: string;
  inputClass: (key: DatePickerTargetString) => string;
  isOpen: {
    time: {
      time: boolean;
      date: boolean;
    };
  };
  shareTime: Extract<ShareTime, { type: typeof SHARE_TIME_TYPES.TIME }>;
  timeFormat: string;
  onSpecificTimeDateChange: (
    date?: Date | undefined,
    dateStr?: string | undefined,
  ) => void;
  onSpecificTimeDateClick: React.MouseEventHandler<any>;
  onSpecificTimeDateKeyDown: React.KeyboardEventHandler<any>;
  onSpecificTimeDateBlur: () => void;
  onSpecificTimeTimeChange: (
    date?: Date | undefined,
    dateStr?: string | undefined,
  ) => void;
  onSpecificTimeTimeClick: React.MouseEventHandler<any>;
  onSpecificTimeTimeKeyDown: React.KeyboardEventHandler<any>;
  onSpecificTimeTimeBlur: () => void;
  onToggle: () => void;
}

const SpecificTime = ({
  calendarIcon,
  guid,
  inputClass,
  isOpen,
  shareTime,
  timeFormat,
  onSpecificTimeDateBlur,
  onSpecificTimeDateChange,
  onSpecificTimeDateClick,
  onSpecificTimeDateKeyDown,
  onSpecificTimeTimeBlur,
  onSpecificTimeTimeChange,
  onSpecificTimeTimeClick,
  onSpecificTimeTimeKeyDown,
  onToggle,
}: SpecificTimeProps) => (
  <div className="time_selection_details pt-2">
    <div className="time_selection_picker">
      <DateTimePicker
        containerClassName={inputClass('time.date')}
        date
        dateIcon={calendarIcon()}
        editFormat="DD-MMM-YY"
        format="DD-MMM-YY"
        // @ts-expect-error -- Some of the properties throw an error, but they appear to be working and are used here.
        footer
        min={new Date()}
        name={`time_date__${guid}`}
        data-cy-input="specificTimeDate"
        onChange={onSpecificTimeDateChange}
        onClick={onSpecificTimeDateClick}
        onKeyDown={onSpecificTimeDateKeyDown}
        onToggle={onToggle}
        onBlur={onSpecificTimeDateBlur}
        open={isOpen.time.date ? 'date' : false}
        time={false}
        value={new Date(shareTime.time * 1000)}
        views={['month']}
      />
    </div>
    <div className="time_selection_picker">
      <DateTimePicker
        containerClassName={inputClass('time.time')}
        date={false}
        dateIcon={calendarIcon()}
        editFormat={timeFormat}
        format={timeFormat}
        // @ts-expect-error -- Some of the properties throw an error, but they appear to be working and are used here.
        footer
        name={`time_time__${guid}`}
        data-cy-input="specificTimeTime"
        onChange={onSpecificTimeTimeChange}
        onClick={onSpecificTimeTimeClick}
        onKeyDown={onSpecificTimeTimeKeyDown}
        onToggle={onToggle}
        onBlur={onSpecificTimeTimeBlur}
        open={isOpen.time.time ? 'time' : false}
        step={30}
        time
        timeFormat={timeFormat}
        value={new Date(shareTime.time * 1000)}
        views={['month']}
      />
    </div>
  </div>
);

export default SpecificTime;
