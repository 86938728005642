export enum SAVE_STATES {
  NONE = 'NONE',
  SAVING = 'SAVING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum ARTICLE_AGE_UNITS {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export enum MESSAGE_SOURCES {
  OPTIMAL_MESSAGE = 'OPTIMAL_MESSAGE',
  RSS_TITLE = 'RSS_TITLE',
  RSS_DESCRIPTION = 'RSS_DESCRIPTION',
  OG_TITLE = 'OG_TITLE',
  OG_DESCRIPTION = 'OG_DESCRIPTION',
  TWITTER_TITLE = 'TWITTER_TITLE',
  TWITTER_DESCRIPTION = 'TWITTER_DESCRIPTION',
  NULL = 'NULL',
}

export enum IMAGE_SOURCES {
  RSS_IMAGE_LARGEST = 'RSS_IMAGE_LARGEST',
  OG_IMAGE_LARGEST = 'OG_IMAGE_LARGEST',
  TWITTER_IMAGE_LARGEST = 'TWITTER_IMAGE_LARGEST',
  NULL = 'NULL',
}
