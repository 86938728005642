import * as React from 'react';

import {
  Accordion,
  AccordionItemHeaderProps,
  Box,
  ChevronButton,
  Dropdown,
  Flex,
  Image,
  Link,
  OpenInNewIcon,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  getSocialNetworkImageSources,
  getSocialNetworkMessageSources,
} from 'common/social';
import { ImageSources, MediaPreviewMetadata, MessageSources } from 'types';

type DropdownOptions = 'og' | 'twitter' | 'rss';

const dropdownSources: Record<DropdownOptions, string> = {
  rss: 'RSS',
  og: 'Open Graph (OG)',
  twitter: 'Twitter',
};

const findFirstPopulatedSourceOption = (
  filteredSourcesMap: Record<
    DropdownOptions,
    {
      source: MessageSources | ImageSources;
      heading: string;
      content: string | undefined;
      isImage?: boolean;
    }[]
  >,
) => {
  const bestOption = Object.entries(filteredSourcesMap).find(
    ([, sources]) => sources.length > 0,
  );
  return bestOption?.[0] as DropdownOptions;
};

interface AvailableSourcesProps {
  metadata: MediaPreviewMetadata | null;
  apiTypeId: number;
  accordionStyles?: Partial<AccordionItemHeaderProps>;
}

const AvailableSources = ({
  metadata,
  apiTypeId,
  accordionStyles,
}: AvailableSourcesProps) => {
  const [selectedOption, setSelectedOption] =
    React.useState<DropdownOptions>('og');

  if (metadata === null) {
    return null;
  }

  const availableSources = [
    ...Object.keys(getSocialNetworkMessageSources({ apiTypeId })),
    ...Object.keys(getSocialNetworkImageSources({ apiTypeId })),
  ];

  const availableSourcesDisplayMap: Record<
    DropdownOptions,
    {
      source: MessageSources | ImageSources;
      heading: string;
      content: string | undefined;
      isImage?: boolean;
    }[]
  > = {
    og: [
      {
        source: 'OG_TITLE',
        heading: 'og:title',
        content: metadata.ogMetaInfo?.title,
      },
      {
        source: 'OG_DESCRIPTION',
        heading: 'og:description',
        content: metadata.ogMetaInfo?.description,
      },
      {
        source: 'OG_IMAGE_LARGEST',
        heading: 'og:image',
        content: metadata.ogMetaInfo?.imageURL,
        isImage: true,
      },
    ],
    twitter: [
      {
        source: 'TWITTER_TITLE',
        heading: 'twitter:title',
        content: metadata.twitterCardMetaInfo?.title,
      },
      {
        source: 'TWITTER_DESCRIPTION',
        heading: 'twitter:description',
        content: metadata.twitterCardMetaInfo?.description,
      },
      {
        source: 'TWITTER_IMAGE_LARGEST',
        heading: 'twitter:image',
        content: metadata.twitterCardMetaInfo?.imageURL,
        isImage: true,
      },
    ],
    rss: [
      {
        source: 'RSS_TITLE',
        heading: 'rss:title',
        content: metadata.syndfeedMetaInfo?.title,
      },
      {
        source: 'RSS_DESCRIPTION',
        heading: 'rss:description',
        content: metadata.syndfeedMetaInfo?.description,
      },
      {
        source: 'RSS_IMAGE_LARGEST',
        heading: 'rss:image',
        content: metadata.syndfeedMetaInfo?.imageURL,
        isImage: true,
      },
    ],
  };

  const handleChange: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setSelectedOption(e.currentTarget.value as DropdownOptions);
  };

  const filteredSourcesMap = Object.entries(availableSourcesDisplayMap).reduce(
    (prev, [option, sourcesDisplay]) => ({
      ...prev,
      [option]: sourcesDisplay.filter(
        (x) => x.content !== undefined && availableSources.includes(x.source),
      ),
    }),
    {},
  ) as {
    [key in DropdownOptions]: {
      source: MessageSources | ImageSources;
      heading: string;
      content: string | undefined;
      isImage?: boolean | undefined;
    }[];
  };

  const filteredSources = filteredSourcesMap[selectedOption];

  if (filteredSources.length === 0) {
    // Find the source option with at least one available source
    const bestOption = findFirstPopulatedSourceOption(filteredSourcesMap);
    setSelectedOption(bestOption);
  }

  return (
    <Accordion>
      <Accordion.Item>
        <Accordion.ItemHeader {...accordionStyles}>
          Available data sources
        </Accordion.ItemHeader>
        <Accordion.ItemPanel pr={0}>
          <Box maxW="3xs" my={2}>
            <Dropdown matchWidth>
              <Dropdown.Button
                as={ChevronButton}
                variant="secondary"
                w="180px"
                textAlign="left"
                aria-label="Select a data source group"
              >
                {dropdownSources[selectedOption]}
              </Dropdown.Button>
              <Dropdown.List>
                {Object.entries(dropdownSources).map(
                  ([source, fullSourceName]) => (
                    <Dropdown.Item
                      value={source}
                      aria-label={source}
                      key={`option-${source}`}
                      isDisabled={
                        filteredSourcesMap[source as DropdownOptions].length ===
                        0
                      }
                      onClick={handleChange}
                    >
                      {fullSourceName}
                    </Dropdown.Item>
                  ),
                )}
              </Dropdown.List>
            </Dropdown>
          </Box>
          <Flex
            direction="column"
            gap={4}
            border="1px"
            borderRadius="md"
            p={4}
            borderColor="gray.300"
            bg={accordionStyles ? 'base' : 'transparent'}
          >
            {filteredSources.map(({ heading, content, isImage }) => (
              <Flex key={heading} gap={4}>
                <Text size="sm" as="h5" fontWeight="medium" minW={24}>
                  {heading}
                </Text>
                <Text
                  size="sm"
                  color="gray.600"
                  display="flex"
                  alignItems="center"
                >
                  {!isImage ? (
                    content
                  ) : (
                    <>
                      <Image src={content} alt="" w={12} h={8} mr={2} />
                      <Link href={content} target="_blank" variant="secondary">
                        View image
                        <OpenInNewIcon ml={1} size={3} />
                      </Link>
                    </>
                  )}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Accordion.ItemPanel>
      </Accordion.Item>
    </Accordion>
  );
};

export default AvailableSources;
