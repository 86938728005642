/* eslint no-use-before-define:"off" */

import EchoboxAuth from '@ebx-auth/ebx-clientauth-frontend-sdk';
import { Auth } from 'aws-amplify';
import { jwtDecode } from 'jwt-decode';

import {
  COGNITO_CHALLENGE_NAMES,
  COLLECTION_NAMES,
  LOG_LEVELS,
  USER_STATES,
  USER_TYPES,
  USER_TYPES_PRIORITY,
} from 'common/constants';
import * as cookie from 'common/cookie';
import {
  isNull,
  isNullOrUndefined,
  isRunningTests,
  isUndefined,
} from 'common/utility';

import { API_PROPERTIES } from './constants';
import { convertToShortenAPIURN, convertToSocialPageURN } from './urn';

export {
  checkIfAnyAuthCookieExists,
  clearCognitoTokens,
  convertClientServiceTokenPermissions,
  convertPermissions,
  decodeJWTToken,
  deleteClientServiceToken,
  deleteOldKeys,
  getAccessToken,
  getChallengeDetails,
  getChallengeUser,
  getClientServiceToken,
  getCognitoGroups,
  getIdToken,
  getImpersonatedByEmail,
  getRefreshToken,
  getUserDetails,
  getUserIdFromCSToken,
  getUserPermissionsFromClientServiceToken,
  getUsername,
  hasChallengeDetails,
  isImpersonating,
  isLoggedIn,
  isStaffUser,
  isValidJWTToken,
  login,
  logout,
  refreshCognitoTokens,
  refreshPermission,
  setClientServiceToken,
  setKeyPrefix,
  storeChallengeDetails,
};

/**
 * Private functions
 */

const extractUserType = (payload) => {
  const groups = payload['cognito:groups'];
  if (groups) {
    return groups.sort(
      (a, b) => USER_TYPES_PRIORITY[b] - USER_TYPES_PRIORITY[a],
    )[0];
  }
  return null;
};

function getItem(key) {
  return sessionStorage.getItem(key);
}

function getKeyPrefix() {
  return cookie.getCookieValue('keyPrefix') ?? getItem('keyPrefix');
}

function getLocalItem(key) {
  const keyPrefix = getKeyPrefix();
  return localStorage.getItem(`${keyPrefix}.${key}`);
}

function getCognitoToken(key) {
  const fullKey = `${getKeyPrefix()}.${key}`;
  return cookie.getCookieValue(fullKey);
}

function removeItem(key) {
  sessionStorage.removeItem(key);
}

function setItem(key, value) {
  sessionStorage.setItem(key, value);
}

function setLocalItem(key, value) {
  const keyPrefix = getKeyPrefix();
  localStorage.setItem(`${keyPrefix}.${key}`, value);
}

function extractAccountAPIIdFromPermissionPart(permissionPart) {
  let accountAPIId;
  let socialAPIURN;
  const socialAPIParts = permissionPart.split(':');
  if (socialAPIParts.length === 2) {
    accountAPIId = socialAPIParts[1];
    const apiTypeId = socialAPIParts[0];
    if (API_PROPERTIES[apiTypeId]?.isShortenAPI) {
      socialAPIURN = convertToShortenAPIURN(
        API_PROPERTIES[apiTypeId]?.urnName,
        accountAPIId,
      );
    } else {
      socialAPIURN = convertToSocialPageURN(
        API_PROPERTIES[apiTypeId]?.urnName,
        accountAPIId,
      );
    }
  } else {
    throw new Error('Permissions not valid.');
  }
  return [accountAPIId, socialAPIURN];
}

/**
 * Public functions
 */

function convertPermissions(cognitoPermissions) {
  const ebxPermissions = [];
  if (cognitoPermissions.length > 0) {
    cognitoPermissions.split(',').forEach((cognitoPermission) => {
      const permissionParts = cognitoPermission.split(':');
      const permissionTypeId = permissionParts[0];
      const propertyId = permissionParts[1];
      const accountAPIId = permissionParts[2];
      const ebxPermission = {
        permissionTypeId: Number(permissionTypeId),
        propertyId: Number(propertyId),
      };
      if (accountAPIId !== '') {
        ebxPermission.accountAPIId = Number(accountAPIId);
      }
      ebxPermissions.push(ebxPermission);
    });
  }

  return ebxPermissions;
}

function convertClientServiceTokenPermissions(
  propertyPermissions,
  socialPagePermissions,
) {
  const ebxPermissions = [];

  // Get property level permissions
  if (propertyPermissions) {
    propertyPermissions.split(',').forEach((propertyPermission) => {
      const permissionParts = propertyPermission.split(':');
      const permissionTypeId = permissionParts[0];
      const propertyId = permissionParts[1];
      const ebxPermission = {
        permissionTypeId: Number(permissionTypeId),
        propertyId: Number(propertyId),
      };
      ebxPermissions.push(ebxPermission);
    });
  }

  // Get Social Page level permissions
  if (socialPagePermissions) {
    socialPagePermissions.split(',').forEach((socialPagePermission) => {
      const permissionParts = socialPagePermission.split(/:(.*)/s);
      const permissionTypeId = permissionParts[0];
      const [accountAPIId, socialAPIURN] =
        extractAccountAPIIdFromPermissionPart(permissionParts[1]);
      const ebxPermission = {
        permissionTypeId: Number(permissionTypeId),
        accountAPIId: Number(accountAPIId),
        socialAPIURN,
      };
      ebxPermissions.push(ebxPermission);
    });
  }
  return ebxPermissions;
}

function getAccessToken() {
  return getCognitoToken('accessToken');
}

function getChallengeDetails() {
  return window?.AWS?.challengeParam ?? null;
}

function getChallengeUser() {
  return window?.AWS?.user ?? null;
}

function getClientServiceToken() {
  return getLocalItem('clientServiceToken');
}

function getIdToken() {
  return getCognitoToken('idToken');
}

function getRefreshToken() {
  return getCognitoToken('refreshToken');
}

function getUserPermissionsFromClientServiceToken(activeUser) {
  const extractPropertyPermissions = (user) => {
    return user?.['custom:property_permissions'];
  };
  const extractSocialPagePermissions = (user) => {
    return user?.['custom:social_page_permissions'];
  };
  return convertClientServiceTokenPermissions(
    extractPropertyPermissions(activeUser),
    extractSocialPagePermissions(activeUser),
  );
}

function isValidJWTToken(token) {
  if (!token) {
    return false;
  }
  try {
    jwtDecode(token);
    return true;
  } catch (error) {
    return false;
  }
}

function getUserIdFromCSToken(clientServiceToken) {
  const decodedCSToken = decodeJWTToken(clientServiceToken);
  const loginIdComponents = decodedCSToken?.login_id?.split(' ');

  // The client service token may take one of 2 formats:
  // `{userPoolId} {userId}` or `{userId}`
  if (Array.isArray(loginIdComponents)) {
    const [part1, part2] = loginIdComponents;
    return part2 ?? part1;
  }
  return undefined;
}

const decodeJWTToken = (token) =>
  isValidJWTToken(token) ? jwtDecode(token) : undefined;

function getUserDetails(loginResponse, clientServiceToken) {
  const decodedCSTokenUser = decodeJWTToken(clientServiceToken);

  const userDetails = {
    challengeName: loginResponse?.challengeName,
    challengeParam: loginResponse?.challengeParam,
    emailAddress: decodedCSTokenUser?.email ?? loginResponse?.attributes?.email,
    keyPrefix: loginResponse?.keyPrefix,
    lastLoginTime: 0,
    name: decodedCSTokenUser?.name ?? loginResponse.attributes?.name,
    permissions: getUserPermissionsFromClientServiceToken(decodedCSTokenUser),
    timeCreated: loginResponse?.attributes?.['custom:initialLoginUnixTime'],
    userId: loginResponse?.username,
    userStateId: USER_STATES.ENABLED,
    userType: loginResponse?.signInUserSession?.accessToken?.payload
      ? extractUserType(loginResponse.signInUserSession.accessToken.payload)
      : null,
    username: loginResponse?.username,
    hasSetupMFA: !!loginResponse?.hasSetupMFA,
    accessToken: loginResponse?.signInUserSession
      ? loginResponse?.signInUserSession?.accessToken?.jwtToken
      : null,
    idToken: loginResponse?.signInUserSession
      ? loginResponse?.signInUserSession?.idToken?.jwtToken
      : null,
    refreshToken: loginResponse?.signInUserSession
      ? loginResponse?.signInUserSession?.refreshToken?.token
      : null,
    accessGroups:
      loginResponse?.signInUserSession?.accessToken?.payload?.[
        'cognito:groups'
      ],
  };
  return userDetails;
}

function refreshPermission(globalInfo) {
  const clientServiceToken = getClientServiceToken();
  const decodedCSTokenUser = decodeJWTToken(clientServiceToken);
  const permissions =
    getUserPermissionsFromClientServiceToken(decodedCSTokenUser);
  return {
    ...globalInfo.user,
    permissions,
  };
}

function getUsername() {
  try {
    return getKeyPrefix().split('.')[2];
  } catch (error) {
    return null;
  }
}

function hasChallengeDetails() {
  return !isNull(getChallengeDetails());
}

function isImpersonating() {
  try {
    const clientServiceToken = getClientServiceToken();
    if (clientServiceToken) {
      const decodedUser = decodeJWTToken(clientServiceToken);
      return 'impersonated_by_staff_email' in decodedUser;
    }
  } catch (error) {
    return false;
  }
  return false;
}

function isLoggedIn() {
  return !isNull(getClientServiceToken());
}

async function isStaffUser() {
  try {
    const clientServiceToken = getClientServiceToken();
    if (clientServiceToken) {
      const decodedUser = decodeJWTToken(clientServiceToken);
      const userGroups = decodedUser?.user_groups;
      const impersonatedByUserGroups = decodedUser?.impersonated_by_user_groups;

      if (!isNullOrUndefined(impersonatedByUserGroups)) {
        return (
          impersonatedByUserGroups.indexOf(USER_TYPES.ECHOBOX_STAFF) !== -1
        );
      }
      return userGroups?.indexOf(USER_TYPES.ECHOBOX_STAFF) !== -1;
    }

    const userDetail = await Auth.currentAuthenticatedUser();
    const userType = extractUserType(
      userDetail.signInUserSession.accessToken.payload,
    );
    return userType === USER_TYPES.ECHOBOX_STAFF;
  } catch (error) {
    return false;
  }
}

function login(signInResponse) {
  // Remove any existing error messages e.g. session terminated due to inactivity
  removeItem('flashMessages');
  removeItem('blockedErrors');

  /* Store key prefix and user type */
  const keyPrefix = `${signInResponse.keyPrefix}.${signInResponse.username}`;
  setKeyPrefix(keyPrefix);

  const { userType } = signInResponse;
  setItem(
    'logLevel',
    userType && userType === USER_TYPES.ECHOBOX_STAFF
      ? LOG_LEVELS.DEBUG
      : LOG_LEVELS.ERROR,
  );
}

function logout() {
  // Persist article feed timeframe filter in local storage
  const displayOptions = sessionStorage.getItem('displayOptions');
  if (!isNull(displayOptions)) {
    try {
      const timeframeFilter =
        JSON.parse(displayOptions)[COLLECTION_NAMES.ARTICLE_FEED].timeFrame;
      localStorage.setItem('timeframeFilter', timeframeFilter);
    } catch (error) {
      //
    }
  }
  // Clear all session data except current version and log level
  const logLevel = getItem('logLevel');
  clearCognitoTokens();
  deleteClientServiceToken();
  deleteAuthCookiesAtTLD();
  sessionStorage.clear();
  // Restore items we want to retain
  setItem('logLevel', logLevel);

  // Clear all performance data
  if (!isRunningTests()) {
    performance.clearMarks();
    performance.clearMeasures();
  }
}

function setKeyPrefix(keyPrefix) {
  // temporary change to delete any keyprefix stored at parent domain level as we have switched to using keyprefix at subdomain level
  cookie.deleteCookie('keyPrefix', {
    domain: cookie.getTLD(window.location.hostname),
  });
  cookie.setCookieValue('keyPrefix', keyPrefix, {
    domain: window.location.hostname,
  });
  setItem('keyPrefix', keyPrefix);
}

function setClientServiceToken(clientServiceToken) {
  return setLocalItem('clientServiceToken', clientServiceToken);
}

function storeChallengeDetails(loginResponse) {
  if (COGNITO_CHALLENGE_NAMES[loginResponse?.challengeName]) {
    if (isUndefined(window.AWS)) {
      window.AWS = {};
    }
    window.AWS.challengeParam = loginResponse.challengeParam;
    window.AWS.user = loginResponse;
  }
}

function deleteClientServiceToken() {
  const keyPrefix = getKeyPrefix();
  localStorage.removeItem(`${keyPrefix}.clientServiceToken`);
}

function deleteOldKeys() {
  const keysForDeletion = [
    'userData',
    'clockDrift',
    'accessToken',
    'clientServiceToken',
    'refreshToken',
    'idToken',
  ];

  const keyPrefix = getKeyPrefix();

  for (const key of Object.keys(localStorage)) {
    const lastIndex = key.lastIndexOf('.');
    const prefix = key.substring(0, lastIndex);
    const suffix = key.substring(lastIndex + 1, key.length);

    if (prefix !== keyPrefix) {
      if (keysForDeletion.includes(suffix)) localStorage.removeItem(key);
    }
  }
}

function clearCognitoTokens() {
  try {
    // delete all cookies which start with CognitoIdentityServiceProvider
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const eqPos = cookies[i].indexOf('=');
      const name = eqPos > -1 ? cookies[i].substr(0, eqPos) : cookies[i];
      if (name.trim().startsWith('CognitoIdentityServiceProvider')) {
        cookie.deleteCookie(name?.trim());
      }
    }
    cookie.deleteCookie('keyPrefix');
  } catch (e) {
    console.log(e);
  }
}

// temporary function to delete any auth cookies set at the top level domain
function deleteAuthCookiesAtTLD() {
  const tld = cookie.getTLD(window.location.hostname);
  const keyPrefix = getKeyPrefix();
  if (keyPrefix) {
    const keyPrefixParts = keyPrefix.split('.');
    const sessionKeyPrefix = `${keyPrefixParts[0]}.${keyPrefixParts[1]}`;
    const cookieNames = [
      `${keyPrefix}.clockDrift`,
      `${keyPrefix}.accessToken`,
      `${keyPrefix}.idToken`,
      `${keyPrefix}.refreshToken`,
      `${keyPrefix}.userData`,
      `${sessionKeyPrefix}.LastAuthUser`,
      keyPrefix,
    ];
    cookieNames.forEach((cookieName) => {
      cookie.deleteCookie(cookieName, {
        domain: tld,
      });
    });
  }
}

function checkIfAnyAuthCookieExists() {
  const keyPrefix = getKeyPrefix();
  if (keyPrefix) {
    const keyPrefixParts = keyPrefix.split('.');
    const sessionKeyPrefix = `${keyPrefixParts[0]}.${keyPrefixParts[1]}`;
    const cookieNames = [
      `${keyPrefix}.clockDrift`,
      `${keyPrefix}.accessToken`,
      `${keyPrefix}.idToken`,
      `${keyPrefix}.refreshToken`,
      `${keyPrefix}.userData`,
      `${sessionKeyPrefix}.LastAuthUser`,
      keyPrefix,
    ];
    return cookieNames.some((cookieName) => cookie.getCookieValue(cookieName));
  }
  return false;
}

async function refreshCognitoTokens() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const session = cognitoUser.getSignInUserSession();
  const refreshToken = session.refreshToken;
  return EchoboxAuth.refreshCognitoTokens(cognitoUser, refreshToken);
}

function getImpersonatedByEmail() {
  const clientServiceToken = getClientServiceToken();
  if (clientServiceToken) {
    const decodedUser = decodeJWTToken(clientServiceToken);
    return decodedUser.impersonated_by_staff_email;
  }

  return null;
}

function getCognitoGroups() {
  const idToken = getIdToken();
  const decodedToken = decodeJWTToken(idToken);

  if (decodedToken) {
    return decodedToken['cognito:groups'];
  }

  return null;
}
