import {
  Box,
  Check2Icon,
  Circle,
  Flex,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { cloneElement, createContext, useContext, useMemo } from 'react';

const StepsContext = createContext();

/**
 * React component for rendering steps workflow
 * Accepts the following props:
 * 1) activeStep (required) - an integer value indicating the current step in the workflow
 * 2) children (required)   - could be text or another react component
 * Any other props passed are sent to the parent div of the component
 * Also maintains an implicit state using react context storing active step
 * exposes one internal component - Step
 */
const Steps = ({ activeStep, children, ...rest }) => {
  const childrenArray = Array.isArray(children) ? children : [children];
  const steps = childrenArray.map((step, index) =>
    cloneElement(step, { index, ...step.props }),
  );

  const stepsContextValue = useMemo(() => {
    return {
      activeStep,
    };
  }, [activeStep]);

  return (
    <StepsContext.Provider value={stepsContextValue} {...rest}>
      <Flex alignItems="center" w="full" {...rest}>
        {steps}
      </Flex>
    </StepsContext.Provider>
  );
};

/**
 * React component for rendering individual steps
 * Renders a label and step number
 * Can only be used inside <Steps> component
 * Accepts the following props:
 * 1) children (required) - could be a text or another react component
 * Any other props passed are sent to the parent div of the component
 * Note: The index shown as a prop is automatically added from <Steps> component
 * so it should not be provided explicitly
 */
const Step = ({ children, index, ...rest }) => {
  const { activeStep } = useContext(StepsContext);
  const isActive = index === activeStep;
  const isCompleted = index < activeStep;
  const isActiveOrCompleted = isActive || isCompleted;
  const isFirstStep = index === 0;

  return (
    <Box flex={1} {...rest}>
      <Flex
        flexDirection="column"
        alignItems="center"
        position="relative"
        m={0}
      >
        {!isFirstStep && (
          <Box
            as="span"
            display="block"
            h="1px"
            bg="gray.300"
            position="absolute"
            top={3}
            left="calc(-50% + 20px)"
            right="calc(50% + 20px)"
          />
        )}
        <Circle
          as="span"
          fontSize="sm"
          size={6}
          mb={4}
          color="white"
          bg={isActiveOrCompleted ? 'primary.600' : 'gray.600'}
        >
          {isCompleted ? <Check2Icon /> : index + 1}
        </Circle>
        <Text
          size="md"
          fontWeight={isActiveOrCompleted ? 'medium' : 'hairline'}
          color={isActiveOrCompleted ? 'gray.900' : 'gray.600'}
        >
          {children}
        </Text>
      </Flex>
    </Box>
  );
};

Steps.propTypes = {
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

Step.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

Steps.Step = Step;

export default Steps;
