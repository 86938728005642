import Button from 'components/misc/Button';

interface DropdownFooterProps {
  clearLabel?: string;
  eventHandlers: {
    handleApply: () => void;
    handleClear: () => void;
  };
}

const DropdownFooter = ({
  clearLabel = 'Clear',
  eventHandlers,
}: DropdownFooterProps) => {
  return (
    <div className="dropdown-footer">
      <Button
        className="mr-2"
        variant="secondary"
        onClick={eventHandlers.handleClear}
        data-cy-action="clear"
      >
        {clearLabel}
      </Button>
      <Button
        variant="dark"
        onClick={eventHandlers.handleApply}
        data-cy-action="apply"
      >
        Apply
      </Button>
    </div>
  );
};

export default DropdownFooter;
