import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { FRONTEND_PAGES } from 'common/constants';
import { isOnPage } from 'common/path';
import { hideZendesk, showZendesk } from 'common/zendesk';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { GlobalInfo } from 'types';

// TODO: This could be converted to a custom hook as it doesn't output any JSX.
const ZendeskWidget = () => {
  const location = useLocation();
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo() as GlobalInfo.GlobalInfo;

  const isSettingsPage =
    isOnPage({ page: FRONTEND_PAGES.SETTINGS, location }) ||
    isOnPage({ page: FRONTEND_PAGES.SELECT, location });
  const isSetupPage = isOnPage({ page: FRONTEND_PAGES.SETUP, location });
  const isOnHomePage = !isSettingsPage && !isSetupPage;

  React.useEffect(() => {
    if (!isOnHomePage) {
      showZendesk(globalInfo?.user);
    } else {
      hideZendesk();
    }
  }, [globalInfo?.user, isOnHomePage]);

  return null;
};

export default ZendeskWidget;
