/* eslint no-use-before-define:"off" */

import { isDefined } from 'common/utility';
import { SAVE } from 'pubsub/MEDIA_ITEM/SAVE';

const handlers = {
  SAVE,
};

export function MEDIA_ITEM(subtopic, args) {
  if (isDefined(handlers[subtopic])) {
    handlers[subtopic](args);
  }
}
