import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';

import { PageData } from 'common/constants/socialListening';
import PagesTable from './PagesTable';

const Leaderboard = ({
  isLoading,
  data,
  onRemove,
}: {
  isLoading: boolean;
  data: PageData[];
  onRemove: (id: string) => void;
}) => {
  return isLoading ? (
    <Spinner color="primary" />
  ) : (
    <PagesTable pages={data} onRemove={onRemove} />
  );
};

export default Leaderboard;
