import {
  Box,
  Flex,
  Progress,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const Loading = ({ progress }: { progress: number | null }) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      gap={3}
      h="full"
    >
      <Text color="text.secondary" size="sm">
        {progress ? 'Creating video' : 'Preparing video'}
      </Text>
      {progress ? (
        <>
          <Progress
            value={progress ?? 0}
            w="240px"
            h="2px"
            color="primary.600"
            bg="gray.300"
            borderRadius="sm"
          />
          <Text color="text.secondary" size="xs">
            {progress ?? 0}%
          </Text>
        </>
      ) : (
        <Spinner color="gray" size="lg" mb="6px" />
      )}
    </Flex>
  );
};

interface VideoPreviewProps {
  isLoading: boolean;
  videoURL: string;
  progress: number | null;
}
const VideoPreview = ({ isLoading, videoURL, progress }: VideoPreviewProps) => {
  return (
    <Flex
      w="506px"
      flexShrink={0}
      flexDir="column"
      bg="gray.200"
      boxShadow="borderRight"
      overflow="hidden"
      h="full"
      pos="relative"
      _before={{
        content: '""',
        boxShadow: 'borderRight',
        inset: 0,
        position: 'absolute',
        pointerEvents: 'none',
        zIndex: 1,
      }}
    >
      {isLoading ? (
        <Loading progress={progress} />
      ) : (
        <Box flexGrow={1} pos="relative">
          <Box
            as="video"
            autoPlay
            loop
            controls
            src={videoURL}
            objectFit="contain"
            h="full"
            w="full"
            pos="absolute"
          />
        </Box>
      )}
    </Flex>
  );
};

export default VideoPreview;
