import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

import { getAPITypeId } from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  DATA_SOURCE_DEFAULTS,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import * as logger from 'common/logger';
import { saveAPIsSettings } from 'common/settings';
import {
  getDataSourceDefault,
  getSocialNetworkImageSources,
  getSocialNetworkMessageSources,
  hasDataSourceDefault,
} from 'common/social';
import * as tracker from 'common/tracker';
import {
  cloneObject,
  isDefined,
  isEmpty,
  isNullOrUndefined,
} from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import SaveChangesButtons from 'components/settings/SaveChangesButtons';
import withGlobalInfo from 'context/withGlobalInfo';
import {
  onSaveFailure,
  onSaveSuccess,
  setPanelButtonText,
} from 'helpers/settingsPage';
import { getNetworkAndPageName } from 'helpers/tracking';

import SharePreview from './SharePreview';

/**
 * Settings - Share Preview
 */

class DataSources extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const sharePreview = this.getSettingOrDefault(props.sharePreview);
    this.state = {
      metadata: null,
      isPreviewed: false,
      data: Immutable({
        sharePreview: sharePreview.dataJSON,
        isChanged: false,
        isSaving: false,
      }),
    };
    this.originalSettings = sharePreview;
    this._bind('handleCancel', 'handleDropDownChange', 'handleSave');
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    setPanelButtonText('collapseSharePreview');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('SharePreview', { nextProps, nextState });
  }

  /**
   * Helper methods
   */

  getSettingOrDefault(sharePreview) {
    // Get setting
    const setting = cloneObject(sharePreview);
    const apiTypeId = getAPITypeId({ accountAPIId: this.props.accountAPIId });

    // Set defaults if setting does not exist
    const textSources = Object.keys(
      getSocialNetworkMessageSources({ apiTypeId }),
    );
    const imageSources = Object.keys(
      getSocialNetworkImageSources({ apiTypeId }),
    );
    if (
      isEmpty(setting) ||
      (isDefined(setting.dataJSON) && isEmpty(setting.dataJSON))
    ) {
      setting.enabled = true;
      setting.dataJSON = {
        messageSource: textSources[DATA_SOURCE_DEFAULTS.MESSAGE_SOURCE],
        imageSource:
          imageSources[
            getDataSourceDefault({
              apiTypeId,
              field: 'image',
              reason: 'empty',
            })
          ],
      };
      if (
        hasDataSourceDefault({
          apiTypeId,
          field: 'description',
          reason: 'empty',
        })
      ) {
        setting.dataJSON.descriptionSource =
          textSources[
            getDataSourceDefault({
              apiTypeId,
              field: 'description',
              reason: 'empty',
            })
          ];
      }
      if (
        hasDataSourceDefault({
          apiTypeId,
          field: 'title',
          reason: 'empty',
        })
      ) {
        setting.dataJSON.titleSource =
          textSources[
            getDataSourceDefault({
              apiTypeId,
              field: 'title',
              reason: 'empty',
            })
          ];
      }
    }

    // Process setting elements that might be stored as null
    if (
      isNullOrUndefined(setting.dataJSON.descriptionSource) &&
      hasDataSourceDefault({
        apiTypeId,
        field: 'description',
        reason: 'missing',
      })
    ) {
      setting.dataJSON.descriptionSource =
        textSources[
          getDataSourceDefault({
            apiTypeId,
            field: 'description',
            reason: 'missing',
          })
        ];
    }
    if (
      isNullOrUndefined(setting.dataJSON.imageSource) &&
      hasDataSourceDefault({ apiTypeId, field: 'image', reason: 'missing' })
    ) {
      setting.dataJSON.imageSource =
        imageSources[
          getDataSourceDefault({ apiTypeId, field: 'image', reason: 'missing' })
        ];
    }
    if (
      isNullOrUndefined(setting.dataJSON.messageSource) &&
      hasDataSourceDefault({
        apiTypeId,
        field: 'message',
        reason: 'missing',
      })
    ) {
      setting.dataJSON.messageSource =
        textSources[
          getDataSourceDefault({
            apiTypeId,
            field: 'message',
            reason: 'missing',
          })
        ];
    }
    if (
      isNullOrUndefined(setting.dataJSON.titleSource) &&
      hasDataSourceDefault({ apiTypeId, field: 'title', reason: 'missing' })
    ) {
      setting.dataJSON.titleSource =
        textSources[
          getDataSourceDefault({
            apiTypeId,
            field: 'title',
            reason: 'missing',
          })
        ];
    }

    // Return setting
    return setting;
  }

  /**
   * Event handlers
   */

  handleCancel() {
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set('sharePreview', this.originalSettings.dataJSON)
          .set('isChanged', false),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'sharePreview',
          hasSettingChanged: false,
        }),
    );
  }

  handleDropDownChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    logger.info(`SharePreview:handleDropDownChange - ${name} ${value}`);
    this.setState(
      (prevState) => ({
        data: prevState.data
          .setIn(['sharePreview', name], value)
          .set('isChanged', true),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'sharePreview',
          hasSettingChanged: false,
        }),
    );
  }

  handleSave() {
    if (this.state.isPreviewed) {
      const { accountAPIId } = this.props;
      const { sharePreview } = this.state.data;
      const previousSharePreview = this.originalSettings.dataJSON;

      tracker.track({
        eventName: 'Update Manual Data Sources Settings',
        trackingParams: {
          'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
          'Account API Id': accountAPIId,
          'Data Sources Message': sharePreview.messageSource,
          'Data Sources Title': sharePreview.titleSource,
          'Data Sources Description': sharePreview.descriptionSource,
          'Data Sources Image': sharePreview.imageSource,
          'Previous Data Sources Message': previousSharePreview.messageSource,
          'Previous Data Sources Title': previousSharePreview.titleSource,
          'Previous Data Sources Description':
            previousSharePreview.descriptionSource,
          'Previous Data Sources Image': previousSharePreview.imageSource,
        },
      });
    }

    this.setState(
      (prevState) => ({
        data: prevState.data.set('isSaving', true).set('isChanged', false),
      }),
      () => {
        const settingConfig = {
          accountAPIId: this.props.accountAPIId,
          settingTypeId: ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES,
          enabled: this.originalSettings.enabled,
          dataJSON: Immutable.asMutable(this.state.data.sharePreview),
        };
        // Convert literal string NULL to actual null value
        [
          'descriptionSource',
          'imageSource',
          'messageSource',
          'titleSource',
        ].forEach((key) => {
          if (settingConfig.dataJSON[key] === 'NULL') {
            settingConfig.dataJSON[key] = null;
          }
        });
        saveAPIsSettings.call(this, {
          settingConfig,
          callback: () => {
            this.originalSettings.enabled = settingConfig.enabled;
            this.originalSettings.dataJSON = settingConfig.dataJSON;
            this.props.eventHandlers.handleHasSettingChanged({
              key: 'sharePreview',
              hasSettingChanged: false,
            });
            onSaveSuccess.call(this);
          },
          errorHandler: () => {
            onSaveFailure.call(this);
          },
        });
      },
    );
  }

  /**
   * Render methods
   */

  render() {
    if (!this.originalSettings.enabled) {
      return REACT_PREVENT_RENDER;
    }

    return (
      <>
        <div className="setting-part d-flex flex-row border-top">
          <div className="mr-auto d-flex flex-column">
            <div className="text-700 ft-14 lh-20">Default Share Preview</div>
            <div className="text-400 ft-12 mr-2">
              Define what should be in the share preview
            </div>
          </div>
          <div
            className="btn btn-light align-self-center"
            data-cy-action="editDataSource"
            data-toggle="collapse"
            data-target="#collapseSharePreview"
            role="button"
            aria-expanded="false"
            aria-controls="collapseSharePreview"
          >
            Edit
          </div>
        </div>
        <div
          className="collapse settings-sub settings-share-preview"
          id="collapseSharePreview"
          data-parent="#accordion"
          data-cy-id="dataSourceSettings"
        >
          <div className="pt-4 pb-3 border-top">
            <SharePreview
              accountAPIId={this.props.accountAPIId}
              dataSourceSettings={this.state.data.sharePreview}
              isSaving={this.state.data.isSaving}
              onDropDownChange={this.handleDropDownChange}
              onPreview={() => this.setState({ isPreviewed: true })}
            />
            <SaveChangesButtons
              disabled={!this.state.data.isChanged || this.state.data.isSaving}
              eventHandlers={{
                handleCancel: this.handleCancel,
                handleSave: this.handleSave,
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

DataSources.propTypes = {
  accountAPIId: PropTypes.string.isRequired,
  sharePreview: PropTypes.object.isRequired,
  eventHandlers: PropTypes.shape({
    handleHasSettingChanged: PropTypes.func,
  }),
};

DataSources.defaultProps = {
  eventHandlers: {
    handleHasSettingChanged: () => {},
  },
};

export default withGlobalInfo(DataSources);
