import {
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React, { useState } from 'react';

import { getAPIsSettingsByTypeAndSocialPageURNs } from 'api/getAPIsSettingsByType';
import getPropertiesAPIs from 'api/getPropertiesAPIs';
import { postAPIsSettingsTypeBySocialPageURN } from 'api/postAPIsSettingsType';
import postBillingUpload from 'api/postBillingUpload';
import postServiceAuth from 'api/postServiceAuth';
import * as authentication from 'common/authentication';
import { ACCOUNT_SETTING_TYPES, FLASH_MESSAGE_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import { convertToPropertyURN, convertToSocialPageURN } from 'common/urn';
import { isNullOrUndefined } from 'common/utility';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import { FixTypeLater, Settings, SocialAPI } from 'types';
import OpsToolsBillingTSV from './OpsToolsBillingTSV';

interface OpsToolsModalInterface {
  onClose: () => void;
}

const OpsToolsModal = ({ onClose }: OpsToolsModalInterface) => {
  const [isAutofeedLoading, setIsAutofeedLoading] = useState(false);
  const [isBillingLoading, setIsBillingLoading] = useState(false);
  const [billingErrorMessage, setBillingErrorMessage] = useState<string | null>(
    null,
  );
  const [tsvFile, setTSVFile] = useState<File | null>(null);
  const flashMessagesContext = useFlashMessagesContext();
  const toast = useToast();

  const isDisabled = isAutofeedLoading || isBillingLoading;

  const handleAutofeedSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    const userCSToken = authentication.getClientServiceToken();

    const target = event.target as typeof event.target & {
      opsAutofeedPropertyId: { value: string };
    };
    const propertyId = Number(target.opsAutofeedPropertyId.value.trim());
    const propertyURN = convertToPropertyURN(propertyId);

    try {
      setIsAutofeedLoading(true);

      const impersonateCSToken = await postServiceAuth({
        overridePropertyURN: propertyURN,
      });

      authentication.setClientServiceToken(impersonateCSToken);

      const socialPages = await getPropertiesAPIs({
        propertyURNs: [propertyURN],
      }).then((data) => {
        return data[propertyId];
      });

      const socialPageURNs = socialPages.map((page: SocialAPI) => {
        return page.socialAPIURN;
      });

      const allPageAutofeedSettings =
        await getAPIsSettingsByTypeAndSocialPageURNs({
          socialPagesURNs: socialPageURNs,
          settingTypeIds: [ACCOUNT_SETTING_TYPES.AUTO_FEED],
        });

      await Promise.all(
        socialPages
          .filter((page: SocialAPI) => {
            return !isNullOrUndefined(
              allPageAutofeedSettings[page.accountAPIId],
            );
          })
          .map(async (page: SocialAPI) => {
            return disableAutofeedForSocialPage(
              page,
              extractPageAutofeedSettings(page, allPageAutofeedSettings),
            );
          }),
      );

      toast({
        title: 'Setting updated',
        description: 'Changes can take up to 8 hours to take effect',
        variant: 'success',
      });
    } catch (error: FixTypeLater) {
      flashMessagesContext.flashMessages.addMessage({
        messageCategory: 'Ops Tools POST disable autofeed',
        type: FLASH_MESSAGE_TYPES.ERROR,
        text: getErrorMessage(determineError(error)),
        timeout: 2500,
      });
    } finally {
      authentication.setClientServiceToken(userCSToken);
      setIsAutofeedLoading(false);
    }
  };

  const extractPageAutofeedSettings = (
    page: SocialAPI,
    autofeedSettings: Record<number, Settings>,
  ) => {
    return autofeedSettings[page.accountAPIId][
      ACCOUNT_SETTING_TYPES.AUTO_FEED
    ][0].dataJSON;
  };

  const disableAutofeedForSocialPage = async (
    page: SocialAPI,
    autofeedSettings: FixTypeLater,
  ) => {
    const existingSettings = autofeedSettings;
    existingSettings.autoFeedActive = false;

    const socialPageURN = page.socialAPIURN
      ? page.socialAPIURN
      : convertToSocialPageURN(page.apiType, page.accountAPIId);

    await postAPIsSettingsTypeBySocialPageURN({
      socialPageURN,
      settingTypeId: ACCOUNT_SETTING_TYPES.AUTO_FEED,
      enabled: true,
      dataJSON: existingSettings,
    });
  };

  const handleSetTSVFile = (file: File | null) => {
    setBillingErrorMessage(null);
    setTSVFile(file);
  };

  const handleBillingSubmit = async () => {
    setIsBillingLoading(true);
    setBillingErrorMessage(null);

    try {
      if (tsvFile != null) {
        await postBillingUpload(tsvFile);

        toast({
          title: 'Billing uploaded successfully',
          variant: 'success',
        });
      }
    } catch (error) {
      setBillingErrorMessage(getErrorMessage(error));
    } finally {
      setIsBillingLoading(false);
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Ops Tools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleAutofeedSubmit}>
          <FormControl>
            <FormControl.FormLabel htmlFor="propertyId">
              Disable Autofeed for all Pages in Property
            </FormControl.FormLabel>
            <HStack>
              <Input
                type="text"
                placeholder="Enter a Property Id..."
                id="opsAutofeedPropertyId"
              />
              <Button
                type="submit"
                variant="secondary"
                isLoading={isAutofeedLoading}
                isDisabled={isDisabled}
                loadingText="Submitting"
                flexShrink={0}
              >
                Submit
              </Button>
            </HStack>
          </FormControl>
        </form>
        <OpsToolsBillingTSV
          tsvFile={tsvFile}
          onSetTSVFile={handleSetTSVFile}
          errorMessage={billingErrorMessage}
          isDisabled={isDisabled}
          isLoading={isBillingLoading}
          onSubmit={() => handleBillingSubmit()}
        />
      </Modal.Body>
    </Modal>
  );
};

export default OpsToolsModal;
