import clsx from 'clsx';

import { COMPOSE_BOX_TABS } from 'common/constants';
import {
  ComposeBoxTab,
  handleSelectedTabChange,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';

const MobileMenu = () => {
  const { dispatch, selectedTab } = useComposeBoxContext();

  const handleTabChange = (tab: ComposeBoxTab) => {
    return () => dispatch(handleSelectedTabChange({ selectedTab: tab }));
  };

  return (
    <div className="menu d-md-none w-100 bg-light-blue border-top border-bottom">
      <a
        className={clsx(
          'w-40 text-center',
          selectedTab === COMPOSE_BOX_TABS.PREVIEW && 'selected',
        )}
        onClick={handleTabChange(COMPOSE_BOX_TABS.PREVIEW)}
      >
        Preview
      </a>
      <a
        className={clsx(
          'w-40 text-center',
          selectedTab === COMPOSE_BOX_TABS.TIMING && 'selected',
        )}
        onClick={handleTabChange(COMPOSE_BOX_TABS.TIMING)}
      >
        Timing
      </a>
    </div>
  );
};

export default MobileMenu;
