import { useQuery, useQueryClient } from '@tanstack/react-query';
import getCompetitorData from 'api/getCompetitorData';
import { getCurrentSocialPageURN } from 'common/accountAPIs';

const useCompetitorData = ({
  competitorIds,
  duration,
}: {
  competitorIds: string[];
  duration: number;
}) => {
  const queryClient = useQueryClient();
  const socialPageURN = getCurrentSocialPageURN() || '';

  const queryResults = useQuery({
    queryKey: ['competitors', socialPageURN, competitorIds, duration],
    queryFn: () => {
      // calculate from and to times from duration and current time in unix. Duration is in hours
      const to = Math.floor(Date.now() / 1000);
      const from = to - duration * 3600;
      return getCompetitorData({
        socialPageURN,
        fbPages: competitorIds,
        from,
        to,
      });
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: 1000 * 60 * 0.5, // 30 seconds
  });

  const setData = ({
    newCompetitorIds,
    data,
  }: {
    newCompetitorIds?: string[];
    data: any;
  }) => {
    queryClient.setQueryData(
      [
        'competitors',
        socialPageURN,
        newCompetitorIds ? newCompetitorIds : competitorIds,
        duration,
      ],
      data,
    );
  };

  return { ...queryResults, setData };
};

export default useCompetitorData;
