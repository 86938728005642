import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';
import ReactContentEditable from 'react-contenteditable';

/**
 * Component wrapping react-contenteditable which fixes the stale state issues
 * mentioned here: https://github.com/lovasoa/react-contenteditable/issues/161
 */

interface ExtendedComponentPropsWithoutRef
  extends ComponentPropsWithoutRef<typeof ReactContentEditable> {
  placeholder: string;
}

const ContentEditable = ({
  onChange,
  onInput,
  onBlur,
  onKeyPress,
  onKeyDown,
  ...props
}: ExtendedComponentPropsWithoutRef) => {
  const onChangeRef = useRef(onChange);
  const onInputRef = useRef(onInput);
  const onBlurRef = useRef(onBlur);
  const onKeyPressRef = useRef(onKeyPress);
  const onKeyDownRef = useRef(onKeyDown);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);
  useEffect(() => {
    onInputRef.current = onInput;
  }, [onInput]);
  useEffect(() => {
    onBlurRef.current = onBlur;
  }, [onBlur]);
  useEffect(() => {
    onKeyPressRef.current = onKeyPress;
  }, [onKeyPress]);
  useEffect(() => {
    onKeyDownRef.current = onKeyDown;
  }, [onKeyDown]);

  return (
    <ReactContentEditable
      {...props}
      onChange={(...args) => {
        onChangeRef.current?.(...args);
      }}
      onInput={
        onInput
          ? (...args) => {
              onInputRef.current?.(...args);
            }
          : undefined
      }
      onBlur={
        onBlur
          ? (...args) => {
              onBlurRef.current?.(...args);
            }
          : undefined
      }
      onKeyPress={
        onKeyPress
          ? (...args) => {
              onKeyPressRef.current?.(...args);
            }
          : undefined
      }
      onKeyDown={
        onKeyDown
          ? (...args) => {
              onKeyDownRef.current?.(...args);
            }
          : undefined
      }
    />
  );
};

export default ContentEditable;
