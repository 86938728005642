import { REACT_PREVENT_RENDER } from 'common/constants';
import { isUndefined } from 'common/utility';
import FlashMessage from 'components/misc/FlashMessage';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import withFlashMessages from 'context/withFlashMessages';
import type { FlashMessageType } from 'types';

interface FlashMessagesProps {
  displayTypes?: Array<FlashMessageType>;
  hidden?: boolean;
  mobileOnly?: boolean;
}

const FlashMessages = ({
  displayTypes = [],
  hidden = false,
  mobileOnly = false,
}: FlashMessagesProps) => {
  const { deleteMessage, messages } = useFlashMessagesContext().flashMessages;

  if (isUndefined(messages) || messages.length === 0 || hidden) {
    return REACT_PREVENT_RENDER;
  }

  return (
    <div className={mobileOnly ? 'd-md-none' : 'flash-messages-wrapper'}>
      {messages.map((message) => {
        if (
          displayTypes.length === 0 ||
          displayTypes.indexOf(message.type) !== -1
        ) {
          return (
            <FlashMessage
              key={message.id}
              message={message}
              onFlashClose={deleteMessage}
            />
          );
        }
        return REACT_PREVENT_RENDER;
      })}
    </div>
  );
};

export default withFlashMessages(FlashMessages);
