import isEqual from 'fast-deep-equal';
import Immutable, { Immutable as ImmutableType } from 'seamless-immutable';

/**
 * arePropsEqual
 */

function arePropsEqual(prevProps: any, nextProps: any) {
  return isEqual(prevProps, nextProps);
}

/**
 * cloneArray
 */

function cloneArray<TArray>(arr: TArray): TArray {
  return JSON.parse(JSON.stringify(arr));
}

/**
 * Clone an error object and all its properties
 */
function cloneError<T = unknown>(error: T): T {
  if (isNullOrUndefined(error)) {
    return error;
  }
  try {
    // If it's an error, constructor a new error and copy all properties
    // This is necessary because JSON.stringify will not copy non-enumerable properties
    if (error instanceof Error) {
      // @ts-expect-error
      const newError = new error.constructor(error.message);

      Object.getOwnPropertyNames(error).forEach((key) => {
        newError[key] = error[key as keyof typeof error];
      });

      return newError;
    }

    // If it's an object, clone it
    if (typeof error === 'object') {
      return cloneObject(error);
    }

    // Otherwise, return it as-is
    return error;
  } catch (e) {
    return error;
  }
}

/**
 * cloneObject
 */

function cloneObject<TObj>(obj: TObj): TObj {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * createEventHandler
 */

function createEventHandler(fn: () => void, ...outer: Array<any>) {
  return function unnamed(...inner: Array<any>) {
    // @ts-expect-error
    return fn.apply(this, outer.concat(Array.prototype.slice.call(inner, 0)));
  };
}

/**
 * immutableClone
 */

function immutableClone<TObj extends object>(
  obj: ImmutableType<TObj>,
): ImmutableType<TObj> {
  return Immutable(
    JSON.parse(JSON.stringify(Immutable.asMutable(obj, { deep: true }))),
  );
}

/**
 * isDefined
 */

function isDefined<TValue>(value: TValue | undefined): value is TValue {
  return !isUndefined(value);
}

/**
 * isEmpty
 */

function isEmpty(
  value: unknown,
): value is null | undefined | Record<string, never> {
  if (typeof value === 'undefined') return true;
  if (value === null) return true;
  if (typeof value !== 'object') return false;
  return !Object.keys(value).length;
}

/**
 * isEmptyOrNullOrUndefined
 */

function isEmptyOrNullOrUndefined<TValue>(
  value: TValue | null | undefined,
): value is null | undefined {
  return isEmpty(value) || isNull(value) || isUndefined(value);
}

/**
 * isError
 */

function isError(error: unknown) {
  switch ({}.toString.call(error)) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return error instanceof Error;
  }
}

/**
 * isErrorLike
 */

function isErrorLike(error: unknown) {
  return (
    !isError(error) &&
    typeof error === 'object' &&
    isDefined(error) &&
    isNotNull(error) &&
    'message' in error &&
    isDefined(error.message)
  );
}

/**
 * isNull
 */

function isNull<TValue>(value: TValue | null): value is null {
  return value === null;
}

/**
 * isNotNull
 */

function isNotNull<T>(arg: T): arg is Exclude<T, null> {
  return arg !== null;
}

/**
 * isNullOrUndefined
 */

function isNullOrUndefined<TValue>(
  value: TValue | null | undefined,
): value is null | undefined {
  return isNull(value) || isUndefined(value);
}

/**
 * isUndefined
 */

function isUndefined<TValue>(value: TValue | undefined): value is undefined {
  return value === undefined || typeof value === 'undefined';
}

/**
 * Returns a boolean to indicate that we are running the application on a local machine. (localhost)
 */
function isRunningLocally() {
  return import.meta.env.DEV;
}

/**
 * Returns a boolean to indicate that we are running the application within a test.
 */
function isRunningTests() {
  return import.meta.env.NODE_ENV === 'test';
}

// https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
function isRunningCypressTests() {
  return !!window.Cypress;
}

/**
 * Method to check whether a value is a positive integer.
 * This is used instead of "typeof value === 'number'"
 * because it could be a string
 * @param {*} value - can be of type string or number
 * @returns
 */
function isPositiveInteger(value: unknown): value is number | string {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return false;
  }

  const num = Number(value);

  if (Number.isInteger(num) && num > 0) {
    return true;
  }

  return false;
}

function until(
  conditionalFn: () => boolean,
  fn: () => void,
  intervalInMs: number,
  timeoutInMs: number,
) {
  if (conditionalFn()) Promise.resolve();
  const startTime = new Date().getTime();
  return new Promise<void>((resolve) => {
    // At a regular interval, call the fn
    const interval = setInterval(() => {
      fn();
      const currentTime = new Date().getTime();
      if (conditionalFn() || currentTime - startTime >= timeoutInMs) {
        clearInterval(interval);
        resolve();
      }
    }, intervalInMs);
  });
}

export async function retry(
  asyncFn: () => Promise<unknown>,
  retries: number,
): Promise<unknown> {
  try {
    return await asyncFn();
  } catch (error) {
    if (retries === 0) throw error;
    return retry(asyncFn, retries - 1);
  }
}

const generateSHA256Checksum = async (file: Blob) => {
  try {
    const buffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const checksum = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');
    return checksum;
  } catch (error) {
    console.error('Error calculating checksum:', error);
    throw error; // Rethrow the error for higher-level error handling
  }
};

export {
  arePropsEqual,
  cloneArray,
  cloneError,
  cloneObject,
  createEventHandler,
  generateSHA256Checksum,
  immutableClone,
  isDefined,
  isEmpty,
  isEmptyOrNullOrUndefined,
  isError,
  isErrorLike,
  isNotNull,
  isNull,
  isNullOrUndefined,
  isPositiveInteger,
  isRunningCypressTests,
  isRunningLocally,
  isRunningTests,
  isUndefined,
  until,
};
