import { createContext, useContext } from 'react';

interface AutomationSettingsContextInterface {
  accountAPIId: number;
  isAutomationActive: boolean;
  showDisableAutomationPrompt: (args: { onConfirm?: () => void }) => void;
}

const AutomationSettingsContext =
  createContext<AutomationSettingsContextInterface | null>(null);

const useAutomationSettingsContext = () => {
  const automationSettingsContext = useContext(AutomationSettingsContext);

  if (automationSettingsContext === undefined) {
    throw new Error(
      'useAutomationSettingsContext must be used within a AutomationSettingsContextProvider',
    );
  }

  if (automationSettingsContext === null) {
    throw new Error(
      'AutomationSettingsContext must be initialized by the Provider',
    );
  }

  return automationSettingsContext;
};

export { AutomationSettingsContext, useAutomationSettingsContext };
