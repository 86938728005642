export const PROPERTIES_WITH_LINK_IN_MESSAGE = [
  102, 11581, 14293, 12970, 12976, 12965, 13000, 11047, 12948, 14003, 14294,
  14335, 12964, 13885, 13294, 10581, 441, 10871, 10876, 10575, 13613, 10423,
  519, 14005, 13386, 11669, 11975, 10418, 13257, 11867, 11393, 337, 6564, 10911,
  10244, 10246, 349, 12852, 11045, 14006, 13892, 11463, 11601, 14004, 3469,
  10424, 10725, 13046, 12944, 12854, 11461, 13261, 359, 10794, 10918, 13883,
  388, 8085, 13887, 14002, 10031, 424, 11481, 11044, 3981, 11525, 11524, 14615,
  13739, 13741, 13742, 13743, 13744,
];
export const PROPERTIES_WITH_LINK_IN_COMMENT = [
  102, 732, 13232, 13562, 13609, 14293, 14108, 12851, 12969, 12968, 12966,
  12568, 10880, 14111, 11147, 11626, 14034, 10700, 11655, 11730, 12561, 14031,
  13217, 11716, 10701, 14312, 13134, 13937, 12543, 10059, 10425, 319, 14097,
  451, 11520, 14290, 14077, 438, 10739, 11762, 13938, 10986, 11731, 10448,
  10914, 11345, 13873, 11369, 14315, 12629, 14275, 1348, 10859, 12996, 11344,
  388, 10065, 13467, 6979, 14095, 13133, 13898, 14033, 13971, 13339, 3451,
  14638, 14664, 14675, 14663, 14614, 14622, 14633, 14634, 14635, 14665, 14666,
  14667, 14669, 14670, 14671, 14672, 14673, 14668,
];
