import { z } from 'zod';

import axios, { AxiosRequestConfig } from 'api/axios';
import { API_TIMEOUTS, constructBaseSocialAPIURL } from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ message: z.string() })
  .describe('getAPIsConnectError.ResponseSchema');

export default async function getAPIsConnectError({
  requestToken,
}: {
  requestToken: string;
}) {
  const guid = metrics.start('getAPIsConnectError');
  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/connect/error`,
    method: 'GET',
    timeout: API_TIMEOUTS.XXL,
    headers: {
      'X-EBX-ConnectState': requestToken,
    },
  };
  try {
    const response = await axios(config);
    metrics.end('getAPIsConnectError', guid);
    logger.debug('getAPIsConnectError', { requestToken });

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData;
  } catch (error) {
    metrics.fail('getAPIsConnectError', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getAPIsConnectError',
      args: { requestToken },
    });
    throw apiError;
  }
}
