import axios, { AxiosRequestConfig } from 'axios';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from './settings';

interface PostPropertySetupOptions {
  propertyURN: string;
  socialAPIURNs: string[];
}

export default async function postPropertySetup({
  propertyURN,
  socialAPIURNs,
}: PostPropertySetupOptions) {
  const guid = metrics.start('postPropertySetup');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL('v3.2')}/properties/${propertyURN}/setup`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
    data: JSON.stringify({ socialAPIURNs }),
  };
  logger.info(`API:postPropertySetup /properties/${propertyURN}/setup`);

  try {
    await axios(config);
    metrics.end('postPropertySetup', guid);
  } catch (error) {
    metrics.fail('postPropertySetup', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postPropertySetup',
    });
    throw apiError;
  }
}
