import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

export default async function putAPIsPauseSharing({
  socialPageURN,
  isSharingPaused,
}: {
  socialPageURN: string;
  isSharingPaused: boolean;
}) {
  const guid = metrics.start('putAPIsPauseSharing');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/pausesharing`,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({ isSharingPaused }),
  };

  try {
    const response = await axios(config);
    metrics.end('putAPIsPauseSharing', guid);
    logger.debug('putAPIsPauseSharing', { socialPageURN, isSharingPaused });
    return response.data;
  } catch (error) {
    metrics.fail('putAPIsPauseSharing', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/putAPIsPauseSharing',
      args: {
        socialPageURN,
        isSharingPaused,
      },
    });
    throw apiError;
  }
}
