import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';

interface LoadingProps {
  mediaId: string;
}

const Loading = ({ mediaId }: LoadingProps) => {
  return (
    <div
      className="article-archive-item d-flex flex-column"
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <div
        className="empty_feed text-center my-1 py-1"
        data-cy-attribute="isLoading:true"
      >
        <Spinner size="lg" />
      </div>
    </div>
  );
};

export default Loading;
