/* eslint react/no-did-update-set-state:"off" */

import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  PARAMETER_MODES,
  PARAMETER_TYPES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import * as logger from 'common/logger';
import { getSetting, saveAPIsSettings } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import { stripAndEncodeSpaces } from 'common/url';
import { isDefined, isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/misc/Button';
import SaveChangesButtons from 'components/settings/SaveChangesButtons';
import withGlobalInfo from 'context/withGlobalInfo';
import {
  onSaveFailure,
  onSaveSuccess,
  setPanelButtonText,
} from 'helpers/settingsPage';

/*
 * Settings - URL Parameters
 */

class URLParameters extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const urlParameters = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS,
      propertyId: getPropertyIdForAccountAPIId({
        accountAPIId: this.props.accountAPIId,
      }),
      accountAPIId: this.props.accountAPIId,
    });
    this.state = {
      data: Immutable({
        urlParameters: URLParameters.settingToState(urlParameters),
        isChanged: false,
        isSaving: false,
      }),
    };
    this._bind(
      'handleCancel',
      'handleNameChange',
      'handleParameterAdd',
      'handleParameterRemove',
      'handleSave',
      'handleTypeChange',
      'handleValueChange',
    );
    this.originalSettings = urlParameters;
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    setPanelButtonText('collapseURLParameters');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('URLParameters', { nextProps, nextState });
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
    );
    if (hasChangedToReady) {
      const urlParameters = getSetting({
        settingTypeId: ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS,
        propertyId: getPropertyIdForAccountAPIId({
          accountAPIId: this.props.accountAPIId,
        }),
        accountAPIId: this.props.accountAPIId,
      });
      this.setState((prevState) => ({
        data: prevState.data.set(
          'urlParameters',
          URLParameters.settingToState(urlParameters),
        ),
      }));
    }
  }

  /**
   * Helper methods
   */

  static getKeyFromType(type) {
    return type === PARAMETER_TYPES.QUERY ? 'queryParams' : 'fragmentParams';
  }

  static settingToState(setting) {
    const state = [];
    let index = 0;
    if (isDefined(setting.dataJSON.queryParams)) {
      setting.dataJSON.queryParams.forEach((param) => {
        state.push({
          type: PARAMETER_TYPES.QUERY,
          key: param.key,
          value: param.value,
          editMode: param.editMode,
          new: false,
          index,
        });
        index += 1;
      });
    }
    if (isDefined(setting.dataJSON.fragmentParams)) {
      setting.dataJSON.fragmentParams.forEach((param) => {
        state.push({
          type: PARAMETER_TYPES.FRAGMENT,
          key: param.key,
          value: param.value,
          editMode: param.editMode,
          new: false,
          index,
        });
        index += 1;
      });
    }
    return state;
  }

  stateToSetting(state) {
    const params = {
      queryParams: [],
      fragmentParams: [],
    };
    state.urlParameters.forEach((param) => {
      const key = URLParameters.getKeyFromType(param.type);
      params[key].push({
        key: stripAndEncodeSpaces(param.key),
        value: stripAndEncodeSpaces(param.value),
        editMode: param.editMode,
      });
    });
    const setting = {
      settingTypeId: ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS,
      enabled: this.originalSettings.enabled,
      dataJSON: params,
    };
    return setting;
  }

  /**
   * Event handlers
   */

  handleCancel() {
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set(
            'urlParameters',
            URLParameters.settingToState(this.originalSettings),
          )
          .set('isChanged', false),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'urlParameters',
          hasSettingChanged: false,
        }),
    );
  }

  handleNameChange(index) {
    return (event) => {
      const value = event.target.value;
      logger.info(`URLParameters:handleNameChange - ${index} ${value}`);

      this.setState(
        (prevState) => ({
          data: prevState.data
            .setIn(['urlParameters', index, 'key'], value)
            .set('isChanged', true),
        }),
        () =>
          this.props.eventHandlers.handleHasSettingChanged({
            key: 'urlParameters',
            hasSettingChanged: true,
          }),
      );
    };
  }

  handleParameterAdd() {
    logger.info('URLParameters:handleParameterAdd');
    this.handleParameterAddRemove();
  }

  handleParameterAddRemove(index) {
    this.setState(
      (prevState) => {
        const params = Immutable.asMutable(prevState.data.urlParameters);
        if (isUndefined(index)) {
          params.push({
            type: PARAMETER_TYPES.QUERY,
            key: '',
            value: '',
            editMode: PARAMETER_MODES.FULL,
            new: true,
            index: params.length,
          });
        } else {
          params.splice(index, 1);
        }
        return {
          data: prevState.data
            .set('urlParameters', Immutable(params))
            .set('isChanged', true),
        };
      },
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'urlParameters',
          hasSettingChanged: true,
        }),
    );
  }

  handleParameterRemove(index) {
    return () => {
      logger.info(`URLParameters:handleParameterRemove - ${index}`);
      this.handleParameterAddRemove(index);
    };
  }

  handleSave() {
    this.setState(
      (prevState) => ({
        data: prevState.data.set('isSaving', true).set('isChanged', false),
      }),
      () => {
        const settingConfig = this.stateToSetting(this.state.data);
        settingConfig.accountAPIId = this.props.accountAPIId;
        saveAPIsSettings.call(this, {
          settingConfig,
          callback: () => {
            this.originalSettings = getSetting({
              settingTypeId: ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS,
              propertyId: getPropertyIdForAccountAPIId({
                accountAPIId: this.props.accountAPIId,
              }),
              accountAPIId: this.props.accountAPIId,
            });
            this.props.eventHandlers.handleHasSettingChanged({
              key: 'urlParameters',
              hasSettingChanged: false,
            });
            onSaveSuccess.call(this);
          },
          errorHandler: () => {
            onSaveFailure.call(this);
          },
        });
      },
    );
  }

  handleTypeChange(index) {
    return (event) => {
      const value = event.target.value;
      logger.info(`URLParameters:handleTypeChange - ${index} ${value}`);

      this.setState(
        (prevState) => ({
          data: prevState.data
            .setIn(['urlParameters', index, 'type'], value)
            .set('isChanged', true),
        }),
        () =>
          this.props.eventHandlers.handleHasSettingChanged({
            key: 'urlParameters',
            hasSettingChanged: true,
          }),
      );
    };
  }

  handleValueChange(index) {
    return (event) => {
      const value = event.target.value;
      logger.info(`URLParameters:handleValueChange - ${index} ${value}`);

      this.setState(
        (prevState) => ({
          data: prevState.data
            .setIn(['urlParameters', index, 'value'], value)
            .set('isChanged', true),
        }),
        () =>
          this.props.eventHandlers.handleHasSettingChanged({
            key: 'urlParameters',
            hasSettingChanged: true,
          }),
      );
    };
  }

  /**
   * Render methods
   */

  renderParameter({
    index = mandatory('index'),
    param = mandatory('param'),
    accountAPIId = mandatory('accountAPIId'),
  } = {}) {
    let value = param.value;
    if (value === '[APITYPE]') {
      const apiTypeId = getAPITypeId({ accountAPIId });
      value = getSocialNetworkName({ apiTypeId });
    }

    return (
      <tr
        key={`url_parameter_${index}`}
        data-cy-id={`${param.type}${param.key}${param.value}`}
      >
        <th scope="row" className="pl-0">
          <select
            className="btn btn-light dropdown-md"
            name="type"
            data-cy-select="parameterType"
            value={param.type}
            onChange={this.handleTypeChange(index)}
          >
            <option value={PARAMETER_TYPES.QUERY}>Query</option>
            <option value={PARAMETER_TYPES.FRAGMENT}>Fragment</option>
          </select>
        </th>
        <td>
          <input
            name="key"
            type="text"
            data-cy-input="parameterName"
            className="input_medium align-self-center"
            value={param.key}
            onChange={this.handleNameChange(index)}
            disabled={!param.new}
          />
        </td>
        <td>
          <input
            name="value"
            type="text"
            data-cy-input="parameterValue"
            className="input_medium align-self-center"
            value={value}
            onChange={this.handleValueChange(index)}
            disabled={param.editMode === PARAMETER_MODES.NONE}
          />
        </td>
        <td>
          {param.editMode === PARAMETER_MODES.FULL && (
            <button
              className="remove ft-12 text-400 close gray-600 align-self-center"
              onClick={this.handleParameterRemove(index)}
              data-cy-action="removeParameter"
              type="button"
            >
              ✖
            </button>
          )}
        </td>
      </tr>
    );
  }

  render() {
    if (!this.originalSettings.enabled || this.props.global.isLoading()) {
      return REACT_PREVENT_RENDER;
    }

    // const savingClass = this.state.data.isSaving ? 'saving_locked' : '';

    return (
      <>
        <div className="setting-part d-flex flex-row border-top">
          <div className="mr-auto d-flex flex-column">
            <div className="text-700 ft-14 lh-20">URL Parameters</div>
            <div className="text-400 ft-12 mr-2">
              Define query and fragment parameters that will be added to link
              posts on this page
            </div>
          </div>
          {this.state.data.isChanged && (
            <span className="align-self-center mr-2 op-60">
              Unsaved Changes
            </span>
          )}
          <div
            className="btn btn-light align-self-center"
            data-toggle="collapse"
            data-target="#collapseURLParameters"
            role="button"
            aria-expanded="false"
            aria-controls="collapseURLParameters"
            data-cy-action="editURLParameters"
          >
            Edit
          </div>
        </div>
        <div
          className="collapse settings-sub settings-url-parameters"
          id="collapseURLParameters"
          data-parent="#accordion"
          data-cy-id="urlParameterSettings"
        >
          <div className="py-4 border-top table-responsive px-2 px-md-0">
            <table className="table w-auto table-borderless ml-2">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Name</th>
                  <th scope="col">Value</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {this.state.data.urlParameters.map((param, index) =>
                  this.renderParameter({
                    index,
                    param,
                    accountAPIId: this.props.accountAPIId,
                  }),
                )}
              </tbody>
            </table>
            <Button
              className="ml-2"
              onClick={this.handleParameterAdd}
              data-cy-action="addParameter"
            >
              Add Parameter
            </Button>
            <SaveChangesButtons
              disabled={!this.state.data.isChanged || this.state.data.isSaving}
              eventHandlers={{
                handleCancel: this.handleCancel,
                handleSave: this.handleSave,
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

URLParameters.propTypes = {
  accountAPIId: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleHasSettingChanged: PropTypes.func,
  }),
};

URLParameters.defaultProps = {
  eventHandlers: {
    handleHasSettingChanged: () => {},
  },
};

export default withGlobalInfo(URLParameters);
