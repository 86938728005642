import PropTypes from 'prop-types';

import { KEYNAMES } from 'common/constants';
import Button from 'components/misc/Button';
import useOnKey from 'hooks/useOnKey';

const Import = (props) => {
  const { eventHandlers } = props;

  useOnKey({
    targetKey: KEYNAMES.ESCAPE,
    onKeyUp: () => eventHandlers.handleModalClose(),
  });

  const renderHeader = () => {
    return (
      <div className="modal-header">
        <div className="top">
          <div className="left mr-2">
            <span>Bulk import articles to your Archive</span>
          </div>
        </div>
        <a
          aria-label="Close"
          onClick={eventHandlers.handleModalClose}
          className="close ft-26 text-400"
        >
          &times;
        </a>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div className="modal-body lh-20">
          <div className="mb-1">
            Echobox allows you to import a history of articles and bulk classify
            them.
          </div>
          <div>
            Please contact our{' '}
            <a
              href="mailto:support@echobox.com?subject=Article Archive Bulk Import"
              target="_blank"
              rel="noopener noreferrer"
            >
              support team
            </a>{' '}
            for more information!
          </div>
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="ml-auto">
          <Button className="ml-auto" onClick={eventHandlers.handleModalClose}>
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="ebx-modal-outer">
      <div className="select-pages modal-dialog modal-sm">
        <div className="modal-content">
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

Import.propTypes = {
  eventHandlers: PropTypes.shape({
    handleModalClose: PropTypes.func.isRequired,
  }).isRequired,
};

export default Import;
