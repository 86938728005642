import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import { sortPages } from 'common/accountAPIs';
import SocialPageRow from 'components/settings/automation/SocialPageRow';
import {
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from 'components/settings/PagesTable';
import type { SocialAPI } from 'types';

interface SocialRowTableProps {
  accountAPIs: SocialAPI[];
  title: string;
}

function SocialRowTable({ accountAPIs, title }: SocialRowTableProps) {
  if (accountAPIs.length === 0) return null;

  return (
    <TableContainer>
      <Table sx={{ tableLayout: 'fixed' }}>
        <colgroup>
          <Box as="col" w={16} />
          <col />
        </colgroup>
        <Thead>
          <Tr>
            <Th colSpan={2} display="table-cell" height="initial">
              {title}
            </Th>
          </Tr>
        </Thead>
        <Tbody background="base">
          {accountAPIs.sort(sortPages).map((accountAPI) => (
            <SocialPageRow
              key={accountAPI.accountAPIId}
              accountAPI={accountAPI}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default SocialRowTable;
