import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';

import authGenerateMFACode from 'api/aws/authGenerateMFACode';
import authSetPreferredMFA from 'api/aws/authSetPreferredMFA';
import authVerifyTotpToken from 'api/aws/authVerifyTotpToken';
import { COGNITO_ERROR_MESSAGES, PREFERRED_MFA_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import Button from 'components/misc/Button';
import Dialog from 'components/misc/Dialog';
import Steps from 'components/misc/Steps';
import GlobalInfoContext from 'context/GlobalInfoContext';

import Step1 from './Step1';
import Step2 from './Step2';

const { DialogHeader, DialogBody, DialogFooter } = Dialog;
const { Step } = Steps;

const steps = ['Register Echobox', 'Verify Token'];

/**
 * React component for setting up MFA for user
 * Renders two steps in MFAPopup (Parent Component):
 * Step 1 - Show bar code and text code to user
 * Step 2 - Show input for user to enter MFA code
 * Accepts the following props (required)
 * 1) onDismiss          - function to dismiss the popup
 * 2) showSuccessMessage - function to navigate to success message screen after verification
 */
const SetupMFA = ({ onDismiss, showSuccessMessage }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [code, setCode] = useState('');
  const [userInput, setUserInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { global } = useContext(GlobalInfoContext);
  const verify = useRef();
  /**
   * Function to return relevant step content based on step index
   * @param {Number} stepIndex
   * @returns {*} relevant step component
   */
  const getStepContent = (stepIndex) => {
    const stepContent = {
      0: <Step1 code={code} error={errorMessage} />,
      1: (
        <Step2
          userInput={userInput}
          handleChange={(value) => setUserInput(value)}
          error={errorMessage}
        />
      ),
    };
    return stepContent[stepIndex] ?? 'Unknown step';
  };

  /**
   * Function to go back to previous step
   * Gets triggered when back button is pressed
   */
  const handleBack = () => {
    setErrorMessage('');
    setUserInput('');
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * Function to proceed to next step
   * Gets triggered when Next button is pressed
   */
  const handleNext = () => {
    setErrorMessage('');
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  /**
   * Function to verify user's input and set preferred MFA
   * Updates global info and calls showSuccessMessage prop function on successful verification
   */
  const handleVerify = async () => {
    try {
      const { Status } = await authVerifyTotpToken(userInput);
      if (Status === 'SUCCESS') {
        const response = await authSetPreferredMFA(PREFERRED_MFA_TYPES.TOTP);
        if (response === 'SUCCESS') {
          const globalInfo = global.getGlobalInfo();
          const updatedUser = { ...globalInfo.user, hasSetupMFA: true };
          global.setGlobalInfo({
            ...globalInfo,
            user: updatedUser,
          });
          showSuccessMessage();
        } else {
          throw new Error(COGNITO_ERROR_MESSAGES.InternalServerErrorException);
        }
      } else {
        throw new Error(COGNITO_ERROR_MESSAGES.InternalServerErrorException);
      }
    } catch (error) {
      if (error) {
        logger.info('SetupMFA:verifyCodeFailure');
        setErrorMessage(getErrorMessage(determineError(error)));
      }
    }
  };

  /**
   * UseEffect hook to be called on component mount
   * Gets MFA code from cognito and saves it in code state variable
   */
  useEffect(() => {
    const generateCode = async () => {
      try {
        const mfaCode = await authGenerateMFACode();
        setCode(mfaCode);
      } catch (error) {
        logger.info('SetupMFA:generateCodeFailure');
        setErrorMessage(getErrorMessage(determineError(error)));
      }
    };
    generateCode();
  }, []);

  /**
   * UseEffect hook to be called whenever user enters a digit in input
   * Automatically triggers click event on verify button when all 6 digits have been entered
   */
  useEffect(() => {
    if (userInput.length === 6) verify.current.click();
  }, [userInput]);

  const isFirstStep = activeStep === 0;
  return (
    <>
      <DialogHeader>
        <Steps activeStep={activeStep}>
          {steps.map((step) => {
            return <Step key={step}>{step}</Step>;
          })}
        </Steps>
      </DialogHeader>
      <DialogBody>
        <div className="text-center">{getStepContent(activeStep)}</div>
      </DialogBody>
      <DialogFooter>
        {isFirstStep ? (
          <>
            <Button
              id="mfa-cancel"
              size="lg"
              className="mr-2"
              onClick={onDismiss}
            >
              Cancel
            </Button>
            <Button
              id="mfa-next"
              disabled={!code}
              variant="dark"
              size="lg"
              onClick={handleNext}
            >
              Next
            </Button>
          </>
        ) : (
          <>
            <Button
              id="mfa-back"
              size="lg"
              className="mr-2"
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              id="mfa-verify"
              disabled={userInput.length !== 6}
              variant="dark"
              size="lg"
              loading
              loadingEl="Verifying"
              ref={verify}
              onClick={handleVerify}
            >
              Verify
            </Button>
          </>
        )}
      </DialogFooter>
    </>
  );
};

SetupMFA.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  showSuccessMessage: PropTypes.func.isRequired,
};

export default SetupMFA;
