interface ChevronUpProps {
  width?: string;
  height?: string;
}

const ChevronUp = ({ width = '20', height = '20' }: ChevronUpProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.17187 9.74424L7.60475 5.49694C7.7321 5.37168 7.87128 5.29977 8.01046 5.29977C8.14964 5.29977 8.28812 5.35124 8.39529 5.45491L12.8282 9.70221C13.0507 9.91451 13.058 10.2668 12.8448 10.4886C12.6332 10.7118 12.2793 10.7191 12.0582 10.5052L8.01046 6.64749L3.94177 10.5445C3.7214 10.7584 3.36695 10.7511 3.15517 10.5279C2.94204 10.3079 2.94928 9.95533 3.17187 9.74424Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronUp;
