import {
  Button,
  ChevronDownIcon,
  Dropdown,
  FormControl,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { DEFAULT_TONE } from 'common/constants';
import { Tone } from 'types';

const TONE_LABELS: Record<Tone, string> = {
  informative: 'Informative',
  uplifting: 'Uplifting',
  sad: 'Sad',
  urgent: 'Urgent',
  playful: 'Playful',
};

interface ToneSelectorProps {
  value: Tone | undefined;
  onChange: (value: Tone) => void;
}
const ToneSelector = ({ value, onChange }: ToneSelectorProps) => {
  return (
    <FormControl>
      <FormControl.FormLabel>Tone</FormControl.FormLabel>
      <Dropdown size="md">
        <Dropdown.Button
          as={Button}
          variant="secondary"
          w="full"
          textAlign="left"
          rightIcon={<ChevronDownIcon />}
          isDisabled={value == null}
        >
          {TONE_LABELS[value ?? DEFAULT_TONE]}
        </Dropdown.Button>
        <Dropdown.List>
          {Object.entries(TONE_LABELS).map(([k, v]) => (
            <Dropdown.Item
              key={k}
              isSelected={value === k}
              onClick={value !== k ? () => onChange(k as Tone) : undefined}
            >
              {v}
            </Dropdown.Item>
          ))}
        </Dropdown.List>
      </Dropdown>
    </FormControl>
  );
};

export default ToneSelector;
