import type {
  ListItemProps,
  ListProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { ListItem, OrderedList } from '@ebx-ui/ebx-ui-component-library-sdk';

const PagesModalSteps = (props: ListProps) => (
  <OrderedList spacing={4} m={0} sx={{ counterReset: 'li' }} {...props} />
);

const PagesModalStep = (props: ListItemProps) => (
  <ListItem
    listStyleType="none"
    display="flex"
    gap={3}
    _before={{
      content: 'counter(li)',
      counterIncrement: 'li',
      height: 7,
      width: 7,
      borderRadius: 'full',
      bg: 'primary.50',
      color: 'primary.700',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'sm',
      lineHeight: '5',
      fontWeight: 'medium',
      flexShrink: 0,
    }}
    {...props}
  />
);

PagesModalSteps.Step = PagesModalStep;

export default PagesModalSteps;
