import { Navigate } from 'react-router-dom';

import postProperties from 'api/postProperties';
import { GLOBAL_INFO_STATES, MIXPANEL_ORIGIN } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import { getQueryParam } from 'common/path';
import { withLocation, withNavigate } from 'common/routing';
import * as tracker from 'common/tracker';
import BaseComponent from 'components/BaseComponent';
import Footer from 'components/login/Footer';
import withGlobalInfo from 'context/withGlobalInfo';

const ORIGIN = {
  setup: MIXPANEL_ORIGIN.SETUP,
  'settings/property': MIXPANEL_ORIGIN.SETTINGS,
  share: MIXPANEL_ORIGIN.SHARE,
};

/*
 * Login screen
 */

class AddProperty extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      isAddingProperty: false,
      propertyName: '',
    };
    this._bind('handleChangePropertyName', 'handleSubmit');
  }

  /**
   * Event handlers
   */

  handleChangePropertyName(event) {
    event.persist();
    const propertyName = event.target.value;
    this.setState({
      propertyName,
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const propertyName = this.state.propertyName.trim();
    logger.info(`AddProperty:handleSubmit ${propertyName}`);

    if (propertyName === '') {
      this.setState({
        errorMessage: 'Please enter a property name',
      });
      return;
    }

    this.setState(
      {
        errorMessage: '',
        isAddingProperty: true,
      },
      async () => {
        try {
          const property = await postProperties({
            propertyName,
          });

          logger.info(
            `AddProperty:handleSubmit property ${property.propertyId} added`,
          );

          const origin = getQueryParam({
            param: 'origin',
            location: this.props.location,
          });
          tracker.track({
            eventName: 'Add Property',
            trackingParams: {
              'New Property Name': propertyName,
              'New Property ID': property.propertyId,
              Origin: ORIGIN[origin],
            },
          });
          window.setTimeout(() => {
            // Refresh global info
            this.props.global.refreshGlobalInfo({
              reasonCode: GLOBAL_INFO_STATES.SELECTING_PROPERTY,
              newPropertyId: property.propertyId,
              allowUnderSetup: true,
              reloadUser: true,
              callback: () => {
                this.props.navigate('/setup/users', {
                  state: { prevLocation: this.props.location },
                });
              },
            });
          }, 2500);
        } catch (error) {
          this.setState({
            errorMessage: getErrorMessage(determineError(error)),
            isAddingProperty: false,
          });
        }
      },
    );
  }

  /**
   * Render method
   */

  render() {
    const isLocked = this.state.isAddingProperty;
    const buttonLabel = !isLocked ? 'Next' : 'Adding...';
    const returnPath = getQueryParam({
      param: 'origin',
      location: this.props.location,
    });

    // Check the origin path is valid to prevent unvalidated redirects
    if (!(returnPath in ORIGIN)) {
      return <Navigate replace to="/" />;
    }

    return (
      <div
        className={`signin-register ${
          this.props.isLoggingIn ? 'saving_locked' : ''
        }`}
      >
        <div className="signinlogo">
          <img src="/img/logo-ebx-main-dark.svg" alt="" />
        </div>
        <div className="signin_box">
          <h2>Add a new property</h2>
          <form onSubmit={this.handleSubmit}>
            <p>
              Please enter the name of the new property you would like to add to
              your Echobox.
            </p>
            <label htmlFor="propertyName">
              <span className="inputform">Property name</span>
              <div className="boxinput">
                <input
                  className={`inputblank ${isLocked ? 'saving_locked' : ''}`}
                  type="text"
                  id="propertyName"
                  value={this.state.propertyName}
                  onChange={this.handleChangePropertyName}
                />
              </div>
            </label>
            <div>
              {this.state.errorMessage !== '' && (
                <div className="d-flex">
                  <img
                    className="mr-1"
                    src="/img/icons/ic-error-red.svg"
                    alt=""
                  />
                  <p className="inputerrortxt semi_bold">
                    {this.state.errorMessage}
                  </p>
                </div>
              )}
              <a
                className={`btn btn-dark btn-signin mt-4 ${
                  isLocked ? 'saving_locked' : ''
                }`}
                onClick={!isLocked ? this.handleSubmit : null}
              >
                {buttonLabel}
              </a>
            </div>
          </form>
        </div>
        <Footer returnPath={`/${returnPath}`} />
      </div>
    );
  }
}

export default withGlobalInfo(withLocation(withNavigate(AddProperty)));
