import axios from 'api/axios';
import type { AxiosRequestConfig } from 'axios';

import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * DELETE /social/api/{socialAPIURN} - delete a account API
 * @param socialAPIURN -Social Page URN or Bitly Shorten API URN
 */

export default async function deleteAccountAPI({
  socialAPIURN,
}: {
  socialAPIURN: string;
}) {
  if (isUndefined(socialAPIURN)) {
    mandatory('socialAPIURN');
  }
  const guid = metrics.start('deleteAccountAPI');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/${socialAPIURN}`,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    await axios(config);
    metrics.end('deleteAccountAPI', guid);
    logger.debug('deleteAccountAPI', { socialAPIURN });
  } catch (error) {
    metrics.fail('deleteAccountAPI', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/deleteAccountAPI',
      args: { socialAPIURN },
    });
    throw apiError;
  }
}
