import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getErrorStatus, handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { TagSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';
import { Tag } from 'types';

const HashtagsResponseSchema = z
  .object({ hashtags: z.array(TagSchema) })
  .describe('getHashtagsAndMentions.HashtagsResponseSchema');
const MentionsResponseSchema = z
  .object({ mentions: z.array(TagSchema) })
  .describe('getHashtagsAndMentions.MentionsResponseSchema');

export default async function getHashtagsAndMentions({
  socialPageURN,
  mediaId,
  throwOnTimeout = true,
}: {
  socialPageURN: string;
  mediaId: string;
  throwOnTimeout?: boolean;
}) {
  const guid = metrics.start('getHashtagsAndMentions');

  const baseURL = constructBaseSocialAPIURL();

  const config = {
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    const [hashtags, mentions] = await Promise.all([
      axios.get<{ hashtags: Tag[] }>(
        `${baseURL}/social/api/${socialPageURN}/media/${mediaId}/hashtags`,
        config,
      ),
      axios.get<{ mentions: Tag[] }>(
        `${baseURL}/social/api/${socialPageURN}/media/${mediaId}/mentions`,
        config,
      ),
    ]);

    const validatedHashtags = lyingParse(HashtagsResponseSchema, hashtags.data);
    const validatedMentions = lyingParse(MentionsResponseSchema, mentions.data);

    metrics.end('getHashtagsAndMentions', guid);
    logger.debug('getHashtagsAndMentions', { socialPageURN, mediaId });
    return {
      hashtags: validatedHashtags.hashtags,
      mentions: validatedMentions.mentions,
    };
  } catch (error) {
    metrics.fail('getHashtagsAndMentions', guid);
    const errorCode = getErrorStatus(error);
    if (!throwOnTimeout && errorCode === 408) {
      return {
        hashtags: [],
        mentions: [],
      };
    }
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getHashtagsAndMentions',
      args: {
        socialPageURN,
        mediaId,
        throwOnTimeout,
      },
    });
    throw apiError;
  }
}
