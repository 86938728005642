import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import clsx from 'clsx';

import { MEDIA_ITEM_STATES } from 'common/constants';
import { round } from 'common/maths';
import Tooltip from 'components/misc/Tooltip';

interface ItemScoreProps {
  itemState: string;
  mediaId: string;
  mostRecentScore: number;
}

const ItemScore = ({ itemState, mediaId, mostRecentScore }: ItemScoreProps) => {
  const roundedScore = round({ value: mostRecentScore * 10, decimals: 1 })
    .toFixed(0)
    .toString();
  const isDeleted = itemState === MEDIA_ITEM_STATES.DELETED;
  const guid = `item-score-${mediaId}`;

  const tooltipText =
    'Predicted performance for this article according to: its content, time of day, other articles available and audience preferences for this page.';

  if (roundedScore !== '0') {
    return (
      <Tooltip id={guid} label={tooltipText}>
        <div
          className={clsx(
            'score mr-1 align-self-start',
            isDeleted && 'deleted_greyed_out',
          )}
          data-tip
          data-for={guid}
          data-iscapture="true"
        >
          {roundedScore}
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip id={guid} label="The Echobox Score is being calculated">
      <div
        className="score score-loading mr-auto align-self-center"
        data-tip
        data-for={guid}
        data-iscapture="true"
      >
        <Spinner size="xs" />
      </div>
    </Tooltip>
  );
};

export default ItemScore;
