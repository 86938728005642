import {
  AddIcon,
  ArrowLeftIcon,
  Button,
  Flex,
  HStack,
  Heading,
  Tab,
  Tabs,
  Text,
  useDisclosure,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getCurrentAPITypeId,
  getCurrentAccountAPIId,
} from 'common/accountAPIs';
import {
  isCompetitorLeaderboardEnabled,
  isCompetitorPostAnalyticsEnabled,
} from 'common/competitorAnalytics';
import { API_TYPE_IDS } from 'common/constants';
import {
  LOCALSTORAGE_PAGES_KEY,
  PageData,
  TIME_RANGE_OPTIONS,
} from 'common/constants/socialListening';
import * as tracker from 'common/tracker';
import AddPageModal from 'components/competitorleaderboard/AddPageModal';
import Leaderboard from 'components/competitorleaderboard/Leaderboard';
import TimeRangeFilter from 'components/competitorleaderboard/TimeRange';
import Shares from 'components/competitorpostanalytics/Shares';
import { getNetworkAndPageName } from 'helpers/tracking';
import useCompetitorData from 'queries/useCompetitorData';

const aggregateData = (data: any) => {
  let aggregatedData: PageData[] = [];
  data?.forEach((pageData: any) => {
    const page = {
      fbPage: pageData.fbPage,
      id: pageData.id,
      name: pageData.name,
      picture: pageData.picture,
      followerCount: pageData.followerCount,
      posts: pageData.posts.length,
      engagement: 0,
      shareOfVoiceInPercent: 0,
    };
    pageData.posts.forEach(({ shares = 0, comments = 0, likes = 0 }: any) => {
      page.engagement += Number(shares) + Number(comments) + Number(likes);
    });
    aggregatedData.push(page);
  });

  const totalEngagement = aggregatedData.reduce(
    (acc, page) => acc + page.engagement,
    0,
  );

  aggregatedData = aggregatedData.map((page) => ({
    ...page,
    shareOfVoiceInPercent: totalEngagement
      ? Number(((page.engagement / totalEngagement) * 100).toFixed(0))
      : 0,
  }));

  aggregatedData.sort((a, b) => b.engagement - a.engagement);

  return aggregatedData;
};

const SELECTED_TIME_RANGE_KEY = 'selectedTimeRangeLeaderboard';

const Competitors = () => {
  const TABS = {
    LEADERBOARD: 'Leaderboard',
    ...(isCompetitorPostAnalyticsEnabled() && { SHARES: 'Shares' }),
  };
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    localStorage.getItem(SELECTED_TIME_RANGE_KEY)
      ? Number(localStorage.getItem(SELECTED_TIME_RANGE_KEY))
      : TIME_RANGE_OPTIONS[3].duration,
  );
  const [hasPageLoaded, setHasPageLoaded] = useState(false);
  const currentAccountAPIId = getCurrentAccountAPIId();
  const initialCompetitorIds = localStorage.getItem(
    `${LOCALSTORAGE_PAGES_KEY}-${currentAccountAPIId}`,
  );
  const [competitorIds, setCompetitorIds] = useState(
    JSON.parse(initialCompetitorIds ? initialCompetitorIds : '[]'),
  );
  const [selectedTab, setSelectedTab] = useState(TABS.LEADERBOARD);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, isLoading, setData, isFetched } = useCompetitorData({
    competitorIds,
    duration: selectedTimeRange,
  });

  const aggregatedData = aggregateData(data);

  const handleRemove = (id: string) => {
    const newCompetitorIds = competitorIds.filter(
      (pageId: string) => pageId !== id,
    );
    localStorage.setItem(
      `${LOCALSTORAGE_PAGES_KEY}-${currentAccountAPIId}`,
      JSON.stringify(newCompetitorIds),
    );
    const newData = data?.filter((page: any) => page.id !== id);
    setData({ newCompetitorIds, data: newData });
    setCompetitorIds(newCompetitorIds);
  };

  const handleAddPages = (newPageIds: string[]) => {
    const newCompetitorIds = Array.from(
      new Set([...competitorIds, ...newPageIds]),
    );
    setCompetitorIds(newCompetitorIds);
    localStorage.setItem(
      `${LOCALSTORAGE_PAGES_KEY}-${currentAccountAPIId}`,
      JSON.stringify(Array.from(newCompetitorIds)),
    );
  };

  const handleTimeRangeChange = (value: number) => {
    const oldTimeRange = TIME_RANGE_OPTIONS.find(
      (item) => item.duration === selectedTimeRange,
    )?.label;
    const newTimeRange = TIME_RANGE_OPTIONS.find(
      (item) => item.duration === value,
    )?.label;
    tracker.track({
      eventName: 'Change Time Range',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId: currentAccountAPIId,
        }),
        'Old Time Range': oldTimeRange,
        'New Time Range': newTimeRange,
        'Competitors tab': selectedTab,
      },
    });
    localStorage.setItem(SELECTED_TIME_RANGE_KEY, value.toString());
    setSelectedTimeRange(value);
  };

  const handleTabChange = (tab: string) => {
    tracker.track({
      eventName: 'Switch Competitors view',
      trackingParams: {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId: currentAccountAPIId,
        }),
        'Competitors tab (before)': selectedTab,
        'Competitors tab (after)': tab,
      },
    });
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (!hasPageLoaded && isFetched) {
      const pageNames = aggregatedData.map((page) => page.name);
      const trackingParams = {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId: currentAccountAPIId,
        }),
        'Account API Id': currentAccountAPIId,
        'Count competitors listed': pageNames.length,
        'Competitors listed': pageNames.join(', '),
      };
      tracker.track({
        eventName: 'Competitors Page',
        trackingParams,
      });
      setHasPageLoaded(true);
    }
  }, [
    aggregatedData,
    currentAccountAPIId,
    hasPageLoaded,
    isFetched,
    isLoading,
  ]);

  return (
    <>
      <Flex px={10} gap={4} flexDir="column" mb={4}>
        <Flex gap={4} flexDir="column">
          <Button
            leftIcon={<ArrowLeftIcon />}
            variant="link"
            onClick={() => navigate('/share')}
            width="fit-content"
            sx={{
              span: {
                mr: 1,
              },
            }}
          >
            Back
          </Button>
          <HStack spacing={2}>
            <Heading>Competitors</Heading>
            {aggregatedData?.length > 0 && (
              <Text color="gray.600" size="sm">
                {aggregatedData.length} page
                {aggregatedData?.length > 1 ? 's' : ''}
              </Text>
            )}
          </HStack>

          <Flex boxShadow="borderBottom" justifyContent="space-between">
            <Tabs selectedValue={selectedTab}>
              {Object.values(TABS).map((tab) => (
                <Tab
                  href="#"
                  py={4}
                  key={tab}
                  value={tab}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab}
                </Tab>
              ))}
            </Tabs>
            <HStack spacing={2}>
              <TimeRangeFilter
                value={selectedTimeRange}
                onChange={handleTimeRangeChange}
              />
              <Button leftIcon={<AddIcon />} onClick={onOpen}>
                Add page
              </Button>
            </HStack>
          </Flex>
        </Flex>
        {selectedTab === TABS.LEADERBOARD && (
          <Leaderboard
            isLoading={isLoading}
            data={aggregatedData}
            onRemove={handleRemove}
          />
        )}
        {selectedTab === TABS.SHARES && (
          <Shares isLoading={isLoading} data={data} />
        )}
      </Flex>
      {isOpen && (
        <AddPageModal
          isOpen={isOpen}
          onClose={onClose}
          onAddPages={handleAddPages}
        />
      )}
    </>
  );
};

// This wrapper component is used to force a re-mount of the Competitors component on page change otherwise the competitors ids are shared across pages which is not what we want
const CompetitorsWrapper = () => {
  const currentAccountAPIId = getCurrentAccountAPIId();
  const isFacebookPage = getCurrentAPITypeId() === API_TYPE_IDS.FACEBOOK;
  const navigate = useNavigate();

  if (!isCompetitorLeaderboardEnabled() || !isFacebookPage) {
    navigate('/share');
    return null;
  }

  return <Competitors key={currentAccountAPIId} />;
};

export default CompetitorsWrapper;
