import {
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React, { useState } from 'react';

import postGetHTTPEcho from 'api/postGetHTTPEcho';
import * as authentication from 'common/authentication';
import { DEFAULT_USER_AGENTS, FLASH_MESSAGE_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import { IndividualHTTPEchoResponse } from 'types';
import WhitelistingToolResponseModal from './WhitelistingToolResponseModal';

interface WhitelistingToolModalInterface {
  onClose: () => void;
}

const WhitelistingToolModal = ({ onClose }: WhitelistingToolModalInterface) => {
  const [isLoading, setIsLoading] = useState(false);
  const [targetURL, setTargetURL] = useState('');
  const [httpEchoResponse, setHTTPEchoResponse] = useState<
    IndividualHTTPEchoResponse[] | null
  >(null);

  const flashMessagesContext = useFlashMessagesContext();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    const userCSToken = authentication.getClientServiceToken();

    try {
      setIsLoading(true);

      const response = await postGetHTTPEcho({
        targetURL: targetURL.trim(),
        userAgents: DEFAULT_USER_AGENTS,
        includeResponseBody: false,
      });
      setHTTPEchoResponse(response);
    } catch (error) {
      flashMessagesContext.flashMessages.addMessage({
        messageCategory: 'Whitelisting Tool POST check URL response error',
        type: FLASH_MESSAGE_TYPES.ERROR,
        text: getErrorMessage(determineError(error)),
        timeout: 2500,
      });
    } finally {
      setIsLoading(false);
      authentication.setClientServiceToken(userCSToken);
    }
  };

  const handleCloseWhitelistingResponse = () => {
    setHTTPEchoResponse(null);
    setTargetURL('');
  };

  return (
    <Modal isOpen onClose={onClose} size="large">
      <Modal.Header>
        <Modal.Title>Whitelisting Tool</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormControl.FormLabel htmlFor="targetURL">
              Check URL response
            </FormControl.FormLabel>
            <HStack>
              <Input
                type="text"
                placeholder="Enter a URL..."
                id="targetURL"
                value={targetURL}
                onChange={(event) => setTargetURL(event.target.value)}
                autoFocus
              />
              <Button
                type="submit"
                variant="secondary"
                flexShrink={0}
                isLoading={isLoading}
              >
                Submit
              </Button>
            </HStack>
          </FormControl>
        </form>
      </Modal.Body>
      {httpEchoResponse !== null && httpEchoResponse.length === 0 && (
        <Text>No responses could be found</Text>
      )}
      {httpEchoResponse !== null && httpEchoResponse.length > 0 && (
        <WhitelistingToolResponseModal
          targetURL={targetURL}
          responses={httpEchoResponse}
          onCloseWhitelistingResponse={handleCloseWhitelistingResponse}
        />
      )}
    </Modal>
  );
};

export default WhitelistingToolModal;
