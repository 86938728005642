/*

Response from AWS:

{
  "username": "0e3a8cfb-c91b-40a6-ae53-db9600651c2a",
  "pool": {
    "userPoolId": "eu-west-1_iUkO72I1J",
    "clientId": "gli4sjuhplkufch7n9n39jl1",
    "client": {
      "endpoint": "https://cognito-idp.eu-west-1.amazonaws.com/",
      "fetchOptions": {}
    },
    "advancedSecurityDataCollectionFlag": true
  },
  "Session": null,
  "client": {
    "endpoint": "https://cognito-idp.eu-west-1.amazonaws.com/",
    "fetchOptions": {}
  },
  "signInUserSession": {
    "idToken": {
      "jwtToken": "eyJraWQiOiIzcUhyRDNhQXN4ZkRYK3haNEp2VjloMkt0TTM1U1MwQVA3RmdkY1F6OUNRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0NWRmYTM0NS0zYmQ5LTQ5YzItOTgzNy05NDJmZjgzYjMyYWEiLCJ6b25laW5mbyI6IkV1cm9wZVwvTG9uZG9uIiwiY29nbml0bzpncm91cHMiOlsiU1RBRkZfVVNFUiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfaVVrTzcySTFKIiwiY29nbml0bzp1c2VybmFtZSI6IjBlM2E4Y2ZiLWM5MWItNDBhNi1hZTUzLWRiOTYwMDY1MWMyYSIsImxvY2FsZSI6InVua25vd24iLCJhdWQiOiJnbGk0c2p1aHBsa3VmY2g3bjluMzlqbDEiLCJldmVudF9pZCI6IjE2ZjE3M2IzLWNmODMtNGVkNC1hYTM4LTZmNzQ1MGZiNDQyNSIsInRva2VuX3VzZSI6ImlkIiwicGVybWlzc2lvbnMiOiJbe1widFwiOjUsXCJhXCI6TlVMTCxcInBcIjo0OTR9LHtcInRcIjo1LFwiYVwiOk5VTEwsXCJwXCI6MTAyfSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjEwNjU0fSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjEwNjU1fSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjEwNjU2fSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjczM30se1widFwiOjUsXCJhXCI6TlVMTCxcInBcIjo3MzJ9XSIsImF1dGhfdGltZSI6MTYwMjY3NjA2MCwibmFtZSI6IkFuZHkgTmVhbGUiLCJleHAiOjE2MDI2Nzk2NjAsImlhdCI6MTYwMjY3NjA2MCwiZW1haWwiOiJhbmR5QGVjaG9ib3guY29tIn0.fT5eJjFE22BmG8egpHsfE1ocnMg9vr_pV1JRtASztqMjHOXvIfdRDh9BIect-EoJIcRdReMw7m46K6UXyVu4EZGAe4hFSZzVKsVlElzqJnwczSriM0GXo1hkrUxMfyoSbpGCw_4xVqkc-XWBieYdg0Yz_vn1SfIKhb3hW0-ewE-IYdBTauzKNh3SmNs2ho8i3u3zNXaBKLGP6cvsGcV0CygjNbKhKAfAgzG9_H0Dz_W2zraSyim-aYcUwhZl9Sh7HJ-9NiEkphQN8A_gSdwk8uRyskpooIHF_MBz28gProm6-Y9yW3zuh7H3vwgG9o8ca6yewIh9EVGBzKoB22XRlA",
      "payload": {
        "sub": "45dfa345-3bd9-49c2-9837-942ff83b32aa",
        "zoneinfo": "Europe/London",
        "cognito:groups": [
          "STAFF_USER"
        ],
        "email_verified": true,
        "iss": "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_iUkO72I1J",
        "cognito:username": "0e3a8cfb-c91b-40a6-ae53-db9600651c2a",
        "locale": "unknown",
        "aud": "gli4sjuhplkufch7n9n39jl1",
        "event_id": "16f173b3-cf83-4ed4-aa38-6f7450fb4425",
        "token_use": "id",
        "permissions": "[{\"t\":5,\"a\":NULL,\"p\":494},{\"t\":5,\"a\":NULL,\"p\":102},{\"t\":5,\"a\":NULL,\"p\":10654},{\"t\":5,\"a\":NULL,\"p\":10655},{\"t\":5,\"a\":NULL,\"p\":10656},{\"t\":5,\"a\":NULL,\"p\":733},{\"t\":5,\"a\":NULL,\"p\":732}]",
        "auth_time": 1602676060,
        "name": "Andy Neale",
        "exp": 1602679660,
        "iat": 1602676060,
        "email": "andy@echobox.com"
      }
    },
    "refreshToken": {
      "token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Dlneto2LkCQPyrj5tijli38_pb7mmrQoNifPCjNmIJf_PNBS2tjPCcFifnzhsGyRyNmwgEY7nHibYtkZ4n_gEMnxEtZmxdBToMbKSUSeRQrXwdMnS65C3azM2AJZLo6i9FLcvnvfJW9HyTAiyBkZiPjvSeqTxScEF_0-XAELdr48Cuo_3uXi3UP-Ut0xHLZx7ugekGQlvfgHRs8ALpg7WyIrt4LZHfVVY6OY9InBgw5PaKwKm-JuI3pC_Ju4_bM8l4XEX-OkqHtkU3WQ-ktvkVAMTn8bw1m5PJio4bnCaiGVR37xKna2fKyunwoWCE2YcvX99xfRujHxYBElfNBn8Q.2IOCDJvcgLa6Wi1C.wHN1go09yWY3C9sLQFCfwMEBcQkiJ_cvckpi253KeLKTtozfwR81d7FIWyukSxDNFqHhZ5eNfcz47RmvUnL9WXuNn-uk6L-lKQ4a7cP17g-sihmCneJrSpf_BtVYHeNWtld7yrAl_FgbHhYL4N9boLLyx4KlGiuglGD7iwkpsUhqfiVDBxD2ylb4z4KdEUCWLDYAb3BBnxEUv_wTeQluJhZd2T4Rw1LuYDKugwcYCjWVktNtEH5l9mr2uLmsjQlu5mNCGWmmymTtxFsNggq-klPgR_V5CyYt_oDX-P6s4Dy6Cp1H3UmLFz-2OjJ1fKQetuwJ_wx6SPgIdZEtKAu4oDYBUwogTo3XyiUuc75IZL72a8BO5o6KZyMhSXqB4IHfiSpwMabX8TLzjD1iVK0idBh741PXmxMk_NgS82V_C8y3Et7l73ZyDHCBLVch46NeBVpAeXLHtr1pK8dwejyDdQU2osDczwpoV0g0qqaCsV0hHIa4uOnrrxWKJ7rqloSu-B4acQAbbVMQBt1I7C4E-4r8GUihmcX-jmxIbR0CjBx6NU5xx-9UGWN3Tf0NSXOc4yo6C3rcqL1WU_G2cjVen8nQR_2xPFBTm3HRe_gw5ye84WfkaPSAEhBf6OlLdo5oDHQWpVJhgJrVItzxWsab-a-JiAH_hWdH76EH-PLZrOSfjHB6ce0EdoDKL_DiNMtpWTPglDzm_X-2aGF784KY5avOAXGFmMUijQeYxcLHKHkJYT7Q48BjILM4z0EaxHTTxnSCm9vckLG9elugxy84koAtrM2KfRPPJPt24Wfi5HhUpCE0p418fn0kR7JvajwBS_vcAglmzUGoX2agwgZ6m87xRkePkm88gmL6w7yM7ijl1J3bFRBPJhqJgvtsgoErXN42__WMyzembz2xW25pcW19SPFyk9VX0IpZycBHr5-p6cHWcn6OjC74YcGo1dqGZK6JlwIJPnhbB8-hm1GDzV4IvuetDukXqFAzcAD_skirBrhhIm_6BJmnPsvBYIaAMJDTSb_X7R5j52fuyzoRcu0cSRktjbYjUbuGBt3yXLfxWJm7VK0lCW7CSSEI4ZEVZwOnTKFoXE15cvn_CMdMRrRgYWUvgAUzqlkmFoKixtK4GJv2BfrUeU6w3W4UWltWGPEWt2Nz8FUzVvl8BfU0WY7wbpYYPFaxMJOcgjeIZcMVfRwm_Q3PYmstealVWHJhX67L_PCOdUMCkXySlrDEMudEF_eRpoY2o-orTRag8TzvZXyaBJo8L9w6qSW0296NMw_CymXJRnf0FM-LY_hSCEEaBrcVwlXPsEMj_vNR4cf8se-8qt7KcLw.P4Erb2aGJhpddomCTEiReQ"
    },
    "accessToken": {
      "jwtToken": "eyJraWQiOiJvVU5BNVhIRmpsXC81SzJkaFRMUSthUEsza0ZUZmJcL3ZzRlMzNkJKWFQwb2s9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI0NWRmYTM0NS0zYmQ5LTQ5YzItOTgzNy05NDJmZjgzYjMyYWEiLCJjb2duaXRvOmdyb3VwcyI6WyJTVEFGRl9VU0VSIl0sImV2ZW50X2lkIjoiMTZmMTczYjMtY2Y4My00ZWQ0LWFhMzgtNmY3NDUwZmI0NDI1IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTYwMjY3NjA2MCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfaVVrTzcySTFKIiwiZXhwIjoxNjAyNjc5NjYwLCJpYXQiOjE2MDI2NzYwNjAsImp0aSI6ImIwNDFhNTRmLTI2NzItNGE1OS04OTcyLWU3YzI0MjM5YTlhNCIsImNsaWVudF9pZCI6ImdsaTRzanVocGxrdWZjaDduOW4zOWpsMSIsInVzZXJuYW1lIjoiMGUzYThjZmItYzkxYi00MGE2LWFlNTMtZGI5NjAwNjUxYzJhIn0.BKkPx1xktIYZt_6cYZIO2RXIKuqhFuETV10GfU_Xep5j_RPKHlsWlLNPmUf89GFBYShi8_pzY0NAL4pw-xf1gvvbOGv5egyAyd4Y2p5-gwzVhMFd27fNawOINrcipzCei5b0Ji9cHi8vABeqnl3i-slfwmod8z8CDO_4-a7ZbjFSQD7J37AUse04R4AT8-6ML4d2_RMa83sSkNm0taVzskgpfx8s_mvGKbUnadQCp3LMfo80fEVMICQ7MFoXYW6ME4iPODv3RNF32P4V0OqzVAnUE9U3XLRm4CCu5HJJvrI6JwMVZ5jQWewimE8Qbj_ROxk9CTiDze_EZfTkL6HwLQ",
      "payload": {
        "sub": "45dfa345-3bd9-49c2-9837-942ff83b32aa",
        "cognito:groups": [
          "STAFF_USER"
        ],
        "event_id": "16f173b3-cf83-4ed4-aa38-6f7450fb4425",
        "token_use": "access",
        "scope": "aws.cognito.signin.user.admin",
        "auth_time": 1602676060,
        "iss": "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_iUkO72I1J",
        "exp": 1602679660,
        "iat": 1602676060,
        "jti": "b041a54f-2672-4a59-8972-e7c24239a9a4",
        "client_id": "gli4sjuhplkufch7n9n39jl1",
        "username": "0e3a8cfb-c91b-40a6-ae53-db9600651c2a"
      }
    },
    "clockDrift": 0
  },
  "authenticationFlowType": "USER_SRP_AUTH",
  "keyPrefix": "CognitoIdentityServiceProvider.gli4sjuhplkufch7n9n39jl1",
  "userDataKey": "CognitoIdentityServiceProvider.gli4sjuhplkufch7n9n39jl1.0e3a8cfb-c91b-40a6-ae53-db9600651c2a.userData",
  "attributes": {
    "sub": "45dfa345-3bd9-49c2-9837-942ff83b32aa",
    "zoneinfo": "Europe/London",
    "email_verified": true,
    "name": "Andy Neale",
    "locale": "unknown",
    "email": "andy@echobox.com"
  },
  "preferredMFA": "NOMFA"
}

Response from method:

{
  emailAddress: "andy@echobox.com",
  keyPrefix: "CognitoIdentityServiceProvider.gli4sjuhplkufch7n9n39jl1",
  name: "Andy Neale",
  userId: "0e3a8cfb-c91b-40a6-ae53-db9600651c2a",
  userType: "STAFF_USER",
  accessToken: "eyJraWQiOiJvVU5BNVhIRmpsXC81SzJkaFRMUSthUEsza0ZUZmJcL3ZzRlMzNkJKWFQwb2s9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI0NWRmYTM0NS0zYmQ5LTQ5YzItOTgzNy05NDJmZjgzYjMyYWEiLCJjb2duaXRvOmdyb3VwcyI6WyJTVEFGRl9VU0VSIl0sImV2ZW50X2lkIjoiMTZmMTczYjMtY2Y4My00ZWQ0LWFhMzgtNmY3NDUwZmI0NDI1IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTYwMjY3NjA2MCwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfaVVrTzcySTFKIiwiZXhwIjoxNjAyNjc5NjYwLCJpYXQiOjE2MDI2NzYwNjAsImp0aSI6ImIwNDFhNTRmLTI2NzItNGE1OS04OTcyLWU3YzI0MjM5YTlhNCIsImNsaWVudF9pZCI6ImdsaTRzanVocGxrdWZjaDduOW4zOWpsMSIsInVzZXJuYW1lIjoiMGUzYThjZmItYzkxYi00MGE2LWFlNTMtZGI5NjAwNjUxYzJhIn0.BKkPx1xktIYZt_6cYZIO2RXIKuqhFuETV10GfU_Xep5j_RPKHlsWlLNPmUf89GFBYShi8_pzY0NAL4pw-xf1gvvbOGv5egyAyd4Y2p5-gwzVhMFd27fNawOINrcipzCei5b0Ji9cHi8vABeqnl3i-slfwmod8z8CDO_4-a7ZbjFSQD7J37AUse04R4AT8-6ML4d2_RMa83sSkNm0taVzskgpfx8s_mvGKbUnadQCp3LMfo80fEVMICQ7MFoXYW6ME4iPODv3RNF32P4V0OqzVAnUE9U3XLRm4CCu5HJJvrI6JwMVZ5jQWewimE8Qbj_ROxk9CTiDze_EZfTkL6HwLQ",
  idToken: "eyJraWQiOiIzcUhyRDNhQXN4ZkRYK3haNEp2VjloMkt0TTM1U1MwQVA3RmdkY1F6OUNRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI0NWRmYTM0NS0zYmQ5LTQ5YzItOTgzNy05NDJmZjgzYjMyYWEiLCJ6b25laW5mbyI6IkV1cm9wZVwvTG9uZG9uIiwiY29nbml0bzpncm91cHMiOlsiU1RBRkZfVVNFUiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfaVVrTzcySTFKIiwiY29nbml0bzp1c2VybmFtZSI6IjBlM2E4Y2ZiLWM5MWItNDBhNi1hZTUzLWRiOTYwMDY1MWMyYSIsImxvY2FsZSI6InVua25vd24iLCJhdWQiOiJnbGk0c2p1aHBsa3VmY2g3bjluMzlqbDEiLCJldmVudF9pZCI6IjE2ZjE3M2IzLWNmODMtNGVkNC1hYTM4LTZmNzQ1MGZiNDQyNSIsInRva2VuX3VzZSI6ImlkIiwicGVybWlzc2lvbnMiOiJbe1widFwiOjUsXCJhXCI6TlVMTCxcInBcIjo0OTR9LHtcInRcIjo1LFwiYVwiOk5VTEwsXCJwXCI6MTAyfSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjEwNjU0fSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjEwNjU1fSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjEwNjU2fSx7XCJ0XCI6NSxcImFcIjpOVUxMLFwicFwiOjczM30se1widFwiOjUsXCJhXCI6TlVMTCxcInBcIjo3MzJ9XSIsImF1dGhfdGltZSI6MTYwMjY3NjA2MCwibmFtZSI6IkFuZHkgTmVhbGUiLCJleHAiOjE2MDI2Nzk2NjAsImlhdCI6MTYwMjY3NjA2MCwiZW1haWwiOiJhbmR5QGVjaG9ib3guY29tIn0.fT5eJjFE22BmG8egpHsfE1ocnMg9vr_pV1JRtASztqMjHOXvIfdRDh9BIect-EoJIcRdReMw7m46K6UXyVu4EZGAe4hFSZzVKsVlElzqJnwczSriM0GXo1hkrUxMfyoSbpGCw_4xVqkc-XWBieYdg0Yz_vn1SfIKhb3hW0-ewE-IYdBTauzKNh3SmNs2ho8i3u3zNXaBKLGP6cvsGcV0CygjNbKhKAfAgzG9_H0Dz_W2zraSyim-aYcUwhZl9Sh7HJ-9NiEkphQN8A_gSdwk8uRyskpooIHF_MBz28gProm6-Y9yW3zuh7H3vwgG9o8ca6yewIh9EVGBzKoB22XRlA",
  impersonateToken: TBC,
  refreshToken: "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.Dlneto2LkCQPyrj5tijli38_pb7mmrQoNifPCjNmIJf_PNBS2tjPCcFifnzhsGyRyNmwgEY7nHibYtkZ4n_gEMnxEtZmxdBToMbKSUSeRQrXwdMnS65C3azM2AJZLo6i9FLcvnvfJW9HyTAiyBkZiPjvSeqTxScEF_0-XAELdr48Cuo_3uXi3UP-Ut0xHLZx7ugekGQlvfgHRs8ALpg7WyIrt4LZHfVVY6OY9InBgw5PaKwKm-JuI3pC_Ju4_bM8l4XEX-OkqHtkU3WQ-ktvkVAMTn8bw1m5PJio4bnCaiGVR37xKna2fKyunwoWCE2YcvX99xfRujHxYBElfNBn8Q.2IOCDJvcgLa6Wi1C.wHN1go09yWY3C9sLQFCfwMEBcQkiJ_cvckpi253KeLKTtozfwR81d7FIWyukSxDNFqHhZ5eNfcz47RmvUnL9WXuNn-uk6L-lKQ4a7cP17g-sihmCneJrSpf_BtVYHeNWtld7yrAl_FgbHhYL4N9boLLyx4KlGiuglGD7iwkpsUhqfiVDBxD2ylb4z4KdEUCWLDYAb3BBnxEUv_wTeQluJhZd2T4Rw1LuYDKugwcYCjWVktNtEH5l9mr2uLmsjQlu5mNCGWmmymTtxFsNggq-klPgR_V5CyYt_oDX-P6s4Dy6Cp1H3UmLFz-2OjJ1fKQetuwJ_wx6SPgIdZEtKAu4oDYBUwogTo3XyiUuc75IZL72a8BO5o6KZyMhSXqB4IHfiSpwMabX8TLzjD1iVK0idBh741PXmxMk_NgS82V_C8y3Et7l73ZyDHCBLVch46NeBVpAeXLHtr1pK8dwejyDdQU2osDczwpoV0g0qqaCsV0hHIa4uOnrrxWKJ7rqloSu-B4acQAbbVMQBt1I7C4E-4r8GUihmcX-jmxIbR0CjBx6NU5xx-9UGWN3Tf0NSXOc4yo6C3rcqL1WU_G2cjVen8nQR_2xPFBTm3HRe_gw5ye84WfkaPSAEhBf6OlLdo5oDHQWpVJhgJrVItzxWsab-a-JiAH_hWdH76EH-PLZrOSfjHB6ce0EdoDKL_DiNMtpWTPglDzm_X-2aGF784KY5avOAXGFmMUijQeYxcLHKHkJYT7Q48BjILM4z0EaxHTTxnSCm9vckLG9elugxy84koAtrM2KfRPPJPt24Wfi5HhUpCE0p418fn0kR7JvajwBS_vcAglmzUGoX2agwgZ6m87xRkePkm88gmL6w7yM7ijl1J3bFRBPJhqJgvtsgoErXN42__WMyzembz2xW25pcW19SPFyk9VX0IpZycBHr5-p6cHWcn6OjC74YcGo1dqGZK6JlwIJPnhbB8-hm1GDzV4IvuetDukXqFAzcAD_skirBrhhIm_6BJmnPsvBYIaAMJDTSb_X7R5j52fuyzoRcu0cSRktjbYjUbuGBt3yXLfxWJm7VK0lCW7CSSEI4ZEVZwOnTKFoXE15cvn_CMdMRrRgYWUvgAUzqlkmFoKixtK4GJv2BfrUeU6w3W4UWltWGPEWt2Nz8FUzVvl8BfU0WY7wbpYYPFaxMJOcgjeIZcMVfRwm_Q3PYmstealVWHJhX67L_PCOdUMCkXySlrDEMudEF_eRpoY2o-orTRag8TzvZXyaBJo8L9w6qSW0296NMw_CymXJRnf0FM-LY_hSCEEaBrcVwlXPsEMj_vNR4cf8se-8qt7KcLw.P4Erb2aGJhpddomCTEiReQ"
}
*/

import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import { COGNITO_CHALLENGE_NAMES, USER_TYPES } from 'common/constants';
import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

export default async function authSignIn({
  username = mandatory('username'),
  password = mandatory('password'),
} = {}) {
  const guid = metrics.start('authSignIn');
  logger.info(`API:authSignIn ${username}`);
  let loginResponse;
  try {
    loginResponse = await Auth.signIn(username.trim(), password);
  } catch (error) {
    logger.error({
      event: 'AWS Cognito Sign In Error',
      properties: {
        ErrorLocation: 'authSignIn',
        Username: username,
        cookies: authentication.checkIfAnyAuthCookieExists(),
      },
      error,
    });
    metrics.fail('authSignIn', guid);
    const message = 'Failed to log in. Please try again.';

    throw handleCognitoError({
      originalError: error,
      errorLocation: 'api/authSignIn',
      args: {
        username,
        message,
      },
    });
  }

  if (
    loginResponse.challengeName !== COGNITO_CHALLENGE_NAMES.SOFTWARE_TOKEN_MFA
  ) {
    if (loginResponse?.signInUserSession) {
      const accessToken = loginResponse.signInUserSession.accessToken;
      const accessGroups = accessToken.payload['cognito:groups'];
      if (
        accessGroups.indexOf(USER_TYPES.DEFAULT) === -1 &&
        accessGroups.indexOf(USER_TYPES.ECHOBOX_STAFF) === -1
      ) {
        metrics.fail('authSignIn', guid);
        throw handleCognitoError({
          originalError: new Error(
            'You are not connected to a Social property. Contact Echobox to find out more.',
          ),
          errorLocation: 'api/authSignIn',
          args: {
            username,
          },
        });
      }
    }
  }

  metrics.end('authSignIn', guid);
  authentication.storeChallengeDetails(loginResponse);
  return loginResponse;
}
