import {
  AUTOFEED_SHARE_MODES,
  DATA_SOURCE_DEFAULTS,
  POST_TYPES,
  SOCIAL_CHANNELS,
  SPECIAL_CHARACTER_REGEX,
  TAG_TYPES,
} from 'common/constants/common';

const constants = {
  urnName: 'li',
  apiType: 'LINKEDIN',
  name: 'LinkedIn',
  lowerName: 'linkedin',
  icon: '/img/icons/ic-linkedin.svg',
  defaultPageIcon: '/img/blank.png',
  emojiSet: 'emojione',
  /* jscpd:ignore-start */
  allowedFileTypes: {
    [POST_TYPES.LINK]: { 'image/jpeg': [], 'image/png': [] },
    [POST_TYPES.STATUS]: { 'image/jpeg': [], 'image/png': [] },
    [POST_TYPES.VIDEO]: { 'video/mp4': [] },
  },
  autofeedDefaults: {
    autoCapitaliseMessage: false,
    autoFeedActive: false,
    bestArticlesNewContentNum: 48,
    bestContentOnlySinceCurrentOnPeriod: false,
    breakingNewsIntervalSecs: null,
    bestArticlesReshareNum: 3,
    excludeAllContentContaining: [],
    excludeAllContentURLPathContaining: [],
    excludeReshareContentContaining: [],
    excludeReshareContentURLPathContaining: [],
    excludeImagesContaining: [],
    includeImages: true,
    includeMessage: true,
    includeOnlyContentContaining: [],
    includeOnlyContentFromRSSFeeds: [],
    includeOnlyContentURLPathContaining: [],
    maxResharesPerArticle: 5,
    messageTextToRemove: [],
    minMinsBetweenSharesOfSameArticle: 14 * 24 * 60,
    newshareMessagePrefix: '',
    newshareMessageSuffix: '',
    newShareMode: AUTOFEED_SHARE_MODES.OPTIMAL,
    prioritiseCuratedContent: false,
    reshareMessagePrefix: '',
    reshareMessageSuffix: '',
    reshareMode: AUTOFEED_SHARE_MODES.OPTIMAL,
    shareNowAllContentContaining: [],
    shareNowAllContentURLPathContaining: [],
    startPastMinsForBestArticleReshare: 80 * 24 * 60,
    startPastMinsForNewShare: 2 * 24 * 60,
    statusScheduleTriggers: [],
  },
  autofeedMessageSources: {
    ARTICLE_QUOTE: 'Article Quote',
    RSS_TITLE: 'RSS Title',
    RSS_DESCRIPTION: 'RSS Description',
    OG_TITLE: 'OG Title',
    OG_DESCRIPTION: 'OG Description',
    TWITTER_TITLE: 'Twitter Title',
    TWITTER_DESCRIPTION: 'Twitter Description',
    NULL: 'Keep Empty',
  },
  appendSpace: true,
  appendMentionsAtSymbol: false,
  canAddVideoThumbnail: true,
  canDeletePosts: true,
  canHaveMultipleAccounts: true,
  canGenerateAIMessage: true,
  canResharePosts: true,
  canReshareVideos: false,
  canShareNow: true,
  /* jscpd:ignore-end */
  dataSourceDefaults: {
    missing: {
      message: DATA_SOURCE_DEFAULTS.DESCRIPTION_SOURCE,
      description: DATA_SOURCE_DEFAULTS.DESCRIPTION_SOURCE,
      image: DATA_SOURCE_DEFAULTS.IMAGE_SOURCE,
      title: DATA_SOURCE_DEFAULTS.TITLE_SOURCE,
    },
    empty: {
      message: DATA_SOURCE_DEFAULTS.DESCRIPTION_SOURCE,
      description: DATA_SOURCE_DEFAULTS.DESCRIPTION_SOURCE,
      image: DATA_SOURCE_DEFAULTS.IMAGE_SOURCE,
      title: DATA_SOURCE_DEFAULTS.TITLE_SOURCE,
    },
  },
  descriptionSourceExclusions: ['OPTIMAL_MESSAGE'],
  entityName: 'page',
  excludeImagesRule:
    'Images that contain any of the following keywords should not be included for Autofeed schedules:',
  firstCommentSettings: {
    maximumLength: 1250,
  },
  hasABVariations: false,
  hasImageOverlays: false,
  hasAudienceRestriction: false,
  hasCharacterCount: false,
  hasDescriptionField: false,
  hasDomainField: false,
  hasHashtagsMentions: true,
  hasLinkPlaceholder: false,
  hasMentionsLookups: false,
  hasMessageBeforeImage: true,
  hasMessageField: true,
  hasShareMessages: true,
  hasSyndFeeds: true,
  hasSponsoredPosts: false,
  hasTitleField: {
    [POST_TYPES.LINK]: true,
    [POST_TYPES.STATUS]: false,
    [POST_TYPES.VIDEO]: false,
  },
  isTitleFieldRequired: true,
  hasTitleFieldCharacterCount: false,
  hasVerifiedAccounts: false,
  hasVideoTitle: false,
  imageAspectRatios: {
    [POST_TYPES.LINK]: {
      min: 1.91,
      max: 1.91,
    },
  },
  imageAspectRatioDefaults: {
    [POST_TYPES.LINK]: {
      default: null,
    },
    [POST_TYPES.STATUS]: {
      default: null,
    },
  },
  imageQuantityLimits: {
    defaults: {
      min: 1,
      max: 1,
    },
    postTypeSpecific: {
      [POST_TYPES.LINK]: {
        min: 0,
      },
      [POST_TYPES.STATUS]: {
        max: 9,
      },
    },
  },
  imageSizeLimits: {
    default: null, // No limits
    postTypeSpecific: {
      [POST_TYPES.LINK]: {
        max: 10 * 1024 * 1024, // 10MB
      },
    },
    imageTypeSpecific: {
      [POST_TYPES.VIDEO]: {
        min: 75 * 1024, // 75KB
        max: 200 * 1024 * 1024, // 200MB,
      },
    },
  },
  imageSources: {
    RSS_IMAGE_LARGEST: 'RSS Image',
    OG_IMAGE_LARGEST: 'OG Image',
    TWITTER_IMAGE_LARGEST: 'Twitter Image',
  },
  isLinkShorteningDisabled: false,
  isSocialNetwork: true,
  isValidAspectRatioMandatory: {
    [POST_TYPES.LINK]: false,
    [POST_TYPES.STATUS]: true,
    [POST_TYPES.VIDEO]: true,
  },
  messageSources: {
    OPTIMAL_MESSAGE: 'ChatGPT',
    RSS_TITLE: 'RSS Title',
    RSS_DESCRIPTION: 'RSS Description',
    OG_TITLE: 'OG Title',
    OG_DESCRIPTION: 'OG Description',
    TWITTER_TITLE: 'Twitter Title',
    TWITTER_DESCRIPTION: 'Twitter Description',
    NULL: 'Keep Empty',
  },
  pageURLTemplate: 'https://www.linkedin.com/company/PAGENAME',
  placeholderText: {
    [POST_TYPES.STATUS]: ['JPG, PNG or GIF files', '10MB max'],
    [POST_TYPES.VIDEO]: ['MP4 files', '3 seconds to 30 minutes', '200MB max'],
  },
  postTypes: {
    [POST_TYPES.LINK]: 'Link',
    [POST_TYPES.STATUS]: 'Photo/Status',
    [POST_TYPES.VIDEO]: 'Video',
  },
  lastSharedLabel: {
    [POST_TYPES.LINK]: 'Link',
    [POST_TYPES.STATUS]: 'Photo/Status',
    [POST_TYPES.VIDEO]: 'Video',
  },
  shareIcon: {
    [POST_TYPES.STATUS]: '/img/icons/ic-photo.svg',
    [POST_TYPES.VIDEO]: '/img/icons/ic-camera.svg',
  },
  showOnSetupPage: true,
  supportedSocialChannels: [SOCIAL_CHANNELS.FEED],
  tagPatterns: {
    // Legacy format: #thisisahashtag
    // New format: {hashtag|\#|thisisahashtag}
    [TAG_TYPES.HASHTAG]: new RegExp(
      `#(${SPECIAL_CHARACTER_REGEX})|\\{hashtag\\|\\\\#\\|(${SPECIAL_CHARACTER_REGEX})\\}`,
      'g',
    ),
    // Legacy format: @[Bio Chemicals London-urn:li:organization:19049739]
    // New format: @[Bio Chemicals London](urn:li:organization:19049739)
    [TAG_TYPES.MENTION]: new RegExp(
      `@\\[(.+?-urn:li:${SPECIAL_CHARACTER_REGEX}:${SPECIAL_CHARACTER_REGEX})\\]|@\\[(.+?\\]\\((urn:li:${SPECIAL_CHARACTER_REGEX}:${SPECIAL_CHARACTER_REGEX}))\\)`,
      'g',
    ),
  },
  titleSourceExclusions: ['OPTIMAL_MESSAGE'],
  validationMessages: {
    videoSmallFileSize:
      'This video file size is too small. Please check the file size is larger than 75KB',
    videoLargeFileSize:
      'This video file size is too large. Please check the file size is smaller than 200MB',
    videoFileType:
      'The file type is not supported by LinkedIn. Please check that the file type is .MP4.',
  },
  videoDurationLimits: {
    [POST_TYPES.VIDEO]: {
      min: 3,
      max: 1800,
    },
  },
} as const;

export default constants;
