const Close = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M9.99805 1.00714L8.9909 0L4.99805 3.99286L1.00519 0L-0.00195312 1.00714L3.9909 5L-0.00195312 8.99286L1.00519 10L4.99805 6.00714L8.9909 10L9.99805 8.99286L6.00519 5L9.99805 1.00714Z"
        fill="#3C4257"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Close;
