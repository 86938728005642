import * as React from 'react';

import {
  getAPIPostName,
  getCurrentAPITypeId,
  getCurrentAccountAPIId,
} from 'common/accountAPIs';
import { getSocialNetworkName } from 'common/social';
import { track } from 'common/tracker';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  getNetworkAndPageName,
  getSchedulePostTrackingParams,
} from 'helpers/tracking';
import { FixTypeLater } from 'types';

export function useInstantVideoTracking() {
  const { global } = useGlobalInfo();

  const globalInfo = global.getGlobalInfo();
  const apiTypeId = getCurrentAPITypeId({ globalInfo });
  const accountAPIId = getCurrentAccountAPIId({ globalInfo });

  // TODO: Possibly move this tracking to a more general location
  const getCommonTrackingParams = React.useCallback(
    ({ articleURL }: { articleURL: string }) => ({
      'Social Network': getSocialNetworkName({ apiTypeId }),
      'Social Page': getAPIPostName({ accountAPIId }),
      'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
      'Account API Id': accountAPIId,
      'Article URL': articleURL,
    }),
    [accountAPIId, apiTypeId],
  );

  const trackGenerateVideo = React.useCallback(
    ({ articleURL }: { articleURL: string }) => {
      track({
        eventName: 'Generate Video',
        trackingParams: getCommonTrackingParams({ articleURL }),
      });
    },
    [getCommonTrackingParams],
  );

  const trackReplaceVideoElement = React.useCallback(
    ({ articleURL, element }: { articleURL: string; element: string }) => {
      track({
        eventName: 'Replace Video Element',
        trackingParams: {
          ...getCommonTrackingParams({ articleURL }),
          'Element Type': element,
        },
      });
    },
    [getCommonTrackingParams],
  );

  const trackDownloadVideo = React.useCallback(
    ({ articleURL }: { articleURL: string }) => {
      track({
        eventName: 'Download Video',
        trackingParams: getCommonTrackingParams({ articleURL }),
      });
    },
    [getCommonTrackingParams],
  );

  const trackSchedulePost = React.useCallback(
    ({
      mediaItem,
      articleURL,
    }: {
      mediaItem: FixTypeLater;
      articleURL: string;
    }) => {
      track({
        eventName: 'Schedule Post',
        trackingParams: {
          ...getSchedulePostTrackingParams({
            mediaItem,
          }),
          'Is Echobox generated?': true,
          'Article URL': articleURL,
        },
      });
    },
    [],
  );

  const trackCancelPost = React.useCallback(
    ({ articleURL }: { articleURL: string }) => {
      track({
        eventName: 'Cancel Post',
        trackingParams: {
          ...getCommonTrackingParams({ articleURL }),
          'Is Echobox generated?': true,
        },
      });
    },
    [getCommonTrackingParams],
  );

  return {
    trackGenerateVideo,
    trackReplaceVideoElement,
    trackDownloadVideo,
    trackSchedulePost,
    trackCancelPost,
  };
}
