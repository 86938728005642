import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';

import ValidationError from './ValidationError';

const inputStyle = {
  fontFamily: 'monospace',
  borderRadius: 6,
  border: '1px solid lightgrey',
  margin: 4,
  paddingLeft: 10,
  width: 36,
  height: 42,
  fontSize: 24,
  boxSizing: 'border-box',
  color: 'black',
  backgroundColor: 'white',
  MozAppearance: 'textfield',
};

const inputStyleInvalid = {
  fontFamily: 'monospace',
  borderRadius: 6,
  border: '1px solid rgb(238, 211, 215)',
  boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px',
  margin: 4,
  paddingLeft: 10,
  width: 36,
  height: 42,
  fontSize: 24,
  boxSizing: 'border-box',
  color: 'rgb(185, 74, 72)',
  backgroundColor: 'rgb(242, 222, 222)',
  MozAppearance: 'textfield',
};

/**
 * React Component to add some default styling to <ReactCodeInput/>
 * Adds default styles for normal input and invalid input states
 * Accepts the following prop (optional):
 * 1) error - an error message. When this is passed, the error message is shown below the input
 * All other props are passed to the ReactCodeInput - https://github.com/40818419/react-code-input
 */
const CodeInput = ({ error, ...rest }) => {
  return (
    <>
      <Box
        as={ReactCodeInput}
        inputStyle={inputStyle}
        inputStyleInvalid={inputStyleInvalid}
        sx={{
          'input::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
          },
          'input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
          },
        }}
        {...rest}
      />
      {error && (
        <Box mt={2.5}>
          <ValidationError errorMessage={error} />
        </Box>
      )}
    </>
  );
};

CodeInput.defaultProps = {
  error: '',
};

CodeInput.propTypes = {
  error: PropTypes.string,
};

export default CodeInput;
