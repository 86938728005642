import { getCurrentPropertyId, getProperty } from 'common/accountAPIs';
import AccountSuspendedBanner from 'components/misc/HighPriorityErrorsBanner/AccountSuspendedBanner';
import AccountWillBeSuspendedBanner from 'components/misc/HighPriorityErrorsBanner/AccountWillBeSuspendedBanner';
import GAWithNoTrackerBanner from 'components/misc/HighPriorityErrorsBanner/GAWithNoTrackerBanner';
import NoTrackerWithNoGABanner from 'components/misc/HighPriorityErrorsBanner/NoTrackerWithNoGABanner';
import OfflineBanner from 'components/misc/HighPriorityErrorsBanner/OfflineBanner';
import {
  useHighPriorityErrorType,
  useHighPriorityErrorTypeActions,
} from 'state/highPriorityErrorType';

const HighPriorityErrorsBanner = () => {
  const errorType = useHighPriorityErrorType();
  const { dismissHighPriorityError } = useHighPriorityErrorTypeActions();

  const currentPropertyId = getCurrentPropertyId();

  if (errorType === 'OFFLINE') {
    return <OfflineBanner />;
  }

  if (errorType === 'ACCOUNT_SUSPENDED') {
    const property = getProperty({ propertyId: currentPropertyId });
    return (
      <AccountSuspendedBanner
        invoiceList={property.propertyBilling?.invoices}
      />
    );
  }

  if (errorType === 'ACCOUNT_WILL_BE_SUSPENDED') {
    return <AccountWillBeSuspendedBanner propertyId={currentPropertyId} />;
  }

  if (errorType === 'GOOGLE_ANALYTICS_WITH_NO_TRACKER') {
    return (
      <GAWithNoTrackerBanner
        onClose={() => dismissHighPriorityError(currentPropertyId)}
      />
    );
  }

  if (errorType === 'NO_TRACKER_WITH_NO_GOOGLE_ANALYTICS') {
    return (
      <NoTrackerWithNoGABanner
        onClose={() => dismissHighPriorityError(currentPropertyId)}
      />
    );
  }

  return null;
};

export default HighPriorityErrorsBanner;
