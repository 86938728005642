import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { AccountSettingTypesSchema } from 'common/schemas';
import { convertToPropertyURN } from 'common/urn';
import { lyingParse } from 'common/zod';
import type { SettingTypeId } from 'types';

const ResponseSchema = z
  .object({
    entries: z.record(
      z
        .string()
        .transform((id) => parseInt(id, 10))
        .pipe(AccountSettingTypesSchema),
      z.array(
        z.object({
          enabled: z.boolean(),
          autoExpireTime: z.number().optional(),
          dataJSON: z.string().optional(),
        }),
      ),
    ),
  })
  .describe('getPropertySettingsByType.ResponseSchema');

export default async function getPropertySettingsByType({
  propertyId,
  settingTypeIds,
}: {
  propertyId: string;
  settingTypeIds: Array<SettingTypeId>;
}) {
  const propertyURN = convertToPropertyURN(propertyId);
  const guid = metrics.start('getPropertySettingsByType');

  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL()}/properties/${propertyURN}/settings/types/${settingTypeIds}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };
  logger.info(
    `API:getPropertySettingsByType properties/${propertyURN}/settings/types/${settingTypeIds}`,
  );

  try {
    const response = await axios(config);
    metrics.end('getPropertySettingsByType', guid);
    logger.debug('getPropertySettingsByType', { propertyURN });

    const data = lyingParse(ResponseSchema, response.data);
    return data;
  } catch (error) {
    metrics.fail('getPropertySettingsByType', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertySettingsByType',
      args: { propertyURN },
    });
    throw apiError;
  }
}
