import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { ImageContextValue } from 'components/compose/imageV2/types';

const ImageContext = createContext<ImageContextValue | null>(null);

const useImageContext = () => {
  const imageContext = useContext(ImageContext);

  if (imageContext === undefined) {
    throw new Error(
      'useImageContext must be used within a ImageContextProvider',
    );
  }
  if (imageContext === null) {
    throw new Error('ImageContextProvider must be initialized by the Provider');
  }

  return imageContext;
};

interface ImageContextProviderProps {
  value: ImageContextValue;
  children: ReactNode;
}
const ImageContextProvider = ({
  value,
  children,
}: ImageContextProviderProps) => (
  <ImageContext.Provider value={value}>{children}</ImageContext.Provider>
);

export { ImageContextProvider, useImageContext };
