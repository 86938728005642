const Warning = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13399 0 0 3.13401 0 7C0 10.866 3.13399 14 7 14ZM6.30003 2.79999C6.2227 2.79999 6.16003 2.86267 6.16003 2.93999V8.25998C6.16003 8.33732 6.2227 8.4 6.30003 8.4H7.69997C7.7773 8.4 7.83997 8.33732 7.83997 8.25998V2.93999C7.83997 2.86267 7.7773 2.79999 7.69997 2.79999H6.30003ZM6.30003 9.52C6.2227 9.52 6.16003 9.58268 6.16003 9.66V11.06C6.16003 11.1373 6.2227 11.2 6.30003 11.2H7.69997C7.74426 11.2 7.78371 11.1794 7.80935 11.1474C7.81689 11.1379 7.82316 11.1275 7.82815 11.1164C7.83569 11.0991 7.83997 11.0801 7.83997 11.06V9.66C7.83997 9.58268 7.7773 9.52 7.69997 9.52H6.30003Z"
      fill="#FF7759"
    />
  </svg>
);

export default Warning;
