import {
  Alert,
  Circle,
  Flex,
  Heading,
  Icon,
  NormalIcon,
  RssFeedIcon,
  Spacer,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  getCurrentPropertyId,
  getPropertyPermission,
  socialNetworkAPIIds,
} from 'common/accountAPIs';
import { isShowingAPIType } from 'common/common';
import { PERMISSION_TYPES } from 'common/constants';
import type { SocialAPI } from 'types';

import { useNavigate } from 'react-router-dom';
import HealthMenuContentFeedError from './HealthMenuContentFeedError';
import HealthMenuPage from './HealthMenuPage';
import HealthMenuTwitterApp from './HealthMenuTwitterApp';

const AllGood = ({
  text,
  onClick,
  icon,
}: {
  text: string;
  onClick?: React.MouseEventHandler;
  icon?: React.ElementType;
}) => (
  <Flex
    px={3}
    py={2}
    bg="gray.100"
    boxShadow="border"
    borderRadius="md"
    alignItems="center"
    justifyContent="left"
    style={{ cursor: onClick ? 'pointer' : 'default' }}
    onClick={onClick}
  >
    <Icon as={icon} boxSize={5} />
    <Heading variant="h5" ml={3}>
      {text}
    </Heading>
    <Spacer />
    <Circle size={2} bg="green.600" />
  </Flex>
);

interface HealthMenuContentProps {
  disconnectedAccountAPIs: SocialAPI[];
  disconnectedTwitterApps: SocialAPI[];
  contentFeedErrorMsg: string | null;
}

const HealthMenuContent = ({
  disconnectedAccountAPIs,
  disconnectedTwitterApps,
  contentFeedErrorMsg,
}: HealthMenuContentProps) => {
  const navigate = useNavigate();
  const anyAccountAPIErrors =
    disconnectedAccountAPIs.length > 0 || disconnectedTwitterApps.length > 0;

  const anyContentFeedErrors = contentFeedErrorMsg !== null;

  const socialNetworks = socialNetworkAPIIds.filter(isShowingAPIType);
  disconnectedAccountAPIs.sort(
    (accountAPIA, accountAPIB) =>
      socialNetworks.indexOf(accountAPIA.apiTypeId) -
      socialNetworks.indexOf(accountAPIB.apiTypeId),
  );

  const propertyPermission = getPropertyPermission({
    propertyId: getCurrentPropertyId(),
  });
  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

  return (
    <VStack spacing={2} alignItems="stretch">
      {!isAdmin && (anyAccountAPIErrors || anyContentFeedErrors) && (
        <Alert variant="warning" hideIcon p={3}>
          <Alert.Description>
            Please contact your administrator to resolve these issues.
          </Alert.Description>
        </Alert>
      )}
      {contentFeedErrorMsg && (
        <HealthMenuContentFeedError message={contentFeedErrorMsg} />
      )}
      {disconnectedTwitterApps.length > 0 && (
        <HealthMenuTwitterApp accountAPI={disconnectedTwitterApps[0]} />
      )}
      {disconnectedAccountAPIs.map((accountAPI) => (
        <HealthMenuPage key={accountAPI.accountAPIId} accountAPI={accountAPI} />
      ))}
      {!anyAccountAPIErrors && (
        <AllGood
          text="Page connections"
          icon={NormalIcon}
          {...(isAdmin && { onClick: () => navigate('/settings/pages') })}
        />
      )}
      {!anyContentFeedErrors &&
        // Hiding from editors as we don't retrieve feed information for them
        isAdmin && (
          <AllGood
            text="Content feeds"
            onClick={() => navigate('/settings/content-feeds')}
            icon={RssFeedIcon}
          />
        )}
    </VStack>
  );
};

export default HealthMenuContent;
