/* eslint camelcase: ["error", {allow: ["impersonated_by_staff_email", "additional_exp"]}] */
import EchoboxAuth from '@ebx-auth/ebx-clientauth-frontend-sdk';
import { Auth } from 'aws-amplify';

import { baseCoreAPIURL, baseSocialAPIURL } from 'api/settings';
import * as authentication from 'common/authentication';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { generateGuid } from 'common/string';
import { isDefined, isNull } from 'common/utility';

export { addCorrelationId, getUserDetails, logRateLimitWarning };

function addCorrelationId(request) {
  if (
    !isNull(request.url.match(baseCoreAPIURL)) ||
    !isNull(request.url.match(baseSocialAPIURL))
  ) {
    const updated = { ...request };
    updated.headers['X-Correlation-Id'] = generateGuid();
    return updated;
  }
  return request;
}

function logRateLimitWarning(response) {
  if (
    isDefined(response.headers) &&
    (isDefined(response.headers['X-EBX-RateLimitWarning']) ||
      isDefined(response.headers['x-ebx-ratelimitwarning']))
  ) {
    logger.error({
      event: 'Rate Limit Warning',
      properties: {
        Metrics: metrics.stringify(),
      },
    });
  }
  return response;
}

function getUserDetails(globalInfo, reloadUser) {
  return new Promise((resolve) => {
    if (!reloadUser) {
      resolve(globalInfo.user);
    } else {
      Auth.currentSession()
        .then((session) => {
          Auth.currentAuthenticatedUser()
            .then((res) => {
              res.refreshSession(session.getRefreshToken(), async () => {
                // Refresh the client service token and always update as the
                // permissions in the CS token will have been updated
                await EchoboxAuth.refreshClientServiceToken(false);
                resolve(authentication.refreshPermission(globalInfo));
              });
            })
            .catch(() => {
              resolve(authentication.refreshPermission(globalInfo));
            });
        })
        .catch(() => {
          resolve(authentication.refreshPermission(globalInfo));
        });
    }
  });
}
