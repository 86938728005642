import twitter from 'twitter-text';

import { SHARE_URL_PLACEHOLDER_REGEX } from 'common/config';
import { TAG_TYPES } from 'common/constants';
import {
  getSocialNetworkTagPattern,
  hasMentionsLookups,
  includeMentionsAtSymbol,
} from 'common/social';
import * as tags from 'common/tags';
import { mandatory } from 'common/validation';
import type { Tag, TagMap, TagType } from 'types';

interface Token {
  type: (typeof TOKEN_TYPES)[keyof typeof TOKEN_TYPES];
  start: number;
  length: number;
  text: string;
  decoratedText: string;
  isPlainText: boolean;
}

function matchRegex(
  regex: RegExp,
  text: string,
  callback: (start: number, matchedChars: string) => void,
) {
  let match = regex.exec(text);
  while (match !== null) {
    const start = match.index;
    callback(start, match[0]);
    match = regex.exec(text);
  }
}

function matchTag(
  apiTypeId: number,
  tagTypeId: TagType,
  text: string,
  callback: (start: number, tagText: string) => void,
) {
  const regex = getSocialNetworkTagPattern({ apiTypeId, tagType: tagTypeId });
  if (regex !== '') {
    matchRegex(regex, text, callback);
  }
}

function matchShareURL(
  text: string,
  callback: (start: number, originalText: string) => void,
) {
  matchRegex(SHARE_URL_PLACEHOLDER_REGEX, text, callback);
}

export const TOKEN_TYPES = {
  TAG: 1,
  SHARE_URL: 2,
  URL: 3,
} as const;

/**
 * Functions to process the raw messages to be used with the DraftJS editor, this
 * means that all the hashtags and mentions need to be decorated but the text
 * cannot be changed (this leads to selection issues). Each tag/mention will
 * be defined a Token that describes its location in the text, the original
 * text and the decorated text.
 */

/**
 * Extracts hashtags and mentions from text
 */
export function extractTags(
  apiTypeId: number,
  replacementFields: TagMap,
  text: string,
  tokens: Token[],
) {
  Object.values(TAG_TYPES).forEach((tagType) => {
    matchTag(apiTypeId, tagType, text, (start, tagText) => {
      // Get the tag from its text and replacement fields
      const tag = tags.identifyReplacementField({
        decoratedText: tagText,
        replacementFields,
      });

      let decoratedText: string;
      let isPlainText: boolean | undefined;
      if (tag) {
        isPlainText = false;

        // If tag is found use the replacement
        decoratedText = getDecoratedText({
          tag,
          tagType,
          apiTypeId,
        });
      } else if (
        tagType === TAG_TYPES.HASHTAG ||
        (tagType === TAG_TYPES.MENTION && !hasMentionsLookups({ apiTypeId }))
      ) {
        // Do nothing if the tag is part of a word with a middle hashtag or mutliple hasthags
        if (start !== 0 && text.charAt(start - 1) !== ' ') {
          return;
        }
        const textFromStart = text.substring(start + 1, text.length);
        const indexOfNextWhitespace = textFromStart.indexOf(' ');
        const nextHashtag = textFromStart.indexOf('#');
        if (
          indexOfNextWhitespace > nextHashtag ||
          (indexOfNextWhitespace === -1 && nextHashtag !== -1)
        ) {
          return;
        }

        // If replacement is not found assume it is a tag as it has been encoded as the hashtags
        // come piecemeal
        const tagToDecorate: Tag = {
          clean: tagText,
          more: '',
          raw: tagText,
        };
        decoratedText = getDecoratedText({
          tag: tagToDecorate,
          tagType,
          apiTypeId,
          prependAtSign: tagType === TAG_TYPES.HASHTAG,
        });
      } else {
        isPlainText = true;

        // If replacement is not found do nothing as the hashtags and mentions come piecemeal
        decoratedText = tagText;
      }

      // Create a token describing location in original text
      tokens.push(
        createToken(
          TOKEN_TYPES.TAG,
          start,
          tagText.length,
          tagText,
          decoratedText,
          isPlainText,
        ),
      );
    });
  });
}

/**
 * Extract share URLs from the text
 */
export function extractShareURLs(
  shareURL: string,
  text: string,
  tokens: Token[],
) {
  matchShareURL(text, (start, originalText) => {
    tokens.push(
      createToken(
        TOKEN_TYPES.SHARE_URL,
        start,
        originalText.length,
        originalText,
        shareURL,
      ),
    );
  });
}

/**
 * Extract URLs from the text
 */
export function extractURLs(text: string | null | undefined, tokens: Token[]) {
  const matches =
    text === null || text === undefined
      ? []
      : twitter.extractUrlsWithIndices(text);
  matches.forEach((match) => {
    const start = match.indices[0];
    const length = match.indices[1] - start;
    const url = match.url;
    tokens.push(createToken(TOKEN_TYPES.URL, start, length, url, url));
  });
}

/**
 * Processes the given content and returns an object with the 'tokens' found
 * and the 'decoratedText' produced by updating the content with all the
 * decorated text of each token
 */
export function process({
  text,
  apiTypeId,
  shareURL,
  replacementFields = { [TAG_TYPES.HASHTAG]: [], [TAG_TYPES.MENTION]: [] },
}: {
  text: string;
  apiTypeId: number;
  shareURL: string;
  replacementFields?: TagMap;
}) {
  if (text === undefined) {
    mandatory('text');
  }
  if (apiTypeId === undefined) {
    mandatory('apiTypeId');
  }
  if (shareURL === undefined) {
    mandatory('shareURL');
  }

  // Extract tokens from the text
  const tokens: Token[] = [];
  extractTags(apiTypeId, replacementFields, text, tokens);
  extractShareURLs(shareURL, text, tokens);
  extractURLs(text, tokens);

  // Ensure tokens are in the order they exist in text
  tokens.sort((a, b) => a.start - b.start);

  // Create the decorated text using the tokens, this is done in a separate loop
  // to ensure that the order of hashtags/mentions do not effect the indexes
  let decoratedText = text;
  let acc = 0;
  for (let i = 0; i < tokens.length; i += 1) {
    const token = tokens[i];
    const start = token.start + acc;
    const end = start + token.length;

    // Update the result text to contain the decorated text
    decoratedText =
      decoratedText.substr(0, start) +
      token.decoratedText +
      decoratedText.substr(end);

    // Update the indexes in the token to be for the decorated text
    const decoratedLength = token.decoratedText.length;

    token.start = start;
    token.length = decoratedLength;

    // Keep track of the change in length
    acc += decoratedLength - token.text.length;

    // Plain text tokens are only used to update the text and should not be returned
    if (token.isPlainText) {
      tokens.splice(i, 1);
      i -= 1;
    }
  }

  return { tokens, text, decoratedText };
}

export function createToken(
  type: Token['type'],
  start: Token['start'],
  length: Token['length'],
  text: Token['text'],
  decoratedText: Token['decoratedText'],
  isPlainText = false,
): Token {
  return {
    type,
    start,
    length,
    text,
    decoratedText,
    isPlainText,
  };
}

/**
 * Get the decorated text from a tag. This is the 'clean' property unless it is
 * an Instagram or Twitter mention where we want to show the '@'
 */
export function getDecoratedText({
  tag,
  tagType,
  apiTypeId,
  prependAtSign = true,
}: {
  tag: Tag;
  tagType: TagType;
  apiTypeId: number;
  prependAtSign?: boolean;
}) {
  if (tag === undefined) {
    mandatory('tag');
  }
  if (tagType === undefined) {
    mandatory('tagType');
  }
  if (apiTypeId === undefined) {
    mandatory('apiTypeId');
  }

  if (
    tagType === TAG_TYPES.MENTION &&
    includeMentionsAtSymbol({ apiTypeId }) &&
    prependAtSign
  ) {
    return `@${tag.clean}`;
  }
  return tag.clean;
}
