/**
 * GET /media/video/thumbnail - Get video thumbnail
 *
 * @param {string} videoURL - video url
 * @return {string} - thumbnail as base64
 */

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import { lyingParse } from 'common/zod';
import { z } from 'zod';

const ResponseSchema = z
  .object({
    data: z.array(
      z.object({
        fbPage: z.string(),
        id: z.string(),
        name: z.string(),
        picture: z.string(),
        followerCount: z.number(),
        fanCount: z.number(),
        posts: z.array(
          z.object({
            id: z.string(),
            creationTime: z.number(),
            postType: z.string(),
            message: z.string().nullable(),
            reactions: z.number(),
            comments: z.number(),
            shares: z.number(),
            fullPicture: z.string(),
            title: z.string().nullable(),
            normalisedEngagement: z.number(),
          }),
        ),
      }),
    ),
  })
  .describe('GetCompetitorResponseData');

export default async function getCompetitorData({
  socialPageURN,
  fbPages,
  from,
  to,
}: {
  socialPageURN: string;
  fbPages: string[];
  from: number;
  to: number;
}) {
  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v4',
    )}/social/api/${socialPageURN}/competitors?fbPages=${fbPages}&from=${from}&to=${to}`,
    method: 'GET',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    const response = await axios(config);

    const responseData = lyingParse(ResponseSchema, response.data);
    return responseData.data;
  } catch (error) {
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: `api/getCompetitorData`,
      args: { socialPageURN, fbPages, from, to },
    });
    logger.error({
      event: 'Failed to get competitor data',
      error: apiError,
      properties: { socialPageURN, fbPages, from, to },
    });
    return null;
  }
}
