import PropTypes from 'prop-types';
import { useContext } from 'react';

import authSetPreferredMFA from 'api/aws/authSetPreferredMFA';
import { COGNITO_ERROR_MESSAGES, PREFERRED_MFA_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import Button from 'components/misc/Button';
import Dialog from 'components/misc/Dialog';
import GlobalInfoContext from 'context/GlobalInfoContext';

const { DialogHeader, DialogBody, DialogFooter } = Dialog;

/**
 * React component for turning off MFA for user
 * Accepts the following props (required)
 * 1) isOpen      - boolean for showing / hiding the dialog
 * 2) onDismiss - function to dismiss the dialog
 */
const TurnOffMFA = ({ isOpen, onDismiss }) => {
  const { global } = useContext(GlobalInfoContext);

  /**
   * Function to set preferred MFA as NOMFA in cognito
   * Updates global info and shows success notification on changing preferred MFA
   */
  const handleTurnOff = async () => {
    try {
      const response = await authSetPreferredMFA(PREFERRED_MFA_TYPES.NOMFA);
      if (response === 'SUCCESS') {
        const globalInfo = global.getGlobalInfo();
        const updatedUser = { ...globalInfo.user, hasSetupMFA: false };
        global.setGlobalInfo({
          ...globalInfo,
          user: updatedUser,
        });
        onDismiss();
        addSuccessNotification('Two-factor Authentication removed');
      } else {
        throw new Error(COGNITO_ERROR_MESSAGES.InternalServerErrorException);
      }
    } catch (error) {
      logger.info('TurnOffMFA:setPreferredMFAFailure');
      const message = getErrorMessage(determineError(error));
      addErrorNotification(message);
    }
  };

  return (
    <Dialog
      className="modal-sm"
      isOpen={isOpen}
      onDismiss={onDismiss}
      centerVertically
    >
      <DialogHeader showClose={false}>
        Turn off Two-factor Authentication?
      </DialogHeader>
      <DialogBody>
        If you turn off Two-factor Authentication, your account will only be
        protected by your password.
      </DialogBody>
      <DialogFooter>
        <Button id="mfa-cancel" size="lg" className="mr-2" onClick={onDismiss}>
          Cancel
        </Button>
        <Button
          id="mfa-turnoff"
          variant="dark"
          size="lg"
          loading
          loadingEl="Turning Off"
          onClick={handleTurnOff}
        >
          Turn Off
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

TurnOffMFA.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default TurnOffMFA;
