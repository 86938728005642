import { POST_TYPES, SOCIAL_CHANNELS } from 'common/constants';
import type { PostType, SocialChannel } from 'types';

function getFileRoot() {
  return `${window.location.protocol}//${window.location.host}/img/placeholders`;
}

function getImageThumbnail({
  postType,
  isNewsFeed = false,
}: {
  postType: PostType;
  isNewsFeed?: boolean;
}) {
  if (postType === POST_TYPES.VIDEO) {
    return `${getFileRoot()}/video.svg`;
  }
  if (isNewsFeed) {
    return `${getFileRoot()}/news-feed-image.svg`;
  }
  return `${getFileRoot()}/image.svg`;
}

function getVideoThumbnail({
  socialChannel,
  isVideoUploaded = false,
}: {
  socialChannel: SocialChannel;
  isVideoUploaded?: boolean;
}) {
  if (isVideoUploaded) {
    if (socialChannel === SOCIAL_CHANNELS.REEL) {
      return `${getFileRoot()}/video-uploaded-thumbnail-9-16.png`;
    }
    return `${getFileRoot()}/video-uploaded-thumbnail.png`;
  }
  return `${getFileRoot()}/video.svg`;
}

function getNewsFeedVideoThumbnail() {
  return `${getFileRoot()}/news-feed-video.svg`;
}

export {
  getFileRoot,
  getImageThumbnail,
  getNewsFeedVideoThumbnail,
  getVideoThumbnail,
};
