import {
  Box,
  EmojiIcon,
  FirstCommentIcon,
  Flex,
  Heading,
  Image,
  ImageIcon,
  ShareIcon,
  Text,
  Tooltip,
  VideoIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import {
  FACEBOOK_POST_TYPE,
  FACEBOOK_POST_TYPES,
} from 'common/constants/socialListening';
import PageImage from 'components/competitorleaderboard/PageImage';
import React from 'react';

interface ShareCardProps {
  id: string;
  pageImage: string;
  pageName: string;
  creationTime: number;
  message: string;
  postType: string;
  title: string;
  reactions: number;
  comments: number;
  shares: number;
  fullPicture: string;
  engagements: number;
}

interface StatsProps {
  icon?: React.ReactElement;
  count: number;
}

const Stats = React.forwardRef<HTMLDivElement, StatsProps>((props, ref) => {
  const { icon, count, ...rest } = props;
  return (
    <Flex gap="1" color="text.secondary">
      {icon}
      <Text ref={ref} color="text.secondary" size="xs" {...rest}>
        {count.toLocaleString()}
      </Text>
    </Flex>
  );
});

const PostTypeIcon = ({ postType }: { postType: FACEBOOK_POST_TYPE }) => {
  if (postType === FACEBOOK_POST_TYPES.LINK) {
    return null;
  }
  const icon =
    postType === FACEBOOK_POST_TYPES.VIDEO ? <VideoIcon /> : <ImageIcon />;

  return (
    <Box
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      bg="surface.secondary.hover"
      borderRadius="full"
      p={1}
      opacity="0.75"
    >
      {icon}
    </Box>
  );
};

const ShareCard = (props: ShareCardProps) => {
  const messageRef = React.useRef<HTMLDivElement>(null);
  // get time in 24-hour format from props.creationTime
  const time = new Date(props.creationTime * 1000);

  const hours = time.getHours();
  // add padding to hours if less than 10
  const paddedHours = hours < 10 ? `0${hours}` : hours;
  // add padding to minutes if less than 10
  const minutes = time.getMinutes();
  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timePosted = `${paddedHours}:${paddedMinutes}`;

  // Get the current date and its Unix timestamp
  const now = new Date();

  // Get the start of the current day in Unix timestamp
  const startOfCurrentDay =
    new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() / 1000;

  // Get the start of yesterday in Unix timestamp
  const startOfYesterday = startOfCurrentDay - 86400; // 86400 seconds in a day

  // Check if the post is from yesterday
  const isYesterday =
    props.creationTime >= startOfYesterday &&
    props.creationTime < startOfCurrentDay;

  const displayDate = isYesterday ? `Yesterday, ${timePosted}` : timePosted;

  // check if message ref is truncated
  const isDisabled =
    messageRef.current?.scrollHeight === messageRef.current?.clientHeight;

  const handleClick = () => {
    // open link in new tab
    window.open(`https://www.facebook.com/${props.id}`, '_blank');
  };

  return (
    <Box
      cursor="pointer"
      borderRadius="md"
      boxShadow="border"
      onClick={handleClick}
      tabIndex={0}
      _hover={{
        boxShadow: 'md',
      }}
    >
      <Box
        p="3"
        backgroundColor="surface.primary"
        boxShadow="border"
        borderTopRadius="md"
      >
        <Flex gap="3" flexDir="column">
          <Flex justifyContent="space-between">
            <Flex>
              <PageImage src={props.pageImage} mr="2" />
              <Heading variant="h5">{props.pageName}</Heading>
            </Flex>
            <Text color="text.secondary" size="xs">
              {displayDate}
            </Text>
          </Flex>
          <Tooltip
            isDisabled={isDisabled}
            label={props.message}
            openDelay={300}
          >
            <Text
              ref={messageRef}
              size="sm"
              noOfLines={2}
              minH="10"
              whiteSpace="pre-line"
            >
              {props.message}
            </Text>
          </Tooltip>
          <Flex gap="3">
            <Box position="relative">
              <Image
                src={props.fullPicture}
                h="60px"
                borderRadius="md"
                borderWidth="1px"
                borderColor="gray.300"
                borderStyle="solid"
              />
              <PostTypeIcon postType={props.postType} />
            </Box>
            <Heading variant="h5" noOfLines={3}>
              {props.title}
            </Heading>
          </Flex>
        </Flex>
      </Box>
      <Flex p="3" justifyContent="space-between">
        <Flex gap="3">
          <Stats icon={<EmojiIcon />} count={props.reactions} />
          <Stats icon={<FirstCommentIcon />} count={props.comments} />
          <Stats icon={<ShareIcon />} count={props.shares} />
        </Flex>
        <Tooltip label="Total engagements" openDelay={300}>
          <Stats count={props.engagements} />
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default ShareCard;
