/* eslint no-underscore-dangle: ["error", { "allow": ["__serialized__"] }] */

import EchoboxAuth, {
  LOGIN_MESSAGING_URLS,
} from '@ebx-auth/ebx-clientauth-frontend-sdk';
import { EbxProvider } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as Sentry from '@sentry/react';
import '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import queryString from 'query-string';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Root from 'Root';
import axios from 'api/axios';
import { addCorrelationId, logRateLimitWarning } from 'api/common';
import postServiceAuth from 'api/postServiceAuth';
import { initChameleon } from 'common/chameleon';
import { AWS_AMPLIFY_CONFIG, ENABLE_MSW } from 'common/config';
import {
  EBX_LAST_USED_PRODUCT_KEY,
  EMAIL,
  LOGIN_STARTED,
  LOG_LEVELS,
  NO_EXPIRY_DATE,
  ROUTE_REDIRECTIONS,
} from 'common/constants';
import * as cookie from 'common/cookie';
import { getHostType, getHostname } from 'common/environment';
import {
  clearFeatureFlagOverrides,
  getFeatureFlagOverrides,
  setFeatureFlagOverrides,
} from 'common/featureFlags';
import * as logger from 'common/logger';
import { stringifyKeys } from 'common/object';
import { initializeOfflineDetection } from 'common/offlineDetection';
import { isIgnoredError } from 'common/sentry';
import { isNull, isRunningLocally } from 'common/utility';
import migrations from 'migrations';

// Run migrations before anything else
migrations.run();

async function enableMocking() {
  if (!isRunningLocally() || !ENABLE_MSW) {
    return undefined;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

if (cookie.getCookieValue(LOGIN_STARTED)) {
  const loginTimeAppLoaded = Date.now() - cookie.getCookieValue(LOGIN_STARTED);
  logger.track({
    event: 'Login Time - Social App Loaded',
    properties: {
      runTimeMS: loginTimeAppLoaded,
      UserEmailAddress: cookie.getCookieValue(EMAIL) ?? '',
    },
  });
}

// Amplify configuration
Amplify.configure(AWS_AMPLIFY_CONFIG);
EchoboxAuth.configure({
  postServiceAuth,
  logger,
});

axios.interceptors.request.use(async (request) => {
  const requestWithCST =
    await EchoboxAuth.checkAndRefreshClientServiceTokens(request);
  return requestWithCST;
});
axios.interceptors.request.use(async (request) => {
  let requestWithCognitoTokens = request;
  try {
    requestWithCognitoTokens =
      await EchoboxAuth.checkAndRefreshCognitoTokens(request);
  } catch (error) {
    logger.error({
      event: 'Failed to refresh Cognito tokens',
      error,
    });

    window.location.href = ROUTE_REDIRECTIONS.LOGOUT;
  }
  return requestWithCognitoTokens;
});

// axios.interceptors.request.use(request => refreshSession(request));
// Add correlation-id header to all requests
axios.interceptors.request.use((request) => addCorrelationId(request));
// Log rate limit warnings
axios.interceptors.response.use((response) => logRateLimitWarning(response));

const retriedRequests = new Set();

axios.interceptors.response.use(
  (response) => {
    const requestKey = `${response.config.method}:${response.config.url}`;
    retriedRequests.delete(requestKey);
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      const { config } = error;
      const { method, url } = config;
      const requestKey = `${method}:${url}`;
      if (!retriedRequests.has(requestKey)) {
        retriedRequests.add(requestKey);
        logger.track({
          event: 'Retrying Axios Request',
          error: stringifyKeys(error),
        });
        return axios.request(config);
      }
      retriedRequests.delete(requestKey);
    }
    return Promise.reject(error);
  },
);

// By default encode all query parameters
axios.defaults.paramsSerializer = {
  serialize: (params) => {
    try {
      return queryString.stringify(params, { skipNull: true });
    } catch (error) {
      logger.error({
        event: 'Failed to serialize parameters',
        error,
        properties: { params },
      });
      throw error;
    }
  },
};

// Sentry initialisation
const hostname = getHostname();
const host = window.location.host.split('.')[0];
const dsns = {
  social:
    'https://0248ca261ade4ee4bd323dc0d36962a3@o155242.ingest.sentry.io/1211089',
  'secure-review':
    'https://5052383a9a2848a08b4c2cb40cc5391b@o155242.ingest.sentry.io/1211088',
  'secure-stage':
    'https://02a43b3e2b494975a5950c17ba550201@o155242.ingest.sentry.io/1211087',
  // local:
  //   'https://101e1945b9ab49a4b4a61808e626f430@o155242.ingest.sentry.io/1211082',
};
const rates = {
  social: 0.4,
  'secure-review': 0.01,
  'secure-stage': 0.01,
  local: 1,
};
const REPLAY_SESSION_SAMPLE_RATE = 0;
const REPLAY_ERROR_SAMPLE_RATE = 0.05;
const dsn = dsns[hostname];

if (typeof dsn !== 'undefined') {
  Sentry.init({
    dsn,
    release: APP_VERSION,
    environment: host,
    replaysSessionSampleRate: REPLAY_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: REPLAY_ERROR_SAMPLE_RATE,
    integrations: [new Sentry.Replay()],
    tracesSampleRate: rates[hostname],
    beforeSend(event, hint) {
      // Ignore non-error promise rejections
      if (
        event &&
        event.__serialized__?.error &&
        event.__serialized__?.status
      ) {
        return null;
      }
      // Handle non-error exceptions
      const ignoredError = isIgnoredError(event, hint);
      if (
        event &&
        event.message &&
        event.message.startsWith('Non-Error exception captured') &&
        hint &&
        hint.originalException?.error &&
        !ignoredError
      ) {
        Sentry.withScope((scope) => {
          scope.setExtra('nonErrorException', true);
          Sentry.captureException(hint.originalException.error);
        });
        return null;
      }
      if (!ignoredError) {
        return event;
      }
      return null;
    },
  });
}

// Global logging level
if (isNull(window.sessionStorage.getItem('logLevel'))) {
  window.sessionStorage.setItem('logLevel', LOG_LEVELS.ERROR);
}

// Starts polling for offline detection - will trigger PubSub events on any change.
initializeOfflineDetection();

// Initialise metrics object
window.metrics = {};

// Set the current session as online if this script was able to run
window.sessionStorage.setItem('isOnline', true);

// Set up EBX Last Used Product Cookie
cookie.setCookieValue(
  EBX_LAST_USED_PRODUCT_KEY,
  `${window.location.protocol}//${window.location.host}`,
  {
    expires: NO_EXPIRY_DATE,
    domain: cookie.getTLD(window.location.hostname),
  },
);

initChameleon();

// update iframe src to point to correct login app based on environment
const hostType = getHostType();
const iframe = document.getElementById('login-app');
iframe.src = LOGIN_MESSAGING_URLS[hostType];

// Set up helper methods to configure feature flag overrides from dev tools
window.EBX = {
  getFeatureFlagOverrides,
  setFeatureFlagOverrides,
  clearFeatureFlagOverrides,
};

// Setup tanstack/react-query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // We handle retries within Axios already

      // Can be excessive for most use cases
      refetchOnWindowFocus: false,
    },
  },
});
const enableTanstackDevTools =
  import.meta.env.VITE_TANSTACK_QUERY_DEV_TOOLS?.toLowerCase() === 'true';

enableMocking().then(() => {
  const router = createBrowserRouter([
    {
      path: '*',
      Component: Root,
      // Suppress errors that reach this error boundary
      // They should be caught and logged by our internal error boundary
      errorElement: () => null,
    },
  ]);

  // Render application
  const root = ReactDOM.createRoot(document.querySelector('#root'));
  root.render(
    <QueryClientProvider client={queryClient}>
      <EbxProvider>
        <RouterProvider router={router} />
      </EbxProvider>
      {enableTanstackDevTools && <ReactQueryDevtools />}
    </QueryClientProvider>,
  );
});
