import { ReactNode } from 'react';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

interface BoldProps {
  children: ReactNode;
}

function Bold({ children }: BoldProps) {
  return (
    <Box as="strong" fontWeight={500}>
      {children}
    </Box>
  );
}

export default Bold;
