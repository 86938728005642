import { TEXT_CASES } from 'common/constants';
import { getSocialNetworkName } from 'common/social';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import { getDefaultColumns } from 'helpers/analyticsPage';
import type { APITypeId } from 'types';
import Insight from './Insight';

interface InsightsProps {
  apiTypeId: APITypeId;
  cellId: string;
  displayOptions: {
    insightColumns?: {
      [networkName: string]: string[];
    };
  };
  insightData:
    | (Record<string, number> & {
        extraInsights?: Record<string, number>;
        breakdown: { type: string; value: string }[];
      })
    | undefined;
}
const Insights = ({
  apiTypeId,
  cellId,
  displayOptions,
  insightData,
}: InsightsProps) => {
  const networkName = getSocialNetworkName({
    apiTypeId,
    textCase: TEXT_CASES.LOWER,
  });

  const insightKeys =
    displayOptions.insightColumns?.[networkName] ??
    getDefaultColumns({ apiTypeId });

  return insightKeys.map((insightKey) => {
    const analyticsInsights = getSocialAnalyticsInsights(apiTypeId);
    const columnDetails = analyticsInsights?.[insightKey];
    if (!columnDetails) return null;
    const cellKey = `${insightKey}_${cellId}`;
    return (
      <Insight
        key={insightKey}
        cellKey={cellKey}
        apiTypeId={apiTypeId}
        insightKey={insightKey}
        insightData={insightData}
      />
    );
  });
};

export default Insights;
