/**
 * GET /api/social/{socialPageURN}/media/search?text={search-text}
 *
 * @param {string} socialPageURN - social page URN
 * @param {string} searchText    - text to search for
 * @return [string]              - array of media ids
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ mediaIds: z.array(z.string()) })
  .describe('getMediaSearch.ResponseSchema');

export default async function getMediaSearch({
  socialPageURN,
  searchText,
}: {
  socialPageURN: string;
  searchText: string;
}) {
  // Search text must be at least two characters
  if (searchText.length < 2) {
    throw new ReferenceError('search text must be at least two characters');
  }

  const guid = metrics.start('getMediaSearch');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${socialPageURN}/media/search`,
    method: 'GET',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
    params: {
      text: searchText,
    },
  };
  logger.info(
    `API:getMediaSearch /social/api/${socialPageURN}/media/search/?text=${searchText}`,
  );

  try {
    const response = await axios(config);
    metrics.end('getMediaSearch', guid);
    logger.debug('getMediaSearch', { socialPageURN, searchText });
    const data = lyingParse(ResponseSchema, response.data);
    return data;
  } catch (error) {
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getMediaSearch',
      args: {
        socialPageURN,
        searchText,
      },
    });
    throw apiError;
  }
}
