import type { AxiosRequestConfig } from 'axios';
import { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { z } from 'zod';

import axios from 'api/axios';
import { handleAPIError } from 'common/errorHandling';
import * as metrics from 'common/metrics';
import { lyingParse } from 'common/zod';

const RETRY_DELAY_MS = 5000;

const ResponseSchema = z
  .object({
    etag: z.string(),
  })
  .describe('putUploadVideoPart.ResponseSchema');

export default async function putUploadVideoPart({
  url,
  data,
  controller,
  onProgress,
  index,
  percentageArray,
}: {
  url: string;
  data: Blob;
  controller: AbortController;
  onProgress?: (args: { percent: number }) => void;
  index: number;
  percentageArray: number[];
}) {
  const guid = metrics.start('putUploadVideoPart');
  const config: AxiosRequestConfig = {
    url,
    method: 'PUT',
    timeout: 0,
    data,
    signal: controller.signal,
    'axios-retry': {
      retries: 3,
      retryCondition: (error) => {
        return error?.code === 'ERR_NETWORK'
          ? true
          : isNetworkOrIdempotentRequestError(error);
      },
      retryDelay: (retryCount, error) => {
        return error?.code === 'ERR_NETWORK' ? RETRY_DELAY_MS : 0;
      },
    },
    onUploadProgress: ({ loaded, total = 1 }) => {
      const percent = Math.floor((loaded / total) * 10000) / 100;
      // eslint-disable-next-line no-param-reassign
      percentageArray[index] = percent;

      let totalPercent = 0;
      for (let i = 0; i < percentageArray.length; i += 1) {
        totalPercent += percentageArray[i];
      }
      const newPercent = totalPercent / percentageArray.length;

      onProgress?.({ percent: newPercent });
    },
  };

  try {
    const response = await axios(config);
    response.headers.etag = response.headers.etag.replaceAll('"', '');
    metrics.end('putUploadVideoPart', guid);
    const validatedData = lyingParse(ResponseSchema, response.headers);
    return validatedData;
  } catch (error) {
    metrics.fail('putUploadVideoPart', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/putUploadVideoPart',
      args: {
        url,
      },
    });
    throw apiError;
  }
}
