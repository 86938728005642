import {
  Box,
  BoxProps,
  Divider,
  EditPen1Icon,
  FormControl,
  forwardRef,
  Header,
  Heading,
  HeadingProps,
  IconProps,
  ImageIcon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Popover,
  Spinner,
  VideoNewIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { FormEvent, useCallback, useRef, useState } from 'react';
import { debounce } from 'throttle-debounce';

import {
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  API_TYPE_IDS,
  DEBOUNCE_INPUT_DELAY,
  FRONTEND_METRICS,
  POST_TYPES,
  SOCIAL_CHANNELS,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { getFeatureToggle, isIGCabinetEnabled } from 'common/settings';
import {
  getMaximumUrlByteLength,
  getMaximumUrlLength,
  hasPostType,
} from 'common/social';
import { supportsSocialChannel } from 'common/socialV2';
import { getLengthInBytes } from 'common/string';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { SocialChannel } from 'types';

interface ArticleInputFormProps extends InputProps {
  onProcessURL: (articleURL: string) => Promise<void>;
}
const ArticleInputForm = forwardRef<ArticleInputFormProps, 'input'>(
  ({ onProcessURL, ...rest }, ref) => {
    const [isLoading, setIsLoading] = useState(false);
    const [articleURL, setArticleURL] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const onProcessChange = async (URL: string) => {
      const apiTypeId = API_TYPE_IDS.INSTAGRAM;
      const maximumUrlLength = getMaximumUrlLength({ apiTypeId });
      const maximumUrlByteLength = getMaximumUrlByteLength({ apiTypeId });

      if (maximumUrlLength != null && URL.length > maximumUrlLength) {
        setErrorMessage('This URL is more than 245 characters');
        return;
      }

      if (
        maximumUrlByteLength != null &&
        getLengthInBytes(URL) > maximumUrlByteLength
      ) {
        setErrorMessage('This URL is too long.');
      }

      if (URL !== '' && !isLoading) {
        setErrorMessage('');
        setIsLoading(true);

        try {
          await onProcessURL(URL);
          setArticleURL('');
        } catch (error) {
          setErrorMessage(getErrorMessage(error) ?? 'An error occurred');
        } finally {
          setIsLoading(false);
        }
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps -- can't use inline function here as it's returned by the 3rd party library throttle-debounce
    const processURLChangeDebounced = useCallback(
      debounce(DEBOUNCE_INPUT_DELAY, onProcessChange),
      [isLoading],
    );

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onProcessChange(articleURL);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setArticleURL(e.target.value);
      setErrorMessage('');
      processURLChangeDebounced(e.target.value);
    };

    return (
      <Box py="2" px="4">
        <form onSubmit={onSubmit} noValidate>
          <FormControl isInvalid={!!errorMessage}>
            <InputGroup>
              <Input
                ref={ref}
                pr="10"
                type="url"
                placeholder="Enter URL"
                value={articleURL}
                onChange={onInputChange}
                {...rest}
              />
              {isLoading && (
                <InputRightElement h="full">
                  <Spinner />
                </InputRightElement>
              )}
            </InputGroup>
            {errorMessage && (
              <FormControl.FormErrorMessage>
                {errorMessage}
              </FormControl.FormErrorMessage>
            )}
          </FormControl>
        </form>
      </Box>
    );
  },
);

interface DropdownItemProps extends BoxProps {
  children: React.ReactNode;
  onClick: () => void;
  id?: string;
}
const DropdownItem = ({
  children,
  id,
  onClick,
  ...rest
}: DropdownItemProps) => (
  <Box
    as="button"
    py="2"
    px="4"
    display="flex"
    width="full"
    background="none"
    border="none"
    alignItems="center"
    data-cy-action={id}
    onClick={onClick}
    type="button"
    color="gray.900"
    fontWeight="medium"
    _hover={{ background: 'primary.50', color: 'primary.600' }}
    _focus={{ background: 'primary.50', color: 'primary.600', outline: 'none' }}
    {...rest}
  >
    {children}
  </Box>
);

interface DropdownHeaderProps extends HeadingProps {
  text: React.ReactNode;
}
const DropdownHeader = ({ text, ...rest }: DropdownHeaderProps) => (
  <Heading variant="h6" pl="4" pt="2" pb="1" color="gray.600" {...rest}>
    {text}
  </Heading>
);

/**
 * A new share menu specifically for Instagram, grouped by destination.
 */
const NewSharePanel = () => {
  const inputRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const isLinkInBioEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.LINKINBIO_ENABLED,
    propertyId: getCurrentPropertyId({ globalInfo }),
  });

  const accountAPIId = getCurrentAccountAPIId();
  const apiTypeId = getAPITypeId({ accountAPIId });

  const isInstagram = apiTypeId === API_TYPE_IDS.INSTAGRAM;
  const isFacebook = apiTypeId === API_TYPE_IDS.FACEBOOK;

  const isStoriesSupported = supportsSocialChannel(
    apiTypeId,
    SOCIAL_CHANNELS.STORY,
  );

  const isReelsSupported = supportsSocialChannel(
    apiTypeId,
    SOCIAL_CHANNELS.REEL,
  );

  const supportsLinkPost =
    !isInstagram &&
    hasPostType({
      apiTypeId,
      postType: POST_TYPES.LINK,
    });

  const onProcessChange = async (
    socialChannel: SocialChannel,
    articleURL: string,
    onSuccess: () => void,
  ) => {
    setIsProcessing(true);
    metrics.mark(FRONTEND_METRICS.OPEN_COMPOSE_BOX_NEW_SHARE);

    try {
      await Compose.addLinkPost({
        accountAPIId,
        articleURL,
        socialChannel,
      });
      onSuccess();
    } catch (error) {
      if (error instanceof Error || error instanceof ErrorEvent) {
        logger.error({
          event: 'Caught Error',
          properties: {
            location: 'components/NewSharePanel',
          },
          error,
        });
      }
      throw error;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAddPhotoPost = (socialChannel?: SocialChannel) => {
    Compose.addNonLinkPost({
      accountAPIId: getCurrentAccountAPIId(),
      postType: POST_TYPES.STATUS,
      ...(socialChannel && { socialChannel }),
    });
  };

  const handleAddVideoPost = (socialChannel?: SocialChannel) => {
    Compose.addNonLinkPost({
      accountAPIId: getCurrentAccountAPIId(),
      postType: POST_TYPES.VIDEO,
      ...(socialChannel && { socialChannel }),
    });
  };

  const handleAddPhotoStory = () => {
    Compose.addNonLinkPost({
      accountAPIId: getCurrentAccountAPIId(),
      postType: POST_TYPES.PHOTO_STORY,
    });
  };

  const dropdownItemIconProps: IconProps = {
    color: 'gray.600',
    _groupHover: { color: 'currentcolor' },
    size: '5',
    mr: '2',
  };

  return (
    <Popover initialFocusRef={inputRef} placement="bottom-end">
      {({ onClose, isOpen }) => {
        const actionThenClose = (func: () => void) => () => {
          func();
          onClose();
        };
        return (
          <>
            <Popover.Trigger>
              <Header.Button
                id="newShareButton"
                data-cy-action="newShare"
                leftIcon={<EditPen1Icon />}
              >
                New share
              </Header.Button>
            </Popover.Trigger>
            <Popover.Content
              w="280px"
              borderRadius="6px"
              boxShadow="lg"
              py="2"
              px="0"
              gap="0"
              data-cy-id="new-share-panel"
            >
              <Box
                // Using a key here will reset the form state when closing the popup
                key={`${isOpen}`}
              >
                <DropdownHeader text="Post" />
                {(isLinkInBioEnabled || supportsLinkPost) && (
                  <ArticleInputForm
                    ref={inputRef}
                    isDisabled={isProcessing}
                    onProcessURL={(url) =>
                      onProcessChange(SOCIAL_CHANNELS.FEED, url, onClose)
                    }
                    data-cy-input="link"
                    aria-label="Article URL for post"
                  />
                )}
                <DropdownItem
                  data-group
                  onClick={actionThenClose(handleAddPhotoPost)}
                  id="imageStatus"
                >
                  <ImageIcon {...dropdownItemIconProps} />
                  Photo{isFacebook && '/Status'}
                </DropdownItem>
                <DropdownItem
                  data-group
                  onClick={actionThenClose(handleAddVideoPost)}
                  id="video"
                >
                  <VideoNewIcon {...dropdownItemIconProps} />
                  Video
                </DropdownItem>
                {isIGCabinetEnabled() && isInstagram && (
                  <>
                    <Divider my={2} color="gray.300" />
                    <DropdownHeader text="Story" />
                    <ArticleInputForm
                      isDisabled={isProcessing}
                      onProcessURL={(url) =>
                        onProcessChange(SOCIAL_CHANNELS.STORY, url, onClose)
                      }
                      data-cy-input="story-link"
                      aria-label="Article URL for story"
                    />
                    <DropdownItem
                      data-group
                      onClick={actionThenClose(handleAddPhotoStory)}
                      id="imageStatus"
                    >
                      <ImageIcon {...dropdownItemIconProps} />
                      Photo
                    </DropdownItem>
                  </>
                )}
                {!isIGCabinetEnabled() && isStoriesSupported && (
                  <>
                    <Divider my={2} color="gray.300" />
                    <DropdownHeader text="Story" />
                    <DropdownItem
                      data-group
                      id="imageStory"
                      onClick={actionThenClose(() =>
                        handleAddPhotoPost(SOCIAL_CHANNELS.STORY),
                      )}
                    >
                      <ImageIcon {...dropdownItemIconProps} />
                      Photo
                    </DropdownItem>
                    <DropdownItem
                      data-group
                      id="videoStory"
                      onClick={actionThenClose(() =>
                        handleAddVideoPost(SOCIAL_CHANNELS.STORY),
                      )}
                    >
                      <VideoNewIcon {...dropdownItemIconProps} />
                      Video
                    </DropdownItem>
                  </>
                )}
                {(!isIGCabinetEnabled() || isFacebook) && isReelsSupported && (
                  <>
                    <Divider my={2} color="gray.300" />
                    <DropdownHeader text="Reel" />
                    <DropdownItem
                      data-group
                      onClick={actionThenClose(() =>
                        handleAddVideoPost(SOCIAL_CHANNELS.REEL),
                      )}
                      id="video"
                    >
                      <VideoNewIcon {...dropdownItemIconProps} />
                      Video
                    </DropdownItem>
                  </>
                )}
              </Box>
            </Popover.Content>
          </>
        );
      }}
    </Popover>
  );
};

export default NewSharePanel;
