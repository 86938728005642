/* eslint no-use-before-define:"off" */

import { FLASH_IGNORED_ERRORS, PROTECTED_ERRORS } from 'common/config';
import { isNullOrUndefined } from 'common/utility';

export { getBlockedErrors, ignoreMessage, isBlockedError, isProtectedError };

function getBlockedErrors() {
  let blockedErrors = sessionStorage.getItem('blockedErrors');
  if (isNullOrUndefined(blockedErrors)) {
    blockedErrors = [];
  } else {
    blockedErrors = JSON.parse(blockedErrors);
  }
  return blockedErrors;
}

function ignoreMessage(message) {
  if (isNullOrUndefined(message) || message.trim() === '') {
    return true;
  }
  let ignored = false;
  FLASH_IGNORED_ERRORS.forEach((ignore) => {
    if (message.match(ignore)) {
      ignored = true;
    }
  });
  return ignored;
}

function isBlockedError(message) {
  const blockedErrors = getBlockedErrors();
  const result = blockedErrors.some((error) => error === message.text);
  return result;
}

function isProtectedError(message) {
  let result = false;
  PROTECTED_ERRORS.forEach((error) => {
    if (error.test(message.text)) {
      result = true;
    }
  });
  return result;
}
