import { Heading } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useEffect } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import ConnectSocialPagesComponent from 'components/settings/page/ConnectSocialPages';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { useOnboardingSetupContext } from 'context/OnboardingContext';

const ConnectSocialPages = () => {
  const { setNextButtonDisabled } = useOnboardingSetupContext();

  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();
  const currentProperty = getCurrentProperty({ globalInfo });
  const connectedSocialPagesLength = Object.keys(
    currentProperty.accountAPIs,
  ).filter((key) =>
    [
      API_TYPE_IDS.FACEBOOK,
      API_TYPE_IDS.TWITTER,
      API_TYPE_IDS.LINKEDIN,
      API_TYPE_IDS.INSTAGRAM,
      API_TYPE_IDS.TIKTOK,
    ].includes(currentProperty.accountAPIs[key].apiTypeId),
  ).length;

  useEffect(() => {
    setNextButtonDisabled(connectedSocialPagesLength === 0);
  }, [connectedSocialPagesLength, setNextButtonDisabled]);

  return (
    <>
      <Heading variant="h2" mb={8}>
        Connect social pages
      </Heading>
      <ConnectSocialPagesComponent isOnboarding />
    </>
  );
};

export default ConnectSocialPages;
