import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Box, Flex, Image, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { postAPIsSettingsType } from 'api/api';
import postUploadImage from 'api/postUploadImage';
import {
  getAPITypeId,
  getCurrentAPITypeId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_PROPERTIES,
  GLOBAL_INFO_STATES,
} from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { convertEchoboxImageToAmazon } from 'common/s3';
import { getSetting } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { convertToSocialPageURN } from 'common/urn';
import Button from 'components/misc/Button';
import withGlobalInfo from 'context/withGlobalInfo';
import { getImageDimensions, toBase64 } from 'helpers/images';
import { getNetworkAndPageName } from 'helpers/tracking';
import Camera from 'svg/Camera';
import Equals from 'svg/Equals';
import Plus from 'svg/Plus';

const ImageBox = (props) => <Image w="189px" h="100px" {...props} />;

const ImageTitle = (props) => (
  <Text
    mb={1.5}
    color="#737b8b"
    fontWeight="medium"
    fontSize="12px"
    {...props}
  />
);

const IconContainer = (props) => <Box mx={4} mt={5} {...props} />;

function ImageOverlay(props) {
  const { accountAPIId } = props;
  const setting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
    propertyId: getCurrentPropertyId(accountAPIId),
    accountAPIId,
  });

  let initImageOverlayURL = setting?.dataJSON?.imageOverlayURL ?? null;
  if (initImageOverlayURL) {
    initImageOverlayURL = convertEchoboxImageToAmazon(initImageOverlayURL);
  }

  const [errorMessage, setErrorMessage] = useState(null);
  const [imageOverlayURL, setImageOverlayURL] = useState(initImageOverlayURL);
  const [isSaving, setIsSaving] = useState(false);

  const savingClass = isSaving ? 'saving_locked' : '';

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsSaving(true);
      setErrorMessage(null);
      const image = acceptedFiles[0];

      const isPNGImage = (file) => {
        return file?.type.indexOf('image/png') > -1;
      };
      const errorHandling = async (file) => {
        if (!isPNGImage(file)) {
          return 'The file type does not seem to be valid. Please check the file type.';
        }
        const base64 = await toBase64({
          file,
        });
        const { width, height } = await getImageDimensions({
          file: base64,
        });
        const aspectRatio = width / height;
        if (aspectRatio < 1.71 || aspectRatio > 2.09) {
          return 'The Overlay File Aspect Ratio should be between 1.71 and 2.09. Please try again.';
        }
        return null;
      };

      const message = await errorHandling(image);
      if (message) {
        setIsSaving(false);
        setErrorMessage(message);
        return;
      }
      const apiTypeId = getAPITypeId({
        accountAPIId,
      });
      const urnName = API_PROPERTIES[apiTypeId].urnName;
      const socialPageURN = convertToSocialPageURN(urnName, accountAPIId);
      const newImageOverlayURL = await postUploadImage({
        socialPageURN,
        imageFile: image,
        addWhiteBackground: false,
      });

      const settingConfig = {
        accountAPIId,
        settingTypeId: ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
        enabled: setting.enabled,
        dataJSON: {
          ...setting.dataJSON,
          imageOverlayURL: newImageOverlayURL,
        },
      };
      try {
        await postAPIsSettingsType(settingConfig);
        props.global.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          callback: async () => {
            setImageOverlayURL(convertEchoboxImageToAmazon(newImageOverlayURL));
            addSuccessNotification('Setting saved');
            setIsSaving(false);

            tracker.track({
              eventName: 'Add Post Overlay',
              trackingParams: {
                'Social Network': getSocialNetworkName({
                  apiTypeId: getCurrentAPITypeId(),
                }),
                'Account API Id': settingConfig.accountAPIId,
                'Network - Social Page': getNetworkAndPageName({
                  accountAPIId: settingConfig.accountAPIId,
                }),
              },
            });
          },
        });
      } catch (error) {
        logger.error({
          event: 'Save Image Overlay Settings Error',
          properties: {
            location: 'common/settings/page',
            arguments: {
              settingConfig,
            },
          },
          error,
        });

        setErrorMessage(getErrorMessage(error));
        setIsSaving(false);
        addErrorNotification('Error saving setting');
      }
    },
    [accountAPIId, props.global, setting.dataJSON, setting.enabled],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
    },
    maxFiles: 1,
    multiple: false,
    onDrop,
  });

  return (
    <>
      <div className="setting-part d-flex flex-row border-top">
        <div className="mr-auto d-flex flex-column">
          <div className="text-700 ft-14 lh-20">Image Overlay</div>
          <div className="text-400 ft-12 mr-2">
            Upload a logo or banner to overlay your post images
          </div>
        </div>
        <div
          className="btn btn-light align-self-center"
          data-cy-action="imageOverlay"
          data-toggle="collapse"
          data-target="#collapseImageOverlay"
          role="button"
          aria-expanded="false"
          aria-controls="collapseImageOverlay"
        >
          Edit
        </div>
      </div>
      <div
        className="collapse settings-sub settings-share-preview"
        id="collapseImageOverlay"
        data-parent="#accordion"
        data-cy-id="dataSourceSettings"
      >
        <div className={`${savingClass}`}>
          <Box mb={5} p={5} borderY="1px solid rgb(225, 227, 231)">
            <Flex pb={5} alignItems="center">
              <div>
                <ImageTitle>Original image</ImageTitle>
                <ImageBox src="/img/image_overlay_bg.png" />
              </div>
              <IconContainer>
                <Plus />
              </IconContainer>
              <div>
                <ImageTitle>Image overlay</ImageTitle>
                {imageOverlayURL && <ImageBox src={imageOverlayURL} />}
                {!imageOverlayURL && (
                  <Flex
                    w="189px"
                    h="100px"
                    borderRadius="3px"
                    border="1px dashed #e1e3e7"
                    flexDir="column"
                    justifyContent="flex-end"
                  >
                    <Box
                      bg="rgba(115, 123, 139, 0.5)"
                      mb={2}
                      color="white"
                      fontSize="12px"
                      py="3px"
                      px="10px"
                    >
                      Your overlay design
                    </Box>
                  </Flex>
                )}
              </div>
              <IconContainer>
                <Equals />
              </IconContainer>
              <div className="position-relative">
                <ImageTitle>Shared image</ImageTitle>
                {imageOverlayURL && (
                  <Image
                    w="189px"
                    h="100px"
                    background="url('/img/image_overlay_bg.png')"
                    src={imageOverlayURL}
                  />
                )}
                {!imageOverlayURL && (
                  <div>
                    <ImageBox src="/img/image_overlay_bg.png" />
                    <Box
                      bg="rgba(115, 123, 139, 0.5)"
                      mb={2}
                      color="white"
                      fontSize="12px"
                      py="3px"
                      px="10px"
                      pos="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                    >
                      Your overlay design
                    </Box>
                  </div>
                )}
              </div>
            </Flex>
            <Text fontSize="13px" lineHeight="1.4">
              Please upload a PNG file with a transparent background. The Image
              Aspect Ratio should be between 1.71 and 2.09. <br /> You’ll be
              able to enable the overlay to your posts in the Compose Box.
            </Text>
          </Box>
          <Button
            className="add_photo .text_unselectable_saving mt-1 mb-4"
            data-tip
            data-for="image-add"
            variant="dark"
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Camera />
              <span className="ml-2">Upload image overlay</span>
            </div>
          </Button>
          {errorMessage && (
            <div>
              <Image display="inline-block" src="/img/icons/ic-alert.svg" />
              <Text size="sm" display="inline-block" color="red.600">
                {errorMessage}
              </Text>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

ImageOverlay.propTypes = {
  accountAPIId: PropTypes.any.isRequired,
  global: PropTypes.shape({
    refreshGlobalInfo: PropTypes.func.isRequired,
  }).isRequired,
};
export default withGlobalInfo(ImageOverlay);
