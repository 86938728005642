import {
  Box,
  Button,
  Collapse,
  DeleteTrashIcon,
  Flex,
  Heading,
  IconButton,
  InfoIcon,
  Modal,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import postSeasonalKeywordsUpload from 'api/postSeasonalKeywordsUpload';
import { getErrorMessage } from 'common/errorHandling';

export const SUPPORTED_FORMATS: Accept = {
  'text/csv': [],
  'text/plain': [],
  'application/csv': [],
  'application/vnd.ms-excel': [],
};

interface UploadSeasonalKeywordsModalInterface {
  onClose: () => void;
}

const UploadSeasonalKeywordsModal = ({
  onClose,
}: UploadSeasonalKeywordsModalInterface) => {
  const [csvFile, setCSVFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: SUPPORTED_FORMATS,
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noDrag: false,
    onDrop: (acceptedFiles) => {
      setCSVFile(acceptedFiles[0]);
    },
    preventDropOnDocument: true,
  });

  const handleUpload = async () => {
    setErrorMessage(null);

    if (csvFile !== null) {
      try {
        await postSeasonalKeywordsUpload(csvFile);
        onClose();
      } catch (error) {
        setErrorMessage(getErrorMessage(error));
      }
    }
  };

  return (
    <Modal isOpen onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Upload Seasonal Keywords</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box
          bg="gray.100"
          py={10}
          px={15}
          border="1px"
          borderStyle="dashed"
          borderColor="gray.300"
          borderRadius="base"
          w="full"
          opacity={isDragActive ? 0.5 : undefined}
          {...getRootProps()}
        >
          <Heading variant="h5" mb={3}>
            Drag and drop file
          </Heading>
          <input {...getInputProps()} />
          <Button variant="secondary" onClick={open}>
            Select .CSV File
          </Button>
        </Box>
        <Collapse in={csvFile !== null} animateOpacity>
          <Box
            border="1px"
            borderColor="gray.300"
            borderRadius="base"
            p={4}
            mt={4}
          >
            <Flex justify="space-between" align="center">
              <Heading variant="h5">{csvFile?.name}</Heading>
              <IconButton
                aria-label="Remove"
                size="lg"
                icon={<DeleteTrashIcon color="gray.600" />}
                onClick={() => setCSVFile(null)}
              />
            </Flex>
            {errorMessage && (
              <Box mt={4}>
                <Heading variant="h5" color="red.600">
                  <InfoIcon mr={1} />
                  {errorMessage}
                </Heading>
              </Box>
            )}
          </Box>
        </Collapse>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button isDisabled={csvFile === null} onClick={handleUpload}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadSeasonalKeywordsModal;
