import { Link } from 'react-router-dom';

const PerformanceGoalLink = () => {
  return (
    <div className="setting-part border-top d-flex flex-row">
      <div className="mr-auto">
        <div className="ft-13 text-500">Performance goal</div>
      </div>
      <Link
        className="btn btn-light align-self-center ml-2"
        to="/settings/property/performancegoal"
        data-cy-action="navigatePerformanceGoal"
      >
        Edit
      </Link>
    </div>
  );
};

export default PerformanceGoalLink;
