import {
  Box,
  Dropdown,
  Flex,
  IconProps,
  IgPostIcon,
  ImageIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { getAPIPostName } from 'common/accountAPIs';
import { API_TYPE_IDS, POST_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getSocialNetworkName } from 'common/social';
import { track } from 'common/tracker';
import {
  handlePostTypeChange,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import { AccountAPIId, FixTypeLater, PostType } from 'types';

type Type = 'post' | 'image';
const TYPE_CONFIG: Record<
  Type,
  { label: string; Icon: (props: IconProps) => JSX.Element }
> = {
  post: { label: 'Post', Icon: IgPostIcon },
  image: { label: 'Image', Icon: ImageIcon },
};

interface PostTypeSwitcherProps {
  guid: string;
  postType: PostType;
  accountAPIId: AccountAPIId;
  mediaItem: FixTypeLater;
}
const PostTypeSwitcher = ({
  guid,
  postType,
  accountAPIId,
  mediaItem,
}: PostTypeSwitcherProps) => {
  const { dispatch } = useComposeBoxContext();
  const articleURL =
    MediaItem.getUnshortenedShareURL({ mediaItem }) ??
    MediaItem.getInstantImageArticleUrl({ mediaItem });

  const handleChange = (type: Type) => {
    const newPostType = type === 'post' ? POST_TYPES.LINK : POST_TYPES.STATUS;

    dispatch(
      handlePostTypeChange({
        guid,
        postType: newPostType,
      }),
    );

    track({
      eventName: 'Switch Post Type',
      trackingParams: {
        'Social Network': getSocialNetworkName({
          apiTypeId: API_TYPE_IDS.FACEBOOK,
        }),
        'Social Page': getAPIPostName({ accountAPIId }),
        'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
        'Account API Id': accountAPIId,
        'Article URL': articleURL,
        'Old Post Type': postType,
        'New Post Type': newPostType,
      },
    });
  };

  const selectedType = postType === POST_TYPES.LINK ? 'post' : 'image';
  const SelectedIcon = TYPE_CONFIG[selectedType].Icon;

  return (
    <Flex flexDir="column" alignItems="flex-start" gap="3px" mb="31px">
      <Box fontSize="12px" lineHeight="14px" color="#a3acb9" m={0}>
        Share link as
      </Box>
      <Dropdown size="md">
        <Flex
          as={Dropdown.Button}
          h="32px"
          py="7px"
          px={2}
          borderRadius="3px"
          boxShadow="inset 0px 0px 0px 1px #e3e8ee, 0px 3px 4px 0px #26487926, 0px 0px 3px 0px #26487966;"
          w="full"
          // Position a chevron icon to the right of the button
          backgroundImage="url(/img/icons/ic-arrow-down-2.svg)"
          backgroundRepeat="no-repeat"
          backgroundPosition="right 10px center"
        >
          <Flex as="span" gap={1} alignItems="center">
            <SelectedIcon aria-hidden size={4} color="#697386" />
            <Box
              as="span"
              color="#697386"
              fontWeight="medium"
              fontSize="13px"
              lineHeight="15px"
            >
              {TYPE_CONFIG[selectedType].label}
            </Box>
          </Flex>
        </Flex>
        <Dropdown.List>
          <Dropdown.Title>Share link as</Dropdown.Title>
          {Object.entries(TYPE_CONFIG).map(([type, { label, Icon }]) => (
            <Dropdown.Item
              key={type}
              onClick={() => {
                if (selectedType !== type) handleChange(type as Type);
              }}
              LeftIcon={Icon}
              isSelected={selectedType === type}
            >
              {label}
            </Dropdown.Item>
          ))}
        </Dropdown.List>
      </Dropdown>
    </Flex>
  );
};

export default PostTypeSwitcher;
