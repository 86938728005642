/* eslint no-use-before-define:"off" */

import { SCHEDULE_QUEUE_DEFAULT_ITEMS } from 'common/config';
import { SHARE_TIME_TYPES } from 'common/constants';
import { getDateFromUnix } from 'common/datetime';
import * as MediaItem from 'common/mediaItem';
import { isDefined, isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export { setExpandedFlags, setItemsPerDay };

/**
 * setExpandedFlags - given an items-per-day object (see seeItemsPerDay below),
 *                    and optionally an existing set of expanded flags,
 *                    create an object where the key is the day offset from today
 *                    and the value is a boolean specifying whether items for
 *                    that day should be shown - initially enough days are
 *                    "expanded" until at least SCHEDULE_QUEUE_DEFAULT_ITEMS items
 *                    are displayed
 */

function setExpandedFlags({
  itemsPerDay = mandatory('itemsPerDay'),
  expandedFlags = {},
} = {}) {
  const isDayExpanded = {};

  let itemsDisplayed = 0;

  // Loop over the items-per-day list and set expanded flags to their existing
  // values, or false by default if no existing entry for the day exists
  Object.keys(itemsPerDay).forEach((dateOffset, index) => {
    // Mark the day as unexpanded by default
    if (isUndefined(isDayExpanded[dateOffset])) {
      isDayExpanded[dateOffset] = false;
    }
    // Use the existing expanded flag if one exists
    if (isDefined(expandedFlags[dateOffset])) {
      isDayExpanded[dateOffset] = expandedFlags[dateOffset];
    } else {
      // Always expand the first entry
      if (index === 0) {
        isDayExpanded[dateOffset] = true;
        itemsDisplayed += itemsPerDay[dateOffset].length;
      }
      // Also expand the second entry if not enough items have been "expanded"
      if (index === 1 && itemsDisplayed < SCHEDULE_QUEUE_DEFAULT_ITEMS) {
        isDayExpanded[dateOffset] = true;
      }
    }
  });

  return isDayExpanded;
}

/**
 * setItemsPerDay - given a list of media items, create an object where the key
 *                  is the day offset from today and the value is a list of
 *                  indexes into the media item list of media items with an
 *                  optimal share time on that day
 */

function setItemsPerDay({ mediaItems = mandatory('mediaItems') } = {}) {
  const itemsPerDay = {};

  mediaItems.forEach((mediaItem, index) => {
    let dateOffset = getDateFromUnix(
      MediaItem.getOptimalShareTime({ mediaItem }),
    ).dateOffset;
    // if media item has share time of now, set dateOffset to now
    if (MediaItem.getShareTime({ mediaItem })?.type === SHARE_TIME_TYPES.NOW) {
      dateOffset = SHARE_TIME_TYPES.NOW;
    }

    if (isUndefined(itemsPerDay[dateOffset])) {
      itemsPerDay[dateOffset] = [];
    }
    itemsPerDay[dateOffset].push(index);
  });

  return itemsPerDay;
}
