import {
  Td as BaseTd,
  Button,
  CheckIcon,
  ChevronRightIcon,
  Circle,
  CloseIcon,
  Flex,
  FormControl,
  Image,
  LightingHighlightIcon,
  LightingOffIcon,
  Link,
  LinkBox,
  LinkOverlay,
  Tag,
  Text,
  Toggle,
  Tooltip,
  useDisclosure,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import postAPIsSettingsType from 'api/postAPIsSettingsType';
import * as accountAPIs from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  AUTOFEED_SHARE_MODES,
  GLOBAL_INFO_STATES,
  SAVE_STATES,
} from 'common/constants';
import * as settings from 'common/settings';
import * as social from 'common/social';
import { getSocialNetworkDefaultPageIcon } from 'common/social';
import * as tracker from 'common/tracker';
import SocialIcon from 'components/misc/SocialIcon';
import ConfirmTurnOnAutomation from 'components/settings/automation/ConfirmTurnOnAutomation';
import { Tr } from 'components/settings/PagesTable';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { getAutofeedSettingsTrackingParams } from 'helpers/tracking';
import type { ApprovalSettings, AutofeedSettings, FixTypeLater } from 'types';

const getSettingsFromGlobalInfo = ({
  accountAPIId,
}: {
  accountAPIId: number;
}) => {
  const defaults = social.getAutofeedDefaults({
    apiTypeId: accountAPIs.getAPITypeId({
      accountAPIId,
    }),
  });

  const autoFeedActive =
    settings.getAutofeedSetting({
      accountAPIId,
      key: 'autoFeedActive',
    }) ?? false;
  const approvalsActive =
    settings.isApprovalsSettingsActive(accountAPIId) ?? false;
  const newShareMode =
    settings.getAutofeedSetting({
      accountAPIId,
      key: 'newShareMode',
    }) ?? defaults.newShareMode;
  const reshareMode =
    settings.getAutofeedSetting({
      accountAPIId,
      key: 'reshareMode',
    }) ?? defaults.reshareMode;
  const newShareApprovals: Partial<ApprovalSettings> =
    settings.getAutofeedSetting({
      accountAPIId,
      key: 'newShareApprovals',
    }) || {};
  const reshareApprovals: Partial<ApprovalSettings> =
    settings.getAutofeedSetting({
      accountAPIId,
      key: 'reshareApprovals',
    }) || {};
  return {
    autoFeedActive,
    approvalsActive,
    newShareMode,
    reshareMode,
    newShareApprovals,
    reshareApprovals,
  };
};

const AutofeedStateChangeErrorSchema = z
  .object({
    error: z.object({ message: z.string() }),
  })
  .describe('AutofeedStateChangeErrorSchema');

type TdProps = React.ComponentPropsWithRef<typeof BaseTd> & {
  children: React.ReactNode;
};

const Td = (props: TdProps) => {
  return (
    <BaseTd
      boxShadow="borderRight"
      _last={{
        boxShadow: 'none',
      }}
      {...props}
    />
  );
};

const FirstCellWrapper = ({
  children,
  isHighlighted,
  isLinkBox,
}: {
  children: React.ReactNode;
  isHighlighted: boolean;
  isLinkBox: boolean;
}) => {
  const styles = {
    width: '64px',
    background: isHighlighted ? 'primary.50' : 'transparent',
    px: 4,
    py: 3.5,
    verticalAlign: 'middle',
    clipPath: 'inset(0px 0px 1px 1px)', // Background color in the cell covers the row divider, so this is a hack to not render the bg color over the box shadow
  };

  return isLinkBox ? (
    <LinkBox as={Td} {...styles} position="initial">
      {children}
    </LinkBox>
  ) : (
    <Td {...styles}>{children}</Td>
  );
};

const SecondCellWrapper = ({
  children,
  isLinkBox,
}: {
  children: React.ReactNode;
  isLinkBox: boolean;
}) => {
  const styles = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    py: 3,
    px: 4,
  };

  return isLinkBox ? (
    <LinkBox as={Td} {...styles}>
      {children}
    </LinkBox>
  ) : (
    <Td {...styles}>{children}</Td>
  );
};

const TitleWrapper = ({
  children,
  accountAPIId,
  isLink,
}: {
  children: React.ReactNode;
  accountAPIId: string;
  isLink: boolean;
}) => {
  return isLink ? (
    <LinkOverlay
      as={RouterLink}
      to={`/settings/automation/${accountAPIId}`}
      data-cy-action={`navigatePage:${accountAPIId}`}
    >
      {children}
    </LinkOverlay>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment -- best to keep this as a fragment rather than switching from a fragment to a div
    <>{children}</>
  );
};

const SettingsWrapper = ({
  areApprovalsActive,
  areNewSharesActive,
  areResharesActive,
}: {
  areApprovalsActive: boolean;
  areNewSharesActive: boolean;
  areResharesActive: boolean;
}) => {
  return (
    <Flex gap={3}>
      <Tag
        color={areApprovalsActive ? 'blue' : 'gray'}
        icon={areApprovalsActive ? <CheckIcon /> : <CloseIcon />}
        iconPlacement="left"
      >
        Approvals
      </Tag>
      <Tag
        color={areNewSharesActive ? 'blue' : 'gray'}
        icon={
          areNewSharesActive ? <LightingHighlightIcon /> : <LightingOffIcon />
        }
        iconPlacement="left"
      >
        New shares
      </Tag>
      <Tag
        color={areResharesActive ? 'blue' : 'gray'}
        icon={
          areResharesActive ? <LightingHighlightIcon /> : <LightingOffIcon />
        }
        iconPlacement="left"
      >
        Reshares
      </Tag>
    </Flex>
  );
};

export interface SocialPageProps {
  accountAPI: FixTypeLater;
}

function SocialPageRow({ accountAPI }: SocialPageProps) {
  const toast = useToast();
  const navigate = useNavigate();
  const finalRef = useRef<HTMLDivElement | null>(null);

  const {
    apiHomePage,
    apiPostImage,
    apiPostName,
    apiAltName,
    requiresReconnect,
    accountAPIId,
    apiTypeId,
  } = accountAPI;

  const automationSettings = getSettingsFromGlobalInfo({ accountAPIId });

  const [isAutoFeedActive, setAutoFeedActive] = useState(
    automationSettings.autoFeedActive,
  );
  const areApprovalsActive = automationSettings.approvalsActive;
  const areNewSharesActive =
    automationSettings.newShareMode !== AUTOFEED_SHARE_MODES.OFF;

  const areResharesActive =
    automationSettings.reshareMode !== AUTOFEED_SHARE_MODES.OFF;
  const isClickable = !requiresReconnect;

  const [saveState, setSaveState] = useState(SAVE_STATES.NONE);
  const {
    isOpen: isConfirmApprovalsOnOpen,
    onOpen: onConfirmApprovalsOnOpen,
    onClose: onConfirmApprovalsOnClose,
  } = useDisclosure();

  const { global } = useGlobalInfo();

  const checkAutomationToggle = () => {
    if (isAutoFeedActive) {
      executeAutomationToggle({});
    } else if (!automationSettings.approvalsActive) {
      onConfirmApprovalsOnOpen();
    } else {
      executeAutomationToggle({});
    }
  };

  const handleTurnOnApprovals = (newIsApprovalsActive: boolean) => {
    executeAutomationToggle({ newIsApprovalsActive });
  };

  const executeAutomationToggle = async ({
    newIsApprovalsActive,
  }: {
    newIsApprovalsActive?: boolean;
  }) => {
    if (saveState === SAVE_STATES.SAVING) {
      return;
    }

    setSaveState(SAVE_STATES.SAVING);

    const previousTracking = getAutofeedSettingsTrackingParams({
      autofeedSettings: settings.getAutofeedSettings({ accountAPIId }),
      origin: 'Property Automation',
    });
    const newAutoFeedActive = !isAutoFeedActive;
    setAutoFeedActive(newAutoFeedActive);

    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};
    existingSettings.autoFeedActive = newAutoFeedActive;

    // If approval state isn't specified, leave current values
    if (newIsApprovalsActive !== undefined) {
      existingSettings.newShareApprovals = {
        active: newIsApprovalsActive,
        evergreenOnly:
          automationSettings.newShareApprovals?.evergreenOnly ?? false,
      };
      existingSettings.reshareApprovals = {
        active: newIsApprovalsActive,
        evergreenOnly:
          automationSettings.reshareApprovals?.evergreenOnly ?? false,
      };
    }

    const settingConfig = {
      accountAPIId,
      settingTypeId: ACCOUNT_SETTING_TYPES.AUTO_FEED,
      enabled: true,
      dataJSON: existingSettings,
    };

    try {
      await postAPIsSettingsType(settingConfig);
      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        callback: () => {
          setSaveState(SAVE_STATES.SUCCEEDED);
          toast({
            title: 'Setting updated',
            description: 'Changes can take up to 8 hours to take effect',
            variant: 'success',
          });
          const updatedTracking = getAutofeedSettingsTrackingParams({
            autofeedSettings: settings.getAutofeedSettings({ accountAPIId }),
            origin: 'Property Automation',
          });
          tracker.track({
            eventName: 'Toggle Autofeed',
            trackingParams: {
              ...updatedTracking,
              'Social Page': apiPostName,
              'Previous Autofeed Status': previousTracking['Autofeed Status'],
            },
          });
        },
      });
    } catch (error) {
      const safeParseResult = AutofeedStateChangeErrorSchema.safeParse(error);
      if (safeParseResult.success) {
        toast({
          title: 'Error saving setting',
          description: safeParseResult.data.error?.message,
          variant: 'error',
        });
      } else {
        toast({
          title: 'Error saving setting',
          variant: 'error',
        });
      }
      // Reset autofeed state
      setAutoFeedActive(!newAutoFeedActive);
    }

    setSaveState(SAVE_STATES.SUCCEEDED);
    onConfirmApprovalsOnClose();
  };

  const handleReconnectClick = () => {
    navigate('/settings/pages');
  };

  const subText = !requiresReconnect ? (
    <Link
      href={apiHomePage}
      target="_blank"
      lineHeight={4}
      variant="secondary"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      @{apiAltName}
    </Link>
  ) : (
    <Button variant="link" color="red.600" onClick={handleReconnectClick}>
      Reconnect page
    </Button>
  );

  return (
    <>
      <Tr {...(isClickable && { _hover: { backgroundColor: 'gray.100' } })}>
        <FirstCellWrapper
          isHighlighted={isAutoFeedActive}
          isLinkBox={isClickable}
        >
          <Tooltip
            label="Let Echobox automatically share content with high potential"
            maxWidth="auto"
            isDisabled={isAutoFeedActive || requiresReconnect}
            placement="top"
          >
            <FormControl display="flex" alignItems="center">
              <Toggle
                isChecked={isAutoFeedActive}
                isDisabled={
                  saveState === SAVE_STATES.SAVING || requiresReconnect
                }
                onChange={checkAutomationToggle}
              />
            </FormControl>
          </Tooltip>
        </FirstCellWrapper>
        <SecondCellWrapper isLinkBox={isClickable}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            gap={3}
            ref={finalRef}
          >
            <Flex
              alignItems="center"
              flexGrow={1}
              flexShrink={1}
              overflow="hidden"
            >
              {apiPostImage !==
              getSocialNetworkDefaultPageIcon({ apiTypeId }) ? (
                <Image
                  width={5}
                  height={5}
                  src={apiPostImage}
                  alt=""
                  border="1px"
                  borderColor="gray.300"
                  borderRadius="full"
                  mr={3}
                />
              ) : (
                <Circle
                  bg="gray.200"
                  border="1px"
                  borderColor="gray.300"
                  size={5}
                  mr={3}
                >
                  <SocialIcon apiTypeId={apiTypeId} color="gray.500" size={3} />
                </Circle>
              )}
              <TitleWrapper accountAPIId={accountAPIId} isLink={isClickable}>
                <Text
                  size="sm"
                  lineHeight={4}
                  color="gray.900"
                  fontWeight="medium"
                  mr={2}
                  {...(requiresReconnect && {
                    textDecorationLine: 'line-through',
                    color: 'gray.500',
                  })}
                >
                  {apiPostName}
                </Text>
              </TitleWrapper>
              {subText}
            </Flex>
            <Flex alignItems="center" gap={6} flexShrink={0}>
              {isAutoFeedActive && (
                <SettingsWrapper
                  areApprovalsActive={areApprovalsActive}
                  areNewSharesActive={areNewSharesActive}
                  areResharesActive={areResharesActive}
                />
              )}
              <ChevronRightIcon m={1} />
            </Flex>
          </Flex>
        </SecondCellWrapper>
      </Tr>
      {isConfirmApprovalsOnOpen && (
        <ConfirmTurnOnAutomation
          accountAPIId={accountAPIId}
          isOpen={isConfirmApprovalsOnOpen}
          onClose={onConfirmApprovalsOnClose}
          handleAutomationChange={handleTurnOnApprovals}
          ref={finalRef}
        />
      )}
    </>
  );
}

export default SocialPageRow;
