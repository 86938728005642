import Immutable from 'seamless-immutable';

import { getAPITypeId } from 'common/accountAPIs';
import { CAN_CUSTOMISE_LINK_POSTS } from 'common/config';
import {
  ADD_LINK_REASONS,
  CONTENT_TYPES,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SHARE_TIME_TYPES,
  TAG_TYPES,
} from 'common/constants';
import * as s3 from 'common/s3';
import {
  addPlaceholderToMessage,
  hasABVariations,
  hasLinkPlaceholder,
} from 'common/social';
import { containsURL } from 'common/string';
import {
  cloneObject,
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import getShortenedURL from 'process/getShortenedURL';

import { addURLParameters } from 'helpers/url';
import { PREVIEW_FIELDS } from './common';
import { derivePostType } from './initialise';
import { minMaxToShareTime } from './save';
import {
  addShareContent,
  findShareContentIndex,
  getCurrentShareContentItem,
} from './shareContent';

export {
  addLink,
  getABInfo,
  getABResults,
  getABTestStatusId,
  getABVariations,
  getAIMessage,
  getAccountAPIId,
  getActionTypeId,
  getAdditionalSocialPages,
  getAutopilotToggle,
  getCanCustomiseLinkPosts,
  getCurrentABVariationIndex,
  getDescription,
  getErrorDetails,
  getErrorMessage,
  getFailReason,
  getFbTargetingParams,
  getFiles,
  getFirstComment,
  getHashtags,
  getIGLinkStickerConfig,
  getImageOverlayResult,
  getImageOverlayURL,
  getImageURLs,
  getInsights,
  getInstantImageArticleUrl,
  getIsApplyImageOverlay,
  getIsChanged,
  getIsLive,
  getIsLoading,
  getIsRefreshPreview,
  getIsReloading,
  getIsSaving,
  getIsTimingValid,
  getIsURLChanged,
  getIsURLResolved,
  getIsURLValid,
  getLastUpdateUnixTime,
  getLastUpdateUser,
  getMaxShareTime,
  getMediaId,
  getMediaItemTags,
  getMediaStatesByAccountAPIId,
  getMediaURN,
  getMentions,
  getMessage,
  getMessageHistory,
  getMessages,
  getMinShareTime,
  getMostRecentScore,
  getMostRecentUnixTimePublished,
  getMostRecentUnixTimeShared,
  getOptimalShareTime,
  getPostImageNeedsToBeUpdated,
  getPostType,
  getPreviewField,
  getPreviewFields,
  getRssTitle,
  getShareTime,
  getShareURL,
  getShouldSendURL,
  getSocialChannel,
  getSocialPostURL,
  getSponsor,
  getSponsorName,
  getState,
  getSuggestionTypeId,
  getTags,
  getTextContentAvailableState,
  getTimeSensitivityTypeId,
  getTitle,
  getTotalNumberOfShares,
  getTrackingDetails,
  getTwitterCardType,
  getUnixTimeShared,
  getUnshortenedShareURL,
  getValidationMessage,
  getVideoURL,
  hasLastUpdateDetails,
  setABInfo,
  setABVariations,
  setAIMessage,
  setAccountAPIId,
  setAdditionalSocialPages,
  setCurrentABVariationIndex,
  setDescription,
  setErrorDetails,
  setErrorMessage,
  setFbTargetingParams,
  setFiles,
  setFirstComment,
  setHashtags,
  setIGLinkStickerConfig,
  setImageOverlayResult,
  setImageOverlayURL,
  setImageURLs,
  setInstantImageArticleUrl,
  setIsApplyImageOverlay,
  setIsChanged,
  setIsLive,
  setIsLoading,
  setIsRefreshPreview,
  setIsReloading,
  setIsSaving,
  setIsTimingValid,
  setIsURLChanged,
  setIsURLResolved,
  setIsURLValid,
  setMediaId,
  setMediaItemTags,
  setMediaURN,
  setMentions,
  setMessage,
  setMessageHistory,
  setMessages,
  setOriginalMessages,
  setPostImageNeedsToBeUpdated,
  setPostType,
  setPreviewField,
  setShareTime,
  setShareURL,
  setShouldSendURL,
  setSocialChannel,
  setSponsor,
  setSponsorName,
  setState,
  setSuggestionTypeId,
  setTextContentAvailableState,
  setTimeSensitivityTypeId,
  setTitle,
  setTrackingDetails,
  setTwitterCardType,
  setUnshortenedShareURL,
  setUpdateDetails,
  setValidationMessage,
  setVideoURL,
};

/**
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * reasonCode: string
 * mediaItemIndex?: number
 * overrideURLParameters?: boolean
 * }}
 */
function addLink({
  mediaItem = mandatory('mediaItem'),
  reasonCode = mandatory('reasonCode'),
  mediaItemIndex = 0,
  overrideURLParameters = false,
} = {}) {
  return new Promise((resolve, reject) => {
    // No need to add link to a non-link post
    const postType = getPostType({ mediaItem });
    if (postType !== POST_TYPES.LINK) {
      resolve(mediaItem);
      return;
    }

    const accountAPIId = getAccountAPIId({ mediaItem });
    const apiTypeId = getAPITypeId({ accountAPIId });

    let replacementURL;
    const startingURL = getUnshortenedShareURL({ mediaItem });
    let mediaItemUpdated = mediaItem;

    // Add URL parameters to the relevant URL
    addURLParameters(
      startingURL, // pageURL
      accountAPIId, // accountAPIId
      isNull(getSuggestionTypeId({ mediaItem })), // isNewURL
      true,
      false,
      mediaItemIndex,
      overrideURLParameters,
    )
      .then((updatedURL) => {
        replacementURL = updatedURL;
        mediaItemUpdated = setUnshortenedShareURL({
          mediaItem,
          fieldValue: replacementURL,
        });
        // When editing item or changing to / from deleted, don't change URL
        if (
          reasonCode === ADD_LINK_REASONS.ENTER_COMPOSE ||
          reasonCode === ADD_LINK_REASONS.CHANGE_STATE
        ) {
          return null;
        }
        // In all other cases, get (potentially) shortened URL
        return getShortenedURL({
          pageURL: updatedURL,
          accountAPIId,
          apiTypeId,
        });
      })
      .then((shortURL) => {
        if (shortURL && shortURL !== '') {
          replacementURL = shortURL;
        }
        // Update the share URL
        mediaItemUpdated = setShareURL({
          mediaItem: mediaItemUpdated,
          fieldValue: replacementURL,
        });
        // Add link placeholder if it or other URL already exists in the share message
        if (
          hasLinkPlaceholder({ apiTypeId }) &&
          !containsURL(getMessage({ mediaItem }))
        ) {
          mediaItemUpdated = setMessage({
            mediaItem: mediaItemUpdated,
            fieldValue: addPlaceholderToMessage({
              apiTypeId: getAPITypeId({ accountAPIId }),
              message: getMessage({ mediaItem: mediaItemUpdated }),
            }),
          });
        }
        // Finished
        resolve(mediaItemUpdated);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {{ isABVariation: false } | { isABVariation: true; currentABVariationIndex; number; }}
 */
function getABInfo({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'abInfo',
  });
}

function getABResults({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.abResults)) {
    return mediaItem.backend.abResults;
  }
  return [];
}

function getABTestStatusId({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.abTestStatusId)) {
    return mediaItem.backend.abTestStatusId;
  }
  return null;
}

/**
 * Gets the ABVariations property from the provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {import('types').ABVariations}
 */
function getABVariations({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'abVariations',
  });
}

function getAccountAPIId({ mediaItem = mandatory('mediaItem') } = {}) {
  return mediaItem.accountAPIId;
}

function getActionTypeId({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.actionTypeId)) {
    return mediaItem.backend.actionTypeId;
  }
  return null;
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @return {number[]}
 */
function getAdditionalSocialPages({ mediaItem = mandatory('mediaItem') } = {}) {
  return (
    getPreviewField({
      mediaItem,
      fieldName: 'additionalSocialPages',
    }) || []
  );
}

/**
 * @param {{
 *   mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {import('seamless-immutable').Immutable<import('types').AIMessage>}
 */
function getAIMessage({ mediaItem = mandatory('mediaItem') } = {}) {
  return mediaItem.frontend.aiMessage;
}

function getAutopilotToggle({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.frontend.autopilotToggle)) {
    return mediaItem.frontend.autopilotToggle;
  }
  return false;
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {boolean}
 */
function getCanCustomiseLinkPosts({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'canCustomiseLinkPosts',
  });
}

function getFirstComment({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend?.firstComment)) {
    return mediaItem.backend.firstComment;
  }
  return null;
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {string | undefined}
 */
function getImageOverlayResult({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'imageOverlayResult',
  });
}

function getCurrentABVariationIndex({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  const abInfo = getPreviewField({
    mediaItem,
    fieldName: 'abInfo',
  });
  if (abInfo.isABVariation) {
    return abInfo.currentABVariationIndex;
  }
  return null;
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {string | null}
 */
function getDescription({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'description',
  });
}

/**
 * @returns {unknown}
 */
function getErrorDetails({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'errorDetails',
  });
}

/**
 * @returns {string | null}
 */
function getErrorMessage({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'errorMessage',
  });
}

function getFailReason({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'failReason',
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {import('types').FbTargetingParams}
 */
function getFbTargetingParams({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'fbTargetingParams',
  });
}

/**
 * getHashtags
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * }}
 * @returns{Array<Tag>}
 */
function getHashtags({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'hashtags',
  });
}

/**
 * Gets the configured dimensions for the instagram link sticker.
 *
 * @param {{
 *  mediaItem: Object
 * }} options
 *
 * @returns { import("types").IGLinkStickerConfig } Returns the sticker dimensions as percentages of the image dimensions
 */
function getIGLinkStickerConfig({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isUndefined(mediaItem.backend)) {
    throw new ReferenceError('Media item is missing backend structure');
  }
  return mediaItem.backend.igLinkStickerConfig ?? null;
}

/**
 * Get the list of Image URLs associated with this media item.
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {string[]}
 */
function getImageURLs({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'imageURLs',
  });
}

/**
 * Get the list of files associated with this media item.
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {import('types').FileMetaDataType[]}
 */
function getFiles({ mediaItem = mandatory('mediaItem') } = {}) {
  // convert base64 strings to files
  return getPreviewField({
    mediaItem,
    fieldName: 'files',
  });
}

function getInsights({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.insights)) {
    return mediaItem.backend.insights;
  }
  return {};
}

function getIsChanged({ mediaItem } = {}) {
  return !!mediaItem?.frontend?.isChanged;
}

/**
 * @returns {boolean}
 */
function getIsLive({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.isLive)) {
    return mediaItem.backend.isLive;
  }
  return false;
}

/**
 * @returns {boolean}
 */
function getIsLoading({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'isLoading',
  });
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater; }}
 * @returns {boolean}
 */
function getIsRefreshPreview({ mediaItem = mandatory('mediaItem') } = {}) {
  return (
    getPreviewField({
      mediaItem,
      fieldName: 'isRefreshPreview',
    }) || false
  );
}

function getIsApplyImageOverlay({ mediaItem = mandatory('mediaItem') } = {}) {
  return (
    getPreviewField({
      mediaItem,
      fieldName: 'isApplyImageOverlay',
    }) || false
  );
}

function getIsReloading({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.frontend.isReloading)) {
    return mediaItem.frontend.isReloading;
  }
  return false;
}

function getIsSaving({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'isSaving',
  });
}

function getIsTimingValid({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.frontend.isTimingValid)) {
    return mediaItem.frontend.isTimingValid;
  }
  return false;
}

function getIsURLChanged({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'isURLChanged',
  });
}

/**
 * getIsURLResolved - returns the isURLResolved field
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 * }}
 *
 * @return {boolean | null} - isURLResolved field
 */
function getIsURLResolved({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'isURLResolved',
  });
}

function getIsURLValid({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'isURLValid',
  });
}

function getLastUpdateUser({ mediaItem = mandatory('mediaItem') } = {}) {
  if (hasLastUpdateDetails({ mediaItem })) {
    return mediaItem.backend.updateDetails.userName;
  }
  return null;
}

function getLastUpdateUnixTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (hasLastUpdateDetails({ mediaItem })) {
    return mediaItem.backend.updateDetails.updatedUnixTime;
  }
  return null;
}

function getMaxShareTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.maxShareTime)) {
    return mediaItem.backend.maxShareTime;
  }
  return null;
}

/**
 * getMediaId
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 */

function getMediaId({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isUndefined(mediaItem.backend)) {
    throw new ReferenceError('Media item is missing backend structure');
  }
  if (isDefined(mediaItem.backend.mediaId)) {
    return mediaItem.backend.mediaId;
  }
  return 'NEW';
}

/**
 * getMediaId
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 */

function getMediaURN({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isUndefined(mediaItem.backend)) {
    throw new ReferenceError('Media item is missing backend structure');
  }
  if (isDefined(mediaItem.backend.mediaURN)) {
    return mediaItem.backend.mediaURN;
  }
  return 'NEW';
}

/**
 * getMentions
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * }}
 * @returns {Array<import('types').Tag>}
 */
function getMentions({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'mentions',
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {string}
 */
function getMessage({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'message',
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {import('types').MessageHistory}
 */
function getMessageHistory({ mediaItem = mandatory('mediaItem') } = {}) {
  return (
    getPreviewField({
      mediaItem,
      fieldName: 'messageHistory',
    }) ?? []
  );
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {import('types').ShareContentResult}
 */
function getMessages({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'messages',
  });
}

function getMinShareTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.minShareTime)) {
    return mediaItem.backend.minShareTime;
  }
  return null;
}

function getMostRecentScore({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.mostRecentScore)) {
    return mediaItem.backend.mostRecentScore;
  }
  return 0.0;
}

function getMostRecentUnixTimePublished({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  if (isDefined(mediaItem.backend.mostRecentUnixTimePublished)) {
    return mediaItem.backend.mostRecentUnixTimePublished;
  }
  return null;
}

function getMostRecentUnixTimeShared({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  if (isDefined(mediaItem.backend.mostRecentUnixTimeShared)) {
    return mediaItem.backend.mostRecentUnixTimeShared;
  }
  return null;
}
/**
 * @returns {Array<{tag: String}>}
 */
function getMediaItemTags({ mediaItem = mandatory('mediaItem') } = {}) {
  const mediaItemTags = getPreviewField({
    mediaItem,
    fieldName: 'mediaItemTags',
  });
  return !isNullOrUndefined(mediaItemTags) ? mediaItemTags : [];
}

/**
 * Gets the `mediaStatesByAccountAPIId` backend property.
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {Record<string, string[]>}
 */
function getMediaStatesByAccountAPIId({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  if (isDefined(mediaItem.backend.mediaStatesByAccountAPIId)) {
    return mediaItem.backend.mediaStatesByAccountAPIId;
  }
  return null;
}

function getTotalNumberOfShares({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.totalNumberOfShares)) {
    return mediaItem.backend.totalNumberOfShares;
  }
  return null;
}

function getOptimalShareTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.optimalShareTime)) {
    return mediaItem.backend.optimalShareTime;
  }
  return null;
}

/**
 * Gets a map of post images and a boolean flag to indicate whether they require an update.
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {Record<number, boolean>}
 */
function getPostImageNeedsToBeUpdated({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'postImageNeedsToBeUpdated',
  });
}

/**
 * Gets the post type.
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {import('types').PostType}
 */
function getPostType({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.postType)) {
    return mediaItem.backend.postType;
  }
  return derivePostType(mediaItem);
}

function getImageOverlayURL({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'imageOverlayURL',
  });
}

/**
 * Gets the socialChannel value from the provided media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 * @returns {import('types').SocialChannel}
 */
function getSocialChannel({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'socialChannel',
  });
}

/**
 * getPreviewField - returns the contents of the specified preview field
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldName: string,
 *  convertToEbx?: boolean,
 * }}
 *
 * @return {unknown} - contents of the specified preview field
 */

function getPreviewField({
  mediaItem = mandatory('mediaItem'),
  fieldName = mandatory('fieldName'),
  convertToEbx = false,
} = {}) {
  if (PREVIEW_FIELDS.indexOf(fieldName) === -1) {
    throw new ReferenceError('fieldName is invalid');
  }

  const accountAPIId = getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });

  if (isNullOrUndefined(mediaItem.frontend)) {
    return null;
  }
  const frontend = mediaItem.frontend;

  // AB variation
  if (
    hasABVariations({ apiTypeId, socialChannel: frontend?.socialChannel }) &&
    frontend &&
    frontend.abInfo?.isABVariation &&
    (fieldName === 'message' ||
      fieldName === 'title' ||
      fieldName === 'description' ||
      fieldName === 'imageURLs' ||
      fieldName === 'videoURL')
  ) {
    const currentVariation = frontend.abInfo.currentABVariationIndex;
    const fieldValue = frontend.abVariations[currentVariation][fieldName];
    return fieldValue === undefined ? fieldValue : cloneObject(fieldValue);
  }

  // Link post
  if (fieldName === 'message') {
    if (frontend.messages?.orderedShareContent.length > 0) {
      const shareMessage = getCurrentShareContentItem({
        mediaItem,
        accountAPIId,
      });
      return shareMessage?.value ?? '';
    }
    if (isDefined(frontend.message)) {
      return frontend.message;
    }
    // Item created via Add New which therefore has no messages structure yet
    const fieldValue = mediaItem.backend.message;
    return isUndefined(fieldValue) ? fieldValue : cloneObject(fieldValue);
  }

  // Status / photo / video post
  if (mediaItem.backend.postType !== POST_TYPES.LINK) {
    let fieldValue;
    if (fieldName === 'imageURLs' && convertToEbx) {
      fieldValue = Immutable.asMutable(frontend[fieldName]);
      if (!Array.isArray(fieldValue)) {
        fieldValue = [].concat(fieldValue);
      }
      fieldValue.forEach((imageURL, index) => {
        fieldValue[index] = s3.convertAmazonImageToEchobox(imageURL);
      });
    } else if (fieldName === 'twitterCardType' && isUndefined(fieldValue)) {
      fieldValue = null;
    } else {
      fieldValue = frontend[fieldName];
    }
    return isUndefined(fieldValue) ? fieldValue : cloneObject(fieldValue);
  }

  // Get required field value
  let fieldValue = frontend[fieldName];
  // Default value for hashtags and mentions is an empty array
  if (
    (fieldName === 'hashtags' || fieldName === 'mentions') &&
    isUndefined(fieldValue)
  ) {
    fieldValue = [];
  }
  // Default value for canCustomiseLinkPosts is true
  if (fieldName === 'canCustomiseLinkPosts' && isUndefined(fieldValue)) {
    fieldValue = CAN_CUSTOMISE_LINK_POSTS;
  }
  if (fieldName === 'imageURLs' && convertToEbx) {
    fieldValue = Immutable.asMutable(fieldValue);
    if (!Array.isArray(fieldValue)) {
      fieldValue = [].concat(fieldValue);
    }
    fieldValue.forEach((imageURL, index) => {
      fieldValue[index] = s3.convertAmazonImageToEchobox(imageURL);
    });
  }

  if (fieldName === 'twitterCardType' && isUndefined(fieldValue)) {
    fieldValue = null;
  }

  if (
    fieldName === 'fbTargetingParams' &&
    isDefined(fieldValue) &&
    isDefined(fieldValue.targetingParams)
  ) {
    fieldValue = {
      countries: fieldValue.targetingParams.countries,
    };
  }

  return isUndefined(fieldValue) ? fieldValue : cloneObject(fieldValue);
}

/**
 * getPreviewFields - returns the contents of all preview fields
 *
 * @param {integer} accountAPIId - account to return preview field for
 *
 * @return {import('types').FixTypeLater} - message, messages, title, description, imageURLs, hashtags, mentions,
 *                      abInfo, abVariations etc.
 *                    note that in addition to the "currently-selected message", we also return
 *                    the entire messages structure for the specified network because this is
 *                    needed e.g. when rendering the share content selector component
 */

function getPreviewFields({
  mediaItem = mandatory('mediaItem'),
  convertToEbx = false,
} = {}) {
  const result = {};

  // Copy all preview fields
  PREVIEW_FIELDS.forEach((fieldName) => {
    result[fieldName] = getPreviewField({
      mediaItem,
      fieldName,
      convertToEbx,
    });
  });
  // Update any fields we don't want to directly copy
  result.failReason = '';
  result.isRefreshPreview = false;
  result.isApplyImageOverlay = false;
  result.postImageNeedsToBeUpdated = false;

  return result;
}

function getRssTitle({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.rssTitle)) {
    return mediaItem.backend.rssTitle;
  }
  return '';
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {import('types').ShareTime}
 */
function getShareTime({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem?.frontend?.shareTime)) {
    return mediaItem.frontend.shareTime;
  }
  if (!isNull(mediaItem?.backend?.minShareTime)) {
    return minMaxToShareTime({ mediaItem });
  }
  return {
    type: SHARE_TIME_TYPES.OPTIMAL,
  };
}

function getShareURL({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'shareURL',
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 * }}
 */
function getShouldSendURL({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.frontend?.shouldSendURL)) {
    return mediaItem.frontend.shouldSendURL;
  }
  return true;
}

function getSocialPostURL({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.socialPostURL)) {
    return mediaItem.backend.socialPostURL;
  }
  return null;
}

function getSponsor({ mediaItem = mandatory('mediaItem') } = {}) {
  if (
    !isNullOrUndefined(mediaItem.backend) &&
    !isNullOrUndefined(mediaItem.backend.sponsor)
  ) {
    return mediaItem.backend.sponsor;
  }
  return null;
}

function getSponsorName({ mediaItem = mandatory('mediaItem') } = {}) {
  if (
    isDefined(mediaItem.frontend) &&
    isDefined(mediaItem.frontend.sponsorName)
  ) {
    return mediaItem.frontend.sponsorName;
  }
  return '';
}

function getState({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.state)) {
    return mediaItem.backend.state;
  }
  return MEDIA_ITEM_STATES.NEW;
}

function getSuggestionTypeId({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.suggestionTypeId)) {
    return mediaItem.backend.suggestionTypeId;
  }
  return null;
}

/**
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {import('types').TagMap}
 */
function getTags({ mediaItem = mandatory('mediaItem') } = {}) {
  let hashtags = getPreviewField({
    mediaItem,
    fieldName: 'hashtags',
  });
  if (isUndefined(hashtags)) {
    hashtags = [];
  }
  let mentions = getPreviewField({
    mediaItem,
    fieldName: 'mentions',
  });
  if (isUndefined(mentions)) {
    mentions = [];
  }
  return {
    [TAG_TYPES.HASHTAG]: hashtags,
    [TAG_TYPES.MENTION]: mentions,
  };
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {import('types').TextContentAvailableState | null}
 */
function getTextContentAvailableState({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  if (isDefined(mediaItem.backend.textContentAvailableState)) {
    return mediaItem.backend.textContentAvailableState;
  }
  return null;
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {import('types').TimeSensitivityType | null}
 */
function getTimeSensitivityTypeId({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.timeSensitivityTypeId)) {
    return mediaItem.backend.timeSensitivityTypeId;
  }
  return null;
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {string | null}
 */
function getTitle({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'title',
  });
}

/**
 *
 * @returns {import('types').FixTypeLater}
 */
function getTrackingDetails({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'trackingDetails',
  });
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {import('types').TwitterCardType | null}
 */
function getTwitterCardType({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'twitterCardType',
  });
}

/**
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * }}
 * @returns {string}
 */
function getUnshortenedShareURL({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'unshortenedShareURL',
  });
}

function getUnixTimeShared({ mediaItem = mandatory('mediaItem') } = {}) {
  if (isDefined(mediaItem.backend.unixTimeShared)) {
    return mediaItem.backend.unixTimeShared;
  }
  return null;
}

/**
 * Gets any validation message for the provided media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 * }}
 * @returns {string | null}
 */
function getValidationMessage({ mediaItem = mandatory('mediaItem') } = {}) {
  return getPreviewField({ mediaItem, fieldName: 'validationMessage' });
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {string}
 */
function getVideoURL({ mediaItem = mandatory('mediaItem') } = {}) {
  const abInfo = getPreviewField({
    mediaItem,
    fieldName: 'abInfo',
  });
  const isABVariation =
    isDefined(abInfo) &&
    isDefined(abInfo.isABVariation) &&
    abInfo.isABVariation;
  if (isABVariation) {
    const abVariations = getABVariations({ mediaItem });
    if (
      isDefined(abVariations[abInfo.currentABVariationIndex]) &&
      isDefined(abVariations[abInfo.currentABVariationIndex].videoURL)
    ) {
      return abVariations[abInfo.currentABVariationIndex].videoURL;
    }
    return '';
  }
  if (isDefined(mediaItem.backend.videoURL)) {
    return mediaItem.backend.videoURL;
  }
  return '';
}

function hasLastUpdateDetails({ mediaItem = mandatory('mediaItem') } = {}) {
  return isDefined(mediaItem.backend.updateDetails);
}

function setABInfo({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'abInfo',
    fieldValue,
  });
}

/**
 * Sets the abVariations property for a mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: import('types').ABVariations,
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setABVariations({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'abVariations',
    fieldValue,
  });
}

function setAccountAPIId({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.set('accountAPIId', fieldValue);
}

/**
 * @param {{
 * mediaItem: import('types').FixTypeLater;
 * fieldValue: import('types').FixTypeLater[]
 * }}
 */

function setAdditionalSocialPages({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'additionalSocialPages',
    fieldValue,
  });
}

/**
 * @param {{
 *   mediaItem: import('types').FixTypeLater;
 *   fieldValue:  import('seamless-immutable').Immutable<import('types').AIMessage>;
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setAIMessage({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
}) {
  return mediaItem.setIn(['frontend', 'aiMessage'], fieldValue);
}

function setCurrentABVariationIndex({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  const abInfo = getPreviewField({
    mediaItem,
    fieldName: 'abInfo',
  });
  if (abInfo.isABVariation) {
    abInfo.currentABVariationIndex = fieldValue;
  }
  return setPreviewField({
    mediaItem,
    fieldName: 'abInfo',
    fieldValue: abInfo,
  });
}

/**
 * Sets the `description` preview field for the provided media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setDescription({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'description',
    fieldValue,
  });
}

function setErrorDetails({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'errorDetails',
    fieldValue,
  });
}

/**
 * Sets the errorMessage property for a mediaItem
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string;
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setErrorMessage({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'errorMessage',
    fieldValue,
  });
}

/**
 * Sets the `fbTargetingParams` preview field for the provided media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').FbTargetingParams;
 * }}
 * @returns {import('types').FixTypeLater} the media item.
 */
function setFbTargetingParams({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'fbTargetingParams',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setFirstComment({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'firstComment'], fieldValue);
}

/**
 * Set the hashtags field for the media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: Tag[];
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setHashtags({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'hashtags',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import("types").IGLinkStickerConfig;
 * }}
 */
function setIGLinkStickerConfig({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'igLinkStickerConfig'], fieldValue);
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: Array<string>;
 *  setIsEdited?: boolean;
 * }}
 */
function setImageURLs({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
  setIsEdited = false,
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'imageURLs',
    fieldValue,
    setIsEdited,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').FileMetaDataType[];
 *  setIsEdited?: boolean;
 * }}
 */
function setFiles({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
  setIsEdited = false,
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'files',
    fieldValue,
    setIsEdited,
  });
}

/**
 * Sets the isChanged property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: boolean | undefined,
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setIsChanged({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['frontend', 'isChanged'], fieldValue);
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean;
 * }}
 */
function setIsLive({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'isLive'], fieldValue);
}

/**
 * setIsLoading
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: boolean
 * }}
 */

function setIsLoading({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  let updatedItem = mediaItem;
  updatedItem = setPreviewField({
    mediaItem: updatedItem,
    fieldName: 'isLoading',
    fieldValue,
  });
  return updatedItem;
}

/**
 * Sets the isRefreshPreview property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean;
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setIsRefreshPreview({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'isRefreshPreview',
    fieldValue,
  });
}

/**
 * Sets the isApplyImageOverlay to the provided mediaItem
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean;
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setIsApplyImageOverlay({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'isApplyImageOverlay',
    fieldValue,
  });
}

function setIsReloading({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['frontend', 'isReloading'], fieldValue);
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean;
 * }}
 */
function setIsSaving({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'isSaving',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean;
 * }}
 */
function setIsTimingValid({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['frontend', 'isTimingValid'], fieldValue);
}

/**
 * Sets the isURLChanged property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: boolean | undefined,
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setIsURLChanged({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'isURLChanged',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean | undefined | null;
 * }}
 */
function setIsURLResolved({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'isURLResolved',
    fieldValue,
  });
}

/**
 * Sets the isURLValid property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean | undefined
 * }}
 */
function setIsURLValid({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'isURLValid',
    fieldValue,
  });
}

/**
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: string
 * }}
 */

function setMediaId({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'mediaId'], fieldValue);
}

/**
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: string
 * }}
 */

function setMediaURN({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'mediaURN'], fieldValue);
}

function setMediaItemTags({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'mediaItemTags',
    fieldValue,
  });
}

/**
 * Set the mentions field for the media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: Tag[];
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setMentions({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'mentions',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 *  deleteABVariation?: boolean;
 * }}
 */
function setMessage({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
  deleteABVariation = false,
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'message',
    fieldValue,
    deleteABVariation,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').MessageHistory;
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setMessageHistory({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'messageHistory',
    fieldValue,
  });
}

/**
 * setMessages
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: import('types').FixTypeLater
 * }}
 */
function setMessages({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  const messages = Immutable.asMutable(fieldValue, { deep: true });
  if (isUndefined(messages.selectedContentIndex)) {
    messages.selectedContentIndex = findShareContentIndex({
      messages,
      contentTypeId: messages.selectedContentTypeId,
    });
  }
  return setPreviewField({
    mediaItem,
    fieldName: 'messages',
    fieldValue: messages,
  });
}

/**
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: import('types').FixTypeLater
 * }}
 */
function setOriginalMessages({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'originalMessages',
    fieldValue,
  });
}

/**
 * Sets the postImageNeedsToBeUpdated property on the provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: Record<number, boolean>
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setPostImageNeedsToBeUpdated({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'postImageNeedsToBeUpdated',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').PostType;
 * }}
 */
function setPostType({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory,
} = {}) {
  return mediaItem.setIn(['backend', 'postType'], fieldValue);
}

/**
 * Sets the social channel field value for the specified media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').SocialChannel;
 * }}
 */
function setSocialChannel({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'socialChannel',
    fieldValue,
  });
}

/**
 * setPreviewField - sets the contents of the specified preview field
 *
 * Populates appropriate preview field
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldName: string;
 *  fieldValue: unknown;
 *  allowTrackingDetailsOverride?: boolean;
 *  deleteABVariation?: boolean;
 *  setIsEdited?: boolean;
 * }}
 */

function setPreviewField({
  mediaItem = mandatory('mediaItem'),
  fieldName = mandatory('fieldName'),
  fieldValue,
  allowTrackingDetailsOverride = false,
  deleteABVariation = false,
  setIsEdited = false,
} = {}) {
  if (PREVIEW_FIELDS.indexOf(fieldName) === -1) {
    throw new ReferenceError('fieldName is invalid');
  }

  const accountAPIId = getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });

  // Ensure we store a value and not a reference to an object
  const fieldValueCopy = isNullOrUndefined(fieldValue)
    ? fieldValue
    : cloneObject(fieldValue);

  const frontend = mediaItem.frontend;
  const frontendPath = ['frontend'];

  let updatedItem = mediaItem;
  if (setIsEdited) {
    updatedItem = mediaItem.setIn(
      frontendPath.concat(['isEdited']),
      setIsEdited,
    );
  }

  const socialChannel = getSocialChannel({ mediaItem });

  // AB variation
  if (
    hasABVariations({ apiTypeId, socialChannel }) &&
    isDefined(frontend) &&
    isDefined(frontend.abInfo) &&
    frontend.abInfo.isABVariation &&
    (fieldName === 'message' ||
      fieldName === 'title' ||
      fieldName === 'description' ||
      fieldName === 'imageURLs' ||
      fieldName === 'videoURL')
  ) {
    const currentVariation = frontend.abInfo.currentABVariationIndex;
    const fieldPath = frontendPath.concat([
      'abVariations',
      currentVariation,
      fieldName,
    ]);
    return updatedItem.setIn(fieldPath, fieldValueCopy);
  }

  // Link post message
  if (
    fieldName === 'message' &&
    !isNullOrUndefined(frontend) &&
    isDefined(frontend.messages) &&
    !isNull(frontend.messages) &&
    !deleteABVariation
  ) {
    const shareMessages = frontend.messages;
    const currentIndex = shareMessages.selectedContentIndex;
    const contentItem = shareMessages.orderedShareContent[currentIndex];
    // If the share content is incomplete or incorrect then just stop there
    if (isUndefined(contentItem)) {
      return updatedItem;
    }
    const contentType = CONTENT_TYPES[contentItem.contentTypeId];
    // If share message content type is 'edited' then just update the content item
    if (contentType.isEdited) {
      const fieldPath = [
        'frontend',
        'messages',
        'orderedShareContent',
        currentIndex,
        'value',
      ];
      return updatedItem.setIn(fieldPath, fieldValueCopy);
    }
    // Otherwise create an 'edited' version of the share content
    return addShareContent({
      mediaItem: updatedItem,
      contentType,
      shareContentValue: fieldValueCopy,
    });
  }

  // Tracking details
  if (fieldName === 'trackingDetails') {
    const trackingDetails = isDefined(frontend?.trackingDetails)
      ? Immutable.asMutable(frontend.trackingDetails)
      : {};
    Object.keys(fieldValueCopy).forEach((key) => {
      if (isUndefined(trackingDetails[key]) || allowTrackingDetailsOverride) {
        trackingDetails[key] = fieldValueCopy[key];
      }
    });
    return updatedItem.setIn(['frontend', 'trackingDetails'], trackingDetails);
  }

  // Any other field
  return updatedItem.setIn(frontendPath.concat(fieldName), fieldValueCopy);
}

/**
 * setShareTime
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: import('types').FixTypeLater
 * }}
 */
function setShareTime({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'shareTime',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setShareURL({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'shareURL',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: boolean;
 * }}
 */
function setShouldSendURL({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['frontend', 'shouldSendURL'], fieldValue);
}

/**
 * Sets the sponsor property for the provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 *  fieldValue: string | null
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setSponsor({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'sponsor'], fieldValue);
}

/**
 * Sets the sponsorName property for the provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater
 *  fieldValue: string | null
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setSponsorName({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['frontend', 'sponsorName'], fieldValue);
}
/**
 * setState
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: string
 * }}
 */
function setState({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'state'], fieldValue);
}

/**
 * setSuggestionTypeId
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: number
 * }}
 */
function setSuggestionTypeId({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'suggestionTypeId'], fieldValue);
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').TextContentAvailableStateType | null;
 * }}
 * @returns import('types').FixTypeLater
 */
function setTextContentAvailableState({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'textContentAvailableState'], fieldValue);
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').TimeSensitivityType | null;
 * }}
 * @returns import('types').FixTypeLater
 */
function setTimeSensitivityTypeId({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'timeSensitivityTypeId'], fieldValue);
}

/**
 * Sets the `title` preview field for the provided media item.
 *
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setTitle({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'title',
    fieldValue,
  });
}

/**
 * setTrackingDetails
 * @param {{
 * mediaItem: import('types').FixTypeLater
 * fieldValue: Record<string, any>
 * allowOverride?: boolean
 * }}
 */

function setTrackingDetails({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
  allowOverride = false,
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'trackingDetails',
    fieldValue,
    allowTrackingDetailsOverride: allowOverride,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: import('types').TwitterCardType | null;
 * }}
 */
function setTwitterCardType({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'twitterCardType',
    fieldValue,
  });
}

/**
 * Sets the unshortenedShareURL property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: string | null,
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setUnshortenedShareURL({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'unshortenedShareURL',
    fieldValue,
  });
}

/**
 * Sets the imageOverlayURL property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: string | null,
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setImageOverlayURL({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'imageOverlayURL',
    fieldValue,
  });
}

/**
 * Sets the imageOverlayResult property for a provided mediaItem.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  fieldValue: string | null,
 * }}
 * @returns {import('types').FixTypeLater}
 */
function setImageOverlayResult({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'imageOverlayResult',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: {
 *    updatedUnixTime: number;
 *    userName: string;
 *  }
 * }}
 */
function setUpdateDetails({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['backend', 'updateDetails'], fieldValue);
}

/**
 * Sets a validation message for that media item provided.
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setValidationMessage({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return setPreviewField({
    mediaItem,
    fieldName: 'validationMessage',
    fieldValue,
  });
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setVideoURL({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  const abInfo = getPreviewField({
    mediaItem,
    fieldName: 'abInfo',
  });
  const isABVariation = abInfo && abInfo.isABVariation;
  if (isABVariation) {
    return mediaItem.setIn(
      ['frontend', 'abVariations', abInfo.currentABVariationIndex, 'videoURL'],
      fieldValue,
    );
  }
  return mediaItem.setIn(['backend', 'videoURL'], fieldValue);
}

/**
 * @param {{
 *  mediaItem: import('types').FixTypeLater;
 *  fieldValue: string | null;
 * }}
 */
function setInstantImageArticleUrl({
  mediaItem = mandatory('mediaItem'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  return mediaItem.setIn(['frontend', 'instantImageArticleUrl'], fieldValue);
}

/**
 * @param {{ mediaItem: import('types').FixTypeLater }}
 * @returns {string | null}
 */
function getInstantImageArticleUrl({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  return getPreviewField({
    mediaItem,
    fieldName: 'instantImageArticleUrl',
  });
}
