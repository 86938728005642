import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import getAPIsConnectError from 'api/getAPIsConnectError';
import getTrafficAPIsConnectError from 'api/getTrafficAPIsConnectError';
import { getCurrentPropertyId } from 'common/accountAPIs';
import {
  ACCOUNT_STATES,
  API_ERROR_MESSAGES,
  API_TYPE_IDS,
} from 'common/constants';
import { getCurrentPropertyStateId } from 'common/currentPropertyAndAPI';
import * as logger from 'common/logger';
import { isIGCabinetEnabled } from 'common/settings';
import { getQueryStringValue } from 'common/url';
import { convertToPropertyURN } from 'common/urn';
import { isRunningTests } from 'common/utility';
import AllNotifications from 'components/misc/AllNotifications';
import Loader from 'components/misc/Loader';

const APIConnectError = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const apiTypeId = sessionStorage.getItem('connectingAPITypeId');
  const isUnderSetup = getCurrentPropertyStateId() === ACCOUNT_STATES.NEWSIGNUP;

  const isIGError =
    apiTypeId &&
    Number(apiTypeId) === API_TYPE_IDS.INSTAGRAM &&
    !isIGCabinetEnabled();

  // Remove the session storage item in case another page is connected.
  if (!isIGError && apiTypeId) {
    sessionStorage.removeItem('connectingAPITypeId');
  }

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getErrors = async (requestToken: string) => {
      try {
        let response: { message: string };
        if (pathname.includes('traffic')) {
          const propertyURN = convertToPropertyURN(getCurrentPropertyId());
          response = await getTrafficAPIsConnectError({
            propertyURN,
            requestToken,
          });
        } else {
          response = await getAPIsConnectError({
            requestToken,
          });
        }
        setErrorMessage(response.message);
        setIsLoading(false);
      } catch (error) {
        // If anything goes wrong ensure there is a default message
        setErrorMessage(API_ERROR_MESSAGES.API_UNKNOWN);
        setIsLoading(false);
        logger.error({
          event: 'Promise Rejection Error',
          error,
        });
        if (!isRunningTests()) {
          console.log(error);
        }
      }
    };

    const requestToken = getQueryStringValue({
      queryParams: search,
      name: 'requestToken',
    });

    // If it's Instagram, go straight to the settings/setup to show a custom modal
    if (isIGError) {
      if (isUnderSetup) {
        navigate(
          `/setup/socialpages?apiConnectErrorRequestToken=${requestToken}`,
        );
      } else {
        navigate(`/settings/pages?apiConnectErrorRequestToken=${requestToken}`);
      }
    } else if (isUnderSetup) {
      // If the property is within setup, we'll redirect to the setup page
      // which will handle the error within that context.
      if (pathname.includes('traffic')) {
        navigate(`/setup/traffic?apiConnectErrorRequestToken=${requestToken}`);
      } else {
        navigate(
          `/setup/socialpages?apiConnectErrorRequestToken=${requestToken}`,
        );
      }
    } else {
      getErrors(requestToken);
    }
  }, [pathname, search, navigate, isUnderSetup, isIGError, apiTypeId]);

  if (isLoading) {
    return <Loader className="setting_page" />;
  }

  return (
    <div className="main" data-cy-id="connectErrorPage">
      <Box className="setting_page" px={10}>
        <AllNotifications />
        <h1 className="setting_title">Social Account Connect Error</h1>
        <div className="sub_note">
          Unfortunately the following error occurred:
        </div>
        <div className="sub_note">
          <span className="text-700" data-cy-id="errorMessage">
            {errorMessage}
          </span>
        </div>
      </Box>
    </div>
  );
};

export default APIConnectError;
