/* eslint no-alert:"off" */
/* eslint no-use-before-define: "off" */

import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import postPropertiesUser from 'api/postPropertiesUser';
import { getCurrentPropertyId, getUser } from 'common/accountAPIs';
import { KEYNAMES, PERMISSION_TYPES } from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import { getSocialNetworkIcon } from 'common/social';
import { formatLikesFollowers } from 'common/string';
import { cloneArray, isNull } from 'common/utility';
import Button from 'components/misc/Button';
import useOnKey from 'hooks/useOnKey';

/*
 * User pages
 */

const UserPages = (props) => {
  /**
   * Initial state
   */

  const [selectedAPIs, setSelectedAPIs] = useState(props.selectedAPIs);
  const [isSavingUser, setIsSavingUser] = useState(false);

  // Get a fixed reference to the user object
  // This is needed because the user object may change after refreshing globalInfo, and
  // this component will break (or look strange) within an undefined user object.
  // However when the `props.userId` changes, the component will be re-mounted because that value is set as the `key`.
  const userRef = useRef(
    !isNull(props.userId) ? getUser({ userId: props.userId }) : null,
  );
  const user = userRef.current;

  /**
   * Event handlers
   */

  const handlePagesCancel = () => {
    logger.info('UserPages:handlePagesCancel');

    let allowClose = true;
    if (isChanged()) {
      allowClose = window.confirm(
        'Do you want to close this window? Changes you made will not be saved.',
      );
    }
    if (allowClose) {
      props.eventHandlers.handlePagesCancel();
    }
  };

  const handlePagesSave = () => {
    // If no changes made, just close
    if (!isChanged()) {
      logger.info('UserPages:handlePagesSave - no changes made');
      props.eventHandlers.handlePagesCancel();
      return;
    }

    // When adding user, just sent selected pages back to UserAdd component
    if (isNull(props.userId)) {
      logger.info('UserPages:handlePagesSave - new user');
      props.eventHandlers.handlePagesSave(selectedAPIs);
      return;
    }

    // When editing user, save permission changes
    logger.info('UserPages:handlePagesSave - existing user');
    props.eventHandlers.handlePagesSave();
    setIsSavingUser(true);
    const userId = props.userId;
    const globalInfo = getGlobalInfo();
    const propertyId = getCurrentPropertyId({
      globalInfo,
    });
    const permissionsOnProperty = [];
    // Add VIEW_ONLY permission at property level
    permissionsOnProperty.push({
      propertyId,
      permissionTypeId: PERMISSION_TYPES.VIEW_ONLY,
    });
    // Add EDITOR permission to selected pages
    selectedAPIs.forEach((accountAPIId) =>
      permissionsOnProperty.push({
        propertyId,
        accountAPIId,
        permissionTypeId: PERMISSION_TYPES.EDITOR,
      }),
    );

    // Save change
    const newUser = getUser({ userId });
    postPropertiesUser({
      propertyId,
      name: newUser.name,
      username: newUser.username,
      emailAddress: newUser.emailAddress,
      permissionsOnProperty,
    })
      .then(() => {
        setIsSavingUser(false);
        props.eventHandlers.handlePagesSaved();
      })
      .catch((error) => {
        console.log(error);
        setIsSavingUser(false);
        addErrorNotification(error);
      });
  };

  const handlePageToggle = (event) => {
    event.persist();
    const apiId = event.target.value;
    logger.info(`UserPages:handlePageToggle ${apiId}`);
    setSelectedAPIs((oldSelectedAPIs) => {
      const newSelectedAPIs = cloneArray(oldSelectedAPIs);
      const position = selectedAPIs.indexOf(apiId);
      if (position !== -1) {
        // Remove
        newSelectedAPIs.splice(position, 1);
      } else {
        // Add
        newSelectedAPIs.push(apiId);
      }
      return newSelectedAPIs;
    });
  };

  const handlePageToggleAll = () => {
    logger.info('UserPages:handlePageToggleAll');
    setSelectedAPIs((oldSelectedAPIs) => {
      let newSelectedAPIs;
      const availableAPIs = props.availableAPIs;
      if (oldSelectedAPIs.length === availableAPIs.length) {
        // Deselect all
        newSelectedAPIs = [];
      } else {
        // Select all
        newSelectedAPIs = availableAPIs.map((api) =>
          api.accountAPIId.toString(),
        );
      }
      return newSelectedAPIs;
    });
  };

  /**
   * Helper methods
   */

  const isChanged = () => {
    const originalAPIs = props.selectedAPIs;
    const currentAPIs = selectedAPIs;

    if (originalAPIs.length !== currentAPIs.length) {
      return true;
    }

    const arraysMatch = originalAPIs.every((x) => currentAPIs.includes(x));
    return !arraysMatch;
  };

  /**
   * Lifecycle methods
   */

  useOnKey({ targetKey: KEYNAMES.ESCAPE, onKeyUp: handlePagesCancel });

  /**
   * Render methods
   */

  const renderFooter = () => {
    const pagesSelected = selectedAPIs.length;
    const pagesAvailable = props.availableAPIs.length;
    const isLocked = pagesSelected === 0 || isSavingUser;

    return (
      <div className="modal-footer">
        {selectedAPIs.length !== 0 && (
          <div className="d-none d-md-flex">
            <div className="selected ft-13">
              {pagesSelected} page{pagesSelected !== 1 ? 's' : ''} selected
            </div>
            <div className="vline ft-13 mx-1">|</div>
            <div className="total ft-13">
              {pagesAvailable} page{pagesAvailable !== 1 ? 's' : ''} total
            </div>
          </div>
        )}
        <div className="description text-danger text-500">
          {selectedAPIs.length === 0 && (
            <span>At least 1 page needs to be selected</span>
          )}
        </div>
        <div className="ml-auto">
          <Button
            className="ml-auto"
            data-cy-action="cancelPages"
            onClick={handlePagesCancel}
          >
            Cancel
          </Button>
        </div>
        <div className="save">
          <Button
            className={isLocked ? 'saving_locked' : ''}
            variant="dark"
            onClick={handlePagesSave}
            disabled={isLocked}
            data-cy-action="savePages"
          >
            Save Changes
          </Button>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    if (isNull(user)) {
      return (
        <div className="top px-3">
          <div className="left mr-2">
            <span className="description ft-13">Select pages:</span>
          </div>
          <div className="right">
            <span className="email">&nbsp;</span>
          </div>
        </div>
      );
    }

    return (
      <div className="top">
        <div className="left mr-2">
          <span className="description ft-13">Select pages for:</span>
        </div>
        <div className="right">
          <span className="email">{user.emailAddress}</span>
        </div>
      </div>
    );
  };

  const renderPage = (accountAPI) => {
    const iconSource = getSocialNetworkIcon({
      apiTypeId: accountAPI.apiTypeId,
    });

    const apiId = accountAPI.accountAPIId.toString();
    const isLocked = isSavingUser;
    const checkboxClass = isLocked ? 'saving_locked' : '';
    return (
      <tr key={apiId} className="border-bottom" data-cy-id={apiId}>
        <td className="pr-2 checkbox">
          <input
            type="checkbox"
            className={checkboxClass}
            value={apiId}
            checked={selectedAPIs.indexOf(apiId) !== -1}
            onChange={handlePageToggle}
            disabled={isLocked}
            id={`user-page-toggle-${apiId}`}
            data-cy-action="togglePage"
          />
          <label htmlFor={`user-page-toggle-${apiId}`} />
        </td>
        <td className="col_3 w-70 pl-0 text-500">
          <img
            src={iconSource}
            alt=""
            style={{ width: '14px', height: '14px' }}
            className="mr-2"
          />
          {accountAPI.apiPostName}
        </td>
        <td className="col_4 w-30 text-left pr-4">
          {formatLikesFollowers(accountAPI.numberFollowers)}
        </td>
      </tr>
    );
  };

  const renderPages = () => {
    const availableAPIs = props.availableAPIs;

    const isLocked = isSavingUser;
    const checkboxClass = isLocked ? 'saving_locked' : '';

    return (
      <div className="middle">
        <table className="w-100 text-left">
          <thead>
            <tr className="table-header">
              <th className="checkbox pr-2 pl-3-5">
                <input
                  type="checkbox"
                  className={checkboxClass}
                  checked={selectedAPIs.length === availableAPIs.length}
                  onChange={handlePageToggleAll}
                  disabled={isLocked}
                  id="all"
                />
                <label htmlFor="all" />
              </th>
              <th className="col_3 pl-0 w-70">All Pages</th>
              <th className="col_4 w-30 text-left">
                <span className="d-none d-md-block">Likes/Followers</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {props.availableAPIs.map((accountAPI) => renderPage(accountAPI))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="ebx-modal-outer">
      <div className="select-pages modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            {renderHeader()}
            <a
              aria-label="Close"
              onClick={handlePagesCancel}
              className="close ft-26 text-400"
            >
              &times;
            </a>
          </div>
          {renderPages()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

UserPages.propTypes = {
  userId: PropTypes.string,
  availableAPIs: PropTypes.array.isRequired,
  selectedAPIs: PropTypes.array,
  eventHandlers: PropTypes.shape({
    handlePagesCancel: PropTypes.func.isRequired,
    handlePagesSave: PropTypes.func.isRequired,
    handlePagesSaved: PropTypes.func,
  }).isRequired,
};

UserPages.defaultProps = {
  userId: null,
  selectedAPIs: [],
};

export default UserPages;
