import PropType from 'prop-types';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import Button from 'components/misc/Button';
import Dialog from 'components/misc/Dialog';

const { DialogHeader, DialogBody, DialogFooter } = Dialog;

/**
 * React Component for showing success message in MFA Popup
 * Accepts handleClose function prop used for closing the Popup
 */
const SuccessMessage = ({ handleClose }) => {
  return (
    <>
      <DialogHeader style={{ fontSize: 40 }}>
        <Box
          as="i"
          className="fa fa-check-circle-o"
          fontSize="40px"
          color="green"
          display="block"
          w="full"
          textAlign="center"
        />
      </DialogHeader>
      <DialogBody style={{ padding: 40, borderRadius: 4 }}>
        <div className="text-center">
          <h1 style={{ fontSize: 22 }}>You&apos;re all set</h1>
          <p>
            From now on, you will use your password and a verification code to
            log in to Echobox
          </p>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button id="mfa-finish" size="lg" variant="dark" onClick={handleClose}>
          Finish
        </Button>
      </DialogFooter>
    </>
  );
};

SuccessMessage.propTypes = {
  handleClose: PropType.func.isRequired,
};

export default SuccessMessage;
