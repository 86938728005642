import $ from 'jquery';

import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';

export { onSaveFailure, onSaveSuccess, setPanelButtonText };

function onSaveSuccess() {
  this.setState((prevState) => ({
    data: prevState.data.set('isChanged', false).set('isSaving', false),
  }));
  addSuccessNotification('Setting saved');
}

function onSaveFailure() {
  this.setState((prevState) => ({
    data: prevState.data.set('isChanged', true).set('isSaving', false),
  }));
  addErrorNotification('Error saving setting');
}

function setPanelButtonText(
  id,
  { closeText = 'Close', openText = 'Edit' } = {},
) {
  const selector = `#${id}`;
  if (typeof $ !== 'undefined') {
    $(selector).on('shown.bs.collapse', () => {
      $(`div[data-target="${selector}"]`).text(closeText);
    });
    $(selector).on('hidden.bs.collapse', () => {
      $(`div[data-target="${selector}"]`).text(openText);
    });
  }
}
