import {
  AutomationsIcon,
  CheckIcon,
  Dropdown,
  EditPen1Icon,
  FireIcon,
  IgPostIcon,
  ImageIcon,
  LeafIcon,
  LinkIcon,
  NormalIcon,
  ReelIcon,
  StoryIcon,
  TestIcon,
  VideoNewIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { getCurrentAPITypeId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import { isIGCabinetEnabled } from 'common/settings';
import { isDefined } from 'common/utility';

import { useEffect, useMemo } from 'react';
import FilterButton from './FilterButton';

const PostTypeFilter = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (val: string) => void;
}) => {
  const uppercaseValue = value.toUpperCase();

  const isFacebook = getCurrentAPITypeId() === API_TYPE_IDS.FACEBOOK;
  const isInstagramGraphAPI =
    !isIGCabinetEnabled() && getCurrentAPITypeId() === API_TYPE_IDS.INSTAGRAM;
  const isInstagramCabinetAPI =
    isIGCabinetEnabled() && getCurrentAPITypeId() === API_TYPE_IDS.INSTAGRAM;
  const isTwitter = getCurrentAPITypeId() === API_TYPE_IDS.TWITTER;
  const isLinkedIn = getCurrentAPITypeId() === API_TYPE_IDS.LINKEDIN;
  const isTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;

  const FILTER_LIST = useMemo(
    () => [
      {
        label: 'All',
        value: 'ALL',
        icon: CheckIcon,
      },
      {
        divider: true,
        key: '1',
      },
      {
        label: 'Breaking',
        value: 'BREAKING',
        icon: FireIcon,
        render: () => !isTikTok,
      },
      {
        label: 'Normal',
        value: 'TODAY',
        icon: NormalIcon,
        render: () => !isTikTok,
      },
      {
        label: 'Evergreen',
        value: 'EVERGREEN',
        icon: LeafIcon,
        render: () => !isTikTok,
      },
      { divider: true, key: '2', render: () => !isTikTok },
      {
        label: 'Links',
        value: 'LINK',
        icon: LinkIcon,
        render: () => !isTikTok,
      },
      {
        label:
          isFacebook || isTwitter || isLinkedIn ? 'Photos/Status' : 'Photos',
        value: 'STATUS',
        icon: ImageIcon,
        render: () => !isTikTok,
      },
      {
        label: 'Videos',
        value: 'VIDEO',
        icon: VideoNewIcon,
        render: () => !isTikTok,
      },
      {
        divider: true,
        key: '3',
        render: () =>
          (!isInstagramCabinetAPI && !isTwitter && !isLinkedIn && !isTikTok) ||
          isFacebook,
      },
      {
        label: 'Posts',
        value: 'FEED',
        icon: isFacebook ? NormalIcon : IgPostIcon,
        render: () =>
          !isInstagramCabinetAPI && !isLinkedIn && !isTikTok && !isTwitter,
      },
      {
        label: 'Reels',
        value: 'REEL',
        icon: ReelIcon,
        render: () => isInstagramGraphAPI || isFacebook,
      },
      {
        label: 'Stories',
        value: 'STORY',
        icon: StoryIcon,
        render: () => isInstagramGraphAPI,
      },
      {
        divider: true,
        key: '4',
        render: () => isFacebook,
      },
      {
        label: 'Autofeed',
        value: 'AUTOFEED',
        icon: AutomationsIcon,
        render: () => isFacebook,
      },
      {
        label: 'Manual',
        value: 'MANUAL',
        icon: EditPen1Icon,
        render: () => isFacebook,
      },
      {
        label: 'AB Tests',
        value: 'AB',
        icon: TestIcon,
        render: () => isFacebook,
      },
      {
        divider: true,
        key: '5',
        render: () => isFacebook,
      },
      {
        label: 'Boosted',
        value: 'BOOSTED',
        render: () => isFacebook,
      },
      {
        label: 'Sponsored',
        value: 'SPONSORED',
        render: () => isFacebook,
      },
      {
        label: 'Echobox shares',
        value: 'SHARE_ECHOBOX',
        render: () => isTikTok,
      },
      {
        label: 'Non-Echobox shares',
        value: 'SHARE_DIRECT',
        render: () => isTikTok,
      },
    ],
    [
      isFacebook,
      isInstagramCabinetAPI,
      isInstagramGraphAPI,
      isLinkedIn,
      isTikTok,
      isTwitter,
    ],
  );

  // reset the filter value to all when navigating to a page which doesn't support the previously selected value
  useEffect(() => {
    const isValueSupported = FILTER_LIST.some((item) => {
      const shouldRender = isDefined(item.render) ? item.render() : true;
      return item.value === uppercaseValue && shouldRender;
    });
    if (!isValueSupported) {
      onChange(FILTER_LIST[0].value ?? 'ALL');
    }
  }, [FILTER_LIST, onChange, uppercaseValue]);

  return (
    <Dropdown hideCheck>
      <FilterButton data-cy-select="filter" minW="140px">
        {FILTER_LIST.find((item) => {
          const shouldRender = isDefined(item.render) ? item.render() : true;
          return item.value === uppercaseValue && shouldRender;
        })?.label ?? FILTER_LIST[0]?.label}
      </FilterButton>
      <Dropdown.List w="180px">
        {FILTER_LIST.map((item) => {
          const shouldRender = isDefined(item.render) ? item.render() : true;
          if (item.divider && shouldRender) {
            return <Dropdown.Divider key={item.key} />;
          }
          if (item.value && shouldRender) {
            const isSelected = uppercaseValue === item.value;
            return (
              <Dropdown.Item
                key={item.value}
                onClick={() => onChange(item.value)}
                LeftIcon={item.icon}
                isSelected={isSelected}
              >
                {item.label}
              </Dropdown.Item>
            );
          }
          return null;
        })}
      </Dropdown.List>
    </Dropdown>
  );
};

export default PostTypeFilter;
