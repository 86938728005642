import { Box, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

interface DebugMediaModalInterface {
  /**
   * The debug response to display within the modal.
   */
  response: string | null;
  /**
   * The event handlers for the modal.
   */
  eventHandlers: {
    /**
     * The event handler called when closing the modal.
     */
    handleCloseDebug: () => void;
  };
}

const DebugMediaModal = ({
  response,
  eventHandlers,
}: DebugMediaModalInterface) => {
  const uniqueIdentifierRegex = /(su|ar|ac)_[a-zA-Z0-9]+\b/g;
  const urlRegex = /\bhttps?:\/\/\S+\b/g;

  const replaceIdentifiersWithLogglyLinks = (identifier: string) => {
    const identifierNoPrefix = identifier.substring(3);
    const url = `https://ebxsocial.loggly.com/search#terms=%22${identifierNoPrefix}%22&from=-7d&until=now&source_group=`;
    return `<a href="${url}" target="_blank">${identifier}</a>`;
  };

  const replaceURLWithHyperlinks = (url: string) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  };

  const processedResponse = response
    ?.replaceAll(urlRegex, replaceURLWithHyperlinks)
    .replaceAll(uniqueIdentifierRegex, replaceIdentifiersWithLogglyLinks);

  return (
    <Modal
      isOpen={response !== null}
      onClose={eventHandlers.handleCloseDebug}
      size="large"
    >
      <Modal.Header>
        <Modal.Title>Debug Media Item Response</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {processedResponse && (
          <Box
            as="pre"
            whiteSpace="pre-wrap"
            className="text-monospace"
            dangerouslySetInnerHTML={{ __html: processedResponse }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DebugMediaModal;
