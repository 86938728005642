import { useState } from 'react';

import isEqual from 'fast-deep-equal';

import {
  Checkbox,
  Flex,
  Heading,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as accountAPIs from 'common/accountAPIs';
import * as schedules from 'common/schedules';
import * as settings from 'common/settings';
import * as tracker from 'common/tracker';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import ScheduleMatrix from 'components/settings/automation/schedule/ScheduleMatrix';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';

import { AutofeedSettings, FixTypeLater, StatusScheduleTrigger } from 'types';
import AutomationSettingsHeader from '../common/AutomationSettingsHeader';

const listItemStyles = {
  color: 'gray.900',
  fontSize: 14,
  lineHeight: '20px',
};

const checkboxStyles = {
  '.chakra-checkbox__control': { marginTop: '2px' },
  '.chakra-checkbox__label': { fontWeight: 400 },
};

export interface SchedulePeriodsSettings {
  statusScheduleTriggers: StatusScheduleTrigger[];
  bestContentOnlySinceCurrentOnPeriod: boolean;
  schedulePeriods: StatusScheduleTrigger[];
}

function SchedulePeriods() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const { accountAPIId } = useAutomationSettingsContext();

  const accountAPI = accountAPIs.getAccountAPI({
    accountAPIId,
    globalInfo,
  });

  const getSchedulePeriodSettings = () => {
    const statusScheduleTriggers = [
      ...(settings.getAutofeedSetting({
        key: 'statusScheduleTriggers',
        accountAPIId,
      }) ?? []),
    ];

    const bestContentOnlySinceCurrentOnPeriod = !!settings.getAutofeedSetting({
      key: 'bestContentOnlySinceCurrentOnPeriod',
      accountAPIId,
    });

    const schedulePeriods = schedules.loadStatusScheduleTriggers(
      statusScheduleTriggers,
      accountAPI,
    );

    return {
      statusScheduleTriggers,
      bestContentOnlySinceCurrentOnPeriod,
      schedulePeriods,
    };
  };

  const schedulePeriodsSettings = getSchedulePeriodSettings();
  const [currentSchedulePeriodsSettings, setCurrentSchedulePeriodsSettings] =
    useState(schedulePeriodsSettings);

  const handleSaveNewSchedulePeriod = async (
    newSettings: SchedulePeriodsSettings,
  ) => {
    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    updatedSettings.statusScheduleTriggers = newSettings.statusScheduleTriggers;
    updatedSettings.bestContentOnlySinceCurrentOnPeriod =
      newSettings.bestContentOnlySinceCurrentOnPeriod;

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
      schedulePeriodMixpanel,
    );
  };

  const schedulePeriodMixpanel = (
    updatedTracking: FixTypeLater,
    previousTracking: FixTypeLater,
  ) => {
    tracker.track({
      eventName: 'Update Autofeed Schedule Period Settings',
      trackingParams: {
        ...updatedTracking,
        'Social Page': accountAPIs.getAPIPostName({ accountAPIId }),
        'Previous Num Active Schedule Periods':
          previousTracking['Num Active Schedule Periods'],
      },
    });
  };

  const handleSchedulePeriodSettingsChange = (
    newSettings: SchedulePeriodsSettings,
  ) => {
    setCurrentSchedulePeriodsSettings(newSettings);

    if (isEqual(newSettings, schedulePeriodsSettings)) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveNewSchedulePeriod(newSettings));
  };

  const handleCheckboxChange = () => {
    const newValue =
      !currentSchedulePeriodsSettings.bestContentOnlySinceCurrentOnPeriod;
    const newSettings = {
      ...currentSchedulePeriodsSettings,
      bestContentOnlySinceCurrentOnPeriod: newValue,
    };
    handleSchedulePeriodSettingsChange(newSettings);
  };

  const getUTCOffset = () => {
    // add UTC offset for user
    const userOffset = (-1 * new Date().getTimezoneOffset()) / 60; // in hours
    if (userOffset > 0) {
      return 'UTC+'.concat(userOffset.toString());
    }
    if (userOffset < 0) {
      return 'UTC'.concat(userOffset.toString());
    }
    // userOffset === 0
    return 'UTC';
  };

  return (
    <>
      <AutomationSettingsHeader
        title="Schedule periods"
        description="Select times when automation should be on/off"
      />
      <Flex flexDir="column" gap={3} display={{ base: 'flex', md: 'none' }}>
        <Heading variant="h5">
          The schedule period settings are not optimized for smartphones yet.
        </Heading>
        <Text size="xs">
          Please use a desktop browser to update these settings.
        </Text>
      </Flex>
      <Flex
        flexDir="column"
        gap={3.5}
        bg="base"
        w="fit-content"
        p={5}
        borderRadius="md"
        boxShadow="border"
      >
        <Text size="sm">
          If all boxes are unchecked Echobox will always share
        </Text>
        <ScheduleMatrix
          currentSchedulePeriodSettings={currentSchedulePeriodsSettings}
          handleSchedulePeriodSettingsChange={
            handleSchedulePeriodSettingsChange
          }
        />
        <Checkbox
          id="bestContentOnlySinceCurrentOnPeriod"
          data-cy-id="activeContent"
          name="bestContentOnlySinceCurrentOnPeriod"
          isChecked={
            currentSchedulePeriodsSettings.bestContentOnlySinceCurrentOnPeriod
          }
          onChange={handleCheckboxChange}
          maxW="980px"
          alignItems="flex-start"
          sx={checkboxStyles}
        >
          For new shares, only select content from the RSS feed if it&apos;s
          published during the active hours set by the Autofeed&apos;s schedule
          (e.g. if you set the schedule to run between 6pm and 11pm, only
          content that appears on the RSS feed within this time frame will be
          chosen for sharing).
        </Checkbox>
        <Flex flexDir="column" gap={1}>
          <Text size="sm">Note:</Text>
          <UnorderedList ml="24px">
            <ListItem {...listItemStyles}>
              The above times are in {getUTCOffset()}.
            </ListItem>
            <ListItem {...listItemStyles}>
              If all boxes are left blank, automation will remain active all the
              time.
            </ListItem>
            <ListItem {...listItemStyles}>
              If automation is turned off completely, Echobox will never share
              and disregard schedule periods.
            </ListItem>
            <ListItem {...listItemStyles}>
              Automation must be turned ON to share during your schedule
              periods.
            </ListItem>
          </UnorderedList>
        </Flex>
      </Flex>
    </>
  );
}

export default SchedulePeriods;
