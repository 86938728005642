import { useToast, UseToastProps } from '@ebx-ui/ebx-ui-component-library-sdk';
import PubSub from 'pubsub-js';
import * as React from 'react';

import { NOTIFICATION_ADD } from 'pubsub/topics';

export type NotificationLevel = 'error' | 'warning' | 'info' | 'success';

export interface NotificationOptions {
  message: string;
  level: NotificationLevel;
  duration: number;
}

/**
 * A notification system that listens to PubSub events and displays a toast
 */
const NotificationSystem = () => {
  const toast = useToast();

  React.useEffect(() => {
    const token = PubSub.subscribe(
      NOTIFICATION_ADD,
      (_: string, { message, level, duration }: NotificationOptions) => {
        const levelVariantMap: Record<
          NotificationLevel,
          UseToastProps['variant']
        > = {
          error: 'error',
          warning: 'chilledError',
          info: 'light',
          success: 'success',
        };

        toast({
          variant: levelVariantMap[level],
          title: message,
          duration,
          isClosable: true,
        });
      },
    );

    return () => {
      PubSub.unsubscribe(token);
    };
  }, [toast]);

  return null;
};

export default NotificationSystem;
