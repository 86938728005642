import { Button, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

import TrackerInstallationGuide from 'components/misc/TrackerInstallationGuide';

const InstallationGuideModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal size="small" isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Installation guide</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TrackerInstallationGuide showHeading={false} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstallationGuideModal;
