import {
  Button,
  FormControl,
  HStack,
  Input,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { getActiveAPIsForProperty } from 'common/accountAPIs';
import { FLASH_MESSAGE_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import * as logger from 'common/logger';
import { ErrorWithMessageSchema, ErrorWithStatusSchema } from 'common/schemas';
import { convertToPropertyURN } from 'common/urn';
import { location } from 'common/window';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import { getPropertyById } from 'helpers/getPropertyById';
import { impersonateProperty } from 'process/impersonate';
import { PropertyDetailsState } from './PropertyToolsModal/PropertyToolsModal';

import PropertyToolsModal from './PropertyToolsModal';

const PLEASE_TRY_AGAIN =
  'Please check the Id is correct and try again. If the error persists, speak to someone in Tech.';

const ImpersonatePropertyForm = ({
  handleAccountAPIToolsClick,
}: {
  handleAccountAPIToolsClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    propertyId: string,
  ) => void;
}) => {
  const [propertyIdInput, setPropertyIdInput] = useState('');
  const [isImpersonatePropertyLoading, setIsImpersonatePropertyLoading] =
    useState(false);
  const [isAllPropertyToolsLoading, setIsAllPropertyToolsLoading] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPropertyToolsModal, setShowPropertyToolsModal] = useState(false);
  const [propertyToolsModalInitialState, setPropertyToolsModalInitialState] =
    useState<PropertyDetailsState>({ state: 'SEARCH' });

  const flashMessagesContext = useFlashMessagesContext();

  const handleImpersonatePropertySubmit = async (propertyId: string) => {
    logger.info('ImpersonatePropertyForm:handleImpersonatePropertySubmit');
    setIsImpersonatePropertyLoading(true);
    setErrorMessage(null);

    try {
      // Attempt to log in to a specified property
      await impersonateProperty({
        propertyId,
      });
      flashMessagesContext.flashMessages.deleteMessages();
      if (window) {
        const apisForProperty = getActiveAPIsForProperty({ propertyId });
        if (apisForProperty.length > 0) {
          const { accountAPIId } = apisForProperty[0];
          location.href = `/share/${accountAPIId}`;
        } else {
          location.href = '/share?impersonate+error=no+active+pages';
        }
      }
    } catch (error) {
      const parsedError = ErrorWithStatusSchema.safeParse(error);
      if (parsedError.success && parsedError.data.status === 403) {
        setErrorMessage(FRONTEND_ERROR_MESSAGES['impersonate-403']);
      } else if (parsedError.success && parsedError.data.status === 422) {
        const errorWithMessage = ErrorWithMessageSchema.safeParse(error);
        if (
          errorWithMessage.success &&
          errorWithMessage.data.error.message.match(
            /Could not get users for Property/g,
          )
        ) {
          setErrorMessage(
            `Could not find any users for the Property URN ${convertToPropertyURN(
              propertyId,
            )}. ${PLEASE_TRY_AGAIN}`,
          );
        } else if (
          errorWithMessage.success &&
          errorWithMessage.data.error.message.match(
            /No Property level admin permissions for Property/g,
          )
        ) {
          setErrorMessage(
            `Could not find any property level admin users for the Property URN ${convertToPropertyURN(
              propertyId,
            )}. ${PLEASE_TRY_AGAIN}`,
          );
        } else if (
          errorWithMessage.success &&
          errorWithMessage.data.error.message.match(
            /Could not get user data from cache for/g,
          )
        ) {
          setErrorMessage(
            `Could not get the username for a user with access to the Property URN ${convertToPropertyURN(
              propertyId,
            )}. ${PLEASE_TRY_AGAIN}`,
          );
        } else {
          setErrorMessage(
            `Error impersonating the Property URN ${convertToPropertyURN(
              propertyId,
            )}. ${PLEASE_TRY_AGAIN}`,
          );
        }
      } else {
        flashMessagesContext.flashMessages.addMessage({
          messageCategory:
            'Impersonate property POST unexpected impersonate error',
          type: FLASH_MESSAGE_TYPES.ERROR,
          text: getErrorMessage(determineError(error)),
          timeout: 2500,
        });
      }
      setIsImpersonatePropertyLoading(false);
    }
  };

  const handleAllPropertyToolsSubmit = async () => {
    logger.info('ImpersonatePropertyForm:handleImpersonatePropertySubmit');

    if (propertyIdInput !== '') {
      setIsAllPropertyToolsLoading(true);

      try {
        await getPropertyById({
          propertyId: propertyIdInput,
          onSuccess: ({ property, socialPages }) => {
            setPropertyToolsModalInitialState({
              state: 'PROPERTY_DETAILS',
              propertyData: property,
              socialPages,
            });
            setShowPropertyToolsModal(true);
          },
        });
      } catch (error) {
        setErrorMessage(
          `Error fetching Property details for Property with URN ${convertToPropertyURN(
            propertyIdInput,
          )}. ${PLEASE_TRY_AGAIN}`,
        );
      }

      setIsAllPropertyToolsLoading(false);
    }
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    logger.info('ImpersonatePropertyForm:handleSubmit');

    if (propertyIdInput !== '') {
      handleImpersonatePropertySubmit(propertyIdInput);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl isInvalid={errorMessage !== null}>
        <FormControl.FormLabel htmlFor="propertyId">
          Property tools
        </FormControl.FormLabel>
        <HStack>
          <Input
            type="text"
            placeholder="Enter a Property Id..."
            id="propertyId"
            value={propertyIdInput}
            onChange={(e) => setPropertyIdInput(e.target.value)}
          />
          <Button
            type="submit"
            variant="secondary"
            isLoading={isImpersonatePropertyLoading}
            loadingText="Submitting"
            flexShrink={0}
          >
            Impersonate
          </Button>
          <Button
            type="button"
            onClick={handleAllPropertyToolsSubmit}
            variant="secondary"
            isLoading={isAllPropertyToolsLoading}
            loadingText="Submitting"
            flexShrink={0}
          >
            All tools
          </Button>
        </HStack>
        {errorMessage && (
          <FormControl.FormErrorMessage>
            {errorMessage}
          </FormControl.FormErrorMessage>
        )}
      </FormControl>
      {showPropertyToolsModal && (
        <PropertyToolsModal
          onClose={() => setShowPropertyToolsModal(false)}
          initialState={propertyToolsModalInitialState}
          handleAccountAPIToolsClick={(event) => {
            handleAccountAPIToolsClick(event, propertyIdInput);
          }}
        />
      )}
    </form>
  );
};

export default ImpersonatePropertyForm;
