/* eslint consistent-return: "off" */

import { useEffect, useRef } from 'react';

import { isNullOrUndefined } from 'common/utility';

function useTimeout(callback: () => void, delay?: number): void {
  const savedCallback = useRef<() => void | undefined>();

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (!isNullOrUndefined(delay)) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}

export default useTimeout;
