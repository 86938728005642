const Video = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '14px' }}
    className="mr-2"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.28571 0C1.02335 0 0 1.02335 0 2.28571V13.7143C0 14.9767 1.02335 16 2.28571 16H13.7143C14.9767 16 16 14.9767 16 13.7143V2.28571C16 1.02335 14.9767 0 13.7143 0H2.28571ZM5.33318 12L11.9998 8L5.33318 4L5.33318 12Z"
    />
  </svg>
);

export default Video;
