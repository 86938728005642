import axios, { AxiosRequestConfig, AxiosResponse } from 'api/axios';
import { API_TIMEOUTS } from 'api/settings';
import { CORS_PROXY_URL } from 'common/config';
import { handleExternalAPIError } from 'common/errorHandling';
import { mandatory } from 'common/validation';

interface ResponseMap {
  blob: Blob;
  arraybuffer: ArrayBuffer;
}

export default async function getDownloadFile<T extends keyof ResponseMap>({
  url,
  responseType,
}: {
  url: string;
  responseType: T;
}): Promise<AxiosResponse<ResponseMap[T]>> {
  if (!url) mandatory('url');

  const config: AxiosRequestConfig = {
    timeout: API_TIMEOUTS.M,
    responseType,
    headers: {
      'X-Requested-With': 'Echobox',
    },
  };

  try {
    const response = await axios.get<ResponseMap[T]>(
      `${CORS_PROXY_URL}${url}`,
      config,
    );
    return response;
  } catch (error) {
    throw handleExternalAPIError({
      originalError: error,
      errorLocation: 'api/getDownloadFile',
      args: {},
    });
  }
}
