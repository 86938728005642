import FormData from 'form-data';

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { MEDIA_PREFIXES } from 'common/config';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isDefined } from 'common/utility';
import { mandatory } from 'common/validation';

export default async function postUploadImage({
  socialPageURN,
  imageFile,
  imageURL,
  onProgress,
  addWhiteBackground,
}: {
  socialPageURN: string | null;
  imageFile?: (Blob & { name?: string; lastModified?: number }) | null;
  imageURL?: string;
  onProgress?: (options: { percent: number }) => void;
  addWhiteBackground?: boolean;
}): Promise<string> {
  if (!socialPageURN) mandatory('socialPageURN');

  if (!isDefined(imageFile) && !isDefined(imageURL)) {
    throw new ReferenceError('imageFile and imageURL are both undefined');
  }

  if (isDefined(imageFile) && isDefined(imageURL)) {
    throw new ReferenceError('imageFile and imageURL are both defined');
  }

  const guid = metrics.start('postUploadImage');
  let url = `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/media/upload/images`;

  let config: AxiosRequestConfig;
  if (imageFile) {
    const imageData = new FormData();
    imageData.append('imageFile', imageFile, imageFile.name);

    if (isDefined(addWhiteBackground) && !addWhiteBackground) {
      url = `${url}?addWhiteBackground=${addWhiteBackground}`;
    }
    config = {
      url,
      method: 'POST',
      timeout: API_TIMEOUTS.XXXL,
      headers: getClientServiceRequestHeaders({
        'Content-Type': 'multipart/form-data',
      }),
      data: imageData,
      onUploadProgress: ({ loaded, total = 1 }) => {
        const percent = Math.floor((loaded / total) * 10000) / 100;
        const progressMessage = `${percent}% ${loaded}/${total} bytes`;
        logger.info(`Image upload progress: ${progressMessage}`);
        if (onProgress) {
          onProgress({ percent });
        }
      },
    };
  } else {
    config = {
      url,
      method: 'POST',
      timeout: API_TIMEOUTS.XXXL,
      headers: getClientServiceRequestHeaders({
        'Content-Type': 'application/json',
      }),
      params: { imageURL },
    };
  }
  logger.info(`API:postUploadImage ${url}`);

  try {
    const response = await axios(config);
    const filename = response.data.imageName;
    metrics.end('postUploadImage', guid);
    logger.debug('postUploadImage', { socialPageURN });
    if (filename.indexOf(MEDIA_PREFIXES.IMAGE) !== -1) {
      return filename;
    }
    return `${MEDIA_PREFIXES.IMAGE}/${filename}`;
  } catch (error) {
    metrics.fail('postUploadImage', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postUploadImage',
      args: {
        socialPageURN,
        imageFile,
        imageURL,
      },
    });
    throw apiError;
  }
}
