export interface InfoProps {
  size?: string;
}

const Info = ({ size = '20' }: InfoProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16663 5.83333H10.8333V7.5H9.16663V5.83333ZM9.16663 9.16667H10.8333V14.1667H9.16663V9.16667ZM9.99996 1.66667C5.39996 1.66667 1.66663 5.4 1.66663 10C1.66663 14.6 5.39996 18.3333 9.99996 18.3333C14.6 18.3333 18.3333 14.6 18.3333 10C18.3333 5.4 14.6 1.66667 9.99996 1.66667ZM9.99996 16.6667C6.32496 16.6667 3.33329 13.675 3.33329 10C3.33329 6.325 6.32496 3.33333 9.99996 3.33333C13.675 3.33333 16.6666 6.325 16.6666 10C16.6666 13.675 13.675 16.6667 9.99996 16.6667Z"
      fill="currentColor"
    />
  </svg>
);

export default Info;
