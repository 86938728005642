import { Tag } from '@ebx-ui/ebx-ui-component-library-sdk';

import { PROPERTY_STATES } from 'common/constants';

interface PropertyStateTagProps {
  propertyState: string;
}

const PropertyStateTag = ({ propertyState }: PropertyStateTagProps) => {
  if (propertyState === PROPERTY_STATES.ACTIVE) {
    return <Tag color="green">{propertyState}</Tag>;
  }

  if (propertyState === PROPERTY_STATES.SUSPENDED) {
    return <Tag color="red">{propertyState}</Tag>;
  }

  return <Tag color="gray">{propertyState}</Tag>;
};

export default PropertyStateTag;
