/* eslint no-use-before-define: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

import { useEffect, useState } from 'react';

import { isNull } from 'common/utility';

export interface UseInfiniteScrollProps {
  id: string;
  isLoading: boolean;
  moreItemsExist: boolean;
  callback: () => void;
}

const useInfiniteScroll = ({
  id,
  isLoading,
  moreItemsExist,
  callback,
}: UseInfiniteScrollProps): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const documentElement = document.getElementById(id);
    if (!isNull(documentElement)) {
      documentElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (!isNull(documentElement)) {
        documentElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading, moreItemsExist]);

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching]);

  const handleScroll = () => {
    const element = document.getElementById(id);
    if (element) {
      const { offsetHeight, scrollHeight, scrollTop } = element;
      if (moreItemsExist && offsetHeight + scrollTop === scrollHeight) {
        setIsFetching(true);
      }
    }
  };

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
