import { Check2Icon, HStack, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import ReviewSection from 'components/setup/ReviewSection';

const ReviewWebsiteTag = () => (
  <ReviewSection title="Web Tag">
    <HStack spacing="2">
      <Check2Icon color="green.600" />
      <Text size="sm" color="green.600" fontWeight="medium">
        Receiving data
      </Text>
    </HStack>
  </ReviewSection>
);

export default ReviewWebsiteTag;
