import PropTypes from 'prop-types';
import { memo } from 'react';

import { isCurrentPropertySuspended } from 'common/accountAPIs';
import { FEED_STATES } from 'common/constants';
import { arePropsEqual } from 'common/utility';
import TimeframeOptions from 'components/home/TimeframeOptions';

/**
 * Article feed filters
 */

const Filters = ({ displayOptions, eventHandlers, feedState }) => {
  const suspendedClass = isCurrentPropertySuspended()
    ? 'account_suspended'
    : '';

  return (
    <div className={`controls d-flex flex-wrap mb-2 ${suspendedClass}`}>
      <select
        className="filter filter_type btn btn-light dropdown-md align-self-center mr-2 my-1"
        data-cy-select="contentType"
        value={feedState.toString()}
        onChange={eventHandlers.handleStateChange}
      >
        <option value={FEED_STATES.NEW}>All New</option>
        <option value={FEED_STATES.DRAFT}>Drafts Only</option>
        <option value={FEED_STATES.DELETED}>Deleted Items</option>
      </select>
      {feedState.toString() !== FEED_STATES.DRAFT.toString() && (
        <select
          className="filter filter_timeframe btn btn-light dropdown-md align-self-center mr-2 my-1"
          data-cy-select="newTimeWindow"
          value={displayOptions.timeFrame}
          onChange={eventHandlers.handleTimeframeChange}
        >
          <option disabled>Timeframe</option>
          <TimeframeOptions />
        </select>
      )}
      <select
        className="filter filter_sorting btn btn-light dropdown-md align-self-center my-1"
        data-cy-select="sortOrder"
        value={displayOptions.sortBy}
        onChange={eventHandlers.handleSortChange}
      >
        <option disabled>Sort by</option>
        <option value="potential">Score</option>
        <option value="date">Date</option>
      </select>
    </div>
  );
};

Filters.propTypes = {
  displayOptions: PropTypes.object.isRequired,
  feedState: PropTypes.oneOf(Object.values(FEED_STATES)).isRequired,
  eventHandlers: PropTypes.shape({
    handleSortChange: PropTypes.func.isRequired,
    handleStateChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(Filters, arePropsEqual);
