import { Flex, Tooltip, useRadio } from '@ebx-ui/ebx-ui-component-library-sdk';

type UseRadioReturnType = ReturnType<typeof useRadio>;

type ShareType = 'NEW' | 'SCHEDULED' | 'DRAFT';

const SHARE_TYPE_TOOLTIP: Partial<Record<ShareType, string>> = {
  DRAFT: 'You can’t change the share type of a draft',
  SCHEDULED: 'You can’t change the share type after it has been scheduled',
} as const;

interface InstagramSocialChannelRadioGroupProps {
  children: React.ReactNode;
  getCheckboxProps: UseRadioReturnType['getCheckboxProps'];
  getInputProps: UseRadioReturnType['getInputProps'];
  isDisabled: boolean | undefined;
  shareType: ShareType;
}

const InstagramSocialChannelRadioGroup = ({
  children,
  getCheckboxProps,
  getInputProps,
  isDisabled,
  shareType,
}: InstagramSocialChannelRadioGroupProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentionally omitting style from the rest of the props
  const { style, ...inputProps } = getInputProps();
  const isChecked = inputProps.checked;
  const isDisabledAndUnchecked = isDisabled && !isChecked;
  const hasShareTypeTooltip = !!SHARE_TYPE_TOOLTIP[shareType];
  return (
    <Tooltip
      placement="top-start"
      isDisabled={!isDisabledAndUnchecked || !hasShareTypeTooltip}
      label={SHARE_TYPE_TOOLTIP[shareType]}
    >
      <Flex
        {...getCheckboxProps()}
        as="label"
        cursor="pointer"
        boxShadow="inset 0px 0px 0px 1px #E3E8EE"
        _checked={{
          boxShadow: 'inset 0px 0px 0px 2px #3976E6',
        }}
        borderRadius="3px"
        pt="11px"
        pb={3}
        flexGrow={1}
        flexDirection="column"
        position="relative"
        alignItems="center"
        gap={0.5}
        opacity={isDisabledAndUnchecked ? '0.35' : '1'}
        _before={{
          content: '""',
          position: 'absolute',
          top: 2.5,
          left: '13px',
          width: '3.5',
          height: '3.5',
          backgroundColor: 'white',
          borderRadius: 'full',
          border: isChecked ? '4px' : '1px',
          borderColor: isChecked ? '#3976E6' : '#A3ACB9',
        }}
        _hover={
          !isDisabledAndUnchecked
            ? {
                backgroundColor: '#F8F9FA',
                _before: { borderColor: '#3976E6' },
              }
            : { cursor: 'default' }
        }
        data-group
      >
        <input type="radio" {...inputProps} disabled={isDisabledAndUnchecked} />
        {children}
      </Flex>
    </Tooltip>
  );
};

export default InstagramSocialChannelRadioGroup;
