import { Center, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import * as s3 from 'common/s3';
import Tooltip from 'components/misc/Tooltip';
import useMergedImage from 'hooks/useMergedImage';
import AnalyticsMessageWithTags from './AnalyticsMessageWithTags';

const ABResult = (props) => {
  const {
    abDetail,
    abLabel,
    accountAPIId,
    accountImage,
    accountName,
    itemTags,
  } = props;
  const imageURL = s3.convertEchoboxImageToAmazon(abDetail.imageURL);
  const overlayURL = s3.convertEchoboxImageToAmazon(abDetail.imageOverlayURL);

  const processedImage = useMergedImage(imageURL, overlayURL);

  const imageStyle = { backgroundImage: `url('${processedImage}')` };

  const tooltipLabel =
    'The results of this AB Test were not statistically significant enough to show a result.';

  return (
    <div className="abtest_full_variation mb-4 col-md-6">
      <Tooltip
        id="failed"
        placement="top"
        label={tooltipLabel}
        isDisabled={!(abDetail.ctrClass === 'failed')}
      >
        <>
          <div
            className="abtest_full_header"
            data-tip
            data-for="failed"
            data-iscapture="true"
          >
            <div className="abtest_full_version">
              Version{` `}
              {abLabel}
            </div>
            <div className={`abtest_full_result ${abDetail.ctrClass}`}>
              {abDetail.ctrLabel}
            </div>
          </div>
          <div className="preview">
            <div className="page_info">
              <img className="page_logo" src={accountImage} alt="" />
              <div className="page_title">{accountName}</div>
            </div>
            <AnalyticsMessageWithTags
              message={abDetail.message}
              tags={itemTags}
              accountAPIId={accountAPIId}
              outerClass="share_message"
            />
            <div className="share_imagery">
              {processedImage !== null ? (
                <div className="share_image" style={imageStyle} />
              ) : (
                <Center>
                  <Spinner size="lg" />
                </Center>
              )}
            </div>
            <div className="share_title mb-1">{abDetail.title}</div>
            <div className="share_description">{abDetail.description}</div>
          </div>
        </>
      </Tooltip>
    </div>
  );
};

ABResult.propTypes = {
  abLabel: PropTypes.string.isRequired,
  abDetail: PropTypes.object.isRequired,
  accountAPIId: PropTypes.number.isRequired,
  accountImage: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  itemTags: PropTypes.object.isRequired,
};

export default ABResult;
