import {
  Box,
  Checkbox,
  Flex,
  Link,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { getFeedTitleOrDefault } from 'common/feed';
import { useEllipsisActive } from 'hooks/useEllipsisActive';
import type { AccountFeed } from 'types';

interface EditContentFeedsCheckboxProps {
  feed: AccountFeed;
  isChecked: boolean;
  isTooltipDisabled?: boolean;
  onChange: () => void;
}

const EditContentFeedsCheckbox = ({
  feed,
  isChecked,
  isTooltipDisabled,
  onChange,
}: EditContentFeedsCheckboxProps) => {
  const linkContainerRef = React.useRef<HTMLDivElement>(null);
  const isEllipsisActive = useEllipsisActive(linkContainerRef);

  return (
    <Flex py={2} flexDirection="column" gap={1} alignItems="flex-start">
      <Checkbox lineHeight={5} isChecked={isChecked} onChange={onChange}>
        {getFeedTitleOrDefault(feed)}
      </Checkbox>
      <Box
        pl={6}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        w="full"
        ref={linkContainerRef}
      >
        <Tooltip
          label={feed.syndFeedURL}
          maxWidth="auto"
          isDisabled={!isEllipsisActive || isTooltipDisabled}
        >
          <Link
            fontSize="xs"
            lineHeight="4"
            variant="secondary"
            href={feed.syndFeedURL}
            isExternal
          >
            {feed.syndFeedURL}
          </Link>
        </Tooltip>
      </Box>
    </Flex>
  );
};

export default EditContentFeedsCheckbox;
