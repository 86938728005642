import { TEXT_CASES } from 'common/constants';
import { getFormattedDateFromUnix } from 'common/datetime';
import { trackOpenArticle } from 'helpers/articleTracking';

interface ItemPublishedProps {
  articleURL: string;
  publishedTime: number;
  twelveHourFormat: boolean;
}

const ItemPublished = ({
  articleURL,
  publishedTime,
  twelveHourFormat,
}: ItemPublishedProps) => {
  return (
    <a
      href={articleURL}
      target="_blank"
      rel="noopener noreferrer"
      className="article_link link_grey"
      onClick={() => trackOpenArticle(articleURL, 'Archive')}
    >
      Published{' '}
      {getFormattedDateFromUnix({
        timestamp: publishedTime,
        twelveHourFormat,
        textCase: TEXT_CASES.LOWER,
      })}
      <img className="link_icon" src="/img/icons/ic-open-in-new.svg" alt="" />
    </a>
  );
};

export default ItemPublished;
