import { Auth } from 'aws-amplify';

import * as authentication from 'common/authentication';
import { handleCognitoError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { mandatory } from 'common/validation';

/**
 * Async Function to verify code during MFA setup
 * Uses Amplify SDK's verifyTotpToken method to verify the code
 * @returns {Object} response - success or failure status eg. {Status: 'SUCCESS'}
 */
export default async function authVerifyTotpToken(code = mandatory('code')) {
  const guid = metrics.start('authVerifyTotpToken');
  const user = await Auth.currentAuthenticatedUser();
  const username = authentication.getUsername();
  logger.info(`API:authVerifyTotpToken ${username}`);
  try {
    const response = await Auth.verifyTotpToken(user, code);
    metrics.end('authVerifyTotpToken', guid);
    return response;
  } catch (error) {
    metrics.fail('authVerifyTotpToken', guid);
    throw handleCognitoError({
      originalError: error,
      errorLocation: 'api/authVerifyTotpToken',
      args: { username },
    });
  }
}
