/* eslint no-lonely-if:"off" */
/* eslint no-use-before-define:"off" */

import {
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialLinkedinIcon,
  SocialTiktokIcon,
  SocialTwitterIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { SHARE_URL_PLACEHOLDER } from 'common/config';
import {
  ACCOUNT_SETTING_TYPES,
  API_PROPERTIES,
  API_STATES,
  API_TYPE_IDS,
  FRONTEND_PAGES,
  POST_TYPES,
  SOCIAL_CHANNELS,
  TAG_TYPES,
  TEXT_CASES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import {
  getFeatureToggle,
  getSetting,
  isIGCabinetEnabled,
} from 'common/settings';
import {
  getCharacterCount,
  getURLLength,
  truncateMessage,
} from 'common/string';
import { cloneObject, isDefined, isNull, isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import { getCurrentPropertyId, sortPages } from './accountAPIs';
import { isWithinAspectRatio } from './image';
import {
  getAllowedFileTypes,
  getAutofeedDefaults,
  getLastSharedLabel,
  getTrackingSocialChannel,
  getURNName,
} from './socialV2';
import validators from './validators';

export {
  addPlaceholderToMessage,
  canAccessPage,
  canAddImage,
  canDeleteImage,
  canDeletePosts,
  canGenerateAIMessage,
  canResharePosts,
  canReshareVideos,
  canShareNow,
  canShareToAllPages,
  colorHashtagsAndMentions,
  createTagDetails,
  getAccountDisconnectedError,
  getAllowedFileTypes,
  getAutofeedDefaults,
  getAutofeedMessageSources,
  getDataSourceDefault,
  getDimensionLimits,
  getEmojiSet,
  getExcludeImagesRule,
  getFirstCommentSettings,
  getImageAspectRatios,
  getImageQuantityLimits,
  getImageTransformOptions,
  getImageUploadCaption,
  getImageUploadTooltip,
  getLastSharedLabel,
  getMaximumUrlByteLength,
  getMaximumUrlLength,
  getMediaSizeLimit,
  getMessageSettings,
  getNewShareOptions,
  getPostTypeLabel,
  getRawMentionsFromMessage,
  getShareIcon,
  getShorteningDisabledSetting,
  getSocialNetWorkIconByAPITypeId,
  getSocialNetworkDefaultPageIcon,
  getSocialNetworkDescriptionSources,
  getSocialNetworkEntityName,
  getSocialNetworkIcon,
  getSocialNetworkImageSources,
  getSocialNetworkMessageBoxPlaceholder,
  getSocialNetworkMessageSources,
  getSocialNetworkName,
  getSocialNetworkTagPattern,
  getSocialNetworkTitleSources,
  getSocialNetworks,
  getStartingPage,
  getTagDetails,
  getTrackingSocialChannel,
  getURNName,
  getValidationMessages,
  getVideoDurationLimits,
  hasABVariations,
  hasAudienceRestriction,
  hasCharacterCount,
  hasDataSourceDefault,
  hasDescriptionField,
  hasDomainField,
  hasHashtagsMentions,
  hasImageOverlays,
  hasLinkPlaceholder,
  hasMentionsLookups,
  hasMessageBeforeImage,
  hasMessageField,
  hasPostType,
  hasShareMessages,
  hasSponsoredPosts,
  hasTitleField,
  hasTitleFieldCharacterCount,
  hasVerifiedAccounts,
  hasVideoTitle,
  includeMentionsAtSymbol,
  isAspectRatioValid,
  isMessageFieldRequired,
  isSocialNetwork,
  isTitleFieldRequired,
  isValidAspectRatioMandatory,
};

/*
 * Private methods
 * -----------------------------------------------------------------------------
 */

function hasKey({
  apiTypeId = mandatory('apiTypeId'),
  key = mandatory('key'),
} = {}) {
  return (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId][key]) &&
    API_PROPERTIES[apiTypeId][key]
  );
}

function hasPerPostTypeKey({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('apiTypeId'),
  key = mandatory('key'),
} = {}) {
  if (typeof API_PROPERTIES[apiTypeId]?.[key] === 'boolean') {
    return API_PROPERTIES[apiTypeId][key];
  }
  return (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId][key]) &&
    isDefined(API_PROPERTIES[apiTypeId][key][postType]) &&
    API_PROPERTIES[apiTypeId][key][postType]
  );
}

function hasSubKey({
  apiTypeId = mandatory('apiTypeId'),
  key = mandatory('key'),
  subkey = mandatory('subkey'),
  defaultValue = false,
} = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId][key]) &&
    isDefined(API_PROPERTIES[apiTypeId][key][subkey])
  ) {
    return API_PROPERTIES[apiTypeId][key][subkey];
  }
  return defaultValue;
}

/**
 * Exported methods
 * -----------------------------------------------------------------------------
 */

/**
 * addPlaceholderToMessage
 * @param {{
 *  apiTypeId: number;
 *  message: string;
 * }}
 */

function addPlaceholderToMessage({
  apiTypeId = mandatory('apiTypeId'),
  message = mandatory('message'),
} = {}) {
  const messageSettings = getMessageSettings({ apiTypeId });
  let result = message;

  if (!isNull(messageSettings.maximumLength)) {
    if (
      getCharacterCount(message, { apiTypeId }) +
        getURLLength('', { apiTypeId }) +
        1 >
      messageSettings.maximumLength
    ) {
      // Truncate message
      result = truncateMessage({
        message: result,
        length: messageSettings.maximumLength - messageSettings.urlLength - 4,
        apiTypeId,
      });
      // Append link
      result = `${result}... ${SHARE_URL_PLACEHOLDER}`;
    } else {
      result += `${result !== '' ? ' ' : ''}${SHARE_URL_PLACEHOLDER}`;
    }
  } else {
    result += `${result !== '' ? ' ' : ''}${SHARE_URL_PLACEHOLDER}`;
  }
  return result;
}

/**
 * canAccessPage
 */

function canAccessPage({
  apiTypeId = mandatory('apiTypeId'),
  page = mandatory('page'),
} = {}) {
  if (
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    page === FRONTEND_PAGES.ANALYTICS &&
    isIGCabinetEnabled()
  ) {
    return false;
  }

  return hasSubKey({
    apiTypeId,
    key: 'canAccessPage',
    subkey: page,
    defaultValue: true,
  });
}

/**
 * Determines if the user can add an image to a post, or a thumbnail to a video post
 *
 *  @param {{
 *  apiTypeId: number;
 *  postType: string;
 *  socialChannel?: import('types').SocialChannel;
 *  videoURL?: string;
 * }}
 */

function canAddImage({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel,
  videoURL = '',
} = {}) {
  // If it's not a video post, or the video is undefined, we can enable uploading
  if (videoURL === '' || postType !== POST_TYPES.VIDEO) {
    return true;
  }

  const canAddVideoThumbnail = API_PROPERTIES[apiTypeId]?.canAddVideoThumbnail;

  if (!canAddVideoThumbnail) {
    return false;
  }

  if (typeof canAddVideoThumbnail === 'boolean') {
    return canAddVideoThumbnail;
  }

  return !!(socialChannel && canAddVideoThumbnail[socialChannel]);
}

/**
 * canDeleteImage
 *  @param {{
 *  postType: string;
 *  noOfImages?: number;
 *  minImages?: number;
 * }}
 */

function canDeleteImage({
  postType = mandatory('postType'),
  noOfImages = 0,
  minImages = 0,
} = {}) {
  const canDelete = noOfImages > minImages && postType !== POST_TYPES.VIDEO;
  return canDelete;
}

/**
 * canDeletePosts
 */

function canDeletePosts({ apiTypeId = mandatory('apiTypeId') }) {
  return hasKey({ apiTypeId, key: 'canDeletePosts' });
}

/**
 * canGenerateAIMessage
 *
 * Whether GPT2 AI messages can be generated for this API type
 * @param {{ apiTypeId: number; postType: import('types').PostType }}
 * @returns {boolean}
 */

function canGenerateAIMessage({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
} = {}) {
  const isInstantImageEnabled =
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.MANUAL_IMAGE_FROM_ARTICLE_ENABLED,
      propertyId: getCurrentPropertyId(),
    }) && apiTypeId === API_TYPE_IDS.FACEBOOK;

  if (postType !== POST_TYPES.LINK && !isInstantImageEnabled) {
    return false;
  }

  return hasKey({ apiTypeId, key: 'canGenerateAIMessage' });
}

/**
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */
function canResharePosts({ apiTypeId = mandatory('apiTypeId') }) {
  return hasKey({ apiTypeId, key: 'canResharePosts' });
}

/**
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */
function canReshareVideos({ apiTypeId = mandatory('apiTypeId') }) {
  return hasKey({ apiTypeId, key: 'canReshareVideos' });
}

/**
 * canShareNow
 * @param{{
 *  apiTypeId: number;
 * }}
 */

function canShareNow({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (isIGCabinetEnabled() && apiTypeId === API_TYPE_IDS.INSTAGRAM) {
    return false;
  }
  return hasKey({ apiTypeId, key: 'canShareNow' });
}

/**
 * canShareToAllPages
 *
 * Can multiple accounts for the same social network be selected at the same time
 * when sharing a post? True for Facebook only at present
 */

function canShareToAllPages({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return API_PROPERTIES[apiTypeId]?.canShareToAllPages;
}

/**
 * colorHashtagsAndMentions
 * @param{{
 *  apiTypeId: number;
 * }}
 */

function colorHashtagsAndMentions({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return API_PROPERTIES[apiTypeId]?.colorHashtagsAndMentions;
}

/**
 * Method: createTagDetails
 *
 * -------------------------------------------------------------------------------------------------
 *
 * Creates tag details (clean / raw / more) from an tag search result
 *
 * Example:
 *
 * INPUT:
 * ```
 * {
 *   raw: '396093583788031'
 *   clean: 'Echobox'
 *   username: 'EchoboxHQ'
 *   results: [{
 *     likes: 804
 *     link: 'https://www.facebook.com/EchoboxHQ/'
 *     name: 'Echobox'
 *     pageId: '396093583788031'
 *     username: 'EchoboxHQ'
 *     verified: false
 *   }]
 * }
 * ```
 *
 * OUTPUT
 *
 * ```
 * {
 *   raw: '@[396093583788031]'
 *   clean: 'Echobox'
 *   more: 'https://www.facebook.com/EchoboxHQ',
 * }
 * ```
 *
 * @param {{
 *  raw: string;
 *  clean: string;
 *  username: string;
 *  apiTypeId: import('types').APITypeId;
 *  results: import('types').Mention[];
 * }}
 */
function createTagDetails({
  raw = mandatory('raw'),
  clean = mandatory('clean'),
  username = mandatory('username'),
  apiTypeId = mandatory('apiTypeId'),
  results = mandatory('results'),
} = {}) {
  let tag;
  if (apiTypeId === API_TYPE_IDS.FACEBOOK) {
    // Facebook
    const resultMatching = results.filter((result) => result.pageId === raw)[0];
    tag = {
      raw: `@[${raw}]`,
      clean,
      more: resultMatching.pageURL,
    };
  } else if (apiTypeId === API_TYPE_IDS.TWITTER) {
    // Twitter
    tag = {
      raw: `@${username}`,
      clean: username,
      more: `https://www.twitter.com/${username}`,
    };
  } else if (apiTypeId === API_TYPE_IDS.INSTAGRAM) {
    // Instagram
    tag = {
      raw: `@${username}`,
      clean: username,
      more: `https://www.instagram.com/${username}`,
    };
  } else if (apiTypeId === API_TYPE_IDS.LINKEDIN) {
    // LinkedIn
    const resultMatching = results.filter((result) => result.pageId === raw)[0];
    const urnType = resultMatching.link?.startsWith(
      'https://www.linkedin.com/showcase/',
    )
      ? 'organizationbrand'
      : 'organization';

    tag = {
      raw: `@[${clean}](urn:li:${urnType}:${raw})`,
      clean,
      more:
        resultMatching.link ??
        `https://www.linkedin.com/organization/${username}`,
    };
  } else if (apiTypeId === API_TYPE_IDS.TIKTOK) {
    // TikTok
    tag = {
      raw: `@${username}`,
      clean: username,
      more: `https://www.tiktok.com/@${username}`,
    };
  }
  return tag;
}

/**
 * getAccountDisconnectedError
 *
 * Error message to display when an account or page belonging to the specified
 * network has been disconnected
 */

function getAccountDisconnectedError({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const canHaveMultipleAccounts =
    API_PROPERTIES[apiTypeId]?.canHaveMultipleAccounts;
  if (canHaveMultipleAccounts) {
    return `One of your ${API_PROPERTIES[apiTypeId].name} accounts isn't connected to Echobox any more. Please reconnect it in the <a href="/settings/pages" class="strong underline text-danger">Settings</a> page.`;
  }
  if (API_PROPERTIES[apiTypeId].name === 'Bitly') {
    return `Your ${API_PROPERTIES[apiTypeId].name} account isn't connected to Echobox any more. Please reconnect it in the <a href="/settings/property" class="strong underline text-danger">Settings</a> page.`;
  }
  return `Your ${API_PROPERTIES[apiTypeId].name} account isn't connected to Echobox any more. Please reconnect it in the <a href="/settings/pages" class="strong underline text-danger">Settings</a> page.`;
}

/**
 * getExcludeImagesRule
 */

function getExcludeImagesRule({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return API_PROPERTIES[apiTypeId].excludeImagesRule;
}

/**
 * Gets the settings related to the first comment for the respective api type.
 * @param {{
 *   apiTypeId: number;
 * }}
 * @returns {{
 *   maximumLength: number | null;
 * } | undefined}
 */
function getFirstCommentSettings({ apiTypeId = mandatory('apiTypeId') }) {
  return API_PROPERTIES[apiTypeId]?.firstCommentSettings;
}

/**
 * Gets the image aspect ratios for a given API type and post
 * @param {{
 *  apiTypeId: number;
 *  postType: import('types').PostType;
 *  socialChannel?: import('types').SocialChannel;
 * }}
 * @returns {{
 *  min: number | null;
 *  max: number | null;
 * }}
 */

function getImageAspectRatios({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel,
} = {}) {
  const getter = validators[apiTypeId]?.getImageAspectRatios;

  if (getter) {
    return getter({ postType, socialChannel });
  }

  return (
    API_PROPERTIES[apiTypeId]?.imageAspectRatios?.[postType] ?? {
      min: null,
      max: null,
    }
  );
}

/**
 * Gets the network specific validation messages if they are defined.
 * @param {{
 *  apiTypeId: number;
 *  validationType: string;
 *  socialChannel?: import('types').SocialChannel;
 * }}
 * @returns {string | null}
 */
function getValidationMessages({
  apiTypeId = mandatory('apiTypeId'),
  validationType = mandatory('validationType'),
  socialChannel,
} = {}) {
  const validationMessages =
    API_PROPERTIES[apiTypeId]?.validationMessages?.[validationType];

  if (typeof validationMessages === 'object') {
    if (socialChannel) {
      return validationMessages[socialChannel];
    }
    return null;
  }

  return validationMessages ?? null;
}

/**
 * getImageQuantityLimits
 * @param {{
 *  apiTypeId: number;
 *  postType: string;
 *  imageType?: string;
 *  socialChannel?: import('types').SocialChannel;
 * }}
 */

function getImageQuantityLimits({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  imageType = null,
  socialChannel,
} = {}) {
  const getter = validators[apiTypeId]?.getImageQuantityLimits;
  if (getter) {
    return getter({ postType, socialChannel });
  }

  // Starting defaults
  let limits = API_PROPERTIES[apiTypeId].imageQuantityLimits.defaults;

  // Override defaults based on post type
  if (
    API_PROPERTIES[apiTypeId]?.imageQuantityLimits?.postTypeSpecific?.[postType]
  ) {
    limits = {
      ...limits,
      ...API_PROPERTIES[apiTypeId].imageQuantityLimits.postTypeSpecific[
        postType
      ],
    };
  }

  // Override defaults based on image type
  if (
    imageType &&
    API_PROPERTIES[apiTypeId]?.imageQuantityLimits?.imageTypeSpecific?.[
      imageType
    ]
  ) {
    limits = {
      ...limits,
      ...API_PROPERTIES[apiTypeId].imageQuantityLimits.imageTypeSpecific[
        imageType
      ],
    };
  }

  // Done
  return limits;
}

/**
 * Determine the maximum image file size allowed
 * for the given combination of social network and post type
 */

function getMediaSizeLimit({
  apiTypeId = mandatory('apiTypeId'),
  socialChannel = null,
  postType = null,
  imageType = null,
} = {}) {
  const getter = validators[apiTypeId]?.getMediaSizeLimits;
  if (getter) {
    return getter({ socialChannel, postType });
  }

  // Starting defaults
  let limits = API_PROPERTIES[apiTypeId].imageSizeLimits.default ?? {};

  // Override defaults based on post type
  if (postType) {
    const postTypeSpecificLimit =
      API_PROPERTIES[apiTypeId]?.imageSizeLimits?.postTypeSpecific?.[postType];

    if (postTypeSpecificLimit) {
      limits = postTypeSpecificLimit;
    }
  }

  // Override defaults based on image type
  if (imageType) {
    const imageTypeSpecificLimit =
      API_PROPERTIES[apiTypeId]?.imageSizeLimits?.imageTypeSpecific?.[
        imageType
      ];

    if (imageTypeSpecificLimit) {
      limits = imageTypeSpecificLimit;
    }
  }

  // Done
  return limits;
}

/**
 * @param {{
 *  apiTypeId: number;
 *  postType: import('types').PostType;
 *  socialChannel?: import('types').SocialChannel;
 * }}
 */

function getImageTransformOptions({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel,
} = {}) {
  // Find out what aspect ratio limits (if any) exist
  const aspectRatios = getImageAspectRatios({
    apiTypeId,
    postType,
    socialChannel,
  });

  // If no limits are defined just return a default set of options
  if (isNull(aspectRatios.min) && isNull(aspectRatios.max)) {
    return {
      categories: [
        {
          identifier: 'imgly_transforms_common',
          items: [
            { identifier: 'imgly_transform_common_custom' },
            { identifier: 'imgly_transform_common_square' },
            { identifier: 'imgly_transform_common_4' },
            { identifier: 'imgly_transform_common_16' },
          ],
        },
      ],
    };
  }

  const hasCommonSquare =
    apiTypeId === API_TYPE_IDS.INSTAGRAM && postType === POST_TYPES.STATUS;
  const networkName = getSocialNetworkName({ apiTypeId });
  const postTypeLabel = getPostTypeLabel({
    apiTypeId,
    postType,
    socialChannel,
  });

  // If only one aspect ratio is available, just offer that one option
  if (aspectRatios.min === aspectRatios.max) {
    const ratioIdentifier = `${networkName.toLowerCase()}_${postTypeLabel.toLowerCase()}`;
    // Return ratio in group named after social network
    const transformOptions = {
      categories: [
        {
          identifier: networkName.toLowerCase(),
          name: networkName,
          items: [
            {
              identifier: ratioIdentifier,
              name: postTypeLabel,
              ratio: aspectRatios.min,
              thumbnailURI: `${ratioIdentifier}.png`,
            },
          ],
        },
      ],
      replaceCategories: true,
    };
    if (hasCommonSquare) {
      transformOptions.categories[0].items.push({
        identifier: 'imgly_transform_common_square',
        name: 'Square',
        ratio: 1,
      });
    }
    return transformOptions;
  }

  // If a range of ratios is available, then offer both extremes as options
  // plus the preset "custom" option
  const ratioIdentifierMin = `${networkName.toLowerCase()}_${postTypeLabel.toLowerCase()}_min`;
  const ratioIdentifierMax = `${networkName.toLowerCase()}_${postTypeLabel.toLowerCase()}_max`;
  const ratioNameMin = `${postTypeLabel} Portrait`;
  const ratioNameMax = `${postTypeLabel} Landscape`;

  // Return custom ratios in their own category
  const transformOptions = {
    availableRatios: [
      'imgly_transform_common_custom',
      ratioIdentifierMin,
      ratioIdentifierMax,
    ],
    categories: [
      {
        identifier: networkName.toLowerCase(),
        name: networkName,
        items: [
          {
            identifier: 'imgly_transform_common_custom',
          },
          {
            identifier: ratioIdentifierMin,
            name: ratioNameMin,
            ratio: aspectRatios.min,
            thumbnailURI: `${ratioIdentifierMin}.png`,
          },
          {
            identifier: ratioIdentifierMax,
            name: ratioNameMax,
            ratio: aspectRatios.max,
            thumbnailURI: `${ratioIdentifierMax}.png`,
          },
        ],
      },
    ],
    replaceCategories: false,
  };
  if (hasCommonSquare) {
    transformOptions.categories[0].items.push({
      identifier: 'imgly_transform_common_square',
      name: 'Square',
      ratio: 1,
    });
  }
  return transformOptions;
}

/**
 * getImageUploadCaption
 *
 * Caption for the image upload button when editing a post
 *  @param {{
 *  postType: string;
 *  hasVideoFile: boolean;
 *  hasVideoThumbnail: boolean;
 * }}
 *
 */

function getImageUploadCaption({
  postType = mandatory('postType'),
  hasVideoFile = mandatory('hasVideoFile'),
  hasVideoThumbnail = mandatory('hasVideoThumbnail'),
} = {}) {
  let caption = 'Upload';

  if (postType === POST_TYPES.VIDEO) {
    if (!hasVideoFile) {
      caption = 'Upload video';
    } else if (!hasVideoThumbnail) {
      caption = 'Upload thumbnail';
    } else {
      caption = 'Change thumbnail';
    }
  }

  return caption;
}

/**
 * getImageUploadTooltip
 *
 * Tooltip for the image upload button when editing a post
 *  @param {{
 *  apiTypeId: number;
 *  postType: string;
 *  hasVideoFile: boolean;
 *  hasVideoThumbnail: boolean;
 * }}
 */

function getImageUploadTooltip({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  hasVideoFile = mandatory('hasVideoFile'),
  hasVideoThumbnail = mandatory('hasVideoThumbnail'),
} = {}) {
  let caption = '';

  if (postType === POST_TYPES.VIDEO) {
    if (hasVideoFile) {
      if (!hasVideoThumbnail) {
        caption = 'Upload video thumbnail';
      } else {
        caption = 'Change video thumbnail';
      }
    }
  } else {
    if (apiTypeId === API_TYPE_IDS.FACEBOOK && postType === POST_TYPES.LINK) {
      caption = 'Change image';
    } else {
      caption = 'Add image';
    }
  }

  return caption;
}

/**
 * getMaximumUrlLength
 * @param{{ apiTypeId: number }}
 * @returns{number|null}
 */

function getMaximumUrlLength({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (apiTypeId === API_TYPE_IDS.INSTAGRAM && !isIGCabinetEnabled()) {
    return null;
  }

  return API_PROPERTIES[apiTypeId]?.maximumUrlLength ?? null;
}

/**
 * getMaximumUrlByteLength
 * @param{{ apiTypeId: number }}
 * @returns{number|null}
 */

function getMaximumUrlByteLength({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (apiTypeId === API_TYPE_IDS.INSTAGRAM && !isIGCabinetEnabled()) {
    return null;
  }

  return API_PROPERTIES?.[apiTypeId]?.maximumUrlByteLength || null;
}

/**
 * getMessageSettings
 * @param {{
 *  apiTypeId: number
 * }}
 */
function getMessageSettings({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].messageSettings)
  ) {
    return API_PROPERTIES[apiTypeId].messageSettings;
  }
  return {
    maximumLength: null,
    urlLength: null,
    minimumLength: null,
  };
}

/**
 * getNewShareOptions
 */

function getNewShareOptions({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].newShareOptions)
  ) {
    return API_PROPERTIES[apiTypeId].newShareOptions;
  }
  return null;
}

/**
 * getDataSourceDefault
 *
 * Returns the specified datasource default for the given field and reason
 * (where reasons are "missing" if the entire datasource defaults setting is missing,
 * and "empty" if the setting exists but the section for the field is missing)
 */

function getDataSourceDefault({
  apiTypeId = mandatory('apiTypeId'),
  reason = mandatory('reason'),
  field = mandatory('field'),
} = {}) {
  if (hasDataSourceDefault({ apiTypeId, reason, field })) {
    return API_PROPERTIES[apiTypeId].dataSourceDefaults[reason][field];
  }
  return null;
}

/**
 * getEmojiSet
 */

function getEmojiSet({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].emojiSet)
  ) {
    return API_PROPERTIES[apiTypeId].emojiSet;
  }
  return 'emojione';
}

/**
 * getPostTypeLabel
 * @param{{
 * apiTypeId: number;
 * postType: string;
 * socialChannel?: string;
 * }}
 * Returns the name to use for the given post type
 */

function getPostTypeLabel({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel = SOCIAL_CHANNELS.FEED,
} = {}) {
  if (isIGCabinetEnabled() && apiTypeId === API_TYPE_IDS.INSTAGRAM) {
    if (postType === POST_TYPES.LINK) {
      return 'Story';
    }
    if (postType === POST_TYPES.STATUS) {
      return 'Photo';
    }
  }

  const socialChannelLabel =
    API_PROPERTIES?.[apiTypeId]?.postTypes?.[postType]?.[socialChannel];

  if (socialChannelLabel) {
    return socialChannelLabel;
  }

  const postTypeLabel = API_PROPERTIES?.[apiTypeId]?.postTypes?.[postType];

  if (postTypeLabel) {
    return postTypeLabel;
  }

  return '';
}

/**
 * @param {{
 *  message: string;
 *  apiTypeId: number;
 * }}
 * @returns {RegExpMatchArray}
 */
function getRawMentionsFromMessage({
  message = mandatory('message'),
  apiTypeId = mandatory('apiTypeId'),
}) {
  if (apiTypeId === API_TYPE_IDS.FACEBOOK) {
    return message.match(/(@\[[\p{L}\d_]+\])/gu) ?? [];
  }
  if (apiTypeId === API_TYPE_IDS.TWITTER) {
    return message.match(/(@[\p{L}\d_]+)/gu) ?? [];
  }
  if (apiTypeId === API_TYPE_IDS.INSTAGRAM) {
    return message.match(/(@[\p{L}\d_]+)/gu) ?? [];
  }
  if (apiTypeId === API_TYPE_IDS.LINKEDIN) {
    return (
      message.match(
        /(@\[[\p{L}\d\s]+-urn:li:organization:[\p{L}\d_]+\])|(@\[[\p{L}\d\s]+\]\(urn:li:organization:[\p{L}\d_]+\))/gu,
      ) ?? []
    );
  }
  if (apiTypeId === API_TYPE_IDS.TIKTOK) {
    return message.match(/(@[\p{L}\d_]+)/gu) ?? [];
  }

  return [];
}

/**
 * getShareIcon
 *
 * Returns the location of the icon to share
 */

function getShareIcon({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
} = {}) {
  return (
    (API_PROPERTIES[apiTypeId]?.shareIcon &&
      API_PROPERTIES[apiTypeId]?.shareIcon[postType]) ||
    ''
  );
}

/**
 * getShorteningDisabledSetting
 *
 * Returns the "is link shortening disabled" setting for the given social network,
 * or an appropriate default value if no setting exists
 */

function getShorteningDisabledSetting({
  propertyId = mandatory('propertyId'),
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  if (apiTypeId === API_TYPE_IDS.FACEBOOK) {
    return getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.FACEBOOK_UNSHORTENED_SHARING,
      propertyId,
    });
  }
  if (apiTypeId === API_TYPE_IDS.TWITTER) {
    return getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.TWITTER_UNSHORTENED_SHARING,
      propertyId,
    });
  }
  if (apiTypeId === API_TYPE_IDS.LINKEDIN) {
    return getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.LINKEDIN_UNSHORTENED_SHARING,
      propertyId,
    });
  }
  if (apiTypeId === API_TYPE_IDS.INSTAGRAM) {
    return getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.INSTAGRAM_UNSHORTENED_SHARING,
      propertyId,
    });
  }
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].isLinkShorteningDisabled)
  ) {
    return [
      {
        propertySettingTypeId: 0,
        enabled: API_PROPERTIES[apiTypeId].isLinkShorteningDisabled,
      },
    ];
  }
  return [
    {
      propertySettingTypeId: 0,
      enabled: false,
    },
  ];
}

/**
 * getSocialNetworkDefaultPageIcon
 * @param {{ apiTypeId: number; }} args
 * @returns {string}
 */

function getSocialNetworkDefaultPageIcon({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].defaultPageIcon)
  ) {
    return API_PROPERTIES[apiTypeId].defaultPageIcon;
  }
  return '';
}

/**
 * getSocialNetworkDescriptionSources
 * @param {{apiTypeId: number}}
 */

function getSocialNetworkDescriptionSources({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const messageSources = cloneObject(
    getSocialNetworkMessageSources({ apiTypeId }),
  );
  if (
    isUndefined(API_PROPERTIES[apiTypeId]) ||
    isUndefined(API_PROPERTIES[apiTypeId].descriptionSourceExclusions)
  ) {
    return messageSources;
  }
  API_PROPERTIES[apiTypeId].descriptionSourceExclusions.forEach(
    (descriptionSourceExclusions) =>
      delete messageSources[descriptionSourceExclusions],
  );
  return messageSources;
}

/**
 * getSocialNetworkEntityName
 *
 * Returns the name of an entity within a particular social network
 * e.g. "page" or "account"
 */

function getSocialNetworkEntityName({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].entityName)
  ) {
    return API_PROPERTIES[apiTypeId].entityName;
  }
  return '';
}

/**
 * getSocialNetworkIcon
 * @param {{ apiTypeId: number; }} args
 * @returns {string}
 */

function getSocialNetworkIcon({ apiTypeId = mandatory('apiTypeId') } = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].icon)
  ) {
    return API_PROPERTIES[apiTypeId].icon;
  }
  return '';
}

/**
 * @param apiTypeId: import('types').APITypeId;
 */
const getSocialNetWorkIconByAPITypeId = (apiTypeId) => {
  const logoDetailsMap = {
    [API_TYPE_IDS.FACEBOOK]: SocialFacebookIcon,
    [API_TYPE_IDS.TWITTER]: SocialTwitterIcon,
    [API_TYPE_IDS.LINKEDIN]: SocialLinkedinIcon,
    [API_TYPE_IDS.INSTAGRAM]: SocialInstagramIcon,
    [API_TYPE_IDS.TIKTOK]: SocialTiktokIcon,
  };

  if (!(apiTypeId in logoDetailsMap)) {
    throw new Error('Unsupported Social Network type');
  }

  return logoDetailsMap[apiTypeId];
};

/**
 * getSocialNetworkImageSources
 * @param {{apiTypeId: number}}
 */

function getSocialNetworkImageSources({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const { imageSources } = API_PROPERTIES[apiTypeId];
  if (imageSources) {
    return imageSources;
  }
  return {};
}

/**
 * @param {{apiTypeId: number}}
 * @returns {string}
 */
function getSocialNetworkMessageBoxPlaceholder({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const { messageBoxPlaceholder } = API_PROPERTIES[apiTypeId];
  if (messageBoxPlaceholder) {
    return messageBoxPlaceholder;
  }
  return 'Write your share message...';
}

/**
 * getSocialNetworkMessageSources
 * @param {{apiTypeId: number}}
 */

function getSocialNetworkMessageSources({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const { messageSources } = API_PROPERTIES[apiTypeId];
  if (!messageSources) {
    return {};
  }
  const isOpenAIEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.OPEN_AI_MESSAGE_ENABLED,
    propertyId: getCurrentPropertyId(),
  });
  if (!isOpenAIEnabled) {
    messageSources.OPTIMAL_MESSAGE = 'Article Quote';
  }
  return messageSources;
}

/**
 * getAutofeedMessageSources
 * @param {{apiTypeId: number}}
 * @returns {{}}
 */

function getAutofeedMessageSources({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const { autofeedMessageSources } = API_PROPERTIES[apiTypeId];
  if (!autofeedMessageSources) {
    return {};
  }
  if (
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.OPEN_AI_MESSAGE_ENABLED,
      propertyId: getCurrentPropertyId(),
    })
  ) {
    const autofeedMessageSourcesWithInspireMe = {
      OPTIMAL_MESSAGE: 'ChatGPT',
      ...autofeedMessageSources,
    };
    return autofeedMessageSourcesWithInspireMe;
  }
  return autofeedMessageSources;
}

/**
 * getSocialNetworkName
 * @param {{
 *   apiTypeId: number | string;
 *   textCase?: string;
 * }} args
 * @returns the name of the social network from its API type id
 * Optionally changes to lower or upper case
 */

function getSocialNetworkName({
  apiTypeId = mandatory('apiTypeId'),
  textCase = TEXT_CASES.CAMEL,
} = {}) {
  if (isUndefined(API_PROPERTIES[apiTypeId])) {
    return '';
  }
  let networkName = API_PROPERTIES[apiTypeId].name;
  switch (textCase) {
    case TEXT_CASES.LOWER:
      networkName = networkName.toLowerCase();
      break;
    case TEXT_CASES.UPPER:
      networkName = networkName.toUpperCase();
      break;
    default:
  }
  return networkName;
}

/**
 * getSocialNetworks
 *
 * Returns a list of apiTypeIds representing all social networks
 */

function getSocialNetworks({ isOnSetupPage = false } = {}) {
  const socialNetworks = [];
  Object.values(API_TYPE_IDS).forEach((apiTypeId) => {
    if (
      API_PROPERTIES[apiTypeId]?.isSocialNetwork &&
      (!isOnSetupPage || API_PROPERTIES[apiTypeId]?.showOnSetupPage)
    ) {
      socialNetworks.push(apiTypeId);
    }
  });
  return socialNetworks;
}

/**
 * getSocialNetworkTagPattern
 * @param {{
 *  apiTypeId: number;
 *  tagType: import('types').TagType
 * }} args
 */

function getSocialNetworkTagPattern({
  apiTypeId = mandatory('apiTypeId'),
  tagType = mandatory('tagType'),
} = {}) {
  if (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].tagPatterns) &&
    isDefined(API_PROPERTIES[apiTypeId].tagPatterns[tagType])
  ) {
    return API_PROPERTIES[apiTypeId].tagPatterns[tagType];
  }
  return '';
}

/**
 * getSocialNetworkTitleSources
 * @param {{apiTypeId: number}}
 */

function getSocialNetworkTitleSources({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  const messageSources = cloneObject(
    getSocialNetworkMessageSources({ apiTypeId }),
  );
  if (
    isUndefined(API_PROPERTIES[apiTypeId]) ||
    isUndefined(API_PROPERTIES[apiTypeId].titleSourceExclusions)
  ) {
    return messageSources;
  }
  API_PROPERTIES[apiTypeId].titleSourceExclusions.forEach(
    (titleSourceExclusions) => delete messageSources[titleSourceExclusions],
  );
  return messageSources;
}

/**
 * getStartingPage
 *
 * Returns the most popular social page based on the number of followers
 * (or the first alphabetically if no follower counts are available)
 */

function getStartingPage({ accountAPIs = mandatory('accountAPIs') } = {}) {
  if (isUndefined(accountAPIs.accountAPIs)) {
    return undefined;
  }

  const activeFacebookAPIs = Object.keys(accountAPIs.accountAPIs)
    .map((key) => accountAPIs.accountAPIs[key])
    .filter(
      (api) =>
        api.apiStateId === API_STATES.ACTIVE &&
        api.apiTypeId === API_TYPE_IDS.FACEBOOK,
    );
  const activeTwitterAPIs = Object.keys(accountAPIs.accountAPIs)
    .map((key) => accountAPIs.accountAPIs[key])
    .filter(
      (api) =>
        api.apiStateId === API_STATES.ACTIVE &&
        api.apiTypeId === API_TYPE_IDS.TWITTER,
    );
  const activeInstagramAPIs = Object.keys(accountAPIs.accountAPIs)
    .map((key) => accountAPIs.accountAPIs[key])
    .filter(
      (api) =>
        api.apiStateId === API_STATES.ACTIVE &&
        api.apiTypeId === API_TYPE_IDS.INSTAGRAM,
    );
  const activeLinkedInAPIs = Object.keys(accountAPIs.accountAPIs)
    .map((key) => accountAPIs.accountAPIs[key])
    .filter(
      (api) =>
        api.apiStateId === API_STATES.ACTIVE &&
        api.apiTypeId === API_TYPE_IDS.LINKEDIN,
    );

  if (activeFacebookAPIs.length !== 0) {
    return activeFacebookAPIs.sort(sortPages)[0].accountAPIId;
  }

  if (activeTwitterAPIs.length !== 0) {
    return activeTwitterAPIs.sort(sortPages)[0].accountAPIId;
  }

  if (activeInstagramAPIs.length !== 0) {
    return activeInstagramAPIs.sort(sortPages)[0].accountAPIId;
  }

  if (activeLinkedInAPIs.length !== 0) {
    return activeLinkedInAPIs.sort(sortPages)[0].accountAPIId;
  }

  return undefined;
}

/**
 * Method: getTagDetails
 *
 * -------------------------------------------------------------------------------------------------
 *
 * Derives tag details (clean / raw / more) from an Echobox-encoded tag
 *
 * Example:
 *
 * INPUTS
 *
 *   tag: '#FB[HASHTAG]'
 *   apiTypeId: API_TYPE_IDS.FACEBOOK
 *   tagTypeId: TAG_TYPES.HASHTAG
 *
 * OUTPUT
 *
 *   {
 *     raw: '#HASHTAG',
 *     clean: '#HASHTAG',
 *     more: 'https://www.facebook.com/hashtag/HASHTAG',
 *   }
 *
 * @param {{
 *  tag: string;
 *  apiTypeId: number;
 *  tagTypeId: import('types').TagType
 * }}
 */

function getTagDetails({
  tag = mandatory('tag'),
  apiTypeId = mandatory('apiTypeId'),
  tagTypeId = mandatory('tagTypeId'),
} = {}) {
  const extracted = tag.replace(
    getSocialNetworkTagPattern({ apiTypeId, tagType: tagTypeId }),
    '$1',
  );
  switch (parseInt(apiTypeId, 10)) {
    case API_TYPE_IDS.FACEBOOK:
      switch (tagTypeId) {
        case TAG_TYPES.HASHTAG:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.facebook.com/hashtag/${extracted}`,
          };
        case TAG_TYPES.MENTION:
          return {
            raw: tag,
            clean: '',
            more: '',
          };
        default:
          return {};
      }
    case API_TYPE_IDS.TWITTER:
      switch (tagTypeId) {
        case TAG_TYPES.HASHTAG:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.twitter.com/hashtag/${extracted}`,
          };
        case TAG_TYPES.MENTION:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.twitter.com/${extracted}`,
          };
        default:
          return {};
      }
    case API_TYPE_IDS.INSTAGRAM:
      switch (tagTypeId) {
        case TAG_TYPES.HASHTAG:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.instagram.com/explore/tags/${extracted}`,
          };
        case TAG_TYPES.MENTION:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.instagram.com/${extracted}`,
          };
        default:
          return {};
      }
    case API_TYPE_IDS.LINKEDIN: {
      // LinkedIn tags have 2 possible formats, so we try to use the first one
      // but fallback to the second if it is undefined.
      const linkedInExtracted = tag.replace(
        getSocialNetworkTagPattern({ apiTypeId, tagType: tagTypeId }),
        (match, p1, p2) => p1 ?? p2,
      );

      switch (tagTypeId) {
        case TAG_TYPES.HASHTAG:
          return {
            raw: tag,
            clean: `#${linkedInExtracted}`,
            more: `https://www.linkedin.com/feed/hashtag/?keywords=${linkedInExtracted}`,
          };
        case TAG_TYPES.MENTION: {
          // We replace "](" with "-" so that the string is in the same format
          // regardless of which format matches
          const [usernameWithSuffix, , urnType] = linkedInExtracted
            .replace('](', '-')
            .split(':');
          const username = usernameWithSuffix.slice(0, -4);
          return {
            raw: tag,
            clean: username,
            more: `https://www.linkedin.com/${
              urnType === 'organizationbrand' ? 'showcase' : 'organization'
            }/${username}`,
          };
        }
        default:
          return {};
      }
    }
    case API_TYPE_IDS.TIKTOK:
      switch (tagTypeId) {
        case TAG_TYPES.HASHTAG:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.tiktok.com/tag/${tag}`,
          };
        case TAG_TYPES.MENTION:
          return {
            raw: tag,
            clean: tag,
            more: `https://www.tiktok.com/@${extracted}`,
          };
        default:
          return {};
      }
    default:
      return {};
  }
}

/**
 * Gets the dimensions limits for the post type and specified social network.
 *
 * @param {{
 *  apiTypeId: number;
 *  postType: import('types').PostType;
 *  socialChannel: import('types').SocialChannel;
 * }}
 * @returns {{
 *  width?: { min?: number; max?: number };
 *  height?: { min?: number; max?: number };
 * }}
 */
function getDimensionLimits({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel,
}) {
  const limits = API_PROPERTIES?.[apiTypeId]?.dimensionLimits?.[postType] ?? {};

  if (socialChannel in limits) {
    return limits[socialChannel];
  }

  return limits;
}

/**
 * Gets the video size limits for the specified social network.
 * @param {{
 *  apiTypeId: number;
 *  postType: import('types').PostType;
 *  socialChannel?: import('types').SocialChannel;
 * }}
 * @returns {{ min: number; max: number; } | null | undefined}
 */
function getVideoDurationLimits({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel,
} = {}) {
  const getter = validators?.[apiTypeId]?.getVideoDurationLimits;
  if (getter) {
    return getter({ postType, socialChannel });
  }

  const limits =
    API_PROPERTIES?.[apiTypeId]?.videoDurationLimits?.[postType] ?? {};

  return limits;
}

/**
 * hasABVariations
 *
 * Does the social network support AB variations?
 * True for Facebook, false for all other social networks
 * @param {{ apiTypeId: number; socialChannel?: import('types').SocialChannel; }}
 * @returns {boolean}
 */

function hasABVariations({
  apiTypeId = mandatory('apiTypeId'),
  socialChannel,
} = {}) {
  const apiTypeABVariations = API_PROPERTIES[apiTypeId]?.hasABVariations;
  if (typeof apiTypeABVariations === 'object') {
    return apiTypeABVariations[socialChannel];
  }
  return !!apiTypeABVariations;
}

/**
 * hasAudienceRestriction
 *
 * Does the social network support audience restrictions?
 * True for Facebook, false for all other social networks
 */

function hasAudienceRestriction({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasAudienceRestriction' });
}

/**
 * hasCharacterCount
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */
function hasCharacterCount({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasCharacterCount' });
}

/**
 * hasDefaultDataSource
 * @param {Object} options
 * @param {number} [options.apiTypeId]
 * @param {string} [options.reason]
 * @param {string} [options.field]
 */

function hasDataSourceDefault({
  apiTypeId = mandatory('mandatory'),
  reason = mandatory('reason'),
  field = mandatory('field'),
} = {}) {
  return (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].dataSourceDefaults) &&
    isDefined(API_PROPERTIES[apiTypeId].dataSourceDefaults[reason]) &&
    isDefined(API_PROPERTIES[apiTypeId].dataSourceDefaults[reason][field])
  );
}

/**
 * hasDescriptionField
 * @param {{apiTypeId: number}}
 * @returns {boolean}
 */

function hasDescriptionField({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasDescriptionField' });
}

/**
 * hasDomainField
 * @param {{ apiTypeId: number }}
 */

function hasDomainField({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasDomainField' });
}

/**
 * hasHashtagsMentions
 *
 * Does the social network support hashtags and mentions?
 * True for Facebook and Twitter, false for all other networks
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */

function hasHashtagsMentions({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasHashtagsMentions' });
}

/**
 * hasImageOverlays
 *
 * Does the social network support image overlays?
 * True for Facebook and false for all other networks
 * @param {{ apiTypeId: number; }}
 * @returns {boolean}
 */
function hasImageOverlays({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasImageOverlays' });
}

/**
 * hasLinkPlaceholder
 *
 * Do share messages include a link placeholder?
 * True for Twitter, false for all other networks
 *
 * @param {{ apiTypeId: number }}
 */

function hasLinkPlaceholder({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasLinkPlaceholder' });
}

/**
 * hasMentionsLookups
 *
 * Does the social network required an additional lookup step for mentions?
 * True for Facebook, false for all other networks
 * @param {{ apiTypeId: number }} args
 * @returns {boolean}
 */

function hasMentionsLookups({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasMentionsLookups' });
}

/**
 * hasMessageBeforeImage
 *
 * Should the message field be displayed before the image when editing posts?
 * True for all networks other than Instagram
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */

function hasMessageBeforeImage({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasMessageBeforeImage' });
}

/**
 * hasMessageField
 * @param {{
 * apiTypeId: number;
 * postType?: string;
 * socialChannel?: import('types').SocialChannel;
 * }}
 */

function hasMessageField({
  apiTypeId = mandatory('apiTypeId'),
  postType,
  socialChannel,
} = {}) {
  if (isDefined(postType)) {
    const hasField = hasPerPostTypeKey({
      apiTypeId,
      postType,
      key: 'hasMessageField',
    });

    if (typeof hasField === 'object') {
      return hasField[socialChannel];
    }

    return hasField;
  }
  if (typeof API_PROPERTIES[apiTypeId]?.hasMessageField === 'boolean') {
    return API_PROPERTIES[apiTypeId].hasMessageField;
  }
  return (
    API_PROPERTIES[apiTypeId]?.hasMessageField &&
    Object.keys(API_PROPERTIES[apiTypeId].hasMessageField).some(
      (thisType) => API_PROPERTIES[apiTypeId].hasMessageField[thisType],
    )
  );
}

/**
 * hasPostType
 * @param {{
 * apiTypeId: number;
 * postType?: string;
 * }}
 */

function hasPostType({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
} = {}) {
  return (
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].postTypes) &&
    isDefined(API_PROPERTIES[apiTypeId].postTypes[postType])
  );
}

/**
 *
 * Does the social network have (potentially) multiple share messages for posts?
 * True for Facebook and Twitter, false for Instagram
 *
 * hasShareMessages
 * @param {{
 * apiTypeId: number;
 * }}
 */

function hasShareMessages({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasShareMessages' });
}

/**
 * hasSponsoredPosts
 *
 * Does the social network have sponsored posts?
 * True for Facebook, false for all other networks
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */

function hasSponsoredPosts({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasSponsoredPosts' });
}

/**
 * hasTitleField
 * @param {{ apiTypeId: number; postType?: import('types').PostType }}
 * @returns {boolean}
 */

function hasTitleField({ apiTypeId = mandatory('apiTypeId'), postType } = {}) {
  if (typeof API_PROPERTIES[apiTypeId]?.hasTitleField === 'boolean') {
    return API_PROPERTIES[apiTypeId].hasTitleField;
  }
  if (
    isDefined(postType) &&
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].hasTitleField) &&
    isDefined(API_PROPERTIES[apiTypeId].hasTitleField[postType])
  ) {
    return API_PROPERTIES[apiTypeId].hasTitleField[postType];
  }
  if (
    isUndefined(postType) &&
    isDefined(API_PROPERTIES[apiTypeId]) &&
    isDefined(API_PROPERTIES[apiTypeId].hasTitleField)
  ) {
    return Object.keys(API_PROPERTIES[apiTypeId].hasTitleField).some(
      (thisType) => API_PROPERTIES[apiTypeId].hasTitleField[thisType],
    );
  }
  return false;
}

/**
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */
function hasTitleFieldCharacterCount({
  apiTypeId = mandatory('apiTypeId'),
} = {}) {
  if (
    typeof API_PROPERTIES[apiTypeId]?.hasTitleFieldCharacterCount === 'boolean'
  ) {
    return API_PROPERTIES[apiTypeId].hasTitleFieldCharacterCount;
  }
  return false;
}

/**
 * hasVerifiedAccounts
 * @param {{ apiTypeId: number }}
 * @returns {boolean}
 */

function hasVerifiedAccounts({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'hasVerifiedAccounts' });
}

/**
 * @param {{ apiTypeId: import('types').APITypeId, socialChannel?: import('types').SocialChannel }}
 */
function hasVideoTitle({
  apiTypeId = mandatory('apiTypeId'),
  socialChannel,
} = {}) {
  const hasTitle = hasKey({ apiTypeId, key: 'hasVideoTitle' });
  if (typeof hasTitle === 'object') {
    return hasTitle[socialChannel];
  }
  return hasTitle;
}

/**
 * includeMentionsAtSymbol
 *
 * Does the social network include the '@' symbol when a tag is inserted?
 * True for Instagram, Tiktok, and Twitter, false for all other networks
 * @param {{apiTypeId: number}} args
 * @returns {boolean}
 */

function includeMentionsAtSymbol({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return hasKey({ apiTypeId, key: 'appendMentionsAtSymbol' });
}

/**
 * Checks if the aspect ratio is valid for the social network.
 *
 * @param {{
 *  apiTypeId: import('types').APITypeId;
 *  postType: import('types').PostType;
 *  imageDimensions: {
 *    width: number;
 *    height: number;
 *  };
 *  socialChannel?: import('types').SocialChannel;
 * }}
 *
 * @returns {boolean}
 */

function isAspectRatioValid({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  imageDimensions = mandatory('imageDimensions'),
  socialChannel,
} = {}) {
  const aspectRatioLimits = getImageAspectRatios({
    apiTypeId,
    postType,
    socialChannel,
  });

  // Override for Instagram cabinet which allows videos to be posted with any aspect ratio
  // Remove when Instagram cabinet is deprecated
  if (
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    postType === POST_TYPES.VIDEO &&
    isIGCabinetEnabled()
  ) {
    return true;
  }

  if (aspectRatioLimits?.min && aspectRatioLimits?.max) {
    return isWithinAspectRatio({
      imageDimensions,
      aspectRatioLimits,
    });
  }

  // No limits exist so assume the image can be any size
  return true;
}

/**
 * isSocialNetwork
 *
 * True if the network in question is a social network (e.g. Facebook)
 * and false otherwise (e.g. Google Analytics)
 * @param {{ apiTypeId: number }} args
 */

function isSocialNetwork({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return API_PROPERTIES[apiTypeId]?.isSocialNetwork ?? false;
}

/**
 * @param {{ apiTypeId: number }}
 * @returns { boolean }
 */
function isTitleFieldRequired({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return API_PROPERTIES[apiTypeId]?.isTitleFieldRequired ?? false;
}

/**
 * @param {{ apiTypeId: number }}
 * @returns { boolean }
 */
function isMessageFieldRequired({ apiTypeId = mandatory('apiTypeId') } = {}) {
  return API_PROPERTIES[apiTypeId]?.isMessageFieldRequired ?? true;
}

/**
 * Checks if images must be in the correct aspect ratio (or range of ratios)
 * before a post can be submitted.
 * @param {{
 *  apiTypeId: import('types').APITypeId;
 *  postType: import('types').PostType
 * }}
 * @returns {boolean} True if images must be in the correct aspect ratio,
 * false if any necessary cropping will be handled automatically by the
 * social network itself after posting.
 */
function isValidAspectRatioMandatory({
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
} = {}) {
  return hasPerPostTypeKey({
    apiTypeId,
    postType,
    key: 'isValidAspectRatioMandatory',
  });
}
