import { getCurrentPropertyId } from './accountAPIs';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from './featureFlags';

const COMPETITOR_ANALYTICS_PROPERTIES = [
  102, 12473, 11581, 13983, 10860, 11888, 14108, 11220, 12968, 12965, 12387,
  10880, 14111, 14238, 10538, 11626, 9917, 10123, 14376, 13503, 13454, 10581,
  10638, 13723, 13613, 517, 11449, 13386, 11669, 12861, 14469, 10840, 728,
  13880, 11584, 14354, 10616, 13246, 13066, 10643, 361, 13291, 14424, 10547,
  479, 438, 823, 10739, 11767, 11762, 10432, 10694, 14322, 13892, 11737, 10662,
  13137, 13127, 10914, 11345, 10926, 10740, 14197, 12427, 14201, 11450, 14121,
  10615, 11812, 10842, 10286, 8085, 14125, 11554, 10932, 10099, 11869, 10031,
  10032, 11965, 13879, 13890, 10484, 13896, 13893,
];

export const isCompetitorLeaderboardEnabled = () => {
  const currentPropertyId = Number(getCurrentPropertyId());
  return (
    isFeatureFlagEnabled({ flag: FEATURE_FLAGS.COMPETITOR_LEADERBOARD }) &&
    COMPETITOR_ANALYTICS_PROPERTIES.includes(currentPropertyId)
  );
};

export const isCompetitorPostAnalyticsEnabled = () => {
  const currentPropertyId = Number(getCurrentPropertyId());
  return (
    isFeatureFlagEnabled({ flag: FEATURE_FLAGS.COMPETITOR_POST_ANALYTICS }) &&
    COMPETITOR_ANALYTICS_PROPERTIES.includes(currentPropertyId)
  );
};
