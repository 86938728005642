import { Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import clsx from 'clsx';
import $ from 'jquery';
import Moment from 'moment';
import {
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import momentLocalizer from 'react-widgets-moment';
import Immutable from 'seamless-immutable';

import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  DEFAULT_VALUES,
  KEYCODES,
  SHARE_TIME_NAMES,
  SHARE_TIME_TYPES,
  TIME_SENSITIVITY_TYPES,
} from 'common/constants';
import {
  getFormattedDuration,
  getSpecificTime,
  getTimeslot,
} from 'common/datetime';
import {
  createTimestamp,
  getDate,
  getTime,
  getTomorrow,
  isNotDateControl,
  toLocalString,
} from 'common/datetimepicker';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { getSetting, isIGCabinetEnabled } from 'common/settings';
import { canShareNow, getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import SpecificTime from 'components/compose/timingoptions/SpecificTime';
import Timeslot from 'components/compose/timingoptions/Timeslot';
import Tooltip from 'components/misc/Tooltip';
import {
  handleIsPopupSelectorOpen,
  handleTimingOptionsChange,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { getCommonTrackingParams } from 'helpers/tracking';
import type {
  FixTypeLater,
  ShareTime,
  ShareTimeType,
  TimeSensitivityType,
} from 'types';

const calendarIcon = () => (
  <img src="/img/icons/ic-calendar.svg" alt="" className="op-60" />
);

function EbxBadge() {
  return (
    <div
      className="badge badge-ebx ml-auto"
      data-tip="true"
      data-for="ebx"
      data-iscapture="true"
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        style={{ width: '10px' }}
        className="ml-auto mr-1"
      >
        <path d="M9.2 0C9.5 0.2 9.8 0.5 9.8 0.9V5.3H13.3C13.7 5.3 14 5.5 14.1 5.8 14.3 6.1 14.3 6.5 14.1 6.7L7.8 15.6C7.6 15.9 7.2 16.1 6.8 16 6.5 15.8 6.2 15.5 6.2 15.1V10.7H2.7C2.3 10.7 2 10.5 1.9 10.2 1.7 9.9 1.7 9.5 1.9 9.3L8.2 0.4C8.4 0.1 8.8-0.1 9.2 0Z" />
      </svg>
      <span>EbX</span>
    </div>
  );
}

function getAPITypesShareNow({ apiTypeId }: { apiTypeId: number }) {
  const apiNames = [];
  const apiName = getSocialNetworkName({ apiTypeId });
  if (apiName !== '') {
    apiNames.push(apiName);
  }
  return apiNames.join('/');
}

function handleToggle() {
  /*
  This method is a no-op and is only used to prevent console warnings.

  The date/time picker expects us to use `onToggle` to toggle the open state, however we
  are instead controlling the `open` prop with `onClick` and `onBlur`.

  This is so that we can have the date/time picker open when the *input* is clicked, as well
  as when the icon is clicked.
  */
}

function shareTimeTypeName(shareTimeType: ShareTimeType) {
  switch (shareTimeType) {
    case SHARE_TIME_TYPES.OPTIMAL:
      return 'Optimal';
    case SHARE_TIME_TYPES.TIMESLOT:
      return 'Timeslot';
    case SHARE_TIME_TYPES.TIME:
      return 'Specific Time';
    case SHARE_TIME_TYPES.NOW:
      return 'Share Now';
    default:
      return 'Unknown';
  }
}

function getOptimalCaption({
  accountAPIId,
  timeSensitivity,
}: {
  accountAPIId: number;
  timeSensitivity: TimeSensitivityType | null;
}) {
  if (timeSensitivity === TIME_SENSITIVITY_TYPES.BREAKING) {
    return 'Within 15 minutes';
  }
  if (timeSensitivity === TIME_SENSITIVITY_TYPES.EVERGREEN) {
    return 'Within 48 hours';
  }

  const customShareInterval = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL,
    propertyId: getPropertyIdForAccountAPIId({
      accountAPIId,
    }),
  });

  if (
    customShareInterval?.enabled &&
    customShareInterval?.dataJSON?.intervalSecs !== undefined
  ) {
    return `Within ${getFormattedDuration(
      customShareInterval.dataJSON.intervalSecs,
    )}`;
  }

  return 'Within 12 hours';
}

function assertIsTimeslotShareTime(
  shareTime: ShareTime,
): asserts shareTime is Extract<
  ShareTime,
  { type: typeof SHARE_TIME_TYPES.TIMESLOT }
> {
  if (shareTime.type !== SHARE_TIME_TYPES.TIMESLOT) {
    throw new Error('shareTime is not timeslot');
  }
}

function assertIsTimeShareTime(
  shareTime: ShareTime,
): asserts shareTime is Extract<
  ShareTime,
  { type: typeof SHARE_TIME_TYPES.TIME }
> {
  if (shareTime.type !== SHARE_TIME_TYPES.TIME) {
    throw new Error('shareTime is not time');
  }
}

type DatePickerType = 'min' | 'max' | 'time';
type DatePickerPart = 'date' | 'time';
export type DatePickerTargetString = `${DatePickerType}.${DatePickerPart}`;

function splitTargetString(target: DatePickerTargetString) {
  return target.split('.') as [DatePickerType, DatePickerPart];
}

interface TimingOptionsProps {
  accountAPIId: number;
  applySingleFieldUpdateForGuid: (args: {
    guid: string;
    fieldName: string;
    fieldValue: unknown;
  }) => void;
  guid: string;
  mediaItem: FixTypeLater;
}

const TimingOptions = ({
  accountAPIId,
  applySingleFieldUpdateForGuid,
  guid,
  mediaItem,
}: TimingOptionsProps) => {
  Moment.updateLocale('en', { week: { dow: 1 } });
  momentLocalizer();

  const [isValidMinDate, setIsValidMinDate] = useState(true);
  const [isValidMinTime, setIsValidMinTime] = useState(true);
  const [isValidMaxDate, setIsValidMaxDate] = useState(true);
  const [isValidMaxTime, setIsValidMaxTime] = useState(true);
  const [isValidTimeDate, setIsValidTimeDate] = useState(true);
  const [isValidTimeTime, setIsValidTimeTime] = useState(true);
  const [isChangedMax, setIsChangedMax] = useState(false);
  const [isOpenMinDate, setIsOpenMinDate] = useState(false);
  const [isOpenMinTime, setIsOpenMinTime] = useState(false);
  const [isOpenMaxDate, setIsOpenMaxDate] = useState(false);
  const [isOpenMaxTime, setIsOpenMaxTime] = useState(false);
  const [isOpenTimeDate, setIsOpenTimeDate] = useState(false);
  const [isOpenTimeTime, setIsOpenTimeTime] = useState(false);
  const wasChanged = useRef(false);
  const { dispatch } = useComposeBoxContext();

  const isValidMap: Record<DatePickerType, Record<DatePickerPart, boolean>> = {
    min: {
      date: isValidMinDate,
      time: isValidMinTime,
    },
    max: {
      date: isValidMaxDate,
      time: isValidMaxTime,
    },
    time: {
      date: isValidTimeDate,
      time: isValidTimeTime,
    },
  };
  const setIsValidMap: Record<
    DatePickerType,
    Record<DatePickerPart, Dispatch<SetStateAction<boolean>>>
  > = {
    min: {
      date: setIsValidMinDate,
      time: setIsValidMinTime,
    },
    max: {
      date: setIsValidMaxDate,
      time: setIsValidMaxTime,
    },
    time: {
      date: setIsValidTimeDate,
      time: setIsValidTimeTime,
    },
  };
  const isOpenMap: Record<DatePickerType, Record<DatePickerPart, boolean>> = {
    min: {
      date: isOpenMinDate,
      time: isOpenMinTime,
    },
    max: {
      date: isOpenMaxDate,
      time: isOpenMaxTime,
    },
    time: {
      date: isOpenTimeDate,
      time: isOpenTimeTime,
    },
  };
  const setIsOpenMap: Record<
    DatePickerType,
    Record<DatePickerPart, Dispatch<SetStateAction<boolean>>>
  > = {
    min: {
      date: setIsOpenMinDate,
      time: setIsOpenMinTime,
    },
    max: {
      date: setIsOpenMaxDate,
      time: setIsOpenMaxTime,
    },
    time: {
      date: setIsOpenTimeDate,
      time: setIsOpenTimeTime,
    },
  };

  const apiTypeId = getAPITypeId({ accountAPIId });
  const abInfo = MediaItem.getABInfo({ mediaItem });
  const isSaving = MediaItem.getIsSaving({ mediaItem });
  const shareTime = MediaItem.getShareTime({ mediaItem });
  const timeSensitivity = MediaItem.getTimeSensitivityTypeId({ mediaItem });
  const isABTest = Boolean(abInfo?.isABVariation);

  const twelveHourFormat = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getPropertyIdForAccountAPIId({
      accountAPIId,
    }),
  }).enabled;
  const timeFormat = twelveHourFormat ? 'h:mm a' : 'HH:mm';

  const getInputClass = (key: DatePickerTargetString) => {
    const [type, part] = splitTargetString(key);
    return isValidMap[type][part] ? '' : 'date-time-error';
  };

  const trackUpdateShareTime = (
    previousShareTimeType: ShareTimeType,
    nextShareTime: ShareTime,
  ) => {
    let startTime = '0000-00-00 00:00:00';
    let endTime = '0000-00-00 00:00:00';
    switch (nextShareTime.type) {
      case SHARE_TIME_TYPES.OPTIMAL:
        break;
      case SHARE_TIME_TYPES.TIMESLOT:
        startTime = Moment(nextShareTime.min * 1000).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        endTime = Moment(nextShareTime.max * 1000).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        break;
      case SHARE_TIME_TYPES.TIME:
        startTime = Moment(nextShareTime.time * 1000).format(
          'YYYY-MM-DD HH:mm:ss',
        );
        break;
      case SHARE_TIME_TYPES.NOW:
        break;
      default:
    }
    const trackingParams = getCommonTrackingParams({ mediaItem });
    trackingParams['Timing Before Update'] = shareTimeTypeName(
      previousShareTimeType,
    );
    trackingParams['Start Time'] = startTime;
    trackingParams['End Time'] = endTime;
    trackingParams.Timing = shareTimeTypeName(nextShareTime.type);
    tracker.track({
      eventName: 'Update Share Time',
      trackingParams,
    });
  };

  const handleTimingTypeChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    logger.info(`TimingOptions:handleTimingTypeChange ${event.target.id}`);
    const id = event.target.id.split('__')[0];

    const previousShareTimeType = shareTime.type;

    let newShareTime: ShareTime;

    switch (id) {
      case SHARE_TIME_NAMES.OPTIMAL:
        newShareTime = {
          type: SHARE_TIME_TYPES.OPTIMAL,
        };
        break;
      case SHARE_TIME_NAMES.TIMESLOT: {
        const [min, max] = getTimeslot();
        newShareTime = {
          type: SHARE_TIME_TYPES.TIMESLOT,
          min,
          max,
        };
        break;
      }
      case SHARE_TIME_NAMES.TIME:
        newShareTime = {
          type: SHARE_TIME_TYPES.TIME,
          time: getSpecificTime(),
        };
        break;
      case SHARE_TIME_NAMES.NOW:
        newShareTime = {
          type: SHARE_TIME_TYPES.NOW,
        };
        break;
      default:
        newShareTime = {
          type: SHARE_TIME_TYPES.OPTIMAL,
        };
    }

    trackUpdateShareTime(previousShareTimeType, newShareTime);
    applySingleFieldUpdateForGuid({
      guid,
      fieldName: 'shareTime',
      fieldValue: newShareTime,
    });
  };

  const createHandleDateTimeChange =
    (target: DatePickerTargetString) => (value: Date | undefined) => {
      logger.info(`TimingOptions:handleDateTimeChange ${target} ${value}`);

      const mutableShareTime = Immutable.asMutable(shareTime);
      const previousShareTimeType = mutableShareTime.type;

      // Check that specified date / time is valid
      const [type, part] = splitTargetString(target);
      setIsValidMap[type][part](value !== null);

      wasChanged.current = isOpenMap[type][part];

      // Mark the media item as invalid if necessary
      if (value === null) {
        applySingleFieldUpdateForGuid({
          guid,
          fieldName: 'isTimingValid',
          fieldValue: false,
        });
        return;
      }

      const local = toLocalString(value);

      switch (target) {
        case 'min.date': {
          assertIsTimeslotShareTime(mutableShareTime);
          // Set shareTime.min to 00:00 (local) on the specified date
          const timestamp = createTimestamp({
            date: getDate(local),
            time: '00:00',
          });
          if (timestamp !== null) {
            mutableShareTime.min = timestamp;
          }
          // Set shareTime.max to shareTime.min plus 24 hours minus 1 minute
          if (!isChangedMax) {
            mutableShareTime.max = getTomorrow(mutableShareTime.min) - 60;
          }
          // Mark the relevant date / time elements as now being valid
          setIsValidMinDate(true);
          setIsValidMinTime(true);
          setIsValidMaxDate(true);
          setIsValidMaxTime(true);
          break;
        }
        case 'min.time': {
          assertIsTimeslotShareTime(mutableShareTime);
          // Set shareTime.min to existing date at specified time
          const timestamp = createTimestamp({
            date: getDate(toLocalString(mutableShareTime.min)),
            time: getTime(local),
          });
          if (timestamp !== null) {
            mutableShareTime.min = timestamp;
          }
          // Set shareTime.max to shareTime.min plus default timeslot duration
          if (!isChangedMax) {
            mutableShareTime.max =
              mutableShareTime.min + DEFAULT_VALUES.TIMESLOT_DURATION * 60 * 60;
          }
          // Mark the relevant date / time elements as now being valid
          setIsValidMinDate(true);
          setIsValidMinTime(true);
          setIsValidMaxDate(true);
          setIsValidMaxTime(true);
          break;
        }
        case 'max.date': {
          assertIsTimeslotShareTime(mutableShareTime);
          // Set shareTime.max to specified date at existing time
          const timestamp = createTimestamp({
            date: getDate(local),
            time: getTime(toLocalString(mutableShareTime.max)),
          });
          if (timestamp !== null) {
            mutableShareTime.max = timestamp;
            setIsChangedMax(true);
          }
          break;
        }
        case 'max.time': {
          assertIsTimeslotShareTime(mutableShareTime);
          // Set shareTime.max to existing date at specified time
          const timestamp = createTimestamp({
            date: getDate(toLocalString(mutableShareTime.max)),
            time: getTime(local),
          });
          if (timestamp !== null) {
            mutableShareTime.max = timestamp;
            setIsChangedMax(true);
          }
          break;
        }
        case 'time.date': {
          assertIsTimeShareTime(mutableShareTime);
          // Set shareTime.time to specified date at existing time
          const timestamp = createTimestamp({
            date: getDate(local),
            time: getTime(toLocalString(mutableShareTime.time)),
          });
          if (timestamp !== null) {
            mutableShareTime.time = timestamp;
          }
          break;
        }
        case 'time.time': {
          assertIsTimeShareTime(mutableShareTime);
          // Set shareTime.time to existing date at specified time
          const timestamp = createTimestamp({
            date: getDate(toLocalString(mutableShareTime.time)),
            time: getTime(local),
          });
          if (timestamp !== null) {
            mutableShareTime.time = timestamp;
          }
          break;
        }
        default:
      }
      trackUpdateShareTime(previousShareTimeType, mutableShareTime);
      dispatch(
        handleTimingOptionsChange({ guid, shareTime: mutableShareTime }),
      );

      setIsOpenMap[type][part](false);
      dispatch(handleIsPopupSelectorOpen({ isPopupSelectorOpen: false }));
    };

  const createHandleDateTimeClick =
    (target: DatePickerTargetString): React.MouseEventHandler<any> =>
    (event) => {
      logger.info(`TimingOptions:handleDateTimeClick ${target}`);

      // Ignore clicks on month selector controls
      const classNames = (event.target as HTMLElement).className.split(' ');
      if (isNotDateControl(classNames)) {
        return;
      }

      const [type, part] = splitTargetString(target);
      const isOpen = isOpenMap[type][part];
      if (!wasChanged.current) {
        setIsOpenMap[type][part]((prev) => !prev);
      }
      wasChanged.current = false;
      dispatch(handleIsPopupSelectorOpen({ isPopupSelectorOpen: !isOpen }));
    };

  const createHandleDateTimeKeyDown =
    (target: DatePickerTargetString): React.KeyboardEventHandler<any> =>
    (event) => {
      if (
        !(
          (event.keyCode >= KEYCODES.ENTER &&
            event.keyCode <= KEYCODES.INSERT) ||
          (event.keyCode >= KEYCODES.LEFT_WINDOW_KEY &&
            event.keyCode <= KEYCODES.SELECT_KEY) ||
          (event.keyCode >= KEYCODES.F1 &&
            event.keyCode <= KEYCODES.SCROLL_LOCK)
        )
      ) {
        const [type, part] = splitTargetString(target);
        setIsOpenMap[type][part](false);
        window.setTimeout(() => {
          dispatch(handleIsPopupSelectorOpen({ isPopupSelectorOpen: false }));
        }, 500);
      } else if (event.keyCode === KEYCODES.ENTER) {
        if (typeof $ !== 'undefined' && $(':focus')[0].tagName === 'INPUT') {
          $(':focus').blur();
        }
      }
    };

  const createHandleDateTimeBlur = (target: DatePickerTargetString) => () => {
    const [type, part] = splitTargetString(target);

    setIsOpenMap[type][part](false);
  };

  const handleTimeslotDateFromChange = createHandleDateTimeChange('min.date');
  const handleTimeslotDateFromClick = createHandleDateTimeClick('min.date');
  const handleTimeslotDateFromKeyDown = createHandleDateTimeKeyDown('min.date');
  const handleTimeslotDateFromBlur = createHandleDateTimeBlur('min.date');

  const handleTimeslotTimeFromChange = createHandleDateTimeChange('min.time');
  const handleTimeslotTimeFromClick = createHandleDateTimeClick('min.time');
  const handleTimeslotTimeFromKeyDown = createHandleDateTimeKeyDown('min.time');
  const handleTimeslotTimeFromBlur = createHandleDateTimeBlur('min.time');

  const handleTimeslotDateToChange = createHandleDateTimeChange('max.date');
  const handleTimeslotDateToClick = createHandleDateTimeClick('max.date');
  const handleTimeslotDateToKeyDown = createHandleDateTimeKeyDown('max.date');
  const handleTimeslotDateToBlur = createHandleDateTimeBlur('max.date');

  const handleTimeslotTimeToChange = createHandleDateTimeChange('max.time');
  const handleTimeslotTimeToClick = createHandleDateTimeClick('max.time');
  const handleTimeslotTimeToKeyDown = createHandleDateTimeKeyDown('max.time');
  const handleTimeslotTimeToBlur = createHandleDateTimeBlur('max.time');

  const handleSpecificTimeDateChange = createHandleDateTimeChange('time.date');
  const handleSpecificTimeDateClick = createHandleDateTimeClick('time.date');
  const handleSpecificTimeDateKeyDown =
    createHandleDateTimeKeyDown('time.date');
  const handleSpecificTimeDateBlur = createHandleDateTimeBlur('time.date');

  const handleSpecificTimeTimeChange = createHandleDateTimeChange('time.time');
  const handleSpecificTimeTimeClick = createHandleDateTimeClick('time.time');
  const handleSpecificTimeTimeKeyDown =
    createHandleDateTimeKeyDown('time.time');
  const handleSpecificTimeTimeBlur = createHandleDateTimeBlur('time.time');

  const tooltipLabel = (
    <ul>
      <li>
        <Text fontSize="s">
          <span className="text-700">Optimal: </span>
          Share time optimized by Echobox
        </Text>
      </li>
      <li>
        <Text fontSize="s">
          <span className="text-700">Time Slot: </span>
          Echobox will pick the best time based on your start and end date. We
          recommend a 3 hour minimum
        </Text>
      </li>
      <li>
        <Text fontSize="s">
          <span className="text-700">Specific Time: </span>
          Echobox will share at the time you&apos;ve picked
        </Text>
      </li>
      {(!isIGCabinetEnabled() || apiTypeId !== API_TYPE_IDS.INSTAGRAM) && (
        <li>
          <Text fontSize="s">
            <span className="text-700">Now: </span>
            Echobox will share now
          </Text>
        </li>
      )}
    </ul>
  );

  return (
    <div className="time_selection radio text_unselectable d-flex flex-column justify-content-center">
      <Tooltip
        id="timing_options"
        className="react-tooltip-wide"
        label={tooltipLabel}
      >
        <div className="subtitle text_unselectable mb-1">
          Share Time&nbsp;
          <img
            className="info_icon"
            src="/img/icons/ic-information.svg"
            alt=""
            data-tip
            data-for="timing_options"
            data-iscapture="true"
            style={{ opacity: '0.35' }}
          />
        </div>
      </Tooltip>
      <div
        className="time_selection_choices timing_options mb-2"
        data-cy-id="timingOption"
      >
        <div
          className={clsx(
            'time_selection_container',
            shareTime.type === SHARE_TIME_TYPES.OPTIMAL && 'active',
          )}
        >
          <input
            type="radio"
            name={`timing_option_selection__${guid}`}
            data-cy-id={SHARE_TIME_TYPES.OPTIMAL}
            id={`time_optimal__${guid}`}
            checked={shareTime.type === SHARE_TIME_TYPES.OPTIMAL}
            onChange={handleTimingTypeChange}
          />
          <Tooltip id="ebx" label="Posting time is optimized by Echobox">
            <label
              htmlFor={`time_optimal__${guid}`}
              className={clsx(
                'time_selection_choice time_optimal',
                shareTime.type === SHARE_TIME_TYPES.OPTIMAL && 'selected',
              )}
            >
              <span>Optimal</span>
              <EbxBadge />
            </label>
          </Tooltip>
          {shareTime.type === SHARE_TIME_TYPES.OPTIMAL && (
            <div className="time_selection_details" data-cy-id="optimalCaption">
              <div className="time_selection_optimal">
                {getOptimalCaption({ accountAPIId, timeSensitivity })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="time_selection_choices timing_options mb-2"
        data-cy-id="timingOption"
      >
        <div
          className={clsx(
            'time_selection_container',
            shareTime.type === SHARE_TIME_TYPES.TIMESLOT && 'active',
          )}
        >
          <input
            type="radio"
            name={`timing_option_selection__${guid}`}
            data-cy-id={SHARE_TIME_TYPES.TIMESLOT}
            id={`time_timeslot__${guid}`}
            checked={shareTime.type === SHARE_TIME_TYPES.TIMESLOT}
            onChange={handleTimingTypeChange}
          />
          <Tooltip id="ebx" label="Posting time is optimized by Echobox">
            <label
              htmlFor={`time_timeslot__${guid}`}
              className={clsx(
                'time_selection_choice time_timeslot',
                shareTime.type === SHARE_TIME_TYPES.TIMESLOT && 'selected',
              )}
            >
              <span>Time Slot</span>
              <EbxBadge />
            </label>
          </Tooltip>

          {shareTime.type === SHARE_TIME_TYPES.TIMESLOT && !isSaving && (
            <Timeslot
              calendarIcon={calendarIcon}
              guid={guid}
              inputClass={getInputClass}
              isOpen={isOpenMap}
              shareTime={shareTime}
              timeFormat={timeFormat}
              onTimeslotDateFromChange={handleTimeslotDateFromChange}
              onTimeslotDateFromClick={handleTimeslotDateFromClick}
              onTimeslotDateFromKeyDown={handleTimeslotDateFromKeyDown}
              onTimeslotDateFromBlur={handleTimeslotDateFromBlur}
              onTimeslotDateToChange={handleTimeslotDateToChange}
              onTimeslotDateToClick={handleTimeslotDateToClick}
              onTimeslotDateToKeyDown={handleTimeslotDateToKeyDown}
              onTimeslotDateToBlur={handleTimeslotDateToBlur}
              onTimeslotTimeFromChange={handleTimeslotTimeFromChange}
              onTimeslotTimeFromClick={handleTimeslotTimeFromClick}
              onTimeslotTimeFromKeyDown={handleTimeslotTimeFromKeyDown}
              onTimeslotTimeFromBlur={handleTimeslotTimeFromBlur}
              onTimeslotTimeToChange={handleTimeslotTimeToChange}
              onTimeslotTimeToClick={handleTimeslotTimeToClick}
              onTimeslotTimeToKeyDown={handleTimeslotTimeToKeyDown}
              onTimeslotTimeToBlur={handleTimeslotTimeToBlur}
              onToggle={handleToggle}
            />
          )}
        </div>

        <div
          className={clsx(
            'time_selection_container',
            shareTime.type === SHARE_TIME_TYPES.TIME && 'active',
          )}
        >
          <input
            type="radio"
            name={`timing_option_selection__${guid}`}
            data-cy-id={SHARE_TIME_TYPES.TIME}
            id={`time_specifictime__${guid}`}
            checked={shareTime.type === SHARE_TIME_TYPES.TIME}
            onChange={handleTimingTypeChange}
          />
          <label
            htmlFor={`time_specifictime__${guid}`}
            className={clsx(
              'time_selection_choice time_specifictime',
              shareTime.type === SHARE_TIME_TYPES.TIME && 'selected',
            )}
          >
            <span>Specific Time</span>
          </label>

          {shareTime.type === SHARE_TIME_TYPES.TIME && !isSaving && (
            <SpecificTime
              calendarIcon={calendarIcon}
              guid={guid}
              inputClass={getInputClass}
              isOpen={isOpenMap}
              shareTime={shareTime}
              timeFormat={timeFormat}
              onSpecificTimeDateBlur={handleSpecificTimeDateBlur}
              onSpecificTimeDateChange={handleSpecificTimeDateChange}
              onSpecificTimeDateClick={handleSpecificTimeDateClick}
              onSpecificTimeDateKeyDown={handleSpecificTimeDateKeyDown}
              onSpecificTimeTimeBlur={handleSpecificTimeTimeBlur}
              onSpecificTimeTimeChange={handleSpecificTimeTimeChange}
              onSpecificTimeTimeClick={handleSpecificTimeTimeClick}
              onSpecificTimeTimeKeyDown={handleSpecificTimeTimeKeyDown}
              onToggle={handleToggle}
            />
          )}
        </div>

        {canShareNow({ apiTypeId }) && (
          <div
            className={clsx(
              'time_selection_container',
              shareTime.type === SHARE_TIME_TYPES.NOW && 'active',
            )}
          >
            <input
              type="radio"
              name={`timing_option_selection__${guid}`}
              data-cy-id={SHARE_TIME_TYPES.NOW}
              id={`time_now__${guid}`}
              checked={shareTime.type === SHARE_TIME_TYPES.NOW}
              onChange={handleTimingTypeChange}
            />
            <label
              htmlFor={`time_now__${guid}`}
              className={clsx(
                'time_selection_choice time_now',
                shareTime.type === SHARE_TIME_TYPES.NOW && 'selected',
              )}
            >
              <span>Now</span>
            </label>
            {shareTime.type === SHARE_TIME_TYPES.NOW && !isSaving && (
              <div className="time_selection_details">
                <span className="time_selection_details_title">
                  {isABTest
                    ? 'AB Tests may take up to 30 minutes to be shared.'
                    : `This takes a few seconds longer as we have to wait for confirmation from ${getAPITypesShareNow(
                        { apiTypeId },
                      )} that the share has been made.`}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TimingOptions;
