/**
 * GET /properties - retrieve account information
 *
 * @param {array of integers} propertyId - list of property ids to retrieve
 * @return {object} - account information
 *
 * Example response
 * {
 *   102: {
 *     propertyName: "Echobox Live Demo",
 *     propertyBilling: {
 *       shutOffUnixTime: 1521222930,
 *       invoices: "INV-123"
 *     },
 *     propertyRootURL: "https://www.venturebeat.com",
 *     propertyStateId: 3,
 *     propertyTypeId: 1
 *   },
 *   494: {
 *     propertyName: "Echobox Live Demo",
 *     propertyBilling: {},
 *     propertyRootURL: "https://www.venturebeat.com",
 *     propertyStateId: 3,
 *     propertyTypeId: 1
 *   }
 * }
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { ACCOUNT_STATES as PROPERTY_STATES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { GetPropertiesResponseDataSchema } from 'common/schemas';
import { convertToPropertyURN, extractPropertyId } from 'common/urn';
import { isNullOrUndefined } from 'common/utility';
import { lyingParse } from 'common/zod';
import { GetPropertiesResponseData, Property } from 'types';

const BATCH_SIZE = 100;

const ResponseSchema = z
  .object({ properties: z.array(GetPropertiesResponseDataSchema) })
  .describe('getProperties.ResponseSchema');

export default async function getProperties({
  propertyIds,
}: {
  propertyIds: Array<string | number>;
}) {
  const guid = metrics.start('getProperties');
  const propertyURNs = propertyIds
    .filter((propertyId) => !isNullOrUndefined(propertyId))
    .map(convertToPropertyURN);

  const getPropertiesChunk = async (chunk: string[]) => {
    const clientAuthHeader = getClientServiceRequestHeaders();
    const config: AxiosRequestConfig = {
      url: `${constructBaseCoreAPIURL()}/properties/${chunk.toString()}`,
      method: 'GET',
      timeout: API_TIMEOUTS.S,
      headers: clientAuthHeader,
    };
    logger.info(`API:getProperties /properties/${chunk.toString()}`);

    try {
      const responses = await axios(config);
      const { properties } = lyingParse(ResponseSchema, responses.data);
      // At the moment, responses is an array of response objects
      // We need to convert this into an object containing one response object per account
      const response = properties.reduce<Record<string, Property>>(
        (prev, curr) => {
          const copy = transformProperty(curr);
          return { ...prev, [copy.propertyId]: copy };
        },
        {},
      );
      return response;
    } catch (error) {
      metrics.fail('getProperties', guid);
      const apiError = await handleAPIError({
        originalError: error,
        errorLocation: 'api/getProperties',
        args: { propertyIds },
      });
      throw apiError;
    }
  };

  // Batch requests if the number of properties is high
  if (propertyURNs.length > BATCH_SIZE) {
    const propertyChunks = [];
    while (propertyURNs.length > 0) {
      propertyChunks.push(propertyURNs.splice(0, BATCH_SIZE));
    }
    const responses = await Promise.all(
      propertyChunks.map((chunk) => getPropertiesChunk(chunk)),
    );

    // Merge results
    const properties = responses.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {},
    );

    metrics.end('getProperties', guid);
    logger.debug('getProperties', { propertyIds });

    return properties;
  }

  return getPropertiesChunk(propertyURNs);
}

function transformProperty(property: GetPropertiesResponseData): Property {
  return {
    ...property,
    propertyId: extractPropertyId(property.propertyURN),
    propertyStateId: PROPERTY_STATES[property.propertyState],
  };
}
