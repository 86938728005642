import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { getAPIPostName } from 'common/accountAPIs';
import { addSuccessNotification } from 'common/notifications';
import Button from 'components/misc/Button';
import Dialog from 'components/misc/Dialog';

const { DialogHeader, DialogBody, DialogFooter } = Dialog;

const StyledLinkButton = ({
  children,
  onClick,
}: {
  children: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}) => (
  <Box
    as="button"
    onClick={onClick}
    border="none"
    bg="none"
    p={0}
    color="#3976e6"
    _hover={{ textDecoration: 'underline' }}
    _focusVisible={{ boxShadow: 'outline', outline: 'none' }}
  >
    {children}
  </Box>
);

interface FailedShareDialogProps {
  failedShares: Array<number>;
  totalShares: number;
  onDismiss: () => void;
}

export default function FailedShareDialog({
  failedShares,
  totalShares,
  onDismiss,
}: FailedShareDialogProps) {
  const [isOpen, setIsOpen] = useState(failedShares.length > 0);

  const copySharesToClipBoard: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
    const failedSharePages = failedShares.map((accountAPIId) =>
      getAPIPostName({ accountAPIId }),
    );
    const failedSharesString = failedSharePages.join(',');

    navigator.clipboard.writeText(failedSharesString);
    addSuccessNotification('Failed pages copied to clipboard');
  };

  const handleDismiss = () => {
    onDismiss();
    setIsOpen(false);
  };

  return (
    <Dialog
      className="modal-sm"
      isOpen={isOpen}
      onDismiss={handleDismiss}
      centerVertically
    >
      <DialogHeader showClose={true}>Error posting shares</DialogHeader>
      <DialogBody>
        There was a problem posting to one or more of your pages.{' '}
        {failedShares.length} out of {totalShares} shares could not be made.
        <div className="mt-3">
          Click{' '}
          <StyledLinkButton onClick={copySharesToClipBoard}>
            here
          </StyledLinkButton>{' '}
          to copy the list of failed pages to your clipboard.
        </div>
      </DialogBody>
      <DialogFooter>
        <Button
          id="close-button"
          size="lg"
          variant="light"
          onClick={handleDismiss}
        >
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
