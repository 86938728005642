import { useEffect, useState } from 'react';

import { addImageOverlay } from 'helpers/imageOverlay';

/**
 * Merges an image with an overlay image.
 *
 * @param imageURL The URL for the image to be merged with the overlay.
 * @param overlayURL The URL for the overlay image.
 * @returns The merged image.
 */
const useMergedImage = (imageURL: string | null, overlayURL: string | null) => {
  const [processedImage, setProcessedImage] = useState<string | null>(null);

  useEffect(() => {
    if (imageURL && overlayURL) {
      (async () => {
        const mergedImage = await addImageOverlay({ imageURL, overlayURL });
        setProcessedImage(mergedImage);
      })();
    } else {
      setProcessedImage(imageURL);
    }
  }, [imageURL, overlayURL]);

  return processedImage;
};

export default useMergedImage;
