/* eslint react/no-danger: "off" */

import clsx from 'clsx';
import createDOMPurify from 'dompurify';
import { memo } from 'react';

import { getAPITypeId, getCurrentAccountAPIId } from 'common/accountAPIs';
import { FLASH_MESSAGE_TYPES } from 'common/constants';
import * as logger from 'common/logger';
import { getAutofeedSettings } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { arePropsEqual, isDefined, isUndefined } from 'common/utility';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import {
  getAutofeedSettingsTrackingParams,
  getNetworkAndPageName,
} from 'helpers/tracking';
import useTimeout from 'hooks/useTimeout';
import { useIsShowingHighPriorityErrorType } from 'state/highPriorityErrorType';
import CloseIcon from 'svg/Close';
import ErrorIcon from 'svg/Error';
import InfoIcon from 'svg/Info';
import WarningIcon from 'svg/Warning';
import type { FlashMessage as TFlashMessage } from 'types';

/*
 * Flash message
 */

interface FlashMessageProps {
  message: TFlashMessage;
  onFlashClose: (args: { id: string }) => void;
}

const FlashMessage = ({ message, onFlashClose }: FlashMessageProps) => {
  const DOMPurify = createDOMPurify(window);
  const { flashMessages } = useFlashMessagesContext();
  const isShowingHighPriorityError = useIsShowingHighPriorityErrorType();

  /**
   * Event handlers
   */

  const handleFlashClose = () => {
    logger.info(`FlashMessage:handleFlashClose ${message.id}`);

    onFlashClose({
      id: message.id,
    });
  };

  const handleFlashCloseClick = () => {
    handleFlashClose();

    const accountAPIId = getCurrentAccountAPIId();
    tracker.track({
      eventName: 'Dismiss Frontend Error',
      trackingParams: {
        ...getAutofeedSettingsTrackingParams({
          autofeedSettings: getAutofeedSettings(),
        }),
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Social Network': getSocialNetworkName({
          apiTypeId: getAPITypeId({ accountAPIId }),
        }),
        '# Errors Displayed': flashMessages.messages.length,
        'Error Message': message.text,
        'Error Type': message.messageCategory,
      },
    });
  };

  /**
   * Lifecycle methods
   */

  useTimeout(handleFlashClose, message.timeout);

  /**
   * Render method
   */

  let notificationClass = '';
  let iconComponent: JSX.Element | undefined;
  switch (message.type) {
    case FLASH_MESSAGE_TYPES.SUCCESS:
      notificationClass = '';
      iconComponent = <InfoIcon />;
      break;
    case FLASH_MESSAGE_TYPES.ERROR:
    case FLASH_MESSAGE_TYPES.API_ERROR:
      notificationClass = 'inline-notification-error';
      iconComponent = <ErrorIcon />;
      break;
    case FLASH_MESSAGE_TYPES.INFO:
      notificationClass = 'inline-notification-info';
      iconComponent = <InfoIcon />;
      break;
    case FLASH_MESSAGE_TYPES.WARNING:
      notificationClass = 'inline-notification-warning';
      iconComponent = <WarningIcon />;
      break;
    default:
  }

  if (isShowingHighPriorityError) {
    return null;
  }

  return (
    <div className={clsx('inline-notification', notificationClass)}>
      <div className="inline-notification-icon">{iconComponent}</div>
      {(isUndefined(message.hideClose) || !message.hideClose) && (
        <a
          className="inline-notification-close"
          onClick={handleFlashCloseClick}
        >
          <CloseIcon />
        </a>
      )}
      <div className="inline-notification-content">
        {isDefined(message.title) && (
          <div className="inline-notification-title">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(message.title),
              }}
            />
          </div>
        )}
        <div className="inline-notification-text">
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(message.text),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(FlashMessage, arePropsEqual);
