import clsx from 'clsx';

import { TWITTER_CARD } from 'common/constants';
import { getTextDirection } from 'common/language';
import { getHostname } from 'common/url';
import type { TwitterCardType } from 'types';

interface TwitterCardProps {
  children: React.ReactNode;
  title: string | null;
  description: string | null;
  accountAPIId: number;
  twitterCardType: TwitterCardType;
  fullURL: string;
}

/**
 * Renders the props as a twitter card
 * Accepts the following props:
 * 1) title - the title of the post
 * 2) description - the description of the post
 * 3) accountAPIId - id of the account API
 * 4) fullURL - url of the article
 * 5) twitterCardType - the twitter card type (summary or summary_large_image)
 */
const TwitterCard = ({
  title,
  description,
  accountAPIId,
  fullURL,
  twitterCardType,
  children,
}: TwitterCardProps) => {
  const hostname = getHostname(fullURL);
  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: `${title} ${description}`,
  });

  return TWITTER_CARD.SUMMARY_LARGE_IMAGE === twitterCardType ? (
    <>
      {children}
      <div className="share_twitter_card_large_image">
        <h2 className={clsx('title', textDirectionClass)} data-cy-input="title">
          {title}
        </h2>
        {description && (
          <div className={clsx('description', textDirectionClass)}>
            {description}
          </div>
        )}
        <div className={clsx('hostname', textDirectionClass)}>{hostname}</div>
      </div>
    </>
  ) : (
    <div className="twitter_card">
      <div className="share_twitter_card_summary">
        {children}
        <div className="overflow-hidden">
          <h2 className={clsx('title', textDirectionClass)}>{title}</h2>
          {description && (
            <div className={clsx('description', textDirectionClass)}>
              {description}
            </div>
          )}
          <div className={clsx('hostname', textDirectionClass)}>{hostname}</div>
        </div>
      </div>
    </div>
  );
};

export default TwitterCard;
