import * as authentication from 'common/authentication';
import * as utility from 'common/utility';
import { location } from 'common/window';
import type { FixTypeLater } from 'types';

export const API_TIMEOUTS = {
  XXS: 5000, // 5 seconds
  XS: 15000, // 15 seconds
  S: 60000, // 1 minute
  M: 120000, // 2 minutes
  LM: 180000, // 3 minutes
  L: 240000, // 4 minutes
  XL: 360000, // 6 minutes
  XXL: 720000, // 12 minutes
  XXXL: 1500000, // 25 minutes
} as const;

export const baseCoreAPIURL =
  location.hostname === 'social.echobox.com' || utility.isRunningCypressTests()
    ? 'https://coreapi.service.echobox.com/'
    : 'https://coreapi-stage.service.echobox.com/';
// export const baseCoreAPIURL = 'http://localhost:8080/';

export const baseSocialAPIURL =
  location.hostname === 'social.echobox.com' || utility.isRunningCypressTests()
    ? 'https://socialapi.service.echobox.com/'
    : 'https://socialapi-stage.service.echobox.com/';
// export const baseSocialAPIURL = 'http://localhost:8080/';

export const baseAppletAPIURL = 'https://applets.service.echobox.com/';

export const baseBenchmarksAPIURL = 'https://benchmarks.service.echobox.com/';

export function constructBaseCoreAPIURL(version: `v${number}` = 'v1') {
  validateVersion(version);
  return `${baseCoreAPIURL}${version}`;
}

export function constructBaseSocialAPIURL(
  version: `v${number}` = 'v3',
  isForceProduction: boolean = false,
) {
  validateVersion(version);

  if (isForceProduction) {
    return `https://socialapi.service.echobox.com/${version}`;
  }

  return `${baseSocialAPIURL}${version}`;
}

export function constructBaseAppletAPIURL(version: `v${number}` = 'v1') {
  validateVersion(version);
  return `${baseAppletAPIURL}${version}`;
}

export function constructBaseBenchmarksAPIURL(version: `v${number}` = 'v1') {
  validateVersion(version);
  return `${baseBenchmarksAPIURL}${version}`;
}

function validateVersion(version: string) {
  // validate the version is v followed by a number
  if (!version.match('^v[0-9.]+$')) {
    throw new ReferenceError(
      'Version for the base url should begin with v followed by a number',
    );
  }
}

// returns the Cognito request headers needed to get the CSTs from Id/Access Token
export function getCognitoRequestHeaders(
  additionalHeaders: Record<string, FixTypeLater> = {},
) {
  const requestHeaders = additionalHeaders;
  requestHeaders['X-EBX-AccessToken'] = authentication.getAccessToken();
  requestHeaders['X-EBX-IdToken'] = authentication.getIdToken();
  return requestHeaders;
}

// Returns the request headers for when the client service token
// is used to authenticate rather than the idtoken/accessToken and other
// headers
export function getClientServiceRequestHeaders(
  additionalHeaders: Record<string, FixTypeLater> = {},
) {
  const requestHeaders = additionalHeaders;
  const clientServiceToken = authentication.getClientServiceToken();
  requestHeaders['X-EBX-ClientServiceToken'] = clientServiceToken;
  return requestHeaders;
}

export const statusPageURL = 'https://jbt229h9v3tn.statuspage.io/api/v2';
