import React, { createContext, useMemo, useReducer } from 'react';

import { COMPOSE_BOX_TABS } from 'common/constants';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';
import type { PostPreview } from 'types';

import { reducer } from './slice';
import type { ComposeBoxContextInterface, State } from './types';

const ComposeBoxContext = createContext<ComposeBoxContextInterface | null>(
  null,
);
ComposeBoxContext.displayName = 'ComposeBoxContext';

const useComposeBoxContext = () => {
  const composeBoxContext = React.useContext(ComposeBoxContext);

  if (composeBoxContext === undefined) {
    throw new Error(
      'useComposeBoxContext must be used within a ComposeBoxProvider',
    );
  }
  if (composeBoxContext === null) {
    throw new Error('ComposeBoxContext must be initialized by the Provider');
  }

  return composeBoxContext;
};

function getInitialState({
  initialPostPreviews,
}: {
  initialPostPreviews: Array<PostPreview>;
}): State {
  return {
    isPopupSelectorOpen: false,
    passedCrossPostLimit: false,
    postPreviews: initialPostPreviews,
    showDuplicateScheduleModal: false,
    selectedTab: COMPOSE_BOX_TABS.PREVIEW,
    isFirstRender: true,
  };
}

interface ComposeBoxProviderPropInterface {
  children: React.ReactNode;
}

const ComposeBoxProvider = ({ children }: ComposeBoxProviderPropInterface) => {
  const initialPostPreviews = useComposeBoxOpenStore(
    (state) => state.initialPostPreviews,
  );
  const [state, dispatch] = useReducer(
    reducer,
    { initialPostPreviews },
    getInitialState,
  );

  const composeBoxContextValue = useMemo(() => {
    return {
      ...state,
      dispatch,
    };
  }, [state]);

  return (
    <ComposeBoxContext.Provider value={composeBoxContextValue}>
      {children}
    </ComposeBoxContext.Provider>
  );
};

export { ComposeBoxProvider, useComposeBoxContext };
