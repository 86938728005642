/* eslint-disable no-param-reassign */

import {
  getAccountAPIPermission,
  getCurrentAccountAPIId,
  getCurrentProperty,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { isImpersonating } from 'common/authentication';
import {
  ACCOUNT_SETTING_TYPES,
  PERFORMANCE_GOAL,
  PERMISSION_TYPES,
  PRODUCT_USER_TYPE_MAP,
  USER_TYPES,
} from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isRunningCypressTests,
} from 'common/utility';
import { getSetting } from './settings';

export interface TrackProperties {
  /**
   * The name of the event to track
   */
  eventName: string;
  /**
   * The parameters to track with the event.
   */
  trackingParams?: Record<string, unknown>;
  /**
   * The callback to execute after tracking the event.
   */
  callback?: () => void;
}

export function track({
  eventName,
  trackingParams = {},
  callback,
}: TrackProperties) {
  const trackAsync = async () => {
    const globalInfo = getGlobalInfo();
    if (!isNull(globalInfo)) {
      const currentUser = globalInfo.user;
      const currentProperty = getCurrentProperty({
        globalInfo,
      });
      const currentPropertyId = getCurrentPropertyId({
        globalInfo,
      });
      const currentRole = getAccountAPIPermission({
        accountAPIId: getCurrentAccountAPIId({
          globalInfo,
        }),
        globalInfo,
      });

      let propertyId = !isNullOrUndefined(currentProperty)
        ? currentPropertyId
        : 'Not set';
      let propertyName = !isNullOrUndefined(currentProperty)
        ? currentProperty.propertyName
        : 'Not set';
      if (isDefined(trackingParams.PropertyID)) {
        propertyId = trackingParams.PropertyID;
        delete trackingParams.PropertyID;
      }
      if (isDefined(trackingParams.PropertyName)) {
        propertyName = trackingParams.PropertyName;
        delete trackingParams.PropertyName;
      }

      const products = (
        currentUser.accessGroups as string[] | undefined
      )?.reduce((acc: string[], userType: string) => {
        if (userType in PRODUCT_USER_TYPE_MAP) {
          acc.push(PRODUCT_USER_TYPE_MAP[userType]);
        }
        return acc;
      }, []);

      const generateActiveProductsString = (allProducts?: string[]) => {
        const activeProducts = [];

        if (allProducts) {
          for (const product of allProducts) {
            activeProducts.push(product[0] + product.slice(1).toLowerCase());
          }
        }

        const activeProductsString = activeProducts.join(':');
        return activeProductsString;
      };
      const activeProductsString = generateActiveProductsString(products);

      const settingTypeId = ACCOUNT_SETTING_TYPES.PERFORMANCE_GOAL;
      const settings = getSetting({ settingTypeId, propertyId });

      trackingParams.$distinct_id = await hashEmailAddress(
        currentUser.emailAddress,
      );
      trackingParams.$name = currentUser.name;
      trackingParams.$property = propertyName;
      trackingParams.$email = currentUser.emailAddress;
      trackingParams['Property ID'] = propertyId;
      trackingParams.Staff = currentUser.userType === USER_TYPES.ECHOBOX_STAFF;
      trackingParams.Role =
        currentRole === PERMISSION_TYPES.EDITOR ? 'Editor' : 'Admin';
      trackingParams.HV = APP_VERSION;
      trackingParams.Products = products?.join(', ');
      trackingParams['Active Products'] = activeProductsString;
      trackingParams['Impersonating Staff Member'] = isImpersonating()
        ? currentUser.emailAddress
        : null;
      trackingParams['Property Performance Goal'] =
        settings?.dataJSON?.target ?? PERFORMANCE_GOAL.TRAFFIC;
      if (typeof mixpanel !== 'undefined') {
        mixpanel.track(eventName, trackingParams, undefined, callback);
      }
    }
  };

  if (!isImpersonating() && !isRunningCypressTests()) {
    trackAsync();
  }
}

export const hashEmailAddress = async (email: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(email.toLowerCase());
  const hash = await crypto.subtle.digest('SHA-1', data);
  const array = Array.from(new Uint8Array(hash));
  const hex = array.map((byte) => byte.toString(16).padStart(2, '0')).join('');
  return hex;
};
