import { Tone } from 'types';

export const INSTANT_VIDEO_PROPERTY_CONFIG: Record<
  number,
  { enabled: boolean; logoURL: string; font: string }
> = {
  // For Tech's Sake
  102: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/newsByLarry.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Poppins/Poppins-Bold.ttf`,
  },
  // News by larry
  732: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/newsByLarry.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf`,
  },
  // Brave Traveller
  12473: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Brave%20Traveller.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf`,
  },
  // Beta participants
  13877: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Binge.nl.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Inter-Bold.ttf`,
  },
  12526: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Cape%20Gazette.png',
    font: `${window.location.origin}/fonts/CreativeEditor/GeorgiaBold.ttf`,
  },
  11655: {
    enabled: true,
    logoURL:
      'https://static.service.echobox.com/logos/comment-economiser.fr.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Lobster-Regular.ttf`,
  },
  413: {
    enabled: true,
    logoURL:
      'https://static.service.echobox.com/logos/Courrier%20International.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/FiraSans/FiraSans-Bold.ttf`,
  },
  14022: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Dagens%20industri.png',
    font: `${window.location.origin}/fonts/CreativeEditor/BentonSans-Condensed-Black.otf`,
  },
  13454: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/devex.png',
    font: `${window.location.origin}/fonts/CreativeEditor/GeorgiaBold.ttf`,
  },
  11913: {
    enabled: true,
    logoURL:
      'https://static.service.echobox.com/logos/Easy%20Health%20Options.png',
    font: `${window.location.origin}/fonts/CreativeEditor/BebasNeue-Regular.ttf`,
  },
  14018: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/eCycle.png',
    font: `${window.location.origin}/fonts/CreativeEditor/GeorgiaBold.ttf`,
  },
  // Excluding from BETA until font is confirmed
  // 10040: {
  //   enabled: true,
  //   logoURL: 'https://static.service.echobox.com/logos/FUNKE.png',
  // },
  11480: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/GOBankingRates.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Poppins/Poppins-Bold.ttf`,
  },
  13989: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/IE%20Tamil%29.png',
    font: `${window.location.origin}/fonts/CreativeEditor/NotoSansTamil-Bold.tff`,
  },
  13739: {
    enabled: true,
    logoURL:
      'https://static.service.echobox.com/logos/Inside%20the%20Magic.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Helvetica.ttc`,
  },
  13959: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Mitt%20i.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/OpenSans/OpenSans-Bold.ttf`,
  },
  10725: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Prensa%20Libre.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Acto-Black.otf`,
  },
  13046: {
    enabled: true,
    logoURL:
      'https://static.service.echobox.com/logos/Pro%20Wrestling%20Stories.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Oswald/Oswald-Bold.ttf`,
  },
  13506: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Telegraaf.nl.png',
    font: 'extensions/ly.img.cesdk.fonts/fonts/Roboto/Roboto-Bold.ttf',
  },
  433: {
    enabled: true,
    logoURL:
      'https://static.service.echobox.com/logos/Stuttgarter%20Zeitung.png',
    font: `${window.location.origin}/fonts/CreativeEditor/PTSerif-Bold.ttf`,
  },
  14246: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Visordown.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Montserrat/Montserrat-Bold.ttf`,
  },
  13565: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/WeLoversize.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Playfair_Display/PlayfairDisplay-Bold.ttf`,
  },
  11525: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Zero%20Hanger.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/FiraSans/FiraSans-Bold.ttf`,
  },
  11524: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Zero%20Tackle.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/FiraSans/FiraSans-Bold.ttf`,
  },
  10031: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/the-sun.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf`,
  },
  14283: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Automotive%20News.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Roboto/Roboto-Bold.ttf`,
  },
  11854: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Express.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Barlow-Bold.ttf`,
  },
  11965: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/TV%20Fanatic.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Merriweather-Bold.ttf`,
  },
  13594: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/Pulse24.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Poppins/Poppins-Bold.ttf`,
  },
  265: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/El%20Diario.png',
    font: `extensions/ly.img.cesdk.fonts/fonts/Roboto/Roboto-Bold.ttf`,
  },
  478: {
    enabled: true,
    logoURL: 'https://static.service.echobox.com/logos/euronews.net.png',
    font: `${window.location.origin}/fonts/CreativeEditor/Inter-Bold.ttf`,
  },
};

export const AUDIO_TRACK_PREFIX =
  'https://static.service.echobox.com/audio-tones';

export const AUDIO_TRACKS_BY_TONE: Record<Tone, string[]> = {
  informative: [
    'A%20Caring%20Friend%20-%20Bad%20Snacks.mp3',
    'Colour%20Of%20Your%20Face%20-%20NoMBe.mp3',
    'Good%20Job%21%20-%20R.LUM.R.mp3',
    'Icelandic%20Arpeggios%20-%20DivKid.mp3',
    'It_ll%20Be%20Alright%20-%20R.LUM.R.mp3',
    'No%20Indication%20-%20TrackTribe.mp3',
    'OSA%20Dress%20Code%20-%20Squadda%20B.mp3',
    'People%20Watching%20-%20Sir%20Cubworth.mp3',
    'QuangerineCream%20-%20Noir%20Et%20Blanc%20Vie.mp3',
    'Read%20All%20Over%20-%20Nathan%20Moore.mp3',
    'Simple%20Step%20-%20Slenderbeats.mp3',
    'Sunny%20Days%20-%20Anno%20Domini%20Beats.mp3',
    'Sunshine%20on%20Sand%20-%20Unicorn%20Heads.mp3',
    'The%20Carrier%20-%20Public%20Memory.mp3',
  ],
  uplifting: [
    'Easy%20Seas%20-%20Chris%20Haugen.mp3',
    'In%20the%20Atmosphere%20-%20Bad%20Snacks.mp3',
    'Know%20Myself%20-%20Patrick%20Patrikios.mp3',
    'Rainy%20Sundays%20-%20The%20126ers.mp3',
    'Ringside%20-%20Dyalla.mp3',
    'Timpani%20Beat%20-%20Nana%20Kwabena.mp3',
    'Tropic%20-%20Anno%20Domini%20Beats.mp3',
    'Who%20Do%20You%20Think%20I%20Think%20You%20Are_%20-%20Mini%20Vandals.mp3',
  ],
  sad: [
    'Aletheia%20%28Unforgetting%29%20-%20Devon%20Church.mp3',
    'Bottle%20Of%20Something%20-%20TrackTribe.mp3',
    'Good%20Gig%20In%20the%20Clouds%20-%20Joel%20Cummins.mp3',
    'I%20Don_t%20Want%20To%20Do%20This%20Without%20You%20-%20Late%20Night%20Feeler.mp3',
    'In%20the%20Temple%20Garden%20-%20Aaron%20Kenny.mp3',
    'Lightning%20Bugs%20-%20Geographer.mp3',
    'Lonely%20Day%20-%20Telecasted.mp3',
    'Natural%20Light%20-%20Chris%20Haugen.mp3',
    'Orbit%20edited.mp3',
    'Sad%20Minuet%20-%20Sir%20Cubworth.mp3',
    'Sarabande%20-%20Joel%20Cummins.mp3',
    'Sweet%20Release%20-%20Dan%20Lebowitz.mp3',
  ],
  urgent: [
    'Final%20Girl%20-%20Jeremy%20Blake.mp3',
    'Hologram%20-%20Bobby%20Richards.mp3',
    'July%20-%20John%20Patitucci.mp3',
    'Missing%20Persons%20-%20Jeremy%20Blake.mp3',
    'Riding%20Into%20The%20Sun%20-%20Telecasted.mp3',
    'URL%20Melt%20-%20Unicorn%20Heads.mp3',
    'Wolf%20Moon%20-%20Unicorn%20Heads.mp3',
  ],
  playful: [
    'Bohemian%20Beach%20-%20Chris%20Haugen.mp3',
    'Chiapas%20Marimba%20-%20Jimena%20Contreras.mp3',
    'Malandragem%20-%20Quincas%20Moreira.mp3',
    'Oakland%20Extasy%20-%20Squadda%20B.mp3',
    'Seaside%20Piazza%20-%20Aaron%20Kenny.mp3',
    'Take%20It%20Easy%20-%20Bad%20Snacks.mp3',
    'Ticklish%20-%20Quincas%20Moreira.mp3',
  ],
};

export const DEFAULT_TONE: Tone = 'informative';

export const VIDEO_PREFIX = 'https://static.service.echobox.com/video-tones';

export const VIDEOS_BY_TONE: Record<Tone, string[]> = {
  informative: [
    '15439747-hd_720_1280_30fps.mov',
    '15439756-hd_720_1280_30fps.mov',
    '15440318-hd_720_1280_30fps.mov',
    '16029657-hd_720_1280_30fps.mov',
    '16029664-hd_720_1280_30fps.mov',
    '7223775-hd_720_1280_30fps.mov',
    '7565434-hd_720_1280_25fps.mov',
    '7565438-hd_720_1280_25fps.mov',
    '7565441-hd_720_1280_25fps.mov',
    '7565442-hd_720_1280_25fps.mov',
    '7565881-hd_720_1280_25fps.mov',
    '7639588-hd_720_1280_30fps.mov',
    '7639710-hd_720_1280_30fps.mov',
    '7793406-hd_720_1280_30fps.mov',
    '7829857-hd_720_1280_30fps.mov',
    '8853009-hd_720_1280_30fps.mov',
    '9669110-hd_720_1280_25fps.mov',
  ],
  playful: [
    '11910328-hd_720_1280_30fps.mov',
    '16029654-hd_720_1280_30fps.mov',
    '16561460-hd_720_1280_30fps.mov',
    '16749295-hd_720_1280_60fps.mov',
    '17256943-hd_720_1280_30fps.mov',
    '18069236-hd_720_1280_24fps.mov',
    '18069863-hd_720_1280_24fps.mov',
    '5876041-hd_720_1366_25fps.mov',
    '7139757-hd_720_1280_30fps.mov',
    '7832369-hd_720_1280_25fps.mov',
  ],
  sad: [
    '4536383-hd_720_1280_30fps.mov',
    '4536559-hd_720_1280_30fps.mov',
    '4604026-hd_720_1280_27fps.mov',
    '5908204-hd_720_1142_25fps.mov',
    '6650300-hd_720_1366_30fps.mov',
    '7011816-hd_720_1366_25fps.mov',
    '7565430-hd_720_1280_25fps.mov',
    '7565432-hd_720_1280_25fps.mov',
    '7565895-hd_720_1280_25fps.mov',
    '7677747-hd_720_1280_25fps.mov',
    '7792610-hd_720_1280_30fps.mov',
    '7829485-hd_720_1280_30fps.mov',
    '7830059-hd_720_1280_30fps.mov',
    '7830751-hd_720_1280_30fps.mov',
    '7946009-hd_720_1366_30fps.mov',
    '8443153-hd_720_1280_25fps.mov',
  ],
  uplifting: [
    '15989686-hd_720_1280_30fps.mov',
    '16029655-hd_720_1280_30fps.mov',
    '3138266-hd_720_1280_30fps.mov',
    '4832755-hd_720_1280_30fps.mov',
    '6546116-hd_720_1280_60fps.mov',
    '7565436-hd_720_1280_25fps.mov',
    '7565623-hd_720_1280_25fps.mov',
    '7565640-hd_720_1280_25fps.mov',
    '7639511-hd_720_1280_30fps.mov',
    '7677540-hd_720_1280_25fps (1).mov',
    '7677549-hd_720_1280_25fps.mov',
    '7792706-hd_720_1280_30fps.mov',
    '7832280-hd_720_1280_25fps.mov',
    '7872729-hd_720_1280_25fps (1).mov',
    '7946008-hd_720_1366_30fps.mov',
  ],
  urgent: [
    '15283132-hd_720_1280_30fps.mov',
    '15283159-hd_720_1280_30fps.mov',
    '15283166-hd_720_1280_30fps.mov',
    '15283169-hd_720_1280_30fps.mov',
    '15283171-hd_720_1280_30fps.mov',
    '15283191-hd_720_1280_30fps.mov',
    '15283196-hd_720_1280_30fps.mov',
    '15283234-hd_720_1280_30fps.mov',
    '15439746-hd_720_1280_30fps.mov',
    '16029663-hd_720_1280_30fps.mov',
    '3514521-hd_720_1260_30fps.mov',
    '6440516-hd_720_1280_30fps.mov',
    '7565435-hd_720_1280_25fps.mov',
    '7565443-hd_720_1280_25fps copy.mov',
    '7565621-hd_720_1280_25fps.mov',
    '7565634-hd_720_1280_25fps.mov',
  ],
};
