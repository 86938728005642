import {
  Accordion,
  CheckCircleIcon,
  HStack,
  Spinner,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { extractPropertyId } from 'common/urn';
import { PagesByPropertyURNType, Property, SocialAPI } from 'types';

import PagesSetupAccordionPage from './PagesSetupAccordionPage';

interface PagesByPropertyAccordionProps {
  properties: { [id: string]: Property };
  pagesByProperty: {
    [id: string]: SocialAPI[];
  };
  selectedPages: PagesByPropertyURNType;
  onCheckboxToggle: (page: SocialAPI) => void;
}

const sortPropertiesAlphabetically = (first: Property, second: Property) => {
  return first.propertyName.toLowerCase() < second.propertyName.toLowerCase()
    ? -1
    : 1;
};

const PagesByPropertyAccordion = ({
  properties,
  pagesByProperty,
  selectedPages,
  onCheckboxToggle,
}: PagesByPropertyAccordionProps) => {
  const isLoading =
    Object.keys(properties).length === 0 &&
    Object.keys(pagesByProperty).length === 0;

  const isCheckboxDisabled = (propertyURN: string) => {
    if (Object.keys(selectedPages).length === 0) {
      return false;
    }

    return propertyURN !== Object.values(selectedPages)[0].propertyURN;
  };

  const isPropertySelected = (propertyURN: string) => {
    if (Object.keys(selectedPages).length === 0) {
      return false;
    }

    return propertyURN === Object.values(selectedPages)[0].propertyURN;
  };

  return isLoading ? (
    <HStack justifyContent="center">
      <Spinner size="md" />
    </HStack>
  ) : (
    <Accordion minWidth={400}>
      {Object.values(properties)
        .sort(sortPropertiesAlphabetically)
        .map((property) => {
          const { propertyName, propertyURN } = property;
          const socialPages = pagesByProperty[propertyURN];
          const isDisabled = isCheckboxDisabled(propertyURN);
          const isSelected = isPropertySelected(propertyURN);

          return (
            <Accordion.Item key={propertyURN}>
              <Accordion.ItemHeader>
                <HStack justifyContent="start">
                  {isSelected && <CheckCircleIcon color="green.500" size={6} />}
                  <Text>{`${extractPropertyId(
                    propertyURN,
                  )} - ${propertyName}`}</Text>
                </HStack>
              </Accordion.ItemHeader>

              <Accordion.ItemPanel display="flex">
                <VStack alignItems="start">
                  {socialPages.map((page) => {
                    return (
                      <PagesSetupAccordionPage
                        key={page.accountAPIId}
                        page={page}
                        onToggle={onCheckboxToggle}
                        isDisabled={isDisabled}
                      />
                    );
                  })}
                </VStack>
              </Accordion.ItemPanel>
            </Accordion.Item>
          );
        })}
    </Accordion>
  );
};

export default PagesByPropertyAccordion;
