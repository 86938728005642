import { useQuery } from '@tanstack/react-query';
import { getMediaItems, getMediaList } from 'api/api';
import {
  getAPITypeId,
  getSocialAPIs,
  getSocialAPIsByTypeAndState,
} from 'common/accountAPIs';
import { FAILED_SHARE_DURATION, REFRESH_MULTIPLIERS } from 'common/config';
import { API_STATES, MEDIA_ITEM_STATES } from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'common/featureFlags';
import { refreshInterval } from 'common/misc';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { APITypeId, AccountAPIId, GlobalInfo } from 'types';

const BATCH_SIZE = 10;

const getAccountAPIIdsToCheck = ({
  globalInfo,
}: {
  globalInfo: GlobalInfo.GlobalInfo;
}) => {
  const accountAPIs = getSocialAPIs({ globalInfo });
  const filteredAccountAPIs = getSocialAPIsByTypeAndState({
    accountAPIs,
    states: [API_STATES.ACTIVE],
  });

  const accountAPIIds = filteredAccountAPIs.map((api) => api.accountAPIId);
  return accountAPIIds;
};

const getFailedMediaItems = async ({
  accountAPIId,
  apiTypeId,
  failFromToTimes,
}: {
  accountAPIId: AccountAPIId;
  apiTypeId: APITypeId;
  failFromToTimes: string;
}) => {
  const getMediaListResponse = await getMediaList({
    accountAPIId,
    state: MEDIA_ITEM_STATES.FAILED,
    failFromToTimes,
    filter: 'all',
    sort: 'date',
  });

  if (getMediaListResponse.mediaIds.length === 0) {
    return {};
  }

  const getMediaItemResponse = await getMediaItems({
    accountAPIId,
    mediaIds: getMediaListResponse.mediaIds,
    state: MEDIA_ITEM_STATES.FAILED,
    apiTypeId,
  });

  return getMediaItemResponse;
};

const useFailedShares = () => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const accountAPIIds = getAccountAPIIdsToCheck({ globalInfo });

  const queryResults = useQuery({
    queryKey: ['failedShares', { accountAPIIds }],
    refetchInterval: refreshInterval(REFRESH_MULTIPLIERS.FAILED_SHARES),
    enabled: isFeatureFlagEnabled({ flag: FEATURE_FLAGS.FAILED_SHARES }),
    queryFn: async () => {
      // Calculate fromTo time in seconds
      const failFromToTimes = `${getUnixTimestamp() - FAILED_SHARE_DURATION * 60 * 60}`;

      const retrieveBatch = async (apiIds: AccountAPIId[]) => {
        const requests = apiIds.map((accountAPIId) => {
          const apiTypeId = getAPITypeId({
            accountAPIId,
            globalInfo,
          });

          return getFailedMediaItems({
            accountAPIId,
            apiTypeId,
            failFromToTimes,
          });
        });

        const results = await Promise.all(requests);
        return results.reduce((acc, result, index) => {
          const accountAPIId = apiIds[index];
          if (Object.keys(result).length === 0) {
            return acc;
          }
          return { ...acc, [accountAPIId]: result };
        }, {});
      };

      // If there's a lot of accountAPIIds, batch them
      if (accountAPIIds.length > BATCH_SIZE) {
        let batchedResults = {};
        for (let i = 0; i < accountAPIIds.length; i += BATCH_SIZE) {
          const batchedAPIIds = accountAPIIds.slice(i, i + BATCH_SIZE);
          const batchedResult = await retrieveBatch(batchedAPIIds);
          batchedResults = { ...batchedResults, ...batchedResult };
        }
        return batchedResults;
      }

      return retrieveBatch(accountAPIIds);
    },
  });

  return queryResults;
};

export default useFailedShares;
