import { Alert, Modal } from '@ebx-ui/ebx-ui-component-library-sdk';

import ErrorBoundary from 'components/misc/ErrorBoundary';

const Fallback = () => {
  return (
    <>
      <Modal.Header>&nbsp;</Modal.Header>
      <Modal.Body>
        <Alert variant="error">
          <Alert.Description>
            An unexpected error occurred. Please refresh the page and try again.
          </Alert.Description>
        </Alert>
      </Modal.Body>
    </>
  );
};

interface InstantVideoErrorBoundaryProps {
  children: React.ReactNode;
}
const InstantVideoErrorBoundary = ({
  children,
}: InstantVideoErrorBoundaryProps) => {
  return (
    <ErrorBoundary
      fallback={<Fallback />}
      logglyProperties={{
        location: 'components/instantvideo/InstantVideoErrorBoundary',
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default InstantVideoErrorBoundary;
