import {
  Button,
  Heading,
  Text,
  Tooltip,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getCurrentPropertyId,
  getPropertyPermission,
} from 'common/accountAPIs';
import { PERMISSION_TYPES } from 'common/constants';
import { getReconnectSocialPageLink } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import PageImage from 'components/misc/PageImage';
import { getNetworkAndPageName } from 'helpers/tracking';
import { useEllipsisActive } from 'hooks/useEllipsisActive';
import { useHealthMenuActions } from 'state/healthMenu';
import type { SocialAPI } from 'types';
import HealthMenuItem from './HealthMenuItem';

export const getErrorMessage = (accountAPI: SocialAPI) =>
  `${getSocialNetworkName({
    apiTypeId: accountAPI.apiTypeId,
  })} returned the following error: ${accountAPI.disconnectReason}`;

interface HealthMenuPageProps {
  accountAPI: SocialAPI;
}

const HealthMenuPage = ({ accountAPI }: HealthMenuPageProps) => {
  const navigate = useNavigate();
  const { closeHealthMenu } = useHealthMenuActions();
  const headingRef = React.useRef<HTMLHeadingElement>(null);
  const isEllipsisActive = useEllipsisActive(headingRef);

  const propertyPermission = getPropertyPermission({
    propertyId: getCurrentPropertyId(),
  });
  const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

  const handleReconnect = () => {
    tracker.track({
      eventName: 'Reconnect Page',
      trackingParams: {
        'Social Network': getSocialNetworkName({
          apiTypeId: accountAPI.apiTypeId,
        }),
        'Account API Id': accountAPI.accountAPIId,
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId: accountAPI.accountAPIId,
        }),
        Location: 'Health Menu',
      },
    });
    const reconnectLink = getReconnectSocialPageLink({ accountAPI });
    closeHealthMenu();
    navigate(reconnectLink);
  };

  return (
    <HealthMenuItem>
      <HealthMenuItem.Header>
        <PageImage accountAPI={accountAPI}>
          <PageImage.SocialLogo size={3} svgSize={2.5} />
          <PageImage.Image size={4} socialIconSize={2.5} />
        </PageImage>
        <Tooltip
          label={accountAPI.apiPostName}
          maxWidth="auto"
          isDisabled={!isEllipsisActive}
          placement="top"
        >
          <Heading
            variant="h5"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            ref={headingRef}
          >
            {accountAPI.apiPostName}
          </Heading>
        </Tooltip>
      </HealthMenuItem.Header>
      <HealthMenuItem.Body>
        {accountAPI.disconnectReason && (
          <Text size="sm">{getErrorMessage(accountAPI)}</Text>
        )}
        {isAdmin && (
          <Button variant="danger" onClick={handleReconnect}>
            Reconnect
          </Button>
        )}
      </HealthMenuItem.Body>
    </HealthMenuItem>
  );
};

export default HealthMenuPage;
