import {
  Dropdown,
  Header,
  HealthIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import {
  getAllDisconnectedAccountAPIs,
  getAllTwitterAppDisconnectedAccountAPIs,
  getCurrentProperty,
  getDisconnectReasonsByAccountAPI,
  getUnseenDisconnectionNotifications,
  isTwitterAppDisconnected,
} from 'common/accountAPIs';
import {
  HEALTH_MENU_ERROR_TYPES,
  HEALTH_MENU_LOCALSTORAGE_KEY,
  RSS_STATE_NAMES,
} from 'common/constants';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  getSeenNotifications,
  sendErrorTrackingEvents,
  updateSeenNotifications,
} from 'helpers/healthMenu';
import useHealthMenuLocalStorageCleanup from 'hooks/useHealthMenuLocalStorageCleanup';
import useFailedShares from 'queries/useFailedShares';
import { useHealthMenuActions, useIsHealthMenuOpen } from 'state/healthMenu';
import { useIsShowingHighPriorityErrorType } from 'state/highPriorityErrorType';
import { AccountFeed, HealthMenuNotificationStorage, SocialAPI } from 'types';
import HealthMenuContent from './HealthMenuContent';
import { getContentFeedsErrorMessage } from './HealthMenuContentFeedError';

const HealthMenu = () => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const currentProperty = getCurrentProperty({ globalInfo });
  const isHealthMenuOpen = useIsHealthMenuOpen();
  const { closeHealthMenu, openHealthMenu } = useHealthMenuActions();
  const isShowingHighPriorityError = useIsShowingHighPriorityErrorType();
  const prevIsHealthMenuOpen = React.useRef(isHealthMenuOpen);
  useFailedShares();

  const disconnectedTwitterPages = getAllTwitterAppDisconnectedAccountAPIs();
  const hasDisconnectedTwitterApp =
    isTwitterAppDisconnected() && disconnectedTwitterPages.length > 0;
  let disconnectedAccountAPIs: SocialAPI[];

  if (hasDisconnectedTwitterApp) {
    const disconnectedTwitterAppIds = disconnectedTwitterPages.map(
      (page) => page.accountAPIId,
    );

    disconnectedAccountAPIs = getAllDisconnectedAccountAPIs().filter(
      (page) => !disconnectedTwitterAppIds.includes(page.accountAPIId),
    );
  } else {
    disconnectedAccountAPIs = getAllDisconnectedAccountAPIs();
  }

  const seenNotifications = getSeenNotifications(HEALTH_MENU_LOCALSTORAGE_KEY);

  const hasUnseenDisconnectionNotifications =
    getUnseenDisconnectionNotifications(
      disconnectedAccountAPIs,
      seenNotifications,
    ).length > 0;
  const hasUnseenTwitterAppNotifications =
    !(HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION in seenNotifications) &&
    hasDisconnectedTwitterApp;

  // Check for RSS feeds not returning data
  const contentFeedErrors: string[] = (
    (currentProperty.accountFeeds ?? []) as AccountFeed[]
  )
    .filter(
      (accountFeed) => accountFeed.syndFeedState === RSS_STATE_NAMES.ERROR,
    )
    .map((accountFeed) => accountFeed.syndFeedURL);
  const hasContentFeedErrors = contentFeedErrors.length > 0;
  const contentFeedErrorMsg = hasContentFeedErrors
    ? getContentFeedsErrorMessage(contentFeedErrors)
    : null;
  // Only show content feed error if it's different from the last one seen
  const hasUnseenContentFeedErrors =
    hasContentFeedErrors &&
    seenNotifications[HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR] !==
      contentFeedErrorMsg;

  const hasAnyUnseenNotifications =
    hasUnseenDisconnectionNotifications ||
    hasUnseenTwitterAppNotifications ||
    hasUnseenContentFeedErrors;

  const showRedDotNotification =
    !isHealthMenuOpen &&
    !isShowingHighPriorityError &&
    hasAnyUnseenNotifications;

  // Update localStorage with seen notifications when menu is open
  React.useEffect(() => {
    if (isHealthMenuOpen) {
      let newSeenNotifications: HealthMenuNotificationStorage[string] = {};

      const disconnectReasonsByAccountAPIIds = getDisconnectReasonsByAccountAPI(
        disconnectedAccountAPIs,
      );

      newSeenNotifications = {
        [HEALTH_MENU_ERROR_TYPES.DISCONNECTIONS]:
          disconnectReasonsByAccountAPIIds,
        ...(hasDisconnectedTwitterApp
          ? {
              [HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION]: true,
            }
          : {}),
        ...(contentFeedErrorMsg
          ? {
              [HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR]: contentFeedErrorMsg,
            }
          : {}),
      };

      updateSeenNotifications(
        newSeenNotifications,
        HEALTH_MENU_LOCALSTORAGE_KEY,
      );
    }
  }, [
    contentFeedErrorMsg,
    disconnectedAccountAPIs,
    hasDisconnectedTwitterApp,
    isHealthMenuOpen,
  ]);

  // Track when menu is opened
  React.useEffect(() => {
    if (isHealthMenuOpen && !prevIsHealthMenuOpen.current) {
      sendErrorTrackingEvents({
        contentFeedErrorMsg,
        disconnectedAccountAPIs,
        disconnectedTwitterPages,
        hasDisconnectedTwitterApp,
      });
    }

    prevIsHealthMenuOpen.current = isHealthMenuOpen;
  }, [
    contentFeedErrorMsg,
    disconnectedAccountAPIs,
    disconnectedTwitterPages,
    hasDisconnectedTwitterApp,
    isHealthMenuOpen,
  ]);

  useHealthMenuLocalStorageCleanup({
    disconnectedAccountAPIs,
    localStorageKey: HEALTH_MENU_LOCALSTORAGE_KEY,
    hasDisconnectedTwitterApp,
    hasContentFeedErrors,
  });

  // Close the health menu when unmounting
  React.useEffect(() => closeHealthMenu, [closeHealthMenu]);

  return (
    <Dropdown
      size="lg"
      gutter={4}
      isOpen={isHealthMenuOpen}
      onOpen={openHealthMenu}
      onClose={closeHealthMenu}
    >
      <Dropdown.Button
        as={Header.IconButton}
        aria-label={`Open Health Menu${
          showRedDotNotification ? ' - Unread Notifications' : ''
        }`}
        icon={<HealthIcon />}
        position="relative"
        _after={
          showRedDotNotification
            ? {
                content: '""',
                position: 'absolute',
                top: 0.5,
                right: 'px',
                height: 3,
                width: 3,
                bg: 'red.600',
                border: '2px',
                borderColor: 'gray.100',
                borderRadius: 'full',
              }
            : undefined
        }
      />
      <Dropdown.List maxH="440px" px={2} overflow="auto">
        <HealthMenuContent
          disconnectedAccountAPIs={disconnectedAccountAPIs}
          disconnectedTwitterApps={
            hasDisconnectedTwitterApp ? disconnectedTwitterPages : []
          }
          contentFeedErrorMsg={contentFeedErrorMsg}
        />
      </Dropdown.List>
    </Dropdown>
  );
};

export default HealthMenu;
