import { TEXT_DIRECTIONS } from 'common/constants';

/**
 * Mapping of ISO-639-3 language codes to text direction
 *
 * Generated using https://codepen.io/andyneale/pen/WYKbBJ
 */

export const iso6393toDirection = {
  aar: TEXT_DIRECTIONS.LTR,
  abk: TEXT_DIRECTIONS.LTR,
  afr: TEXT_DIRECTIONS.LTR,
  aka: TEXT_DIRECTIONS.LTR,
  amh: TEXT_DIRECTIONS.LTR,
  ara: TEXT_DIRECTIONS.RTL,
  arb: TEXT_DIRECTIONS.RTL,
  arg: TEXT_DIRECTIONS.LTR,
  asm: TEXT_DIRECTIONS.LTR,
  ava: TEXT_DIRECTIONS.LTR,
  ave: TEXT_DIRECTIONS.RTL,
  aym: TEXT_DIRECTIONS.LTR,
  aze: TEXT_DIRECTIONS.RTL,
  bak: TEXT_DIRECTIONS.LTR,
  bam: TEXT_DIRECTIONS.LTR,
  bel: TEXT_DIRECTIONS.LTR,
  ben: TEXT_DIRECTIONS.LTR,
  bis: TEXT_DIRECTIONS.LTR,
  bod: TEXT_DIRECTIONS.LTR,
  bos: TEXT_DIRECTIONS.LTR,
  bre: TEXT_DIRECTIONS.LTR,
  bul: TEXT_DIRECTIONS.LTR,
  cat: TEXT_DIRECTIONS.LTR,
  ces: TEXT_DIRECTIONS.LTR,
  cha: TEXT_DIRECTIONS.LTR,
  che: TEXT_DIRECTIONS.LTR,
  chu: TEXT_DIRECTIONS.LTR,
  chv: TEXT_DIRECTIONS.LTR,
  cmn: TEXT_DIRECTIONS.LTR,
  cor: TEXT_DIRECTIONS.LTR,
  cos: TEXT_DIRECTIONS.LTR,
  cre: TEXT_DIRECTIONS.LTR,
  cym: TEXT_DIRECTIONS.LTR,
  dan: TEXT_DIRECTIONS.LTR,
  deu: TEXT_DIRECTIONS.LTR,
  div: TEXT_DIRECTIONS.RTL,
  dzo: TEXT_DIRECTIONS.LTR,
  ell: TEXT_DIRECTIONS.LTR,
  eng: TEXT_DIRECTIONS.LTR,
  epo: TEXT_DIRECTIONS.LTR,
  est: TEXT_DIRECTIONS.LTR,
  eus: TEXT_DIRECTIONS.LTR,
  ewe: TEXT_DIRECTIONS.LTR,
  fao: TEXT_DIRECTIONS.LTR,
  fas: TEXT_DIRECTIONS.RTL,
  fij: TEXT_DIRECTIONS.LTR,
  fin: TEXT_DIRECTIONS.LTR,
  fra: TEXT_DIRECTIONS.LTR,
  fry: TEXT_DIRECTIONS.LTR,
  ful: TEXT_DIRECTIONS.LTR,
  gla: TEXT_DIRECTIONS.LTR,
  gle: TEXT_DIRECTIONS.LTR,
  glg: TEXT_DIRECTIONS.LTR,
  glv: TEXT_DIRECTIONS.LTR,
  grn: TEXT_DIRECTIONS.LTR,
  guj: TEXT_DIRECTIONS.LTR,
  hat: TEXT_DIRECTIONS.LTR,
  hau: TEXT_DIRECTIONS.RTL,
  heb: TEXT_DIRECTIONS.RTL,
  her: TEXT_DIRECTIONS.LTR,
  hin: TEXT_DIRECTIONS.LTR,
  hmo: TEXT_DIRECTIONS.LTR,
  hrv: TEXT_DIRECTIONS.LTR,
  hun: TEXT_DIRECTIONS.LTR,
  hye: TEXT_DIRECTIONS.LTR,
  ibo: TEXT_DIRECTIONS.LTR,
  iii: TEXT_DIRECTIONS.LTR,
  iku: TEXT_DIRECTIONS.LTR,
  ina: TEXT_DIRECTIONS.LTR,
  ind: TEXT_DIRECTIONS.LTR,
  ipk: TEXT_DIRECTIONS.LTR,
  isl: TEXT_DIRECTIONS.LTR,
  ita: TEXT_DIRECTIONS.LTR,
  jav: TEXT_DIRECTIONS.LTR,
  jpn: TEXT_DIRECTIONS.LTR,
  kal: TEXT_DIRECTIONS.LTR,
  kan: TEXT_DIRECTIONS.LTR,
  kas: TEXT_DIRECTIONS.RTL,
  kat: TEXT_DIRECTIONS.LTR,
  kau: TEXT_DIRECTIONS.LTR,
  kaz: TEXT_DIRECTIONS.RTL,
  khm: TEXT_DIRECTIONS.LTR,
  kik: TEXT_DIRECTIONS.LTR,
  kin: TEXT_DIRECTIONS.LTR,
  kir: TEXT_DIRECTIONS.RTL,
  kom: TEXT_DIRECTIONS.LTR,
  kon: TEXT_DIRECTIONS.LTR,
  kor: TEXT_DIRECTIONS.LTR,
  kua: TEXT_DIRECTIONS.LTR,
  kur: TEXT_DIRECTIONS.RTL,
  lao: TEXT_DIRECTIONS.LTR,
  lat: TEXT_DIRECTIONS.LTR,
  lav: TEXT_DIRECTIONS.LTR,
  lim: TEXT_DIRECTIONS.LTR,
  lin: TEXT_DIRECTIONS.LTR,
  lit: TEXT_DIRECTIONS.LTR,
  ltz: TEXT_DIRECTIONS.LTR,
  lub: TEXT_DIRECTIONS.LTR,
  lug: TEXT_DIRECTIONS.LTR,
  mah: TEXT_DIRECTIONS.LTR,
  mal: TEXT_DIRECTIONS.LTR,
  mar: TEXT_DIRECTIONS.LTR,
  mkd: TEXT_DIRECTIONS.LTR,
  mlg: TEXT_DIRECTIONS.LTR,
  mlt: TEXT_DIRECTIONS.LTR,
  mon: TEXT_DIRECTIONS.LTR,
  mri: TEXT_DIRECTIONS.LTR,
  msa: TEXT_DIRECTIONS.RTL,
  mya: TEXT_DIRECTIONS.LTR,
  nau: TEXT_DIRECTIONS.LTR,
  nav: TEXT_DIRECTIONS.LTR,
  nbl: TEXT_DIRECTIONS.LTR,
  nde: TEXT_DIRECTIONS.LTR,
  ndo: TEXT_DIRECTIONS.LTR,
  nep: TEXT_DIRECTIONS.LTR,
  nld: TEXT_DIRECTIONS.LTR,
  nno: TEXT_DIRECTIONS.LTR,
  nob: TEXT_DIRECTIONS.LTR,
  nor: TEXT_DIRECTIONS.LTR,
  nya: TEXT_DIRECTIONS.LTR,
  oci: TEXT_DIRECTIONS.LTR,
  oji: TEXT_DIRECTIONS.LTR,
  ori: TEXT_DIRECTIONS.LTR,
  orm: TEXT_DIRECTIONS.LTR,
  oss: TEXT_DIRECTIONS.LTR,
  pan: TEXT_DIRECTIONS.RTL,
  pli: TEXT_DIRECTIONS.LTR,
  pol: TEXT_DIRECTIONS.LTR,
  por: TEXT_DIRECTIONS.LTR,
  pus: TEXT_DIRECTIONS.RTL,
  que: TEXT_DIRECTIONS.LTR,
  roh: TEXT_DIRECTIONS.LTR,
  ron: TEXT_DIRECTIONS.LTR,
  run: TEXT_DIRECTIONS.LTR,
  rus: TEXT_DIRECTIONS.LTR,
  sag: TEXT_DIRECTIONS.LTR,
  san: TEXT_DIRECTIONS.LTR,
  sco: TEXT_DIRECTIONS.LTR,
  sin: TEXT_DIRECTIONS.LTR,
  slk: TEXT_DIRECTIONS.LTR,
  slv: TEXT_DIRECTIONS.LTR,
  sme: TEXT_DIRECTIONS.LTR,
  smo: TEXT_DIRECTIONS.LTR,
  sna: TEXT_DIRECTIONS.LTR,
  snd: TEXT_DIRECTIONS.RTL,
  som: TEXT_DIRECTIONS.LTR,
  sot: TEXT_DIRECTIONS.LTR,
  spa: TEXT_DIRECTIONS.LTR,
  sqi: TEXT_DIRECTIONS.LTR,
  srd: TEXT_DIRECTIONS.LTR,
  srp: TEXT_DIRECTIONS.LTR,
  ssw: TEXT_DIRECTIONS.LTR,
  sun: TEXT_DIRECTIONS.LTR,
  swa: TEXT_DIRECTIONS.LTR,
  swe: TEXT_DIRECTIONS.LTR,
  tah: TEXT_DIRECTIONS.LTR,
  tam: TEXT_DIRECTIONS.LTR,
  tat: TEXT_DIRECTIONS.LTR,
  tel: TEXT_DIRECTIONS.LTR,
  tgk: TEXT_DIRECTIONS.RTL,
  tgl: TEXT_DIRECTIONS.LTR,
  tha: TEXT_DIRECTIONS.LTR,
  tir: TEXT_DIRECTIONS.LTR,
  ton: TEXT_DIRECTIONS.LTR,
  tsn: TEXT_DIRECTIONS.LTR,
  tso: TEXT_DIRECTIONS.LTR,
  tuk: TEXT_DIRECTIONS.RTL,
  tur: TEXT_DIRECTIONS.LTR,
  uig: TEXT_DIRECTIONS.RTL,
  ukr: TEXT_DIRECTIONS.LTR,
  urd: TEXT_DIRECTIONS.RTL,
  uzb: TEXT_DIRECTIONS.RTL,
  ven: TEXT_DIRECTIONS.LTR,
  vie: TEXT_DIRECTIONS.LTR,
  vol: TEXT_DIRECTIONS.LTR,
  wln: TEXT_DIRECTIONS.LTR,
  wol: TEXT_DIRECTIONS.LTR,
  xho: TEXT_DIRECTIONS.LTR,
  yid: TEXT_DIRECTIONS.RTL,
  yor: TEXT_DIRECTIONS.LTR,
  zha: TEXT_DIRECTIONS.LTR,
  zho: TEXT_DIRECTIONS.LTR,
  zlm: TEXT_DIRECTIONS.LTR,
  zul: TEXT_DIRECTIONS.LTR,
};

export const ARTICLE_QUOTE_SUPPORTED_LANGUAGES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  SPANISH: 'es',
  GERMAN: 'de',
  ITALIAN: 'it',
  GREEK: 'el',
  ARABIC: 'ar',
  PORTUGUESE: 'pt',
  DUTCH: 'nl',
  RUSSIAN: 'ru',
  NORWEGIAN: 'no',
  CZECH: 'cs',
  INDONESIAN: 'id',
  CATALAN: 'ca',
  SWEDISH: 'sv',
  DANISH: 'dn',
  FINNISH: 'fi',
  HUNGARIAN: 'hu',
  ROMANIAN: 'ro',
  TURKISH: 'tr',
  JAPANESE: 'ja',
  POLISH: 'pl',
};
