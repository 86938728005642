import type { TrafficAPI } from 'types';
import { getProperty } from './accountAPIs';
import { TRAFFIC_API_STATES, TRAFFIC_API_TYPES } from './constants';

function getTrafficAPIs(propertyId: string): Array<TrafficAPI> {
  const currentProperty = getProperty({ propertyId });

  return 'trafficAPIs' in currentProperty
    ? Object.values(currentProperty.trafficAPIs)
    : [];
}

export function hasActiveWebTag(propertyId: string) {
  const trafficAPIs = getTrafficAPIs(propertyId);

  return trafficAPIs.some(
    (api) =>
      api.trafficAPIType === TRAFFIC_API_TYPES.EBX_TRACKER &&
      api.trafficAPIState === TRAFFIC_API_STATES.ACTIVE,
  );
}

export function hasActiveGoogleAnalytics(propertyId: string) {
  const trafficAPIs = getTrafficAPIs(propertyId);

  return trafficAPIs.some(
    (api) =>
      api.trafficAPIType === TRAFFIC_API_TYPES.GA_CORE &&
      api.trafficAPIState === TRAFFIC_API_STATES.ACTIVE,
  );
}
