/* eslint consistent-return: "off" */
import $ from 'jquery';

function setBackgroundColor(color: string) {
  if (typeof $ !== 'undefined') {
    try {
      $('.ebx-modal-outer').first().css('background-color', color);
    } catch (e) {
      //
    }
  }
}

function setModalActive(active: boolean) {
  let mouseIsDown = false;
  document.onkeydown = (e) => {
    if (e.which === 45 || mouseIsDown) {
      return false;
    }
  };

  document.onmousedown = () => {
    mouseIsDown = true;
  };

  document.onmouseup = () => {
    mouseIsDown = false;
  };

  if (typeof $ !== 'undefined') {
    if (active && typeof $ !== 'undefined') {
      const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
      $('html').css('overflow-y', 'hidden');
      $('body').css('margin-right', scrollBarWidth);
      $('.wrapper-header').css('margin-left', -(scrollBarWidth / 2));
    } else {
      $('html').css('overflow-y', 'scroll');
      $('body').css('margin-right', '');
      $('.wrapper-header').css('margin-left', 'auto');
    }
  }
}

export { setBackgroundColor, setModalActive };
