import {
  Button,
  Dropdown,
  DropdownItemProps,
  SocialFacebookIcon,
  SocialInstagramIcon,
  SocialLinkedinIcon,
  SocialTiktokIcon,
  SocialTwitterIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { API_TYPE_IDS, GLOBAL_INFO_STATES } from 'common/constants';
import { isIGCabinetEnabled } from 'common/settings';
import {
  connectTwitterApp,
  isTwitterProjectAppEnabled,
} from 'common/twitterConnect';
import AddFacebookModal from 'components/settings/pages/AddFacebookModal';
import AddInstagramModal from 'components/settings/pages/AddInstagramModal';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import ChevronDown from 'svg/ChevronDown';
import ChevronUp from 'svg/ChevronUp';
import type { APITypeId } from 'types';

import { getErrorMessage, getErrorStatus } from 'common/errorHandling';
import AddIGGraphApiModal from '../pages/AddIGGraphApiModal';
import ConnectTwitterAppModal from '../pages/ConnectTwitterAppModal';
import ConnectTwitterAppSuccessModal from '../pages/ConnectTwitterAppSuccessModal';
import { useSuccessfulTwitterAppConnection } from '../pages/useSuccessfulTwitterAppConnection';
import IsInstagramAccountReadyModal from './IsInstagramAccountReadyModal';

interface SocialMenuItemProps extends DropdownItemProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

const SocialMenuItem = ({
  children,
  onClick,
  ...rest
}: SocialMenuItemProps) => (
  <Dropdown.Item onClick={onClick} {...rest}>
    {children}
  </Dropdown.Item>
);

interface AddPageButtonProps {
  onHandleApiType: (apiTypeId: APITypeId) => () => Promise<void>;
}

const AddPageButton = ({ onHandleApiType }: AddPageButtonProps) => {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const location = useLocation();
  const navigate = useNavigate();
  const [showFacebookModal, setShowFacebookModal] = React.useState(false);
  const [showTwitterAppModal, setShowTwitterAppModal] = React.useState(false);
  const [showSuccessTwitterAppModal, setShowSuccessTwitterAppModal] =
    React.useState(false);
  const [showInstagramModal, setShowInstagramModal] = React.useState(false);
  const [isInstagramAccountReadyModal, setIsInstagramAccountReadyModal] =
    React.useState(false);

  const { showSuccessMessage } = useSuccessfulTwitterAppConnection();

  const twitterAppEnabled = isTwitterProjectAppEnabled({ globalInfo });

  const handleAddTwitter = React.useCallback(async () => {
    if (!twitterAppEnabled) {
      setShowTwitterAppModal(true);
    } else {
      try {
        await onHandleApiType(API_TYPE_IDS.TWITTER)();
      } catch (error) {
        // Check for a Twitter App connection error and start the connection flow
        if (
          getErrorStatus(error) === 400 &&
          getErrorMessage(error).includes(
            'Twitter could not validate your credentials.',
          )
        ) {
          setShowTwitterAppModal(true);
        }
      }
    }
  }, [onHandleApiType, twitterAppEnabled]);

  // If we detect a query parameter, automatically open the related modal
  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const apiName = queryParams.get('addNewPage');

    if (apiName) {
      switch (apiName) {
        case 'facebook':
          setShowFacebookModal(true);
          break;
        case 'instagram':
          if (!isIGCabinetEnabled()) {
            setIsInstagramAccountReadyModal(true);
          } else {
            setShowInstagramModal(true);
          }
          break;
        case 'twitter':
          handleAddTwitter();
          break;

        // no default
      }

      queryParams.delete('addNewPage');
      navigate(`${location.pathname}?${queryParams}`, { replace: true });
    }
  }, [
    handleAddTwitter,
    navigate,
    location.pathname,
    location.search,
    twitterAppEnabled,
  ]);

  const handleTwitterAppConnection = async (
    appKey: string,
    appSecret: string,
  ) => {
    await connectTwitterApp({ appKey, appSecret, globalInfo });

    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.REFRESHING,
      allowUnderSetup: true,
    });

    showSuccessMessage();
    setShowSuccessTwitterAppModal(true);
  };

  return (
    <>
      <Dropdown gutter={8}>
        {({ isOpen: menuOpen }) => (
          <>
            <Dropdown.Button
              as={Button}
              variant="secondary"
              rightIcon={
                menuOpen ? (
                  <ChevronUp width="16" height="16" />
                ) : (
                  <ChevronDown width="16" height="16" />
                )
              }
              data-cy-action="addPage"
            >
              Add page
            </Dropdown.Button>
            <Dropdown.List>
              <SocialMenuItem
                data-cy-action="addFacebookPage"
                onClick={() => setShowFacebookModal(true)}
                LeftIcon={SocialFacebookIcon}
              >
                Facebook
              </SocialMenuItem>
              <SocialMenuItem
                data-cy-action="addTwitterPage"
                onClick={handleAddTwitter}
                LeftIcon={SocialTwitterIcon}
              >
                Twitter
              </SocialMenuItem>
              <SocialMenuItem
                data-cy-action="addLinkedinPage"
                onClick={onHandleApiType(API_TYPE_IDS.LINKEDIN)}
                LeftIcon={SocialLinkedinIcon}
              >
                LinkedIn
              </SocialMenuItem>
              <SocialMenuItem
                data-cy-action="addInstagramPage"
                onClick={() => {
                  return !isIGCabinetEnabled()
                    ? setIsInstagramAccountReadyModal(true)
                    : setShowInstagramModal(true);
                }}
                LeftIcon={SocialInstagramIcon}
              >
                Instagram
              </SocialMenuItem>
              <SocialMenuItem
                data-cy-action="addTikTokPage"
                onClick={onHandleApiType(API_TYPE_IDS.TIKTOK)}
                LeftIcon={SocialTiktokIcon}
              >
                TikTok
              </SocialMenuItem>
            </Dropdown.List>
          </>
        )}
      </Dropdown>
      {showFacebookModal && (
        <AddFacebookModal
          onClose={() => setShowFacebookModal(false)}
          onSubmit={onHandleApiType(API_TYPE_IDS.FACEBOOK)}
        />
      )}
      {showTwitterAppModal && (
        <ConnectTwitterAppModal
          onClose={() => setShowTwitterAppModal(false)}
          onConnect={handleTwitterAppConnection}
        />
      )}
      {showSuccessTwitterAppModal && (
        <ConnectTwitterAppSuccessModal
          onAddTwitterPage={onHandleApiType(API_TYPE_IDS.TWITTER)}
          onClose={() => setShowSuccessTwitterAppModal(false)}
        />
      )}
      {showInstagramModal && !isIGCabinetEnabled() && (
        <AddIGGraphApiModal
          onClose={() => setShowInstagramModal(false)}
          onBack={() => setIsInstagramAccountReadyModal(true)}
          onSubmit={onHandleApiType(API_TYPE_IDS.INSTAGRAM)}
        />
      )}
      {showInstagramModal && isIGCabinetEnabled() && (
        <AddInstagramModal onClose={() => setShowInstagramModal(false)} />
      )}
      {isInstagramAccountReadyModal && (
        <IsInstagramAccountReadyModal
          onReady={() => setShowInstagramModal(true)}
          onClose={() => setIsInstagramAccountReadyModal(false)}
        />
      )}
    </>
  );
};

export default AddPageButton;
