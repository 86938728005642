import { Box, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { MEDIA_ITEM_VALIDATION_ERRORS } from 'common/config';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as tracker from 'common/tracker';
import MessageBox from 'components/compose/messagebox/MessageBox';
import Tooltip from 'components/misc/Tooltip';
import {
  firstCommentChange,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { getCommonTrackingParams } from 'helpers/tracking';
import type { FixTypeLater } from 'types';

interface FirstCommentProps {
  comment: string | null;
  guid: string;
  mediaItem: FixTypeLater;
}

const FirstComment = ({ comment, guid, mediaItem }: FirstCommentProps) => {
  const [isChecked, setIsChecked] = useState(
    comment !== null && comment !== '',
  );
  const { dispatch } = useComposeBoxContext();
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });

  const handleFirstCommentChange = () => {
    logger.info(`FirstComment:handleFirstCommentChange - guid ${guid}`);
    dispatch(firstCommentChange({ guid, isEnabled: !isChecked }));
  };

  const handleCheckboxChange = () => {
    const trackingParams = getCommonTrackingParams({
      mediaItem,
    });
    delete trackingParams['Social Page'];
    trackingParams['First Comment New Status'] = !isChecked;

    tracker.track({
      eventName: 'Toggle First Comment',
      trackingParams,
    });

    setIsChecked((prev) => !prev);
    handleFirstCommentChange();
  };

  const mediaItemError = MediaItem.getErrorMessage({ mediaItem });
  const hasError =
    mediaItemError === MEDIA_ITEM_VALIDATION_ERRORS.MAX_LENGTH_FIRST_COMMENT;

  return (
    <>
      <Flex mb={2} alignItems="center">
        <input
          type="checkbox"
          id={guid}
          data-cy-action="firstComment"
          name="firstComment"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor={guid} className="flex-grow-1">
          First comment
        </label>
        <Tooltip
          id="firstCommentIcon"
          placement="top"
          label="A comment added to your post, as soon as it is published"
        >
          <img
            className="info_icon"
            src="/img/icons/ic-information.svg"
            alt=""
            data-tip="true"
            data-for="firstCommentIcon"
            data-cy="firstCommentTooltip"
            data-iscapture="true"
          />
        </Tooltip>
      </Flex>
      {isChecked && (
        <Box
          border="1px"
          borderColor={hasError ? 'red.600' : '#B8C6D0'}
          borderRadius="3px"
          data-cy-id="firstCommentBox"
        >
          <MessageBox
            mediaItem={mediaItem}
            guid={guid}
            accountAPIId={accountAPIId}
            isFirstComment
            placeholder="Write a comment..."
          />
        </Box>
      )}
    </>
  );
};

export default FirstComment;
