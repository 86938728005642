import { z } from 'zod';

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({ socialAPIURNs: z.string().array() })
  .describe('getAPIsByTypesAndStates.ResponseSchema');

export default async function getAPIsByTypesAndStates({
  apiTypes,
  apiStates,
}: {
  apiTypes: string[];
  apiStates: string[];
}) {
  const guid = metrics.start('getAPIsByTypesAndStates');

  const apiTypesParameter = apiTypes.join(',');
  const apiStatesParameter = apiStates.join(',');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${apiTypesParameter}/${apiStatesParameter}`,
    method: 'GET',
    timeout: API_TIMEOUTS.M,
    headers: getClientServiceRequestHeaders(),
  };
  logger.info(
    `API:getAPIsByTypesAndStates social/api/${apiTypes}/${apiStates}`,
  );

  try {
    const response = await axios(config);
    metrics.end('getAPIsByTypesAndStates', guid);
    logger.debug('getAPIsByTypesAndStates', { apiTypes, apiStates });

    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData.socialAPIURNs;
  } catch (error) {
    metrics.fail('getAPIsByTypesAndStates', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getAPIsByTypesAndStates',
      args: { apiTypes, apiStates },
    });
    throw apiError;
  }
}
