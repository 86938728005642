import FlashMessagesContext from 'context/FlashMessageContext';

const withFlashMessages = (Component) => (props) => (
  <FlashMessagesContext.Consumer>
    {(flashMessagesContext) => (
      <Component {...props} {...flashMessagesContext} />
    )}
  </FlashMessagesContext.Consumer>
);

export default withFlashMessages;
