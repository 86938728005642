/**
 * List of exported methods
 */

/**
 * Returns a random integer in the range value +/- variance.
 *
 * @example randomish({ value: 10, variance: 5 }) // returns a random integer between 5 and 15
 */

function randomish({ value, variance }: { value: number; variance: number }) {
  return value - variance + Math.floor(Math.random() * (variance * 2 + 1));
}

/**
 * round - rounds a number to the specified number of decimal places
 */

function round({ value, decimals }: { value: number; decimals: number }) {
  return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);
}

export { randomish, round };
