import { memo } from 'react';

import { getDomain } from 'common/url';
import { arePropsEqual } from 'common/utility';

interface DomainProps {
  hasTitleFieldCharacterCount: boolean;
  title: string;
  url: string;
}

const Domain = (props: DomainProps) => {
  const domain = getDomain(props.url);

  return (
    <div className="d-flex align-items-center">
      <div className="share-website-url text-uppercase" data-cy="domain">
        {domain}
      </div>
      {props.hasTitleFieldCharacterCount && (
        <div className="character-count ml-auto">{props.title.length}</div>
      )}
    </div>
  );
};

export default memo(Domain, arePropsEqual);
