/* eslint consistent-return:"off" */
/* eslint no-use-before-define:"off" */

import {
  getCurrentProperty,
  getSocialAPIsByTypeAndState,
} from 'common/accountAPIs';
import {
  ACCOUNT_STATES,
  API_STATES,
  EBX_LAST_VISITED_PROPERTY_URN_KEY,
  EBX_LAST_VISITED_SOCIAL_PAGES_KEY,
} from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import { getStartingPage } from 'common/social';
import { extractPropertyId } from 'common/urn';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';

/**
 * List of exported methods
 */

export {
  getCurrentPropertyState,
  getCurrentPropertyStateId,
  getPropertiesByState,
  isPropertyNewSignup,
  resetCurrentPropertyAndAPI,
  setCurrentPropertyAndAPI,
};

/**
 * getCurrentPropertyState
 */

function getCurrentPropertyState({ globalInfo = getGlobalInfo() } = {}) {
  return getCurrentProperty({ globalInfo })?.propertyState ?? null;
}

/**
 * getCurrentPropertyStateId
 */

function getCurrentPropertyStateId({ globalInfo = getGlobalInfo() } = {}) {
  if (isNull(globalInfo)) {
    return null;
  }

  const currentProperty = getCurrentProperty({
    globalInfo,
  });
  if (!isNullOrUndefined(currentProperty)) {
    return currentProperty.propertyStateId;
  }
  return null;
}

/**
 * getPropertiesByState
 */

function getPropertiesByState({
  propertiesAndAPIs = mandatory('propertiesAndAPIs'),
  state = mandatory('state'),
} = {}) {
  const propertiesUnderSetup = [];
  Object.keys(propertiesAndAPIs).forEach((property) => {
    if (propertiesAndAPIs[property].propertyStateId === state) {
      propertiesUnderSetup.push(property);
    }
  });
  return propertiesUnderSetup;
}

/**
 * getPropertiesGivenAPIStateAPIs
 *
 * Remap properties structure into a simpler structure containing only active Facebook/Twitter APIs
 * @param  {object} propertiesAndAPIs - structure retrieved from getProperties endpoint
 * @return {object} - structure containing one key per property and an array of active APIs:
 *
 * propertiesAndActiveAPIs: {
 *   102: [240, 241, 691],
 *   733: [3291, 3293, 3294, 3298]
 * }
 */

function getPropertiesGivenAPIStateAPIs({
  propertiesAndAPIs = mandatory('propertiesAndAPIs'),
  states = [API_STATES.ACTIVE],
} = {}) {
  const propertiesAndActiveAPIs = {};
  Object.keys(propertiesAndAPIs).forEach((property) => {
    propertiesAndActiveAPIs[property] = [];
    const accountAPIs = propertiesAndAPIs[property].accountAPIs;
    if (isDefined(accountAPIs)) {
      const filteredSocialAPIs = getSocialAPIsByTypeAndState({
        accountAPIs,
        states,
      });
      propertiesAndActiveAPIs[property] = filteredSocialAPIs.map(
        (api) => api.accountAPIId,
      );
    }
  });
  return propertiesAndActiveAPIs;
}

/**
 * isPropertyNewSignup
 */

function isPropertyNewSignup({ globalInfo = mandatory('globalInfo') } = {}) {
  if (isNull(globalInfo)) {
    return null;
  }

  return (
    getCurrentProperty({
      globalInfo,
    }).propertyStateId === ACCOUNT_STATES.NEWSIGNUP
  );
}

function loadPageFromStorage(propertiesAndAPIs) {
  const propertyURNFromStorage = localStorage.getItem(
    EBX_LAST_VISITED_PROPERTY_URN_KEY,
  );

  if (propertyURNFromStorage != null) {
    const userPropertiesPages = getPropertiesGivenAPIStateAPIs({
      propertiesAndAPIs,
      states: [API_STATES.ACTIVE],
    });

    const userProperties = Object.keys(userPropertiesPages);
    const userPages = Object.values(userPropertiesPages).flat();

    const propertyIdFromStorage = extractPropertyId(propertyURNFromStorage);

    const propertiesPagesFromStorage = JSON.parse(
      localStorage.getItem(EBX_LAST_VISITED_SOCIAL_PAGES_KEY) ?? {},
    );

    const retrievedPageId = propertiesPagesFromStorage[propertyIdFromStorage];

    if (
      userProperties.includes(propertyIdFromStorage.toString()) &&
      userPages.includes(retrievedPageId) &&
      retrievedPageId !== 0
    ) {
      return {
        propertyId: propertyIdFromStorage.toString(),
        accountAPIId: retrievedPageId,
      };
    }
  }
  return null;
}

/**
 * resetCurrentPropertyAndAPI
 *
 * Reset current propertyId and accountAPIId if no longer present in revised properties list
 *
 * @param {object} currentSettings - current propertyId and accountAPIId
 * @param {object} newPropertiesAndAPIs - properties and APIs structure
 */

function resetCurrentPropertyAndAPI({
  currentSettings = mandatory('currentSettings'),
  propertiesAndAPIs = mandatory('propertiesAndAPIs'),
  allowUnderSetup = false,
} = {}) {
  const lastVisitedPage = loadPageFromStorage(propertiesAndAPIs);
  if (lastVisitedPage != null) {
    return lastVisitedPage;
  }

  // Convert newProperties to a structure we can work with - active APIs
  const propertiesAndActiveAPIs = getPropertiesGivenAPIStateAPIs({
    propertiesAndAPIs,
    states: [API_STATES.ACTIVE, API_STATES.UNDER_SETUP],
  });

  // If the existing property and account API exist, no changes required
  if (
    isDefined(propertiesAndActiveAPIs[currentSettings.propertyId]) &&
    propertiesAndActiveAPIs[currentSettings.propertyId].indexOf(
      currentSettings.accountAPIId,
    ) > -1
  ) {
    return currentSettings;
  }

  // If the existing property exists but the account API doesn't, see if we
  // can find a suitable API within the existing property
  if (isDefined(propertiesAndActiveAPIs[currentSettings.propertyId])) {
    const startingAPIId = getStartingPage({
      accountAPIs: propertiesAndAPIs[currentSettings.propertyId],
    });
    if (isDefined(startingAPIId)) {
      return {
        propertyId: currentSettings.propertyId,
        accountAPIId: startingAPIId,
      };
    }
  }

  // Convert newProperties to a structure we can work with - bad auth APIs
  const propertiesAndBadAuthAPIs = getPropertiesGivenAPIStateAPIs({
    propertiesAndAPIs,
    states: [API_STATES.BAD_AUTH],
  });

  // If the existing property and account API exist, no changes required
  if (
    isDefined(propertiesAndBadAuthAPIs[currentSettings.propertyId]) &&
    propertiesAndBadAuthAPIs[currentSettings.propertyId].indexOf(
      currentSettings.accountAPIId,
    ) > -1
  ) {
    return currentSettings;
  }

  // Last try with UNDER SETUP APIs...
  const propertiesAndUnderSetupAPIs = getPropertiesGivenAPIStateAPIs({
    propertiesAndAPIs,
    states: [API_STATES.UNDER_SETUP],
  });

  // If the existing property and account API exist, no changes required
  if (
    isDefined(propertiesAndUnderSetupAPIs[currentSettings.propertyId]) &&
    propertiesAndUnderSetupAPIs[currentSettings.propertyId].indexOf(
      currentSettings.accountAPIId,
    ) > -1
  ) {
    return currentSettings;
  }

  // If the specified property is under setup we are allowed to return null
  // for the account API to allow access to the setup page for the property
  if (allowUnderSetup) {
    return {
      propertyId: currentSettings.propertyId,
      accountAPIId: null,
    };
  }

  // Otherwise get the first property and the first active API within that property
  return setCurrentPropertyAndAPI({ propertiesAndAPIs });
}

/**
 * setCurrentPropertyAndAPI
 *
 * Set current propertyId and accountAPIId by finding first active API
 *
 * @param {object} propertiesAndAPIs - property and API data
 * @return {{
 *  propertyId: string | null,
 *  accountAPIId: number | null,
 * }} - structure containing propertyId and accountAPIId
 */

function setCurrentPropertyAndAPI({
  propertiesAndAPIs = mandatory('propertiesAndAPIs'),
} = {}) {
  const lastVisitedPage = loadPageFromStorage(propertiesAndAPIs);
  if (lastVisitedPage !== null) {
    return lastVisitedPage;
  }

  // Convert propertiesAndAPIs to a structure we can work with
  const propertiesAndActiveAPIs = getPropertiesGivenAPIStateAPIs({
    propertiesAndAPIs,
    states: [API_STATES.ACTIVE, API_STATES.BAD_AUTH],
  });

  // Loop over properties...
  const properties = Object.keys(propertiesAndActiveAPIs);

  for (let idx = 0; idx < properties.length; idx += 1) {
    const property = properties[idx];

    let startingAPIId = getStartingPage({
      accountAPIs: propertiesAndAPIs[property],
    });
    if (isUndefined(startingAPIId)) {
      startingAPIId = propertiesAndActiveAPIs[property][0];
    }

    if (propertiesAndActiveAPIs[property].length > 0) {
      return {
        propertyId: property,
        accountAPIId: startingAPIId,
      };
    }
  }

  // Check if there is any account new signup
  const propertiesNewSignup = getPropertiesByState({
    propertiesAndAPIs,
    state: ACCOUNT_STATES.NEWSIGNUP,
  });
  if (propertiesNewSignup.length > 0) {
    return {
      propertyId: propertiesNewSignup[0],
      accountAPIId: null,
    };
  }

  // Check if there is any account under setup
  const propertiesUnderSetup = getPropertiesByState({
    propertiesAndAPIs,
    state: ACCOUNT_STATES.UNDERSETUP,
  });
  if (propertiesUnderSetup.length > 0) {
    return {
      propertyId: propertiesUnderSetup[0],
      accountAPIId: null,
    };
  }

  // Check if there is any account suspended
  const propertiesNewSuspended = getPropertiesByState({
    propertiesAndAPIs,
    state: ACCOUNT_STATES.SUSPENDED,
  });
  if (propertiesNewSuspended.length > 0) {
    return {
      propertyId: propertiesNewSuspended[0],
      accountAPIId: null,
    };
  }

  if (!isNullOrUndefined(properties[0])) {
    return {
      propertyId: properties[0],
      accountAPIId: null,
    };
  }
  return {
    propertyId: null,
    accountAPIId: null,
  };
}
