import { RefObject } from 'react';

import {
  Button,
  Flex,
  Modal,
  Text,
  forwardRef,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as accountAPIs from 'common/accountAPIs';

import { useGlobalInfo } from 'context/GlobalInfoContext';

interface ConfirmTurnOnAutomationProps {
  accountAPIId: number;
  isOpen: boolean;
  onClose: () => void;
  handleAutomationChange: (toggleApprovals: boolean) => void;
}

const ConfirmTurnOnAutomation = forwardRef<ConfirmTurnOnAutomationProps, 'div'>(
  ({ accountAPIId, isOpen, onClose, handleAutomationChange }, ref) => {
    const { global } = useGlobalInfo();
    const globalInfo = global.getGlobalInfo();

    const accountAPI = accountAPIs.getAccountAPI({
      accountAPIId,
      globalInfo,
    });
    const pageName = accountAPI ? accountAPI.apiPostName : '';

    const handleAutomation = (toggleApproval: boolean) => {
      handleAutomationChange(toggleApproval);
      onClose();
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="small"
        {...(ref && { finalFocusRef: ref as RefObject<HTMLDivElement> })}
      >
        <Modal.Header>
          <Modal.Title>Turn Approvals on?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text size="sm" color="gray.600">
            Should automated shares for{' '}
            <span style={{ fontWeight: 500 }}>{pageName}</span> require your
            approval before being shared
          </Text>
        </Modal.Body>
        <Modal.Footer justifyContent="space-between">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Flex gap={2}>
            <Button variant="secondary" onClick={() => handleAutomation(false)}>
              Keep Approvals off
            </Button>
            <Button onClick={() => handleAutomation(true)}>
              Turn Approvals on
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    );
  },
);

export default ConfirmTurnOnAutomation;
