/* eslint no-use-before-define:"off" */

import { NESTED_SHARE_URL_PATTERNS } from 'common/config';
import * as logger from 'common/logger';
import { ebxMessage, generateGuid } from 'common/string';
import { isNull, isNullOrUndefined, isUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export {
  addCacheBuster,
  escapeURL,
  extractURLElements,
  getDomain,
  getFileSuffix,
  getFragmentParams,
  getHostname,
  getProtocol,
  getQueryParams,
  getQueryStringValue,
  getRootDomain,
  getRootURL,
  getShareParameters,
  isNestedShare,
  isValidURL,
  isVideoURL,
  objectToQueryString,
  queryStringToObject,
  removeTimestamp,
  renderShortLink,
  splitURL,
  stripAndEncodeSpaces,
  stripAndEncodeSpacesInURL,
  unescapeURL,
  validateURL,
};

/**
 * addCacheBuster
 *
 * @param {{
 *  originalURL: string;
 *  cacheBuster?: string;
 * }}
 */

function addCacheBuster({
  originalURL = mandatory('originalURL'),
  cacheBuster = generateGuid(),
} = {}) {
  const [strippedURL, queryParameters, fragmentParameters] =
    extractURLElements(originalURL);

  let updatedURL = strippedURL;
  if (queryParameters === '') {
    updatedURL += `?${cacheBuster}`;
  } else {
    updatedURL += `?${queryParameters}&${cacheBuster}`;
  }
  if (fragmentParameters !== '') {
    updatedURL += `#${fragmentParameters}`;
  }

  return updatedURL;
}

/**
 * addURLParameters
 */

/**
 * decodeURLComponentWithFallbackAndLogging
 *
 * @param {string} originalURL
 */

function decodeURIComponentWithFallbackAndLogging(originalURL) {
  let decodedURL;
  try {
    decodedURL = decodeURIComponent(originalURL);
    if (decodedURL !== originalURL) {
      logger.error({
        event: 'Decode URI',
        args: {
          OriginalURL: originalURL,
          DecodedURL: decodedURL,
        },
      });
      decodedURL = originalURL;
    }
  } catch (e) {
    decodedURL = originalURL;
  }

  return decodedURL;
}

/**
 * escapeURL
 */

function escapeURL(url) {
  return url.replace(/&/g, '&amp;');
}

/**
 * extractURLElements
 *
 * @param {string} url
 */

function extractURLElements(url) {
  let strippedURL = decodeURIComponentWithFallbackAndLogging(url);

  // Break out the hash portion, e.g. everything after the hash
  let fragmentParameters = '';
  if (strippedURL.indexOf('#') !== -1) {
    // Split on the first hash only
    const hashSplit = strippedURL.split(/#(.*)/);
    fragmentParameters = hashSplit[1];
    // URL now becomes everything except everything after the hash
    strippedURL = hashSplit[0];
  }

  // Break out any remaining query portion
  let queryParameters = '';
  if (strippedURL.indexOf('?') !== -1) {
    // Split on the first ? only
    const querySplit = strippedURL.split(/\?(.*)/);
    queryParameters = querySplit[1];
    // URL no longer contains any query or hash portion
    strippedURL = querySplit[0];
  }

  return [strippedURL, queryParameters, fragmentParameters];
}

/**
 * getDomain
 */

function getDomain(url) {
  try {
    // Strip off protocol prefix
    const path = url.replace(/https?:\/\/(.*)/gi, '$1');
    // Strip off anything following the first slash
    const domain = path.split('/')[0];

    return domain;
  } catch (e) {
    return null;
  }
}

/**
 * getFileSuffix
 */

function getFileSuffix(url) {
  try {
    return splitURL(url)
      .rootURL.match(/\.[0-9a-z]+$/i)[0]
      .substr(1);
  } catch (e) {
    return '';
  }
}

/**
 * getFragmentParams
 */

function getFragmentParams(fullURL) {
  const fragmentParams = fullURL.split('#')[1];
  if (isUndefined(fragmentParams)) {
    return [];
  }

  return fragmentParams.split('?')[0].split('&');
}

/**
 * getProtocol
 */

function getProtocol(fullURL) {
  try {
    const urlParts = fullURL.split('://');
    if (urlParts.length < 2) {
      return null;
    }
    return urlParts[0];
  } catch (e) {
    return null;
  }
}

/**
 * getQueryParams
 */

function getQueryParams(fullURL) {
  const queryParams = fullURL.split('?')[1];
  if (isUndefined(queryParams)) {
    return [];
  }

  return queryParams.split('#')[0].split('&');
}

/**
 * getQueryStringValue
 * @param {{ queryParams: string, name: string }}
 * @returns {string} the query string value
 */
function getQueryStringValue({
  queryParams = mandatory('queryParams'),
  name = mandatory('name'),
}) {
  const regex = new RegExp('[\\?&]'.concat(name, '=([^&#]*)'));
  const results = regex.exec(queryParams);
  return isNull(results)
    ? ''
    : decodeURIComponentWithFallbackAndLogging(results[1].replace(/\+/g, ' '));
}

/**
 * getRootDomain
 */

function getRootDomain(url) {
  try {
    // Check that protocol exists
    const protocol = url.match(/(https?:\/\/).*/gi);
    if (protocol === null) {
      return null;
    }
    // Strip off protocol prefix
    const path = url.replace(/https?:\/\/(.*)/gi, '$1');
    // Strip off anything following the first slash
    let domain = path.split('/')[0];
    // Strip off a leading www. if it exists
    domain = domain.replace(/^www./gi, '');
    return domain;
  } catch (e) {
    return null;
  }
}

/**
 * getRootURL
 */

function getRootURL(fullURL) {
  return fullURL.split('?')[0].split('#')[0];
}

/**
 * getShareParameters
 */

function getShareParameters(fullURL) {
  return fullURL.replace(getRootURL(fullURL), '');
}

/**
 * isNestedShare
 */

function isNestedShare(url) {
  return NESTED_SHARE_URL_PATTERNS.some((pattern) => url.match(pattern));
}

/**
 * isValidURL
 */

function isValidURL(url, isPathAllowed = true) {
  /*
  const regexp = isPathAllowed
    ? /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    : /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(\/)?$/;
    */
  const base =
    '^(?:(?:https?):\\/\\/)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\\d{2,5})?';
  const regexp = new RegExp(
    `${base}${isPathAllowed ? '(?:\\/\\S*)?$' : '(\\/)?$'}`,
  );
  return regexp.test(encodeURI(url));
}

/**
 * isVideoURL
 */

function isVideoURL(url) {
  if (isNullOrUndefined(url)) {
    return false;
  }
  const regex =
    /https?:\/\/((www|m)\.)?(youtu(be|\.be)(\.com)?|dailymotion\.com|vimeo\.com)/gi;
  return !isNull(url.match(regex));
}

/**
 * objectToQueryString
 */

function objectToQueryString(queryObject, queryPrefix) {
  const queryArray = [];
  let queryString = '';
  let queryValue;
  Object.keys(queryObject).forEach((queryKey) => {
    queryValue = queryObject[queryKey];
    if (queryValue !== '') {
      queryArray.push(`${queryKey}=${queryValue}`);
    } else {
      queryArray.push(`${queryKey}`);
    }
  });
  if (queryArray.length > 0) {
    queryString += queryPrefix + queryArray.join('&');
  }

  return queryString;
}

/**
 * queryStringToObject
 */

function queryStringToObject(queryString) {
  const queryObject = {};
  const queryArray = queryString.split('&');
  queryArray.forEach((queryPair) => {
    const queryEntry = queryPair.split('=');
    if (queryEntry.length === 2) {
      queryObject[queryEntry[0]] = queryEntry[1];
    } else if (queryEntry.length > 2) {
      // In the case where the value entry contains an encoded equals %3D but it was decoded should
      // be part of the query object
      queryObject[queryEntry[0]] = queryPair.substring(
        queryPair.indexOf('=') + 1,
      );
    }
  });
  return queryObject;
}

/**
 * removeTimestamp
 *
 * Removes the Echobox=9999999999 timestamp from a URL
 *
 * @param  {string} url [description]
 * @return {string}     [description]
 */

function removeTimestamp(url) {
  return (
    url
      .replace(/&?Echobox=(\d{10})/gi, '') // Remove timestamp parameter
      // Now clean up any trailing parameter elements after removing the timestamp...
      .replace(/\?$/gi, '') // Remove trailing query parameter indicator
      .replace(/#$/gi, '') // Remove trailing fragment parameter indicator
      .replace(/\?&/, '?') // Remove dangling query parameter separator
      .replace(/#&/, '#') // Remove dangling fragment parameter separator
  );
}

/**
 * renderShortURL
 */

function renderShortLink(shortURL) {
  return `<span className="tage" contenteditable="false">${shortURL}</span>`;
}

/**
 * splitURL
 */

function splitURL(fullURL) {
  const rootURL = getRootURL(fullURL);
  const shareParameters = getShareParameters(fullURL);
  const queryParams = getQueryParams(fullURL);
  const fragmentParams = getFragmentParams(fullURL);
  return { rootURL, shareParameters, queryParams, fragmentParams };
}

/**
 * stripAndEncodeSpaces
 *
 * Removes any leading or trailing spaces from a string and encodes any remaining spaces as plus symbols
 */

function stripAndEncodeSpaces(fullURL) {
  return fullURL.trim().replace(/ /g, '+');
}

/**
 * stripAndEncodeSpacesInParameter
 */

function stripAndEncodeSpacesInParameter(parameter) {
  const paramParts = parameter.split('=');
  if (paramParts.length === 1) {
    return stripAndEncodeSpaces(paramParts[0]);
  }
  return `${stripAndEncodeSpaces(paramParts[0])}=${stripAndEncodeSpaces(
    paramParts[1],
  )}`;
}

/**
 * stripAndEncodeSpacesInURL
 *
 * Removes any leading or trailing spaces from a URL parameter name or value
 * and encodes any remaining spaces as plus symbols in the query/fragment
 * parameters section ONLY for the supplied URL
 */

function stripAndEncodeSpacesInURL(fullURL) {
  const rootURL = getRootURL(fullURL);
  const queryParams = getQueryParams(fullURL);
  const fragmentParams = getFragmentParams(fullURL);

  const encodedQueryParams = [];
  const encodedFragmentParams = [];
  queryParams.forEach((queryParam) => {
    encodedQueryParams.push(stripAndEncodeSpacesInParameter(queryParam));
  });
  fragmentParams.forEach((fragmentParam) => {
    encodedFragmentParams.push(stripAndEncodeSpacesInParameter(fragmentParam));
  });

  let encodedURL = rootURL;
  if (encodedQueryParams.length > 0) {
    encodedURL += `?${encodedQueryParams.join('&')}`;
  }
  if (encodedFragmentParams.length > 0) {
    encodedURL += `#${encodedFragmentParams.join('&')}`;
  }

  return encodedURL;
}

/**
 * unescapeURL
 *
 * Because URLs are edited in a ContentEditable container, some of the characters
 * in the URL have to be encoded as HTML entities so that they render correctly -
 * this methods takes these entities and converts them back to regular characters
 *
 * @param {string} url - the URL to unescape
 * @result string - the unescaped URL
 */

function unescapeURL(url) {
  return url
    .replace(/<\/?[^>]+(>|$)/g, '') // Remove invalid characters
    .replace(/&amp;/gi, '&') // Convert encoded & symbols back from HTML entities
    .replace(/&nbsp;/gi, ' ');
}

/**
 * validateURL
 *
 * @param {string} articleURL - the URL to validate
 * @param {boolean} asPromise - should the result be returned as a promise
 * @result Promise | boolean - if {asPromise} is true, the result will be returned
 *                             as a promise, which will resolve with the updated URL
 *                             (an http:// prefix will be added if this is missing)
 *                             if the URL is valid, or reject with a suitable error
 *                             message if the URL is invalid
 *                             if {asPromise} is false, it will return a simple boolean
 *                             indicating whether the URL is valid or not
 */

function validateURL(articleURL, asPromise = true) {
  let url = articleURL.trim();
  let isValid = true;
  let errorMessage = '';

  if (url === '') {
    isValid = false;
    errorMessage = "Please ensure the URL isn't empty";
  }
  if (
    isValid &&
    url.indexOf('http://') !== 0 &&
    url.indexOf('https://') !== 0
  ) {
    url = `http://${url}`;
  }
  if (isValid && !isValidURL(url)) {
    isValid = false;
    errorMessage = 'This URL is invalid';
  }

  if (asPromise) {
    return new Promise((resolve, reject) => {
      if (isValid) {
        resolve(url);
      } else {
        reject(ebxMessage(errorMessage));
      }
    });
  }
  return isValid;
}

/**
 * Extracts the hostname from the URL
 */
const getHostname = (fullURL) => {
  const parser = document.createElement('a');
  parser.href = fullURL;
  let hostname = parser.hostname;
  if (hostname.startsWith('www')) {
    hostname = hostname.slice(4);
  }
  return hostname;
};
