import PropTypes from 'prop-types';

import CodeInput from 'components/misc/CodeInput';

/**
 * React component for rendering Step 2 of MFA
 * Renders an input for entering code from authenticator app
 * Accepts the following props:
 * 1) userInput    - state variable for setting value to input
 * 2) handleChange - function to update userInput state variable
 * 3) error        - error message to be displayed in case of failure
 */
const Step2 = ({ userInput, handleChange, error }) => {
  return (
    <>
      <p>Enter the six digit code from the authenticator app</p>
      <CodeInput
        type="number"
        fields={6}
        isValid={!error}
        value={userInput}
        error={error}
        onChange={handleChange}
      />
    </>
  );
};

Step2.defaultProps = {
  error: '',
};

Step2.propTypes = {
  userInput: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default Step2;
