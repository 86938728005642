// eslint-disable-next-line camelcase
import { unstable_usePrompt } from 'react-router-dom';

interface PromptProps {
  /**
   * A value or function that returns a value indicating whether navigation should be blocked.
   */
  when: boolean;

  /**
   * The message to show when navigation is blocked.
   */
  message: string;
}

/**
 * Displays a prompt when the user tries to navigate away from the current page.
 */
const Prompt = ({ when, message }: PromptProps) => {
  unstable_usePrompt({ message, when });

  return null;
};

export default Prompt;
