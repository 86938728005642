import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { convertToPropertyURN } from 'common/urn';

export default async function deletePropertiesSynd({
  propertyId,
  syndFeedURN,
}: {
  propertyId: string;
  syndFeedURN: string;
}) {
  const guid = metrics.start('deletePropertiesSynd');

  const propertyURN = convertToPropertyURN(propertyId);
  // Need to encode the syndfeedURN as it contains a url as part of the urn, which in turn
  // contains special characters in urls (for example ./#&). These can cause the request to
  // return a MalformedURL Error
  const encodedSyndFeedURN = encodeURIComponent(syndFeedURN);
  const clientAuthHeader = getClientServiceRequestHeaders();
  const config = {
    url: `${constructBaseCoreAPIURL()}/properties/${propertyURN}/syndfeeds/${encodedSyndFeedURN}`,
    method: 'DELETE',
    timeout: API_TIMEOUTS.S,
    headers: clientAuthHeader,
  };

  logger.info(
    `API:deletePropertiesSynd /properties/${propertyURN}/syndfeeds/${encodedSyndFeedURN}`,
  );

  try {
    await axios(config);
    metrics.end('deletePropertiesSynd', guid);
    logger.debug('deletePropertiesSynd', { propertyId, syndFeedURN });
  } catch (error) {
    metrics.fail('deletePropertiesSynd', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/deletePropertiesSynd',
      args: {
        propertyId,
        syndFeedURN,
      },
    });
    throw apiError;
  }
}
