import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { RssFeedDataSchema } from 'common/schemas';
import { convertToPropertyURN } from 'common/urn';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({
    feedData: RssFeedDataSchema,
  })
  .describe('postPropertiesSyndValidate.ResponseSchema');

/**
 * POST /properties/{propertyId}/syndfeeds/validate - Add a new syndication feed to the specified account.
 * The feed is assumed to be valid if we can access at least one article.
 *
 * @param feedURL - syndication feed URL
 * @return synd feed information
 */
export default async function postPropertiesSyndValidate({
  propertyId,
  feedURL,
}: {
  propertyId: string;
  feedURL: string;
}) {
  const guid = metrics.start('postPropertiesSyndValidate');

  const propertyURN = convertToPropertyURN(propertyId);
  const clientAuthHeader = getClientServiceRequestHeaders({
    'Content-Type': 'application/json',
  });
  const config = {
    url: `${constructBaseCoreAPIURL(
      'v1.1',
    )}/properties/${propertyURN}/syndfeeds/validate`,
    method: 'POST',
    timeout: API_TIMEOUTS.XXL,
    headers: clientAuthHeader,
    data: JSON.stringify({
      feedURL,
    }),
  };
  logger.info(
    `API:postPropertiesSyndValidate /properties/${propertyURN}/syndfeeds/validate ${feedURL}`,
  );

  try {
    const response = await axios(config);
    metrics.end('postPropertiesSyndValidate', guid);
    logger.debug('postPropertiesSyndValidate', { propertyId, feedURL });
    const validatedData = lyingParse(ResponseSchema, response.data);
    return validatedData;
  } catch (error) {
    metrics.fail('postPropertiesSyndValidate', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postPropertiesSyndValidate',
      args: {
        propertyId,
        feedURL,
      },
    });
    throw apiError;
  }
}
