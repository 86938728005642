import {
  Alert,
  Button,
  ChevronLeftIcon,
  Flex,
  Spinner,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import getPropertySettingsByType from 'api/getPropertySettingsByType';
import postServiceAuth from 'api/postServiceAuth';
import * as authentication from 'common/authentication';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import {
  CustomShareTimeSettingsSchema,
  FeatureToggleSettingsSchema,
  liveStoryByDefaultSettingsSchema,
} from 'common/schemas';
import { lyingParse } from 'common/zod';
import FeatureToggleSettingsCard from 'components/header/PropertyToolsModal/FeatureToggleSettingsCard';
import OptimalShareTimeWindowCard from 'components/header/PropertyToolsModal/OptimalShareTimeWindowCard';
import type {
  CustomShareTimeWindowSettings,
  FeatureToggle,
  FeatureToggleSettings,
  liveStoryByDefaultSettings,
} from 'types';
import LiveStoryCard from './LiveStoryCard';

type State =
  | { status: 'loading' }
  | { status: 'error' }
  | {
      status: 'success';
      featureToggleSettings: FeatureToggleSettings;
      customShareTimeWindowSettings: CustomShareTimeWindowSettings;
      liveStoryByDefaultSettings: liveStoryByDefaultSettings;
    };

const initialState: State = { status: 'loading' };

type ActionType =
  | {
      type: 'LOAD_PROPERTY_SETTINGS_SUCCESS';
      payload: {
        customShareTimeWindowSettings: CustomShareTimeWindowSettings;
        featureToggleSettings: FeatureToggleSettings;
        liveStoryByDefaultSettings: liveStoryByDefaultSettings;
      };
    }
  | {
      type: 'LOAD_PROPERTY_SETTINGS_FAILURE';
    }
  | {
      type: 'TOGGLE_FEATURE_TOGGLE';
      payload: {
        settingName: FeatureToggle;
      };
    };

function reducer(state: State, action: ActionType): State {
  switch (action.type) {
    case 'LOAD_PROPERTY_SETTINGS_FAILURE': {
      return {
        status: 'error',
      };
    }

    case 'LOAD_PROPERTY_SETTINGS_SUCCESS': {
      return {
        status: 'success',
        customShareTimeWindowSettings:
          action.payload.customShareTimeWindowSettings,
        featureToggleSettings: action.payload.featureToggleSettings,
        liveStoryByDefaultSettings: action.payload.liveStoryByDefaultSettings,
      };
    }

    case 'TOGGLE_FEATURE_TOGGLE': {
      if (state.status === 'success') {
        const { settingName } = action.payload;
        const currentSettingValue = state.featureToggleSettings[settingName];

        return {
          ...state,
          featureToggleSettings: {
            ...state.featureToggleSettings,
            [settingName]: !currentSettingValue,
          },
        };
      }

      return state;
    }

    default:
      throw new Error(`Invalid action type: ${(action as any).type}`);
  }
}

interface UpdatePropertySettingsProps {
  propertyId: number;
  propertyName: string;
  propertyURN: string;
  onBackClick: () => void;
}

const UpdatePropertySettings = ({
  propertyId,
  propertyName,
  propertyURN,
  onBackClick,
}: UpdatePropertySettingsProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleFeatureTogglesSettingToggle = (settingName: FeatureToggle) => {
    dispatch({ type: 'TOGGLE_FEATURE_TOGGLE', payload: { settingName } });
  };

  React.useEffect(() => {
    const loadPropertySettings = async () => {
      const userCSToken = authentication.getClientServiceToken();

      try {
        const impersonateCSToken = await postServiceAuth({
          overridePropertyURN: propertyURN,
        });

        authentication.setClientServiceToken(impersonateCSToken);

        const { entries } = await getPropertySettingsByType({
          propertyId: propertyId.toString(),
          settingTypeIds: [
            ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL,
            ACCOUNT_SETTING_TYPES.FEATURE_TOGGLES,
            ACCOUNT_SETTING_TYPES.LIVE_STORY_DEFAULT,
          ],
        });

        const customShareTimeWindowSettings = lyingParse(
          CustomShareTimeSettingsSchema,
          JSON.parse(
            entries[ACCOUNT_SETTING_TYPES.CUSTOM_AUTO_SHARE_INTERVAL]?.[0]
              .dataJSON ?? '{}',
          ),
        );

        const featureToggleSettings = lyingParse(
          FeatureToggleSettingsSchema,
          JSON.parse(
            entries[ACCOUNT_SETTING_TYPES.FEATURE_TOGGLES]?.[0].dataJSON ??
              '{}',
          ),
        );

        const liveStoryByDefaultSettings = lyingParse(
          liveStoryByDefaultSettingsSchema,
          JSON.parse(
            entries[ACCOUNT_SETTING_TYPES.LIVE_STORY_DEFAULT]?.[0].enabled
              ? 'true'
              : 'false',
          ),
        );

        dispatch({
          type: 'LOAD_PROPERTY_SETTINGS_SUCCESS',
          payload: {
            customShareTimeWindowSettings,
            featureToggleSettings,
            liveStoryByDefaultSettings,
          },
        });
      } catch (error) {
        console.error(error);
        dispatch({ type: 'LOAD_PROPERTY_SETTINGS_FAILURE' });
      } finally {
        authentication.setClientServiceToken(userCSToken);
      }
    };

    loadPropertySettings();
  }, [propertyId, propertyURN]);

  return (
    <Flex flexDirection="column" gap={6}>
      <Button
        variant="secondary"
        w="fit-content"
        onClick={onBackClick}
        leftIcon={<ChevronLeftIcon />}
      >
        Back to details
      </Button>
      {state.status === 'loading' && (
        <Flex justifyContent="center">
          <Spinner size="lg" />
        </Flex>
      )}
      {state.status === 'success' && (
        <>
          <FeatureToggleSettingsCard
            featureToggleSettings={state.featureToggleSettings}
            onFeatureTogglesSettingToggle={handleFeatureTogglesSettingToggle}
            propertyId={propertyId}
            propertyURN={propertyURN}
          />
          <OptimalShareTimeWindowCard
            customShareTimeWindowSettings={state.customShareTimeWindowSettings}
            propertyId={propertyId}
            propertyName={propertyName}
            propertyURN={propertyURN}
          />
          <LiveStoryCard
            currentToggleState={state.liveStoryByDefaultSettings}
            propertyId={propertyId}
            propertyURN={propertyURN}
          />
        </>
      )}
      {state.status === 'error' && (
        <Alert variant="error">
          <Alert.Description>
            An unexpected error occurred. Please try again and if the problem
            persists, contact the tech team.
          </Alert.Description>
        </Alert>
      )}
    </Flex>
  );
};

export default UpdatePropertySettings;
