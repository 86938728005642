import { Input, InputProps } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { useIMask } from 'react-imask';

interface AddInstagramMFAInputProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  onChange: (value: string) => void;
}

const AddInstagramMFAInput = ({
  onChange,
  ...props
}: AddInstagramMFAInputProps) => {
  const { ref } = useIMask(
    { mask: '0000 0000', eager: 'remove' },
    {
      onAccept: (value) => {
        onChange(value.replace(' ', ''));
      },
    },
  );

  return (
    <Input
      placeholder="••••  ••••"
      type="text"
      inputMode="numeric"
      ref={ref as React.RefObject<HTMLInputElement>}
      {...props}
    />
  );
};

export default AddInstagramMFAInput;
