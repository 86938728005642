import { Tr as BaseTr } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

type TrProps = React.ComponentPropsWithRef<typeof BaseTr> & {
  children: React.ReactNode;
};

const Tr = (props: TrProps) => {
  return (
    <BaseTr
      boxShadow="borderBottom"
      _last={{
        boxShadow: 'none',
      }}
      {...props}
    />
  );
};

export default Tr;
