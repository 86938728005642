/* global mixpanel */

import { Center, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as API from 'api/api';
import {
  getAccountAPIPermission,
  getCurrentAccountAPIId,
} from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import {
  EBX_LAST_USED_PRODUCT_KEY,
  EMAIL,
  FRONTEND_METRICS,
  IFRAME_MESSAGE_TIMEOUT,
  LOGIN_APP_MESSAGES,
  LOGIN_STARTED,
  NO_EXPIRY_DATE,
  NO_PERMISSIONS_PREFIX,
  PERMISSION_TYPES,
  ROUTE_REDIRECTIONS,
  USER_TYPES,
} from 'common/constants';
import * as cookie from 'common/cookie';
import * as environment from 'common/environment';
import { getGlobalInfo, storeGlobalInfo } from 'common/globalInfo';
import { redirectBrowser } from 'common/location';
import * as logger from 'common/logger';
import * as loginApp from 'common/loginapp';
import * as metrics from 'common/metrics';
import * as object from 'common/object';
import * as tracker from 'common/tracker';
import { retry, until } from 'common/utility';
import { getZendeskRedirectURL, getZendeskReturnURL } from 'common/zendesk';
import setUpGlobalData from 'process/setUpGlobalData';

const loginUrl = environment.getLoginUrl();

const saveTokens = (tokens) => {
  authentication.clearCognitoTokens();
  Object.keys(tokens).forEach((key) => {
    const value =
      typeof tokens[key] === 'object'
        ? JSON.stringify(tokens[key])
        : tokens[key];
    cookie.setCookieValue(encodeURIComponent(key), value, {
      sameSite: 'strict',
    });
  });
};

const getTokens = async (loginStartedTime, email) => {
  loginApp.loadApp();
  await until(
    () => sessionStorage.getItem('isGettingTokens') === 'false',
    () => loginApp.postMessage(LOGIN_APP_MESSAGES.GET_TOKENS),
    100,
    IFRAME_MESSAGE_TIMEOUT,
  );

  if (
    sessionStorage.getItem('isGettingTokens') === 'true' &&
    loginStartedTime.current
  ) {
    logger.error({
      event: 'GET_TOKENS_FAILED',
      properties: {
        UserEmailAddress: email.current,
      },
    });
    throw new Error('Cannot get tokens from login app');
  }
};

const domain = cookie.getTLD(window.location.hostname);

function SessionWrapper({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isSignupOrLogout =
    location.pathname === ROUTE_REDIRECTIONS.LOGOUT ||
    location.pathname === ROUTE_REDIRECTIONS.SIGNUP;
  const [isLoading, setIsLoading] = useState(!isSignupOrLogout);
  const [isZendeskRedirecting, setIsZendeskRedirecting] = useState(false);
  const [isLoginAppRedirecting, setIsLoginAppRedirecting] = useState(false);
  const [isGettingTokens, setIsGettingTokens] = useState(true);

  const loginStartedTime = useRef(cookie.getCookieValue(LOGIN_STARTED));
  const loginTimeCognitoTokensSaved = useRef();
  const email = useRef(cookie.getCookieValue(EMAIL));

  useEffect(() => {
    if (loginStartedTime.current) {
      cookie.deleteCookie(LOGIN_STARTED, {
        domain,
      });
      if (email.current) {
        cookie.deleteCookie(EMAIL, { domain });
      }
    }
  }, []);

  /**
   * UseEffect to check if a user has an active cognito session otherwise
   * attempt to get tokens from login app using iframe.
   * If the user is not signed in, redirect to login page.
   */
  useEffect(() => {
    const checkCognitoSession = async () => {
      try {
        sessionStorage.setItem('isGettingTokens', false);
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        logger.info(`Current session is not valid: ${error}`);
        // listen for tokens from login ui
        window.onmessage = async (event) => {
          if (
            event.origin === loginUrl &&
            sessionStorage.getItem('isGettingTokens') === 'true'
          ) {
            const {
              data: { tokens, status },
            } = event;
            if (status === 401) {
              sessionStorage.setItem('isGettingTokens', false);
              setIsLoginAppRedirecting(true);
              // Destroy client-side authentication details if any
              authentication.logout();
              window.location.href = `${loginUrl}${location.search}`;
              return;
            }
            if (tokens) {
              sessionStorage.setItem('isGettingTokens', false);
              if (loginStartedTime.current) {
                loginTimeCognitoTokensSaved.current =
                  Date.now() - loginStartedTime.current;
              }
              saveTokens(tokens);
              setIsGettingTokens(false);
            }
          }
        };
        // get tokens from login app
        logger.track({
          event: 'GET_TOKENS_FROM_LOGIN_APP',
          properties: {
            UserEmailAddress: email.current,
          },
        });
        sessionStorage.setItem('isGettingTokens', true);
        try {
          await retry(() => getTokens(loginStartedTime, email), 3);
        } catch (e) {
          if (
            sessionStorage.getItem('isGettingTokens') === 'true' &&
            loginStartedTime.current
          ) {
            logger.error({
              event: 'SESSION_WRAPPER_CANNOT_GET_TOKENS',
              properties: {
                UserEmailAddress: email.current,
              },
            });
          }
        }
      } finally {
        if (!isLoginAppRedirecting) {
          sessionStorage.setItem('isGettingTokens', false);
          setIsGettingTokens(false);
        }
      }
    };

    if (isGettingTokens && !isSignupOrLogout) {
      checkCognitoSession();
    }
  }, [
    isGettingTokens,
    isLoginAppRedirecting,
    isSignupOrLogout,
    location.search,
  ]);

  /**
   * UseEffect to check if a user is authenticated on page load.
   * If the user is authenticated, we then check if client service token and global info are present
   * and then render StateWrapper. If the token and global info are not present, we then fetch them
   * from the backend and then render StateWrapper.
   * If the user is not authenticated, we then clear out any tokens and redirect to the login page.
   */
  useEffect(() => {
    const executeZendeskRedirection = async () => {
      const isLogout = location.pathname === ROUTE_REDIRECTIONS.LOGOUT;
      const zendeskReturnURL = getZendeskReturnURL(location);
      if (zendeskReturnURL && !isLogout) {
        let redirectURL = '/';
        try {
          redirectURL = await getZendeskRedirectURL(zendeskReturnURL);
        } catch (error) {
          redirectURL = ROUTE_REDIRECTIONS.LOGOUT;
        }
        logger.info(`SessionWrapper: Redirecting to Zendesk${redirectURL}`);
        setIsZendeskRedirecting(true);
        redirectBrowser(redirectURL);
      }
    };
    const setupData = async () => {
      try {
        // Remove properties resolved urls from local storage.
        // This is only temporary for users who already have their localStorage filled with getPropertiesResolveURL
        localStorage.removeItem('getPropertiesResolveURL');

        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        const preferredMFA = await Auth.getPreferredMFA(user, {
          bypassCache: true,
        });
        const keyPrefix = `${user.keyPrefix}.${encodeURIComponent(user.username)}`;
        authentication.setKeyPrefix(keyPrefix);

        /* Check if client service token and global info are present */
        if (!authentication.isLoggedIn() || getGlobalInfo() === null) {
          // Get the user details from the cognito response, so we can get the
          // username and emailAddress for /serviceAuth error handling
          let userDetail = authentication.getUserDetails(user, null);

          /* Exchange access and id tokens for client service token */
          const clientServiceToken = await API.postServiceAuth({
            username: userDetail.userId,
            emailAddress: userDetail.emailAddress,
          });

          // calls getUserDetails a second time - this time using the
          // client service token values as an override
          userDetail = authentication.getUserDetails(user, clientServiceToken);
          userDetail.hasSetupMFA = preferredMFA === 'SOFTWARE_TOKEN_MFA';
          authentication.setClientServiceToken(clientServiceToken);

          // check if zendesk login
          await executeZendeskRedirection();

          const globalData = await setUpGlobalData({
            userDetail,
            setGlobalInfo: storeGlobalInfo,
          });

          const currentProperty =
            globalData.properties[globalData.current.propertyId];

          if (document.referrer.includes(loginUrl)) {
            const currentPropertyId = globalData.current.propertyId;
            const currentRole = getAccountAPIPermission({
              accountAPIId: getCurrentAccountAPIId({
                globalInfo: globalData,
              }),
              globalInfo: globalData,
            });

            const currentUser = globalData.user;
            if (loginStartedTime.current) {
              const loginTimeGlobalDataLoaded =
                Date.now() - loginStartedTime.current;
              // sending both login events to loggly at the same time so that we always have same number of events for both
              logger.track({
                event: 'Login Time - Cognito Tokens Saved',
                properties: {
                  runTimeMS: loginTimeCognitoTokensSaved.current,
                },
              });
              logger.track({
                event: 'Login Time - Global Data Loaded',
                properties: {
                  runTimeMS: loginTimeGlobalDataLoaded,
                },
              });
            }
            // Track login event to Mixpanel adding extra information about the user
            if (typeof mixpanel !== 'undefined') {
              const mixpanelId = await tracker.hashEmailAddress(
                currentUser.emailAddress,
              );
              mixpanel.people.set({
                $distinct_id: mixpanelId,
                $name: currentUser.name,
                $property: currentProperty?.propertyName ?? 'Not set',
                $email: currentUser.emailAddress,
                'Property ID': currentPropertyId ?? 'Not set',
                Staff: currentUser.userType === USER_TYPES.ECHOBOX_STAFF,
                Role:
                  currentRole === PERMISSION_TYPES.EDITOR ? 'Editor' : 'Admin',
                HV: window.sessionStorage.getItem('currentVersion'),
              });
              mixpanel.identify(mixpanelId);
              tracker.track({ eventName: 'Login' });
            }
          }
        } else {
          // update user information in global info - name, email and mfasetup
          const globalInfo = getGlobalInfo();
          globalInfo.user.name = user.attributes.name;
          globalInfo.user.emailAddress = user.attributes.email;
          globalInfo.user.hasSetupMFA = preferredMFA === 'SOFTWARE_TOKEN_MFA';
          storeGlobalInfo(globalInfo);
          await executeZendeskRedirection();
        }
        setIsLoading(false);
      } catch (error) {
        if (
          error?.noPermissions &&
          document.referrer.includes(loginUrl) &&
          getGlobalInfo() === null &&
          !window.location.href.includes('timeout') &&
          loginStartedTime.current
        ) {
          // log error to loggly
          logger.error({
            event: 'Client Service Token Error',
            properties: {
              UserEmailAddress: email.current,
            },
            error: object.stringifyKeys(error),
          });
          // Set up No Permissions Cookie
          cookie.setCookieValue(
            `${NO_PERMISSIONS_PREFIX}${window.location.host}`,
            'true',
            {
              expires: NO_EXPIRY_DATE,
              domain: cookie.getTLD(window.location.hostname),
            },
          );
          cookie.deleteCookie(EBX_LAST_USED_PRODUCT_KEY, {
            domain: cookie.getTLD(window.location.hostname),
          });
          authentication.logout();
          window.location.href = loginUrl;
          return;
        }
        logger.error({
          event: 'Session Wrapper Error',
          error: object.stringifyKeys(error), // And here
        });
        /* Destroy client-side authentication details */
        authentication.logout();
        navigate(`${ROUTE_REDIRECTIONS.LOGOUT}${location.search}`);
        setIsLoading(false);
      }
    };
    if (!isGettingTokens && !isSignupOrLogout && !isLoginAppRedirecting) {
      setupData();
    }
    // cannot add location as a dependency as we don't need this to run everytime location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, isGettingTokens, isSignupOrLogout]);

  useEffect(() => {
    if (isSignupOrLogout) setIsLoading(false);
  }, [isSignupOrLogout]);

  useEffect(() => {
    if (
      document?.location?.pathname === '/share' ||
      document?.location?.pathname === '/'
    ) {
      metrics.mark(FRONTEND_METRICS.HOME_PAGE_LOAD);
    }
  }, []);

  if (isLoading || isZendeskRedirecting || isLoginAppRedirecting) {
    return (
      <Center h="100vh">
        <Spinner size="lg" />
      </Center>
    );
  }

  return children;
}

SessionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SessionWrapper;
