import * as React from 'react';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import Tooltip from 'components/misc/Tooltip';
import {
  MessageBoxState,
  useMessageBoxContext,
} from 'context/MessageBoxContext';

interface MessageBoxAdditionalMenuProps {
  children: React.ReactNode;
  tooltipContent: string;
}

const MessageBoxAdditionalMenu = ({
  children,
  tooltipContent,
}: MessageBoxAdditionalMenuProps) => {
  const { currentUserInputType } = useMessageBoxContext();
  const isTooltipDisabled =
    currentUserInputType === MessageBoxState.ADDITIONAL_OPTIONS_MENU_OPEN;

  return (
    <div data-cy-id="three-dot" className="enabled" id="dropdown-container">
      <Tooltip
        id="three-dot-menu"
        label={tooltipContent}
        isDisabled={isTooltipDisabled}
      >
        <button
          type="button"
          className="clean_button"
          id="messageBoxThreeDot"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-label={tooltipContent}
          aria-expanded="false"
          data-cy-action="three-dot"
          data-tip
          data-for="three-dot-menu"
          data-iscapture="true"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 4"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM14 0C12.9 0 12 0.9 12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 0 14 0ZM8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0Z" />
          </svg>
        </button>
      </Tooltip>
      <ErrorBoundary showMessageOnError={false}>
        <Box
          position="absolute"
          zIndex={800}
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="messageBoxThreeDot"
        >
          {children}
        </Box>
      </ErrorBoundary>
    </div>
  );
};

const MessageBoxAdditionalMenuItem = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
) => (
  <button
    type="button"
    className="clean_button d-flex align-items-center"
    {...props}
  />
);

MessageBoxAdditionalMenu.Item = MessageBoxAdditionalMenuItem;

export default MessageBoxAdditionalMenu;
