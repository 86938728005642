import { useState } from 'react';

import {
  Alert,
  Card,
  Flex,
  Toggle,
  useDisclosure,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { z } from 'zod';

import * as api from 'api/api';
import * as accountAPIs from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  AUTOFEED_SHARE_MODES,
  GLOBAL_INFO_STATES,
} from 'common/constants';
import * as notifications from 'common/notifications';
import * as settings from 'common/settings';
import * as tracker from 'common/tracker';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import ConfirmTurnOnAutomation from 'components/settings/automation/ConfirmTurnOnAutomation';
import { useGlobalInfo } from 'context/GlobalInfoContext';

import * as tracking from 'helpers/tracking';

import { AutofeedSettings } from 'types';

const AutofeedStateChangeErrorSchema = z
  .object({
    error: z.object({ message: z.string() }),
  })
  .describe('AutofeedStateChangeErrorSchema');
interface AutomationToggleProps {
  automationActive: boolean;
  showWarning: boolean;
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  approvalsActive: boolean;
  setApprovalState: (
    currentSettings: Partial<AutofeedSettings>,
    approvalState: boolean,
  ) => Partial<AutofeedSettings>;
  setIsApprovalsSaving: (value: boolean) => void;
}

function AutomationToggle({
  automationActive,
  showWarning,
  errorMessage,
  setErrorMessage,
  approvalsActive,
  setApprovalState,
  setIsApprovalsSaving,
}: AutomationToggleProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { global } = useGlobalInfo();
  const toast = useToast();

  const [isSaving, setIsSaving] = useState(false);
  const handleCloseAlert = () => setErrorMessage('');

  const { accountAPIId } = useAutomationSettingsContext();

  const handleAutomationChange = async (toggleApproval: boolean) => {
    setIsSaving(true);
    if (toggleApproval) setIsApprovalsSaving(true);

    const newAutomationValue = !automationActive;

    const previousTracking = tracking.getAutofeedSettingsTrackingParams({
      autofeedSettings: settings.getAutofeedSettings({ accountAPIId }),
    });

    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = toggleApproval
      ? setApprovalState(existingSettings, true)
      : existingSettings;
    updatedSettings.autoFeedActive = newAutomationValue;
    if (
      updatedSettings?.newShareMode === AUTOFEED_SHARE_MODES.OFF &&
      updatedSettings?.reshareMode === AUTOFEED_SHARE_MODES.OFF &&
      newAutomationValue
    ) {
      updatedSettings.newShareMode = AUTOFEED_SHARE_MODES.OPTIMAL;
      updatedSettings.reshareMode = AUTOFEED_SHARE_MODES.OPTIMAL;
    }

    const settingConfig = {
      accountAPIId,
      settingTypeId: ACCOUNT_SETTING_TYPES.AUTO_FEED,
      enabled: true,
      dataJSON: updatedSettings,
    };

    try {
      await api.postAPIsSettingsType(settingConfig);
      global.refreshGlobalInfo({
        reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
        callback: () => {
          setIsSaving(false);
          setIsApprovalsSaving(false);
          toast({
            title: 'Setting updated',
            description: 'Changes can take up to 8 hours to take effect',
            variant: 'success',
          });
          const updatedTracking = tracking.getAutofeedSettingsTrackingParams({
            autofeedSettings: settings.getAutofeedSettings({ accountAPIId }),
          });
          tracker.track({
            eventName: 'Toggle Autofeed',
            trackingParams: {
              ...updatedTracking,
              'Social Page': accountAPIs.getAPIPostName({
                accountAPIId: accountAPIs.getCurrentAccountAPIId(),
              }),
              'Previous Autofeed Status': previousTracking['Autofeed Status'],
            },
          });
        },
      });
    } catch (error) {
      const safeParseResult = AutofeedStateChangeErrorSchema.safeParse(error);
      if (safeParseResult.success) {
        setErrorMessage(safeParseResult.data.error?.message);
      }

      notifications.addErrorNotification('Error saving setting');
      setIsSaving(false);
      setIsApprovalsSaving(false);
    }
  };

  const showModal = !approvalsActive && !automationActive;

  return (
    <>
      <Flex flexDir="column" gap={1}>
        {errorMessage && (
          <Alert variant="error" onClose={handleCloseAlert}>
            <Alert.Description>{errorMessage}</Alert.Description>
          </Alert>
        )}
        {showWarning && (
          <Alert variant="warning">
            <Alert.Description>
              Both New Shares and Reshares are off. Share as soon as possible
              keywords are still active.
            </Alert.Description>
          </Alert>
        )}
        <Card flexDirection="column" gap={3}>
          <Flex justifyContent="space-between" width="100%">
            <Card.Content>
              <Card.Title>Automation</Card.Title>
              <Card.Description>
                Let Echobox automatically share content with high potential
              </Card.Description>
            </Card.Content>
            <Toggle
              isChecked={automationActive}
              isDisabled={isSaving}
              labelPosition="left"
              onChange={
                showModal ? onOpen : () => handleAutomationChange(false)
              }
              data-cy-id="autofeedStatus"
              data-cy-action="autofeedToggle"
              data-cy-attribute={`isSaving:${isSaving}`}
            >
              {automationActive ? 'On' : 'Off'}
            </Toggle>
          </Flex>
          {!automationActive && (
            <Alert variant="info">
              <Alert.Title>You can still configure your automation</Alert.Title>
              <Alert.Description>
                Changes will take effect after you turn automation back on
              </Alert.Description>
            </Alert>
          )}
        </Card>
      </Flex>
      {isOpen && (
        <ConfirmTurnOnAutomation
          accountAPIId={accountAPIId}
          isOpen={isOpen}
          onClose={onClose}
          handleAutomationChange={handleAutomationChange}
        />
      )}
    </>
  );
}

export default AutomationToggle;
