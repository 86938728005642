import {
  Button,
  FormControl,
  HStack,
  Input,
  Modal,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import React, { useCallback, useEffect, useState } from 'react';

import deleteAccountAPI from 'api/deleteAccountAPI';
import getPropertiesAPIs from 'api/getPropertiesAPIs';
import postServiceAuth from 'api/postServiceAuth';
import * as authentication from 'common/authentication';
import { API_STATES, API_TYPE_IDS } from 'common/constants';
import { toLocalString } from 'common/datetimepicker';
import { convertToPropertyURN } from 'common/urn';
import { isNotNull } from 'common/utility';
import { SocialAPI } from 'types';

interface AccountAPIToolsModalInterface {
  onClose: () => void;
  preparedPropertyId?: string;
}

const AccountAPIToolsModal = ({
  onClose,
  preparedPropertyId,
}: AccountAPIToolsModalInterface) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStaffUser, setIsStaffUser] = useState(false);
  const [socialPages, setSocialPages] = useState<SocialAPI[]>([]);
  const [inputValue, setInputValue] = useState<string>(
    preparedPropertyId || '',
  );
  const [isLoadingResults, setIsLoadingResults] = useState(
    preparedPropertyId != null,
  );

  const [displayedSocialPages, setDisplayedSocialPages] = useState<SocialAPI[]>(
    [],
  );
  const [filters, setFilters] = useState<
    Map<string, (socialPage: SocialAPI) => boolean>
  >(new Map<string, (socialPage: SocialAPI) => boolean>());
  const [selectedSocialPage, setSelectedSocialPage] =
    useState<SocialAPI | null>(null);

  const toast = useToast();

  const getResult = useCallback(
    async (propertyId: string) => {
      const propertyURN = convertToPropertyURN(propertyId);

      let userCSToken = null;
      if (authentication.isImpersonating()) {
        userCSToken = authentication.getClientServiceToken();

        const impersonatedByUsername = authentication.getImpersonatedByEmail();
        const impersonateCSToken = await postServiceAuth({
          overrideUsername: impersonatedByUsername,
        });
        authentication.setClientServiceToken(impersonateCSToken);
      }

      try {
        setIsLoading(true);

        await getPropertiesAPIs({ propertyURNs: [propertyURN] }).then(
          (data) => {
            const pages = data[propertyId as keyof typeof data];
            setSocialPages(pages);
            setDisplayedSocialPages(pages);
          },
        );
      } catch (error) {
        toast({
          variant: 'chilledError',
          title: 'Something went wrong',
          description:
            'An error occurred while getting pages for this property. Please try again and contact support if the problem persists',
        });
      } finally {
        setIsLoading(false);
        setIsLoadingResults(false);

        if (isNotNull(userCSToken)) {
          authentication.setClientServiceToken(userCSToken);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We don't want to include toast because it isn't memoized.
    [],
  );

  useEffect(() => {
    const checkForUserType = async () => {
      const isStaff = await authentication.isStaffUser();
      setIsStaffUser(isStaff);
    };

    checkForUserType();

    if (isLoadingResults) {
      getResult(inputValue);
    }
  }, [isLoadingResults, inputValue, getResult]);

  const closeModal = () => {
    setSocialPages([]);
    setDisplayedSocialPages([]);
    onClose();
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    const target = event.target as typeof event.target & {
      propertyId: { value: string };
    };

    getResult(target.propertyId.value.trim());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAPIStateChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    const selectedValue = Number(event.currentTarget.value);
    filterSocialPages(
      selectedValue,
      'API_STATE_FILTER',
      (socialPage) => socialPage.apiStateId === selectedValue,
    );
  };

  const handleAPITypeChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    const selectedValue = Number(event.currentTarget.value);
    filterSocialPages(
      selectedValue,
      'API_TYPE_FILTER',
      (socialPage) => socialPage.apiTypeId === selectedValue,
    );
  };

  function filterSocialPages(
    selectedValue: number,
    key: string,
    filter: (socialPage: SocialAPI) => boolean,
  ): void {
    const filtersCopy = filters;
    if (selectedValue === -1) {
      filtersCopy.delete(key);
    } else {
      filtersCopy.set(key, filter);
    }
    setFilters(filtersCopy);
    applyFilters();
  }

  function applyFilters(): void {
    let filteredSocialPages = socialPages;
    for (const filter of filters.values()) {
      filteredSocialPages = filteredSocialPages.filter(filter);
    }
    setDisplayedSocialPages(filteredSocialPages);
  }

  const selectSocialPageToDelete = (socialPage: SocialAPI) => {
    setSelectedSocialPage(socialPage);
    setConfirmDeleteModalOpen(true);
  };

  const pageToDelete = () => {
    return (
      selectedSocialPage?.apiPostName ??
      selectedSocialPage?.apiAltName ??
      'Unknown'
    );
  };

  const handleConfirmDeleteModalClose = () => {
    setSelectedSocialPage(null);
    setConfirmDeleteModalOpen(false);
  };

  const confirmDeleteModal = () => {
    return (
      <Modal isOpen onClose={handleConfirmDeleteModalClose}>
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to remove&nbsp;{pageToDelete()}?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You&#39;re about to permanently remove&nbsp;
          <strong>{pageToDelete()}</strong> from property&nbsp;
          <strong>{selectedSocialPage?.propertyId}</strong>.
        </Modal.Body>
        <Modal.Footer>
          <Button data-dismiss="modal" onClick={handleConfirmDeleteModalClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteSocialPage}>
            Remove&nbsp;<strong>{pageToDelete()}</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const deleteSocialPage = async () => {
    if (!selectedSocialPage || !selectedSocialPage.socialAPIURN) {
      return;
    }

    let userCSToken = null;

    try {
      if (authentication.isImpersonating()) {
        userCSToken = authentication.getClientServiceToken();

        const impersonatedByUsername = authentication.getImpersonatedByEmail();
        const impersonateCSToken = await postServiceAuth({
          overrideUsername: impersonatedByUsername,
        });
        authentication.setClientServiceToken(impersonateCSToken);
      }

      await deleteAccountAPI({ socialAPIURN: selectedSocialPage.socialAPIURN });

      const socialPagesCopy = socialPages.filter(
        (socialPage) =>
          socialPage.socialAPIURN !== selectedSocialPage.socialAPIURN,
      );
      setSocialPages(socialPagesCopy);

      const displayedSocialPagesCopy = displayedSocialPages.filter(
        (socialPage) =>
          socialPage.socialAPIURN !== selectedSocialPage.socialAPIURN,
      );
      setDisplayedSocialPages(displayedSocialPagesCopy);
    } catch (error) {
      toast({
        variant: 'chilledError',
        title: 'Something went wrong',
        description:
          'An error occurred while deleting this page. Please try again and contact support if the problem persists',
      });
    } finally {
      handleConfirmDeleteModalClose();

      if (isNotNull(userCSToken)) {
        authentication.setClientServiceToken(userCSToken);
      }
    }
  };

  return (
    <Modal isOpen onClose={closeModal} size="large">
      <Modal.Header>
        <Modal.Title>Account API Tools</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <FormControl.FormLabel htmlFor="propertyId">
              Find a property
            </FormControl.FormLabel>
            <HStack>
              <Input
                type="text"
                placeholder="Enter a Property Id..."
                id="propertyId"
                value={inputValue}
                onChange={handleInputChange}
              />
              <Button
                type="submit"
                variant="secondary"
                isLoading={isLoading}
                loadingText="Submitting"
                flexShrink={0}
              >
                Submit
              </Button>
            </HStack>
          </FormControl>
        </form>
        <br />
        <Table>
          <Thead>
            <Tr py="10px" px="5px">
              <Th>API Id</Th>
              <Th>Name</Th>
              <Th>
                <Select onChange={handleAPIStateChange}>
                  <option value="-1">All States</option>
                  <option value={API_STATES.ACTIVE}>Active</option>
                  <option value={API_STATES.BAD_AUTH}>Bad Auth</option>
                  <option value={API_STATES.QUOTA_HIT}>Quota Hit</option>
                  <option value={API_STATES.UNDER_SETUP}>Under Setup</option>
                </Select>
              </Th>
              <Th>
                <Select onChange={handleAPITypeChange}>
                  <option value="-1">All Types</option>
                  <option value={API_TYPE_IDS.FACEBOOK}>Facebook</option>
                  <option value={API_TYPE_IDS.TWITTER}>Twitter</option>
                  <option value={API_TYPE_IDS.LINKEDIN}>LinkedIn</option>
                  <option value={API_TYPE_IDS.INSTAGRAM}>Instagram</option>
                  <option value={API_TYPE_IDS.TIKTOK}>TikTok</option>
                </Select>
              </Th>
              <Th>Time Created</Th>
              {isStaffUser && <Th>&nbsp;</Th>}
            </Tr>
          </Thead>
          <Tbody>
            <Tr hidden={displayedSocialPages.length !== 0}>
              <Td colSpan={6}>No social pages to display</Td>
            </Tr>
            {displayedSocialPages.map((socialPage) => {
              return (
                <Tr key={socialPage.accountAPIId} py="10px" px="5px">
                  <Td>{socialPage.accountAPIId}</Td>
                  <Td>
                    {socialPage.apiPostName ??
                      socialPage.apiAltName ??
                      'Unknown'}
                  </Td>
                  <Td> {socialPage.apiState} </Td>
                  <Td> {socialPage.apiType} </Td>
                  <Td> {toLocalString(socialPage.createdUnixTime)}</Td>
                  {isStaffUser && (
                    <Td>
                      <span
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => selectSocialPageToDelete(socialPage)}
                      >
                        delete
                      </span>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {confirmDeleteModalOpen && confirmDeleteModal()}
      </Modal.Body>
    </Modal>
  );
};

export default AccountAPIToolsModal;
