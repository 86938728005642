import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Heading, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import {
  getAutomationSettingsAPIs,
  getCurrentProperty,
  getFacebookAPIs,
  getLinkedInAPIs,
  getTwitterAPIs,
} from 'common/accountAPIs';
import * as tracker from 'common/tracker';

import SocialRowTable from 'components/settings/automation/SocialPageTable';

import { useGlobalInfo } from 'context/GlobalInfoContext';

function AutomationSettings() {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const navigate = useNavigate();

  const automationSettingsPages = getAutomationSettingsAPIs({
    globalInfo,
  });

  const { accountAPIs } = getCurrentProperty({ globalInfo });
  const facebookAPIs = getFacebookAPIs({ accountAPIs });
  const twitterAPIs = getTwitterAPIs({ accountAPIs });
  const linkedInAPIs = getLinkedInAPIs({ accountAPIs });
  // const instagramAPIs = getInstagramAPIs({ accountAPIs });   --> NO AUTOFEED FOR INSTAGRAM AT MOMENT
  // const tikTokAPIs = getTikTokAPIs({ accountAPIs });   --> NO AUTOFEED FOR INSTAGRAM AT MOMENT

  useEffect(() => {
    if (automationSettingsPages.length === 0) {
      navigate('/share', { replace: true });
    }

    tracker.track({
      eventName: 'Property Automation',
    });
  });

  return (
    <>
      <Heading>Automation</Heading>
      <Text color="gray.600" mt={2} mb={8} size="sm">
        Toggle automation on or off or configure advanced preferences
      </Text>
      <Flex direction="column" gap={3}>
        <SocialRowTable accountAPIs={facebookAPIs} title="Facebook" />
        <SocialRowTable accountAPIs={twitterAPIs} title="Twitter" />
        <SocialRowTable accountAPIs={linkedInAPIs} title="LinkedIn" />
        {/* <SocialRowTable accountAPIs={instagramAPIs} title="Instagram" />    --> NO AUTOFEED FOR INSTAGRAM AT MOMENT */}
        {/* <SocialRowTable accountAPIs={tikTokAPIs} title="TikTok" />    --> NO AUTOFEED FOR TIKTOK AT MOMENT */}
      </Flex>
    </>
  );
}

export default AutomationSettings;
