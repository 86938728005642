import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';

export function useSuccessfulTwitterAppConnection() {
  const toast = useToast();

  const showSuccessMessage = () =>
    toast({ title: 'Twitter Project App connected', variant: 'success' });

  return { showSuccessMessage };
}
