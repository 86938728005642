import { API_TYPE_IDS } from 'common/constants';

// Constants used by others need to be defined first

export const BACKEND_PROPERTIES = [
  'abResults',
  'abTestStatusId',
  'abTestVariations',
  'canCustomiseLinkPosts',
  'creationReasonId',
  'description',
  'failReason',
  'fbTargetingParams',
  'firstComment',
  'igLinkStickerConfig',
  'imageURLs',
  'isLive',
  'maxShareTime',
  'mediaId',
  'mediaItemTags',
  'mediaURN',
  'message',
  'minShareTime',
  'mostRecentScore',
  'mostRecentScoreCalculationUnixTime',
  'mostRecentUnixTimePublished',
  'mostRecentUnixTimeShared',
  'mediaStatesByAccountAPIId',
  'totalNumberOfShares',
  'optimalShareTime',
  'postType',
  'socialChannel',
  'reshare',
  'rssTitle',
  'shareURL',
  'sponsor',
  'state',
  'suggestionTypeId',
  'textContentAvailableState',
  'timeSensitivityTypeId',
  'title',
  'trackingDetails',
  'twitterCardType',
  'unshortenedShareURL',
  'updateDetails',
  'videoURL',
  'imageOverlayURL',
];

export const FRONTEND_PROPERTIES = [
  'aiMessage',
  'autopilotToggle',
  'isChanged',
  'isEditing',
  'isRefreshPreview',
  'isApplyImageOverlay',
  'isReloading',
  'isTimingValid',
  'messages',
  'sponsorName',
  'mediaItemTags',
  'shouldSendURL',
];

export const SHARED_PROPERTIES = [
  'actionTypeId',
  'insights',
  'socialPostURL',
  'unixTimeShared',
  'socialChannel',
];

export const PREVIEW_FIELDS = [
  'abInfo',
  'abVariations',
  'additionalSocialPages',
  'canCustomiseLinkPosts',
  'description',
  'errorDetails',
  'errorMessage',
  'failReason',
  'fbTargetingParams',
  'hashtags',
  'imageURLs',
  'isEdited',
  'isLoading',
  'isRefreshPreview',
  'isApplyImageOverlay',
  'isSaving',
  'isURLChanged',
  'isURLResolved',
  'isURLValid',
  'mediaItemTags',
  'mentions',
  'message',
  'messageHistory',
  'messages',
  'originalMessages',
  'postImageNeedsToBeUpdated',
  'socialChannel',
  'shareTime',
  'shareURL',
  'title',
  'trackingDetails',
  'twitterCardType',
  'unshortenedShareURL',
  'imageOverlayURL',
  'imageOverlayResult',
  'validationMessage',
  'files',
  'instantImageArticleUrl',
];

export const NETWORK_PROPERTIES = {
  abTestStatusId: [API_TYPE_IDS.FACEBOOK],
  abVariations: [API_TYPE_IDS.FACEBOOK],
  description: [API_TYPE_IDS.FACEBOOK],
  fbTargetingParams: [API_TYPE_IDS.FACEBOOK],
  title: [API_TYPE_IDS.FACEBOOK],
};
