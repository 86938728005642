/**
 * GET /syndfeeds/{identifiers} - retrieve synd feeds information
 *
 * @param {string[]} identifiers - synd feed URLs or URNs
 * @return {object} - synd feeds information
 */

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { SyndFeedDataSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';
import { z } from 'zod';

const GET_SYNDFEEDS = 'getSyndFeeds';

const ResponseSchema = z
  .object({ syndFeeds: z.array(SyndFeedDataSchema) })
  .describe('GetSyndFeedsResponseDataSchema');

export default async function getSyndFeeds({
  identifiers,
}: {
  identifiers: string[];
}) {
  const guid = metrics.start(GET_SYNDFEEDS);

  const identifierString = identifiers.join(',');

  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL('v1.1')}/syndfeeds/${identifierString}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    const response = await axios(config);
    const { syndFeeds } = lyingParse(ResponseSchema, response.data);

    metrics.end(GET_SYNDFEEDS, guid);
    logger.debug(GET_SYNDFEEDS, {
      identifiers,
    });

    return syndFeeds;
  } catch (error) {
    metrics.fail(GET_SYNDFEEDS, guid);

    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: `api/${GET_SYNDFEEDS}`,
      args: { identifiers },
    });
    throw apiError;
  }
}
