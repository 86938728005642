/**
 * POST /apis/{accountAPIId}/settings/types/{settingTypeId} - Add a setting to the specified account
 *
 * @param {integer} accountAPIId - Account id
 * @param {integer} settingTypeId - Setting type id for the setting
 * @param {boolean} enabled - Flag indicating whether the setting is active
 * @param {integer} autoExpireTime - Optional timestamp indicating when the setting will expire
 * @param {string} dataJSON - JSON representation of the setting details
 * @return {object} - accountSettingId that was created
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES_NAMES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { AccountSettingTypesNamesSchema } from 'common/schemas';
import { getURNName } from 'common/social';
import { convertToSocialPageURN, extractAccountAPIId } from 'common/urn';
import { isNull } from 'common/utility';
import { lyingParse } from 'common/zod';
import type { AccountSettingsData, SettingTypeId } from 'types';

const ResponseSchema = z
  .object({
    settingType: AccountSettingTypesNamesSchema,
  })
  .describe('postAPIsSettingsType.ResponseSchema');

interface CommonArgs {
  settingTypeId: SettingTypeId;
  enabled: boolean;
  dataJSON?: Partial<AccountSettingsData> | null;
}

export default async function postAPIsSettingsTypeByAccountAPIId({
  accountAPIId,
  settingTypeId,
  enabled,
  dataJSON = null,
}: CommonArgs & {
  accountAPIId: number;
}) {
  const apiTypeId = getAPITypeId({
    accountAPIId,
  });
  const urnName = getURNName({ apiTypeId });
  const socialPageURN = convertToSocialPageURN(urnName, accountAPIId);

  return postAPIsSettingsTypeBySocialPageURN({
    socialPageURN,
    settingTypeId,
    enabled,
    dataJSON,
  });
}

export async function postAPIsSettingsTypeBySocialPageURN({
  socialPageURN,
  settingTypeId,
  enabled,
  dataJSON = null,
}: CommonArgs & {
  socialPageURN: string;
}) {
  const guid = metrics.start('postAPIsSettingsType');

  const settingType = ACCOUNT_SETTING_TYPES_NAMES[settingTypeId];

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${socialPageURN}/settings/types/${settingType}`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: JSON.stringify({
      enabled,
      dataJSON: isNull(dataJSON) ? dataJSON : JSON.stringify(dataJSON),
    }),
  };

  try {
    const response = await axios(config);
    metrics.end('postAPIsSettingsType', guid);
    logger.debug('postAPIsSettingsType', {
      accountAPIId: extractAccountAPIId(socialPageURN),
      settingTypeId,
    });
    const data = lyingParse(ResponseSchema, response.data);

    return {
      ...data,
      settingTypeId,
    };
  } catch (error) {
    metrics.fail('postAPIsSettingsType', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postAPIsSettingsType',
      args: {
        accountAPIId: extractAccountAPIId(socialPageURN),
        settingTypeId,
        enabled,
        dataJSON,
      },
    });
    throw apiError;
  }
}
