/* eslint react/no-did-update-set-state:"off" */

import PropTypes from 'prop-types';

import { cloneObject } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import Button from 'components/misc/Button';

// DEV Note: This component is used for both adding and reconnecting Instagram pages
// which are connected via the Instagram cabinet.
export default class ConnectInstagram extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      pagename: '',
      username: '',
      password: '',
      mfa: false,
      mfa1: '',
      mfa2: '',
      mfa3: '',
      isSaving: false,
      errorMessage: this.props.apiError !== '' ? this.props.apiError : '',
    };
    this._bind('handleSubmit');
  }

  /**
   * Lifecycle methods
   */

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('ConnectInstagram', {
      nextProps,
      nextState,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiError === '' && this.props.apiError !== '') {
      this.setState({
        errorMessage: this.props.apiError,
        isSaving: false,
      });
    }
  }

  /**
   * Event handlers
   */

  handleInputChange(control) {
    return (event) => {
      this.setState({
        errorMessage: '',
        [control]: event.target.value,
      });
    };
  }

  handleInputToggle(control) {
    return () => {
      this.setState((prevState) => {
        const nextState = cloneObject(prevState);
        nextState[control] = !nextState[control];
        return nextState;
      });
    };
  }

  handleSubmit() {
    const isAdding = this.props.isAdding;
    let errorExists = false;
    let errorMessage = '';
    if (!errorExists && isAdding && this.state.pagename === '') {
      errorExists = true;
      errorMessage = 'Please enter the name of your page';
    }
    if (!errorExists && this.state.username === '') {
      errorExists = true;
      errorMessage = 'Please enter your username';
    }
    if (!errorExists && this.state.password === '') {
      errorExists = true;
      errorMessage = 'Please enter your password';
    }
    if (!errorExists && this.state.mfa && this.state.mfa1 === '') {
      errorExists = true;
      errorMessage = 'Please enter your first recovery code';
    }
    if (!errorExists && this.state.mfa && this.state.mfa2 === '') {
      errorExists = true;
      errorMessage = 'Please enter your second recovery code';
    }
    if (!errorExists && this.state.mfa && this.state.mfa3 === '') {
      errorExists = true;
      errorMessage = 'Please enter your third recovery code';
    }
    if (errorExists) {
      this.setState({
        errorMessage,
      });
      return;
    }

    const args = {
      url: `https://www.instagram.com/${this.state.pagename}`,
      username: this.state.username,
      password: this.state.password,
      mfa: this.state.mfa,
      mfa1: this.state.mfa1,
      mfa2: this.state.mfa2,
      mfa3: this.state.mfa3,
    };
    this.setState(
      {
        isSaving: true,
      },
      () => {
        this.props.eventHandlers.handleSubmit(args);
      },
    );
  }

  /**
   * Render method
   */

  render() {
    const { isSaving } = this.state;
    const className = isSaving ? 'saving_locked' : '';
    const pageAction = this.props.isAdding ? 'Connect' : 'Reconnect';

    return (
      <div className="ebx-modal-outer" data-cy-id="addInstagramPageModal">
        <div className="ebx-modal-inner modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <div id="addInstagramPageLabel">
                {pageAction} your Instagram Account
              </div>
              <button
                type="button"
                className="close ft-26 text-400"
                aria-label="Close"
                onClick={this.props.eventHandlers.handleCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body border-radius-2 p-3 p-sm-4 p-md-5">
              <form autoComplete="off">
                <img
                  src="/img/icons/ic-instagram-font.png"
                  alt=""
                  width="150px"
                  className="d-block mx-auto mb-4 pb-3 mt-3"
                />
                {this.state.errorMessage !== '' && (
                  <div
                    className="error_notification pb-3"
                    data-cy-id="errorMessage"
                  >
                    {this.state.errorMessage}
                  </div>
                )}
                {this.props.isAdding && (
                  <>
                    <label htmlFor="pagename" className="mb-1">
                      Page name
                    </label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <div className="input-group-text input-medium ft-13">
                          https://instagram.com/
                        </div>
                      </div>
                      <input
                        type="text"
                        autoComplete="off"
                        data-lpignore="true"
                        className={`form-control input-medium ${className}`}
                        disabled={isSaving}
                        placeholder="johndoe"
                        aria-label="Page name"
                        id="pagename"
                        data-cy-input="pageName"
                        value={this.state.pagename}
                        onChange={this.handleInputChange('pagename')}
                      />
                    </div>
                  </>
                )}
                <label htmlFor="username" className="mb-1">
                  Username
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  data-lpignore="true"
                  className={`form-control input_medium mb-3 p-2 w-100 ${className}`}
                  disabled={isSaving}
                  placeholder="johndoe"
                  aria-label="Username"
                  id="username"
                  data-cy-input="username"
                  value={this.state.username}
                  onChange={this.handleInputChange('username')}
                />
                <label htmlFor="password" className="mb-1">
                  Password
                </label>
                <input
                  type="password"
                  autoComplete="new-password"
                  data-lpignore="true"
                  className={`form-control input_medium mb-3 p-2 w-100 ${className}`}
                  disabled={isSaving}
                  placeholder=""
                  aria-label="Password"
                  id="password"
                  data-cy-input="password"
                  value={this.state.password}
                  onChange={this.handleInputChange('password')}
                />
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="mfa"
                    data-cy-input="mfa"
                    className={className}
                    disabled={isSaving}
                    checked={this.state.mfa}
                    onChange={this.handleInputToggle('mfa')}
                  />
                  <label
                    className="mb-0 word-break checked text_unselectable"
                    htmlFor="mfa"
                  >
                    My account has Two-Factor Authentication
                  </label>
                </div>
                {this.state.mfa && (
                  <>
                    <div className="d-flex">
                      <span className="mt-3 mb-1">Recovery code</span>
                      <a
                        className="mt-3 mb-1 ml-auto gray-600"
                        href="https://help.instagram.com/1006568999411025?helpref=search&sr=1&query=recovery%20code"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        How to find recovery codes
                      </a>
                    </div>
                    <input
                      type="text"
                      autoComplete="off"
                      data-lpignore="true"
                      className={`form-control input_medium mb-2 p-2 w-100 ${className}`}
                      placeholder="•••• ••••"
                      aria-label="Recovery code 1"
                      value={this.state.mfa1}
                      onChange={this.handleInputChange('mfa1')}
                      data-cy-input="mfa1"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      data-lpignore="true"
                      className={`form-control input_medium mb-2 p-2 w-100 ${className}`}
                      placeholder="•••• ••••"
                      aria-label="Recovery code 2"
                      value={this.state.mfa2}
                      onChange={this.handleInputChange('mfa2')}
                      data-cy-input="mfa2"
                    />
                    <input
                      type="text"
                      autoComplete="off"
                      data-lpignore="true"
                      className={`form-control input_medium mb-2 p-2 w-100 ${className}`}
                      placeholder="•••• ••••"
                      aria-label="Recovery code 3"
                      value={this.state.mfa3}
                      onChange={this.handleInputChange('mfa3')}
                      data-cy-input="mfa3"
                    />
                  </>
                )}
              </form>
            </div>
            <div className="modal-footer">
              <Button
                onClick={this.props.eventHandlers.handleCancel}
                data-cy-action="cancel"
              >
                Close
              </Button>
              <Button
                className={`ml-2 ${className}`}
                variant="dark"
                onClick={this.handleSubmit}
                data-cy-action="submit"
              >
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 text-light"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {pageAction} Instagram
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConnectInstagram.propTypes = {
  apiError: PropTypes.string,
  isAdding: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
};

ConnectInstagram.defaultProps = {
  apiError: '',
};
