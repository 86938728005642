import clsx from 'clsx';
import createDOMPurify from 'dompurify';
import { distance } from 'fastest-levenshtein';
import $ from 'jquery';
import { useCallback, useEffect, useState } from 'react';
import type { ContentEditableEvent } from 'react-contenteditable';

import { getClipboardData } from 'common/clipboard';
import { CAN_CUSTOMISE_LINK_POSTS } from 'common/config';
import { KEYCODES } from 'common/constants';
import { getTextDirection } from 'common/language';
import { encodeHTML, encodeTextMessage } from 'common/string';
import * as tracker from 'common/tracker';
import { isRunningTests } from 'common/utility';
import ContentEditable from 'components/misc/ContentEditable';
import { handleCopy } from 'helpers/composeBox';
import { getCommonTrackingParams } from 'helpers/tracking';
import type { FixTypeLater } from 'types';

const matchProblematicCharacters = /(?:\r\n|\r|\n|\v|\f|\t)/g;
const matchDoubleSpace = /\s{2,}/g;

interface EditableTextProps {
  accountAPIId: number;
  mediaItem?: FixTypeLater;
  canCustomiseLinkPosts?: boolean;
  className: string;
  isRefreshPreview?: boolean;
  isVideoLink?: boolean;
  hasImageOverlay?: boolean;
  dataCyInput: string;
  messageText: string;
  maxLines?: number | null;
  classOnBlur?: string;
  placeholder: string;
  onChange: (updatedValue: string) => void;
  onShowDescription?: (showDescription: boolean) => void;
}

export default function EditableText({
  accountAPIId,
  mediaItem = {},
  canCustomiseLinkPosts = CAN_CUSTOMISE_LINK_POSTS,
  className,
  isRefreshPreview = false,
  isVideoLink = false,
  hasImageOverlay = false,
  dataCyInput,
  messageText,
  maxLines = null,
  classOnBlur = '',
  placeholder,
  onChange,
  onShowDescription,
}: EditableTextProps) {
  const DOMPurify = createDOMPurify(window);
  const sanitizedText = DOMPurify.sanitize(messageText);
  const encodedText = encodeHTML(sanitizedText);

  const [text, setText] = useState(encodedText);
  const [prevText, setPrevText] = useState(encodedText);
  const [prevMessageTextProp, setPrevMessageTextProp] = useState(messageText);
  const [isFocused, setIsFocused] = useState(false);

  if (prevMessageTextProp !== messageText) {
    setPrevMessageTextProp(messageText);
    const stateMessage = encodeTextMessage(text);
    if (messageText !== stateMessage) {
      setText(encodedText);
    }
  }

  const textDirectionClass = getTextDirection({
    accountAPIId,
    text: messageText,
  });

  const isDisabled =
    !canCustomiseLinkPosts ||
    isRefreshPreview ||
    isVideoLink ||
    hasImageOverlay;

  const checkTitleHeight = useCallback(() => {
    try {
      const pixelHeight = $(`.${className}`).height() ?? 0;
      const lineHeight = $(`.${className}`)
        .css('line-height')
        .replace('px', '');
      const showDescription =
        pixelHeight <= Number(lineHeight) * (maxLines ?? 0);
      onShowDescription?.(showDescription);
    } catch (error) {
      //
    }
  }, [className, maxLines, onShowDescription]);

  const handleBlur = () => {
    setIsFocused(false);
    if (className === 'share_title') {
      if (
        messageText !== null &&
        prevText !== null &&
        messageText !== prevText
      ) {
        const levenshteinDistance = distance(messageText, prevText);
        const trackingParams = getCommonTrackingParams({ mediaItem });
        trackingParams['Post Title'] = messageText;
        trackingParams['Previous Post Title'] = prevText;
        trackingParams['Before/After Levenshtein Distance'] =
          levenshteinDistance;
        tracker.track({
          eventName: 'Edit Post Title',
          trackingParams,
        });
      }
    }
    setPrevText(encodedText);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    // Prevent line breaks from being added in title and description fields
    if (event.keyCode === KEYCODES.ENTER) {
      event.preventDefault();
    }
  };

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    // Get the pasted text from the clipboard
    let clipboardText = getClipboardData(event);

    if (clipboardText != null) {
      // Replace all possible problem characters with a space
      clipboardText = clipboardText.replace(matchProblematicCharacters, ' ');
      // Replace all instances of a double space with a single space
      clipboardText = clipboardText.replace(matchDoubleSpace, ' ');

      document.execCommand('insertText', false, clipboardText);
    }
  };

  const handleTextChange = (event: ContentEditableEvent) => {
    if (maxLines != null) {
      checkTitleHeight();
    }

    const updatedText = event.target.value;

    if (updatedText === placeholder) {
      return;
    }

    setText(updatedText);
    onChange(encodeTextMessage(updatedText));
  };

  useEffect(() => {
    if (maxLines != null && !isRunningTests()) {
      checkTitleHeight();
    }
  }, [checkTitleHeight, maxLines]);

  return (
    <ContentEditable
      placeholder={placeholder}
      className={clsx(
        'with-placeholder',
        className,
        textDirectionClass,
        classOnBlur !== '' && !isFocused && classOnBlur,
        isDisabled && 'disabled',
      )}
      html={text}
      disabled={isDisabled}
      onChange={handleTextChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onCopy={handleCopy}
      onPaste={handlePaste}
      onFocus={() => setIsFocused(true)}
      data-cy={`editable-text-${dataCyInput}`}
      data-cy-input={dataCyInput}
    />
  );
}
