import { useState } from 'react';

import isEqual from 'fast-deep-equal';

import {
  Alert,
  Flex,
  Heading,
  Tag,
  WarningIcon,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as settings from 'common/settings';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';
import KeywordsInput from 'components/settings/automation/common/KeywordsInput';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';

import Bold from 'components/misc/Bold';
import { AutofeedSettings } from 'types';

export interface NewSharesKeywordSettings {
  includeOnlyContentURLPathContaining: string[];
  excludeAllContentContaining: string[];
  excludeAllContentURLPathContaining: string[];
  shareNowAllContentContaining: string[];
  shareNowAllContentURLPathContaining: string[];
}

function Keywords() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();
  const { global } = useGlobalInfo();
  const { accountAPIId } = useAutomationSettingsContext();

  const getKeywordSettings = () => {
    const includeOnlyContentURLPathContaining = [
      ...(settings.getAutofeedSetting({
        key: 'includeOnlyContentURLPathContaining',
        accountAPIId,
      }) ?? []),
    ];

    const excludeAllContentContaining = [
      ...(settings.getAutofeedSetting({
        key: 'excludeAllContentContaining',
        accountAPIId,
      }) ?? []),
    ];

    const excludeAllContentURLPathContaining = [
      ...(settings.getAutofeedSetting({
        key: 'excludeAllContentURLPathContaining',
        accountAPIId,
      }) ?? []),
    ];

    const shareNowAllContentContaining = [
      ...(settings.getAutofeedSetting({
        key: 'shareNowAllContentContaining',
        accountAPIId,
      }) ?? []),
    ];

    const shareNowAllContentURLPathContaining = [
      ...(settings.getAutofeedSetting({
        key: 'shareNowAllContentURLPathContaining',
        accountAPIId,
      }) ?? []),
    ];

    return {
      includeOnlyContentURLPathContaining,
      excludeAllContentContaining,
      excludeAllContentURLPathContaining,
      shareNowAllContentContaining,
      shareNowAllContentURLPathContaining,
    };
  };

  const handleSaveKeywords = async (
    newKeywordSettings: NewSharesKeywordSettings,
  ) => {
    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    existingSettings.includeOnlyContentURLPathContaining =
      newKeywordSettings.includeOnlyContentURLPathContaining;
    existingSettings.excludeAllContentContaining =
      newKeywordSettings.excludeAllContentContaining;
    existingSettings.excludeAllContentURLPathContaining =
      newKeywordSettings.excludeAllContentURLPathContaining;
    existingSettings.shareNowAllContentContaining =
      newKeywordSettings.shareNowAllContentContaining;
    existingSettings.shareNowAllContentURLPathContaining =
      newKeywordSettings.shareNowAllContentURLPathContaining;

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
    );
  };

  const keywordSettings = getKeywordSettings();
  const [currentKeywordSettings, setCurrentKeywordSettings] =
    useState(keywordSettings);

  const handleKeywordSettingsChange = (
    newSettings: NewSharesKeywordSettings,
  ) => {
    setCurrentKeywordSettings(newSettings);

    if (isEqual(newSettings, keywordSettings)) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveKeywords(newSettings));
  };

  return (
    <>
      <AutomationSettingsHeader
        title="Keywords"
        description="Use keywords to specify which content can be shared"
      />
      <Flex flexDir="column" gap={6} mb={6}>
        <Heading variant="h3">Rules</Heading>
        <KeywordsInput
          label={
            <span>
              Only <Bold>URLs</Bold> containing any of the following keywords
              should <Bold>be shared</Bold>
            </span>
          }
          currentKeywordSettings={currentKeywordSettings}
          handleKeywordSettingsChange={handleKeywordSettingsChange}
          keywordType="includeOnlyContentURLPathContaining"
        />
        <KeywordsInput
          label={
            <span>
              Articles with the following keywords in the <Bold>RSS title</Bold>{' '}
              should <Bold>not be shared</Bold>
            </span>
          }
          currentKeywordSettings={currentKeywordSettings}
          handleKeywordSettingsChange={handleKeywordSettingsChange}
          keywordType="excludeAllContentContaining"
        />
        <KeywordsInput
          label={
            <span>
              <Bold>URLs</Bold> that contain any of the following keywords
              should <Bold>not be shared</Bold>
            </span>
          }
          currentKeywordSettings={currentKeywordSettings}
          handleKeywordSettingsChange={handleKeywordSettingsChange}
          keywordType="excludeAllContentURLPathContaining"
        />
      </Flex>
      <Flex flexDir="column" gap={6} sx={{ strong: { fontWeight: 500 } }}>
        <Flex gap={3} flexWrap="wrap">
          <Heading variant="h3" flexShrink={0}>
            Share as soon as possible
          </Heading>
          <Flex gap={3} flexWrap="wrap">
            <Tag color="gray" icon={<WarningIcon />}>
              Subject to other Autofeed settings
            </Tag>
            {settings.isApprovalsActive(accountAPIId) && (
              <Tag color="gray" icon={<WarningIcon />}>
                Share ASAP keywords are disabled when Approvals is Active
              </Tag>
            )}
          </Flex>
        </Flex>
        <Alert variant="warning">
          <Alert.Description>
            This will override other keyword rules
          </Alert.Description>
        </Alert>
        <KeywordsInput
          label={
            <span>
              New articles with the following keywords in the{' '}
              <Bold>RSS title</Bold> will always be{' '}
              <Bold>shared as soon as possible</Bold>
            </span>
          }
          currentKeywordSettings={currentKeywordSettings}
          handleKeywordSettingsChange={handleKeywordSettingsChange}
          keywordType="shareNowAllContentContaining"
        />
        <KeywordsInput
          label={
            <span>
              New articles with the following keywords in the <Bold>URL</Bold>{' '}
              will always be <Bold>shared as soon as possible</Bold>
            </span>
          }
          currentKeywordSettings={currentKeywordSettings}
          handleKeywordSettingsChange={handleKeywordSettingsChange}
          keywordType="shareNowAllContentURLPathContaining"
        />
      </Flex>
    </>
  );
}

export default Keywords;
