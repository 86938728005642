import {
  Flex,
  Heading,
  LightingHighlightIcon,
  Tag,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';

interface AutomationSettingsHeaderProps {
  title: string;
  description: string;
}

function AutomationSettingsHeader({
  title,
  description,
}: AutomationSettingsHeaderProps) {
  const { isAutomationActive } = useAutomationSettingsContext();

  return (
    <Flex flexDir="column" gap={2} mb={6}>
      <Flex alignItems="center" gap={3}>
        <Heading>{title}</Heading>
        <Tag
          color={isAutomationActive ? 'blue' : 'gray'}
          icon={<LightingHighlightIcon />}
        >
          Automation is {isAutomationActive ? 'on' : 'off'}
        </Tag>
      </Flex>
      <Text size="sm" color="gray.600">
        {description}
      </Text>
    </Flex>
  );
}

export default AutomationSettingsHeader;
