import {
  BellNotificationIcon,
  Box,
  LightingHighlightIcon,
  RssFeedIcon,
  SidebarGroup,
  SidebarItem,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as accountAPIs from 'common/accountAPIs';
import SocialPropertySelect from 'components/misc/SocialPropertySelect';
import GlobalInfoContext from 'context/GlobalInfoContext';

import * as environment from 'common/environment';
import Page from 'svg/Page';
import Property from 'svg/Property';
import UserProfile from 'svg/UserProfile';
import UsersTeam from 'svg/UsersTeam';

const loginUrl = environment.getLoginUrl();

const SocalSettingsSidebar = () => {
  const { global } = useContext(GlobalInfoContext);

  const globalInfo = global.getGlobalInfo();
  const user = globalInfo.user;

  const { pathname } = useLocation();

  const automationSettingsPages = accountAPIs.getAutomationSettingsAPIs({
    globalInfo,
  });

  const routeGroups: {
    title: string;
    routes: {
      title: string;
      path: string;
      matches?: string[];
      icon: JSX.Element;
    }[];
  }[] = [
    {
      title: 'settings',
      routes: [
        {
          title: 'Property',
          path: '/settings/property',
          icon: <Property />,
        },
        {
          title: 'Pages',
          path: '/settings/pages',
          matches: ['settings/page'],
          icon: <Page />,
        },
        {
          title: 'Team',
          path: '/settings/users',
          icon: <UsersTeam />,
        },
        {
          title: 'Content feeds',
          path: '/settings/content-feeds',
          icon: <RssFeedIcon size={5} />,
        },
      ],
    },
    {
      title: user.emailAddress,
      routes: [
        {
          title: 'My details',
          path: `${loginUrl}/myprofile?returnPath=settings`,
          icon: <UserProfile />,
        },
        {
          title: 'Alerts',
          path: `${loginUrl}/alerts/social?currentPropertyId=${accountAPIs.getCurrentPropertyId()}`,
          icon: <BellNotificationIcon size={5} />,
        },
      ],
    },
  ];

  if (automationSettingsPages.length > 0) {
    const automationGroup = {
      title: 'Automation',
      path: '/settings/automation',
      matches: ['settings/automation'],
      icon: <LightingHighlightIcon size={5} />,
    };
    routeGroups[0].routes.splice(1, 0, automationGroup);
  }

  return (
    <Box
      borderRight="1px"
      borderColor="gray.300"
      backgroundColor="gray.100"
      w="xs"
    >
      <Box pt={6} pb={7} px={4}>
        <SocialPropertySelect redirectOrigin="settings/property" />
      </Box>
      {routeGroups.map(({ title: groupTitle, routes }) => (
        <SidebarGroup key={groupTitle} title={groupTitle}>
          {routes.map(({ title, path, matches, icon }) => (
            <SidebarItem
              key={path}
              to={path}
              isActive={
                path === pathname || matches?.some((m) => pathname.includes(m))
              }
            >
              <SidebarItem.Link
                as={Link}
                data-cy-action={`settings-${title
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              >
                {icon}
                <SidebarItem.Text>{title}</SidebarItem.Text>
              </SidebarItem.Link>
            </SidebarItem>
          ))}
        </SidebarGroup>
      ))}
    </Box>
  );
};

export default SocalSettingsSidebar;
