/* eslint no-cond-assign:"off" */
/* eslint no-param-reassign:"off" */

const STATE_PLAINTEXT = 1;
const STATE_HTML = 2;
const STATE_COMMENT = 3;

const ALLOWED_TAGS_REGEX = /<(\w*)>/g;
const NORMALIZETAG_REGEX = /<\/?([^\s/>]+)/;

function normalizeTag(tagBuffer) {
  const match = NORMALIZETAG_REGEX.exec(tagBuffer);

  return match ? match[1].toLowerCase() : null;
}

function striptagsInternal(html, context) {
  const allowableTags = context.allowableTags;
  const tagReplacement = context.tagReplacement;

  let state = context.state;
  let tagBuffer = context.tagBuffer;
  let depth = context.depth;
  let inQuoteChar = context.inQuoteChar;
  let output = '';

  for (let idx = 0, length = html.length; idx < length; idx += 1) {
    const char = html[idx];

    if (state === STATE_PLAINTEXT) {
      switch (char) {
        case '<':
          state = STATE_HTML;
          tagBuffer += char;
          break;

        default:
          output += char;
          break;
      }
    } else if (state === STATE_HTML) {
      switch (char) {
        case '<':
          // ignore '<' if inside a quote
          if (inQuoteChar) {
            break;
          }

          // we're seeing a nested '<'
          depth += 1;
          break;

        case '>':
          // ignore '>' if inside a quote
          if (inQuoteChar) {
            break;
          }

          // something like this is happening: '<<Fragment>>'
          if (depth) {
            depth -= 1;

            break;
          }

          // this is closing the tag in tagBuffer
          inQuoteChar = '';
          state = STATE_PLAINTEXT;
          tagBuffer += '>';

          if (allowableTags.has(normalizeTag(tagBuffer))) {
            output += tagBuffer;
          } else {
            output += tagReplacement;
          }

          tagBuffer = '';
          break;

        case '"':
        case "'":
          // catch both single and double quotes

          if (char === inQuoteChar) {
            inQuoteChar = '';
          } else {
            inQuoteChar = inQuoteChar || char;
          }

          tagBuffer += char;
          break;

        case '-':
          if (tagBuffer === '<!-') {
            state = STATE_COMMENT;
          }

          tagBuffer += char;
          break;

        case ' ':
        case '\n':
          if (tagBuffer === '<') {
            state = STATE_PLAINTEXT;
            output += '< ';
            tagBuffer = '';

            break;
          }

          tagBuffer += char;
          break;

        default:
          tagBuffer += char;
          break;
      }
    } else if (state === STATE_COMMENT) {
      switch (char) {
        case '>':
          if (tagBuffer.slice(-2) === '--') {
            // close the comment
            state = STATE_PLAINTEXT;
          }

          tagBuffer = '';
          break;

        default:
          tagBuffer += char;
          break;
      }
    }
  }

  // save the context for future iterations
  context.state = state;
  context.tagBuffer = tagBuffer;
  context.depth = depth;
  context.inQuoteChar = inQuoteChar;

  return output;
}

function parseAllowableTags(allowableTags) {
  let tagSet = new Set();

  if (typeof allowableTags === 'string') {
    let match;
    while ((match = ALLOWED_TAGS_REGEX.exec(allowableTags))) {
      tagSet.add(match[1]);
    }
  } else if (
    !Symbol.nonNative &&
    typeof allowableTags[Symbol.iterator] === 'function'
  ) {
    tagSet = new Set(allowableTags);
  } else if (typeof allowableTags.forEach === 'function') {
    // IE11 compatible
    allowableTags.forEach(tagSet.add, tagSet);
  }

  return tagSet;
}

function initContext(allowableTags, tagReplacement) {
  allowableTags = parseAllowableTags(allowableTags);

  return {
    allowableTags,
    tagReplacement,
    state: STATE_PLAINTEXT,
    tagBuffer: '',
    depth: 0,
    inQuoteChar: '',
  };
}

export default function striptags(html, allowableTags, tagReplacement) {
  html = html || '';
  allowableTags = allowableTags || [];
  tagReplacement = tagReplacement || '';

  const context = initContext(allowableTags, tagReplacement);

  return striptagsInternal(html, context);
}
