/**
 * GET /properties/search?propertyNameContains={propertyName}&maxResults={maxResults} - search for properties by property name
 */

import { z } from 'zod';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { GetPropertiesResponseDataSchema } from 'common/schemas';
import { lyingParse } from 'common/zod';

const GetPropertySearchByNameSchema = z
  .object({
    properties: z.array(GetPropertiesResponseDataSchema),
    warning: z.string().optional(),
  })
  .describe('getPropertySearchByName.GetPropertySearchByNameSchema');

export default async function getPropertySearchByName({
  maxResults = 5,
  propertyName,
}: {
  maxResults?: number;
  propertyName: string;
}) {
  const guid = metrics.start('getPropertySearchByName');

  const config = {
    url: `${constructBaseCoreAPIURL()}/properties/search?propertyNameContains=${propertyName}&maxResults=${maxResults}`,
    method: 'GET',
    timeout: API_TIMEOUTS.M,
    headers: getClientServiceRequestHeaders(),
  };
  logger.info(`API:getPropertySearchByName /properties/search`);

  try {
    const { data } = await axios(config);
    const parsedData = lyingParse(GetPropertySearchByNameSchema, data);

    metrics.end('getPropertySearchByName', guid);
    logger.debug('getPropertySearchByName', { propertyName });

    return parsedData;
  } catch (error) {
    metrics.fail('getPropertySearchByName', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getPropertySearchByName',
      args: { propertyName },
    });
    throw apiError;
  }
}
