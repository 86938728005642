import { Box, useToken } from '@ebx-ui/ebx-ui-component-library-sdk';
import { fabric } from 'fabric';
import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'throttle-debounce';

import { generateGuid } from 'common/guid';
import {
  alignStickerBackgroundWidthWithText,
  calculateStickerPosition,
  COMPOSE_IMAGE_DIMENSIONS,
  createSticker,
  getStickerPosition,
  INSTAGRAM_LINK_STICKER_NAMES,
  scaleStickerDownToFitCanvas,
  STICKER_FONT_FAMILY,
  STICKER_MAX_HEIGHT_RATIO,
} from 'common/instagramSticker';
import * as MediaItem from 'common/mediaItem';
import * as tracker from 'common/tracker';
import {
  ComposeBoxContextInterface,
  handleIGLinkStickerConfigUpdate,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import useFontLoader from 'hooks/useFontLoader';
import { FixTypeLater, IGLinkStickerConfig } from 'types';

const updateMediaItemStickerConfig = debounce(
  500,
  ({
    dispatch,
    guid,
    linkStickerConfig,
  }: {
    dispatch: ComposeBoxContextInterface['dispatch'];
    guid: string;
    linkStickerConfig: IGLinkStickerConfig;
  }) => {
    dispatch(handleIGLinkStickerConfigUpdate({ guid, linkStickerConfig }));
  },
);

const SNAP_THRESHOLD = 5;
const STICKER_DEFAULT_HEIGHT =
  COMPOSE_IMAGE_DIMENSIONS.HEIGHT * STICKER_MAX_HEIGHT_RATIO;

let isMouseDown = false;
let isHovering = false;

const createRecommendedBoundaryArea = (colour: string) => {
  const areaTop = new fabric.Rect({
    top: 0,
    left: 0,
    width: COMPOSE_IMAGE_DIMENSIONS.WIDTH,
    height: COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    fill: colour,
    opacity: 0.25,
  });

  const areaBottom = new fabric.Rect({
    top: COMPOSE_IMAGE_DIMENSIONS.HEIGHT - COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    left: 0,
    width: COMPOSE_IMAGE_DIMENSIONS.WIDTH,
    height: COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    fill: colour,
    opacity: 0.25,
  });

  const areaLeft = new fabric.Rect({
    top: COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    left: 0,
    width: COMPOSE_IMAGE_DIMENSIONS.PADDING_X,
    height:
      COMPOSE_IMAGE_DIMENSIONS.HEIGHT - 2 * COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    fill: colour,
    opacity: 0.25,
  });

  const areaRight = new fabric.Rect({
    top: COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    left: COMPOSE_IMAGE_DIMENSIONS.WIDTH - COMPOSE_IMAGE_DIMENSIONS.PADDING_X,
    width: COMPOSE_IMAGE_DIMENSIONS.PADDING_X,
    height:
      COMPOSE_IMAGE_DIMENSIONS.HEIGHT - 2 * COMPOSE_IMAGE_DIMENSIONS.PADDING_Y,
    fill: colour,
    opacity: 0.25,
  });

  const recommendedBoundaryArea = new fabric.Group([
    areaTop,
    areaBottom,
    areaLeft,
    areaRight,
  ]);

  recommendedBoundaryArea.selectable = false;
  recommendedBoundaryArea.hoverCursor = 'default';

  return recommendedBoundaryArea;
};

interface InstagramLinkStickerPreviewProps {
  guid: string;
  url: string;
  mediaItem: FixTypeLater;
  onPendingStickerConfig: (pendingStickerConfig: IGLinkStickerConfig) => void;
  pendingStickerConfig: IGLinkStickerConfig | null;
}

const InstagramLinkStickerPreview = ({
  guid,
  url,
  mediaItem,
  onPendingStickerConfig,
  pendingStickerConfig,
}: InstagramLinkStickerPreviewProps) => {
  const id = useRef(generateGuid());
  const { dispatch } = useComposeBoxContext();

  const canvasRef = useRef<fabric.Canvas | null>(null);

  const [red600] = useToken('colors', ['red.600']);
  const { fontLoaded } = useFontLoader({ fontFamily: STICKER_FONT_FAMILY });

  // The original config (positioning) for the link sticker is either using the pending configuration
  // or the configuration stored on the mediaItem.
  const config =
    pendingStickerConfig ?? MediaItem.getIGLinkStickerConfig({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });

  const articleURL =
    MediaItem.getUnshortenedShareURL({
      mediaItem,
    }) ?? 'undefined';

  const initSticker = useCallback(() => {
    const fabricCanvas = new fabric.Canvas(`sticker-canvas-${id.current}`, {
      width: COMPOSE_IMAGE_DIMENSIONS.WIDTH,
      height: COMPOSE_IMAGE_DIMENSIONS.HEIGHT,
      selection: false,
    });
    canvasRef.current = fabricCanvas;

    const { stickerText, stickerBackground } = createSticker(url);

    const stickerGroup = new fabric.Group([stickerBackground, stickerText], {
      hoverCursor: 'grab',
      moveCursor: 'grabbing',
      borderColor: 'transparent',
      name: INSTAGRAM_LINK_STICKER_NAMES.STICKER_GROUP,
      // Set objectCaching to false so the group rerenders when
      // canvas.renderAll is called after the text has changed
      objectCaching: false,
    });

    // Add the link icon to the sticker
    fabric.loadSVGFromURL(
      '/img/icons/instagram-link-sticker.svg',
      (objects, options) => {
        const svgData = fabric.util.groupSVGElements(objects, options);
        svgData.top = (stickerBackground.top ?? 0) + 8;
        svgData.left = (stickerBackground.left ?? 0) + 10;
        svgData.selectable = false;
        stickerGroup.add(svgData);
        fabricCanvas.renderAll();
      },
    );

    // Prevent the user from resizing or rotating the sticker
    stickerGroup.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: false,
      br: false,
      tl: false,
      tr: false,
      mtr: false,
    });

    // Scale sticker group to correct height while preserving proportions
    stickerGroup.scaleToHeight(STICKER_DEFAULT_HEIGHT);
    scaleStickerDownToFitCanvas(stickerGroup);

    if (config) {
      stickerGroup.set(
        calculateStickerPosition(config, fabricCanvas, stickerGroup),
      );
      fabricCanvas.add(stickerGroup);
    } else {
      fabricCanvas.add(stickerGroup);
      fabricCanvas.centerObject(stickerGroup);
    }

    const horizontal = new fabric.Line(
      [
        0,
        COMPOSE_IMAGE_DIMENSIONS.HEIGHT / 2,
        COMPOSE_IMAGE_DIMENSIONS.WIDTH,
        COMPOSE_IMAGE_DIMENSIONS.HEIGHT / 2,
      ],
      {
        backgroundColor: red600,
        hoverCursor: 'default',
        selectable: false,
      },
    );

    const vertical = new fabric.Line(
      [
        COMPOSE_IMAGE_DIMENSIONS.WIDTH / 2,
        0,
        COMPOSE_IMAGE_DIMENSIONS.WIDTH / 2,
        COMPOSE_IMAGE_DIMENSIONS.HEIGHT,
      ],
      {
        backgroundColor: red600,

        // Remove controls to resize/rotate
        hoverCursor: 'default',
        selectable: false,
      },
    );

    fabricCanvas.add(horizontal, vertical);

    // Hide guidelines initially
    horizontal.visible = false;
    vertical.visible = false;

    // Show the red coloured area around border of the image
    const recommendedBoundaryArea = createRecommendedBoundaryArea(red600);

    fabricCanvas.add(recommendedBoundaryArea);
    fabricCanvas.sendToBack(recommendedBoundaryArea);
    recommendedBoundaryArea.visible = false;

    // Update the sticker's opacity when the mouse is hovering over it
    stickerGroup.on('mouseover', () => {
      stickerBackground.set('fill', 'rgba(115, 123, 139, 0.8)');
      fabricCanvas.renderAll();
      isHovering = true;
    });

    // Reset the sticker's opacity when the mouse leaves the sticker
    stickerGroup.on('mouseout', () => {
      // Check mouse is down in case user has dragged mouse off canvas but still has mouse down
      if (!isMouseDown) {
        stickerBackground.set('fill', 'rgba(115, 123, 139, 0.4)');
        fabricCanvas.renderAll();
      }
      isHovering = false;
    });

    // Track whether the mouse is down
    stickerGroup.on('mousedown', () => {
      recommendedBoundaryArea.visible = true;
      isMouseDown = true;
      fabricCanvas.setCursor('grabbing');
    });

    // Reset the sticker's opacity when the user stops dragging the mouse
    stickerGroup.on('mouseup', () => {
      const trackingParams = {
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Article URL': articleURL,
      };
      tracker.track({
        eventName: 'Move Link Sticker',
        trackingParams,
      });
      // Check mouse is not hovering over the sticker
      if (!isHovering) {
        stickerBackground.set('fill', 'rgba(115, 123, 139, 0.4)');
        fabricCanvas.renderAll();
        fabricCanvas.setCursor('default');
      }
      recommendedBoundaryArea.visible = false;
      isMouseDown = false;

      // Hide guidelines
      horizontal.visible = false;
      vertical.visible = false;

      onPendingStickerConfig(getStickerPosition(stickerGroup));
    });

    // Prevent the user from moving the sticker outside the image
    fabricCanvas.on('object:moving', (e) => {
      const sticker = e.target;
      if (!sticker) {
        return;
      }

      const height = sticker.getScaledHeight();
      const width = sticker.getScaledWidth();
      const rightBound = COMPOSE_IMAGE_DIMENSIONS.WIDTH;
      const bottomBound = COMPOSE_IMAGE_DIMENSIONS.HEIGHT;
      let boundedTop = sticker.top;
      let boundedLeft = sticker.left;

      if (sticker.top && sticker.top < 0) {
        // Prevent overflow top
        boundedTop = 0;
      } else if (sticker.top && sticker.top + height > bottomBound) {
        // Prevent overflow bottom
        boundedTop = bottomBound - height;
      }

      if (sticker.left && sticker.left < 0) {
        // Prevent overflow left
        boundedLeft = 0;
      } else if (sticker.left && sticker.left + width > rightBound) {
        // Prevent overflow right
        boundedLeft = rightBound - width;
      }

      // Update the sticker's position if it's been moved outside the image
      if (boundedTop !== sticker.top || boundedLeft !== sticker.left) {
        sticker.set({
          left: boundedLeft,
          top: boundedTop,
        });
      }

      const { x, y } = sticker.getCenterPoint();

      // Snap to guidelines if sticker is within the pixel threshold
      if (Math.abs(x - COMPOSE_IMAGE_DIMENSIONS.WIDTH / 2) < SNAP_THRESHOLD) {
        sticker.set({
          left: COMPOSE_IMAGE_DIMENSIONS.WIDTH / 2 - width / 2,
        });
        vertical.set('visible', true);
      } else {
        vertical.set('visible', false);
      }
      if (Math.abs(y - COMPOSE_IMAGE_DIMENSIONS.HEIGHT / 2) < SNAP_THRESHOLD) {
        sticker.set({
          top: COMPOSE_IMAGE_DIMENSIONS.HEIGHT / 2 - height / 2,
        });
        horizontal.set('visible', true);
      } else {
        horizontal.set('visible', false);
      }

      updateMediaItemStickerConfig({
        dispatch,
        guid,
        linkStickerConfig: getStickerPosition(stickerGroup),
      });
    });

    updateMediaItemStickerConfig({
      dispatch,
      guid,
      linkStickerConfig: getStickerPosition(stickerGroup),
    });
  }, [
    accountAPIId,
    articleURL,
    config,
    dispatch,
    guid,
    onPendingStickerConfig,
    red600,
    url,
  ]);

  useEffect(() => {
    if (fontLoaded && !canvasRef.current) {
      initSticker();
    }
  }, [initSticker, fontLoaded]);

  useEffect(() => {
    const fabricCanvas = canvasRef.current;
    if (!fabricCanvas) {
      return;
    }

    const stickerGroup = fabricCanvas
      .getObjects('group')
      .find(
        (obj) => obj.name === INSTAGRAM_LINK_STICKER_NAMES.STICKER_GROUP,
      ) as fabric.Group;
    const stickerText = stickerGroup
      .getObjects('text')
      .find(
        (obj) => obj.name === INSTAGRAM_LINK_STICKER_NAMES.STICKER_TEXT,
      ) as fabric.Text;
    const stickerBackground = stickerGroup
      .getObjects('rect')
      .find(
        (obj) => obj.name === INSTAGRAM_LINK_STICKER_NAMES.STICKER_BACKGROUND,
      ) as fabric.Rect;

    stickerText.text = url ? url.toUpperCase() : '';
    fabricCanvas.renderAll(); // Render to update text width

    alignStickerBackgroundWidthWithText(stickerBackground, stickerText);
    stickerGroup.addWithUpdate(); // Update sticker group width to new width
    fabricCanvas.renderAll();

    // Resize the text is it needs to shrink/grow
    if (stickerGroup.getScaledHeight() < STICKER_DEFAULT_HEIGHT) {
      stickerGroup.scaleToHeight(STICKER_DEFAULT_HEIGHT);
    }
    scaleStickerDownToFitCanvas(stickerGroup);

    // Move sticker if it is overflowing off canvas
    const stickerLeft = stickerGroup.left ?? 0;
    const stickerRight = stickerLeft + stickerGroup.getScaledWidth();
    if (stickerRight > COMPOSE_IMAGE_DIMENSIONS.WIDTH) {
      stickerGroup.left =
        COMPOSE_IMAGE_DIMENSIONS.WIDTH - stickerGroup.getScaledWidth();
    }

    fabricCanvas.renderAll();
  }, [url]);

  return (
    <Box
      position="absolute"
      inset={0}
      width={COMPOSE_IMAGE_DIMENSIONS.WIDTH}
      marginX="auto"
    >
      <canvas id={`sticker-canvas-${id.current}`} />
    </Box>
  );
};

export default InstagramLinkStickerPreview;
