import {
  Button,
  Flex,
  Image,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import {
  getBitlyAPIs,
  getFacebookAPIs,
  getInstagramAPIs,
  getLinkedInAPIs,
  getTikTokAPIs,
  getTwitterAPIs,
} from 'common/accountAPIs';
import * as logger from 'common/logger';
import { getSocialNetworkIcon, getURNName } from 'common/social';
import { convertToSocialPageURN } from 'common/urn';
import ReviewSection from 'components/setup/ReviewSection';
import reconnectAPI from 'process/reconnectAPI';
import Check from 'svg/Check';
import type { APITypeId, SocialAPI } from 'types';

interface ReviewSocialPageProps {
  accounts: ReadonlyArray<SocialAPI> | undefined;
  title: string;
  onEdit: () => void;
}

const ReviewSocialPage = ({
  title,
  accounts,
  onEdit,
}: ReviewSocialPageProps) => {
  const [isReconnecting, setIsReconnecting] = useState(false);

  if (!accounts || accounts.length === 0) {
    return null;
  }

  const handleReconnect = async (
    accountAPIId: number,
    apiTypeId: APITypeId,
  ) => {
    setIsReconnecting(true);

    const socialPageURN = convertToSocialPageURN(
      getURNName({ apiTypeId }),
      accountAPIId,
    );
    logger.info(`Pages.handleReconnectAPI - api ${accountAPIId}`);
    await reconnectAPI({
      socialPageURN,
    });

    setIsReconnecting(false);
  };

  return (
    <ReviewSection title={title} onEdit={onEdit}>
      {accounts.map(
        ({
          apiPostName,
          apiPostImage,
          requiresReconnect,
          accountAPIId,
          apiTypeId,
        }) => {
          const postImage =
            apiPostImage === '/img/blank.png'
              ? getSocialNetworkIcon({ apiTypeId })
              : apiPostImage;

          return (
            <Flex
              gap={2}
              fontSize="sm"
              lineHeight="5"
              color="gray.900"
              key={apiPostName}
              alignItems="center"
            >
              <Image h={6} w={6} borderRadius="xl" src={postImage} alt="" />
              <Text
                size="sm"
                lineHeight={4}
                color="gray.900"
                fontWeight="medium"
                mr={2}
                {...(requiresReconnect && {
                  textDecorationLine: 'line-through',
                  color: 'gray.500',
                })}
              >
                {apiPostName}
              </Text>
              {requiresReconnect && (
                <Button
                  variant="link"
                  color="red.600"
                  onClick={() => handleReconnect(accountAPIId, apiTypeId)}
                  isDisabled={isReconnecting}
                >
                  Reconnect page
                </Button>
              )}
            </Flex>
          );
        },
      )}
    </ReviewSection>
  );
};

interface ReviewSocialPagesProps {
  accountAPIs: { [accountAPIId: string]: SocialAPI };
  onEdit: () => void;
}

const ReviewSocialPages = ({ accountAPIs, onEdit }: ReviewSocialPagesProps) => {
  const facebookAPIs = getFacebookAPIs({ accountAPIs });
  const twitterAPIs = getTwitterAPIs({ accountAPIs });
  const linkedInAPIs = getLinkedInAPIs({ accountAPIs });
  const instagramAPIs = getInstagramAPIs({ accountAPIs });
  const tikTokAPIs = getTikTokAPIs({ accountAPIs });
  const bitlyAPIs = getBitlyAPIs({ accountAPIs });

  return (
    <>
      <ReviewSocialPage
        accounts={facebookAPIs}
        title="Facebook"
        onEdit={onEdit}
      />
      <ReviewSocialPage
        accounts={twitterAPIs}
        title="Twitter"
        onEdit={onEdit}
      />
      <ReviewSocialPage
        accounts={linkedInAPIs}
        title="LinkedIn"
        onEdit={onEdit}
      />
      <ReviewSocialPage
        accounts={instagramAPIs}
        title="Instagram"
        onEdit={onEdit}
      />
      <ReviewSocialPage accounts={tikTokAPIs} title="TikTok" onEdit={onEdit} />
      {bitlyAPIs?.length > 0 && (
        <ReviewSection title="Bitly" onEdit={onEdit}>
          <Flex
            gap={2}
            fontSize="sm"
            lineHeight="5"
            alignItems="center"
            color="green.600"
            fontWeight="500"
          >
            <Check />
            Connected
          </Flex>
        </ReviewSection>
      )}
    </>
  );
};

export default ReviewSocialPages;
