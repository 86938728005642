import EditableText from 'components/compose/EditableText';
import type { FixTypeLater } from 'types';

interface TitleProps {
  accountAPIId: number;
  canCustomiseLinkPosts: boolean;
  isRefreshPreview: boolean;
  isVideoLink: boolean;
  hasImageOverlay?: boolean;
  mediaItem: FixTypeLater;
  title: string;
  onTitleChange: (newTitle: string) => void;
  onShowDescription: (showDescription: boolean) => void;
}

const Title = ({
  accountAPIId,
  canCustomiseLinkPosts,
  isRefreshPreview,
  isVideoLink,
  mediaItem,
  onShowDescription,
  onTitleChange,
  title,
  hasImageOverlay = false,
}: TitleProps) => {
  return (
    <EditableText
      mediaItem={mediaItem}
      accountAPIId={accountAPIId}
      canCustomiseLinkPosts={canCustomiseLinkPosts}
      className="share_title"
      isRefreshPreview={isRefreshPreview}
      isVideoLink={isVideoLink}
      hasImageOverlay={hasImageOverlay}
      messageText={title}
      placeholder="Enter a title..."
      dataCyInput="title"
      maxLines={1}
      classOnBlur="text-truncate-two-lines"
      onChange={onTitleChange}
      onShowDescription={onShowDescription}
    />
  );
};

export default Title;
