import PropTypes from 'prop-types';

import Button from 'components/misc/Button';

const AddFooter = (props) => {
  const { isSaving, eventHandlers } = props;

  const savingClass = isSaving ? 'saving_locked' : '';

  return (
    <div className="modal-footer">
      <div className="ml-auto">
        <Button
          className="ml-auto"
          data-cy-action="cancelPages"
          onClick={!isSaving ? eventHandlers.handleAddCancel : null}
          disabled={isSaving}
        >
          Cancel
        </Button>
      </div>
      <div className="save">
        <Button
          className={savingClass}
          variant="dark"
          onClick={!isSaving ? eventHandlers.handleAddDomain : null}
          disabled={isSaving}
          data-cy-action="saveNewUser"
        >
          Add Domain
        </Button>
      </div>
    </div>
  );
};

AddFooter.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleAddCancel: PropTypes.func.isRequired,
    handleAddDomain: PropTypes.func.isRequired,
  }).isRequired,
};

export default AddFooter;
