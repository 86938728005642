import { UnorderedList } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

interface StepsProps {
  children: React.ReactNode;
}

const Steps = ({ children }: StepsProps) => (
  <UnorderedList
    m={0}
    gridAutoColumns="1fr"
    display="inline-grid"
    overflow="hidden"
    overflowX="auto"
    gridAutoRows="1fr"
    gridAutoFlow="row"
    sx={{
      counterReset: 'step',
    }}
  >
    {children}
  </UnorderedList>
);

export default Steps;
