import PropTypes from 'prop-types';
import { memo } from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  ACCOUNT_SETTING_TYPES,
  COLLECTION_NAMES,
  SHARE_ORIGINS,
} from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getSetting } from 'common/settings';
import { isVideoURL } from 'common/url';
import { arePropsEqual, isNull } from 'common/utility';
import ItemActions from 'components/home/archive/ItemActions';
import ItemImage from 'components/home/archive/ItemImage';
import ItemMenu from 'components/home/archive/ItemMenu';
import ItemPublished from 'components/home/archive/ItemPublished';
import ItemTimeSensitivity from 'components/home/archive/ItemTimeSensitivity';
import ItemTitle from 'components/home/archive/ItemTitle';
import Loading from 'components/home/archive/Loading';
import ItemScore from 'components/home/ItemScore';
import TimingBadges from 'components/home/TimingBadges';
import { useIsComposeBoxOpen } from 'state/composeBoxOpen';

const Item = (props) => {
  const isComposeBoxOpen = useIsComposeBoxOpen();

  const { mediaItem, sharedMethods } = props;

  const isLoading = MediaItem.getIsLoading({ mediaItem });
  const mediaId = MediaItem.getMediaId({ mediaItem });

  if (isLoading) {
    return <Loading mediaId={mediaId} />;
  }

  const handleArticleShare = () => {
    if (isComposeBoxOpen) {
      return;
    }

    Compose.editPost({
      editItem: mediaItem,
      itemOrigin: SHARE_ORIGINS.ARCHIVE,
    });
  };

  const handleTimeSensitivityClick = (timeSensitivityTypeId) => {
    sharedMethods.updateAndSaveCollectionItem({
      collectionName: COLLECTION_NAMES.ARTICLE_ARCHIVE,
      mediaId,
      mediaItem,
      fieldName: 'timeSensitivityTypeId',
      fieldValue: timeSensitivityTypeId,
    });
  };

  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  const articleTitle = MediaItem.getRssTitle({ mediaItem });
  const isSaving = MediaItem.getIsSaving({ mediaItem });
  const itemState = MediaItem.getState({ mediaItem });
  const itemTags = MediaItem.getTags({ mediaItem });
  const mostRecentScore = MediaItem.getMostRecentScore({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const previewFields = MediaItem.getPreviewFields({ mediaItem });
  const publishedTime = MediaItem.getMostRecentUnixTimePublished({
    mediaItem,
  });
  const shareTime = MediaItem.getShareTime({ mediaItem });
  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });
  const timeSensitivityTypeId = MediaItem.getTimeSensitivityTypeId({
    mediaItem,
  });
  const totalNumberOfShares = MediaItem.getTotalNumberOfShares({ mediaItem });
  const unshortenedShareURL = MediaItem.getUnshortenedShareURL({ mediaItem });

  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId: getCurrentPropertyId(),
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;

  const savingClass = isSaving ? 'saving_locked' : '';

  return (
    <div
      className={`article-archive-item d-flex flex-column ${savingClass}`}
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      <div
        className="article-expanded d-flex flex-row"
        data-cy-attribute="isLoading:false"
      >
        <div className="d-none" data-cy-id="message">
          {previewFields.message}
        </div>
        <ItemImage
          imageURLs={previewFields.imageURLs}
          isVideoLink={isVideoURL(unshortenedShareURL)}
        />
        <div className="article-content order-1 order-md-2">
          <div className="article-badges-title">
            <div className="article-badges">
              <ItemScore
                itemState={itemState}
                mediaId={mediaId}
                mostRecentScore={mostRecentScore}
              />
              <TimingBadges
                origin="ARTICLE_ARCHIVE"
                shareTime={shareTime}
                postType={postType}
                twelveHourFormat={twelveHourFormat}
                timeSensitivityTypeId={timeSensitivityTypeId}
                suggestionTypeId={suggestionTypeId}
                accountAPIId={accountAPIId}
                totalNumberOfShares={totalNumberOfShares}
                showReshareCount={false}
                showShareCount={true}
                showTimeSensitivity={true}
                mediaId={mediaId}
              />
            </div>
            <ItemTitle
              accountAPIId={accountAPIId}
              articleTitle={articleTitle}
              itemTags={itemTags}
            />
          </div>
          <ItemPublished
            articleURL={unshortenedShareURL}
            publishedTime={publishedTime}
            twelveHourFormat={twelveHourFormat}
          />
        </div>
        <div className="btn-expanded order-3">
          <div className="btn-group-display-hover">
            <ItemTimeSensitivity
              timeSensitivityTypeId={timeSensitivityTypeId}
              eventHandlers={{ handleTimeSensitivityClick }}
            />
          </div>
          <ItemActions isMobile={false} onArticleShare={handleArticleShare} />
        </div>
      </div>
      <div className="article-compact">
        <ItemTimeSensitivity
          timeSensitivityTypeId={timeSensitivityTypeId}
          eventHandlers={{ handleTimeSensitivityClick }}
        />
        <ItemMenu articleURL={unshortenedShareURL} />
        <ItemActions isMobile={true} onArticleShare={handleArticleShare} />
      </div>
    </div>
  );
};

Item.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  sharedMethods: PropTypes.shape({
    updateAndSaveCollectionItem: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(Item, arePropsEqual);
