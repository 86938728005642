import {
  Alert,
  Flex,
  useRadioGroup,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { AUTOFEED_SHARE_MODES } from 'common/constants';
import SettingsRadioCard from 'components/misc/SettingsRadioCard';
import CustomSettings from 'components/settings/automation/newshares/CustomSettings';
import { NewSharesSettings } from 'components/settings/automation/newshares/NewShares';
import { AutofeedShareMode } from 'types';

interface NewShareModesProps {
  currentNewShareSettings: NewSharesSettings;
  handleNewShareSettingsChange: (
    settings: NewSharesSettings,
    error?: boolean,
  ) => void;
}

function NewShareModes({
  currentNewShareSettings,
  handleNewShareSettingsChange,
}: NewShareModesProps) {
  const handleNewShareMode = (shareMode: AutofeedShareMode) => {
    const newSettings = {
      ...currentNewShareSettings,
      newShareMode: shareMode,
    };
    handleNewShareSettingsChange(newSettings);
  };

  const shareModes = {
    [AUTOFEED_SHARE_MODES.OPTIMAL]: [
      'Selects content for maximum performance',
      'Optimizes timing for each share to maximize performance',
      'Picks the right share volume to maximize performance',
    ],
    [AUTOFEED_SHARE_MODES.CUSTOM]: [
      'Set a daily target of new shares',
      'Select how far back Echobox may pick content from',
      'Your highest scoring content is shared at the optimal time to maximize performance',
    ],
    [AUTOFEED_SHARE_MODES.OFF]: [],
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'new-shares-settings',
    defaultValue: currentNewShareSettings.newShareMode,
    onChange: handleNewShareMode,
  });

  const group = getRootProps();

  return (
    <Flex flexDir="column" gap={2} mb="48px" {...group}>
      <SettingsRadioCard
        key={AUTOFEED_SHARE_MODES.OPTIMAL}
        settingDescriptions={shareModes[AUTOFEED_SHARE_MODES.OPTIMAL]}
        value={AUTOFEED_SHARE_MODES.OPTIMAL}
        {...getRadioProps({ value: AUTOFEED_SHARE_MODES.OPTIMAL })}
      >
        <Alert variant="info">
          <Alert.Description>
            Setting both New Shares and Reshares to Optimal may prioritise
            resharing articles to maximize performance
          </Alert.Description>
        </Alert>
      </SettingsRadioCard>
      <SettingsRadioCard
        key={AUTOFEED_SHARE_MODES.CUSTOM}
        settingDescriptions={shareModes[AUTOFEED_SHARE_MODES.CUSTOM]}
        value={AUTOFEED_SHARE_MODES.CUSTOM}
        {...getRadioProps({ value: AUTOFEED_SHARE_MODES.CUSTOM })}
      >
        <CustomSettings
          currentNewShareSettings={currentNewShareSettings}
          handleNewShareSettingsChange={handleNewShareSettingsChange}
        />
      </SettingsRadioCard>
      <SettingsRadioCard
        key={AUTOFEED_SHARE_MODES.OFF}
        settingDescriptions={shareModes[AUTOFEED_SHARE_MODES.OFF]}
        value={AUTOFEED_SHARE_MODES.OFF}
        {...getRadioProps({ value: AUTOFEED_SHARE_MODES.OFF })}
        hideIcon
      />
    </Flex>
  );
}

export default NewShareModes;
