import PropTypes from 'prop-types';

import Button from 'components/misc/Button';
import Dialog from 'components/misc/Dialog';

const { DialogHeader, DialogBody, DialogFooter } = Dialog;

const ChangePasswordConfirmation = ({
  isOpen,
  onDismiss,
  onChangePassword,
}) => {
  return (
    <Dialog
      className="modal-sm"
      isOpen={isOpen}
      onDismiss={onDismiss}
      centerVertically
    >
      <DialogHeader>Change password</DialogHeader>
      <DialogBody>
        You will be signed out of Echobox. Please use your new password to sign
        back in.
      </DialogBody>
      <DialogFooter>
        <Button
          id="change-password-cancel"
          size="lg"
          className="mr-2"
          onClick={onDismiss}
        >
          Cancel
        </Button>
        <Button
          id="change-password"
          variant="dark"
          size="lg"
          loading
          loadingEl="Changing password"
          onClick={onChangePassword}
        >
          Change password and sign out
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

ChangePasswordConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
};

export default ChangePasswordConfirmation;
