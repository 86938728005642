import { LOGIN_MESSAGING_URLS } from '@ebx-auth/ebx-clientauth-frontend-sdk';

import * as environment from 'common/environment';

export const postMessage = (message: string) => {
  const hostType = environment.getHostType();
  const iframe = document.getElementById('login-app') as HTMLIFrameElement;
  iframe.contentWindow?.postMessage(message, LOGIN_MESSAGING_URLS[hostType]);
};

export const loadApp = () => {
  // update iframe src to point to correct login app based on environment
  const hostType = environment.getHostType();
  const iframe = document.getElementById('login-app') as HTMLIFrameElement;
  iframe.src = LOGIN_MESSAGING_URLS[hostType];
};
