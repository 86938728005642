import {
  Box,
  Center,
  Heading,
  Spinner,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useContext, useEffect, useState } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import { GLOBAL_INFO_STATES } from 'common/constants';
import { getAllRSSFeeds } from 'common/feed';
import AddRSSFeed from 'components/setup/AddRSSFeed';
import AllowContentCheckbox from 'components/setup/AllowContentCheckbox';
import ConnectedFeeds from 'components/setup/ConnectedFeeds';
import ConnectRSSFeedToSocialPages from 'components/setup/ConnectRSSFeedToSocialPages';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { useOnboardingSetupContext } from 'context/OnboardingContext';
import useAllowContentFromAllDomains from 'hooks/useAllowContentFromAllDomains';
import type { RssFeedData } from 'types';

const ConnectYourContent = () => {
  const {
    allowContentFromAllDomains: initialAllowContentFromAllDomains,
    isLoading,
  } = useAllowContentFromAllDomains();
  const { setNextButtonDisabled } = useOnboardingSetupContext();
  const { global } = useContext(GlobalInfoContext);
  const globalInfo = global.getGlobalInfo();

  const [rssFeedData, setRssFeedData] = useState<RssFeedData | null>(null);
  const accountFeeds = getCurrentProperty({ globalInfo }).accountFeeds;

  const [isAdd, setIsAdd] = useState(false);
  const allRSSFeeds = getAllRSSFeeds();

  useEffect(() => {
    setNextButtonDisabled(!accountFeeds || accountFeeds.length === 0);
  }, [accountFeeds, setNextButtonDisabled]);

  useEffect(() => {
    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.REFRESHING,
      allowUnderSetup: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || global.isRefreshing()) {
    return (
      <Center>
        <Spinner size="lg" />
      </Center>
    );
  }

  return (
    <Box maxW="776px" data-cy-attribute="isLoading:false">
      <Heading variant="h2">Add content feeds</Heading>
      <Text size="sm" color="gray.600" mt={2}>
        A content feed is an RSS feed or sitemap that contains all the articles
        you’ve published to your website. You need to add the URL to at least
        one of these, so Echobox can optimise your sharing.
      </Text>
      <Box mt={8}>
        <VStack align="stretch" spacing={8}>
          <VStack align="flex-start" spacing={10}>
            <AddRSSFeed
              existingFeeds={allRSSFeeds}
              onSaveRssFeed={(feedData) => {
                setIsAdd(true);
                setRssFeedData(feedData);
              }}
            />
            <ConnectedFeeds
              onEditFeed={(feedData) => {
                setIsAdd(false);
                setRssFeedData(feedData);
              }}
            />
          </VStack>
          {allRSSFeeds.length > 0 && (
            <AllowContentCheckbox
              initialAllowContentFromAllDomains={
                initialAllowContentFromAllDomains
              }
            />
          )}
          {rssFeedData && (
            <ConnectRSSFeedToSocialPages
              onDismiss={() => setRssFeedData(null)}
              rssFeedData={rssFeedData}
              isAdd={isAdd}
            />
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default ConnectYourContent;
