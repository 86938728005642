import {
  Button,
  ButtonProps,
  forwardRef,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const HeaderButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    ref={ref}
    px={3}
    py={2}
    fontWeight="medium"
    fontSize="sm"
    lineHeight="base"
    color="gray.600"
    bg="gray.100"
    border="none"
    boxShadow="border"
    borderRadius="md"
    filter="none"
    h={9}
    _hover={{
      bg: 'gray.200',
    }}
    _focus={{
      outline: 'none',
    }}
    _focusVisible={{
      outline: 'none',
      boxShadow: 'focus',
      bg: 'gray.200',
    }}
    _active={{
      outline: 'none',
      boxShadow: 'focus',
      bg: 'gray.100',
    }}
    {...props}
  />
));

export default HeaderButton;
