import {
  AUTOFEED_SHARE_MODES,
  FRONTEND_PAGES,
  POST_TYPES,
  SOCIAL_CHANNELS,
  SPECIAL_CHARACTER_REGEX,
  TAG_TYPES,
} from 'common/constants/common';

const constants = {
  urnName: 'ig',
  apiType: 'INSTAGRAM',
  name: 'Instagram',
  lowerName: 'instagram',
  icon: '/img/icons/ic-instagram.svg',
  defaultPageIcon: '/img/icons/ic-instagram-transparent.svg',
  emojiSet: 'emojione',
  allowedFileTypes: {
    [POST_TYPES.STATUS]: { 'image/jpeg': [] },
    [POST_TYPES.LINK]: { 'image/jpeg': [] },
  },
  /* jscpd:ignore-start */
  autofeedDefaults: {
    autoCapitaliseMessage: false,
    autoFeedActive: false,
    bestArticlesNewContentNum: 288,
    bestContentOnlySinceCurrentOnPeriod: false,
    breakingNewsIntervalSecs: 3600,
    bestArticlesReshareNum: 15,
    excludeAllContentContaining: [],
    excludeAllContentURLPathContaining: [],
    excludeReshareContentContaining: [],
    excludeReshareContentURLPathContaining: [],
    excludeImagesContaining: [],
    includeImages: true,
    includeMessage: true,
    includeOnlyContentContaining: [],
    includeOnlyContentFromRSSFeeds: [],
    includeOnlyContentURLPathContaining: [],
    messageTextToRemove: [],
    maxResharesPerArticle: 5,
    minMinsBetweenSharesOfSameArticle: 14 * 24 * 60,
    newshareMessagePrefix: '',
    newshareMessageSuffix: '',
    newShareMode: AUTOFEED_SHARE_MODES.OPTIMAL,
    prioritiseCuratedContent: false,
    reshareMessagePrefix: '',
    reshareMessageSuffix: '',
    reshareMode: AUTOFEED_SHARE_MODES.OPTIMAL,
    shareNowAllContentContaining: [],
    startPastMinsForBestArticleReshare: 80 * 24 * 60,
    startPastMinsForNewShare: 2 * 24 * 60,
    statusScheduleTriggers: [],
  },
  /* jscpd:ignore-end */
  autofeedMessageSources: {
    ARTICLE_QUOTE: 'Article Quote',
    RSS_TITLE: 'RSS Title',
    RSS_DESCRIPTION: 'RSS Description',
    OG_TITLE: 'OG Title',
    OG_DESCRIPTION: 'OG Description',
    TWITTER_TITLE: 'Twitter Title',
    TWITTER_DESCRIPTION: 'Twitter Description',
    NULL: 'Keep Empty',
  },
  appendMentionsAtSymbol: true,
  appendSpace: true,
  canAccessPage: {
    [FRONTEND_PAGES.AUTOFEED]: false,
  },
  canAddVideoThumbnail: {
    [SOCIAL_CHANNELS.REEL]: true,
    [SOCIAL_CHANNELS.FEED]: true,
  },
  canDeletePosts: false,
  canHaveMultipleAccounts: true,
  canResharePosts: true,
  canReshareVideos: false,
  canShareNow: true,
  colorHashtagsAndMentions: true,
  dataSourceDefaults: {
    missing: {
      image: 1, // OG Image
    },
    empty: {},
  },
  descriptionSourceExclusions: ['OPTIMAL_MESSAGE'],
  dimensionLimits: {
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.REEL]: {
        width: {
          max: 1920,
        },
      },
      [SOCIAL_CHANNELS.FEED]: {
        width: {
          max: 1920,
        },
      },
      [SOCIAL_CHANNELS.STORY]: {},
    },
  },
  entityName: 'page',
  excludeImagesRule:
    'Images that contain any of the following keywords should not be included for Autofeed schedules:',
  firstCommentSettings: {
    maximumLength: 2200,
  },
  hasABVariations: false,
  hasImageOverlays: false,
  hasAudienceRestriction: false,
  hasCharacterCount: false,
  hasDescriptionField: false,
  hasDomainField: false,
  hasHashtagsMentions: true,
  hasLinkPlaceholder: false,
  hasMentionsLookups: false,
  hasMessageBeforeImage: false,
  hasMessageField: {
    [POST_TYPES.LINK]: {
      [SOCIAL_CHANNELS.FEED]: true,
      [SOCIAL_CHANNELS.STORY]: false,
    },
    [POST_TYPES.STATUS]: {
      [SOCIAL_CHANNELS.FEED]: true,
      [SOCIAL_CHANNELS.STORY]: false,
    },
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: true,
      [SOCIAL_CHANNELS.REEL]: true,
      [SOCIAL_CHANNELS.STORY]: false,
    },
    [POST_TYPES.PHOTO_STORY]: false,
  },
  hasShareMessages: false,
  hasSponsoredPosts: false,
  hasSyndFeeds: true,
  hasTitleField: false,
  hasVerifiedAccounts: false,
  hasVideoTitle: false,
  imageSources: {
    RSS_IMAGE_LARGEST: 'RSS Image',
    OG_IMAGE_LARGEST: 'OG Image',
    TWITTER_IMAGE_LARGEST: 'Twitter Image',
  },
  isLinkShorteningDisabled: true,
  isMessageFieldRequired: false,
  isSocialNetwork: true,
  isValidAspectRatioMandatory: true,
  maximumUrlLength: 245,
  maximumUrlByteLength: 251,
  messageBoxPlaceholder: 'Write a caption...',
  messageSettings: {
    maximumLength: 2200,
  },
  messageSources: {
    OPTIMAL_MESSAGE: 'ChatGPT',
    RSS_TITLE: 'RSS Title',
    RSS_DESCRIPTION: 'RSS Description',
    OG_TITLE: 'OG Title',
    OG_DESCRIPTION: 'OG Description',
    TWITTER_TITLE: 'Twitter Title',
    TWITTER_DESCRIPTION: 'Twitter Description',
    NULL: 'Keep Empty',
  },
  newShareOptions: {
    articleUrlIcon: 'ic-ig-new-share.svg',
    photoVideoTitle: 'FEED',
  },
  pageURLTemplate: 'https://www.instagram.com/PAGENAME',
  placeholderText: {
    [POST_TYPES.STATUS]: ['JPG files', '8MB max'],
    [POST_TYPES.PHOTO_STORY]: ['JPG files', '8MB max'],
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: [
        'MP4 or MOV files',
        '3 seconds to 15 minutes',
        '1GB max',
      ],
      [SOCIAL_CHANNELS.STORY]: [
        'MP4 or MOV files',
        '3 to 60 seconds',
        '9:16 aspect ratio',
        '100MB max',
      ],
      [SOCIAL_CHANNELS.REEL]: [
        'MP4 or MOV files',
        '3 seconds to 15 minutes',
        '9:16 aspect ratio',
        '1GB max',
      ],
    },
  },
  postTypes: {
    [POST_TYPES.LINK]: 'Photo',
    [POST_TYPES.STATUS]: {
      [SOCIAL_CHANNELS.FEED]: 'Photo',
      [SOCIAL_CHANNELS.STORY]: 'Story',
    },
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: 'Video',
      [SOCIAL_CHANNELS.REEL]: 'Reel',
      [SOCIAL_CHANNELS.STORY]: 'Story',
    },
    [POST_TYPES.PHOTO_STORY]: 'Photo',
  },
  lastSharedLabel: {
    [POST_TYPES.LINK]: 'Link',
    [POST_TYPES.STATUS]: {
      [SOCIAL_CHANNELS.FEED]: 'Photo Feed',
      [SOCIAL_CHANNELS.STORY]: 'Photo Story',
    },
    [POST_TYPES.VIDEO]: {
      [SOCIAL_CHANNELS.FEED]: 'Video Feed',
      [SOCIAL_CHANNELS.REEL]: 'Reel',
      [SOCIAL_CHANNELS.STORY]: 'Video Story',
    },
    [POST_TYPES.PHOTO_STORY]: 'Photo Story',
  },
  shareIcon: {
    [POST_TYPES.STATUS]: '/img/icons/ic-photo.svg',
    [POST_TYPES.VIDEO]: '/img/icons/ic-camera.svg',
    [POST_TYPES.PHOTO_STORY]: '/img/icons/ic-photo-story.svg',
  },
  showOnSetupPage: false,
  supportedSocialChannels: [
    SOCIAL_CHANNELS.FEED,
    SOCIAL_CHANNELS.STORY,
    SOCIAL_CHANNELS.REEL,
  ],
  tagPatterns: {
    [TAG_TYPES.HASHTAG]: new RegExp(`#(${SPECIAL_CHARACTER_REGEX})`, 'g'),
    [TAG_TYPES.MENTION]: new RegExp(`@(${SPECIAL_CHARACTER_REGEX})`, 'g'),
  },
  titleSourceExclusions: ['OPTIMAL_MESSAGE'],
  validationMessages: {
    videoAspectRatio: {
      [SOCIAL_CHANNELS.REEL]:
        'Instagram requires Reels have a 9:16 aspect ratio',
    },
    videoFileType: {
      [SOCIAL_CHANNELS.REEL]:
        'Instagram requires Reels be of the filetype .MP4 or .MOV',
    },
    videoDuration: {
      [SOCIAL_CHANNELS.REEL]:
        'Instagram requires Reels be between 3 seconds and 15 minutes',
    },
    videoLargeFileSize: {
      [SOCIAL_CHANNELS.REEL]: 'Reels must have a file size less than 1GB',
    },
    videoMissing: {
      [SOCIAL_CHANNELS.REEL]: 'Please ensure your Reel includes a video.',
    },
  },
} as const;

export default constants;
