import { Box, Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

export interface DeleteFeedConfirmationModalProps {
  feedTitle?: string;
  feedURL: string;
  onCancel: () => void;
  onDelete: () => Promise<void>;
}

const DeleteFeedConfirmationModal = ({
  feedTitle,
  feedURL,
  onCancel,
  onDelete,
}: DeleteFeedConfirmationModalProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
  };

  const handleClose = () => {
    if (!isDeleting) {
      onCancel();
    }
  };

  const formattedFeedTitle = feedTitle?.replace('|', '-');

  return (
    <Modal isOpen onClose={handleClose} size="small">
      <Modal.Header>
        <Modal.Title>Remove content feed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text color="gray.600" size="sm" wordBreak="break-word">
          <>
            You’re about to remove{' '}
            {feedTitle && (
              <Box as="span" fontWeight="bold">
                {`${formattedFeedTitle} `}
              </Box>
            )}
            {feedTitle ? (
              `(${feedURL})`
            ) : (
              <Box as="span" fontWeight="medium">
                {feedURL}
              </Box>
            )}{' '}
            from Echobox.
          </>
        </Text>
      </Modal.Body>
      <Modal.Footer data-cy-id="setupModalFooter">
        <Button variant="secondary" onClick={onCancel} isDisabled={isDeleting}>
          Cancel
        </Button>
        <Button
          data-cy-action="confirmDelete"
          variant="danger"
          onClick={handleDelete}
          isDisabled={isDeleting}
          isLoading={isDeleting}
          loadingText="Removing"
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteFeedConfirmationModal;
