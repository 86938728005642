import { SUGGESTION_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import Tooltip from 'components/misc/Tooltip';
import * as React from 'react';
import type { FixTypeLater } from 'types';

interface ForceShareProps {
  applySingleFieldUpdateForGuid: (args: {
    guid: string;
    fieldName: string;
    fieldValue: number;
  }) => void;
  guid: string;
  mediaItem: FixTypeLater;
}

const ForceShare = (props: ForceShareProps) => {
  const { applySingleFieldUpdateForGuid, guid, mediaItem } = props;
  const id = `force-share-${guid}`;

  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });

  const handleChange = React.useCallback(() => {
    applySingleFieldUpdateForGuid({
      guid,
      fieldName: 'suggestionTypeId',
      fieldValue:
        suggestionTypeId === SUGGESTION_TYPES.AUTO_SOCIAL_SHARE
          ? SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE
          : SUGGESTION_TYPES.AUTO_SOCIAL_SHARE,
    });
  }, [applySingleFieldUpdateForGuid, guid, suggestionTypeId]);

  // Store the original value of isChanged in a ref so that we can compare it to the current value
  // If the value of isChanged switches from false to true, then enable 'Force Share' just once.
  const isChangedRef = React.useRef(MediaItem.getIsChanged({ mediaItem }));
  const isChanged = MediaItem.getIsChanged({ mediaItem });
  React.useEffect(() => {
    if (!isChangedRef.current && isChanged) {
      handleChange();
      isChangedRef.current = true;
    }
  }, [handleChange, isChanged]);

  return (
    <div className="d-flex align-items-center mb-2">
      <input
        type="checkbox"
        id={id}
        name="autofeed"
        checked={suggestionTypeId === SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE}
        onChange={handleChange}
      />
      <label htmlFor={id} className="flex-grow-1">
        Force Share
      </label>
      <Tooltip
        id="autofeedicon"
        label="Prevent the Autofeed from deleting this post"
      >
        <img
          className="info_icon"
          src="/img/icons/ic-information.svg"
          alt=""
          data-tip
          data-for="autofeedicon"
          data-iscapture="true"
        />
      </Tooltip>
    </div>
  );
};

export default ForceShare;
