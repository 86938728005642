import { memo } from 'react';

import { arePropsEqual } from 'common/utility';

const TimeframeOptions = () => (
  <>
    <option value="6">6 hours</option>
    <option value="12">12 hours</option>
    <option value="24">24 hours</option>
    <option value="48">48 hours</option>
    <option value="168">1 week</option>
  </>
);

export default memo(TimeframeOptions, arePropsEqual);
