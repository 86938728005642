import {
  Alert,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { extractPropertyId } from 'common/urn';
import type { GetPropertiesResponseData } from 'types';
import PropertyStateTag from './PropertyStateTag';

interface PropertySearchOptionsProps {
  onBackClick: () => void;
  onPropertySelect: (propertyURN: string) => void;
  properties: Array<GetPropertiesResponseData>;
  warning?: string;
}

const PropertySearchOptions = ({
  onBackClick,
  onPropertySelect,
  properties,
  warning,
}: PropertySearchOptionsProps) => {
  return (
    <>
      <div>
        <Button variant="secondary" gap={1} onClick={onBackClick}>
          <ChevronLeftIcon /> Back to search
        </Button>
      </div>
      {warning && (
        <Alert variant="info">
          <Alert.Title fontSize="sm">{warning}</Alert.Title>
        </Alert>
      )}
      <Flex flexDir="column" gap={1}>
        {properties.map((property) => (
          <Button
            key={property.propertyURN}
            onClick={() => onPropertySelect(property.propertyURN)}
            variant="tertiary"
            rightIcon={<ChevronRightIcon />}
            justifyContent="space-between"
          >
            <Flex as="span" gap={1}>
              {property.propertyName} ({extractPropertyId(property.propertyURN)}
              )
              <PropertyStateTag propertyState={property.propertyState} />
            </Flex>
          </Button>
        ))}
      </Flex>
    </>
  );
};

export default PropertySearchOptions;
