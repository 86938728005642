const sortRSSFeedsByTitle = (feeds) => {
  const compareFeedTitles = (a, b) => {
    if (a.feedTitle < b.feedTitle) {
      return -1;
    }
    if (a.feedTitle > b.feedTitle) {
      return 1;
    }
    return 0;
  };

  return feeds.sort(compareFeedTitles);
};

export default sortRSSFeedsByTitle;
