import $ from 'jquery';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';

import { Box, Drawer, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import getMediaList from 'api/getMediaList';
import {
  getAPIsByNetwork,
  getCurrentAccountAPIId,
  getCurrentPropertyId,
  getPropertyIdForAccountAPIId,
  getPropertyPermission,
  isCurrentPropertySuspended,
} from 'common/accountAPIs';
import { FAILED_SHARE_DURATION, REFRESH_MULTIPLIERS } from 'common/config';
import {
  API_STATES,
  FRONTEND_PAGES,
  GLOBAL_INFO_CHANGES,
  MEDIA_ITEM_STATES,
  PERMISSION_TYPES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import { getTimeFilterRange } from 'common/datetime';
import { isEventInElementByClass } from 'common/event';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { refreshInterval as getRefreshInterval } from 'common/misc';
import { isOnPage } from 'common/path';
import { withLocation } from 'common/routing';
import {
  isDefined,
  isEmpty,
  isEmptyOrNullOrUndefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import SocialPropertySelect from 'components/misc/SocialPropertySelect';
import SidebarNetworks from 'components/sidebar/SidebarNetworks';
import withGlobalInfo from 'context/withGlobalInfo';
import { canCollectPageStatistics, getAccountAPIs } from 'helpers/sidebar';
import * as topics from 'pubsub/topics';

import AddPage from './AddPage';
import SidebarUserMenu from './SidebarUserMenu';

const { REQUEST_API_INFO, RESPONSE_API_INFO } = topics;

/**
 * Left-hand menu
 */

class Sidebar extends BaseComponent {
  /**
   * Constructor and initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isLoggingIn: false,
    };
    this._bind('handleMouseDown', 'handleUserCancel', 'receiveQueueCounts');
    this.firstRefreshTimeout = null;
    this.tokens = [];
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    logger.info('Sidebar:componentDidMount');

    window.addEventListener(
      'beforeunload',
      this.componentWillUnmount.bind(this),
    );

    if (
      isOnPage({ page: FRONTEND_PAGES.LOGIN, location: this.props.location })
    ) {
      return;
    }

    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Sidebar', {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.ANY_STATE_CHANGE,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountAPIId !== this.props.accountAPIId) {
      this.reinitialiseComponent();
    }
  }

  componentWillUnmount() {
    logger.info('Sidebar:componentWillUnmount');

    this.terminateComponent();
  }

  initialiseComponent() {
    logger.info('Sidebar:initialiseComponent');

    // Add event handlers
    window.addEventListener('mousedown', this.handleMouseDown);

    // Add intervals to get queue counts for all active pages after an initial delay
    const globalInfo = this.props.global.getGlobalInfo();
    if (!isNullOrUndefined(globalInfo) && !isEmpty(globalInfo)) {
      this.initialiseIntervals();
    }

    // Add subscriptions
    logger.info(
      `PubSub: subscribe ${RESPONSE_API_INFO} in components/sidebar/Sidebar.initialiseComponent`,
    );
    this.tokens = [
      PubSub.subscribe(RESPONSE_API_INFO, this.receiveQueueCounts),
    ];
  }

  initialiseIntervals() {
    const globalInfo = this.props.global.getGlobalInfo();
    const currentAccountAPIId = getCurrentAccountAPIId({ globalInfo });
    const accountAPIs = getAccountAPIs(getAPIsByNetwork());
    const propertyId = getCurrentPropertyId();

    this.firstRefreshTimeout = window.setTimeout(async () => {
      // Get initial queued counts
      if (canCollectPageStatistics(propertyId)) {
        const mediaListResponses = await Promise.all(
          accountAPIs
            .filter(({ apiStateId }) => apiStateId === API_STATES.ACTIVE)
            .map(async ({ accountAPIId }) => {
              const response = { accountAPIId };
              const scheduleQueueConfig = {
                accountAPIId,
                state: MEDIA_ITEM_STATES.SCHEDULED,
                origin: `${REQUEST_API_INFO}:refreshQueueCounts`,
              };
              const failedQueueConfig = {
                accountAPIId,
                state: MEDIA_ITEM_STATES.FAILED,
                from: getTimeFilterRange(FAILED_SHARE_DURATION).fromTime,
                to: null,
                filter: 'all',
                origin: `${REQUEST_API_INFO}:refreshQueueCounts`,
              };
              const scheduledMediaList =
                await getMediaList(scheduleQueueConfig);
              response.queuedItems = scheduledMediaList.mediaIds.length;

              const failedMediaList = await getMediaList(failedQueueConfig);
              response.failedItems = failedMediaList.mediaIds.length;

              return response;
            }),
        );

        mediaListResponses.forEach((response) =>
          PubSub.publish(RESPONSE_API_INFO, response),
        );
      }

      accountAPIs.forEach((accountAPI) => {
        const { accountAPIId, apiStateId } = accountAPI;
        const isSelected = accountAPIId === currentAccountAPIId;
        if (apiStateId === API_STATES.ACTIVE) {
          const refreshInterval = isSelected
            ? getRefreshInterval(REFRESH_MULTIPLIERS.SELECTED_API_QUEUED_ITEMS)
            : getRefreshInterval(
                REFRESH_MULTIPLIERS.UNSELECTED_API_QUEUED_ITEMS,
              );

          // Create interval to refresh queued counts
          if (isUndefined(window.intervals)) {
            window.intervals = {};
          }
          if (isUndefined(window.intervals.refreshQueueCounts)) {
            window.intervals.refreshQueueCounts = {};
          }
          if (isDefined(window.intervals.refreshQueueCounts[accountAPIId])) {
            window.clearInterval(
              window.intervals.refreshQueueCounts[accountAPIId],
            );
          }
          window.intervals.refreshQueueCounts[accountAPIId] =
            window.setInterval(() => {
              Sidebar.requestQueueCounts(accountAPIId, propertyId);
            }, refreshInterval);
        }
      });
    }, getRefreshInterval(REFRESH_MULTIPLIERS.API_QUEUED_ITEMS_FIRST));
  }

  reinitialiseComponent() {
    this.terminateComponent();
    this.initialiseComponent();
  }

  terminateComponent() {
    logger.info('Sidebar:terminateComponent');

    // Remove event handlers
    window.removeEventListener('mousedown', this.handleMouseDown);

    // Remove timeouts and intervals
    if (!isNull(this.firstRefreshTimeout)) {
      window.clearTimeout(this.firstRefreshTimeout);
    }
    if (window.intervals?.refreshQueueCounts) {
      Object.keys(window.intervals.refreshQueueCounts).forEach(
        (accountAPIId) => {
          window.clearTimeout(
            window.intervals.refreshQueueCounts[accountAPIId],
          );
          delete window.intervals.refreshQueueCounts[accountAPIId];
        },
      );
    }

    // Remove subscriptions
    logger.info(
      `PubSub: unsubscribe ${RESPONSE_API_INFO} in components/sidebar/Sidebar.terminateComponent`,
    );
    if (isDefined(this.token)) {
      this.tokens.forEach((token) => {
        PubSub.unsubscribe(token);
      });
    }
  }

  /**
   * Helper methods
   */

  receiveQueueCounts(message, data) {
    const { accountAPIId, queuedItems, failedItems } = data;

    logger.info(
      `Sidebar:receiveQueueCounts ${accountAPIId} queued ${queuedItems} failed ${failedItems}`,
    );

    const globalInfo = this.props.global.getGlobalInfo();
    const propertyId = getPropertyIdForAccountAPIId({
      accountAPIId,
      globalInfo,
    });
    if (isDefined(propertyId) && isDefined(globalInfo.properties[propertyId])) {
      globalInfo.properties[propertyId].accountAPIs[accountAPIId].queuedItems =
        queuedItems;
      globalInfo.properties[propertyId].accountAPIs[accountAPIId].failedItems =
        failedItems;
      this.props.global.setGlobalInfo(globalInfo);
    }
    this.forceUpdate();
  }

  static requestQueueCounts(accountAPIId, propertyId) {
    logger.info(`Sidebar:requestQueueCounts ${accountAPIId}`);

    logger.info(
      `PubSub: publish ${REQUEST_API_INFO} ${accountAPIId} in components/sidebar/Sidebar.requestQueueCounts`,
    );
    if (canCollectPageStatistics(propertyId)) {
      PubSub.publish(REQUEST_API_INFO, { accountAPIId });
    }
  }

  /**
   * Event handlers
   */

  handleMouseDown(event) {
    if (typeof $ !== 'undefined') {
      const classSelected = $(event.target).attr('class');
      const imageSelected = $(event.target).attr('src');
      if (
        this.state.isExpanded &&
        imageSelected !== 'img/ic_user.png' &&
        !isEventInElementByClass({
          event,
          targetClass: 'user-details-dropdown d-flex',
        }) &&
        classSelected !== 'account_user' &&
        (isUndefined(classSelected) ||
          classSelected.indexOf('account_user_email') === -1) &&
        classSelected !== 'account_user_options'
      ) {
        this.handleUserCancel();
      }
    }
  }

  handleUserCancel() {
    logger.info('Sidebar:handleUserCancel');

    this.setState({
      isExpanded: false,
    });
  }

  /**
   * Render method
   */

  renderSidebar({ globalInfo }) {
    const apisByNetwork = getAPIsByNetwork({ globalInfo });
    const isSuspended = isCurrentPropertySuspended();

    const propertyId = getCurrentPropertyId({ globalInfo });
    const propertyPermission = getPropertyPermission({
      propertyId,
      globalInfo,
    });
    const isAdmin = propertyPermission === PERMISSION_TYPES.ADMIN;

    return (
      <Flex
        data-cy-id="sidebar"
        data-cy-attribute={`globalInfoState:${this.props.global.globalInfoState}`}
        flexDirection="column"
        pt={5}
        gap={4}
      >
        {this.props.showMobileMenu && (
          <Box px={4}>
            <SocialPropertySelect redirectOrigin="share" />
          </Box>
        )}

        {!isSuspended && (
          <Box as="nav" mb={2}>
            <SidebarNetworks apisByNetwork={apisByNetwork} />
          </Box>
        )}
        <Flex flexDir="column" px={4} gap={4}>
          {isAdmin && <AddPage />}
          <SidebarUserMenu />
        </Flex>
      </Flex>
    );
  }

  render() {
    const globalInfo = getGlobalInfo();
    if (isEmptyOrNullOrUndefined(globalInfo)) {
      return REACT_PREVENT_RENDER;
    }

    if (!this.props.showMobileMenu) {
      return (
        <Box
          className={!this.props.showMobileMenu ? 'd-none d-md-block' : ''}
          position="absolute"
          width="xs"
          boxShadow="borderRight"
          flexShrink={0}
          pb={5}
          left={0}
          bottom={0}
          top="60px"
          overflowY="auto"
        >
          {this.renderSidebar({ globalInfo })}
        </Box>
      );
    }

    return (
      <Drawer
        isOpen={this.props.showMobileMenu}
        onClose={this.props.eventHandlers.handleMobileMenuClose}
        placement="left"
        autoFocus={false}
      >
        <Drawer.Overlay bg="gray.600" opacity="0.2 !important" />
        <Drawer.Content maxW="calc(100% - 56px)">
          <Drawer.Body>{this.renderSidebar({ globalInfo })}</Drawer.Body>
        </Drawer.Content>
      </Drawer>
    );
  }
}

Sidebar.propTypes = {
  propertyId: PropTypes.any,
  accountAPIId: PropTypes.any,
  showMobileMenu: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleMobileMenuClose: PropTypes.func.isRequired,
  }).isRequired,
};

Sidebar.defaultProps = {
  propertyId: null,
  accountAPIId: null,
};

export default withGlobalInfo(withLocation(Sidebar));
