import Immutable from 'seamless-immutable';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import * as cache from 'common/cache';
import {
  API_PROPERTIES,
  API_TYPE_IDS,
  MEDIA_ITEM_STATES,
  TAG_TYPES,
  TWITTER_SHARE_FAILED_ERROR_MESSAGES,
} from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import { isValidImage } from 'common/image';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import { convertEchoboxImageToAmazon } from 'common/s3';
import { getSocialNetworkName, hasMentionsLookups } from 'common/social';
import { encodeHTML } from 'common/string';
import { convertToSocialPageURN } from 'common/urn';
import {
  isNull,
  isNullOrUndefined,
  isRunningTests,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import { addImageOverlay } from 'helpers/imageOverlay';

/**
 * Retrieves details of the specified media item(s)
 * @param {{
 *  accountAPIId: number,
 *  apiTypeId: import('types').APITypeId,
 *  state: import('types').MediaItemState,
 *  mediaIds: string[],
 *  fields?: string[],
 *  getPageInfo?: boolean,
 *  extraInsights?: string[],
 * }}
 * @returns {Promise<Record<string, import('types').FixTypeLater>>}
 */
export default async function getMediaItems({
  accountAPIId = mandatory('accountAPIId'),
  apiTypeId = mandatory('apiTypeId'),
  state = mandatory('state'),
  mediaIds = mandatory('mediaIds'),
  fields = null,
  getPageInfo = false,
  extraInsights = null,
} = {}) {
  const guid = metrics.start('getMediaItems');
  /**
   * Additional parameter validation
   */

  if (mediaIds.length > 50) {
    throw new ReferenceError('Invalid parameter mediaIds');
  }

  try {
    const mediaItems = {};

    /**
     * 1. First request - retrieve media item details
     */

    /**
     * 1.1. Request - media item details - /social/api/{socialPageURN}/media/{state}/?mediaIds={mediaIds}[&fields={fieldList}]
     */

    let requests = [];
    let requestMap = {};
    let responses = [];
    let response = {};
    let config;

    let gotPageInfo = false;

    const typeId = getAPITypeId({ accountAPIId });
    const socialNetworkType = API_PROPERTIES[typeId]?.urnName;
    const socialPageURN = convertToSocialPageURN(
      socialNetworkType,
      accountAPIId,
    );

    const params = {
      mediaIds: stringifyMediaIds(mediaIds),
    };

    if (fields && fields.length > 0) {
      params.fields = `${fields}`;
    }

    if (extraInsights && extraInsights.length > 0) {
      params.extraInsights = `${extraInsights}`;
    }

    config = {
      url: `${constructBaseSocialAPIURL(
        'v3.1',
      )}/social/api/${socialPageURN}/media/${state}`,
      method: 'GET',
      timeout: API_TIMEOUTS.L,
      headers: getClientServiceRequestHeaders(),
      params,
    };

    logger.info(
      `API:getMediaItems /social/api/${socialPageURN}/media/${state}/?mediaIds=${stringifyMediaIds(
        mediaIds,
      )}`,
    );

    requests.push(axios(config));
    requestMap.mediaItem = requests.length - 1;

    responses = await Promise.all(requests);

    /**
     * 1.2. Response
     */

    response = responses[requestMap.mediaItem].data.mediaItemDataMap;

    mediaIds.forEach((mediaId) => {
      let mediaItem = MediaItem.initialise({
        accountAPIId,
        backend: {
          mediaId,
          ...response[mediaId],
        },
      });
      const failReason = MediaItem.getFailReason({
        mediaItem,
      });
      const suggestionState = MediaItem.getState({
        mediaItem,
      });
      const defaultFailReason = `An unexpected error occurred`;
      const network = getSocialNetworkName({ apiTypeId });
      if (suggestionState === MEDIA_ITEM_STATES.FAILED) {
        if (
          !isNullOrUndefined(failReason) &&
          !failReason.startsWith(defaultFailReason)
        ) {
          if (
            TWITTER_SHARE_FAILED_ERROR_MESSAGES.some((item) =>
              failReason.toLowerCase().includes(item.toLowerCase()),
            )
          ) {
            mediaItem = MediaItem.setErrorMessage({
              mediaItem,
              fieldValue: `Share failed. ${encodeHTML(
                failReason,
              )} Please refer to our <a href="https://echobox.zendesk.com/hc/en-us/articles/10257937885853-Connecting-Twitter-to-Echobox">guide</a>.`,
            });
          } else {
            mediaItem = MediaItem.setErrorMessage({
              mediaItem,
              fieldValue: `Share failed. ${encodeHTML(
                failReason,
              )} Please contact <a href="mailto:support@echobox.com">support</a> for assistance.`,
            });
          }
        } else {
          mediaItem = MediaItem.setErrorMessage({
            mediaItem,
            fieldValue: `${network} was unable to share your post. Please click "Try again".
            If it fails again, please contact <a href="mailto:support@echobox.com">Support</a>.`,
          });
        }
      }
      mediaItems[mediaId] = mediaItem;
    });

    /**
     * 2. Second set of requests - inline mentions
     */

    /**
     * 2.1. Requests - Facebook mentions - /pageinfo
     */

    requests = [];
    requestMap = {};
    responses = [];

    if (getPageInfo) {
      mediaIds.forEach((mediaId) => {
        const tags = MediaItem.extractHashtagsAndMentions({
          mediaItem: mediaItems[mediaId],
        });
        if (
          hasMentionsLookups({ apiTypeId }) &&
          tags[TAG_TYPES.MENTION][accountAPIId].length !== 0
        ) {
          gotPageInfo = true;
          tags[TAG_TYPES.MENTION][accountAPIId].forEach((tag) => {
            const pageId = tag.raw.substring(2, tag.raw.length - 1);
            const cached = cache.getCacheItem('getPageInfo', pageId);
            if (!isNull(cached)) {
              requests.push(
                new Promise((resolve) => {
                  resolve(cached);
                }),
              );
            } else {
              config = {
                url: `${constructBaseSocialAPIURL()}/pageinfo`,
                method: 'GET',
                timeout: API_TIMEOUTS.S,
                headers: getClientServiceRequestHeaders(),
                params: {
                  pageId,
                },
              };
              logger.info(`API:getMediaItems /pageinfo ${pageId}`);
              requests.push(axios(config));
            }
            if (isUndefined(requestMap.facebookMentions)) {
              requestMap.facebookMentions = {};
            }
            if (isUndefined(requestMap.facebookMentions[pageId])) {
              requestMap.facebookMentions[pageId] = {
                mediaIds: [mediaId],
                index: requests.length - 1,
              };
            } else {
              requestMap.facebookMentions[pageId].mediaIds.push(mediaId);
            }
          });
        }
      });
    }

    /*
     * 2.1.4. LinkedIn/Instagram tags
     */

    if (
      apiTypeId === API_TYPE_IDS.LINKEDIN ||
      apiTypeId === API_TYPE_IDS.INSTAGRAM
    ) {
      mediaIds.forEach((mediaId) => {
        const tags = MediaItem.extractHashtagsAndMentions({
          mediaItem: mediaItems[mediaId],
        });
        const mentions = tags[TAG_TYPES.MENTION][accountAPIId];
        if (mentions.length !== 0) {
          mediaItems[mediaId] = MediaItem.setMentions({
            mediaItem: mediaItems[mediaId],
            fieldValue: Immutable(mentions),
          });
        }
        const hashtags = tags[TAG_TYPES.HASHTAG][accountAPIId];
        if (hashtags.length !== 0) {
          mediaItems[mediaId] = MediaItem.setHashtags({
            mediaItem: mediaItems[mediaId],
            fieldValue: Immutable(hashtags),
          });
        }
      });
    }

    /**
     * 2.2. Responses
     */

    try {
      responses = await Promise.all(requests);

      if (gotPageInfo) {
        Object.keys(requestMap.facebookMentions).forEach((pageId) => {
          const result =
            responses[requestMap.facebookMentions[pageId].index].data;
          cache.setCacheItem('getPageInfo', result.pageId, { data: result });
          requestMap.facebookMentions[pageId].mediaIds.forEach((mediaId) => {
            let mediaItem = mediaItems[mediaId];
            const mentions = Immutable.asMutable(
              MediaItem.getMentions({ mediaItem }),
            );
            mentions.push({
              raw: `@[${result.pageId}]`,
              clean: result.name,
              more: result.pageURL,
            });
            mediaItem = MediaItem.setMentions({
              mediaItem,
              fieldValue: Immutable(mentions),
            });
            mediaItems[mediaId] = mediaItem;
          });
        });
      }
    } catch (error) {
      if (!isRunningTests()) {
        console.log(error);
      }
    }

    requests = Object.keys(mediaItems)
      .filter((mediaId) => {
        const mediaItem = mediaItems[mediaId];
        const overlayURL = MediaItem.getImageOverlayURL({ mediaItem });
        const imageURLs = MediaItem.getImageURLs({ mediaItem });
        return overlayURL && imageURLs && imageURLs.length > 0;
      })
      .map((mediaId) => {
        let mediaItem = mediaItems[mediaId];
        const overlayURL = MediaItem.getImageOverlayURL({ mediaItem });
        const promise = new Promise((resolve) => {
          resolve(overlayURL);
        });
        return promise.then(async (imageOverlayURL) => {
          const firstImageURL = MediaItem.getImageURLs({ mediaItem })[0];
          const isValidImageURL = await isValidImage(
            convertEchoboxImageToAmazon(firstImageURL),
          );
          const isValidOverlayImage = await isValidImage(
            convertEchoboxImageToAmazon(imageOverlayURL),
          );

          if (isValidOverlayImage && isValidImageURL) {
            const resultURL = await addImageOverlay({
              imageURL: firstImageURL,
              overlayURL: imageOverlayURL,
            });

            mediaItem = MediaItem.setImageOverlayResult({
              mediaItem,
              fieldValue: resultURL,
            });
          }

          mediaItems[mediaId] = mediaItem;
        });
      });

    await Promise.all(requests);

    metrics.end('getMediaItems', guid);
    logger.debug('getMediaItems', { accountAPIId, state });
    return mediaItems;
  } catch (error) {
    metrics.fail('getMediaItems', guid);
    if (!isRunningTests()) {
      console.log(error);
    }
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getMediaItems',
      args: {
        accountAPIId,
        apiTypeId,
        state,
        mediaIds,
        getPageInfo,
      },
    });
    throw apiError;
  }
}

/**
 * Turn media ids parameter into a comma-separated list
 */

function stringifyMediaIds(mediaIds) {
  let stringified;
  // If media ids is an array, stringify it
  if (Array.isArray(mediaIds)) {
    stringified = JSON.stringify(mediaIds);
  }
  // Now ensure the stringified list contains no brackets or quotes
  stringified = stringified.replace(/[[\]"']/gi, '');
  return stringified;
}
