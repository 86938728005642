interface ChevronDownProps {
  width?: string;
  height?: string;
}

const ChevronDown = ({ width = '20', height = '20' }: ChevronDownProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6623 6.87486C15.3378 6.55044 14.8119 6.55023 14.4873 6.87438L10.7065 10.6489C10.3161 11.0387 9.68387 11.0387 9.29347 10.6489L5.51274 6.8744C5.18806 6.55025 4.66215 6.55047 4.33774 6.87488C4.01314 7.19948 4.01314 7.72576 4.33774 8.05036L9.29289 13.0055C9.68342 13.396 10.3166 13.396 10.7071 13.0055L15.6623 8.05034C15.9869 7.72574 15.9869 7.19946 15.6623 6.87486Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDown;
