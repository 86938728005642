import { z } from 'zod';

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';

import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({
    bucket: z.string(),
    partSizeBytes: z.number(),
    uploadId: z.string(),
    signedURLParts: z.array(z.string()),
  })
  .describe('postUploadVideoInitialise.ResponseSchema');

export default async function postUploadVideoInitialise({
  fileName,
  fileSizeBytes,
  checksumSHA256,
  socialPageURN,
}: {
  fileName: string;
  fileSizeBytes: number;
  checksumSHA256: string;
  socialPageURN: string;
}) {
  const guid = metrics.start('postUploadVideoInitialise');

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL()}/social/api/${socialPageURN}/media/upload/initialise`,
    method: 'POST',
    timeout: API_TIMEOUTS.M,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: {
      fileName,
      fileSizeBytes,
      checksumSHA256,
    },
  };
  logger.info(
    `API:postUploadVideoInitialise /social/api/${socialPageURN}/media/upload/initialise`,
  );

  try {
    const response = await axios(config);

    metrics.end('postUploadVideoInitialise', guid);
    logger.debug('postUploadVideoInitialise', {
      socialPageURN,
      fileName,
      fileSizeBytes,
    });
    const validatedData = lyingParse(ResponseSchema, response?.data);

    return validatedData;
  } catch (error) {
    metrics.fail('postUploadVideo', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postUploadVideoInitialise',
      args: {
        socialPageURN,
        fileName,
        fileSizeBytes,
      },
    });
    throw apiError;
  }
}
