const BrandBitly = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.54 12.65C7.8665 12.662 7.3755 12.4455 7.34525 11.857C7.334 11.636 7.33775 11.401 7.3485 11.259C7.4135 10.399 8.015 9.78025 8.6085 9.6615C9.347 9.51375 9.839 9.8515 9.839 10.818C9.839 11.4712 9.6575 12.6305 8.54 12.6503V12.65ZM7.9725 2C4.66675 2 2 4.65375 2 8.124C2 9.92325 2.9625 11.6615 4.316 12.706C4.5765 12.9073 4.887 12.8888 5.065 12.7163C5.215 12.5712 5.2025 12.2212 4.9235 11.9762C3.8415 11.0245 3.0935 9.584 3.0935 8.14525C3.0935 5.56125 5.388 3.387 7.97275 3.387C11.1187 3.387 12.801 5.942 12.801 8.10725C12.801 9.4305 12.154 11.0282 10.986 12.0472C10.9878 12.0425 11.2285 11.569 11.2285 10.6465C11.2285 9.0765 10.2335 8.22575 9.07925 8.22575C8.2435 8.22575 7.74325 8.5245 7.40275 8.80325C7.40275 8.1635 7.424 6.96925 7.424 6.96925C7.424 6.18075 7.148 5.54975 6.18525 5.536C5.62825 5.528 5.21475 5.7835 4.957 6.361C4.864 6.578 4.89825 6.81325 5.082 6.92C5.234 7.00825 5.48375 6.94275 5.6075 6.7775C5.69 6.6745 5.7365 6.6525 5.80825 6.66025C5.9265 6.67275 5.93075 6.8635 5.93575 6.98525C5.93925 7.079 6.03025 8.438 5.9805 11.9303C5.9805 12.8935 6.736 14 8.545 14C9.32375 14 9.92175 13.7825 10.7905 13.2887C12.113 12.5372 14 10.8705 14 8.0515C14 4.529 11.1475 2 7.9725 2Z"
      fill="currentColor"
    />
  </svg>
);

export default BrandBitly;
