import $ from 'jquery';

import { REFRESH_INTERVALS } from 'common/config';

function scrollToTop() {
  window.setTimeout(() => {
    if (typeof $ !== 'undefined') {
      $('html').scrollTop(0);
    }
  }, REFRESH_INTERVALS.SCROLL_TO_TOP);
}

export { scrollToTop };
