/* eslint no-use-before-define: ["error", { "functions": false }] */

import Immutable from 'seamless-immutable';

import { getAPITypeId, getPropertyIdForAccountAPIId } from 'common/accountAPIs';
import { mergeDedupeArrays } from 'common/array';
import { CAN_CUSTOMISE_LINK_POSTS } from 'common/config';
import {
  AB_TEST_STATUSES,
  ACCOUNT_SETTING_TYPES,
  API_PROPERTIES,
  API_TYPE_IDS,
  CONTENT_TYPE_AI_IDS,
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SHARE_TIME_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
  TAG_TYPES,
} from 'common/constants';
import * as s3 from 'common/s3';
import { getSetting, isIGCabinetEnabled } from 'common/settings';
import { replaceShareURL } from 'common/shareURL';
import {
  canShareNow,
  getImageQuantityLimits,
  hasAudienceRestriction,
  hasDescriptionField,
  hasTitleField,
  isSocialNetwork,
} from 'common/social';
import { cleanTags } from 'common/tags';
import {
  cloneObject,
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import * as MessageBoxTools from 'components/compose/messagebox/MessageBoxTools';

import { addURLParameters } from 'helpers/url';
import {
  BACKEND_PROPERTIES,
  FRONTEND_PROPERTIES,
  PREVIEW_FIELDS,
  SHARED_PROPERTIES,
} from './common';
import {
  getABInfo,
  getABVariations,
  getAccountAPIId,
  getFirstComment,
  getIGLinkStickerConfig,
  getIsLive,
  getIsURLChanged,
  getIsURLResolved,
  getMediaId,
  getMessages,
  getPostType,
  getPreviewFields,
  getSocialChannel,
  getSponsor,
  getSponsorName,
  getTimeSensitivityTypeId,
  getUnshortenedShareURL,
  getVideoURL,
  setABInfo,
  setABVariations,
  setAccountAPIId,
  setFirstComment,
  setIGLinkStickerConfig,
  setIsChanged,
  setIsLive,
  setMediaId,
  setPreviewField,
  setSocialChannel,
  setSponsor,
  setSponsorName,
  setTimeSensitivityTypeId,
  setVideoURL,
} from './gettersSetters';
import { extractHashtagsAndMentions } from './hashtagsMentions';
import { minMaxToShareTime } from './save';

export {
  derivePostType,
  duplicateLinkPost,
  duplicateNonLinkPost,
  initialise,
  initialiseAIMessage,
  populateFbTargetingParams,
};

/**
 * Constructor
 * Add all valid properties to the appropriate sub-substructure
 * @returns {import('types').FixTypeLater}
 */

function initialise(args) {
  if (isUndefined(args.accountAPIId)) {
    throw new ReferenceError('accountAPIId undefined');
  }
  if (isUndefined(args.backend) && isUndefined(args.frontend)) {
    throw new ReferenceError('backend and frontend undefined');
  }

  // Create an immutable copy of our constructor arguments, just to make sure
  // we don't accidentally change any properties that have been passed in by reference
  const properties = Immutable(args);

  // Create item to be populated
  let mediaItem = {
    accountAPIId: args.accountAPIId,
    backend: {},
    frontend: {},
  };

  // Save backend properties
  if (isDefined(properties.backend)) {
    mediaItem = storeBackendProperties({
      originalItem: mediaItem,
      properties: properties.backend,
    });
  }

  // Save frontend properties, if supplied... otherwise derive them where necessary
  if (isDefined(properties.frontend)) {
    mediaItem = storeFrontendProperties({
      originalItem: mediaItem,
      properties: properties.frontend,
    });
  } else {
    mediaItem = deriveFrontendProperties(mediaItem);
  }

  // Return the newly-created media item object and make all its properties immutable
  return Immutable(mediaItem, { deep: true });
}

function initialiseAIMessage({ mediaItem, accountAPIId, isFirstRender }) {
  const postType = getPostType({ mediaItem });
  const messages = getMessages({ mediaItem });

  let aiMessage = Immutable({
    isLoading: MessageBoxTools.shouldGetAIMessageOnRender({
      accountAPIId,
      mediaItem,
      isFirstRender,
    }),
    loadingFailed: false,
    messages: [],
    selectedIndex: -1,
  });

  // Ensure any provided AI messages are stored in the state and that the selected index
  // is correctly set if the selected message is the AI message
  if (postType === POST_TYPES.LINK && messages) {
    const aiMessages = messages.orderedShareContent.filter(
      (x) =>
        x.contentTypeId !== null &&
        CONTENT_TYPE_AI_IDS.indexOf(x.contentTypeId) !== -1,
    );
    const aiSelectedIndex = aiMessages
      .map((x) => x.contentTypeId)
      .indexOf(messages.selectedContentTypeId);

    aiMessage = aiMessage
      .set('messages', aiMessages)
      .set('selectedIndex', aiSelectedIndex);
  }

  return aiMessage;
}

/**
 * Store backend properties in mediaItem.backend
 */

function storeBackendProperties({
  originalItem = mandatory('originalItem'),
  properties = mandatory('properties'),
} = {}) {
  const mediaItem = cloneObject(originalItem);

  // Store supplied backend properties
  Object.keys(properties).forEach((property) => {
    if (
      BACKEND_PROPERTIES.indexOf(property) !== -1 ||
      SHARED_PROPERTIES.indexOf(property) !== -1
    ) {
      mediaItem.backend[property] = properties[property];
    }
  });

  // Set defaults for properties which the endpoint won't return if null
  // minShareTime / maxShareTime
  if (isUndefined(mediaItem.backend.minShareTime)) {
    mediaItem.backend.minShareTime = null;
  }
  if (isUndefined(mediaItem.backend.maxShareTime)) {
    mediaItem.backend.maxShareTime = null;
  }
  // message
  if (isUndefined(mediaItem.backend.message)) {
    mediaItem.backend.message = '';
  }
  if (isUndefined(mediaItem.backend.isLive)) {
    // If LIVE_STORY_DEFAULT setting exists, use that
    const isLiveDefaultSetting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.LIVE_STORY_DEFAULT,
      propertyId: getPropertyIdForAccountAPIId({
        accountAPIId: mediaItem.accountAPIId,
      }),
    });
    mediaItem.backend.isLive = isLiveDefaultSetting.enabled;
  }

  // Properties which need processing
  // imageURLs
  if (
    mediaItem.backend?.imageURLs &&
    mediaItem.backend?.imageURLs.length > 0 &&
    mediaItem.backend?.imageURLs[0]
  ) {
    const expandedImageURLs = mediaItem.backend.imageURLs.map((url) =>
      s3.convertEchoboxImageToAmazon(url),
    );
    mediaItem.backend.imageURLs = expandedImageURLs;
  } else {
    mediaItem.backend.imageURLs = [];
  }
  // postType
  if (isUndefined(mediaItem.backend.postType)) {
    mediaItem.backend.postType = derivePostType(mediaItem);
  }
  // videoURL
  if (isDefined(mediaItem.backend.videoURL)) {
    mediaItem.backend.videoURL = s3.convertEchoboxVideoToAmazon(
      mediaItem.backend.videoURL,
    );
  }

  return mediaItem;
}

/**
 * Store frontend properties in mediaItem.frontend
 */

function storeFrontendProperties({
  originalItem = mandatory('originalItem'),
  properties = mandatory('properties'),
} = {}) {
  const mediaItem = cloneObject(originalItem);

  // Store supplied frontend properties
  Object.keys(properties).forEach((property) => {
    if (
      FRONTEND_PROPERTIES.indexOf(property) !== -1 ||
      PREVIEW_FIELDS.indexOf(property) !== -1
    ) {
      mediaItem.frontend[property] = properties[property];
    }
  });

  return mediaItem;
}

/**
 * Derive any mandatory frontend properties that have not been specified
 */

function deriveFrontendProperties(originalItem) {
  const mediaItem = cloneObject(originalItem);

  // Preview fields
  const previewFields = populatePreviewFields({
    mediaItem,
    postType: getPostType({ mediaItem }),
  });
  mediaItem.frontend = Object.assign(mediaItem.frontend, previewFields);

  // Derived properties which will probably not be set directly
  if (isUndefined(mediaItem.frontend.autopilotToggle)) {
    mediaItem.frontend.autopilotToggle = deriveAutopilotToggle(mediaItem);
  }
  if (isUndefined(mediaItem.frontend.isTimingValid)) {
    mediaItem.frontend.isTimingValid = true;
  }

  return mediaItem;
}

/**
 * Methods for deriving calculated properties
 */

function deriveAutopilotToggle(mediaItem) {
  return (
    isDefined(mediaItem.backend.suggestionTypeId) &&
    isDefined(mediaItem.backend.state) &&
    mediaItem.backend.suggestionTypeId === SUGGESTION_TYPES.AUTO_SOCIAL_SHARE &&
    (mediaItem.backend.state === MEDIA_ITEM_STATES.SCHEDULED ||
      mediaItem.backend.state === MEDIA_ITEM_STATES.APPROVAL_REQUIRED)
  );
}

function derivePostType(mediaItem) {
  let derivedType = POST_TYPES.LINK;
  if (
    isUndefined(mediaItem.backend.unshortenedShareURL) ||
    isNull(mediaItem.backend.unshortenedShareURL)
  ) {
    if (
      mediaItem.backend.videoURL ||
      mediaItem.backend.socialChannel === SOCIAL_CHANNELS.REEL
    ) {
      derivedType = POST_TYPES.VIDEO;
    } else if (
      mediaItem.backend.socialChannel === SOCIAL_CHANNELS.STORY &&
      isIGCabinetEnabled({
        propertyId: getPropertyIdForAccountAPIId({
          accountAPIId: mediaItem.accountAPIId,
        }),
      })
    ) {
      derivedType = POST_TYPES.PHOTO_STORY;
    } else {
      derivedType = POST_TYPES.STATUS;
    }
  }
  return derivedType;
}

/**
 * Determines the social channel of a media item by the new APITypeId and postType.
 */
function deriveSocialChannel({
  postType,
  sourceItem,
  toApiTypeId,
  toAccountAPIId,
}) {
  const sourceSocialChannel =
    getSocialChannel({ mediaItem: sourceItem }) ?? SOCIAL_CHANNELS.FEED;
  const propertyId = getPropertyIdForAccountAPIId({
    accountAPIId: toAccountAPIId,
  });

  const isIGCabinetEnabledForProperty = isIGCabinetEnabled({ propertyId });

  // If sharing with a cabinet IG page, it will sometimes be a story
  // TODO: Remove this once we destroy the cabinet.
  if (
    toApiTypeId === API_TYPE_IDS.INSTAGRAM &&
    isIGCabinetEnabledForProperty &&
    (postType === POST_TYPES.LINK || postType === POST_TYPES.PHOTO_STORY)
  ) {
    return SOCIAL_CHANNELS.STORY;
  }

  const supportedSocialChannels = API_PROPERTIES[toApiTypeId]
    .supportedSocialChannels ?? [SOCIAL_CHANNELS.FEED];

  // If the network we are posting to supports the socialChannel of the source item, use that
  if (supportedSocialChannels.includes(sourceSocialChannel)) {
    return sourceSocialChannel;
  }

  // Otherwise, we need to pick the first supported socialChannel for the network
  return supportedSocialChannels[0];
}

/**
 * duplicateLinkPost
 *
 * Returns a media item composed by starting with a base item and then copying
 * any relevant changes from the edited item
 *
 * @param {object} baseItem - the underlying media item returned from the backend
 * @param {object} editedItem - the edited media item that exists in the frontend
 * @param {number} accountAPIId - the social page to which the duplicated media item will belong
 * @returns {object} - the resulting media item
 */

function duplicateLinkPost({
  editedItem = mandatory('editedItem'),
  baseItem = mandatory('baseItem'),
} = {}) {
  let mediaItem = Immutable(cloneObject(baseItem));

  const fromAccountAPIId = getAccountAPIId({ mediaItem: editedItem });
  const toAccountAPIId = getAccountAPIId({ mediaItem: baseItem });
  const fromApiTypeId = getAPITypeId({ accountAPIId: fromAccountAPIId });
  const toApiTypeId = getAPITypeId({ accountAPIId: toAccountAPIId });
  const postType = getPostType({ mediaItem: baseItem });
  const isURLChanged = getIsURLChanged({ mediaItem: editedItem });
  const isURLResolved = getIsURLResolved({ mediaItem: editedItem });

  const unshortenedShareURL = getUnshortenedShareURL({ mediaItem: editedItem });

  // Get preview fields from media item being duplicated
  const editedFields = getPreviewFields({ mediaItem: editedItem });
  const editedTags = {
    [TAG_TYPES.HASHTAG]: editedFields.hashtags,
    [TAG_TYPES.MENTION]: editedFields.mentions,
  };

  // Get preview fields to be copied
  const baseFields = getPreviewFields({ mediaItem: baseItem });

  mediaItem = setAccountAPIId({
    mediaItem,
    fieldValue: toAccountAPIId,
  });
  mediaItem = setMediaId({
    mediaItem,
    fieldValue: getMediaId({ mediaItem: baseItem }),
  });

  // Copy preview fields to target account
  const { title, description, imageURLs, setToNull } =
    copyTitleDescriptionAndImages({
      baseFields,
      editedFields,
      postType,
      toApiTypeId,
    });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'description',
    fieldValue: description,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'errorDetails',
    fieldValue: {},
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'errorMessage',
    fieldValue: '',
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'imageURLs',
    fieldValue: imageURLs,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isLoading',
    fieldValue: false,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isSaving',
    fieldValue: false,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isURLChanged',
    fieldValue: isURLChanged,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isURLResolved',
    fieldValue: null,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isURLValid',
    fieldValue: editedFields.isURLValid,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'mediaItemTags',
    fieldValue: editedFields.mediaItemTags,
  });

  if (fromApiTypeId === toApiTypeId) {
    // Retain mentions when API type has not changed
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'mentions',
      fieldValue: mergeDedupeArrays(
        editedFields.mentions,
        baseFields.mentions,
        'raw',
      ),
    });
  }
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'message',
    fieldValue: prepareMessage({
      message: editedFields.message,
      editedTags,
      fromApiTypeId,
      toApiTypeId,
    }),
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'messages',
    fieldValue: prepareMessage({
      message: editedFields.messages,
      editedTags,
      fromApiTypeId,
      toApiTypeId,
    }),
  });
  if (
    editedFields.shareTime.type === SHARE_TIME_TYPES.NOW &&
    !canShareNow({ apiTypeId: toApiTypeId })
  ) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'shareTime',
      fieldValue: { type: SHARE_TIME_TYPES.OPTIMAL },
    });
  } else {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'shareTime',
      fieldValue: editedFields.shareTime,
    });
  }
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'shareURL',
    fieldValue: '',
  });

  mediaItem = setSocialChannel({
    mediaItem,
    fieldValue: deriveSocialChannel({
      postType,
      sourceItem: editedItem,
      toApiTypeId,
      toAccountAPIId,
    }),
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'title',
    fieldValue: title,
  });
  if (setToNull) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'twitterCardType',
      fieldValue: null,
    });
  }
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isURLResolved',
    fieldValue: isURLResolved,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'unshortenedShareURL',
    fieldValue: isURLChanged
      ? addURLParameters(
          unshortenedShareURL,
          toAccountAPIId,
          !isURLChanged,
          false, // asPromise = false
          true, // isCopyingURL = true
        )
      : addURLParameters(
          getUnshortenedShareURL({ mediaItem: baseItem }),
          toAccountAPIId,
          !isURLChanged,
          false, // asPromise = false
          true, // isCopyingURL = true
        ),
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'canCustomiseLinkPosts',
    fieldValue: baseFields.canCustomiseLinkPosts,
  });

  // Add AB variation if there is one
  const isABVariation = getABInfo({
    mediaItem: editedItem,
  }).isABVariation;
  if (isABVariation) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'abInfo',
      fieldValue: editedFields.abInfo,
    });
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'abVariations',
      fieldValue: editedFields.abVariations,
    });
  }

  // Audience restriction
  let fbTargetingParams;
  if (
    hasAudienceRestriction({ apiTypeId: fromApiTypeId }) &&
    hasAudienceRestriction({ apiTypeId: toApiTypeId })
  ) {
    fbTargetingParams = editedFields.fbTargetingParams;
  } else if (hasAudienceRestriction({ apiTypeId: toApiTypeId })) {
    fbTargetingParams = populateFbTargetingParams({
      mediaItem: editedItem,
      accountAPIId: toAccountAPIId,
    });
  }

  if (isDefined(fbTargetingParams)) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'fbTargetingParams',
      fieldValue: fbTargetingParams,
    });
  }

  // Instagram Link Sticker
  const toIGLinkStickerConfig = getIGLinkStickerConfig({
    mediaItem: editedItem,
  });
  if (toIGLinkStickerConfig != null) {
    mediaItem = setIGLinkStickerConfig({
      mediaItem,
      fieldValue: toIGLinkStickerConfig,
    });
  }

  // Miscellaneous
  mediaItem = setIsLive({
    mediaItem,
    fieldValue: getIsLive({ mediaItem: editedItem }),
  });
  mediaItem = setSponsor({
    mediaItem,
    fieldValue: getSponsor({ mediaItem: editedItem }),
  });
  mediaItem = setSponsorName({
    mediaItem,
    fieldValue: getSponsorName({ mediaItem: editedItem }),
  });
  mediaItem = setTimeSensitivityTypeId({
    mediaItem,
    fieldValue: getTimeSensitivityTypeId({ mediaItem: editedItem }),
  });
  mediaItem = setFirstComment({
    mediaItem,
    fieldValue: getFirstComment({ mediaItem: editedItem }),
  });

  return mediaItem;
}

/**
 * Returns a media item composed by starting with a base item and then copying
 * any relevant changes from the edited item
 *
 * @param {{
 *  sourceItem: import('types').FixTypeLater,
 *  accountAPIId: number,
 * }}
 * @returns {import('types').FixTypeLater} - the resulting media item
 */

function duplicateNonLinkPost({
  sourceItem = mandatory('sourceItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  const fromAccountAPIId = getAccountAPIId({ mediaItem: sourceItem });
  const toAccountAPIId = accountAPIId;
  const fromApiTypeId = getAPITypeId({ accountAPIId: fromAccountAPIId });
  const toApiTypeId = getAPITypeId({ accountAPIId: toAccountAPIId });
  const postType = getPostType({ mediaItem: sourceItem });
  const socialChannel = getSocialChannel({ mediaItem: sourceItem });

  let mediaItem = initialise({
    accountAPIId,
    backend: {
      mediaId: 'NEW',
      minShareTime: null,
      maxShareTime: null,
      postType,
    },
  });

  // Get preview fields to be copied
  const previewFields = getPreviewFields({ mediaItem: sourceItem });
  const editedTags = {
    [TAG_TYPES.HASHTAG]: previewFields.hashtags,
    [TAG_TYPES.MENTION]: previewFields.mentions,
  };

  // Copy preview fields to target account
  if (hasDescriptionField({ apiTypeId: toApiTypeId })) {
    mediaItem = setPreviewField({
      mediaItem,
      accountAPIId: toAccountAPIId,
      fieldName: 'description',
      fieldValue: previewFields.description,
    });
  }
  mediaItem = setPreviewField({
    mediaItem,
    accountAPIId: toAccountAPIId,
    fieldName: 'errorMessage',
    fieldValue: '',
  });
  let fbTargetingParams;
  if (
    hasAudienceRestriction({ apiTypeId: fromApiTypeId }) &&
    hasAudienceRestriction({ apiTypeId: toApiTypeId })
  ) {
    fbTargetingParams = previewFields.fbTargetingParams;
  } else if (hasAudienceRestriction({ apiTypeId: toApiTypeId })) {
    fbTargetingParams = populateFbTargetingParams({
      mediaItem,
      accountAPIId: toAccountAPIId,
    });
  }
  if (isDefined(fbTargetingParams)) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'fbTargetingParams',
      fieldValue: fbTargetingParams,
    });
  }
  // Hashtags need to be "re-calculated" for the target account
  mediaItem = setPreviewField({
    mediaItem,
    accountAPIId: toAccountAPIId,
    fieldName: 'hashtags',
    fieldValue: extractHashtagsAndMentions({
      mediaItem: sourceItem,
    })[TAG_TYPES.HASHTAG][fromAccountAPIId],
  });
  // Copy images for all post types other than video posts where the image
  // is either a placeholder or a "thumbnail" which can only be copied if the
  // source and target social networks are the same - otherwise leave the image
  // blank for the user to upload a suitable thumbnail (if applicable to the
  // target social network, of course)

  // if the post type is video and image url exists, then copy the image urls
  const isVideoPostWithImage =
    postType === POST_TYPES.VIDEO &&
    previewFields.imageURLs &&
    previewFields.imageURLs.length > 0;

  if (
    postType !== POST_TYPES.VIDEO ||
    fromApiTypeId === toApiTypeId ||
    isVideoPostWithImage
  ) {
    // Establish how many images are allowed for the target page / post type
    const imageLimits = getImageQuantityLimits({
      apiTypeId: toApiTypeId,
      postType,
      socialChannel,
    });
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'imageURLs',
      fieldValue:
        previewFields.imageURLs.length <= imageLimits.max
          ? previewFields.imageURLs
          : previewFields.imageURLs.slice(0, imageLimits.max),
    });
  }
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isEdited',
    fieldValue: false,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isLoading',
    fieldValue: false,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'isSaving',
    fieldValue: false,
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'mediaItemTags',
    fieldValue: previewFields.mediaItemTags,
  });
  // Keep mentions for the same API type
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'mentions',
    fieldValue: fromApiTypeId === toApiTypeId ? previewFields.mentions : [],
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'message',
    fieldValue: prepareMessage({
      message: previewFields.message,
      editedTags,
      fromApiTypeId,
      toApiTypeId,
    }),
  });
  mediaItem = setPreviewField({
    mediaItem,
    fieldName: 'messages',
    fieldValue: prepareMessage({
      message: previewFields.messages,
      editedTags,
      fromApiTypeId,
      toApiTypeId,
    }),
  });
  if (
    previewFields.shareTime.type === SHARE_TIME_TYPES.NOW &&
    !canShareNow({ apiTypeId: toApiTypeId })
  ) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'shareTime',
      fieldValue: { type: SHARE_TIME_TYPES.OPTIMAL },
    });
  } else {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'shareTime',
      fieldValue: previewFields.shareTime,
    });
  }
  mediaItem = setSocialChannel({
    mediaItem,
    fieldValue: deriveSocialChannel({
      postType,
      sourceItem,
      toApiTypeId,
      toAccountAPIId,
    }),
  });
  if (hasTitleField({ apiTypeId: toApiTypeId, postType })) {
    mediaItem = setPreviewField({
      mediaItem,
      fieldName: 'title',
      fieldValue: previewFields.title,
    });
  }

  // Non-preview fields
  mediaItem = setIsChanged({
    mediaItem,
    fieldValue: false,
  });
  mediaItem = setTimeSensitivityTypeId({
    mediaItem,
    fieldValue: getTimeSensitivityTypeId({ mediaItem: sourceItem }),
  });
  mediaItem = setVideoURL({
    mediaItem,
    fieldValue: getVideoURL({ mediaItem: sourceItem }),
  });

  // AB variations
  if (getABInfo({ mediaItem: sourceItem }).isABVariation) {
    mediaItem = setABInfo({
      mediaItem,
      fieldValue: getABInfo({ mediaItem: sourceItem }),
    });
    mediaItem = setABVariations({
      mediaItem,
      fieldValue: getABVariations({ mediaItem: sourceItem }),
    });
  }

  mediaItem = setFirstComment({
    mediaItem,
    fieldValue: getFirstComment({ mediaItem: sourceItem }),
  });

  return mediaItem;
}

/**
 * populatePreviewFields
 *
 * Populates preview fields
 *
 * @param {object} mediaItem - media item structure
 *                 postType - post type (link, status, video)
 */

function populatePreviewFields({
  mediaItem = mandatory('mediaItem'),
  postType = mandatory('postType'),
} = {}) {
  const accountAPIId = getAccountAPIId({ mediaItem });
  const apiTypeId = getAPITypeId({ accountAPIId });
  // Add message, description, title and imageURLs fields if necessary
  const previewFields = {
    additionalSocialPages: [],
    aiMessage: initialiseAIMessage({
      mediaItem,
      accountAPIId,
      isFirstRender: false,
    }),
    canCustomiseLinkPosts:
      mediaItem.backend.canCustomiseLinkPosts ?? CAN_CUSTOMISE_LINK_POSTS,
    errorDetails: {},
    errorMessage: '',
    hashtags: [],
    imageURLs: mediaItem.backend.imageURLs,
    imageOverlayURL: mediaItem.backend.imageOverlayURL,
    isLoading: false,
    isRefreshPreview: false,
    isApplyImageOverlay: false,
    isSaving: false,
    mediaItemTags: mediaItem.backend.mediaItemTags ?? [],
    mentions: [],
    message: mediaItem.backend.message,
    shareTime: minMaxToShareTime({ mediaItem }),
    socialChannel: getDefaultSocialChannel({ mediaItem, apiTypeId, postType }),
    // Used by the "Post to link in bio" checkbox in the compose box so that users can use
    // an article to populate the compose box, but share a post as a feed post (not link in bio)
    shouldSendURL: true,
  };

  // Only populate these fields if they exist in the backend response
  [
    'description',
    'shareURL',
    'title',
    'twitterCardType',
    'unshortenedShareURL',
  ].forEach((key) => {
    if (!isNullOrUndefined(mediaItem.backend[key])) {
      previewFields[key] = mediaItem.backend[key];
    }
  });
  // Only populate these fields for link posts
  if (postType === POST_TYPES.LINK) {
    previewFields.isURLChanged = false;
    previewFields.isURLResolved = null;
    previewFields.isURLValid = true;
  }

  // Add fb targeting params
  const fbTargetingParams = populateFbTargetingParams({
    mediaItem,
    accountAPIId,
  });
  if (!isNullOrUndefined(fbTargetingParams)) {
    previewFields.fbTargetingParams = fbTargetingParams;
  }

  // Add hashtags and mentions fields
  if (
    mediaItem.backend.postType === POST_TYPES.LINK &&
    isDefined(mediaItem.frontend.messages)
  ) {
    if (isSocialNetwork({ apiTypeId })) {
      previewFields.messages = mediaItem.frontend.messages;
      previewFields.hashtags = mediaItem.frontend.hashtags;
      previewFields.mentions = mediaItem.frontend.mentions;
    }
  }
  // Add abInfo and abVariations fields
  const hasABVariations = isDefined(mediaItem.backend.abTestVariations);
  const isCompletedABTest =
    mediaItem.backend.abTestStatusId === AB_TEST_STATUSES.COMPLETED &&
    mediaItem.backend.state !== MEDIA_ITEM_STATES.SHARED;
  const isFailedABTest =
    mediaItem.backend.abTestStatusId === AB_TEST_STATUSES.ERROR;
  if (!hasABVariations || isCompletedABTest || isFailedABTest) {
    // Completed and errored AB tests are treated as though they are no longer AB tests
    previewFields.abInfo = {
      isABVariation: false,
    };
    // For errored AB tests use the message/title/images/description from the base media item
    if (mediaItem.backend.abTestStatusId === AB_TEST_STATUSES.ERROR) {
      previewFields.message = mediaItem.backend.message ?? '';
      previewFields.title = mediaItem.backend.title ?? '';
      previewFields.imageURLs = !isNullOrUndefined(mediaItem.backend.imageURLs)
        ? mediaItem.backend.imageURLs.map((url) =>
            s3.convertEchoboxImageToAmazon(url),
          )
        : [];
      previewFields.description = mediaItem.backend.description ?? '';
      previewFields.videoURL = mediaItem.backend.videoURL ?? '';
    }
  } else {
    previewFields.abInfo = {
      isABVariation: true,
      currentABVariationIndex: 0,
    };
    previewFields.abVariations = mediaItem.backend.abTestVariations;
    previewFields.abVariations = previewFields.abVariations.map((variation) => {
      return {
        ...variation,
        imageURLs: isNullOrUndefined(variation.imageURLs)
          ? []
          : variation.imageURLs.map((imageURL) =>
              s3.convertEchoboxImageToAmazon(imageURL),
            ),
      };
    });
  }

  // Add fail reason
  if (isDefined(mediaItem.backend.failReason)) {
    previewFields.failReason = mediaItem.backend.failReason;
  }
  // Add video thumbnail set
  previewFields.postImageNeedsToBeUpdated = {};
  // Add tracking parameters
  previewFields.trackingDetails = mediaItem.backend.trackingDetails ?? {};

  return previewFields;
}

function populateFbTargetingParams({
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  let fbTargetingParams = mediaItem.backend.fbTargetingParams;
  if (isNullOrUndefined(fbTargetingParams)) {
    const apiTypeId = getAPITypeId({ accountAPIId });
    if (hasAudienceRestriction({ apiTypeId })) {
      fbTargetingParams = {};
    }
  }
  return fbTargetingParams;
}

/**
 * Gets the default social channel for a media item.
 * @param {{
 *  mediaItem: import('types').FixTypeLater,
 *  apiTypeId: import('types').APITypeId,
 *  postType: import('types').PostType,
 * }}
 * @returns {import('types').SocialChannel}
 */
function getDefaultSocialChannel({
  mediaItem = mandatory('mediaItem'),
  apiTypeId = mandatory('apiType'),
  postType = mandatory('postType'),
}) {
  // If it's already defined on the backend, use that
  if (mediaItem.backend.socialChannel) {
    return mediaItem.backend.socialChannel;
  }

  if (
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    isIGCabinetEnabled() &&
    (postType === POST_TYPES.LINK || postType === POST_TYPES.PHOTO_STORY)
  ) {
    // the default social channel should be 'STORY' for link and photo story IG posts
    return SOCIAL_CHANNELS.STORY;
  }

  const supportedSocialChannels = API_PROPERTIES[apiTypeId]
    .supportedSocialChannels ?? [SOCIAL_CHANNELS.FEED];

  return supportedSocialChannels[0];
}

function copyTitleDescriptionAndImages({
  baseFields = mandatory('baseFields'),
  editedFields = mandatory('editedFields'),
  postType = mandatory('postType'),
  toApiTypeId = mandatory('toApiTypeId'),
} = {}) {
  const isTwitterAPI = toApiTypeId === API_TYPE_IDS.TWITTER;
  const hasTwitterCard = !isNull(baseFields.twitterCardType);
  const canCustomiseLinkPosts = baseFields.canCustomiseLinkPosts;

  let description = null;
  let title = null;
  let imageURLs = editedFields.imageURLs;
  let setToNull = false;

  if (hasTwitterCard) {
    const diffImages = editedFields.imageURLs.filter(s3.isEbxImage).length > 0;
    if (!diffImages) {
      description = baseFields.description;
      title = baseFields.title;
      imageURLs = baseFields.imageURLs;
    } else {
      setToNull = true;
    }
  } else {
    if (isTwitterAPI) {
      description = null;
      title = null;
    } else if (canCustomiseLinkPosts) {
      description = !isNullOrUndefined(editedFields.description)
        ? editedFields.description
        : baseFields.description;
      title = !isNullOrUndefined(editedFields.title)
        ? editedFields.title
        : baseFields.title;
    } else {
      description = baseFields.description;
      title = baseFields.title;
    }

    if (canCustomiseLinkPosts) {
      imageURLs = editedFields.imageURLs;
    } else {
      imageURLs = baseFields.imageURLs;
    }
  }

  const imageLimits = getImageQuantityLimits({
    apiTypeId: toApiTypeId,
    postType,
    socialChannel: editedFields.socialChannel,
  });
  if (imageURLs.length > imageLimits.max) {
    imageURLs = imageURLs.slice(0, imageLimits.max);
  }
  return {
    title,
    description,
    imageURLs,
    setToNull,
  };
}

function prepareMessage({
  message,
  editedTags = mandatory('editedTags'),
  fromApiTypeId = mandatory('fromApiTypeId'),
  toApiTypeId = mandatory('toApiTypeId'),
} = {}) {
  if (fromApiTypeId !== toApiTypeId) {
    // If we're cross-posting from Twitter to a non-Twitter API, strip the share message placeholder
    // See https://echobox.atlassian.net/browse/HELP-4901
    if (
      fromApiTypeId === API_TYPE_IDS.TWITTER &&
      toApiTypeId !== API_TYPE_IDS.TWITTER
    ) {
      if (typeof message === 'string') {
        let messageToClean = replaceShareURL({
          text: message,
          shareURL: '',
        });

        // Should never be null here, but we're getting HELP tickets with errors
        // where this is null. So just in case, we'll check for null before trimming.
        messageToClean =
          messageToClean != null ? messageToClean.trim() : messageToClean;

        return cleanTags(messageToClean, editedTags);
      }

      if (isDefined(message?.orderedShareContent)) {
        const messageToClean = message;

        messageToClean.orderedShareContent.forEach((text, index) => {
          const cleanedMessage = replaceShareURL({
            text: messageToClean.orderedShareContent[index].value,
            shareURL: '',
          });

          messageToClean.orderedShareContent[index].value =
            cleanedMessage != null ? cleanedMessage.trim() : cleanedMessage;
        });

        return cleanTags(message, editedTags);
      }
    }

    return cleanTags(message, editedTags);
  }
  return message;
}
