import PropTypes from 'prop-types';

const AddHeader = (props) => {
  const { eventHandlers } = props;

  return (
    <div className="modal-header">
      <div className="top">
        <div className="left mr-2">
          <span>Add a Domain</span>
        </div>
      </div>
      <a
        aria-label="Close"
        onClick={eventHandlers.handleAddCancel}
        className="close ft-26 text-400"
      >
        &times;
      </a>
    </div>
  );
};

AddHeader.propTypes = {
  eventHandlers: PropTypes.shape({
    handleAddCancel: PropTypes.func.isRequired,
  }).isRequired,
};

export default AddHeader;
