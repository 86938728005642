import { ClipboardEventHandler } from 'react';

import { setClipboardData } from 'common/clipboard';
import * as MediaItem from 'common/mediaItem';
import { PostPreview } from 'types';

export function composeBoxShareToAPIs(postPreviews: PostPreview[]) {
  return Array.from(
    new Set(
      postPreviews
        .filter((postPreview) => !postPreview.isHidden)
        .flatMap((postPreview) => {
          const additionalSocialPages = MediaItem.getAdditionalSocialPages({
            mediaItem: postPreview.mediaItem,
          });
          additionalSocialPages.push(Number(postPreview.accountAPIId));
          return additionalSocialPages;
        }),
    ),
  );
}

export const handleCopy: ClipboardEventHandler<HTMLDivElement> = (event) => {
  event.preventDefault();

  const text = window.getSelection()?.toString();

  setClipboardData(event, text);
};

export function isLoadingAny(postPreviews: PostPreview[]) {
  return postPreviews
    .map((preview) => preview.mediaItem)
    .some((mediaItem) =>
      MediaItem.getIsLoading({
        mediaItem,
      }),
    );
}

export function isSavingAny(postPreviews: PostPreview[]) {
  return postPreviews
    .map((preview) => preview.mediaItem)
    .some((mediaItem) =>
      MediaItem.getIsSaving({
        mediaItem,
      }),
    );
}

export function getComposeBoxPostPreviews(postPreviews: PostPreview[]) {
  return postPreviews.filter((item) => !item.isHidden);
}

export const getNumPosts = (previews: PostPreview[]) => {
  const numAdditionalSocialPages = previews
    .filter((preview) => !preview.isHidden)
    .flatMap((item) =>
      MediaItem.getAdditionalSocialPages({ mediaItem: item.mediaItem }),
    ).length;
  const numPreviews = previews.filter((preview) => !preview.isHidden).length;
  return numAdditionalSocialPages + numPreviews;
};
