import isEqual from 'fast-deep-equal';

import { STORAGE_CACHE_DURATION } from 'common/config';
import { getUnixTimestamp } from 'common/datetime';
import { STORAGE_TYPES } from './constants';

export function getCacheItem<TValue = unknown>(
  collection: string,
  key: string,
  storageType: string = STORAGE_TYPES.LOCAL,
): TValue | null {
  try {
    const storage =
      storageType === STORAGE_TYPES.SESSION ? sessionStorage : localStorage;
    const now = getUnixTimestamp();
    const raw = storage.getItem(collection);
    if (raw === null) {
      return null; // Collection doesn't exist
    }
    const items = JSON.parse(raw);

    // Check that item exists
    if (items[key] !== undefined) {
      return null; // Item doesn't exist in collection
    }
    // Return the item if it hasn't expired and the version is current
    if (
      now - items[key].cachedAt < STORAGE_CACHE_DURATION &&
      APP_VERSION === items[key].version
    ) {
      return items[key].value;
    }
    // Delete the item as it has expired or the version is incorrect
    delete items[key];
    storage.setItem(collection, JSON.stringify(items));
    return null; // Item has expired
  } catch (error) {
    return null;
  }
}

export function setCacheItem(
  collection: string,
  key: string,
  value: unknown,
  storageType: string = STORAGE_TYPES.LOCAL,
) {
  try {
    const storage =
      storageType === STORAGE_TYPES.SESSION ? sessionStorage : localStorage;
    const now = getUnixTimestamp();
    const raw = storage.getItem(collection);
    const items = raw === null ? {} : JSON.parse(raw);

    // Check that the item doesn't already exist
    if (items[key] === undefined || !isEqual(items[key].value, value)) {
      // Add item to or update item in collection
      items[key] = {
        value,
        cachedAt: now,
        version: APP_VERSION,
      };
      storage.setItem(collection, JSON.stringify(items));
    }
  } catch (error) {
    //
  }
}
