/* eslint no-use-before-define: ["error", { "functions": false }] */

import { getAPITypeId } from 'common/accountAPIs';
import { TAG_TYPES } from 'common/constants';
import * as MediaItem from 'common/mediaItem';
import { getSocialNetworkTagPattern, getTagDetails } from 'common/social';
import { isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

import { getMessage, getMessages } from './gettersSetters';

export {
  dedupeArray,
  extractDedupedTags,
  extractHashtagsAndMentions,
  populateTagDetails,
};

/**
 * Add any non-null message to the content, ensuring no duplicates
 */
function addMessageToContent({
  content = mandatory('content'),
  message,
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  if (!isNullOrUndefined(message)) {
    if (content[accountAPIId].indexOf(message) === -1) {
      content[accountAPIId].push(message);
    }
  }
}

/**
 * Add all messages in the media item to the content
 */
function addAllMessagesToContent({
  content = mandatory('content'),
  mediaItem = mandatory('mediaItem'),
  accountAPIId = mandatory('accountAPIId'),
} = {}) {
  const message = getMessage({ mediaItem });
  addMessageToContent({ content, message, accountAPIId });

  const firstComment = MediaItem.getFirstComment({ mediaItem });
  addMessageToContent({ content, message: firstComment, accountAPIId });

  const messages = getMessages({ mediaItem });
  if (
    !isNullOrUndefined(messages) &&
    !isNullOrUndefined(messages.orderedShareContent)
  ) {
    messages.orderedShareContent.forEach((shareContent) => {
      addMessageToContent({
        content,
        message: shareContent.value,
        accountAPIId,
      });
    });
  }
}

/**
 * Extract a list of unique hashtags and mentions found in the item's message, first comment and share messages
 */
function extractHashtagsAndMentions({
  mediaItem = mandatory('mediaItem'),
} = {}) {
  const content = {};

  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
  content[accountAPIId] = [];

  // Add the messages to content
  addAllMessagesToContent({ content, mediaItem, accountAPIId });

  // If it is an AB variation must also add the other variation messages
  const abInfo = mediaItem.frontend.abInfo;
  if (abInfo && abInfo.isABVariation) {
    const variationIndex = abInfo.currentABVariationIndex === 0 ? 1 : 0;
    const mediaItemAB = MediaItem.changeABVariation({
      mediaItem,
      accountAPIId,
      variationIndex,
    });
    addAllMessagesToContent({
      content,
      mediaItem: mediaItemAB,
      accountAPIId,
    });
  }

  const tags = {
    [TAG_TYPES.HASHTAG]: extractDedupedTags({
      accountAPIId,
      content,
      tagType: TAG_TYPES.HASHTAG,
    }),
    [TAG_TYPES.MENTION]: extractDedupedTags({
      accountAPIId,
      content,
      tagType: TAG_TYPES.MENTION,
    }),
  };

  return {
    [TAG_TYPES.HASHTAG]: populateTagDetails({
      tags: tags[TAG_TYPES.HASHTAG],
      tagTypeId: TAG_TYPES.HASHTAG,
    }),
    [TAG_TYPES.MENTION]: populateTagDetails({
      tags: tags[TAG_TYPES.MENTION],
      tagTypeId: TAG_TYPES.MENTION,
    }),
  };
}

function extractDedupedTags({
  accountAPIId = mandatory('accountAPIId'),
  content = mandatory('content'),
  tagType = mandatory('tagType'),
} = {}) {
  const tags = {};
  let matches;

  tags[accountAPIId] = [];
  const apiTypeId = getAPITypeId({ accountAPIId });
  content[accountAPIId].forEach((message) => {
    matches = message.match(getSocialNetworkTagPattern({ apiTypeId, tagType }));
    if (!isNull(matches)) {
      tags[accountAPIId] = tags[accountAPIId].concat(matches);
    }
  });

  const dedupedTags = {};
  dedupedTags[accountAPIId] = dedupeArray(tags[accountAPIId]);

  return dedupedTags;
}

function dedupeArray(array) {
  return array
    .slice() // Create copy of original array
    .sort() // Sort entries
    .reduce((a, b) => {
      if (a.slice(-1)[0] !== b) a.push(b); // Merge duplicates
      return a;
    }, []);
}

/**
 * Method: populateTagDetails
 *
 * -------------------------------------------------------------------------------------------------
 *
 * Derives tag details (clean / raw / more) from a per-account list of Echobox-encoded tags
 *
 * Example:
 *
 * INPUTS
 *
 *   tags: {
 *     240: ['#FB[HASHTAG1', '#FB[HASHTAG2']
 *   }
 *   tagTypeId: TAG_TYPES.HASHTAG
 *
 * OUTPUT
 *
 *   {
 *     240: [
 *       {
 *         raw: '#HASHTAG1',
 *         clean: '#HASHTAG1',
 *         more: 'https://www.facebook.com/hashtag/HASHTAG1'
 *       },
 *       {
 *         raw: '#HASHTAG2',
 *         clean: '#HASHTAG2',
 *         more: 'https://www.facebook.com/hashtag/HASHTAG2'
 *       }
 *     ]
 *   }
 */

function populateTagDetails({
  tags = mandatory('tags'),
  tagTypeId = mandatory('tagTypeId'),
} = {}) {
  const result = {};
  Object.keys(tags).forEach((accountAPIId) => {
    const apiTypeId = getAPITypeId({ accountAPIId });
    result[accountAPIId] = [];
    tags[accountAPIId].forEach((tag) => {
      result[accountAPIId].push(getTagDetails({ tag, apiTypeId, tagTypeId }));
    });
  });
  return result;
}
