/* eslint jsx-a11y/no-autofocus:"off" */

import * as validator from 'email-validator';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';

import { useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import postPropertiesDataExport from 'api/postPropertiesDataExport';
import { getCurrentPropertyId } from 'common/accountAPIs';
import {
  CSV_EXPORT_FROM_TIME,
  CSV_EXPORT_FROM_TIME_SECS_OFFSET,
  KEYCODES,
} from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import { convertToPropertyURN } from 'common/urn';
import Button from 'components/misc/Button';
import DropDown from 'components/misc/DropDown';
import GlobalInfoContext from 'context/GlobalInfoContext';

const Export = (props) => {
  /**
   * Initial state
   */

  const [errorMessage, setErrorMessage] = useState('');
  const [fromTime, setFromTime] = useState('ONE_MONTH');

  const globalContext = useContext(GlobalInfoContext).global;
  const globalInfo = globalContext.getGlobalInfo();
  const [emailAddress, setEmailAddress] = useState(
    globalInfo.user.emailAddress,
  );
  const [validEmail, isValidEmail] = useState(true);
  const toast = useToast();

  /**
   * Event handlers
   */

  const handleEmailChange = (event) => {
    logger.info('Export:handleEmailChange');

    isValidEmail(validator.validate(event.target.value));
    setEmailAddress(event.target.value);
  };

  const handleExportCancel = () => {
    logger.info('Export:handleExportCancel');

    props.eventHandlers.handleExportCancel();
  };

  const handleExportSubmit = () => {
    logger.info('Export:handleExportSubmit');

    const fromTimeOffSet = CSV_EXPORT_FROM_TIME_SECS_OFFSET[fromTime];
    const fromTimeStamp = Math.floor(
      new Date().getTime() / 1000 - fromTimeOffSet,
    );
    const propertyId = getCurrentPropertyId({
      globalInfo,
    });
    postPropertiesDataExport({
      propertyURN: convertToPropertyURN(propertyId),
      fromTime: fromTimeStamp,
      recipientEmail: emailAddress !== '' ? emailAddress : null,
    })
      .then(() => {
        toast({
          title: 'An email will be sent once your export is complete',
          description: 'This can take up to 12 hours',
        });
        props.eventHandlers.handleExportCancel();
      })
      .catch((error) => {
        setErrorMessage(getErrorMessage(determineError(error)));
      });
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === KEYCODES.ESCAPE) {
      setEmailAddress('');
      setErrorMessage('');
      handleExportCancel();
    }
  };

  const handleExportFromTimeChange = (event) => {
    setFromTime(event.target.value);
  };

  /**
   * Render methods
   */

  const renderHeader = () => {
    return (
      <div className="modal-header">
        <div className="top">
          <div className="left mr-2">
            <span>Export Analytics</span>
          </div>
        </div>
        <a
          aria-label="Close"
          onClick={handleExportCancel}
          className="close ft-26 text-400"
        >
          &times;
        </a>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div className="modal-body lh-20">
          <div>
            We will email you the CSV once it&#39;s ready. You can request one
            export per day.
          </div>
        </div>
        <div className="modal-divider" />
        <div className="modal-body lh-20">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="text-500 mb-1 d-flex">Email</div>
              <input
                type="email"
                className="input_medium"
                placeholder="Enter email..."
                value={emailAddress}
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-12 col-md-4 pl-md-0 mt-4 mt-md-0">
              <div className="text-500 mb-1 d-flex">Date range</div>
              <DropDown
                dropDownClass="btn btn-light dropdown-md"
                name="CSVFromTime"
                value={fromTime}
                handleDropDownChange={handleExportFromTimeChange}
                sources={CSV_EXPORT_FROM_TIME}
                disabled={false}
                style={{ width: '100%', maxWidth: '132px' }}
              />
            </div>
          </div>
        </div>
        {errorMessage !== '' && (
          <div className="modal-body modal-error save-state save failed">
            <img
              className="icon-error mr-1"
              src="/img/icons/ic-warning-red.svg"
              alt=""
            />{' '}
            {errorMessage}
          </div>
        )}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <div className="ml-auto">
          <Button className="ml-auto" onClick={handleExportCancel}>
            Cancel
          </Button>
        </div>
        <div className="save">
          <Button
            variant="dark"
            onClick={handleExportSubmit}
            data-cy-action="saveNewUser"
            disabled={!validEmail}
          >
            Send CSV
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="ebx-modal-outer">
      <div className="select-pages modal-dialog modal-sm">
        <div className="modal-content">
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </div>
      </div>
    </div>
  );
};

Export.propTypes = {
  eventHandlers: PropTypes.shape({
    handleExportCancel: PropTypes.func.isRequired,
  }).isRequired,
};

export default Export;
