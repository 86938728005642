import { REFRESH_INTERVALS } from 'common/config';
import { IMAGE_TYPES } from 'common/constants';
import { isNullOrUndefined } from 'common/utility';
import { FileMetaDataType, User } from 'types';

export function getImageType(file: Blob | FileMetaDataType) {
  if (!isNullOrUndefined(file)) {
    return file.type.indexOf('image/gif') > -1
      ? IMAGE_TYPES.GIF
      : IMAGE_TYPES.OTHER;
  }
  return null;
}

export function refreshInterval(multiplier: number) {
  const interval = localStorage.getItem('refreshInterval');
  return (
    (interval != null ? Number(interval) : REFRESH_INTERVALS.BASE_VALUE) *
    multiplier
  );
}

export function sortUsers(users: User[], alphabeticalOrder = true) {
  if (isNullOrUndefined(users)) {
    return [];
  }
  return users.sort((a, b) => {
    const [nameA, nameB] = [a.name.toUpperCase(), b.name.toUpperCase()];
    // Name A is before Name B
    if (nameA < nameB) {
      if (alphabeticalOrder) return -1;
      return 1;
    }
    // Name A is after Name B
    if (nameA > nameB) {
      if (alphabeticalOrder) return 1;
      return -1;
    }
    // Names must be equal
    return 0;
  });
}
