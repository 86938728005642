/* eslint no-useless-constructor:"off" */
/* eslint react/no-did-mount-set-state:"off" */

import queryString from 'query-string';

import authSignOut from 'api/aws/authSignOut';
import * as authentication from 'common/authentication';
import {
  FLASH_MESSAGE_TYPES,
  IFRAME_MESSAGE_TIMEOUT,
  LOGIN_APP_MESSAGES,
} from 'common/constants';
import * as environment from 'common/environment';
import * as logger from 'common/logger';
import * as loginApp from 'common/loginapp';
import { withLocation } from 'common/routing';
import * as tracker from 'common/tracker';
import { isDefined, isNullOrUndefined, until } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import FullPageSpinner from 'components/misc/FullPageSpinner';
import withFlashMessages from 'context/withFlashMessages';

const loginUrl = environment.getLoginUrl();

const LOGOUT_KEY = 'isLoggingOut';

/*
 * Logout
 */

class Logout extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this._bind('logout');
    this.state = {
      redirect: false,
    };
  }

  /**
   * Lifecycle methods
   */

  async componentDidMount() {
    logger.info('Logout:componentDidMount');
    sessionStorage.removeItem(LOGOUT_KEY);
    window.onmessage = async (event) => {
      if (event.origin === loginUrl) {
        const {
          data: { status },
        } = event;
        if (status === 200) {
          sessionStorage.removeItem(LOGOUT_KEY);
          window.location.href = `${loginUrl}${window.location.search}`;
        }
      } else {
        logger.info(`Received message from unexpected origin: ${event.origin}`);
      }
    };
    // Remove all error messages
    this.props.flashMessages.deleteMessages();

    const isLoggedIn = authentication.isLoggedIn();
    const query = queryString.parse(this.props.location.search);
    const wasTimedOut = isDefined(query.timeout);
    const wasBadAuth =
      isDefined(query.badauth) ||
      (isDefined(this.props.location.pathname) &&
        this.props.location.pathname.indexOf('badauth') !== -1);
    const wasValidationSuccessful = isDefined(query.validationsuccessful);
    const wasValidationFailure = isDefined(query.validationfailed);

    // Log the user out if necessary
    if (isLoggedIn) {
      if (wasTimedOut) {
        await authSignOut(false);
        this.logout({ wasTimedOut });
      } else {
        await authSignOut();
        this.logout({ wasBadAuth });
      }
    }

    // Email validation
    if (wasValidationSuccessful || wasValidationFailure) {
      if (wasValidationSuccessful) {
        this.props.flashMessages.addMessage({
          messageCategory: 'Email has been verified',
          type: FLASH_MESSAGE_TYPES.INFO,
          text: 'Your new email address has been verified. Please sign in with your new credentials.',
          hideClose: true,
        });
      }
      if (wasValidationFailure) {
        let errorMessage = isDefined(query.message)
          ? decodeURIComponent(query.message)
          : 'Email validation failed';
        if (
          errorMessage ===
          'Unauthorized: Authentication token was either missing, invalid or expired.'
        ) {
          errorMessage =
            'Your email address could not be updated. Please log in and try again.';
        }
        this.props.flashMessages.addMessage({
          messageCategory: 'Email validation failed',
          type: FLASH_MESSAGE_TYPES.ERROR,
          text: errorMessage,
        });
      }
      this.setState({ redirect: true });
    }

    // Logout page visited when already logged out
    if (!isLoggedIn && !wasValidationSuccessful && !wasValidationFailure) {
      this.setState({ redirect: true });
    }
  }

  logout({ wasTimedOut = false, wasBadAuth = false } = {}) {
    // Track logout event in Mixpanel
    tracker.track({ eventName: 'Logout' });
    // Destroy Beamer and ensure all processes are stopped
    if (!isNullOrUndefined(window.Beamer)) {
      window.Beamer.destroy();
    }
    // Destroy client-side authentication details
    authentication.logout();
    // Reset Beamer
    setTimeout(() => {
      delete window.beamer_config;
    }, 500);

    if (wasTimedOut) {
      logger.track('User session expired');
      this.props.flashMessages.addMessage({
        messageCategory: 'Logout user session expired',
        type: FLASH_MESSAGE_TYPES.INFO,
        text:
          'Your session has expired. You may have logged in from another device. If you are ' +
          'sharing login credentials with other users, please make sure to create your own.',
      });
    }
    if (wasBadAuth) {
      this.props.flashMessages.addMessage({
        messageCategory: 'Logout social pages not connected to Echobox',
        type: FLASH_MESSAGE_TYPES.INFO,
        text:
          'The social pages you have access to are currently not connected to Echobox. ' +
          'Until these are reconnected, you will not be able to log in. ' +
          'Please contact an admin user to reconnect your pages.',
      });
    }
    this.setState({ redirect: true });
  }

  /**
   * Render method
   */

  render() {
    if (this.state.redirect) {
      // logout in login app
      sessionStorage.setItem(LOGOUT_KEY, true);
      until(
        () => !sessionStorage.getItem(LOGOUT_KEY),
        () => loginApp.postMessage(LOGIN_APP_MESSAGES.SIGN_OUT),
        50,
        IFRAME_MESSAGE_TIMEOUT,
      );
    }

    return <FullPageSpinner />;
  }
}

export default withFlashMessages(withLocation(Logout));
