import { ChangeEvent, MouseEvent } from 'react';

import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import { DAYS } from 'common/constants';
import { generateGuid } from 'common/guid';
import * as logger from 'common/logger';
import * as schedules from 'common/schedules';

import { SchedulePeriodsSettings } from 'components/settings/automation/schedule/SchedulePeriods';

import { useGlobalInfo } from 'context/GlobalInfoContext';

const matrixStyles = { span: { color: 'gray.900', lineHeight: '18px' } };

interface ScheduleMatrixProps {
  currentSchedulePeriodSettings: SchedulePeriodsSettings;
  handleSchedulePeriodSettingsChange: (
    settings: SchedulePeriodsSettings,
  ) => void;
}

function ScheduleMatrix({
  currentSchedulePeriodSettings,
  handleSchedulePeriodSettingsChange,
}: ScheduleMatrixProps) {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  /**
   * Event handlers
   */

  function handleAllHourClick(event: MouseEvent) {
    const dataHour = event.currentTarget.getAttribute('data-hour');
    if (!dataHour) return;

    const hour = parseInt(dataHour, 10);
    logger.info(`Detail:handleAllHourClick ${hour}`);
    const newSchedulePeriods = [
      ...currentSchedulePeriodSettings.schedulePeriods,
    ];

    let noOfTicks = 0;
    for (let offset = 0; offset < 7; offset += 1) {
      if (newSchedulePeriods[hour + offset * 24].statusAfterTrigger) {
        noOfTicks += 1;
      }
    }
    const setToChecked = noOfTicks !== 7;
    for (let offset = 0; offset < 7; offset += 1) {
      newSchedulePeriods[hour + offset * 24].statusAfterTrigger = setToChecked;
    }

    const triggersJSON = schedules.toJSONFromSchedulePeriods(
      newSchedulePeriods,
      globalInfo,
    );

    const newSettings: SchedulePeriodsSettings = {
      ...currentSchedulePeriodSettings,
      statusScheduleTriggers: triggersJSON,
      schedulePeriods: newSchedulePeriods,
    };

    handleSchedulePeriodSettingsChange(newSettings);
  }

  function handleAllDayClick(event: MouseEvent) {
    const dataOffset = event.currentTarget.getAttribute('data-offset');
    if (!dataOffset) return;

    const start = parseInt(dataOffset, 10);
    logger.info(`Detail:handleAllDayClick ${start}`);
    const newSchedulePeriods = [
      ...currentSchedulePeriodSettings.schedulePeriods,
    ];

    const noOfTicks = newSchedulePeriods
      .slice(start, start + 24)
      .map((column) => (column.statusAfterTrigger ? 1 : 0) as number)
      .reduce((sum, val) => sum + val);
    const setToChecked = noOfTicks !== 24;
    for (let offset = 0; offset < 24; offset += 1) {
      newSchedulePeriods[start + offset].statusAfterTrigger = setToChecked;
    }

    const triggersJSON = schedules.toJSONFromSchedulePeriods(
      newSchedulePeriods,
      globalInfo,
    );

    const newSettings: SchedulePeriodsSettings = {
      ...currentSchedulePeriodSettings,
      statusScheduleTriggers: triggersJSON,
      schedulePeriods: newSchedulePeriods,
    };

    handleSchedulePeriodSettingsChange(newSettings);
  }

  function handleStatusScheduleTriggerChange(
    event: ChangeEvent<HTMLInputElement>,
  ) {
    const schedulePeriodHour = parseInt(event.target.name, 10); // the index
    const checked = event.target.checked;

    // Merge into range this.state.data.schedulePeriods - update the view
    // Turn schedule periods back into the correct JSON
    const newSchedulePeriods = [
      ...currentSchedulePeriodSettings.schedulePeriods,
    ];
    const newHourValue = newSchedulePeriods[schedulePeriodHour];
    newHourValue.statusAfterTrigger = checked;

    newSchedulePeriods[schedulePeriodHour] = newHourValue;

    const triggersJSON = schedules.toJSONFromSchedulePeriods(
      newSchedulePeriods,
      globalInfo,
    );

    const newSettings: SchedulePeriodsSettings = {
      ...currentSchedulePeriodSettings,
      statusScheduleTriggers: triggersJSON,
      schedulePeriods: newSchedulePeriods,
    };

    handleSchedulePeriodSettingsChange(newSettings);
  }

  /**
   * Render methods
   */

  const renderMatrix = () => {
    // Create the checkbox matrix
    const output = [];
    output.push(
      <div key="checkbox-matrix">
        <span className="day" />
        {Array(24)
          .fill(0)
          .map((_, index) => (
            <span key={generateGuid()} className="value">
              {index}
            </span>
          ))}
      </div>,
    );
    const all = [];
    for (let i = 0; i < 24; i += 1) {
      all.push(
        <span className="all" key={`all-button-hour-${i}`}>
          <button
            type="button"
            className="btn btn-light btn-sm"
            data-hour={i}
            onClick={handleAllHourClick}
          >
            All
          </button>
        </span>,
      );
    }
    output.push(
      <div key="all">
        <span className="day" />
        {all}
      </div>,
    );
    for (let i = 0; i < 7; i += 1) {
      const start = i * 24;
      const end = (i + 1) * 24;
      output.push(
        <div key={`row-wrapper-day-${i}`}>
          {renderRow({
            day: i,
            start,
            end,
          })}
        </div>,
      );
    }

    return <div className="periods_table sub_note checkbox">{output}</div>;
  };

  const renderRow = ({
    day,
    start,
    end,
  }: {
    day: number;
    start: number;
    end: number;
  }) => {
    const vector = currentSchedulePeriodSettings.schedulePeriods;
    const output = [];
    output.push(
      <span className="day" key={`row-day-${day}`}>
        {DAYS[day]}
      </span>,
    );
    let checked;
    for (let j = start; j < end; j += 1) {
      checked = vector[j] ? vector[j].statusAfterTrigger : false;
      output.push(
        <div className="d-inline-block" key={`input_div_${j}`}>
          <input
            id={j.toString()}
            key={`input_${j}`}
            data-cy-id={`period${j}`}
            name={j.toString()}
            type="checkbox"
            checked={checked}
            onChange={handleStatusScheduleTriggerChange}
          />
          <label htmlFor={j.toString()} />
        </div>,
      );
    }
    output.push(
      <span className="all" key={`all-day-${day}`}>
        <button
          type="button"
          className="btn btn-light btn-sm px-2 ml-1"
          onClick={handleAllDayClick}
          data-offset={start}
          data-cy-id={`day${day}`}
        >
          All
        </button>
      </span>,
    );
    return output;
  };

  return <Box sx={matrixStyles}>{renderMatrix()}</Box>;
}
export default ScheduleMatrix;
