import { useState } from 'react';

import authChangePassword from 'api/aws/authChangePassword';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { addSuccessNotification } from 'common/notifications';
import {
  checkPasswordStrength,
  checkPasswordsMatch,
} from 'common/passwordValidator';
import { ebxMessage } from 'common/string';
import { isNull } from 'common/utility';
import Button from 'components/misc/Button';
import SettingsError from 'components/settings/SettingsError';

import ChangePasswordConfirmation from './ChangePasswordConfirmation';

/*
 * Settings - change password
 */

const ChangePassword = () => {
  /**
   * Initial state
   */

  const [errorMessage, setErrorMessage] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifiedPassword, setVerifiedPassword] = useState('');
  const [isOpenConfirmationPopup, setIsOpenConfirmationPopup] = useState(false);

  /**
   * Event handlers
   */

  const handlePasswordSave = () => {
    // Validation
    if (oldPassword === '') {
      setErrorMessage(ebxMessage('Please enter your old password.'));
      return;
    }
    if (newPassword === '') {
      setErrorMessage(ebxMessage('Please enter your new password.'));
      return;
    }
    if (verifiedPassword === '') {
      setErrorMessage(ebxMessage('Please verify your new password.'));
      return;
    }
    if (!checkPasswordsMatch(newPassword, verifiedPassword)) {
      setErrorMessage(ebxMessage('Please make sure both new passwords match.'));
      return;
    }
    if (!checkPasswordStrength(newPassword)) {
      setErrorMessage(
        ebxMessage(`Please ensure your password is at least 10 characters
                long and contains at least one number, one uppercase letter, and one lowercase
                letter.`),
      );
      return;
    }
    setIsOpenConfirmationPopup(true);
  };

  const handleChangePassword = () => {
    return authChangePassword({ oldPassword, newPassword })
      .then(() => {
        setErrorMessage('');
        setOldPassword('');
        setNewPassword('');
        setVerifiedPassword('');
        addSuccessNotification('Password updated');
      })
      .catch((error) => {
        if (isNull(getErrorStatus(error))) {
          logger.error({
            event: 'handleChangePassword Error',
            properties: {
              location: 'components/settings/user/ChangePassword',
            },
            error,
          });
        }
        setErrorMessage(getErrorMessage(determineError(error)));
        setIsOpenConfirmationPopup(false);
      });
  };

  const handleErrorClose = () => {
    setErrorMessage('');
  };

  /**
   * Render method
   */

  return (
    <div className="box">
      <div className="part settingsChangePassword">
        <div className="d-flex box-header">
          <h3 className="align-self-center">Change Password</h3>
        </div>
        <div className="setting-part pb-3">
          <form>
            <SettingsError
              errorMessage={errorMessage}
              eventHandlers={{ handleErrorClose }}
            />
            <p className="sub_note mb-4 mt-3">
              Passwords must be at least 10 characters long and must contain at
              least one number, one uppercase letter, and one lowercase letter.
            </p>
            <div className="password mb-3">
              <label htmlFor="oldPassword">
                <span className="label text-500 pb-1">Old Password</span>
                <div>
                  <input
                    className="input_medium"
                    name="oldPassword"
                    id="oldPassword"
                    data-cy-input="oldPassword"
                    onChange={(event) => setOldPassword(event.target.value)}
                    value={oldPassword}
                    type="password"
                    style={{ minWidth: '240px' }}
                  />
                </div>
              </label>
            </div>
            <div className="password mb-3">
              <label htmlFor="newPassword">
                <span className="label text-500 pb-1">New Password</span>
                <div>
                  <input
                    className="input_medium"
                    name="newPassword"
                    id="newPassword"
                    data-cy-input="newPassword"
                    onChange={(event) => setNewPassword(event.target.value)}
                    value={newPassword}
                    type="password"
                    style={{ minWidth: '240px' }}
                  />
                </div>
              </label>
            </div>
            <div className="password mb-3">
              <label htmlFor="verifiedPassword">
                <span className="label text-500 pb-1">Verify New Password</span>
                <div>
                  <input
                    className="input_medium"
                    name="verifiedPassword"
                    id="verifiedPassword"
                    data-cy-input="verifyPassword"
                    onChange={(event) =>
                      setVerifiedPassword(event.target.value)
                    }
                    value={verifiedPassword}
                    type="password"
                    style={{ minWidth: '240px' }}
                  />
                </div>
              </label>
            </div>
            <hr className="op-30 mt-3 mb-2" />
            <Button
              variant="dark"
              data-cy-action="savePassword"
              onClick={handlePasswordSave}
            >
              Save
            </Button>
          </form>
        </div>
      </div>
      {isOpenConfirmationPopup && (
        <ChangePasswordConfirmation
          isOpen={isOpenConfirmationPopup}
          onDismiss={() => setIsOpenConfirmationPopup(false)}
          onChangePassword={handleChangePassword}
        />
      )}
    </div>
  );
};

export default ChangePassword;
