import { Box, Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

interface MessageBoxControlsProps {
  children: React.ReactNode;
}

const MessageBoxControls = ({ children }: MessageBoxControlsProps) => {
  return (
    <Flex className="message_controls" h="auto !important">
      {children}
    </Flex>
  );
};

interface MessageBoxControlsContainerProps {
  children: React.ReactNode;
}

const MessageBoxControlsContainer = ({
  children,
}: MessageBoxControlsContainerProps) => <Box pb="2.5">{children}</Box>;

MessageBoxControls.Container = MessageBoxControlsContainer;

export default MessageBoxControls;
