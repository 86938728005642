/**
 * GET /{root}/{state}/categories
 *
 * @param {string} socialPageURN - social page URN
 * @param {string} state         - state ('ARCHIVED')
 * @param {timestamp} from       - optional from timestamp
 * @param {timestamp} to         - optional to timestamp
 * @param {string} filter        - optional comma-separated list of filters, available options are:
 *                                  share, reshare, today, breaking, evergreen
 *                                 multiple options are only allowed for the state ARCHIVED
 * @return [string]              - return array of categories
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import {
  getCurrentPropertyId,
  getPropertyIdForAccountAPIId,
} from 'common/accountAPIs';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { extractAccountAPIId } from 'common/urn';
import { lyingParse } from 'common/zod';
import type { MediaItemState } from 'types';

const ResponseSchema = z.object({
  categories: z.array(z.string()),
});

export default async function getMediaItemCategories({
  socialPageURN,
  state,
  to,
  from,
  publishFromToTimes,
  shareFromToTimes,
  filter = null,
}: {
  socialPageURN: string;
  state: MediaItemState;
  to?: number;
  from?: number;
  publishFromToTimes?: string;
  shareFromToTimes?: string;
  filter?: string | null;
}) {
  const guid = metrics.start('getMediaItemCategories');
  const params = {
    filters: filter,
    ...(publishFromToTimes && { publishFromToTimes }),
    ...(shareFromToTimes && { shareFromToTimes }),
  };

  const config: AxiosRequestConfig = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${socialPageURN}/media/${state}/categories`,
    method: 'GET',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders(),
    params,
  };
  logger.info(
    `API:getMediaItemCategories /social/api/${socialPageURN}/media/${state}/categories ${
      params.filters ?? ''
    }`,
  );
  try {
    const response = await axios(config);
    metrics.end('getMediaItemCategories', guid);
    const data = lyingParse(ResponseSchema, response.data);
    return data;
  } catch (error) {
    const accountAPIId = extractAccountAPIId(socialPageURN);
    if (
      getPropertyIdForAccountAPIId({ accountAPIId }) === getCurrentPropertyId()
    ) {
      metrics.fail('getMediaItemCategories', guid);
      const apiError = await handleAPIError({
        originalError: error,
        errorLocation: 'api/getMediaItemCategories',
        args: {
          socialPageURN,
          state,
          to,
          from,
          filter,
        },
      });
      throw apiError;
    }
    // Current property has been changed since the request was issued
    // Throw an error (to stop any subsequent processing) that will then be ignored in pubsub/API_INFO
    throw new Error('EBX:INTERNAL - current property has changed');
  }
}
