import type { ChangeEventHandler } from 'react';
import { memo } from 'react';

import { arePropsEqual } from 'common/utility';

interface SocialPagesSearchProps {
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  searchText: string;
}

const SocialPagesSearch = ({
  onSearchChange,
  searchText,
}: SocialPagesSearchProps) => (
  <div className="form-group mb-0 page-search">
    <input
      id="pageSearch"
      type="text"
      className="form-control input_medium ft-12 border-radius-2 dropdown-search-pages"
      placeholder="Search page..."
      value={searchText}
      onChange={onSearchChange}
    />
  </div>
);

export default memo(SocialPagesSearch, arePropsEqual);
