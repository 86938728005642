import { franc } from 'franc';
import $ from 'jquery';

import { RTL_CONFIGURATION } from 'common/config';
import { TEXT_DIRECTIONS } from 'common/constants';
import { iso6393toDirection } from 'common/language/data';
import * as logger from 'common/logger';
import { isDefined, isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import type { TextDirection } from 'types';

import { TextDirectionSchema } from './schemas';

/**
 * This method is called whenever we want to set the text direction for a piece of text.
 *
 * If the piece of text is longer than {MIN_TEXT_LENGTH} characters, we store it.
 * If the number of pieces of text in storage exceeds {MIN_ITEMS_REQUIRED}, then it’s ready for examination.
 *
 * During the examination, we use `franc`, to determine the language code. That code is checked against iso6393toDirection to determine the text direction.
 * This text direction (ltr or rtl) is then stored for next time we need to determine the text direction.
 *
 * @returns a string representing the text direction ("ltr" or "rtl")
 */
function getTextDirection({
  accountAPIId,
  text,
}: {
  /**
   * The account API ID
   */
  accountAPIId: number;
  /**
   * The text to be examined
   */
  text: string;
}) {
  if (accountAPIId === undefined) {
    mandatory('accountAPIId');
  }
  if (text === undefined) {
    mandatory('text');
  }

  // Default text direction is LTR
  let direction: TextDirection = TEXT_DIRECTIONS.LTR;

  // Flag tracking whether we have determined the required text direction
  let decided = false;

  // Attempt to retrieve sessionStorage.textDirection
  if (isDefined(sessionStorage)) {
    const session = sessionStorage.getItem('textDirection');
    const parsedSession = TextDirectionSchema.safeParse(session);
    // If it doesn't exist or has not been set...
    if (session === null || !parsedSession.success) {
      // Check the list of account APIs which are hard-coded to use RTL
      if (RTL_CONFIGURATION.ACCOUNT_APIS.indexOf(accountAPIId) !== -1) {
        direction = TEXT_DIRECTIONS.RTL;
        decided = true;
      }

      // Store the text and update the number of items examined counter
      // (But ignore the text provided if it is not long enough)
      if (
        !decided &&
        !isNull(text) &&
        text.length >= RTL_CONFIGURATION.MIN_TEXT_LENGTH
      ) {
        let itemsExamined = Number(sessionStorage.getItem('itemsExamined'));
        if (isNull(itemsExamined)) {
          itemsExamined = 0;
        }
        itemsExamined += 1;
        sessionStorage.setItem('itemsExamined', itemsExamined.toString());
        let textToExamine = sessionStorage.getItem('textToExamine') ?? '';
        textToExamine += ` ${text}`;
        sessionStorage.setItem('textToExamine', textToExamine);

        // If we have stored enough text, examine it to determine the language
        if (itemsExamined >= RTL_CONFIGURATION.MIN_ITEMS_REQUIRED) {
          // Detect language based on saved text - ISO 693-3 format
          const languageCode = franc(textToExamine);
          // Convert language to text direction
          let languageDirection =
            languageCode in iso6393toDirection
              ? iso6393toDirection[
                  languageCode as keyof typeof iso6393toDirection
                ]
              : undefined;
          // If for some reason there is no mapping from the detected language,
          // set a default and log the fact that this has happened
          if (languageDirection === null || languageDirection === undefined) {
            languageDirection = TEXT_DIRECTIONS.LTR;
            logger.error({
              event: 'Language Detection Error',
              properties: {
                TextToExamine: textToExamine,
                LanguageDetected: languageCode,
              },
            });
          } else {
            logger.track({
              event: 'Text direction detected',
              properties: {
                TextToExamine: textToExamine,
                LanguageDetected: languageCode,
                DirectionDetected: languageDirection,
              },
            });
          }
          direction = languageDirection;
          decided = true;
          // Ensure that any text rendered so far gets re-rendered using the correct class
          if (typeof $ !== 'undefined') {
            const removeClassName =
              direction === TEXT_DIRECTIONS.LTR
                ? TEXT_DIRECTIONS.RTL
                : TEXT_DIRECTIONS.LTR;
            const addClassName = direction;
            $(`.${removeClassName}`).addClass(addClassName);
            $(`.${removeClassName}`).removeClass(removeClassName);
          }
        }
      }
    } else {
      // ... otherwise use the existing value
      direction = parsedSession.data;
    }
    // Ensure that sessionStorage.textDirection is set/updated so the next time
    // this method is called we can use the set value rather than having to
    // derive it again
    if (decided) {
      sessionStorage.setItem('textDirection', direction);
    }
  }

  return direction;
}

function resetTextDirection() {
  sessionStorage.removeItem('textDirection');
  sessionStorage.removeItem('itemsExamined');
  sessionStorage.removeItem('textToExamine');
}

export { getTextDirection, resetTextDirection };
