import { z } from 'zod';

import {
  HEALTH_MENU_ERROR_TYPES,
  HEALTH_MENU_LOCALSTORAGE_KEY,
  HEALTH_MENU_TOAST_LOCALSTORAGE_KEY,
} from 'common/constants';
import { HealthMenuNotificationStorage } from 'types';

const OldSeenNotificationsSchema = z
  .string()
  .transform((raw) => JSON.parse(raw))
  .pipe(z.record(z.record(z.string().or(z.boolean()))))
  .describe('SeenNotificationsSchema');

function migrateHealthMenuStorageStructure() {
  const healthMenuLocalStorageKeys = [
    HEALTH_MENU_LOCALSTORAGE_KEY,
    HEALTH_MENU_TOAST_LOCALSTORAGE_KEY,
  ];

  healthMenuLocalStorageKeys.forEach((localStorageKey) => {
    const old = localStorage.getItem(localStorageKey);
    if (!old) {
      return;
    }

    const oldStructure = OldSeenNotificationsSchema.safeParse(old);
    if (!oldStructure.success) {
      // The structure is already in the new format.
      return;
    }

    const newStructure: HealthMenuNotificationStorage = {};
    for (const [propertyId, value] of Object.entries(oldStructure.data)) {
      newStructure[propertyId] = {};

      for (const [subKey, subValue] of Object.entries(value)) {
        if (subKey === HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION) {
          newStructure[propertyId][
            HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION
          ] = subValue as boolean;
        } else if (subKey === HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR) {
          newStructure[propertyId][HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR] =
            subValue;
        } else {
          const disconnections =
            newStructure[propertyId][HEALTH_MENU_ERROR_TYPES.DISCONNECTIONS] ??
            {};

          disconnections[subKey] = subValue as string;

          newStructure[propertyId][HEALTH_MENU_ERROR_TYPES.DISCONNECTIONS] =
            disconnections;
        }
      }
    }

    localStorage.setItem(localStorageKey, JSON.stringify(newStructure));
  });
}

export default migrateHealthMenuStorageStructure;
