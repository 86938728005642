import PropTypes from 'prop-types';
import { useState } from 'react';

import Dialog from 'components/misc/Dialog';

import SetupMFA from './SetupMFA';
import SuccessMessage from './SuccessMessage';

/**
 * React component for rendering dialog for MFA
 * Maintains a popupState state variable for rendering different screens in the dialog
 * Requires two props:
 * 1) isOpen      - A boolean variable used for deciding whether to show dialog or not
 * 2) onDismiss - A function to dismiss the popup. Should ideally change value of open to false
 */
const MFAPopup = ({ isOpen, onDismiss }) => {
  const [popupState, setPopupState] = useState('MFA_SETUP');

  /**
   * Function to dismiss the popup and reset popup state to MFA_SETUP
   */
  const handleClose = () => {
    onDismiss();
    setPopupState('MFA_SETUP');
  };

  /**
   * Function to render correct component based on popup state
   * Currently supports SETUP_MFA and SUCCESS_MESSAGE states
   * @returns {Component} react component to render
   */
  const renderChild = () => {
    const childComponents = {
      MFA_SETUP: (
        <SetupMFA
          onDismiss={onDismiss}
          showSuccessMessage={() => setPopupState('SUCCESS_MESSAGE')}
        />
      ),
      SUCCESS_MESSAGE: <SuccessMessage handleClose={handleClose} />,
    };
    return childComponents[popupState];
  };

  return (
    <Dialog isOpen={isOpen} onDismiss={handleClose} centerVertically>
      {renderChild()}
    </Dialog>
  );
};

MFAPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default MFAPopup;
