/* eslint no-param-reassign:"off" */

import {
  Box,
  Flex,
  HStack,
  VisuallyHidden,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Immutable from 'seamless-immutable';

import { getAPITypeId, getCurrentPropertyId } from 'common/accountAPIs';
import * as Compose from 'common/compose';
import {
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  MEDIA_ITEM_STATES,
  MEDIA_ITEM_TYPES,
  POST_TYPES,
  SHARE_ORIGINS,
  SOCIAL_CHANNELS,
  TEXT_CASES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getFormattedDateFromUnix } from 'common/datetime';
import { getGlobalInfo } from 'common/globalInfo';
import { isInstantVideoEnabled } from 'common/instantVideo';
import * as MediaItem from 'common/mediaItem';
import { isEbxImage } from 'common/s3';
import {
  getFeatureToggle,
  getSetting,
  isIGCabinetEnabled,
} from 'common/settings';
import { supportsSocialChannel } from 'common/socialV2';
import { isVideoURL } from 'common/url';
import { getMediaItemType } from 'common/urn';
import { arePropsEqual, isNull } from 'common/utility';
import ItemError from 'components/home/ItemError';
import ItemScore from 'components/home/ItemScore';
import LastEditedUser from 'components/home/LastEditedUser';
import ItemImages from 'components/home/articlefeed/ItemImages';
import ItemPublished from 'components/home/articlefeed/ItemPublished';
import ItemShareButton from 'components/home/articlefeed/ItemShareButton';
import ItemTitle from 'components/home/articlefeed/ItemTitle';
import Loading from 'components/home/articlefeed/Loading';
import Tooltip from 'components/misc/Tooltip';
import useThumbnail from 'hooks/useThumbnail';
import GenerateVideoButton from './GenerateVideoButton';
import InstantShareButton from './InstantShareButton';

/**
 * Article feed item
 */

const Item = (props) => {
  const videoURL = MediaItem.getVideoURL({ mediaItem: props.mediaItem });
  const imageURLs = MediaItem.getImageURLs({ mediaItem: props.mediaItem });
  const thumbnail = useThumbnail({
    videoURL,
    shouldFetch:
      imageURLs == null || imageURLs.length === 0 || !isEbxImage(imageURLs[0]),
  });
  /**
   * Event handlers
   */

  const handleArticleDelete = () => {
    props.eventHandlers.handleArticleDelete({
      collectionName: props.collectionName,
      mediaId: MediaItem.getMediaId({ mediaItem: props.mediaItem }),
    });
  };

  const handleShowInstantVideoModal = () => {
    props.eventHandlers.handleShowInstantVideoModal({
      mediaId: MediaItem.getMediaId({ mediaItem: props.mediaItem }),
    });
  };

  const handleOpenInstantImage = () => {
    Compose.editPost({
      editItem: mediaItem,
      itemOrigin: SHARE_ORIGINS.HOME_FEED,
      instantImage: true,
    });
  };

  const handleArticleEdit = () => {
    // if a video, then update the video thumbnail
    let mediaItem = Immutable(props.mediaItem);

    if (thumbnail && imageURLs.length === 0) {
      mediaItem = MediaItem.setImageURLs({
        mediaItem,
        fieldValue: [thumbnail],
      });
    }
    const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
    Compose.editPost({
      editItem: mediaItem,
      itemOrigin: trackingDetails?.Origin || SHARE_ORIGINS.HOME_FEED,
    });
  };

  const handleArticleRestore = () => {
    props.eventHandlers.handleArticleRestore({
      collectionName: props.collectionName,
      mediaId: MediaItem.getMediaId({ mediaItem: props.mediaItem }),
    });
  };

  /**
   * Render methods
   */

  const renderDeleteButton = (mediaId) => {
    const guid = `delete-button-${mediaId}`;
    const mediaType = getMediaItemType(mediaId);

    return (
      <Tooltip id={guid} label="Delete this article">
        <a
          className="ml-auto button_delete mr-1"
          style={
            mediaType === MEDIA_ITEM_TYPES.ARTICLE
              ? {}
              : { visibility: 'hidden' }
          }
          onClick={handleArticleDelete}
          data-cy-action="deleteNewFeed"
        >
          <img
            className="delete"
            src="/img/icons/ic-delete.svg"
            alt="Delete"
            data-tip
            data-for={guid}
            data-iscapture="true"
          />
        </a>
      </Tooltip>
    );
  };

  const renderRestoreButton = () => {
    return (
      <a
        onClick={handleArticleRestore}
        className="ml-auto restore_article link_grey"
        data-cy-action="restore"
      >
        restore article
      </a>
    );
  };

  const mediaItem = props.mediaItem;
  const mediaId = MediaItem.getMediaId({ mediaItem });
  const accountAPIId = props.accountAPIId;
  const propertyId = getCurrentPropertyId();

  const globalInfo = getGlobalInfo();
  const apiTypeId = getAPITypeId({
    accountAPIId,
    globalInfo,
  });

  let articleTitle = MediaItem.getRssTitle({ mediaItem });
  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const errorMessage = MediaItem.getErrorMessage({ mediaItem });
  const isLoading = MediaItem.getIsLoading({ mediaItem });
  const isSaving = MediaItem.getIsSaving({ mediaItem });
  const itemTags = MediaItem.getTags({ mediaItem });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const itemState = MediaItem.getState({ mediaItem });
  const labels = MediaItem.getMediaItemTags({ mediaItem });
  const mediaItemStates = MediaItem.getMediaStatesByAccountAPIId({ mediaItem });
  const mostRecentScore = MediaItem.getMostRecentScore({ mediaItem });
  const mostRecentUnixTimePublished = MediaItem.getMostRecentUnixTimePublished({
    mediaItem,
  });
  const mostRecentUnixTimeShared = MediaItem.getMostRecentUnixTimeShared({
    mediaItem,
  });
  const postType = MediaItem.getPostType({ mediaItem });
  const previewFields = MediaItem.getPreviewFields({
    mediaItem,
  });
  const publishedTime = MediaItem.getMostRecentUnixTimePublished({
    mediaItem,
  });
  const suggestionTypeId = MediaItem.getSuggestionTypeId({ mediaItem });
  const message = MediaItem.getMessage({ mediaItem });

  const isAlreadyQueued =
    !isNull(mediaItemStates) &&
    mediaItemStates[accountAPIId]?.includes(MEDIA_ITEM_STATES.SCHEDULED);

  const twelveHourFormatSetting = getSetting({
    settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
    propertyId,
  });
  const twelveHourFormat = !isNull(twelveHourFormatSetting)
    ? twelveHourFormatSetting.enabled
    : false;

  const isNewVersion =
    !isNull(mostRecentUnixTimePublished) &&
    !isNull(mostRecentUnixTimeShared) &&
    mostRecentUnixTimePublished - mostRecentUnixTimeShared >= 60;

  const isUserManagementEnabled = getFeatureToggle({
    featureName: FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED,
    propertyId,
  });
  const hasUpdateDetails = MediaItem.hasLastUpdateDetails({ mediaItem });

  let articleClass = '';
  if (isSaving) {
    articleClass = 'saving_locked';
  } else if (errorMessage !== '') {
    articleClass = 'error_validation_box';
  }

  if (postType !== POST_TYPES.LINK) {
    articleTitle = previewFields.message;
  }

  if (isLoading) {
    return <Loading mediaId={mediaId} />;
  }
  const imageURLS = previewFields.imageOverlayResult
    ? [previewFields.imageOverlayResult]
    : previewFields.imageURLs;

  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;
  const isReels =
    (apiTypeId === API_TYPE_IDS.INSTAGRAM ||
      apiTypeId === API_TYPE_IDS.FACEBOOK) &&
    socialChannel === SOCIAL_CHANNELS.REEL;

  const isIGStories =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    !isIGCabinetEnabled();

  if (imageURLS && imageURLS.length === 0 && thumbnail) {
    imageURLS.push(thumbnail);
  }

  const itemImages = (
    <ItemImages
      imageURLs={imageURLS}
      itemState={itemState}
      apiTypeId={apiTypeId}
      postType={postType}
      socialChannel={socialChannel}
      suggestionTypeId={suggestionTypeId}
      isNewVersion={isNewVersion}
      isAlreadyQueued={isAlreadyQueued}
      isVideoURL={isVideoURL(articleURL)}
    />
  );

  const itemTitle = (
    <ItemTitle
      articleTitle={articleTitle || message}
      itemState={itemState}
      tags={itemTags}
      accountAPIId={accountAPIId}
    />
  );

  const lastEditedUser = (
    <LastEditedUser
      // Apply tiktok class to keep user left aligned
      className={`mt-1 ${isTikTok && 'tiktok-article'}`}
      lastUser={MediaItem.getLastUpdateUser({ mediaItem })}
      lastEditedTime={getFormattedDateFromUnix({
        timestamp: MediaItem.getLastUpdateUnixTime({ mediaItem }),
        twelveHourFormat,
        textCase: TEXT_CASES.LOWER,
        useLongForm: true,
      })}
      mediaId={mediaId}
    />
  );

  const renderButtons = () => {
    return (
      <Flex
        flexGrow={1}
        alignItems="center"
        opacity={isInstantVideoEnabled(Number(propertyId)) ? 0 : 1}
        _groupHover={{ opacity: 1 }}
        sx={{
          // Show the buttons if any element inside the group is focused
          // We aren't using _groupFocusWithin because we only want to show the buttons
          // when a focus is visible (i.e. not from a mouse click)
          // https://larsmagnus.co/blog/focus-visible-within-the-missing-pseudo-class
          ':has(:focus-visible)': {
            opacity: 1,
          },

          // Show the buttons on touch devices
          '@media (hover: none)': {
            opacity: 1,
          },
        }}
      >
        {itemState !== MEDIA_ITEM_STATES.DELETED && renderDeleteButton(mediaId)}
        {itemState === MEDIA_ITEM_STATES.DELETED && renderRestoreButton()}
        {itemState !== MEDIA_ITEM_STATES.DELETED &&
          isInstantVideoEnabled(Number(propertyId)) &&
          supportsSocialChannel(apiTypeId, SOCIAL_CHANNELS.REEL) && (
            <GenerateVideoButton
              articleURL={articleURL}
              onClick={handleShowInstantVideoModal}
            />
          )}
        <ItemShareButton
          isSaving={isSaving}
          itemState={itemState}
          isNewVersion={isNewVersion}
          eventHandlers={{
            handleArticleEdit,
          }}
        />
      </Flex>
    );
  };

  if (isTikTok || isReels || isIGStories) {
    return (
      <Box
        className={`article ${articleClass}`}
        data-tip
        data-group
        data-for={mediaId}
        data-cy-id="mediaItem"
        data-cy-attribute={`mediaId:${mediaId}`}
        p="2.5"
      >
        <HStack align="stretch" spacing="2">
          {itemImages}
          <Flex
            direction="column"
            flexGrow="1"
            alignItems="baseline"
            align="stretch"
            justifyContent="space-between"
            data-cy-attribute="isLoading:false"
          >
            <VisuallyHidden data-cy-id="guid">{labels[0]?.tag}</VisuallyHidden>
            <Flex w="full">
              <Box lineHeight="18px" fontSize="13px">
                {articleTitle
                  ? itemTitle
                  : !isIGStories && <Box color="gray.500">No caption</Box>}
              </Box>
              <Box ml="auto">
                <HStack>{renderButtons()}</HStack>
              </Box>
            </Flex>
            {isUserManagementEnabled && hasUpdateDetails && lastEditedUser}
          </Flex>
        </HStack>
        {errorMessage !== '' && <ItemError errorMessage={errorMessage} />}
      </Box>
    );
  }

  return (
    <div
      className={`article ${articleClass}`}
      data-tip
      data-group
      data-for={mediaId}
      data-cy-id="mediaItem"
      data-cy-attribute={`mediaId:${mediaId}`}
    >
      {itemImages}
      <div className="article_content" data-cy-attribute="isLoading:false">
        <div className="d-none" data-cy-id="guid">
          {labels[0]?.tag}
        </div>
        <div className="top d-flex align-items-center">
          {postType === POST_TYPES.LINK && (
            <ItemScore
              itemState={itemState}
              mediaId={mediaId}
              mostRecentScore={mostRecentScore}
            />
          )}
          {getFeatureToggle({
            featureName: FEATURE_TOGGLES.MANUAL_IMAGE_FROM_ARTICLE_ENABLED,
            propertyId,
          }) && apiTypeId === API_TYPE_IDS.FACEBOOK ? (
            <InstantShareButton
              articleURL={articleURL}
              isSaving={isSaving}
              isDeleted={itemState === MEDIA_ITEM_STATES.DELETED}
              onShareClick={handleArticleEdit}
              onImageClick={handleOpenInstantImage}
              onReelsClick={
                isInstantVideoEnabled(propertyId) &&
                supportsSocialChannel(apiTypeId, SOCIAL_CHANNELS.REEL) // Always true for FB, but keeping the check for future-proofing
                  ? handleShowInstantVideoModal
                  : undefined
              }
              onDeleteClick={handleArticleDelete}
              onRestoreClick={handleArticleRestore}
            />
          ) : (
            renderButtons()
          )}
        </div>
        <div className="bottom">
          {itemTitle}
          {postType === POST_TYPES.LINK && (
            <ItemPublished
              articleURL={articleURL}
              publishedTime={publishedTime}
              twelveHourFormat={twelveHourFormat}
              isNewVersion={isNewVersion}
            />
          )}
          {isUserManagementEnabled && hasUpdateDetails && lastEditedUser}
        </div>
      </div>
      {errorMessage !== '' && <ItemError errorMessage={errorMessage} />}
    </div>
  );
};

Item.propTypes = {
  mediaItem: PropTypes.object.isRequired,
  accountAPIId: PropTypes.number.isRequired,
  collectionName: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
    handleArticleLoad: PropTypes.func.isRequired,
    handleArticleRestore: PropTypes.func.isRequired,
    handleShowInstantVideoModal: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(Item, arePropsEqual);
