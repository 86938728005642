import * as MediaItem from 'common/mediaItem';
import { cloneObject } from 'common/utility';
import type { APITypeId, FixTypeLater, PostType, SocialChannel } from 'types';

import {
  ANALYTICS_INSIGHTS,
  API_PROPERTIES,
  API_TYPE_IDS,
  FILE_TYPE_ACCEPTS,
  POST_TYPES,
  SOCIAL_CHANNELS,
  TRACKING_SOCIAL_CHANNELS,
} from './constants';
import { isIGCabinetEnabled } from './settings';

/**
 * Only for use within this file. External files should use getAllowedFileTypes
 */
function getAllowedFileTypesFromAPIProperties({
  apiTypeId,
}: {
  apiTypeId: APITypeId;
}) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  if ('allowedFileTypes' in apiProperties) {
    return apiProperties.allowedFileTypes;
  }

  return undefined;
}

/**
 * Gets the allowed file types for a given API type and post type
 */
export function getAllowedFileTypes({
  apiTypeId,
  postType,
  socialChannel,
  isVideoUploaded = false,
}: {
  apiTypeId: APITypeId;
  postType: PostType;
  socialChannel?: SocialChannel;
  isVideoUploaded?: boolean;
}) {
  let fileTypes: Record<string, ReadonlyArray<string>> = postType ===
  POST_TYPES.VIDEO
    ? FILE_TYPE_ACCEPTS.VIDEO
    : FILE_TYPE_ACCEPTS.IMAGE;

  let videoThumbnailFileTypes = {};

  // If we have an override, it trumps the default file types
  const allowedFileTypesByPostType = getAllowedFileTypesFromAPIProperties({
    apiTypeId,
  });
  const override =
    allowedFileTypesByPostType?.[
      postType as keyof typeof allowedFileTypesByPostType
    ];
  if (override) {
    fileTypes = override;
  }

  // Condition for properties using the Instagram Cabinet.
  // TODO: remove once the Instagram Cabinet is fully deprecated.
  if (apiTypeId === API_TYPE_IDS.INSTAGRAM && isIGCabinetEnabled()) {
    if (postType === POST_TYPES.VIDEO) {
      fileTypes = FILE_TYPE_ACCEPTS.VIDEO;
    } else {
      fileTypes = FILE_TYPE_ACCEPTS.IMAGE;
    }
  }

  // If we have an uploaded video, we can extend the file types to support uploading thumbnail images
  if (postType === POST_TYPES.VIDEO && isVideoUploaded) {
    const apiProperties = API_PROPERTIES[apiTypeId];
    const canAddVideoThumbnail =
      'canAddVideoThumbnail' in apiProperties
        ? apiProperties.canAddVideoThumbnail
        : undefined;

    if (
      (typeof canAddVideoThumbnail === 'boolean' && canAddVideoThumbnail) ||
      (socialChannel &&
        typeof canAddVideoThumbnail === 'object' &&
        canAddVideoThumbnail[
          socialChannel as keyof typeof canAddVideoThumbnail
        ])
    ) {
      videoThumbnailFileTypes =
        allowedFileTypesByPostType?.[POST_TYPES.STATUS] ??
        FILE_TYPE_ACCEPTS.IMAGE;
      return videoThumbnailFileTypes;
    }
  }

  // We need the returned values to be mutable so we clone them
  // to make sure we don't mutate the original object

  fileTypes = {
    ...fileTypes,
    ...videoThumbnailFileTypes,
  };
  return cloneObject(fileTypes) as Record<string, Array<string>>;
}

export function getAPIType({ apiTypeId }: { apiTypeId: APITypeId }) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  if ('apiType' in apiProperties) {
    return apiProperties.apiType;
  }

  return undefined;
}

/**
 * Get the autofeed defaults for the given API type
 */
export function getAutofeedDefaults({ apiTypeId }: { apiTypeId: APITypeId }) {
  return API_PROPERTIES[apiTypeId].autofeedDefaults ?? {};
}

/**
 * Returns the last shared label for the given post type and social channel
 */
export function getLastSharedLabel({
  apiTypeId,
  postType,
  socialChannel,
}: {
  apiTypeId: APITypeId;
  postType: PostType;
  socialChannel?: SocialChannel;
}) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  const lastSharedLabel =
    apiProperties.lastSharedLabel && postType in apiProperties.lastSharedLabel
      ? apiProperties.lastSharedLabel?.[
          postType as keyof typeof apiProperties.lastSharedLabel
        ]
      : undefined;

  if (socialChannel && typeof lastSharedLabel === 'object') {
    const socialChannelLabel = lastSharedLabel[socialChannel];
    if (socialChannelLabel) {
      return socialChannelLabel;
    }
  } else if (lastSharedLabel) {
    return lastSharedLabel;
  }

  return '';
}

/**
 * Generates the home page URL for the given social page
 */
export function getSocialPageURL({
  apiTypeId,
  apiAltName,
}: {
  apiTypeId: APITypeId;
  apiAltName: string;
}) {
  const apiTypeProps = API_PROPERTIES[apiTypeId];
  if (apiTypeProps.pageURLTemplate) {
    return apiTypeProps.pageURLTemplate.replace('PAGENAME', apiAltName);
  }
  return '';
}

export function getSupportedSocialChannels({
  apiTypeId,
}: {
  apiTypeId: APITypeId;
}) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  if ('supportedSocialChannels' in apiProperties) {
    return apiProperties.supportedSocialChannels;
  }

  return undefined;
}

/**
 * Returns the socialChannel name for the given post type
 */
export function getTrackingSocialChannel({
  apiTypeId,
  mediaItem,
}: {
  apiTypeId: APITypeId;
  mediaItem: FixTypeLater;
}) {
  const socialChannel =
    MediaItem.getSocialChannel({ mediaItem }) ??
    getSupportedSocialChannels({ apiTypeId })?.[0] ??
    SOCIAL_CHANNELS.FEED;
  return TRACKING_SOCIAL_CHANNELS[socialChannel];
}

export function getURNName({ apiTypeId }: { apiTypeId: APITypeId }) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  if ('urnName' in apiProperties) {
    return apiProperties.urnName;
  }

  return undefined;
}

export function appendSpaceAfterTagInsertion({
  apiTypeId,
}: {
  apiTypeId: APITypeId;
}) {
  const typeId = API_PROPERTIES[apiTypeId];
  return !!typeId?.appendSpace;
}

export function canAddThumbnail(apiTypeId: APITypeId) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  const canAddVideoThumbnail =
    'canAddVideoThumbnail' in apiProperties
      ? apiProperties.canAddVideoThumbnail
      : undefined;
  return canAddVideoThumbnail;
}

export function canEditMetrics(apiTypeId: APITypeId) {
  const apiProperties = API_PROPERTIES[apiTypeId];
  return 'canEditMetrics' in apiProperties
    ? apiProperties.canEditMetrics
    : true;
}

export function supportsSocialChannel(
  apiTypeId: APITypeId,
  socialChannel: SocialChannel,
) {
  const supportedSocialChannels = getSupportedSocialChannels({
    apiTypeId,
  }) as SocialChannel[] | undefined;
  if (!supportedSocialChannels) return false;
  return supportedSocialChannels?.includes(socialChannel);
}

export function getPlaceholderText({
  apiTypeId,
  postType,
  socialChannel,
}: {
  apiTypeId: APITypeId;
  postType: PostType;
  socialChannel?: SocialChannel;
}) {
  const apiProperties = API_PROPERTIES[apiTypeId];

  if (apiProperties.placeholderText) {
    const postTypeText = apiProperties.placeholderText[postType];

    if (postTypeText && Array.isArray(postTypeText)) {
      return postTypeText;
    }

    if (socialChannel) {
      return postTypeText?.[socialChannel] ?? [];
    }
  }

  return [];
}

export function getSocialAnalyticsInsights(apiTypeId: APITypeId) {
  return ANALYTICS_INSIGHTS[apiTypeId];
}
