import {
  Box,
  Button,
  Center,
  Checkbox,
  Circle,
  Flex,
  Image,
  Link,
  Modal,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import getAPIsConnectSelect from 'api/getAPIsConnectSelect';
import { isCurrentPropertySuspended } from 'common/accountAPIs';
import { API_PROPERTIES, API_TYPE_IDS } from 'common/constants';
import { SAVE_STATES } from 'common/enums';
import { isIGCabinetEnabled } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import { ebxMessage } from 'common/string';
import ValidationError from 'components/misc/ValidationError';
import InstagramConnectionErrorModal from 'components/settings/pages/InstagramConnectionErrorModal';
// Requires @typescript-eslint but can't be installed yet
// We first need to update react-scripts
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { APITypeId, FixTypeLater } from 'types';

interface Page {
  id: string;
  name: string;
  profilePictureURL: string;
  link: string;
  apiType: string;
}

const getPageLinkDisplay = (link: string, apiType: string) => {
  const url = new URL(link);
  switch (apiType) {
    case 'LINKEDIN': {
      return `/${url.pathname.split('/')[2]}`;
    }
    default: {
      return `@${url.pathname.substring(1)}`;
    }
  }
};

function isValidAPITypeId(value: number): value is APITypeId {
  return Object.values(API_TYPE_IDS).includes(value as APITypeId);
}

interface APISelectorProps {
  apiTypeId: string | number | null;
  errorMessage: string;
  onSubmit: (
    selected: ReadonlyArray<{ id: string; name: string }>,
    onClose: () => void,
  ) => void;
  requestToken: string;
  saveState: SAVE_STATES;
}

const APISelector = ({
  apiTypeId,
  errorMessage,
  onSubmit,
  requestToken,
  saveState,
}: APISelectorProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiErrorMessage, setAPIErrorMessage] = useState<string | null>(null);
  const [showIGErrorModal, setShowIGErrorModal] = useState(false);
  const [pages, setPages] = useState<ReadonlyArray<Page>>([]);
  const [selected, setSelected] = useState<
    ReadonlyArray<{ id: string; name: string }>
  >([]);
  const location = useLocation();
  const navigate = useNavigate();

  const suspendedClass = isCurrentPropertySuspended()
    ? 'account_suspended'
    : '';

  const handleClose = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('requestToken')) {
      queryParams.delete('requestToken');
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  }, [location.search, location.pathname, navigate]);

  useEffect(() => {
    const getPages = async () => {
      try {
        const response = (await getAPIsConnectSelect({
          requestToken,
        })) as { data?: ReadonlyArray<Page> } | null;

        if (response === null || !response.data) {
          setIsLoading(false);
          if (
            Number(apiTypeId) === API_TYPE_IDS.INSTAGRAM &&
            !isIGCabinetEnabled()
          ) {
            setShowIGErrorModal(true);
          } else {
            setAPIErrorMessage(
              ebxMessage(
                'This email address has no associated accounts. Please select another.',
              ),
            );
            window.setTimeout(handleClose, 2500);
          }
        } else {
          /*
          The response could contain a mix of api types (e.g Facebook and Instagram)
          So we want to filter results by the specified apiType (which should have been set in session storage before the redirect)
          If it isn't provided, we will use the first result to dictate the apiType.
          */
          const numberAPITypeId = Number(apiTypeId);
          const apiType =
            apiTypeId !== null && isValidAPITypeId(numberAPITypeId)
              ? API_PROPERTIES[numberAPITypeId].apiType
              : response.data[0].apiType;
          setPages(response.data.filter((x) => apiType === x.apiType));
        }
        setIsLoading(false);
      } catch (error: FixTypeLater) {
        if (
          error.error?.message &&
          error.error.message.includes('Cannot find any properties or views.')
        ) {
          setIsLoading(false);
          setAPIErrorMessage(
            ebxMessage(
              'Cannot find any properties or views associated to the selected analytics page.',
            ),
          );
        } else {
          setIsLoading(false);
          setAPIErrorMessage(
            ebxMessage(
              'Unfortunately there was an error. Please retry or reload the page.',
            ),
          );
        }
      }
    };

    getPages();
  }, [apiTypeId, handleClose, requestToken]);

  const handleChangeSelectAPI = (pageId: string, pageName: string) => {
    return () => {
      setSelected((prevState) => {
        const index = prevState.findIndex((item) => item.id === pageId);
        if (index !== -1) {
          // Remove existing item
          return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
        }

        // Add new item
        return [...prevState, { id: pageId, name: pageName }];
      });
    };
  };

  const handleSelectAll = () => {
    if (pages) {
      setSelected(
        pages.map(({ id, name }) => ({
          id,
          name,
        })),
      );
    }
  };

  const handleSubmit = () => {
    onSubmit(selected, handleClose);
  };

  if (isLoading) {
    return (
      <Modal isOpen onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Select pages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Center>
            <Spinner size="lg" />
          </Center>
        </Modal.Body>
      </Modal>
    );
  }

  if (showIGErrorModal) {
    return (
      <InstagramConnectionErrorModal
        isOpen
        onClose={() => {
          setShowIGErrorModal(false);
          handleClose();
        }}
        onTryAgain={() => {
          setShowIGErrorModal(false);
          navigate(`${location.pathname}?addNewPage=instagram`, {
            replace: true,
          });
        }}
      />
    );
  }

  if (apiErrorMessage) {
    return (
      <Modal isOpen onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Select pages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text color="red.600" size="sm">
            {apiErrorMessage}
          </Text>
        </Modal.Body>
      </Modal>
    );
  }

  if (pages.length === 0) {
    return (
      <Modal isOpen onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Select pages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={suspendedClass}>
            <span className="bold green_text">No pages found</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-dismiss="modal"
            data-cy-action="closePagePopup"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // Determine the Social Network name with the apiTypeId provided, or the first page
  const apiTypeName = getSocialNetworkName({
    apiTypeId:
      apiTypeId ??
      Object.entries(API_PROPERTIES).find(
        ([, properties]) => properties.apiType === pages[0].apiType,
      )?.[0] ??
      API_TYPE_IDS.FACEBOOK,
  });

  const savingClass = saveState === SAVE_STATES.SAVING ? 'saving_locked' : '';

  const pagesOrAccountsText =
    Number(apiTypeId) === API_TYPE_IDS.INSTAGRAM ? 'accounts' : 'pages';

  return (
    <Modal isOpen onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>
          Select {apiTypeName} {pagesOrAccountsText} to add to Echobox
        </Modal.Title>
      </Modal.Header>
      <Modal.Body overflowY="auto" maxHeight="96">
        <div className={suspendedClass}>
          <Flex direction="column" alignItems="flex-start">
            <ValidationError errorMessage={errorMessage} />
            <Text size="sm">
              Select the {apiTypeName} {pagesOrAccountsText} you&apos;d like to
              use in Echobox.
            </Text>
            <Button
              variant="link"
              onClick={handleSelectAll}
              mt={4}
              mb={3}
              lineHeight="140%"
            >
              Select all
            </Button>
            <Flex gap={4} flexDir="column">
              {pages.map((page) => (
                <Box
                  key={page.id}
                  display="flex"
                  alignItems="center"
                  className={`option ${savingClass}`}
                >
                  <Checkbox
                    mr={2}
                    name="pages"
                    value={page.name}
                    id={page.id}
                    isChecked={
                      selected.findIndex((item) => item.id === page.id) !== -1
                    }
                    onChange={handleChangeSelectAPI(page.id, page.name)}
                  />
                  <Box
                    as="label"
                    htmlFor={page.id}
                    display="inline-flex"
                    alignItems="center"
                    gap={2}
                    cursor="pointer"
                  >
                    <Circle
                      size={6}
                      overflow="hidden"
                      border="1px"
                      borderColor="gray.300"
                    >
                      <Image
                        w="full"
                        h="full"
                        src={page.profilePictureURL}
                        alt=""
                      />
                    </Circle>
                    <Text
                      as="span"
                      fontWeight="medium"
                      size="sm"
                      lineHeight="140%"
                    >
                      {page.name}
                    </Text>
                  </Box>
                  <Link
                    href={page.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    color="gray.600"
                    fontWeight="normal"
                    ml={2}
                    _hover={{ color: 'primary.600' }}
                  >
                    {getPageLinkDisplay(page.link, page.apiType)}
                  </Link>
                </Box>
              ))}
            </Flex>
          </Flex>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          data-dismiss="modal"
          data-cy-action="closePagePopup"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          className={savingClass}
          data-dismiss="modal"
          data-cy-action="closePagePopup"
          onClick={handleSubmit}
          isDisabled={selected.length === 0 || saveState === SAVE_STATES.SAVING}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default APISelector;
