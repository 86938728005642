import { create } from 'zustand';

interface ComposeBoxSharesState {
  totalShares: number;
  failedShares: Array<number>;
  setTotalShares: (totalShares: number) => void;
  setFailedShares: (failedShares: Array<number>) => void;
}

const useComposeBoxSharesStore = create<ComposeBoxSharesState>((set) => ({
  totalShares: 1,
  failedShares: [],
  setTotalShares: (totalShares: number) => set({ totalShares }),
  setFailedShares: (failedShares: Array<number>) => set({ failedShares }),
}));

export { useComposeBoxSharesStore };
