/**
 * isEventInElementByClass
 *
 * Checks id the targetClass in in the element hierarchy of the event
 */

export function isEventInElementByClass({
  event,
  targetClass,
}: {
  event: Event;
  targetClass: string;
}) {
  // Use path if it exists (won't in some browsers)
  if (event.composedPath && event.composedPath() instanceof Array) {
    return (
      event
        .composedPath()
        .map((t) => (t as Element).className)
        .indexOf(targetClass) !== -1
    );
  }

  // Otherwise recursively look up the element hierarchy
  let node = event.target as Element;
  while (node) {
    if (node.className === targetClass) {
      return true;
    }
    node = node.parentNode as Element;
  }
  return false;
}
