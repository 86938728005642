import * as React from 'react';

import getVideoThumbnail from 'api/getVideoThumbnail';

/**
 * A custom hook for extracting thumbnail for a video file
 */
const useThumbnail = ({
  videoURL,
  shouldFetch = true,
}: {
  videoURL: string;
  shouldFetch?: boolean;
}) => {
  const [thumbnail, setThumbnail] = React.useState<string | null>(null);

  React.useEffect(() => {
    setThumbnail(null);

    async function getThumbnail() {
      if (videoURL && shouldFetch) {
        const response = await getVideoThumbnail({ videoURL });
        if (response) setThumbnail(response);
      }
    }
    getThumbnail();
  }, [videoURL, shouldFetch]);

  return thumbnail;
};

export default useThumbnail;
