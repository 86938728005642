/* eslint consistent-return:"off" */
/* eslint no-use-before-define:"off" */

import PubSub from 'pubsub-js';

import {
  ACCOUNT_SETTING_TYPES,
  API_PROPERTIES,
  API_STATES,
  API_TYPE_IDS,
  AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES,
  FLASH_MESSAGE_TYPES,
  MEDIA_ITEM_STATES,
  PERMISSION_TYPES,
  POST_TYPES,
  ACCOUNT_STATES as PROPERTY_STATES,
  TRAFFIC_API_TYPES,
} from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { isIGCabinetEnabled } from 'common/settings';
import {
  getAccountDisconnectedError,
  getSocialNetworkIcon,
  getSocialNetworkName,
  getSocialNetworks,
  hasPostType,
  isSocialNetwork,
} from 'common/social';
import { convertToSocialPageURN } from 'common/urn';
import {
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import { isBlockedError } from 'helpers/flashMessages';
import * as topics from 'pubsub/topics';

const {
  COMMAND_FLASH_MESSAGES_ADD_MESSAGE,
  COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE,
} = topics;

// Re-export all methods from accountAPIsV2
// This is to allow for a gradual migration to a TypeScript version of this file
export * from './accountAPIsV2';
/**
 * List of exported methods
 */
export {
  getAPIAltName,
  getAPIIdsByType,
  getAPIPostName,
  getAPIPropertyMap,
  getAPITypeId,
  getAPIsByNetwork,
  getAPIsByStateAndType,
  getAPIsByType,
  getAPIsWithPermissions,
  getAccountAPI,
  getAccountAPIMessageSource,
  getAccountAPIPermission,
  getActiveAPIsForProperty,
  getAllAccountAPIIds,
  getAllPropertyIds,
  getAutomationSettingsAPIs,
  getBitlyAPIs,
  getCurrentAPITypeId,
  getCurrentAccountAPI,
  getCurrentAccountAPIId,
  getCurrentProperty,
  getCurrentPropertyBilling,
  getCurrentPropertyId,
  getCurrentPropertyOrderForms,
  getCurrentSocialPageURN,
  getEnabledTwitterApp,
  getFacebookAPIs,
  getFilteredPropertiesAndPages,
  getGACoreAPIs,
  getInstagramAPIs,
  getLinkedInAPIs,
  getOptinalImageOverlay,
  getOrderForms,
  getOrderedPagesForProperty,
  getOrderedPropertiesAndPages,
  getProperty,
  getPropertyAndOrderedPages,
  getPropertyBilling,
  getPropertyIdForAccountAPIId,
  getPropertyName,
  getPropertyPermission,
  getSocialAPIIds,
  getSocialAPIs,
  getSocialAPIsByTypeAndState,
  getTikTokAPIs,
  getTrafficAPIParts,
  getTwitterAPIs,
  getUser,
  getUserAPIIds,
  getUserAccountAPIPermission,
  getUserPagePermissions,
  getUserPropertiesAndAPIs,
  getUserPropertyPermission,
  hasSocialAPIInState,
  isCurrentAccountAPIPaused,
  isCurrentPropertySuspended,
  isPropertySuspended,
  raiseAPIErrors,
  socialNetworkAPIIds,
  sortPages,
};

/**
 * addFlashMessages
 */

function addFlashMessages({ messages = mandatory('messages') } = {}) {
  messages.forEach((message) => {
    const isBlocked = isBlockedError(message);
    if (!isBlocked) {
      logger.info(
        `accountAPIs:addFlashMessage ${message.type} ${message.text}`,
      );
      logger.info(
        `PubSub: publish ${COMMAND_FLASH_MESSAGES_ADD_MESSAGE} in common/accountAPIs.addFlashMessages`,
      );
      PubSub.publish(COMMAND_FLASH_MESSAGES_ADD_MESSAGE, message);
    }
  });
}

/**
 * getAccountAPI
 * @param {{
 *   accountAPIId: number;
 *   globalInfo?: object;
 * }} args
 * @returns {import('types').GlobalInfo.SocialAPI | null}
 */
function getAccountAPI({
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    let accountAPI = null;
    Object.keys(globalInfo.properties).forEach((propertyId) => {
      if (
        isNull(accountAPI) &&
        globalInfo?.properties?.[propertyId]?.accountAPIs?.[accountAPIId]
      ) {
        accountAPI =
          globalInfo.properties[propertyId].accountAPIs[accountAPIId];
      }
    });
    return accountAPI;
  } catch (e) {
    return null;
  }
}

/**
 * @param {{
 *   accountAPIId: number;
 *   globalInfo?: {import('types').GlobalInfo.GlobalInfo};
 * }} args
 * getAccountAPIMessageSource
 * @returns {string}
 */

function getAccountAPIMessageSource({
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    const accountAPI = getAccountAPI({ accountAPIId, globalInfo });
    return accountAPI.accountSettings[
      ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES
    ][0]?.dataJSON.messageSource;
  } catch (e) {
    return null;
  }
}

/**
 * getAccountAPIPermission
 * @returns {import('types').PermissionTypeId | null}
 */

function getAccountAPIPermission({
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    let permissionTypeId;
    // Attempt to find account-level permission
    globalInfo.user.permissions.forEach((permission) => {
      if (
        isDefined(permission.accountAPIId) &&
        permission.accountAPIId.toString() === accountAPIId.toString()
      ) {
        permissionTypeId = permission.permissionTypeId;
      }
    });
    if (isDefined(permissionTypeId)) {
      return permissionTypeId;
    }
    // Attempt to find property-level permission
    const propertyId = getPropertyIdForAccountAPIId({
      accountAPIId,
      globalInfo,
    });
    globalInfo.user.permissions.forEach((permission) => {
      if (
        isDefined(permission.propertyId) &&
        isUndefined(permission.accountAPIId) &&
        permission.propertyId.toString() === propertyId.toString() &&
        permission.permissionTypeId === PERMISSION_TYPES.ADMIN
      ) {
        permissionTypeId = permission.permissionTypeId;
      }
    });
    if (isDefined(permissionTypeId)) {
      return permissionTypeId;
    }

    return PERMISSION_TYPES.NONE;
  } catch (e) {
    return null;
  }
}

/**
 * getActiveAPIsForProperty
 * @param {{
 *  propertyId: string;
 *  allowUnderSetup?: boolean;
 *  globalInfo?: import('types').FixTypeLater;
 * }}
 */

function getActiveAPIsForProperty({
  propertyId = mandatory('propertyId'),
  allowUnderSetup = false,
  globalInfo = getGlobalInfo(),
} = {}) {
  // Exit if the specified property does not exist
  if (isUndefined(globalInfo.properties[propertyId])) {
    return [];
  }
  // Exit if the specified property is not active
  const property = globalInfo.properties[propertyId];
  if (
    property.propertyStateId !== PROPERTY_STATES.ACTIVE &&
    property.propertyStateId !== PROPERTY_STATES.UNDERSETUP &&
    property.propertyStateId !== PROPERTY_STATES.NEWSIGNUP
  ) {
    return [];
  }

  // Loop over the property's APIs and return any active social networks
  const activeAPIs = [];
  if (property?.accountAPIs) {
    Object.keys(property.accountAPIs).forEach((accountAPIId) => {
      const accountAPI = property.accountAPIs[accountAPIId];
      if (
        (accountAPI.apiStateId === API_STATES.ACTIVE ||
          (allowUnderSetup &&
            accountAPI.apiStateId === API_STATES.UNDER_SETUP)) &&
        isSocialNetwork({ apiTypeId: accountAPI.apiTypeId })
      ) {
        activeAPIs.push(accountAPI);
      }
    });
  }
  return activeAPIs;
}

/**
 * getAllAccountAPIIds
 */

function getAllAccountAPIIds({
  propertyList,
  globalInfo = getGlobalInfo(),
} = {}) {
  let propList;

  if (isDefined(propertyList)) {
    propList = propertyList;
  } else {
    propList = globalInfo.properties;
  }

  let apiIds = [];

  try {
    Object.keys(propList).forEach((propertyId) => {
      if (propList?.[propertyId]?.accountAPIs) {
        apiIds = apiIds.concat(Object.keys(propList[propertyId].accountAPIs));
      }
    });
  } catch (e) {
    // Oops
  }

  return apiIds;
}

/**
 * getAllPropertyIds
 */

function getAllPropertyIds({ globalInfo = getGlobalInfo() } = {}) {
  try {
    return Object.keys(globalInfo.properties);
  } catch (e) {
    return null;
  }
}

/**
 * @param {{
 *  accountAPIId: number;
 *  globalInfo?: import('types').GlobalInfo.GlobalInfo;
 * }}
 */

function getAPIAltName({
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  // Return a "cached" response if one exists
  if (window?.apiAltNames?.[accountAPIId]) {
    return window.apiAltNames[accountAPIId];
  }
  // Otherwise retrieve the result from global info
  let apiAltName = null;
  Object.keys(globalInfo.properties).forEach((propertyId) => {
    if (
      isNull(apiAltName) &&
      globalInfo?.properties?.[propertyId]?.accountAPIs?.[accountAPIId]
    ) {
      apiAltName =
        globalInfo.properties[propertyId].accountAPIs[accountAPIId].apiAltName;
    }
  });
  if (!window.apiAltNames) {
    window.apiAltNames = {};
  }
  // Cache the result before returning it
  window.apiAltNames[accountAPIId] = apiAltName;
  return apiAltName;
}

/**
 * getAPIPostName
 * @param {{ accountAPIId: number }}
 */

function getAPIPostName({ accountAPIId = mandatory('accountAPIId') }) {
  const accountAPI = getAccountAPI({ accountAPIId });
  return accountAPI?.apiPostName ?? null;
}

/**
 * getAPIPropertyMap
 *
 * @param {{
 *  propertiesAndAPIs: Record<string, Partial<import('types').GlobalInfo.Property>>;
 *  allowUnderSetup: boolean;
 * }}
 * @returns {Record<string, string>}
 */

function getAPIPropertyMap({
  propertiesAndAPIs = mandatory('propertiesAndAPIs'),
  allowUnderSetup = mandatory('allowUnderSetup'),
} = {}) {
  const apiPropertyMap = {};
  Object.keys(propertiesAndAPIs).forEach((propertyId) => {
    const property = propertiesAndAPIs[propertyId];
    if (
      property.propertyStateId === PROPERTY_STATES.ACTIVE ||
      property.propertyStateId === PROPERTY_STATES.UNDERSETUP ||
      property.propertyStateId === PROPERTY_STATES.NEWSIGNUP
    ) {
      if (isDefined(property.accountAPIs)) {
        Object.keys(property.accountAPIs).forEach((accountAPIId) => {
          const accountAPI =
            propertiesAndAPIs[propertyId].accountAPIs[accountAPIId];
          if (
            (accountAPI.apiStateId === API_STATES.ACTIVE ||
              (allowUnderSetup &&
                accountAPI.apiStateId === API_STATES.UNDER_SETUP)) &&
            isSocialNetwork({ apiTypeId: accountAPI.apiTypeId })
          ) {
            apiPropertyMap[accountAPIId] = propertyId;
          }
        });
      }
    }
  });
  return apiPropertyMap;
}

/**
 * getAPIIdsByType
 */

function getAPIIdsByType({
  accountAPIs = mandatory('accountAPIs'),
  apiTypeId = mandatory('apiTypeId'),
  activeOnly = false,
} = {}) {
  const matchingAPIs = [];
  if (!isNullOrUndefined(accountAPIs)) {
    Object.keys(accountAPIs).forEach((accountAPI) => {
      if (
        accountAPIs[accountAPI].apiTypeId === apiTypeId &&
        (!activeOnly ||
          accountAPIs[accountAPI].apiStateId === API_STATES.ACTIVE)
      ) {
        matchingAPIs.push(accountAPIs[accountAPI].accountAPIId);
      }
    });
  }
  return matchingAPIs;
}

/**
 * getAPIsByStateAndType
 * @param {{
 *  apiStateId: number[]
 *  propertyId: number;
 *  globalInfo: any;
 *  apiTypeId?: any[];
 * }}
 */

function getAPIsByStateAndType({
  propertyId = mandatory('propertyId'),
  apiStateId = mandatory('apiStateId'),
  apiTypeId = getSocialNetworks(),
  globalInfo = getGlobalInfo(),
} = {}) {
  // Exit if the specified property does not exist
  if (isUndefined(globalInfo.properties[propertyId])) {
    return [];
  }
  // Exit if the specified property is not active
  const property = globalInfo.properties[propertyId];
  if (property.propertyStateId !== PROPERTY_STATES.ACTIVE) {
    return [];
  }

  // Convert both parameters to arrays
  const apiStateIds = Array.isArray(apiStateId) ? apiStateId : [apiStateId];
  const apiTypeIds = Array.isArray(apiTypeId) ? apiTypeId : [apiTypeId];

  // Loop over the property's APIs and return any matching the specified state and type
  const matchingAPIs = [];
  if (property?.accountAPIs) {
    Object.keys(property.accountAPIs).forEach((accountAPIId) => {
      const accountAPI = property.accountAPIs[accountAPIId];
      if (
        apiStateIds.indexOf(accountAPI.apiStateId) !== -1 &&
        apiTypeIds.indexOf(accountAPI.apiTypeId) !== -1
      ) {
        matchingAPIs.push(accountAPI);
      }
    });
  }
  return matchingAPIs;
}

/**
 * getAPIsByNetwork
 * @returns {Record<number, Array<import('types').SocialAPI>>}
 */
function getAPIsByNetwork({
  allowUnderSetup = true,
  globalInfo = getGlobalInfo(),
} = {}) {
  // Exit if the current property is not specified
  if (
    isUndefined(globalInfo.current) ||
    isUndefined(globalInfo.current.propertyId)
  ) {
    return {};
  }
  const propertyId = globalInfo.current.propertyId;
  // Exit if the specified property does not exist
  if (isUndefined(globalInfo.properties[propertyId])) {
    return {};
  }
  // Exit if the specified property is not active
  const property = globalInfo.properties[propertyId];
  if (property.propertyStateId !== PROPERTY_STATES.ACTIVE) {
    return {};
  }

  // Loop over the property's APIs and return any active social networks
  const activeAPIs = {};
  if (property?.accountAPIs) {
    Object.keys(property.accountAPIs).forEach((accountAPIId) => {
      const accountAPI = property.accountAPIs[accountAPIId];
      const apiPermission = getAccountAPIPermission({
        accountAPIId,
        globalInfo,
      });
      if (apiPermission > PERMISSION_TYPES.VIEW_ONLY) {
        const apiTypeId = accountAPI.apiTypeId;
        if (
          (accountAPI.apiStateId === API_STATES.ACTIVE ||
            accountAPI.apiStateId === API_STATES.BAD_AUTH ||
            (allowUnderSetup &&
              accountAPI.apiStateId === API_STATES.UNDER_SETUP)) &&
          isSocialNetwork({ apiTypeId })
        ) {
          if (isUndefined(activeAPIs[apiTypeId])) {
            activeAPIs[apiTypeId] = [];
          }
          activeAPIs[apiTypeId].push(accountAPI);
        }
      }
    });
  }
  return activeAPIs;
}

/**
 * Filters the provided APIs by the API_TYPE provided.
 * @param {{
 *  accountAPIs: import('types').SocialAPI[];
 *  apiTypeId: import('types').APITypeId;
 *  activeOnly?: boolean;
 * }}
 */

function getAPIsByType({
  accountAPIs = mandatory('accountAPIs'),
  apiTypeId = mandatory('apiTypeId'),
  activeOnly = false,
} = {}) {
  const matchingAPIs = [];
  if (!isNullOrUndefined(accountAPIs)) {
    Object.keys(accountAPIs).forEach((accountAPI) => {
      if (
        accountAPIs[accountAPI].apiTypeId === apiTypeId &&
        (!activeOnly ||
          accountAPIs[accountAPI].apiStateId === API_STATES.ACTIVE)
      ) {
        matchingAPIs.push(accountAPIs[accountAPI]);
      }
    });
  }
  return matchingAPIs;
}

/**
 * getAPIsWithPermissions
 *
 * Returns a list of account API ids belonging to the current property
 * for which the current user has EDITOR level access or above
 */

function getAPIsWithPermissions({
  allowUnderSetup = false,
  globalInfo = getGlobalInfo(),
} = {}) {
  const apisWithPermissions = [];

  Object.keys(globalInfo.properties).forEach((propertyId) => {
    // Get list of active account APIs for the current property
    const apisForProperty = getActiveAPIsForProperty({
      propertyId,
      allowUnderSetup,
      globalInfo,
    });
    // Filter this list to find APIs for which the user has at least EDITOR permissions
    const apisWithPermissionsForProperty = apisForProperty
      .map((api) => api.accountAPIId)
      .filter(
        (api) =>
          getAccountAPIPermission({
            accountAPIId: api,
            globalInfo,
          }) > PERMISSION_TYPES.VIEW_ONLY,
      );
    Array.prototype.push.apply(
      apisWithPermissions,
      apisWithPermissionsForProperty,
    );
  });

  // Done
  return apisWithPermissions;
}

/**
 * getAPITypeId
 * @param {{
 *   accountAPIId: number;
 *   globalInfo?: object;
 * }} args
 * @returns {import('types').APITypeId}
 */

function getAPITypeId({
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  // Return a "cached" response if one exists
  if (window && window.apiTypeIds && window.apiTypeIds[accountAPIId]) {
    return window.apiTypeIds[accountAPIId];
  }
  // Otherwise retrieve the result from global info
  let apiTypeId = null;
  Object.keys(globalInfo.properties).forEach((propertyId) => {
    if (
      isNull(apiTypeId) &&
      globalInfo?.properties?.[propertyId]?.accountAPIs?.[accountAPIId]
    ) {
      apiTypeId =
        globalInfo.properties[propertyId].accountAPIs[accountAPIId].apiTypeId;
    }
  });
  if (!window.apiTypeIds) {
    window.apiTypeIds = {};
  }
  // Cache the result before returning it
  window.apiTypeIds[accountAPIId] = apiTypeId;
  return apiTypeId;
}

/**
 * getBitlyAPIs
 * @param {{ accountAPIs: object }}
 */

function getBitlyAPIs({ accountAPIs = mandatory('accountAPIs') } = {}) {
  return getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.BITLY,
  });
}

/**
 * getCurrentAccountAPI
 */

function getCurrentAccountAPI({ globalInfo = getGlobalInfo() } = {}) {
  try {
    return globalInfo.properties[globalInfo.current.propertyId].accountAPIs[
      globalInfo.current.accountAPIId
    ];
  } catch (e) {
    return null;
  }
}

/**
 * getCurrentAccountAPIId
 */

function getCurrentAccountAPIId({ globalInfo = getGlobalInfo() } = {}) {
  try {
    return globalInfo.current.accountAPIId;
  } catch (e) {
    return null;
  }
}

/**
 * Get current socialPage URN form the current accountAPI
 */

function getCurrentSocialPageURN() {
  try {
    const accountAPIId = getCurrentAccountAPIId();
    const apiTypeId = getCurrentAPITypeId();
    return convertToSocialPageURN(
      API_PROPERTIES[apiTypeId]?.urnName,
      accountAPIId,
    );
  } catch (e) {
    return null;
  }
}

/**
 * getCurrentAPITypeId
 */

function getCurrentAPITypeId({ globalInfo = getGlobalInfo() } = {}) {
  try {
    return globalInfo.properties[globalInfo.current.propertyId].accountAPIs[
      globalInfo.current.accountAPIId
    ].apiTypeId;
  } catch (e) {
    return null;
  }
}

/**
 * getCurrentProperty
 */

function getCurrentProperty({ globalInfo = getGlobalInfo() } = {}) {
  try {
    return globalInfo.properties[globalInfo.current.propertyId];
  } catch (e) {
    return null;
  }
}

/**
 * getCurrentPropertyBilling
 */

function getCurrentPropertyBilling({ globalInfo = getGlobalInfo() } = {}) {
  const propertyId = getCurrentPropertyId();
  if (!isNull(propertyId)) {
    return getPropertyBilling({ propertyId, globalInfo });
  }
  return null;
}

/**
 * getCurrentPropertyOrderForm
 */

function getCurrentPropertyOrderForms({ globalInfo = getGlobalInfo() } = {}) {
  const propertyId = getCurrentPropertyId();
  if (!isNull(propertyId)) {
    return getOrderForms({ propertyId, globalInfo });
  }
  return null;
}

/**
 * getCurrentPropertyId
 */

function getCurrentPropertyId({ globalInfo = getGlobalInfo() } = {}) {
  try {
    return globalInfo.current.propertyId;
  } catch (e) {
    return null;
  }
}

/**
 * getFacebookAPIs
 * @param {{ accountAPIs: object }} args
 */

function getFacebookAPIs({ accountAPIs = mandatory('accountAPIs') } = {}) {
  return getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.FACEBOOK,
  });
}

/**
 * Gets the properties and pages, filtered by the arguments provided.
 *
 * @param {{
 *  apiTypeId?: number;
 *  checkedAPIs?: number[];
 *  mediaStates?: Record<string, string[]> | null;
 *  postType: string;
 *  propertyId?: number;
 *  searchText?: string;
 *  globalInfo?: import('types').FixTypeLater;
 * }}
 * @returns {{
 *  propertyId: number;
 *  propertyName: string;
 *  accountAPIs: (import('types').SocialAPIWithStates)[];
 * }[]}
 */
function getFilteredPropertiesAndPages({
  apiTypeId = null,
  checkedAPIs = [],
  mediaStates = null,
  postType = mandatory('postType'),
  propertyId = null,
  searchText = '',
  globalInfo = getGlobalInfo(),
} = {}) {
  const propertiesAndPages = getOrderedPropertiesAndPages({ globalInfo });
  const filteredPropertiesAndPages = [];

  propertiesAndPages.forEach((property) => {
    if (
      isNullOrUndefined(propertyId) ||
      Number(property.propertyId) === Number(propertyId)
    ) {
      const filteredPages = [];

      let filteredAPIIds;
      if (property?.accountAPIs) {
        filteredAPIIds = Object.values(property.accountAPIs)
          // ... ensure the post type is available for the network in question...
          .filter((accountAPI) => {
            // Do not allow cross-posting a graph api post to ig cabinet enabled property instagram account and vice versa.
            const isIGCabinetEnabledForProperty = isIGCabinetEnabled({
              propertyId: property.propertyId,
            });
            const isGraphAPIEnabledForCurrentProperty = !isIGCabinetEnabled();

            if (
              ((isIGCabinetEnabledForProperty &&
                isGraphAPIEnabledForCurrentProperty) ||
                (!isIGCabinetEnabledForProperty &&
                  !isGraphAPIEnabledForCurrentProperty)) &&
              accountAPI.apiTypeId === API_TYPE_IDS.INSTAGRAM
            ) {
              return false;
            }

            return hasPostType({ apiTypeId: accountAPI.apiTypeId, postType });
          })
          // ... filter to return only active APIs for the specified network
          .filter(
            (accountAPI) =>
              (isNullOrUndefined(apiTypeId) ||
                accountAPI.apiTypeId === apiTypeId) &&
              accountAPI.apiStateId === API_STATES.ACTIVE,
          )
          // ... apply search text if applicable ...
          .filter(
            (accountAPI) =>
              searchText === '' ||
              (isDefined(accountAPI.apiPostName) &&
                accountAPI.apiPostName
                  .toLowerCase()
                  .indexOf(searchText.toLowerCase()) !== -1),
          )
          // ... and extract account ids...
          .map((accountAPI) => accountAPI.accountAPIId);
      }

      // Check that the user has permissions for each account...
      if (!isNullOrUndefined(filteredAPIIds)) {
        filteredAPIIds.forEach((accountAPIId) => {
          const permissionTypeId = getAccountAPIPermission({
            accountAPIId,
            globalInfo,
          });
          if (
            permissionTypeId === PERMISSION_TYPES.EDITOR ||
            permissionTypeId === PERMISSION_TYPES.ADMIN
          ) {
            // ... and if they do, populate the filtered pages array with the relevant details
            const isChecked = checkedAPIs.indexOf(accountAPIId) !== -1;
            const isQueued =
              postType === POST_TYPES.LINK &&
              !isNull(mediaStates) &&
              isDefined(mediaStates[accountAPIId]) &&
              mediaStates[accountAPIId].includes(MEDIA_ITEM_STATES.SCHEDULED);
            const isShared =
              postType === POST_TYPES.LINK &&
              !isNull(mediaStates) &&
              isDefined(mediaStates[accountAPIId]) &&
              mediaStates[accountAPIId].includes(MEDIA_ITEM_STATES.SHARED);
            const accountAPI = property.accountAPIs.find(
              (api) => api.accountAPIId === accountAPIId,
            );
            filteredPages.push({
              accountAPIId,
              apiNetworkIcon: getSocialNetworkIcon({
                apiTypeId: getAPITypeId({ accountAPIId, globalInfo }),
              }),
              apiNetworkName: getSocialNetworkName({
                apiTypeId: getAPITypeId({ accountAPIId, globalInfo }),
              }),
              apiPostImage: accountAPI.apiPostImage,
              apiPostName: accountAPI.apiPostName,
              isChecked,
              isQueued,
              isShared,
            });
          }
        });
      }

      if (filteredPages.length) {
        filteredPropertiesAndPages.push({
          propertyId: property.propertyId,
          propertyName: property.propertyName,
          accountAPIs: filteredPages,
        });
      }
    }
  });

  return filteredPropertiesAndPages;
}

/**
 * getGACoreAPIs
 */

function getGACoreAPIs({ trafficAPIs } = {}) {
  return getTrafficAPIsByType({
    trafficAPIs,
    trafficAPIType: TRAFFIC_API_TYPES.GA_CORE,
  });
}

/**
 * getTrafficAPIsByType
 */

function getTrafficAPIsByType({
  trafficAPIs,
  trafficAPIType = mandatory('trafficAPIType'),
} = {}) {
  return trafficAPIs
    ? Object.values(trafficAPIs)?.filter(
        (trafficAPI) => trafficAPI.trafficAPIType === trafficAPIType,
      )
    : [];
}

/**
 * getTrafficAPIParts
 * @param {{ trafficAPI: import("types").TrafficAPI }} args
 */
function getTrafficAPIParts({ trafficAPI = mandatory('trafficAPI') } = {}) {
  const [, , , , gaApi, , gaView] = trafficAPI.trafficAPIURN.split(':');
  return {
    gaApi,
    gaView,
    gaAccountName: trafficAPI.gaAccountName,
    gaPropertyName: trafficAPI.gaPropertyName,
    gaViewName: trafficAPI.gaViewName,
    gaDomainURL: trafficAPI.gaDomainURL,
  };
}

/**
 * getInstagramAPIs
 * @param {{ accountAPIs: object }}
 */

function getInstagramAPIs({ accountAPIs = mandatory('accountAPIs') } = {}) {
  return getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.INSTAGRAM,
  });
}

/**
 * getLinkedInAPIs
 * @param {{ accountAPIs: object }}
 */

function getLinkedInAPIs({ accountAPIs = mandatory('accountAPIs') } = {}) {
  return getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.LINKEDIN,
  });
}

/**
 * getAutofeedSettingsAPIs
 */

function getAutomationSettingsAPIs({ globalInfo = getGlobalInfo() } = {}) {
  const { accountAPIs } = getCurrentProperty(globalInfo);

  const automationSettingsPages =
    AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES.flatMap((apiTypeId) =>
      getAPIsByType({
        accountAPIs,
        apiTypeId,
      }),
    );

  return automationSettingsPages;
}

/**
 * getOrderedPagesForProperty
 */

function getOrderedPagesForProperty({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  const property = getProperty({
    propertyId,
    globalInfo,
  });
  if (isNullOrUndefined(property) || isNullOrUndefined(property.accountAPIs)) {
    return [];
  }
  const orderedPages = [];
  Object.values(property.accountAPIs)
    .sort(sortPages)
    .forEach((page) => {
      orderedPages.push(page);
    });
  return orderedPages;
}

/**
 * getOrderedPropertiesAndPages
 */

function getOrderedPropertiesAndPages({ globalInfo = getGlobalInfo() } = {}) {
  const orderedPropertiesAndPages = [];

  const currentPropertyId = globalInfo.current.propertyId;

  orderedPropertiesAndPages.push(
    getPropertyAndOrderedPages({
      propertyId: Number(currentPropertyId),
      globalInfo,
    }),
  );

  Object.keys(globalInfo.properties).forEach((propertyId) => {
    if (Number(propertyId) !== Number(currentPropertyId)) {
      orderedPropertiesAndPages.push(
        getPropertyAndOrderedPages({
          propertyId: Number(propertyId),
          globalInfo,
        }),
      );
    }
  });

  return orderedPropertiesAndPages;
}

/**
 * getOrderForms
 */

function getOrderForms({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    let orderForms = null;

    if (
      isDefined(globalInfo.properties[propertyId]) &&
      isDefined(globalInfo.properties[propertyId].orderForms)
    ) {
      orderForms = globalInfo.properties[propertyId].orderForms;
    }

    return orderForms;
  } catch (e) {
    return null;
  }
}

/**
 * getProperty
 * @param {{
 *  propertyId: string | number;
 *  globalInfo?: import('types').FixTypeLater;
 * }}
 */
function getProperty({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    if (isDefined(globalInfo.properties[propertyId])) {
      return globalInfo.properties[propertyId];
    }
    return null;
  } catch (e) {
    return null;
  }
}

/**
 * getPropertyAndOrderedPages
 */

function getPropertyAndOrderedPages({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    if (
      isDefined(globalInfo.properties) &&
      isDefined(globalInfo.properties[propertyId])
    ) {
      return {
        propertyId,
        propertyName: globalInfo.properties[propertyId].propertyName,
        accountAPIs: getOrderedPagesForProperty({ propertyId, globalInfo }),
      };
    }
    return null;
  } catch (e) {
    return null;
  }
}

/**
 * getPropertyBilling
 */

function getPropertyBilling({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    let propertyBilling = null;

    if (
      isDefined(globalInfo.properties[propertyId]) &&
      isDefined(globalInfo.properties[propertyId].propertyBilling)
    ) {
      propertyBilling = globalInfo.properties[propertyId].propertyBilling;
    }

    return propertyBilling;
  } catch (e) {
    return null;
  }
}

/**
 * @param {{
 *  accountAPIId: number;
 *  globalInfo?: object | null;
 * }} args
 * @returns {string}
 */
function getPropertyIdForAccountAPIId({
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  let propertyId;
  if (isDefined(globalInfo.properties)) {
    Object.keys(globalInfo.properties).forEach((property) => {
      if (
        isDefined(globalInfo.properties[property].accountAPIs) &&
        isDefined(globalInfo.properties[property].accountAPIs[accountAPIId])
      ) {
        propertyId = property;
      }
    });
  }
  return propertyId;
}

/**
 * getPropertyName
 * @param {{
 *  propertyId: string | number;
 *  globalInfo?: import('types').GlobalInfo.GlobalInfo
 * }}
 * @returns {string | null}
 */
function getPropertyName({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    if (
      isDefined(globalInfo.properties) &&
      isDefined(globalInfo.properties[propertyId]) &&
      isDefined(globalInfo.properties[propertyId].propertyName)
    ) {
      return globalInfo.properties[propertyId].propertyName;
    }
    return null;
  } catch (e) {
    return null;
  }
}

/**
 * getPropertyPermission
 * @param {{
 *  propertyId: string;
 *  globalInfo?: import('types').GlobalInfo.GlobalInfo;
 * }}
 * @returns {import('types').PermissionTypeId | null}
 */

function getPropertyPermission({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  try {
    let permissionTypeId = PERMISSION_TYPES.NONE;
    globalInfo.user.permissions.forEach((permission) => {
      if (
        isDefined(permission.propertyId) &&
        isUndefined(permission.accountAPIId) &&
        permission.propertyId.toString() === propertyId.toString()
      ) {
        permissionTypeId = permission.permissionTypeId;
      }
    });
    return permissionTypeId;
  } catch (e) {
    return null;
  }
}

/**
 * getSocialAPIIds
 */

function getSocialAPIIds({ globalInfo = getGlobalInfo() } = {}) {
  const accountAPIs =
    globalInfo.properties[globalInfo.current.propertyId].accountAPIs;
  return getFacebookAPIs({ accountAPIs }).concat(
    getTwitterAPIs({ accountAPIs }),
  );
}

/**
 * getSocialAPIs
 */

function getSocialAPIs({ globalInfo = getGlobalInfo() } = {}) {
  const propertyId = globalInfo.current.propertyId;
  const accountAPIs = globalInfo.properties[propertyId].accountAPIs;
  const facebookAPIs = getFacebookAPIs({ accountAPIs });
  const twitterAPIs = getTwitterAPIs({ accountAPIs });
  const instagramAPIs = getInstagramAPIs({ accountAPIs });
  const linkedInAPIs = getLinkedInAPIs({ accountAPIs });
  const tikTokAPIs = getTikTokAPIs({ accountAPIs });
  return facebookAPIs.concat(
    twitterAPIs,
    instagramAPIs,
    linkedInAPIs,
    tikTokAPIs,
  );
}

/**
 * getSocialAPIsByTypeAndState
 * @param {{
 *  accountAPIs: import('types').FixTypeLater[];
 *  states: import('types').APIStateId[];
 * }}
 */

function getSocialAPIsByTypeAndState({
  accountAPIs = mandatory('accountAPIs'),
  states = [API_STATES.ACTIVE],
} = {}) {
  const filteredAPIs = [];
  if (!isNullOrUndefined(accountAPIs)) {
    Object.keys(accountAPIs).forEach((accountAPIId) => {
      if (
        states.filter((x) => x === accountAPIs[accountAPIId].apiStateId)
          .length > 0 &&
        isSocialNetwork({ apiTypeId: accountAPIs[accountAPIId].apiTypeId })
      ) {
        filteredAPIs.push(accountAPIs[accountAPIId]);
      }
    });
  }

  return filteredAPIs;
}

/**
 * getTikTokAPIs
 * @param {{ accountAPIs: object }} args
 */

function getTikTokAPIs({ accountAPIs = mandatory('accountAPIs') } = {}) {
  return getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.TIKTOK,
  });
}

/**
 * getTwitterAPIs
 * @param {{ accountAPIs: object }}
 */

function getTwitterAPIs({ accountAPIs = mandatory('accountAPIs') } = {}) {
  return getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.TWITTER,
  });
}

/**
 * Gets the Twitter Project App if it's enabled.
 * @param {{ accountAPIs: object }}
 * @returns {import('types').SocialAPI | null}
 */
function getEnabledTwitterApp({ accountAPIs = mandatory('accountAPIs') } = {}) {
  const twitterApps = getAPIsByType({
    accountAPIs,
    apiTypeId: API_TYPE_IDS.TWITTER_APP,
  });
  if (twitterApps.length > 0 && twitterApps[0].socialAPIURN != null) {
    return twitterApps[0];
  }
  return null;
}

/**
 * getUser
 * @param {{
 *  userId: string;
 *  globalInfo?: import('types').FixTypeLater
 * }} args
 * @returns {import('types').FixTypeLater}
 */

function getUser({
  userId = mandatory('userId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  let result;

  Object.keys(globalInfo.properties).forEach((propertyId) => {
    if (propertyId.toString() === globalInfo.current.propertyId.toString()) {
      if (isDefined(globalInfo.properties[propertyId].propertyUsers)) {
        globalInfo.properties[propertyId].propertyUsers.forEach((user) => {
          if (user.userId.toString() === userId.toString()) {
            result = user;
          }
        });
      }
    }
  });

  return result;
}

/**
 * getUserAccountAPIPermission
 */

function getUserAccountAPIPermission({
  userId = mandatory('userId'),
  accountAPIId = mandatory('accountAPIId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  let permissionTypeId = null;

  Object.keys(globalInfo.properties).forEach((propertyId) => {
    if (isDefined(globalInfo.properties[propertyId].propertyUsers)) {
      const propertyUsers = globalInfo.properties[propertyId].propertyUsers;
      propertyUsers.forEach((user) => {
        if (user.userId.toString() === userId.toString()) {
          user.permissions.forEach((permission) => {
            if (
              isDefined(permission.accountAPIId) &&
              permission.accountAPIId.toString() === accountAPIId.toString()
            ) {
              permissionTypeId = permission.permissionTypeId;
            }
          });
        }
      });
    }
  });

  return permissionTypeId;
}

/**
 * getUserAPIIds
 *
 * Returns a list of account API ids to which the current user has access,
 * either because they have ADMIN access to the parent property, or because
 * they have EDITOR access to individual APIs
 */

function getUserAPIIds({ globalInfo = getGlobalInfo() } = {}) {
  const permissions = globalInfo.user.permissions;
  const accountAPIIds = [];
  permissions.forEach((permission) => {
    if (permission.permissionTypeId === PERMISSION_TYPES.ADMIN) {
      const propertyAPIs = getActiveAPIsForProperty({
        propertyId: permission.propertyId,
        globalInfo,
      });
      propertyAPIs.forEach((accountAPI) => {
        if (accountAPIIds.indexOf(accountAPI.accountAPIId) === -1) {
          accountAPIIds.push(accountAPI.accountAPIId);
        }
      });
    } else if (permission.permissionTypeId === PERMISSION_TYPES.EDITOR) {
      accountAPIIds.push(permission.accountAPIId);
    }
  });
  return accountAPIIds;
}

/**
 * getUserPagePermissions
 * @param {{
 *  userId: string;
 *  propertyId: string;
 *  globalInfo?: import('types').FixTypeLater;
 * }}
 */

function getUserPagePermissions({
  userId = mandatory('userId'),
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  const permissions = [];

  const property = globalInfo.properties[propertyId];
  // If the property is active...
  if (property.propertyStateId === PROPERTY_STATES.ACTIVE) {
    // Find the user's property-level permission
    const propertyPermission = getUserPropertyPermission({
      userId,
      propertyId,
      globalInfo,
    });
    if (!isNull(propertyPermission) && property?.accountAPIs) {
      // For each api...
      Object.keys(property.accountAPIs).forEach((accountAPIId) => {
        const accountAPI = property.accountAPIs[accountAPIId];
        // If the api is an active or bad auth social network...
        if (
          (accountAPI.apiStateId === API_STATES.ACTIVE ||
            accountAPI.apiStateId === API_STATES.BAD_AUTH ||
            accountAPI.apiStateId === API_STATES.UNDER_SETUP) &&
          isSocialNetwork({ apiTypeId: accountAPI.apiTypeId })
        ) {
          // Find and return the user's api-level permissions
          const apiPermission = getUserAccountAPIPermission({
            userId,
            accountAPIId,
            globalInfo,
          });
          if (!isNull(apiPermission)) {
            permissions.push({
              propertyId: propertyId.toString(),
              accountAPIId,
              permissionTypeId: apiPermission,
            });
          }
        }
      });
    }
  }

  return permissions;
}

/**
 * @param {{
 *   user?: import('types').User;
 * }}
 * @returns {[number[], number[], string[]]}
 */
function getUserPropertiesAndAPIs({ user = getGlobalInfo().user } = {}) {
  // List of all properties for which the user has property or API-level permissions
  const allProperties = [];
  // List of properties for which the user has property-level permissions
  const propertyIds = [];
  // List of accounts for which the user has API-level permissions
  const socialAPIURNs = [];

  if (isDefined(user) && isDefined(user.permissions)) {
    // Property-level permissions
    user.permissions
      .filter((permission) => isUndefined(permission.accountAPIId))
      .forEach((permission) => {
        if (
          isDefined(permission.propertyId) &&
          allProperties.indexOf(permission.propertyId) === -1
        ) {
          allProperties.push(permission.propertyId);
        }
        if (permission.permissionTypeId === PERMISSION_TYPES.ADMIN) {
          propertyIds.push(permission.propertyId);
        }
      });
    // API-level permissions
    user.permissions
      .filter((permission) => isDefined(permission.socialAPIURN))
      .forEach((permission) => {
        if (
          isDefined(permission.propertyId) &&
          allProperties.indexOf(permission.propertyId) === -1
        ) {
          allProperties.push(permission.propertyId);
        }
        socialAPIURNs.push(permission.socialAPIURN);
      });
  }

  return [allProperties, propertyIds, socialAPIURNs];
}

/**
 * getUserPropertyPermission
 * @returns {import('types').PermissionTypeId | null}
 */

function getUserPropertyPermission({
  userId = mandatory('userId'),
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  let permissionTypeId = null;

  if (userId === globalInfo.user.userId) {
    globalInfo.user.permissions.forEach((permission) => {
      if (
        isDefined(permission.propertyId) &&
        isUndefined(permission.accountAPIId) &&
        permission.propertyId.toString() === propertyId.toString()
      ) {
        permissionTypeId = permission.permissionTypeId;
      }
    });
  }
  if (!isNull(permissionTypeId)) {
    return permissionTypeId;
  }

  Object.keys(globalInfo.properties).forEach((propId) => {
    if (isDefined(globalInfo.properties[propId].propertyUsers)) {
      const propertyUsers = globalInfo.properties[propId].propertyUsers;
      propertyUsers.forEach((user) => {
        if (user.userId.toString() === userId.toString()) {
          user.permissions.forEach((permission) => {
            if (
              isDefined(permission.propertyId) &&
              isUndefined(permission.accountAPIId) &&
              permission.propertyId.toString() === propertyId.toString()
            ) {
              permissionTypeId = permission.permissionTypeId;
            }
          });
        }
      });
    }
  });

  return permissionTypeId;
}

/**
 * Checks whether a given property is in the set up stage.
 *
 * @param {{
 *  propertyId: string | number;
 *  globalInfo?: import('types').FixTypeLater;
 * }}
 * @returns {boolean}
 */
export function isPropertyIncomplete({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
}) {
  const propertyStateId = globalInfo.properties[propertyId]?.propertyStateId;
  return (
    propertyStateId === PROPERTY_STATES.NEWSIGNUP ||
    propertyStateId === PROPERTY_STATES.UNDERSETUP
  );
}

/**
 * hasSocialAPIInState
 *
 * Returns true if any social networks have the specified state
 */

function hasSocialAPIInState({
  accountAPIState = mandatory('accountAPIState'),
  globalInfo = getGlobalInfo(),
} = {}) {
  if (isNull(globalInfo)) {
    return false;
  }
  const currentProperty = getCurrentProperty({
    globalInfo,
  });
  if (isNullOrUndefined(currentProperty)) {
    return false;
  }
  const currentPropertyId = getCurrentPropertyId({
    globalInfo,
  });
  if (isNullOrUndefined(currentPropertyId)) {
    return false;
  }

  const propertyAPIs = currentProperty.accountAPIs;
  if (isNullOrUndefined(propertyAPIs)) {
    return false;
  }
  return !isUndefined(
    Object.keys(propertyAPIs).find(
      (api) =>
        propertyAPIs[api].apiStateId === accountAPIState &&
        isSocialNetwork({ apiTypeId: propertyAPIs[api].apiTypeId }),
    ),
  );
}

/**
 * isCurrentAccountAPIPaused
 */

function isCurrentAccountAPIPaused(globalInfo = getGlobalInfo()) {
  const accountAPI = getCurrentAccountAPI({ globalInfo });
  return !isNullOrUndefined(accountAPI) ? accountAPI.isSharingPaused : false;
}

/**
 * getOptinalImageOverlay
 */
function getOptinalImageOverlay(globalInfo = getGlobalInfo()) {
  const accountAPI = getCurrentAccountAPI({ globalInfo });

  const optionalImageOverlays = accountAPI.optionalImageOverlays;

  if (isNullOrUndefined(optionalImageOverlays) || !optionalImageOverlays) {
    return null;
  }

  return optionalImageOverlays[0];
}

/**
 * isCurrentPropertySuspended
 */

function isCurrentPropertySuspended({ globalInfo = getGlobalInfo() } = {}) {
  const propertyId = getCurrentPropertyId();
  if (!isNull(propertyId)) {
    return isPropertySuspended({ propertyId, globalInfo });
  }
  return false;
}

/**
 * isPropertySuspended
 */

function isPropertySuspended({
  propertyId = mandatory('propertyId'),
  globalInfo = getGlobalInfo(),
} = {}) {
  const propertyBilling = getPropertyBilling({ propertyId, globalInfo });
  if (
    !isNull(propertyBilling) &&
    propertyBilling.shutOffUnixTime < getUnixTimestamp()
  ) {
    return true;
  }

  const orderForms = getOrderForms({ propertyId, globalInfo });
  if (!isNull(orderForms) && orderForms.shutOffUnixTime < getUnixTimestamp()) {
    return true;
  }

  return false;
}

/**
 * Sort pages by number of followers, if available, or alphabetically otherwise
 * @param {import('types').FixTypeLater} accountA
 * @param {import('types').FixTypeLater} accountB
 * @returns {number} -1 if accountA should be before accountB, 1 if accountB should be before accountA, 0 if they are equal
 */
function sortPages(accountA, accountB) {
  if (accountA.numberFollowers == null && accountB.numberFollowers == null) {
    if (
      isUndefined(accountA.apiPostName) &&
      isUndefined(accountB.apiPostName)
    ) {
      return accountB.accountAPIId - accountA.accountAPIId;
    }
    if (accountA.apiPostName < accountB.apiPostName) {
      return -1;
    }
    if (accountA.apiPostName === accountB.apiPostName) {
      return 0;
    }
    return 1;
  }

  const followersA = accountA.numberFollowers ?? 0;
  const followersB = accountB.numberFollowers ?? 0;
  return followersB - followersA;
}

/**
 * raiseAPIErrors
 */

function raiseAPIErrors({ property = mandatory('property') } = {}) {
  logger.info('accountAPIs:raiseAPIErrors');

  const messages = [];

  logger.info(
    `PubSub: publish ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE} in common/accountAPIs.raiseAPIErrors`,
  );
  PubSub.publish(COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE, {
    type: FLASH_MESSAGE_TYPES.API_ERROR,
  });

  // Check for APIs needed to be reconnected
  if (isDefined(property.accountAPIs)) {
    Object.values(property.accountAPIs).forEach((accountAPI) => {
      if (
        accountAPI.requiresReconnect &&
        (isNullOrUndefined(accountAPI.disconnectReason) ||
          getSocialNetworkName({ apiTypeId: accountAPI.apiTypeId }) ===
            API_PROPERTIES[API_TYPE_IDS.BITLY].name)
      ) {
        messages.push({
          messageCategory: 'Social page disconnected',
          type: FLASH_MESSAGE_TYPES.API_ERROR,
          text: getAccountDisconnectedError({
            apiTypeId: accountAPI.apiTypeId,
          }),
        });
      }
    });
  }

  // Add error messages
  addFlashMessages({
    messages,
  });
}

/**
 * @type Array<import('types').APITypeId>
 */
const socialNetworkAPIIds = [
  API_TYPE_IDS.FACEBOOK,
  API_TYPE_IDS.TWITTER,
  API_TYPE_IDS.LINKEDIN,
  API_TYPE_IDS.INSTAGRAM,
  API_TYPE_IDS.TIKTOK,
];
