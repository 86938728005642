/* eslint no-use-before-define:"off" */
import $ from 'jquery';

import getDownloadFile from 'api/external/getDownloadFile';
import {
  MAX_IMAGE_HEIGHT,
  MAX_IMAGE_WIDTH,
  TWITTER_SETTINGS,
} from 'common/config';
import {
  API_TYPE_IDS,
  ENABLE_IMAGE_PREFIX,
  IMAGE_RESIZE_OPTIONS,
  IMAGE_TYPES,
  IMAGE_URL_PREFIX,
  POST_TYPES,
} from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { getMediaSizeLimit } from 'common/social';
import { isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export {
  addImagePrefix,
  base64ToArrayBuffer,
  calculateFinalImageHeight,
  calculateFinalImageWidth,
  findDiv,
  findElement,
  findImg,
  getCroppedImage,
  getFileSizeLimits,
  getImageDimensions,
  getImageSuffix,
  isImageUploadNotAllowed,
  logError,
  resizeDataURL,
  toBase64,
  urlToBase64,
};

/**
 * Calculates the final height of the image.
 * @param {{
 *  guid: string;
 *  apiTypeId: import('types').APITypeId;
 *  naturalDimensions: {
 *    width: number;
 *    height: number;
 *  };
 * }}
 * @returns {number}
 */
function calculateFinalImageHeight({
  guid = mandatory('guid'),
  apiTypeId = mandatory('apiTypeId'),
  naturalDimensions = mandatory('naturalDimensions'),
} = {}) {
  const { width: naturalWidth, height: naturalHeight } = naturalDimensions;
  const actualWidth = isNull(naturalWidth)
    ? findImg(guid).width()
    : naturalWidth;
  const actualHeight = isNull(naturalHeight)
    ? findImg(guid).height()
    : naturalHeight;
  const targetWidth =
    apiTypeId === API_TYPE_IDS.INSTAGRAM
      ? MAX_IMAGE_WIDTH[apiTypeId]
      : findElement(guid).width();
  const targetHeight = actualHeight * (targetWidth / actualWidth);
  const maxHeight = MAX_IMAGE_HEIGHT[apiTypeId];
  return targetHeight > maxHeight ? maxHeight : targetHeight;
}

/**
 * Calculates the final width of the image.
 * @param {{
 *  guid: string;
 *  apiTypeId: import('types').APITypeId;
 *  naturalDimensions: {
 *    width: number;
 *    height: number;
 *  };
 * }}
 * @returns {number}
 */
function calculateFinalImageWidth({
  guid = mandatory('guid'),
  apiTypeId = mandatory('apiTypeId'),
  naturalDimensions = null,
} = {}) {
  const { width: naturalWidth = null, height: naturalHeight = null } =
    naturalDimensions ?? {};
  const actualWidth = isNull(naturalWidth)
    ? findImg(guid).width()
    : naturalWidth;
  const actualHeight = isNull(naturalHeight)
    ? findImg(guid).height()
    : naturalHeight;
  const targetHeight = findElement(guid).height();
  const targetWidth = actualWidth * (targetHeight / actualHeight);
  const maxWidth = MAX_IMAGE_WIDTH[apiTypeId];
  return targetWidth > maxWidth ? maxWidth : targetWidth;
}

function findElement(guid) {
  return findDiv(guid).length > 0 ? findDiv(guid) : findImg(guid);
}

function findDiv(guid) {
  return $(
    `[data-cy-id="composeBox"] [data-cy-id="${guid}"] div[data-cy-id="shareImage"]`,
  );
}

function findImg(guid) {
  return $(
    `[data-cy-id="composeBox"] [data-cy-id="${guid}"] img[data-cy-id="shareImage"]`,
  );
}

/**
 *
 * @param {{
 *  croppedCanvas: HTMLCanvasElement;
 *  fileName: string;
 * }}
 * @returns {Promise<File & { name: string; lastModified: number; }> | null}
 */
function getCroppedImage({
  croppedCanvas = mandatory('croppedCanvas'),
  fileName = mandatory('fileName'),
} = {}) {
  // As a blob
  return new Promise((resolve) => {
    if (isNullOrUndefined(croppedCanvas)) {
      resolve(null);
    }
    croppedCanvas.toBlob((file) => {
      if (isNullOrUndefined(file)) {
        resolve(null);
      } else {
        const fileCopy = file;
        fileCopy.name = fileName;
        fileCopy.lastModified = Date.now();
        resolve(fileCopy);
      }
    }, 'image/jpeg');
  });
}

/**
 *
 * @param {{
 *  file: File | import('types').FileMetaDataType
 *  apiTypeId: import('types').APITypeId;
 *  postType: import('types').PostType;
 *  socialChannel: import('types').SocialChannel;
 * }}
 *
 * @returns {{ min?: number; max?: number; }}
 */
function getFileSizeLimits({
  file = mandatory('file'),
  apiTypeId = mandatory('apiTypeId'),
  postType = mandatory('postType'),
  socialChannel = null,
} = {}) {
  if (postType === POST_TYPES.VIDEO) {
    return getMediaSizeLimit({
      apiTypeId,
      socialChannel,
      imageType: IMAGE_TYPES.VIDEO,
      postType,
    });
  }
  if (!isNull(file) && file.type.indexOf('image/gif') > -1) {
    return getMediaSizeLimit({
      apiTypeId,
      imageType: IMAGE_TYPES.GIF,
      socialChannel,
      ...(apiTypeId === API_TYPE_IDS.FACEBOOK && { postType }),
    });
  }
  return getMediaSizeLimit({ apiTypeId, postType, socialChannel });
}

function getImageSuffix(imageURL) {
  if (isNullOrUndefined(imageURL) || imageURL.split('.').length < 2) {
    return null;
  }
  const parts = imageURL.split('.');
  return parts[parts.length - 1].toLowerCase();
}

/**
 *
 * @param {{
 *  articleImages: string[];
 *  apiTypeId: import('types').APITypeId;
 *  mediaItem: import('types').FixTypeLater;
 *  limits: { min: number; max: number; };
 *  file: Blob | import('types').FileMetaDataType;};
 * }}
 * @returns {boolean}
 */
function isImageUploadNotAllowed({
  articleImages = mandatory('articleImages'),
  apiTypeId = mandatory('apiTypeId'),
  mediaItem = mandatory('mediaItem'),
  limits = mandatory('limits'),
  file = mandatory('file'),
} = {}) {
  const images = articleImages;
  const twitterCardType = MediaItem.getTwitterCardType({
    mediaItem,
  });
  const hasTwitterCardType = !isNull(twitterCardType);
  return (
    apiTypeId === API_TYPE_IDS.TWITTER &&
    ((limits.max === TWITTER_SETTINGS.MAX_NUM_IMAGES &&
      file.type.split('/')[1] === 'gif') ||
      (limits.max === TWITTER_SETTINGS.MAX_NUM_GIFS &&
        file.type.split('/')[1] !== 'gif') ||
      // Already got a gif
      (limits.max === TWITTER_SETTINGS.MAX_NUM_IMAGES &&
        !hasTwitterCardType &&
        images.filter(
          (image) => image.split('.')[image.split('.').length - 1] === 'gif',
        ).length > 0) ||
      (limits.max === TWITTER_SETTINGS.MAX_NUM_GIFS &&
        !hasTwitterCardType &&
        images.filter(
          (image) => image.split('.')[image.split('.').length - 1] !== 'gif',
        ).length > 0))
  );
}

/**
 * Log an error from the images component.
 *
 * @param {{
 *  error: unknown;
 *  fileType: string;
 * }}
 * @returns {string}
 */
function logError({ error = mandatory('error'), fileType } = {}) {
  const errorMessage = getErrorMessage(determineError(error));
  if (isNull(getErrorStatus(error))) {
    logger.error({
      event: 'handleCrop Error',
      properties: {
        location: 'common/Images',
        arguments: fileType,
      },
      error,
    });
  }
  return errorMessage;
}

/**
 * getImageDimensions
 * @param {{ file: string; }}
 * @returns {Promise<{ height: number; width: number }>}
 */
function getImageDimensions({ file = mandatory('file') } = {}) {
  return new Promise((resolved) => {
    const img = new Image();
    img.onload = () => {
      resolved({ width: img.width, height: img.height });
    };
    img.src = file;
  });
}

/**
 * Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
 * @param {{
 *  file: string;
 *  targetWidth: number;
 *  targetHeight: number;
 * }} args
 * @returns {Promise<string>}
 */
function resizeDataURL({
  file = mandatory('file'),
  targetWidth = mandatory('targetWidth'),
  targetHeight = mandatory('targetHeight'),
} = {}) {
  return new Promise((resolve) => {
    // We create an image to receive the Data URI
    const img = document.createElement('img');

    // When the event "onload" is triggered we can resize the image.
    const onload = () => {
      // We create a canvas and get its context.
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // We set the dimensions at the wanted size.
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      // We resize the image with the canvas method drawImage();
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

      const dataURI = canvas.toDataURL();

      // This is the return of the Promise
      resolve(dataURI);
    };

    img.onload = onload;

    // We put the Data URI in the image's src attribute
    img.src = file;
  });
}

async function toBase64({ file = mandatory('file') }) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  bytes.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });
  return window.btoa(binary);
}

/**
 * Convert URL to base64 string.
 * @param {{ url: string }} args
 * @returns string
 */
async function urlToBase64({ url = mandatory('url') }) {
  const response = await getDownloadFile({
    url,
    responseType: 'arraybuffer',
  });
  const { headers } = response;
  const fileType = headers['content-type'];
  return `data:${fileType};base64,${arrayBufferToBase64(response.data)}`;
}

function base64ToArrayBuffer(base64) {
  let base64String = base64;
  const base64Parts = base64.split(',');
  if (base64Parts.length > 1) {
    base64String = base64Parts[1];
  }
  const binaryString = window.atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

function addImagePrefix(url, options = {}) {
  if (ENABLE_IMAGE_PREFIX) {
    const imageOptions = {
      ...IMAGE_RESIZE_OPTIONS,
      ...options,
    };

    const imageOptionsParams = new URLSearchParams(imageOptions);
    const imageOptionsString = imageOptionsParams.toString();

    const encodedUrl = encodeURI(url);

    return `${IMAGE_URL_PREFIX}?${imageOptionsString}&image=${encodedUrl}`;
  }
  return url;
}
