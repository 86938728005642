/* eslint no-await-in-loop: "off" */
/* eslint no-alert: "off" */
/* eslint no-param-reassign: "off" */

import { Box, Grid, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';
import $ from 'jquery';
import proptypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useEffect, useRef } from 'react';
import { flushSync } from 'react-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Immutable from 'seamless-immutable';
import { debounce } from 'throttle-debounce';

import { postMediaItemField } from 'api/api';
import getMediaItemCategories from 'api/getMediaItemCategories';
import getMediaItems from 'api/getMediaItems';
import getMediaList from 'api/getMediaList';
import clsx from 'clsx';
import {
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
  getCurrentPropertyId,
  getCurrentSocialPageURN,
  getPropertyIdForAccountAPIId,
  isCurrentPropertySuspended,
} from 'common/accountAPIs';
import { chunkArray } from 'common/array';
import { isImpersonating } from 'common/authentication';
import * as Compose from 'common/compose';
import {
  FAILED_SHARE_DURATION,
  MAX_MEDIA_ITEMS_IN_BATCH,
  MAX_REFRESH_ITEMS_TO_ADD,
  REFRESH_ERROR_THRESHOLD,
  REFRESH_INTERVALS,
  REFRESH_MULTIPLIERS,
} from 'common/config';
import {
  ACCOUNT_SETTING_TYPES,
  ACTION_TYPES,
  API_TYPE_IDS,
  COGNITO_ERROR_MESSAGES,
  COLLECTION_FIELDS,
  COLLECTION_NAMES,
  FEED_STATES,
  FLASH_MESSAGE_TYPES,
  FRONTEND_METRICS,
  FRONTEND_METRICS_STATUS,
  FRONTEND_PAGES,
  GLOBAL_INFO_STATES,
  MEDIA_ITEM_STATES,
  NON_API_ERROR_MESSAGES,
  NON_API_ERROR_TYPES,
  POST_TYPES,
  ROUTE_REDIRECTIONS,
  SHARE_ORIGINS,
  SHARE_TIME_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
  TEXT_CASES,
  TIME_SENSITIVITY_NAMES,
  UI_MESSAGES,
} from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import {
  createTimeRange,
  getTimeFilterRange,
  getUnixTimestamp,
} from 'common/datetime';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'common/featureFlags';
import { refresh } from 'common/feed';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import { refreshInterval } from 'common/misc';
import {
  addErrorNotification,
  addSuccessNotification,
} from 'common/notifications';
import { stripKeys } from 'common/object';
import { isOnPage } from 'common/path';
import * as queue from 'common/queue';
import { withLocation, withNavigate } from 'common/routing';
import {
  getAutofeedSettings,
  getFeatureToggle,
  getSetting,
  isApprovalsActive,
} from 'common/settings';
import {
  getSocialNetworkName,
  getURNName,
  hasMentionsLookups,
} from 'common/social';
import { getSocialAnalyticsInsights } from 'common/socialV2';
import * as tracker from 'common/tracker';
import {
  cloneArray,
  immutableClone,
  isDefined,
  isNull,
  isNullOrUndefined,
  isRunningTests,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import { location } from 'common/window';
import BaseComponent from 'components/BaseComponent';
import ComposeBox from 'components/compose/ComposeBox';
import PageSelect from 'components/header/PageSelect';
import AnalyticsPage from 'components/home/analytics/AnalyticsPage';
import Approvals, {
  trackApprovalAction,
} from 'components/home/approvals/Approvals';
import ArticleArchive from 'components/home/archive/ArticleArchive';
import ArticleFeed from 'components/home/articlefeed/ArticleFeed';
import HealthMenuToast from 'components/home/HealthMenuToast';
import LastShared from 'components/home/lastshared/LastShared';
import ScheduleQueue from 'components/home/schedulequeue/ScheduleQueue';
import InstantVideoModal from 'components/instantvideo/InstantVideoModal';
import AllNotifications from 'components/misc/AllNotifications';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import withFlashMessages from 'context/withFlashMessages';
import withGlobalInfo from 'context/withGlobalInfo';
import { defaultDateRange } from 'helpers/analyticsPage';
import { getQuartileName } from 'helpers/newsFeed';
import {
  getAutofeedSettingsTrackingParams,
  getCommonTrackingParams,
  getNetworkAndPageName,
  getSchedulePostTrackingParams,
} from 'helpers/tracking';
import { scrollToTop } from 'helpers/window';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import saveMediaItem from 'process/saveMediaItem';
import * as topics from 'pubsub/topics';
import { useIsComposeBoxOpen } from 'state/composeBoxOpen';

const isFailedSharesEnabled = () =>
  isFeatureFlagEnabled({ flag: FEATURE_FLAGS.FAILED_SHARES });

const {
  COMMAND_SET_SAVING_STATE,
  MESSAGE_MEDIA_ITEM_ERROR,
  MESSAGE_SAVING_STATE_SET,
  REQUEST_API_INFO,
} = topics;

/**
 * Share page
 */

class NewsFeed extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const displayOptions = NewsFeed.getDisplayOptions();
    this.state = {
      data: Immutable({
        articleFeed: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.articleFeed,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 7,
          errors: [],
        },
        scheduleQueue: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.scheduleQueue,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 999999,
          errors: [],
        },
        approvals: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.approvals,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 999999,
          errors: [],
        },
        lastShared: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.lastShared,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 10,
          errors: [],
        },
        analyticsPage: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.analyticsPage,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 9,
          errors: [],
        },
        deletedItems: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.deletedItems,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 999999,
          errors: [],
        },
        failedShares: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.failedShares,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 999999,
          errors: [],
        },
        articleArchive: {
          mediaIds: [],
          mediaItems: [],
          displayOptions: displayOptions.articleArchive,
          hasError: false,
          isInitialised: false,
          isLoading: true,
          itemsRendered: 0,
          itemsPerPage: 10,
          categories: [],
          isLoadingCategory: false,
          errors: [],
        },
        maxYScroll: 0,
        currentTime: getUnixTimestamp(),
        articleFeedState: displayOptions.articleFeedState,
      }),
      isInstantVideoModalOpen: false,
      instantVideoMediaItem: null,
    };
    this._bind(
      'checkForUpdates',
      'checkLoadMoreItems',
      'debouncedInfiniteLoad',
      'findMediaItem',
      'getCategoriesConfig',
      'getFeedData',
      'getMediaItemIndex',
      'getMediaListConfig',
      'getPageName',
      'handleApprove',
      'handleApprovalSkipped',
      'handleArticleAction',
      'handleArticleDelete',
      'handleArticleLoad',
      'handleArticleReshare',
      'handleArticleRestore',
      'handleInstantVideoSchedule',
      'handleError',
      'handleFilterChange',
      'handleLoadingCategoriesError',
      'handleSelectedCategoriesChanged',
      'handleInsightColumnsChange',
      'handleShowInstantVideoModal',
      'handleSortChange',
      'handleStateChange',
      'handleTimeframeChange',
      'loadMoreItems',
      'onMediaItemError',
      'onSetSavingState',
      'onSavingStateSet',
      'renderAnalytics',
      'renderNoMoreItems',
      'resetIntervalOnEvent',
      'updateAndSaveCollectionItem',
    );
    this.hasLoggedInitialPageLoad = false;
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    logger.info('NewsFeed:componentDidMount');
    this.initialiseComponent();

    logger.info(
      `PubSub: subscribe ${COMMAND_SET_SAVING_STATE} in pages/NewsFeed.componentDidMount`,
    );
    logger.info(
      `PubSub: subscribe ${MESSAGE_MEDIA_ITEM_ERROR} in pages/NewsFeed.componentDidMount`,
    );
    this.tokens = [
      PubSub.subscribe(COMMAND_SET_SAVING_STATE, this.onSetSavingState),
      PubSub.subscribe(MESSAGE_MEDIA_ITEM_ERROR, this.onMediaItemError),
    ];

    // Check if there has been an error from an social page connection attempt
    const queryParams = new URLSearchParams(this.props.location.search);
    if (queryParams.get('error') === NON_API_ERROR_TYPES.CONNECT_CANCELLED) {
      this.props.flashMessages.addMessage({
        messageCategory: 'Social page connection attempt cancelled',
        type: FLASH_MESSAGE_TYPES.INFO,
        text: NON_API_ERROR_MESSAGES.CONNECT_CANCELLED,
      });

      // Remove so this does not happen again on reload
      queryParams.delete('error');
      this.props.navigate(
        `${this.props.location.pathname}?${queryParams.toString()}`,
        {
          replace: true,
        },
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('NewsFeed', { nextProps, nextState });
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.global.globalInfoState;
    const next = this.props.global.globalInfoState;
    const hasChangedToReady = this.props.global.hasChangedToReady(prev, next, {
      excludeLoadingAndRefreshing: true,
    });
    if (hasChangedToReady) {
      logger.info(
        `NewsFeed:componentDidUpdate - re-initialising due to global info state change - prev ${prev} next ${next}`,
      );
      this.initialiseComponent();
    }

    // When compose box is opened, stop refreshing data in the background
    if (!prevProps.isComposeBoxOpen && this.props.isComposeBoxOpen) {
      NewsFeed.clearBackgroundIntervals();
    }

    // When compose box is closed, immediately refresh data and re-initialise background refresh
    else if (prevProps.isComposeBoxOpen && !this.props.isComposeBoxOpen) {
      this.checkForArticleFeedUpdates();
      this.initializeBackgroundIntervals();
    }

    // Load additional items if necessary
    if (this.props.global.hasChangedLoading(prev, next)) {
      this.checkLoadMoreItems();
      window.intervals.loadMoreItems = window.setInterval(
        this.checkLoadMoreItems.bind(this),
        REFRESH_INTERVALS.LOAD_MORE_ITEMS,
      );
    }

    // Track page load
    this._trackLastUpdate(FRONTEND_METRICS.LOGIN);
    const pageName = this.getPageName();
    switch (pageName) {
      case 'analytics':
        this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_ANALYTICS);
        break;
      case 'archive':
        this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_ARCHIVE);
        break;
      default:
        this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_HOME);
    }

    if (!isRunningTests()) {
      const collectionName = this.getCollectionName();
      const collectionData = this.state.data[collectionName];
      const scrollbarHasAppeared =
        document.getElementById('page-content').clientHeight <
        document.getElementById('page-content').scrollHeight;

      // If the scrollbar hasn't appeared, it's not possible to scroll and load more articles
      // So we should automatically load more articles
      if (
        !scrollbarHasAppeared &&
        collectionData.itemsRendered < collectionData.mediaIds.length &&
        !collectionData.isLoading
      ) {
        this.checkLoadMoreItems();
      }
    }

    if (!this.hasLoggedInitialPageLoad) {
      const isHomePage = pageName === 'share';
      const hasArticleFeedLoaded = this.hasQueueLoaded({
        collectionName: 'articleFeed',
      });
      const hasScheduleQueueLoaded = this.hasQueueLoaded({
        collectionName: 'scheduleQueue',
      });
      const hasLastSharedQueueLoaded = this.hasQueueLoaded({
        collectionName: 'lastShared',
      });
      const hasApprovalsLoaded = this.hasQueueLoaded({
        collectionName: 'approvals',
      });
      const hasArticleFeedFailedToLoad = this.hasQueueFailedToLoad({
        collectionName: 'articleFeed',
      });
      const hasScheduleQueueFailedToLoad = this.hasQueueFailedToLoad({
        collectionName: 'scheduleQueue',
      });
      const hasLastSharedQueueFailedToLoad = this.hasQueueFailedToLoad({
        collectionName: 'lastShared',
      });
      const hasApprovalsFailedToLoad = this.hasQueueFailedToLoad({
        collectionName: 'approvals',
      });
      if (
        isHomePage &&
        (hasArticleFeedLoaded || hasArticleFeedFailedToLoad) &&
        (hasScheduleQueueLoaded || hasScheduleQueueFailedToLoad) &&
        (hasLastSharedQueueLoaded || hasLastSharedQueueFailedToLoad) &&
        (!isApprovalsActive() || hasApprovalsLoaded || hasApprovalsFailedToLoad)
      ) {
        const status =
          hasArticleFeedFailedToLoad ||
          hasScheduleQueueFailedToLoad ||
          hasLastSharedQueueFailedToLoad ||
          hasApprovalsFailedToLoad
            ? FRONTEND_METRICS_STATUS.UNKNOWN_ERROR
            : FRONTEND_METRICS_STATUS.OK;
        metrics.measure(FRONTEND_METRICS.HOME_PAGE_LOAD, { status });
        // Track completion of page select
        metrics.measure(FRONTEND_METRICS.CHANGE_PAGE, { status });
        this.hasLoggedInitialPageLoad = true;
      }
    }
  }

  componentWillUnmount() {
    logger.info('NewsFeed:componentWillUnmount');

    // Remove event handlers
    window.removeEventListener('click', this.resetIntervalOnEvent);
    window.removeEventListener('keypress', this.resetIntervalOnEvent);
    window.removeEventListener('wheel', this.resetIntervalOnEvent);

    // Remove periodic checks
    window.clearInterval(window.intervals.loadMoreItems);
    window.intervals.loadMoreItems = null;
    if (
      isOnPage({ page: FRONTEND_PAGES.SHARE, location: this.props.location })
    ) {
      NewsFeed.clearBackgroundIntervals();
    }

    const pageName = this.getPageName();
    if (pageName !== 'analytics') {
      $('#page-content').off('scroll', this.debouncedInfiniteLoad);
    }

    logger.info(
      `PubSub: unsubscribe ${COMMAND_SET_SAVING_STATE} in pages/NewsFeed.componentWillUnmount`,
    );
    logger.info(
      `PubSub: unsubscribe ${MESSAGE_MEDIA_ITEM_ERROR} in pages/NewsFeed.componentWillUnmount`,
    );
    if (isDefined(this.tokens)) {
      this.tokens.forEach((token) => {
        PubSub.unsubscribe(token);
      });
    }
  }

  /**
   * Helper methods
   */

  hasQueueLoaded({ collectionName = mandatory('collectionName') } = {}) {
    return (
      this.state.data[collectionName].isInitialised &&
      !this.state.data[collectionName].mediaItems.some((mediaItem) =>
        MediaItem.getIsLoading({ mediaItem }),
      )
    );
  }

  hasQueueFailedToLoad({ collectionName = mandatory('collectionName') } = {}) {
    return (
      this.state.data[collectionName].hasError &&
      !this.state.data[collectionName].mediaItems.some((mediaItem) =>
        MediaItem.getIsLoading({ mediaItem }),
      )
    );
  }

  addCollectionItem({
    collectionName = mandatory('collectionName'),
    index = mandatory('index'),
    mediaId = mandatory('mediaId'),
    mediaItem = mandatory('mediaItem'),
  } = {}) {
    logger.info(
      `NewsFeed:addCollectionItem - collection ${collectionName} index ${index} media id ${mediaId}`,
    );

    this.setState((prevState) => {
      let newFeed = Immutable.asMutable(prevState.data[collectionName], {
        deep: true,
      });
      newFeed = queue.addItem({ feed: newFeed, index, mediaId, mediaItem });
      if (isNull(newFeed)) {
        return { data: prevState.data };
      }
      return {
        data: prevState.data
          .setIn([collectionName, 'mediaIds'], Immutable(newFeed.mediaIds))
          .setIn([collectionName, 'mediaItems'], Immutable(newFeed.mediaItems))
          .setIn([collectionName, 'itemsRendered'], newFeed.itemsRendered),
      };
    });
  }

  /**
   * Check and display a single error message if we have had a number of
   * failed requests for a particular collection.
   */
  checkBackgroundRequestErrorMessage() {
    const BACKGROUND_ERROR_COLLECTION_NAMES = [
      COLLECTION_NAMES.ARTICLE_FEED,
      COLLECTION_NAMES.SCHEDULE_QUEUE,
      COLLECTION_NAMES.APPROVALS,
      COLLECTION_NAMES.LAST_SHARED,
      COLLECTION_NAMES.FAILED_SHARES,
    ];
    const errorCountExceedsLimit = BACKGROUND_ERROR_COLLECTION_NAMES.some(
      (collectionName) =>
        this.state.data[collectionName].errors.length >=
        REFRESH_ERROR_THRESHOLD,
    );

    // If it's already visible, check if we need to disabled
    if (this.backgroundRequestErrorId && !errorCountExceedsLimit) {
      this.props.flashMessages.deleteMessage({
        id: this.backgroundRequestErrorId,
      });
      this.backgroundRequestErrorId = null;
    }
    // Otherwise, check if we need to enable it.
    else if (!this.backgroundRequestErrorId && errorCountExceedsLimit) {
      const errors = BACKGROUND_ERROR_COLLECTION_NAMES.map((col) => {
        return this.state.data[col].errors.map((err) => stripKeys(err));
      });

      logger.error({
        event: 'Multiple background update failures',
        properties: {
          errors,
        },
      });
      this.backgroundRequestErrorId = this.props.flashMessages.addMessage({
        messageCategory: 'Background updates failing',
        type: FLASH_MESSAGE_TYPES.WARNING,
        text: 'Unable to refresh Echobox. Data shown on this screen may be a few minutes out of date. Please wait or log in again.',
      });
    }
  }

  checkForAnalyticsPageUpdates() {
    this.checkForUpdates({
      collectionName: COLLECTION_NAMES.ANALYTICS_PAGE,
      isCompleteRefresh: false,
    });
  }

  checkForArticleFeedUpdates() {
    this.checkForUpdates({
      collectionName: COLLECTION_NAMES.ARTICLE_FEED,
      isCompleteRefresh: false,
    });
  }

  checkForScheduleQueueUpdates() {
    this.checkForUpdates({
      collectionName: COLLECTION_NAMES.SCHEDULE_QUEUE,
      isCompleteRefresh: true,
    });
  }

  checkForApprovalUpdates() {
    if (isApprovalsActive()) {
      this.checkForUpdates({
        collectionName: COLLECTION_NAMES.APPROVALS,
        isCompleteRefresh: true,
      });
    }
  }

  checkForLastSharedUpdates() {
    this.checkForUpdates({
      collectionName: COLLECTION_NAMES.LAST_SHARED,
      isCompleteRefresh: false,
    });
    this.setState((prevState) => ({
      data: prevState.data.set('currentTime', getUnixTimestamp()),
    }));
  }

  checkForFailedSharesUpdates() {
    this.checkForUpdates({
      collectionName: COLLECTION_NAMES.FAILED_SHARES,
      isCompleteRefresh: false,
    });
  }

  checkForUpdates({
    collectionName = mandatory('collectionName'),
    isCompleteRefresh = mandatory('isCompleteRefresh'),
  } = {}) {
    if (this.props.isOffline) {
      return;
    }

    if (
      collectionName === COLLECTION_NAMES.FAILED_SHARES &&
      isFailedSharesEnabled()
    ) {
      return;
    }

    logger.info(`NewsFeed:checkForUpdates - ${collectionName}`);

    if (isCompleteRefresh) {
      this.getFeedData({
        collectionName,
        isCompleteRefresh,
        backgroundUpdate: true,
      });
      return;
    }

    const config = this.getMediaListConfig({
      collectionName,
    });
    config.origin = 'NewsFeed:checkForUpdates';
    getMediaList(config)
      .then((mediaListResult) => {
        const accountAPIId = getCurrentAccountAPIId();

        const createMediaItem = ({ mediaId = mandatory('mediaId') } = {}) => {
          let mediaItem = MediaItem.initialise({
            accountAPIId,
            backend: {
              mediaId,
              postType: POST_TYPES.UNKNOWN,
              state: config.state,
            },
          });
          mediaItem = MediaItem.setIsLoading({
            mediaItem,
            fieldValue: true,
          });
          return mediaItem;
        };

        const [updatedFeed, mediaIdsAdded] = refresh({
          feed: this.state.data[collectionName],
          mediaIds: mediaListResult.mediaIds,
          createMediaItem,
          maxItemsToAdd: MAX_REFRESH_ITEMS_TO_ADD,
          isCompleteRefresh,
        });

        this.setState(
          (prevState) => ({
            data: prevState.data
              .setIn(
                [collectionName, 'mediaIds'],
                Immutable(updatedFeed.mediaIds),
              )
              .setIn(
                [collectionName, 'mediaItems'],
                Immutable(updatedFeed.mediaItems),
              )
              .setIn(
                [collectionName, 'itemsRendered'],
                Immutable(updatedFeed.itemsRendered),
              )
              .setIn([collectionName, 'errors'], []),
          }),
          async () => {
            if (mediaIdsAdded.length > 0) {
              logger.info(`NewsFeed:checkForUpdates - added ${mediaIdsAdded}`);

              this.loadMediaItems({
                collectionName,
                mediaIds: mediaIdsAdded,
              });
            }
            return null;
          },
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState((prevState) => ({
          data: prevState.data
            .setIn([collectionName, 'hasError'], true)
            .setIn([collectionName, 'isLoading'], false)
            .setIn(
              [collectionName, 'errors'],
              [...prevState.data[collectionName].errors, error],
            ),
        }));
      })
      .finally(() => {
        const feedData = this.state.data[collectionName];
        if (feedData.isInitialised) {
          this.checkBackgroundRequestErrorMessage();
        }
      });
  }

  checkLoadMoreItems() {
    if (typeof $ !== 'undefined') {
      const collectionName = this.getCollectionName();
      const pageName = this.getPageName();
      let containerName;
      switch (pageName) {
        case 'analytics':
          containerName = '.analytics';
          break;
        case 'archive':
          containerName = '.archive';
          break;
        default:
          containerName = '.feed';
      }
      const bottomMargin = this.getPageName() === 'share' ? 80 : 60;
      const contentHeight = $(containerName).height();
      const windowHeight = $('body').height();
      const collectionData = this.state.data[collectionName];
      if (
        contentHeight - bottomMargin < windowHeight &&
        collectionData.itemsRendered < collectionData.mediaIds.length
      ) {
        const itemsToLoad = this.state.data[collectionName].itemsPerPage;
        logger.info(
          `NewsFeed:checkLoadMoreItems - adding ${itemsToLoad} items to ${collectionName}...`,
        );
        this.loadMoreItems({
          collectionName,
          itemsToLoad,
        });
      }
    }
  }

  deleteCollectionItem({
    collectionName = mandatory('collectionName'),
    index = mandatory('index'),
  } = {}) {
    logger.info(
      `NewsFeed:deleteCollectionItem - deleting item ${index} from ${collectionName}`,
    );

    this.setState((prevState) => {
      let newFeed = Immutable.asMutable(prevState.data[collectionName], {
        deep: true,
      });
      newFeed = queue.deleteItem({ feed: newFeed, index });
      if (isNull(newFeed)) {
        return { data: prevState.data };
      }
      return {
        data: prevState.data
          .setIn([collectionName, 'mediaIds'], Immutable(newFeed.mediaIds))
          .setIn([collectionName, 'mediaItems'], Immutable(newFeed.mediaItems))
          .setIn([collectionName, 'itemsRendered'], newFeed.itemsRendered),
      };
    });
  }

  findMediaItem({
    collectionName = mandatory('collectionName'),
    mediaId = mandatory('mediaId'),
  }) {
    if (collectionName === COLLECTION_NAMES.COMPOSE) {
      throw new Error("NO, DON'T DO THAT YOU SILLY MAN");
    }
    const index = this.getMediaItemIndex({ collectionName, mediaId });
    if (isDefined(index) && index !== -1) {
      return this.state.data[collectionName].mediaItems[index];
    }
    return null;
  }

  static getPageContentScrollY() {
    return $('#page-content').scrollTop();
  }

  static getDisplayOptions() {
    const displayOptionsJSON = sessionStorage.getItem('displayOptions');
    let displayOptions;
    // Check for stored display options
    if (!isNull(displayOptionsJSON)) {
      displayOptions = JSON.parse(displayOptionsJSON);
      if (
        isUndefined(displayOptions.articleFeed) ||
        isUndefined(displayOptions.scheduleQueue) ||
        isUndefined(displayOptions.approvals) ||
        isUndefined(displayOptions.lastShared) ||
        isUndefined(displayOptions.analyticsPage) ||
        isUndefined(displayOptions.deletedItems) ||
        isUndefined(displayOptions.failedShares) ||
        isUndefined(displayOptions.articleFeedState) ||
        isUndefined(displayOptions.lastRefreshAt) ||
        displayOptions.lastRefreshAt.toString() < '1525430425'
      ) {
        displayOptions = null;
      }
    }
    // Set defaults if display options are missing or incomplete
    if (isNullOrUndefined(displayOptions)) {
      displayOptions = {
        articleFeed: {
          timeFrame: '12',
          sortBy: 'potential',
          sortOrder: 'DESC',
          filterBy: 'all',
        },
        scheduleQueue: {
          timeFrame: '168',
          sortBy: 'approxsharetime',
          sortOrder: 'ASC',
          filterBy: 'all',
        },
        approvals: {
          timeFrame: '168',
          sortBy: 'potential',
          sortOrder: 'DESC',
          filterBy: 'all',
        },
        lastShared: {
          timeFrame: '12',
          sortBy: 'date',
          sortOrder: 'DESC',
          filterBy: 'all',
        },
        analyticsPage: {
          timeFrame: '12',
          sortBy: 'date',
          sortOrder: 'DESC',
          filterBy: 'all',
        },
        deletedItems: {
          timeFrame: '12',
          sortBy: 'date',
          sortOrder: 'DESC',
          filterBy: 'all',
        },
        failedShares: {
          timeFrame: FAILED_SHARE_DURATION,
          sortBy: 'date',
          sortOrder: 'DESC',
          filterBy: 'all',
        },
        articleArchive: {
          timeField: 'publish',
          timeFrame: '168',
          sortBy: 'DATE_PUBLISHED',
          sortOrder: 'DESC',
          filterBy: '',
          selectedCategories: [],
        },
        articleFeedState: FEED_STATES.NEW,
        lastRefreshAt: getUnixTimestamp().toString(),
      };
    }
    if (isUndefined(displayOptions.articleArchive)) {
      displayOptions.articleArchive = {
        timeField: 'publish',
        timeFrame: '168',
        sortBy: 'DATE_PUBLISHED',
        sortOrder: 'DESC',
        filterBy: '',
        selectedCategories: [],
      };
    }
    // Calculate timing options for all collections
    [
      COLLECTION_NAMES.ARTICLE_FEED,
      COLLECTION_NAMES.SCHEDULE_QUEUE,
      COLLECTION_NAMES.APPROVALS,
      COLLECTION_NAMES.LAST_SHARED,
      COLLECTION_NAMES.ANALYTICS_PAGE,
      COLLECTION_NAMES.DELETED_ITEMS,
      COLLECTION_NAMES.FAILED_SHARES,
      COLLECTION_NAMES.ARTICLE_ARCHIVE,
    ].forEach((collectionName) => {
      const displayTiming = NewsFeed.getDisplayTiming({
        collectionDisplayOptions: displayOptions[collectionName],
      });
      displayOptions[collectionName].fromTime = displayTiming.fromTime;
      displayOptions[collectionName].toTime = displayTiming.toTime;
    });
    // Restore article feed time filter from local storage
    const timeframeFilter = localStorage.getItem('timeframeFilter');
    if (!isNull(timeframeFilter)) {
      displayOptions[COLLECTION_NAMES.ARTICLE_FEED].timeFrame = timeframeFilter;
      localStorage.removeItem('timeframeFilter');
    }

    // Store updated display options
    sessionStorage.setItem('displayOptions', JSON.stringify(displayOptions));

    // Modify display options for tiktok
    const isTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;
    if (isTikTok) {
      displayOptions.articleFeedState = FEED_STATES.DRAFT;
      displayOptions.articleFeed = {
        ...displayOptions.articleFeed,
        articleFeedState: FEED_STATES.DRAFT,
        filterBy: 'draft',
      };
    }

    return displayOptions;
  }

  static getDisplayTiming({
    collectionDisplayOptions = mandatory('collectionDisplayOptions'),
  }) {
    if (collectionDisplayOptions.timeFrame === 'CUSTOM') {
      if (
        isNull(collectionDisplayOptions.fromTime) ||
        isNull(collectionDisplayOptions.toTime)
      ) {
        const { defaultFrom, defaultTo } = defaultDateRange();
        return {
          fromTime: defaultFrom,
          toTime: defaultTo,
        };
      }
      return {
        fromTime: collectionDisplayOptions.fromTime,
        toTime: collectionDisplayOptions.toTime,
      };
    }
    const fromTime = getTimeFilterRange(
      collectionDisplayOptions.timeFrame,
    ).fromTime;
    const toTime = null;
    return { fromTime, toTime };
  }

  getCollectionName() {
    const pageName = this.getPageName();
    switch (pageName) {
      case 'share':
        if (this.state.data.articleFeedState === FEED_STATES.DELETED) {
          return COLLECTION_NAMES.DELETED_ITEMS;
        }
        return COLLECTION_NAMES.ARTICLE_FEED;
      case 'analytics':
        return COLLECTION_NAMES.ANALYTICS_PAGE;
      case 'archive':
        return COLLECTION_NAMES.ARTICLE_ARCHIVE;
      default:
        throw new ReferenceError(
          'Unknown page name in call to getCollectionName',
        );
    }
  }

  getCollectionNameFromState(targetState) {
    const pageName = this.getPageName();
    switch (targetState) {
      case MEDIA_ITEM_STATES.NEW:
        return COLLECTION_NAMES.ARTICLE_FEED;
      case MEDIA_ITEM_STATES.SCHEDULED:
        return COLLECTION_NAMES.SCHEDULE_QUEUE;
      case MEDIA_ITEM_STATES.SHARED:
        return pageName === 'analytics'
          ? COLLECTION_NAMES.ANALYTICS_PAGE
          : COLLECTION_NAMES.LAST_SHARED;
      case MEDIA_ITEM_STATES.DELETED:
        return COLLECTION_NAMES.DELETED_ITEMS;
      case MEDIA_ITEM_STATES.FAILED:
        return COLLECTION_NAMES.FAILED_SHARES;
      case MEDIA_ITEM_STATES.ARCHIVED:
        return COLLECTION_NAMES.ARTICLE_ARCHIVE;
      case MEDIA_ITEM_STATES.APPROVAL_REQUIRED:
        return COLLECTION_NAMES.APPROVALS;
      default:
        throw new ReferenceError(
          'Unknown targetState passed to getCollectionName',
        );
    }
  }

  getFeedData({
    collectionName = mandatory('collectionName'),
    isCompleteRefresh = false,
    requireCategoryRefresh = true,
    backgroundUpdate = false,
  } = {}) {
    if (
      collectionName === COLLECTION_NAMES.ANALYTICS_PAGE &&
      this.state.data[collectionName].displayOptions.timeFrame === 'CUSTOM' &&
      isNull(this.state.data[collectionName].displayOptions.fromTime) &&
      isNull(this.state.data[collectionName].displayOptions.toTime)
    ) {
      return;
    }

    if (
      collectionName === COLLECTION_NAMES.FAILED_SHARES &&
      isFailedSharesEnabled()
    ) {
      return;
    }

    const refreshCategoryConfig =
      collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE &&
      requireCategoryRefresh;

    // Reset categories dropdown
    if (refreshCategoryConfig) {
      this.setState((prevState) => {
        const displayOptions = Immutable.asMutable(
          prevState.data[collectionName].displayOptions,
        );
        displayOptions.selectedCategories = [];
        return {
          data: prevState.data
            .setIn([collectionName, 'categories'], [])
            .setIn([collectionName, 'displayOptions'], displayOptions)
            .setIn([collectionName, 'isLoadingCategory'], true),
        };
      });
    }

    const configParam = { collectionName };
    if (refreshCategoryConfig) {
      configParam.categories = [];
    }
    const webserviceConfig = this.getMediaListConfig(configParam);
    webserviceConfig.origin = 'NewsFeed:getFeedData';

    this.setState(
      (prevState) => ({
        data: prevState.data.setIn([collectionName, 'hasError'], false).setIn(
          [collectionName, 'isLoading'],
          !isCompleteRefresh, // Don't show loading spinner if refreshing entire queue
        ),
      }),
      async () => {
        // Get list of media ids
        let mediaList;
        try {
          mediaList = await getMediaList(webserviceConfig);
        } catch (error) {
          if (backgroundUpdate) {
            this.setState((prevState) => ({
              data: prevState.data.setIn(
                [collectionName, 'errors'],
                [...prevState.data[collectionName].errors, error],
              ),
            }));
            this.checkBackgroundRequestErrorMessage();
          }
          if (isNull(error)) {
            this.setState((prevState) => ({
              data: prevState.data
                .setIn([collectionName, 'hasError'], true)
                .setIn([collectionName, 'isLoading'], false),
            }));
            return null; // Not sure how this happens but it does occasionally... ignore null errors we can't do anything sensible with
          }
          if (
            isUndefined(error.message) ||
            error.message.indexOf('EBX:INTERNAL') === -1
          ) {
            return this.handleError({
              collectionName,
              error,
              displayError: !backgroundUpdate,
            });
          }
        }

        if (isUndefined(mediaList)) {
          this.setState((prevState) => ({
            data: prevState.data
              .setIn([collectionName, 'hasError'], true)
              .setIn([collectionName, 'isLoading'], false),
          }));
          return null; // Exit here if an EBX:INTERNAL error has occurred
        }

        logger.info(
          `NewsFeed:getFeedData - returned ${mediaList.mediaIds.length} ${collectionName} items`,
        );

        // Check for (and report) duplicates
        if (
          !isNullOrUndefined(mediaList.mediaIds) &&
          mediaList.mediaIds.length !== 0
        ) {
          const duplicates = mediaList.mediaIds.filter(
            (value, index) => mediaList.mediaIds.indexOf(value) !== index,
          );
          if (duplicates.length !== 0) {
            logger.error({
              event: 'News Feed Item Duplication',
              properties: {
                collectionName,
                duplicateIds: JSON.stringify(duplicates),
              },
            });
          }
        }

        // Get list of media items we need to retrieve
        const mediaIds = [];
        let mediaItems = [];
        const { accountAPIId } = webserviceConfig;
        if (isCompleteRefresh) {
          // While reloading just show the existing queue items
          mediaItems = this.state.data[collectionName].mediaItems;
        }
        mediaList.mediaIds.forEach((mediaId, idx) => {
          if (
            idx <
            this.state.data[collectionName].itemsRendered +
              this.state.data[collectionName].itemsPerPage
          ) {
            // Update the list of media items to retrieve
            mediaIds.push(mediaId);
            if (!isCompleteRefresh) {
              // While loading show a series of "loading" media items
              let mediaItem = MediaItem.initialise({
                accountAPIId,
                backend: {
                  mediaId,
                  postType: POST_TYPES.UNKNOWN,
                  state: webserviceConfig.state,
                },
              });
              mediaItem = MediaItem.setIsLoading({
                mediaItem,
                fieldValue: true,
              });
              mediaItems.push(mediaItem);
            }
          }
        });

        // Update collection data
        this.setState(
          (prevState) => ({
            data: prevState.data
              .setIn(
                [collectionName, 'mediaIds'],
                Immutable(mediaList.mediaIds),
              )
              .setIn([collectionName, 'mediaItems'], Immutable(mediaItems))
              .setIn([collectionName, 'hasError'], false)
              .setIn([collectionName, 'isInitialised'], true)
              .setIn([collectionName, 'isLoading'], false)
              .setIn([collectionName, 'itemsRendered'], mediaItems.length)
              .setIn([collectionName, 'errors'], []),
          }),
          async () => {
            if (backgroundUpdate) {
              this.checkBackgroundRequestErrorMessage();
            }

            this.loadMediaItems({
              collectionName,
              mediaIds,
              mediaList,
            });

            return null;
          },
        );
        if (
          collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE &&
          requireCategoryRefresh
        ) {
          // Load categories
          const categoriesConfig = this.getCategoriesConfig({
            collectionName,
          });
          let categoryList;
          try {
            categoryList = await getMediaItemCategories(categoriesConfig);
          } catch (error) {
            // Just log the error and not affect Archive page
            logger.info(
              `Error in getMediaItemCategories: ${JSON.stringify(error)}`,
            );
            // handle categories error
            this.handleLoadingCategoriesError({ collectionName });
            return null;
          } finally {
            this.setState((prevState) => ({
              data: prevState.data.setIn(
                [collectionName, 'isLoadingCategory'],
                false,
              ),
            }));
          }
          if (
            Array.isArray(categoryList?.categories) &&
            categoryList?.categories.length > 0
          ) {
            this.setState((prevState) => ({
              data: prevState.data.setIn(
                [collectionName, 'categories'],
                Immutable(categoryList.categories),
              ),
            }));
          } else {
            this.setState((prevState) => ({
              data: prevState.data.setIn([collectionName, 'categories'], []),
            }));
          }
        }
        return null;
      },
    );
  }

  getMediaItemIndex({
    collectionName = mandatory('collectionName'),
    mediaId = mandatory('mediaId'),
  }) {
    if (collectionName === COLLECTION_NAMES.COMPOSE) {
      throw new Error("NO, DON'T DO THAT YOU SILLY MAN");
    }
    return this.state.data[collectionName].mediaItems
      .filter(Boolean)
      .findIndex(
        (mediaItem) => MediaItem.getMediaId({ mediaItem }) === mediaId,
      );
  }

  getMediaListConfig({
    collectionName = mandatory('collectionName'),
    categories,
  }) {
    const displayOptions = this.state.data[collectionName].displayOptions;
    const feedState = this.state.data.articleFeedState;
    const displayTiming = NewsFeed.getDisplayTiming({
      collectionDisplayOptions: displayOptions,
    });
    switch (collectionName) {
      case COLLECTION_NAMES.ARTICLE_FEED:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.NEW,
          from: feedState === FEED_STATES.DRAFT ? null : displayTiming.fromTime,
          to: feedState === FEED_STATES.DRAFT ? null : displayTiming.toTime,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
        };
      case COLLECTION_NAMES.SCHEDULE_QUEUE:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.SCHEDULED,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
          sortOrder: displayOptions.sortOrder,
        };
      case COLLECTION_NAMES.APPROVALS:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.APPROVAL_REQUIRED,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
          sortOrder: displayOptions.sortOrder,
        };
      case COLLECTION_NAMES.LAST_SHARED:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.SHARED,
          from: displayTiming.fromTime,
          to: displayTiming.toTime,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
          sortOrder: displayOptions.sortOrder,
        };
      case COLLECTION_NAMES.ANALYTICS_PAGE:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.SHARED,
          from: displayTiming.fromTime,
          to: displayTiming.toTime,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
          sortOrder: displayOptions.sortOrder,
        };
      case COLLECTION_NAMES.DELETED_ITEMS:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.DELETED,
          from: displayTiming.fromTime,
          to: displayTiming.toTime,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
        };
      case COLLECTION_NAMES.FAILED_SHARES:
        return {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.FAILED,
          from: displayTiming.fromTime,
          to: displayTiming.toTime,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
        };
      case COLLECTION_NAMES.ARTICLE_ARCHIVE: {
        const selectedCategories =
          categories ?? displayOptions.selectedCategories;
        const config = {
          accountAPIId: getCurrentAccountAPIId(),
          state: MEDIA_ITEM_STATES.ARCHIVED,
          filter: displayOptions.filterBy,
          sort: displayOptions.sortBy,
          sortOrder: displayOptions.sortOrder,
          categories: selectedCategories,
        };
        const timeRange = createTimeRange(
          displayTiming.fromTime,
          displayTiming.toTime,
        );
        switch (displayOptions.timeField) {
          case 'share':
            config.shareFromToTimes = timeRange;
            break;
          case 'publish':
            config.publishFromToTimes = timeRange;
            break;
          case 'fail':
            config.failFromToTimes = timeRange;
            break;
          case 'delete':
            config.deleteFromToTimes = timeRange;
            break;
          default:
          //
        }
        return config;
      }
      default:
        throw new ReferenceError(
          'Unknown collectionName passed to getMediaListConfig',
        );
    }
  }

  getCategoriesConfig({ collectionName = mandatory('collectionName') }) {
    const displayOptions = this.state.data[collectionName].displayOptions;
    const displayTiming = NewsFeed.getDisplayTiming({
      collectionDisplayOptions: displayOptions,
    });
    if (collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE) {
      const socialPageURN = getCurrentSocialPageURN();
      const config = {
        socialPageURN,
        state: MEDIA_ITEM_STATES.ARCHIVED,
        filter: displayOptions.filterBy,
      };
      const timeRange = createTimeRange(
        displayTiming.fromTime,
        displayTiming.toTime,
      );
      switch (displayOptions.timeField) {
        case 'share':
          config.shareFromToTimes = timeRange;
          break;
        case 'publish':
          config.publishFromToTimes = timeRange;
          break;
        default:
        //
      }
      return config;
    }
    // Categories filter is allowed to ARCHIVED state only
    throw new ReferenceError(
      'Unknown collectionName passed to getCategoriesConfig',
    );
  }

  getPageName() {
    const loc = this.props.location;
    if (isOnPage({ page: FRONTEND_PAGES.ANALYTICS, location: loc })) {
      return 'analytics';
    }
    if (isOnPage({ page: FRONTEND_PAGES.ARCHIVE, location: loc })) {
      return 'archive';
    }
    return 'share';
  }

  static getTabName() {
    const pathname = location.pathname.replace('/', '');
    return pathname === '' ? 'share' : pathname;
  }

  static findFailedMediaId(message = mandatory('message')) {
    const results =
      /Error: (.*)\. This item has already been shared and can no longer be edited through Echobox/.exec(
        message,
      );
    return results?.[1];
  }

  removeFailedMediaItem({
    collectionName = mandatory('collectionName'),
    failedMediaId = mandatory('failedMediaId'),
  }) {
    this.setState((prevState) => {
      const { mediaIds: mIds, mediaItems } = prevState.data[collectionName];
      const index = this.getMediaItemIndex({
        collectionName,
        mediaId: failedMediaId,
      });
      return {
        data: prevState.data
          .setIn(
            [collectionName, 'mediaIds'],
            mIds.filter((id) => id !== failedMediaId),
          )
          .setIn(
            [collectionName, 'mediaItems'],
            [...mediaItems.slice(0, index), ...mediaItems.slice(index + 1)],
          ),
      };
    });
  }

  handleError({
    collectionName = mandatory('collectionName'),
    error = mandatory('error'),
    mediaIds = [],
    retryCallback,
    retryAttempt = 0,
    displayError = true,
  } = {}) {
    if (!isRunningTests()) {
      if (error === COGNITO_ERROR_MESSAGES.INVALID_SESSION) {
        this.props.navigate(ROUTE_REDIRECTIONS.LOGOUT);
      }
    }

    // If one particular media ID is included in the response,
    // then remove it, suppress the error and attempt to fetch again.
    const message = getErrorMessage(error);
    if (message && retryCallback) {
      const failedMediaId = NewsFeed.findFailedMediaId(message);
      if (failedMediaId) {
        this.removeFailedMediaItem({
          collectionName,
          failedMediaId,
        });
        if (retryAttempt < 3) {
          retryCallback(mediaIds.filter((id) => id !== failedMediaId));
          return;
        }
      }
    }

    this.setState((prevState) => ({
      data: prevState.data
        .setIn([collectionName, 'hasError'], true)
        .setIn([collectionName, 'isLoading'], false),
    }));
    const feedData = this.state.data[collectionName];

    if (feedData.isInitialised) {
      if (displayError) {
        this.props.flashMessages.addMessage({
          messageCategory: 'Error updating feed data in Newsfeed',
          type: FLASH_MESSAGE_TYPES.ERROR,
          text: message,
        });
      }
    } else {
      this.props.flashMessages.addMessage({
        messageCategory: 'Error initialising feed data in Newsfeed',
        type: FLASH_MESSAGE_TYPES.INFO,
        text: 'Please refresh page',
      });
    }
  }

  handleLoadingCategoriesError({
    collectionName = mandatory('collectionName'),
  } = {}) {
    if (
      collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE &&
      !this.state.data.articleArchive.hasError
    ) {
      this.props.flashMessages.addMessage({
        messageCategory: 'Categories cannot be loaded',
        type: FLASH_MESSAGE_TYPES.INFO,
        text: 'Categories cannot be loaded. Please refresh the page.',
      });
    }
  }

  initialiseComponent() {
    if (isUndefined(window.intervals)) {
      window.intervals = {};
    }

    const pageName = this.getPageName();
    if (pageName !== 'analytics') {
      this.debouncedInfiniteLoad = debounce(100, this.debouncedInfiniteLoad);

      $('#page-content').on('scroll', this.debouncedInfiniteLoad);
    }

    // Any page interaction should reset the periodic page refresh
    window.addEventListener('click', this.resetIntervalOnEvent.bind(this));
    window.addEventListener('keypress', this.resetIntervalOnEvent.bind(this));
    window.addEventListener('wheel', this.resetIntervalOnEvent.bind(this));

    this.initializeBackgroundIntervals();

    // Scroll to top if necessary
    scrollToTop();

    const updatedTracking = getAutofeedSettingsTrackingParams({
      autofeedSettings: getAutofeedSettings(),
    });
    const imageOverlaySetting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.IMAGE_OVERLAY,
      propertyId: getCurrentPropertyId(),
    });
    const accountAPIId = getCurrentAccountAPIId();
    tracker.track({
      eventName: 'Home Page',
      trackingParams: {
        ...updatedTracking,
        'Image Overlay Uploaded?':
          !!imageOverlaySetting?.dataJSON?.imageOverlayURL,
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
      },
    });
  }

  initializeBackgroundIntervals() {
    // Ensure that all previous intervals are cleared as this can be toggled on/off
    if (
      isOnPage({ page: FRONTEND_PAGES.SHARE, location: this.props.location })
    ) {
      if (!isNull(window.intervals.reloadArticleFeed)) {
        window.clearInterval(window.intervals.reloadArticleFeed);
        window.intervals.reloadArticleFeed = null;
      }
      if (!isNull(window.intervals.reloadScheduleQueue)) {
        window.clearInterval(window.intervals.reloadScheduleQueue);
        window.intervals.reloadScheduleQueue = null;
      }
      if (!isNull(window.intervals.reloadApprovals)) {
        window.clearInterval(window.intervals.reloadApprovals);
        window.intervals.reloadApprovals = null;
      }
      if (!isNull(window.intervals.reloadLastShared)) {
        window.clearInterval(window.intervals.reloadLastShared);
        window.intervals.reloadLastShared = null;
      }
      if (!isNull(window.intervals.reloadFailedShares)) {
        window.clearInterval(window.intervals.reloadFailedShares);
        window.intervals.reloadFailedShares = null;
      }
    }

    // Allow for auto-refresh to be disabled
    if (!sessionStorage.getItem('disableAutoRefresh')) {
      if (
        isOnPage({ page: FRONTEND_PAGES.SHARE, location: this.props.location })
      ) {
        window.intervals.reloadArticleFeed = window.setInterval(
          this.checkForArticleFeedUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.ARTICLE_FEED),
        );
        window.intervals.reloadScheduleQueue = window.setInterval(
          this.checkForScheduleQueueUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.SCHEDULE_QUEUE),
        );
        window.intervals.reloadApprovals = window.setInterval(
          this.checkForApprovalUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.APPROVALS),
        );
        window.intervals.reloadLastShared = window.setInterval(
          this.checkForLastSharedUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.LAST_SHARED),
        );
        window.intervals.reloadFailedShares = window.setInterval(
          this.checkForFailedSharesUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.LEGACY_FAILED_SHARES),
        );
      }
    }
  }

  static clearBackgroundIntervals() {
    window.clearInterval(window.intervals.reloadArticleFeed);
    window.clearInterval(window.intervals.reloadScheduleQueue);
    window.clearInterval(window.intervals.reloadApprovals);
    window.clearInterval(window.intervals.reloadLastShared);
    window.clearInterval(window.intervals.reloadFailedShares);
    window.intervals.reloadArticleFeed = null;
    window.intervals.reloadScheduleQueue = null;
    window.intervals.reloadApprovals = null;
    window.intervals.reloadLastShared = null;
    window.intervals.reloadFailedShares = null;
  }

  async loadMediaItems({
    collectionName = mandatory('collectionName'),
    mediaIds = mandatory('mediaIds'),
    mediaList,
    retryAttempt = 0,
  } = {}) {
    const accountAPIId = getCurrentAccountAPIId();
    const apiTypeId = getAPITypeId({ accountAPIId });
    const webserviceConfig = this.getMediaListConfig({
      collectionName,
    });
    webserviceConfig.origin = 'NewsFeed:loadMoreItems';

    // Only ever get the data for the first Approval as only this is shown
    if (collectionName === COLLECTION_NAMES.APPROVALS && mediaIds.length > 0) {
      mediaIds = [mediaIds[0]];
    }

    const extraInsights = [];
    // If we are on the analytics page, we need to include any extra insights
    if (collectionName === COLLECTION_NAMES.ANALYTICS_PAGE) {
      const displayOptions = NewsFeed.getDisplayOptions();
      const networkName = getSocialNetworkName({
        apiTypeId,
        textCase: TEXT_CASES.LOWER,
      });
      const configuredInsights =
        displayOptions.analyticsPage.insightColumns?.[networkName];
      if (configuredInsights?.length) {
        // Get the default insights for this network
        const defaultInsights = Object.entries(
          getSocialAnalyticsInsights(apiTypeId),
        )
          .filter(([, v]) => v.default)
          .map(([k]) => k);

        // Filter out the defaults
        const filteredInsights = configuredInsights?.filter(
          (i) => !defaultInsights.includes(i),
        );

        if (filteredInsights.length) {
          extraInsights.push(
            ...filteredInsights
              // Prefix the insight with the short network name
              .map((i) => `${getURNName({ apiTypeId })}${i}`),
          );
        }
      }
    }

    // Fetch media items
    const mediaIdGroups = chunkArray(
      cloneArray(mediaIds),
      MAX_MEDIA_ITEMS_IN_BATCH,
    );
    const requests = [];
    mediaIdGroups.forEach((mediaIdGroup) => {
      requests.push(
        getMediaItems({
          accountAPIId,
          apiTypeId,
          state: webserviceConfig.state,
          mediaIds: mediaIdGroup,
          fields: COLLECTION_FIELDS[collectionName],
          getPageInfo: hasMentionsLookups({ apiTypeId }),
          extraInsights,
        }),
      );
    });
    let responses;
    try {
      responses = await Promise.all(requests);
    } catch (error) {
      return this.handleError({
        collectionName,
        error,
        mediaIds,
        retryCallback: (mIds) => {
          // If we are retrying, it likely that one
          // particular media ID failed. So make sure to use the
          // new list of mediaIds.
          mediaList.mediaIds = mIds;
          this.loadMediaItems({
            mediaList,
            collectionName,
            mediaIds: mIds,
            retryAttempt: retryAttempt + 1,
          });
        },
        retryAttempt,
      });
    }

    // Populate feed
    if (isDefined(mediaList)) {
      // Using flushSync here so that the state upates before the next state update
      // Otherwise, the next state update may use stale indices, which can cause problems
      // like https://echobox.atlassian.net/browse/HELP-4496
      flushSync(() => {
        this.setState((prevState) => {
          const loadedItems = [];
          responses.forEach((responseGroup) => {
            Object.keys(responseGroup).forEach((mediaId) => {
              const mediaItem = MediaItem.initialise(responseGroup[mediaId]);
              // NOTE - use the sequence of the media item in the updated
              //        list of media ids, at this point the list of
              //        media items is still in the old sequence and therefore
              //        using getMediaItemIndex will return the old position
              const index = mediaList.mediaIds.indexOf(mediaId);
              // Add the item to the collection in the relevant position
              loadedItems[index] = mediaItem;
            });
          });
          return {
            data: prevState.data
              .setIn([collectionName, 'mediaItems'], loadedItems)
              .setIn([collectionName, 'itemsRendered'], loadedItems.length),
          };
        });
      });
    }
    // Update feed
    this.setState((prevState) => {
      let nextData = prevState.data;
      responses.forEach((responseGroup) => {
        Object.keys(responseGroup).forEach((mediaId) => {
          // Create media item
          const mediaItem = MediaItem.initialise(responseGroup[mediaId]);
          const index = this.getMediaItemIndex({
            collectionName,
            mediaId,
          });
          if (index !== -1) {
            nextData = nextData.setIn(
              [collectionName, 'mediaItems', index],
              mediaItem,
            );
          }
        });
      });
      return { data: nextData };
    });

    return null;
  }

  async loadMoreItems({
    collectionName = mandatory('collectionName'),
    itemsToLoad = 1,
  }) {
    const mediaIdsAdded = [];
    this.setState(
      (prevState) => {
        const mediaIds = prevState.data[collectionName].mediaIds;
        const mediaItems = Immutable.asMutable(
          prevState.data[collectionName].mediaItems,
        );
        let itemsRendered = prevState.data[collectionName].itemsRendered;
        let itemsAdded = 0;
        const currentState =
          collectionName === COLLECTION_NAMES.ARTICLE_FEED
            ? MEDIA_ITEM_STATES.NEW
            : MEDIA_ITEM_STATES.SHARED;
        mediaIds.forEach((mediaId, idx) => {
          if (idx >= itemsRendered && idx < itemsRendered + itemsToLoad) {
            const index = this.getMediaItemIndex({ collectionName, mediaId });
            if (index === -1) {
              // Double-check that the item doesn't already exist
              let mediaItem = MediaItem.initialise({
                accountAPIId: getCurrentAccountAPIId(),
                backend: {
                  mediaId,
                  postType: POST_TYPES.UNKNOWN,
                  state: currentState,
                },
              });
              mediaItem = MediaItem.setIsLoading({
                mediaItem,
                fieldValue: true,
              });
              logger.info(
                `NewsFeed:loadMoreItems - adding media item ${mediaId}`,
              );
              mediaIdsAdded.push(mediaId);
              mediaItems.push(mediaItem);
              itemsAdded += 1;
            }
          }
        });
        if (itemsAdded > 0) {
          itemsRendered += itemsAdded;
        }
        return {
          data: prevState.data
            .setIn([collectionName, 'itemsRendered'], itemsRendered)
            .setIn([collectionName, 'mediaItems'], Immutable(mediaItems)),
        };
      },
      async () => {
        logger.info(`NewsFeed:loadMoreItems - added ${mediaIdsAdded}...`);

        await this.loadMediaItems({
          collectionName,
          mediaIds: mediaIdsAdded,
        });

        return null;
      },
    );
  }

  resetIntervalOnEvent() {
    if (
      isOnPage({ page: FRONTEND_PAGES.SHARE, location: this.props.location })
    ) {
      if (!isNull(window.intervals.reloadArticleFeed)) {
        window.clearInterval(window.intervals.reloadArticleFeed);
        window.intervals.reloadArticleFeed = window.setInterval(
          this.checkForArticleFeedUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.ARTICLE_FEED),
        );
      }
      if (!isNull(window.intervals.reloadScheduleQueue)) {
        window.clearInterval(window.intervals.reloadScheduleQueue);
        window.intervals.reloadScheduleQueue = window.setInterval(
          this.checkForScheduleQueueUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.SCHEDULE_QUEUE),
        );
      }
      if (!isNull(window.intervals.reloadApprovals)) {
        window.clearInterval(window.intervals.reloadApprovals);
        window.intervals.reloadApprovals = window.setInterval(
          this.checkForApprovalUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.APPROVALS),
        );
      }
      if (!isNull(window.intervals.reloadLastShared)) {
        window.clearInterval(window.intervals.reloadLastShared);
        window.intervals.reloadLastShared = window.setInterval(
          this.checkForLastSharedUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.LAST_SHARED),
        );
      }
      if (!isNull(window.intervals.reloadFailedShares)) {
        window.clearInterval(window.intervals.reloadFailedShares);
        window.intervals.reloadFailedShares = window.setInterval(
          this.checkForFailedSharesUpdates.bind(this),
          refreshInterval(REFRESH_MULTIPLIERS.LEGACY_FAILED_SHARES),
        );
      }
    }
  }

  static saveDisplayOptions({
    collectionName = mandatory('collectionName'),
    displayOptions = mandatory('displayOptions'),
  }) {
    const storedOptionsJSON = sessionStorage.getItem('displayOptions');
    let storedOptions;
    if (!isNull(storedOptionsJSON)) {
      storedOptions = JSON.parse(storedOptionsJSON);
      if (
        isUndefined(storedOptions.articleFeed) ||
        isUndefined(storedOptions.scheduleQueue) ||
        isUndefined(storedOptions.approvals) ||
        isUndefined(storedOptions.lastShared) ||
        isUndefined(storedOptions.analyticsPage) ||
        isUndefined(storedOptions.deletedItems) ||
        isUndefined(storedOptions.failedShares) ||
        isUndefined(storedOptions.articleArchive) ||
        isUndefined(storedOptions.articleFeedState)
      ) {
        storedOptions = null;
      }
    }
    if (isNull(storedOptions)) {
      storedOptions = {
        articleFeed: {},
        scheduleQueue: {},
        approvals: {},
        lastShared: {},
        analyticsPage: {},
        deleteItems: {},
        failedShares: {},
        articleArchive: {},
        articleFeedState: FEED_STATES.NEW,
      };
    }
    storedOptions[collectionName] = displayOptions;
    if (isDefined(displayOptions.articleFeedState)) {
      storedOptions.articleFeedState = displayOptions.articleFeedState;
    }
    sessionStorage.setItem('displayOptions', JSON.stringify(storedOptions));
    // Persist article feed time filter in local storage
    if (collectionName === COLLECTION_NAMES.ARTICLE_FEED) {
      localStorage.setItem(
        'timeframeFilter',
        storedOptions[COLLECTION_NAMES.ARTICLE_FEED].timeFrame,
      );
    }
  }

  updateAndSaveCollectionItem({
    collectionName = mandatory('collectionName'),
    mediaId = mandatory('mediaId'),
    mediaItem = mandatory('mediaItem'),
    fieldName = mandatory('fieldName'),
    fieldValue = mandatory('fieldValue'),
  } = {}) {
    logger.info(
      `NewsFeed:updateAndSaveCollectionItem - updating ${fieldName} for media id ${mediaId} in ${collectionName} to ${fieldValue}`,
    );

    const index = this.getMediaItemIndex({
      collectionName,
      mediaId,
    });
    if (index === -1) {
      return; // Media item cannot be found
    }
    let updatedItem = immutableClone(mediaItem);
    let originalValue;
    switch (fieldName) {
      case 'timeSensitivityTypeId':
        originalValue = MediaItem.getTimeSensitivityTypeId({
          mediaItem: updatedItem,
        });
        updatedItem = MediaItem.setTimeSensitivityTypeId({
          mediaItem: updatedItem,
          fieldValue,
        });
        break;
      default:
        return; // Not an updatable field
    }
    updatedItem = MediaItem.setIsSaving({
      mediaItem: updatedItem,
      fieldValue: true,
    });
    this.updateCollectionItem({
      collectionName,
      index,
      mediaId,
      mediaItem: updatedItem,
      callback: async () => {
        try {
          await postMediaItemField({
            accountAPIId: MediaItem.getAccountAPIId({ mediaItem: updatedItem }),
            state: MediaItem.getState({ mediaItem: updatedItem }),
            mediaIds: [mediaId],
            fieldName,
            fieldValue,
          });
          switch (fieldName) {
            case 'timeSensitivityTypeId': {
              const postType = MediaItem.getPostType({
                mediaItem: updatedItem,
              });
              const trackingParams = getCommonTrackingParams({
                mediaItem: updatedItem,
              });
              trackingParams.Origin = 'Archive';
              trackingParams['Time Sensitivity Before Update'] =
                postType === POST_TYPES.LINK &&
                isDefined(TIME_SENSITIVITY_NAMES[originalValue])
                  ? TIME_SENSITIVITY_NAMES[originalValue].categoryLabel
                  : 'undefined';
              trackingParams['Time Sensitivity'] =
                postType === POST_TYPES.LINK
                  ? TIME_SENSITIVITY_NAMES[fieldValue].categoryLabel
                  : 'undefined';
              tracker.track({
                eventName: 'Update Time Sensitivity',
                trackingParams,
              });
              break;
            }
            default:
            // Nothing to do
          }
        } catch (error) {
          addErrorNotification(error);
        }
        updatedItem = MediaItem.setIsSaving({
          mediaItem: updatedItem,
          fieldValue: false,
        });
        this.updateCollectionItem({
          collectionName,
          index,
          mediaId,
          mediaItem: updatedItem,
        });
      },
    });
  }

  updateCollectionItem({
    collectionName = mandatory('collectionName'),
    index = mandatory('index'),
    mediaId = mandatory('mediaId'),
    mediaItem = mandatory('mediaItem'),
    callback,
  } = {}) {
    logger.info(
      `NewsFeed:updateCollectionItem - updating media id ${mediaId} at position ${index} in ${collectionName}`,
    );

    this.setState(
      (prevState) => {
        let newFeed = Immutable.asMutable(prevState.data[collectionName], {
          deep: true,
        });
        newFeed = queue.updateItem({
          feed: newFeed,
          index,
          mediaId,
          mediaItem,
        });
        if (isNull(newFeed)) {
          return { data: prevState.data };
        }
        return {
          data: prevState.data
            .setIn([collectionName, 'mediaIds'], Immutable(newFeed.mediaIds))
            .setIn(
              [collectionName, 'mediaItems'],
              Immutable(newFeed.mediaItems),
            )
            .setIn([collectionName, 'itemsRendered'], newFeed.itemsRendered),
        };
      },
      () => {
        if (typeof callback === 'function') {
          callback();
        }
      },
    );
  }

  /**
   * Event handlers
   */

  handleApprovalSkipped({ mediaId, mediaItem } = {}) {
    logger.info('NewsFeed:handleApprovalSkipped');

    // A skip just deletes the suggestion, the media item goes back in the news feed
    this.handleArticleAction({
      collectionName: COLLECTION_NAMES.APPROVALS,
      mediaId,
      actionType: ACTION_TYPES.DELETE,
    });

    trackApprovalAction({
      mediaItem,
      action: 'Skip',
      origin: 'Approval Card',
    });
  }

  handleApprove({ mediaId, mediaItem } = {}) {
    logger.info('NewsFeed:handleApprove');

    // To approve a suggestion change the state to SCHEDULED
    const origin = 'Approval Card';

    this.handleArticleAction({
      collectionName: COLLECTION_NAMES.APPROVALS,
      mediaId,
      actionType: ACTION_TYPES.SCHEDULE,
    });

    trackApprovalAction({
      mediaItem,
      action: 'Approve',
      origin,
    });
  }

  handleArticleAction({
    collectionName = null,
    mediaId = null,
    actionType = mandatory('actionType'),
    shareOrigin,
  }) {
    // Save item in specified collection
    const mediaItem = this.findMediaItem({ collectionName, mediaId });
    if (isNull(mediaItem)) {
      return; // Do nothing if the media item no longer exists
    }
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });

    // Validate timing options
    const isTimingValid = MediaItem.getIsTimingValid({ mediaItem });
    if (!isTimingValid) {
      logger.info(
        `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in pages/NewsFeed.handleArticleAction`,
      );
      PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
        collectionName,
        mediaId,
        accountAPIId,
        error: 'Time selection invalid',
      });
      return;
    }

    // Validate URLs
    const postType = MediaItem.getPostType({ mediaItem });
    let okToContinue = true;
    if (
      postType === POST_TYPES.LINK &&
      actionType === ACTION_TYPES.SCHEDULE &&
      collectionName !== COLLECTION_NAMES.APPROVALS
    ) {
      const isURLValid = MediaItem.getIsURLValid({ mediaItem });
      // Do not save if the URL is invalid
      if (!isURLValid) {
        logger.info(
          `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in pages/NewsFeed.handleArticleAction`,
        );
        PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
          collectionName,
          mediaId,
          error: 'Please ensure your post URLs is valid',
        });
        okToContinue = false;
      }
      // Request confirmation if the URL is unresolved
      const isURLResolved = MediaItem.getIsURLResolved({ mediaItem });
      if (!isURLResolved) {
        const confirmMessage =
          "Your share URL doesn't seem to resolve to an article. Do you still want to save this post?";
        if (!window.confirm(confirmMessage)) {
          okToContinue = false;
        }
      }
    }
    if (!okToContinue) {
      return; // Do nothing if errors exist or the user chooses not to continue
    }

    // Mark the media item(s) as saving and specify the pub-sub handler to exceute when this has been done
    logger.info(
      `PubSub: publish ${COMMAND_SET_SAVING_STATE} in pages/NewsFeed.handleArticleAction`,
    );
    PubSub.publish(COMMAND_SET_SAVING_STATE, {
      collectionName,
      mediaId,
      value: true,
      onCompletion: {
        message: MESSAGE_SAVING_STATE_SET,
        args: { collectionName, mediaId, actionType, shareOrigin },
      },
    });
    logger.info(
      `PubSub: subscribe ${MESSAGE_SAVING_STATE_SET}.${collectionName}.${mediaId} in pages/NewsFeed.handleArticleAction`,
    );
    PubSub.subscribe(
      `${MESSAGE_SAVING_STATE_SET}.${collectionName}.${mediaId}`,
      this.onSavingStateSet,
    );
  }

  handleArticleDelete({
    collectionName = mandatory('collectionName'),
    mediaId = mandatory('mediaId'),
  }) {
    logger.info(`NewsFeed:handleArticleDelete - media id ${mediaId}`);

    this.handleArticleAction({
      collectionName,
      mediaId,
      actionType: ACTION_TYPES.DELETE,
    });

    const mediaItem = this.findMediaItem({ collectionName, mediaId });
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
    const apiTypeId = getAPITypeId({ accountAPIId });
    const postType = MediaItem.getPostType({ mediaItem });

    const isDeletePost =
      collectionName === COLLECTION_NAMES.ANALYTICS_PAGE ||
      collectionName === COLLECTION_NAMES.LAST_SHARED;
    const isDeleteSchedule = collectionName === COLLECTION_NAMES.SCHEDULE_QUEUE;
    let eventName;
    if (isDeletePost) {
      eventName = 'Delete Post';
    } else if (isDeleteSchedule) {
      eventName = 'Delete Schedule';
    } else {
      eventName = 'Delete Article';
    }
    if (isDeletePost || isDeleteSchedule) {
      const trackingParams = getSchedulePostTrackingParams({ mediaItem });
      if (isDeletePost) {
        trackingParams.Location =
          collectionName === COLLECTION_NAMES.ANALYTICS_PAGE
            ? 'Analytics'
            : 'Last Shared';
      }
      tracker.track({
        eventName,
        trackingParams,
      });
      return;
    }
    const trackingParams = {
      'Social Network': getSocialNetworkName({ apiTypeId }),
      'Social Page': getAPIPostName({ accountAPIId }),
      'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
      'Account API Id': accountAPIId,
    };
    trackingParams['Failed Share'] = collectionName === 'failedShares';
    trackingParams['Article URL'] =
      postType === POST_TYPES.LINK
        ? MediaItem.getUnshortenedShareURL({ mediaItem })
        : 'undefined';
    tracker.track({
      eventName,
      trackingParams,
    });
  }

  handleArticleLoad({
    collectionName = mandatory('collectionName'),
    mediaId = mandatory('mediaId'),
    mediaItem = mandatory('mediaItem'),
    accountAPIId = mandatory('accountAPIId'),
  }) {
    if (accountAPIId === getCurrentAccountAPIId()) {
      this.setState((prevState) => {
        const index = this.getMediaItemIndex({ collectionName, mediaId });
        if (index === -1) {
          return null;
        }
        return {
          data: prevState.data.setIn(
            [collectionName, 'mediaItems', index],
            mediaItem,
          ),
        };
      });
    } else {
      logger.info(
        `NewsFeed:handleArticleLoad - Ignoring media item ${mediaId} for api ${accountAPIId} as current api is now ${getCurrentAccountAPIId()}`,
      );
    }
  }

  handleArticleReshare({
    mediaId = mandatory('mediaId'),
    sourceCollection = mandatory('sourceCollection'),
    shareOrigin = mandatory('shareOrigin'),
    thumbnail,
  }) {
    logger.info(`NewsFeed:handleArticleReshare - media id ${mediaId}`);

    // Find the item to be reshared in the analytics page data
    const mediaItem = this.findMediaItem({
      collectionName: sourceCollection,
      mediaId,
    });
    if (isNull(mediaItem)) {
      return; // Do nothing if the media item no longer exists
    }

    Compose.resharePost({
      mediaId,
      accountAPIId: getCurrentAccountAPIId(),
      postType: MediaItem.getPostType({ mediaItem }),
      shareOrigin,
      thumbnail,
    });
  }

  handleArticleRestore({
    collectionName = mandatory('collectionName'),
    mediaId = mandatory('mediaId'),
  } = {}) {
    logger.info('NewsFeed:handleArticleRestore');

    const mediaItem = this.findMediaItem({ collectionName, mediaId });
    const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
    const apiTypeId = getAPITypeId({ accountAPIId });
    const postType = MediaItem.getPostType({ mediaItem });

    this.handleArticleAction({
      actionType: ACTION_TYPES.UNSCHEDULE,
      collectionName,
      mediaId,
    });

    const trackingParams = {
      'Social Network': getSocialNetworkName({ apiTypeId }),
      'Social Page': getAPIPostName({ accountAPIId }),
      'Network - Social Page': getNetworkAndPageName({ accountAPIId }),
      'Account API Id': accountAPIId,
    };
    trackingParams['Failed Share'] = collectionName === 'failedShares';
    trackingParams['Article URL'] =
      postType === POST_TYPES.LINK
        ? MediaItem.getUnshortenedShareURL({ mediaItem })
        : 'undefined';
    tracker.track({
      eventName: 'Restore Article',
      trackingParams,
    });
  }

  handleInstantVideoSchedule() {
    // Close the modal and refresh the scheduled queue
    this.setState((nextState) => ({
      isInstantVideoModalOpen: false,
      data: nextState.data
        .setIn([COLLECTION_NAMES.SCHEDULE_QUEUE, 'mediaIds'], [])
        .setIn([COLLECTION_NAMES.SCHEDULE_QUEUE, 'mediaItems'], [])
        .setIn([COLLECTION_NAMES.SCHEDULE_QUEUE, 'hasError'], false)
        .setIn([COLLECTION_NAMES.SCHEDULE_QUEUE, 'isLoading'], true)
        .setIn([COLLECTION_NAMES.SCHEDULE_QUEUE, 'itemsRendered'], 0),
    }));

    addSuccessNotification('Post queued');

    this.getFeedData({
      collectionName: COLLECTION_NAMES.SCHEDULE_QUEUE,
    });
  }

  handleFilterChange({
    collectionName = mandatory('collectionName'),
    filterBy = mandatory('filterBy'),
  }) {
    logger.info(`NewsFeed:handleFilterChange - filter ${filterBy}`);
    this.setState(
      (prevState) => {
        const displayOptions = Immutable.asMutable(
          prevState.data[collectionName].displayOptions,
        );
        displayOptions.filterBy = filterBy;
        if (collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE) {
          displayOptions.selectedCategories = [];
        }
        NewsFeed.saveDisplayOptions({
          collectionName,
          displayOptions,
        });
        return {
          data: prevState.data
            .setIn([collectionName, 'mediaIds'], [])
            .setIn([collectionName, 'mediaItems'], [])
            .setIn([collectionName, 'displayOptions'], displayOptions)
            .setIn([collectionName, 'hasError'], false)
            .setIn([collectionName, 'isLoading'], true)
            .setIn([collectionName, 'itemsRendered'], 0)
            .set('maxYScroll', NewsFeed.getPageContentScrollY()),
        };
      },
      () => {
        this.getFeedData({
          collectionName,
        });
      },
    );
  }

  handleSelectedCategoriesChanged({
    collectionName = mandatory('collectionName'),
    userSelected = mandatory('userSelected'),
  }) {
    // Only ArticleArchive should use this method
    if (collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE) {
      this.setState(
        (prevState) => {
          const displayOptions = Immutable.asMutable(
            prevState.data[collectionName].displayOptions,
          );
          displayOptions.selectedCategories = userSelected;
          NewsFeed.saveDisplayOptions({
            collectionName,
            displayOptions,
          });

          return {
            data: prevState.data
              .setIn([collectionName, 'mediaIds'], [])
              .setIn([collectionName, 'mediaItems'], [])
              .setIn([collectionName, 'displayOptions'], displayOptions)
              .setIn([collectionName, 'hasError'], false)
              .setIn([collectionName, 'isLoading'], true)
              .setIn([collectionName, 'itemsRendered'], 0)
              .set('maxYScroll', NewsFeed.getPageContentScrollY()),
          };
        },
        () => {
          this.getFeedData({
            collectionName,
            isCompleteRefresh: false,
            requireCategoryRefresh: false,
          });
        },
      );
    }
  }

  handleInsightColumnsChange({ columns, networkName }) {
    const collectionName = COLLECTION_NAMES.ANALYTICS_PAGE;

    this.setState(
      (prevState) => {
        const displayOptions = Immutable.asMutable(
          prevState.data[collectionName].displayOptions,
        );
        displayOptions.insightColumns = {
          ...displayOptions.insightColumns,
          [networkName]: columns,
        };

        // If sorted column is removed, reset the sort
        if (!columns.includes(displayOptions.sortBy)) {
          displayOptions.sortBy = 'date';
          displayOptions.sortOrder = 'DESC';
        }

        NewsFeed.saveDisplayOptions({
          collectionName,
          displayOptions,
        });
        return {
          data: prevState.data
            .setIn([collectionName, 'mediaIds'], [])
            .setIn([collectionName, 'mediaItems'], [])
            .setIn([collectionName, 'displayOptions'], displayOptions)
            .setIn([collectionName, 'hasError'], false)
            .setIn([collectionName, 'isLoading'], true)
            .setIn([collectionName, 'itemsRendered'], 0)
            .set('maxYScroll', NewsFeed.getPageContentScrollY()),
        };
      },
      () => {
        this.getFeedData({
          collectionName,
        });
      },
    );
  }

  debouncedInfiniteLoad() {
    logger.info('NewsFeed:debouncedInfiniteLoad');
    const collectionName = this.getCollectionName();
    const { maxYScroll } = this.state.data;
    const currentYScroll = NewsFeed.getPageContentScrollY();
    if (
      this.state.data[collectionName].itemsRendered <
        this.state.data[collectionName].mediaIds.length &&
      maxYScroll <= currentYScroll
    ) {
      const itemsToLoad = this.state.data[collectionName].itemsPerPage;
      logger.info(
        `NewsFeed:debouncedInfiniteLoad - adding ${itemsToLoad} items to ${collectionName}`,
      );
      this.loadMoreItems({
        collectionName,
        itemsToLoad,
      });
      this.setState((prevState) => ({
        data: prevState.data.set('maxYScroll', currentYScroll),
      }));
    }
  }

  handleShowInstantVideoModal({ mediaId }) {
    // Get the media item
    let mediaItem = [
      ...this.state.data[COLLECTION_NAMES.ARTICLE_FEED].mediaItems,
      ...(this.state.data[COLLECTION_NAMES.FAILED_SHARES].mediaItems ?? []),
    ]
      .filter(Boolean)
      .find((item) => MediaItem.getMediaId({ mediaItem: item }) === mediaId);

    // Set the loading state and open the modal
    mediaItem = MediaItem.setIsLoading({ mediaItem, fieldValue: true });
    this.setState({
      isInstantVideoModalOpen: true,
      instantVideoMediaItem: mediaItem,
    });
  }

  handleSortChange({
    collectionName = mandatory('collectionName'),
    sortBy = mandatory('sortBy'),
    sortSequence,
  }) {
    this.setState(
      (prevState) => {
        const displayOptions = Immutable.asMutable(
          prevState.data[collectionName].displayOptions,
        );
        displayOptions.sortBy = sortBy;
        if (isUndefined(sortSequence)) {
          if (prevState.data[collectionName].displayOptions.sortBy === sortBy) {
            displayOptions.sortOrder =
              displayOptions.sortOrder === 'ASC' ? 'DESC' : 'ASC';
          } else {
            displayOptions.sortOrder = 'DESC';
          }
        } else {
          displayOptions.sortOrder = sortSequence;
        }
        logger.info(
          `NewsFeed:handleSortChange - sort ${displayOptions.sortBy} ${displayOptions.sortOrder}`,
        );
        NewsFeed.saveDisplayOptions({
          collectionName,
          displayOptions,
        });
        return {
          data: prevState.data
            .setIn([collectionName, 'mediaIds'], [])
            .setIn([collectionName, 'mediaItems'], [])
            .setIn([collectionName, 'displayOptions'], displayOptions)
            .setIn([collectionName, 'hasError'], false)
            .setIn([collectionName, 'isLoading'], true)
            .setIn([collectionName, 'itemsRendered'], 0)
            .set('maxYScroll', NewsFeed.getPageContentScrollY()),
        };
      },
      () => {
        this.getFeedData({
          collectionName,
          isCompleteRefresh: false,
          requireCategoryRefresh: false,
        });
      },
    );
  }

  handleStateChange({ event = mandatory('event') }) {
    const feedState = event.target.value;
    const targetCollection =
      feedState === FEED_STATES.DELETED
        ? COLLECTION_NAMES.DELETED_ITEMS
        : COLLECTION_NAMES.ARTICLE_FEED;
    logger.info(
      `NewsFeed:handleStateChange - collection ${targetCollection} state ${feedState}`,
    );
    this.setState(
      (prevState) => {
        const displayOptions = Immutable.asMutable(
          prevState.data[targetCollection].displayOptions,
        );
        if (feedState === FEED_STATES.DRAFT) {
          displayOptions.filterBy = 'draft';
        } else {
          displayOptions.filterBy = 'all';
        }
        displayOptions.articleFeedState = feedState;
        if (targetCollection === COLLECTION_NAMES.ARTICLE_ARCHIVE) {
          displayOptions.selectedCategories = [];
        }

        NewsFeed.saveDisplayOptions({
          collectionName: targetCollection,
          displayOptions,
        });
        return {
          data: prevState.data
            .set('articleFeedState', feedState)
            .setIn([targetCollection, 'mediaIds'], [])
            .setIn([targetCollection, 'mediaItems'], [])
            .setIn([targetCollection, 'displayOptions'], displayOptions)
            .setIn([targetCollection, 'hasError'], false)
            .setIn([targetCollection, 'isLoading'], true)
            .setIn([targetCollection, 'itemsRendered'], 0)
            .set('maxYScroll', NewsFeed.getPageContentScrollY()),
        };
      },
      () => {
        switch (feedState) {
          case FEED_STATES.NEW:
            this.getFeedData({
              collectionName: COLLECTION_NAMES.ARTICLE_FEED,
            });
            this.getFeedData({
              collectionName: COLLECTION_NAMES.FAILED_SHARES,
            });
            break;
          case FEED_STATES.DRAFT:
            this.getFeedData({
              collectionName: COLLECTION_NAMES.ARTICLE_FEED,
            });
            break;
          case FEED_STATES.DELETED:
            this.getFeedData({
              collectionName: COLLECTION_NAMES.DELETED_ITEMS,
            });
            break;
          default:
        }
      },
    );
  }

  handleTimeframeChange({
    collectionName = mandatory('collectionName'),
    timeFrame = mandatory('timeFrame'),
    fromTime = mandatory('fromTime'),
    toTime = mandatory('toTime'),
    timeField,
    filterBy,
    refreshFeedData = true,
  }) {
    logger.info(
      `NewsFeed:handleTimeframeChange - collection ${collectionName} timeframe ${timeFrame} from ${fromTime} to ${toTime}`,
    );
    this.setState(
      (prevState) => {
        const displayOptions = Immutable.asMutable(
          prevState.data[collectionName].displayOptions,
        );
        displayOptions.timeFrame = timeFrame;
        displayOptions.toTime = toTime;
        displayOptions.fromTime = fromTime;
        if (isDefined(timeField)) {
          displayOptions.timeField = timeField;
        }
        if (isDefined(filterBy)) {
          displayOptions.filterBy = filterBy;
        }
        if (
          refreshFeedData &&
          collectionName === COLLECTION_NAMES.ARTICLE_ARCHIVE
        ) {
          displayOptions.selectedCategories = [];
        }

        NewsFeed.saveDisplayOptions({
          collectionName,
          displayOptions,
        });
        let newMediaIds;
        let newMediaItems;
        let newItemsRendered;
        let newIsLoading;
        if (
          timeFrame === 'CUSTOM' &&
          (!refreshFeedData || (isNull(fromTime) && isNull(toTime)))
        ) {
          newMediaIds = prevState.data[collectionName].mediaIds;
          newMediaItems = prevState.data[collectionName].mediaItems;
          newItemsRendered = prevState.data[collectionName].itemsRendered;
          newIsLoading = false;
        } else {
          newMediaIds = [];
          newMediaItems = [];
          newItemsRendered = 0;
          newIsLoading = true;
        }
        return {
          data: prevState.data
            .setIn([collectionName, 'mediaIds'], newMediaIds)
            .setIn([collectionName, 'mediaItems'], newMediaItems)
            .setIn([collectionName, 'displayOptions'], displayOptions)
            .setIn([collectionName, 'hasError'], false)
            .setIn([collectionName, 'isLoading'], newIsLoading)
            .setIn([collectionName, 'itemsRendered'], newItemsRendered)
            .set('maxYScroll', NewsFeed.getPageContentScrollY()),
        };
      },
      () => {
        if (refreshFeedData) {
          this.getFeedData({
            collectionName,
          });
        }
      },
    );
  }

  /**
   * Pub-sub event handlers
   */

  onMediaItemError(message, data) {
    const { collectionName, mediaId, error } = data;
    if (isUndefined(collectionName)) {
      return; // Ignore compose box errors (these will be handled in the Master component)
    }

    logger.info(`NewsFeed:onMediaItemError - media id ${mediaId}`);

    let { accountAPIId } = data;
    if (isUndefined(accountAPIId)) {
      accountAPIId = getCurrentAccountAPIId();
    }

    const index = this.getMediaItemIndex({ collectionName, mediaId });
    if (index === -1) {
      this.props.flashMessages.addMessage({
        messageCategory: 'NewsFeed media item does not exist',
        type: FLASH_MESSAGE_TYPES.ERROR,
        text: getErrorMessage(determineError(error)),
      });
    } else if (
      typeof error === 'object' &&
      !isNullOrUndefined(error) &&
      error.error?.message ===
        'Provided state does not match the current state of the mediaId'
    ) {
      this.deleteCollectionItem({
        collectionName,
        index,
      });
      this.props.flashMessages.addMessage({
        messageCategory: 'NewsFeed media item edited by another user',
        type: FLASH_MESSAGE_TYPES.ERROR,
        text: `Error: ${mediaId}. Changes made to this share were not saved
          as it has been edited by another user.`,
      });
    } else {
      let mediaItem = this.findMediaItem({ collectionName, mediaId });
      if (!isNull(mediaItem)) {
        const errorMessage = getErrorMessage(error);

        // For approvals just show a flash message on error
        if (collectionName === COLLECTION_NAMES.APPROVALS) {
          this.props.flashMessages.addMessage({
            messageCategory: 'NewsFeed media item approvals error',
            type: FLASH_MESSAGE_TYPES.ERROR,
            text: errorMessage,
          });
        } else {
          mediaItem = MediaItem.setErrorMessage({
            mediaItem,
            fieldValue: errorMessage,
          });
        }

        mediaItem = MediaItem.setIsLoading({
          mediaItem,
          fieldValue: false,
        });
        mediaItem = MediaItem.setIsSaving({
          mediaItem,
          fieldValue: false,
        });
        this.setState((prevState) => ({
          data: prevState.data.setIn(
            [collectionName, 'mediaItems', index],
            mediaItem,
          ),
        }));
      }
    }
  }

  onSetSavingState(msg, data) {
    const { collectionName, mediaId, onCompletion } = data;

    if (isUndefined(collectionName) || isUndefined(mediaId)) {
      return; // Ignore requests to set saving state for compose box items (these will be handled in the Master component)
    }

    const index = this.getMediaItemIndex({ collectionName, mediaId });
    if (index === -1) {
      return;
    }
    let mediaItem = this.findMediaItem({ collectionName, mediaId });
    if (isNull(mediaItem)) {
      return;
    }
    const isSaving = MediaItem.getIsSaving({ mediaItem });
    if (!isSaving) {
      this.setState(
        (prevState) => {
          mediaItem = MediaItem.setIsSaving({
            mediaItem,
            fieldValue: true,
          });
          return {
            data: prevState.data.setIn(
              [collectionName, 'mediaItems', index],
              mediaItem,
            ),
          };
        },
        () => {
          if (isDefined(onCompletion)) {
            const { message, args } = onCompletion;
            logger.info(
              `PubSub: publish ${message}.${args.collectionName}.${args.mediaId} in pages/NewsFeed.onSetSavingState`,
            );
            PubSub.publish(
              `${message}.${args.collectionName}.${args.mediaId}`,
              {
                ...args,
              },
            );
          }
        },
      );
    }
  }

  onSavingStateSet(msg, data) {
    const { actionType, shareOrigin } = data;
    const collectionName = data.collectionName ?? COLLECTION_NAMES.COMPOSE;

    const isEditing = this.props.isComposeBoxOpen;
    const pageName = this.getPageName();

    let postPreviews;
    if (collectionName === COLLECTION_NAMES.COMPOSE) {
      postPreviews = data.postPreviews;
    } else {
      const mediaItem = this.findMediaItem({
        collectionName,
        mediaId: data.mediaId,
      });
      postPreviews =
        data.collectionName && data.mediaId && mediaItem ? [{ mediaItem }] : [];
    }

    // Remove any previews to the same page that aren't intentional duplicates
    const checkedAccountAPIIds = [];
    const accidentallyDuplicatedGuids = [];
    postPreviews.forEach((preview, index) => {
      const postType = MediaItem.getPostType({ mediaItem: preview.mediaItem });
      const socialChannel = MediaItem.getSocialChannel({
        mediaItem: preview.mediaItem,
      });
      let mediaItem = { ...preview.mediaItem };
      if (
        postType === POST_TYPES.STATUS &&
        socialChannel === SOCIAL_CHANNELS.STORY
      ) {
        mediaItem = MediaItem.removeMediaItemLinkProperties({
          mediaItem: preview.mediaItem,
        });
        preview = { ...preview, mediaItem };
        postPreviews[index] = preview;
      }

      const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
      if (
        checkedAccountAPIIds.includes(preview.accountAPIId) &&
        trackingDetails.Origin !== SHARE_ORIGINS.DUPLICATE
      ) {
        accidentallyDuplicatedGuids.push(preview.guid);
        logger.error({
          event: 'NewsFeed - Unintentional Duplicate Share Found',
          properties: {
            accountAPIId: preview.accountAPIId,
            mediaItem: preview.mediaItem,
          },
        });
      } else {
        checkedAccountAPIIds.push(preview.accountAPIId);
      }
    });

    postPreviews
      .filter((preview) => !accidentallyDuplicatedGuids.includes(preview.guid))
      .forEach(async (item, mediaItemIndex) => {
        let mediaItem = item.mediaItem;
        if (isNullOrUndefined(mediaItem)) {
          return; // Skip media item if for some reason it isn't properly defined
        }
        mediaItem = Immutable(mediaItem);
        const mediaId = MediaItem.getMediaId({ mediaItem });
        const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });
        const isCurrentAccountAPIId = accountAPIId === getCurrentAccountAPIId();
        const currentState = MediaItem.getState({ mediaItem });
        const shareTime = MediaItem.getShareTime({ mediaItem });
        const additionalSocialPages = MediaItem.getAdditionalSocialPages({
          mediaItem,
        });
        const isCurrentTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;
        let targetState;
        switch (actionType) {
          case ACTION_TYPES.DELETE:
            targetState = MEDIA_ITEM_STATES.DELETED;
            break;
          case ACTION_TYPES.SAVE:
            targetState =
              mediaId === 'NEW' ||
              mediaId === 'RESHARE' ||
              currentState === MEDIA_ITEM_STATES.DELETED ||
              currentState === MEDIA_ITEM_STATES.FAILED
                ? MEDIA_ITEM_STATES.NEW
                : MediaItem.getState({ mediaItem });

            // When reverting to draft convert to a manual share
            if (currentState === MEDIA_ITEM_STATES.APPROVAL_REQUIRED) {
              targetState = MEDIA_ITEM_STATES.NEW;
              mediaItem = MediaItem.setSuggestionTypeId({
                mediaItem,
                fieldValue: SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE,
              });
            }

            break;
          case ACTION_TYPES.SCHEDULE:
            if (shareTime.type === SHARE_TIME_TYPES.NOW) {
              // Evaluate feature flag only when we are sharing now
              const globalInfo = this.props.global.getGlobalInfo();
              const propertyId = getCurrentPropertyId({ globalInfo });
              const isInstantShareNowEnabled = getFeatureToggle({
                featureName: FEATURE_TOGGLES.SHARENOW_INSTANT_ENABLED,
                propertyId,
              });
              const isVideoPost =
                MediaItem.getPostType({ mediaItem }) === POST_TYPES.VIDEO;
              targetState =
                (isInstantShareNowEnabled && !isCurrentAccountAPIId) ||
                isVideoPost
                  ? MEDIA_ITEM_STATES.SCHEDULED
                  : MEDIA_ITEM_STATES.SHARED;
            } else {
              targetState = MEDIA_ITEM_STATES.SCHEDULED;
            }
            break;
          case ACTION_TYPES.UNSCHEDULE:
            targetState = MEDIA_ITEM_STATES.NEW;
            break;
          default:
            throw new ReferenceError('Unknown action type');
        }

        const sourceCollection = this.getCollectionNameFromState(currentState);

        let targetCollection;
        if (targetState === MEDIA_ITEM_STATES.SHARED) {
          // Shares are asynchronous for TikTok so show the schedule queue here
          targetCollection = isCurrentTikTok
            ? COLLECTION_NAMES.SCHEDULE_QUEUE
            : COLLECTION_NAMES.LAST_SHARED;
        } else {
          targetCollection = this.getCollectionNameFromState(targetState);
        }

        const allMediaItems = [];
        // Check if there are any collapsed social pages
        if (additionalSocialPages.length > 0) {
          for (const socialPageId of additionalSocialPages) {
            const result = await Compose.sharePostTo({
              sourceItem: item.mediaItem,
              accountAPIId: socialPageId,
              publishToComposeBox: false,
            });
            allMediaItems.push(result.mediaItem);
          }
        }
        // Add the media item which contains all the previews last incase any scheudling issues occur
        allMediaItems.push(mediaItem);

        for (const currentMediaItem of allMediaItems) {
          const currentMediaId = MediaItem.getMediaId({
            mediaItem: currentMediaItem,
          });
          const currentAccountAPIId = MediaItem.getAccountAPIId({
            mediaItem: currentMediaItem,
          });
          let returnSavedItem;

          // Prepare the item for saving (which at the moment just means adding a URL to the end
          // of the share message, if one doesn't already exist, when posting to Twitter)
          let preparedItem;
          await new Promise((resolve) => {
            resolve(currentMediaItem);
          })
            .then((result) => {
              preparedItem = result;
              switch (actionType) {
                case ACTION_TYPES.DELETE:
                  returnSavedItem = false;
                  break;
                case ACTION_TYPES.SAVE:
                  returnSavedItem =
                    isCurrentAccountAPIId &&
                    currentState !== MEDIA_ITEM_STATES.DELETED;
                  break;
                case ACTION_TYPES.SCHEDULE:
                  returnSavedItem =
                    isCurrentAccountAPIId && currentState === targetState;
                  break;
                case ACTION_TYPES.UNSCHEDULE:
                  returnSavedItem =
                    isCurrentAccountAPIId &&
                    pageName !== 'analytics' &&
                    pageName !== 'archive';
                  break;
                default:
                  throw new ReferenceError('Unknown action type');
              }

              // Reset current state to NEW if we are sharing to an account other than
              // the item's "original" account
              if (!isCurrentAccountAPIId) {
                preparedItem = MediaItem.setState({
                  mediaItem: preparedItem,
                  fieldValue: MEDIA_ITEM_STATES.NEW,
                });
              }

              // Change the target state from SHARED to SCHEDULED if we are doing a SHARE NOW
              // for an api with AB variations (where a mandatory 30 minute delay is needed)
              const isABTest = MediaItem.getABInfo({
                mediaItem: currentMediaItem,
              }).isABVariation;
              let finalTargetState = targetState;
              if (targetState === MEDIA_ITEM_STATES.SHARED && isABTest) {
                finalTargetState = MEDIA_ITEM_STATES.SCHEDULED;
              }

              // Add tracking ranking if applicable
              if (
                sourceCollection === COLLECTION_NAMES.ARTICLE_FEED &&
                (targetCollection === COLLECTION_NAMES.SCHEDULE_QUEUE ||
                  targetCollection === COLLECTION_NAMES.LAST_SHARED)
              ) {
                // Only add ranking if this is not a new share
                const trackingDetails = MediaItem.getTrackingDetails({
                  mediaItem: preparedItem,
                });
                if (
                  isDefined(trackingDetails.Origin) &&
                  trackingDetails.Origin === SHARE_ORIGINS.HOME_FEED
                ) {
                  preparedItem = MediaItem.setTrackingDetails({
                    mediaItem: preparedItem,
                    fieldValue: {
                      NewsFeedArticleRankAtSchedule: getQuartileName({
                        itemIndex: this.getMediaItemIndex({
                          collectionName: sourceCollection,
                          mediaId: currentMediaId,
                        }),
                        sortOrder:
                          this.state.data[sourceCollection].displayOptions
                            .sortBy,
                        totalNumberOfItems:
                          this.state.data[sourceCollection].mediaIds.length,
                      }),
                    },
                  });
                }
              }

              // Add origin tracking if applicable
              if (!isNullOrUndefined(shareOrigin)) {
                preparedItem = MediaItem.setTrackingDetails({
                  mediaItem: preparedItem,
                  fieldValue: {
                    Origin: shareOrigin,
                  },
                });
              }

              // Perform the save
              return saveMediaItem({
                mediaItem: preparedItem,
                accountAPIId: currentAccountAPIId,
                targetState: finalTargetState,
                returnSavedItem,
                mediaItemIndex,
                errorOnDuplicate: data.errorOnDuplicate,
              });
            })
            .then((savedItem) => {
              const savedMediaId = !isNull(savedItem)
                ? MediaItem.getMediaId({ mediaItem: savedItem })
                : currentMediaId;

              // The original item should be removed from the queue if its media id has changed
              // or if it is being moved to a different queue, but only if it is being saved
              // to the currently-selected account
              const removeFromSourceCollection =
                isCurrentAccountAPIId &&
                ((pageName !== 'analytics' && pageName !== 'archive') ||
                  targetState === MEDIA_ITEM_STATES.DELETED) &&
                isDefined(currentMediaId) &&
                currentMediaId !== 'NEW' &&
                currentMediaId !== 'RESHARE' &&
                (currentMediaId !== savedMediaId ||
                  currentState !== targetState);

              // The saved item should be added to the queue if its media id has changed,
              // but only if it is being saved to the currently-selected account
              const addToSourceCollection =
                isCurrentAccountAPIId &&
                pageName !== 'analytics' &&
                pageName !== 'archive' &&
                currentMediaId !== savedMediaId;

              const updateSourceCollection =
                isCurrentAccountAPIId &&
                currentMediaId === savedMediaId &&
                currentState === targetState;

              // The target queue should be completely reloaded if the item is being moved
              // to a different queue, but only if it is being saved to the current account
              const reloadTargetCollection =
                collectionName !== COLLECTION_NAMES.APPROVALS &&
                isCurrentAccountAPIId &&
                ((pageName !== 'analytics' && pageName !== 'archive') ||
                  targetState === MEDIA_ITEM_STATES.SHARED) &&
                currentState !== targetState &&
                targetState !== MEDIA_ITEM_STATES.DELETED;

              // Show notification if required
              let notificationMessage = '';
              if (actionType === ACTION_TYPES.SCHEDULE) {
                if (currentState === targetState) {
                  notificationMessage = 'Changes saved';
                } else {
                  const isVideoPost =
                    MediaItem.getPostType({ mediaItem }) === POST_TYPES.VIDEO;
                  notificationMessage =
                    shareTime.type === SHARE_TIME_TYPES.NOW &&
                    isCurrentAccountAPIId &&
                    !isVideoPost
                      ? 'Post shared'
                      : 'Post queued';
                }
              }
              if (notificationMessage !== '') {
                addSuccessNotification(notificationMessage);
              }

              // Track save as draft event
              if (
                (actionType === ACTION_TYPES.SAVE &&
                  currentState === MEDIA_ITEM_STATES.NEW) ||
                actionType === ACTION_TYPES.UNSCHEDULE
              ) {
                tracker.track({
                  eventName: 'Save as Draft',
                  trackingParams: getSchedulePostTrackingParams({
                    mediaItem: preparedItem,
                  }),
                });
              } else if (
                actionType === ACTION_TYPES.SAVE &&
                currentState === MEDIA_ITEM_STATES.APPROVAL_REQUIRED
              ) {
                trackApprovalAction({
                  mediaItem: preparedItem,
                  action: 'Save as Draft',
                  origin: 'Compose Box',
                });
              }

              // Track schedule post event
              if (actionType === ACTION_TYPES.SCHEDULE) {
                tracker.track({
                  eventName: 'Schedule Post',
                  trackingParams: getSchedulePostTrackingParams({
                    mediaItem: preparedItem,
                    mediaItemIndex,
                  }),
                });
              }

              // Mark the item as saved
              if (savedItem) {
                savedItem = MediaItem.setIsSaving({
                  mediaItem: savedItem,
                  fieldValue: false,
                });
              }

              // Update the queued item count for the api
              logger.info(
                `PubSub: publish ${REQUEST_API_INFO} in pages/NewsFeed.onSavingStateSet`,
              );
              PubSub.publish(REQUEST_API_INFO, {
                accountAPIId,
              });

              logger.info(
                'NewsFeed:handleArticleAction - ' +
                  `removeFromSourceCollection ${removeFromSourceCollection} ` +
                  `addToSourceCollection ${addToSourceCollection} ` +
                  `updateSourceCollection ${updateSourceCollection} ` +
                  `reloadTargetCollection ${reloadTargetCollection} ` +
                  `isEditing ${isEditing}`,
              );

              if (removeFromSourceCollection) {
                const index = this.getMediaItemIndex({
                  collectionName: sourceCollection,
                  mediaId: currentMediaId,
                });
                if (index !== -1) {
                  this.deleteCollectionItem({
                    collectionName: sourceCollection,
                    index,
                  });
                }
              }

              if (addToSourceCollection) {
                this.addCollectionItem({
                  collectionName: sourceCollection,
                  index: 0,
                  mediaId: savedMediaId,
                  mediaItem: savedItem,
                });
              }

              if (updateSourceCollection) {
                const index = this.getMediaItemIndex({
                  collectionName: sourceCollection,
                  mediaId: currentMediaId,
                });
                if (index !== -1) {
                  this.updateCollectionItem({
                    collectionName: sourceCollection,
                    index,
                    mediaId: currentMediaId,
                    mediaItem: savedItem,
                  });
                }
              }

              this.setState(
                (prevState) => {
                  let nextState = prevState;

                  if (reloadTargetCollection) {
                    nextState = {
                      data: nextState.data
                        .setIn([targetCollection, 'mediaIds'], [])
                        .setIn([targetCollection, 'mediaItems'], [])
                        .setIn([targetCollection, 'hasError'], false)
                        .setIn([targetCollection, 'isLoading'], true)
                        .setIn([targetCollection, 'itemsRendered'], 0),
                    };
                  }

                  if (isEditing) {
                    logger.info(
                      `PubSub: publish ${topics.MESSAGE_SAVE_COMPOSE_BOX} in pages/NewsFeed.onSavingStateSet`,
                    );
                    PubSub.publish(topics.MESSAGE_SAVE_COMPOSE_BOX, {
                      guid: item.guid,
                      additionalSocialPages,
                      accountAPIId,
                      currentAccountAPIId,
                      actionType,
                    });
                  }

                  return nextState;
                },
                () => {
                  if (removeFromSourceCollection) {
                    this.loadMoreItems({ collectionName: sourceCollection });
                  }
                  if (reloadTargetCollection) {
                    if (pageName !== 'analytics') {
                      window.setTimeout(
                        this.checkForLastSharedUpdates.bind(this),
                        5 * 1000,
                      );
                    } else {
                      window.setTimeout(
                        this.checkForAnalyticsPageUpdates.bind(this),
                        5 * 1000,
                      );
                    }
                    this.getFeedData({ collectionName: targetCollection });
                  }

                  if (sourceCollection === COLLECTION_NAMES.APPROVALS) {
                    // Any action on an approval will remove it from the collection
                    this.getFeedData({
                      collectionName: COLLECTION_NAMES.APPROVALS,
                    });

                    // Item will be removed from home feed
                    this.checkForUpdates({
                      collectionName: COLLECTION_NAMES.ARTICLE_FEED,
                      isCompleteRefresh: true,
                    });

                    // Check for update of the tarted collection
                    if (targetState === MEDIA_ITEM_STATES.SCHEDULED) {
                      // Item will be added to queue
                      this.checkForUpdates({
                        collectionName: COLLECTION_NAMES.SCHEDULE_QUEUE,
                        isCompleteRefresh: false,
                      });
                    }
                  }
                },
              );
            })
            .catch((error) => {
              console.log(error);
              determineError(error);
              if (isNull(getErrorStatus(error))) {
                logger.error({
                  event: 'Handle Article Action Error',
                  properties: {
                    location: 'pages/NewsFeed',
                  },
                  error,
                });
              }
              if (collectionName === COLLECTION_NAMES.COMPOSE) {
                logger.info(
                  `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in pages/NewsFeed.onSavingStateSet`,
                );
                // TODO: not this guid
                PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
                  guid: item.guid,
                  error,
                });
                this.props.global.refreshGlobalInfo({
                  reasonCode: GLOBAL_INFO_STATES.ERROR,
                });
                return;
              }
              const index = this.getMediaItemIndex({
                collectionName,
                mediaId: currentMediaId,
              });
              if (index === -1) {
                return; // Do nothing if the media item no longer exists
              }
              let failedItem = this.findMediaItem({ collectionName, mediaId });
              if (isNull(failedItem)) {
                return; // Do nothing if the media item no longer exists
              }
              failedItem = MediaItem.setIsSaving({
                mediaItem: failedItem,
                fieldValue: false,
              });
              this.setState(
                (prevState) => ({
                  data: prevState.data.setIn(
                    [collectionName, 'mediaItems', index],
                    failedItem,
                  ),
                }),
                () => {
                  logger.info(
                    `PubSub: publish ${MESSAGE_MEDIA_ITEM_ERROR} in pages/NewsFeed.onSavingStateSet`,
                  );
                  PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
                    collectionName,
                    mediaId,
                    accountAPIId,
                    error,
                  });
                },
              );
            }); // end of promise chain
        }
      }); // end of postPreviews.forEach loop
  }

  /**
   * Render method
   */

  renderAnalytics(accountAPIId, isSuspended) {
    const { isComposeBoxOpen } = this.props;

    return (
      <Box className="main" px={{ base: 4, md: 10 }}>
        <div className="right ml-auto page-select d-none d-md-flex">
          {!isSuspended && (
            <PageSelect
              isUnderSetup={this.props.isUnderSetup}
              permissionTypeId={this.props.permissionTypeId}
              isMobileWindowWidth={this.props.isMobileWindowWidth}
              showMobileMenu={this.props.showMobileMenu}
            />
          )}
        </div>
        <AnalyticsPage
          accountAPIId={accountAPIId}
          feedData={this.state.data.analyticsPage}
          isMobileWindowWidth={this.props.isMobileWindowWidth}
          itemsToLoad={
            this.state.data[COLLECTION_NAMES.ANALYTICS_PAGE].itemsPerPage
          }
          showMobileMenu={this.props.showMobileMenu}
          eventHandlers={{
            handleArticleDelete: this.handleArticleDelete,
            handleArticleLoad: this.handleArticleLoad,
            handleArticleReshare: this.handleArticleReshare,
            handleFilterChange: this.handleFilterChange,
            handleInsightColumnsChange: this.handleInsightColumnsChange,
            handleSortChange: this.handleSortChange,
            handleTimeframeChange: this.handleTimeframeChange,
          }}
          sharedMethods={{
            getFeedData: this.getFeedData,
            loadMoreItems: this.loadMoreItems,
            renderNoMoreItems: this.renderNoMoreItems,
          }}
        />
        {isComposeBoxOpen && (
          <ComposeBox
            isMobileWindowWidth={this.props.isMobileWindowWidth}
            onSavingStateSet={this.onSavingStateSet}
          />
        )}
      </Box>
    );
  }

  renderArchive(accountAPIId, isSuspended) {
    const { isComposeBoxOpen } = this.props;

    return (
      <Box className="main  article-archive" px={{ base: 4, md: 10 }} pb={4}>
        <div className="right ml-auto page-select d-none d-md-flex">
          {!isSuspended && (
            <PageSelect
              isUnderSetup={this.props.isUnderSetup}
              permissionTypeId={this.props.permissionTypeId}
              isMobileWindowWidth={this.props.isMobileWindowWidth}
              showMobileMenu={this.props.showMobileMenu}
            />
          )}
        </div>
        <ArticleArchive
          feedData={this.state.data.articleArchive}
          isMobileWindowWidth={this.props.isMobileWindowWidth}
          showMobileMenu={this.props.showMobileMenu}
          eventHandlers={{
            handleFilterChange: this.handleFilterChange,
            handleSortChange: this.handleSortChange,
            handleTimeframeChange: this.handleTimeframeChange,
            handleSelectedCategoriesChanged:
              this.handleSelectedCategoriesChanged,
          }}
          sharedMethods={{
            getFeedData: this.getFeedData,
            renderNoMoreItems: this.renderNoMoreItems,
            updateAndSaveCollectionItem: this.updateAndSaveCollectionItem,
          }}
        />
        {isComposeBoxOpen && (
          <ComposeBox
            isMobileWindowWidth={this.props.isMobileWindowWidth}
            onSavingStateSet={this.onSavingStateSet}
          />
        )}
      </Box>
    );
  }

  renderNoMoreItems() {
    const collectionName = this.getCollectionName();
    const feedData = this.state.data[collectionName];
    if (feedData.itemsRendered < feedData.mediaIds.length) {
      return null;
    }
    return (
      <span className="new_article_notification no_new_article_notification">
        {UI_MESSAGES.INFINITE_SCROLL_NO_ITEMS_FOUND}
      </span>
    );
  }

  render() {
    const pageName = this.getPageName();
    const accountAPIId = getCurrentAccountAPIId();

    // No selected page
    if (isNull(accountAPIId)) {
      return (
        <div className="h5 pt-5 d-flex justify-content-center">
          This page is under setup, we&apos;ll be in touch when it&apos;s ready.
        </div>
      );
    }

    const isSuspended = isCurrentPropertySuspended();

    // Analytics page
    if (pageName === 'analytics') {
      return this.renderAnalytics(accountAPIId, isSuspended);
    }

    // Article archive
    if (pageName === 'archive') {
      return this.renderArchive(accountAPIId, isSuspended);
    }

    // News feed
    const { isComposeBoxOpen, isMobileWindowWidth, showMobileMenu } =
      this.props;
    const tabName = isMobileWindowWidth ? NewsFeed.getTabName() : 'share';
    const feedClass =
      showMobileMenu && isMobileWindowWidth ? 'd-none d-md-block' : '';

    return (
      <>
        <Box className={`main ${feedClass}`} px={{ base: 4, md: 10 }}>
          <Box
            display={{ base: 'none', md: 'flex' }}
            ml="auto"
            mb={3}
            className="page-select"
          >
            {!isSuspended && (
              <PageSelect
                isUnderSetup={this.props.isUnderSetup}
                permissionTypeId={this.props.permissionTypeId}
                isMobileWindowWidth={this.props.isMobileWindowWidth}
                showMobileMenu={this.props.showMobileMenu}
              />
            )}
          </Box>
          <AllNotifications />
          <Grid px={0} pb={20} rowGap={0}>
            <Grid.GridItem colSpan={{ base: 2, md: 7 }}>
              <ArticleFeed
                accountAPIId={accountAPIId}
                feedData={{
                  articleFeed: this.state.data.articleFeed,
                  failedShares: this.state.data.failedShares,
                  deletedItems: this.state.data.deletedItems,
                }}
                feedState={this.state.data.articleFeedState}
                tabName={tabName}
                sharedMethods={{
                  getFeedData: this.getFeedData,
                  renderNoMoreItems: this.renderNoMoreItems,
                }}
                eventHandlers={{
                  handleArticleDelete: this.handleArticleDelete,
                  handleArticleLoad: this.handleArticleLoad,
                  handleArticleRestore: this.handleArticleRestore,
                  handleShowInstantVideoModal: this.handleShowInstantVideoModal,
                  handleSortChange: this.handleSortChange,
                  handleStateChange: this.handleStateChange,
                  handleTimeframeChange: this.handleTimeframeChange,
                }}
              />
            </Grid.GridItem>
            <Grid.GridItem colSpan={{ base: 2, md: 5 }}>
              <div
                className={clsx('queues', isSuspended && 'account_suspended')}
              >
                {isApprovalsActive() ? (
                  <Approvals
                    accountAPIId={accountAPIId}
                    feedData={this.state.data.approvals}
                    tabName={tabName}
                    sharedMethods={{
                      getFeedData: this.getFeedData,
                    }}
                    eventHandlers={{
                      handleApprovalSkipped: this.handleApprovalSkipped,
                      handleApprove: this.handleApprove,
                    }}
                  />
                ) : null}
                <ScheduleQueue
                  accountAPIId={accountAPIId}
                  feedData={this.state.data.scheduleQueue}
                  tabName={tabName}
                  eventHandlers={{
                    handleArticleDelete: this.handleArticleDelete,
                    handleArticleLoad: this.handleArticleLoad,
                    handleFilterChange: this.handleFilterChange,
                    handleSortChange: this.handleSortChange,
                    handleTimeframeChange: this.handleTimeframeChange,
                  }}
                  sharedMethods={{
                    getFeedData: this.getFeedData,
                  }}
                />
                <LastShared
                  accountAPIId={accountAPIId}
                  currentTime={this.state.data.currentTime}
                  feedData={this.state.data.lastShared}
                  tabName={tabName}
                  eventHandlers={{
                    handleArticleLoad: this.handleArticleLoad,
                    handleArticleReshare: this.handleArticleReshare,
                    handleFilterChange: this.handleFilterChange,
                    handleSortChange: this.handleSortChange,
                    handleTimeframeChange: this.handleTimeframeChange,
                    handleArticleDelete: this.handleArticleDelete,
                  }}
                  sharedMethods={{
                    getFeedData: this.getFeedData,
                    loadMoreItems: this.loadMoreItems,
                  }}
                />
              </div>
            </Grid.GridItem>
          </Grid>
        </Box>
        {isComposeBoxOpen && (
          <ComposeBox
            isMobileWindowWidth={this.props.isMobileWindowWidth}
            onSavingStateSet={this.onSavingStateSet}
          />
        )}
        <InstantVideoModal
          isOpen={this.state.isInstantVideoModalOpen}
          onClose={() =>
            this.setState({
              isInstantVideoModalOpen: false,
              instantVideoMediaItem: null,
            })
          }
          onSchedule={this.handleInstantVideoSchedule}
          initialMediaItem={this.state.instantVideoMediaItem}
        />
      </>
    );
  }
}

// Wrapper component that will re-mount the Newsfeed component
// whenever the current account api is updated
const NewsFeedWrapper = (props) => {
  const { accountAPIId: accountAPIIdParam } = useParams();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const isComposeBoxOpen = useIsComposeBoxOpen();
  const { isOnline } = useOnlineStatus();
  const toast = useToast();
  // Toast isn't properly memoized so we need this to stop the useEffect retriggers
  const toastRef = useRef(toast);

  const currentAccountAPIId = getCurrentAccountAPIId({ globalInfo });
  const currentPropertyId = getCurrentPropertyId({ globalInfo });

  // This useEffect detects if the URL specifies a particular accountAPIId
  // It then checks if the current property or account matches and if not,
  // switches to the related property and account.
  useEffect(() => {
    const accountAPIId = Number(accountAPIIdParam);

    if (
      currentAccountAPIId != null &&
      !Number.isNaN(accountAPIId) &&
      accountAPIId !== currentAccountAPIId
    ) {
      const propertyId = getPropertyIdForAccountAPIId({
        accountAPIId,
        globalInfo,
      });

      // If the property exists, then continue with the switch.
      // Otherwise, ignore (could be lack of permissions, incorrectly typed URL, e.t.c)
      if (propertyId) {
        if (currentPropertyId !== propertyId) {
          // Switch property then don't continue - this useEffect will be called again when the property changes
          global.handlePropertyChange(propertyId);
          return;
        }

        global.handleAccountChange(accountAPIId);
      }
    }

    // Remove the accountAPIId from the URL now that the switch (if valid) has been made
    if (accountAPIIdParam) {
      navigate('/share', { replace: true });
    }
  }, [
    currentAccountAPIId,
    global,
    globalInfo,
    navigate,
    accountAPIIdParam,
    currentPropertyId,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const impersonateError = queryParams.get('impersonate error');

    if (isImpersonating() && impersonateError === 'no active pages') {
      toastRef.current({
        title: 'No active pages found for property',
        description:
          'The property you were trying to impersonate has no active pages. If you still want to impersonate the property, use the property select in the sidebar.',
        duration: null,
        variant: 'chilledError',
      });

      queryParams.delete('impersonate error');
      navigate(`${pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [navigate, search, pathname]);

  if (global.isLoading()) {
    return null;
  }

  return (
    <HealthMenuToast
      isInitialisingHighPriorityErrorState={
        props.isInitialisingHighPriorityErrorState
      }
    >
      <NewsFeed
        key={currentAccountAPIId}
        isComposeBoxOpen={isComposeBoxOpen}
        isOffline={!isOnline}
        {...props}
      />
    </HealthMenuToast>
  );
};

NewsFeedWrapper.propTypes = {
  isInitialisingHighPriorityErrorState: proptypes.bool.isRequired,
};

export default withFlashMessages(
  withGlobalInfo(withLocation(withNavigate(NewsFeedWrapper))),
);
