import { Box, Center, Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import * as settings from 'common/settings';
import * as tracker from 'common/tracker';
import { AutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import * as tracking from 'helpers/tracking';
import AutoDisableAutomationModal from './AutoDisableAutomationModal';

function SinglePageAutomationSettings() {
  const [disableAutomationPrompt, setDisableAutomationPrompt] = useState<{
    show: boolean;
    onConfirm?: (() => void) | null;
  }>({ show: false });

  const { global } = useGlobalInfo();

  const { accountAPIId: urlAccountAPIId } = useParams();
  const accountAPIId = Number(urlAccountAPIId);

  const isAutomationActive =
    settings.getAutofeedSetting({
      key: 'autoFeedActive',
      accountAPIId,
    }) ?? false;

  const handleAutoDisableAutomationConfirmation = async () => {
    disableAutomationPrompt.onConfirm?.();

    setDisableAutomationPrompt({ show: false, onConfirm: null });
  };

  const showDisableAutomationPrompt = useCallback(
    ({ onConfirm }: { onConfirm?: () => void }) => {
      setDisableAutomationPrompt({ show: true, onConfirm });
    },
    [],
  );

  useEffect(() => {
    tracker.track({
      eventName: 'Page Automation',
      trackingParams: {
        'Network - Social Page': tracking.getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
      },
    });
  }, [accountAPIId]);

  const newContext = useMemo(
    () => ({
      accountAPIId,
      isAutomationActive,
      showDisableAutomationPrompt,
    }),
    [accountAPIId, isAutomationActive, showDisableAutomationPrompt],
  );

  if (global.isLoading() || global.isSelecting()) {
    return (
      <Box
        className="main pt-5"
        px={{ base: 4, md: 10 }}
        data-cy-id="settingsAutofeed"
        data-cy-attribute="isLoading:true"
      >
        <Center>
          <Spinner size="lg" />
        </Center>
      </Box>
    );
  }

  return (
    <AutomationSettingsContext.Provider value={newContext}>
      <AutoDisableAutomationModal
        isOpen={disableAutomationPrompt.show}
        onCancel={() => {
          setDisableAutomationPrompt({ show: false, onConfirm: null });
        }}
        onConfirm={handleAutoDisableAutomationConfirmation}
      />
      <Box data-cy-id="settingsAutofeed" data-cy-attribute="isLoading:false">
        <Outlet />
      </Box>
    </AutomationSettingsContext.Provider>
  );
}

export default SinglePageAutomationSettings;
