import {
  Button,
  Card,
  Flex,
  Grid,
  HStack,
  Spacer,
  Toggle,
  useToast,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import postPropertiesSettings from 'api/postPropertiesSettings';
import postServiceAuth from 'api/postServiceAuth';
import * as authentication from 'common/authentication';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getFeatureToggle } from 'common/settings';
import type { FeatureToggle, FeatureToggleSettings } from 'types';

interface FeatureToggleSettingsCardProps {
  featureToggleSettings: FeatureToggleSettings;
  onFeatureTogglesSettingToggle: (settingName: FeatureToggle) => void;
  propertyId: number;
  propertyURN: string;
}

const FeatureToggleSettingsCard = ({
  featureToggleSettings,
  onFeatureTogglesSettingToggle,
  propertyId,
  propertyURN,
}: FeatureToggleSettingsCardProps) => {
  const toast = useToast();

  const handleSaveClick = async () => {
    const userCSToken = authentication.getClientServiceToken();

    try {
      const impersonateCSToken = await postServiceAuth({
        overridePropertyURN: propertyURN,
      });
      authentication.setClientServiceToken(impersonateCSToken);

      await postPropertiesSettings({
        enabled: true,
        propertyId,
        settingTypeId: ACCOUNT_SETTING_TYPES.FEATURE_TOGGLES,
        dataJSON: featureToggleSettings,
      });

      toast({
        variant: 'success',
        title: 'Settings have been successfully updated',
      });
    } catch (error) {
      console.error(error);
      toast({
        variant: 'chilledError',
        title: 'Something went wrong',
        description:
          'Please try again. If the error persists, contact someone in Tech.',
      });
    } finally {
      authentication.setClientServiceToken(userCSToken);
    }
  };

  const featureToggles: Partial<Record<FeatureToggle, string>> = {
    [FEATURE_TOGGLES.LINKINBIO_ENABLED]: 'Link in Bio',
    [FEATURE_TOGGLES.REQUIRES_EBX_TRACKER]:
      'Require install webtag step in Property setup',
    [FEATURE_TOGGLES.TRACKER_PROVISIONED_MANUALLY]: 'Webtag installed manually',
  };

  return (
    <Card alignItems="end">
      <Card.Content flexGrow={1} gap={4}>
        <Card.Title>Feature Toggles</Card.Title>
        <Flex direction="column" gap={4}>
          <Grid
            as="ul"
            px={0}
            py={2}
            gap={2}
            templateColumns={{ base: '1fr', md: 'repeat(1, 1fr)' }}
          >
            {Object.entries(featureToggles).map(([key, value]) => {
              const featureToggle = key as FeatureToggle;
              return (
                <Grid.GridItem key={featureToggle} as="li" w="full">
                  <Toggle
                    isChecked={
                      featureToggleSettings[featureToggle] ??
                      getFeatureToggle({
                        featureName: featureToggle,
                        propertyId,
                      })
                    }
                    onChange={() =>
                      onFeatureTogglesSettingToggle(featureToggle)
                    }
                  >
                    {value}
                  </Toggle>
                </Grid.GridItem>
              );
            })}
          </Grid>
          <HStack>
            <Spacer />
            <Button
              w="fit-content"
              onClick={handleSaveClick}
              alignItems="right"
            >
              Save
            </Button>
          </HStack>
        </Flex>
      </Card.Content>
    </Card>
  );
};

export default FeatureToggleSettingsCard;
