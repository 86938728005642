import {
  Button,
  Circle,
  Dropdown,
  DropdownButtonProps,
  Flex,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Tag,
  Text,
  WrenchIcon,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { deleteAccountAPI, deletePropertiesSynd } from 'api/api';
import {
  getCurrentProperty,
  getCurrentPropertyId,
  getSocialAPIs,
} from 'common/accountAPIs';
import {
  API_STATES,
  API_TYPE_IDS,
  AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES,
  GLOBAL_INFO_STATES,
  MIXPANEL_ORIGIN,
  PROPERTY_STATES,
  RSS_STATE_NAMES,
} from 'common/constants';
import { getCurrentPropertyState } from 'common/currentPropertyAndAPI';
import { getErrorMessage, getErrorStatus } from 'common/errorHandling';
import { getPagesByFeed } from 'common/feed';
import * as logger from 'common/logger';
import { isIGCabinetEnabled } from 'common/settings';
import {
  getSocialNetworkDefaultPageIcon,
  getSocialNetworkName,
  getURNName,
} from 'common/social';
import * as tracker from 'common/tracker';
import {
  connectTwitterApp,
  isTwitterProjectAppEnabled,
} from 'common/twitterConnect';
import { convertToSocialPageURN } from 'common/urn';
import SocialIcon from 'components/misc/SocialIcon';
import EditContentFeedsModal from 'components/settings/pages/EditContentFeedsModal';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { getNetworkAndPageName } from 'helpers/tracking';
import reconnectAPI from 'process/reconnectAPI';
import MoreOptions from 'svg/MoreOptions';
import type { AccountFeed, FixTypeLater } from 'types';
import { Td, Tr } from '../PagesTable';
import ConnectInstagram from '../page/ConnectInstagram';
import IsInstagramAccountReadyModal from '../page/IsInstagramAccountReadyModal';
import AddIGGraphApiModal from './AddIGGraphApiModal';
import ConnectTwitterAppModal from './ConnectTwitterAppModal';
import RemovePageModal from './RemovePageModal';
import { useSuccessfulTwitterAppConnection } from './useSuccessfulTwitterAppConnection';

const TableCellWrapper = ({
  children,
  isLinkBox,
}: {
  children: React.ReactNode;
  isLinkBox: boolean;
}) => {
  return isLinkBox ? (
    <LinkBox as={Td}>{children}</LinkBox>
  ) : (
    <Td>{children}</Td>
  );
};

const TitleWrapper = ({
  children,
  accountAPIId,
  isLink,
}: {
  children: React.ReactNode;
  accountAPIId: string;
  isLink: boolean;
}) => {
  return isLink ? (
    <LinkOverlay
      as={RouterLink}
      to={`/settings/page/${accountAPIId}`}
      data-cy-action={`navigatePage:${accountAPIId}`}
    >
      {children}
    </LinkOverlay>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment -- best to keep this as a fragment rather than switching from a fragment to a div
    <>{children}</>
  );
};

const hoverActiveStyles: DropdownButtonProps = {
  bg: 'gray.900-08',
};

export interface SocialPageRowProps {
  accountAPI: FixTypeLater;
  onDelete: () => void;
  isOnboarding?: boolean;
}
const SocialPageRow = ({
  accountAPI,
  onDelete,
  isOnboarding = false,
}: SocialPageRowProps) => {
  const {
    apiHomePage,
    apiPostImage,
    apiPostName,
    apiAltName,
    requiresReconnect,
    accountAPIId,
    apiTypeId,
    apiStateId,
  } = accountAPI;
  const location = useLocation();
  const navigate = useNavigate();

  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const socialPageURN = convertToSocialPageURN(
    getURNName({ apiTypeId }),
    accountAPIId,
  );

  const [showRemovePageModal, setShowRemovePageModal] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [
    isInstagramAccountReadyModalOpen,
    setIsInstagramAccountReadyModalOpen,
  ] = useState(false);
  const [isAddIGGraphAPIModalOpen, setIsAddIGGraphAPIModalOpen] =
    useState(false);
  const [
    isIGCabinetReconnectionModalOpen,
    setIsIGCabinetReconnectionModalOpen,
  ] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isConnectTwitterAppModalOpen, setIsConnectTwitterAppModalOpen] =
    useState(false);
  const [isEditContentFeedsModalOpen, setIsEditContentFeedsModalOpen] =
    useState(false);

  const { showSuccessMessage } = useSuccessfulTwitterAppConnection();

  const showPageUnderSetupIndicator =
    !isOnboarding && apiStateId === API_STATES.UNDER_SETUP;

  const removeSinglyConnectedSyndfeeds = () => {
    const isUnderSetup =
      getCurrentPropertyState() === PROPERTY_STATES.NEWSIGNUP;
    const { accountAPIs, accountFeeds } = getCurrentProperty({
      globalInfo,
    });

    const allFeeds: AccountFeed[] = accountFeeds ?? [];

    const rssFeeds = allFeeds.filter(
      (feed) =>
        feed.syndFeedState === RSS_STATE_NAMES.ACTIVE ||
        feed.syndFeedState === RSS_STATE_NAMES.ERROR,
    );

    const pagesByFeed = getPagesByFeed({
      feeds: allFeeds,
      accountAPIs,
    });

    allFeeds.forEach(async (feed) => {
      if (
        pagesByFeed[feed.syndFeedURN].length === 1 &&
        pagesByFeed[feed.syndFeedURN][0] === accountAPIId
      ) {
        await deletePropertiesSynd({
          propertyId: getCurrentPropertyId(),
          syndFeedURN: feed.syndFeedURN,
        });

        delete globalInfo.properties[globalInfo.current.propertyId]
          .accountFeeds[rssFeeds.indexOf(feed)];

        tracker.track({
          eventName: 'Syndfeed Onboarding Delete Edge Case',
          trackingParams: {
            'Syndfeed URL': feed.syndFeedURL,
            Origin: isUnderSetup
              ? MIXPANEL_ORIGIN.SETUP
              : MIXPANEL_ORIGIN.SETTINGS,
          },
        });
      }
    });

    global.setGlobalInfo(globalInfo);
  };

  const removeSocialMediaAccountFromGlobalInfo = () => {
    delete globalInfo.properties[globalInfo.current.propertyId].accountAPIs[
      accountAPIId
    ];
    global.setGlobalInfo(globalInfo);
  };

  const handleRemove = async () => {
    try {
      await deleteAccountAPI({ socialAPIURN: socialPageURN });
      const socialAPIs = getSocialAPIs();
      socialAPIs.splice(socialAPIs.indexOf(accountAPIId), 1);
      removeSinglyConnectedSyndfeeds();
      removeSocialMediaAccountFromGlobalInfo();
      onDelete();
    } catch (error) {
      if (getErrorStatus(error) === null) {
        console.log(error);
        logger.error({
          event: 'Handle Remove API Error',
          properties: {
            location: 'components/setup/ConnectPageRow',
          },
          error,
        });
      }
    }

    setShowRemovePageModal(false);
  };

  const handleReconnect = useCallback(async () => {
    setIsReconnecting(true);
    if (apiTypeId === API_TYPE_IDS.INSTAGRAM) {
      if (!isIGCabinetEnabled()) {
        setIsInstagramAccountReadyModalOpen(true);
      } else {
        setIsIGCabinetReconnectionModalOpen(true);
      }
    } else {
      logger.info(`Pages.handleReconnectAPI - api ${accountAPIId}`);
      try {
        await reconnectAPI({
          socialPageURN,
        });
      } catch (error) {
        // Check for a Twitter App connection error and start the connection flow
        if (
          getErrorStatus(error) === 400 &&
          getErrorMessage(error).includes(
            'Twitter could not validate your credentials.',
          )
        ) {
          setIsConnectTwitterAppModalOpen(true);
        }
      }
    }

    setIsReconnecting(false);
  }, [accountAPIId, apiTypeId, socialPageURN]);

  const handleReconnectClick = useCallback(async () => {
    tracker.track({
      eventName: 'Reconnect Page',
      trackingParams: {
        'Social Network': getSocialNetworkName({
          apiTypeId,
        }),
        'Account API Id': accountAPIId,
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        Location: 'Page Settings',
      },
    });

    await handleReconnect();
  }, [accountAPIId, apiTypeId, handleReconnect]);

  const handleReconnectInstagram = async () => {
    logger.info(`Pages.handleReconnectAPI - api ${accountAPIId}`);
    await reconnectAPI({
      socialPageURN,
    });
    setIsAddIGGraphAPIModalOpen(false);
  };

  const handleReconnectInstagramCabinet = async (args = {}) => {
    logger.info(`Pages.handleReconnectAPI - api ${accountAPIId}`);

    setIsReconnecting(true);
    setApiError('');

    sessionStorage.setItem('connectingAPITypeId', apiTypeId);
    try {
      await reconnectAPI({ socialPageURN, args });
      setIsIGCabinetReconnectionModalOpen(false);
    } catch (error) {
      setApiError(getErrorMessage(error));
    }
  };

  const handleTwitterAppReconnection = async (
    appKey: string,
    appSecret: string,
  ) => {
    await connectTwitterApp({ appKey, appSecret, globalInfo });
    global.refreshGlobalInfo({
      reasonCode: GLOBAL_INFO_STATES.REFRESHING,
    });
    showSuccessMessage();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialAccountAPIId = params.get('accountAPIId');
    const isReconnectingPage = params.get('reconnectPage');
    if (
      isReconnectingPage === 'true' &&
      initialAccountAPIId &&
      Number(initialAccountAPIId) === accountAPIId
    ) {
      if (apiTypeId === API_TYPE_IDS.INSTAGRAM) {
        handleReconnect();
      } else if (apiTypeId === API_TYPE_IDS.TWITTER) {
        if (!isTwitterProjectAppEnabled({ globalInfo })) {
          setIsConnectTwitterAppModalOpen(true);
        } else {
          handleReconnect();
        }
      }
      params.delete('reconnectPage');
      params.delete('accountAPIId');
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [
    accountAPIId,
    apiTypeId,
    globalInfo,
    handleReconnect,
    navigate,
    location,
  ]);

  const isClickable = !isOnboarding && !requiresReconnect;

  let subText = (
    <Link href={apiHomePage} target="_blank" lineHeight={4} variant="secondary">
      @{apiAltName}
    </Link>
  );

  if (
    !isTwitterProjectAppEnabled({ globalInfo }) &&
    apiTypeId === API_TYPE_IDS.TWITTER
  ) {
    subText = (
      <Button
        variant="link"
        color="red.600"
        onClick={() => setIsConnectTwitterAppModalOpen(true)}
      >
        Reconnect Twitter Project App
      </Button>
    );
  } else if (requiresReconnect) {
    subText = (
      <Button
        variant="link"
        color="red.600"
        onClick={handleReconnectClick}
        isLoading={isReconnecting}
      >
        Reconnect page
      </Button>
    );
  }

  return (
    <>
      <Tr {...(isClickable && { _hover: { backgroundColor: 'gray.100' } })}>
        <TableCellWrapper isLinkBox={isClickable}>
          {apiPostImage !== getSocialNetworkDefaultPageIcon({ apiTypeId }) ? (
            <Image
              width={4}
              height={4}
              src={apiPostImage}
              alt=""
              borderRadius="full"
              mr={3}
            />
          ) : (
            <Circle
              bg="gray.200"
              border="1px"
              borderColor="gray.300"
              size={4}
              mr={3}
            >
              <SocialIcon apiTypeId={apiTypeId} color="gray.500" size={3} />
            </Circle>
          )}
          <TitleWrapper accountAPIId={accountAPIId} isLink={isClickable}>
            <Text
              size="sm"
              lineHeight={4}
              color="gray.900"
              fontWeight="medium"
              mr={2}
              {...(requiresReconnect && {
                textDecorationLine: 'line-through',
                color: 'gray.500',
              })}
            >
              {apiPostName}
            </Text>
          </TitleWrapper>
          {subText}
          <Flex ml="auto" gap={3}>
            {showPageUnderSetupIndicator && (
              <Tag color="gray" icon={<WrenchIcon />} iconPlacement="left">
                Under setup
              </Tag>
            )}
            <Dropdown gutter={8} strategy="fixed">
              <Dropdown.Button
                zIndex="10"
                aria-label="More options"
                width={6}
                height={6}
                color="gray.600"
                p={0.5}
                borderRadius="base"
                _active={hoverActiveStyles}
                _hover={hoverActiveStyles}
              >
                <MoreOptions />
              </Dropdown.Button>
              <Dropdown.List>
                {!isOnboarding && !requiresReconnect && (
                  <Dropdown.Item
                    as={RouterLink}
                    to={`/settings/page/${accountAPIId}`}
                  >
                    Edit Page Settings
                  </Dropdown.Item>
                )}
                {!isOnboarding &&
                  !requiresReconnect &&
                  AUTOMATION_SETTINGS_SUPPORTED_SOCIAL_PAGES.includes(
                    apiTypeId,
                  ) && (
                    <Dropdown.Item
                      as={RouterLink}
                      to={`/settings/automation/${accountAPIId}`}
                    >
                      Customize automation
                    </Dropdown.Item>
                  )}
                {!requiresReconnect && (
                  <Dropdown.Item
                    onClick={() => {
                      setIsEditContentFeedsModalOpen(true);
                    }}
                  >
                    Content feeds
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  isDisabled={isReconnecting}
                  onClick={handleReconnectClick}
                >
                  Reconnect page
                </Dropdown.Item>
                {apiTypeId === API_TYPE_IDS.TWITTER && (
                  <Dropdown.Item
                    onClick={() => setIsConnectTwitterAppModalOpen(true)}
                  >
                    Reconnect Twitter Project App
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => window.open(apiHomePage)}>
                  Visit page
                </Dropdown.Item>
                <Dropdown.Item
                  color="red.600"
                  onClick={() => setShowRemovePageModal(true)}
                >
                  Remove page
                </Dropdown.Item>
              </Dropdown.List>
            </Dropdown>
          </Flex>
          {showRemovePageModal && (
            <RemovePageModal
              accountAPI={accountAPI}
              onClose={() => setShowRemovePageModal(false)}
              onRemove={handleRemove}
            />
          )}
        </TableCellWrapper>
      </Tr>
      {isConnectTwitterAppModalOpen && (
        <ConnectTwitterAppModal
          onClose={() => setIsConnectTwitterAppModalOpen(false)}
          onConnect={handleTwitterAppReconnection}
          isReconnecting={true}
        />
      )}
      {isInstagramAccountReadyModalOpen && (
        <IsInstagramAccountReadyModal
          onClose={() => setIsInstagramAccountReadyModalOpen(false)}
          onReady={() => setIsAddIGGraphAPIModalOpen(true)}
        />
      )}
      {isAddIGGraphAPIModalOpen && (
        <AddIGGraphApiModal
          onClose={() => setIsAddIGGraphAPIModalOpen(false)}
          onBack={() => setIsInstagramAccountReadyModalOpen(true)}
          onSubmit={handleReconnectInstagram}
        />
      )}
      {isIGCabinetReconnectionModalOpen && (
        <ConnectInstagram
          apiError={apiError}
          isAdding={false}
          eventHandlers={{
            handleCancel: () => setIsIGCabinetReconnectionModalOpen(false),
            handleSubmit: handleReconnectInstagramCabinet,
          }}
        />
      )}
      {isEditContentFeedsModalOpen && (
        <EditContentFeedsModal
          accountAPIId={accountAPIId}
          onClose={() => {
            setIsEditContentFeedsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SocialPageRow;
