import { Image, ImageProps } from '@ebx-ui/ebx-ui-component-library-sdk';

const PageImage = (props: ImageProps) => {
  return (
    <Image
      w="5"
      h="5"
      borderRadius="full"
      borderWidth="1px"
      borderColor="gray.300"
      borderStyle="solid"
      {...props}
    />
  );
};

export default PageImage;
