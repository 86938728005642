import PropTypes from 'prop-types';
import { useState } from 'react';

import MFAPopup from 'components/mfa/MFAPopup';
import TurnOffMFA from 'components/mfa/TurnOffMFA';
import Button from 'components/misc/Button';
import Panel from 'components/settings/user/Panel';

/**
 * React component for rendering MFA panel in user details
 * Accept the following props (required):
 * 1) hasSetupMFA - boolean value indicating if a user has setup MFA or not
 */
const MFA = ({ hasSetupMFA }) => {
  const [isOpenSetupMFA, setIsOpenSetupMFA] = useState(false);
  const [isOpenTurnOffMFA, setIsOpenTurnOffMFA] = useState(false);

  // An object to store details of what to show based on user's MFA status
  const buttonAndTextObj = {
    false: {
      button: 'Set up Two-factor Authentication',
      text: "With Two-factor Authentication, you'll protect your account with both your password and your phone.",
      onClick: () => setIsOpenSetupMFA(true),
    },
    true: {
      button: 'Turn Off Two-factor Authentication',
      text: "You'll need to reconfigure the authenticator app if you decide to start using 2FA again",
      onClick: () => setIsOpenTurnOffMFA(true),
    },
  };

  return (
    <>
      <Panel title="Two-factor Authentication (2FA)">
        <Button
          id="setup-mfa"
          variant="dark"
          size="lg"
          className="mt-3 mb-3"
          onClick={buttonAndTextObj[hasSetupMFA].onClick}
        >
          {buttonAndTextObj[hasSetupMFA].button}
        </Button>
        <p>{buttonAndTextObj[hasSetupMFA].text}</p>
      </Panel>
      <MFAPopup
        isOpen={isOpenSetupMFA}
        onDismiss={() => setIsOpenSetupMFA(false)}
      />
      <TurnOffMFA
        isOpen={isOpenTurnOffMFA}
        onDismiss={() => setIsOpenTurnOffMFA(false)}
      />
    </>
  );
};

MFA.propTypes = {
  hasSetupMFA: PropTypes.bool.isRequired,
};

export default MFA;
