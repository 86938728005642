import { create } from 'zustand';

import type { PostPreview } from 'types';

interface ComposeBoxOpenState {
  isComposeBoxOpen: boolean;
  initialPostPreviews: Array<PostPreview>;
  preloadedFiles: Array<File> | null;
  openComposeBox: (initialPostPreviews: Array<PostPreview>) => void;
  closeComposeBox: () => void;
  setPreloadedFiles: (preloadedFiles: Array<File> | null) => void;
}

const useComposeBoxOpenStore = create<ComposeBoxOpenState>((set) => ({
  isComposeBoxOpen: false,
  initialPostPreviews: [],
  preloadedFiles: null,
  openComposeBox: (initialPostPreviews: Array<PostPreview>) =>
    set({ isComposeBoxOpen: true, initialPostPreviews }),
  closeComposeBox: () =>
    set({
      isComposeBoxOpen: false,
      initialPostPreviews: [],
      preloadedFiles: null,
    }),
  setPreloadedFiles: (preloadedFiles: Array<File> | null) =>
    set({ preloadedFiles }),
}));

const useIsComposeBoxOpen = () =>
  useComposeBoxOpenStore((state) => state.isComposeBoxOpen);

export { useComposeBoxOpenStore, useIsComposeBoxOpen };
