import { Flex } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { getAPITypeId } from 'common/accountAPIs';
import { DataSourceSettings, MediaPreviewMetadata } from 'types';

import AvailableSources from '../common/AvailableSources';
import Preview from '../common/Preview';
import Options from './sharepreview/Options';

interface SharePreviewProps {
  accountAPIId: number;
  dataSourceSettings: DataSourceSettings;
  isSaving: boolean;
  onDropDownChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPreview?: () => void;
  overrideMessageSources?: Record<string, string>;
}

const SharePreview = ({
  accountAPIId,
  dataSourceSettings,
  isSaving,
  onDropDownChange,
  onPreview,
  overrideMessageSources,
}: SharePreviewProps) => {
  const [metadata, setMetadata] = useState<MediaPreviewMetadata | null>(null);

  const apiTypeId = getAPITypeId({ accountAPIId });

  return (
    <Flex gap={20}>
      <Options
        accountAPIId={accountAPIId}
        isSaving={isSaving}
        dataSourceSettings={dataSourceSettings}
        onDropdownChange={onDropDownChange}
        overrideMessageSources={overrideMessageSources}
      />
      <Flex maxW="lg" flexGrow={1} gap={2} flexDir="column">
        <Preview
          dataSourceSettings={dataSourceSettings}
          metadata={metadata}
          onMetadataChange={setMetadata}
          onPreview={onPreview}
        />
        <AvailableSources metadata={metadata} apiTypeId={apiTypeId} />
      </Flex>
    </Flex>
  );
};
export default SharePreview;
