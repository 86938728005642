import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';
import { z } from 'zod';

import {
  getAccountAPI,
  getAPITypeId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES, TEXT_CASES } from 'common/constants';
import {
  getDateFromUnix,
  getFormattedDateFromUnix,
  getFormattedTimeslotsFromUnix,
} from 'common/datetime';
import * as MediaItem from 'common/mediaItem';
import { getSetting } from 'common/settings';
import {
  getSocialNetworkDefaultPageIcon,
  getSocialNetworkIcon,
  getSocialNetworkName,
} from 'common/social';
import * as tracker from 'common/tracker';
import { isNullOrUndefined } from 'common/utility';
import { lyingParse } from 'common/zod';
import { getSchedulePostTrackingParams } from 'helpers/tracking';
import { FixTypeLater } from 'types';

const errorDetailsSchema = z
  .object({
    maxScheduleUnixTime: z.string().optional(),
    message: z.string(),
    minScheduleUnixTime: z.string().optional(),
    scheduledByUser: z.string().optional(),
    scheduledUnixTime: z.string().optional(),
  })
  .describe('DuplicateSchedulePopupProps.errorDetailsSchema');

interface DuplicateSchedulePopupProps {
  mediaItem: FixTypeLater;
  onClose: () => void;
  onSchedule: () => void;
}

const DuplicateSchedulePopup = ({
  mediaItem,
  onClose,
  onSchedule,
}: DuplicateSchedulePopupProps) => {
  // Track each time a new duplicate post warning is shown
  useEffect(() => {
    tracker.track({
      eventName: 'Display Duplicate Post Warning',
      trackingParams: getSchedulePostTrackingParams({
        mediaItem,
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorDetails = MediaItem.getErrorDetails({ mediaItem });

  if (
    typeof errorDetails !== 'object' ||
    errorDetails == null ||
    !('error' in errorDetails) ||
    !errorDetails?.error
  ) {
    return null;
  }

  const {
    maxScheduleUnixTime,
    message,
    minScheduleUnixTime,
    scheduledByUser,
    scheduledUnixTime,
  } = lyingParse(errorDetailsSchema, errorDetails.error);
  const currentPageId = MediaItem.getAccountAPIId({ mediaItem });

  const trackAction = (action: string) => {
    const trackingParams = getSchedulePostTrackingParams({
      mediaItem,
    });
    tracker.track({
      eventName: 'Action Duplicate Post Warning',
      trackingParams: {
        ...trackingParams,
        Action: action,
      },
    });
  };

  const handleClose = () => {
    trackAction('Close Popup');
    onClose();
  };

  const handleDontSchedule = () => {
    trackAction("Don't Schedule");
    onClose();
  };

  const handleSchedule = () => {
    trackAction('Schedule');
    onSchedule();
  };

  const generateScheduleInfo = () => {
    const twelveHourFormatSetting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
      propertyId: getCurrentPropertyId(),
    });
    const twelveHourFormat: boolean = !isNullOrUndefined(
      twelveHourFormatSetting,
    )
      ? twelveHourFormatSetting.enabled
      : false;

    let scheduleInfo = '';
    if (isNullOrUndefined(scheduledByUser)) {
      scheduleInfo = 'Scheduled automatically by Echobox';
    } else if (minScheduleUnixTime === '0' && maxScheduleUnixTime !== '0') {
      const date = scheduledUnixTime
        ? getDateFromUnix(parseInt(scheduledUnixTime, 10))
        : null;
      if (date?.slang) {
        scheduleInfo = `Scheduled: ${date.slang}, Optimal by ${scheduledByUser}`;
      } else {
        scheduleInfo = `Scheduled: Optimal by ${scheduledByUser}`;
      }
    } else if (
      scheduledUnixTime &&
      minScheduleUnixTime === maxScheduleUnixTime
    ) {
      const scheduledTime = getFormattedDateFromUnix({
        timestamp: parseInt(scheduledUnixTime, 10),
        twelveHourFormat,
        textCase: TEXT_CASES.CAMEL,
        useLongForm: true,
      });
      scheduleInfo = `Scheduled: ${scheduledTime} by ${scheduledByUser}`;
    } else if (minScheduleUnixTime && maxScheduleUnixTime) {
      const scheduledTime = getFormattedTimeslotsFromUnix({
        minTime: parseInt(minScheduleUnixTime, 10),
        maxTime: parseInt(maxScheduleUnixTime, 10),
        twelveHourFormat,
      });
      scheduleInfo = `Scheduled: ${scheduledTime} by ${scheduledByUser}`;
    }

    return scheduleInfo;
  };

  const currentPage = getAccountAPI({ accountAPIId: currentPageId });
  const apiTypeId = getAPITypeId({ accountAPIId: currentPageId });

  const accountImage =
    currentPage?.apiPostImage ?? getSocialNetworkDefaultPageIcon({ apiTypeId });
  const accountName = currentPage?.apiPostName ?? '';
  const networkImage = getSocialNetworkIcon({ apiTypeId });
  const networkName = getSocialNetworkName({ apiTypeId });

  return (
    <Modal isOpen onClose={handleClose} size="medium">
      <Modal.Header>
        <Modal.Title>Duplicate detected</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm" marginBottom={4}>
          {message} on:
        </Text>
        <Box pos="relative">
          <Flex alignItems="center" gap={3}>
            <Box>
              <Image
                w={22}
                h={22}
                rounded="full"
                src={accountImage}
                alt={accountName}
              />
              <Box pos="absolute" top={3} left={3.5}>
                <Image w={3.5} h={3.5} src={networkImage} alt={networkName} />
              </Box>
            </Box>
            <Text marginTop={1} size="sm" fontWeight={700}>
              {accountName}
            </Text>
            <Box marginTop={1}>
              <Text size="sm" color="gray.600">
                {generateScheduleInfo()}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleDontSchedule}>
          Cancel
        </Button>
        <Button onClick={handleSchedule}>Schedule anyway</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateSchedulePopup;
