import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { isCompetitorLeaderboardEnabled } from 'common/competitorAnalytics';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'common/featureFlags';
import * as routing from 'common/routing';
import { requireStaff } from 'common/routing';
import ErrorBoundary from 'components/misc/ErrorBoundary';
import FullPageSpinner from 'components/misc/FullPageSpinner';
import Advanced from 'components/settings/automation/Advanced';
import AutomationSettings from 'components/settings/automation/AutomationSettings';
import Overview from 'components/settings/automation/Overview';
import SinglePageAutomationSettings from 'components/settings/automation/SinglePageAutomationSettings';
import NewSharesKeywords from 'components/settings/automation/newshares/Keywords';
import NewShares from 'components/settings/automation/newshares/NewShares';
import NewSharesPrefixSuffix from 'components/settings/automation/newshares/PrefixSuffix';
import DataSources from 'components/settings/automation/newshares/datasources/DataSources';
import ResharesKeywords from 'components/settings/automation/reshares/Keywords';
import ResharesPrefixSuffix from 'components/settings/automation/reshares/PrefixSuffix';
import Reshares from 'components/settings/automation/reshares/Reshares';
import SchedulePeriods from 'components/settings/automation/schedule/SchedulePeriods';
import FlashMessagesProvider from 'context/FlashMessagesProvider';
import { lazyWithRetries } from 'helpers/lazyLoad';
import Echobox from 'layouts/Echobox';
import Global from 'layouts/Global';
import Protected from 'layouts/Protected';
import SessionWrapper from 'layouts/SessionWrapper';
import APIConnectError from 'pages/APIConnectError';
import AddProperty from 'pages/AddProperty';
import Competitors from 'pages/Competitors';
import Details from 'pages/Details';
import Logout from 'pages/Logout';
import Master from 'pages/Master';
import NewsFeed from 'pages/NewsFeed';
import OnBoardingSetup from 'pages/OnBoardingSetup';
import Settings from 'pages/Settings';
import Signup from 'pages/Signup';
import ContentFeeds from 'pages/settings/ContentFeeds';
import Page from 'pages/settings/Page';
import Pages from 'pages/settings/Pages';
import Property from 'pages/settings/Property';
import Team from 'pages/settings/Team';
import User from 'pages/settings/User';
import Users from 'pages/settings/Users';
import PerformanceGoal from 'pages/settings/property/PerformanceGoal';

const TemplateEditor = lazyWithRetries(() => import('pages/TemplateEditor'));

// TODO: Re-enable lazy loading, once we have a solution for server-side caching
// const Settings = lazyWithRetries(() => import('pages/Settings'));
// const OnBoardingSetup = lazyWithRetries(() => import('pages/OnBoardingSetup'));

const isTeamSettingsEnabled = isFeatureFlagEnabled({
  flag: FEATURE_FLAGS.TEAM_SETTINGS,
});

const Root = () => {
  return (
    <Routes>
      <Route
        element={
          <ErrorBoundary showMessageOnError={false}>
            <FlashMessagesProvider>
              <SessionWrapper>
                <Global />
              </SessionWrapper>
            </FlashMessagesProvider>
          </ErrorBoundary>
        }
      >
        <Route
          path="/addproperty"
          element={
            <Protected redirector={routing.requireAdmin}>
              <WrapPartial component={AddProperty} />
            </Protected>
          }
        />
        <Route path="/health" element={<div />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/analytics"
          element={
            <Protected redirector={routing.requireSetup('/analytics')}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        <Route
          path="/apiConnectError"
          element={<WrapFull component={APIConnectError} />}
        />
        <Route
          path="/archive"
          element={
            <Protected redirector={routing.requireSetup('/archive')}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        <Route
          path="/details"
          element={
            <Protected redirector={routing.requireSetup('/details')}>
              <WrapFull component={Details} />
            </Protected>
          }
        />
        <Route
          path="/login"
          element={
            <Protected redirector={routing.requireNotLoggedIn}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        <Route
          path="/queue"
          element={
            <Protected redirector={routing.requireSetup('/queue')}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        <Route
          path="/approvals"
          element={
            <Protected redirector={routing.requireSetup('/approvals')}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        {isCompetitorLeaderboardEnabled() && (
          <Route
            path="/competitors"
            element={
              <Protected redirector={routing.requireSetup('/competitors')}>
                <WrapFull component={Competitors} />
              </Protected>
            }
          />
        )}
        <Route
          path="/competitors"
          element={
            <Protected redirector={routing.requireSetup('/competitors')}>
              <WrapFull component={Competitors} />
            </Protected>
          }
        />
        <Route
          path="/select"
          element={
            <Protected redirector={() => routing.selectOrSetup()}>
              <WrapPartial component={Settings} />
            </Protected>
          }
        />
        <Route
          path="/settings/*"
          element={
            <Suspense fallback={<FullPageSpinner />}>
              <WrapPartial component={Settings} />
            </Suspense>
          }
        >
          <Route
            path="automation/:accountAPIId"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin(
                  '/settings/automation/:accountAPIId',
                )}
              >
                <SinglePageAutomationSettings />
              </Protected>
            }
          >
            <Route path="*" element={<Overview />} />
            <Route path="newshares" element={<NewShares />} />
            <Route path="newshares/datasources" element={<DataSources />} />
            <Route
              path="newshares/prefix-suffix"
              element={<NewSharesPrefixSuffix />}
            />
            <Route path="newshares/keywords" element={<NewSharesKeywords />} />
            <Route path="reshares" element={<Reshares />} />
            <Route path="reshares/keywords" element={<ResharesKeywords />} />
            <Route
              path="reshares/prefix-suffix"
              element={<ResharesPrefixSuffix />}
            />
            <Route path="schedule" element={<SchedulePeriods />} />
            <Route path="advanced" element={<Advanced />} />
          </Route>
          <Route
            path="automation"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin(
                  '/settings/automation',
                )}
              >
                <AutomationSettings />
              </Protected>
            }
          />
          <Route
            path="property/performancegoal"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin(
                  '/settings/property/performancegoal',
                )}
              >
                <PerformanceGoal />
              </Protected>
            }
          />
          <Route
            path="property"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin('/settings/property')}
              >
                <Property />
              </Protected>
            }
          />
          <Route
            path="pages"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin('/settings/pages')}
              >
                <Pages />
              </Protected>
            }
          />
          <Route
            path="page/:accountAPIId"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin('/settings/page')}
              >
                <Page />
              </Protected>
            }
          />
          <Route
            path="users"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin('/settings/users')}
              >
                {isTeamSettingsEnabled ? <Team /> : <Users />}
              </Protected>
            }
          />
          <Route path="user" element={<User />} />
          <Route
            path="content-feeds"
            element={
              <Protected
                redirector={routing.requireSetupAndAdmin(
                  '/settings/content-feeds',
                )}
              >
                <ContentFeeds />
              </Protected>
            }
          />
          <Route
            path="*"
            element={<Navigate replace to="/settings/property" />}
          />
        </Route>
        <Route
          path="create-template"
          element={
            <Protected redirector={requireStaff}>
              <Suspense>
                <TemplateEditor />
              </Suspense>
            </Protected>
          }
        />
        <Route
          path="/setup/*"
          element={
            <Protected redirector={routing.setupOrSettings}>
              <Suspense fallback={<FullPageSpinner />}>
                <WrapPartial component={OnBoardingSetup} />
              </Suspense>
            </Protected>
          }
        />
        <Route
          path="/share/:accountAPIId"
          element={
            <Protected redirector={routing.requireSetup('/share')}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        <Route
          path="/share"
          element={
            <Protected redirector={routing.requireSetup('/share')}>
              <WrapFull component={NewsFeed} />
            </Protected>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Route>
    </Routes>
  );
};

export default Root;

interface WrapProps {
  component: React.ComponentType;
}

const WrapFull = ({ component: Component, ...rest }: WrapProps) => (
  <FlashMessagesProvider>
    <Echobox>
      <Master>
        <Component {...rest} />
      </Master>
    </Echobox>
  </FlashMessagesProvider>
);

const WrapPartial = ({ component: Component, ...rest }: WrapProps) => (
  <Echobox>
    <Component {...rest} />
  </Echobox>
);
