const Page = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55188 7.3125H6.45813C6.17454 7.3125 5.9425 7.54453 5.9425 7.82812C5.9425 8.11172 6.17454 8.34375 6.45813 8.34375H9.55188C9.83762 8.34375 10.0675 8.11387 10.0675 7.82812C10.0675 7.54238 9.83762 7.3125 9.55188 7.3125ZM7.48938 9.375H6.45813C6.17454 9.375 5.9425 9.60703 5.9425 9.89062C5.9425 10.1742 6.17346 10.4062 6.45813 10.4062H7.48938C7.77405 10.4062 8.005 10.1753 8.005 9.89062C8.005 9.60596 7.77512 9.375 7.48938 9.375ZM9.55188 5.25H6.45813C6.17454 5.25 5.9425 5.48203 5.9425 5.76562C5.9425 6.04922 6.17454 6.28125 6.45813 6.28125H9.55188C9.83762 6.28125 10.0675 6.05137 10.0675 5.76562C10.0675 5.47988 9.83762 5.25 9.55188 5.25ZM10.755 2.5H5.255C4.49553 2.5 3.88 3.11553 3.88 3.875V12.125C3.88 12.8845 4.49553 13.5 5.255 13.5H10.755C11.5145 13.5 12.13 12.8845 12.13 12.125V3.875C12.13 3.11553 11.5134 2.5 10.755 2.5ZM11.0988 12.125C11.0988 12.3145 10.9445 12.4688 10.755 12.4688H5.255C5.06547 12.4688 4.91125 12.3145 4.91125 12.125V3.875C4.91125 3.68546 5.06547 3.53125 5.255 3.53125H10.755C10.9445 3.53125 11.0988 3.68546 11.0988 3.875V12.125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Page;
