import * as React from 'react';

import { FRONTEND_METRICS } from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as metrics from 'common/metrics';
import type { FixTypeLater } from 'types';

interface PreviewMetricsProps {
  children: JSX.Element;
  mediaItem: FixTypeLater;
  previewIndex: number;
}

const PreviewMetrics = ({
  children,
  mediaItem,
  previewIndex,
}: PreviewMetricsProps) => {
  const timeMountedRef = React.useRef(Date.now());
  const hasLoggedMetricsRef = React.useRef(false);

  const isLoading = MediaItem.getIsLoading({ mediaItem });
  const accountAPIId = MediaItem.getAccountAPIId({ mediaItem });

  React.useEffect(() => {
    if (
      !isLoading &&
      // Don't log if we uncheck and then recheck page in page dropdown
      !hasLoggedMetricsRef.current &&
      // Don't log if it is the first preview (loading time handled by ComposeBox component)
      previewIndex !== 0
    ) {
      logger.track({
        event: 'Post Preview Load Metrics',
        properties: {
          loadTimeMS: Date.now() - timeMountedRef.current,
        },
      });
      hasLoggedMetricsRef.current = true;
      metrics.measure(FRONTEND_METRICS.ADD_SOCIAL_PAGE, {
        id: accountAPIId,
      });
    }
  }, [accountAPIId, isLoading, previewIndex]);

  return children;
};

export default PreviewMetrics;
