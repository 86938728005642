import {
  getPropertiesConnectRequestNew,
  getSocialPageConnectRequestNew,
} from 'api/api';
import { constructBaseSocialAPIURL } from 'api/settings';
import { FixTypeLater } from 'types';

import {
  getCurrentProperty,
  getCurrentPropertyId,
  getEnabledTwitterApp,
} from './accountAPIs';
import { API_PROPERTIES, API_TYPE_IDS } from './constants';
import { getGlobalInfo } from './globalInfo';
import { convertToPropertyURN } from './urn';

/**
 * Detects if the Twitter Project App is enabled for the current property.
 */
export function isTwitterProjectAppEnabled({
  globalInfo = getGlobalInfo(),
}: {
  globalInfo?: FixTypeLater;
} = {}) {
  const accountAPIs = getCurrentProperty({ globalInfo }).accountAPIs;
  const twitterApp = getEnabledTwitterApp({ accountAPIs });
  return twitterApp !== null;
}

/**
 * Connects the Twitter Project App for the current property using the app key and secret provided.
 */
export async function connectTwitterApp({
  appKey,
  appSecret,
  globalInfo = getGlobalInfo(),
}: {
  appKey: string;
  appSecret: string;
  globalInfo: FixTypeLater;
}) {
  const args = {
    socialAPIType: API_PROPERTIES[API_TYPE_IDS.TWITTER_APP].apiType!,
    uiCallbackURL: window.location.href,
    apiCallbackURL: constructBaseSocialAPIURL('v3', true),
    extraParameters: {
      appKey,
      appSecret,
    },
  };

  const accountAPIs = getCurrentProperty({ globalInfo }).accountAPIs;
  const twitterApp = getEnabledTwitterApp({ accountAPIs });

  if (twitterApp?.socialAPIURN != null) {
    await getSocialPageConnectRequestNew({
      socialAPIURN: twitterApp.socialAPIURN,
      ...args,
    });
  } else {
    await getPropertiesConnectRequestNew({
      propertyURN: convertToPropertyURN(
        getCurrentPropertyId({
          globalInfo,
        }),
      ),
      ...args,
    });
  }
}
