import { getAccountAPI, getAPITypeId } from 'common/accountAPIs';
import { REACT_PREVENT_RENDER } from 'common/constants';
import { getSocialNetworkIcon, getSocialNetworkName } from 'common/social';
import { isNullOrUndefined } from 'common/utility';
import type { SocialAPI } from 'types';

interface SocialPagesHeaderItemProps {
  accountAPIId: number;
}

const SocialPagesHeaderItem = (props: SocialPagesHeaderItemProps) => {
  const { accountAPIId } = props;
  const accountAPI = getAccountAPI({ accountAPIId });
  if (isNullOrUndefined(accountAPI)) {
    return REACT_PREVENT_RENDER;
  }
  const apiTypeId = getAPITypeId({ accountAPIId });
  // Checked for null accountAPI above, so can assume it is defined here
  const { apiPostImage, apiPostName } = accountAPI as SocialAPI;
  const networkIcon = getSocialNetworkIcon({ apiTypeId });
  const networkName = getSocialNetworkName({ apiTypeId });

  return (
    <span className="bg-light-blue mr-1 mb-1 d-flex align-items-center selected-pages">
      <div className="position-relative mr-2">
        <img
          className=""
          src={networkIcon}
          alt={networkName}
          width="13px;"
          style={{
            position: 'absolute',
            bottom: '-4px',
            right: '-4px',
          }}
        />
        <img
          className="rounded-circle"
          width="18px;"
          src={apiPostImage}
          alt=""
        />
      </div>
      <span className="text-truncate">{apiPostName}</span>
    </span>
  );
};

export default SocialPagesHeaderItem;
