import PropTypes from 'prop-types';

import DropDown from 'components/misc/DropDown';

/*
 * DropDownAdd
 */

const DropDownAdd = (props) => {
  /**
   * Render method
   */

  return (
    <DropDown
      name={props.name}
      value=""
      handleDropDownChange={props.handleDropDownChange}
      dataCySelect={props.dataCySelect}
      sources={props.sources}
      disabled={props.disabled}
      default={{
        value: '',
        label: 'Select country',
        selectable: true,
      }}
      dropDownClass={props.className}
    />
  );
};

DropDownAdd.propTypes = {
  name: PropTypes.string.isRequired,
  sources: PropTypes.object.isRequired,
  handleDropDownChange: PropTypes.func.isRequired,
  dataCySelect: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

DropDownAdd.defaultProps = {
  className: '',
};

export default DropDownAdd;
