import {
  Button,
  Image,
  Modal,
  Text,
  useToken,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import Steps from './PagesModalSteps';

interface AddFacebookModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const AddFacebookModal = ({ onClose, onSubmit }: AddFacebookModalProps) => {
  const [red600] = useToken('colors', ['red.600']);

  const redText = {
    color: red600,
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add Facebook page</Modal.Title>
      </Modal.Header>
      <Modal.Body overflowY="auto" maxHeight="70vh">
        <Steps>
          <Steps.Step>
            <Text as="span">
              Make sure you have <span style={redText}>Admin permissions </span>{' '}
              for every Facebook page you would like to use with Echobox. If you
              are using Meta Business Suite,{' '}
              <span style={redText}>Manage Page </span> permissions must be
              turned on
            </Text>
          </Steps.Step>
          <Steps.Step>
            <Text as="span">
              You&apos;ll be redirected to Facebook, where{' '}
              <span style={redText}>all pages must be selected</span>, including
              the ones you don&apos;t intend to use in Echobox
            </Text>
          </Steps.Step>
          <Image
            src="/img/img-connect-fb-page-select-pages (Cropped).png"
            w="full"
            borderRadius="md"
          />
          <Steps.Step>
            <Text as="span">
              Once redirected back, you will be able to select the specific
              Facebook pages you want to use in Echobox
            </Text>
          </Steps.Step>
        </Steps>
      </Modal.Body>
      <Modal.Footer>
        <div className="mr-auto">
          <Button
            variant="tertiary"
            as="a"
            href="mailto:support@echobox.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact support
          </Button>
        </div>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSubmit}>I understand</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFacebookModal;
