import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { getFeatureToggle } from 'common/settings';
import Step from 'components/setup/SidebarStep';
import Steps from 'components/setup/SidebarSteps';

const Sidebar = () => {
  const isTrackerEnabledForCurrentProperty =
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.REQUIRES_EBX_TRACKER,
      propertyId: getCurrentPropertyId(),
    }) ?? true;

  const steps = [
    { id: 'add-team-members', name: 'Add team members' },
    { id: 'connect-social-pages', name: 'Connect social pages' },
    { id: 'add-content-feeds', name: 'Add content feeds' },
    ...(isTrackerEnabledForCurrentProperty
      ? [{ id: 'install-web-tag', name: 'Install Web Tag' }]
      : []),
    { id: 'review', name: 'Review' },
  ];
  return (
    <Box
      pos="absolute"
      inset="0 auto 0 0"
      mt={15}
      w={80}
      py={12}
      px={8}
      bg="base"
      boxShadow="borderRight"
    >
      <Steps>
        {steps.map((step, index) => (
          <Step key={step.id} stepNumber={index + 1} id={step.id}>
            {step.name}
          </Step>
        ))}
      </Steps>
    </Box>
  );
};

export default Sidebar;
