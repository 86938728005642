import type { ReactEventHandler } from 'react';
import { useEffect, useMemo, useState } from 'react';

import {
  getAccountAPI,
  getAPIPostName,
  getAPITypeId,
  getCurrentAPITypeId,
} from 'common/accountAPIs';
import {
  API_TYPE_IDS,
  POST_TYPES,
  SOCIAL_CHANNELS,
  TEXT_CASES,
} from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { convertEchoboxImageToAmazon, isEbxImage, isEbxMedia } from 'common/s3';
import {
  getAllowedFileTypes,
  getImageAspectRatios,
  getImageTransformOptions,
  getPostTypeLabel,
  getSocialNetworkName,
} from 'common/social';
import { generateGuid } from 'common/string';
import * as tracker from 'common/tracker';
import { addCacheBuster, getRootDomain, isVideoURL } from 'common/url';
import { getImageDimensions } from 'helpers/images';
import { getNetworkAndPageName } from 'helpers/tracking';
import { useComposeBoxOpenStore } from 'state/composeBoxOpen';
import type { FixTypeLater, IGLinkStickerConfig, PostType } from 'types';

import { isIGCabinetEnabled } from 'common/settings';
import { ACTIONS, Image, IMAGE_FILE_TYPES, IMAGE_MODES } from '../imageV2';
import type { ImageAction, ImageMode } from '../imageV2/types';
import InstagramLinkStickerPreview from './InstagramLinkStickerPreview';
import SocialImageControls from './SocialImageControls';
import TwitterCard from './TwitterCard';
import TwitterImageControls from './TwitterImageControls';

const getImageMode = ({
  postType,
  mediaItem,
}: {
  postType: PostType;
  mediaItem: FixTypeLater;
}) => {
  const canCustomiseLinkPosts = MediaItem.getCanCustomiseLinkPosts({
    mediaItem,
  });
  const articleURL = MediaItem.getUnshortenedShareURL({ mediaItem }); // here
  const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
  const hasImageOverlay = trackingDetails?.hasImageOverlay ?? false;

  if (postType !== POST_TYPES.LINK) {
    return IMAGE_MODES.PREVIEW;
  }

  if (hasImageOverlay) {
    return IMAGE_MODES.UNEDITABLE_NO_REFRESH;
  }

  if (!canCustomiseLinkPosts || isVideoURL(articleURL)) {
    return IMAGE_MODES.UNEDITABLE;
  }
  return IMAGE_MODES.PREVIEW;
};

interface SocialImageProps {
  /**
   * The mediaItem object containing image urls
   */
  mediaItem: FixTypeLater;

  /**
   * The images managed by the component
   */
  articleImages: Array<string>;

  /**
   * The guid of the image
   */
  guid: string;

  /**
   * The ID for the account API
   */
  accountAPIId: number;

  /**
   * The image to show on the Twitter card
   */
  twitterCardImage?: string | null;

  /**
   * Boolean of whether there is a correct aspect ratio
   */
  hasCorrectAspectRatio: boolean;

  /**
   * A flag to indicate that the image has been modified
   */
  hasBeenModified: boolean;

  /**
   * The index of the current image
   */
  currentImage: number;

  /**
   * Boolean of whether the image is currently downloading
   */
  isDownloading: boolean;

  /**
   * Boolean of whether the image is currently being edited
   */
  isEditing: boolean;

  /**
   * Boolean of whether the image is currently being uploaded to s3
   */
  isUploading: boolean;

  /**
   * The percentage of the upload progress
   */
  uploadProgressPercent?: number;

  pendingStickerConfig?: IGLinkStickerConfig | null;
  onSave: (file: File) => void;
  onEdit: () => void;
  onEditClose: () => void;
  onCrop: () => void;
  onRefresh: () => void;
  onDownload: (downloadImageURL: string) => Promise<void>;
  onFileDrop: (files: Array<File>) => Promise<void>;
  onPendingStickerConfig: (pendingStickerConfig: IGLinkStickerConfig) => void;
  onNextImage: () => void;
  onPrevImage: () => void;
  onDeleteImage: () => void;
  onDeleteVideo: () => void;
  onImageLoad: ReactEventHandler<HTMLImageElement & HTMLVideoElement>;
  onVideoLoad: ReactEventHandler<HTMLImageElement & HTMLVideoElement>;
  onCancel: () => void;
  onUploadCancel: () => void;
}

const SocialImage = ({
  mediaItem,
  articleImages,
  guid,
  accountAPIId,
  twitterCardImage = null,
  hasCorrectAspectRatio,
  hasBeenModified,
  currentImage,
  isDownloading,
  isEditing,
  isUploading,
  uploadProgressPercent = 0,
  pendingStickerConfig = null,
  onCancel,
  onCrop,
  onDeleteImage,
  onDeleteVideo,
  onDownload,
  onEdit,
  onEditClose,
  onFileDrop,
  onImageLoad,
  onVideoLoad,
  onNextImage,
  onPendingStickerConfig,
  onPrevImage,
  onRefresh,
  onSave,
  onUploadCancel,
}: SocialImageProps) => {
  const postType = MediaItem.getPostType({ mediaItem });
  const initialMode = getImageMode({ postType, mediaItem });
  const [mode, setMode] = useState<ImageMode>(initialMode);
  const preloadedFiles = useComposeBoxOpenStore(
    (state) => state.preloadedFiles,
  );

  useEffect(
    () =>
      setMode(
        preloadedFiles && preloadedFiles.length > 0
          ? IMAGE_MODES.UPLOADING
          : initialMode,
      ),
    [initialMode, preloadedFiles],
  );

  /**
   * Event handler triggered when a file is edited in Image Component
   * @param files - changed files
   * @param action - type of change. Currently only EDIT
   */
  const handleChange = async (files: Array<File>, action: ImageAction) => {
    switch (action) {
      case ACTIONS.EDIT:
        handleEditSave(files[0]);
        onEditClose();
        break;
      case ACTIONS.CROP:
        handleCropSave(files[0]);
        break;
      case ACTIONS.UPLOAD:
        await onFileDrop(files);
        break;
      case ACTIONS.CANCEL:
        await onCancel();
        break;
      default:
        logger.error({ event: `action not supported - ${action}` });
    }
  };

  /**
   * Function to handle the edited image
   * Takes the edited image, tracks changed width and height in mixpanel and calls handleSave
   * @param file - the edited image
   */
  const handleEditSave = async (file: File) => {
    if (imageURL == null) {
      return;
    }

    const { width, height } = await getImageDimensions({ file: imageURL });
    const { width: newWidth, height: newHeight } = await getImageDimensions({
      file: URL.createObjectURL(file),
    });
    tracker.track({
      eventName: 'Edit Image',
      trackingParams: {
        'Social Network': getSocialNetworkName({
          apiTypeId: getCurrentAPITypeId(),
        }),
        'Social Page': getAPIPostName({
          accountAPIId,
        }),
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Width Before': width,
        'Height Before': height,
        'Width After': newWidth,
        'Height After': newHeight,
      },
    });

    onSave(file);
  };

  /**
   * Function to save the cropped image
   * Takes the cropped image, tracks changed width and height in mixpanel and calls handleSave
   * @param file - the cropped image
   */
  const handleCropSave = async (file: File) => {
    if (imageURL == null) {
      return;
    }

    const { width, height } = await getImageDimensions({ file: imageURL });
    const { width: newWidth, height: newHeight } = await getImageDimensions({
      file: URL.createObjectURL(file),
    });
    tracker.track({
      eventName: 'Crop Image',
      trackingParams: {
        'Social Network': getSocialNetworkName({
          apiTypeId: getCurrentAPITypeId(),
        }),
        'Social Page': getAPIPostName({
          accountAPIId,
        }),
        'Network - Social Page': getNetworkAndPageName({
          accountAPIId,
        }),
        'Account API Id': accountAPIId,
        'Width Before': width,
        'Height Before': height,
        'Width After': newWidth,
        'Height After': newHeight,
      },
    });
    onSave(file);
  };

  /**
   * Event handler triggered when crop button is clicked in Image Controls
   * Calls handleCrop prop and changes mode to 'crop'
   */
  const handleCrop = () => {
    onCrop();
    setMode(IMAGE_MODES.CROP);
  };

  /**
   * useEffect to run when edit button is clicked
   * This opens photoeditor once the image has finished uploading to s3
   */
  useEffect(() => {
    if (isEditing && !isUploading) setMode(IMAGE_MODES.EDIT);
  }, [isEditing, isUploading]);

  const apiTypeId = getAPITypeId({ accountAPIId });
  const accountAPI = getAccountAPI({ accountAPIId });
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });

  const isTikTok = apiTypeId === API_TYPE_IDS.TIKTOK;

  const showInstagramLinkStickerPreview =
    postType === POST_TYPES.LINK &&
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    !accountAPI?.useSwipeUpLinks &&
    socialChannel === SOCIAL_CHANNELS.STORY;
  const videoURL = MediaItem.getVideoURL({ mediaItem });

  const transformOptions = getImageTransformOptions({
    apiTypeId,
    postType,
    socialChannel,
  });
  let imageURL =
    articleImages.length > 0
      ? convertEchoboxImageToAmazon(articleImages[currentImage])
      : undefined;

  const cacheBuster = useMemo(() => generateGuid(), []);

  if (imageURL) {
    imageURL = isEbxImage(imageURL)
      ? addCacheBuster({ originalURL: imageURL, cacheBuster })
      : imageURL;

    imageURL = isEbxMedia(imageURL) ? `${imageURL}?${guid}` : imageURL;
  }

  const imageOverlayResult = MediaItem.getImageOverlayResult({ mediaItem });
  imageURL = imageOverlayResult ?? imageURL;
  let canDownload = articleImages.length > 0;
  if (
    postType === POST_TYPES.VIDEO &&
    articleImages.length === 1 &&
    imageURL === undefined
  ) {
    canDownload = false;
  }

  const fullURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const isVideoLink = isVideoURL(fullURL);

  const isInstagramStoryPost =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    (postType === POST_TYPES.LINK || postType === POST_TYPES.PHOTO_STORY) &&
    socialChannel === SOCIAL_CHANNELS.STORY;
  const isInstagramFeedImage =
    (apiTypeId === API_TYPE_IDS.INSTAGRAM && postType === POST_TYPES.STATUS) ||
    (postType === POST_TYPES.LINK && socialChannel === SOCIAL_CHANNELS.FEED);
  const isInstagramReelPost =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.REEL;
  const isFacebookReelPost =
    apiTypeId === API_TYPE_IDS.FACEBOOK &&
    socialChannel === SOCIAL_CHANNELS.REEL;
  const isInstagramGraphStoryPost =
    apiTypeId === API_TYPE_IDS.INSTAGRAM &&
    socialChannel === SOCIAL_CHANNELS.STORY &&
    !isIGCabinetEnabled();

  const showHasCorrectAspectRatioWarning =
    !hasCorrectAspectRatio &&
    articleImages.length > 0 &&
    postType !== POST_TYPES.VIDEO &&
    !isInstagramStoryPost &&
    !isInstagramFeedImage;

  const errorMessage = showHasCorrectAspectRatioWarning ? (
    <>
      The current aspect ratio is not supported by{' '}
      {getSocialNetworkName({ apiTypeId })}.
      <br />
      Please crop your image.
    </>
  ) : null;

  const canCustomiseLinkPosts = MediaItem.getCanCustomiseLinkPosts({
    mediaItem,
  });
  const showImageUneditableMessage =
    mode === IMAGE_MODES.UNEDITABLE && !canCustomiseLinkPosts && !isVideoLink;
  const imageUneditableMessage = showImageUneditableMessage ? (
    <span>
      To edit the image, title and description of your Facebook post, you need
      to link your domain in your Business Manager. Start here:&nbsp;
      <a href="https://ebx.sh/fblink" target="_blank" rel="noopener noreferrer">
        https://ebx.sh/fblink
      </a>
    </span>
  ) : null;

  let instaClassName: string | undefined;
  if (!hasCorrectAspectRatio) {
    if (isInstagramStoryPost || isInstagramGraphStoryPost) {
      instaClassName = 'share_image_instagram_story';
    } else if (isInstagramFeedImage) {
      instaClassName = 'share_image_instagram_feed_image';
    }
  }

  const outerWrapperClass = `${getSocialNetworkName({
    apiTypeId,
    textCase: TEXT_CASES.LOWER,
  })}-${getPostTypeLabel({
    apiTypeId,
    postType,
    socialChannel,
  }).toLowerCase()}${
    isInstagramGraphStoryPost || isInstagramReelPost || isFacebookReelPost
      ? ' instagram-video'
      : ''
  }`;

  const acceptedFileTypes = getAllowedFileTypes({
    apiTypeId,
    postType,
    socialChannel,
    isVideoUploaded: videoURL !== '',
  });

  const isVertical =
    socialChannel === SOCIAL_CHANNELS.REEL ||
    socialChannel === SOCIAL_CHANNELS.STORY;

  const instaProps = API_TYPE_IDS.INSTAGRAM
    ? {
        ...(instaClassName && { className: instaClassName }),
        innerWrapperClass:
          isInstagramStoryPost &&
          articleImages.length > 0 &&
          !showInstagramLinkStickerPreview
            ? 'layout-instagram-story insta-overlay'
            : '',
      }
    : {};

  let height;
  if (
    // TODO: Can we use `isVertical` here to make it more generic?
    isTikTok ||
    isInstagramReelPost ||
    isInstagramGraphStoryPost ||
    isFacebookReelPost
  ) {
    height = '442px';
  } else {
    height = '370px';
  }

  const placeholderPxHeight = isVertical ? 440 : 276;

  const imageProps = {
    src: imageURL,
    onLoad: onImageLoad,
    fileType: IMAGE_FILE_TYPES.IMAGE,
    style: {
      display: 'block', // TODO: remove this, and remove css that hides img.shareImage
    },
    ...instaProps,
  };
  const videoPosterStyle =
    mode === IMAGE_MODES.UPLOADING
      ? {
          display: 'block', // TODO: remove this, and remove css that hides img.shareImage
          height,
        }
      : {};
  const videoProps = {
    src: `${videoURL}${
      socialChannel === SOCIAL_CHANNELS.STORY ? '#t=0.001' : ''
    }`,
    onLoadedMetadata: onVideoLoad,
    fileType: IMAGE_FILE_TYPES.VIDEO,
    showPlayButton: isVideoLink,
    ...((apiTypeId === API_TYPE_IDS.TIKTOK ||
      isInstagramGraphStoryPost ||
      isInstagramReelPost ||
      isFacebookReelPost) && {
      className: 'tiktok-instagram-story-video-el',
    }),
    ...(imageURL && !isInstagramGraphStoryPost && { poster: imageURL }),
    style: videoPosterStyle,
  };
  const childProps =
    postType === POST_TYPES.VIDEO && videoURL !== '' ? videoProps : imageProps;
  const aspectRatios = getImageAspectRatios({
    apiTypeId,
    postType,
    socialChannel,
  });
  const imageComponentProps = {
    acceptedFileTypes,
    uploadProgressPercent,
    mode,
    onModeChange: setMode,
    onChange: handleChange,
    onEditClose,
    ...(postType === POST_TYPES.VIDEO &&
      !videoURL && { onCancel: onUploadCancel }),
    preloadedFiles,
    hasBeenModified,
    isVertical,
    placeholderPxHeight,
  };

  const twitterCardType = MediaItem.getTwitterCardType({ mediaItem });
  const title = MediaItem.getTitle({ mediaItem });
  const hasTwitterCardType = twitterCardType !== null && title !== undefined;
  if (hasTwitterCardType && twitterCardImage) {
    const description = MediaItem.getDescription({ mediaItem });

    return (
      <TwitterCard
        title={title}
        description={description}
        accountAPIId={accountAPIId}
        fullURL={fullURL}
        twitterCardType={twitterCardType}
      >
        <Image
          src={twitterCardImage}
          style={{ display: 'block' }} // TODO: remove this, and remove css that hides img.shareImage
          mediaItem={mediaItem}
          {...imageComponentProps}
        >
          <TwitterImageControls
            twitterCardType={twitterCardType}
            postType={postType}
            onRefresh={onRefresh}
          />
        </Image>
      </TwitterCard>
    );
  }

  return (
    <Image
      errorMessage={errorMessage}
      imageUneditableMessage={imageUneditableMessage}
      outerWrapperClass={outerWrapperClass}
      transformOptions={transformOptions}
      aspectRatioRange={aspectRatios}
      mediaItem={mediaItem}
      {...imageComponentProps}
      {...childProps}
    >
      {articleImages.length > 0 && showInstagramLinkStickerPreview ? (
        <InstagramLinkStickerPreview
          guid={guid}
          url={getRootDomain(fullURL)}
          mediaItem={mediaItem}
          onPendingStickerConfig={onPendingStickerConfig}
          pendingStickerConfig={pendingStickerConfig}
        />
      ) : null}
      <SocialImageControls
        apiTypeId={apiTypeId}
        articleImages={articleImages}
        canDownload={canDownload}
        currentImage={currentImage}
        imageURL={imageURL}
        isDownloading={isDownloading}
        mode={mode}
        mediaItem={mediaItem}
        onCrop={handleCrop}
        onDeleteImage={onDeleteImage}
        onDeleteVideo={onDeleteVideo}
        onDownload={onDownload}
        onEdit={onEdit}
        onNextImage={onNextImage}
        onPrevImage={onPrevImage}
        onRefresh={onRefresh}
      />
    </Image>
  );
};

export default SocialImage;
