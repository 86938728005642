import { Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { getReconnectSocialPageLink } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { getNetworkAndPageName } from 'helpers/tracking';
import type { SocialAPI } from 'types';

export default function useDisconnectToastProps({
  disconnectedAccountAPIs,
  closeToast,
}: {
  disconnectedAccountAPIs: Array<SocialAPI>;
  closeToast: () => void;
}) {
  const navigate = useNavigate();

  if (disconnectedAccountAPIs.length === 1) {
    const disconnectedAccountAPI = disconnectedAccountAPIs[0];
    const socialNetworkName = getSocialNetworkName({
      apiTypeId: disconnectedAccountAPI.apiTypeId,
    });

    const handleReconnect: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation(); // Stops the onClick on the Toast container triggering
      tracker.track({
        eventName: 'Reconnect Page',
        trackingParams: {
          'Social Network': getSocialNetworkName({
            apiTypeId: disconnectedAccountAPI.apiTypeId,
          }),
          'Account API Id': disconnectedAccountAPI.accountAPIId,
          'Network - Social Page': getNetworkAndPageName({
            accountAPIId: disconnectedAccountAPI.accountAPIId,
          }),
          Location: 'Toast',
        },
      });
      const reconnectLink = getReconnectSocialPageLink({
        accountAPI: disconnectedAccountAPI,
      });
      navigate(reconnectLink);
      closeToast();
    };

    return {
      title: `${disconnectedAccountAPI.apiPostName} ${socialNetworkName} page was disconnected`,
      description: `${socialNetworkName} returned the following error: ${disconnectedAccountAPI.disconnectReason}`,
      buttons: (
        <Button variant="secondary" onClick={handleReconnect}>
          Reconnect
        </Button>
      ),
    };
  }

  return {
    description: 'Click to review',
    title: `${disconnectedAccountAPIs.length} pages disconnected`,
  };
}
