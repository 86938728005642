import {
  Alert,
  Button,
  Flex,
  FormControl,
  HStack,
  Image,
  Input,
  Link,
  LinkProps,
  Modal,
  Radio,
  RadioGroup,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { API_TYPE_IDS } from 'common/constants';
import { getErrorMessage } from 'common/errorHandling';
import * as logger from 'common/logger';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import addNewAPI from 'process/addNewAPI';

import AddInstagramMFAInput from './AddInstagramMFAInput';

const TwoFactorAuthRadioValues = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const InstagramSettingsLink = (props: LinkProps) => (
  <Link
    href="https://www.instagram.com/accounts/two_factor_authentication/"
    target="_blank"
    rel="noopener noreferrer"
    lineHeight={4}
    {...props}
  />
);

interface AddInstagramLoginProps {
  onBack: () => void;
  onClose: () => void;
}

const AddInstagramLogin = ({ onBack, onClose }: AddInstagramLoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorAuthValue, setTwoFactorAuthValue] = useState<
    string | undefined
  >(undefined);
  const [mfa1, setMFA1] = useState('');
  const [mfa2, setMFA2] = useState('');
  const [mfa3, setMFA3] = useState('');
  const [isConnecting, setIsConnecting] = useState(false);

  const [apiError, setApiError] = useState('');

  const { global } = useGlobalInfo();

  const is2FAEnabled = twoFactorAuthValue === TwoFactorAuthRadioValues.ENABLED;
  const isConnectDisabled =
    username === '' ||
    password === '' ||
    twoFactorAuthValue === undefined ||
    (is2FAEnabled && (mfa1.length < 8 || mfa2.length < 8 || mfa3.length < 8)) ||
    isConnecting;

  const handleConnect = async () => {
    const args = {
      url: `https://www.instagram.com/${username}`,
      username,
      password,
      mfa: is2FAEnabled,
      mfa1,
      mfa2,
      mfa3,
    };
    setIsConnecting(true);

    setApiError('');

    logger.info(
      `ConnectInstagramModal.handleAddAPI - type ${API_TYPE_IDS.INSTAGRAM}`,
    );
    const propertyId = getCurrentPropertyId({
      globalInfo: global.getGlobalInfo(),
    });

    sessionStorage.setItem(
      'connectingAPITypeId',
      API_TYPE_IDS.INSTAGRAM.toString(),
    );
    try {
      await addNewAPI({ propertyId, apiTypeId: API_TYPE_IDS.INSTAGRAM, args });
      setIsConnecting(false);
      onClose();
    } catch (error) {
      setApiError(getErrorMessage(error));
      setIsConnecting(false);
    }
  };

  return (
    <>
      <Modal.Body py={8} overflowY="auto" maxHeight="70vh">
        <Flex flexDir="column" alignItems="center" gap={8}>
          {apiError && (
            <Alert variant="error">
              <Alert.Title>Something went wrong</Alert.Title>
              <Alert.Description>{apiError}</Alert.Description>
            </Alert>
          )}
          <Image
            src="/img/icons/Instagram_Glyph_Gradient_RGB 1.svg"
            alt=""
            boxSize={20}
          />
          <FormControl>
            <FormControl.FormLabel htmlFor="username">
              Username
            </FormControl.FormLabel>
            <Input
              id="username"
              type="text"
              autoComplete="off"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              isDisabled={isConnecting}
              data-cy-input="username"
            />
          </FormControl>
          <FormControl>
            <FormControl.FormLabel htmlFor="password">
              Password
            </FormControl.FormLabel>
            <Input
              id="password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isDisabled={isConnecting}
              data-cy-input="password"
            />
          </FormControl>
          <FormControl as="fieldset">
            <FormControl.FormLabel as="legend">
              Does your Instagram account have two-factor authentication (2FA)
              enabled?
            </FormControl.FormLabel>
            <RadioGroup
              value={twoFactorAuthValue}
              onChange={(newValue) => setTwoFactorAuthValue(newValue)}
              isDisabled={isConnecting}
            >
              <HStack spacing={8}>
                <Radio
                  value={TwoFactorAuthRadioValues.ENABLED}
                  data-cy-input="mfa_yes"
                >
                  Yes
                </Radio>
                <Radio
                  value={TwoFactorAuthRadioValues.DISABLED}
                  data-cy-input="mfa_no"
                >
                  No
                </Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          {is2FAEnabled && (
            <>
              <Alert variant="info">
                <Alert.Title>Two-factor authentication (2FA)</Alert.Title>
                <Alert.Description>
                  Retrieve 3 back-up security codes from your Instagram account.
                  You can find the security codes in your{' '}
                  <InstagramSettingsLink fontWeight="bold">
                    Instagram settings page
                  </InstagramSettingsLink>
                </Alert.Description>
              </Alert>
              <FormControl>
                <Flex alignItems="baseline" justifyContent="space-between">
                  <FormControl.FormLabel htmlFor="recovery-code-1">
                    Recovery codes
                  </FormControl.FormLabel>
                  <InstagramSettingsLink
                    fontWeight="medium"
                    href="https://help.instagram.com/1006568999411025"
                  >
                    How to find recovery codes
                  </InstagramSettingsLink>
                </Flex>
                <VStack spacing={4}>
                  <AddInstagramMFAInput
                    id="recovery-code-1"
                    aria-label="First recovery code"
                    onChange={setMFA1}
                    isDisabled={isConnecting}
                  />
                  <AddInstagramMFAInput
                    id="recovery-code-2"
                    aria-label="Second recovery code"
                    onChange={setMFA2}
                    isDisabled={isConnecting}
                  />
                  <AddInstagramMFAInput
                    id="recovery-code-3"
                    aria-label="Third recovery code"
                    onChange={setMFA3}
                    isDisabled={isConnecting}
                  />
                </VStack>
              </FormControl>
            </>
          )}
        </Flex>
      </Modal.Body>
      <Modal.Footer px={5}>
        <Button variant="secondary" onClick={onBack} isDisabled={isConnecting}>
          Back
        </Button>
        <Button
          onClick={handleConnect}
          isDisabled={isConnectDisabled}
          data-cy-action="connect"
        >
          Connect Instagram
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddInstagramLogin;
