import {
  Center,
  Heading,
  Spinner,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';

import { getCurrentProperty } from 'common/accountAPIs';
import SettingsUserManagement from 'components/settings/team/UserManagement';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { useOnboardingSetupContext } from 'context/OnboardingContext';
import usePropertyUsers from 'hooks/usePropertyUsers';

const UserManagement = () => {
  const { setHaveChangesBeenMade, setNextButtonDisabled } =
    useOnboardingSetupContext();
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const { isLoading, propertyUsers, onUsersChange } = usePropertyUsers(
    getCurrentProperty({
      globalInfo,
    }).propertyUsers,
  );

  // Shows the "Save and exit" button in the header if users have been added
  useEffect(() => {
    setHaveChangesBeenMade(propertyUsers?.length > 1);

    return () => {
      setHaveChangesBeenMade(false);
    };
  }, [propertyUsers?.length, setHaveChangesBeenMade]);

  // Enables the next button in the header
  useEffect(() => {
    setNextButtonDisabled(false);
  }, [setNextButtonDisabled]);

  if (isLoading) {
    return (
      <Center mt={5} width="full">
        <Spinner size="lg" />
      </Center>
    );
  }

  return (
    <>
      <Heading>Add team members</Heading>
      <Text color="gray.600" mt={2} mb={6} size="sm" maxWidth="776px">
        During this setup, you will be asked to install the Web Tag and connect
        your social media pages to Echobox. If you don&apos;t have access to
        these, please invite team members that do.
      </Text>
      <SettingsUserManagement
        users={propertyUsers}
        onUsersChange={onUsersChange}
        showRoleSettings={false}
      />
    </>
  );
};

export default UserManagement;
