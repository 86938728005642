/* eslint react/no-danger:"off" */

import createDOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { REACT_PREVENT_RENDER } from 'common/constants';
import { arePropsEqual } from 'common/utility';
import withStatusPage from 'context/withStatusPage';
import InfoIcon from 'svg/Info';

const StatusPageNotification = (props) => {
  const isHidingStatusPage = () => sessionStorage.getItem('hideStatusPage');
  const DOMPurify = createDOMPurify(window);

  if (props.statusPage.notification === '' || isHidingStatusPage()) {
    return REACT_PREVENT_RENDER;
  }
  const className = props.onLoginPage ? 'inline-notification-signin' : '';

  return (
    <div className="inline-notifications-wrapper">
      <div
        className={`inline-notification inline-notification-info ${className}`}
      >
        <div className="inline-notification-icon">
          <InfoIcon />
        </div>
        <div className="inline-notification-content">
          <div className="inline-notification-text">
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.statusPage.notification),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StatusPageNotification.propTypes = {
  onLoginPage: PropTypes.bool,
  statusPage: PropTypes.object,
};

StatusPageNotification.defaultProps = {
  onLoginPage: false,
  statusPage: {
    notification: '',
  },
};

export default withStatusPage(memo(StatusPageNotification, arePropsEqual));
