import { Alert, Button } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useNavigate } from 'react-router-dom';

import { FRONTEND_PAGES, FRONTEND_PATHS } from 'common/constants';

export const WebsiteTagRequiredAlert = () => {
  const navigate = useNavigate();
  return (
    <Alert
      maxW="776px"
      variant="warning"
      buttons={
        <>
          <Button
            onClick={() =>
              navigate(FRONTEND_PATHS[FRONTEND_PAGES.SETTINGS_PROPERTY][0], {
                state: { from: 'Performance Goal Page' },
              })
            }
          >
            Install Web Tag
          </Button>
          <Button
            variant="tertiary"
            onClick={() =>
              window.open(
                'https://www.echobox.com/resources/blog/introducing-the-echobox-web-tag/',
                '_blank',
              )
            }
          >
            Learn more
          </Button>
        </>
      }
    >
      <Alert.Title>Echobox Web Tag is required</Alert.Title>
      <Alert.Description>
        For Echobox to maximize your chosen performance goal, a small snippet
        must be installed on your website
      </Alert.Description>
    </Alert>
  );
};
