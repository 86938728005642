import { useState } from 'react';

import isEqual from 'fast-deep-equal';

import { Flex, useToast } from '@ebx-ui/ebx-ui-component-library-sdk';

import * as settings from 'common/settings';

import Bold from 'components/misc/Bold';
import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import AutomationSettingsHeader from 'components/settings/automation/common/AutomationSettingsHeader';
import KeywordsInput from 'components/settings/automation/common/KeywordsInput';

import { useGlobalInfo } from 'context/GlobalInfoContext';
import {
  resetSettingsState,
  setSettingsChanged,
  updateOnSave,
  useSettingsContext,
} from 'context/SettingsContext';

import { AutofeedSettings } from 'types';

export interface AdvancedSettings {
  excludeImagesContaining: string[];
  messageTextToRemove: string[];
}

function Advanced() {
  const { dispatch } = useSettingsContext();
  const toast = useToast();
  const { global } = useGlobalInfo();
  const { accountAPIId } = useAutomationSettingsContext();

  const getAdvancedSettings = () => {
    const excludeImagesContaining = [
      ...(settings.getAutofeedSetting({
        key: 'excludeImagesContaining',
        accountAPIId,
      }) ?? []),
    ];

    const messageTextToRemove = [
      ...(settings.getAutofeedSetting({
        key: 'messageTextToRemove',
        accountAPIId,
      }) ?? []),
    ];

    return {
      excludeImagesContaining,
      messageTextToRemove,
    };
  };

  const handleSaveAdvancedSettings = async (
    advancedSettings: AdvancedSettings,
  ) => {
    const existingSettings: Partial<AutofeedSettings> =
      settings.getAutofeedSettings({ accountAPIId }) ?? {};

    const updatedSettings = existingSettings;
    existingSettings.excludeImagesContaining =
      advancedSettings.excludeImagesContaining;
    existingSettings.messageTextToRemove = advancedSettings.messageTextToRemove;

    settings.saveAutomationSettings(
      accountAPIId,
      updatedSettings,
      dispatch,
      toast,
      global,
    );
  };

  const advancedSettings = getAdvancedSettings();
  const [currentAdvancedSettings, setCurrentAdvancedSettings] =
    useState(advancedSettings);

  const handleAdvancedSettingsChange = (newSettings: AdvancedSettings) => {
    setCurrentAdvancedSettings(newSettings);

    if (isEqual(newSettings, advancedSettings)) {
      resetSettingsState(dispatch);
    } else {
      setSettingsChanged(dispatch);
    }
    updateOnSave(dispatch, () => handleSaveAdvancedSettings(newSettings));
  };

  return (
    <>
      <AutomationSettingsHeader
        title="Advanced settings"
        description="Further customize the automation with advanced settings"
      />
      <Flex flexDir="column" gap={6} mb={6}>
        <KeywordsInput
          label={
            <span>
              The following <Bold>keywords</Bold> will be <Bold>removed</Bold>{' '}
              from your share messages
            </span>
          }
          currentKeywordSettings={currentAdvancedSettings}
          handleKeywordSettingsChange={handleAdvancedSettingsChange}
          keywordType="excludeImagesContaining"
        />
        <KeywordsInput
          label={
            <span>
              Articles with these keywords in their{' '}
              <Bold>image&apos;s URL</Bold> will <Bold>not be shared</Bold>
            </span>
          }
          currentKeywordSettings={currentAdvancedSettings}
          handleKeywordSettingsChange={handleAdvancedSettingsChange}
          keywordType="messageTextToRemove"
        />
      </Flex>
    </>
  );
}

export default Advanced;
