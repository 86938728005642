/**
 * GET /media/video/thumbnail - Get video thumbnail
 *
 * @param {string} videoURL - video url
 * @return {string} - thumbnail as base64
 */

import axios, { AxiosRequestConfig } from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { lyingParse } from 'common/zod';
import { z } from 'zod';

const GET_THUMBNAIL = 'getThumbnail';

const ResponseSchema = z
  .object({ data: z.array(z.number()) })
  .describe('GetVideoThumbnailResponseDataSchema');

export default async function getVideoThumbnail({
  videoURL,
}: {
  videoURL: string;
}) {
  const guid = metrics.start(GET_THUMBNAIL);

  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL(
      'v1',
    )}/media/video/thumbnail?videoURL=${videoURL}`,
    method: 'GET',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders(),
  };

  try {
    const response = await axios(config);

    const responseData = lyingParse(ResponseSchema, response.data);

    // convert array buffer to base64
    const typedArray = new Uint8Array(responseData?.data);
    const base64String = btoa(
      typedArray.reduce((data, byte) => data + String.fromCharCode(byte), ''),
    );
    metrics.end(GET_THUMBNAIL, guid);
    logger.debug(GET_THUMBNAIL, {
      videoURL,
    });

    return `data:image/jpeg;base64,${base64String}`;
  } catch (error) {
    metrics.fail(GET_THUMBNAIL, guid);

    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: `api/${GET_THUMBNAIL}`,
      args: { videoURL },
    });
    logger.error({
      event: 'Failed to get thumbnail for video',
      error: apiError,
      properties: { videoURL },
    });
    return null;
  }
}
