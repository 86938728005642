import {
  Button,
  FormControl,
  HStack,
  Input,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';
import { z } from 'zod';

import { determineError, getErrorMessage } from 'common/errorHandling';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import { lyingParse } from 'common/zod';

const SearchPropertyIdSchema = z
  .object({ propertyId: z.string() })
  .describe('SearchPropertyIdSchema');

const SearchPropertyNameSchema = z
  .object({ propertyName: z.string() })
  .describe('SearchPropertyNameSchema');

interface PropertySearchProps {
  onSearchById: (propertyId: string) => Promise<void>;
  onSearchByName: (nameToSearch: string) => Promise<void>;
}

const PropertySearch = ({
  onSearchById,
  onSearchByName,
}: PropertySearchProps) => {
  const [isIdLoading, setIsIdLoading] = React.useState(false);
  const [idErrorMessage, setIdErrorMessage] = React.useState<string | null>(
    null,
  );
  const [isNameLoading, setIsNameLoading] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState<string | null>(
    null,
  );

  const handleSubmitId: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    clearErrorMessages();

    if (!(event.target instanceof HTMLFormElement)) {
      return;
    }

    const formData = Object.fromEntries(new FormData(event.target));
    const { propertyId } = lyingParse(SearchPropertyIdSchema, formData);

    if (propertyId.length) {
      setIsIdLoading(true);

      try {
        await onSearchById(propertyId);
      } catch (error) {
        setIdErrorMessage(FRONTEND_ERROR_MESSAGES['property-tools-id-search']);
      }

      setIsIdLoading(false);
    }
  };

  const handleSubmitName: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    setIsNameLoading(true);
    clearErrorMessages();

    if (!(event.target instanceof HTMLFormElement)) {
      return;
    }

    const formData = Object.fromEntries(new FormData(event.target));
    const { propertyName: nameToSearch } = lyingParse(
      SearchPropertyNameSchema,
      formData,
    );

    if (nameToSearch.trim().length) {
      try {
        await onSearchByName(nameToSearch);
      } catch (error) {
        setNameErrorMessage(getErrorMessage(determineError(error)));
      }
    }

    setIsNameLoading(false);
  };

  const clearErrorMessages = () => {
    setIdErrorMessage(null);
    setNameErrorMessage(null);
  };

  return (
    <>
      <form onSubmit={handleSubmitId}>
        <FormControl isInvalid={idErrorMessage !== null}>
          <FormControl.FormLabel htmlFor="propertyToolsPropertyId">
            Search for a Property by Id
          </FormControl.FormLabel>
          <HStack>
            <Input
              type="text"
              placeholder="Enter a property Id..."
              id="propertyToolsPropertyId"
              name="propertyId"
              autoFocus
            />
            <Button
              type="submit"
              variant="secondary"
              loadingText="Submitting"
              flexShrink={0}
              isLoading={isIdLoading}
              isDisabled={isIdLoading || isNameLoading}
            >
              Submit
            </Button>
          </HStack>
          {idErrorMessage && (
            <FormControl.FormErrorMessage>
              {idErrorMessage}
            </FormControl.FormErrorMessage>
          )}
        </FormControl>
      </form>
      <form onSubmit={handleSubmitName}>
        <FormControl isInvalid={nameErrorMessage !== null}>
          <FormControl.FormLabel htmlFor="propertyToolsPropertyId">
            Search for a Property by Name
          </FormControl.FormLabel>
          <HStack>
            <Input
              type="text"
              placeholder="Enter a property name..."
              name="propertyName"
              id="propertyToolsPropertyName"
            />
            <Button
              type="submit"
              variant="secondary"
              loadingText="Submitting"
              flexShrink={0}
              isLoading={isNameLoading}
              isDisabled={isIdLoading || isNameLoading}
            >
              Submit
            </Button>
          </HStack>
        </FormControl>
        {nameErrorMessage && (
          <FormControl.FormErrorMessage>
            {nameErrorMessage}
          </FormControl.FormErrorMessage>
        )}
      </form>
    </>
  );
};

export default PropertySearch;
