// Commands
export const COMMAND_FLASH_MESSAGES_ADD_MESSAGE =
  'COMMAND.FLASH_MESSAGES_ADD_MESSAGE';
export const COMMAND_FLASH_MESSAGES_DELETE_MESSAGE =
  'COMMAND.FLASH_MESSAGES_DELETE_MESSAGE';
export const COMMAND_FLASH_MESSAGES_DELETE_MESSAGE_TYPE =
  'COMMAND.FLASH_MESSAGES_DELETE_MESSAGE_TYPE';
export const COMMAND_FLASH_MESSAGES_DELETE_MESSAGES =
  'COMMAND.FLASH_MESSAGES_DELETE_MESSAGES';
export const COMMAND_SET_SAVING_STATE = 'COMMAND.SET_SAVING_STATE';

// Messages
export const MESSAGE_MEDIA_ITEM_ERROR = 'MESSAGE.MEDIA_ITEM_ERROR';
export const MESSAGE_SAVING_STATE_SET = 'MESSAGE.SAVING_STATE_SET';
export const MESSAGE_SAVE_COMPOSE_BOX = 'MESSAGE.SAVE_COMPOSE_BOX';

// Requests
export const REQUEST_ADD_COMPOSE_BOX_ITEM = 'REQUEST.ADD_COMPOSE_BOX_ITEM';
export const REQUEST_DUPLICATE_COMPOSE_BOX_ITEM =
  'REQUEST.DUPLICATE_COMPOSE_BOX_ITEM';
export const REQUEST_API_INFO = 'REQUEST.API_INFO';
export const REQUEST_DELETE_COMPOSE_BOX_ITEM =
  'REQUEST.DELETE_COMPOSE_BOX_ITEM';
export const REQUEST_FIND_COMPOSE_BOX_ITEM = 'REQUEST.FIND_COMPOSE_BOX_ITEM';
export const REQUEST_MEDIA_ITEM_SAVE = 'REQUEST.MEDIA_ITEM.SAVE';
export const REQUEST_UPDATE_COMPOSE_BOX_ITEM =
  'REQUEST.UPDATE_COMPOSE_BOX_ITEM';

// Responses
export const RESPONSE_ADD_COMPOSE_BOX_ITEM = 'RESPONSE.ADD_COMPOSE_BOX_ITEM';
export const RESPONSE_DUPLICATE_COMPOSE_BOX_ITEM =
  'RESPONSE.DUPLICATE_COMPOSE_BOX_ITEM';
export const RESPONSE_API_INFO = 'RESPONSE.API_INFO';
export const RESPONSE_FIND_COMPOSE_BOX_ITEM = 'RESPONSE.FIND_COMPOSE_BOX_ITEM';
export const RESPONSE_UPDATE_COMPOSE_BOX_ITEM =
  'RESPONSE.UPDATE_COMPOSE_BOX_ITEM';

// Offline/Online detection
export const OFFLINE_DETECTED = 'OFFLINE_DETECTED';
export const ONLINE_DETECTED = 'ONLINE_DETECTED';

// Notification system
export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
