import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseCoreAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import type { PropertyState } from 'types';

export default async function putProperties({
  propertyId,
  propertyState,
  propertyTimezone,
  language,
  propertyCRMIdentifier,
  propertyName,
}: {
  propertyId: string;
  propertyState?: PropertyState;
  propertyTimezone?: string;
  language?: string;
  propertyCRMIdentifier?: string;
  propertyName?: string;
}) {
  const guid = metrics.start('putProperties');
  const clientAuthHeader = getClientServiceRequestHeaders({
    'Content-Type': 'application/json',
  });
  const config: AxiosRequestConfig = {
    url: `${constructBaseCoreAPIURL()}/properties/urn:property:${propertyId}`,
    method: 'PUT',
    timeout: API_TIMEOUTS.S,
    headers: clientAuthHeader,
    data: JSON.stringify({
      propertyState,
      propertyTimezone,
      language,
      propertyCRMIdentifier,
      propertyName,
    }),
  };

  try {
    const response = await axios(config);
    metrics.end('putProperties', guid);
    logger.debug('putProperties', {
      propertyId,
      propertyState,
      propertyTimezone,
      language,
      propertyCRMIdentifier,
      propertyName,
    });
    return response.data;
  } catch (error) {
    metrics.fail('putProperties', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/putProperties',
      args: {
        propertyId,
        propertyState,
        propertyTimezone,
        language,
        propertyCRMIdentifier,
        propertyName,
      },
    });
    throw apiError;
  }
}
