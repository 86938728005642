/**
 * login - log into Echobox and retrieve user / property info
 *
 * @param {string} username
 * @param {string} password
 * @return {object} - user and property information
 *
 * Example response
 * {
 *   user: {object}
 *   properties: {array of objects}
 *   current: {
 *     propertyId: {integer},
 *     accountAPIId: {integer}
 *   }
 * }
 */

import authSignIn from 'api/aws/authSignIn';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import { isNull } from 'common/utility';
import { mandatory } from 'common/validation';

import postLoginDataSetup from './postLoginDataSetup';

export default async function login({
  username = mandatory('username'),
  password = mandatory('password'),
  setGlobalInfo = mandatory('setGlobalInfo'),
} = {}) {
  try {
    // Login
    const loginResponse = await authSignIn({
      username: username.trim(),
      password,
    });
    return postLoginDataSetup({
      cognitoResponse: loginResponse,
      setGlobalInfo,
    });
  } catch (error) {
    console.log(error);
    const handledError = determineError(error);
    if (isNull(getErrorStatus)) {
      logger.error({
        event: 'Login Error',
        properties: {
          location: 'process/login',
          arguments: {
            username,
            password,
          },
        },
        handledError,
      });
    }
    throw handledError;
  }
}
