import getPropertiesConnectRequestNew from 'api/getPropertiesConnectRequestNew';
import { constructBaseSocialAPIURL } from 'api/settings';
import { API_PROPERTIES } from 'common/constants';
import { determineError, getErrorStatus } from 'common/errorHandling';
import * as logger from 'common/logger';
import { convertToPropertyURN } from 'common/urn';
import { location } from 'common/window';
import type { APITypeId, InstagramParams } from 'types';

interface AddNewAPIArgs {
  propertyId: number;
  apiTypeId: APITypeId;
  instagramParams?: InstagramParams;
  args?: {
    url?: string;
    username?: string;
    password?: string;
    mfa?: boolean;
    mfa1?: string;
    mfa2?: string;
    mfa3?: string;
  };
}

export default function addNewAPI({
  propertyId,
  apiTypeId,
  args,
}: {
  propertyId: any;
  apiTypeId: APITypeId;
  args?: {
    url: string;
    username: string;
    password: string;
    mfa: boolean;
    mfa1: string;
    mfa2: string;
    mfa3: string;
  };
}): Promise<AddNewAPIArgs> {
  const propertyURN = convertToPropertyURN(propertyId);
  const socialAPIType = API_PROPERTIES[apiTypeId]?.apiType;
  let instagramParams: InstagramParams | undefined;

  return new Promise((resolve, reject) => {
    if (
      args !== undefined &&
      args.url !== undefined &&
      args.username !== undefined &&
      args.password !== undefined
    ) {
      instagramParams = {
        connectType: 'LITE',
        pageURL: args.url,
        username: args.username,
        password: args.password,
      };
    }
    if (args?.mfa && instagramParams !== null && instagramParams) {
      instagramParams = {
        ...instagramParams,
        mfa1: args.mfa1,
        mfa2: args.mfa2,
        mfa3: args.mfa3,
      };
    }
    getPropertiesConnectRequestNew({
      propertyURN,
      socialAPIType,
      uiCallbackURL: `${location.protocol}//${location.host}/settings/pages`,
      apiCallbackURL: constructBaseSocialAPIURL('v3'),
      instagramParams,
    })
      .then((connectRequestResponse) => {
        // Redirect step
        location.href = connectRequestResponse.location;
      })
      .catch((error) => {
        const handledError = determineError(error);
        if (getErrorStatus(error)) {
          logger.error({
            event: 'Add New API Error',
            properties: {
              location: 'process/addNewAPI',
              arguments: {
                propertyURN,
                socialAPIType,
              },
            },
            error,
          });
        }
        reject(handledError);
      });
  });
}
