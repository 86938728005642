import { socialNetworkAPIIds } from 'common/accountAPIs';
import { isShowingAPIType } from 'common/common';
import SidebarNetwork from 'components/sidebar/SidebarNetwork';
import type { FixTypeLater } from 'types';

interface SidebarNetworksProps {
  apisByNetwork: Record<number, Array<FixTypeLater>>;
}

const SidebarNetworks = ({ apisByNetwork }: SidebarNetworksProps) => {
  const socialNetworks = socialNetworkAPIIds.filter((apiTypeId) =>
    isShowingAPIType(apiTypeId),
  );

  return socialNetworks.map((apiTypeId) => (
    <SidebarNetwork
      key={apiTypeId}
      apiTypeId={apiTypeId}
      accountAPIs={apisByNetwork[apiTypeId]}
    />
  ));
};

export default SidebarNetworks;
