import {
  Button,
  Flex,
  FormControl,
  Textarea,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { Frame } from 'types';

const countWords = (text: string) => {
  return text.split(/\s+/).filter((word) => word).length;
};

const LABEL_MAP: Record<Frame, string> = {
  frame1: 'Frame 1',
  frame2: 'Frame 2',
  frame3: 'Frame 3',
};

interface TextEditorProps {
  frame: Frame;
  initialValue: string | undefined;
  onCancel: () => void;
  onSave: (text: string) => void;
}
const TextEditor = ({
  frame,
  initialValue,
  onCancel,
  onSave,
}: TextEditorProps) => {
  const [text, setText] = React.useState(initialValue);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!text) return;
    onSave(text);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex flexDir="column" gap={3}>
        <FormControl>
          <FormControl.FormLabel>{LABEL_MAP[frame]} text</FormControl.FormLabel>
          <Textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            // 👇 These styling props could be extracted into a component if the Textarea component is used in multiple places
            size="sm"
            p={3}
            border="none"
            boxShadow="border"
            resize="none"
            minH={16}
            h={16}
            bg="surface.primary"
            color="text.primary"
            lineHeight={5}
            borderRadius="md"
            _focus={{
              boxShadow: 'focusWithBorder',
            }}
          />
        </FormControl>
        <Flex gap={3}>
          <Button
            type="submit"
            isDisabled={!text || text === initialValue || countWords(text) > 30}
          >
            Save
          </Button>
          <Button type="button" onClick={onCancel} variant="secondary">
            Cancel
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default TextEditor;
