import { SOCIAL_CHANNELS } from 'common/constants';
import { isWithinAspectRatio } from 'common/image';
import { SocialChannel } from 'types';

export function validateReelsThumbnailImage({
  socialChannel,
  imageDimensions,
}: {
  socialChannel: SocialChannel;
  imageDimensions: { width: number; height: number };
}) {
  if (socialChannel === SOCIAL_CHANNELS.REEL) {
    const isValid = isWithinAspectRatio({
      imageDimensions,
      aspectRatioLimits: {
        min: 0.5625,
        max: 0.5625,
      },
    });
    if (!isValid) {
      return 'Thumbnail must have a 9:16 aspect ratio';
    }
  }
  return null;
}
