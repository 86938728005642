/* eslint no-param-reassign:"off" */

import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import $ from 'jquery';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import Immutable from 'seamless-immutable';

import {
  getCurrentPropertyId,
  getUserPropertyPermission,
} from 'common/accountAPIs';
import { canCreateProperty } from 'common/common';
import {
  ACCOUNT_SETTING_TYPES,
  PERMISSION_TYPES,
  REACT_PREVENT_RENDER,
  SOCIAL_PAGE_SETTING_TYPES_PROPERTIES,
} from 'common/constants';
import * as logger from 'common/logger';
import { withLocation } from 'common/routing';
import { loadSettingsForProperty } from 'common/settings';
import * as tracker from 'common/tracker';
import { isDefined, isEmptyOrNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';
import BaseComponent from 'components/BaseComponent';
import Prompt from 'components/misc/Prompt';
import DomainSwitching from 'components/settings/property/DomainSwitching';
import LinkShortening from 'components/settings/property/LinkShortening';
import TimeFormat from 'components/settings/property/TimeFormat';
import WebsiteTag from 'components/settings/property/WebsiteTag';
import withGlobalInfo from 'context/withGlobalInfo';
import { scrollToTop } from 'helpers/window';
import PerformanceGoalLink from './property/PerformanceGoalLink';

class Property extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      data: Immutable({
        hasChanged: {
          timeFormat: false,
          linkShortening: false,
          rssFeeds: false,
        },
        isLoadingSettings: true,
        domainSwitchingSettings: {},
        allowContentFromAllDomainsSettings: {},
      }),
    };
    this._bind(
      'areUnsavedChangesPresent',
      'handleHasSettingChanged',
      'handleWindowUnload',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    // Detect attempts to leave the page so we can check for unsaved changes
    window.addEventListener('beforeunload', this.handleWindowUnload);

    // Scroll to top if necessary
    scrollToTop();

    tracker.track({ eventName: 'Settings Page' });

    // Automatically expand panel if required
    const query = queryString.parse(this.props.location.search);
    if (isDefined(query.expand)) {
      $(`#collapse${query.expand}`).collapse('show');
    }

    this.loadSettings();
  }

  componentWillUnmount() {
    logger.info('Property:componentWillUnmount');
    // Remove event handlers
    window.removeEventListener('beforeunload', this.handleWindowUnload);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('Property', { nextProps, nextState });
  }

  /**
   * Event handlers
   */

  handleHasSettingChanged({
    key = mandatory('key'),
    hasSettingChanged = mandatory('hasSettingChanged'),
  }) {
    logger.info(
      `Property:handleHasSettingChanged - ${key} ${hasSettingChanged}`,
    );

    this.setState((prevState) => ({
      data: prevState.data.setIn(['hasChanged', key], hasSettingChanged),
    }));
  }

  handleWindowUnload(event) {
    logger.info('Property:handleWindowUnload');

    // Prevent the user from leaving the page if there are unsaved changes
    if (this.areUnsavedChangesPresent()) {
      event.preventDefault();
      event.returnValue = ''; // Message controlled by browser so doesn't matter what we put here
    } else {
      this.componentWillUnmount();
    }
  }

  /**
   * Helper methods
   */

  loadSettings() {
    this.setState(
      (prevState) => ({
        data: prevState.data.set('isLoadingSettings', true),
      }),
      () => {
        loadSettingsForProperty({
          settingTypeIds: SOCIAL_PAGE_SETTING_TYPES_PROPERTIES,
        }).then((response) => {
          this.setState((prevState) => ({
            data: prevState.data
              .set('isLoadingSettings', false)
              .set(
                'domainSwitchingSettings',
                response[ACCOUNT_SETTING_TYPES.DOMAIN_SWITCHING],
              )
              .set(
                'allowContentFromAllDomainsSettings',
                response[ACCOUNT_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS],
              ),
          }));
        });
      },
    );
  }

  areUnsavedChangesPresent() {
    return Object.keys(this.state.data.hasChanged).some(
      (setting) => this.state.data.hasChanged[setting],
    );
  }

  /**
   * Render method
   */

  render() {
    const globalInfo = this.props.global.getGlobalInfo();
    if (isEmptyOrNullOrUndefined(globalInfo)) {
      return REACT_PREVENT_RENDER;
    }

    const permissionTypeId = getUserPropertyPermission({
      userId: globalInfo.user.userId,
      propertyId: getCurrentPropertyId({
        globalInfo,
      }),
      globalInfo,
    });

    const propertyId = getCurrentPropertyId({ globalInfo });
    const propertyName = globalInfo.properties[propertyId].propertyName;

    if (this.state.data.isLoadingSettings) {
      return (
        <div className="text-center my-3 py-3">
          <Spinner size="lg" />
          <div className="my-3 text-400">Fetching your settings...</div>
        </div>
      );
    }

    return (
      <>
        <Prompt
          when={this.areUnsavedChangesPresent()}
          message="Do you want to leave this page? Changes you made may not be saved."
        />
        <div
          className="box"
          id="accordionPropertySettings"
          data-cy-id="propertySettings"
        >
          <div className="d-flex box-header">
            <h3 className="mr-auto align-self-center mb-0">
              Property ({propertyName})
            </h3>
            {permissionTypeId === PERMISSION_TYPES.ADMIN &&
              canCreateProperty() && (
                <Link
                  className="btn btn-light align-self-center ml-2"
                  to="/addproperty?origin=settings/property"
                >
                  Create New Property
                </Link>
              )}
          </div>
          <DomainSwitching
            domainSwitchingSettings={this.state.data.domainSwitchingSettings}
            eventHandlers={{
              handleHasSettingChanged: this.handleHasSettingChanged,
            }}
          />
          <LinkShortening
            eventHandlers={{
              handleHasSettingChanged: this.handleHasSettingChanged,
            }}
          />
          <TimeFormat
            eventHandlers={{
              handleHasSettingChanged: this.handleHasSettingChanged,
            }}
          />
          <WebsiteTag />
          <PerformanceGoalLink />
        </div>
      </>
    );
  }
}

export default withGlobalInfo(withLocation(Property));
