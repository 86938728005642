import { Header, SettingsCogIcon } from '@ebx-ui/ebx-ui-component-library-sdk';
import { Link } from 'react-router-dom';

import { ROUTE_REDIRECTIONS } from 'common/constants';

const Settings = () => {
  return (
    <Header.IconButton
      as={Link}
      to={ROUTE_REDIRECTIONS.SETTINGS_PROPERTY}
      aria-label="Settings"
      icon={<SettingsCogIcon size="6" />}
      data-cy="settings-link"
    />
  );
};

export default Settings;
