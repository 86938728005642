import { create } from 'zustand';

interface ComposeBoxScheduleMetricsState {
  numberOfPostPreviews: number | null;
  scheduleClickTime: number | null;
  includesShareNowPost: boolean | null;

  // https://tkdodo.eu/blog/working-with-zustand#separate-actions-from-state
  actions: {
    resetScheduleMetrics: () => void;
    startScheduleMetrics: (args: {
      numberOfPostPreviews: number;
      includesShareNowPost: boolean;
    }) => void;
  };
}

const useComposeBoxScheduleMetricsStore =
  create<ComposeBoxScheduleMetricsState>((set) => ({
    numberOfPostPreviews: null,
    scheduleClickTime: null,
    includesShareNowPost: null,

    actions: {
      resetScheduleMetrics: () => set({ scheduleClickTime: null }),
      startScheduleMetrics: ({ numberOfPostPreviews, includesShareNowPost }) =>
        set({
          numberOfPostPreviews,
          scheduleClickTime: Date.now(),
          includesShareNowPost,
        }),
    },
  }));

const useNumberOfPostPreviews = () =>
  useComposeBoxScheduleMetricsStore((state) => state.numberOfPostPreviews);
const useScheduleClickTime = () =>
  useComposeBoxScheduleMetricsStore((state) => state.scheduleClickTime);
const useComposeBoxScheduleMetricsActions = () =>
  useComposeBoxScheduleMetricsStore((state) => state.actions);
const useIncludesShareNowPost = () =>
  useComposeBoxScheduleMetricsStore((state) => state.includesShareNowPost);

export {
  useComposeBoxScheduleMetricsActions,
  useIncludesShareNowPost,
  useNumberOfPostPreviews,
  useScheduleClickTime,
};
