import { Box, Spinner, Tooltip } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import putAPIsPauseSharing from 'api/putAPIsPauseSharing';
import {
  getAPIPostName,
  getAPITypeId,
  getCurrentAccountAPIId,
  getCurrentAPITypeId,
  getCurrentPropertyId,
  getCurrentSocialPageURN,
  isCurrentAccountAPIPaused,
} from 'common/accountAPIs';
import {
  ACCOUNT_SETTING_TYPES,
  API_TYPE_IDS,
  COLLECTION_NAMES,
  GLOBAL_INFO_CHANGES,
  GLOBAL_INFO_STATES,
  REACT_PREVENT_RENDER,
  SHARE_TIME_TYPES,
  UI_MESSAGES,
} from 'common/constants';
import {
  getDateFromUnix,
  getFormattedShareTimeFromUnix,
  getUnixTimestampFromOffset,
} from 'common/datetime';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import { addErrorNotification } from 'common/notifications';
import { getSetting } from 'common/settings';
import { getSocialNetworkName } from 'common/social';
import * as tracker from 'common/tracker';
import { cloneObject, isEmpty, isNull } from 'common/utility';
import { mandatory } from 'common/validation';
import BaseComponent from 'components/BaseComponent';
import Item from 'components/home/schedulequeue/Item';
import Button from 'components/misc/Button';
import withGlobalInfo from 'context/withGlobalInfo';
import { setExpandedFlags, setItemsPerDay } from 'helpers/scheduleQueue';

/**
 * Schedule queue
 */

class ScheduleQueue extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      guid: null,
      itemsPerDay: {},
      expandedFlags: {},
      prevState: {
        itemsPerDay: {},
        expandedFlags: {},
      },
    };
    this._bind(
      'handleCollapseAll',
      'handleExpandAll',
      'handleExpandCollapse',
      'handlePauseSharing',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare(COLLECTION_NAMES.SCHEDULE_QUEUE, {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.LOADING_OR_SELECTING,
    });
  }

  static getDerivedStateFromProps(props, state) {
    const itemsPerDay = setItemsPerDay({
      mediaItems: props.feedData.mediaItems.filter(Boolean),
    });
    let expandedFlags = state.expandedFlags;
    // Set initial expanded / collapsed state for date "buckets"
    // (a) this state variable has not yet been initialised, or
    // (b) the date "bucket" data has changed
    if (
      isEmpty(state.prevState.expandedFlags) ||
      JSON.stringify(state.prevState.itemsPerDay) !==
        JSON.stringify(itemsPerDay)
    ) {
      expandedFlags = setExpandedFlags({
        itemsPerDay,
        expandedFlags: state.prevState.expandedFlags,
      });
    }
    return {
      itemsPerDay,
      expandedFlags,
      prevState: {
        itemsPerDay,
        expandedFlags,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
      { excludeLoadingAndRefreshing: true },
    );
    logger.info(
      `ScheduleQueue:componentDidUpdate - hasChangedToReady ${hasChangedToReady}`,
    );
    if (hasChangedToReady) {
      this.initialiseComponent();
    }
  }

  /**
   * Event handlers
   */

  handleCollapseAll() {
    this.handleExpandCollapseAll(false);
  }

  handleExpandAll() {
    this.handleExpandCollapseAll(true);
  }

  handleExpandCollapseAll(value) {
    logger.info(`ScheduleQueue:handleExpandCollapseAll - ${value}`);
    this.setState((prevState) => {
      const nextState = cloneObject(prevState);
      Object.keys(nextState.expandedFlags).forEach((dateOffset) => {
        nextState.expandedFlags[dateOffset] = value;
      });
      return nextState;
    });
  }

  handleExpandCollapse(dateOffset) {
    return () => {
      logger.info(`ScheduleQueue:handleExpandCollapse - ${dateOffset}`);
      this.setState((prevState) => {
        const nextState = cloneObject(prevState);
        nextState.expandedFlags[dateOffset] =
          !nextState.expandedFlags[dateOffset];
        return nextState;
      });
    };
  }

  handlePauseSharing() {
    logger.info('ScheduleQueue:handlePauseSharing');

    const isSharingPaused = isCurrentAccountAPIPaused();
    const successMessage = isSharingPaused
      ? 'Sharing resumed'
      : 'Sharing paused';
    const failureMessage = isSharingPaused
      ? 'Failed to resume sharing'
      : 'Failed to pause sharing';

    tracker.track({
      eventName: 'Pause Sharing',
      trackingParams: {
        Action: isSharingPaused ? 'Resumed' : 'Paused',
        'Social Network': getSocialNetworkName({
          apiTypeId: getCurrentAPITypeId(),
        }),
        'Social Page': getAPIPostName({
          accountAPIId: getCurrentAccountAPIId(),
        }),
      },
    });
    putAPIsPauseSharing({
      socialPageURN: getCurrentSocialPageURN(),
      isSharingPaused: !isSharingPaused,
    })
      .then(() => {
        this.props.global.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.REFRESHING,
          successMessage,
        });
      })
      .catch((error) => {
        console.log(error);
        addErrorNotification(failureMessage);
      });
  }

  /**
   * Helper methods
   */

  getIsLoading() {
    const mediaItems = this.props.feedData.mediaItems.filter(Boolean);
    return (
      mediaItems.length > 0 &&
      mediaItems.every((mediaItem) =>
        MediaItem.getIsLoading({
          mediaItem,
        }),
      )
    );
  }

  initialiseComponent() {
    logger.info('ScheduleQueue:initialiseComponent');

    this.props.sharedMethods.getFeedData({
      collectionName: COLLECTION_NAMES.SCHEDULE_QUEUE,
    });
  }

  /**
   * Render methods
   */

  renderDateHeader({ dateOffset = mandatory('dateOffset') } = {}) {
    if (dateOffset < 0) {
      return REACT_PREVENT_RENDER;
    }

    const isExpanded = this.state.expandedFlags[dateOffset];
    const numberOfItems = this.state.itemsPerDay[dateOffset].length;
    const shareDate =
      dateOffset === SHARE_TIME_TYPES.NOW
        ? { slang: 'Now' }
        : getDateFromUnix(
            getUnixTimestampFromOffset({
              dateOffset,
              hour: 12,
              minute: 0,
            }),
          );
    const displayDate =
      shareDate.slang !== ''
        ? shareDate.slang
        : `${shareDate.monthName.substring(0, 3)} ${shareDate.day}`;

    return (
      <div
        className="card-schedule bg-gray-100"
        key={`date-header-${dateOffset}`}
        data-cy-id="dateHeader"
        data-cy-attribute={`dateOffset:${dateOffset}`}
      >
        <a
          className={`schedule-queue-header ${!isExpanded ? 'collapsed' : ''}`}
          onClick={this.handleExpandCollapse(dateOffset)}
          data-cy-id="expandCollapse"
        >
          <div
            className="d-flex"
            data-cy-id="offsetStatus"
            data-cy-attribute={`isExpanded:${isExpanded}`}
          >
            <span className="text-500">{displayDate}</span>
            <Tooltip
              placement="bottom"
              label={`You currently have ${numberOfItems} post${
                numberOfItems === 1 ? '' : 's'
              } scheduled for this day`}
            >
              <span
                className="ml-auto mr-3"
                data-tip
                data-for={`number-of-items-${dateOffset}`}
                data-iscapture="true"
              >
                <span data-cy-id="numberOfItems">{numberOfItems}</span>
              </span>
            </Tooltip>
          </div>
        </a>
      </div>
    );
  }

  renderDateItems({ dateOffset = mandatory('dateOffset') } = {}) {
    const mediaItems = this.props.feedData.mediaItems.filter(Boolean);
    const itemsPerDay = this.state.itemsPerDay;
    const eventHandlers = this.props.eventHandlers;

    const dateItems = [];
    itemsPerDay[dateOffset].forEach((index) => {
      const mediaItem = mediaItems[index];
      dateItems.push(
        <Item
          mediaItem={mediaItem}
          accountAPIId={this.props.accountAPIId}
          key={MediaItem.getMediaId({ mediaItem })}
          eventHandlers={{
            handleArticleDelete: eventHandlers.handleArticleDelete,
            handleArticleLoad: eventHandlers.handleArticleLoad,
          }}
        />,
      );
    });

    return (
      <div className="border-top" key={`date-items-${dateOffset}`}>
        {dateItems}
      </div>
    );
  }

  renderScheduleQueue() {
    const expandedFlags = this.state.expandedFlags;
    // bring now flag to the top
    const dateOffsets = Object.keys(expandedFlags).sort((a, b) => {
      if (a === SHARE_TIME_TYPES.NOW || b === SHARE_TIME_TYPES.NOW) {
        return -1;
      }
      return a - b;
    });
    const queueItems = [];
    dateOffsets.forEach((dateOffset) => {
      queueItems.push(this.renderDateHeader({ dateOffset }));
      if (expandedFlags[dateOffset]) {
        queueItems.push(this.renderDateItems({ dateOffset }));
      }
    });

    return <div className="accordion">{queueItems}</div>;
  }

  renderShareTime() {
    const mediaItems = this.props.feedData.mediaItems.filter(Boolean);
    if (this.getIsLoading()) {
      return REACT_PREVENT_RENDER;
    }
    if (mediaItems.length === 0) {
      return (
        <div className="ml-auto d-flex align-items-center">
          {this.renderSharingUnpaused()}
        </div>
      );
    }
    const mediaItem = mediaItems[0];
    if (MediaItem.getIsLoading({ mediaItem })) {
      return REACT_PREVENT_RENDER;
    }
    const twelveHourFormatSetting = getSetting({
      settingTypeId: ACCOUNT_SETTING_TYPES.TWELVE_HOUR_TIME_FORMAT,
      propertyId: getCurrentPropertyId(),
    });
    const twelveHourFormat = !isNull(twelveHourFormatSetting)
      ? twelveHourFormatSetting.enabled
      : false;
    const shareTime = getFormattedShareTimeFromUnix({
      timestamp: MediaItem.getOptimalShareTime({ mediaItem }),
      twelveHourFormat,
    });
    if (isNull(shareTime)) {
      return (
        <div className="ml-auto d-flex align-items-center">
          {this.renderSharingUnpaused()}
        </div>
      );
    }

    return (
      <div className="ml-auto d-flex align-items-center">
        <span className="d-flex align-items-center">
          <span className="next-share-time" data-cy-id="scheduleQueueLabel">
            next share {shareTime}
          </span>
          <Tooltip
            placement="bottom"
            label="The time of the next share may change. The share times for all posts
          are continuously being recalculated and optimized based on the latest
          available data."
          >
            <img
              className="info_icon mx-1 op-60 d-none d-md-block"
              src="/img/icons/ic-information.svg"
              alt=""
            />
          </Tooltip>
        </span>
        {this.renderSharingUnpaused()}
      </div>
    );
  }

  renderSharingPaused() {
    const apiTypeId = getAPITypeId({ accountAPIId: this.props.accountAPIId });
    const socialNetworkName = getSocialNetworkName({ apiTypeId });
    const tooltipLabel = `Resume all posting on this ${socialNetworkName} page`;

    return (
      <div className="ml-auto d-flex align-items-center">
        <Tooltip placement="top" label={tooltipLabel}>
          <Button
            className="mr-1 lh-24 resume-sharing"
            onClick={this.handlePauseSharing}
          >
            Resume posting{' '}
            <img src="/img/icons/ic-play.svg" alt="" className="pl-1" />
          </Button>
        </Tooltip>
        <div className="dropdown align-self-center">
          <button
            className="border-0 align-self-center dot-menu dot-menu-secondary border-radius-2 schedule-queue-menu"
            type="button"
            id="dropdownMenuPause"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-cy-id="scheduleQueueMenu"
          >
            <svg
              viewBox="0 0 22 22"
              focusable="false"
              style={{ pointerEvents: 'none', width: '18px', height: '18px' }}
              fill="#A3ACB9"
            >
              <path
                d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                className=""
              />
            </svg>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuPause"
          >
            <Tooltip placement="top" label={tooltipLabel}>
              <button
                type="button"
                className="dropdown-item d-flex align-items-center resume-sharing"
                onClick={this.handlePauseSharing}
                data-cy-action="scheduleQueueResume"
              >
                <img src="/img/icons/ic-play.svg" alt="" className="mr-2" />
                Resume posting
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }

  renderSharingUnpaused() {
    const apiTypeId = getAPITypeId({ accountAPIId: this.props.accountAPIId });
    const socialNetworkName = getSocialNetworkName({ apiTypeId });

    return (
      <div className="dropdown align-self-center">
        <button
          className="border-0 align-self-center dot-menu dot-menu-secondary border-radius-2 schedule-queue-menu"
          type="button"
          id="dropdownMenuPause"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          data-cy-id="scheduleQueueMenu"
        >
          <svg
            viewBox="0 0 22 22"
            focusable="false"
            style={{ pointerEvents: 'none', width: '18px', height: '18px' }}
            fill="#A3ACB9"
          >
            <path
              d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
              className=""
            />
          </svg>
        </button>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuPause"
        >
          <Tooltip
            placement="top"
            label={`Pause all posting on this ${socialNetworkName} page`}
          >
            <button
              type="button"
              className="dropdown-item d-flex align-items-center pause-sharing py-2"
              data-cy-id="pauseSharing"
              onClick={this.handlePauseSharing}
              data-tip
              data-for="sharing-pause-unpause"
              data-iscapture="true"
              data-cy-action="scheduleQueuePause"
            >
              <img src="/img/icons/ic-pause.svg" alt="" className="mr-2" />
              Pause all posting
            </button>
          </Tooltip>
          <div className="dropdown-divider" />
          <button
            className="dropdown-item d-flex align-items-center py-2"
            data-cy-id="expandAll"
            onClick={this.handleExpandAll}
            type="button"
          >
            Expand all
          </button>
          <button
            className="dropdown-item d-flex align-items-center py-2"
            data-cy-id="collapseAll"
            onClick={this.handleCollapseAll}
            type="button"
          >
            Collapse all
          </button>
        </div>
      </div>
    );
  }

  render() {
    const feedData = this.props.feedData;
    const isLoading =
      feedData.isLoading ||
      this.props.global.isLoading() ||
      this.props.global.isSelecting();
    const emptyMessage = UI_MESSAGES.SCHEDULE_QUEUE_NO_ITEMS_FOUND;
    const mediaItems = feedData.mediaItems;
    const isTikTok = getCurrentAPITypeId() === API_TYPE_IDS.TIKTOK;
    const isSharingPaused = isCurrentAccountAPIPaused();
    const queueClass = isSharingPaused ? 'op-50' : '';

    return (
      <Box
        display={{
          base: this.props.tabName === 'queue' ? 'inline-block' : 'none',
          md: 'inline-block',
        }}
        w="full"
        className="queue"
        data-cy-id="scheduleQueue"
        data-cy-attribute={`isLoading:${isLoading}`}
        {...(!isTikTok && { mt: { base: 0, md: 4 } })}
      >
        <div
          className="queue_header d-flex align-items-center"
          data-cy-id="queueStatus"
          data-cy-attribute={`isSharingPaused:${isSharingPaused}`}
        >
          <div className="heading text-700 mr-2 lh-20">
            Schedule Queue{' '}
            <span data-cy-id="scheduleQueueCount">
              {!isLoading && `(${feedData.mediaItems.length})`}
            </span>
          </div>
          {isSharingPaused
            ? this.renderSharingPaused()
            : this.renderShareTime()}
        </div>
        {isSharingPaused && (
          <div className="py-2 lh-16" style={{ clear: 'both' }}>
            Your Schedule Queue is paused.
            <br />
            No posts will be shared until you resume posting.
          </div>
        )}
        {isLoading && (
          <div className="empty_feed text-center py-5">
            <Spinner size="lg" />
          </div>
        )}
        {!isLoading && mediaItems.length > 0 && (
          <div className={`queue_main ${queueClass}`}>
            {this.renderScheduleQueue()}
          </div>
        )}
        {!isLoading && mediaItems.length === 0 && (
          <div className="queue_main">
            <div className="share_item_empty">
              <div className="cleared_message">{emptyMessage}</div>
            </div>
          </div>
        )}
      </Box>
    );
  }
}

ScheduleQueue.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  feedData: PropTypes.object.isRequired,
  tabName: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleDelete: PropTypes.func.isRequired,
  }).isRequired,
  sharedMethods: PropTypes.shape({
    getFeedData: PropTypes.func.isRequired,
  }).isRequired,
};

export default withGlobalInfo(ScheduleQueue);
