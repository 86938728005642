/* jscpd:ignore-start */
import { Flex, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import { REACT_PREVENT_RENDER } from 'common/constants';

/*
 * Validation error
 */

const ValidationError = (props) => {
  if (props.errorMessage === '') {
    return REACT_PREVENT_RENDER;
  }
  let url = props.url;
  if (url !== '') {
    // Not all URLs may start with http
    url = url.startsWith('http') ? url : `http://${url}`;
  }

  return (
    <table
      className={`alert_message alert_message_bad ${props.className ?? ''}`}
    >
      <Flex gap="1">
        <img className="icon-error" src="/img/icons/ic-error-red.svg" alt="" />
        <Text color="#cd3d64" fontSize="13px" data-cy-id={props.dataCyId}>
          {props.errorMessage}
        </Text>
        {url !== '' && (
          <span>
            &nbsp;
            <a href={url} target="_blank" rel="noopener noreferrer">
              {props.url}
            </a>
          </span>
        )}
      </Flex>
    </table>
  );
};

ValidationError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  className: PropTypes.string,
  url: PropTypes.string,
  dataCyId: PropTypes.string,
};

ValidationError.defaultProps = {
  className: null,
  url: '',
  dataCyId: 'errorMessage',
};

export default ValidationError;
/* jscpd:ignore-end */
