import {
  Button,
  Card,
  ChevronLeftIcon,
  Flex,
  Grid,
  Heading,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { SyndFeedInterface } from 'types';
import ContentFeedMetaData from './ContentFeedMetaData';
import ContentFeedUpdateHistory from './ContentFeedUpdateHistory';

const contentFeedDisplayDetails: Array<{
  heading: string;
  getValueFromContentFeed: (syndFeed: SyndFeedInterface) => string;
}> = [
  {
    heading: 'Content Feed Title',
    getValueFromContentFeed: (feed) => feed.syndFeedTitle ?? '',
  },
  {
    heading: 'Content Feed State',
    getValueFromContentFeed: (feed) => feed.syndFeedState,
  },
  {
    heading: 'Content Feed Type',
    getValueFromContentFeed: (feed) => feed.syndFeedType,
  },
  {
    heading: 'Last Article Time',
    getValueFromContentFeed: (feed) =>
      feed.lastArticleTime === 0
        ? ''
        : new Date(feed.lastArticleTime * 1000).toUTCString(),
  },
  {
    heading: 'Refresh Interval (Seconds)',
    getValueFromContentFeed: (feed) => feed.refreshSecs.toString(),
  },
  {
    heading: 'Failure Rate',
    getValueFromContentFeed: (feed) => `${feed.failureRate}%`,
  },
];

interface ContentFeedDetailsProps {
  contentFeed: SyndFeedInterface;
  onBackClick: () => void;
}

const ContentFeedDetails = ({
  contentFeed,
  onBackClick,
}: ContentFeedDetailsProps) => {
  return (
    <Flex flexDirection="column" gap={4}>
      <div>
        <Button
          variant="secondary"
          onClick={onBackClick}
          leftIcon={<ChevronLeftIcon />}
        >
          Back to search
        </Button>
      </div>
      <Card>
        <Card.Content gap={4} flexGrow={1}>
          <Card.Title>Content Feed Details</Card.Title>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} px={0}>
            <Grid.GridItem key="Content Feed URL" colSpan={2}>
              <Heading variant="h6" color="gray.600" mb={1}>
                Content Feed URL:
              </Heading>
              <Text size="sm">{contentFeed.syndFeedURL}</Text>
            </Grid.GridItem>

            <Grid.GridItem key="Content Feed URN" colSpan={2}>
              <Heading variant="h6" color="gray.600" mb={1}>
                Content Feed URN:
              </Heading>
              <Text size="sm">{contentFeed.syndFeedURN}</Text>
            </Grid.GridItem>

            {contentFeedDisplayDetails.map(
              ({ getValueFromContentFeed, heading }) => (
                <Grid.GridItem key={heading}>
                  <Heading variant="h6" color="gray.600" mb={1}>
                    {heading}:
                  </Heading>
                  <Text size="sm">{getValueFromContentFeed(contentFeed)}</Text>
                </Grid.GridItem>
              ),
            )}
            <Grid.GridItem />
          </Grid>

          {contentFeed.metadata &&
            Object.keys(contentFeed.metadata).length > 0 && (
              <ContentFeedMetaData metaData={contentFeed.metadata} />
            )}
          {contentFeed.updateHistory?.length > 0 && (
            <ContentFeedUpdateHistory
              updateHistory={contentFeed.updateHistory}
            />
          )}
        </Card.Content>
      </Card>
    </Flex>
  );
};

export default ContentFeedDetails;
