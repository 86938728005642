import {
  Alert,
  Button,
  Center,
  Flex,
  FormControl,
  Input,
  Link,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import * as React from 'react';

import { TWITTER_PROJECT_APP_INSTRUCTIONS_URL } from 'common/constants';
import * as logger from 'common/logger';
import * as tracker from 'common/tracker';
import SocialTwitterLogo2 from 'svg/SocialTwitterLogo2';

interface ConnectTwitterAppModalProps {
  onClose: () => void;
  onConnect: (accessToken: string, secret: string) => Promise<void>;
  isReconnecting?: boolean;
}
const ConnectTwitterAppModal = ({
  onClose,
  onConnect,
  isReconnecting = false,
}: ConnectTwitterAppModalProps) => {
  const [accessToken, setAccessToken] = React.useState('');
  const [secret, setSecret] = React.useState('');
  const [isConnecting, setIsConnecting] = React.useState(false);
  const [error, setError] = React.useState('');
  const [hasGenericError, setHasGenericError] = React.useState(false);

  const trackAction = (action: string) => {
    tracker.track({
      eventName: 'Action Connect Twitter Project App Modal',
      trackingParams: {
        Action: action,
      },
    });
  };

  const handleClose = () => {
    trackAction('Close');
    onClose();
  };

  const handleCancel = () => {
    trackAction('Cancel');
    onClose();
  };

  const handleConnect = async () => {
    trackAction('Connect');
    setError('');
    setHasGenericError(false);
    setIsConnecting(true);
    try {
      await onConnect(accessToken, secret);
      onClose();
    } catch (connectError: any) {
      setIsConnecting(false);
      logger.error({
        event: 'Error connecting Twitter App',
        error: connectError,
      });
      if (connectError?.status === 400) {
        setError(connectError?.error?.message);
        tracker.track({
          eventName: 'Connect Twitter Project App Modal Error',
          trackingParams: {
            'Error Message': connectError?.error?.message,
          },
        });
      } else {
        setHasGenericError(true);
      }
    }
  };

  React.useEffect(() => {
    tracker.track({
      eventName: 'Display Connect Twitter Project App Modal',
      trackingParams: {
        'Reconnection?': isReconnecting,
      },
    });
  }, [isReconnecting]);

  return (
    <Modal isOpen onClose={handleClose} size="small">
      <Modal.Header>
        <Modal.Title>Connect Twitter Project App</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Flex direction="column" gap={6} pt={2}>
          <Center>
            <SocialTwitterLogo2 />
          </Center>
          {error && (
            <Alert variant="error">
              <Alert.Description wordBreak="break-word">
                {error}
              </Alert.Description>
            </Alert>
          )}
          <Text size="sm" color="gray.600">
            Before adding a Twitter page, please connect your Twitter Project
            App.{' '}
            <Link
              href={TWITTER_PROJECT_APP_INSTRUCTIONS_URL}
              onClick={() => trackAction('Follow these steps')}
              isExternal
              fontWeight="medium"
              color="primary.600"
            >
              Follow these steps
            </Link>{' '}
            to get your API Key and Secret.
          </Text>
          <Flex as="form" direction="column" gap={6}>
            <FormControl>
              <FormControl.FormLabel>API Key</FormControl.FormLabel>
              <Input
                value={accessToken}
                onChange={(e) => setAccessToken(e.target.value)}
                type="password"
              />
            </FormControl>
            <FormControl>
              <FormControl.FormLabel>API Key Secret</FormControl.FormLabel>
              <Input
                value={secret}
                onChange={(e) => setSecret(e.target.value)}
                type="password"
              />
            </FormControl>
          </Flex>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        {hasGenericError ? (
          <Text color="red.600" size="sm" mr="auto">
            Please try again.
          </Text>
        ) : (
          <Link
            mr="auto"
            href={TWITTER_PROJECT_APP_INSTRUCTIONS_URL}
            onClick={() => trackAction('Read instructions')}
            isExternal
            color="gray.900"
          >
            Read instructions
          </Link>
        )}
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          isDisabled={!accessToken || !secret || isConnecting}
          onClick={handleConnect}
          isLoading={isConnecting}
          loadingText="Connecting"
        >
          Connect
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectTwitterAppModal;
