import { useEffect, useState } from 'react';

import {
  getAllAccountAPIIds,
  getAPITypeId,
  getCurrentProperty,
} from 'common/accountAPIs';
import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import { loadSettingsForAPI } from 'common/settings';
import { isSocialNetwork } from 'common/social';

export default function useAllowContentFromAllDomains() {
  const [isLoading, setIsLoading] = useState(true);
  const [allowContentFromAllDomains, setAllowContentFromAllDomains] =
    useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      setIsLoading(true);
      const currentProperty = getCurrentProperty();
      const firstSocialAccountAPIId = getAllAccountAPIIds({
        propertyList: { [currentProperty.propertyId]: currentProperty },
      }).find((accountAPIId) => {
        const apiTypeId = getAPITypeId({ accountAPIId });
        return isSocialNetwork({ apiTypeId });
      });
      const response = await loadSettingsForAPI({
        accountAPIId: firstSocialAccountAPIId,
        settingTypeIds: [ACCOUNT_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS],
      });

      const isEnabled = response
        ? !!response[ACCOUNT_SETTING_TYPES.ALLOW_CONTENT_FROM_ALL_DOMAINS]
            ?.enabled
        : false;
      setAllowContentFromAllDomains(isEnabled);
      setIsLoading(false);
    };

    loadSettings();
  }, []);

  return { isLoading, allowContentFromAllDomains };
}
