export function canvasScale(img: HTMLImageElement, width: number) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = width;
  canvas.height = canvas.width * (img.height / img.width);
  ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
}

export function steppedScale(
  img: HTMLImageElement,
  width: number,
  step: number,
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const oc = document.createElement('canvas');
  const octx = oc.getContext('2d');

  canvas.width = width;
  canvas.height = (canvas.width * img.height) / img.width;

  if (img.width * step > width && octx && ctx) {
    const mul = 1 / step;
    let cur = {
      width: Math.floor(img.width * step),
      height: Math.floor(img.height * step),
    };

    oc.width = cur.width;
    oc.height = cur.height;

    octx.drawImage(img, 0, 0, cur.width, cur.height);

    while (cur.width * step > width) {
      cur = {
        width: Math.floor(cur.width * step),
        height: Math.floor(cur.height * step),
      };
      octx.drawImage(
        oc,
        0,
        0,
        cur.width * mul,
        cur.height * mul,
        0,
        0,
        cur.width,
        cur.height,
      );
    }

    ctx.drawImage(
      oc,
      0,
      0,
      cur.width,
      cur.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );
  } else {
    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
  }
  return canvas.toDataURL();
}

export function isWithinAspectRatio({
  imageDimensions: { width, height },
  aspectRatioLimits: aspectRatiosLimits,
}: {
  imageDimensions: { width: number; height: number };
  aspectRatioLimits: { min: number; max: number };
}) {
  const MULTIPLIER = 1000;
  const ACCURACY = 2;
  // Calculate image aspect ratio and compare to allowed values
  const aspectRatio = (
    Math.round((width * MULTIPLIER) / height) / MULTIPLIER
  ).toFixed(ACCURACY);

  const minRatio = (
    Math.round(aspectRatiosLimits.min * MULTIPLIER) / MULTIPLIER
  ).toFixed(ACCURACY);
  const maxRatio = (
    Math.round(aspectRatiosLimits.max * MULTIPLIER) / MULTIPLIER
  ).toFixed(ACCURACY);

  return aspectRatio >= minRatio && aspectRatio <= maxRatio;
}

export function isValidImage(url: string) {
  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(true);
    };
    image.onerror = () => {
      resolve(false);
    };

    image.src = url;
  });
}
