import { memo, MouseEventHandler } from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { CROSS_POST_PROPERTIES } from 'common/config';
import { SHARE_ORIGINS } from 'common/constants';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import * as tracker from 'common/tracker';
import { arePropsEqual } from 'common/utility';
import {
  duplicateComposeBoxItem,
  useComposeBoxContext,
} from 'context/ComposeBoxContext';
import { getCommonTrackingParams } from 'helpers/tracking';

interface OtherActionsProps {
  allowDelete: boolean;
  containsSingleAccountAPIId: boolean;
  deleteComposeBoxItem: (args: { guid: string }) => void;
  guid: string;
  previewCollapsed: boolean;
}

const OtherActions = ({
  allowDelete,
  containsSingleAccountAPIId,
  deleteComposeBoxItem,
  guid,
  previewCollapsed,
}: OtherActionsProps) => {
  const { dispatch, postPreviews } = useComposeBoxContext();

  /**
   * Event handlers
   */

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    logger.info('OtherActions:handleDelete');
    deleteComposeBoxItem({ guid });

    const preview = postPreviews.find((item) => item.guid === guid);
    if (preview !== undefined) {
      const { mediaItem } = preview;
      const shareOrigin = MediaItem.getTrackingDetails({ mediaItem }).Origin;
      if (shareOrigin === SHARE_ORIGINS.DUPLICATE) {
        const trackingParams = getCommonTrackingParams({ mediaItem });
        tracker.track({
          eventName: 'Remove Duplicate Post',
          trackingParams,
        });
      }
    }
  };

  const handleDuplicate: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    logger.info('OtherActions:handleDelete');
    dispatch(duplicateComposeBoxItem({ guid }));
  };

  /**
   * Render method
   */

  return (
    <div className="mt-3 mb-2 border-top d-flex">
      {!CROSS_POST_PROPERTIES.includes(getCurrentPropertyId()) ||
      (containsSingleAccountAPIId && !previewCollapsed) ? (
        <a
          className="link_grey align-self-center py-2"
          data-cy-action="duplicate"
          onClick={handleDuplicate}
        >
          Duplicate post
        </a>
      ) : null}
      {allowDelete ? (
        <div className="dropdown align-self-center ml-auto py-2">
          <div
            className="border-0 align-self-center dot-menu dot-menu-primary border-radius-2"
            id="dropdownMenuPage"
            data-cy-select="pageOptions"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <svg
              viewBox="0 0 22 22"
              focusable="false"
              style={{ pointerEvents: 'none', width: '18px', height: '18px' }}
              fill="#A3ACB9"
            >
              <path
                d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                className="style-scope yt-icon"
              />
            </svg>
          </div>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuPage">
            <button
              className="dropdown-item"
              data-id="691"
              data-cy-action="delete"
              type="button"
              onClick={handleDelete}
            >
              Remove
            </button>
          </div>
        </div>
      ) : (
        <div className="ml-auto py-2" style={{ marginTop: '11px' }}>
          &nbsp;
        </div>
      )}
    </div>
  );
};

export default memo(OtherActions, arePropsEqual);
