/* eslint no-param-reassign: ["error", { "props": false }] */

/**
 * postMediaItemField - update specified field for one or more media items
 *
 * @param {long} accountAPIId  - the account to which the item belongs
 * @param {string} state       - the existing state of the media item
 * @param {array} mediaIds     - the media item id(s)
 * @param {string} fieldName   - the name of the field to be updated
 * @param {any} fieldValue     - the value to assign to the specified field
 */

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { getAPITypeId } from 'common/accountAPIs';
import { API_PROPERTIES } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { convertToSocialPageURN } from 'common/urn';
import { mandatory } from 'common/validation';

export default async function postMediaItemField({
  accountAPIId = mandatory('accountAPIId'),
  state = mandatory('state'),
  mediaIds = mandatory('mediaIds'),
  fieldName = mandatory('fieldName'),
  fieldValue = mandatory('fieldValue'),
} = {}) {
  const guid = metrics.start('postMediaItemField');

  const typeId = getAPITypeId({ accountAPIId });
  const socialNetworkType = API_PROPERTIES[typeId]?.urnName;
  const socialPageURN = convertToSocialPageURN(socialNetworkType, accountAPIId);

  const config = {
    url: `${constructBaseSocialAPIURL(
      'v3.2',
    )}/social/api/${socialPageURN}/media/${state}/${mediaIds}/${fieldName}`,
    method: 'POST',
    timeout: API_TIMEOUTS.L,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
    data: { [fieldName]: fieldValue },
  };
  logger.info(
    `API:postMediaItemFields /social/api/${socialPageURN}/media/${state}/${mediaIds}/${fieldName} ${fieldValue}`,
  );

  try {
    const response = await axios(config);
    metrics.end('postMediaItemField', guid);
    logger.debug('postMediaItemField', {
      accountAPIId,
      state,
      mediaIds,
      fieldName,
      fieldValue,
    });
    return response.data.mediaId;
  } catch (error) {
    metrics.fail('postMediaItemField', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postMediaItemField',
      args: {
        accountAPIId,
        state,
        mediaIds,
        fieldName,
        fieldValue,
      },
    });
    throw apiError;
  }
}
