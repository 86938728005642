import MessageWithTags from 'components/home/analytics/AnalyticsMessageWithTags';
import * as helpers from 'helpers/analyticsPage';
import {
  ABResults,
  ABVariations,
  ActionHistoryType,
  PostType,
  TagMap,
} from 'types';

interface CommonProps {
  accountAPIId: number;
  actionTypeId: ActionHistoryType;
  messageText: string;
  postType: PostType;
  tags: TagMap;
}

type ABProps =
  | {
      abResults: undefined;
      abTestStatusId: undefined;
      abVariations: undefined;
      isABVariation: false;
    }
  | {
      abResults: ABResults;
      abTestStatusId: number;
      abVariations: ABVariations;
      isABVariation: true;
    };

type MessageProps = CommonProps & ABProps;

const Message = ({
  abResults,
  abTestStatusId,
  abVariations,
  accountAPIId,
  actionTypeId,
  isABVariation,
  messageText,
  postType,
  tags,
}: MessageProps) => {
  if (!isABVariation) {
    return (
      <MessageWithTags
        truncateLines={2}
        actionTypeId={actionTypeId}
        message={messageText}
        tags={tags}
        accountAPIId={accountAPIId}
        outerClass="share_message lh-20 ft-13 word-break"
      />
    );
  }

  const abDetails = helpers.getABDetails({
    abVariations,
    abResults,
    abTestStatusId,
    postType,
  });

  return (
    <div className="abtest_content">
      <div className="abtest_variation">
        <div className="abtest_result">{abDetails[0].ctrLabel}</div>
        <MessageWithTags
          actionTypeId={actionTypeId}
          message={abDetails[0].message}
          tags={tags}
          accountAPIId={accountAPIId}
          outerClass="abtest_message"
        />
      </div>
      <div className="abtest_variation">
        <div className="abtest_result">{abDetails[1].ctrLabel}</div>
        <div className="abtest_message">
          <MessageWithTags
            actionTypeId={actionTypeId}
            message={abDetails[1].message}
            tags={tags}
            accountAPIId={accountAPIId}
            outerClass="abtest_message"
          />
        </div>
      </div>
    </div>
  );
};

export default Message;
