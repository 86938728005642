interface SortArrowProps {
  displayOptions: {
    sortBy: string;
    sortOrder: 'ASC' | 'DESC';
  };
  fieldName: string;
}

const SortArrow = ({ displayOptions, fieldName }: SortArrowProps) => {
  let className;
  if (fieldName === displayOptions.sortBy) {
    className = displayOptions.sortOrder.toLowerCase();
  } else {
    className = 'desc';
  }
  return (
    <img
      src="/img/icons/ic-arrow-long-down.svg"
      alt=""
      className={`arrow_${className}`}
    />
  );
};

export default SortArrow;
