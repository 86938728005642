import PropTypes from 'prop-types';

const Intro = (props) => {
  const { rootURL } = props;

  return (
    <div className="pt-4 border-top">
      <div className="border rounded-sm py-2 px-3 bg-gray-100">
        <div className="d-flex">
          <div className="position-relative mr-3">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7.7 10.5H6.3V6.3H7.7V10.5ZM7.7 4.9H6.3V3.5H7.7V4.9Z"
                fill="#3C4257"
              />
            </svg>
          </div>
          <div>
            <div className="text-500 pb-2">
              Allows links with matching domains to be automatically switched to
              the current Property domain.
            </div>
            <div className="pb-1">
              This is useful for publishers with multiple Properties, who may
              want to white label content across their Properties.
            </div>
            <div>
              Example: The domain of this Property is{' '}
              <span className="underline">{rootURL}</span> and you&apos;d like
              to share content from{' '}
              <span className="underline">www.example.com</span> but want to
              swap domains and use <span className="underline">{rootURL}</span>{' '}
              instead. If you add{' '}
              <span className="underline">www.example.com</span> to the list
              below, any links you share starting with{' '}
              <span className="underline">www.example.com</span> will
              automatically be switched to{' '}
              <span className="underline">{rootURL}</span>. For instance, a link
              post with{' '}
              <span className="underline">www.example.com/article_1</span> will
              in fact use <span className="underline">{rootURL}/article_1</span>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Intro.propTypes = {
  rootURL: PropTypes.string.isRequired,
};

export default Intro;
