import PropTypes from 'prop-types';

import { isNull } from 'common/utility';

const Footer = (props) => {
  const returnPath = props.returnPath ?? 'https://www.echobox.com/';
  const linkTarget = isNull(props.returnPath) ? '_blank' : '_self';

  return (
    <div className="small_menu">
      <div className="small_menu_left">
        <a
          className="link_green"
          href="mailto:support@echobox.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Contact support</span>
        </a>
      </div>
      <div className="small_menu_center">
        <span className="ebxltd">&copy; Echobox Ltd</span>
      </div>
      <div className="small_menu_right">
        <a
          className="link_green"
          href={returnPath}
          target={linkTarget}
          rel="noopener noreferrer"
        >
          <span>Back to homepage</span>
        </a>
      </div>
    </div>
  );
};

Footer.propTypes = {
  returnPath: PropTypes.string,
};

Footer.defaultProps = {
  returnPath: null,
};

export default Footer;
