import {
  Center,
  Spinner,
  SpinnerProps,
} from '@ebx-ui/ebx-ui-component-library-sdk';

const FullPageSpinner = (props: SpinnerProps) => {
  return (
    <Center h="100vh">
      <Spinner size="lg" {...props} />
    </Center>
  );
};

export default FullPageSpinner;
