import { Button, Modal, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

interface ConfirmTurnOffApprovalsProps {
  isOpen: boolean;
  onClose: () => void;
  handleApprovalChange: () => void;
}

function ConfirmTurnOffApprovals({
  isOpen,
  onClose,
  handleApprovalChange,
}: ConfirmTurnOffApprovalsProps) {
  const handleApproval = () => {
    handleApprovalChange();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Turn Approvals off?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm" color="gray.600">
          By turning Approvals off, Echobox will share directly to your social
          page.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleApproval}>
          Turn Approvals off
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmTurnOffApprovals;
