import {
  Button,
  Link,
  Modal,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { INSTAGRAM_INSTRUCTIONS_URL } from 'common/constants';

interface InstagramConnectionErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTryAgain: () => void;
}
const InstagramConnectionErrorModal = ({
  isOpen,
  onClose,
  onTryAgain,
}: InstagramConnectionErrorModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="small">
      <Modal.Header>
        <Modal.Title>Something went wrong</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text size="sm">
          We can’t find any Instagram business accounts that are connected to a
          Facebook business page.
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Link mr="auto" isExternal href={INSTAGRAM_INSTRUCTIONS_URL}>
          Read our guide
        </Link>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onTryAgain}>Try again</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstagramConnectionErrorModal;
