/* eslint consistent-return:"off" */
/* eslint no-param-reassign:"off" */
/* eslint no-use-before-define: ["error", { "functions": false }] */

/**
 * saveMediaItem - create / update media item and share content
 *
 * @param {long} accountAPIId  - the account to which the item belongs
 * @param {string} targetState - the required state of the media item
 * @param {object} mediaItem   - the media item details, including message content
 * @return {object}            - the created / updated media item
 */

import Immutable from 'seamless-immutable';

import getMediaItem from 'api/getMediaItem';
import postMedia from 'api/postMedia';
import { getAPITypeId } from 'common/accountAPIs';
import {
  AB_TEST_MINIMUM_TIME_LENGTH_IN_SECS,
  MEDIA_ITEM_VALIDATION_ERRORS,
} from 'common/config';
import {
  MEDIA_ITEM_STATES,
  POST_TYPES,
  SHARE_TIME_TYPES,
  SOCIAL_CHANNELS,
  SUGGESTION_TYPES,
} from 'common/constants';
import { getUnixTimestamp } from 'common/datetime';
import * as logger from 'common/logger';
import * as MediaItem from 'common/mediaItem';
import {
  canAddImage,
  getFirstCommentSettings,
  getMaximumUrlByteLength,
  getMaximumUrlLength,
  getMessageSettings,
  getValidationMessages,
  hasTitleField,
  isMessageFieldRequired,
  isTitleFieldRequired,
} from 'common/social';
import { ebxMessage, getCharacterCount, getLengthInBytes } from 'common/string';
import { validateURL } from 'common/url';
import { isDefined, isNull, isNullOrUndefined } from 'common/utility';
import { mandatory } from 'common/validation';

export { validateMediaItem };

/**
 * Save media item
 *
 * @param {{
 *  accountAPIId: number,
 *  mediaItem: import('types').FixTypeLater,
 *  targetState: string,
 * }}
 */
export default function saveMediaItem({
  accountAPIId = mandatory('accountAPIId'),
  mediaItem = mandatory('mediaItem'),
  targetState = mandatory('targetState'),
  returnSavedItem = true,
  mediaItemIndex = 0,
  errorOnDuplicate = true,
}) {
  return new Promise((resolve, reject) => {
    const apiTypeId = getAPITypeId({ accountAPIId });
    let prepareItem = Immutable(mediaItem);

    // If "Post to link in bio" checkbox was unticked, convert to a feed post
    if (!MediaItem.getShouldSendURL({ mediaItem: prepareItem })) {
      prepareItem = prepareItem.set(
        'frontend',
        prepareItem.frontend.without('unshortenedShareURL', 'shareURL'),
      );
      prepareItem = MediaItem.setPostType({
        mediaItem: prepareItem,
        fieldValue: POST_TYPES.STATUS,
      });
    }

    let mediaId = MediaItem.getMediaId({ mediaItem: prepareItem });
    const postType = MediaItem.getPostType({ mediaItem: prepareItem });
    const socialChannel = MediaItem.getSocialChannel({
      mediaItem: prepareItem,
    });
    let saveItem;

    // Get current and required states the right way round
    const currentState =
      mediaId === 'NEW'
        ? // New items are posted to their target state
          targetState
        : // Existing items are posted to their existing state
          MediaItem.getState({ mediaItem: prepareItem });

    // Convert past times/timeslots to optimal shares when rescheduling deleted items or unqueuing
    // items
    if (
      (currentState === MEDIA_ITEM_STATES.DELETED &&
        targetState === MEDIA_ITEM_STATES.SCHEDULED &&
        MediaItem.getMaxShareTime({ mediaItem: prepareItem }) <
          getUnixTimestamp()) ||
      (currentState === MEDIA_ITEM_STATES.SCHEDULED &&
        targetState === MEDIA_ITEM_STATES.NEW &&
        MediaItem.getMinShareTime({ mediaItem: prepareItem }) <
          getUnixTimestamp())
    ) {
      prepareItem = MediaItem.setShareTime({
        mediaItem: prepareItem,
        fieldValue: {
          type: SHARE_TIME_TYPES.OPTIMAL,
        },
      });
    }
    const shareTime = MediaItem.getShareTime({ mediaItem: prepareItem });

    // Step 1 - Create mediaItem to save

    const isChangingState =
      currentState === MEDIA_ITEM_STATES.DELETED ||
      targetState === MEDIA_ITEM_STATES.DELETED;

    const unshortenedShareURL = MediaItem.getUnshortenedShareURL({
      mediaItem: prepareItem,
    });

    Promise.resolve()

      .then(() => {
        if (unshortenedShareURL != null) {
          return validateURL(unshortenedShareURL);
        }
        return null;
      })

      .then(() =>
        MediaItem.getBackendProperties({
          mediaItem: prepareItem,
          accountAPIId,
          isChangingState,
          mediaItemIndex,
        }),
      )

      // Step 2 - Identify and execute any share content changes

      .then((backendItem) => {
        saveItem = backendItem;

        // Remove AB results
        if (isDefined(saveItem.abResults)) {
          delete saveItem.abResults;
        }
        if (isDefined(saveItem.abTestStatusId)) {
          delete saveItem.abTestStatusId;
        }

        delete saveItem.messages;

        // Remove images if video post (and can't upload thumbnails)
        // This is to ensure images are cleared when switching social channels
        if (
          !canAddImage({
            apiTypeId,
            postType,
            socialChannel,
            videoURL: saveItem.videoURL,
          })
        ) {
          delete saveItem.imageURLs;
        }

        // If media item is deleted, scheduled or unscheduled manually and the current
        // suggestion type is AUTO_SOCIAL_SHARE, then convert to MANUAL_SOCIAL_SHARE
        if (
          currentState !== targetState &&
          currentState !== MEDIA_ITEM_STATES.APPROVAL_REQUIRED &&
          (targetState === MEDIA_ITEM_STATES.SCHEDULED ||
            targetState === MEDIA_ITEM_STATES.DELETED ||
            targetState === MEDIA_ITEM_STATES.DELETED ||
            (currentState === MEDIA_ITEM_STATES.SCHEDULED &&
              targetState === MEDIA_ITEM_STATES.NEW)) &&
          saveItem.suggestionTypeId === SUGGESTION_TYPES.AUTO_SOCIAL_SHARE
        ) {
          saveItem.suggestionTypeId = SUGGESTION_TYPES.MANUAL_SOCIAL_SHARE;
        }

        // Get current and required states the right way round
        saveItem.state = targetState;

        // Set socialChannel if required
        if (postType === POST_TYPES.PHOTO_STORY) {
          saveItem.socialChannel = SOCIAL_CHANNELS.STORY;
        }

        const postImageNeedsToBeUpdated =
          MediaItem.getPostImageNeedsToBeUpdated({ mediaItem });

        // Validation
        const validationError = validateMediaItem(
          saveItem,
          apiTypeId,
          postType,
          shareTime,
          postImageNeedsToBeUpdated,
          socialChannel,
        );
        if (!isNull(validationError)) {
          throw validationError;
        }

        logger.log('----------------------------------------');
        logger.log(`SAVING MEDIA ITEM ${mediaId || ''}...`);
        logger.info(
          `saveMediaItem: starting save for media item ${mediaId} for account ${accountAPIId}`,
        );
        logger.log('');
        logger.log(`Account API: ${accountAPIId}`);
        logger.log(`Current state: ${currentState}`);
        logger.log(`Target state: ${saveItem.state}`);
        logger.log(`Unshortened URL: ${saveItem.unshortenedShareURL}`);
        logger.log(`Message: ${saveItem.message}`);
        logger.log(`Title: ${saveItem.title}`);
        logger.log(`Description: ${saveItem.description}`);
        logger.log(`Images: ${saveItem.imageURLs}`);

        saveItem.accountAPIId = accountAPIId;

        return null;
      })

      // Step 3 - create or update media item

      .then(() => {
        const config = {
          accountAPIId,
          state: currentState,
          mediaItem: saveItem,
          errorOnDuplicate,
        };
        return postMedia(config);
      })
      .then(({ mediaId: newMediaId, failedMsg }) => {
        if (failedMsg != null) {
          reject(failedMsg);
          return null;
        }
        const originalId = mediaId;
        if (isDefined(newMediaId)) {
          mediaId = newMediaId;
        }
        logger.log(`Media ID: ${mediaId}`);
        if (mediaId === originalId) {
          logger.info(`saveMediaItem: save complete for media item ${mediaId}`);
        } else {
          logger.info(
            `saveMediaItem: save complete, media item ${originalId} is now ${mediaId}`,
          );
        }
        logger.log('- - - - - - - - - - - - - - - - - - - - ');

        // Nothing to do if we are deleting the item or don't need to return the saved item
        if (
          saveItem.state === MEDIA_ITEM_STATES.DELETED ||
          returnSavedItem === false
        ) {
          return null;
        }
        // Otherwise read back the updated media item
        const state = mediaId.startsWith('ac_')
          ? MEDIA_ITEM_STATES.SHARED
          : targetState;
        return getMediaItem({
          accountAPIId,
          apiTypeId,
          state,
          mediaId,
          getPageInfo: true,
        });
      })
      .then((mediaItemResponse) => {
        let savedMediaItem;
        // Nothing to do if we are deleting the item or don't need to return the saved item
        if (
          saveItem.state === MEDIA_ITEM_STATES.DELETED ||
          returnSavedItem === false
        ) {
          savedMediaItem = null;
          // Otherwise display / return the saved media item
        } else {
          savedMediaItem = mediaItemResponse;
          logger.log(JSON.stringify(savedMediaItem, null, 2));
          savedMediaItem = MediaItem.initialise(savedMediaItem);
        }
        logger.log(`SAVE COMPLETE FOR MEDIA ITEM ${mediaId}`);
        logger.log('----------------------------------------');
        resolve(savedMediaItem);
      })
      .catch((error) => {
        if (error instanceof Error || error instanceof ErrorEvent) {
          logger.error({
            event: 'Caught Error',
            properties: {
              location: 'process/saveMediaItem',
              arguments: {
                accountAPIId,
                mediaItem,
                targetState,
                returnSavedItem,
              },
            },
            error,
          });
        }
        if (error?.response) {
          reject({
            status: error.response.status,
            error: error.response.data.error,
          });
        } else {
          reject(error);
        }
      });
  });
}

/**
 * Validate media item
 */

function validateMediaItem(
  mediaItem,
  apiTypeId,
  postType,
  shareTime,
  postImageNeedsToBeUpdated,
  socialChannel,
) {
  if (mediaItem.state === MEDIA_ITEM_STATES.DELETED) {
    return null;
  }

  const isABVariation = isDefined(mediaItem.abTestVariations);
  const now = getUnixTimestamp();

  if (shareTime.type === SHARE_TIME_TYPES.TIMESLOT) {
    if (shareTime.max < shareTime.min) {
      return ebxMessage(
        MEDIA_ITEM_VALIDATION_ERRORS.END_SHARE_TIME_AFTER_START_SHARE_TIME,
      );
    }

    // Ensure that the end time is in the future
    if (shareTime.max < now) {
      return MEDIA_ITEM_VALIDATION_ERRORS.END_SHARE_TIME_IN_FUTURE;
    }

    if (
      isABVariation &&
      shareTime.min < now + AB_TEST_MINIMUM_TIME_LENGTH_IN_SECS
    ) {
      return ebxMessage(
        MEDIA_ITEM_VALIDATION_ERRORS.AB_START_SHARE_TIME_AFTER_LIMIT,
      );
    }
  }

  if (shareTime.type === SHARE_TIME_TYPES.TIME) {
    if (shareTime.time < now) {
      return ebxMessage(
        MEDIA_ITEM_VALIDATION_ERRORS.START_SHARE_TIME_IN_FUTURE,
      );
    }

    if (
      isABVariation &&
      shareTime.time < now + AB_TEST_MINIMUM_TIME_LENGTH_IN_SECS
    ) {
      return ebxMessage(
        MEDIA_ITEM_VALIDATION_ERRORS.AB_START_SHARE_TIME_AFTER_LIMIT,
      );
    }
  }

  const messageSettings = getMessageSettings({ apiTypeId });
  if (!isNull(messageSettings.maximumLength)) {
    if (!isABVariation) {
      if (
        !isNullOrUndefined(mediaItem.message) &&
        getCharacterCount(mediaItem.message, { apiTypeId }) >
          messageSettings.maximumLength
      ) {
        return ebxMessage(
          MEDIA_ITEM_VALIDATION_ERRORS.MAX_LENGTH_SHARE_MESSAGE,
        );
      }
    } else {
      for (let idx = 0; idx < mediaItem.abTestVariations.length; idx += 1) {
        const variation = mediaItem.abTestVariations[idx];
        if (
          !isNull(variation.message) &&
          getCharacterCount(variation.message, { apiTypeId }) >
            messageSettings.maximumLength
        ) {
          return ebxMessage(
            MEDIA_ITEM_VALIDATION_ERRORS.MAX_LENGTH_SHARE_MESSAGE_AB.replace(
              '[idx]',
              idx + 1,
            ),
          );
        }
      }
    }
  }
  if (!isNullOrUndefined(messageSettings.minimumLength)) {
    if (
      !isNullOrUndefined(mediaItem.message) &&
      getCharacterCount(mediaItem.message, { apiTypeId }) <
        messageSettings.minimumLength
    ) {
      return MEDIA_ITEM_VALIDATION_ERRORS.MIN_LENGTH_SHARE_MESSAGE;
    }
  }

  // Validate first comment
  const firstCommentSettings = getFirstCommentSettings({ apiTypeId });
  if (firstCommentSettings?.maximumLength != null) {
    if (
      !isNullOrUndefined(mediaItem.firstComment) &&
      getCharacterCount(mediaItem.firstComment, { apiTypeId }) >
        firstCommentSettings.maximumLength
    ) {
      return MEDIA_ITEM_VALIDATION_ERRORS.MAX_LENGTH_FIRST_COMMENT;
    }
  }

  if (isDefined(mediaItem.sponsor) && !isNull(mediaItem.sponsor)) {
    if (!isABVariation) {
      if (
        isDefined(mediaItem.message) &&
        (isNull(mediaItem.message) || mediaItem.message.trim() === '')
      ) {
        return ebxMessage(MEDIA_ITEM_VALIDATION_ERRORS.SPONSORED_POST_MESSAGE);
      }
    } else {
      for (let idx = 0; idx < mediaItem.abTestVariations.length; idx += 1) {
        const variation = mediaItem.abTestVariations[idx];
        if (isNull(variation.message) || variation.message.trim() === '') {
          return ebxMessage(
            MEDIA_ITEM_VALIDATION_ERRORS.SPONSORED_POST_MESSAGE_AB.replace(
              '[idx]',
              idx + 1,
            ),
          );
        }
      }
    }
  }

  const anyPostImageNeedsToBeUpdated = isNullOrUndefined(
    postImageNeedsToBeUpdated,
  )
    ? false
    : Object.values(postImageNeedsToBeUpdated).indexOf(true) > -1;
  if (anyPostImageNeedsToBeUpdated) {
    return ebxMessage(MEDIA_ITEM_VALIDATION_ERRORS.SAVE_CANCEL_IMAGE_EDITS);
  }

  if (postType === POST_TYPES.LINK) {
    const maximumUrlByteLength = getMaximumUrlByteLength({ apiTypeId });
    const maximumUrlLength = getMaximumUrlLength({ apiTypeId });
    if (
      (!isNull(maximumUrlByteLength) &&
        getLengthInBytes(mediaItem.shareURL) > maximumUrlByteLength) ||
      (!isNull(maximumUrlLength) &&
        mediaItem.shareURL.length > maximumUrlLength)
    ) {
      return ebxMessage(MEDIA_ITEM_VALIDATION_ERRORS.MAX_LINK_URL_LENGTH);
    }
  }

  if (
    postType === POST_TYPES.STATUS ||
    postType === POST_TYPES.VIDEO ||
    postType === POST_TYPES.PHOTO_STORY
  ) {
    let hasContent = false;
    if (!isABVariation) {
      // Status/photo posts must have a message and/or at least one image
      if (
        postType === POST_TYPES.STATUS &&
        !isNullOrUndefined(mediaItem.message) &&
        mediaItem.message.trim() !== ''
      ) {
        hasContent = true;
      }
      if (
        postType === POST_TYPES.STATUS &&
        !isNullOrUndefined(mediaItem.imageURLs) &&
        mediaItem.imageURLs.length > 0
      ) {
        hasContent = true;
      }
      // Video posts must have a video
      if (
        postType === POST_TYPES.VIDEO &&
        isDefined(mediaItem.videoURL) &&
        !isNull(mediaItem.videoURL) &&
        mediaItem.videoURL !== ''
      ) {
        hasContent = true;
      }
      // Photo story posts must have images
      if (
        postType === POST_TYPES.PHOTO_STORY &&
        Array.isArray(mediaItem.imageURLs) &&
        mediaItem.imageURLs.length > 0
      ) {
        hasContent = true;
      }
    } else {
      for (let idx = 0; idx < mediaItem.abTestVariations.length; idx += 1) {
        const variation = mediaItem.abTestVariations[idx];
        if (!isNull(variation.message) && variation.message.trim() !== '') {
          hasContent = true;
        }
        if (postType === POST_TYPES.STATUS && variation.imageURLs.length > 0) {
          hasContent = true;
        } else if (
          postType === POST_TYPES.VIDEO &&
          isDefined(variation.videoURL) &&
          !isNull(variation.videoURL) &&
          variation.videoURL !== ''
        ) {
          hasContent = true;
        }
      }
    }
    if (!hasContent) {
      if (postType === POST_TYPES.STATUS) {
        if (isMessageFieldRequired({ apiTypeId })) {
          if (!isABVariation) {
            return ebxMessage(
              MEDIA_ITEM_VALIDATION_ERRORS.POST_INCLUDES_MESSAGE_OR_IMAGE,
            );
          }
          return ebxMessage(
            MEDIA_ITEM_VALIDATION_ERRORS.POST_INCLUDES_MESSAGE_AND_IMAGE,
          );
        }
        return ebxMessage(MEDIA_ITEM_VALIDATION_ERRORS.POST_INCLUDES_IMAGE);
      }
      if (postType === POST_TYPES.PHOTO_STORY) {
        return ebxMessage(MEDIA_ITEM_VALIDATION_ERRORS.POST_INCLUDES_IMAGE);
      }
      return (
        getValidationMessages({
          apiTypeId,
          validationType: 'videoMissing',
          socialChannel,
        }) ?? MEDIA_ITEM_VALIDATION_ERRORS.VIDEO_POSTS_INCLUDE_VIDEO
      );
    }
  }

  if (
    hasTitleField({ apiTypeId, postType }) &&
    isTitleFieldRequired({ apiTypeId }) &&
    !mediaItem.title
  ) {
    return MEDIA_ITEM_VALIDATION_ERRORS.POST_INCLUDES_TITLE;
  }

  return null;
}
