import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';

import {
  ACCOUNT_SETTING_TYPES,
  COUNTRIES,
  REACT_PREVENT_RENDER,
} from 'common/constants';
import * as logger from 'common/logger';
import { saveAPIsSettings } from 'common/settings';
import {
  cloneObject,
  isEmpty,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import BaseComponent from 'components/BaseComponent';
import DropDownAdd from 'components/misc/DropDownAdd';
import KeywordList from 'components/settings/KeywordList';
import SaveChangesButtons from 'components/settings/SaveChangesButtons';
import withGlobalInfo from 'context/withGlobalInfo';
import {
  onSaveFailure,
  onSaveSuccess,
  setPanelButtonText,
} from 'helpers/settingsPage';

/*
 * Settings - Audience Restriction
 */

class AudienceRestriction extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    const audienceRestriction = cloneObject(props.audienceRestriction);
    if (isEmpty(audienceRestriction.dataJSON)) {
      audienceRestriction.dataJSON.targetingParams = {};
    }
    if (isUndefined(audienceRestriction.dataJSON.targetingParams)) {
      audienceRestriction.dataJSON.targetingParams = { countries: [] };
    }
    if (isUndefined(audienceRestriction.dataJSON.targetingParams.countries)) {
      audienceRestriction.dataJSON.targetingParams.countries = [];
    }
    this.state = {
      data: Immutable({
        countries: AudienceRestriction.getCountries(audienceRestriction),
        isChanged: false,
        isSaving: false,
      }),
    };
    this._bind(
      'handleCancel',
      'handleCountryAdd',
      'handleCountryAddRemove',
      'handleCountryRemove',
      'handleSave',
    );
    this.originalSettings = audienceRestriction;
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    setPanelButtonText('collapseAR');
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare('AudienceRestriction', { nextProps, nextState });
  }

  /**
   * Helper methods
   */

  static getCountries(setting) {
    if (isNullOrUndefined(setting.dataJSON)) {
      return [];
    }
    if (isNullOrUndefined(setting.dataJSON.targetingParams)) {
      return [];
    }
    if (isNullOrUndefined(setting.dataJSON.targetingParams.countries)) {
      return [];
    }
    return setting.dataJSON.targetingParams.countries;
  }

  static getCountryCode(countryName) {
    return Object.keys(COUNTRIES).find(
      (country) => COUNTRIES[country] === countryName,
    );
  }

  /**
   * Event handlers
   */

  handleCancel() {
    this.setState(
      (prevState) => ({
        data: prevState.data
          .set(
            'countries',
            AudienceRestriction.getCountries(this.originalSettings),
          )
          .set('isChanged', false),
      }),
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'audienceRestriction',
          hasSettingChanged: false,
        }),
    );
  }

  handleCountryAdd(event) {
    const countryCode = event.target.value;
    this.handleCountryAddRemove({ countryCode, isAdding: true });
  }

  handleCountryAddRemove({
    countryCode = mandatory('countryCode'),
    isAdding = mandatory('isAdding'),
  } = {}) {
    if (isAdding) {
      logger.info(`AudienceRestriction:handleCountryAdd - ${countryCode}`);
    } else {
      logger.info(`AudienceRestriction:handleCountryRemove - ${countryCode}`);
    }
    this.setState(
      (prevState) => {
        const countryList = Immutable.asMutable(prevState.data.countries);
        if (isAdding) {
          const findCountry = countryList.indexOf(countryCode);
          if (findCountry === -1) {
            countryList.push(countryCode);
          }
        } else {
          const findCountry = countryList.indexOf(countryCode);
          countryList.splice(findCountry, 1);
        }
        return {
          data: prevState.data
            .set('countries', Immutable(countryList))
            .set('isChanged', true),
        };
      },
      () =>
        this.props.eventHandlers.handleHasSettingChanged({
          key: 'audienceRestriction',
          hasSettingChanged: true,
        }),
    );
  }

  handleCountryRemove(keyword) {
    return () => {
      const countryName = keyword;
      const countryCode = AudienceRestriction.getCountryCode(countryName);
      this.handleCountryAddRemove({ countryCode, isAdding: false });
    };
  }

  handleSave() {
    this.setState(
      (prevState) => ({
        data: prevState.data.set('isSaving', true).set('isChanged', false),
      }),
      () => {
        // Start with original settings
        const settingConfig = {
          accountAPIId: this.props.accountAPIId,
          settingTypeId: ACCOUNT_SETTING_TYPES.DEFAULT_FACEBOOK_TARGETING,
          enabled: this.originalSettings.enabled,
          dataJSON: this.originalSettings.dataJSON,
        };
        // Add in any targeting parameters managed by this component
        settingConfig.dataJSON.targetingParams.countries =
          this.state.data.countries;
        // Save changes
        saveAPIsSettings.call(this, {
          settingConfig,
          callback: () => {
            this.originalSettings.enabled = settingConfig.enabled;
            this.originalSettings.dataJSON = settingConfig.dataJSON;
            this.props.eventHandlers.handleHasSettingChanged({
              key: 'audienceRestriction',
              hasSettingChanged: false,
            });
            onSaveSuccess.call(this);
          },
          errorHandler: () => {
            onSaveFailure.call(this);
          },
        });
      },
    );
  }

  /**
   * Render methods
   */

  renderSelectedCountries() {
    const countries = this.state.data.countries.map(
      (country) => COUNTRIES[country],
    );
    return (
      <KeywordList
        keywordList={countries}
        onKeywordRemove={this.handleCountryRemove}
      />
    );
  }

  render() {
    if (!this.originalSettings.enabled || this.props.global.isLoading()) {
      return REACT_PREVENT_RENDER;
    }

    const savingClass = this.state.data.isSaving ? 'saving_locked' : '';

    return (
      <>
        <div className="setting-part d-flex flex-row border-top">
          <div className="mr-auto d-flex flex-column">
            <div className="text-700 ft-14 lh-20">Audience Restriction</div>
            <div className="text-400 ft-12 mr-2">
              Restrict Facebook posts on this page to specific audiences
            </div>
          </div>
          {this.state.data.isChanged && (
            <span className="align-self-center mr-2 op-60">
              Unsaved Changes
            </span>
          )}
          <div
            className="btn btn-light align-self-center"
            data-cy-id="editAudienceRestriction"
            data-toggle="collapse"
            data-target="#collapseAR"
            role="button"
            aria-expanded="false"
            aria-controls="collapseAR"
          >
            Edit
          </div>
        </div>
        <div
          className="collapse settings-sub settings-audience-restriction"
          id="collapseAR"
          data-parent="#accordion"
          data-cy-id="audienceRestrictionSettings"
        >
          <div className="pt-4 pb-3 border-top">
            <p className="mb-1">Locations</p>
            <DropDownAdd
              name="country"
              dataCySelect="locations"
              sources={COUNTRIES}
              handleDropDownChange={this.handleCountryAdd}
              disabled={this.state.data.isSaving}
              className={savingClass}
            />
            <div className="mt-2 d-flex">{this.renderSelectedCountries()}</div>
            <SaveChangesButtons
              disabled={!this.state.data.isChanged || this.state.data.isSaving}
              eventHandlers={{
                handleCancel: this.handleCancel,
                handleSave: this.handleSave,
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

AudienceRestriction.propTypes = {
  accountAPIId: PropTypes.string.isRequired,
  audienceRestriction: PropTypes.object.isRequired,
  eventHandlers: PropTypes.shape({
    handleHasSettingChanged: PropTypes.func,
  }),
};

AudienceRestriction.defaultProps = {
  eventHandlers: {
    handleHasSettingChanged: () => {},
  },
};

export default withGlobalInfo(AudienceRestriction);
