/* eslint no-use-before-define: "off" */

import { getUnixTimestamp } from 'common/datetime';
import { mandatory } from 'common/validation';

export {
  createTimestamp,
  extractDateParts,
  getDate,
  getDatePlus,
  getTime,
  getTomorrow,
  isNotDateControl,
  toLocalString,
};

/**
 * createTimestamp
 *
 * @param {{
 *  date: string | null;
 *  time: string | null;
 * }} date - date in YYYY-MM-DD format
 *    time - time in HH:MM format
 * @return - Unix timestamp of date constructed
 */

function createTimestamp({
  date = mandatory('date'),
  time = mandatory('time'),
} = {}) {
  try {
    const iso = `${date}T${time}:00.000`;
    const { YYYY, MM, DD, hh, mm } = extractDateParts(iso);
    return getUnixTimestamp(new Date(YYYY, MM, DD, hh, mm));
  } catch (e) {
    return null;
  }
}

/**
 * extractDateParts - extract year, month, date, hours, minutes
 *                    from date in ISO 8601 formatt date
 */

function extractDateParts(iso) {
  try {
    const date = getDate(iso);
    const YYYY = parseInt(date.split('-')[0], 10);
    const MM = parseInt(date.split('-')[1], 10) - 1;
    const DD = parseInt(date.split('-')[2], 10);
    const time = getTime(iso);
    const hh = parseInt(time.split(':')[0], 10);
    const mm = parseInt(time.split(':')[1], 10);
    return { YYYY, MM, DD, hh, mm };
  } catch (e) {
    return null;
  }
}

/**
 * getDate - extract date part of ISO 8601 format date
 */

function getDate(arg) {
  if (typeof arg === 'string') {
    const result = arg.replace(
      /([0-9]{4}-[0-9]{2}-[0-9]{2})T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z?/gi,
      '$1',
    );
    if (arg === result) {
      return null;
    }
    return result;
  }
  if (typeof arg === 'number') {
    return getDate(new Date(arg * 1000));
  }
  return null;
}

/**
 * getDatePlus - takes a Unix timestamp and returns an ISO 8601 format string
 *               representation of the same time in the specified number of days,
 *               taking into account possible DST changes that might occur between the two
 */

function getDatePlus(start, days) {
  if (start === undefined) mandatory('start');
  const plus = new Date(start * 1000);
  plus.setDate(plus.getDate() + days);
  return plus.getTime() / 1000;
}

/**
 * getTime - extract time part of ISO 8601 format string
 */

function getTime(arg) {
  if (typeof arg === 'string') {
    const result = arg.replace(
      /[0-9]{4}-[0-9]{2}-[0-9]{2}T([0-9]{2}:[0-9]{2}):[0-9]{2}.[0-9]{3}Z?/gi,
      '$1',
    );
    if (arg === result) {
      return null;
    }
    return result;
  }
  if (typeof arg === 'number') {
    return getTime(new Date(arg * 1000));
  }
  return null;
}

/**
 * getTomorrow - takes a Unix timestamp and returns an ISO 8601 format string
 *               representation of the same time tomorrow, taking into account
 *               possible DST changes that might occur between the two
 * @param {number} today
 */

function getTomorrow(today = mandatory('today')) {
  return getDatePlus(today, 1);
}

/**
 * isNotDateControl - returns true if the class name of the element clicked on
 *                    is part of the date-time picker control that isn't a date
 */

function isNotDateControl(classNames) {
  return (
    classNames.indexOf('rw-calendar') !== -1 ||
    classNames.indexOf('rw-calendar-btn-left') !== -1 ||
    classNames.indexOf('rw-calendar-btn-right') !== -1 ||
    classNames.indexOf('rw-calendar-btn-view') !== -1 ||
    classNames.indexOf('rw-calendar-header') !== -1 ||
    classNames.indexOf('rw-cell-not-allowed') !== -1 ||
    classNames.indexOf('rw-cell-off-range') !== -1 ||
    classNames.indexOf('rw-head-cell') !== -1 ||
    classNames.indexOf('rw-i-chevron-left') !== -1 ||
    classNames.indexOf('rw-i-chevron-right') !== -1
  );
}

/**
 * pad
 */

function pad(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

/**
 * toLocalString - create ISO 8601 format string representing supplied date
 *                 where the result will pretend that the local timezone is UTC
 * @param {date} date - date object or Unix timestamp
 * @return {string} - ISO 8601 representation of the supplied date
 *
 * e.g. in: Fri Jul 27 2018 00:00:00 GMT+0100 (British Summer Time)
 *      out: 2018-07-27T00:00:00.000Z
 *
 * Note how in the above example the result pretends that the local timezone
 * is UTC and ignores the actual timezone entirely
 */

function toLocalString(date = mandatory('date')) {
  let source;
  if (typeof date === 'number') {
    source = new Date(date * 1000);
  } else {
    source = date;
  }
  const YYYY = source.getFullYear();
  const MM = pad(source.getMonth() + 1);
  const DD = pad(source.getDate());
  const hh = pad(source.getHours());
  const mm = pad(source.getMinutes());
  return `${YYYY}-${MM}-${DD}T${hh}:${mm}:00.000Z`;
}
