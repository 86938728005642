import * as React from 'react';

interface LinkBlockProps {
  children: React.ReactNode;
  decoratedText: string;
}

/**
 * Although these are anchor links DraftJS disables them when the editor is in
 * 'readOnly' mode, this is so that only complete links are decorated visually.
 */
const LinkBlock = ({ children, decoratedText }: LinkBlockProps) => (
  <a href={decoratedText} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default LinkBlock;
