import { Box, Button, Text } from '@ebx-ui/ebx-ui-component-library-sdk';

import { getAccountAPI, getAPITypeId } from 'common/accountAPIs';
import { getURNName } from 'common/social';
import { convertToSocialPageURN } from 'common/urn';

interface CopyLinkProps {
  accountAPIId: number;
}

const CopyLink = ({ accountAPIId }: CopyLinkProps) => {
  const apiTypeId = getAPITypeId({
    accountAPIId,
  });
  const socialPageURN = convertToSocialPageURN(
    getURNName({ apiTypeId }),
    accountAPIId,
  );

  const accountAPI = getAccountAPI({ accountAPIId });

  const link = `https://echobox.bio/${socialPageURN}/${accountAPI?.apiPostName}`;

  return (
    <Box pt={5} mb={9} borderTop="1px" borderColor="#e3e8ee">
      <Text
        mb={2}
        fontSize="13px"
        lineHeight="15px"
        color="gray.900"
        fontWeight="semibold"
      >
        Your Link
      </Text>
      <Text
        mb={4}
        fontWeight="normal"
        color="#3c4257"
        fontSize="13px"
        lineHeight="15px"
      >
        Copy and paste this URL into your Instagram Bio
      </Text>
      <Box borderRadius="md" boxShadow="border" display="inline-flex">
        <Box
          px="3"
          py="5px"
          color="primary.600"
          lineHeight="5"
          fontWeight="semibold"
          onClick={(e) => {
            // Select all text when clicked
            const range = document.createRange();
            range.selectNode(e.currentTarget);
            window.getSelection()?.removeAllRanges();
            window.getSelection()?.addRange(range);
          }}
        >
          {link}
        </Box>
        <Button
          filter="none"
          h="auto"
          fontWeight="medium"
          fontSize="xs"
          px={3}
          py="5px"
          borderLeftRadius="0"
          variant="secondary"
          onClick={() => {
            navigator.clipboard.writeText(link);
          }}
        >
          Copy
        </Button>
      </Box>
    </Box>
  );
};

export default CopyLink;
