const Camera = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.69565 1.71429V0H2.82609V1.71429H4.52174V2.85714H2.82609V4.57143H1.69565V2.85714H0V1.71429H1.69565ZM3.3913 5.14286V3.42857H5.08696V1.71429H9.04348L10.0778 2.85714H11.8696C12.4913 2.85714 13 3.37143 13 4V10.8571C13 11.4857 12.4913 12 11.8696 12H2.82609C2.20435 12 1.69565 11.4857 1.69565 10.8571V5.14286H3.3913ZM7.34783 10.2857C8.90783 10.2857 10.1739 9.00571 10.1739 7.42857C10.1739 5.85143 8.90783 4.57143 7.34783 4.57143C5.78783 4.57143 4.52174 5.85143 4.52174 7.42857C4.52174 9.00571 5.78783 10.2857 7.34783 10.2857ZM5.53913 7.42857C5.53913 8.44 6.34739 9.25714 7.34783 9.25714C8.34826 9.25714 9.15652 8.44 9.15652 7.42857C9.15652 6.41714 8.34826 5.6 7.34783 5.6C6.34739 5.6 5.53913 6.41714 5.53913 7.42857Z"
      fill="white"
    />
  </svg>
);

export default Camera;
