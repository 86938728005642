/* eslint global-require:"off" */

/**
 * GET /version - Get version from package.json
 *
 * @return {string} - Version number
 */

import { z } from 'zod';

import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import { API_TIMEOUTS } from 'api/settings';
import { location } from 'common/window';
import { lyingParse } from 'common/zod';

const ResponseSchema = z
  .object({
    version: z.string(),
  })
  .describe('getVersion.ResponseSchema');

export default async function getVersion() {
  const dev = location.hostname === 'localhost';
  if (dev) {
    return APP_VERSION;
  }
  const url = `${location.protocol}//${location.host}/package.json`;
  const config: AxiosRequestConfig = {
    url,
    method: 'GET',
    timeout: API_TIMEOUTS.XXS,
  };

  const response = await axios(config);
  const { version } = lyingParse(ResponseSchema, response.data);
  return version;
}
