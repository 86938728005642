import * as Sentry from '@sentry/react';
import * as React from 'react';

import { NON_API_ERROR_TYPES } from 'common/constants';
import { getGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { isEmptyOrNullOrUndefined } from 'common/utility';

const DefaultFallback = () => {
  return (
    <div className="error_notification notification mb-3 w-100 pr-3">
      An unexpected error occurred.
    </div>
  );
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ComponentPropsWithoutRef<
    typeof Sentry.ErrorBoundary
  >['fallback'];
  logglyProperties?: Record<string, unknown>;
  showMessageOnError?: boolean;
}

const ErrorBoundary = ({
  children,
  fallback,
  logglyProperties = {},
  showMessageOnError = true,
}: ErrorBoundaryProps) => {
  const version = APP_VERSION;

  const beforeCapture = (scope: Sentry.Scope) => {
    const globalInfo = getGlobalInfo();
    if (!isEmptyOrNullOrUndefined(globalInfo)) {
      scope.setUser({
        email: globalInfo.user.emailAddress,
        id: globalInfo.user.userId,
        username: globalInfo.user.username,
      });
      scope.setTags({
        propertyId: globalInfo.current.propertyId,
        accountAPIId: globalInfo.current.accountAPIId,
      });
    }
    scope.setTag('version', version);
  };

  const onError = (error: Error) => {
    logger.error({
      event: 'Unhandled Error caught by ErrorBoundary',
      error: { type: NON_API_ERROR_TYPES.NON_API_GENERAL, error },
      properties: logglyProperties,
    });
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      onError={onError}
      {...(showMessageOnError && { fallback: fallback ?? <DefaultFallback /> })}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
