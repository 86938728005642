import { Grid, Heading, Input } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';
import { Property } from 'types';

interface PropertyNameSettingGridItemProps {
  setSelectedPropertyName: React.Dispatch<React.SetStateAction<string>>;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  isPropertyNameChanged: React.Dispatch<React.SetStateAction<boolean>>;
  property: Property;
}

const PropertyNameSettingGridItem = ({
  setSelectedPropertyName,
  setChangesMade,
  property,
  isPropertyNameChanged,
}: PropertyNameSettingGridItemProps) => {
  const [value, setValue] = useState(property.propertyName);

  return (
    <Grid.GridItem>
      <Heading variant="h6" color="gray.600" mb={1}>
        Property Name:
      </Heading>
      <Input
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          setSelectedPropertyName(e.target.value);
          setChangesMade(true);
          isPropertyNameChanged(true);
        }}
      />
    </Grid.GridItem>
  );
};

export default PropertyNameSettingGridItem;
