import {
  Button,
  Heading,
  Image,
  Link,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import { openZendeskContactForm } from 'common/zendesk';

const HoldingScreen = () => (
  <>
    <Heading>Well done for setting up your Echobox!</Heading>
    <Text size="sm" color="gray.600" mt={2}>
      We&apos;ve started analyzing your historical datasets to tailor our
      algorithms to your audience. Your custom-built Echobox will be ready soon.
      You will hear from us shortly.
    </Text>
    <div className="mt-4">
      <Button variant="secondary" onClick={openZendeskContactForm}>
        Contact us
      </Button>
      <Link
        href="https://www.echobox.com/resources/white-papers/"
        color="gray.900"
        ml={7}
        _hover={{
          textDecoration: 'underline',
          color: 'primary.700',
        }}
      >
        Read our white papers
      </Link>
    </div>
    <Image mx="auto" mt="85px" display="block" src="/img/holding.png" alt="" />
  </>
);

export default HoldingScreen;
