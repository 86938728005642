import {
  Box,
  CloseIcon,
  Heading,
  HeadingProps,
  HStack,
  IconButton,
  Progress,
  Tooltip,
  useToken,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useMemo } from 'react';

import { useImageContext } from 'context/ComposeBoxImageContext';

const headingProps: HeadingProps = {
  variant: 'h5',
  color: 'gray.900',
};

/**
 * Renders placeholder image for image uploads
 * Displays a progress bar using uploadProgressPercent from context
 */
const ImageLoad = () => {
  const {
    uploadProgressPercent = 0,
    imageProps: { style },
    onCancel,
    placeholderPxHeight,
  } = useImageContext();

  const [primary600] = useToken('colors', ['primary.600']);

  const uploadProgressInteger = Math.trunc(uploadProgressPercent ?? 0);
  return useMemo(() => {
    return (
      <Box
        data-cy-id="shareImage"
        style={style}
        height={placeholderPxHeight}
        bg="gray.100"
      >
        <VStack
          height="full"
          justifyContent="center"
          w="168px"
          margin="auto"
          gap="2"
        >
          <HStack w="full" justifyContent="space-between">
            <HStack>
              {onCancel && uploadProgressInteger !== 100 && (
                <Tooltip label="Cancel upload" placement="top-start">
                  <IconButton
                    size="sm"
                    aria-label="Cancel upload"
                    icon={<CloseIcon color="gray.600" />}
                    onClick={onCancel}
                  />
                </Tooltip>
              )}
              <Heading {...headingProps}>Uploading...</Heading>
            </HStack>
            <Heading {...headingProps}>{uploadProgressInteger}%</Heading>
          </HStack>
          <Progress
            size="xs"
            background="gray.300"
            borderRadius="3px"
            sx={{
              div: {
                backgroundColor: primary600,
              },
            }}
            value={uploadProgressInteger}
            w="full"
          />
        </VStack>
      </Box>
    );
  }, [placeholderPxHeight, onCancel, primary600, style, uploadProgressInteger]);
};

export default ImageLoad;
