import type { ChangeEventHandler } from 'react';
import { useState } from 'react';

import {
  getCurrentPropertyId,
  getFilteredPropertiesAndPages,
} from 'common/accountAPIs';
import * as MediaItem from 'common/mediaItem';
import SocialPagesExpandCollapse from 'components/compose/socialpages/SocialPagesExpandCollapse';
import SocialPagesProperty from 'components/compose/socialpages/SocialPagesProperty';
import SocialPagesSearch from 'components/compose/socialpages/SocialPagesSearch';
import { composeBoxShareToAPIs } from 'helpers/composeBox';
import type { PostPreview } from 'types';

interface SocialPagesDropdownProps {
  onSearchChange: ChangeEventHandler<HTMLInputElement>;
  onSelectAll: (propertyId: number, apiTypeId: number) => () => void;
  onSocialPagesChange: ChangeEventHandler<HTMLInputElement>;
  postPreviews: Array<PostPreview>;
  searchText: string;
}

const SocialPagesDropdown = ({
  onSearchChange,
  onSelectAll,
  onSocialPagesChange,
  postPreviews,
  searchText,
}: SocialPagesDropdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandCollapse = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const text = event.target.value;
    if (text.length > 0 && !isExpanded) {
      setIsExpanded(true);
    }
    onSearchChange(event);
  };

  const checkedAPIs = composeBoxShareToAPIs(postPreviews);
  const firstMediaItem = postPreviews[0].mediaItem;
  const mediaStates = MediaItem.getMediaStatesByAccountAPIId({
    mediaItem: firstMediaItem,
  });
  const postType = MediaItem.getPostType({ mediaItem: firstMediaItem });

  const currentPropertyId = Number(getCurrentPropertyId());
  const availablePropertiesAndPages = getFilteredPropertiesAndPages({
    postType,
  });
  const filteredPropertiesAndPages = getFilteredPropertiesAndPages({
    postType,
    searchText,
  });

  let pagesForCurrentProperty = 0;
  let pagesForOtherProperties = 0;
  filteredPropertiesAndPages.forEach((property) => {
    if (property.propertyId === currentPropertyId) {
      pagesForCurrentProperty += property.accountAPIs.length;
    } else {
      pagesForOtherProperties += property.accountAPIs.length;
    }
  });

  const showCurrentProperty = pagesForCurrentProperty > 0;
  const showOtherProperties =
    availablePropertiesAndPages.length > 1 && pagesForOtherProperties > 0;

  return (
    <div
      className="dropdown-menu checkbox dropdown-page-selector"
      data-cy-id="socialPagesMenu"
      style={{ width: '100%', maxHeight: '500px', overflow: 'auto' }}
    >
      <SocialPagesSearch
        onSearchChange={handleSearchChange}
        searchText={searchText}
      />
      {showCurrentProperty && (
        <SocialPagesProperty
          checkedAPIs={checkedAPIs}
          mediaStates={mediaStates}
          onSelectAll={onSelectAll}
          onSocialPagesChange={onSocialPagesChange}
          postType={postType}
          propertyId={currentPropertyId}
          searchText={searchText}
          showName={false}
        />
      )}
      {showOtherProperties && (
        <SocialPagesExpandCollapse
          isExpanded={isExpanded}
          onExpandCollapse={handleExpandCollapse}
        />
      )}
      {isExpanded &&
        showOtherProperties &&
        availablePropertiesAndPages.map(
          (property) =>
            property.propertyId !== currentPropertyId && (
              <SocialPagesProperty
                checkedAPIs={checkedAPIs}
                mediaStates={mediaStates}
                onSelectAll={onSelectAll}
                onSocialPagesChange={onSocialPagesChange}
                postType={postType}
                propertyId={property.propertyId}
                searchText={searchText}
                showName={true}
                key={property.propertyId}
              />
            ),
        )}
    </div>
  );
};

export default SocialPagesDropdown;
