import { Box, VideoNewIcon } from '@ebx-ui/ebx-ui-component-library-sdk';

import { useInstantVideoTracking } from 'components/instantvideo/useInstantVideoTracking';
import Button from 'components/misc/Button';

interface GenerateVideoButtonProps {
  articleURL: string;
  onClick: () => void;
}

const GenerateVideoButton = ({
  articleURL,
  onClick,
}: GenerateVideoButtonProps) => {
  const { trackGenerateVideo } = useInstantVideoTracking();

  const handleClick = () => {
    trackGenerateVideo({ articleURL });
    onClick();
  };

  return (
    <Box
      as={Button}
      aria-label="Generate Video"
      mr="2.5"
      display={{ base: 'none', md: 'flex' }}
      pt="7px"
      pb="6.5px"
      onClick={handleClick}
    >
      <VideoNewIcon color="gray.600" />
    </Box>
  );
};

export default GenerateVideoButton;
