import {
  ArrowDownloadIcon,
  Button,
  ButtonGroup,
  ChevronDownIcon,
  Dropdown,
} from '@ebx-ui/ebx-ui-component-library-sdk';

interface ScheduleButtonProps {
  label: string;
  isDisabled?: boolean;
  onScheduledClick: () => void;
  onDownloadClick: () => void;
}
const ScheduleButton = ({
  label,
  isDisabled,
  onScheduledClick,
  onDownloadClick,
}: ScheduleButtonProps) => {
  return (
    <ButtonGroup isAttached>
      <Button isDisabled={isDisabled} onClick={onScheduledClick}>
        {label}
      </Button>
      <Dropdown size="md" placement="bottom-end">
        <Dropdown.Button
          as={Button}
          isDisabled={isDisabled}
          aria-label="More options"
        >
          <ChevronDownIcon />
        </Dropdown.Button>
        <Dropdown.List>
          <Dropdown.Item LeftIcon={ArrowDownloadIcon} onClick={onDownloadClick}>
            Download video
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>
    </ButtonGroup>
  );
};

export default ScheduleButton;
