import mergeImages from 'merge-images';

import { TARGET_HEIGTH, TARGET_WIDTH } from 'common/constants';
import { convertEchoboxImageToAmazon } from 'common/s3';
import { mandatory } from 'common/validation';
import { getImageDimensions, resizeDataURL, urlToBase64 } from 'helpers/images';

async function addImageOverlay({
  imageURL,
  overlayURL,
  targetWidth = TARGET_WIDTH,
  targetHeight = TARGET_HEIGTH,
}: {
  imageURL: string;
  overlayURL: string;
  targetWidth?: number;
  targetHeight?: number;
}) {
  if (imageURL === undefined) mandatory('imageURL');
  if (overlayURL === undefined) mandatory('overlayURL');

  // Asserting URLs are defined as they have been validated above.
  const b64Image = await urlToBase64({
    url: convertEchoboxImageToAmazon(imageURL)!,
  });
  let b64Overlay = await urlToBase64({
    url: convertEchoboxImageToAmazon(overlayURL)!,
  });
  const { width, height } = await getImageDimensions({ file: b64Image });
  const rescaleRatio = targetWidth / width;
  const rescaledHeight = height * rescaleRatio;
  const croppedHeight =
    rescaledHeight > targetHeight
      ? (rescaledHeight - targetHeight) / rescaleRatio
      : 0;
  const { width: overlayWidth, height: overlayHeight } =
    await getImageDimensions({
      file: b64Overlay,
    });
  // Resize overlay image to the width of shared image, but keeping the aspect ratio
  const resizeOverlayToHeight = Math.min(
    (width * overlayHeight) / overlayWidth,
    height,
  );
  b64Overlay = await resizeDataURL({
    file: b64Overlay,
    targetWidth: width,
    targetHeight: resizeOverlayToHeight,
  });
  // Align the overlay image with bottom-left corner
  // on the bottom-left corner of the shared image
  const offsetY = height - croppedHeight / 2 - resizeOverlayToHeight;
  return mergeImages(
    [
      {
        src: b64Image,
        x: 0,
        y: 0,
      },
      {
        src: b64Overlay,
        x: 0,
        y: Math.max(offsetY, 0),
      },
    ],
    {
      crossOrigin: 'anonymous',
    },
  );
}

export { addImageOverlay };
