import { AxiosRequestConfig } from 'axios';

import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseBenchmarksAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { handleAPIError } from 'common/errorHandling';

/**
 * POST /properties/provision
 * - provision a benchmark property for social property if it doesn't exist
 * @return {Object}
 */

export default async function postProvisionBenchmarkProperty() {
  const config: AxiosRequestConfig = {
    url: `${constructBaseBenchmarksAPIURL()}/properties/provision`,
    method: 'POST',
    timeout: API_TIMEOUTS.S,
    headers: getClientServiceRequestHeaders({
      'Content-Type': 'application/json',
    }),
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/postProvisionBenchmarkProperty',
    });
    throw apiError;
  }
}
