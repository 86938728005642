import clsx from 'clsx';
import type { MouseEvent } from 'react';

interface SocialPagesExpandProps {
  isExpanded: boolean;
  onExpandCollapse: (event: MouseEvent<HTMLDivElement>) => void;
}

const SocialPagesExpand = ({
  isExpanded,
  onExpandCollapse,
}: SocialPagesExpandProps) => {
  const arrowClass = isExpanded ? 'dropdown-down-arrow' : 'dropdown-left-arrow';

  return (
    <div className="dropdown-action">
      <div
        className={clsx('dropdown-item d-flex align-items-center', arrowClass)}
        onClick={onExpandCollapse}
      >
        See other properties
      </div>
    </div>
  );
};

export default SocialPagesExpand;
