/* eslint no-param-reassign:"off" */
/* eslint no-use-before-define:"off" */

import { ACCOUNT_SETTING_TYPES } from 'common/constants';
import { isDefined, isNull, isUndefined } from 'common/utility';

export { fixGlobalInfo, getGlobalInfo, storeGlobalInfo };

/**
 * Fix missing shareDataSource entry in autopilot settings
 */

function fixGlobalInfo(globalInfo) {
  const fixedInfo = globalInfo;
  // For each property...
  Object.keys(fixedInfo.properties).forEach((propertyId) => {
    if (isDefined(fixedInfo.properties[propertyId].accountAPIs)) {
      Object.keys(fixedInfo.properties[propertyId].accountAPIs).forEach(
        (accountAPIId) => {
          // For each account's autofeed settings...
          if (
            isDefined(
              fixedInfo.properties[propertyId].accountAPIs[accountAPIId]
                .accountSettings,
            ) &&
            isDefined(
              fixedInfo.properties[propertyId].accountAPIs[accountAPIId]
                .accountSettings[ACCOUNT_SETTING_TYPES.AUTO_FEED],
            )
          ) {
            const autofeedSettings =
              fixedInfo.properties[propertyId].accountAPIs[accountAPIId]
                .accountSettings[ACCOUNT_SETTING_TYPES.AUTO_FEED];
            // For each setting entry (currently only one but it may be possible for there to be more)
            autofeedSettings.forEach((autofeedSetting) => {
              if (isUndefined(autofeedSetting.dataJSON.shareDataSource)) {
                // console.log(`shareDataSource missing for
                //   property ${propertyId} account ${accountAPIId}`);
                autofeedSetting.dataJSON.shareDataSource = {};
              }
            });
          }
        },
      );
    }
  });
  return fixedInfo;
}

/**
 * Retrieve global info from global scope (if it exists) or from local storage
 *  (Abstracted in case we want to use a different storage mechanism in future)
 * @return {import('types').FixTypeLater} globalInfo{ properties, current, user }
 */

function getGlobalInfo() {
  if (window && window.globalInfo) {
    return window.globalInfo;
  }
  const globalInfo = sessionStorage.getItem('globalInfo');
  if (
    isDefined(globalInfo) &&
    !isNull(globalInfo) &&
    globalInfo !== 'undefined'
  ) {
    return JSON.parse(globalInfo);
  }
  return null;
}

function storeGlobalInfo(globalInfo) {
  const fixedGlobalInfo = fixGlobalInfo(globalInfo);
  if (window) {
    window.globalInfo = fixedGlobalInfo;
    window.sessionStorage.setItem(
      'globalInfo',
      JSON.stringify(fixedGlobalInfo),
    );
  }
}
