import PropTypes from 'prop-types';

import Button from 'components/misc/Button';
import Domain from 'components/settings/property/domainswitching/Domain';

const Domains = (props) => {
  const { domainsToSwitch, isSaving, rootURL, eventHandlers } = props;

  const savingClass = isSaving ? 'saving_locked' : '';

  // Solution to https://echobox.atlassian.net/browse/SL-9040 to address
  // regular expressions appearing in the Domain Switching config:
  const removeRegexFromDomain = (domain) => {
    if (domain.startsWith('^(') && domain.endsWith(')')) {
      return domain.match(/\^\(\(www\\.\)\?(.+?)\)/)[1];
    }
    return domain;
  };

  return (
    <div className="border rounded-sm mb-3">
      <div className="py-2 px-3 bg-gray-100">
        <div className="d-flex align-items-center">
          <div>
            <div className="text-500 mb-2">
              Domains configured to automatically switch
            </div>
            <div>
              The domains below will be changed to{' '}
              <span className="underline">{rootURL}</span>
            </div>
          </div>
          <div className="ml-auto">
            <Button
              className={savingClass}
              onClick={eventHandlers.handleAddClick}
            >
              Add domain
            </Button>
          </div>
        </div>
      </div>
      {domainsToSwitch.map((domain) => (
        <Domain
          domain={removeRegexFromDomain(domain)}
          isSaving={isSaving}
          eventHandlers={{
            handleRemoveDomain: eventHandlers.handleRemoveDomain,
          }}
          key={domain}
        />
      ))}
    </div>
  );
};

Domains.propTypes = {
  domainsToSwitch: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSaving: PropTypes.bool.isRequired,
  rootURL: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleAddClick: PropTypes.func.isRequired,
    handleRemoveDomain: PropTypes.func.isRequired,
  }).isRequired,
};

export default Domains;
