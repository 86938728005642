import { z } from 'zod';

import {
  ACCOUNT_SETTING_TYPES,
  ACCOUNT_SETTING_TYPES_NAMES,
  API_TYPES,
  CONTENT_TYPE_IDS,
  HEALTH_MENU_ERROR_TYPES,
  PROPERTY_STATES,
  TEXT_DIRECTIONS,
  TRAFFIC_API_STATES,
} from 'common/constants';
import { FEATURE_TOGGLES } from './constants/settings';

export const MentionSchema = z
  .object({
    likes: z.number().nullable(), // null for LinkedIn
    link: z.string().optional(), // undefined for Twitter
    name: z.string().nullable(), // Sometimes null for TikTok
    pageId: z.string().optional(), // undefined for TikTok
    profileImage: z.string().nullable(), // null for LinkedIn
    updatedTime: z.number(),
    username: z.string().nullable(), // Sometimes null for Facebook
    verified: z.boolean(),
  })
  .describe('MentionSchema');

export const SponsorPageInfoSchema = z
  .object({
    checkins: z.number(),
    coverPhoto: z.string().optional(),
    isVerified: z.boolean(),
    likes: z.number(),
    name: z.string(),
    pageId: z.string(),
    pageURL: z.string(),
    profilePhoto: z.string(),
  })
  .describe('SponsorPageInfoSchema');

export const TagSchema = z
  .object({
    raw: z.string(),
    clean: z.string(),
    more: z.string(),
  })
  .describe('TagSchema');

export const IndividualHTTPEchoResponseSchema = z
  .object({
    userAgent: z.string(),
    responseBody: z.string().optional(),
    responseTimeMS: z.number().optional(),
    responseCode: z.number().optional(),
    responseHeaders: z.record(z.string(), z.string()).optional(),
    errorReason: z.string().optional(),
  })
  .describe('IndividualHTTPEchoResponseSchema');

export const HTTPEchoResponseSchema = z
  .object({ responses: z.array(IndividualHTTPEchoResponseSchema) })
  .describe('HTTPEchoResponseSchema');

const textDirectionValues = Object.values(TEXT_DIRECTIONS);
export const TextDirectionSchema = z
  .enum([textDirectionValues[0], ...textDirectionValues.slice(1)])
  .describe('TextDirectionSchema');

export const PropertyStateSchema = z
  .nativeEnum(PROPERTY_STATES)
  .describe('PropertyStateSchema');

export const PropertyBillingSchema = z
  .object({
    shutOffUnixTime: z.number(),
    invoices: z.string(),
  })
  .describe('PropertyBillingSchema');

export const GetPropertiesResponseDataSchema = z
  .object({
    propertyURN: z.string(),
    propertyName: z.string(),
    propertyRootURL: z.string(),
    propertyState: PropertyStateSchema,
    propertyType: z.enum([
      'SOCIAL_TEST',
      'SOCIAL',
      'NEWSLETTER',
      'SHARECRAFT',
      'NEWSLETTER_TEST',
      'PAYWALL',
      'PAYWALL_TEST',
      'BENCHMARK',
      'BENCHMARK_TEST',
    ]),
    propertyBilling: PropertyBillingSchema.optional(),
    orderForms: PropertyBillingSchema.optional(),
    companyDetails: z
      .object({
        companyName: z.string(),
        addressLine1: z.string().optional(),
        addressLine2: z.string().optional(),
        addressLine3: z.string().optional(),
        city: z.string().optional(),
        postcode: z.string().optional(),
        country: z.string().optional(),
        phoneNumber: z.string().optional(),
      })
      .optional(),
    crmIdentifier: z.string().optional(),
    language: z.string(),
    timezone: z.string(),
    timeCreated: z.number(),
    approxStartTime: z.number(),
    approxEndTime: z.number(),
  })
  .describe('GetPropertiesResponseDataSchema');

export const ErrorWithStatusSchema = z
  .object({ status: z.number() })
  .describe('ErrorWithStatusSchema');

export const ErrorWithMessageSchema = z
  .object({
    error: z.object({
      message: z.string(),
    }),
  })
  .describe('ErrorWithMessageSchema');

export const SeenNotificationsSchema = z
  .string()
  .transform((raw) => JSON.parse(raw))
  .pipe(
    z.record(
      z.object({
        [HEALTH_MENU_ERROR_TYPES.DISCONNECTIONS]: z
          .record(z.string())
          .optional(),
        [HEALTH_MENU_ERROR_TYPES.TWITTER_APP_DISCONNECTION]: z
          .boolean()
          .optional(),
        [HEALTH_MENU_ERROR_TYPES.CONTENT_FEED_ERROR]: z
          .string()
          .or(z.boolean())
          .optional(),
      }),
    ),
  )
  .catch({})
  .describe('SeenNotificationsSchema');

export const SyndfeedTypeSchema = z
  .enum([
    'RSS_NORMAL',
    'RSS_VALID_PUBLISH_TIME',
    'RSS_OVERRIDE_PUBLISH_TIME',
    'RSS_OVERRIDE_PUBLISH_TIME_WITHIN_24_HOURS',
    'SITEMAP_VALID_PUBLISH_TIME',
    'SITEMAP_OVERRIDE_PUBLISH_TIME',
    'SITEMAP_OVERRIDE_PUBLISH_TIME_WITHIN_24_HOURS',
  ])
  .describe('SyndfeedTypeSchema');

export const RssFeedDataSchema = z
  .object({
    syndFeedURN: z.string(),
    syndFeedURL: z.string(),
    syndFeedTitle: z.string().optional().nullable(),
    syndFeedType: SyndfeedTypeSchema,
  })
  .describe('RssFeedDataSchema');

export const HighPriorityErrorTypeSchema = z
  .enum([
    'OFFLINE',
    'ACCOUNT_SUSPENDED',
    'ACCOUNT_WILL_BE_SUSPENDED',
    'GOOGLE_ANALYTICS_WITH_NO_TRACKER',
    'NO_TRACKER_WITH_NO_GOOGLE_ANALYTICS',
  ])
  .describe('HighPriorityErrorTypeSchema');

export const AccountSettingTypesNamesSchema = z
  .nativeEnum(ACCOUNT_SETTING_TYPES_NAMES)
  .describe('AccountSettingTypesNamesSchema');

export const AccountSettingTypesSchema = z
  .nativeEnum(ACCOUNT_SETTING_TYPES)
  .describe('AccountSettingTypesSchema');

export const ContentTypeIdSchema = z
  .union([z.nativeEnum(CONTENT_TYPE_IDS), z.null()])
  .describe('ContentTypeIdSchema');

export const ShareContentSchema = z
  .object({
    contentId: z.string().or(z.null()),
    contentTypeId: ContentTypeIdSchema,
    sequence: z.number(),
    unixTimeUpdated: z.number(),
    value: z.string().or(z.null()),
  })
  .describe('ShareContentSchema');

export const SocialAPIResponseSchema = z
  .object({
    apiAltName: z.string().optional(),
    apiPostImage: z.string().optional(),
    apiPostName: z.string().optional(),
    apiState: z.nativeEnum(TRAFFIC_API_STATES),
    apiType: z.enum([API_TYPES[0], ...API_TYPES.slice(1)]),
    createdUnixTime: z.number(),
    disconnectReason: z.string().optional(),
    isSetupInProgress: z.boolean(),
    isSharingPaused: z.boolean(),
    isVerified: z.boolean().optional(),
    lastShareTime: z.number().optional(),
    numberFollowers: z.number().optional(),
    propertyURN: z.string(),
    remainingTwitterXRateLimitResetSeconds: z.number().optional(),
    requiresReconnect: z.boolean(),
    socialAPIURN: z.string(),
    urlPlaceholderDefault: z.string().optional(),
    useSwipeUpLinks: z.boolean().optional(),
    utcOffsetSecs: z.number(),
  })
  .describe('SocialAPIResponseSchema');

export const FeatureToggleSettingsSchema = z
  .object({
    [FEATURE_TOGGLES.DISABLE_IG_ECHOBOX_TIME]: z.boolean(),
    [FEATURE_TOGGLES.IG_CABINET_ENABLED]: z.boolean(),
    [FEATURE_TOGGLES.LINKINBIO_ENABLED]: z.boolean(),
    [FEATURE_TOGGLES.OPEN_AI_MESSAGE_ENABLED]: z.boolean(),
    [FEATURE_TOGGLES.REQUIRES_EBX_TRACKER]: z.boolean(),
    [FEATURE_TOGGLES.SHARENOW_INSTANT_ENABLED]: z.boolean(),
    [FEATURE_TOGGLES.USER_MANAGEMENT_EDITOR_ENABLED]: z.boolean(),
    [FEATURE_TOGGLES.TRACKER_PROVISIONED_MANUALLY]: z.boolean(),
  })
  // Adding .passthrough because we don't want to strip unrecognised keys.
  // Stripping unrecognised keys will result in those settings being removed
  // when we POST an updated object to the backend (e.g. with postPropertiesSettings)
  .passthrough()
  .partial()
  .describe('FeatureToggleSettingsSchema');

export const CustomShareTimeSettingsSchema = z
  .object({
    intervalSecs: z.string().optional(),
  })
  .describe('CustomShareTimeSettingsSchema');

export const liveStoryByDefaultSettingsSchema = z
  .boolean()
  .optional()
  .describe('liveStoryByDefaultSettingsSchema');

export const SyndFeedStateSchema = z
  .enum(['ACTIVE', 'DISABLED', 'ERROR', 'DELETED'])
  .describe('SyndFeedStateSchema');

export const SyndFeedUpdateHistoryIndividualSchema = z.object({
  updateTime: z.number(),
  updateSucceeded: z.boolean(),
  articlesSeen: z.number().optional(),
  feedTitle: z.string().optional(),
});

export const SyndFeedMetaDataSchema = z.object({
  title: z.string().optional(),
});

export const SyndFeedDataSchema = z.object({
  syndFeedURN: z.string(),
  syndFeedType: SyndfeedTypeSchema,
  syndFeedState: SyndFeedStateSchema,
  syndFeedURL: z.string(),
  lastArticleTime: z.number(),
  refreshSecs: z.number(),
  failureRate: z.number(),
  syndFeedTitle: z.string().optional(),
  updateHistory: z.array(SyndFeedUpdateHistoryIndividualSchema),
  metadata: SyndFeedMetaDataSchema,
  initialised: z.boolean(),
});
