import $ from 'jquery';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import { KEYCODES } from 'common/constants';
import { endOfToday, getUnixTimestamp } from 'common/datetime';
import {
  createTimestamp,
  getDate,
  isNotDateControl,
  toLocalString,
} from 'common/datetimepicker';
import * as logger from 'common/logger';
import { cloneObject, createEventHandler, isNull } from 'common/utility';
import DropdownFooter from 'components/home/archive/DropdownFooter';

const PERMITTED_DATE_RANGE_IN_SECONDS = 366 * 24 * 60 * 60;

const CustomRange = (props) => {
  const { fromTime, toTime, eventHandlers } = props;

  const [dateValue, setDateValue] = useState({ fromTime, toTime });
  const [isValid, setIsValid] = useState({ fromTime: true, toTime: true });
  const [isOpen, setIsOpen] = useState({ fromTime: false, toTime: false });
  const [errorMessage, setErrorMessage] = useState(null);

  const wasChanged = useRef(false);

  const calendarIcon = () => (
    <img src="/img/icons/ic-calendar.svg" alt="" className="op-60" />
  );

  const handleApply = () => {
    const validationErrors = validateDates();
    setErrorMessage(validationErrors);
    if (isNull(validationErrors)) {
      eventHandlers.handleApply(dateValue.fromTime, dateValue.toTime);
    }
  };

  const handleChange = (target, value) => {
    logger.info(`CustomRange:handleChange ${target} ${value}`);

    wasChanged.current = isOpen[target];

    if (isNull(value)) {
      setDateValue((prev) => {
        const next = cloneObject(prev);
        next[target] = false;
        return next;
      });
      setIsValid((prev) => {
        const next = cloneObject(prev);
        next[target] = false;
        return next;
      });
      return;
    }

    const local = toLocalString(value);

    setIsValid((prev) => {
      const next = cloneObject(prev);
      next[target] = true;
      return next;
    });
    switch (target) {
      case 'fromTime':
        setDateValue((prev) => {
          const next = cloneObject(prev);
          next[target] = createTimestamp({
            date: getDate(local),
            time: '00:00',
          });
          return next;
        });
        break;
      case 'toTime':
        setDateValue((prev) => {
          const next = cloneObject(prev);
          next[target] = createTimestamp({
            date: getDate(local),
            time: '23:59',
          });
          return next;
        });
        break;
      default:
    }
    setIsOpen((prev) => {
      const next = cloneObject(prev);
      next[target] = false;
      return next;
    });
  };

  const handleClear = () => {
    setDateValue({
      fromTime: props.fromTime,
      toTime: props.toTime,
    });
    setIsValid({ fromTime: true, toTime: true });
  };

  const handleClick = (target, event) => {
    logger.info(`CustomRange:handleClick ${target}`);

    // Ignore clicks on month selector controls
    const classNames = event.target.className.split(' ');
    if (isNotDateControl(classNames)) {
      return;
    }

    if (!wasChanged.current) {
      setIsOpen((prev) => {
        const next = cloneObject(prev);
        next[target] = !next[target];
        return next;
      });
    }
    wasChanged.current = false;
  };

  const handleKeyDown = (target, event) => {
    logger.info(`CustomRange:handleKeyDown ${target}`);

    if (
      !(
        (event.keyCode >= KEYCODES.ENTER && event.keyCode <= KEYCODES.INSERT) ||
        (event.keyCode >= KEYCODES.LEFT_WINDOW_KEY &&
          event.keyCode <= KEYCODES.SELECT_KEY) ||
        (event.keyCode >= KEYCODES.F1 && event.keyCode <= KEYCODES.SCROLL_LOCK)
      )
    ) {
      setIsOpen((prev) => {
        const next = cloneObject(prev);
        next[target] = false;
        return next;
      });
    } else if (event.keyCode === KEYCODES.ENTER) {
      if (typeof $ !== 'undefined' && $(':focus')[0].tagName === 'INPUT') {
        $(':focus').blur();
      }
    }
  };

  const handleBlur = () => setIsOpen({ fromTime: false, toTime: false });

  const handleFromChange = createEventHandler(handleChange, 'fromTime');
  const handleToChange = createEventHandler(handleChange, 'toTime');
  const handleFromClick = createEventHandler(handleClick, 'fromTime');
  const handleToClick = createEventHandler(handleClick, 'toTime');
  const handleFromKeyDown = createEventHandler(handleKeyDown, 'fromTime');
  const handleToKeyDown = createEventHandler(handleKeyDown, 'toTime');

  const validateDates = () => {
    if (!isValid.fromTime) {
      return 'Please enter a valid start date';
    }
    if (!isValid.toTime) {
      return 'Please enter a valid end date';
    }
    if (dateValue.fromTime > getUnixTimestamp()) {
      return 'Start date cannot be in the future';
    }
    if (dateValue.toTime > endOfToday()) {
      return 'End date cannot be in the future';
    }
    if (dateValue.fromTime > dateValue.toTime) {
      return 'End date must be after start date';
    }
    if (
      dateValue.toTime - dateValue.fromTime >=
      PERMITTED_DATE_RANGE_IN_SECONDS
    ) {
      return 'End date cannot be more than 365 days after start date';
    }
    return null;
  };

  const inputClass = (key) => {
    return isValid[key] ? '' : 'date-time-error';
  };

  return (
    <form>
      <a className="dropdown-item" onClick={eventHandlers.handleClose}>
        <svg
          width="5"
          height="8"
          viewBox="0 0 5 8"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: '5px', position: 'relative', top: '-1px' }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.65219 6.47719L3.15582 4.91995C3.15582 4.91995 2.62291 4.45331 2.62291 4C2.62291 3.54669 3.15442 3.08139 3.15442 3.08139L4.65219 1.52281C4.81886 1.35135 4.91072 1.12606 4.90916 0.892598C4.9076 0.659138 4.81273 0.434978 4.64378 0.265548C4.56303 0.182488 4.4652 0.116068 4.35636 0.0703883C4.24752 0.0247183 4.12998 0.000768194 4.01101 1.81305e-05C3.89204 -0.000731456 3.77418 0.0217381 3.66471 0.0660381C3.55524 0.110328 3.4565 0.175518 3.3746 0.257548L0.257039 3.37337C0.0890283 3.54257 -0.00303178 3.76734 0.000398588 4C-0.00266175 4.2322 0.0893683 4.45643 0.257039 4.6253L3.3746 7.74245C3.4565 7.82448 3.55524 7.88967 3.66471 7.93396C3.77418 7.97826 3.89204 8.00073 4.01101 7.99998C4.12998 7.99923 4.24752 7.97528 4.35636 7.92961C4.4652 7.88393 4.56303 7.81751 4.64378 7.73445C4.81273 7.56502 4.9076 7.34086 4.90916 7.1074C4.91072 6.87394 4.81886 6.64865 4.65219 6.47719Z"
          />
        </svg>
        Back
      </a>
      {!isNull(errorMessage) && (
        <div
          className="modal-body modal-error save-state save failed"
          style={{ fontSize: '13px', maxWidth: '300px' }}
          data-cy-id="errorMessage"
        >
          <img
            className="icon-error mr-1"
            src="/img/icons/ic-warning-red.svg"
            alt=""
          />
          {errorMessage}
        </div>
      )}
      <div
        className="dropdown-body d-flex align-items-center"
        style={{ maxWidth: '300px' }}
      >
        <DateTimePicker
          containerClassName={inputClass('fromTime')}
          date={true}
          dateIcon={calendarIcon()}
          editFormat="DD-MMM-YY"
          footer={true}
          format="DD-MMM-YY"
          name="from"
          data-cy-input="fromTime"
          onBlur={handleBlur}
          onChange={handleFromChange}
          onClick={handleFromClick}
          onKeyDown={handleFromKeyDown}
          onToggle={() => {}}
          open={isOpen.fromTime ? 'date' : false}
          time={false}
          value={new Date(dateValue.fromTime * 1000)}
          views={['month']}
        />
        <span className="mx-2 text-500">and</span>
        <DateTimePicker
          containerClassName={inputClass('toTime')}
          date={true}
          dateIcon={calendarIcon()}
          editFormat="DD-MMM-YY"
          footer={true}
          format="DD-MMM-YY"
          name="to"
          data-cy-input="toTime"
          onBlur={handleBlur}
          onChange={handleToChange}
          onClick={handleToClick}
          onKeyDown={handleToKeyDown}
          onToggle={() => {}}
          open={isOpen.toTime ? 'date' : false}
          time={false}
          value={new Date(dateValue.toTime * 1000)}
          views={['month']}
        />
      </div>
      <DropdownFooter
        clearLabel="Reset"
        eventHandlers={{
          handleApply,
          handleClear,
        }}
      />
    </form>
  );
};

CustomRange.propTypes = {
  fromTime: PropTypes.number.isRequired,
  toTime: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    handleApply: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  }).isRequired,
};

export default CustomRange;
