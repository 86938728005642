import { REACT_PREVENT_RENDER } from 'common/constants';
import * as logger from 'common/logger';
import EditableText from 'components/compose/EditableText';

interface DescriptionProps {
  guid: string;
  accountAPIId: number;
  canCustomiseLinkPosts: boolean;
  description: string;
  isRefreshPreview: boolean;
  isVideoLink: boolean;
  hasImageOverlay?: boolean;
  showDescription: boolean;
  onDescriptionChange: (newDescription: string) => void;
}

const Description = ({
  guid,
  accountAPIId,
  canCustomiseLinkPosts,
  description,
  isRefreshPreview,
  isVideoLink,
  hasImageOverlay = false,
  showDescription,
  onDescriptionChange,
}: DescriptionProps) => {
  if (!showDescription) {
    return REACT_PREVENT_RENDER;
  }

  logger.info(`Description:render ${guid} ${description}`);

  return (
    <EditableText
      accountAPIId={accountAPIId}
      canCustomiseLinkPosts={canCustomiseLinkPosts}
      className="share_description"
      isRefreshPreview={isRefreshPreview}
      isVideoLink={isVideoLink}
      hasImageOverlay={hasImageOverlay}
      messageText={description}
      placeholder="Enter a description..."
      dataCyInput="description"
      classOnBlur="text-truncate"
      onChange={onDescriptionChange}
    />
  );
};

export default Description;
