import { AxiosError, isAxiosError } from 'axios';
import { z } from 'zod';

import * as authentication from 'common/authentication';
import * as logger from 'common/logger';
import {
  COMMAND_FLASH_MESSAGES_ADD_MESSAGE,
  COMMAND_FLASH_MESSAGES_DELETE_MESSAGES,
} from 'pubsub/topics';
import {
  API_ERROR_MESSAGES,
  API_ERROR_TYPES,
  API_RESPONSE_IMPERSONATION_ERROR_MESSAGES,
  COGNITO_ERROR_MESSAGES,
  FLASH_MESSAGE_TYPES,
  NON_API_ERROR_MESSAGES,
  NON_API_ERROR_TYPES,
} from './constants';
import { addNotification } from './notifications';
import { stringifyKeys } from './object';
import { captureSentryError } from './sentry';
import { errorGuid } from './string';
import { cloneError, isEmpty, isRunningTests } from './utility';

function isCognitoError(error: unknown) {
  const Schema = z.object({
    code: z.unknown(),
    message: z.unknown(),
    name: z.unknown(),
  });

  const errorResult = Schema.safeParse(error);

  if (errorResult.success) {
    const { code, message, name } = errorResult.data;
    return code != null && message != null && name != null;
  }
  return false;
}

function isNetworkError(error: unknown) {
  const Schema = z.object({
    message: z.string(),
  });

  const errorResult = Schema.safeParse(error);

  if (errorResult.success) {
    const { message } = errorResult.data;
    return (
      message === API_ERROR_MESSAGES.API_TIMEOUT ||
      message === 'Network Error' ||
      message.includes(API_ERROR_MESSAGES.API_HTTP)
    );
  }
  return false;
}

function handleJSError(originalError: unknown) {
  const Schema = z.object({
    message: z.string(),
    type: z.string(),
    origin: z.string(),
  });

  const errorResult = Schema.partial().safeParse(originalError);

  if (errorResult.success) {
    if (
      errorResult.data.message === undefined ||
      (!errorResult.data.message.includes('Symbol.iterator') &&
        !errorResult.data.message.includes('EBX:INTERNAL'))
    ) {
      const guid = errorGuid();

      // This method is asynchronous, but we don't need to wait for it to complete
      captureSentryError(cloneError(errorResult.data), guid);

      // Capture categorised error
      if (errorResult.data.message) {
        errorResult.data.origin = errorResult.data.message;
      }

      errorResult.data.message = `Code: N-${guid}. ${NON_API_ERROR_MESSAGES.NON_API_GENERAL}`;
      errorResult.data.type = NON_API_ERROR_TYPES.NON_API_GENERAL;

      logger.error({
        event: 'Unhandled JavaScript Error',
        error: errorResult.data,
      });

      return errorResult.data;
    }
  }

  return originalError;
}

/**
 * Process an unknown error object and return a suitable error object.
 * If the error is a network error, API error, or cognito error, it will be returned as is.
 * If the error is a JavaScript error, it will be logged and a generic error message/code will be returned.
 */
export function determineError(error: unknown) {
  // Return API or cognito errors without further processing
  if (
    isNetworkError(error) ||
    getErrorStatus(error) !== null ||
    isCognitoError(error)
  ) {
    return error;
  }

  return handleJSError(error);
}

/**
 * Retrieve any suitable error message from an unknown object.
 * We are using zod to safely parse the error object so we can query it.
 * @param error The unknown object to extract an error message from.
 * @returns an error message string
 */
export function getErrorMessage(error: unknown): string {
  if (typeof error === 'string') {
    return error;
  }

  const Schema = z.object({
    response: z.object({
      data: z.object({
        error: z.object({
          message: z.string(),
        }),
      }),
    }),
    error: z.string().or(
      z.object({
        message: z.string(),
      }),
    ),
    message: z.string(),
  });

  const errorResult = Schema
    // Deep partial is converts all properties within the nested object to optional
    .deepPartial()
    .safeParse(error);
  if (errorResult.success) {
    const safeError = errorResult.data;
    if (safeError.response?.data?.error?.message) {
      return safeError.response.data.error.message;
    }
    if (safeError.error) {
      if (typeof safeError.error === 'string') {
        return safeError.error;
      }
      if (safeError.error.message) {
        return safeError.error.message;
      }
    }
    if (safeError.message) {
      return safeError.message;
    }
  }
  return '';
}

/**
 * Gets the status code from an error object.
 * @param error An unknown object that may contain a status code.
 * @returns A status code or null if not found.
 */
export function getErrorStatus(error: unknown): number | null {
  const Schema = z.object({
    response: z.object({
      status: z.number(),
    }),
    status: z.number(),
    code: z.string(),
  });

  const errorResult = Schema
    // Deep partial is converts all properties within the nested object to optional
    .deepPartial()
    .safeParse(error);

  if (errorResult.success) {
    const safeError = errorResult.data;
    if (safeError.status) {
      return safeError.status;
    }
    if (safeError.response?.status) {
      return safeError.response.status;
    }
    if (safeError.code === 'ECONNABORTED') {
      return 408;
    }
  }
  return null;
}

/**
 * Generic error handling for any errors that occur from an API.
 * This function will log the error and return a suitable error object.
 */
export async function handleAPIError({
  originalError,
  errorLocation,
  args,
  isSensitiveRequest = false,
}: {
  originalError: unknown;
  errorLocation: string;
  args?: Record<string, unknown>;
  isSensitiveRequest?: boolean;
}) {
  // If the error is not an axios error, process it as a JS error
  if (!isAxiosError(originalError)) {
    return determineError(originalError);
  }

  // Cast the error so we can modify properties
  const error: AxiosError & { origin?: string; type?: string } = originalError;

  // Ensure we log the original message
  error.origin = error.message;

  // Strip out information which is duplicated elsewhere
  if (error.config && error.response?.config) {
    delete error.config;
  }
  if (error.request && error.response?.request) {
    delete error.request;
  }

  // Axios may return config.data already stringified, so convert it back
  // to an object so that we can strip any sensitive data from it if necessary
  if (error.config?.data && typeof error.config.data === 'string') {
    error.config.data = JSON.parse(error.config.data.trim());
  }

  if (
    error.response?.config?.data &&
    typeof error.response.config.data === 'string'
  ) {
    error.response.config.data = JSON.parse(error.response.config.data.trim());
  }

  // Move keys in the __sentry_xhr__ structure up a level
  if (error.request && error.request.__sentry_xhr__) {
    error.request = { ...error.request, ...error.request.__sentry_xhr__ };
    delete error.request.__sentry_xhr__;
  }

  if (error.code === 'ECONNABORTED') {
    error.message = API_ERROR_MESSAGES.API_TIMEOUT;
    error.type = API_ERROR_TYPES.API_TIMEOUT;
  } else if (error.message === 'Network Error') {
    // Perhaps the user is offline, or this particular URL is blocked
    // We don't want to explicitly say we are "offline" as this is handled by the offline detection system.
    error.message = `Code: H-${errorGuid()}. ${API_ERROR_MESSAGES.API_HTTP}`;
    error.type = API_ERROR_TYPES.API_HTTP;
  } else if (error.response === undefined) {
    logger.error({
      event: 'Unidentified Error',
      properties: {
        OriginalError: originalError,
      },
    });
    if (error.request && error.request?.status_code === 0) {
      // XHR status code 0
      error.message = `Code: X-${errorGuid()}. ${API_ERROR_MESSAGES.API_XHR}`;
      error.type = API_ERROR_TYPES.API_XHR;
    } else {
      error.message = `Code: H-${errorGuid()}. ${API_ERROR_MESSAGES.API_HTTP}`;
      error.type = API_ERROR_TYPES.API_HTTP;
    }
  } else if (error.response.status != null) {
    if (
      error.response.status === 500 ||
      !isErrorResponseBody(error.response.data)
    ) {
      error.message = `Code: H-${errorGuid()}. ${API_ERROR_MESSAGES.API_HTTP}`;
    } else if (
      error.response.status === 403 &&
      typeof error.response.data.error === 'object' &&
      API_RESPONSE_IMPERSONATION_ERROR_MESSAGES.includes(
        error.response.data.error.message,
      )
    ) {
      addNotification(
        'Users that are impersonating cannot call this endpoint',
        'warning',
      );
      return {
        status: '',
        error: '',
      };
    } else {
      error.message =
        typeof error.response.data.error === 'object'
          ? error.response.data.error.message
          : error.response.data.error;
    }
    error.type = API_ERROR_TYPES.API_HTTP;
  }

  const status = getErrorStatus(error);

  // Ignore unauthorised errors received when on the login page (these could be caused
  // by API requests completing *after* the user has logged out) or when validating
  // a new email address, or when running Cypress tests where "background" requests
  // are prone to failing even though they don't affect the tests themselves
  const currentPath = window?.location?.pathname;
  if (status === 401 && (currentPath === '/login' || window.Cypress)) {
    return null;
  }

  // Forbidden errors occur when a user no longer has permissions on a property or api
  // In this case we reload the page, which in turn will refresh global info and, if
  // necessary, reset the current property and api
  if (
    status === 403 &&
    !isRunningTests() &&
    isErrorResponseBody(error.response?.data) &&
    typeof error.response.data.error === 'object' &&
    !API_RESPONSE_IMPERSONATION_ERROR_MESSAGES.includes(
      error.response.data.error.message,
    )
  ) {
    logger.error({
      event: '403 Error',
      properties: {
        OriginalError: originalError,
      },
    });
    // delete client service token so that on reload new token is fetched with updated permissions
    authentication.deleteClientServiceToken();

    let isStaffUser = false;
    try {
      isStaffUser = await authentication.isStaffUser();
    } catch {
      //
    }
    const isImpersonating = authentication.isImpersonating();
    if (!isStaffUser && !isImpersonating) {
      // Add message informing the user
      logger.info(
        `PubSub: publish ${COMMAND_FLASH_MESSAGES_ADD_MESSAGE} in common/errorHandling.handleAPIError`,
      );
      PubSub.publish(COMMAND_FLASH_MESSAGES_ADD_MESSAGE, {
        messageCategory: 'User permissions changed',
        type: FLASH_MESSAGE_TYPES.ERROR,
        text: `An administrator has changed your permissions. You have been redirected here
            and the selected action has not been performed.`,
      });
      // Reload page
      location.reload();
    }
  }

  // Stringify config data
  if (error.config && typeof error.config?.data === 'object') {
    error.config.data = JSON.stringify(error.config.data);
  }
  if (error.response && typeof error.response?.config?.data === 'object') {
    error.response.config.data = JSON.stringify(error.response.config.data);
  }

  logger.error({
    event: 'API Layer Error',
    properties: {
      ErrorLocation: errorLocation,
      Arguments: stringifyKeys(args), // Stringify and strip certain keys first
    },
    error: {
      ...stringifyKeys(error),

      // If this is a sensitive request, we should remove some of the request details
      // from the error object.
      // This is used primarily because credentials are sent in the URL as
      // query parameters. Ideally these should be POST requests.
      ...(isSensitiveRequest
        ? {
            response: {
              ...error.response,
              config: null,
              request: null,
            },
          }
        : {}),
    },
  });

  // Return the user to the login page if an unauthorised error is received
  // If an auth token still exists in their session this implies the session was terminated
  // on the server side due and therefore we should pass a parameter to the logout page
  if (typeof window !== 'undefined' && status === 401) {
    // Remove all flash messages on logout
    logger.info(
      `PubSub: publish ${COMMAND_FLASH_MESSAGES_DELETE_MESSAGES} in common/errorHandling.handleAPIError`,
    );
    PubSub.publish(COMMAND_FLASH_MESSAGES_DELETE_MESSAGES);
    // Display message if the user was previously logged in
    if (authentication.isLoggedIn()) {
      location.href = '/logout?timeout';
    } else {
      location.href = '/logout';
    }
  }

  if (!isEmpty(error.response)) {
    if (error.response.data) {
      if (status === 500) {
        return {
          status: error.response.status,
          error: error.message,
        };
      }
      if (isErrorResponseBody(error.response.data)) {
        return {
          status: error.response.status,
          error: error.response.data.error,
        };
      }
    }
    return {
      status: error.response.status,
      error: error.response,
    };
  }

  return error;
}

/**
 * Handle error from AWS Cognito.
 */
export function handleCognitoError({
  originalError,
  errorLocation,
  args,
}: {
  originalError: unknown;
  errorLocation: string;
  args?: Record<string, unknown>;
}) {
  const error = cloneError(originalError) as
    | null
    | undefined
    | Record<string, unknown>;

  if (error) {
    error.type = API_ERROR_TYPES.API_HTTP_COGNITO;

    if (args?.message) {
      error.message = args.message;
    }

    if (error.code && typeof error.code === 'string') {
      error.message = COGNITO_ERROR_MESSAGES[error.code] || error.message;
    }
  }

  logger.error({
    event: 'AWS Cognito Error',
    properties: {
      ErrorLocation: errorLocation,
      Arguments: stringifyKeys(args), // Stringify dataJSON entries first
    },
    error,
  });

  return error;
}

/**
 * Handle error from an external API.
 */
export function handleExternalAPIError({
  originalError,
  errorLocation,
  args,
}: {
  originalError: unknown;
  errorLocation: string;
  args: Record<string, unknown>;
}) {
  const error = cloneError(originalError) as
    | null
    | undefined
    | Record<string, unknown>;

  if (error) {
    error.type = API_ERROR_TYPES.API_HTTP_EXTERNAL;
  }

  logger.error({
    event: 'External Service Error',
    properties: {
      ErrorLocation: errorLocation,
      Arguments: stringifyKeys(args), // Stringify dataJSON entries first
    },
    error,
  });

  return error;
}

function isErrorResponseBody(
  body: unknown,
): body is { error: { message: string } | string } {
  const Schema = z.object({
    error: z
      .object({
        message: z.string(),
      })
      .or(z.string()),
  });

  return Schema.safeParse(body).success;
}
