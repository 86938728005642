const Photo = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '14px' }}
    className="mr-2"
  >
    <path d="M16 14.2223V1.77777C16 0.8 15.2 0 14.2223 0H1.77777C0.8 0 0 0.8 0 1.77777V14.2223C0 15.2 0.8 16 1.77777 16H14.2223C15.2 16 16 15.2 16 14.2223ZM4.88889 9.33333L7.11111 12.0089L10.2222 8L14.2223 13.3333H1.77777L4.88889 9.33333Z" />
  </svg>
);

export default Photo;
