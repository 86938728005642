import type { AxiosRequestConfig } from 'api/axios';
import axios from 'api/axios';
import {
  API_TIMEOUTS,
  constructBaseSocialAPIURL,
  getClientServiceRequestHeaders,
} from 'api/settings';
import { API_TYPE_IDS } from 'common/constants';
import { handleAPIError } from 'common/errorHandling';
import * as logger from 'common/logger';
import * as metrics from 'common/metrics';
import { isSocialPageURNAPIType } from 'common/urn';
import { isDefined, isNull } from 'common/utility';

interface InstagramParamsBase {
  connectType: 'LITE';
  username: string;
  password: string;
}

interface InstagramParamsWithMFA extends InstagramParamsBase {
  mfa1: string;
  mfa2: string;
  mfa3: string;
}

type InstagramParams = InstagramParamsBase | InstagramParamsWithMFA;

type InstagramParamsWithOptionalPassword = Omit<InstagramParams, 'password'> &
  Pick<Partial<InstagramParams>, 'password'>;

export default async function getAPIsConnectRequestUpdate({
  socialPageURN,
  uiCallbackURL,
  apiCallbackURL,
  timezone = null,
  instagramParams = null,
}: {
  socialPageURN: string;
  uiCallbackURL: string;
  apiCallbackURL: string;
  timezone?: string | null;
  instagramParams?: InstagramParams | null;
}) {
  const guid = metrics.start('getAPIsConnectRequestUpdate');

  const timeout = isSocialPageURNAPIType(socialPageURN, API_TYPE_IDS.INSTAGRAM)
    ? API_TIMEOUTS.LM
    : API_TIMEOUTS.S;

  // encode password by ourselves rather than let axios to do so
  const copiedInstagramParams: InstagramParamsWithOptionalPassword | null =
    instagramParams == null ? null : { ...instagramParams };
  let passwordQueryStr = '';
  if (
    !isNull(copiedInstagramParams) &&
    isDefined(copiedInstagramParams.password)
  ) {
    const password = encodeURIComponent(copiedInstagramParams.password);
    delete copiedInstagramParams.password;
    passwordQueryStr = `?password=${password}`;
  }

  const isTikTokAPI = isSocialPageURNAPIType(
    socialPageURN,
    API_TYPE_IDS.TIKTOK,
  );

  const constructForcedBaseSocialAPIURL = () => {
    if (isTikTokAPI) {
      return constructBaseSocialAPIURL(undefined, true);
    }

    return constructBaseSocialAPIURL();
  };

  const constructForcedAPICallbackURL = () => {
    try {
      if (isTikTokAPI && apiCallbackURL.includes('-stage')) {
        return apiCallbackURL.replace('-stage', '');
      }
    } catch (ex) {
      console.log(ex);
    }
    return apiCallbackURL;
  };

  const config: AxiosRequestConfig = {
    url: `${constructForcedBaseSocialAPIURL()}/social/api/${socialPageURN}/connect/request${passwordQueryStr}`,
    method: 'GET',
    timeout,
    headers: getClientServiceRequestHeaders(),
    params: {
      ...copiedInstagramParams,
      uiCallbackURL,
      apiCallbackURL: constructForcedAPICallbackURL(),
      timezone,
    },
    maxRedirects: 0,
  };

  try {
    const response = await axios(config);
    metrics.end('getAPIsConnectRequestUpdate', guid);
    logger.debug('getAPIsConnectRequestUpdate', { socialPageURN });
    return { location: response.headers.location };
  } catch (error) {
    metrics.fail('getAPIsConnectRequestUpdate', guid);
    const apiError = await handleAPIError({
      originalError: error,
      errorLocation: 'api/getAPIsConnectRequestUpdate',
      args: {
        socialPageURN,
        uiCallbackURL,
        apiCallbackURL,
        timezone,
      },
    });
    throw apiError;
  }
}
