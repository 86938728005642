import { Circle, PlayIcon } from '@ebx-ui/ebx-ui-component-library-sdk';

import { API_TYPE_IDS } from 'common/constants';
import { isIGCabinetEnabled } from 'common/settings';

interface AnalyticsVideoIconProps {
  apiTypeId: number;
}

const AnalyticsVideoIcon = ({ apiTypeId }: AnalyticsVideoIconProps) => {
  if (apiTypeId === API_TYPE_IDS.INSTAGRAM && !isIGCabinetEnabled()) {
    return (
      <Circle
        position="absolute"
        inset={0}
        bg="gray.300-60"
        size={6}
        margin="auto"
      >
        <PlayIcon color="gray.900" />
      </Circle>
    );
  }

  return (
    <img
      src="/img/icons/ic-play-w.svg"
      alt=""
      className="analytics-page-video"
    />
  );
};

export default AnalyticsVideoIcon;
