import {
  Button,
  CheckCircleIcon,
  Heading,
  HStack,
  Link,
  Modal,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { INSTAGRAM_INSTRUCTIONS_URL } from 'common/constants';

const bodyText = [
  {
    title: 'Instagram business account',
    subtitle: 'Switch your account type in Instagram Settings section',
  },
  {
    title: 'Facebook business page with admin access',
    subtitle:
      'If you are using Meta Business Suite, Manage Page permissions must be turned on',
  },
  {
    title: 'Connection between the Instagram account and Facebook page',
    subtitle:
      'Use Facebook on desktop or Instagram on mobile to create the connection',
  },
];

export interface IsInstagramAccountReadyModalProps {
  onClose: () => void;
  onReady: () => void;
}

const IsInstagramAccountReadyModal = ({
  onClose,
  onReady,
}: IsInstagramAccountReadyModalProps) => {
  const handleReady = () => {
    onReady();
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Is your Instagram account ready?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text color="gray.600" mb={4}>
          To connect an Instagram account to Echobox, ensure you have:
        </Text>
        <VStack spacing={4} alignItems="flex-start">
          {bodyText.map((item) => (
            <HStack key={item.title} spacing={2} alignItems="flex-start">
              <CheckCircleIcon color="gray.500" size={5} />
              <VStack spacing={1} alignItems="flex-start">
                <Heading variant="h4">{item.title}</Heading>
                <Text size="sm" color="gray.600">
                  {item.subtitle}
                </Text>
              </VStack>
            </HStack>
          ))}
        </VStack>
      </Modal.Body>
      <Modal.Footer>
        <Link mr="auto" isExternal href={INSTAGRAM_INSTRUCTIONS_URL}>
          Read our guide
        </Link>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleReady}>Ready</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IsInstagramAccountReadyModal;
