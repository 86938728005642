import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PubSub from 'pubsub-js';
import { memo, useEffect, useRef, useState } from 'react';
import Immutable from 'seamless-immutable';
import { z } from 'zod';

import getHashtagsAndMentions from 'api/getHashtagsAndMentions';
import {
  getAccountAPI,
  getAPITypeId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import * as Compose from 'common/compose';
import { CAN_CUSTOMISE_LINK_POSTS } from 'common/config';
import { API_TYPE_IDS, COMPOSE_BOX_TABS, POST_TYPES } from 'common/constants';
import { FEATURE_TOGGLES } from 'common/constants/settings';
import { FEATURE_FLAGS, isFeatureFlagEnabled } from 'common/featureFlags';
import * as MediaItem from 'common/mediaItem';
import { getFeatureToggle } from 'common/settings';
import { getShareURLPlaceholder, replaceShareURL } from 'common/shareURL';
import {
  getSocialNetworkMessageBoxPlaceholder,
  getURNName,
  hasVideoTitle,
} from 'common/social';
import { isVideoURL } from 'common/url';
import { convertToSocialPageURN } from 'common/urn';
import { arePropsEqual, isRunningTests } from 'common/utility';
import { lyingParse } from 'common/zod';
import DomainTitleDescription from 'components/compose/DomainTitleDescription';
import EditableText from 'components/compose/EditableText';
import Images from 'components/compose/Images';
import Labels from 'components/compose/Labels';
import MessageBox from 'components/compose/messagebox/MessageBox';
import OtherActions from 'components/compose/OtherActions';
import OtherSettings from 'components/compose/OtherSettings';
import PreviewError from 'components/compose/PreviewError';
import PreviewHeader from 'components/compose/PreviewHeader';
import TimingOptions from 'components/compose/TimingOptions';
import URL from 'components/compose/URL';
import AnimateOnChange from 'components/misc/AnimateOnChange';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import * as topics from 'pubsub/topics';
import { FixTypeLater, IGLinkStickerConfig, Tag } from 'types';
import PostTypeSwitcher from './PostTypeSwitcher';
import type { SharedPreviewProps } from './Previews';
import ShareType from './ShareType';

const { MESSAGE_MEDIA_ITEM_ERROR } = topics;

const errorStatusCodeSchema = z
  .number()
  .nullable()
  .optional()
  .describe('Preview.errorStatusCodeSchema');

const handleArticleTagsLoad = async ({
  guid,
  itemTags,
}: {
  guid: string;
  itemTags: {
    hashtags: Tag[];
    mentions: Tag[];
  };
}) => {
  let mediaItem = await Compose.findComposeBoxItem({ guid });
  if (mediaItem === null) {
    return; // Do nothing if the media item no longer exists
  }
  mediaItem = Immutable(mediaItem);
  const existingHashtags = MediaItem.getHashtags({ mediaItem });
  const existingMentions = MediaItem.getMentions({ mediaItem });
  mediaItem = MediaItem.setHashtags({
    mediaItem,
    fieldValue: existingHashtags.concat(itemTags.hashtags),
  });
  mediaItem = MediaItem.setTrackingDetails({
    mediaItem,
    fieldValue: {
      hashtagsSuggested: itemTags.hashtags.map((tag) => tag.clean),
      mentionsSuggested: itemTags.mentions.map((tag) => tag.clean),
    },
  });
  mediaItem = MediaItem.setMentions({
    mediaItem,
    fieldValue: existingMentions.concat(itemTags.mentions),
  });
  await Compose.updateComposeBoxItem({ guid, mediaItem });
};

function getErrorMessage({
  accountAPIId,
  mediaItem,
}: {
  accountAPIId: number;
  mediaItem: FixTypeLater;
}) {
  const validationMessage = MediaItem.getValidationMessage({ mediaItem });
  if (validationMessage) {
    return validationMessage;
  }

  const mediaItemErrorMessage = MediaItem.getErrorMessage({ mediaItem });
  if (mediaItemErrorMessage) {
    return mediaItemErrorMessage;
  }

  const remainingTwitterXRateLimitResetSeconds = getAccountAPI({
    accountAPIId,
  })?.remainingTwitterXRateLimitResetSeconds;
  if (remainingTwitterXRateLimitResetSeconds) {
    return 'Twitter has limited your sharing volume. Please avoid sharing for at least 15 minutes.';
  }

  return null;
}

interface PreviewProps extends SharedPreviewProps {
  mediaId: string;
  mediaItem: FixTypeLater;
  accountAPIId: number;
  guid: string;
  allowDelete: boolean;
  containsSingleAccountAPIId: boolean;
}

const Preview = ({
  accountAPIId,
  allowDelete,
  containsSingleAccountAPIId,
  guid,
  mediaId,
  mediaItem,
  previewCollapsed,
  selectedTab,
  applySingleFieldUpdateForGuid,
  deleteComposeBoxItem,
}: PreviewProps) => {
  const [isLoadingTags, setIsLoadingTags] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [pendingStickerConfig, setPendingStickerConfig] =
    useState<IGLinkStickerConfig | null>(null);

  const currentABVariationIndex = useRef<number | null>(null);
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const propertyId = getCurrentPropertyId({ globalInfo });

  useEffect(() => {
    const loadTags = async () => {
      const socialPageURN = convertToSocialPageURN(
        getURNName({ apiTypeId }),
        accountAPIId,
      );

      try {
        const itemTags = await getHashtagsAndMentions({
          socialPageURN,
          mediaId,
          throwOnTimeout: false,
        });
        await handleArticleTagsLoad({ guid, itemTags });
      } catch (error) {
        if (!isRunningTests()) {
          console.log(error);
        }
        PubSub.publish(MESSAGE_MEDIA_ITEM_ERROR, {
          guid,
          error,
        });
      }
    };

    const apiTypeId = getAPITypeId({ accountAPIId });
    if (isLoadingTags) {
      setIsLoadingTags(false);
      if (!(mediaId === 'NEW' || mediaId === 'RESHARE')) {
        loadTags();
      }
    }
  }, [accountAPIId, guid, mediaId, mediaItem, isLoadingTags]);

  const apiTypeId = getAPITypeId({ accountAPIId });
  const shareURLReplacement = getShareURLPlaceholder({ mediaItem });
  const sponsorName = MediaItem.getSponsorName({ mediaItem });
  const sponsor = MediaItem.getSponsor({ mediaItem });
  const errorMessage = getErrorMessage({ accountAPIId, mediaItem });
  const errorDetails = MediaItem.getErrorDetails({ mediaItem });
  const unvalidatedStatusCode =
    typeof errorDetails === 'object' &&
    errorDetails != null &&
    'status' in errorDetails
      ? errorDetails.status
      : undefined;
  const statusCode = lyingParse(errorStatusCodeSchema, unvalidatedStatusCode);
  const isRefreshPreview = MediaItem.getIsRefreshPreview({ mediaItem });
  const postType = MediaItem.getPostType({ mediaItem });
  const previewFields = MediaItem.getPreviewFields({ mediaItem });
  const title =
    replaceShareURL({
      text: previewFields.title,
      shareURL: shareURLReplacement,
    }) || '';
  const description =
    replaceShareURL({
      text: previewFields.description,
      shareURL: shareURLReplacement,
    }) || '';
  const canCustomiseLinkPosts =
    previewFields.canCustomiseLinkPosts ?? CAN_CUSTOMISE_LINK_POSTS;
  const targetingParams = MediaItem.getFbTargetingParams({ mediaItem });
  const unshortenedShareURL = MediaItem.getUnshortenedShareURL({ mediaItem });
  const isApplyImageOverlay = MediaItem.getIsApplyImageOverlay({ mediaItem });
  const trackingDetails = MediaItem.getTrackingDetails({ mediaItem });
  const abInfo = MediaItem.getABInfo({ mediaItem });
  const hasImageOverlay =
    trackingDetails?.hasImageOverlay && abInfo.isABVariation;
  const socialChannel = MediaItem.getSocialChannel({ mediaItem });
  const hasInstantImageArticleUrl = !!MediaItem.getInstantImageArticleUrl({
    mediaItem,
  });
  const showShareTypeSwitcher =
    isFeatureFlagEnabled({ flag: FEATURE_FLAGS.POST_TYPE_SWITCHER }) &&
    getFeatureToggle({
      featureName: FEATURE_TOGGLES.MANUAL_IMAGE_FROM_ARTICLE_ENABLED,
      propertyId,
    }) &&
    (postType === POST_TYPES.LINK ||
      (postType === POST_TYPES.STATUS && hasInstantImageArticleUrl));

  // We want to detect when the ab variation has changed, so we can enable animation for this one particular render.
  // So we are keeping reference to the current variation index and updating it when we detect a new variation.
  const abVariationChanged =
    abInfo.isABVariation &&
    // The variation hasn't changed if the check box was previously disabled (null)
    currentABVariationIndex.current !== null &&
    abInfo.currentABVariationIndex !== currentABVariationIndex.current;
  if (abInfo.isABVariation) {
    currentABVariationIndex.current = abInfo.currentABVariationIndex;
  } else {
    currentABVariationIndex.current = null;
  }

  const articleImages = previewFields.imageURLs;

  const leftClass =
    selectedTab === COMPOSE_BOX_TABS.PREVIEW ? 'd-block' : 'd-none d-md-block';
  const rightClass =
    selectedTab === COMPOSE_BOX_TABS.PREVIEW ? 'd-none d-md-block' : 'd-block';

  const messageBoxKey = `${abInfo.isABVariation ? abInfo.currentABVariationIndex : 0}-${postType}`;

  return (
    <div data-cy-attribute={`accountAPIId:${accountAPIId}`}>
      <PreviewError errorMessage={errorMessage} statusCode={statusCode} />
      <PreviewHeader
        accountAPIId={accountAPIId}
        guid={guid}
        apiTypeId={apiTypeId}
        sponsorId={sponsor}
        sponsorName={sponsorName}
        additionalHeaders={mediaItem?.frontend?.additionalSocialPages}
      />
      <AnimateOnChange
        baseClassName="preview-animation-wrapper"
        animationClassName="ab-option"
        animate={abVariationChanged}
      >
        <div
          id={`collapsePreview-${guid}`}
          className="collapse show"
          aria-labelledby={`headingPreview-${guid}`}
          data-cy-id="mediaId"
          data-cy-attribute={`mediaId:${mediaId}`}
        >
          <div className="d-flex">
            <div className={`left main-preview ${leftClass}`}>
              <div className="previews">
                <div className="preview-wrapper">
                  <div className="preview">
                    <div className="border-bottom">
                      <MessageBox
                        key={messageBoxKey}
                        mediaItem={mediaItem}
                        guid={guid}
                        accountAPIId={accountAPIId}
                        isLoadingTags={isLoadingTags}
                        targetingParams={targetingParams}
                        placeholder={getSocialNetworkMessageBoxPlaceholder({
                          apiTypeId,
                        })}
                        applySingleFieldUpdateForGuid={
                          applySingleFieldUpdateForGuid
                        }
                      />
                      {postType === POST_TYPES.VIDEO &&
                        hasVideoTitle({ apiTypeId, socialChannel }) && (
                          <div className="py-3 px-4">
                            <table className="input video_title w-100">
                              <tbody>
                                <tr>
                                  <td className="label label_video">
                                    <span>Video title</span>
                                  </td>
                                  <td>
                                    <EditableText
                                      accountAPIId={accountAPIId}
                                      canCustomiseLinkPosts={
                                        canCustomiseLinkPosts
                                      }
                                      className="tag_actions"
                                      messageText={title}
                                      placeholder="Enter a title..."
                                      dataCyInput="videoTitle"
                                      onChange={(videoTitle) =>
                                        applySingleFieldUpdateForGuid({
                                          guid,
                                          fieldName: 'title',
                                          fieldValue: videoTitle,
                                        })
                                      }
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                    </div>
                    {isRefreshPreview || isApplyImageOverlay ? (
                      <div className="share_imagery">
                        <div className="text-center mt-4 mb-5 op-50">
                          <Spinner size="md" />
                          <span className="text-loading mt-5">
                            {isRefreshPreview
                              ? 'Retrieving article data...'
                              : 'Applying image overlay...'}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <Images
                        mediaItem={mediaItem}
                        accountAPIId={accountAPIId}
                        guid={guid}
                        apiTypeId={apiTypeId}
                        articleImages={articleImages}
                        pendingStickerConfig={pendingStickerConfig}
                        onPendingStickerConfig={setPendingStickerConfig}
                        isUploading={isUploading}
                        setIsUploading={setIsUploading}
                      />
                    )}

                    <DomainTitleDescription
                      guid={guid}
                      mediaItem={mediaItem}
                      accountAPIId={accountAPIId}
                      canCustomiseLinkPosts={canCustomiseLinkPosts}
                      description={description}
                      isRefreshPreview={isRefreshPreview}
                      isVideoLink={isVideoURL(unshortenedShareURL)}
                      hasImageOverlay={hasImageOverlay}
                      title={title}
                      onDescriptionChange={(newDescription) =>
                        applySingleFieldUpdateForGuid({
                          guid,
                          fieldName: 'description',
                          fieldValue: newDescription,
                        })
                      }
                      onTitleChange={(newTitle) =>
                        applySingleFieldUpdateForGuid({
                          guid,
                          fieldName: 'title',
                          fieldValue: newTitle,
                        })
                      }
                    />
                  </div>
                  {postType === POST_TYPES.LINK && (
                    <URL
                      mediaItem={mediaItem}
                      guid={guid}
                      url={unshortenedShareURL}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={`options right options-right ${rightClass}`}>
              {apiTypeId === API_TYPE_IDS.FACEBOOK &&
                (showShareTypeSwitcher ? (
                  <PostTypeSwitcher
                    guid={guid}
                    postType={postType}
                    accountAPIId={accountAPIId}
                    mediaItem={mediaItem}
                  />
                ) : (
                  <ShareType mediaItem={mediaItem} />
                ))}
              <TimingOptions
                guid={guid}
                mediaItem={mediaItem}
                accountAPIId={accountAPIId}
                applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
              />
              <OtherSettings
                guid={guid}
                mediaItem={mediaItem}
                accountAPIId={accountAPIId}
                isUploading={isUploading}
                applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
              />
              <Labels
                guid={guid}
                mediaItem={mediaItem}
                applySingleFieldUpdateForGuid={applySingleFieldUpdateForGuid}
              />
              <OtherActions
                guid={guid}
                allowDelete={allowDelete}
                deleteComposeBoxItem={deleteComposeBoxItem}
                previewCollapsed={previewCollapsed}
                containsSingleAccountAPIId={containsSingleAccountAPIId}
              />
            </div>
          </div>
        </div>
      </AnimateOnChange>
    </div>
  );
};

export default memo(Preview, arePropsEqual);
