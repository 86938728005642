import { EditorState, Modifier } from 'draft-js';

import { getAPITypeId } from 'common/accountAPIs';
import * as MediaItem from 'common/mediaItem';
import { getShareURLPlaceholder } from 'common/shareURL';
import * as DraftJSTools from 'components/compose/messagebox/DraftJSTools';

import { ACTION_TYPE, MessageBoxState } from './constants';
import type { ACTION, MessageBoxStateType, State } from './types';

export function reducer(state: State, action: ACTION): State {
  switch (action.type) {
    case ACTION_TYPE.HANDLE_ADDITIONAL_MENU_CLOSE:
      // If the user is adding a sponsor or tag, keep that state value (don't change it to default)
      if (
        (
          [
            MessageBoxState.ADDING_SPONSER,
            MessageBoxState.ADDING_TAG,
          ] as MessageBoxStateType[]
        ).includes(state.currentUserInputType)
      ) {
        return state;
      }
      return {
        ...state,
        currentUserInputType: MessageBoxState.DEFAULT,
      };

    case ACTION_TYPE.HANDLE_ADDITIONAL_MENU_OPEN:
      return {
        ...state,
        currentUserInputType: MessageBoxState.ADDITIONAL_OPTIONS_MENU_OPEN,
      };

    case ACTION_TYPE.HANDLE_EDITOR_STATE_CHANGE: {
      const { editorState } = action.payload;

      return { ...state, editorState };
    }

    case ACTION_TYPE.HANDLE_FULL_STOP_ADDED_ON_DOUBLE_SPACE: {
      if (action.payload !== '. ') {
        return state;
      }

      const { editorState } = state;
      const currentSelection = editorState.getSelection();

      return {
        ...state,
        editorState: EditorState.set(editorState, {
          currentContent: Modifier.replaceText(
            editorState.getCurrentContent(),
            currentSelection,
            ' ',
          ),
        }),
      };
    }

    case ACTION_TYPE.INSERT_EMOJI: {
      const { editorState } = action.payload;

      return {
        ...state,
        currentUserInputType: MessageBoxState.DEFAULT,
        editorState,
      };
    }

    case ACTION_TYPE.INSERT_TAG: {
      const { editorState } = action.payload;

      return {
        ...state,
        currentUserInputType: MessageBoxState.DEFAULT,
        editorState,
        handleMention: '',
        handleHashtag: '',
      };
    }

    case ACTION_TYPE.RESET_MESSAGEBOX_STATE:
      return {
        ...state,
        currentUserInputType: MessageBoxState.DEFAULT,
        handleMention: '',
        handleHashtag: '',
      };

    case ACTION_TYPE.START_ADDING_HASHTAG: {
      const stringBeforeCursor = DraftJSTools.getStringBeforeCursor(
        state.editorState,
      );

      // We only want to start an inline hashtag if there is an empty space before the '#'
      // Or if the previous string ended in a new line.
      if (stringBeforeCursor !== '' && !stringBeforeCursor.endsWith('\n')) {
        return {
          ...state,
          currentUserInputType: MessageBoxState.DEFAULT,
          handleHashtag: '',
        };
      }
      return {
        ...state,
        currentUserInputType: MessageBoxState.ADDING_INLINE_HASHTAG,
      };
    }

    case ACTION_TYPE.START_ADDING_MENTION:
      return {
        ...state,
        currentUserInputType: MessageBoxState.ADDING_INLINE_MENTION,
        handleMention: '',
        handleHashtag: '',
        didPaste: action.payload.didPaste,
      };

    case ACTION_TYPE.TOGGLE_ADDING_SPONSER_MENU: {
      return {
        ...state,
        currentUserInputType:
          state.currentUserInputType === MessageBoxState.ADDING_SPONSER
            ? MessageBoxState.DEFAULT
            : MessageBoxState.ADDING_SPONSER,
        handleMention: '',
        handleHashtag: '',
      };
    }

    case ACTION_TYPE.TOGGLE_ADDING_TAG_MENU: {
      return {
        ...state,
        currentUserInputType:
          state.currentUserInputType === MessageBoxState.ADDING_TAG
            ? MessageBoxState.DEFAULT
            : MessageBoxState.ADDING_TAG,
        handleMention: '',
        handleHashtag: '',
      };
    }

    case ACTION_TYPE.TOGGLE_EMOJI_MENU: {
      return {
        ...state,
        currentUserInputType:
          state.currentUserInputType === MessageBoxState.ADDING_EMOJI
            ? MessageBoxState.DEFAULT
            : MessageBoxState.ADDING_EMOJI,
        handleMention: '',
        handleHashtag: '',
      };
    }

    case ACTION_TYPE.UPDATE_HANDLE_HASHTAG:
      return { ...state, handleHashtag: action.payload.handleHashtag };

    case ACTION_TYPE.UPDATE_HANDLE_MENTION:
      return { ...state, handleMention: action.payload.handleMention };

    case ACTION_TYPE.UPDATE_SELECTED_SHARE_CONTENT: {
      const { accountAPIId, mediaItem, selectedShareContent } = action.payload;

      const messages = MediaItem.getMessages({ mediaItem });

      if (!messages) {
        return { ...state };
      }

      const content = selectedShareContent.value;

      const shareURL = getShareURLPlaceholder({ mediaItem }) ?? '';
      const apiTypeId = getAPITypeId({ accountAPIId });

      return {
        ...state,
        editorState: DraftJSTools.createEditorState({
          config: {
            content: content || '',
            apiTypeId,
            replacementFields: MediaItem.getTags({ mediaItem }),
            shareURL,
          },
        }),
      };
    }

    default: {
      const unrecognisedActionType = (action as any).type;
      throw new Error(`Unhandled action type: ${unrecognisedActionType}`);
    }
  }
}
