import { Grid, Heading, Select } from '@ebx-ui/ebx-ui-component-library-sdk';
import { LANGUAGES } from 'common/constants';
import { Property } from 'types';

interface LanguageGridSettingItemProps {
  setSelectedLanguage: React.Dispatch<React.SetStateAction<string>>;
  setChangesMade: React.Dispatch<React.SetStateAction<boolean>>;
  property: Property;
}

const LanguageGridSettingItem = ({
  setChangesMade,
  setSelectedLanguage,
  property,
}: LanguageGridSettingItemProps) => {
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
  const languageList = Object.keys(LANGUAGES).map((language) => (
    <option key={language} value={language}>
      {languageNames.of(language)}
    </option>
  ));

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedLanguage(event.target.value);
    setChangesMade(true);
  };

  return (
    <Grid.GridItem>
      <Heading variant="h6" color="gray.600" mb={1}>
        Language:
      </Heading>
      <Select
        boxShadow="xs"
        placeholder={languageNames.of(property.language)}
        onChange={handleLanguageChange}
      >
        {languageList}
      </Select>
    </Grid.GridItem>
  );
};

export default LanguageGridSettingItem;
