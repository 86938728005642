import PropTypes from 'prop-types';

const PasswordRules = (props) => {
  const {
    containsTenCharacters,
    containsLowerCase,
    containsUpperCase,
    containsNumberCharacter,
    marginTop,
  } = props;
  const containsTenCharactersClass = containsTenCharacters
    ? 'password_checked'
    : 'password_unchecked';
  const containsUpperCaseClass = containsUpperCase
    ? 'password_checked'
    : 'password_unchecked';
  const containsLowerCaseClass = containsLowerCase
    ? 'password_checked'
    : 'password_unchecked';
  const containsNumberCharacterClass = containsNumberCharacter
    ? 'password_checked'
    : 'password_unchecked';
  const listClass = marginTop ? 'mt-2' : '';

  return (
    <ul className={listClass}>
      <li
        data-cy-attribute="hasTenCharacters"
        data-cy-value={containsTenCharacters}
        className={containsTenCharactersClass}
      >
        At least 10 characters long
      </li>
      <li
        data-cy-attribute="hasLowerCase"
        data-cy-value={containsLowerCase}
        className={containsLowerCaseClass}
      >
        One lowercase character
      </li>
      <li
        data-cy-attribute="hasUpperCase"
        data-cy-value={containsUpperCase}
        className={containsUpperCaseClass}
      >
        One uppercase character
      </li>
      <li
        data-cy-attribute="hasNumberCharacter"
        data-cy-value={containsNumberCharacter}
        className={containsNumberCharacterClass}
      >
        One number character
      </li>
    </ul>
  );
};

PasswordRules.propTypes = {
  containsTenCharacters: PropTypes.bool.isRequired,
  containsLowerCase: PropTypes.bool.isRequired,
  containsUpperCase: PropTypes.bool.isRequired,
  containsNumberCharacter: PropTypes.bool.isRequired,
  marginTop: PropTypes.bool,
};

PasswordRules.defaultProps = {
  marginTop: false,
};

export default PasswordRules;
