import { Flex, Text } from '@ebx-ui/ebx-ui-component-library-sdk';
import { useEffect } from 'react';

import { setPanelButtonText } from 'helpers/settingsPage';

import CopyLink from './CopyLink';

interface LinkInBioProps {
  accountAPIId: string;
}

const LinkInBio = ({ accountAPIId }: LinkInBioProps) => {
  useEffect(() => {
    setPanelButtonText('collapseLinkInBio', { openText: 'View' });
  }, []);

  return (
    <>
      <Flex
        borderTop="1px"
        borderColor="#e3e8ee"
        alignItems="center"
        py={3}
        px={5}
      >
        <Flex mr="auto" direction="column" pr={2}>
          <Text size="sm" fontWeight="bold" color="#3c4257">
            Link in bio
          </Text>
          <Text size="xs" fontWeight="normal" color="#3c4257">
            Your Posts and associated links are automatically added to your link
            in bio page to drive traffic from Instagram
          </Text>
        </Flex>
        <div
          className="btn btn-light align-self-center"
          data-cy-action="editLinkInBio"
          data-toggle="collapse"
          data-target="#collapseLinkInBio"
          role="button"
          aria-controls="collapseLinkInBio"
        >
          View
        </div>
      </Flex>
      <div
        className="collapse settings-sub settings-share-preview"
        id="collapseLinkInBio"
        data-parent="#accordion"
        data-cy-id="LinkInBio"
      >
        <CopyLink accountAPIId={Number(accountAPIId)} />
      </div>
    </>
  );
};

export default LinkInBio;
