import * as React from 'react';

import { KEYNAMES } from 'common/constants';
import useOnKey from 'hooks/useOnKey';

type DefaultDivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface DialogContextInterface {
  isOpen: boolean;
  onDismiss: () => void;
}

const DialogContext = React.createContext<DialogContextInterface>({
  isOpen: false,
  onDismiss: () => {},
});

export interface DialogProps extends DefaultDivProps {
  children: React.ReactNode;
  isOpen: boolean;
  onDismiss: () => void;
  className?: string;
  centerVertically?: boolean;
}

/**
 * React component for rendering a dialog
 * Accepts the following props:
 * 1) isOpen (required)           - a boolean value used to decide whether to render the dialog
 * 2) onDismiss (required)        - a function to dismiss the dialog
 * 3) children (required)         - could be text or another react component
 * 4) centerVertically (optional) - a boolean value used for centering the dialog vertically. Defaults to false
 * 5) className (optional)        - classes to be added to the actual dialog element. Defaults to modal-sm
 * Any other props passed are sent to the parent div of the component
 * Also maintains an implicit state using react context storing open and onDismiss prop values
 * exposes three internal Dialog components - DialogHeader, DialogBody and DialogFooter
 */
const Dialog = ({
  isOpen,
  onDismiss,
  children,
  centerVertically = false,
  className = 'modal-md',
  ...rest
}: DialogProps) => {
  // Close modal on pressing ESCAPE
  useOnKey({
    targetKey: KEYNAMES.ESCAPE,
    onKeyUp: () => {
      if (isOpen) onDismiss();
    },
  });

  const dialogContextValue = React.useMemo(() => {
    return {
      isOpen,
      onDismiss,
    };
  }, [isOpen, onDismiss]);

  if (!isOpen) return null;

  return (
    <DialogContext.Provider value={dialogContextValue}>
      <div
        className="ebx-modal-outer overflow-hidden"
        tabIndex={-1}
        role="dialog"
        style={{ display: 'block' }}
        {...rest}
      >
        <div
          className={`modal-dialog modal-full-width ${
            centerVertically && 'modal-dialog-centered shadow-none'
          } ${className}`}
          role="document"
        >
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </DialogContext.Provider>
  );
};

export interface DialogHeaderProps extends DefaultDivProps {
  children: React.ReactNode;
  className?: string;
  showClose?: boolean;
}

/**
 * React component for rendering header in a dialog
 * Accepts the following props:
 * 1) children (required)  - could be text or another react component
 * 2) showClose (optional) - a boolean to show 'x' icon in header. Defaults to true
 * Any of the other props are passed to the parent div
 */
const DialogHeader = ({
  children,
  className = '',
  showClose = true,
  ...rest
}: DialogHeaderProps) => {
  const { onDismiss } = React.useContext(DialogContext);
  return (
    <div className={`modal-header ${className}`} {...rest}>
      {children}
      {showClose && (
        <button
          type="button"
          className="close ft-26 text-400"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onDismiss}
        >
          ×
        </button>
      )}
    </div>
  );
};

export interface DialogBodyProps extends DefaultDivProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * React component for rendering body in a dialog
 * Accepts the following props:
 * 1) children (required)  - could be text or another react component
 * Any of the other props are passed to the parent div
 */
const DialogBody = ({ children, className = '', ...rest }: DialogBodyProps) => {
  return (
    <div className={`modal-body lh-20 ${className}`} {...rest}>
      {children}
    </div>
  );
};

export interface DialogFooterProps extends DefaultDivProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * React component for rendering footer in a dialog
 * Accepts the following props:
 * 1) children (required)  - could be text or another react component
 * Any of the other props are passed to the parent div
 */
const DialogFooter = ({
  children,
  className = '',
  ...rest
}: DialogFooterProps) => {
  return (
    <div className={`modal-footer ${className}`} {...rest}>
      {children}
    </div>
  );
};

Dialog.DialogHeader = DialogHeader;
Dialog.DialogBody = DialogBody;
Dialog.DialogFooter = DialogFooter;

export default Dialog;
