import * as validator from 'email-validator';

import postSignup from 'api/postSignup';
import { FRONTEND_METRICS, REACT_PREVENT_RENDER } from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import { fixGlobalInfo } from 'common/globalInfo';
import * as logger from 'common/logger';
import { withNavigate } from 'common/routing';
import { ebxMessage } from 'common/string';
import { isDefined, isNull } from 'common/utility';
import { location } from 'common/window';
import { hideZendesk } from 'common/zendesk';
import BaseComponent from 'components/BaseComponent';
import Footer from 'components/login/Footer';
import PasswordRules from 'components/login/PasswordRules';
import StatusPageNotification from 'components/misc/StatusPageNotification';
import { handlePasswordHideShow } from 'helpers/loginForm';
import login from 'process/login';

/**
 * Signup screen
 */

class Signup extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      publicationName: '',
      emailAddress: '',
      password: '',
      textType: 'password',
      showPassword: false,
      passwordShowText: 'Show Password',
      containsTenCharacters: false,
      containsLowerCase: false,
      containsUpperCase: false,
      containsNumberCharacter: false,
      isCreatingAccount: false,
      errorMessages: {
        publicationName: '',
        emailAddress: '',
        password: '',
        general: '',
      },
    };
    this._bind(
      'handleChangeEmailAddress',
      'handleChangePassword',
      'handleChangePublicationName',
      'handleSubmit',
    );
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    hideZendesk();
  }

  componentDidUpdate() {
    this._trackLastUpdate(FRONTEND_METRICS.PAGE_NAVIGATION_MISC);
  }

  /**
   * Event handlers
   */

  handleChangeEmailAddress(event) {
    const emailAddress = event.target.value;
    this.setState((prevState) => ({
      emailAddress,
      errorMessages: {
        ...prevState.errorMessages,
        emailAddress: '',
      },
    }));
  }

  handleChangePassword(event) {
    event.preventDefault();
    const password = event.target.value;
    // Check contains upper case
    const containsUpperCase = /(?=.*[A-Z])/.test(password);
    // Check contains lower case
    const containsLowerCase = /(?=.*[a-z])/.test(password);
    // Check contains number character
    const containsNumberCharacter = /^(?=.*[0-9])/.test(password);
    // Check is 10 characters long
    const containsTenCharacters = password.length >= 10;

    this.setState((prevState) => ({
      password,
      containsLowerCase,
      containsUpperCase,
      containsTenCharacters,
      containsNumberCharacter,
      errorMessages: {
        ...prevState.errorMessages,
        password: '',
      },
    }));
  }

  handleChangePublicationName(event) {
    const publicationName = event.target.value;
    this.setState((prevState) => ({
      publicationName,
      errorMessages: {
        ...prevState.errorMessages,
        publicationName: '',
      },
    }));
  }

  handleSubmit() {
    if (this.state.publicationName === '') {
      this.setState((prevState) => ({
        errorMessages: {
          ...prevState.errorMessages,
          publicationName: 'Please enter your publication name.',
        },
      }));
      return;
    }
    if (this.state.emailAddress === '') {
      this.setState((prevState) => ({
        errorMessages: {
          ...prevState.errorMessages,
          emailAddress: 'Please enter an email address.',
        },
      }));
      return;
    }
    if (!validator.validate(this.state.emailAddress)) {
      this.setState((prevState) => ({
        errorMessages: {
          ...prevState.errorMessages,
          emailAddress: 'Please enter a valid email address.',
        },
      }));
      return;
    }
    if (this.state.password === '') {
      this.setState((prevState) => ({
        errorMessages: {
          ...prevState.errorMessages,
          password: 'Please enter a password.',
        },
      }));
      return;
    }
    if (
      !this.state.containsLowerCase ||
      !this.state.containsUpperCase ||
      !this.state.containsTenCharacters ||
      !this.state.containsNumberCharacter
    ) {
      this.setState((prevState) => ({
        errorMessages: {
          ...prevState.errorMessages,
          password:
            'Please ensure your password meets the listed requirements.',
        },
      }));
      return;
    }
    this.setState(
      {
        isCreatingAccount: true,
      },
      () => {
        postSignup({
          propertyName: this.state.publicationName,
          emailAddress: this.state.emailAddress,
          password: this.state.password,
        })
          .then(() => {
            login({
              username: this.state.emailAddress,
              password: this.state.password,
              setGlobalInfo: Signup.setGlobalInfo,
            })
              .then(() => {
                // Redirect to setup
                // Echobox component will redirect if they're under setup to initialise global info
                location.href = '/';
              })
              .catch((error) => {
                this.setState({
                  isCreatingAccount: false,
                });
                let errorMessage = getErrorMessage(determineError(error));
                if (errorMessage === 'Network Error') {
                  errorMessage = ebxMessage(`Please check your Internet
              connection and make sure you're online.`);
                }
                if (isNull(getErrorStatus(error))) {
                  logger.error({
                    event: 'Signup Error',
                    properties: {
                      location: 'components/home/schedulequeue/Item',
                    },
                    error,
                  });
                }
                this.setState((prevState) => ({
                  errorMessages: {
                    ...prevState.errorMessages,
                    general: errorMessage,
                  },
                }));
              });
          })
          .catch((error) => {
            this.setState({
              isCreatingAccount: false,
            });
            if (error.message === 'Network Error' || !isDefined(error.error)) {
              this.setState((prevState) => ({
                errorMessages: {
                  ...prevState.errorMessages,
                  general: ebxMessage(
                    'Unable to reach Echobox. Please check your Internet connection.',
                  ),
                },
              }));
            } else if (
              error.status === 409 ||
              error.error.target === 'emailAddress'
            ) {
              this.setState((prevState) => ({
                errorMessages: {
                  ...prevState.errorMessages,
                  general: ebxMessage(
                    'It looks like you already have an account. Please sign in.',
                  ),
                },
              }));
              // Pass base-64-encoded email address to login page
              const encodedEmail = window.btoa(this.state.emailAddress);
              this.props.navigate(`/login?username=${encodedEmail}`);
            } else if (error.status === 422) {
              if (error.error.target === 'password') {
                this.setState((prevState) => ({
                  errorMessages: {
                    ...prevState.errorMessages,
                    password: ebxMessage(
                      'Passwords must be at least 10 characters long and must contain at least one number, one uppercase letter, and one lowercase letter.',
                    ),
                  },
                }));
              }
            }
          });
      },
    );
  }

  /**
   * Helper methods
   */

  static setGlobalInfo(globalInfo) {
    const fixedGlobalInfo = fixGlobalInfo(globalInfo);
    if (window) {
      window.globalInfo = fixedGlobalInfo;
      window.sessionStorage.setItem(
        'globalInfo',
        JSON.stringify(fixedGlobalInfo),
      );
    }
  }

  static setInputClass(isCreatingAccount, hasError) {
    let inputClass = '';
    if (isCreatingAccount) {
      inputClass += ' saving_locked';
    }
    if (hasError) {
      inputClass += ' input-with-error';
    }
    return inputClass;
  }

  /**
   * Render methods
   */

  renderEmailAddress() {
    const { errorMessages, isCreatingAccount } = this.state;
    const errorMessage = errorMessages.emailAddress;
    const inputClass = Signup.setInputClass(
      isCreatingAccount,
      errorMessage !== '',
    );

    return (
      <label htmlFor="emailAddress">
        <span className="inputform">Work Email</span>
        <input
          type="text"
          name="emailAddress"
          id="emailAddress"
          data-cy-input="emailAddress"
          className={`inputblank w-100 ${inputClass}`}
          value={this.state.emailAddress}
          onChange={this.handleChangeEmailAddress}
        />
        {Signup.renderFieldError(errorMessage)}
      </label>
    );
  }

  static renderFieldError(errorMessage) {
    if (errorMessage === '') {
      return REACT_PREVENT_RENDER;
    }
    return (
      <div className="d-flex">
        <img className="mr-1" src="/img/icons/ic-error-red.svg" alt="" />
        <p className="inputerrortxt" data-cy-id="errorMessage">
          {errorMessage}
        </p>
      </div>
    );
  }

  renderPassword() {
    const {
      isCreatingAccount,
      containsTenCharacters,
      containsUpperCase,
      containsLowerCase,
      containsNumberCharacter,
      errorMessages,
    } = this.state;
    const errorMessage = errorMessages.password;
    const inputClass = Signup.setInputClass(
      isCreatingAccount,
      errorMessage !== '',
    );

    return (
      <label htmlFor="password">
        <span className="inputform">Password</span>
        <a onClick={() => handlePasswordHideShow.call(this)}>
          <span className="small_link_right">
            {this.state.passwordShowText}
          </span>
        </a>
        <div className="boxinput">
          <input
            className={`inputblank w-100 ${inputClass}`}
            type={this.state.textType}
            id="password"
            data-cy-input="password"
            value={this.state.password}
            onChange={this.handleChangePassword}
          />
          {Signup.renderFieldError(errorMessage)}
        </div>
        <PasswordRules
          containsTenCharacters={containsTenCharacters}
          containsLowerCase={containsLowerCase}
          containsUpperCase={containsUpperCase}
          containsNumberCharacter={containsNumberCharacter}
          marginTop={true}
        />
      </label>
    );
  }

  renderPublicationName() {
    const { isCreatingAccount, errorMessages } = this.state;
    const errorMessage = errorMessages.publicationName;
    const inputClass = Signup.setInputClass(
      isCreatingAccount,
      errorMessage !== '',
    );

    return (
      <label htmlFor="publicationName">
        <span className="inputform">Publication Name</span>
        <input
          type="text"
          name="publicationName"
          id="publicationName"
          data-cy-input="publicationName"
          className={`inputblank w-100 ${inputClass}`}
          value={this.state.publicationName}
          onChange={this.handleChangePublicationName}
        />
        {Signup.renderFieldError(errorMessage)}
      </label>
    );
  }

  render() {
    const { isCreatingAccount } = this.state;
    const createClass = isCreatingAccount ? 'saving-locked' : '';

    return (
      <div className={`signin-register ${createClass}`} data-cy-id="signupPage">
        <div className="signinlogo">
          <img src="/img/logo-ebx-main-dark.svg" alt="" />
        </div>
        <div className="signin_box">
          <form>
            <StatusPageNotification />
            <div className="wrapper_center">
              <div className="boxheader">
                <h2>Create your account</h2>
              </div>
              <div className="boxinput">
                {this.renderPublicationName()}
                {this.renderEmailAddress()}
                {this.renderPassword()}
                <div style={{ clear: 'both' }} />
                <a
                  className={`btn btn-dark btn-signin mt-3 ${createClass}`}
                  onClick={isCreatingAccount ? null : this.handleSubmit}
                  disabled={isCreatingAccount}
                  data-cy-action="submit"
                >
                  Create your Echobox account
                </a>
              </div>
              <p className="annotation">
                By creating an Echobox account, you&#39;re agreeing to accept
                the Echobox&nbsp;
                <a
                  href="https://www.echobox.com/terms"
                  className="small_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                .
              </p>
              <hr />
              <p className="mb-0">
                Already have an account? &nbsp;
                <a href="/login" className="small_link">
                  Sign in
                </a>
              </p>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withNavigate(Signup);
