import { Link } from 'react-router-dom';

import {
  Flex,
  Heading,
  SettingsCard,
  Tag,
  Text,
} from '@ebx-ui/ebx-ui-component-library-sdk';

import * as accountAPIs from 'common/accountAPIs';
import { AUTOFEED_SHARE_MODES } from 'common/constants';
import * as settings from 'common/settings';
import * as social from 'common/social';
import PageImage from 'components/misc/PageImage';
import AutomationAndApprovalToggles from 'components/settings/automation/AutomationAndApprovalToggles';
import { useAutomationSettingsContext } from 'components/settings/automation/AutomationSettingsContext';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { AutofeedShareMode } from 'types';

export interface AutomationSettings {
  automationActive: boolean;
  approvalsActive: boolean;
  newShareMode: AutofeedShareMode;
  reshareMode: AutofeedShareMode;
}

function Overview() {
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();

  const { accountAPIId, isAutomationActive } = useAutomationSettingsContext();

  const accountAPI = accountAPIs.getAccountAPI({
    accountAPIId,
    globalInfo,
  });

  const pageName = accountAPI ? accountAPI.apiPostName : '';

  const getSettingsFromGlobalInfo = () => {
    const defaults = social.getAutofeedDefaults({
      apiTypeId: accountAPIs.getAPITypeId({
        accountAPIId,
      }),
    });

    const newShareMode =
      settings.getAutofeedSetting({
        key: 'newShareMode',
        accountAPIId,
      }) ??
      defaults.newShareMode ??
      AUTOFEED_SHARE_MODES.OPTIMAL;

    const reshareMode =
      settings.getAutofeedSetting({
        key: 'reshareMode',
        accountAPIId,
      }) ??
      defaults.reshareMode ??
      AUTOFEED_SHARE_MODES.OPTIMAL;

    const approvalsActive =
      settings.isApprovalsSettingsActive(accountAPIId) ?? false;

    return {
      automationActive: isAutomationActive,
      approvalsActive,
      newShareMode,
      reshareMode,
    };
  };

  const getSettingsTag = (shareMode: AutofeedShareMode) => {
    switch (shareMode) {
      case AUTOFEED_SHARE_MODES.OPTIMAL:
        return <Tag color="blue">Optimal</Tag>;
      case AUTOFEED_SHARE_MODES.CUSTOM:
        return <Tag color="blue">Custom</Tag>;
      case AUTOFEED_SHARE_MODES.OFF:
        return <Tag color="gray">Off</Tag>;
      default:
        return null;
    }
  };

  const automationSettings = getSettingsFromGlobalInfo();

  return (
    <Flex flexDir="column" gap={8}>
      <Flex gap={3}>
        <PageImage accountAPI={accountAPI}>
          <PageImage.SocialLogo size={5} svgSize={3.5} />
          <PageImage.Image size={10} />
        </PageImage>
        <Flex flexDir="column">
          <Heading variant="h3">{pageName}</Heading>
          <Text size="sm" color="gray.600">
            Customize automation
          </Text>
        </Flex>
      </Flex>
      <AutomationAndApprovalToggles automationSettings={automationSettings} />
      <Flex flexDir="column" gap={2}>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/newshares`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="New shares"
            description="Customize the automation of first-time shares"
            tag={getSettingsTag(automationSettings.newShareMode)}
          />
          <SettingsCard.Controls />
        </SettingsCard>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/reshares`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Reshares"
            description="Customize the automation of reshares"
            tag={getSettingsTag(automationSettings.reshareMode)}
          />
          <SettingsCard.Controls />
        </SettingsCard>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/schedule`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Schedule periods"
            description="Select times when the automation should be on/off"
          />
          <SettingsCard.Controls />
        </SettingsCard>
        <SettingsCard
          href={`/settings/automation/${accountAPIId}/advanced`}
          linkAs={Link}
        >
          <SettingsCard.Main
            title="Advanced settings"
            description="Further customize your automation choices"
          />
          <SettingsCard.Controls />
        </SettingsCard>
      </Flex>
    </Flex>
  );
}

export default Overview;
