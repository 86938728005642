import CreativeEngine from '@cesdk/engine';
import * as React from 'react';

import { getCurrentPropertyId } from 'common/accountAPIs';
import { SHARE_ORIGINS } from 'common/constants';
import {
  constructVideo,
  initializeCreativeEngine,
  initializeMediaItem,
  retrieveVideoParameters,
} from 'common/instantVideo';
import * as MediaItem from 'common/mediaItem';
import { useGlobalInfo } from 'context/GlobalInfoContext';
import { FixTypeLater, VideoParameters } from 'types';

export function useMediaItemVideoGeneration({
  initialMediaItem,
  shareOrigin = SHARE_ORIGINS.HOME_FEED,
}: {
  initialMediaItem: FixTypeLater;
  shareOrigin?: string;
}) {
  const [mediaItem, setMediaItem] =
    React.useState<FixTypeLater>(initialMediaItem);
  const [progress, setProgress] = React.useState<number | null>(null);
  const [blob, setBlob] = React.useState<Blob | null>(null);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState<VideoParameters | null>(null);
  const [engine, setEngine] = React.useState<CreativeEngine | null>(null);
  const { global } = useGlobalInfo();
  const globalInfo = global.getGlobalInfo();
  const userId = globalInfo.user.id;
  const propertyId = getCurrentPropertyId({ globalInfo });

  // Throwing any errors which may have occurred during the video generation process
  // These will be caught by the error boundary and the user will be notified that something went wrong
  if (error) {
    throw error;
  }

  const regenerateVideo = React.useCallback(
    async (parameters: VideoParameters) => {
      if (engine === null) return;
      try {
        setProgress(0);
        setMediaItem((mi: FixTypeLater) =>
          MediaItem.setIsLoading({ mediaItem: mi, fieldValue: true }),
        );

        const videoBlob = await constructVideo({
          parameters,
          engine,
          setProgress,
        });

        setBlob(videoBlob);

        const videoURL = URL.createObjectURL(videoBlob);

        setMediaItem((mi: FixTypeLater) => {
          let updatedMediaItem = MediaItem.setVideoURL({
            mediaItem: mi,
            fieldValue: videoURL,
          });
          updatedMediaItem = MediaItem.setIsLoading({
            mediaItem: updatedMediaItem,
            fieldValue: false,
          });
          return updatedMediaItem;
        });
      } catch (e: any) {
        setError(e);
      }
    },
    [engine],
  );

  // Initialize and dispose of the Creative Engine
  React.useEffect(() => {
    let creativeEngine: CreativeEngine | null = null;
    const initializeEngine = async () => {
      creativeEngine = await initializeCreativeEngine({ userId });
      setEngine(creativeEngine);
    };
    initializeEngine();

    return () => {
      if (creativeEngine) {
        creativeEngine.element?.remove();
        creativeEngine.dispose();
        setEngine(null);
      }
    };
  }, [userId]);

  // Populating the media item and generating a new video based on the article
  React.useEffect(() => {
    const populateMediaItem = async () => {
      if (engine === null) return;
      try {
        let updatedMediaItem = await initializeMediaItem({
          mediaItem: initialMediaItem,
          shareOrigin,
        });

        updatedMediaItem = MediaItem.setIsLoading({
          mediaItem: updatedMediaItem,
          fieldValue: true,
        });

        setMediaItem(updatedMediaItem);

        const parameters = await retrieveVideoParameters({
          mediaItem: updatedMediaItem,
          propertyId,
        });

        setData(parameters);

        const videoBlob = await constructVideo({
          parameters,
          engine,
          setProgress,
        });

        setBlob(videoBlob);

        const videoURL = URL.createObjectURL(videoBlob);

        setMediaItem((mi: FixTypeLater) => {
          let newMediaItem = MediaItem.setVideoURL({
            mediaItem: mi,
            fieldValue: videoURL,
          });
          newMediaItem = MediaItem.setIsLoading({
            mediaItem: newMediaItem,
            fieldValue: false,
          });
          return newMediaItem;
        });
      } catch (e: any) {
        setError(e);
      }
    };
    populateMediaItem();
  }, [initialMediaItem, shareOrigin, propertyId, engine, setProgress]);

  return {
    mediaItem,
    setMediaItem,
    data,
    setData,
    blob,
    regenerateVideo,
    progress,
  };
}
