import {
  CloseIcon,
  Divider,
  Flex,
  IconButton,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import { useState } from 'react';

import HeaderButtons from 'components/setup/HeaderButtons';
import SignoutModal from 'components/setup/SignoutModal';
import SwitchPropertyDropdown from 'components/setup/SwitchPropertyDropdown';

const Header = () => {
  const [showSignoutModal, setShowSignoutModal] = useState(false);

  const showSignoutFromButton = () => {
    setShowSignoutModal(true);
  };

  return (
    <>
      <Flex
        bg="white"
        py="3"
        pl="4"
        pr="10"
        boxShadow="borderBottom"
        alignItems="center"
        justifyContent="flex-start"
        minHeight="15"
        zIndex="1"
        position="absolute"
        inset="0 0 auto"
      >
        <IconButton
          aria-label="close"
          onClick={showSignoutFromButton}
          size="lg"
          icon={<CloseIcon color="gray.500" opacity="0.5" />}
        />
        <Divider orientation="vertical" h="5" borderRight="1px" ml="4" mr="6" />
        <SwitchPropertyDropdown />
        <HeaderButtons showSignOutModal={showSignoutFromButton} />
      </Flex>
      {showSignoutModal && (
        <SignoutModal onDismiss={() => setShowSignoutModal(false)} />
      )}
    </>
  );
};

export default Header;
