/* eslint no-return-assign:"off" */
/* eslint no-use-before-define:"off" */

import getAPIsSettingsByType from 'api/getAPIsSettingsByType';
import getPropertySettingsByType from 'api/getPropertySettingsByType';
import postAPIsSettingsType from 'api/postAPIsSettingsType';
import postPropertiesSettings from 'api/postPropertiesSettings';
import {
  getCurrentAccountAPIId,
  getCurrentPropertyId,
} from 'common/accountAPIs';
import { ACCOUNT_API_LEVEL_SETTINGS } from 'common/config';
import {
  ACCOUNT_SETTING_TYPES,
  GLOBAL_INFO_STATES,
  SAVE_STATES,
} from 'common/constants';
import {
  determineError,
  getErrorMessage,
  getErrorStatus,
} from 'common/errorHandling';
import * as logger from 'common/logger';
import { addErrorNotification } from 'common/notifications';
import {
  cloneObject,
  isDefined,
  isNull,
  isNullOrUndefined,
  isUndefined,
} from 'common/utility';
import { mandatory } from 'common/validation';
import { resetSettingsState, setSettingsSaving } from 'context/SettingsContext';
import * as tracking from 'helpers/tracking';
import { getAutofeedSettings } from './settingsV2';

export * from './settingsV2';

export {
  loadSettingsForAPI,
  loadSettingsForProperty,
  saveAPIsSettings,
  saveAutomationSettings,
  savePropertiesSettings,
  storeSettings,
};

/**
 * @deprecated Use settingsV2.ts instead
 *
 * Returns sensible defaults for each different setting type
 *
 * @param {number} settingTypeId
 * @return {object}
 */

function getDefaultSetting({
  settingTypeId = mandatory('settingTypeId'),
} = {}) {
  const isPropertySetting =
    ACCOUNT_API_LEVEL_SETTINGS.indexOf(Number(settingTypeId)) === -1;
  const defaultSetting = isPropertySetting
    ? { enabled: false, dataJSON: {} }
    : { enabled: true, dataJSON: {} };

  switch (settingTypeId) {
    case ACCOUNT_SETTING_TYPES.ACCOUNT_API_RSS_FEED_PREFERENCES:
      // Default = no RSS feeds selected
      defaultSetting.dataJSON = [];
      break;
    case ACCOUNT_SETTING_TYPES.SHARE_DATA_SOURCES:
      // No need to set defaults, SharePreview page will do this
      // dependent on the current social network type
      break;
    case ACCOUNT_SETTING_TYPES.DEFAULT_FACEBOOK_TARGETING:
      // Default structure containing empty list of countries
      defaultSetting.dataJSON = { targetingParams: { countries: [] } };
      break;
    case ACCOUNT_SETTING_TYPES.DEFAULT_URL_PARAMS:
      // No need to set defaults, webservice will return property-level settings
      // if no settings exist at account API level
      break;
    default:
    //
  }

  return defaultSetting;
}

/**
 * loadSettingsForAPI
 * @param {{
 *  accountAPIId?: number;
 *  settingTypeIds: number[];
 *  settingIndex?: number;
 * }}
 * @returns {Promise<{ [settingTypeId: number]: { enabled: boolean; dataJSON: object; } } | null>}
 */
async function loadSettingsForAPI({
  accountAPIId = getCurrentAccountAPIId(),
  settingTypeIds = mandatory('settingTypeIds'),
  settingIndex = 0,
} = {}) {
  const settings = {};
  try {
    const response = await getAPIsSettingsByType({
      accountAPIIds: [accountAPIId],
      settingTypeIds,
    });
    if (
      isNullOrUndefined(response) ||
      isNullOrUndefined(response[accountAPIId])
    ) {
      settingTypeIds.forEach(
        (settingTypeId) =>
          (settings[settingTypeId] = getDefaultSetting({ settingTypeId })),
      );
    } else {
      settingTypeIds.forEach((settingTypeId) => {
        if (
          isNullOrUndefined(response[accountAPIId][settingTypeId]) ||
          isNullOrUndefined(response[accountAPIId][settingTypeId][settingIndex])
        ) {
          settings[settingTypeId] = getDefaultSetting({ settingTypeId });
        } else {
          settings[settingTypeId] = {};
          settings[settingTypeId].enabled =
            response[accountAPIId][settingTypeId][settingIndex].enabled;
          settings[settingTypeId].dataJSON =
            response[accountAPIId][settingTypeId][settingIndex].dataJSON;
        }
      });
    }
    return settings;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * @param {{
 *  propertyId?: string;
 *  settingTypeIds: number[];
 *  settingIndex?: number;
 * }}
 * @returns {Promise<{ [settingTypeId: number]: { enabled: boolean; dataJSON: object; } } | null>}
 */
async function loadSettingsForProperty({
  propertyId = getCurrentPropertyId(),
  settingTypeIds = mandatory('settingTypeIds'),
  settingIndex = 0,
} = {}) {
  const settings = {};
  try {
    const response = await getPropertySettingsByType({
      propertyId,
      settingTypeIds,
    });
    if (isNullOrUndefined(response)) {
      settingTypeIds.forEach(
        (settingTypeId) =>
          (settings[settingTypeId] = getDefaultSetting({ settingTypeId })),
      );
    } else {
      settingTypeIds.forEach((settingTypeId) => {
        if (
          isNullOrUndefined(response.entries[settingTypeId]) ||
          isNullOrUndefined(response.entries[settingTypeId][settingIndex])
        ) {
          settings[settingTypeId] = getDefaultSetting({ settingTypeId });
        } else {
          const { enabled, dataJSON } =
            response.entries[settingTypeId][settingIndex];
          settings[settingTypeId] = {
            enabled,
            dataJSON: dataJSON ? JSON.parse(dataJSON) : dataJSON,
          };
        }
      });
    }
    return settings;
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**
 * saveAPIsSettings
 *
 * @param {Object} options - The options object.
 * @param {Object} options.settingConfig
 * @param {function} options.callback
 * @param {function} [options.errorHandler]
 * @param {Object} [options.globalInfo]
 *
 * */

function saveAPIsSettings({
  settingConfig = mandatory('settingConfig'),
  callback,
  errorHandler,
  globalInfo,
} = {}) {
  const logError = (error) => {
    const errorMessage = getErrorMessage(determineError(error));
    if (isNull(getErrorStatus(error))) {
      logger.error({
        event: 'Save Account API Settings Error',
        properties: {
          location: 'common/settings/saveAPIsSettings',
          arguments: {
            settingConfig,
          },
        },
        error,
      });
    }
    return errorMessage;
  };

  const resetSaveState = () => {
    if (this.props?.eventHandlers?.handleSaveState) {
      this.props.eventHandlers.handleSaveState(false);
    }
  };

  postAPIsSettingsType(settingConfig)
    .then(() => {
      if (globalInfo) {
        globalInfo.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          callback: () => {
            if (isDefined(callback)) {
              callback();
            }
          },
        });
      } else {
        this.props.global.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          callback: () => {
            if (this) {
              resetSaveState();
              this.setState(
                (prevState) => ({
                  data: prevState.data
                    .set('saveState', SAVE_STATES.SUCCEEDED)
                    .set('saveMessage', 'Saved'),
                }),
                () => {
                  if (isDefined(callback)) {
                    callback();
                  }
                },
              );
            }
          },
        });
      }
    })
    .catch((error) => {
      const errorMessage = logError(error);
      if (globalInfo) {
        if (isDefined(errorHandler)) {
          errorHandler(errorMessage);
        }
      } else {
        resetSaveState();
        this.setState(
          (prevState) => ({
            data: prevState.data
              .set('saveState', SAVE_STATES.FAILED)
              .set('saveMessage', 'Not saved')
              .set('errorMessage', errorMessage),
          }),
          () => {
            if (isDefined(errorHandler)) {
              errorHandler(errorMessage);
            }
          },
        );
      }
    });
}

/**
 * savePropertiesSettings
 * @param {{
 *  propertyId: string;
 *  settingConfig: object;
 *  callback?: ()=>void;
 *  errorHandler?: () => void;
 * globalInfo?: import('types').GlobalInfo;
 * }} args
 * */
function savePropertiesSettings({
  propertyId = mandatory('propertyId'),
  settingConfig = mandatory('settingConfig'),
  callback,
  errorHandler,
  globalInfo,
} = {}) {
  const { settingTypeId } = settingConfig;
  const logError = (error) => {
    const errorMessage = getErrorMessage(determineError(error));
    if (isNull(getErrorStatus(error))) {
      logger.error({
        event: 'Save Properties Settings Error',
        properties: {
          location: 'common/savePropertiesSettings',
          arguments: {
            propertyId,
            settingTypeId,
            settingConfig,
          },
        },
        error,
      });
    }
    return errorMessage;
  };

  postPropertiesSettings(settingConfig)
    .then(() => {
      if (this) {
        this.props.global.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          callback: () => {
            this.setState((prevState) => ({
              data: prevState.data
                .set('saveState', SAVE_STATES.SUCCEEDED)
                .set('saveMessage', 'Saved')
                .set('isChanged', false),
            }));
            if (isDefined(callback)) {
              callback();
            }
          },
        });
      } else {
        globalInfo.refreshGlobalInfo({
          reasonCode: GLOBAL_INFO_STATES.UPDATING_SETTINGS,
          callback: () => {
            if (isDefined(callback)) {
              callback();
            }
          },
        });
      }
    })
    .catch((error) => {
      const errorMessage = logError(error);
      this.setState(
        (prevState) => ({
          data: prevState.data
            .set('saveState', SAVE_STATES.FAILED)
            .set('saveMessage', 'Not saved')
            .set('errorMessage', errorMessage)
            .set('isChanged', true),
        }),
        () => {
          if (isDefined(errorHandler)) {
            errorHandler();
          }
        },
      );
    });
}

/**
 * storeSettings
 *
 * @param {{
 *  propertyId: string,
 *  accountAPIId: string,
 *  existingProperties: Record<string, Partial<import('types').GlobalInfo.Property>>,
 *  settings: Record<import('types').SettingTypeId, import('types').GlobalInfo.Setting[]>,
 * }}
 * @return {Record<string, import('types').GlobalInfo.Property>}
 */

function storeSettings({
  propertyId = mandatory('propertyId'),
  accountAPIId = mandatory('accountAPIId'),
  existingProperties = mandatory('existingProperties'),
  settings = mandatory('settings'),
} = {}) {
  // Create independent copy of properties data
  const properties = cloneObject(existingProperties);

  // Loop over each setting...
  Object.keys(settings).forEach((settingTypeId) => {
    const setting = settings[settingTypeId];
    if (ACCOUNT_API_LEVEL_SETTINGS.indexOf(Number(settingTypeId)) === -1) {
      // Property level setting
      // Create any missing intermediate structures
      if (isUndefined(properties[propertyId].propertySettings)) {
        properties[propertyId].propertySettings = {};
      }
      // Store setting
      properties[propertyId].propertySettings[settingTypeId] = setting;
    } else {
      // API level setting
      // Create any missing intermediate structures
      if (isUndefined(properties[propertyId].accountAPIs)) {
        properties[propertyId].accountAPIs = {};
      }
      if (isUndefined(properties[propertyId].accountAPIs[accountAPIId])) {
        properties[propertyId].accountAPIs[accountAPIId] = {};
      }
      if (
        isUndefined(
          properties[propertyId].accountAPIs[accountAPIId].accountSettings,
        )
      ) {
        properties[propertyId].accountAPIs[accountAPIId].accountSettings = {};
      }
      // Store setting
      properties[propertyId].accountAPIs[accountAPIId].accountSettings[
        settingTypeId
      ] = setting;
    }
  });

  // Return updated properties object
  return properties;
}

/** *
Saves automation settings for a given account.
* @param {number} accountAPIId - The ID of the account.
* @param {object} settingsJSON - The JSON object containing automation settings.
* @param {Dispatch<ACTION>} dispatch - The dispatch function for Redux actions.
* @param {ChakraUI.Toast} toast - The ChakraUI toast component.
* @param {object} globalInfo
* @param {function | null} mixpanelTracking - The method for Mixpanel tracking.
* @returns {void}
*/

function saveAutomationSettings(
  accountAPIId,
  settingsJSON,
  dispatch,
  toast,
  globalInfo,
  mixpanelTracking = null,
) {
  const settingConfig = {
    accountAPIId,
    settingTypeId: ACCOUNT_SETTING_TYPES.AUTO_FEED,
    enabled: true,
    dataJSON: settingsJSON,
  };

  setSettingsSaving(dispatch);
  const previousTracking = tracking.getAutofeedSettingsTrackingParams({
    autofeedSettings: getAutofeedSettings({ accountAPIId }),
  });

  saveAPIsSettings({
    settingConfig,
    callback: () => {
      toast({
        title: 'Setting updated',
        description: 'Changes can take up to 8 hours to take effect',
        variant: 'success',
      });
      resetSettingsState(dispatch);
      const updatedTracking = tracking.getAutofeedSettingsTrackingParams({
        autofeedSettings: getAutofeedSettings({ accountAPIId }),
      });
      if (mixpanelTracking) mixpanelTracking(previousTracking, updatedTracking);
    },
    errorHandler: () => {
      addErrorNotification('Error saving setting');
    },
    globalInfo,
  });
}
