import { Spinner } from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';

import {
  COLLECTION_NAMES,
  GLOBAL_INFO_CHANGES,
  UI_MESSAGES,
} from 'common/constants';
import { getTimeFilterRange, getUnixTimestamp } from 'common/datetime';
import { getErrorMessage } from 'common/errorHandling';
import * as MediaItem from 'common/mediaItem';
import { addErrorNotification } from 'common/notifications';
import { isNull } from 'common/utility';
import BaseComponent from 'components/BaseComponent';
import Filters from 'components/home/lastshared/Filters';
import Item from 'components/home/lastshared/Item';
import withGlobalInfo from 'context/withGlobalInfo';

/**
 * Last queued
 */

class LastShared extends BaseComponent {
  /**
   * Initial state
   */

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      isLoadingMore: false,
    };
    this._bind('handleExpand', 'handleTimeframeChange');
  }

  /**
   * Lifecycle methods
   */

  componentDidMount() {
    this.initialiseComponent();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this._compare(COLLECTION_NAMES.LAST_SHARED, {
      nextProps,
      nextState,
      globalInfo: GLOBAL_INFO_CHANGES.LOADING_OR_SELECTING,
    });
  }

  componentDidUpdate(prevProps) {
    const hasChangedToReady = this.props.global.hasChangedToReady(
      prevProps.global.globalInfoState,
      this.props.global.globalInfoState,
      { excludeLoading: true },
    );
    if (hasChangedToReady) {
      this.initialiseComponent();
    }
  }

  /**
   * Event handlers
   */

  handleExpand() {
    const { sharedMethods } = this.props;
    this.setState(
      {
        isExpanded: true,
        isLoadingMore: true,
      },
      async () => {
        try {
          await sharedMethods.loadMoreItems({
            collectionName: COLLECTION_NAMES.LAST_SHARED,
            itemsToLoad: 999999,
          });
          this.setState({
            isLoadingMore: false,
          });
        } catch (error) {
          let message = getErrorMessage(error);
          if (message === '') {
            message = 'An error occurred';
          }
          addErrorNotification(message);
          this.setState({
            isLoadingMore: false,
          });
        }
      },
    );
  }

  handleTimeframeChange(event) {
    const { fromTime, toTime } = getTimeFilterRange(event.target.value);
    this.props.eventHandlers.handleTimeframeChange({
      collectionName: COLLECTION_NAMES.LAST_SHARED,
      timeFrame: event.target.value,
      fromTime,
      toTime,
    });
  }

  /**
   * Helper methods
   */

  initialiseComponent() {
    this.props.sharedMethods.getFeedData({
      collectionName: COLLECTION_NAMES.LAST_SHARED,
    });
  }

  /**
   * Render method
   */

  render() {
    const MIN_ITEMS = 10;
    const feedData = this.props.feedData;
    const isLoading =
      feedData.isLoading ||
      this.props.global.isLoading() ||
      this.props.global.isSelecting();
    const emptyMessage = UI_MESSAGES.LAST_SHARED_NO_ITEMS_FOUND;
    const isExpanded = this.state.isExpanded;
    const isLoadingMore = this.state.isLoadingMore;
    let showData;
    if (isExpanded || feedData.mediaIds.length <= MIN_ITEMS + 1) {
      showData = feedData.mediaItems;
    } else {
      showData = feedData.mediaItems.slice(0, MIN_ITEMS);
    }
    const eventHandlers = this.props.eventHandlers;
    let lastShared =
      feedData.mediaItems.length > 0
        ? MediaItem.getMostRecentUnixTimeShared({
            mediaItem: feedData.mediaItems[0],
          })
        : null;
    if (!isNull(lastShared)) {
      lastShared = getUnixTimestamp() - lastShared;
    }
    const feedClass =
      this.props.tabName === 'lastshared'
        ? 'd-md-none'
        : 'd-none d-md-inline-block';

    return (
      <div
        className={`queue last_shared w-100 ${feedClass}`}
        data-cy-id="lastShared"
        data-cy-attribute={`isLoading:${isLoading}`}
      >
        <Filters
          displayOptions={feedData.displayOptions}
          lastSharedCount={feedData.mediaIds.length}
          lastSharedDate={lastShared}
          isLoading={isLoading}
          currentTime={this.props.currentTime}
          eventHandlers={{
            handleTimeframeChange: this.handleTimeframeChange,
          }}
        />
        {isLoading && (
          <div className="empty_feed text-center py-5">
            <Spinner size="lg" />
          </div>
        )}
        {!isLoading && showData.length > 0 && (
          <div className="queue_main">
            {showData.map((mediaItem) => (
              <Item
                mediaItem={mediaItem}
                accountAPIId={this.props.accountAPIId}
                key={MediaItem.getMediaId({ mediaItem })}
                eventHandlers={{
                  handleArticleDelete: eventHandlers.handleArticleDelete,
                  handleArticleLoad: eventHandlers.handleArticleLoad,
                  handleArticleReshare: eventHandlers.handleArticleReshare,
                }}
              />
            ))}
            {!isExpanded && feedData.mediaIds.length > showData.length && (
              <a
                className="expand link_grey"
                onClick={this.handleExpand}
                data-cy-id="expandLastShared"
              >
                <img src="/img/icons/ic-arrow-down.svg" alt="" />
                {feedData.mediaIds.length - showData.length} more posts
              </a>
            )}
          </div>
        )}
        {!isLoading && showData.length === 0 && (
          <div className="queue_main">
            <div className="share_item_empty">
              <div className="cleared_message">{emptyMessage}</div>
            </div>
          </div>
        )}
        {isLoadingMore && (
          <div className="empty_feed text-center py-5">
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

LastShared.propTypes = {
  accountAPIId: PropTypes.number.isRequired,
  feedData: PropTypes.object.isRequired,
  tabName: PropTypes.string.isRequired,
  eventHandlers: PropTypes.shape({
    handleArticleLoad: PropTypes.func.isRequired,
    handleArticleReshare: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    handleTimeframeChange: PropTypes.func.isRequired,
    handleArticleDelete: PropTypes.func.isRequired,
  }).isRequired,
  sharedMethods: PropTypes.shape({
    getFeedData: PropTypes.func.isRequired,
    loadMoreItems: PropTypes.func.isRequired,
  }).isRequired,
};

export default withGlobalInfo(LastShared);
