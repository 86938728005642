import FlashMessages from 'components/misc/FlashMessages';
import StatusPageNotification from 'components/misc/StatusPageNotification';

const AllNotifications = () => {
  return (
    <div className="all-notifications-wrapper">
      <StatusPageNotification />
      <FlashMessages />
    </div>
  );
};

export default AllNotifications;
