import PropTypes from 'prop-types';

const Domain = (props) => {
  const { domain, isSaving, eventHandlers } = props;

  const savingClass = isSaving ? 'saving_locked' : '';

  const handleRemoveDomain = () => {
    eventHandlers.handleRemoveDomain(domain);
  };

  return (
    <div
      className="border-top py-2 px-3 d-flex align-items-center"
      key={domain}
    >
      <div>{domain}</div>
      <div className="ml-auto">
        <button
          onClick={handleRemoveDomain}
          className={`close ml-auto lh-14 ${savingClass}`}
          aria-label="Close"
          disabled={isSaving}
          type="button"
          data-cy-action="removeDomain"
        >
          <span aria-hidden="true" className="text-400 gray-600 ft-20">
            &times;
          </span>
        </button>
      </div>
    </div>
  );
};

Domain.propTypes = {
  domain: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  eventHandlers: PropTypes.shape({
    handleRemoveDomain: PropTypes.func.isRequired,
  }).isRequired,
};

export default Domain;
