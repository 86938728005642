import { Link } from 'react-router-dom';

const NoFeeds = () => {
  return (
    <div className="setting_page">
      <div className="box notification-no-rss" data-cy-id="noFeeds">
        <div className="d-flex align-items-start">
          <img
            className="rss-not-connected-icon"
            src="/img/icons/ic-rss-feed-2.svg"
            alt=""
          />
          <div className="d-flex flex-column">
            <div className="ft-15 text-700 mr-auto align-self-center rss-not-connected">
              RSS Feed not connected
            </div>
            <div className="mt-1">
              Add an RSS feed to this page to automatically see content here
            </div>
          </div>
        </div>
        <div className="align-self-center ml-auto mt-2 mt-sm-0">
          <Link to="/settings/content-feeds" className="btn btn-dark">
            Add RSS Feed
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoFeeds;
