import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  HStack,
  Input,
  Modal,
  Tag,
  Text,
  VStack,
} from '@ebx-ui/ebx-ui-component-library-sdk';
import PropTypes from 'prop-types';
import { memo, useContext, useEffect, useState } from 'react';

import { getDebugMediaItem, getPropertiesUsers } from 'api/api';
import {
  getCurrentAccountAPI,
  getCurrentProperty,
  getUserPropertyPermission,
} from 'common/accountAPIs';
import * as authentication from 'common/authentication';
import { setClipboardData } from 'common/clipboard';
import { FLASH_MESSAGE_TYPES, PERMISSION_TYPES } from 'common/constants';
import { determineError, getErrorMessage } from 'common/errorHandling';
import { FRONTEND_ERROR_MESSAGES } from 'common/errorMessages';
import * as logger from 'common/logger';
import { addNotification } from 'common/notifications';
import { ErrorWithStatusSchema } from 'common/schemas';
import { arePropsEqual, isNull, isRunningTests } from 'common/utility';
import { location } from 'common/window';
import DebugMediaModal from 'components/header/DebugMediaModal';
import ImpersonatePropertyForm from 'components/header/ImpersonatePropertyForm';
import ImpersonateSocialPageForm from 'components/header/ImpersonateSocialPageForm';
import OpsToolsModal from 'components/header/OpsToolsModal/OpsToolsModal';
import UploadSeasonalKeywordsModal from 'components/header/UploadSeasonalKeywordsModal';
import { useFlashMessagesContext } from 'context/FlashMessageContext';
import GlobalInfoContext from 'context/GlobalInfoContext';
import { useOnlineStatus } from 'hooks/useOnlineStatus';
import { impersonateUser } from 'process/impersonate';

import AccountAPIToolsModal from './AccountAPIToolsModal';
import ContentFeedToolsModal from './ContentFeedToolsModal/ContentFeedToolsModal';
import FeatureFlagModal from './FeatureFlagModal';
import PagesSetupModal from './PagesSetupModal';
import PropertyToolsModal from './PropertyToolsModal';
import WhitelistingToolModal from './WhitelistingToolModal';

/**
 * Enhanced permissions user login and options
 */

const EnhancedPermissionsMenu = ({
  eventHandlers: { handleHideEnhancedPermissionsMenu },
}) => {
  /**
   * Initial state
   */
  const flashMessagesContext = useFlashMessagesContext();
  const globalInfoContext = useContext(GlobalInfoContext);
  const [isLoadingImpersonatedUser, setIsLoadingImpersonatedUser] =
    useState(false);
  const [isLoadingStopImpersonating, setIsLoadingStopImpersonating] =
    useState(false);
  const [retrievingDebugInfo, setRetrievingDebugInfo] = useState(false);
  const [debugMediaResponse, setDebugMediaResponse] = useState(null);
  const [debugMediaError, setDebugMediaError] = useState(null);
  const { isOnline } = useOnlineStatus();
  const [enhancedPermissionsUsername, setEnhancedPermissionsUsername] =
    useState('');
  const [
    enhancedPermissionsUsernameError,
    setEnhancedPermissionsUsernameError,
  ] = useState(null);
  const [mediaItem, setMediaItem] = useState('');
  const [isStaffUser, setIsStaffUser] = useState(false);

  const [showAccountAPITools, setShowAccountAPITools] = useState(false);
  const [showFeatureFlagModal, setShowFeatureFlagsModal] = useState(false);
  const [showOpsToolsModal, setShowOpsToolsModal] = useState(false);
  const [showPagesSetupModal, setShowPagesSetupModal] = useState(false);
  const [showPropertyToolsModal, setShowPropertyToolsModal] = useState(false);
  const [showSeasonalKeywordsModal, setShowSeasonalKeywordsModal] =
    useState(false);
  const [showWhitelistingToolModal, setShowWhitelistingToolModal] =
    useState(false);
  const [showContentFeedToolsModal, setShowContentFeedToolsModal] =
    useState(false);
  const [accountAPIToolsPropertyId, setAccountAPIToolsPropertyId] =
    useState('');

  useEffect(() => {
    const checkForUserType = async () => {
      const isStaff = await authentication.isStaffUser();
      setIsStaffUser(isStaff);
    };

    checkForUserType();
  }, []);

  /**
   * Helper methods
   */

  const isHidingStatusPage = () => sessionStorage.getItem('hideStatusPage');
  const isHidingTooltips = () => sessionStorage.getItem('hideTooltips');
  const isRefreshDisabled = () => sessionStorage.getItem('disableAutoRefresh');

  /**
   * Event handlers
   */

  const handleDebugClick = async (event) => {
    event.preventDefault();

    const globalInfo = globalInfoContext.global.getGlobalInfo();

    const property = getCurrentProperty();
    const propertyDetails = `${property.propertyId} - ${property.propertyName}`;

    const page = getCurrentAccountAPI();
    const pageDetails = page
      ? `${page.accountAPIId} - ${page.apiPostName} - ${page.apiHomePage}`
      : 'Not set';

    let users = [];
    const isAdmin =
      getUserPropertyPermission({
        userId: globalInfo.user.userId,
        propertyId: property.propertyId,
        globalInfo,
      }) === PERMISSION_TYPES.ADMIN;

    if (property.propertyUsers) {
      users = property.propertyUsers;
    } else if (isAdmin) {
      const response = await getPropertiesUsers({
        propertyIds: [property.propertyId],
      });
      if (!isNull(response)) {
        users = response[property.propertyId];
        globalInfo.properties[property.propertyId].propertyUsers = users;
      }
      globalInfoContext.global.setGlobalInfo(globalInfo);
    }
    let userDetails;
    if (!isAdmin || users.length === 0) {
      userDetails = `${globalInfo.user.emailAddress} [Editor]`;
    } else {
      const admins = users
        .filter(
          (user) =>
            getUserPropertyPermission({
              userId: user.userId,
              propertyId: property.propertyId,
              globalInfo,
            }) === PERMISSION_TYPES.ADMIN,
        )
        .sort((user1, user2) => user1.timeCreated - user2.timeCreated);
      const admin = admins ? admins[0] : null;
      userDetails = `${admin.emailAddress} [Admin]`;
    }

    const text = `*Property:* ${propertyDetails}
*User email:* ${userDetails}
*Page:* ${pageDetails}
`;
    setClipboardData(null, text);
    addNotification('Metadata copied to clipboard', 'success');
    handleHideEnhancedPermissionsMenu();
  };

  const handleFlashMessageClick = (type, text) => {
    flashMessagesContext.flashMessages.addMessage({
      messageCategory: 'Enhanced permissions menu flash message test',
      type,
      text,
    });
    handleHideEnhancedPermissionsMenu();
  };

  const handleHideStatusPageClick = (event) => {
    event.preventDefault();

    logger.info('EnhancedPermissionsMenu:handleHideStatusPageClick');
    if (isHidingStatusPage()) {
      sessionStorage.removeItem('hideStatusPage');
    } else {
      sessionStorage.setItem('hideStatusPage', 'true');
    }
    location.reload(true);
  };

  const handleHideTooltipsClick = (event) => {
    event.preventDefault();

    logger.info('EnhancedPermissionsMenu:handleHideTooltipsClick');
    if (isHidingTooltips()) {
      sessionStorage.removeItem('hideTooltips');
    } else {
      sessionStorage.setItem('hideTooltips', 'true');
    }
    location.reload(true);
  };

  const handleNotificationClick = (level, message) => {
    addNotification(message, level);
    handleHideEnhancedPermissionsMenu();
  };

  const impersonateUsername = async (username) => {
    await impersonateUser({
      username,
    });
    flashMessagesContext.flashMessages.deleteMessages();
    if (window) {
      location.reload(true);
    }
  };

  const handleEnhancedPermissionsSubmit = async (event) => {
    event.preventDefault();
    setEnhancedPermissionsUsernameError(null);
    setIsLoadingImpersonatedUser(true);
    logger.info('EnhancedPermissionsMenu:handleEnhancedPermissionsSubmit');

    if (enhancedPermissionsUsername !== '') {
      // Attempt to log in as specified user
      impersonateUsername(enhancedPermissionsUsername.trim()).catch((error) => {
        const parsedError = ErrorWithStatusSchema.safeParse(error);
        if (parsedError.success && parsedError.data.status === 403) {
          setEnhancedPermissionsUsernameError(
            FRONTEND_ERROR_MESSAGES['impersonate-403'],
          );
        } else if (parsedError.success && parsedError.data.status === 404) {
          setEnhancedPermissionsUsernameError(
            FRONTEND_ERROR_MESSAGES['impersonate-404'],
          );
        } else {
          flashMessagesContext.flashMessages.addMessage({
            messageCategory:
              'Impersonate user POST unexpected impersonate error',
            type: FLASH_MESSAGE_TYPES.ERROR,
            text: getErrorMessage(determineError(error)),
            timeout: 2500,
          });
        }
        setIsLoadingImpersonatedUser(false);
        setEnhancedPermissionsUsername('');
      });
    }
  };

  const handleStopImpersonating = (event) => {
    setIsLoadingStopImpersonating(true);
    event.preventDefault();
    const clientServiceToken = authentication.getClientServiceToken();
    const decodedUser = authentication.decodeJWTToken(clientServiceToken);
    impersonateUsername(decodedUser.impersonated_by_staff_email);
  };

  const handleMediaItemSubmit = async (event) => {
    event.preventDefault();
    logger.info('EnhancedPermissionsMenu:handleMediaItemSubmit');
    if (mediaItem !== '') {
      setRetrievingDebugInfo(true);

      try {
        const response = await getDebugMediaItem({
          mediaItemIdentifier: mediaItem,
        });
        setDebugMediaResponse(response);
      } catch (error) {
        setDebugMediaError(getErrorMessage(determineError(error)));
      }

      setRetrievingDebugInfo(false);
    }
  };

  const handleStatusPageClick = (event) => {
    event.preventDefault();

    sessionStorage.setItem('simulateStatusPageNotification', 'true');
    location.reload(true);
  };

  const handleToggleRefreshClick = (event) => {
    event.preventDefault();

    if (isRefreshDisabled()) {
      sessionStorage.removeItem('disableAutoRefresh');
    } else {
      sessionStorage.setItem('disableAutoRefresh', 'true');
    }
    location.reload(true);
  };

  const handleUsernameChange = (event) => {
    setEnhancedPermissionsUsername(event.target.value.trim());
  };

  const handleMediaItemChange = (event) => {
    setDebugMediaError(null);
    setMediaItem(event.target.value.trim());
  };

  const handlePropertyClick = (event) => {
    event.preventDefault();
    setShowPropertyToolsModal(true);
  };

  const handleAccountAPIClick = (event, propertyId) => {
    event.preventDefault();
    setAccountAPIToolsPropertyId(propertyId);
    setShowAccountAPITools(true);
  };

  const handleOpsClick = (event) => {
    event.preventDefault();
    setShowOpsToolsModal(true);
  };

  const handleWhitelistingClick = (event) => {
    event.preventDefault();
    setShowWhitelistingToolModal(true);
  };

  const handleFeatureFlagClick = () => {
    setShowFeatureFlagsModal(true);
  };

  const handlePageSetupClick = () => {
    setShowPagesSetupModal(true);
  };

  const handleSyndFeedClick = () => {
    setShowContentFeedToolsModal(true);
  };

  /**
   * @type Array<{
   *   title: string;
   *   items: Array<{
   *     title: string;
   *     onClick?: React.MouseEventHandler<HTMLButtonElement>;
   *   }>;
   * }>
   */
  const SECTIONS = [
    {
      title: 'Other tools',
      items: [
        { title: 'Account API tools', onClick: handleAccountAPIClick },
        { title: 'Capture debug metadata', onClick: handleDebugClick },
        {
          title: `${isRefreshDisabled() ? 'Enable' : 'Disable'} auto refresh`,
          onClick: handleToggleRefreshClick,
        },
        { title: 'Content Feed tools', onClick: handleSyndFeedClick },
        { title: 'Whitelisting tool', onClick: handleWhitelistingClick },
      ],
    },
  ];

  if (isStaffUser) {
    SECTIONS[0].items = [
      ...SECTIONS[0].items,
      { title: 'Ops tools', onClick: handleOpsClick },
      { title: 'Override feature flags', onClick: handleFeatureFlagClick },
      { title: 'Pages setup', onClick: handlePageSetupClick },
    ];

    SECTIONS.push({
      title: 'Visual settings',
      items: [
        {
          title: `${isHidingStatusPage() ? 'Show' : 'Hide'} external status
        notifications`,
          onClick: handleHideStatusPageClick,
        },
        {
          title: `${isHidingTooltips() ? 'Show' : 'Hide'} tooltips`,
          onClick: handleHideTooltipsClick,
        },
      ],
    });

    SECTIONS.push({
      title: 'Display notification',
      items: [
        {
          title: 'Page-wide',
        },
        {
          title: 'Status page',
          onClick: handleStatusPageClick,
        },
        {
          title: 'Success',
          onClick: (event) => {
            event.preventDefault();
            handleFlashMessageClick(
              FLASH_MESSAGE_TYPES.SUCCESS,
              'TEST SUCCESS MESSAGE, PLEASE IGNORE',
            );
          },
        },
        {
          title: 'Error',
          onClick: (event) => {
            event.preventDefault();
            handleFlashMessageClick(
              FLASH_MESSAGE_TYPES.ERROR,
              'TEST SUCCESS ERROR, PLEASE IGNORE',
            );
          },
        },
        {
          title: 'Info',
          onClick: (event) => {
            event.preventDefault();
            handleFlashMessageClick(
              FLASH_MESSAGE_TYPES.INFO,
              'TEST SUCCESS INFO, PLEASE IGNORE',
            );
          },
        },
        {
          title: 'Bottom-left',
        },
        {
          title: 'Success',
          onClick: (event) => {
            event.preventDefault();
            handleNotificationClick(
              'success',
              'TEST SUCCESS NOTIFICATION, PLEASE IGNORE',
            );
          },
        },
        {
          title: 'Error',
          onClick: (event) => {
            event.preventDefault();
            handleNotificationClick(
              'error',
              'TEST ERROR NOTIFICATION, PLEASE IGNORE',
            );
          },
        },
      ],
    });

    SECTIONS.push({
      title: 'Seasonal Keywords',
      items: [
        {
          title: 'Upload CSV',
          onClick: () => setShowSeasonalKeywordsModal(true),
        },
      ],
    });
  }

  return (
    <Modal
      isOpen
      onClose={handleHideEnhancedPermissionsMenu}
      size="small"
      closeOnEsc
    >
      <Modal.Header>
        <Modal.Title>
          Staff menu
          <Tag ml={4} color={isOnline ? 'green' : 'red'}>
            {isOnline ? 'Online' : 'Offline'}
          </Tag>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body background="gray.100">
        <Flex gap={7} direction="column">
          {authentication.isImpersonating() && (
            <Button
              variant="secondary"
              onClick={handleStopImpersonating}
              isLoading={isLoadingStopImpersonating}
              flexShrink={0}
              mb={2}
            >
              Stop Impersonating
            </Button>
          )}
          <Box as="form" onSubmit={handleEnhancedPermissionsSubmit}>
            <FormControl isInvalid={enhancedPermissionsUsernameError !== null}>
              <FormControl.FormLabel>Impersonate a user</FormControl.FormLabel>
              <HStack>
                <Input
                  type="text"
                  placeholder="Enter client email..."
                  value={enhancedPermissionsUsername}
                  onChange={handleUsernameChange}
                  autoComplete="new-password"
                  autoFocus={!isRunningTests()}
                />
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={handleEnhancedPermissionsSubmit}
                  isLoading={isLoadingImpersonatedUser}
                  loadingText="Submitting"
                  flexShrink={0}
                >
                  Submit
                </Button>
              </HStack>
              {enhancedPermissionsUsernameError && (
                <FormControl.FormErrorMessage>
                  {enhancedPermissionsUsernameError}
                </FormControl.FormErrorMessage>
              )}
            </FormControl>
          </Box>
          <VStack alignItems="right" mt={1} spacing={2} mb={0}>
            <ImpersonatePropertyForm
              handleAccountAPIToolsClick={(event, propertyId) =>
                handleAccountAPIClick(event, propertyId)
              }
            />
            <Box>
              <Button variant="link" onClick={handlePropertyClick}>
                Property search
              </Button>
            </Box>
          </VStack>
          <ImpersonateSocialPageForm />
          <Box as="form" onSubmit={handleMediaItemSubmit}>
            <FormControl isInvalid={!!debugMediaError}>
              <FormControl.FormLabel>Debug media item</FormControl.FormLabel>
              <HStack>
                <Input
                  type="text"
                  placeholder="Enter media item ID or URL..."
                  onChange={handleMediaItemChange}
                  value={mediaItem}
                />
                <Button
                  type="submit"
                  variant="secondary"
                  onClick={handleMediaItemSubmit}
                  isLoading={retrievingDebugInfo}
                  loadingText="Submitting"
                  flexShrink={0}
                >
                  Submit
                </Button>
              </HStack>
              {debugMediaError && (
                <FormControl.FormErrorMessage wordBreak="break-word">
                  {debugMediaError}
                </FormControl.FormErrorMessage>
              )}
            </FormControl>
          </Box>
          {SECTIONS.map(({ title, items }) => (
            <Box key={title} as="section">
              <Heading variant="h5">{title}</Heading>
              <VStack as="ul" alignItems="start" mt={2} spacing={2} mb={0}>
                {items.map(({ title: itemTitle, onClick }, idx) => {
                  const key = `${title}-${itemTitle}-${idx}`;
                  if (!onClick) {
                    return (
                      <Text
                        size="sm"
                        key={key}
                        as="li"
                        color="gray.600"
                        fontWeight="medium"
                      >
                        {itemTitle}
                      </Text>
                    );
                  }
                  return (
                    <Box key={key} as="li">
                      <Button variant="link" onClick={onClick}>
                        {itemTitle}
                      </Button>
                    </Box>
                  );
                })}
              </VStack>
            </Box>
          ))}
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleHideEnhancedPermissionsMenu}>
          Close
        </Button>
      </Modal.Footer>
      <DebugMediaModal
        response={debugMediaResponse}
        eventHandlers={{
          handleCloseDebug: () => setDebugMediaResponse(null),
        }}
      />
      {showPropertyToolsModal && (
        <PropertyToolsModal
          handleAccountAPIToolsClick={handleAccountAPIClick}
          onClose={() => setShowPropertyToolsModal(false)}
        />
      )}
      {showAccountAPITools && (
        <AccountAPIToolsModal
          preparedPropertyId={accountAPIToolsPropertyId}
          onClose={() => setShowAccountAPITools(false)}
        />
      )}
      {showOpsToolsModal && (
        <OpsToolsModal onClose={() => setShowOpsToolsModal(false)} />
      )}
      {showWhitelistingToolModal && (
        <WhitelistingToolModal
          onClose={() => setShowWhitelistingToolModal(false)}
        />
      )}
      {showSeasonalKeywordsModal && (
        <UploadSeasonalKeywordsModal
          onClose={() => setShowSeasonalKeywordsModal(false)}
        />
      )}
      {showFeatureFlagModal && (
        <FeatureFlagModal onClose={() => setShowFeatureFlagsModal(false)} />
      )}
      {showPagesSetupModal && (
        <PagesSetupModal onClose={() => setShowPagesSetupModal(false)} />
      )}
      {showContentFeedToolsModal && (
        <ContentFeedToolsModal
          onClose={() => setShowContentFeedToolsModal(false)}
        />
      )}
    </Modal>
  );
};

EnhancedPermissionsMenu.propTypes = {
  eventHandlers: PropTypes.shape({
    handleHideEnhancedPermissionsMenu: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(EnhancedPermissionsMenu, arePropsEqual);
