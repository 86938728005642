import { Box } from '@ebx-ui/ebx-ui-component-library-sdk';

import PageDetails from 'components/header/PageDetails';

const Details = () => {
  return (
    <Box pt="2.5">
      <PageDetails />
    </Box>
  );
};

export default Details;
